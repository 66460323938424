import { Injectable, Input, EventEmitter } from '@angular/core';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
var Buffer = require('buffer/').Buffer;
@Injectable({
  providedIn: 'root'
})
export class NovaLigacaoService {

  constructor() { }

  public id: any;

  public imprimirNovaLigacao(data, original: any = 'Original', report: string = 'imprimir') {
    console.log('data => ', data)
    // You'll need to make your image into a Data URL
    // Use http://dataurl.net/#dataurlmaker
    var imgData = data.empresa.logotipo
    var doc = new jsPDF('p');
    var posX = 1;
    var posY = 5;
    var logo_width = 35;
    var logo_height = 40;
    var pos_body = logo_height + 15;
    var pos_equipamento = 0;
    var pos_cliente = 0;
    var pos_contrato = 0;

    var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
    var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

    doc.addImage(imgData, 'JPEG', 8, 14, 28, 24);
    doc.setFontSize(8);
    doc.setFontType('Arial');

    if (data.empresa.addressDetail.length <= 62) {
      doc.text(posX + logo_width + 5, 30, 'NIF: ');
      doc.text(posX + logo_width + 13, 30, '' + (data.empresa.taxRegistrationNumber == null ? '' : data.empresa.taxRegistrationNumber));
      doc.text(posX + logo_width + 5, 35, 'Email: ');
      doc.text(posX + logo_width + 16, 35, '' + (data.empresa.email == null ? '' : data.empresa.email));
      doc.text(posX + logo_width + 50, 30, 'Telefone : ');
      doc.text(posX + logo_width + 65, 30, '' + (data.empresa.telefone == null ? '' : data.empresa.telefone));
      doc.text(posX + logo_width + 50, 35, 'Website: ');
      doc.text(posX + logo_width + 65, 35, '' + (data.empresa.site == null ? '' : data.empresa.site));
    } else {
      doc.text(posX + logo_width + 5, 33, 'NIF: ');
      doc.text(posX + logo_width + 13, 33, '' + (data.empresa.taxRegistrationNumber == null ? '' : data.empresa.taxRegistrationNumber));
      doc.text(posX + logo_width + 5, 38, 'Email: ');
      doc.text(posX + logo_width + 16, 38, '' + (data.empresa.email == null ? '' : data.empresa.email));
      doc.text(posX + logo_width + 60, 33, 'Telefone : ');
      doc.text(posX + logo_width + 80, 33, '' + (data.empresa.telefone == null ? '' : data.empresa.telefone));
      doc.text(posX + logo_width + 60, 38, 'Website: ');
      doc.text(posX + logo_width + 80, 38, '' + (data.empresa.site == null ? '' : data.empresa.site));
    }
    doc.text(posX + logo_width + 5, 20, '' + (data.empresa.companyName == null ? '' : data.empresa.companyName));
    //lines = doc.splitTextToSize(endereco, 62)
    doc.text(posX + logo_width + 5, 25, '' + (data.empresa.addressDetail == null ? '' : data.empresa.addressDetail));
    var pos_rectangulo = posX + logo_width + 110;
    doc.rect(pos_rectangulo, 18, pageWidth / 3 - 17, 26)
    doc.setFontSize(8);
    doc.setFontType('bold');
    doc.text(pos_rectangulo + 5, 23, 'Nº ' + ((data.novaLigacao || {}).novaligacao_id == null ? '' : (data.novaLigacao || {}).novaligacao_id))
    doc.setFontType('Normal');
    doc.text(pos_rectangulo + 5, 28, 'Data:' + (data.novaLigacao || {}).data_criacao + ' ' + (data.novaLigacao || {}).hora_criacao);
    doc.text(pos_rectangulo + 5, 34, 'Agência:');
    doc.text(pos_rectangulo + 18, 34, '' + ((data.novaLigacao || {}).loja == null ? '' : (data.novaLigacao || {}).loja));
    doc.setFontType('Normal');
    doc.text(pos_rectangulo + 5, 40, 'Utilizador:');
    doc.text(pos_rectangulo + 18, 40, '' + ((data.novaLigacao || {}).user == null ? '' : (data.novaLigacao || {}).user));



    // CORPO DO RELATÓRIO ........
    doc.setFontSize(8);
    doc.setFillColor(208, 211, 212);
    doc.setFontType('bold');
    doc.rect(10, pos_body, pageWidth - 20, 5);
    logo_height = pos_body - 5;
    doc.text(posX + 10, logo_height + 8.5, 'DADOS DO PEDIDO DE NOVA LIGAÇÃO -' + (data.novaLigacao || {}).estado + ' ');

    var corpo_ordem = logo_height + 14;
    /*
    var corpo_ordem = logo_height+14;
    
    if((data.novaLigacao || {} ).estado_flag == 'CANCELADO') {
      doc.setFontType('normal');
      doc.setFontSize(7);
      doc.text(posX + 110, corpo_ordem + 13, '' + ((data.estado_flag || {} ).data_fim_execucao == null ? '' : (data.estado_flag || {} ).data_fim_execucao));
      doc.text(posX+130,corpo_ordem+13,'' + ((data.estado_flag || {} ).hora_fim_execucao == null ? '' : (data.estado_flag || {} ).hora_fim_execucao));
      doc.text(posX+150,corpo_ordem+13,'' + ((data.estado_flag || {} ).dif_dias_definida == null ? '' : (data.estado_flag || {} ).dif_dias_definida));
      doc.text(posX+155,corpo_ordem+13,'' + ((data.estado_flag || {} ).dif_horas_definida == null ? '' : (data.estado_flag || {} ).dif_horas_definida));
      doc.text(posX+160,corpo_ordem+13,'' + ((data.ordemTrabalho || {} ).dif_minutos_definida == null ? '' : (data.ordemTrabalho || {} ).dif_minutos_definida));
      doc.setTextColor(0, 0, 255);
      doc.setFontSize(8);
      doc.text(posX+53,corpo_ordem-5.5,'EXECUÇÃO',null,'center');
      doc.setTextColor(0, 0, 0);
       doc.setFontSize(7);
      doc.text(posX+10,(corpo_ordem+13),'' + ((data.ordemTrabalho || {} ).data_inicio_previsao == null ? '' : (data.ordemTrabalho || {} ).data_inicio_previsao));
      doc.text(posX+25,(corpo_ordem+13),'' + ((data.ordemTrabalho || {} ).hora_inicio_previsao == null ? '' : (data.ordemTrabalho || {} ).hora_inicio_previsao));
      doc.text(posX+40,(corpo_ordem+13),'' + ((data.ordemTrabalho || {} ).dif_dias_prevista == null ? '' : (data.ordemTrabalho || {} ).dif_dias_prevista));
      doc.text(posX+45,(corpo_ordem+13),'' + ((data.ordemTrabalho || {} ).dif_horas_prevista == null ? '' : (data.ordemTrabalho || {} ).dif_horas_prevista));
      doc.text(posX+50,(corpo_ordem+13),'' + ((data.ordemTrabalho || {} ).dif_minutos_prevista == null ? '' : (data.ordemTrabalho || {} ).dif_minutos_prevista));
    } else {
    
    
      doc.setTextColor(45, 124, 35);
      doc.setFontSize(8);
      doc.text(posX+10,(corpo_ordem+13),'' + ((data.ordemTrabalho || {} ).data_inicio_previsao == null ? '' : (data.ordemTrabalho || {} ).data_inicio_previsao));
      doc.text(posX+25,(corpo_ordem+13),'' + ((data.ordemTrabalho || {} ).hora_inicio_previsao == null ? '' : (data.ordemTrabalho || {} ).hora_inicio_previsao));
      doc.text(posX+40,(corpo_ordem+13),'' + ((data.ordemTrabalho || {} ).dif_dias_prevista == null ? '' : (data.ordemTrabalho || {} ).dif_dias_prevista));
      doc.text(posX+45,(corpo_ordem+13),'' + ((data.ordemTrabalho || {} ).dif_horas_prevista == null ? '' : (data.ordemTrabalho || {} ).dif_horas_prevista));
      doc.text(posX+50,(corpo_ordem+13),'' + ((data.ordemTrabalho || {} ).dif_minutos_prevista == null ? '' : (data.ordemTrabalho || {} ).dif_minutos_prevista));
      doc.text(posX+62,corpo_ordem-5.5,'' + ((data.ordemTrabalho || {} ).agendamento_estado == null ? '' : (data.ordemTrabalho || {} ).agendamento_estado.toUpperCase()),null,'center');
      doc.setTextColor(0, 0, 0);
      doc.setFontSize(7);
    }*/


    /*Dados da OS.*/
    doc.setFontSize(8);
    doc.setFontType('bold');
    doc.text(posX + 10, corpo_ordem, 'TIPO');
    doc.text(posX + 150, corpo_ordem, 'DATA FECHO ');
    doc.setFontType('bold');
    doc.text(posX + 10, (corpo_ordem + 10), 'DESCRIÇÃO');
    doc.setFontType('normal');

    doc.setFontSize(8);
    doc.text(posX + 12, corpo_ordem + 3, data.novaLigacao.tipo_nova ? data.novaLigacao.tipo_nova : '');
    doc.text(posX + 152, corpo_ordem + 3, data.novaLigacao.data_fecho ? data.novaLigacao.data_fecho : '');
    doc.text(posX + 12, corpo_ordem + 13, data.novaLigacao.descricao_ligacao ? data.novaLigacao.descricao_ligacao : '');


    if (data.novaLigacao.cliente_id) {
      pos_cliente = corpo_ordem + 18;
      doc.setFontSize(8);
      doc.setFontType('bold');
      doc.setFillColor(208, 211, 212);
      doc.rect(10, pos_cliente, pageWidth - 20, 5);
      doc.text(posX + 10, pos_cliente + 3.5, 'DADOS DO CLIENTE');
      doc.setFontSize(8);
      doc.setFontType('bold');


      doc.text(posX + 10, pos_cliente + 13, 'Nº CLIENTE');
      doc.text(posX + 75, pos_cliente + 13, 'NOME ');
      doc.text(posX + 150, (pos_cliente + 13), 'Telefone');


      doc.text(posX + 10, pos_cliente + 26, 'MUNICIPIO');
      doc.text(posX + 75, pos_cliente + 26, 'DISTRITO');
      doc.text(posX + 150, (pos_cliente + 26), 'BAIRRO');


      doc.text(posX + 10, pos_cliente + 39, 'MORADA');

      doc.setFontSize(8);
      doc.setFontType('normal');
      doc.text(posX + 12, pos_cliente + 16, data.novaLigacao.cliente_id ? data.novaLigacao.cliente_id.toString() : '');
      doc.text(posX + 77, pos_cliente + 16, data.novaLigacao.cliente ? data.novaLigacao.cliente : '');
      doc.text(posX + 152, pos_cliente + 16, data.novaLigacao.cliente_telefone ? data.novaLigacao.cliente_telefone : '');


      doc.text(posX + 12, pos_cliente + 29, data.novaLigacao.municipio ? data.novaLigacao.municipio : '');
      doc.text(posX + 77, pos_cliente + 29, data.novaLigacao.distrito ? data.novaLigacao.distrito : '');
      doc.text(posX + 152, pos_cliente + 29, data.novaLigacao.bairro ? data.novaLigacao.bairro : '');

      //doc.text(posX+12,pos_cliente+42,data.novaLigacao.morada ? data.novaLigacao.morada :'' );
      doc.text(posX + 12, pos_cliente + 42, ((data.novaLigacao.is_predio ? 'Prédio ' + (data.novaLigacao.predio_nome || '') + ', ' + (data.novaLigacao.predio_andar || '') + 'º Andar - Porta ' : 'Residência ') + (data.novaLigacao.moradia_numero || '')));


      pos_cliente = pos_cliente + 42;

    }

    if (data.novaLigacao.contrato_id) {
      pos_contrato = pos_cliente + 18;
      doc.setFontSize(8);
      doc.setFontType('bold');
      doc.setFillColor(208, 211, 212);
      doc.rect(10, pos_contrato, pageWidth - 20, 5);
      doc.text(posX + 10, pos_contrato + 3.5, 'DADOS DO CONTRATO');
      doc.setFontSize(8);
      doc.setFontType('bold');


      doc.text(posX + 10, pos_contrato + 13, 'Nº CONTRATO');
      doc.text(posX + 50, pos_contrato + 13, 'CIL ');
      doc.text(posX + 110, (pos_contrato + 13), 'MORADA CIL');
      doc.text(posX + 160, (pos_contrato + 13), 'CONTADOR');

      doc.setFontSize(8);
      doc.setFontType('normal');
      doc.text(posX + 12, pos_contrato + 16, data.novaLigacao.contrato_id ? data.novaLigacao.contrato_id.toString() : '');
      doc.text(posX + 52, pos_contrato + 16, data.novaLigacao.cil ? data.novaLigacao.cil : '');
      doc.text(posX + 112, pos_contrato + 16, data.novaLigacao.predio_nome ? data.novaLigacao.predio_nome : ' ');
      doc.text(posX + 162, pos_contrato + 16, data.novaLigacao.numero_serie ? data.novaLigacao.numero_serie : '');

    }






    assinaturas(pageHeight - 25);



    function assinaturas(posy) {
      doc.setFontSize(9);
      doc.line(20, posy + 2, 80, posy + 2);
      doc.text('Entregou', posX + 45, posy + 5.5);

      doc.text('Executou', posX + 150, posy + 5.5);
      doc.line(130, posy + 2, 190, posy + 2);

    }
    //var pos_materiais = logo_height + 180;
    /*RODAPÉ*/
    doc.setFontType('normal');
    doc.setFontStyle('italic');
    doc.setFontSize(6);
    var pageCount = doc.internal.getNumberOfPages();
    for (var i = 0; i <= pageCount; i++) {
      doc.line(10, pageHeight - 10, pageWidth - 10, pageHeight - 10);
      doc.text('-Processado por programa validado 4/AGT119', 105, doc.internal.pageSize.height - 7, null, null, 'center');
      doc.setPage(i);
      doc.text(10, pageHeight - 15, 'Versão: ' + original);
      doc.text('Página: ' + doc.internal.getCurrentPageInfo().pageNumber + '/' + pageCount, pageWidth - 10, pageHeight - 15, null, null, 'right');
      doc.text(pageWidth - 10, pageHeight - 12, '', 'right');
      doc.text("NIF: " + (data.empresa.taxRegistrationNumber == null ? '' : data.empresa.taxRegistrationNumber) + " - " + (data.empresa.companyName == null ? '' : data.empresa.companyName) + " / " + (data.empresa.addressDetail == null ? '' : data.empresa.addressDetail) + " / " + (data.empresa.telefone == null ? '' : data.empresa.telefone) + " / " + (data.empresa.email == null ? '' : data.empresa.email), 105, doc.internal.pageSize.height - 11, null, null, 'center');
    }

    if (report == 'imprimir') {
      doc.autoPrint();
      window.open(doc.output("bloburl")); //opens the data uri in new window
    } else if (report == 'buffer') {
      return new Buffer.from(doc.output('arraybuffer'))
    } else {
      doc.save('nome.pdf'); /* download the file immediately on loading */

    }
  }
}
