import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxImageCompressService } from 'ngx-image-compress';
import { first } from 'rxjs/operators';
import { MouseEvent } from '@agm/core';
@Component({
  selector: 'app-viw-lista-trabalho',
  templateUrl: './viw-lista-trabalho.component.html',
  styleUrls: ['./viw-lista-trabalho.component.css']
})
export class ViwListaTrabalhoComponent implements OnInit {

  @Input() modal: string = "modalViewListaTrabalho";
  @Input() title: string = "Registar Equipamento";
  @Input() resultados: any;
  @Input() maiusculaTitle: any;
  @Input() responsavel: any;

  submitted = false;
  formErrors: any;
  private loading: boolean = false;
  private ver: boolean = true;

  private success_new: boolean = false;

  private items: any = [];

  public filters = {
    search: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    pagination: {
      perPage: 5,// Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima, 
      lastPage: null,
      total: null // defini o total de registo da BD
    },
    filter: null // subelemente do filter
  }

  private coordenada = {
    latitude: null,
    longitude: null,
    equipamentoDescricao: null
  }

  constructor(private http: HttpService, private configService: ConfigService, private formBuilder: FormBuilder) { 
    
  }

  ngOnInit() {
  }

  private getLatitudeLongitude(id) {

    this.coordenada.latitude = null;
    this.coordenada.longitude = null;

   
      this.configService.loaddinStarter('start');
      this.http.call_get('ordemservico/equipamento/getCoordenadas/' + id, null).subscribe(
        response => {
          this.coordenada.latitude = Object(response).data.latitude;
          this.coordenada.longitude = Object(response).data.longitude;
          this.coordenada.equipamentoDescricao = Object(response).data.descricao;
          this.configService.loaddinStarter('stop');
        }
      );
    

  }

  private imprimirAgendamento(id){
    this.configService.imprimirAgendamento(id, "2ª Via", "imprimir")
  }


}
