import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

@Component({​
    selector: 'app-conf-forma-pagamento',
    templateUrl: './conf-forma-pagamento.component.html',
    styleUrls: ['./conf-forma-pagamento.component.css']
  }​)
  export class ConfFormaPagamentoComponent implements OnInit, OnDestroy {​

  private formapagamento = {
    id: null,
    designacao: null,
    descricao: null,
    flag:null,
  }

  private items: any = [];

  constructor(private http: HttpService, private configService: ConfigService) { }

  ngOnInit() {
    this.http.filters.search = null
    this.getPageFilterData(1);
  }
  ngOnDestroy(){
    this.http.filters.orderBy = null
  }


  private listaformapagamento() {

    this.configService.loaddinStarter('start');

    this.http.__call('formaPagamento/listar', this.http.filters).subscribe(

      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.configService.loaddinStarter('stop');

      }
    );
  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page =Number.isInteger(page) ? page : 1 ;
    this.listaformapagamento();
  }


  private register(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (this.formapagamento.designacao == null || this.formapagamento.descricao == null) {
      this.configService.showAlert("O campo Designação e Descrição são obrigatórios", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    } else if (this.formapagamento.designacao == null && this.formapagamento.descricao == null) {
      this.configService.showAlert("O campo Designação e Descrição são obrigatórios", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    }
    else {
    this.http.__call('formaPagamento/register', this.formapagamento).subscribe(
      res => {
        if (Object(res).code == 201) {
          this.configService.showAlert(Object(res).message, 'alert-danger', true);
          this.configService.loaddinStarter('stop');
        } else {
          this.configService.showAlert(Object(res).message, 'alert-success', true);
          this.clearFormInputs(e);
          this.listaformapagamento()
          this.configService.loaddinStarter('stop');
        }
      }
    )
  };
}


  private clearFormInputs(e) {
    e.target.elements[0].value = null;
    e.target.elements[1].value = null;
    e.target.elements[2].value = null;
    e.target.elements[3].value = null;
  }

  private refresh(item) {
    this.formapagamento.id = item.id;
    this.formapagamento.designacao = item.designacao;
    this.formapagamento.descricao = item.descricao;
    this.formapagamento.flag = item.slug;
    console.log(item)
  }

  private editar(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (this.formapagamento.designacao == null || this.formapagamento.descricao == null) {
      this.configService.showAlert("O campo Designação e Descrição são obrigatórios", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    } else if (this.formapagamento.designacao == null && this.formapagamento.descricao == null) {
      this.configService.showAlert("O campo Designação e Descrição são obrigatórios", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    }
    else {
      this.http.__call('formaPagamento/update/' + this.formapagamento.id, this.formapagamento).subscribe(
        res => {
          if (Object(res).code == 201) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.listaformapagamento();

          }
        }
      );
    }
    this.configService.loaddinStarter('stop');
  }


  private ini() {
    this.formapagamento = {
      id: null,
      designacao: null,
      descricao: null,
      flag:null
    }
  }





}
