import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { ExcelService } from 'src/app/services/excel.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-plano-manutencao',
  templateUrl: './plano-manutencao.component.html',
  styleUrls: ['./plano-manutencao.component.css']
})
export class PlanoManutencaoComponent implements OnInit {




  public planos_manutencao: any;
  public codigo_equipamento: any;
  public equipamento_id: any;
  private loading: boolean = false;
  public filters = {
    search: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    pagination: {
      perPage: 5,// Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima, 
      lastPage: null,
      total: null // defini o total de registo da BD
    },
    filter: null // subelemente do filter
  }

  private plano = {
    id: null,
    descricao: null,

  }


  constructor(private auth: AuthService, private http: HttpService, private configService: ConfigService,private excelService: ExcelService) { }
 
  private items: any = [];
  private itens_planos: any = [];
  private tarefas_planos: any = [];
  private contadores_equipamento: any = [];

  ngOnInit() {
    this.getPageFilterData(1);
  }


  private listar() {

    //this.configService.loaddinStarter('start');

    this.loading = true
    this.http.__call('manutencao-preventiva/plano-manutencao/listagem', this.filters).subscribe(
      response => {
        this.filters.pagination.lastPage = Object(response).data.lastPage;
        this.filters.pagination.page = Object(response).data.page;
        this.filters.pagination.total = Object(response).data.total;
        this.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.loading = false
      }
    );
  }

  
  private getItensByPlano(item: any) {

    this.plano.id = item.id
    this.codigo_equipamento = item.equipamentoCodigoRegisto;

    this.configService.loaddinStarter('start');
    this.http.call_get('manutencao-preventiva/item-plano/itemByPlanoManutencao/' + this.plano.id, null).subscribe(
      response => {
        this.itens_planos = Object(response).data;
        this.configService.loaddinStarter('stop');
      }
    );
  }
  
  private getTarefasByPlano(item: any) {

    this.plano.id = item.id
    this.codigo_equipamento = item.equipamentoCodigoRegisto;

    this.configService.loaddinStarter('start');
    this.http.call_get('manutencao-preventiva/tipo-tarefas/tarefaByPlanoManutencao/' + this.plano.id, null).subscribe(
      response => {
        this.tarefas_planos = Object(response).data;
        this.configService.loaddinStarter('stop');
      }
    );
  }

  private getContadorByPlano(item: any) {

    this.plano.id = item.id
    this.codigo_equipamento = item.equipamentoCodigoRegisto;
    this.equipamento_id = item.equipamentoID;

    this.configService.loaddinStarter('start');
    this.http.call_get('manutencao-preventiva/contador-equipamento/contadorByEquipamento/' + item.id, null).subscribe(
      response => {
        this.contadores_equipamento = Object(response).data;
        this.configService.loaddinStarter('stop');
      }
    );
  }

  private confirmar(item) {

    Swal.fire({
      html: `
      <h3><b>Pretende eliminar este item?</b></h3>
      CÓDIGO EQUIPAMENTO: `+item.equipamentoCodigoRegisto+`
   `,
      //title: '<strong></strong>',
      text: "Você não poderá reverter isso!",
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      confirmButtonColor: '#d33'

    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.value) {
        this.cancelarPlano(item.id)
        
      } else if (!result.value) {
        Swal.fire('', '', 'info')
      }
    })

  }

  private cancelarPlano(id) {

    this.http.__call('manutencao-preventiva/plano-manutencao/cancelar/'+id,null).subscribe(
      response => {
        if (Object(response).code == 200) {
          Swal.fire('Sucesso!', '', 'success')
          this.listar();
        } else {
         
          Swal.fire(Object(response).message, '', 'info')
          this.listar();
        }
      }
    );
  }
 
  getPageFilterData(page: number) {
    if (this.filters.pagination.perPage == null) {
      return;
    }
    this.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
    this.listar();
  }

  
  private setData(item) {
    this.planos_manutencao = item
  }
 
}
