
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ClassificacaoService } from '../../config.components/classificacao-produto/classificacao.service';
import { ListarProdutosComponent } from '../listar-produtos.component';
import { ProdutoService } from '../produto.service';

@Component({
  selector: 'createOrEditproduto',
  templateUrl: './create-or-edit-produto.component.html',
  styleUrls: ['./create-or-edit-produto.component.css']
})
export class CreateOrEditProdutoComponent implements OnInit {

  @Input() modal: string = "modalprodutoCreateOrEdit";
  @Input() title: string = "Registar produto";
  @Input() produto: any;
  @Input() produtos: {};
  @Input() tipoProduto: any = [];
  

  imponstos = [
    { id: 0, descricao: null }
  ]
  private centro_custo=[]
  private incidencia=[]

  submitted = false;
  private loading: boolean = false;
  @Input() simpleFormproduto: FormGroup;
  @Output() private loadListproduto = new EventEmitter<any>();
  classificacoes: any = []
  consumos: any = []

  constructor(
      private http: HttpService, 
      private configService: ConfigService, 
      private formBuilder: FormBuilder,
      private _classificacaoService: ClassificacaoService,
      private produtoService:ProdutoService
    ) {
      this.createForm();
    }

  ngOnInit() {
    console.log("eitar now1",this.produtoService.produtos)
    this.getCentroCusto()
    this.getIncidencia()   
    this.getImposto();
    this.configService.listarMoedas();
    this.getConsumo()
    this._classificacaoService.getClassificacoes()
    .subscribe((response) => {
        console.log( response.data )
        this.classificacoes = response.data.data
    })
  }

  createForm() {
    this.simpleFormproduto = this.formBuilder.group({
      index: [{ value: null, disabled: true }],
      nome: [null, Validators.required],
      barcode: [null],
      quantidade: [null],
      tipo_produto_id: [null, Validators.required],
      valor: [null, Validators.required],
      imposto_id: [null, Validators.required],
      classificacao_produto_id: [null, Validators.required],
      moeda_id: [null, Validators.required],
      observacao: [null],
      incidencia_id:null,
      custo_id:null,
      centro_custo_id:null
    });
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.simpleFormproduto.controls;
  }

  onReset() {
    this.submitted = false;
    this.simpleFormproduto.reset();
  }

  getConsumo(){
      this.http.__call("list_all_consumo", null).subscribe(
          response=>{
            this.consumos=Object(response).data 
          }
      )
  }

  onSubmit() {

    this.submitted = true;

    // parar aquei se o formulário for inválido
    console.log(this.simpleFormproduto.invalid)
    if (this.simpleFormproduto.invalid) {
      return;
    }
    this.loading = true;
    const index = this.simpleFormproduto.getRawValue().index;
    // TODO: usado para fazer a requisição com a api de criação de objsct or update
    const uri = (index === null ? 'artigo/create' : 'artigo/update/' + index);
    this.createOrEdit(uri, this.simpleFormproduto, (index === null ? true : false));

  }

  createOrEdit(uri: any, formulario: FormGroup, isCreate: boolean) {

    // TODO: usado para fazer a requisição com a api de criação de object
    this.http.__call(uri, formulario.value).pipe(first()).subscribe(
      response => {
        this.submitted = false;
        this.loading = false;
        if (isCreate) {
          formulario.reset();
          this.loadListprodutos(Object(response).data);
        }

        if (Object(response).code == 200) {
          this.loadListprodutos(Object(response).data);
        }
      },
      error => {
        this.submitted = false;
        this.loading = false;
      });
  }




  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
   
    if (this.produto !== undefined) {
      this.title = "Editar produto";
      this.simpleFormproduto.patchValue({
        index: this.produtoService.produtos.id ,
        nome: this.produtoService.produtos.nome,
        tipo_produto_id: this.produtoService.produtos.tipo_produto_id,
        observacao: this.produtoService.produtos.observacao,
        valor: this.produtoService.produtos.valor,
        moeda_id: this.produtoService.produtos.moeda_id,
        incidencia_id:(this.produtoService.produtos.incidencia_id || null),
        centro_custo_id:this.produtoService.produtos.centro_custo_id,
        classificacao_produto_id:(this.produtoService.produtos.classificacao_produto_id || null),
/*         is_trigger: this.produto.is_trigger, */
        imposto_id: this.produtoService.produtos.imposto_id,
/*         is_active: this.produto.is_active */
      });
    } else {
      this.title = "Registar produto";
    }
  }

  private getImposto() {

    this.http.__call('imposto/getall', null).subscribe(
      data => {
        var count = Object.keys(data).length;
        for (var i = 0; i < count; i++) {
          this.imponstos[i] = data[i];
        }
      }
    );
  }
  
  private getCentroCusto(){
    this.http.__call('artigo/Centro_custo', null).subscribe(
      data => {
        this.centro_custo = Object(data)
      }
    );
  }

  private getIncidencia(){
    this.http.__call('artigo/incidencia', null).subscribe(
      data => {
          this.incidencia = Object(data)
        }
    );
  }

  public loadListprodutos(produto) {
    this.loadListproduto.emit(produto);
  }


}

