import { Component, Input, OnInit } from '@angular/core';
import 'ol/ol.css';
import GeoJSON from 'ol/format/GeoJSON';
import Map from 'ol/Map';
import VectorSource from 'ol/source/Vector';
import View from 'ol/View';
import XYZ from 'ol/source/XYZ';
import {Stroke, Style} from 'ol/style';
import { transform } from 'ol/proj';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import {bbox as bboxStrategy} from 'ol/loadingstrategy';

import Overlay from 'ol/Overlay';
import { toStringHDMS } from 'ol/coordinate.js';
import { toLonLat } from 'ol/proj.js';
import OSM from 'ol/source/OSM';


import TileWMS from 'ol/source/TileWMS';

@Component({
  selector: 'app-openlayers',
  templateUrl: './openlayers.component.html',
  styleUrls: ['./openlayers.component.css']
})
export class OpenlayersComponent implements OnInit {

  @Input() modal: string = "modalMapaEquipamentoLocation";

  constructor() { }


  ngOnInit(){

    this.initilizeMap()

const wmsSource = new TileWMS({
  url: 'https://ahocevar.com/geoserver/wms',
  params: {'LAYERS': 'ne:ne', 'TILED': true},
  serverType: 'geoserver',
  crossOrigin: 'anonymous',
});

const wmsLayer:any = new TileLayer({
  source: wmsSource,
});

const view = new View({
  center: [0, 0],
  zoom: 1,
});

const map = new Map({
  layers: [wmsLayer],
  target: 'map',
  view: view,
});


map.on('singleclick', function (evt) {
   //document.getElementById('info').innerHTML = '';
  ($("#info") as any).innerHTML = ''

  const viewResolution = /** @type {number} */ (view.getResolution());
  const url = wmsSource.getFeatureInfoUrl(
    evt.coordinate,
    viewResolution,
    'EPSG:3857',
    {'INFO_FORMAT': 'text/html'}
  );
  if (url) {
    fetch(url)
      .then((response) => response.text())
      .then((html) => {
       /// document.getElementById('info').innerHTML = html;
         console.log('info=>',html);
        ($("#info") as any).innerHTML = html
      });
  }
});

map.on('pointermove', function (evt) {
  if (evt.dragging) {
    return;
  }
  const data = wmsLayer.getData(evt.pixel);
  const hit = data && data[3] > 0; // transparent pixels have zero for data[3]
  map.getTargetElement().style.cursor = hit ? 'pointer' : '';
});

console.log('info',map)
  }



    initilizeMap(){

      const container = document.getElementById('popup');
      const content = document.getElementById('popup-content');
      const closer = document.getElementById('popup-closer');

      const overlay = new Overlay({
        element: container,
        autoPan: true,
        autoPanAnimation: {
          duration: 250
        }
      });

      var vectorSource = new VectorSource({
        format: new GeoJSON(),
        url: function (extent) {
          return (
            'https://ahocevar.com/geoserver/wfs?service=WFS&' +
            'version=1.1.0&request=GetFeature&typename=osm:water_areas&' +
            'outputFormat=application/json&srsname=EPSG:3857&' +
            'bbox=' +
            extent.join(',') +
            ',EPSG:3857'
          );
        },
        strategy: bboxStrategy,
      });

      var vector = new VectorLayer({
        source: vectorSource,
        style: new Style({
          stroke: new Stroke({
            color: 'rgba(0, 0, 255, 1.0)',
            width: 2,
          }),
        }),
      });

      var key = 'eKMYeVfLU2zK6ZjTsRFl';
      var attributions =
        '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> ' +
        '<a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>';

      var raster = new TileLayer({
        source: new XYZ({
          attributions: attributions,
          //url: 'http://83.240.217.33/WFSRegadio/service.svc/get?request=GetCapabilities&service=WFS',
          url: 'https://api.maptiler.com/tiles/satellite/{z}/{x}/{y}.jpg?key=' + key,
          maxZoom: 20,
        }),
      });

      var map = new Map({
        layers: [raster, vector],
        target: 'ol-map',
        overlays: [overlay], //
        view: new View({
          center: [-8908887.277395891, 5381918.072437216],
          maxZoom: 19,
          zoom: 12,
        }),
      });

      map.on('singleclick', function (evt) {

        const coordinate = evt.coordinate;
      const hdms = toStringHDMS(toLonLat(coordinate));

      content.innerHTML = '<p>Coordenadas actuais :</p><code>' + hdms +
        '</code>';
      overlay.setPosition(coordinate);

        console.log(evt.coordinate);

        // convert coordinate to EPSG-4326
        console.log(transform(evt.coordinate, 'EPSG:3857', 'EPSG:4326'));
    });

    closer.onclick = function () {
      overlay.setPosition(undefined);
      closer.blur();
      return false;
    };

    }
  openModal(modal=''){
      this.initilizeMap();
      ($("#modalMapaEquipamentoLocation") as any).modal("show")
  }
  closeModal(modal=''){
   ($("#modalMapaEquipamentoLocation") as any).modal("hide")
  }
}
