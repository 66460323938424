import { User } from './../../../models/user';
import { Idle } from '@ng-idle/core';
import { ReportFacturacaoGestorComponent } from './../../relatorios/financeiro/report-facturacao-gestor/report-facturacao-gestor.component';
import { DOCUMENT, Time } from '@angular/common';
import { AfterContentChecked, Component, ElementRef, Inject, OnChanges, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { ConfigService } from 'src/app/providers/config/config.service';
import { HttpService } from 'src/app/providers/http/http.service';
import { ExcelService } from 'src/app/services/excel.service';
import { ExcelAutoService } from 'src/app/services/excel/excel-auto.service';
import { toDate, format } from 'date-fns-tz'
import Swal from 'sweetalert2';
import { forkJoin, Observable } from 'rxjs';
import { ImpressaoPdfService } from './service/impressao-pdf.service';
import { saveAs } from 'file-saver';
import { HttpEvent, HttpEventType } from '@angular/common/http';
const axios = require('axios')
import { environment } from 'src/environments/environment';
import { finalize } from 'rxjs/operators';
import { ApiService } from 'src/app/providers/http/api.service';
import { promise } from 'protractor';
//import 'sweetalert2/src/sweetalert2.scss';
interface Ifile {
  ext: string,
  nome: any,
  url: string,
  id: number,
  flag: string
}
@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.css']
})


export class RegistroComponent implements OnInit, OnChanges {

  @ViewChild('closebutton') closebutton;

  loading: boolean = false;
  disableButton: boolean = true;
  private isDisabled: boolean = false;
  checkedAll: boolean = false;
  filenout
  motivos:any=[]
  fileList = []
  title = 'templent';
  formDate: FormData;
  orderForm
  submitted = false;
  TipoArray:any=[]
  dropdownSettingsTipo:Object={}
  public filter = {
    municipio_id: null,
    bairro_id:null,
    distrito_id:null,
    quarteirao_id:null
  }
  os_tipo_id:any=null
  public filters = {
    search: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    pagination: {
      perPage: 5,// Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima,
      lastPage: null,
      total: null // defini o total de registo da BD
    },
    filter: null // subelemente do filter
    , is_allexel: false
  }
  public filtersComunca = {
    search: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    pagination: {
      perPage: 5,// Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima,
      lastPage: null,
      total: null // defini o total de registo da BD
    },
    filter: null // subelemente do filter
    , is_allexel: false
  }


  private cliente = {
    nome: null,
  }
  public isOrdemServicoWithRegistro:boolean=false
  private causasArray: any = [];
  private sintomasArray: any = [];

  private localUrl: any;
  private largura: any;
  private altura: any;
  items: any = [];
  _items: any = [];
  items1: any = [];
  reclamacoes: any = []
  estado: any = [];
  download: any = [];
  nivels: any = [];
  tipos: any = [];
  encamiamento: any = [];
  origems: any = [];
  categorias: any = [];
  clientes: any = [];
  contas: any = [];
  contactos:any=[]
  ot_tipos: any = [];
  anexos: any = [];
  distritos:any=[]
  bairros:any=[]
  quarteiraos:any=[]
  state:any=null
  _state:any=null
  private cliente_contrato_id = null;
  dropdownSettingsDepartamentos = {};
  private departamentoArray: any = [];
  private dropdownDepartamentos: any = [];

  private local_consumo: any = [];

  private is_sintoma: boolean = false;
  private is_causa: boolean = false;
  private is_titulo: boolean = false;
  private editable: boolean = false;
  contratos: any = [];
  prioridades: any = [];
  classificacoes: any = [];
  empresa: any = [];
  municipios: any = [];
  nivel_ids: any = [];
  simpleFormCliente: FormGroup
  createOrEditContacto:FormGroup
  contacto_id:any=null
  simpleFormCreateOrEdit: FormGroup
  formHistorico: FormGroup
  validateState: any = []
  historico: any = []
  CurrentDate = new Date();
  view_client = true;
  stateValidate: boolean
  valiateOsFild: Boolean
  timeOutput: any
  now: string
  today: string
  flag: number
  private is_contrato_id: boolean = false;
  datatime: string
  times: string = moment(this.CurrentDate).format('H') + ":" + moment(this.CurrentDate).format('m')


  constructor(private http: HttpService,
    private configService: ConfigService,
    private excelService: ExcelService,
    private relatorioRegisto: ImpressaoPdfService,
    private excelsService: ExcelAutoService,
    private _formBuilder: FormBuilder,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private api: ApiService
  ) {
    this.createForm()
    this.createFormContacto()
    this.createFormOT()
    this.createHistory()
  }
  progress: any = 0;
  progress_1: string = null;
  userName: Object = JSON.parse(localStorage.getItem('_token')) ?
    JSON.parse(localStorage.getItem('_token')).user ?
      JSON.parse(localStorage.getItem('_token')).user.nome : null : null
  loadings = { municipios: false }
  ngOnInit() {

    this.getEstadoFechado()
    this.getMotivo()
    this.getRegisto()
    this.progress
    this.progress_1
    this.filenout = document.getElementById('file-input')
    this.empresaUser()
    this.getcategoria()
    this.getPageFilterDataPage(1);
    this.getDropDownDepartamentos();
    this.getEstado(null)
    this.changeValue(true)
    this.DateTimeZone()
    /*this.getHistoricoId(1) */
    // this.getCliente()
    const param = this.route.queryParams.subscribe(params => {
      const id = params['c'] || 0;
      const name = params['n'] || 0;
      if (id > 0 && name) {
        this.setCliente({ id: id, nome: name });
        this.simpleFormCliente.get('cliente').disable();
      }
    });
    /*  this.getNivel() */
    this.getMunicipios()
  }

  ngOnChanges() {
    // ...
  }

  private getMunicipios() {
    this.loadings.municipios = true;
    this.api.get('municipio/selectBox')
      .pipe(finalize(() => { this.loadings.municipios = false; }))
      .subscribe(response => { this.municipios = Object(response).data; }, error => { this.loadings.municipios = false; });
  }
  private getDistritos() {
    this.bairros = [];
    this.filter.distrito_id = null;
    this.filter.bairro_id = null;
    this.filter.quarteirao_id = null;
    this.api.get(`distrito/selectBoxDistritosByMunicipio/${this.filter.municipio_id}`)
    .subscribe(response => {
         this.distritos = Object(response).data;
        }
    );
  }

  private getBairrosByDistritos() {
    this.filter.quarteirao_id = null;
    if(this.filter.distrito_id==""){this.filter.distrito_id = null};
    this.api.get(`bairro/getBairrosByDistrito/${this.filter.distrito_id}`)
            .subscribe(response => {
                this.bairros = Object(response).data;
            }
       );
  }

  private selectBoxQuarteiraosByBairro() {

       this.http.call_get('quarteirao/getQuarteiraosByBairro/' + this.filter.bairro_id, null).subscribe(
            response => {
                this.quarteiraos = Object(response).data;
            }
        );

   }

  DateTimeZone() {
    const event = new Date();
    const parisDate = toDate(event.toISOString())
    const pattern = "yyyy-MM-dd'T'HH:mm"
    const timeOutput = format(parisDate, pattern, { timeZone: 'UTC/GMT+1' })
    return this.datatime = timeOutput.slice(0, 16);
  }

  _DateTimeZone(date) {
    if(!date) return null;
    const parisDate = toDate(date);
    const pattern = "MM/dd/yyyy"
    const timeOutput = format(parisDate, pattern, { timeZone: 'UTC/GMT+1' })
    return timeOutput
  }

  getTimeZone(date) {
    const parisDate = toDate(date)
    const pattern = 'HH:mm'
    this.timeOutput = format(parisDate, pattern, { timeZone: 'UTC/GMT+1' })
    return this.timeOutput
  }

  private getDropDownDepartamentos() {
    this.http.call_get('departamento/selectBox', null).subscribe(
      response => {
        this.dropdownDepartamentos = Object(response).data;

        this.dropdownSettingsDepartamentos = {
          singleSelection: false,
          idField: 'id',
          textField: 'descricao',
          selectAllText: 'Seleccione Todos',
          unSelectAllText: 'DeSeleccionar Todos',
          itemsShowLimit: 10,
          allowSearchFilter: true
        };

      }
    );
  }

  onItemSelectDepartamento(item: any) {
    this.departamentoArray.push(item);
  }
  onSelectAllDepartamentos(items: any) {
    this.departamentoArray = items;
  }

  onItemSelectTipo(item: any) {
    this.TipoArray.push(item);
  }
  onSelectAllTipos(items: any) {
    this.TipoArray = items;
  }


  private validateOs(value) {
    this.valiateOsFild = value
  }
  createHistory() {
    this.formHistorico = this._formBuilder.group({
      sub_descricao: ['', [Validators.required]],
      title: [null, [Validators.required]],
      sub_estado_id: null,
      hostory_id: [null],
      data: [this.DateTimeZone(), [Validators.required]],
      // user: this.userName.user.nome
    })
  }


  private getContratoID() {


    if (this.simpleFormCliente.value.contrato_id != null) {
      this.is_contrato_id = true;

      this.getContas(this.simpleFormCliente.value.cliente_id);

      this.simpleFormCreateOrEdit.value.conta_id = this.simpleFormCliente.value.conta;

      this.getContratosByConta();

      this.cliente.nome = this.simpleFormCliente.value.cliente;
      this.editable = true;

      this.simpleFormCreateOrEdit.patchValue({
        cliente_nome: this.simpleFormCliente.value.cliente,
        conta_id: this.simpleFormCliente.value.conta,
        cliente_contrato_id: this.simpleFormCliente.value.contrato_id,
        origem: "COMERCIAL"
      });

    } else {
      this.is_contrato_id = false;

    }

  }

  private setClienteData(item) {


    this.getContas(item.cliente_id);

    this.getContratosByConta();

    this.cliente.nome = item.cliente;
    this.editable = true;

    this.simpleFormCreateOrEdit.patchValue({
      cliente_nome: item.cliente,
      conta_id: item.conta_id,
      cliente_contrato_id: item.contrato_id,
      origem: "COMERCIAL",
      reclamacao_id: item.id
    });

  }


  onResetSome() {
    this.simpleFormCreateOrEdit.patchValue({
      titulo: null,
      sla: null,
      data_prevista: null,
      prioridade_id: null,
      cliente_id: null,
      classificacao_id: null,
      email: null,
      descricao: null,
      categoria_descricao: null,
      tipo_id: null,
      reclamacao_id: null

    });

    this.isDisabled = false;
  }
     /*  this.getContas(item.cliente_id);

      this.getContratosByConta();

      this.cliente.nome = item.cliente;
      this.editable = true;

      this.simpleFormCreateOrEdit.patchValue({
        cliente_nome: item.cliente,
        cliente_id: item.cliente_id,
        conta_id: item.conta_id,
        cliente_contrato_id: item.contrato_id,
        origem: "COMERCIAL"
      }); */

  createFormContacto(){
    this.createOrEditContacto = this.formBuilder.group({
      descricao:[null, [Validators.required]],
      estado_id:[null],
      via_contacto:[null, [Validators.required]],
      registro_id:[null]
    })
  }
  createForm() {

    this.simpleFormCliente = this._formBuilder.group({
      id: null,
      historico_id: null,
      cliente: [null, [Validators.required]],
      cliente_id: [null],
      tipo_id: [null],
      data_prevista1:[null, [Validators.required]],
      motivo_id:[null, [Validators.required]],
      data_prevista2:[null, this.matchValidator.bind(this)],
      data_afectiva1:[null],
      data_afectiva2:[null,this._matchValidator.bind(this)],
      descricao: ['',
        [Validators.required]
      ],
      checked:false,
      estado_id: null,
      origem: [null],
      categoria: [null, [Validators.required]],
      encaminhamento: [null],
      title: [null, [Validators.required]],
      data: [this.DateTimeZone(), [Validators.required]],
      time: this.DateTimeZone(),
      nivel_1: [null,],
      os_id:null,
      // nivel_1: [null, [Validators.required]],
      nivel_2: [null],
      conta: [null],
      contrato_id: [null],
      hostory_id: null,
      // user: this.userName.user.nome,
      file: [null]
    })
  }

  matchValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const fromValue = control.value;
    if (fromValue) {

      // console.log(this.simpleForm.controls)
      const startDate = (<FormGroup>this.simpleFormCliente.get('data_prevista1')).value;
      const endDate = (<FormGroup>this.simpleFormCliente.get('data_prevista2')).value;
      if (startDate <= endDate) {
        //console.log('Control: ', control);
        return null;
      }
      //console.log('Control: ', control);
      return { 'invalidDate': true };
    }

  }

  _matchValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const fromValue = control.value;
    if (fromValue) {

      // console.log(this.simpleForm.controls)
      const startDate = (<FormGroup>this.simpleFormCliente.get('data_afectiva1')).value;
      const endDate = (<FormGroup>this.simpleFormCliente.get('data_afectiva2')).value;
      if(!startDate || !endDate)return null
      if (startDate <= endDate) {
        //console.log('Control: ', control);
        return null;
      }
      //console.log('Control: ', control);
      return { 'invalidDate': true };
    }

  }

  createFormOT() {
    this.simpleFormCreateOrEdit = this.formBuilder.group({
      index: [{ value: null, disabled: true }],
      cliente_nome: [null],
      cliente_id: [null],
      titulo: [null],
      data_prevista: [null, Validators.required],
      prioridade_id: [null, Validators.required],
      classificacao_id: [null, Validators.required],
      tipo_id: [null, Validators.required],
      conta_id: [null],
      cliente_contrato_id: [null],
      descricao: [null, Validators.required],
      categoria_descricao: [null],
      predio_nome: [null],
      numero_serie: [null],
      reclamacao_id: [null],
      cil: [null],
      origem: [null],
      email: [null],
      nivel_3: [null],
      nivel_2: [null],
      nivel_1: [null],
      causa_id: [null],
      sintoma_id: [null],
      sla: [null]
    })
  }
  // convenience getter for easy access to form fields
  get g() {
    return this.simpleFormCreateOrEdit.controls;
  }

  get validateContrato(){
    return this.createOrEditContacto.controls;
  }

  exportAsPDF(): void {
    var file = document.getElementsByClassName("exportAsXLSXCliente")[0]
    this.relatorioRegisto.relatorioRegisto('save', file, this.localUrl);

  }

  imprimirPDF(): void {
    var file = document.getElementsByClassName("exportAsXLSXCliente")[0];
    this.relatorioRegisto.relatorioRegisto('print', file, this.localUrl);
  }

  imprimir(data): void {


    this.getOrdens(data.id)

    this.http.call_get('cliente/getClienteById/' + data.cliente_id, null).subscribe(

      response => {
        let clientes = []
        let datas = []
        clientes.push(Object(response));
        datas.push({ ...data, reclamacoes: this.reclamacoes, cliente: Object(response), empresa: this.empresa, user: this.userName })
        this.relatorioRegisto.relatorioRegistoPdf('imprimir', datas, this.localUrl);
      })


  }
  private setCliente(client: any) {
    /*  console.log('client=',client) */
    this.simpleFormCliente.patchValue({
      cliente_id: client.id,
      cliente: client.nome,
    });
    this.getConta(client)
    this.getCliente()
    this.view_client = false;
    this.clientes = []
  }
  private setHistory(history: any) {
    this.formHistorico.patchValue({
      hostory_id: history.id,
      sub_descricao: history.descricao,
      title: history.title,
      sub_estado_id: history.estado_id,
      data: this.DateTimeZone(),
      time: this.DateTimeZone(),
      user: this.userName,
    });
    this.simpleFormCliente.patchValue(
      {
        estado_id: history.id_estado,
        data: this.DateTimeZone(),
        time: this.DateTimeZone(),
        user: this.userName,
      });
    //this.iniCliente(history)
    //this.getTipo()
    this.view_client = false;
  }
  private resetHistory() {
    this.formHistorico.reset();
  }

  private empresaUser() {
    this.configService.loaddinStarter('start');

    this.http.call_get('empresa/empresa-user', null).subscribe(
      response => {
        this.empresa = Object(response).data
        this.localUrl = Object(response).data.logotipo
        this.altura = Object(response).data.width
        this.largura = Object(response).data.height
        this.configService.loaddinStarter('stop');
      }
    );
  }

  private validateEstado(estado_id) {
    const res = this.estado.map(res => {
      if (res.id == estado_id) {
        return res.descricao
      }

      this.validateState.push(res.descricao)
      return res.descricao
    })
  }

  private getAssociacao() {

    this.simpleFormCreateOrEdit.patchValue({
      titulo: this.simpleFormCreateOrEdit.value.titulo
    });

    this.is_titulo = false;

    this.simpleFormCreateOrEdit.patchValue({
      categoria_descricao: null
    });

    if (this.simpleFormCreateOrEdit.value.tipo_id != null) {
      this.configService.loaddinStarter('start');
      this.http.call_get('ordemservico/ostipo/associacao/' + this.simpleFormCreateOrEdit.value.tipo_id, null).subscribe(
        response => {

          this.simpleFormCreateOrEdit.patchValue({
            categoria_descricao: Object(response).data.categoria_descricao,
            sla: Object(response).data.sla
          });

          this.is_sintoma = Object(response).data.sintoma;
          this.is_causa = Object(response).data.causa;
          this.is_titulo = Object(response).data.is_titulo;

          if (this.is_titulo) {

            this.simpleFormCreateOrEdit.patchValue({
              titulo: Object(response).data.descricao
            });


          }
          this.configService.loaddinStarter('stop');
        }

      );
    }

    this.is_titulo = false

  }

  private carregando = {
    tipo: 'Seleccione o tipo'
  }

  private getTipoByClassificacao() {

    const uri ='ordemservico/ostipo-trigger-by-flag/selectBox/' + this.simpleFormCreateOrEdit.value.classificacao_id
    // const uri = (this.is_alterar_titularidade === true ? 'ordemservico/ostipo-alteracao-titularidade/selectBox/' + this.ordemServico.classificacao_id : 'ordemservico/ostipo-remocao-contador/selectBox/' + this.ordemServico.classificacao_id);

    this.carregando.tipo = 'Carregando...';
    this.http.__call(uri, {flag:"REGISTRO_COMUNICACAO"}).subscribe(
      response => {

        this.ot_tipos = Object(response).data;
        this.getSelectedTrigger(this.os_tipo_id,this.ot_tipos)
        this.carregando.tipo = 'Seleccione o tipo';
        this.dropdownSettingsTipo = {
          singleSelection: false,
          idField: 'id',
          textField: 'descricao',
          selectAllText: 'Seleccione Todos',
          unSelectAllText: 'DeSeleccionar Todos',
          itemsShowLimit: 10,
          allowSearchFilter: true
        };

      }
      );

  }


  private getCliente() {
    this.contas = []
    this.contratos = []
    this.view_client = true;
    this.http.__call('cliente/search-cliente', { start: 1, end: 10, search: this.simpleFormCliente.getRawValue().cliente }).subscribe(
      response => {
        this.clientes = Object(response).data.data;
      })
  }
  private getConta(cliente) {

    this.http.call_get('cliente/conta/selectBox/' + cliente.id, null).subscribe(
      response => {
        this.contas = Object(response).data
      })
  }
  private getAnexo(item) {
    this.anexos = item.comunicacaoAnexo
  }
  /* private downloadAnexo(item) {
    this.http._getFileFromServer(item).subscribe(
      response => {
        this.download = Object(response);

        console.log(this.download)
        this.configService.loaddinStarter('stop');
      }
    );

  } */

  public getContratosByConta() {
    this.configService.loaddinStarter('start');
    this.contratos = []
    this.is_contrato_id = null;
    let conta_id = this.simpleFormCliente.get('conta').value

    if (conta_id) {
      this.http.__call('conta/contratoByConta/' + conta_id, null).subscribe(
        response => {
          this.contratos = Object(response);
          this.configService.loaddinStarter('stop');
        }
      );
    }
  }

  private getPrioridade() {
    this.http.call_get('ordemservico/prioridade/selectBox', null).subscribe(
      response => {
        this.prioridades = Object(response).data;
      }
    );
  }

  private getClassificacao() {

    const uri = 'ordemservico/classificacao-comercial/selectBox';

    this.http.call_get(uri, null).subscribe(
      response => {
        this.classificacoes = Object(response).data;
      }
    );
  }


  private getMotivo() {

    this.loading = true
    this.http.__call('comunicacoes/motivo/activo', this.filters).subscribe(
      response => {

        this.motivos = Object(response).data;
      }
    );
  }

  private _getEstado(flag: string) {
    this.state=''
    if(flag==null || flag=='null')return
    this.http.__call('comunicacoes/estado_listagem', { flag }).subscribe(
      response => {
        let estado = Object(response).data;

        this.state=estado[0].flag
        this._state=estado[0].flag
      })
  }

  private _getEstados(id) {

    this._state=''
    let ids=this.simpleFormCliente.get('estado_id').value
    if(!id || id =='null')return
    this.http.__call('comunicacoes/estado_listagem/'+id, null).subscribe(
      response => {
        let estado = Object(response).data;

        this._state=estado[0]?estado[0].flag:null
      })
  }

  private getEstado(flag: string) {
    this.estado=[]
    this.http.__call('comunicacoes/estado_listagem', { flag }).subscribe(
      response => {

        if(Array.isArray(Object(response).data)){
          this.estado = Object(response).data;
        }else{
            this.estado.push(Object(response).data)
        }

      })
  }
  private getTipo() {
    const _ = {
      categoria: this.simpleFormCliente.get('categoria').value,
      nivel_1: this.simpleFormCliente.get('nivel_1').value,
      nivel_2: this.simpleFormCliente.get('nivel_2').value
    }
    if (_.categoria == null || _.nivel_1 == null || _.nivel_2 == null || _.nivel_2[0] == null) return
    this.http.__call(`comunicacoes/registro/${_.categoria}/${_.nivel_1}/${_.nivel_2}`, null).subscribe(
      response => {
        this.tipos = Object(response);
        this.simpleFormCliente.patchValue({
          origem: this.tipos[0] != null ? this.tipos[0].origem : null,
          encaminhamento: this.tipos[0] != null ? this.tipos[0].encaminhamento_default : null
        })
        this.os_tipo_id=(this.tipos[0] || {}).os_id

         this.simpleFormCliente.patchValue({
            os_id: this.os_tipo_id
        })

        if (this.simpleFormCliente.value.encaminhamento == null
          || this.simpleFormCliente.value.origem == null) return
        this.getEncamiamento(this.simpleFormCliente.value.encaminhamento)
        this.getOrigem(this.simpleFormCliente.value.origem)
      })

  }

  getSelectedTrigger(tipo_id,ot_tipos){
    let data=[]
    let frequncyCountElement={}
    this.http.call_get(`ordemservico/os_tipo_trigger/${tipo_id}`)
    .subscribe(
          response => {

              data=Object(response).data
              let newArray=[]
              console.log(ot_tipos,data[0])
              for (let i=0 ;i<ot_tipos.length;i++) {

                     if(ot_tipos[i].id==data[0].os_tipo_id){
                            newArray.push(ot_tipos[i])
                      }
              }
               this.simpleFormCreateOrEdit.patchValue({
                    tipo_id:newArray
                })


          }
      )
}

  private getEncamiamento(id) {
    // console.log(id)
    this.http.call_get('departamento/selectBoxById/' + id, null).subscribe(
      response => {
        this.encamiamento = Object(response).data;

      })
  }


  private getLocalConsumoByContratoId() {

    this.simpleFormCreateOrEdit.patchValue({
      cil: null,
      numero_serie: null,
      predio_nome: null
    });

    if (this.simpleFormCreateOrEdit.value.cliente_contrato_id != null) {

      this.cliente_contrato_id = this.simpleFormCreateOrEdit.value.cliente_contrato_id;

      this.http.call_get('local-consumo/getLocalConsumoByContratoId/' + this.simpleFormCreateOrEdit.value.cliente_contrato_id, null).subscribe(
        response => {

          this.local_consumo = Object(response);
          this.simpleFormCreateOrEdit.patchValue({
            cil: this.local_consumo.cil == null ? '' : this.local_consumo.cil,
            numero_serie: this.local_consumo.numero_serie == null ? '' : this.local_consumo.numero_serie,
            predio_nome: ((this.local_consumo.cil != null) ? '[' + this.local_consumo.cil + '] ' : '[0000-000-000] ') + ((this.local_consumo.is_predio) ? 'Prédio ' + this.local_consumo.predio_nome + ', ' + (this.local_consumo.predio_andar ? this.local_consumo.predio_andar + ' º Andar - ' : '') + 'Porta ' : 'Residência ') + this.local_consumo.moradia_numero
          });

        }
      );
    } else {
      this.simpleFormCreateOrEdit.patchValue({
        cil: null,
        numero_serie: null,
        predio_nome: null
      });

      this.cliente_contrato_id = null
    }



  }


  private getOrigem(id) {
    this.http.__call('comunicacoes/origem/edite/' + id, null).subscribe(
      response => {
        this.origems = Object(response).data;
      })
  }
  private getcategoria() {
    // console.log("userName",this.userName)
    // console.log("userNameLOCALSTOREGE",JSON.parse(localStorage.getItem('_token')).user)
    this.refreshNivelId()
    this.nivels = []
    this.origems = [],
      this.nivel_ids = [],
      this.encamiamento = []
    this.http.__call('comunicacoes/categorias_listagem_all', null).subscribe(
      response => {
        this.categorias = Object(response).data;
      })
  }

  private getNivel() {
    this.refreshNivelId()
    this.nivels = []
    this.origems = [],
      this.nivel_ids = [],
      this.encamiamento = []
    const id = this.simpleFormCliente.get('categoria').value
    this.http.__call('comunicacoes/categoriasByNivel/' + id, null).subscribe(
      response => {
        this.nivels = Object(response).data;
      })
  }

  refreshNivelId() {
    const data = () => {
      this.origems = [],
        this.nivel_ids = [],
        this.encamiamento = []
    }
    return data
  }
  private _getNivelId() {
    this.refreshNivelId()
    const id = this.simpleFormCliente.get('nivel_1').value
    this.http.__call('comunicacoes/niveis_tipo/' + id, null).subscribe(
      response => {
        this.nivel_ids = Object(response).data

      })

    this.getTipo()
  }

  setIsRegistroOrdem(){
    this.isOrdemServicoWithRegistro=false
  }

  alertOrdemServico(){
    this.isOrdemServicoWithRegistro=true
    this.getPrioridade()
    this.getClassificacao()
   if(this.simpleFormCliente.value.os_id){
      Swal.fire({
          title: 'Você tem certeza?',
          text: "Ordem de Serviço!",
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim, Registrar',
          cancelButtonText: 'Não, Cancelar!',
          reverseButtons: true
      }).then((result) => {
          if (result.value) {
          // this.configService.loaddinStarter('start')
            this.configService.showAlert('Processamento em andamento', 'alert-success', true);

            ($("#createOTModal") as any).modal("show")
          }else{

            this.onSubmit();
          }
      })
   }else{
     this.onSubmit();
   }

  }


  changeValue(value) {
    this.state=null
    this.stateValidate = value
    this.DateTimeZone()
  }

  private getHistoricoId(id) {
    this.simpleFormCliente.patchValue({
      id: id
    })
    // this.getHistorico()
  }

  private resetHistorico() {
    this.formHistorico.reset()
  }

  private createHistorico() {
    const url = this.stateValidate == true ?
      `historico/${this.simpleFormCliente.value.id}`
      : `historico/update/${this.formHistorico.value.hostory_id}/${this.simpleFormCliente.value.id}`
    this.getHistorico(this.simpleFormCliente.value.id)
    this.http.__call(url, this.formHistorico.value).subscribe(
      response => {
        this.submitted = false
        this.loading = false
        this.configService.showAlert(Object(response).message, 'success', true)

        this.getRegisto()
        if (this.stateValidate == true) this.resetHistory()
      },
      error => {
        this.configService.showAlert("Alguma coisa correu mal", 'warning', true);
        this.submitted = false;
      })
  }

  private getHistorico(id) {


    this.http.__call(`historico/list/${id}`, this.filtersComunca).subscribe(
      response => {
        this.filtersComunca.pagination.lastPage = Object(response).data.lastPage;
        this.filtersComunca.pagination.page = Object(response).data.page;
        this.filtersComunca.pagination.total = Object(response).data.total;
        this.filtersComunca.pagination.perPage = Object(response).data.perPage;
        this.historico = Object(response).data.data;
      }

    )
  }

  setTrue() {
    this.filters.pagination.page = this.filters.pagination.page
    this.filters.is_allexel = true
    this.getRegisto()
  }

    CalculateDate() {
    const event = new Date();
    const parisDate = toDate(event.toISOString())
    const pattern = "MM/dd/yyyy"
    const timeOutput = format(parisDate, pattern, { timeZone: 'UTC/GMT+1' })
    return timeOutput.slice(0, 16);
  }

  private atrasoDeDias(date){

      let dates =[]
      dates[0]=this.CalculateDate()
      dates[1]=this._DateTimeZone(date)
      if(!dates[1])return '---'
      let sum = 0;
      let prevdate:any = false;

      for(let k in dates) {
        let thedate:any = moment(dates[k], "M/D/YYYY");
        if(prevdate) {
          sum += prevdate.diff(thedate, 'days');
        }
        prevdate = thedate;
      }

      return (sum / (dates.length - 1));

  }

  private getRegisto() {
    this.lengthOfChecketItem=[]
    this.loading = true
    this._items =[]
    this.items=[]
    this.filters.filter = this.filter
    this.http.__call('comunicacoes/registro_listagem', this.filters).subscribe(
      response => {
        this.filters.pagination.lastPage = Object(response).data.lastPage;
        this.filters.pagination.page = Object(response).data.page;
        this.filters.pagination.total = Object(response).data.total;
        this.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data.map(item=>{
          return {
            ...item,
            atraso_dia:this.atrasoDeDias(item.date),
          }
      });
      this._items = this.items
        //this.items=this.items
        this.loading = false;

        if (this.filters.is_allexel == false) {
          this.items = Object(response).data.data;
        } else {
          this.items = Object(response).data.data;
          this.exportAsXLSX(this.items)
        }
        this.filters.is_allexel = false
        this.loading = false;
      }
    );
  }

  private getRegistoById(id) {

    this.http.__call('comunicacoes/registro_listagem/' + id, this.filters).subscribe(
      response => {
        this.filters.pagination.lastPage = Object(response).data.lastPage;
        this.filters.pagination.page = Object(response).data.page;
        this.filters.pagination.total = Object(response).data.total;
        this.filters.pagination.perPage = Object(response).data.perPage;

        this.items1 = Object(response).data.data.data;

        console.log('items1=>',this.items1)
        if (this.filters.is_allexel == false) {
          this.items1 = Object(response).data.data.data;
        } else {
          this.items1 = Object(response).data.data.data;
          this.exportAsXLSX(this.items1)
        }
        this.filters.is_allexel = false

      }
    );
  }


  private getOrdens(id) {

    this.loading = true

    this.http.call_get('ordemservico/os-reclamacao/' + id, null).subscribe(
      response => {
        this.reclamacoes = Object(response).data;
        this.loading = false;

      }
    );

  }



  private changesState(event){

    this._getEstado(event)
  }

  private getContas(cliente_id) {

    this.contas = [];

    this.simpleFormCreateOrEdit.patchValue({
      cil: null,
      numero_serie: null,
      predio_nome: null
    });

    this.http.call_get('cliente/conta/selectBox/' + cliente_id, null).subscribe(
      response => {
        this.contas = Object(response).data
      })
  }


  exportAsXLSX(data: any): void {
    var CurrentDate = new Date();
    for (let i = 0; i < data.length; i++) {
      if (data[i].is_active == 1) {
        data[i].is_active = "Aberto"
      } else {
        data[i].is_active = "Fechado"
      }
    }
    var keys = [
      { key: 'categoria', width: 50 },
      { key: 'descricao', width: 50 },
      { key: 'cliente_id', width: 30, style: { font: { name: 'Calibri' } } },
      { key: 'contaDescricao', width: 30 },
      { key: 'contrato_id', width: 30 },
      { key: 'cliente', width: 30, style: { font: { name: 'Calibri' } } },
      { key: 'user', width: 30, style: { font: { name: 'Calibri' } } },
      { key: 'municipio_nome', width: 30, style: { font: { name: 'Calibri' } } },
      { key: 'cil', width: 30, style: { font: { name: 'Calibri' } } },
      { key: 'desc', width: 30 },
      { key: 'encaminhamento', width: 30 },
      { key: 'origem', width: 30 },
      { key: 'nivel_desc', width: 30 },
      { key: 'data', width: 50, style: { numFmt: 'dd/mm/yyyy' } },
      { key: 'data', width: 50, style: { numFmt: 'HH:MM' } },

    ];

    var Cols = [
      'Categoria Descrição',
      'Descrição',
      'Nº Cliente',
      'Conta',
      'Contrato',
      'Cliente',
      'Operador',
      'Município',
      'Cil',
      'Estado',
      'Encaminhamento',
      'Origem',
      'Nível',
      'Data',
      'Hora',
    ]

    var title = 'RELATÓRIOS COMUNICAÇÃO REGISTO'
    var nameFile = "RELATORIOS_COMUNICACAO_REGISTO -" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    this.excelsService.excels(data, nameFile, this.localUrl, keys, Cols, title, 5, 15, 40, 3)
  }

  private setFlag(flag) {
    return this.flag = flag
  }

  changeContactoId(id){
    this.contacto_id=id
  }

  private getContacto(id) {

    this.contactos = [];

    this.http.__call('comunicacoes/contacto/list/'+id, null).subscribe(
      response => {
        this.contactos = Object(response).data
      })
  }

  onSubmitContacto(){

   let value= this.createOrEditContacto.getRawValue()
    let url=this.contacto_id?'comunicacoes/contactos/'+this.contacto_id:'comunicacoes/contacto'


    this.http.$__call(url,value).subscribe(
      response => {
        this.submitted = false;
        this.loading = false;
        this.getContacto(this.registro_id)
      })
  }
initContacto(item){
  this.createOrEditContacto.patchValue({
    registro_id: item.registro_id,
   // estado_id:item.estado_id,
    via_contacto:item.via_contacto,
    descricao:item.descricao
})
}
  setCreate(){
    this.contacto_id=null
    this.createOrEditContacto.patchValue({
      via_contacto:null,
      descricao:null
  })
  }

  estadoFechado:any=null

  private getEstadoFechado() {
   // this.estado=''
    this.http.__call('comunicacoes/estado_fechado', null).subscribe(
      response => {
        this.estadoFechado = Object(response).data;
      })
  }

  private get contratosChecked(): any[] {
    return this.items.filter(val => val.checked === true);
  }

  setCheckedAll(checked: boolean) {
    console.log(checked)
    this.checkedAll = checked;
    this._items.forEach(f => f.checked = checked);
  }

  addItemMassiva(){
    let request=[]
       const data = this._items.filter(val => val.checked === true);
       console.log(data,this.estadoFechado)
       if(data.length<=1){
          this.configService.showAlert("Seleccione no minimo duas ou mais comunicações", "warning", true);
          return;
       }
       for (const item of data) {
          let url =`comunicacoes/registro/massiva/${item.id}`
          let uploadData = new FormData();
          uploadData.append('id', item.id);
          uploadData.append('estado_id', this.estadoFechado.id);
          if(item.checked){
             uploadData.append('checked', item.checked);
          }
          request.push(this.http.$__call(url,uploadData))
        }

        forkJoin(request).subscribe(
          res=>{
             console.log(res,'forkjoin')
             this.getRegisto()
        })
       // this.$createOrUpdateCliente(url,)

  }

  lengthOfChecketItem=[]
  updateChecked(f: any) {
    //this.iniCliente(f)
    this.lengthOfChecketItem.push(f)

    this._items.forEach(element => {
      element.checked = element.id === f.id ? !f.checked : element.checked;
      Object.assign(element);
    });
    const lengthFalse = this._items.filter(val => val.checked === true);
    this.checkedAll = lengthFalse.length === this._items.length ? true : false;
    console.log('checkedAll',this.checkedAll,lengthFalse.length,this._items.length,this._items)
  }

  _updateChecked() {
    //this.iniCliente(f)

    let request=[]

    for (const item of this._items) {
      let url =`comunicacoes/registro/massiva/${item.id}`
      let uploadData = new FormData();
      uploadData.append('id', item.id);
      uploadData.append('estado_id', this.estadoFechado.id);
      if(item.checked){
         uploadData.append('checked', item.checked);
      }
      request.push(this.http.$__call(url,uploadData))
    }

    forkJoin(request).subscribe(
      res=>{
         console.log(res,'forkjoin')

    })
   this.getRegisto()
    console.log(this.items)
  }

  $createOrUpdateCliente(url, cliente_value) {
    this.getEstadoFechado()
    let uploadData = new FormData();
    if(cliente_value.checked){
      uploadData.append('checked', cliente_value.checked);
   }
    uploadData.append('id', cliente_value.id);
    uploadData.append('estado_id', cliente_value.estado_id);


    for (let file of this.fileList) {
      uploadData.append("file", file.file);
    }
    this.http.$__call(url, uploadData).subscribe(
       event => {
        if (this.simpleFormCliente.value.id == null) {
          this.onReset()
        }

        this.submitted = false
        this.loading = false
        this.getRegisto()
        this.getHistorico(this.simpleFormCliente.value.id)
      },
      error => {
        this.configService.showAlert("Alguma coisa correu mal", 'warning', true);
        this.submitted = false;
      },
    ),


      this.getPageFilterDataPage(1)
    this.loading = false
  }

  onSubmit() {

    this.submitted = true
    if (this.simpleFormCliente.value.id == null) {
      this.simpleFormCliente.patchValue({
        estado_id: 1
      })
    }
    if(!this.isOrdemServicoWithRegistro){
      this.simpleFormCreateOrEdit.reset()
   }
    if(this._state=='FECHADO'){
      this.simpleFormCliente.patchValue({
        checked: true
      })
    }


    if (this.simpleFormCliente.invalid) { return }
    let cliente_value = this.simpleFormCliente.getRawValue()



    let create_registo = (cliente_value.id ? (`comunicacoes/registro/${cliente_value.id}`) : ('comunicacoes/registro'))
    let add_registo = this.stateValidate == true ? `historico/${this.simpleFormCliente.value.id}` : `historico/update/${this.simpleFormCliente.value.hostory_id}/${this.simpleFormCliente.value.id}`
    let url_action = null
    if (this.flag == 1) {
      url_action = add_registo
    } else {
      url_action = create_registo
    }

    this._createOrUpdateCliente(url_action, {...cliente_value, ordemServico:this.simpleFormCreateOrEdit.value})
  }

  onSubmitCriarOT() {
    this.submitted = true;

        if(this.isOrdemServicoWithRegistro){

           this.onSubmit()
        }else{

          if (this.simpleFormCreateOrEdit.invalid) {
            return;
          }
          this.loading = true;
          const index = this.simpleFormCreateOrEdit.getRawValue().index;
          // TODO: usado para fazer a requisição com a api de criação de objsct or update
          const uri = 'ordemservico/ordem-servico/create';
          this.createNewOT(uri, this.simpleFormCreateOrEdit, (index === null ? true : false));
    }


    // parar aquei se o formulário for inválido

  }

  createNewOT(uri: any, formulario: FormGroup, isCreate: boolean) {


    this.isDisabled = true;
    // TODO: usado para fazer a requisição com a api de criação de object
    let uploadData = new FormData();

    uploadData.append("ordemServico", JSON.stringify(formulario.value));
    uploadData.append("causas", JSON.stringify(this.causasArray));
    uploadData.append("sintomas", JSON.stringify(this.sintomasArray));
    uploadData.append("departamentos", JSON.stringify(this.departamentoArray));

    this.http.$__call(uri, uploadData).subscribe(
      response => {
        this.submitted = false;
        this.loading = false;

        //this.closebutton.nativeElement.click();

        this.onReset()

        if (isCreate && Object(response).code == 200) {
          formulario.reset();
          this.onReset()
        }

       if ((Object(response).body || {}).code == 200) {
         this.alertPrintOrdemServico((Object(response).body || {}).data.ordem.id)
          formulario.reset();
          this.onReset()
        }
      },
      error => {
        this.submitted = false;
        this.loading = false;
      });
  }

  alertPrintOrdemServico(id){
   
    if(!id) return
     Swal.fire({
         title: 'Imprimir Ordem de Serviço!',
         text: "Ordem de Serviço!",
         type: 'success',
         showCancelButton: true,
         confirmButtonText: 'Imprimir',
         cancelButtonText: 'Cancelar!',
         reverseButtons: true
     }).then((result) => {
         if (result.value) {
           this.imprimirOrdemServico(id)
         }
     })
 
 }
 private imprimirOrdemServico(id) {
   this.configService.imprimirOrdemServico(id, "2ª Via", "imprimir")
 }
  _createOrUpdateCliente(url, cliente_value) {
    let uploadData = new FormData();

 // return
    uploadData.append('id', cliente_value.id);
    uploadData.append('historico_id', cliente_value.historico_id);
    uploadData.append('cliente', cliente_value.cliente);
    uploadData.append('cliente_id', cliente_value.cliente_id);
    uploadData.append('tipo_id', cliente_value.tipo_id);
    uploadData.append('descricao', cliente_value.descricao);
    uploadData.append('estado_id', cliente_value.estado_id);
    uploadData.append('origem', cliente_value.origem);
    uploadData.append('categoria', cliente_value.categoria);
    uploadData.append('encaminhamento', cliente_value.encaminhamento);
    uploadData.append('title', cliente_value.title);
    uploadData.append('data', cliente_value.data);
    uploadData.append('time', cliente_value.time);
    uploadData.append('nivel_1', cliente_value.nivel_1);
    uploadData.append('nivel_2', cliente_value.nivel_2);
    uploadData.append('conta', cliente_value.conta);
    uploadData.append('contrato_id', cliente_value.contrato_id);
    uploadData.append('hostory_id', cliente_value.hostory_id);
    uploadData.append('data_afectiva1', cliente_value.data_afectiva1);
    uploadData.append('data_afectiva2', cliente_value.data_afectiva2);
    uploadData.append('data_prevista1', cliente_value.data_prevista1);
    uploadData.append('data_prevista2', cliente_value.data_prevista2);
    uploadData.append('motivo_id', cliente_value.motivo_id);
    uploadData.append('departamento', JSON.stringify(this.encamiamento));

    uploadData.append("ordemServico", JSON.stringify(cliente_value.ordemServico));
    uploadData.append("causas", JSON.stringify(this.causasArray));
    uploadData.append("sintomas", JSON.stringify(this.sintomasArray));
    uploadData.append("departamentos", JSON.stringify(this.departamentoArray));


    if(cliente_value.checked){
       uploadData.append('checked', cliente_value.checked);
    }

    for (let file of this.fileList) {
      uploadData.append("file", file.file);
    }
    this.http.$__call(url, uploadData).subscribe(
      (event: HttpEvent<any>) => {
        if (this.simpleFormCliente.value.id == null) {
          this.onReset()
        }
        // console.log(event.type)
        switch (event.type) {
          case HttpEventType.Sent:
            console.log('Request has been made!');
            break;
          case HttpEventType.ResponseHeader:
            console.log('Response header has been received!');
            break;
          case HttpEventType.UploadProgress:
            this.progress = Math.round(event.loaded / event.total * 100);
            break;
          case HttpEventType.Response:
              /*
             this.simpleFormCreateOrEdit.patchValue({
              reclamacao_id: ((Object(event.body).data).res || {}).id || null
             })  */
            setTimeout(() => {
              this.progress = 0;
              this.fileList = []
            }, 1500);

        }
        this.submitted = false
        this.loading = false
        this.getRegisto()
        this.getHistorico(this.simpleFormCliente.value.id)
      },
      error => {
        this.configService.showAlert("Alguma coisa correu mal", 'warning', true);
        this.submitted = false;
      },
    ),


      this.getPageFilterDataPage(1)
    this.loading = false
  }
  registro_id:any=null
  iniCliente(cliente) {
    this.state=null
    this.simpleFormCreateOrEdit.patchValue({

    })
    this.createOrEditContacto.patchValue({
      estado_id:cliente.id_estado,
      registro_id: cliente.id
   })
   this.registro_id=cliente.id
   this.getContacto(cliente.id)


    this.simpleFormCliente.patchValue(
      {
        id: cliente.id,
        cliente: cliente.cliente,
        contrato_id: cliente.contrato_id,
        tipo_id: cliente.tipo_id,
        descricao: cliente.descricao,
        title: cliente.title,
        estado_id: cliente.id_estado,
        origem: cliente.origem_id,
        categoria: cliente.comunicacao_categoria_id,
        data: this.DateTimeZone(),
        time: this.DateTimeZone(),
        conta: cliente.conta_id,
        nivel_1: cliente.comunicacao_nivel_id,
        nivel_2: cliente.nivel_id,

        data_prevista1:cliente.data_inicio_prevista,
        data_prevista2:cliente.data_fim_prevista,
        data_afectiva1:null,
        data_afectiva2:null,
        encaminhamento: cliente.encaminhamento_id,
        user: this.userName,
        hostory_id: cliente.h_id
      }
    )
    this.getEstado(cliente.flag)
    // this.files=[]
    this._getNivelId()
    this.setCliente({ nome: cliente.cliente, id: cliente.cliente_id })
    this.getNivel()
    this.getCliente()
    this.getEncamiamento(cliente.encaminhamento_id)
    this.getOrigem(cliente.origem_id)
    this.getHistorico(cliente.id)
    this.getContratosByConta()
    this.view_client = false;
    //this.getEstado()
    this.contas = []
    this.contratos = []
  }

  _closeModal(closeModal) {
    var action = document.getElementById(closeModal)
    action.click()
  }
  private disable(condition) {

    if (condition != true && condition != false) {
      this.simpleFormCliente.controls.cliente.disable();
      this.simpleFormCliente.controls.contrato_id.disable();
      this.simpleFormCliente.controls.tipo_id.disable();
      this.simpleFormCliente.controls.origem.disable();
      this.simpleFormCliente.controls.categoria.disable();
      this.simpleFormCliente.controls.data.disable();
      this.simpleFormCliente.controls.conta.disable();
      this.simpleFormCliente.controls.encaminhamento.disable();
      this.simpleFormCliente.controls.nivel_1.disable();
      this.simpleFormCliente.controls.nivel_2.disable();

      this.simpleFormCliente.controls.data_prevista1.disable();
      this.simpleFormCliente.controls.data_prevista2.disable();
    } else {

      if (condition == true) {
        this.simpleFormCliente.controls.cliente.disable();
        this.simpleFormCliente.controls.contrato_id.disable();
        this.simpleFormCliente.controls.tipo_id.disable();
        this.simpleFormCliente.controls.origem.disable();
        this.simpleFormCliente.controls.categoria.disable();
        this.simpleFormCliente.controls.data.disable();
        this.simpleFormCliente.controls.conta.disable();
        this.simpleFormCliente.controls.encaminhamento.disable();
        this.simpleFormCliente.controls.nivel_1.disable();
        this.simpleFormCliente.controls.nivel_2.disable();
      /*   this.simpleFormCliente.controls.data_fim_afectiva.disable(); */
        this.simpleFormCliente.controls.data_prevista1.disable();
 /*        this.simpleFormCliente.controls.data_inicio_afectiva.disable(); */
        this.simpleFormCliente.controls.data_prevista2.disable();
        this.simpleFormCliente.patchValue(
          {
            descricao: null,
          })

      } else {

        this.simpleFormCliente.controls.data_prevista1.enable();
        this.simpleFormCliente.controls.data_prevista2.enable();
        this.simpleFormCliente.controls.cliente.enable();
        this.simpleFormCliente.controls.contrato_id.enable();
        this.simpleFormCliente.controls.tipo_id.enable();
        this.simpleFormCliente.controls.title.enable();
        this.simpleFormCliente.controls.origem.enable();
        this.simpleFormCliente.controls.categoria.enable();
        this.simpleFormCliente.controls.data.disable();
        this.simpleFormCliente.controls.conta.enable();
        this.simpleFormCliente.controls.encaminhamento.enable();
        this.simpleFormCliente.controls.nivel_1.enable();
        this.simpleFormCliente.controls.nivel_2.enable();
      }
    }
  }


  get f() {
    return this.simpleFormCliente.controls;
  }
  get fs() {
    return this.formHistorico.controls;
  }
  onReset() {
    if (this.stateValidate == false) {
      this.submitted = false;
      this.simpleFormCliente.reset(
        {
          historico_id: null,
          cliente: null,
          cliente_id: null,
          tipo_id: [null],
          descricao: '',
          estado_id: null,
          origem: null,
          categoria: null,
          encaminhamento: null,
          title: null,
          data: this.DateTimeZone(),

          nivel_1: [null,],
          // nivel_1: [null, [Validators.required]],
          nivel_2: [null],
          conta: [null],
          contrato_id: [null],
          hostory_id: null,
          user: this.userName,
          file: [null]
        });
      this.simpleFormCliente.patchValue(
        {
        }
      );
    }
    this.fileList = []
    this.contas = []
    this.contratos = []
    return
  }

  getPageFilterDataPage(page: number) {
    if (this.filters.pagination.perPage == null) {
      return;
    }
    this.filters.pagination.page = Number.isInteger(page) ? page : 1;
    this.getRegisto();
    this.getEstadoFechado()
  }
  getPageFilterDataPageCmunica(page: number) {
    if (this.filtersComunca.pagination.perPage == null) {
      return;
    }
    this.filtersComunca.pagination.page = Number.isInteger(page) ? page : 1;
    this.getHistorico(this.simpleFormCliente.value.id);
  }


  modalDescricao(data) {
    if (data.length < 96) return
    const swal = Swal.mixin({

    })
    swal.fire({
      title: 'Descrição',
      html: '<p>' + data + '</p>'
    })

  }

  files: File[] = [];

  file() {
    //this.files=[]
    //this.fileList=[]
    console.log(this.filenout)
    this.files.push(this.filenout.files)
    for (var i = 0; i < this.filenout.files.length; i++) {

      this.fileList.push({
        name: this.filenout.files[i].name,
        size: this.filenout.files[i].size,
        file: this.filenout.files[i]
      })
    }
    this.filenout.value=null
   // document.getElementById('file-input')
  }

  removeFile(value) {
    this.filenout.value=null
    console.log('remove files',this.filenout.files)
    const index = this.fileList.indexOf(value)
    this.fileList.splice(index, 1);
  }


  async downloads({ ext, url, nome, id, flag }: Ifile) {
    const documentStyles = document.documentElement.style;

    this.progress = id
    this.progress_1 = flag
    url = environment.app_url + url;

    axios({
      url: url,
      method: "post",
      responseType: "blob",
      async onDownloadProgress(progressEvent) {
        if (flag == 'no') {
          this.progress = await Math.round((progressEvent.loaded / progressEvent.total) * 100);
          documentStyles.setProperty('--progress', `${this.progress}%`);
        } else {
          this.progress = await Math.round((progressEvent.loaded / progressEvent.total) * 100);
          documentStyles.setProperty('--progress', `${this.progress}%`);
        }
      }
    }).then(response => {
      var binaryData = [];
      var index = nome.indexOf(".");
      var afterComa = nome.substr(index, nome.length);

      if (ext == "vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        nome = nome.replace(afterComa, '.xlsx')

      }
      else if (ext == "vnd.oasis.opendocument.text") {
        nome = nome.replace(afterComa, '.odt')
      }

      const res = response

      binaryData.push(res.data);
      if (flag == 'no') {
        this.progress = 0;
        documentStyles.setProperty('--progress', `${this.progress}%`);
        return saveAs(new Blob(binaryData, { type: res.data.type }), nome)
      }
      this.progress = 0;
      documentStyles.setProperty('--progress', `${this.progress}%`);
      const fileURL = window.URL.createObjectURL(new Blob(binaryData, { type: res.data.type }))
      window.open(fileURL, '_blank');
    });
  }
}




// new Blob([res.data], {type: "application"})
