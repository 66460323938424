import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/providers/config/config.service';
import { HttpService } from 'src/app/providers/http/http.service';
import { ExcelService } from 'src/app/services/excel.service';
import { ExcelAutoService } from 'src/app/services/excel/excel-auto.service';
import { ReportClienteService } from '../../report-at/relatorios/report-cliente.service';
import * as moment from 'moment';
import { ReportAdiantamentosService } from '../../report-at/relatorios/report-adiantamnetos.service';


@Component({
  selector: 'app-relatorio-adiantamentos',
  templateUrl: './relatorio-adiantamentos.component.html',
  styleUrls: ['./relatorio-adiantamentos.component.css']
})
export class RelatorioAdiantamentosComponent implements OnInit {



  constructor(private http: HttpService,
    private configService: ConfigService,
    private excelService: ExcelService,
    private reportAdiantamento: ReportAdiantamentosService,
    private excelsService: ExcelAutoService
  ) {

  }
  loading: boolean = false;
  disableButton: boolean = true;
  public checkoutDate:boolean=false
  private filter = {
    cliente: null,
    cliente_id: null
  }

  submitted = false;
  public filters = {
    search: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    pagination: {
      perPage: 5,// Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima,
      lastPage: null,
      total: null // defini o total de registo da BD
    },
    filter: null // subelemente do filter
    , is_allexel: false,
    municipio: null,
    bairro: null,
    distrito : null,
    data2:null,
    data1:null
  }

  private localUrl: any;
  private largura: any;
  private altura: any;
  items: any = [];
  direccoes: any = [];
  gestores: any = [];
  generos: any = [];
  tipoclientes: any = [];
  municipios: any = [];

  bairros: any = [];
  distritos : any = []
  ruas: any = [];
  tipoidentidades: any = [];
  clientes: any = [];



  ngOnInit() {
    this.getPageFilterData(1),
      this.empresaUser(),
      this.getMunicipio()
  }

  resetDate(){
    this.checkoutDate=this.hendleDateValidate()
   }

   hendleDateValidate=()=>{
    let format = "YYYY-MM-DD"
    let data1 = moment( this.filters.data1 ).format(format);
    let data2 = moment( this.filters.data2 ).format(format);
    let condition= ( data1 > data2 )
    return condition
  }

  exportAsPDF(): void {
    var file = document.getElementsByClassName("exportAsXLSXCliente")[0]
    this.reportAdiantamento.relatorioAdiantamentos('save', file, this.localUrl);
  }


  private empresaUser() {
    this.configService.loaddinStarter('start');

    this.http.call_get('empresa/empresa-user', null).subscribe(
      response => {
        this.localUrl = Object(response).data.logotipo
        this.altura = Object(response).data.width
        this.largura = Object(response).data.height
        this.configService.loaddinStarter('stop');
      }
    );
  }

  private getMunicipio() {
    this.http.__call('municipio/listagems', null).subscribe(
      response => {
        this.municipios = Object(response).data;
      }
    );
  }

  getDistrito(id) {
    this.http.__call('get-distritos/' + id, null).subscribe(
      response => {
        this.distritos = Object(response).data;
      }
    );
  }

  private getBairro(id) {

    this.http.call_get('bairro/getBairrosByDistrito/' + id, null).subscribe(
      response => {
        this.bairros = Object(response).data;
      }
    );

  }


  imprimirPDF(): void {
    var file = document.getElementsByClassName("exportAsXLSXCliente")[0];
    this.reportAdiantamento.relatorioAdiantamentos('print', file, this.localUrl);
  }


  setTrue() {
    this.filters.pagination.page = this.filters.pagination.page
    this.filters.is_allexel = true
    this.getTotalDeAdiantamentos()
  }

  getPageFilterData(page: number) {
    if (this.filters.pagination.perPage == null) {
      return;
    }
    console.log('page=>',page)
    this.filters.pagination.page = Number.isInteger(page) ? page : 1;
    this.getTotalDeAdiantamentos();
  }

  private getTotalDeAdiantamentos() {
    this.configService.loaddinStarter('start');
    this.loading = true;
    this.filters.search = this.filter.cliente;
    this.filters.municipio = this.filters.municipio;
    this.items=[]

    this.http.__call('relatorio/adiantamentos', this.filters).subscribe(
      response => {
        this.filters.pagination.lastPage = Object(response).data.lastPage;
        this.filters.pagination.page = Object(response).data.page;
        this.filters.pagination.total = Object(response).data.total;
        this.filters.pagination.perPage = Object(response).data.perPage;
        this.items = Object(response).data.data
        this.loading = false;
        if (this.items != 0) {
          this.disableButton = false;
        }

        this.loading = false;
      }
    );
    this.filters.is_allexel = false
    this.configService.loaddinStarter('stop');
  }

  exportAsXLSX(): void {
    var CurrentDate = new Date();

    var keys = [
      { key: 'id', width: 30 },
      { key: 'nome', width: 30, style: { font: { name: 'Calibri' } } },
      { key: 'municipio_nome', width: 30, style: { font: { name: 'Calibri' } } },
      { key: 'distrito_nome', width: 30, style: { font: { name: 'Calibri' } } },
      { key: 'bairro_nome', width: 30, style: { font: { name: 'Calibri' } } },
      { key: 'valor', width: 30, style: { font: { name: 'Calibri' } } },
    ];

    var Cols = [
      'Nº Cliente',
      'Nome Cliente',
      'Município',
      'Distrito',
      	'Bairro',
      'Total',
    ]

    var title = 'Relatório de Adiantamentos'
    var nameFile = "Relatório de Adiantamentos -" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    this.excelsService.excels(this.items, nameFile, this.localUrl, keys, Cols, title, 5, 6, 30, 3)
  }

  view_client = false;
  private getCliente(search: any) {
    search = search.target.value;
    this.view_client = true;
    if (search == '') {
      this.view_client = false;
      this.filter.cliente_id = null;

    }
    this.http.__call('cliente/search-cliente', { start: 1, end: 10, search: search }).subscribe(
      response => {
        this.clientes = Object(response).data.data;
      }
    );
  }

  private setCliente(client: any) {
    this.view_client = false;
    this.filter.cliente_id = client.id,
      this.filter.cliente = client.nome
  }





}
