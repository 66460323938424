import { Injectable, EventEmitter } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import * as moment from 'moment';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
// ES6 Modules or TypeScript
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'

@Injectable({
  providedIn: 'root'
})
export class ContaCorrenteReportService {
  

  public alertEvent = new EventEmitter<Object>();
  public loaddingEvent = new EventEmitter<Object>();
  public pdfEvent = new EventEmitter<Object>();

  constructor(private http: HttpService) { }

  public showAlert(message: string, cls: string, show: boolean) {
    this.alertEvent.emit({ message: message, class: cls, show: show });
  }

  public loaddinStarter(type: string) {
    this.loaddingEvent.emit({ type: type });
  }

  public contaCorrenteReport(cliente, estado_factura,table,empresa,totalaberto,p = 'print') {
    //console.log(1)
    //this.imprimirReport();

   
    
    this.http.__call('contaCorrente/report', {cliente_id: cliente, estado_factura: estado_factura}).subscribe(
      response => { 
        console.log(Object(response).data);
        this.imprimirReport(Object(response).data.facturas,Object(response).data.cliente,table,empresa,totalaberto,p);      
        
      }
    );
    

  }

  public imprimirReport(facturas: any[], cliente: any,table:any,empresa,totalaberto, p) {
 // Don't forget, that there are CORS-Restrictions. So if you want to run it without a Server in your Browser you need to transform the image to a dataURL
// Use http://dataurl.net/#dataurlmaker
var doc = new jsPDF('landscape');

doc.setProperties({
  title: 'Conta_corrente',
  subject: 'Conta Corrente',
  author: 'ITGEST',
  //keywords: '',
  creator: 'UNIG'
  });

  //doc.addImage(img_codigobarras, 'PNG', 129.5, 39, 69, 10)

  doc.setFontSize(8);
  doc.setFont("calibri");
  doc.setTextColor(0);
  doc.text('' + (empresa.companyName==null ?'':empresa.companyName), 47, 20);
  doc.setTextColor(0);
  doc.text('' + (empresa.addressDetail==null ?'':empresa.addressDetail), 47, 25);
  doc.text('NIF: ' + (empresa.taxRegistrationNumber==null ?'':empresa.taxRegistrationNumber), 47, 30);
  doc.text('Email: ' + (empresa.email==null?'':empresa.email), 47, 35);
  doc.text('Telefone: ', 103, 30);
  doc.text('' + (empresa.telefone==null?'':empresa.telefone), 115, 30);
  doc.text('WebSite: ', 103, 35);
  doc.setTextColor(0, 0, 255);
  doc.text('' + (empresa.site == null ? '' : empresa.site), 115, 35);
  doc.addImage(empresa.logotipo, 'JPEG',  15, 16, 28, 24);
  doc.setTextColor(0)

  doc.setFontSize(8);
    doc.setDrawColor(0);
    doc.setFillColor(255);

    doc.rect(230, 14, 53, 23, 'B');

    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.text('Cliente Nº:', 236, 20);
    doc.text('Nome:', 236, 25);
    doc.text('NIF:', 236, (cliente.nome.length > 33? 32: 30));

   doc.text(''+ (cliente.id),251, 20);
   doc.text(''+ (cliente.nome== null?'':cliente.nome),245, 25,{ maxWidth: 33 });
   doc.text(''+ (cliente.numero_identificacao ==null?'':cliente.numero_identificacao),245,(cliente.nome.length>33? 32: 30), 'left');

   doc.setFontStyle('calibri')
   doc.setFontSize(9);
   doc.setTextColor(0)

   doc.setFontStyle('bold')
   doc.setTextColor(0)
   doc.text('Conta Corrente', 150, 50, 'right');
   //doc.text('Nome:',248, 30, 'right');
   //doc.text('NIF:',248, 35, 'right');

   let currentpage = 0;
   var today =  moment().format("DD-MM-YYYY H:mm:ss");
   var versao =  'C.C 1.0.0';
   const totalPagesExp = "{total_pages_count_string}";

   doc.autoTable({ html: table ,
    didParseCell: function (data) {
    var rows = data.table.body;
    if (data.row.index === 0 ) {
    data.cell.styles.fontStyle = 'bold';
    data.cell.styles.textColor = "white";
    data.cell.styles.fillColor = [0,153,255];
    data.cell.styles.halign = 'center';
    data.cell.styles.fontSize = 10;
    }
    },
    didDrawPage : data => {
    let footerStr = "Página " + doc.internal.getNumberOfPages();
    if (currentpage < doc.internal.getNumberOfPages()) {
      doc.setFontType('calibri')
      footerStr = footerStr + " de " + totalPagesExp;
      
    }
    doc.setFontType('calibri')
    doc.setFontSize(10);
    doc.text(versao,267, 200,'left')
    doc.text(today,150, 200, 'center')
    doc.text(footerStr, data.settings.margin.left, doc.internal.pageSize.height - 10);
    currentpage = doc.internal.getNumberOfPages();
    },
      styles: { textColor: [0, 0, 0] ,font: "calibri",fontSize: 9},margin: {bottom : 20, left: 15},
      columnStyles: {
        0: {cellWidth: 20,halign: 'center'},
        1: {cellWidth: 20,halign: 'center'},
        2: {cellWidth: 35,halign: 'center'},
        3: {cellWidth: 22,halign: 'center'},
        4: {cellWidth: 34,halign: 'center'},
        5: {cellWidth: 24,halign: 'center'},
        6: {cellWidth: 28,halign: 'right'},
        7: {cellWidth: 28,halign: 'right'},
        8: {cellWidth: 28,halign: 'right'},
        9: {cellWidth: 32,halign: 'right'}},
      rowsStyles:{},
    startY: 60,
    theme: 'grid',
    })
    function ConvertNum(num) {
      return (
        num
          .toFixed(2) // duas casas decimais
          .replace('.', ',') // separadores,
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
      ) // use . as a separador
    }
    let finalY = doc.lastAutoTable.finalY ; 
    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.rect(214, finalY + 5, 70, 5, 'B');
    doc.text('TOTAL Em Aberto', 220, finalY + 8);
    doc.text('' + ConvertNum(totalaberto) + " AOA", 283, finalY + 8, 'right');

    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }

     /*doc.setFontStyle('normal')
    doc.setTextColor(0)
    doc.setFontStyle('bold')
    doc.setDrawColor(0);
    doc.setFillColor(191, 191, 191);
    doc.rect(15, 60, 70, 6, 'FD');
    doc.text('CLIENTEFILHO', 50, 64, 'center');
    
    doc.setFontStyle('normal')
    doc.setTextColor(0)
    doc.setFillColor(191, 191, 191);
    doc.setFontStyle('bold')
    doc.setDrawColor(0);
    doc.rect(85, 60, 52, 6, 'FD');
    doc.text('NUMERO', 112, 64, 'center');
    
    doc.setFontStyle('normal')
    doc.setTextColor(0)
    doc.setFillColor(191, 191, 191);
    doc.setFontStyle('bold')
    doc.setDrawColor(0);
    doc.rect(137, 60, 43, 6, 'FD');
    doc.text('ESTADO', 158, 64, 'center');
    
    doc.setFontStyle('normal')
    doc.setTextColor(0)
    doc.setFillColor(191, 191, 191);
    doc.setFontStyle('bold')
    doc.setDrawColor(0);
    doc.rect(180, 60, 35, 6, 'FD');
    doc.text('TIPO', 195, 64, 'center');
    
    doc.setFontStyle('normal')
    doc.setTextColor(0)
    doc.setFillColor(191, 191, 191);
    doc.setFontStyle('bold')
    doc.setDrawColor(0);
    doc.rect(215, 60, 31, 6, 'FD');
    doc.text('DATA', 230, 64, 'center');
    
    doc.setFontStyle('normal')
    doc.setTextColor(0)
    doc.setFillColor(191, 191, 191);
    doc.setFontStyle('bold')
    doc.setDrawColor(0);
    doc.rect(246, 60, 40, 6, 'FD');
    doc.text('VALOR', 265, 64, 'center');
  
 /*   
   var dados = [
{clientefilhos:"770537962 - Angola Prev, Lda. - Filial de", contacorrente:[{numero:"FT 11909/0012715",estado:'A Pagamento', tipo:'Factura', data:'11/4/2020', valor:'200,246.9'},{numero:"FT 11909/0012715",estado:'A Pagamento', tipo:'Factura', data:'11/4/2020', valor:'200,246.9'}, {numero:"FT 11909/0012715",estado:'A Pagamento', tipo:'Factura', data:'11/4/2020', valor:'200,246.9'}]},
{clientefilhos:"770537960 - Angola Prev, Lda. - Filial de", contacorrente:[{numero:"FR 11909/0012715",estado:'Pago', tipo:'Recibo', data:'11/4/2020', valor:'200,246.9'},{numero:"FT 11909/0012715",estado:'A Pagamento', tipo:'Factura', data:'15/7/2020', valor:'200,246.9'}]},
]
*/
    //
  /*  
    var yRect = 66;
    var yText = 70;
    var total = 0;
    var somaRecibo = 0;
    var somaFactura = 0;
    var somaNotacredito = 0;
    var totalDivida = 0;
    //var ferstRect = 6*data3.length;
    
    //
for(var i=0; i < facturas.length; i++){   
     doc.setFontStyle('normal')
    doc.setTextColor(0)
    doc.setFontStyle('normal')
    doc.setDrawColor(0);
    doc.setFillColor(255);
    doc.rect(15, yRect, 70, 6*facturas[i].contacorrente.length);
    doc.text(''+facturas[i].clientefilhos, 20, yText, { maxWidth: 60 });
     
for(var j=0; j< facturas[i].contacorrente.length; j++){
    doc.setFontStyle('normal')
    doc.setTextColor(0)
    doc.setFillColor(255);
    doc.setFontStyle('normal')
    doc.setDrawColor(0);
    doc.rect(85, yRect, 52, 6);
    doc.text(''+facturas[i].contacorrente[j].numero, 112, yText, 'center');
    
    doc.setFontStyle('normal')
    doc.setTextColor(0)
    doc.setFillColor(255);
    doc.setFontStyle('normal')
    doc.setDrawColor(0);
    doc.rect(137, yRect, 43, 6);
    doc.text(''+(facturas[i].contacorrente[j].sigla=='RC'? 'Pago': (facturas[i].contacorrente[j].estado==0? 'A Pagamento': 'Pago')), 158, yText, 'center');
    
    doc.setFontStyle('normal')
    doc.setTextColor(0)
    doc.setFillColor(255);
    doc.setFontStyle('normal')
    doc.setDrawColor(0);
    doc.rect(180, yRect, 35, 6);
    doc.text(''+facturas[i].contacorrente[j].tipo, 195, yText, 'center');
    
    doc.setFontStyle('normal')
    doc.setTextColor(0)
    doc.setFillColor(255);
    doc.setFontStyle('normal')
    doc.setDrawColor(0);
    doc.rect(215, yRect, 31, 6);
    doc.text(''+facturas[i].contacorrente[j].data, 230, yText, 'center');
    
    doc.setFontStyle('normal')
    doc.setTextColor(0)
    doc.setFillColor(255);
    doc.setFontStyle('normal')
    doc.setDrawColor(0);
    doc.rect(246, yRect, 40, 6);
    doc.text(''+this.numberFormat(facturas[i].contacorrente[j].valor), 284, yText, 'right');
    
      yRect += 6;
      yText += 6;
      

    somaRecibo+= (facturas[i].contacorrente[j].sigla=='RC'? facturas[i].contacorrente[j].valor : 0)
    somaFactura+= (facturas[i].contacorrente[j].sigla=='FT'? facturas[i].contacorrente[j].valor : 0)
    somaNotacredito+= (facturas[i].contacorrente[j].sigla=='NC'? facturas[i].contacorrente[j].valor : 0)

      
     //total+=facturas[i].contacorrente[j].valor
      
      }
      
     if(yRect + 20 > doc.internal.pageSize.height){
        doc.addPage();
       yRect = 30;
       yText = 34;
        

    }
    
  
  
  }

  //console.log(somaFactura)
  //console.log(somaNotacredito)
  //console.log(somaRecibo)
  
  total = ((somaFactura - somaNotacredito) - somaRecibo)
  
   doc.setFontStyle('normal')
    doc.setTextColor(0)
    doc.setFontStyle('bold')
    doc.setDrawColor(0);
    doc.setFillColor(255);
    doc.rect(15, yRect, 70, 6);
    doc.text('Total', 22, yText, 'right');
    
    doc.setFontStyle('normal')
    doc.setTextColor(0)
    doc.setFillColor(255);
    doc.setFontStyle('normal')
    doc.setDrawColor(0);
    doc.rect(85, yRect, 52, 6);
    doc.text('', 110, yText, 'left');
    
    doc.setFontStyle('normal')
    doc.setTextColor(0)
    doc.setFillColor(255);
    doc.setFontStyle('normal')
    doc.setDrawColor(0);
    doc.rect(137, yRect, 43, 6);
    doc.text('', 160, yText, 'left');
    
    doc.setFontStyle('normal')
    doc.setTextColor(0)
    doc.setFillColor(255);
    doc.setFontStyle('normal')
    doc.setDrawColor(0);
    doc.rect(180, yRect, 35, 6);
    doc.text('', 205, yText, 'left');
    
    doc.setFontStyle('normal')
    doc.setTextColor(0)
    doc.setFillColor(255);
    doc.setFontStyle('normal')
    doc.setDrawColor(0);
    doc.rect(215, yRect, 31, 6);
    doc.text('', 230, yText, 'center');
    
    doc.setFontStyle('normal')
    doc.setTextColor(0)
    doc.setFillColor(255);
    doc.setFontStyle('normal')
    doc.setDrawColor(0);
    doc.rect(246, yRect, 40, 6);
    doc.text(''+this.numberFormat(total), 284, yText, 'right');
    */



   if (p === 'save') {
    doc.save('Conta_corrente_'+today+'.pdf');
  } else {
    doc.autoPrint();
    doc.output("dataurlnewwindow");
  }

  }

  public numberFormat(number) {
    return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(number).replace('€', '').trim();
  }

}
