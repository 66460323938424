import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-listar-artigo',
  templateUrl: './listar-artigo.component.html',
  styleUrls: ['./listar-artigo.component.css']
})
export class ListarArtigoComponent implements OnInit {

  constructor() { }

  ngOnInit() {

  }

}
