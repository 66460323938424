import { Injectable } from '@angular/core';
import * as moment from 'moment';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';

@Injectable({
  providedIn: 'root'
})
export class MovimentoCaixaService {

  constructor() { }

  public ReportMovimentoCaixa(table:any,empresa, p) {

   var doc = new jsPDF('landscape');

   doc.setProperties({
     title: 'Movimento de Caixa',
     subject: 'Movimento Caixa',
     author: 'ITGEST',
     creator: 'UNIG'
     });

     doc.setFontSize(8);
     doc.setFont("calibri");
     doc.setTextColor(0);
     doc.text('' + empresa.companyName, 40, 20);
     doc.setTextColor(0);
     doc.text('' + empresa.addressDetail, 40, 25);
     doc.text('NIF: ' + empresa.taxRegistrationNumber, 40, 30);
     doc.text('Email: ' + empresa.email, 40, 35);
     doc.text('Telefone: ', 100, 30);
     doc.text('' + empresa.telefone, 112, 30);
     doc.text('WebSite: ', 100, 35);
     doc.setTextColor(0, 0, 255);
     doc.text('' + (empresa.site == null ? '' : empresa.site), 112, 35);
     doc.addImage(empresa.logotipo, 'JPEG', -4, 5, 40, 40);


      doc.setFontStyle('calibri')
      doc.setFontStyle('bold')
      doc.setTextColor(0)
      //doc.text('Conta Corrente', 280, 34, 'right');

      let currentpage = 0;
      var today =  moment().format("DD-MM-YYYY H:mm:ss");
      doc.setFontSize(15);
      var titulo =  'Lista de Movimentos de Caixa';

      const totalPagesExp = "{total_pages_count_string}";

      doc.text(titulo,120, 51);
      doc.setFontSize(10);
      doc.autoTable({ html: table ,
       didParseCell: function (data) {
       var rows = data.table.body;
       if (data.row.index === 0 ) {
       data.cell.styles.fontStyle = 'bold';
       data.cell.styles.textColor = "white";
       data.cell.styles.fillColor = [0,153,255];
       data.cell.styles.halign = 'center';
       }
       },
       didDrawPage : data => {
       let footerStr = "Página " + doc.internal.getNumberOfPages();
       if (currentpage < doc.internal.getNumberOfPages()) {
         doc.setFontType('calibri')
         footerStr = footerStr + " de " + totalPagesExp;
       }
       doc.setFontType('calibri')
       doc.setFontSize(10);
       //doc.text(versao,267, 200,'left')
       doc.text(footerStr,270, 200)
       doc.text(today, 5, doc.internal.pageSize.height - 10);
       currentpage = doc.internal.getNumberOfPages();
       },
         styles: { textColor: [0, 0, 0] },margin: {bottom : 20, left: 5},
         columnStyles: {
           0: {cellWidth: 15,halign: 'center'},
           1: {cellWidth: 30,halign: 'center'},
           2: {cellWidth: 25,halign: 'center'},
           3: {cellWidth: 20,halign: 'right'},
           4: {cellWidth: 22,halign: 'center'},
           5: {cellWidth: 20,halign: 'right'},
           6: {cellWidth: 22,halign: 'center'},
           7: {cellWidth: 22,halign: 'right'},
           8: {cellWidth: 25,halign: 'right'},
           9: {cellWidth: 20,halign: 'center'},
          10: {cellWidth: 20,halign: 'center'},
          11: {cellWidth: 25,halign: 'center'},
          12: {cellWidth: 20,halign: 'center'}
          },
         rowsStyles:{},
       startY: 60,
       theme: 'grid',

       })

       if (typeof doc.putTotalPages === 'function') {
         doc.putTotalPages(totalPagesExp);
       }
      if (p === 'save') {
       doc.save('Movimentos_Caixa '+today+'.pdf');
     } else {
       doc.autoPrint();
       doc.output("dataurlnewwindow");
     }

     }
}
