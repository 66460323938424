import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { ExcelService } from 'src/app/services/excel.service';

@Component({
  selector: 'app-lista-os-trabalho',
  templateUrl: './lista-os-trabalho.component.html',
  styleUrls: ['./lista-os-trabalho.component.css']
})
export class ListaOsTrabalhoComponent implements OnInit {



  public tipos: any;
  private loading: boolean = false;
  public filters = {
    search: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    viewBy: null, // Ordenação
    pagination: {
      perPage: 25,// Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima, 
      lastPage: null,
      total: null // defini o total de registo da BD
    },
    filter: null, // subelemente do filter
    permission: null, // subelemente do filter
    startDate: null,
    endDate: null
  }

  @Input() ordem_sigla: string = "OT";
  @Input() minusculaTitle: string = "Ordem de Trabalho";
  @Input() maiusculaTitle: string = "ORDEM DE TRABALHO";

  constructor(private auth: AuthService, private http: HttpService, private configService: ConfigService, private excelService: ExcelService) { }

  private items: any = [];
  private agendamentos: any = [];
  private resultados: any = [];
  private responsaveis: any = [];

  private responsavel: string;

  ngOnInit() {
    this.filters.permission = localStorage.getItem('permission');

    this.minusculaTitle = this.filters.permission == 'listagem_ot_comercial' ? 'Ordem de Serviço' : 'Ordem de Trabalho';
    this.maiusculaTitle = this.filters.permission == 'listagem_ot_comercial' ? 'ORDEM DE SERVIÇO' : 'ORDEM DE TRABALHO';

    if(this.filters.permission === 'listagem_ot_comercial'){
      this.ordem_sigla = "OS";
    }else{
      this.ordem_sigla = "OT";
    }
    this.getPageFilterData(1);
    this.getResponsaveis()
  }


  private listar() {

    this.loading = true
    this.http.__call('ordemservico/agendamentoPorResponsavel/listagem', this.filters).subscribe(
      response => {
        this.filters.pagination.lastPage = Object(response).data.lastPage;
        this.filters.pagination.page = Object(response).data.page;
        this.filters.pagination.total = Object(response).data.total;
        this.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.loading = false
      }
    );
  }

  private imprimirListaTrabalho(item) {
    this.configService.imprimirListaTrabalho(item)
  }

  private getResponsaveis() {
    this.http.call_get('ordemservico/responsaveis/selectBox', null).subscribe(
      response => {
        this.responsaveis = Object(response).data;
      }
    );
  }

  private getAgendamentos(item) {
    this.http.__call('ordemservico/agendamentos-by-data-responsavel', {
       inicio_previsao: item.inicio_previsao,
       colaborador_id: item.colaborador_id
    }).subscribe(
      response => {
        this.agendamentos = Object(response).data;

        for(let i=0; i < this.agendamentos.length; i++){
          this.imprimirAgendamento(this.agendamentos[i].id)
        }
      }
    );
  }

  private imprimirAgendamento(id) {
    this.configService.imprimirAgendamento(id, "2ª Via", "imprimir")
  }

  private viewListaTrabalho(item) {
    
    this.maiusculaTitle = this.filters.permission == 'listagem_ot_comercial' ? 'ORDEM DE SERVIÇO' : 'ORDEM DE TRABALHO';

    this.responsavel = item.colaboradorNome;

    this.http.__call('ordemservico/get-report-lista-trabalho', {
      inicio_previsao: item.inicio_previsao,
      colaborador_id: item.colaborador_id
    }).subscribe(
      response => {
        this.resultados = Object(response).data.ordemTrabalho;
      }
    );
  }

  private clearFilters() {
    this.filters = {
      search: null,
      orderBy: null,
      viewBy: null, // Ordenação
      pagination: {
        perPage: 25,
        page: 1,
        lastPage: null,
        total: null
      },
      filter: null,
      permission: null,
      startDate: null,
      endDate: null

    }

    this.getPageFilterData(1);
  }

  getPageFilterData(page: number) {

    if (this.filters.startDate != null && this.filters.endDate != null) {
      if (this.filters.startDate > this.filters.endDate) {
        this.configService.showAlert("Data Início não pode ser superior a Data Fim", 'alert-danger', true);
        return;
      }
    }

    if (this.filters.startDate != null && this.filters.endDate == null) {
      this.configService.showAlert("Digite a Data Fim", 'alert-danger', true);
      return;
    }

    if (this.filters.startDate == null && this.filters.endDate != null) {
      this.configService.showAlert("Digite a Data Início", 'alert-danger', true);
      return;
    }

    if (this.filters.pagination.perPage == null) {
      return;
    }
    this.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
    this.listar();
  }




}
