import { Component, OnInit, Input, SimpleChange, EventEmitter, OnChanges, Output } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReciboService } from 'src/app/components/reports/recibo/recibo.service'
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'
@Component({
  selector: 'app-editar-linha-prestacao',
  templateUrl: './editar-linha-prestacao.component.html',
  styleUrls: ['./editar-linha-prestacao.component.css']
})
export class EditarLinhaPrestacaoComponent implements OnInit {
  @Input() linha: any = null;

  loading = false;
  public linhaPrestacao = {
    id: null,
    valor: null,
    data_inicio: null,
    data_fim: null,
    estado_id: null,
    numero_prestacao: null,
    plano_prestacao_id: null
  }
  constructor(private http: HttpService, private configService: ConfigService, private formBuilder: FormBuilder) { }

  ngOnInit() { }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if (this.linha != undefined) {
      this.linhaPrestacao.id = this.linha.id;
      this.linhaPrestacao.valor = this.linha.valor;
      this.linhaPrestacao.data_inicio = this.linha.data_inicio;
      this.linhaPrestacao.data_fim = this.linha.data_fim;
      this.linhaPrestacao.estado_id = this.linha.estado_id;
      this.linhaPrestacao.numero_prestacao = this.linha.numero_prestacao;
      this.linhaPrestacao.plano_prestacao_id = this.linha.plano_prestacao_id;
    }
  }


  private editar(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (this.linha.valor == null || this.linha.valor == 0) {
      this.configService.showAlert("Upss O Valor da Prestação esta mal Prienchido", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    } else if (this.linha.valor > this.linha.total) {
      this.configService.showAlert("O Valor da Prestação na Pode Ser Superior ao Total da Divida", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    }
    else {
      this.http.__call('actualizar/linha-prestacao/update/' + this.linha.id, this.linhaPrestacao).subscribe(
        res => {
          if (Object(res).code == 201) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            this.configService.showAlert(Object(res).message, 'alert-success', true);


          }
        }
      );
    }
    this.configService.loaddinStarter('stop');
  }






  // convenience getter for easy access to form fields




}
