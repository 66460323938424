import { Injectable, EventEmitter } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from 'src/environments/environment'
import { HttpService } from 'src/app/providers/http/http.service'


@Injectable({
  providedIn: 'root'
})

export class FacturaService {

  private token = sessionStorage.getItem('sessionToken')

  private headers = new HttpHeaders()
    .set('content-type', 'application/json')
    .set('Access-Control-Allow-Origin', '*')
    .set('Authorization', `Bearer ${this.token}`)

  constructor(
    private http: HttpService,
    private _http_client: HttpClient
  ) { }


  //=================================================================================================
  LeituraCicloIndividual(object: any) {
    return this._http_client.post<any>(
      `${environment.app_url}api/${environment.apiVersion}/facturas/leitura-ciclo-individual`,
      object,
      { 'headers': this.headers }
    )
  }

  fcaturarConsumoExcessivoCicloIndividual(object: any) {
    return this._http_client.post<any>(
      `${environment.app_url}api/${environment.apiVersion}/facturas/facturar-consumo-excessivo-ciclo-individual`,
      object,
      { 'headers': this.headers }
    )
  }

  GetChargesCicloIndividual(contrato_id: any) {
    return this._http_client.get<any>(
      `${environment.app_url}api/${environment.apiVersion}/facturas/charges-by-contrato/${contrato_id}`,
      { 'headers': this.headers }
    )
  }

  FacturarCicloIndividual(object: any) {
    return this._http_client.post<any>(
      `${environment.app_url}api/${environment.apiVersion}/facturas/facturar-ciclo-individual`,
      object,
      { 'headers': this.headers }
    )
  }

  ultimaLeitura(id: any) {
    return this._http_client.post<any>(
      `${environment.app_url}api/${environment.apiVersion}/facturas/ultima-leitura/` + id,
      null,
      { 'headers': this.headers }
    )
  }

  validarPreFactura(leituras: any) {
    return this._http_client.post<any>(
      `${environment.app_url}api/${environment.apiVersion}/facturas/validar_pre_facturacao`,
      { leituras: leituras },
      { 'headers': this.headers }
    )
  }

  precessPreFactura(roteiros: any, rota_header_id: number, agendamento_id: number, current_slug: any) {
    return this._http_client.post<any>(
      `${environment.app_url}api/${environment.apiVersion}/facturas/processar_pre_facturacao`,
      {
        roteiros: roteiros,
        rota_header_id: rota_header_id,
        agendamento_id: agendamento_id,
        current_slug: current_slug
      },
      { 'headers': this.headers }
    )
  }

  precessPreFacturaEstimativa(periodo: any, filters: any) {
    return this._http_client.post<any>(
      `${environment.app_url}api/${environment.apiVersion}/facturas/processar_pre_facturacao_estimativas`,
      { periodo, filters },
      { 'headers': this.headers }
    )
  }

  precessPreFacturaAvenca(periodo: any, bairro_id: number) {
    return this._http_client.post<any>(
      `${environment.app_url}api/${environment.apiVersion}/facturas/processar_pre_facturacao_avenca`,
      { periodo, bairro_id },
      { 'headers': this.headers }
    )
  }

  getRoteirosByRota(rota_id: number) {
    return this._http_client.get<any>(
      `${environment.app_url}api/${environment.apiVersion}/facturas/roteirosByRota/${rota_id}`,
      { 'headers': this.headers }
    )
  }

  getRoteirosComTipos(filter: any) {
    return this._http_client.post<any>(
      `${environment.app_url}api/${environment.apiVersion}/facturas/roteirosComTipos`,
      filter,
      { 'headers': this.headers }
    )
  }

  getClientesByBairro(bairro_id: number) {
    return this._http_client.get<any>(
      `${environment.app_url}api/${environment.apiVersion}/facturas/clientesByBairro/${bairro_id}`,
      { 'headers': this.headers }
    )
  }

  getBairros(municipio_id: number) {
    return this._http_client.get<any>(
      `${environment.app_url}api/${environment.apiVersion}/facturas/getBairros/${municipio_id}`,
      { 'headers': this.headers }
    )
  }

  getRotas() {
    return this._http_client.get<any>(
      `${environment.app_url}api/${environment.apiVersion}/facturas/rotas`,
      { 'headers': this.headers }
    )
  }

  getMedicoes() {
    return this._http_client.get<any>(
      `${environment.app_url}api/${environment.apiVersion}/facturas/medicoes`,
      { 'headers': this.headers }
    )
  }

  getMunicipios() {
    return this._http_client.get<any>(
      `${environment.app_url}api/${environment.apiVersion}/facturas/get_municipios`,
      { 'headers': this.headers }
    )
  }

  getCharges() {
    return this._http_client.get<any>(
      `${environment.app_url}api/${environment.apiVersion}/facturas/charges`,
      { 'headers': this.headers }
    )
  }

  updateMediaConsumo(contratos: any) {
    return this._http_client.put<any>(
      `${environment.app_url}api/${environment.apiVersion}/contartos/actualizar-media-consumo`,
      { contratos: contratos },
      { 'headers': this.headers }
    )
  }

  _closeModal(closeModal) {
    var action = document.getElementById(closeModal)
    action.click()
  }

  _openModal(closeModal) {
    var action = document.getElementById(closeModal)
    action.click()
  }

}
