import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { ExcelService } from 'src/app/services/excel.service';
import { AuthService } from 'src/app/providers/auth/auth.service';

import * as moment from 'moment';
import { RotaService } from '../rotas.service';
import { ExcelAutoService } from 'src/app/services/excel/excel-auto.service';
import { RoteiroService } from '../../reports/roteiro/roteiro.service';
import { ModalOrdemServicoService } from '../../ordemservico/modal-create-ordem-trabalho/modal-ordem-servico.service';

@Component({
  selector: 'app-leitura',
  templateUrl: './leitura.component.html',
  styleUrls: ['./leitura.component.css']
})
export class LeituraComponent implements OnInit, OnDestroy {

  @ViewChild("search") search;
  @ViewChild("orderBy") orderBy;
  @ViewChild("searchData") searchData;
  @ViewChild("mostrarResultado") mostrarResultado;

  public currentUser: any;
  private loading: boolean = false;

  private has_rotas_header: boolean = false;

  private rotas_header: any = [];
  private rotaRuns: any = [];
  private DadosEmpresa: any = [];

  private corridasLeiturasArray = [];
  private leituraToProcess: boolean = true;
  private rotas: any[];

  private rota_header = {
    id: null,
    descricao: null,
    data_inicio: null,
    data_fim: null,
    leitor_id: null,
    user_nome: null,
    estado: null,
  }

  quarteiroes: any = []
  ruas: any = []
  distritos: any = []
  bairros: any = []
  municipios: any = [];

  private rotarun = {
    id: null,
    nome_cliente: null,
    rota_header_id: null,
    conta_id: null,
    servico_id: null,
    id_servico: null,
    provincia_id: null,
    municipio_id: null,
    bairro_id: null,
    has_distrito: null,
  }

  private items: any = [];
  checkedAll: boolean = true;
  view_user = false

  leitura: any = {
    id: null,
    motivo_edicao: null,
    motivo_anulacao: null,
    leitura: null,
    ultima_leitura: null,
    data_leitura: null,
    data_ultima_leitura: null,
    nao_leitura: null,
    motivo: null,
    consumo: null,
    contrato_id: null
  }

  motivos_nao_leitura_list: any = []
  motivos_alteracao_leitura_list: any = []


  private current_cliente = {
    cliente: null,
    contrato: null
  }

  leituras: any = []

  constructor(
    private auth: AuthService,
    private http: HttpService,
    private _rotaService: RotaService,
    private flag: ModalOrdemServicoService,
    private configService: ConfigService,
    private reportRoteiro: RoteiroService,
    private excelService: ExcelAutoService,
  ) {

    this._resetFilters();
    this.getPageFilterData(1);
    this.currentUser = this.auth.currentUserValue;
    this._loadAllDataFilter();
    this.getRotas();

    this._rotaService.getMotivosNaoLeitura()
      .subscribe(
        response => {
          this.motivos_nao_leitura_list = response.data
        }
      )
  }

  imprimirPDF(): void {

  }

  //decimalFormat method
  public decimalFormat(number) {
    return number ? number : 0;
  }

  ngOnInit() {
    this.flag.flag = "ANOMALIAS_DE_LEITURAS"
    this.http.filters.search = null
    this.http.filters.orderBy = null
    this.orderBy = null
    console.log(this.orderBy)
    console.log(this.http)
    this.getPageFilterData(1)
    this._rotaService.motivoAnomaliaLeitura()
      .subscribe(
        response => {
          this.motivos_alteracao_leitura_list = Object(response)
        }
      )
  }
  ngOnDestroy() {
    this.http.filters.orderBy = null
  }



  onReset() {
    this.leitura = {
      motivo_edicao: null,
      leitura: null,
      ultima_leitura: null,
      data_leitura: null,
      nao_leitura: null,
      motivo: null
    }
  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      this.http.filters.pagination.page = 1;
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1;
    this.listarLeituras();
    this._resetFilters();
  }

  _resetFilters() {
    this.http.filters.data1 = null;
    this.http.filters.data2 = null;
    this.http.filters.municipio_id = null;
    this.http.filters.distrito_id = null;
    this.http.filters.bairro_id = null;
    this.http.filters.quarteirao_id = null;
    this.http.filters.rua_id = null;
  }

  private listarLeituras() {
    this.loading = true
    this.http.__call('leitura/listagem', { filters: this.http.filters }).subscribe(
      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;
        this.items = Object(response).data.data;
        this.loading = false
      }
    );
  }

  private get leiturasChecked(): any[] {
    return this.items.filter(val => val.checked === true);
  }

  setCheckedAll(checked: boolean) {
    this.checkedAll = checked;
    this.items.forEach(f => f.checked = checked);
  }

  updateChecked(f: any) {
    this.items.forEach(element => {
      element.checked = element.contrato_id === f.contrato_id ? !f.checked : element.checked;
      Object.assign(element);
    });
    const lengthFalse = this.items.filter(val => val.checked === true);
    this.checkedAll = lengthFalse.length === this.items.length ? true : false;
  }

  private async criarOrdemServico() {

    if (this.items === 0) {
      this.configService.showAlert("Nenhuma linha seleccionada", "alert-error", true);
      return;
    }
    if (this.leiturasChecked.length == 1) {
      this.configService.showAlert("Seleccione no minimo duas ou mais linhas", "alert-error", true);
      return;
    }

    this.configService.showAlert("A processar...", "alert-info", true);

    const incumprimentos = this.leiturasChecked;
    //console.log(incumprimentos)
  }

  private listarRotaRunByLeitor() {
    this.http.call_get('rota-header/getRotasHeaderWithRunsByLeitor/' + this.currentUser.user.id, null).subscribe(
      response => {
        this.rotas_header = Object(response).data;
        if (Object(response).data.length > 0) {
          this.has_rotas_header = true;
        }
        else {
          this.has_rotas_header = false;
        }

      }
    );

  }

  private contratoAnomaliaData: any = [];
  private cliente: any = [];

  private setDataContrato(item) {

    this.contratoAnomaliaData = item;


  }

  private listarRotaRunByRotaHeader() {
    this.configService.loaddinStarter('start');
    this.http.call_get('rota-run/getRotasRunPendentesByRotaHeader/' + this.rota_header.id, null).subscribe(
      response => {
        this.rotaRuns = Object(response).data;
        this.configService.loaddinStarter('stop');
      }
    );
  }


  private setNullUser() {
    this.rota_header.leitor_id = null;
    this.rota_header.user_nome = null;
    this.view_user = false;
  }


  private setDataRotaHeader(item: any) {

    for (let index = 0; index < this.rotas_header.length; index++) {

      if (this.rotas_header[index].id == this.rota_header.id) {
        this.rota_header.descricao = this.rotas_header[index].nome;
        this.rota_header.data_inicio = this.rotas_header[index].data_inicio;
        this.rota_header.data_fim = this.rotas_header[index].data_fim;
        this.rota_header.leitor_id = this.rotas_header[index].leitor_id;
      }
    }
  }

  private clearFormInputs() {
    this.rota_header.id = null,
      this.rota_header.descricao = null,
      this.rota_header.data_inicio = null,
      this.rota_header.data_fim = null,
      this.rota_header.leitor_id = null,
      this.rota_header.user_nome = null
  }

  private clearFormRotaRun() {

    this.rotarun.nome_cliente = null,
      this.rotarun.conta_id = null,
      this.rotarun.servico_id = null,
      this.rotarun.id_servico = null
  }

  _update() {

    //====================== VALIDATION ==========================
    if (this.leitura.leitura < 0) {
      this.configService.showAlert('O valor da leitura não deve ser negativo', 'alert-danger', true)
      this.configService.loaddinStarter('stop')
      return
    }

    this._rotaService.updateLeitura(this.leitura)
      .subscribe((response) => {

        this.listarLeituras()
        this._rotaService._closeModal("closeModalUpdateLeitura")

        this.leitura.leitura = {
          id: null,
          leitura: null,
          ultima_leitura: null,
          data_leitura: null,
          nao_leitura: null,
          motivo: null,
          contrato_id: null
        }
      })
  }

  _validate_anomalia() {
    this._rotaService.validateAnomalia(this.leitura)
      .subscribe((response) => {

        this.listarLeituras()
        this._rotaService._closeModal("closeModalUpdateLeitura")

        this.leitura.leitura = {
          id: null,
          leitura: null,
          ultima_leitura: null,
          data_leitura: null,
          nao_leitura: null,
          motivo: null,
          contrato_id: null
        }
      })
  }

  _validate_anomalia_v2() {
    this.http.__call('api/v1/leituras/validate_anomalia', this.leitura)
      .subscribe((response) => {

        this.listarLeituras()
        this._rotaService._closeModal("closeModalUpdateLeitura")
        this.leitura.leitura = {
          id: null,
          leitura: null,
          ultima_leitura: null,
          data_leitura: null,
          nao_leitura: null,
          motivo: null,
          contrato_id: null
        }
      })
  }


  _updateNaoLeituraToLeitura() {
    this.leitura.nao_leitura = false
    this.leitura.motivo = ''

    this._rotaService.updateLeitura(this.leitura)
      .subscribe((response) => {
        this._rotaService._closeModal("closeModalNaoLeitura")
        this._rotaService._closeModal("closeModalUpdateLeitura")
        this.listarLeituras()

        this.leitura = {
          id: null,
          leitura: null,
          ultima_leitura: null,
          data_leitura: null,
          nao_leitura: null,
          motivo: null
        }
      })
  }

  _loadAllDataFilter() {
    this._getMunicipios();
    this.empresaUser();
  }

  _getMunicipios() {
    this.http.__call('municipio/listagems', null)
      .subscribe(response => {
        this.municipios = Object(response).data
      }),
      (error) => {
        if (!error.ok) {
          console.log(error)
        }
      }
  }

  _changeDistrito(municipio_id) {
    this.distritos = [];
    this.bairros = [];
    this.quarteiroes = []
    this.ruas = []
    this._resetFilters()
    if (municipio_id) {
      this._getComuna(municipio_id);
    }
  }
  _changeBairros(distrito_id) {
    this.quarteiroes = []
    if (distrito_id) {
      this._getBairros(distrito_id);
    }
  }

  _changeQuarteirao(bairro_id) {
    this.quarteiroes = []
    if (bairro_id) {
      this._getQuarteirosDoBairro(bairro_id)
    }
  }

  _changeRuas(quarteirao_id) {
    this.ruas = []
    if (quarteirao_id) {
      this._getRuasDoQuarteirao(quarteirao_id)
    }
  }

  _getRuasDoQuarteirao(id) {
    this.http.call_get(`rua/getRuasByQuarteirao/${id}`, null)
      .subscribe(
        response => {
          this.ruas = Object(response).data
        }
      )
  }
  _getBairros(id) {
    this.http.__call('get-bairros/' + id, this.http.filters)
      .subscribe(response => {
        this.bairros = Object(response).data
      }),
      (error) => {
        if (!error.ok) {
          console.log(error)
        }
      }
  }

  _getComuna(id) {
    this.http.__call('get-distritos/' + id, this.http.filters)
      .subscribe(response => {
        this.distritos = Object(response).data
      }),
      (error) => {
        if (!error.ok) {
        }
      }
  }

  _getQuarteirosDoBairro(id) {
    this.http.call_get(`quarteirao/getQuarteiraosByBairro/${id}`, null)
      .subscribe(
        response => {
          this.quarteiroes = Object(response).data
        }
      )
  }

  historicos: any;
  private getLeituraHistorico(leitura_id: any) {
    this.loading = true;
    this.http.call_get("gestao/leitura-historicos/" + leitura_id, null).subscribe(res => {
      this.historicos = Object(res).data;
      this.loading = false;
    });

  }


  public getRotas() {
    this.http.__call('get/rotas', null)
      .subscribe(res => {
        this.rotas = Object(res).data;
      })
  }

  validateDate(event: any) {
    if (moment(event.target.value).format('YYYY-MM-DD') > moment(new Date()).format('YYYY-MM-DD')) {
      this.leitura.data_leitura = null;

      this.configService.showAlert(
        'A data da leitura não deve ser maior que actual.',
        'alert-error', true);
      return;
    }
  }

  setleitura: any;
  _title: any = 'Anular Leitura';
  text_alert: any = 'Tens certeza que desejas anular está leitura ?';

  setLeitura(leitura: any) {
    this._title = leitura.is_annulled ? 'Voltar ao normal' : 'Anular Leitura';
    this.text_alert = leitura.is_annulled ? 'Voltar ao normal' : 'Tens certeza que pretende anular está leitura?';
    this.setleitura = leitura;
  }

  anularleitura() {
    this.loading = true;
    this.http.__call("anulateOrnot-leitura/" + this.setleitura.id, { motivo: this.leitura.motivo_anulacao })
      .subscribe((res) => {
        this.getPageFilterData(1);
        this.loading = false;
      })
  }


  private empresaUser() {
    this.configService.loaddinStarter('start');

    this.http.call_get('empresa/empresa-user', null).subscribe(
      response => {
        this.DadosEmpresa = Object(response).data;
      }
    );
  }

  exportAsPDF(): void {
    var file = document.getElementsByClassName("tablePDF")[0]
    this.reportRoteiro.reportLeiturasInvalidas(file, this.DadosEmpresa, 'save', 'Rotas', this.items.length);
  }


  exportAsXLSXAsHistorico(): void {
    var CurrentDate = new Date();

    var keys = [
      { key: 'leitura', width: 35, style: { font: { name: 'Calibri' } } },
      { key: 'ultima_leitura', width: 25 },
      { key: 'consumo', width: 55 },
      { key: 'numero_serie', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'data_leitura', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'estado', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'created_at', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'leitor', width: 20, style: { font: { name: 'Calibri' } } },
    ];

    var Cols = ["Leitura", "Última Leitura", "Consumo", "Contador", "Data Leitura", "Estado", "Data Registo", "Registado Por"]
    var title = 'Histórico de Leitura';
    var nameFile = "Histórico de Leitura -" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    this.excelService.excels(this.historicos, nameFile, this.DadosEmpresa.logotipo, keys, Cols, title, 5, 8, 30, 3)
  }

  exportAsXLSX(): void {
    var CurrentDate = new Date();

    var keys = [
      { key: 'rota_header', width: 35, style: { font: { name: 'Calibri' } } },
      { key: 'contrato_id', width: 25 },
      { key: 'cliente', width: 30 },
      { key: 'cil', width: 55 },
      { key: 'motivo', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'morada_format', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'numero_serie', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'ultima_leitura', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'leitura', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'consumo', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'data_leitura', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'data_ultima_leitura', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'is_anulada', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'is_facturada', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'tipo_anomalia', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'is_estado', width: 20, style: { font: { name: 'Calibri' } } },
    ];

    var Cols = ["Rota", "Contrato", "Cliente", "Cil", "Motivo", "Morada", "Contador", "Última Leitura", "Leitura", "Consumo", "Data Leitura", "Data Última Leitura", "Anulada?", "Facturada?", "Tipo Anomalia", "Estado"]
    var title = 'Leituras';
    var nameFile = "Lista de Leituras Inválidas -" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    this.excelService.excels(this.items, nameFile, this.DadosEmpresa.logotipo, keys, Cols, title, 5, 16, 30, 3)
  }


  exportAsPDFHistorioConsumo(): void {
    var file = document.getElementsByClassName("tablePDfHistoricoConsumo")[0]
    this.reportRoteiro.reportHistoricoLeituras(file, this.DadosEmpresa, 'save', 'Rotas', this.leituras.length, this.current_cliente);
  }

  exportAsXLSXAsHistoricoConsumo(): void {
    var CurrentDate = new Date();

    var keys = [
      { key: 'cil', width: 35, style: { font: { name: 'Calibri' } } },
      { key: 'morada_format', width: 25 },
      { key: 'ultima_leitura', width: 55 },
      { key: 'leitura', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'consumo', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'is_facturada', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'tipo_facturacao', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'numero_serie', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'data_leitura', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'is_estado', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'data_registo', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'leitor', width: 20, style: { font: { name: 'Calibri' } } },
    ];

    var Cols = ["Cil", "Morada", "Última Leitura", "Leitura", "Consumo", "Facturado?", "Tipo De Facturação", "Contador", "Data de Leitura	", "Estado", "Data Registo	", "Registado Por"]
    var title = 'Histórico de Leitura';
    var nameFile = "Histórico de Leitura -" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    this.excelService.excels(this.leituras, nameFile, this.DadosEmpresa.logotipo, keys, Cols, title, 5, 12, 30, 3)
  }

  setDataLeitura(item) {
    this.leitura.id = item.id
    this.leitura.motivo_edicao = item.motivo_edicao
    this.leitura.contador_id = item.contador_id
    this.leitura.contrato_id = item.contrato_id
    this.leitura.nao_leitura = item.nao_leitura
    this.leitura.motivo = item.motivo
    this.leitura.leitura = item.leitura
    this.leitura.perido = item.perido
    this.leitura.ultima_leitura = item.ultima_leitura
    this.leitura.data_leitura = item.data_leitura;
    this.leitura.data_ultima_leitura = item.data_ultima_leitura
  }

  _getLeiturasByContrato(item: any) {

    this.current_cliente.cliente = item.cliente
    this.current_cliente.contrato = item.contrato_id

    this.loading = true
    this.configService.loaddinStarter('start')

    this._rotaService.getLeiturasByContrato(item.contrato_id)
      .subscribe(
        response => {
          this.leituras = response.data
          this.loading = false
          this.configService.loaddinStarter('stop')
        }
      )
  }

}
