import { Component, OnInit, Input, createPlatformFactory } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { ExcelService } from 'src/app/services/excel.service';
import * as moment from 'moment';
import { ConfigModuloService } from 'src/app/services/config-modulo.service';
import { LocalInstalacaoService } from '../local-instalacao/local-instalacao.service';

@Component({
  selector: 'app-local-instacao-disponiveis',
  templateUrl: './local-instacao-disponiveis.component.html',
  styleUrls: ['./local-instacao-disponiveis.component.css']
})
export class LocalInstacaoDisponiveisComponent implements OnInit {

  private local_instalacao = {
    id: null,
    moradia_numero: null,
    is_predio: null,
    predio_id: null,
    predio_andar: null,
    predio_nome: null,
    cil: null,
    selo_id: null,
    selo: null,
    rua_id: null,
    rua: null,
    bairro_id: null,
    bairro: null,
    has_quarteirao: null,
    quarteirao_id: null,
    quarteirao: null,
    has_distrito: false,
    municipio_id: null,
    municipio: null,
    distrito_id: null,
    distrito: null,
    provincia_id: null,
    provincia: null,
    latitude: null,
    longitude: null,

    ligacao_ramal_id: null,
    ligacao_comprimento: null,
    ligacao_diametro: null,
    ligacao_profundidade: null,

    objecto_ligacao_id: null,
    objecto_ligacao: null,
    objecto_ligacao_latitude: null,
    objecto_ligacao_longitude: null,

    tipo_objecto_id: null,
    tipo_objecto_ligacao: null,
    comprimento: null,
    profundidade: null,

    tipo_ramal_id: null,
    diametro_ramal_id: null,
    material_ramal_id: null,
    estado_ramal_id: null,

    fita_sinalizacao: null,
    valvula_ramal: null,
    latitude_ramal: null,
    longitude_ramal: null,

    instalacao_sanitaria_qtd: null,
    reservatorio_flag: null,
    reservatorio_capacidade: null,
    piscina_flag: null,
    piscina_capacidade: null,
    jardim_flag: null,
    campo_jardim_id: null,
    campo_jardim: null,
    poco_alternativo_flag: null,
    fossa_flag: null,
    fossa_capacidade: null,
    acesso_camiao_flag: null,
    anexo_flag: null,
    anexo_quantidade: null,
    caixa_contador_flag: null,
    estado_caixa_contador_id: null,
    abastecimento_cil_id: null,
    abastecimento_cil: null,
    calibre_id: null,
    saneamento_flag: null,

    is_active: null,
    user_id: null,
    user: null,

  };

  private detalhe_historico = {
    id: null,
    operacao: null,
    alocacao: null
  };

  public filters = {
    search: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    direction: null,
    municipio_id: null,
    bairro_id: null,
    quarteiraoId: null,
    distrito_id: null,
    motivo_id: null,
    rota_header_id: null,
    leitor_id: null,
    nao_leitura: null,
    status: '1',
    pagination: {
      perPage: 5,// Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima,
      lastPage: null,
      total: null // defini o total de registo da BD
    },
    filter: null // subelemente do filter
  }
  private filter = {
    municipio: null,
    distrito: null,
    bairro: null,
    status: null,
    local_instalacao_flag: 'T'
  }

  private detalheProps: any;

  @Input() ruas: any[];
  @Input() quarteiraos: any[];
  @Input() bairros: any[];
  @Input() distritos: any[];
  @Input() municipios: any = [];

  _bairros: any[];
  _distritos: any[];
  _municipios: any = [];


  private _empty = '-------';

  private ligacao_local_instalacaos: any = [];
  private local_instalacao_historico: any = [];

  private addRows: boolean = true;
  private title: string = "Registar Local de Instalação";

  private items: any = [];
  private local_instalacaos: any = [];


  constructor(
    private http: HttpService,
    private configService: ConfigService,
    private excelService: ExcelService,
    private config: ConfigModuloService,
    private localInstalService: LocalInstalacaoService
  ) { }



  exportAsXLSX(): void {
    var CurrentDate = new Date();
    var nameFile = "lista_local_instalacaos -" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    this.excelService.exportAsExcelFile(document.getElementsByClassName("exportAsXLSXCliente")[0], nameFile);
  }

  exportAsPDF(): void {
    //this.reportLoja.relatorioLoja(this.cobrancas, this.simpleForm.value, 'save');
  }

  imprimirPDF(): void {
    //this.reportLoja.relatorioLoja(this.cobrancas, this.simpleForm.value);
  }

  ngOnInit() {
    this.getPageFilterData(1);
    this.getMunicipio()
  }

  private getDadosModal() {

  }

  private getMunicipio() {
    if (this.filter.municipio == null) {
      this.getLocalInstalacaos()
      this.filter.bairro = null
      this.filter.distrito = null
    }
    this._distritos = []
    this.http.__call('municipio/listagems', null).subscribe(
      response => {
        this._municipios = Object(response).data;
      }
    );
  }
  private getDistrito(id) {

    this._bairros = []
    this.http.call_get(`distrito/selectBoxByMunicipio/${id}`, null).subscribe(
      response => {
        this._distritos = Object(response);
        console.log(this.distritos)
      }
    );
  }
  private getBairro(id) {
    this.http.call_get(`bairro/selectBoxByDistrito/${id}`, null).subscribe(
      response => {
        this._bairros = Object(response);
        console.log(this._bairros)
      }
    );
  }

  public getLocalInstalacaos() {

    this.configService.loaddinStarter('start');
    this.filters.filter = this.filter;
    this.localInstalService.localInstalacaoListagemDisponivel(this.filters).subscribe(

      response => {
        this.filters.pagination.lastPage = Object(response).data.lastPage;
        this.filters.pagination.page = Object(response).data.page;
        this.filters.pagination.total = Object(response).data.total;
        this.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.configService.loaddinStarter('stop');

      }
    );
  }

  private initLocalInstalacao() {

    this.local_instalacao.id = null;
    this.local_instalacao.predio_nome = null;
    this.local_instalacao.rua_id = null;

    this.local_instalacao.bairro_id = null;
    this.local_instalacao.has_distrito = null;
    this.local_instalacao.distrito_id = null;
    this.local_instalacao.municipio_id = null;
    this.local_instalacao.provincia_id = null;
    this.local_instalacao.is_active = null;
    this.local_instalacao.user_id = null;

    this.distritos = null;
    this.municipios = null;
    this.bairros = null;
    this.ruas = null;

    this.addRows = true;
  }

  private setDataLocalInstalacao(item) {
    console.log(item)
    this.local_instalacao.id = item.id;
    this.local_instalacao.abastecimento_cil_id = item.abastecimento_cil_id;
    this.local_instalacao.acesso_camiao_flag = item.acesso_camiao_flag;
    this.local_instalacao.anexo_flag = item.anexo_flag;
    this.local_instalacao.anexo_quantidade = item.anexo_quantidade;
    this.local_instalacao.bairro = item.bairro;
    this.local_instalacao.bairro_id = item.bairro_id
    this.local_instalacao.caixa_contador_flag = item.caixa_contador_flag;
    this.local_instalacao.calibre_id = item.calibre_id;
    this.local_instalacao.campo_jardim_id = item.campo_jardim_id;
    this.local_instalacao.cil = item.cil;
    this.local_instalacao.selo_id = item.selo_id;
    this.local_instalacao.selo = item.selo;
    this.local_instalacao.distrito = item.distrito;
    this.local_instalacao.distrito_id = item.distrito_id;
    this.local_instalacao.estado_caixa_contador_id = item.estado_caixa_contador_id;
    this.local_instalacao.fossa_capacidade = item.fossa_capacidade;
    this.local_instalacao.fossa_flag = item.fossa_flag;
    this.local_instalacao.instalacao_sanitaria_qtd = item.instalacao_sanitaria_qtd;
    this.local_instalacao.jardim_flag = item.jardim_flag;
    this.local_instalacao.latitude = item.latitude;
    this.local_instalacao.ligacao_comprimento = item.ligacao_comprimento
    this.local_instalacao.ligacao_diametro = item.ligacao_diametro;
    this.local_instalacao.ligacao_profundidade = item.ligacao_profundidade;
    this.local_instalacao.longitude = item.longitude;
    this.local_instalacao.moradia_numero = item.moradia_numero;
    this.local_instalacao.municipio = item.municipio;
    this.local_instalacao.municipio_id = item.municipio_id;
    this.local_instalacao.objecto_ligacao = item.objecto_ligacao;
    this.local_instalacao.objecto_ligacao_id = item.objecto_ligacao_id;
    this.local_instalacao.objecto_ligacao_latitude = item.objecto_ligacao_latitude;
    this.local_instalacao.objecto_ligacao_longitude = item.objecto_ligacao_longitude;
    this.local_instalacao.piscina_capacidade = item.piscina_capacidade;
    this.local_instalacao.piscina_flag = item.piscina_flag;
    this.local_instalacao.poco_alternativo_flag = item.poco_alternativo_flag;
    this.local_instalacao.predio_andar = item.predio_andar;
    this.local_instalacao.predio_id = item.predio_id;
    this.local_instalacao.predio_nome = item.predio_nome;
    this.local_instalacao.provincia = item.provincia;
    this.local_instalacao.provincia_id = item.provincia_id;
    this.local_instalacao.quarteirao = item.quarteirao;
    this.local_instalacao.quarteirao_id = item.quarteirao_id;
    this.local_instalacao.reservatorio_capacidade = item.reservatorio_capacidade;
    this.local_instalacao.reservatorio_flag = item.reservatorio_flag;
    this.local_instalacao.rua = item.rua;
    this.local_instalacao.rua_id = item.rua_id;
    this.local_instalacao.saneamento_flag = item.saneamento_flag;
    this.local_instalacao.tipo_objecto_ligacao = item.tipo_objecto_ligacao;
    this.local_instalacao.user = item.user;
    this.local_instalacao.user_id = item.user_id;
    this.local_instalacao.id = item.id;
    this.local_instalacao.is_predio = item.is_predio;
    this.local_instalacao.cil = item.cil;
    this.local_instalacao.rua_id = item.rua_id;
    this.local_instalacao.bairro_id = item.bairro_id;
    this.local_instalacao.has_distrito = (item.distrito_id > 0) ? true : false;
    this.local_instalacao.distrito_id = item.distrito_id;
    this.local_instalacao.municipio_id = item.municipio_id;
    this.local_instalacao.is_active = item.is_active;

    this.setDataToEditLocalInstalacao();
  }

  private setDataToEditLocalInstalacao() {


    if (this.local_instalacao.id > 0) this.title = "Editar Local de Instalação";

    if (this.local_instalacao.has_distrito) this.selectBoxDistritosByMunicipio(this.local_instalacao.municipio_id);

    this.selectBoxMunicipiosByProvincia(this.local_instalacao.provincia_id);
    this.selectBoxBairrosByMunicipio(this.local_instalacao.municipio_id);
    this.selectBoxDistritosByMunicipio(this.local_instalacao.municipio_id);
    this.selectBoxQuarteiraosByBairro(this.local_instalacao.bairro_id);
    this.selectBoxRuasByQuarteirao(this.local_instalacao.quarteirao_id);

    this.addRows = false;

  }



  private getDetalheHistorico(item) {

    this.detalhe_historico = {
      id: item.id,
      operacao: item.operacao,
      alocacao: this.getAlocacao(JSON.parse(item.actualizacao))
    }

    this.detalheProps = this.getPropriedadeDetalhe(JSON.parse(item.historico), JSON.parse(item.actualizacao));

    //console.log(item);
  }

  private getPropriedadeDetalhe(historico, actualizacao) {

    var detalhe: any = [];

    //console.log(historico);

    detalhe.push(this.getDadosPropriedade('Alocação', ((historico != null) ? this.getAlocacao(historico) : this._empty), this.getAlocacao(actualizacao)));

    detalhe.push(this.getDadosPropriedade('Estado', ((historico != null) ? this.getEstadoLabel(historico.is_active) : this._empty), this.getEstadoLabel(actualizacao.is_active)));
    detalhe.push(this.getDadosPropriedade('Latitude', ((historico != null) ? this.validatePropValue(historico.latitude) : this._empty), this.validatePropValue(actualizacao.latitude)));
    detalhe.push(this.getDadosPropriedade('Longitude', ((historico != null) ? this.validatePropValue(historico.longitude) : this._empty), this.validatePropValue(actualizacao.longitude)));
    detalhe.push(this.getDadosPropriedade('Saneamento', ((historico != null) ? this.getFlagLabel(historico.saneamento_flag) : this._empty), this.getFlagLabel(actualizacao.saneamento_flag)));
    detalhe.push(this.getDadosPropriedade('Instalações Sanitárias (Qtd)', ((historico != null) ? this.validatePropValue(historico.instalacao_sanitaria_qtd) : this._empty), this.validatePropValue(actualizacao.instalacao_sanitaria_qtd)));
    detalhe.push(this.getDadosPropriedade('Reservatório', ((historico != null) ? this.getFlagLabel(historico.reservatorio_flag) : this._empty), this.getFlagLabel(actualizacao.reservatorio_flag)));
    detalhe.push(this.getDadosPropriedade('Capacidade do Reservatório', ((historico != null) ? this.validatePropValue(historico.reservatorio_capacidade) : this._empty), this.validatePropValue(actualizacao.reservatorio_capacidade)));
    detalhe.push(this.getDadosPropriedade('Piscina', ((historico != null) ? this.getFlagLabel(historico.piscina_flag) : this._empty), this.getFlagLabel(actualizacao.piscina_flag)));
    detalhe.push(this.getDadosPropriedade('Capacidade da Piscina', ((historico != null) ? this.validatePropValue(historico.piscina_capacidade) : this._empty), this.validatePropValue(actualizacao.piscina_capacidade)));
    detalhe.push(this.getDadosPropriedade('Jardim', ((historico != null) ? this.getFlagLabel(historico.jardim_flag) : this._empty), this.getFlagLabel(actualizacao.jardim_flag)));
    detalhe.push(this.getDadosPropriedade('Campo do Jardim', ((historico != null) ? this.validatePropValue(historico.campo_jardim) : this._empty), this.validatePropValue(actualizacao.campo_jardim)));
    detalhe.push(this.getDadosPropriedade('Poço Alternativo', ((historico != null) ? this.getFlagLabel(historico.poco_alternativo_flag) : this._empty), this.getFlagLabel(actualizacao.poco_alternativo_flag)));
    detalhe.push(this.getDadosPropriedade('Fossa', ((historico != null) ? this.getFlagLabel(historico.fossa_flag) : this._empty), this.getFlagLabel(actualizacao.fossa_flag)));
    detalhe.push(this.getDadosPropriedade('Capacidade da Fossa', ((historico != null) ? this.validatePropValue(historico.fossa_capacidade) : this._empty), this.validatePropValue(actualizacao.fossa_capacidade)));
    detalhe.push(this.getDadosPropriedade('Acesso de Camião', ((historico != null) ? this.getFlagLabel(historico.acesso_camiao_flag) : this._empty), this.getFlagLabel(actualizacao.acesso_camiao_flag)));
    detalhe.push(this.getDadosPropriedade('Anexo', ((historico != null) ? this.getFlagLabel(historico.anexo_flag) : this._empty), this.getFlagLabel(actualizacao.anexo_flag)));
    detalhe.push(this.getDadosPropriedade('Anexo (Qtd)', ((historico != null) ? this.validatePropValue(historico.anexo_quantidade) : this._empty), this.validatePropValue(actualizacao.anexo_quantidade)));
    detalhe.push(this.getDadosPropriedade('Caixa de Contador', ((historico != null) ? this.getFlagLabel(historico.caixa_contador_flag) : this._empty), this.getFlagLabel(actualizacao.caixa_contador_flag)));
    detalhe.push(this.getDadosPropriedade('Estado da Caixa', ((historico != null) ? this.validatePropValue(historico.estado_caixa_contador) : this._empty), this.validatePropValue(actualizacao.estado_caixa_contador)));
    detalhe.push(this.getDadosPropriedade('Tipo de Abastecimento', ((historico != null) ? this.validatePropValue(historico.abastecimento_cil) : this._empty), this.validatePropValue(actualizacao.abastecimento_cil)));
    //detalhe.push(this.getDadosPropriedade('Calibre', ((historico != null) ? this.validatePropValue(historico.calibre) : this._empty), this.validatePropValue(actualizacao.calibre)));
    //detalhe.push(this.getDadosPropriedade('Diâmetro da Ligação', ((historico != null) ? this.validatePropValue(historico.ligacao_diametro) : this._empty), this.validatePropValue(actualizacao.ligacao_diametro)));
    //detalhe.push(this.getDadosPropriedade('Comprimento da Ligação', ((historico != null) ? this.validatePropValue(historico.ligacao_comprimento) : this._empty), this.validatePropValue(actualizacao.ligacao_comprimento)));
    //detalhe.push(this.getDadosPropriedade('Profundidade da Ligação', ((historico != null) ? this.validatePropValue(historico.ligacao_profundidade) : this._empty), this.validatePropValue(actualizacao.ligacao_profundidade)));
    //detalhe.push(this.getDadosPropriedade('Objecto de Ligação', ((historico != null) ? this.validatePropValue(historico.objecto_ligacao) : this._empty), this.validatePropValue(actualizacao.objecto_ligacao)));
    //detalhe.push(this.getDadosPropriedade('Latitude do Objecto de Ligação', ((historico != null) ? this.validatePropValue(historico.objecto_ligacao_latitude) : this._empty), this.validatePropValue(actualizacao.objecto_ligacao_latitude)));
    //detalhe.push(this.getDadosPropriedade('Longitude do Objecto de Ligação', ((historico != null) ? this.validatePropValue(historico.objecto_ligacao_longitude) : this._empty), this.validatePropValue(actualizacao.objecto_ligacao_longitude)));
    //detalhe.push(this.getDadosPropriedade('Tipo de Ligação', ((historico != null) ? this.validatePropValue(historico.tipo_objecto_ligacao) : this._empty), this.validatePropValue(actualizacao.tipo_objecto_ligacao)));
    detalhe.push(this.getDadosPropriedade('rua', ((historico != null) ? this.validatePropValue(historico.rua) : this._empty), this.validatePropValue(actualizacao.rua)));
    detalhe.push(this.getDadosPropriedade('Quarteirão', ((historico != null) ? this.validatePropValue(historico.quarteirao) : this._empty), this.validatePropValue(actualizacao.quarteirao)));
    detalhe.push(this.getDadosPropriedade('Bairro/Zona', ((historico != null) ? this.validatePropValue(historico.bairro) : this._empty), this.validatePropValue(actualizacao.bairro)));
    detalhe.push(this.getDadosPropriedade('Comuna/Dist. Urbano/Localidade', ((historico != null) ? this.validatePropValue(historico.distrito) : this._empty), this.validatePropValue(actualizacao.distrito)));
    detalhe.push(this.getDadosPropriedade('Munícipio', ((historico != null) ? this.validatePropValue(historico.municipio) : this._empty), this.validatePropValue(actualizacao.municipio)));
    detalhe.push(this.getDadosPropriedade('Província', ((historico != null) ? this.validatePropValue(historico.provincia) : this._empty), this.validatePropValue(actualizacao.provincia)));
    detalhe.push(this.getDadosPropriedade('Utilizador', ((historico != null) ? this.validatePropValue(historico.user) : this._empty), this.validatePropValue(actualizacao.user)));
    //console.log(detalhe)

    return detalhe;

  }

  private validatePropValue(item) {
    return ((item != null) ? item : this._empty);
  }

  private getEstadoLabel(item) {
    return ((item) ? 'Activo' : 'Desactivo');
  }

  private getFlagLabel(item) {
    return ((item) ? 'Sim' : 'Não');
  }



  private getAlocacao(item) {

    return ((item.cil != null) ? '[' + item.cil + '] ' : '[0000-000-000] ') + ((item.is_predio) ? 'Prédio ' + item.predio_nome + ', ' + this.configService.decimalFormat(item.predio_andar) + 'º Andar - Porta ' : 'Residência ') + this.configService.decimalFormat(item.moradia_numero);

  }

  private getDadosPropriedade(label, item_historico, item_actualizacao) {

    let _label: string = "<div class='col-sm-4'><strong>" + label + "</strong></div>";

    if (item_historico != this._empty && item_historico != item_actualizacao) {
      return _label + "<div class='col-sm-4 text-right'><span class='badge badge-danger'>" + item_historico + "</span></div>"
        + "<div class='col-sm-4'><span class='badge badge-success'>" + item_actualizacao + "</span></div>";
    }

    return _label + "<div class='col-sm-4 text-right'>" + item_historico + "</div>"
      + "<div class='col-sm-4'>" + item_actualizacao + "</div>";
  }



  private updateStateLocalInstalacao(item) {

    this.local_instalacao.id = item.id;
    this.local_instalacao.predio_nome = item.predio_nome;

    this.local_instalacao.rua_id = item.rua_id;
    this.local_instalacao.bairro_id = item.bairro_id;
    this.local_instalacao.has_distrito = item.has_distrito;
    this.local_instalacao.distrito_id = item.distrito_id;
    this.local_instalacao.municipio_id = item.municipio_id;
    this.local_instalacao.provincia_id = item.provincia_id;
    this.local_instalacao.is_active = !item.is_active;
    this.local_instalacao.user_id = item.user_id;

    //console.log(item);
    this.http.__call('local-instalacao/update/' + this.local_instalacao.id, this.local_instalacao).subscribe(
      response => {

        if (Object(response).code == 200) {
          var update = (this.local_instalacao.is_active == true) ? "Activado" : "Desactivado";

          this.configService.showAlert("Local de Instalação " + this.local_instalacao.predio_nome + " foi " + update, "alert-success", true);
        }

      }
    );

    for (let i = 0; i < this.items.length; ++i) {
      if (this.items[i].id == this.local_instalacao.id) {
        this.items[i].is_active = this.local_instalacao.is_active;
      }
    }
  }


  private getLigacoesFinaisByIdLocalInstacao(item) {

    //console.log(item);
    this.http.__call('local-instalacao/getLigacoesFinaisByIdLocalInstacao/' + item.id, null).subscribe(
      response => {

        if (Object(response).code == 200) {
          this.ligacao_local_instalacaos = Object(response).data;
        }

      }
    );

  }


  private selectBoxQuarteiraosByBairro(id) {

    if (this.local_instalacao.has_quarteirao) {
      this.http.call_get('quarteirao/getQuarteiraosByBairro/' + id, null).subscribe(
        response => {
          //console.log(Object(response).data);

          this.quarteiraos = Object(response).data;
        }
      );
    }
  }

  private selectBoxRuasByBairro(id) {

    //console.log(this.local_instalacao);

    this.http.call_get('rua/getRuasByBairro/' + id, null).subscribe(
      response => {
        //console.log(Object(response).data);

        this.ruas = Object(response).data;
      }
    );
  }


  private selectBoxRuasByQuarteirao(id) {

    //console.log(this.local_instalacao);

    this.http.call_get('rua/getRuasByQuarteirao/' + id, null).subscribe(
      response => {
        //console.log(Object(response).data);

        this.ruas = Object(response).data;
      }
    );
  }




  private getHistoricoById(id) {

    //console.log(this.local_instalacao);

    this.http.call_get('local-instalacao-historico/getHistoricoById/' + id, null).subscribe(
      response => {
        //console.log(Object(response));

        this.local_instalacao_historico = Object(response);
      }
    );
  }

  private selectBoxDistritosByMunicipio(id) {

    //console.log(this.local_instalacao);

    this.http.call_get('distrito/getDistritosByMunicipio/' + id, null).subscribe(
      response => {
        console.log(Object(response).data);

        this.distritos = Object(response).data;
      }
    );
  }

  private selectBoxBairrosByMunicipio(id) {

    //console.log(this.local_instalacao);

    this.http.call_get('bairro/getBairrosByMunicipio/' + id, null).subscribe(
      response => {
        //console.log(Object(response).data);

        this.bairros = Object(response).data;
      }
    );
  }

  private selectBoxMunicipiosByProvincia(id) {

    //console.log(this.local_instalacao);

    this.http.call_get('municipio/getMunicipiosByProvincia/' + id, null).subscribe(
      response => {
        //console.log(Object(response).data);

        this.municipios = Object(response).data;
      }
    );
  }


  getPageFilterData(page: number) {
    if (this.filters.pagination.perPage == null) {
      return;
    }
    this.filter.municipio = null
    this.filter.distrito = null
    this.filter.bairro = null
    this.filters.pagination.page = Number.isInteger(page) ? page : 1;
    this.getLocalInstalacaos();
  }

  private getLocalInstalacaosByLocalInstalacao(id) {

    this.configService.loaddinStarter('start');

    this.http.call_get('local-instalacao/getResidenciasByResidencia/' + id, this.filters).subscribe(

      response => {

        this.local_instalacaos = Object(response).data.data;
        this.configService.loaddinStarter('stop');

      }
    );
  }


  public getConfiguracaos() {

    let result = null;

    const slugs = [
      this.config.provincia_default
    ];

    for (let index = 0; index < slugs.length; index++) {

      //console.log(slugs[index]);
      this.http.__call('configuracao/getConfiguracaobySlug/' + slugs[index], null).subscribe(
        response => {

          //console.log(Object(response));

          if (Object(response).code != 200) {
            //this.config.saveConfig(slugs[index], this.config.modulo.CONFIGURACOES, null);
            result = null;
          }
          else {

            result = Object(response).data;

            if (slugs[index] == this.config.provincia_default) {
              this.local_instalacao.provincia_id = result.valor;
              this.selectBoxMunicipiosByProvincia(result.valor);
            }
          }
        });
    }
  }

}
