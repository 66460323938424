import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

@Component({​
    selector: 'app-conf-tipos-tipologia',
    templateUrl: './conf-tipos-tipologia.component.html',
    styleUrls: ['./conf-tipos-tipologia.component.css']
  }​)
  export class ConfTiposTipologiaComponent implements OnInit {​

  private tipostipologia = {
    id: null,
    descricao: null,
    slug: null, 
    tipologia_id: null
  }


  private items: any = [];
  private tipologia: any = [];

  constructor(private http: HttpService, private configService: ConfigService) { }

  ngOnInit() {
    this.getPageFilterData(1);
    this.listarTipologiaCliente();
  }


  private listatipostipologia() {

    this.configService.loaddinStarter('start');

    this.http.__call('tipos-tipologia/listagem', this.http.filters).subscribe(

      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.configService.loaddinStarter('stop');

      }
    );
  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
    this.listatipostipologia();
  }


  private register(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (this.tipostipologia.descricao == "" || this.tipostipologia.slug == null || this.tipostipologia.slug == null) {
      this.configService.showAlert("Os campos Descrição e Slug são obrigatórios", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    } else {
    this.http.__call('tipos-tipologia/create', this.tipostipologia).subscribe(
      res => {
        if (Object(res).code == 201) {
          this.configService.showAlert(Object(res).message, 'alert-danger', true);
          this.configService.loaddinStarter('stop');
        } else {
          this.configService.showAlert(Object(res).message, 'alert-success', true);
          this.clearFormInputs(e);
          this.listatipostipologia()
          this.configService.loaddinStarter('stop');
        }
      }
    )
  };
}


  private clearFormInputs(e) {
    e.target.elements[0].value = null;
    e.target.elements[1].value = null;
    e.target.elements[2].value = null;
    e.target.elements[3].value = null;
  }

  private refresh(id, descricao, slug, tipologia_id) {
    this.tipostipologia.id = id;
    this.tipostipologia.descricao = descricao;
    this.tipostipologia.slug = slug;
    this.tipostipologia.tipologia_id = tipologia_id;
  }

  private editar(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (this.tipostipologia.descricao == "" ) {
      this.configService.showAlert("O campo Descrição são obrigatórios", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    } else {
      this.http.__call('tipos-tipologia/update/' + this.tipostipologia.id, this.tipostipologia).subscribe(
        res => {
          if (Object(res).code == 201) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            //this.configService.clearFormInputs(e);
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.listatipostipologia();

          }
        }
      );
    }
    this.configService.loaddinStarter('stop');
  }

  private listarTipologiaCliente() {
    this.http.call_get('tipologia-cliente/selectBox', null).subscribe(
      response => {
        this.tipologia = Object(response);
      }
    );
  }

  private ini() {
    this.tipostipologia = {
      id: null,
      descricao: null,
      slug: null,
      tipologia_id: null
    }
  }

}
