import { Component, OnInit, Input, createPlatformFactory, OnDestroy } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { ExcelService } from 'src/app/services/excel.service';
import * as moment from 'moment';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-tipo-ocorrencia',
  templateUrl: './tipo-ocorrencia.component.html',
  styleUrls: ['./tipo-ocorrencia.component.css']
})
export class TipoOcorrenciaComponent implements OnInit,OnDestroy {

  @Input() simpleFormTipoOcorrencia: FormGroup;

  private tipo_ocorrencia = {
    id: null,
    nome: null,
    is_active: null,
    user_id: null
  };

  public currentUser: any;

  private title: string = "Registar Tipo Ocorrência";

  private input_default: boolean = false;
  private input_required: boolean = false;

  private items: any = [];
  private modulos: any = [];
  /*  private estados: any = []; */

  constructor(private http: HttpService, private configService: ConfigService, private excelService: ExcelService, private auth: AuthService) {
    this.currentUser = this.auth.currentUserValue;
  }



  exportAsXLSX(): void {
    var CurrentDate = new Date();
    var nameFile = "lista_configuracoes -" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    this.excelService.exportAsExcelFile(document.getElementsByClassName("exportAsXLSXCliente")[0], nameFile);
  }
  exportAsPDF(): void {
    //this.reportLoja.relatorioLoja(this.cobrancas, this.simpleForm.value, 'save');
  }

  imprimirPDF(): void {
    //this.reportLoja.relatorioLoja(this.cobrancas, this.simpleForm.value);
  }

  ngOnInit() {
    this.http.filters.search = null
    this.getPageFilterData(1);
  }
  ngOnDestroy(){
    this.http.filters.orderBy = null
  }

  private getDadosModal() {

  }

  private getTipoOcorrencias() {

    this.configService.loaddinStarter('start');

    this.http.__call('tipo-ocorrencia/listagem', this.http.filters).subscribe(

      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.configService.loaddinStarter('stop');

      }
    );
  }


  view_tipo_ocorrencia = false;

  private saveTipoOcorrencia() {
    console.log(this.tipo_ocorrencia);

    if (this.tipo_ocorrencia.id == null) {

      this.http.__call('tipo-ocorrencia/create', {
        nome: this.tipo_ocorrencia.nome,
        user_id: this.currentUser.user.id
      }).subscribe(
        res => {
          if (Object(res).code == 200) {
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            /*    this.clearFormInputs();
               this.listarRotaRunByRotaHeader(); */

              this.getTipoOcorrencias();

            this.configService.loaddinStarter('stop');
          } else {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
            this.configService.loaddinStarter('stop');
          }
        }
      )
    }
    else {

      this.http.__call('tipo-ocorrencia/update/' + this.tipo_ocorrencia.id, {
        nome: this.tipo_ocorrencia.nome,
        user_id: this.currentUser.user.id
      }).subscribe(
        res => {
          if (Object(res).code == 200) {
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            /*    this.clearFormInputs();
               this.listarRotaRunByRotaHeader(); */

               this.getTipoOcorrencias();

              this.configService.loaddinStarter('stop');
          } else {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
            this.configService.loaddinStarter('stop');
          }
        }
      )
    }

  }

  private onReset() {

    this.tipo_ocorrencia.nome = null;
  }


  private actualizarEstadoTipoOcorrencia(item) {

    this.tipo_ocorrencia.id = item.id;
    this.tipo_ocorrencia.nome = item.nome;
    this.tipo_ocorrencia.is_active = !item.is_active;
    this.tipo_ocorrencia.user_id = item.user_id;

    //console.log(item);
    this.http.__call('tipo-ocorrencia/update/' + this.tipo_ocorrencia.id, this.tipo_ocorrencia).subscribe(
      response => {

        if (Object(response).code == 200) {
          var update = (this.tipo_ocorrencia.is_active == true) ? "Activado" : "Desactivado";

          this.configService.showAlert("Tipo Ocorrência " + this.tipo_ocorrencia.nome + " foi " + update, "alert-success", true);
        }

      }
    );

    for (let i = 0; i < this.items.length; ++i) {
      if (this.items[i].id == this.tipo_ocorrencia.id) {
        this.items[i].is_active = this.tipo_ocorrencia.is_active;
      }
    }
  }


  private setDataTipoOcorrencia(item) {

    if (item !== undefined) {
      this.title = "Editar Tipo Ocorrência";

      this.tipo_ocorrencia.id = item.id;
      this.tipo_ocorrencia.nome = item.nome;
      this.tipo_ocorrencia.is_active = item.is_active;
      this.tipo_ocorrencia.user_id = item.user_id;
    }
  }


  private getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
    this.getTipoOcorrencias();
  }

  private setDefault() {
    this.input_default = !this.input_default;
  }

  private setRequired() {
    this.input_required = !this.input_required;
  }

}
