import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

import { ExcelService } from 'src/app/services/excel.service';
import * as moment from 'moment';

@Component({
  selector: 'app-tipos-pedido',
  templateUrl: './tipos-pedido.component.html',
  styleUrls: ['./tipos-pedido.component.css']
})
export class TiposPedidoComponent implements OnInit {
  
  
  private loading: boolean = false;

  private tipoPedido = {
    id: null,
    slug: null,
    descricao: null,
    rota_crm_inicial: null
  }


  private items: any = [];

  constructor(private http: HttpService, private configService: ConfigService,private excelService: ExcelService) { }

  ngOnInit() {
    this.getPageFilterData(1);
  }

  exportAsXLSX(): void {
    var CurrentDate = new Date();
    var nameFile = "Lista_tipos_Pedidos-" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    this.excelService.exportAsExcelFile(document.getElementsByClassName("exportAsXLSXCliente")[0], nameFile);
  }
  exportAsPDF(): void {
    //this.reportLoja.relatorioLoja(this.cobrancas, this.simpleForm.value, 'save');
  }

  imprimirPDF(): void {
    //this.reportLoja.relatorioLoja(this.cobrancas, this.simpleForm.value);
  }

  private tiposPedidos() {
    this.loading = true
    this.configService.loaddinStarter('start');

    this.http.__call('tipos/pedidos/listagem', this.http.filters).subscribe(

      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.loading = false
        this.configService.loaddinStarter('stop');

      }
    );
  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
    this.tiposPedidos();
  }


  private register(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (this.tipoPedido.slug == null || this.tipoPedido.descricao == null) {
      this.configService.showAlert("O campo Slug e Descrição são Obrigatórios", 'alert-danger', true);
    } else {
    this.http.__call('tipos/pedido/create', this.tipoPedido).subscribe(
      res => {
        if (Object(res).code == 500) {
          this.configService.showAlert(Object(res).message, 'alert-danger', true);
          this.configService.loaddinStarter('stop');
        } else {
          this.configService.showAlert(Object(res).message, 'alert-success', true);
          this.clearFormInputs(e);
          this.tiposPedidos()
          this.configService.loaddinStarter('stop');
        }
      }
    )
  }
      ;
  }


  private clearFormInputs(e) {
    e.target.elements[0].value = null;
    e.target.elements[1].value = null;
    e.target.elements[2].value = null;
  }

  private refresh(id, slug, descricao, rota_crm_inicial) {

    this.tipoPedido.id = id;
    this.tipoPedido.slug = slug;
    this.tipoPedido.descricao = descricao;
    this.tipoPedido.rota_crm_inicial = rota_crm_inicial;
    

  }

  private editar(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (this.tipoPedido.slug == "" || this.tipoPedido.descricao == null) {
      this.configService.showAlert("O campo Slug e Descrição são obrigatorio", 'alert-danger', true);
    } else {
      this.http.__call('tipos/pedido/update/' + this.tipoPedido.id, this.tipoPedido).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            //this.configService.clearFormInputs(e);
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.tiposPedidos();

          }
        }
      );
    }
    this.configService.loaddinStarter('stop');
  }


  private ini() {
    this.tipoPedido = {
      id: null,
      slug: null,
      descricao: null,
      rota_crm_inicial: null
    }
  }








}
