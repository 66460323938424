import { Injectable, Input, EventEmitter } from "@angular/core";
import { AuthService } from "src/app/providers/auth/auth.service";
import { environment } from "src/environments/environment";
import * as moment from "moment";
import * as jsPDF from "jspdf";
import * as extenso from "extenso";

import "jspdf-autotable";
var Buffer = require("buffer/").Buffer;
//
@Injectable({
  providedIn: "root",
})
export class FacturaServicoService {
  @Input() app_environment: null;

  public pdfEvent = new EventEmitter<Object>();

  constructor(private auth: AuthService) {
    this.app_environment = this.auth.getAppEnvironment();

    if (this.app_environment == null) {
      var url = require("url");
      var app_url = url.parse(environment.app_url, true);
      this.app_environment = app_url.host;
    }
  }

  public async imprimirFacturaServico(
    factura: any,
    produtos: any[],
    cliente: any,
    tipos_identidades: any,
    user: any,
    pagamento: any,
    contrato: any,
    leituras: [],
    contabancaria: any[],
    lojas: any[],
    mensagem_cliente: any,
    report: string = "imprimir",
    original: any = "Original"
  ) {
    contrato = contrato || Object;

    contrato.localconsumo = contrato.localconsumo || Object;
    contrato.localconsumo[0] = contrato.localconsumo[0] || Object;
    contrato.tarifa = contrato.tarifa || Object;

    var numberPage = 1;
    var posY = 125;
    var doc = new jsPDF();

    var img_logotipo = user.empresa.logotipo;
    var doc = new jsPDF();
    doc.addImage(img_logotipo, "JPEG", 8, 14, 28, 24);

    let tipo_regime =
      user.empresa.sigla_empresa == "EPASN" ||
        user.empresa.sigla_empresa == "EPASLS"
        ? "Regime Simplificado"
        : "IVA - Regime de Caixa";

    if (user.empresa.sigla_empresa == "EPASN") {
      if (new Date(factura.created_at) > new Date("2021-05-31")) {
        tipo_regime = "IVA - Regime de Caixa";
      } else {
        tipo_regime = "Regime Simplificado";
      }
    }
    //var local_consumo = contrato?.localconsumo

    doc.setFontSize(8);
    doc.setFont("calibri");
    doc.setTextColor(0);
    doc.text("" + (user.empresa.companyName || ""), 37, 20);
    doc.setTextColor(0);
    doc.text("" + (user.empresa.addressDetail || ""), 37, 25);
    doc.text("NIF: " + (user.empresa.taxRegistrationNumber || ""), 37, 30);
    doc.text("Telefone: ", 95, 30);
    let telefone = user.empresa.telefone || "";

    doc.text(telefone, 107, 30);
    doc.text("WebSite: ", 95, 35);
    doc.setTextColor(0, 0, 255);
    doc.text("" + (user.empresa.site || ""), 107, 35);

    doc.setFontSize(8);
    doc.setDrawColor(0);
    doc.setFillColor(255);

    doc.rect(145, 14, 48, 23, "B");

    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.text("FACTURA N.º", 148, 20);
    doc.text("Data Emissão:", 148, 25);
    doc.text("Vencimento:", 148, 30);
    doc.text("Via: " + (original == null ? "" : original), 148, 35);

    doc.setFontSize(8);
    doc.setFontType("normal");
    doc.text("" + (factura.factura_sigla || ""), 168, 20);
    doc.text("" + moment(factura.created_at).format("DD/MM/YYYY"), 168, 25);
    doc.text(
      "" +
      (factura.data_vencimento == null
        ? ""
        : moment(factura.data_vencimento).format("DD/MM/YYYY")),
      168,
      30
    );
    doc.setFontSize(8);
    doc.setFont("calibri");
    doc.setFontType("normal");
    //doc.text(factura.nome_do_cliente == null ? "" : factura.nome_do_cliente, 120, 52);

    //let text_local = local_consumo == null ? '' : local_consumo.is_predio == null ? `, Prédio ${local_consumo.predio_nome ? local_consumo.predio_nome : ''} - ${local_consumo.prepredio_andardio_nome ? local_consumo.predio_andar : ''}  ${local_consumo.moradia_numero}` : ` ${local_consumo.moradia_numero ? `, Residência nº ${local_consumo.moradia_numero}` : ''}`
    let full_morada = `${factura.morada_do_cliente}`;

    doc.text(factura.morada_do_cliente == null ? "" : full_morada, 120, 57);
    doc.text("" + (cliente.municipio.nome || ""), 120, 62);

    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.rect(12, 75, 40.5, 3.5, "B");
    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.setFont("calibri");
    doc.text("CONTRATO N.º: " + (contrato.id || ""), 13, 77.5);
    doc.text(
      "CIL: " +
      (contrato.localconsumo.cil || contrato.localconsumo[0].cil || ""),
      13,
      83
    );
    doc.text("Morada Local de Consumo", 13, 88);
    doc.setFontType("normal");
    doc.text(
      "Bairro : " +
      (contrato.localconsumo.bairro || contrato.localconsumo[0].bairro || ""),
      13,
      91
    );
    doc.text(
      "Rua :  " +
      (contrato.localconsumo.rua || contrato.localconsumo[0].rua || ""),
      13,
      95
    );
    doc.text(
      "Apartamento/Moradia/Caixa : " +
      (contrato.localconsumo.moradia_numero ||
        contrato.localconsumo[0].moradia_numero ||
        ""),
      13,
      99
    );
    if (contrato.localconsumo[0]) {
      doc.text(
        "Prédio :  " + (contrato.localconsumo[0].predio_nome || ""),
        13,
        103
      );
    } else {
      doc.text(
        "Prédio :  " + (contrato.localconsumo.predio_nome || ""),
        13,
        103
      );
    }
    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.rect(85, 75, 34.5, 3.5, "B");
    doc.setFontType("bold");
    doc.text("CLIENTE N.º: " + (cliente.id || ""), 86, 77.5);
    doc.text("NIF: ", 86, 88);
    doc.text("Tarifa: ", 86, 93);
    doc.setFontType("normal");
    doc.text(
      factura.nome_do_cliente.trimStart() == null ? "" : factura.nome_do_cliente.trimStart(),
      86,
      83
    );

    let nif = tipos_identidades.find((obj) => obj.nome == "NIF");
    doc.text("" + (nif ? nif.numero_identidade : ""), 92, 88);
    doc.text("" + (contrato.tarifa.descricao || ""), 96, 93);

    var totalNumberPage = 1;
    totalNumberPage = Math.ceil(produtos.length / 8);

    header();

    doc.setFontSize(8);
    doc.setTextColor(0);

    var totalImposto = 0;
    var totalIncidencia = 0;
    var count_prod = 0;
    var total_transporte = 0;

    footer();
    posY = 117;

    for (var i = 0; i < produtos.length; i++) {
      const data = produtos[i];
      let prod_des = data.produto.nome == null ? "" : data.produto.nome;
      let linha_juros =
        data.referencia_fat_juros == null ? "" : data.referencia_fat_juros;
      let descricao_produto = prod_des + " " + linha_juros;
      doc.setFontSize(8);
      doc.text("" + descricao_produto, 13, posY);
      doc.setFontSize(7);
      doc.text("" + (data.quantidade == null ? "" : data.quantidade), 80, posY, "right");


      doc.text(
        "" +
        (data.produto.incidencia == null
          ? ""
          : data.produto.incidencia.abreviacao),
        89,
        posY,
        "right"
      );
      if (factura.observacao == 'FATURA DE JUROS') {
        doc.text("" + ' - ', 109, posY, "right");
      } else {
        doc.text("" + numberFormat(data.valor), 109, posY, "right");
      }

      doc.text(
        "" + numberFormat(data.linhaTotalSemImposto),
        133,
        posY,
        "right"
      );
      doc.text(
        "" +
        (produtos[0].imposto.codigo == null
          ? "IVA (14%)"
          : produtos[0].imposto.codigo),
        151,
        posY,
        "right"
      );
      doc.text("" + numberFormat(data.valor_imposto), 170, posY, "right");
      doc.text("" + numberFormat(data.total), 192, posY, "right");

      posY += 3.5;

      total_transporte += data.total;
      totalImposto += data.valor_imposto;
      totalIncidencia += data.quantidade * data.valor;

      count_prod++;

      if (count_prod == 8) {
        if (numberPage == 1 && count_prod == 8) {
          //total_transporte = 0; //SEG 01 = 0  SEG 02 = 0
          posY += 3.5;
          total();
          //total_transporte = 0; //SEG 01 = 98715  SEG 02 = 0
        }
        numberPage++;
        newPage(count_prod);
        posY += 10;
        //total_transporte = 0; //SEG 01 = 98715  SEG 02 = 0
        total();
        //total_transporte = 0; //SEG 01 = 98715  SEG 02 = 98715
        count_prod = 0;
        posY -= 30;
      } else {
        posY += 3.5;
      }
    }

    doc.text(
      "" + totalImposto == null ? "" : numberFormat(totalImposto),
      175,
      posY + 10,
      "right"
    );
    doc.setFontSize(8);
    doc.setDrawColor(0, 0, 0);
    doc.setLineWidth(0.1);
    doc.line(12, posY + 4.5, 194, posY + 4.5);

    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.rect(12, posY + 5, 182, 5, "B");
    doc.text("TOTAL FACTURA", 13, posY + 8);
    doc.text("" + numberFormat(factura.total), 193, posY + 8, "right");

    // TOTAL RECEBIDO EM EXTENSO
    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.rect(12, posY + 10, 182, 5, "B");
    doc.text(13, posY + 14, doc.splitTextToSize("VALOR POR EXTENSO", 50));
    var valor = extenso("" + numberFormat(factura.total), {
      locale: "pt",
      mode: "currency",
      currency: { type: "EUR" },
      decimal: "formal",
    });
    doc.text(
      "" + valor.replace(/(euros?)/gi, "kwanzas") + "",
      193,
      posY + 14,
      "right"
    );

    //======================= RESUMO DOS IMPOSTOS =======================

    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.rect(12, posY + 22, 182, 5, "B");
    doc.text("RESUMO DOS IMPOSTOS", 13, posY + 25);
    doc.text("CÓDIGO", 13, posY + 30);
    doc.text("INCIDÊNCIA", 52, posY + 30);
    doc.text("DESCRIÇÃO", 85, posY + 30);
    doc.text("VALOR IMPOSTO", 135, posY + 30);
    doc.setDrawColor(0, 0, 0);
    doc.setLineWidth(0.1);
    doc.line(12, posY + 31, 194, posY + 31); // horizontal line

    doc.text(
      "" +
      (produtos[0] == undefined
        ? "IVA"
        : produtos[0].imposto == null
          ? "IVA"
          : produtos[0].imposto.codigo == null
            ? "IVA"
            : produtos[0].imposto.codigo),
      13,
      posY + 35
    );
    doc.text(
      "" + numberFormat(factura.totalSemImposto),
      69,
      posY + 35,
      "right"
    ); // adicionar o valor total sem o IVA
    doc.text(
      "" +
      (produtos[0] == undefined
        ? "IVA (14%)"
        : produtos[0].imposto == null
          ? "IVA (14%)"
          : produtos[0].imposto.descricao == null
            ? "IVA (14%)"
            : produtos[0].imposto.descricao),
      85,
      posY + 35
    );
    doc.text("" + numberFormat(totalImposto), 159, posY + 35, "right");

    var somaY = 0;

    //======================= LEITURA =======================
    if (factura.leitura != null) {
      doc.setTextColor(0);
      doc.setFontType("bold");
      doc.setDrawColor(250);
      doc.setFillColor(220, 220, 220);
      doc.rect(12, posY + 41, 182, 5, "B");
      doc.text("LEITURA: " + (factura.leitura || ""), 13, posY + 45);

      somaY = 4;
    }

    if (factura.observacao == 'FATURA DE JUROS'

    ) {
      posY = posY + 12;
      doc.setTextColor(0);
      doc.setFontType("bold");
      doc.setDrawColor(250);
      doc.setFillColor(220, 220, 220);
      doc.rect(12, posY + 34, 182, 5, "B");
      doc.text("RESUMO DOS JUROS", 13, posY + 38);
      doc.text("FACTURA", 13, posY + 42);
      doc.text("TAXA JURO", 52, posY + 42);
      doc.text("PERÍODO", 85, posY + 42);
      doc.text("MONTANTE DE INCIDÊNCIA", 135, posY + 42);
      doc.setDrawColor(0, 0, 0);
      doc.setLineWidth(0.1);
      doc.line(12, posY + 43, 194, posY + 43); // horizontal line

      /* let calculateDay = lastNumber[1] >= 5 ? Math.ceil((factura.lines[i] || {}).quantidade * 30 || 0) :
                         Math.floor((factura.lines[i] || {}).quantidade * 30 || 0)
 */
      for (let i = 0; i < (factura.lines || []).length; i++) {
        doc.text(
          "" + ((factura.lines[i] || {}).referencia_fat_juros || 0),
          13,
          posY + 47
        ); // adicionar o valor total sem o IVA
        doc.text(
          "" + ((factura.lines[i] || {}).taxa || ""),
          56,
          posY + 47
        );
        doc.text(
          "" + moment((factura.lines[i] || {}).data_inicio_cobranca_juro || "").format('DD-MM-YYYY') + " - " + moment((factura.lines[i] || {}).data_fim_cobranca_juro || "").format('DD-MM-YYYY'),
          105,
          posY + 47,
          "right"
        );
        doc.text(
          "" + numberFormat((factura.lines[i] || {}).valor_incidencia || 0),
          175,
          posY + 47,
          "right"
        );
        posY += 5;
      }
      posY = posY + 12;
    }

    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.rect(12, posY + 45 + somaY, 182, 5, "B");
    doc.text(
      "OBSERVAÇÃO: " + (factura.observacao || ""),
      13,
      posY + 49 + somaY
    );
    doc.text("Mensagem: " + mensagem_cliente, 13, posY + 55 + somaY);



    doc.setFontType("bold");
    if (report == "imprimir") {
      doc.autoPrint();
      window.open(doc.output("bloburl")); //opens the data uri in new window
      return;
    } else if (report == "buffer") {
      return new Buffer.from(doc.output("arraybuffer"));
    } else {
      doc.save(
        factura.factura_sigla + ".pdf"
      ); /* download the file immediately on loading */
      return;
    }

    function newPage(count) {
      doc.addPage();
      header();
      footer();
    }

    function total() {
      doc.setFontSize(6);
      doc.setDrawColor(0, 0, 0);
      doc.setLineWidth(0.1);
      doc.setTextColor(0);
      doc.setFontType("bold");
      doc.setDrawColor(250);
      doc.setFillColor(220, 220, 220);
      doc.rect(12, posY + 2, 182, 5, "B");
      doc.text("SUBTOTAL", 13, posY + 5);
      doc.text("" + numberFormat(total_transporte), 193, posY + 5, "right");
      doc.setFontType("normal");
    }

    function footer() {
      posY = 140;

      var somaY = 0;

      if (factura.serie.documento.sigla === "FR") {
        posY += 20;
        doc.setTextColor(0);
        doc.setFontType("bold");
        doc.setDrawColor(250);
        doc.setFillColor(220, 220, 220);
        doc.rect(12, posY + 22, 182, 5, "B");
        doc.text("MEIOS DE PAGAMENTOS", 13, posY + 25);
        doc.text("DESCRIÇÃO", 13, posY + 30);
        doc.text("VALOR RECEBIDO", 155, posY + 30, "right");
        doc.setDrawColor(0, 0, 0);
        doc.setLineWidth(0.1);
        doc.line(12, posY + 31, 194, posY + 31); // horizontal line
        var posYLinhaPagamento = posY + 1;
        if (pagamento) {
          pagamento.lines.forEach((pagament) => {
            doc.text(
              "" + pagament.forma_pagamento.designacao,
              13,
              posYLinhaPagamento + 35
            );
            doc.text(
              "" +
              (pagament.forma_pagamento.designacao == "Numerário"
                ? numberFormat(pagament.valor_recebido)
                : numberFormat(pagament.valor_recebido)),
              155,
              posYLinhaPagamento + 35,
              "right"
            );
            posYLinhaPagamento += 4;
          });


          if (pagamento.isTrocoAdiantamento) {
            let text_adiantamento = `Adiantamento registado no valor de: ${numberFormat(
              pagamento.troco
            )} AOA`;
            doc.text(
              text_adiantamento,
              15,
              posYLinhaPagamento + 38,
              null,
              null,
              "left"
            );
          }
        }
      }

      posY = 135;
      doc.setDrawColor(0);
      doc.setFillColor(220, 220, 220);
      doc.rect(160, posY + 89, 35, 5, "B");
      doc.setTextColor(0);
      doc.setFontType("bold");
      doc.text(tipo_regime, 192, posY + 92.5, "right");
      doc.setTextColor(0);
      doc.setDrawColor(250);
      doc.setFillColor(220, 220, 220);
      doc.rect(10, posY + 106, 185.5, 5, "B");
      doc.setTextColor(0);
      doc.setFontType("bold");
      doc.setDrawColor(0);
      doc.rect(10, posY + 95, 185, 20, "B");
      doc.setDrawColor(250);
      doc.setFillColor(220, 220, 220);
      doc.setTextColor(0);
      doc.setDrawColor(250);
      doc.setFillColor(220, 220, 220);
      doc.text("Contas Bancárias (IBAN):", 12, posY + 99.5);
      doc.setTextColor(0);
      doc.setFontType("bold");
      doc.setFontSize(8);
      doc.setDrawColor(0);
      doc.setFillColor(255);
      doc.rect(10, posY + 101, 185, 20, "B");
      doc.setTextColor(0);
      doc.setFontType("normal");

      var posX = 12;
      let posYY = 105;
      for (var i = 0; i < contabancaria.length; i++) {
        if (i !== 0 && i % 3 === 0) {
          posX = 12;
          posYY += 4;
        }
        doc.text("" + contabancaria[i].abreviatura, posX, posY + posYY);
        doc.text("" + contabancaria[i].iban, posX + 7, posY + posYY);
        posX += 60;
      }

      doc.text(
        "Sempre que efectuar uma transferência ou depósito, deve indicar o número das facturas a liquidar.",
        12,
        posY + 116
      );
      doc.setDrawColor(250);
      doc.setFillColor(220, 220, 220);
      doc.rect(10, posY + 124.5, 52, 5, "B");
      doc.setTextColor(0);
      doc.setFontType("bold");
      doc.text(
        "Data Limite de Pagamento: " +
        (factura.data_vencimento == null
          ? ""
          : moment(factura.data_vencimento).format("DD/MM/YYYY")),
        11,
        posY + 128
      );

      doc.text("Lojas EPAS:", 10, posY + 132);
      doc.setFontType("normal");
      for (let loja of lojas) {
        doc.text(
          "" +
          loja.nome +
          ", " +
          loja.endereco +
          ", " +
          (loja.horario ? loja.horario : ""),
          11,
          posY + 135
        );
        posY += 3;
      }

      var dataActual = moment(new Date()).format("DD/MM/YYYY");
      doc.text(
        dataActual + " " + moment().format("H:m") + "/ Versão: " + original,
        11,
        285
      );
      /*  doc.text((factura.datahora_emissao == null ? '' : moment(factura.datahora_emissao).format("DD/MM/YYYY") + ' ' + moment(factura.datahora_emissao).format("H:m") + '/ Versão: ') + original, 11, 285); */
      doc.text("Página: " + numberPage + "/" + totalNumberPage, 180, 285);

      doc.setFontSize(8);
      doc.setDrawColor(0);
      doc.setFillColor(0);

      doc.line(
        10,
        doc.internal.pageSize.height - 9,
        194,
        doc.internal.pageSize.height - 9
      ); // vertical line

      var hash = factura.hash ? factura.hash.substring(0, 1) : '';
      hash += factura.hash ? factura.hash.substring(10, 11) : '';
      hash += factura.hash ? factura.hash.substring(20, 21) : '';
      hash += factura.hash ? factura.hash.substring(30, 31) : '';
      doc.setFontSize(6);

      let telefone = user.empresa.telefone ? `/ ${user.empresa.telefone}` : "";
      let email = user.empresa.email ? `/ ${user.empresa.email}` : "";

      doc.text(
        "NIF: " +
        (user.empresa.taxRegistrationNumber || "") +
        " - " +
        (user.empresa.companyName || "") +
        " / " +
        (user.empresa.addressDetail || "") +
        telefone +
        email,
        105,
        doc.internal.pageSize.height - 6,
        null,
        null,
        "center"
      );
      doc.setFontSize(7);
      doc.text(
        hash + "-Processado por programa validado nº 4/AGT119",
        105,
        doc.internal.pageSize.height - 3,
        null,
        null,
        "center"
      );

      let postionStatus = 135;
      if (factura.status == "A") {
        doc.setDrawColor(0);
        doc.setFillColor(220, 220, 220);
        doc.rect(10, postionStatus + 89, 35, 5, "B");
        doc.setTextColor(0);
        doc.setFontType("bold");
        doc.text("Estado: ", 12, postionStatus + 92.5);
        doc.setTextColor("red");
        doc.text("Anulação", 24, postionStatus + 92.5);
        doc.setTextColor(0);
      }
    }

    function header() {
      doc.setProperties({
        title: "" + factura.factura_sigla,
        subject: "" + factura.serie.documento.nome,
        author: "Unig",
        keywords: "",
        creator: "" + user.empresa.companyName,
      });
      var img_logotipo = user.empresa.logotipo;
      doc.addImage(img_logotipo, "JPEG", 8, 14, 28, 24);
      doc.setFontSize(8);
      doc.setFont("calibri");
      doc.setTextColor(0);
      doc.text("" + (user.empresa.companyName || ""), 37, 20);
      doc.setTextColor(0);
      doc.text("" + user.empresa.addressDetail || "", 37, 25);
      doc.text("NIF: " + user.empresa.taxRegistrationNumber || "", 37, 30);

      let email = user.empresa.telefone ? user.empresa.telefone : "";
      doc.text("Email: " + (user.empresa.email || ""), 37, 35);

      let telefone = user.empresa.telefone ? user.empresa.telefone : "";
      doc.text("Telefone: ", 95, 30);
      doc.text(telefone, 107, 30);

      doc.text("WebSite: ", 95, 35);
      doc.setTextColor(0, 0, 255);
      doc.text("" + (user.empresa.site || ""), 107, 35);

      doc.setFontSize(8);
      doc.setDrawColor(0);
      doc.setFillColor(255);

      doc.rect(142, 14, 58, 23, "B");

      doc.setTextColor(0);
      doc.setFontType("bold");
      doc.text("FACTURA N.º", 145, 20);
      doc.text("Data Emissão:", 145, 25);
      doc.text("Vencimento:", 145, 30);
      doc.text("Via: " + original, 145, 35);

      doc.setFontSize(8);
      doc.setFontType("normal");
      doc.text("" + factura.factura_sigla, 165, 20);
      doc.text("" + moment(factura.created_at).format("DD/MM/YYYY"), 165, 25);
      doc.text(
        "" +
        (factura.data_vencimento == null
          ? ""
          : moment(factura.data_vencimento).format("DD/MM/YYYY")),
        165,
        30
      );
      doc.setFontSize(8);
      doc.setFont("calibri");
      doc.setFontType("normal");
      doc.text(factura.nome_do_cliente ? factura.nome_do_cliente.trimStart() : '', 120, 52);
      doc.text(factura.morada_do_cliente, 120, 57);
      doc.text("" + cliente.municipio.nome, 120, 62);

      doc.setDrawColor(250);
      doc.setFillColor(220, 220, 220);
      doc.rect(12, 75, 40.5, 3.5, "B");
      doc.setTextColor(0);
      doc.setFontType("bold");
      doc.setFont("calibri");
      doc.text("CONTRATO N.º: " + (contrato.id || ""), 13, 77.5);
      doc.text(
        "CIL: " +
        (contrato.localconsumo !== null
          ? contrato.localconsumo.cil || ""
          : ""),
        13,
        83
      );
      doc.text("Morada Local de Consumo:", 13, 88);
      doc.setFontType("normal");

      doc.text(
        "Bairro : " +
        (contrato.localconsumo !== null
          ? contrato.localconsumo.bairro || ""
          : ""),
        13,
        91
      );
      doc.text(
        "Rua :  " +
        (contrato.localconsumo !== null
          ? contrato.localconsumo.rua || ""
          : ""),
        13,
        95
      );
      doc.text(
        "Apartamento/Moradia/Caixa : " +
        (contrato.localconsumo !== null
          ? contrato.localconsumo.moradia_numero || ""
          : ""),
        13,
        99
      );
      doc.text(
        "Prédio :  " +
        (contrato.localconsumo !== null
          ? contrato.localconsumo.predio_nome || ""
          : ""),
        13,
        103
      );

      // 1º linha Rua, Nome do Predio, Nº da Porta
      // 2º linha Bairro, Município
      // Obs: a vírgula fica ligado com o outro ponto

      doc.setDrawColor(250);
      doc.setFillColor(220, 220, 220);
      doc.rect(85, 75, 34.5, 3.5, "B");
      doc.setFontType("bold");
      doc.text("CLIENTE N.º: " + cliente.id, 86, 77.5);
      doc.text("NIF: ", 86, 88);
      doc.text("Tarifa: ", 86, 93);
      doc.setFontType("normal");
      doc.text(factura.nome_do_cliente ? factura.nome_do_cliente.trimStart() : '', 86, 83);

      /*       doc.text( '' + (nif ? nif.numero_identidade : "") , 92, 88); */
      doc.text("" + (contrato.tarifa.descricao || ""), 96, 93);

      posY = -10;
      doc.setDrawColor(250);
      doc.setFillColor(220, 220, 220);
      doc.rect(12, 114 + posY, 182, 5, "B");
      doc.setFontSize(8);
      doc.setTextColor(0);
      doc.setFontType("bold");
      doc.text("Resumo da", 13, 117.5 + posY);
      doc.text(
        "" + factura.serie.documento.nome + " " + factura.factura_sigla,
        29,
        117.5 + posY
      );
      doc.setFontType("normal");

      doc.setDrawColor(0, 0, 0);
      doc.setLineWidth(0.1);
      doc.line(12, 114.5 + posY, 194, 114.5 + posY); // horizontal line

      doc.setFontType("bold");
      doc.text("Descrição", 13, 122 + posY);
      doc.text("Qtd.", 77, 122 + posY);
      doc.text("Un.", 85, 122 + posY);
      doc.text("V. Unit", 100, 122 + posY);
      doc.text("Total S/Iva", 120, 122 + posY);
      doc.text("Cod.Imp.", 140, 122 + posY);
      doc.text("V.Imp.", 162, 122 + posY);
      doc.text("TOTAL", 183, 122 + posY);
      doc.setTextColor(0);
      doc.setFontType("normal");
      doc.setDrawColor(0, 0, 0);
      doc.setLineWidth(0.1);
      doc.line(12, 119 + posY, 194, 119 + posY); // horizontal line
    }

    function numberFormat(number) {
      return new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 2,
      })
        .format(number || 0)
        .replace("€", "")
        .trim();
    }
  }
}
