import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { AuthService } from 'src/app/providers/auth/auth.service';


@Injectable({
  providedIn: 'root'
})
export class ConfigModuloService {

  private currentUser;

  public modulo = {
    GESTAO_CLIENTES: 'gestao_clientes',
    GESTAO_LEITURAS: 'gestao_leituras',
    LOGISTICA: 'logistica',
    VENDAS: 'vendas',
    ROTEIROS: 'roteiros',
    OPERACOES: 'operacoes',
    ORDENS_SERVICOS: 'ordens_servicos',
    CONFIGURACOES: 'configuracoes',
    FACTURACAO: 'facturacao'
  }

  public provincia_default: string = "provincia_default";
  public quarteirao_view: string = "quarteirao_view";
  public distrito_view: string = "distrito_view";
  public municipio_default: string = "municipio_default";
  public tipo_identidade_default: string = "tipo_identidade_default";
  public caucao_default: string = "caucao_default";
  public tipo_facturacao_default: string = "tipo_facturacao_default";
  public media_consumo_default: string = "media_consumo_default";
  public gestor_cliente_view: string = "gestor_cliente_view";
  public direccao_view: string = "direccao_view";
  public nivel_sensibilidade_default: string = "nivel_sensibilidade_default";
  public tarifa_fixa_modal_view: string = "tarifa_fixa_modal_view";
  public prozo_para_leituras_max_default: string = "prozo_para_leituras_max_default";
  public prozo_para_resolucao_anomalias_default: string = "prozo_para_resolucao_anomalias_default";
  public prozo_para_facturacao_default: string = "prozo_para_facturacao_default";
  public meses_facturacao_media_consumo: string = "meses_facturacao_media_consumo";
  public moeda_id_default: string = "moeda_id_default";
  public estado_rescisao_default: string = "estado_rescisao_default";
  public pisco_de_leituras_anteriores: string = "pisco_de_leituras_anteriores";
  public tipo_de_escalao: string = "tipo_de_escalao";
  public adianntamento_como_default: string = "adianntamento_como_default";
  public serie_nota_de_credito_default: string = "serie_nota_de_credito_default";
  public cil_disabled_view: string = "cil_disabled_view";
  public validacao_stock_disponivel: string = "validacao_stock_disponivel";
  public validacao_stock_reserva: string = "validacao_stock_reserva";
  public validacao_de_recursos_humanos: string = "validacao_de_recursos_humanos";
  public facturacao_automatica: string = "facturacao_automatica";
  public tipo_de_avenca: string = "tipo_de_avenca";
  public load_cil: string = "load_cil";
  public distrito_cil_sequencial: string = "distrito_cil_sequencial";



  private Configuracaos = [
    { slug: this.tipo_de_avenca, modulo: this.modulo.CONFIGURACOES },
    { slug: this.validacao_stock_disponivel, modulo: this.modulo.ORDENS_SERVICOS },
    { slug: this.validacao_stock_reserva, modulo: this.modulo.ORDENS_SERVICOS },
    { slug: this.validacao_de_recursos_humanos, modulo: this.modulo.ORDENS_SERVICOS },
    { slug: this.adianntamento_como_default, modulo: this.modulo.CONFIGURACOES },
    { slug: this.meses_facturacao_media_consumo, modulo: this.modulo.CONFIGURACOES },
    { slug: this.provincia_default, modulo: this.modulo.CONFIGURACOES },
    { slug: this.quarteirao_view, modulo: this.modulo.CONFIGURACOES },
    { slug: this.distrito_view, modulo: this.modulo.CONFIGURACOES },
    { slug: this.municipio_default, modulo: this.modulo.ROTEIROS },
    { slug: this.tipo_identidade_default, modulo: this.modulo.GESTAO_CLIENTES },
    { slug: this.caucao_default, modulo: this.modulo.CONFIGURACOES },
    { slug: this.tipo_facturacao_default, modulo: this.modulo.GESTAO_CLIENTES },
    { slug: this.media_consumo_default, modulo: this.modulo.GESTAO_CLIENTES },
    { slug: this.gestor_cliente_view, modulo: this.modulo.GESTAO_CLIENTES },
    { slug: this.direccao_view, modulo: this.modulo.GESTAO_CLIENTES },
    { slug: this.nivel_sensibilidade_default, modulo: this.modulo.GESTAO_CLIENTES },
    { slug: this.tarifa_fixa_modal_view, modulo: this.modulo.GESTAO_CLIENTES },
    { slug: this.prozo_para_leituras_max_default, modulo: this.modulo.ROTEIROS },
    { slug: this.prozo_para_resolucao_anomalias_default, modulo: this.modulo.ROTEIROS },
    { slug: this.prozo_para_facturacao_default, modulo: this.modulo.ROTEIROS },
    { slug: this.moeda_id_default, modulo: this.modulo.CONFIGURACOES },
    { slug: this.estado_rescisao_default, modulo: this.modulo.GESTAO_CLIENTES },
    { slug: this.pisco_de_leituras_anteriores, modulo: this.modulo.GESTAO_LEITURAS },
    { slug: this.tipo_de_escalao, modulo: this.modulo.GESTAO_LEITURAS },
    { slug: this.serie_nota_de_credito_default, modulo: this.modulo.GESTAO_LEITURAS },
    { slug: this.cil_disabled_view, modulo: this.modulo.GESTAO_CLIENTES },
    { slug: this.load_cil, modulo: this.modulo.OPERACOES },
    { slug: this.distrito_cil_sequencial, modulo: this.modulo.OPERACOES },
    { slug: this.facturacao_automatica, modulo: this.modulo.FACTURACAO },

  ];

  constructor(private http: HttpService, private auth: AuthService) {
    this.currentUser = this.auth.currentUserValue;
  }
  /*
    public async getConfig(modulo, slug, callback: Function) {

      var result = null;

      console.log(slug);

      this.http.__call('configuracao/getConfiguracaobySlug/' + slug, null).subscribe(
        response => {

          console.log(Object(response));

          if (Object(response).code != 200) {
            this.saveConfig(slug, modulo);
            result = null;
          }
          else {
            result = Object(response).data.valor;
          }

          //callback();

        }
      );

      return result;

    }*/


  public saveConfig(slug, modulo, valor) {

    /* console.log("save config");
    console.log(slug);
    console.log(modulo);
    console.log(valor);
    console.log(this.currentUser); */

    this.http.__call('configuracao/auto-create/',
      {
        slug: slug,
        is_active: true,
        modulo: modulo,
        descricao: null,
        valor: valor,
        required: false,
        default: false,
        user_id: (this.currentUser == null) ? '1' : this.currentUser.user.id
      }).subscribe(

        response => {

          //console.log(Object(response));

        });
  }

  public initConfiguracaos() {

    //console.log( this.Configuracaos )

    /*

    for (let index = 0; index < this.Configuracaos.length; index++) {

      //console.log(this.Configuracaos[index].slug);

      this.http.__call('configuracao/auto-create/',
        {
          slug: this.Configuracaos[index].slug,
          is_active: true,
          modulo: this.Configuracaos[index].modulo,
          descricao: null,
          valor: null,
          required: false,
          default: false,
          is_boolean: false,
          user_id: (this.currentUser == null) ? '1' : this.currentUser.user.id
        }).subscribe(

          response => {

            //console.log(Object(response));

          }
        );
    }
    */

    return;


  }


}
