import { Component, OnInit, Input,ViewChild } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-list-agendamento-by-ordem',
  templateUrl: './list-agendamento-by-ordem.component.html',
  styleUrls: ['./list-agendamento-by-ordem.component.css']
})
export class ListAgendamentoByOrdemComponent implements OnInit {

  @Input() modal: string = "modalListaAgendamentos";
  @Input() res: any;
  @Input() item: any;
  @Input() os_tipo_id:any

  items:any={}

  public filters = {
    search: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    pagination: {
      perPage: 5,// Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima,
      lastPage: null,
      total: null // defini o total de registo da BD
    },
    filter: null // subelemente do filter
  }

  constructor(private http: HttpService, private configService: ConfigService, private formBuilder: FormBuilder) {

  }

  ngOnInit() {

  }

  getOrdemServio(ordem_servico_id){
    console.log('item',this.item)
    for(let item of this.item){
        console.log('os_tipos',item)
       if(item.agendamento === ordem_servico_id){
            console.log('item ordem',item)
            return item.os_tipo_id
       }
    }
    return null
  }

  private imprimirAgendamento(id){

    this.configService.imprimirAgendamento(id, "2ª Via", "imprimir")
  }

  imprimirAgendamentoMassiva(id){
    this.configService.imprimirAgendamentoMassiva(id, "2ª Via", "imprimir")
  }
  private imprimirAgendamentoPreventivo(id){
    console.log(id)

    this.configService.imprimirAgendamentoPreventivo(id, "2ª Via", "imprimir")
  }



}
