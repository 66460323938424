import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-classe-tarifario',
  templateUrl: './classe-tarifario.component.html',
  styleUrls: ['./classe-tarifario.component.css']
})
export class ClasseTarifarioComponent implements OnInit, OnDestroy {

  private classeTarifario = {
    id: null,
    valor: null,
    valor_quota: null,
    tarifa_variavel: null,
    tarifa_fixa_mensal: null,
    media_consumo: null,
    is_active: null,
    consumo_minimo: null,
    consumo_maximo: null,
    tarifario: null,
    descricao: null,
    produto_id: null,
    nome: null,
    tarifario_id: null,
    data_inicio: null,
    data_fim: null
  }

  private items: any = [];
  private tarifarios: any = [];
  private produtos: any = [];

  simpleForm: FormGroup;
  constructor(private formBuilder: FormBuilder, private http: HttpService,
    private configService: ConfigService) {
    //this.createForm();
  }

  validated: boolean = false

  ngOnInit() {
    this.http.filters.search = null
    this.getPageFilterData(1);
    // this.getTarifarios()

  }
  ngOnDestroy() {
    this.http.filters.orderBy = null
  }


  private valida() {
    if (this.classeTarifario.data_inicio > this.classeTarifario.data_fim) {
      this.validated = !this.validated
    }
  }

  private listaclasseTarifario() {
    this.configService.loaddinStarter('start');
    this.http.__call('classe-tarifario/listagem', this.http.filters).subscribe(
      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;
        this.items = Object(response).data.data;
        this.configService.loaddinStarter('stop');
      }
    );
  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1;
    this.listaclasseTarifario();
  }


  private register(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (this.classeTarifario.tarifario_id == null) {
      this.configService.showAlert("É obrigatório seleccionar Tarifário", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    } else if (this.classeTarifario.tarifa_variavel == null) {
      this.configService.showAlert("O valor dat tarifa variável não pode ser nulo", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    }
    else if (this.classeTarifario.data_inicio > this.classeTarifario.data_fim) {
      this.configService.showAlert("A data inicial não pode ser maior que que data final", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    }
    else if (this.classeTarifario.tarifa_fixa_mensal == null) {
      this.configService.showAlert("O valor dat tarifa fixa mensal não pode ser nulo", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    } else if (this.classeTarifario.consumo_maximo <= this.classeTarifario.consumo_minimo) {
      this.configService.showAlert("Consumo Mínimo não pode ser maior ou igual que o Consumo Máximo", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    } else {
      console.log(this.classeTarifario)
      this.http.__call('classe-tarifario/create', this.classeTarifario).subscribe(
        res => {
          if (Object(res).code == 201) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
            this.configService.loaddinStarter('stop');
          } else {
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.clearFormInputs(e);
            this.listaclasseTarifario()
            this.configService.loaddinStarter('stop');
          }
        }
      )
    }

  }

  private clearFormInputs(e) {
    e.target.elements[0].value = null;
    e.target.elements[1].value = null;
    e.target.elements[2].value = null;
    e.target.elements[3].value = null;
    e.target.elements[4].value = null;
    e.target.elements[5].value = null;
  }

  private _initClasseTarifario(classeTarifario) {
    this.getTarifarios();
    this.getProdutos();
    this.classeTarifario = classeTarifario
  }

  private getTarifarios() {
    this.http.call_get('classe-tarifario/selectBox', null)
      .subscribe(
        response => {
          this.tarifarios = Object(response).data
        }
      );
  }



  private editar(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    this.http.__call(`classe-tarifario/update/${this.classeTarifario.id}`, this.classeTarifario)
      .subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.listaclasseTarifario();

          }
        }
      );

    this.configService.loaddinStarter('stop');
  }

  private loadingData = {
    produto: "Carregando..."
  }

  private getProdutos() {
    this.loadingData.produto = "Carregando..."
    this.http.call_get('artigo/selectProdutos', null).subscribe(
      response => {
        this.produtos = Object(response).data
        this.loadingData.produto = "Seleccione o Produto"
      }
    );
  }

  private ini() {
    this.getTarifarios();
    this.getProdutos();
    this.classeTarifario.id = null;
    this.classeTarifario.is_active = null;
    this.classeTarifario.consumo_minimo = null;
    this.classeTarifario.consumo_maximo = null;
    this.classeTarifario.descricao = null;
    this.classeTarifario.tarifario_id = null;
    this.classeTarifario.media_consumo = null;
    this.classeTarifario.tarifario = null;
    this.classeTarifario.nome = null;
  }





}
