
import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/providers/http/api.service";
import { ConfigService } from "src/app/providers/config/config.service";

import { FacturaServicoService } from "src/app/components/reports/factura-servico/factura-servico.service";

import { HttpParams } from "@angular/common/http";
import { finalize } from 'rxjs/operators';
import { FacturaCicloService } from "../reports/factura-ciclo/factura-ciclo.service";

var moment = require("moment");

export class Filter {
  serie_id: string = "";
  municipio_id: string = "";
  distrito_id: string = ""
  bairro_id: string = "";
  startDate: string = "";
  endDate: string = "";
  intervalo: string = "";
  selectedInvoices: string = "";
  typeFilter: string = "";
  saldado: string = "";
  status: string = "";
  constructor() {
  }
}

export class Pagination {
  lastPage: number;
  page: number = 1;
  perPage: number = 100;
  total: number;
  deserialize(input): this {
    return Object.assign(this, input);
  }
}
@Component({
  selector: 'app-impressao-massiva',
  templateUrl: './impressao-massiva.component.html',
  styleUrls: ['./impressao-massiva.component.css']
})
export class ImpressaoMassivaComponent implements OnInit {
  public pagination = new Pagination();
  public filter = new Filter();
  loading: boolean = false;
  private facturas: any = [];
  selectedInvoices: any = [];
  checkedAll: boolean = true;
  series: any = [];
  municipios: any = [];
  distritos: any = [];
  bairros: any = [];
  leituras: any[] =[]
  consumo: any[] =[]
  private loadings = {
    series: false,
    municipios: false,
    distritos: false,
    bairros: false
  };


  constructor(
    private configService: ConfigService,
    private api: ApiService, private facturaServicoService: FacturaServicoService,
    private facturaCicloService: FacturaCicloService
  ) {

  }

  ngOnInit() {
    this.getMunicipios();
    this.getSeries();
  }

  /**
   * @name "Listar facturação"
   * @descriptio "Esta Função permite Listar todas facturações"
   * @author "caniggia.moreira@itgest.pt"
   * @param start
   * @param end
   */
  private listarFacturas() {
    this.facturas = [];
    const validar_filtro = this.filter.distrito_id
      || this.filter.municipio_id
      || this.filter.saldado
      || this.filter.status
      || this.filter.distrito_id || this.filter.bairro_id
      || (this.filter.startDate && this.filter.endDate)
      || this.filter.intervalo
      || this.filter.selectedInvoices
      ? false : true;
    if (validar_filtro) {
      this.configService.showAlert(`O campo serie é obrigatório - Seleccione mais um filtro`, "alert-error", true);
      return;
    }
    this.loading = true;
    var params = new HttpParams();
    params = this.api.params
      .set("serie_id", this.filter.serie_id)
      .set("municipio_id", this.filter.municipio_id)
      .set("distrito_id", this.filter.distrito_id)
      .set("bairro_id", this.filter.bairro_id)
      .set("startDate", this.filter.startDate)
      .set("endDate", this.filter.endDate)
      .set("intervalo", this.filter.intervalo.toString())
      .set("invoices", this.filter.selectedInvoices)
      .set("saldado", this.filter.saldado)
      .set("status", this.filter.status)
      .set("page", (Number.isInteger(this.pagination.page) || 1).toString())
      .set("perPage", (this.pagination.perPage || 5).toString())

    this.api
      .get(`facturas/massiva`, params)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(response => {
        this.facturas = Object(response).data.data;
        this.leituras = Object(response).data.leituras
        this.consumo = Object(response).data.consumo
        this.pagination.lastPage = Object(response).data.lastPage;
        this.pagination.page = Object(response).data.page;
        this.pagination.total = Object(response).data.total;
        this.pagination.perPage = Object(response).data.perPage;
        this.facturas.forEach((element) => {
          Object.assign(element, { checked: true });
        });
        this.selectedInvoices = this.facturas;
      }, error => {
        this.loading = false;
      })
  }


  //--------------------------------------------------------------------------

  getPageFilterData(page = null) {
    console.log(page)
    if(page)this.pagination.perPage = page
    this.listarFacturas();
  }


  //--------------------------------------------------------------------------

  async getFactura(id) {
    const factura = this.facturas.find(val => val.id === id);
    const leituras = this.leituras.find(val => val.id === id);
    const consumo = this.consumo.find(val => val.factura_id === id);
    console.log('leituras',leituras)
    return {
      factura: factura,
      pagamentos: factura.pagamentos,
      cliente: factura.cliente,
      tipos_identidades: factura.cliente.tipos_identidades,
      lojas: factura.cliente.municipio.lojas,
      contabancaria: factura.user.agencia.bancos,
      contrato: factura.contrato == null ? null : Object.assign(factura.contrato, { localconsumo: factura.contrato.localconsumo[0] }),
      produtos: factura.lines,
      user: factura.user,
      conta_corrente: {
        saldo_anterio: 0,
        pagamentos: 0,
        factura_actual: factura.total
      },
      leituras: leituras.contrato == null ? null : leituras.contrato.leituras,
      historico: this.historicoConsumos(factura.contrato.historico_consumos, moment(factura.periodo_consumo_fim).format("YYYY-MM-DD")),
      consumo: consumo//factura.consumo
    }
  }

  private async printFacturaPreviewOrDownload(id, type = 'imprimir') {
    const dados = await this.getFactura(id);
    await this.selectTipoFactura(dados.factura.tipo_factura, dados, 'imprimir');
  }

  calcularPercentual(f: any) {
    var calc = f.total - f.valor_aberto;
    return f.serie.documento.sigla == "NC"
      ? 100
      : f.pago === 0 && f.status === "N" && f.valor_aberto == null
        ? 0
        : (calc * 100) / f.total;
  }

  private getSeries() {
    this.loadings.series = true;
    this.api.post('serie/selectBoxSeries', { documentos: ["FT", "FR"], status: [false, true] })
      .pipe(finalize(() => { this.loadings.series = false; }))
      .subscribe(
        response => {
          this.series = Object(response);
        }
      );
  }

  private getMunicipios() {
    this.distritos = [];
    this.loadings.municipios = true;
    this.api.get('municipio/selectBox')
      .pipe(finalize(() => { this.loadings.municipios = false; }))
      .subscribe(response => { this.municipios = Object(response).data; }, error => { this.loadings.municipios = false; });
  }

  private getDistritos() {
    this.bairros = [];
    this.loadings.distritos = true;
    this.filter.distrito_id = "";
    this.filter.bairro_id = "";
    this.api.get(`distrito/selectBoxDistritosByMunicipio/${this.filter.municipio_id}`)
      .pipe(finalize(() => { this.loadings.distritos = false; }))
      .subscribe(response => { this.distritos = Object(response).data; }
        , error => { this.loadings.distritos = false; }
      );
  }
  private getBairrosByDistritos() {
    this.filter.bairro_id = "";
    this.loadings.bairros = true;
    if (this.filter.distrito_id == "") return;
    this.api.get(`bairro/getBairrosByDistrito/${this.filter.distrito_id}`)
      .pipe(finalize(() => { this.loadings.bairros = false; }))
      .subscribe(response => { this.bairros = Object(response).data; }
        , error => { this.loadings.bairros = false; }
      );
  }

  setCheckedAll(checked: boolean) {
    this.checkedAll = checked;
    this.facturas.forEach(f => f.checked = checked);
  }

  updateChecked(f: any) {
    this.facturas.forEach(element => {
      element.checked = element.id === f.id ? !f.checked : element.checked;
      Object.assign(element);
    });
    const lengthFalse = this.facturas.filter(val => val.checked === true);
    this.checkedAll = lengthFalse.length === this.facturas.length ? true : false;
  }

  private get facturasChecked(): any[] {
    return this.facturas.filter(val => val.checked === true);
  }

  private async printMergePdfBuffers() {

    if (this.facturasChecked.length === 0) {
      this.configService.showAlert("Nenhuma factura seleccionada", "alert-error", true);
      return;
    }
    if (this.facturasChecked.length == 1) {
      this.configService.showAlert("Seleccione no minimo duas ou mais facturas", "alert-error", true);
      return;
    }
    this.loading = true;
    this.configService.showAlert("Estamos a gerar facturas estão a ser geradas", "alert-info", true);
    const facturas = this.facturasChecked;
    var buffers = [];
    for (let index = 0; index < facturas.length; index++) {
      const factura = facturas[index];
      const dados = await this.getFactura(factura.id);
      const buffer = await this.selectTipoFactura(factura.tipo_factura, dados, 'buffer');
      buffers.push(buffer);
    }
    const pdfUrl = await this.configService.mergePdfBuffers(buffers);
    this.loading = false;
    window.open(pdfUrl, '_blank');
  }

  async selectTipoFactura(type: string, dados: any, report = 'buffer') {
    return type == 'CICLO' ?
      await this.facturaCicloService.imprimirFacturaCiclo(dados.factura, dados.produtos, dados.cliente, dados.tipos_identidades, dados.user, dados.consumo, dados.pagamentos, dados.contrato, dados.leituras, dados.contabancaria, dados.lojas, dados.conta_corrente, dados.historico, dados.mensagem_cliente, null, report, "2ª Via")
      : await this.facturaServicoService.imprimirFacturaServico(dados.factura, dados.produtos, dados.cliente, dados.tipos_identidades, dados.user, dados.pagamentos, dados.contrato, null, dados.contabancaria, dados.lojas, dados.mensagem_cliente, report, "2ª Via")
  }

 filtrarArrayByMonth(array, mes) {
    return array.filter(function (val) {
        //return val.periodo_consumo_fim.getMonth() + 1 === mes;
        return Number(moment(val.periodo_consumo_fim).format("MM")) + 1 === mes;
    });
  }
  private historicoConsumos(historico_consumos: [], periodo_consumo_fim) {
    console.log('historico_consumos = >',historico_consumos)
    var MediaConsumoDoMes = []
          if (historico_consumos) {
              var ConsumosDoMes = []


              for (let i = 1; i <= 12; i++) {
                  ConsumosDoMes.push(this.filtrarArrayByMonth(historico_consumos, i))
              }
              console.log('ConsumosDoMes ==>',ConsumosDoMes)
              ConsumosDoMes.forEach((element) => {
                  const somatorioDoConsumo = element.reduce((acum, ele) => {
                      return ele.consumo + acum
                  }, 0)
                  if (somatorioDoConsumo === 0) {
                      MediaConsumoDoMes.push((somatorioDoConsumo))
                  } else {
                      MediaConsumoDoMes.push((somatorioDoConsumo).toFixed(1))
                  }

              })
          }
          console.log('MediaConsumoDoMes ==>',MediaConsumoDoMes)
      return MediaConsumoDoMes == null ? null : MediaConsumoDoMes
    /* var ConsumosDoMes = [];
    var MediaConsumoDoMes = [];
    for (let i = 1; i <= 12; i++) {
      ConsumosDoMes.push(filtrarArray(historico_consumos, i))
    }

    ConsumosDoMes.forEach((element) => {
      const somatorioDoConsumo = element.reduce((acum, ele) => {
        if (ele.tipo == "LEITURA") {
          return ele.consumo + acum
        }
        else {
          return 0
        }
      }, 0)
      if (somatorioDoConsumo === 0) {
        MediaConsumoDoMes.push((somatorioDoConsumo))
      }
      else {
        MediaConsumoDoMes.push((somatorioDoConsumo).toFixed(1))
      }
    });
    function filtrarArray(array, mes) {
      return array.filter(function (val) {
        return Number(moment(val.periodo_consumo_fim).format("MM")) === mes;
      });
    }
    return MediaConsumoDoMes.length > 0 ? MediaConsumoDoMes : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] */
  }
}
