import {
    SocketService
} from './../../../services/socket/socket.service';
import {
    HttpService
} from 'src/app/providers/http/http.service';
import {
    Component,
    OnInit
} from "@angular/core";
import {
    ConfigService
} from "src/app/providers/config/config.service";
import {
    FacturaService
} from "../factura.service";
import * as WSClient from "@adonisjs/websocket-client"
import {
    environment
} from 'src/environments/environment'
import {
    MgxCircularProgressBarModule,
    MgxCircularProgressFullBarModule,
    MgxCircularProgressPieModule
} from 'mgx-circular-progress-bar';
import * as moment from 'moment';
@Component({
    selector: "app-pre-facturacao",
    templateUrl: "./pre-facturacao.component.html",
    styleUrls: ["./pre-facturacao.component.css"]
})
export class PreFacturacaoComponent implements OnInit {
    loading: boolean
    process_estimativa: boolean
    rotas: any = []
    rota: any = {}
    roteiros: any = []
    medicoes: any = []
    contratos: any = []
    municipios: any = []
    bairros: any = []
    clientes_estimativas: any = []
    pendente: number = 0
    realizado: number = 0
    validateButton: boolean = true;
    rota_header_id: number
    agendamento_id: number
    bairro_id: number
    periodo: any = moment(new Date()).format('YYYY-MM-DD')
    quarteiroes: any = []
    distritos: any = []
    ruas: any = []
    /*
    processo_avenca: any = {
    clientes: {},
    periodo: moment(new Date()).format('YYYY-MM-DD')
    }
    */
    socket
    ws_channel
    progress_estimativa = 0
    current_slug: string = 'TODOS'
    private progresso = 0
    constructor(
        private http: HttpService,
        private configService: ConfigService,
        private _facturaService: FacturaService
    ) {
        this.loadingMethods()
        this._resetFilters()
    }
    ngOnInit() {
        this.loading = false
        //this.initWSClient()
    }

    /*
    initWSClient() {
        const url_ws = `ws:${environment.base_ws_url}`
        this.socket = WSClient(url_ws, {})
        this.socket.connect()
        this.ws_channel = this.socket.subscribe('channel_facturacao_estimativa')
        this.ws_channel.on("message", event => {
            let value = event.percent.toFixed(2);
            this.process_estimativa = value
            this.progresso = value;
            if (value == 100) {}
        })
    }
  
  
    ngOnDestroy() {
        this.ws_channel.close();
    }
    */

    _changeTipoFacturacao(slug: string) {
        this.current_slug = slug
        this.validateButton = true;


        /*
        if (slug == 'ESTIMATIVA') {
            this.process_estimativa = true
            this.getPageFilterData(1)
            this._get_clientes_estimativas()
        } else if (slug == 'AVENCA') {
            this.process_estimativa = true
            this.getPageFilterData(1)
        } else {
            this.process_estimativa = false
            this.clientes_estimativas = []
        }
        */
    }

    getPageFilterData(page: number) {
        if (this.http.filters.pagination.perPage == null) {
            this.http.filters.pagination.page = 1;
            return;
        }
        this.http.filters.pagination.page = Number.isInteger(page) ? page : 1;
        this._get_clientes_estimativas()
    }

    _resetFilters() {
        this.http.filters.distrito_id = null;
        this.http.filters.bairro_id = null;
        this.http.filters.rua_id = null;
        this.http.filters.quarteirao_id = null;
    }
    _get_clientes_estimativas() {
        this.loading = true
        this.http.__call('clientes_estimativas', {
            filters: this.http.filters
        }).subscribe(
            response => {
                this.http.filters.pagination.lastPage = Object(response).data.data.lastPage;
                this.http.filters.pagination.page = Object(response).data.data.page;
                this.http.filters.pagination.total = Object(response).data.data.total;
                this.http.filters.pagination.perPage = Object(response).data.data.perPage;
                this.clientes_estimativas = Object(response).data.data.data;
                this.loading = false
            }
        )
    }

    _getRoteirosByRotaId(id: number) {
        this.loading = true

        let current_filter = {
            rota_header_id: id,
            current_slug: this.current_slug
        }

        this._facturaService.getRoteirosComTipos(current_filter)
            .subscribe(
                response => {
                    let data = response.data
                    if (data != null) {
                        this.realizado = data.roteiros.filter(objt => objt.processada).length
                        this.pendente = data.roteiros.filter(objt => !objt.processada).length
                        this.rota = data.rota
                        this.roteiros = data.roteiros
                        this.rota_header_id = data.rota.id
                        this.agendamento_id = null
                    }
                    this.loading = false
                }
            )
    }
    _changemunicipio_id(id: number) {
        this.loading = true
        this.clientes_estimativas = []
        this._get_bairros(id)
        this.loading = false
    }
    _get_bairros(municipio_id: number) {
        this._facturaService.getBairros(municipio_id)
            .subscribe(
                response => {
                    this.bairros = response
                }
            )
    }
    _getClientesBybairro_id(id: number) {
        this.loading = true
        console.log(id)
        this._facturaService.getClientesByBairro(id)
            .subscribe(
                response => {
                    this.bairro_id = id
                    this.http.filters.pagination.lastPage = Object(response).data.lastPage;
                    this.http.filters.pagination.page = Object(response).data.page;
                    this.http.filters.pagination.total = Object(response).data.total;
                    this.http.filters.pagination.perPage = Object(response).data.perPage;
                    this.clientes_estimativas = Object(response).data;
                    this.loading = false
                }
            )
    }
    _initProcessClientesEstimativas() {
        this.loading = true
        this.validateButton = false;
        if (this.current_slug == 'ESTIMATIVA') {
            this._facturaService.precessPreFacturaEstimativa(this.periodo, this.http.filters)
                .subscribe(
                    response => {
                        this.configService.showAlert("Pré-facturação processada com sucesso - ESTIMATIVA", "alert-success", true)
                        this.loading = false
                        this.clientes_estimativas = []
                        this.loadingMethods()
                    }, error => {
                        this.configService.showAlert('Pré-Faturação em Processo..!', "alert-success", true);
                        this.loading = false;
                    })
        }

    }

    _initProcess() {
        this.loading = true
        this.validateButton = false;
        if (!this.roteiros.length) {
            this.configService.showAlert("Rota sem leituras para serem processadas", "alert-danger", true)
            return
        }
        this._processamentoPrefacturacao()
    }
    loadingMethods() {
        this.loading = true
        this.process_estimativa = false
        this._get_municipios()
        this._facturaService.getMedicoes()
            .subscribe(
                response => {
                    this.medicoes = response.data.data
                    this.loading = false
                })
        this._facturaService.getRotas()
            .subscribe(
                response => {
                    this.rotas = response.data.data
                    this.loading = false
                })
    }
    _updateMediaConsumo() {
        this.loading = true
        this._facturaService.updateMediaConsumo(this.contratos)
            .subscribe(response => {
                this._facturaService._closeModal('closeModalMediaConsumo')
                this._processamentoPrefacturacao()
                this.loading = false
                this.realizado = 0
                this.pendente = 0
            })
    }
    _processamentoPrefacturacao() {
        this.validateButton = false;
        this._facturaService.precessPreFactura(
            this.roteiros,
            this.rota_header_id,
            this.agendamento_id,
            this.current_slug
        )
            .subscribe(
                response => {
                    this.configService.showAlert("Pré-facturação processada com sucesso", "alert-success", true)
                    this.loading = false
                    this.roteiros = []
                    this.pendente = 0
                    this.realizado = 0
                    this.loadingMethods()
                })
    }
    _get_municipios() {
        this._facturaService.getMunicipios()
            .subscribe(
                response => {
                    this.municipios = response
                    this.loading = false
                })
    }
    validateDate(event: any) {
        /*
        if (moment(event.target.value).format('YYYY-MM-DD') > moment(new Date()).format('YYYY-MM-DD')) {
        this.periodo = moment(new Date()).format('YYYY-MM-DD')
        this.configService.showAlert(
        'A data não deve ser maior que actual.',
        'alert-error', true);
        return;
        }
        */
    }
    //==== filtros para estimativas ====
    _getMunicipios() {
        this.http.__call('municipio/listagem', this.http.filters)
            .subscribe(response => {
                this.municipios = Object(response).data.data
            }),
            (error) => {
                if (!error.ok) {
                    console.log(error)
                }
            }
    }
    _changeDistrito(municipio_id) {
        this.distritos = [];
        this.bairros = [];
        this.quarteiroes = []
        this.ruas = []
        this._resetFilters()
        if (municipio_id) {
            this._getComuna(municipio_id);
        }
    }
    _changeBairros(distrito_id) {
        this.quarteiroes = []
        if (distrito_id) {
            this._getBairros(distrito_id);
        }
    }
    _changeQuarteirao(bairro_id) {
        this.quarteiroes = []
        this._get_clientes_estimativas()
        if (bairro_id) {
            this._getQuarteirosDoBairro(bairro_id)
        }
    }
    _changeRuas(quarteirao_id) {
        this.ruas = []
        this._get_clientes_estimativas()
        if (quarteirao_id) {
            this._getRuasDoQuarteirao(quarteirao_id)
        }
    }
    _changeClientes(id: any) {
        this._get_clientes_estimativas()
    }
    _getBairros(id) {
        this.http.__call('get-bairros/' + id, this.http.filters)
            .subscribe(response => {
                this.bairros = Object(response).data
            }),
            (error) => {
                if (!error.ok) {
                    console.log(error)
                }
            }
    }
    _getComuna(id) {
        this.http.__call('get-distritos/' + id, this.http.filters)
            .subscribe(response => {
                this.distritos = Object(response).data
            }),
            (error) => {
                if (!error.ok) { }
            }
    }
    _getQuarteirosDoBairro(id) {
        this.http.call_get(`quarteirao/getQuarteiraosByBairro/${id}`, null)
            .subscribe(
                response => {
                    this.quarteiroes = Object(response).data
                }
            )
    }
    _getRuasDoQuarteirao(id) {
        this.http.call_get(`rua/getRuasByQuarteirao/${id}`, null)
            .subscribe(
                response => {
                    this.ruas = Object(response).data
                }
            )
    }
}