import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ResumoFaturacaoService {

  public filterSearchBar:Object={
    data1: null,
    data2:null,
    distrito_id: 'null'
  }

  constructor() { }
}
