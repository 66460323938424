import { Component, OnInit, Input, createPlatformFactory, OnDestroy } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { ExcelService } from 'src/app/services/excel.service';
import * as moment from 'moment';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-contexto-configuracao',
  templateUrl: './contexto-configuracao.component.html',
  styleUrls: ['./contexto-configuracao.component.css']
})
export class ContextoConfiguracaoComponent implements OnInit, OnDestroy {

  @Input() simpleFormContextoConfiguracao: FormGroup;

  private contexto_configuracao = {
    id: null,
    nome: null,
    campo: null,
    is_delected: null,
    //user_id: null
  };


  public currentUser: any;

  private title: string = "Registar Contexto de Configuração";

  private input_default: boolean = false;
  private input_required: boolean = false;

  private items: any = [];
  private info_schemas: any = [];
  private info_tables: any = [];

  constructor(private http: HttpService, private configService: ConfigService, private excelService: ExcelService, private auth: AuthService) {
    this.currentUser = this.auth.currentUserValue;
  }



  exportAsXLSX(): void {
    var CurrentDate = new Date();
    var nameFile = "lista_contexto_configuracoes -" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    this.excelService.exportAsExcelFile(document.getElementsByClassName("exportAsXLSXCliente")[0], nameFile);
  }
  exportAsPDF(): void {
    //this.reportLoja.relatorioLoja(this.cobrancas, this.simpleForm.value, 'save');
  }

  imprimirPDF(): void {
    //this.reportLoja.relatorioLoja(this.cobrancas, this.simpleForm.value);
  }

  ngOnInit() {
    this.http.filters.search = null
    this.getPageFilterData(1);

    this.selectBoxInfoSchemas();

    /*  if (this.info_schemas.length <= 0) {
       this.selectBoxInfoSchemas();
     } */
  }

  ngOnDestroy(){
    this.http.filters.orderBy = null
  }

  private getDadosModal() {

  }

  private getContextoConfiguracaos() {

    this.configService.loaddinStarter('start');

    this.http.__call('contexto-configuracao/listagem', this.http.filters).subscribe(

      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.configService.loaddinStarter('stop');

      }
    );
  }



  private saveContextoConfiguracao() {
    console.log(this.contexto_configuracao);

    if (this.contexto_configuracao.id == null) {

      this.http.__call('contexto-configuracao/create', {
        nome: this.contexto_configuracao.nome,
        campo: this.contexto_configuracao.campo,
        is_delected: this.contexto_configuracao.is_delected
        //user_id: this.currentUser.user.id
      }).subscribe(
        res => {
          if (Object(res).code == 200) {
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            /*    this.clearFormInputs();
               this.listarRotaRunByRotaHeader(); */

            this.configService.loaddinStarter('stop');
          } else {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
            this.configService.loaddinStarter('stop');
          }
        }
      )
    }
    else {

      this.http.__call('contexto-configuracao/update/' + this.contexto_configuracao.id, {
        nome: this.contexto_configuracao.nome,
        campo: this.contexto_configuracao.campo,
        is_delected: this.contexto_configuracao.is_delected,
        //user_id: this.currentUser.user.id
      }).subscribe(
        res => {
          if (Object(res).code == 200) {
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            /*    this.clearFormInputs();
               this.listarRotaRunByRotaHeader(); */

            this.configService.loaddinStarter('stop');
          } else {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
            this.configService.loaddinStarter('stop');
          }
        }
      )
    }

  }


  private setDataContextoConfiguracao(item) {

    if (item !== undefined) {
      this.title = "Editar Contexto de Configuração";

      this.contexto_configuracao.id = item.id;
      this.contexto_configuracao.nome = item.nome;
      this.contexto_configuracao.campo = item.campo;
      this.contexto_configuracao.is_delected = item.is_delected;
      //this.contexto_configuracao.user_id = item.user_id;

    this.selectBoxInfoTablesByInfoSchema();
    }

  }


  private selectBoxInfoSchemas() {
    this.http.call_get('contexto-configuracao/getInformationSchema', null).subscribe(
      response => {
        //console.log(Object(response));

        this.info_schemas = Object(response);
      }
    );
  }

  private selectBoxInfoTablesByInfoSchema() {

    console.log(this.contexto_configuracao);

    this.http.call_get('contexto-configuracao/getTableDescription/' + this.contexto_configuracao.nome, null).subscribe(
      response => {
        //console.log(Object(response).data);

        this.info_tables = Object(response);
      }
    );
  }

  private getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
    this.getContextoConfiguracaos();
  }

}
