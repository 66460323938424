import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { ConfigModuloService } from 'src/app/services/config-modulo.service';
import { environment } from 'src/environments/environment';
import { HttpService } from 'src/app/providers/http/http.service';
import { ModalOrdemServicoService } from '../../ordemservico/modal-create-ordem-trabalho/modal-ordem-servico.service'
import { ModalListOrdemServicoService } from '../../ordemservico/listar-ordem-servico/modal-ordem-servico.service';
@Component({
  selector: 'app-asidenavbar',
  templateUrl: './asidenavbar.component.html',
  styleUrls: ['./asidenavbar.component.css']
})
export class AsidenavbarComponent {

  @Input() app_environment: null;
  private quarteirao_view: boolean = false;
  private distrito_view: boolean = false;
  private user_chefe_loja: Object = null
  public currentUser: any;
  public lojas:any = []
  constructor(
    private auth: AuthService,
    private configService: ConfigService,
    private flag: ModalOrdemServicoService,
    private checkOrdemServico: ModalListOrdemServicoService,
    private config: ConfigModuloService,
    private http: HttpService
  ) {
    this.currentUser = this.auth.currentUserValue;

    this.app_environment = this.auth.getAppEnvironment();

    if (this.app_environment == null) {

      var url = require('url');
      var app_url = url.parse(environment.app_url, true);

      this.app_environment = app_url.host;

    }
    this.user_chefe_loja = (
        (JSON.parse(localStorage.getItem('_token')) || {}).user || {}).id

    this.getConfiguracaos();
    //console.log("this.app_environment " + this.app_environment);

    }

  canActivateRouterLink(permission: string): boolean {
    return this.auth.canActivateRouterLink(permission);
  }
  openModal() {
    this.getLojas()
    this.user_chefe_loja = ((JSON.parse(localStorage.getItem('_token')) || {}).user || {}).id
  }

  changeFlagTrigger(flag: string) {
    console.log("asidebar flag")
    this.flag.flag = flag
  }

  ngOnInit() {
    //  this.isOrdem()
    //this.app_environment = this.auth.getAppEnvironment();
    /*
       if (this.app_environment == null) {

         var url = require('url');
         var app_url = url.parse(environment.app_url, true);

         this.app_environment = app_url.host;

         this.config.saveConfig(app_url.host, this.config.modulo.CONFIGURACOES, app_url.host);
       }

       console.log("this.app_environment " + this.app_environment); */
  }


  getLojas() {
    let user_id = ((JSON.parse(localStorage.getItem('_token')) || {}).user || {}).id
    console.log(user_id,'user_id')
    const uri = 'loja/abertura-lista/'+ user_id;
       // TODO: usado para fazer a requisição com a api de criação de organismo
       this.http.__call(uri, null).subscribe(
        res => {
          this.lojas = Object(res)
          console.log('this.lojas',this.lojas)
        });
  }

  public isOrdem(value = true) {
    this.checkOrdemServico.checkIfIsOrdemServico = value
  }
  public setPermission(permission) {
    this.configService.setPermission(permission);
  }
  public getConfiguracaos() {

    let result = null;

    const slugs = [
      this.config.quarteirao_view,
      this.config.distrito_view
    ];

    for (let index = 0; index < slugs.length; index++) {

      //console.log(slugs[index]);
      this.http.__call('configuracao/getConfiguracaobySlug/' + slugs[index], null).subscribe(
        response => {
          if (Object(response).code != 200) {
            result = null;
          }
          else {
            result = Object(response).data;
            if (slugs[index] == this.config.quarteirao_view) this.quarteirao_view = Boolean(result.valor);

            if (slugs[index] == this.config.distrito_view) this.distrito_view = Boolean(result.valor);
          }
        });
    }
  }

}
