import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reclamacoes-relatorio',
  templateUrl: './reclamacoes-relatorio.component.html',
  styleUrls: ['./reclamacoes-relatorio.component.css']
})
export class ReclamacoesRelatorioComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
