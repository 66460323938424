import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js';

import { AuthService } from 'src/app/providers/auth/auth.service';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

@Component({
  selector: 'app-relatorio-financeiro',
  templateUrl: './relatorio-financeiro.component.html',
  styleUrls: ['./relatorio-financeiro.component.css']
})
export class RelatorioFinanceiroComponent implements OnInit {


  private dash = {
    clienteCount: 0,
    facturaCount: 0,
    produtoCount: 0,
    documentoCount: 0,


    facturaSumTotalSemImpostoHoje: 0,
    facturaSumTotalSemImpostoMes: 0,
    facturaSumTotalSemImpostoOntem: 0,
    facturaSumTotalSemImpostoMesAnterior: 0,
    facturaSumTotalSemImpostoGeral: 0,

    facturaSumTotalComImpostoHoje: 0,
    facturaSumTotalComImpostoMes: 0,
    facturaSumTotalComImpostoOntem: 0,
    facturaSumTotalComImpostoMesAnterior: 0,
    facturaSumTotalComImpostoGeral: 0
  }


  public pieChartLabels = ['Sales Q1', 'Sales Q2', 'Sales Q3', 'Sales Q4'];
  public pieChartData = [120, 150, 180, 90];
  public pieChartType = 'pie';

  private meio: any =['Dih']

  myChart: any = []

  constructor(private auth: AuthService, private http: HttpService, private configService: ConfigService) {
  }

  ngOnInit() {
    this.dashboard();
    this.myChart = new Chart('myChart', {
      type: 'bar',
      data: {
        labels: ['Facturas pagas', 'Facturas não pagas', 'Notas de crédito', 'Recibo'],
        datasets: [{
          label: '# Conta corrente por clientes ',
          data: this.pieChartData,
          backgroundColor: [
            'rgb(40, 167, 69)',
            'rgb(0, 123, 255)',
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(255, 206, 86, 1)',
            'rgb(222, 226, 230)'
          ],
          borderColor: [
            'rgb(40, 167, 69)',
            'rgb(0, 123, 255)',
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(255, 206, 86, 1)',
            'rgb(222, 226, 230)'
          ],
          borderWidth: 1
        }]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    });
  }

  private dashboard() {
    this.configService.loaddinStarter('start');
    this.http.call_get('dashboard/listar', null).subscribe(
      response => {
        this.dash.clienteCount = Object(response).data.clienteCount;
        this.dash.produtoCount = Object(response).data.produtoCount;
        this.dash.facturaCount = Object(response).data.facturaCount;
        this.dash.documentoCount = Object(response).data.documentoCount;


        this.dash.facturaSumTotalSemImpostoHoje = Object(response).data.facturaSumTotalSemImpostoHoje;
        this.dash.facturaSumTotalSemImpostoOntem = Object(response).data.facturaSumTotalSemImpostoOntem;
        this.dash.facturaSumTotalSemImpostoMes = Object(response).data.facturaSumTotalSemImpostoMes;
        this.dash.facturaSumTotalSemImpostoMesAnterior = Object(response).data.facturaSumTotalSemImpostoMesAnterior;
        this.dash.facturaSumTotalSemImpostoGeral = Object(response).data.facturaSumTotalSemImpostoGeral;


        this.dash.facturaSumTotalComImpostoHoje = Object(response).data.facturaSumTotalComImpostoHoje;
        this.dash.facturaSumTotalComImpostoOntem = Object(response).data.facturaSumTotalComImpostoMes;
        this.dash.facturaSumTotalComImpostoMes = Object(response).data.facturaSumTotalComImpostoMes;
        this.dash.facturaSumTotalComImpostoMesAnterior = Object(response).data.facturaSumTotalComImpostoMesAnterior;
        this.dash.facturaSumTotalComImpostoGeral = Object(response).data.facturaSumTotalComImpostoGeral;
        this.configService.loaddinStarter('stop');
      }
    );
  }
}
