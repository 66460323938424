import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { BsModalService, BsModalRef, ModalDirective, ModalModule } from 'ngx-bootstrap/modal';
import { ConfigService } from 'src/app/providers/config/config.service';
import { AuthService } from 'src/app/providers/auth/auth.service';

@Component({
  selector: 'app-log-ot-fiscalizacao',
  templateUrl: './log-ot-fiscalizacao.component.html',
  styleUrls: ['./log-ot-fiscalizacao.component.css']
})
export class LogOtFiscalizacaoComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('modalAppendServico') modalAppendServico: ModalDirective;

  loadingListarFacturacao = false;
  loadingListarFacturas = false;

  @ViewChild('modalShowEvent') public modalShowEvent: ModalDirective;

  private filter = {
    municipio_id: null,
    distrito_id: null,
    bairro_id: null,
    quarteirao_id: null
  }
  public filters = {
    search: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    pagination: {
      perPage: 10,// Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima,
      lastPage: null,
      total: null // defini o total de registo da BD
    },
    filter: null // subelemente do filter
  }
  private logs: any = [];
  checkedAll: boolean = true;
  loading: boolean = false;
  private facturasIncumprimento: any = [];

  private dadosClienteSelecionado = {
    nome: null,
    totaldivida: null
  }

  private cliente: any = {}


  private dashboard = {
    totalContratos: null,
    totalFacturas: null,
    totaldividas: null,
  };
  distritos: any[]
  municipios: any[]
  bairros: any[]
  quarteiroes: any[]

  constructor(
    private auth: AuthService,
    private http: HttpService,
    private configService: ConfigService
  ) { }

  ngOnInit() {
    this.getPageFilterData(1);
    //this.dashboardFacturacao();
    this.listagem();

    this.http.filters.pagination.perPage = 5
    this.http.filters.pagination.page = 5
    this.getMunicipios()
  }

  private getMunicipios() {

    this.municipios = []
    this.filter.distrito_id = null
    this.filter.bairro_id = null
    this.http.call_get('municipio/selectBox', null)
      .subscribe(
        response => {
          this.municipios = Object(response).data;
        }
      );
  }


  private selectBoxDistritosByMunicipio(id) {
    this.distritos = [];
    this.filter.bairro_id = null
    if (!id || id == 'null') return
    this.http.call_get('distrito/getDistritosByMunicipio/' + id, null).subscribe(
      response => {
        this.distritos = Object(response).data;
      }
    );
  }

  private selectBoxBairrosByDistrito(id) {
    this.bairros = []
    console.log(id)

    if (!id || id == 'null') return
    this.http.call_get('bairro/selectBoxByDistrito/' + id, null).subscribe(
      response => {
        this.bairros = Object(response);
      }
    );
  }

  _getQuarteirosDoBairro(id) {
    this.http.call_get(`quarteirao/getQuarteiraosByBairro/${id}`, null)
      .subscribe(
        response => {
          this.quarteiroes = Object(response).data
        }
      )
  }


  private dashboardFacturacao() {
    this.http.call_get('contrato/dashboardIncumprimentos', null).subscribe(
      response => {
        this.somaTotaisDoDashboard(Object(response).data)
      }
    )
  }

  async retornarFacturaIncumprimetoPorContrato(contrato_id: any, nome: any) {
    this.loadingListarFacturas = true
    this.dadosClienteSelecionado.nome = nome + ' - ' + contrato_id;
    await this.http.call_get('facturasIncumprimento/contratos/' + contrato_id, null).subscribe(
      response => {
        this.facturasIncumprimento = Object(response).data
        this.somaTotais(this.facturasIncumprimento)
        this.loadingListarFacturas = false
      }
    )
  }

  private get incumprimentosChecked(): any[] {
    return this.logs.filter(val => val.checked === true);
  }

  setCheckedAll(checked: boolean) {
    this.checkedAll = checked;
    this.logs.forEach(f => f.checked = checked);
  }

  updateChecked(f: any) {
    this.logs.forEach(element => {
      element.checked = element.contrato_id === f.contrato_id ? !f.checked : element.checked;
      Object.assign(element);
    });
    const lengthFalse = this.logs.filter(val => val.checked === true);
    this.checkedAll = lengthFalse.length === this.logs.length ? true : false;
  }

  private async criarOrdemServico() {

    if (this.logs === 0) {
      this.configService.showAlert("Nenhuma linha seleccionada", "alert-error", true);
      return;
    }
    if (this.incumprimentosChecked.length == 1) {
      this.configService.showAlert("Seleccione no minimo duas ou mais linhas", "alert-error", true);
      return;
    }

    this.configService.showAlert("A processar...", "alert-info", true);
    this.modalShowEvent.show();
    const incumprimentos = this.incumprimentosChecked;
    //console.log(incumprimentos)
  }

  private closeModal() {
    this.modalAppendServico.hide();
  }

  private contrato: any = [];

  private setDataContrato(item) {
    this.contrato = item;
  }

  private notificarCliente() {
    const data = {
      cliente_id: this.cliente.cliente_id,
      contrato: this.cliente.contrato_id
    }

    this.http.__call('api/v1/notifications/customer', data).subscribe(response => {
      //console.log(response)
    })
  }

  private notificarClienteModal(item) {
    this.cliente = item
    //console.log(item)
  }

  getPageFilterData(page: number) {
    if (this.filters.pagination.perPage == null) {
      return;
    }
    this.filters.pagination.page = Number(page);
    this.listagem();
  }
  private listagem() {
    this.filters.filter = this.filter
    this.loadingListarFacturacao = true
    this.http.__call('api/v1/notifications/logs_os_fiscalizacao/listagem', { filters: this.filters }).subscribe(
      response => {
        this.filters.pagination.lastPage = Object(response).data.lastPage;
        this.filters.pagination.page = Object(response).data.page;
        this.filters.pagination.total = Object(response).data.total;
        this.filters.pagination.perPage = Object(response).data.perPage;
        this.logs = Object(response).data.data;
        this.loadingListarFacturacao = false
      }
    );
  }

  private cliente_notifications: any[];
  private getClientesNofications(id) {
    this.loadingListarFacturacao = true
    this.http.call_get("api/v1/notifications/clientes/" + id)
      .subscribe(res => {
        this.cliente_notifications = Object(res);
        this.loadingListarFacturacao = false
      })
  }

  private somaTotais(dados) {
    var total_divida: number = 0;
    dados.forEach(element => {
      total_divida += element.valor_aberto;
    });
    this.dadosClienteSelecionado.totaldivida = total_divida;
  }

  private somaTotaisDoDashboard(dados) {
    var total_contratos: number = 0;
    var total_facturas: number = 0;
    var total_Dividas: number = 0;
    dados.forEach(element => {
      total_facturas += element.numero_facturas;
      total_Dividas += element.total_divida;
    });
    total_contratos = dados.length;
    this.dashboard.totalContratos = total_contratos;
    this.dashboard.totalFacturas = total_facturas;
    this.dashboard.totaldividas = total_Dividas;

  }

}
