import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from 'src/environments/environment'


@Injectable({
    providedIn: 'root'
})

export class TipoClienteService {

    private token = localStorage.getItem('sessionToken')

    private headers = new HttpHeaders()
    .set('content-type', 'application/json')
    .set('Access-Control-Allow-Origin', '*')
    .set('Authorization', `Bearer ${this.token}`)

    constructor(
        private _http_client: HttpClient
    ){}

    getTipoCliiente(id: number) {
        return   this._http_client.get<any>(`${environment.app_url}api/${environment.apiVersion}/tipos_de_clientes${id}`, { 'headers': this.headers })
    }

    getTipoCliientes() {
        return   this._http_client.get<any>(`${environment.app_url}api/${environment.apiVersion}/tipos_de_clientes`, { 'headers': this.headers })
    }

    CreateTipoCliente(new_objet: any) {
        return   this._http_client.post<any>(`${environment.app_url}api/${environment.apiVersion}/tipos_de_clientes`, new_objet, { 'headers': this.headers })
    }

    UpdateTipoCliente(edit_objet: any) {
        return   this._http_client.patch<any>(`${environment.app_url}api/${environment.apiVersion}/tipos_de_clientes/${edit_objet.id}`, edit_objet, { 'headers': this.headers })
    }
}
