import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators,AbstractControl } from '@angular/forms';

import { ExcelService } from 'src/app/services/excel.service';
import { ReportLojaService } from 'src/app/components/report-at/relatorios/financeira/report-loja.service';

import * as moment from 'moment';
import { ExcelAutoService } from 'src/app/services/excel/excel-auto.service';
@Component({
  selector: 'app-report-loja',
  templateUrl: './report-loja.component.html',
  styleUrls: ['./report-loja.component.css']
})
export class ReportLojaComponent implements OnInit {


  loading: boolean = false;
  private meses = [
    { nome: "Janeiro", numero: "01" },
    { nome: "Fevereiro", numero: "02" },
    { nome: "Março", numero: "03" },
    { nome: "Abril", numero: "04" },
    { nome: "Maio", numero: "05" },
    { nome: "Junho", numero: "06" },
    { nome: "Julho", numero: "07" },
    { nome: "Agosto", numero: "08" },
    { nome: "Setembro", numero: "09" },
    { nome: "Outubro", numero: "10" },
    { nome: "Novembro", numero: "11" },
    { nome: "Dezembro", numero: "12" }
  ];
  private dias = []
  public filters = {
    search: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    pagination: {
      perPage: 5,// Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima,
      lastPage: null,
      total: null // defini o total de registo da BD
    },
    filter: null // subelemente do filter
    ,is_allexel:false
  }

  private localUrl: any;
  private largura: any;
  private altura: any;
  private cobrancas: any = [];
  private lojas: any = [];
  private filials: any = [];
  private anos: any = [];
  private loadingLojas: string = "Agência"



  private disabledButton = true

  submitted = false;
  simpleForm: FormGroup;

  constructor(private formBuilder: FormBuilder, private http: HttpService, private configService: ConfigService, private excelService: ExcelAutoService, private reportLoja: ReportLojaService) {
    this.createForm();
  }

  ngOnInit() {

    this.filtros = null;
    this.gerarAno();
    this.getFilials();
    this.empresaUser()
  }

  private filtros = {
    ano: null,
    mes: null,
    dia: null,
    loja: null,
    filial: null,
    estado: null,
    loja_nome: null,
    data1: null,
    data2: null
  }

  createForm() {
    this.simpleForm = this.formBuilder.group({
      ano: [null],
      mes: [null],
      dia: [null],
      loja: [null],
      filial: [null],
      estado: [null],
      loja_nome: [null],
      data1: [null, Validators.required],
      data2: [null, [this.matchValidator.bind(this)]]
    });
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.simpleForm.controls;
  }
  matchValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const fromValue = control.value;
    if(fromValue) {

     // console.log(this.simpleForm.controls)
      const startDate = (<FormGroup>this.simpleForm.get('data1')).value;
      const endDate = (<FormGroup>this.simpleForm.get('data2')).value;
      if (startDate <= endDate) {
        //console.log('Control: ', control);
       return null;
      }
      //console.log('Control: ', control);
      return { 'invalidDate' : true };
    }

  }

  onSubmit() {

    this.submitted = true;
    // parar aquei se o simpleFormulário for inválido
    if (this.simpleForm.invalid) {
      return;
    }
    const uri = 'relatorio/financeira/loja';
    this.createOrEdit(uri, this.simpleForm);
  }

  /**
  * @name "relatorioFacturacaoRealizadaCobrancaGlobal"
  * @descriptio "Esta Função permite gerar relatorio Facturacao Realizada Cobrança Global"
  * @author "caniggiamoreira@gmail.com"
  * @param start
  * @param end
  */
 private empresaUser() {
  this.configService.loaddinStarter('start');

  this.http.call_get('empresa/empresa-user', null).subscribe(
    response => {
      this.localUrl = Object(response).data.logotipo
      this.altura = Object(response).data.width
      this.largura = Object(response).data.height
      this.configService.loaddinStarter('stop');
    }
  );
}

setTrue(){
  this.filters.pagination.page=this.filters.pagination.page
  this.filters.is_allexel=true
  this.onSubmit()
}
  createOrEdit(uri: any, simpleFormulario: FormGroup) {
    this.filtros = this.simpleForm.value;
    this.cobrancas = []
    this.loading = true;
    this.totais.total = 0;
    this.totais.valor_total_aberto = 0
    this.disabledButton = true;
    // TODO: usado para fazer a requisição com a api de criação de organismo
    this.http.__call(uri, simpleFormulario.value).subscribe(
      response => {
        this.cobrancas = Object(response).data;
        this.loading = false;
        if (this.cobrancas.length != 0) {
          this.disabledButton = false;
          this.somaTotais();
        }
        if(this.filters.is_allexel==false){
          this.cobrancas = Object(response).data;
        }else{
          this.cobrancas = Object(response).data;
          this.exportAsXLSX(this.cobrancas)
        }
        this.filters.is_allexel=false
        this.loading = false;
      }
    );
  }

  exportAsXLSX(data:any):void {
    var CurrentDate = new Date();

    var item=[]
   for(let i=0;i<data.length;i++){
     if(data[i].pago!=1){
       var datas={
        factura_sigla:data[i].factura_sigla,
        data:data[i].data,
        cliente_id:data[i].cliente_id,
        nome:data[i].nome,
        total:data[i].total,
        loja:data[i].loja,
        contrato_id:data[i].contrato_id,
        totals:data[i].total-data[i].valor_aberto,
        status:data[i].status="Não Saldado",
       }
       item.push(datas)
     }else{
       datas={
      factura_sigla:data[i].factura_sigla,
      data:data[i].data,
      cliente_id:data[i].cliente_id,
      nome:data[i].nome,
      total:data[i].total,
      loja:data[i].loja,
      contrato_id:data[i].contrato_id,
      totals:data[i].total-data[i].valor_aberto,
       status:data[i].status="Saldado",
      }

      item.push(datas)
     }

   }
 var keys= [
    { key: 'factura_sigla', width:40, style: { font: { name: 'Calibri' } } },
    { key: 'data', width: 50 },
    { key: 'cliente_id', width: 30 },
    { key: 'nome', width:50, style: { font: { name: 'Calibri' } } },
    { key: 'contrato_id', width: 30 },
    { key: 'loja', width: 30 },
    { key: 'total', width:20,style: { numFmt: '#,##0.00', }},
    { key: 'totals', width:20,style: { numFmt: '#,##0.00', } },
    { key: 'status', width: 20 },
  ];
    var Cols = ["NÚMERO FACTURA",	"DATA"	,"NÚMERO CLIENTE"	,"NOME CLIENTE"	,	"CONTRATO","AGÊNCIA","VALOR",	"VALOR PAGO","ESTADO"]
    var title='RELATÓRIO Agência'
    var nameFile = "Relatório_de_agencia -" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
     this.excelService.excels(item,nameFile,this.localUrl,keys,Cols,title,5,9,40,3,[7,8])
  }


  exportAsPDF(): void {

    var file = document.getElementsByClassName("tableCobranca")[0]
    this.reportLoja.relatorioLoja(file, 'save',this.filtros,this.localUrl);
  }
  
  imprimirPDF(): void {
    var file = document.getElementsByClassName("tableCobranca")[0]
    this.reportLoja.relatorioLoja(file, 'imprimir', this.filtros,this.localUrl);
  }



  private gerarAno() {
    var fecha = new Date();
    var anyo = fecha.getFullYear();

    let j = 0;
    for (let i = anyo; i >= 2000; i--) {
      this.anos[j] = i;
      j++;
    }
    j = 0;
    for (let i = 1; i <= 31; i++) {
      this.dias[j] = i;
      j++;
    }
  }



  private getLojas() {
    this.loadingLojas = 'Carregando...';
    const id = this.simpleForm.getRawValue().filial;
    if (id != "" || id != null || id != "T") {
      this.http.call_get('lojas/selectBox/' + id, null).subscribe(
        response => {
          this.lojas = Object(response)
          this.loadingLojas = 'Loja';
        }
      );
    }
  }


  private getFilials() {
      this.http.call_get('municipios/selectBox', null).subscribe(
        response => {
        this.filials = Object(response).data
      }
    );
  }


  private totais = {
    total: 0,
    valor_total_aberto: 0
  }
  private somaTotais() {
    var valor_aberto: number = 0;
    var total: number = 0;
    this.cobrancas.forEach(element => {
      total += element.total;
      valor_aberto += (element.pago == 1 ? (element.total) : (element.total - element.valor_aberto));
    });
    this.totais.total = total;
    this.totais.valor_total_aberto = valor_aberto
  }

  changeLoja() {
    this.simpleForm.patchValue({
      loja_nome: null
    });
    this.lojas.forEach(element => {
      if (element.id == this.simpleForm.getRawValue().loja) {
        this.simpleForm.patchValue({
          loja_nome: element.nome
        });
      }
    });
  }


}
