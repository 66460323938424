import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

@Component({
  selector: 'app-conf-modelo',
  templateUrl: './conf-modelo.component.html',
  styleUrls: ['./conf-modelo.component.css']
})
export class ConfModeloComponent implements OnInit, OnDestroy {

  private modelo = {
    id: null,
    descricao: null,
    slug: null,
    id_marca: null
  }


  private items: any = [];
  private marcas: any = [];

  constructor(private http: HttpService, private configService: ConfigService) { }

  ngOnInit() {
    this.http.filters.search = null
    this.getPageFilterData(1);
    this.listarMarca();
  }
  ngOnDestroy(){
    this.http.filters.orderBy = null
  }


  private listamodelo() {

    this.configService.loaddinStarter('start');

    this.http.__call('modelo/listagem', this.http.filters).subscribe(

      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.configService.loaddinStarter('stop');

      }
    );
  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
    this.listamodelo();
  }


  private register(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (this.modelo.descricao == "" || this.modelo.slug == null) {
      this.configService.showAlert("Os campos Descrição e Slug são obrigatórios", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    } else {
    this.http.__call('modelo/create', this.modelo).subscribe(
      res => {
        if (Object(res).code == 201) {
          this.configService.showAlert(Object(res).message, 'alert-danger', true);
          this.configService.loaddinStarter('stop');
        } else {
          this.configService.showAlert(Object(res).message, 'alert-success', true);
          this.clearFormInputs(e);
          this.listamodelo()
          this.configService.loaddinStarter('stop');
        }
      }
    )
  };
}


  private clearFormInputs(e) {
    e.target.elements[0].value = null;
    e.target.elements[1].value = null;
    e.target.elements[2].value = null;
    e.target.elements[3].value = null;
  }

  private refresh(id, descricao, slug, id_marca) {
    this.modelo.id = id;
    this.modelo.id_marca = id_marca;
    this.modelo.descricao = descricao;
    this.modelo.slug = slug;
  }

  private editar(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (this.modelo.descricao == "" || this.modelo.slug == null) {
      this.configService.showAlert("Os campos Descrição e Slug são obrigatórios", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    } else {
      this.http.__call('modelo/update/' + this.modelo.id, this.modelo).subscribe(
        res => {
          if (Object(res).code == 201) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            //this.configService.clearFormInputs(e);
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.listamodelo();

          }
        }
      );
    }
    this.configService.loaddinStarter('stop');
  }

  private listarMarca() {
    this.http.call_get('marca/selectBox', null).subscribe(
      response => {
        this.marcas = Object(response);
      }
    );
  }

  private ini() {
    this.modelo = {
      id: null,
      descricao: null,
      slug: null,
      id_marca: null
    }
  }

}
