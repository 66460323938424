
import { Component, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { ConfigModuloService } from 'src/app/services/config-modulo.service';
import { HttpService } from 'src/app/providers/http/http.service';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { NullAstVisitor } from '@angular/compiler';

@Component({
  selector: 'app-mapa-rota',
  templateUrl: './mapa-rota.component.html',
  styleUrls: ['./mapa-rota.component.css']
})

export class MapaRotaComponent implements AfterViewInit {

  @ViewChild('mapContainer') gmap: ElementRef;

  public currentUser: any;

  private mapa = {
    leitor_id: null,
    rota_header_id: null
  }


  private rotas_header: any = [];
  private leitores: any = [];

  modulo: string = null;
  provincia_id;

  private rotas;

  map: google.maps.Map;
  lat = null;
  lng = null;

/*
  coordinates = new google.maps.LatLng(this.lat, this.lng);

  mapOptions: google.maps.MapOptions = {
    center: this.coordinates,
    zoom: 8,
  };

  marker = new google.maps.Marker({
    position: this.coordinates,
    map: this.map,
  }); */

  marker: any;

  /* markers = [
    {
      position: new google.maps.LatLng(-12.361790420529694, 13.509253625260923),
      map: this.map,
      title: "Baia Farta",
      description: "Cliente Estélvio Marques"
    },
    {
      position: new google.maps.LatLng(-12.366066262617915, 13.53380120216522),
      map: this.map,
      title: "Porto Seco",
      description: "Porto Seco"
    },
    {
      position: new google.maps.LatLng(-12.358897899459194, 13.54277050911102),
      map: this.map,
      title: "Canata",
      description: "Canata"
    }
  ]; */

  markers: any = [];


  constructor(
    private auth: AuthService,     
    private configService: ConfigService,
    private http: HttpService, 
    private config: ConfigModuloService
    ) {

    this.currentUser = this.auth.currentUserValue;

    /* this.modulo = config.modulo.CONFIGURACOES;

    this.provincia_id = config.getConfig(this.modulo, "provincia_id"); */

  }

  ngAfterViewInit(): void {

    this.selectBoxLeitoresWithRotasHeaders();

    this.mapInitializer();
  }

  async loadAllMarkers() {

    const loadedStateCallback = () => {

      for (let index = 0; index < this.rotas.length; index++) {

        let item = this.rotas[index];

        const markerInfo = {
          position: new google.maps.LatLng(item.latitude, item.longitude),
          map: this.map,
          title: item.moradia_numero,
          description: ""
        };

        const marker = new google.maps.Marker({
          ...markerInfo
        });

        let dist = (item.has_distrito) ? '<br>Distrito ' + item.distrito : '';
        let alocacao = (item.is_predio) ? 'Prédio ' + item.predio_nome + ', ' + this.configService.decimalFormat(item.predio_andar) + 'º Andar - Porta ' + this.configService.decimalFormat(item.moradia_numero) : 'Residência ' + this.configService.decimalFormat(item.moradia_numero);

        var content = '<div style="width: 380px">' +
          '<div style="background-color: #0099FF!important; color: white; padding: 5px; border-radius: 5px; font-size: 22px; " align="center">' + item.cliente + '</div>' +
          '<div style="font-size: 16px"><br>' +
          '<div class="row">' +
            '<div class="col-md-6">Rota ' + item.rota_header +
              '<br>Estado ' + item.estado_rota +
              '<br>Província ' + item.provincia +
              '<br>Município ' + item.municipio +
              dist +
              '<br>Bairro/Zona ' + item.bairro +
              '<br>Rua ' + item.rua +
            '</div>' +
            '<div class="col-md-6">' +
              alocacao +
              '<br>Telefone ' + item.cliente_telefone +
              '<br>Conta ' + item.conta_id +
              '<br>Contador ' + item.numero_serie +
            '</div>' +
          '</div>' +
          '<div class="iw-bottom-gradient"></div>' +
          '</div>';


        //creating a new info window with markers info
        const infoWindow = new google.maps.InfoWindow({
          content: content
        });

        //Add click event to open info window on marker
        marker.addListener("click", () => {
          infoWindow.open(marker.getMap(), marker);
        });

        //Adding marker to google map
        marker.setMap(this.map);

      }

      console.log(this.markers);
      console.log("this.markers: " + this.markers.length);

      /* if (this.markers.length > 0) {


        console.log("markers: " + this.markers.length);
        this.markers.forEach(markerInfo => {
          //Creating a new marker object
          const marker = new google.maps.Marker({
            ...markerInfo
          });

          //creating a new info window with markers info
          const infoWindow = new google.maps.InfoWindow({
            content: marker.getTitle()
          });

          //Add click event to open info window on marker
          marker.addListener("click", () => {
            infoWindow.open(marker.getMap(), marker);
          });

          //Adding marker to google map
          marker.setMap(this.map);
        });

      } */

    }

    await this.getRotas(loadedStateCallback);


    console.log("markers");
    console.log(this.markers);

    /* this.loadMarkersFromRotas(); */

  }



  mapInitializer() {

    this.lat = -7.739212;
    this.lng = 21.251341;


  const coordinates = new google.maps.LatLng(this.lat, this.lng);

  const mapOptions: google.maps.MapOptions = {
    center: coordinates,
    zoom: 8,
  };

  this.marker = new google.maps.Marker({
    position: coordinates,
    map: this.map,
  });


    this.map = new google.maps.Map(this.gmap.nativeElement,
      mapOptions);

    this.marker.addListener("click", () => {
      const infoWindow = new google.maps.InfoWindow({
        content: this.marker.getTitle()
      });
      infoWindow.open(this.marker.getMap(), this.marker);
    });

    this.marker.setMap(this.map);

    //Adding other markers
    this.loadAllMarkers();

  }


  async getRotas(callback: Function) {
    this.http.call_get('local-instalacao/getLocaisMapa', null).subscribe(
      response => {
        this.rotas = Object(response);
        callback();
      }
    )
  }


  private selectBoxLeitoresWithRotasHeaders() {

    this.markers = [];

    this.http.call_get('user/selectBoxLeitoresWithRotasHeaders', null).subscribe(
      response => {

        this.leitores = Object(response);

      }
    );

  }


  private selectBoxRotaHeadersByLeitor() {


    console.log(this.mapa.leitor_id);

    this.http.call_get('rota-header/getRotasHeaderByLeitor/' + this.mapa.leitor_id, null).subscribe(
      response => {

        this.rotas_header = Object(response).data;

        console.log(Object(response));
      }
    );

  }


  private getRotasHeaderByLeitor(leitor_id) {

    this.http.call_get('rota-header/getRotasHeaderByLeitor/' + leitor_id, null).subscribe(
      response => {

        this.rotas_header = Object(response).data;

      }
    );

  }

  /*  private loadMarkersFromRotas() {

     for (let index = 0; index < this.rotas.length; index++) {

       this.markers.push({
         position: new google.maps.LatLng(this.rotas[index].latitude, this.rotas[index].longitude),
         map: this.map,
         title: this.rotas[index].rota_header + " " + this.rotas[index].moradia_numero,
         description: "Cliente Estélvio Marques"
       })

     }

     this.rotas.forEach(element => {
       this.markers.push({
         position: new google.maps.LatLng(element.latitude, element.longitude),
         map: this.map,
         title: element.rota_header + " " + element.moradia_numero,
         description: "Cliente Estélvio Marques"
       })

     });

     console.log("markers");
     console.log(this.markers);

   } */

}


