import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OrganizeInNewArrayService {

  constructor() { }

  _cobrancaCopy1=[]
  _cobrancaCopy2=[]

   OrganizeData(array){
    let count=0
    let iterator=0
    let iteratorTime=0
    let linhaPagamentosCount={}
    let copyObject=[]
    let copyArray=[]
          for (const item of array) {
              if(item.__meta__["linhaPagamentos_count"]<=1){
                this._cobrancaCopy1.push(item)
              }else{
                linhaPagamentosCount[count++]=item.__meta__
                this._cobrancaCopy2.push(item)
              }
            }
          
          copyObject=this._cobrancaCopy2.slice()
          while(iterator<this._cobrancaCopy2.length){
           if(Object.keys(linhaPagamentosCount)[iterator]==undefined )break
           if(iteratorTime<linhaPagamentosCount[iterator].linhaPagamentos_count){
              if(this._cobrancaCopy2[iterator]==copyObject[iterator]){
                   copyArray[0]=this._cobrancaCopy2[iterator].linhaPagamentos[iteratorTime]
                   let total=iteratorTime==0?this._cobrancaCopy2[iterator].total:null
                    this._cobrancaCopy1.push(JSON.parse(JSON.stringify({
                        ...copyObject[iterator],
                        total:total,
                        linhaPagamentos:copyArray
                      })))
                    iteratorTime++
                  }
              }else{
                iterator++
                iteratorTime=0
              }
              
           }
          return this._cobrancaCopy1
      }
}
