import { Component, OnInit } from '@angular/core';
import { RotaService } from '../rotas.service';

@Component({
  selector: 'app-periodicidade-roteiro',
  templateUrl: './periodicidade-roteiro.component.html',
  styleUrls: ['./periodicidade-roteiro.component.css']
})
export class PeriodicidadeRoteiroComponent implements OnInit {

  loading: boolean
  periodicidades: any = []

  periodicidade: any = {
    descricao: null,
    ciclo: 1
  }


  constructor(
    private _rotaService: RotaService
  ) {
    this._loadingDependences()
  }

  set_periodicidade(periodicidade){
    this.periodicidade = periodicidade
  }

  _save(){
    if( Boolean(this.periodicidade.id) ){
      this._update_periodicidade(this.periodicidade)
    }else{
      this._create_periodicidade(this.periodicidade)
    }
  }

  _create_periodicidade(periodicidade){
    this._rotaService.CreatePeriodicidade(periodicidade)
    .subscribe((response) => {
      this._loadingDependences()
      this.periodicidade.descricao = null
    })
  }

  _update_periodicidade(periodicidade){
    this._rotaService.updatePeriodicidade(periodicidade)
    .subscribe((response) => {
      this._loadingDependences()
    })
  }


  ngOnInit() {
  }

  // ===========================================================================

  _loadingDependences(){
    this.loading = true
    this._rotaService.getPeriodicidades()
    .subscribe((response) => {
      this.periodicidades = response.data
    })
    this.loading = false
  }

  onReset(){
    this.periodicidade = {
      descricao: null
    }
    this._loadingDependences()
  }


}
