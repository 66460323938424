import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/providers/config/config.service';
import { HttpService } from 'src/app/providers/http/http.service';

@Component({
  selector: 'app-tipo-ramal',
  templateUrl: './tipo-ramal.component.html',
  styleUrls: ['./tipo-ramal.component.css']
})
export class TipoRamalComponent implements OnInit {

  private tipo_ramal = {
    id: null,
    descricao: null,
    slug: null
  }


  private items: any = [];

  constructor(private http: HttpService, private configService: ConfigService) { }

  ngOnInit() {
    this.http.filters.search = null
    this.getPageFilterData(1);
  }
  ngOnDestroy() {
    this.http.filters.orderBy = null
  }

  private listaTipoRamais() {

    this.configService.loaddinStarter('start');

    this.http.__call('tipo-ramal/listagem', this.http.filters).subscribe(

      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.configService.loaddinStarter('stop');

      }
    );
  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1;
    this.listaTipoRamais();
  }

  private register(e: any) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (this.tipo_ramal.descricao == null || this.tipo_ramal.slug == null) {
      this.configService.showAlert("Os campos Estado e Slug são obrigatórios", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    } else {
      this.http.__call('tipo-ramal/create', this.tipo_ramal).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
            this.configService.loaddinStarter('stop');
          } else {
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.listaTipoRamais()
            this.clearFormInputs();
            this.configService.loaddinStarter('stop');
          }
        }
      )
    };
  }

  /*
  private clearFormInputs(e) {
    e.target.elements[0].value = null;
    e.target.elements[1].value = null;
    e.target.elements[2].value = null;
  }*/

  private refresh(id: any, descricao: any, slug: any) {
    this.tipo_ramal.id = id;
    this.tipo_ramal.descricao = descricao;
    this.tipo_ramal.slug = slug;
  }

  private editar(e: any) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (this.tipo_ramal.descricao == null || this.tipo_ramal.slug == null) {
      this.configService.showAlert("Os campos Estado e Slug são obrigatórios", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    } else {
      this.http.__call('tipo-ramal/update/' + this.tipo_ramal.id, this.tipo_ramal).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            this.clearFormInputs();
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.listaTipoRamais();

          }
        }
      );
    }
    this.configService.loaddinStarter('stop');
  }

  private clearFormInputs() {
    this.tipo_ramal = {
      id: null,
      descricao: null,
      slug: null
    }
  }

}
