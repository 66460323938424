import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-loja',
  templateUrl: './loja.component.html',
  styleUrls: ['./loja.component.css']
})
export class LojaComponent implements OnInit, OnDestroy {

  public phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];

  private agencias: any = [];
  private agencias_tipologia_servico: any = [];

  private tipologia_servicos: any = []
  private provincias: any = [];
  private municipios: any = [];

  private agenciasBox: any = [];
  private users: any = [];
  private seriesNotInAgencias: any = []
  private seriesRecibosNotInAgencias:any = []
  private seriesOrcamentosNotInAgencias:any = []
  private title_modal: string;

  private loading = {
    user: 'Gerente',
    municipio: 'Município'
  }

  private copiar_agencia_title: string = "Copiar Agência";

  submitted = false;
  simpleForm: FormGroup;
  private forma_pagamento_id: number = null;
  private is_active: number = null;
  private formasPagamentos = [];
  private bancosAssociados = [];
  private seriesAssociadas = [];
  private bancos = [];
  private series = [];
  private agencia = {
    id: null,
    nome: null
  }


  constructor(private http: HttpService, private configService: ConfigService, private formBuilder: FormBuilder) {
    this.createForm();
  }

  ngOnInit() {
    this.getFormaPagamentos();
    this.http.filters.search = null
    this.getPageFilterData(1);
    this.getSeriesOrcamentosNotInAgencias('')
  }
  ngOnDestroy() {
    this.http.filters.orderBy = null
  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
    this.listarAgencias();
  }

  /**
   * @name "Listar Agências"
   * @descriptio "Esta Função permite Listar todas Agências"
   * @author "caniggia.moreira@itgest.pt"
   * @param start
   * @param end
   */
  private listarAgencias() {
    this.configService.loaddinStarter('start');
    this.http.__call('loja/listar', this.http.filters).subscribe(
      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;
        this.agencias = Object(response).data.data;
        this.configService.loaddinStarter('stop');
      }
    );
  }

  titleModal(title: string) {
    this.title_modal = title;
    this.getProvincias();
  }

  createForm() {
    this.simpleForm = this.formBuilder.group({
      index: [{ value: null, disabled: true }],
      nome: [null, Validators.required],
      numero: [null],
      copiar_agencia: [null],
      telefone: [null, Validators.required],
      email: [null],
      endereco: [null],
      is_active: [null],
      provincia_id: [null],
      municipio_id: [null, Validators.required],
      nif: [null, Validators.required],
      horario: [null, Validators.required],
/*       serie_id: [null, Validators.required],
      series: [null],
      serie_id_recibo:[null,Validators.required], */
      tipologia_servico_id: [null],
      agencia_id: [null]
    });
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.simpleForm.controls;
  }

  onReset() {

    this.submitted = false;
    this.simpleForm.reset();

  }
  lojaDelete(loja,banco){
    const uri = `loja/delete/${loja}/${banco}`;
    this.http.__call(uri, null).subscribe(
      response => {
        console.log(response)
        this.getBancosAssociados();
      }
    );


  }

  onSubmit() {

    this.submitted = true;

    // parar aquei se o simpleFormulário for inválido
    if (this.simpleForm.invalid) {
      return;
    }

    this.configService.loaddinStarter('start');
/*      console.log( this.simpleForm) */
    const index = this.simpleForm.getRawValue().index;
    if (index === null) {
      // TODO: usado para fazer a requisição com a api de criação de organismo
      const uri = 'loja/create';
      this.createOrEdit(uri, this.simpleForm, true);
    } else {
      // TODO: update
      const uri = 'loja/update/' + index;
      this.createOrEdit(uri, this.simpleForm, false);
    }
  }

  createOrEdit(uri: any, simpleFormulario: FormGroup, isCreate: boolean) {
    // TODO: usado para fazer a requisição com a api de criação de organismo
    this.http.__call(uri, simpleFormulario.value).subscribe(
      res => {
        this.submitted = false;
        if (isCreate) {
          simpleFormulario.reset();
          this.getSeriesNotInAgencias();
          this.getSeriesRecibosNotInAgencias();
          this.getSeriesOrcamentosNotInAgencias('')
        } else {

        }
        this.configService.showAlert(Object(res).message, 'alert-success', true);
        this.listarAgencias();
      }/*,
        error => {
          this.submitted = false;
          this.configService.showAlert(Object(res).message, 'alert-danger', true);
        }*/
    );
    this.configService.loaddinStarter('stop');
  }


  private editar(agencia: any) {

    this.simpleForm.patchValue({
      index: agencia.id,
      nome: agencia.nome,
      numero: agencia.numero,
      telefone: agencia.telefone,
      email: agencia.email,
      endereco: agencia.endereco,
      is_active: agencia.is_active,
      provincia_id: agencia.provincia_id,
/*       serie_id: agencia.serie_id, */
      tipologia_servico_id: agencia.tipologia_servico_id,
/*       serie_id_recibo: agencia.serie_id_recibo,
      serie_id_orcamento: agencia.serie_id_orcamento, */
      nif: agencia.nif,
      horario: agencia.horario
    });

    this.selectBoxMunicipiosByProvincia();
    this.simpleForm.patchValue({
      municipio_id: agencia.municipio_id,
    });


  }

  private getProvincias() {
    this.http.call_get('provincia/selectBox', null).subscribe(
      response => {
        this.provincias = Object(response).data
 /*        console.log( this.provincias) */
      }
    );

  }

  private selectBoxMunicipiosByProvincia() {

    this.loading.municipio = 'Carregando...';

    this.http.call_get('municipio/getMunicipiosByProvincia/' + this.simpleForm.getRawValue().provincia_id, null).subscribe(
      response => {
        //console.log(Object(response));

        this.municipios = Object(response).data;
        this.loading.municipio = 'Município';

        //console.log(this.municipios);

      }
    );
  }

  private getTipologiaServicos() {
    this.http.call_get('tipologia-servico/selectBox', null).subscribe(
      response => {
        this.tipologia_servicos = Object(response);
      }
    );
  }

  private getAgenciasByTipologiaServico() {
    this.http.call_get('loja/selectBoxAgenciasByTipologiaServico/' + this.simpleForm.getRawValue().tipologia_servico_id, null).subscribe(
      response => {
        this.agencias_tipologia_servico = Object(response);
      }
    );
  }


  private changeTipologiaServicoMode() {

    let aux = this.simpleForm.getRawValue().copiar_agencia;

    this.copiar_agencia_title = (aux) ? "Copiar Agência" : "Tipologia de Serviço";

    this.simpleForm.patchValue({
      copiar_agencia: !aux,
      tipologia_servico_id: (aux) ? null : this.simpleForm.getRawValue().tipologia_servico_id,
/*       serie_id: 0,
      series: '',
      serie_id_recibo: 0,
 */      municipio_id: 0,
/*       serie_id_orcamento:0, */
      telefone: "copy",
    });


/*     console.log(this.simpleForm); */
  }


  private getUsers(id: number) {
    this.gerente.agencia_id = id;
    this.loading.user = 'Carregando...';
    this.http.call_get('user/selectBox/' + id, null).subscribe(
      response => {
        this.users = Object(response).data
        this.loading.user = 'Gerente';
      }
    );
  }

  private setAgencia(agencia: any) {
    this.agencia.id = agencia.id;
    this.agencia.nome = agencia.nome;
  }
  private getSeriesNotInAgencias() {
    this.http.call_get('serie/loja/selectSerieLojaBox', null).subscribe(
      response => {
        this.seriesNotInAgencias = Object(response).data
/*         console.log( this.seriesNotInAgencias) */
      }
    );
  }

  private getSeriesRecibosNotInAgencias() {
    this.http.call_get('serie/loja/selectSeriesRecibosNotInLojasBox', null).subscribe(
      response => {
        this.seriesRecibosNotInAgencias = Object(response).data
/*         console.log(this.seriesRecibosNotInAgencias); */
      }
    );
  }
  private getSeriesOrcamentosNotInAgencias(series) {
    this.seriesOrcamentosNotInAgencias=[]
/*     console.log(series) */
    this.http.__call('serie/loja/selectSeriesOrcamentosNotInLojasBox', {series}).subscribe(
      response => {
        this.seriesOrcamentosNotInAgencias = Object(response).data
      }
    );
  }


  gerente = {
    user_chefe_id: null,
    agencia_id: null
  }
  addGerente() {
    if (this.gerente.user_chefe_id == null) {
      this.configService.showAlert("Informe o Gerente", 'alert-danger', true);
    } else {
      this.configService.loaddinStarter('start');
      this.http.__call('loja/addChefe', this.gerente).subscribe(
        res => {
          this.configService.showAlert(Object(res).message, 'alert-success', true);
          this.listarAgencias();
        }
      );
      this.configService.loaddinStarter('stop');
    }
  }

  addFormaPagamentoAgencia() {
    if (this.forma_pagamento_id == null || this.is_active == null) {
      this.configService.showAlert("Informe a forma pagamento e o estado de activação", 'alert-danger', true);
    } else {
      this.configService.loaddinStarter('start');
      this.http.__call('loja/storeFormaPagamentoLoja', { loja_id: this.agencia.id, forma_pagamento_id: this.forma_pagamento_id, is_active: this.is_active }).subscribe(
        res => {
          this.configService.showAlert(Object(res).message, 'alert-success', true);
          this.forma_pagamento_id = null;
          this.is_active = null;
          this.listarFormaPagamentos(this.agencia.id);
        }
      );
      this.configService.loaddinStarter('stop');
    }
  }

  activacaoFormaPagamentoAgencia(is_active: number,id:number) {
    this.configService.loaddinStarter('start');
    this.http.__call('loja/formaPagamentoLoja/active', { id: id, is_active: is_active }).subscribe(
      res => {
        this.configService.showAlert(Object(res).message, 'alert-success', true);
        this.listarFormaPagamentos(this.agencia.id);
      }
    );
    this.configService.loaddinStarter('stop');

  }

  private formas = []

  private listarFormaPagamentos(id: number) {

    this.configService.loaddinStarter('start');
    this.http.__call('loja/listarFormaPagamentoPorLoja', { id: id }).subscribe(
      response => {
        this.formas = Object(response).data;
        this.configService.loaddinStarter('stop');

      }
    );
  }

  private getFormaPagamentos() {

    this.configService.loaddinStarter('start');
    this.http.__call('loja/forma/selectBox', { loja_id: this.agencia.id }).subscribe(
      response => {
        this.formasPagamentos = Object(response).data;
        this.configService.loaddinStarter('stop');

      }
    );
  }

  private getBancos() {
    this.http.call_get('banco/listar', null).subscribe(
      response => {
        this.bancos = Object(response).data;
      }
    );
  }

  private getSeries() {
    this.http.__call('serie/listar', null).subscribe(
      response => {
        this.series = Object(response);
      }
    );
  }

  private getBancosAssociados() {
    this.http.call_get('loja_bancos/getbancos/' + this.agencia.id, null).subscribe(
      response => {
        this.bancosAssociados = Object(response).data;
      }
    );
  }

  private getSeriesAssociadas() {

    this.http.call_get('loja_series/getseries/' + this.agencia.id, null).subscribe(
      response => {
        this.seriesAssociadas = Object(response).data;
      }
    );
  }

  private clearFormInputs(e) {
    e.target.elements[0].value = null;
    e.target.elements[1].value = null;
    e.target.elements[2].value = null;
    e.target.elements[3].value = null;
  }

  private loja_bancos = {
    banco_id: null
  }

  private adicionarBancos(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();

      this.http.__call('loja_bancos/addbancos/' + this.agencia.id, this.loja_bancos).subscribe(
        res => {
          if (Object(res).code == 201) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            this.configService.clearFormInputs(e);
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.getBancosAssociados();
          }
        }
      );
    this.configService.loaddinStarter('stop');
  }

  private loja_series = {
    id: null,
    serie_id: null,
    loja_id: null,
    is_default: null,
    estado: null,
    id_loja_serie: null
  }

  private adicionarSeries(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();

      this.http.__call('loja_series/addseries/' + this.agencia.id, this.loja_series).subscribe(
        res => {
          if (Object(res).code == 201) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            this.configService.clearFormInputs(e);
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.getSeriesAssociadas();
            this.getSeriesOrcamentosNotInAgencias('')
          }
        }
      );
    this.configService.loaddinStarter('stop');
  }

  private refresh(id_loja_serie, serie_id, estado, is_default) {
    this.loja_series.serie_id = serie_id;
    this.loja_series.estado = estado;
    this.loja_series.is_default = is_default;
    this.loja_series.loja_id = this.agencia.id;
    //this.loja_series.id = id;
    this.loja_series.id_loja_serie = id_loja_serie;
  }

  private editarSeries(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();

      this.http.__call('loja_series/updateseries/' + this.loja_series.id, this.loja_series).subscribe(
        res => {
          if (Object(res).code == 201) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            this.configService.clearFormInputs(e);
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.getSeriesAssociadas();
            this.getSeriesOrcamentosNotInAgencias('')
          }
        }
      );
    this.configService.loaddinStarter('stop');
  }

}
