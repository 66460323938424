import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { url } from '@rxweb/reactive-form-validators';
import * as moment from 'moment';
import { ConfigService } from 'src/app/providers/config/config.service';
import { HttpService } from 'src/app/providers/http/http.service';
import { ExcelService } from 'src/app/services/excel.service';
import { ExcelAutoService } from 'src/app/services/excel/excel-auto.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-config-aviso-corte',
  templateUrl: './config-aviso-corte.component.html',
  styleUrls: ['./config-aviso-corte.component.css']
})
export class ConfigAvisoCorteComponent implements OnInit {

  loading: boolean = false;
  disableButton: boolean = true;


  submitted = false;
  public filters = {
    search: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    pagination: {
      perPage: 5,// Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima,
      lastPage: null,
      total: null // defini o total de registo da BD
    },
    filter: null // subelemente do filter
    , is_allexel: false
  }

  items: any = [];
  estado: any = [];
  configAvisoCorteForm: FormGroup

  constructor(private http: HttpService,
    private configService: ConfigService,
    private excelService: ExcelService,
    private excelsService: ExcelAutoService,
    private _formBuilder: FormBuilder,
  ) {
    this.createForm()
  }

  ngOnInit() {
    this.getPageFilterData(1);
    this.listagem()
    this.getPageFilterDataPage(1)
  }

  ngOnDestroy() {
    this.http.filters.orderBy = null
  }

  get f() {
    return this.configAvisoCorteForm.controls;
  }
  createForm() {
    this.configAvisoCorteForm = this._formBuilder.group({
      id: null,
      quantidade_dias_vencidos: [null, [Validators.required]],
      quantidade_dias_antes_aviso_corte: [null, [Validators.required]],
      quantidade_dias_depois_aviso_corte: [null, [Validators.required]],
    })

  }
  private createFlagKeyup(value) {
    this.configAvisoCorteForm.patchValue({ flag: value })
  }
  private disable() {
    this.configAvisoCorteForm.controls.flag.disable();
  }



  private listagem() {
    this.loading = true
    return this.http.__call(`api/v1/notifications/config/avisoCorte/index`, this.filters).subscribe(
      response => {
        this.filters.pagination.lastPage = Object(response).data.lastPage;
        this.filters.pagination.page = Object(response).data.page;
        this.filters.pagination.total = Object(response).data.total;
        this.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.loading = false;
      }
    );
  }

  onSubmit() {
    this.submitted = true
    if (this.configAvisoCorteForm.invalid) { return }
    let cliente_value = this.configAvisoCorteForm.getRawValue()

    let url = cliente_value.id ? `api/v1/notifications/config/avisoCorte/update/${cliente_value.id}` : `api/v1/notifications/config/avisoCorte/create`

    this.createOrEditTipoAvisoCorte(cliente_value, url)
  }
  createOrEditTipoAvisoCorte(cliente, url) {
    this.http.__call(url, cliente)
      .subscribe(
        response => {

          this.submitted = false
          this.loading = false
          this.listagem();
          this.configService.showAlert(Object(response).message, 'success', true)
          this.getPageFilterData(1)
          if (this.configAvisoCorteForm.value.id == null) this.configAvisoCorteForm.reset()
        },
        error => {
          this.configService.showAlert("Alguma coisa correu mal", 'warning', true);
          this.submitted = false;
        }

      )
    this.loading = false
  }

  iniCliente(cliente) {
    this.configAvisoCorteForm.patchValue(cliente)
    // this.disable()
  }

  _closeModal(closeModal) {
    var action = document.getElementById(closeModal)
    action.click()
  }

  onReset() {
    this.submitted = false;
    this.configAvisoCorteForm.reset();
  }


  getPageFilterData(page: number) {
    if (this.filters.pagination.perPage == null) {
      return;
    }
    this.filters.pagination.page = Number.isInteger(page) ? page : 1;
    this.listagem();
  }
  getPageFilterDataPage(page: number) {
    if (this.filters.pagination.perPage == null) {
      return;
    }
    this.filters.pagination.page = Number.isInteger(page) ? page : 1;
    this.listagem();
  }

  private refreshData({ descricao }) {
    this.configAvisoCorteForm.value.descricao = descricao
  }

}
