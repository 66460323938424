import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { HttpService } from 'src/app/providers/http/http.service';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { ExcelService } from 'src/app/services/excel.service';
import * as moment from 'moment';

@Component({
  selector: 'app-stock-tipo-movimentos',
  templateUrl: './stock-tipo-movimentos.component.html',
  styleUrls: ['./stock-tipo-movimentos.component.css']
})
export class StockTipoMovimentosComponent implements OnInit, OnDestroy {


  public tipoMovimentos: any;
  private loading: boolean = false;
  public filters = {
    search: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    pagination: {
      perPage: 5,// Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima,
      lastPage: null,
      total: null // defini o total de registo da BD
    },
    filter: null // subelemente do filter
  }


  constructor(private auth: AuthService, private http: HttpService, private configService: ConfigService,private excelService: ExcelService) { }

  private items: any = [];

  ngOnInit() {
    this.getPageFilterData(1);

  }
  ngOnDestroy(){
    this.http.filters.orderBy = null
  }


  private listar() {

    this.loading = true
    this.http.__call('tipo-movimento/listar', this.filters).subscribe(
      response => {
        this.filters.pagination.lastPage = Object(response).data.lastPage;
        this.filters.pagination.page = Object(response).data.page;
        this.filters.pagination.total = Object(response).data.total;
        this.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.loading = false
      }
    );
  }

  getPageFilterData(page: number) {
    if (this.filters.pagination.perPage == null) {
      return;
    }
    this.filters.pagination.page = page;
    this.listar();
  }


  private setData(item) {
    this.tipoMovimentos = item;
  }





}
