import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { HttpService } from 'src/app/providers/http/http.service';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { ExcelService } from 'src/app/services/excel.service';
import * as moment from 'moment';


@Component({
  selector: 'app-utilizador',
  templateUrl: './utilizador.component.html',
  styleUrls: ['./utilizador.component.css']
})
export class UtilizadorComponent implements OnInit,OnDestroy {
  private filter={
    role_id:null
  }
  public utilizador: any;
  private loading: boolean = false;

  constructor(private auth: AuthService, private http: HttpService, private configService: ConfigService, private excelService: ExcelService) { }
  exportAsXLSX(): void {
    var CurrentDate = new Date();
    var nameFile = "Lista_Utilizadores -" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    this.excelService.exportAsExcelFile(document.getElementsByClassName("exportAsXLSXCliente")[0], nameFile);
  }
  exportAsPDF(): void {
    //this.reportLoja.relatorioLoja(this.cobrancas, this.simpleForm.value, 'save');
  }

  imprimirPDF(): void {
    //this.reportLoja.relatorioLoja(this.cobrancas, this.simpleForm.value);
  }


  private items: any = [];
  private lojas: any = [];
  private roles: any = [];
  private empresas: any = [];


  ngOnInit() {
    this.http.filters.search = null
    this.getPageFilterData(1);
    this.rolesOption()
  }
  ngOnDestroy(){
    this.http.filters.orderBy = null
  }


  private listarUsers() {

    this.configService.loaddinStarter('start');
    console.log('filter',this.http.filters)
    this.http.filters.filter=this.filter
    this.loading = true
    this.http.__call('user/listar', this.http.filters).subscribe(
      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.loading = false
      }
    );
  }

  private rolesOption() {

    this.http.call_get('role/select-option', null).subscribe(
      response => {
        this.roles = Object(response).data;
      }
    );
  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
    this.listarUsers();
  }


  private setDataUtilizador(utilizador) {
    this.utilizador = utilizador
  }
  BloquearUser(user_id, status) {
    this.loading = true;
    const url = 'user/bloquear/' + user_id;
    this.http.__call(url, { user_id: user_id, status: status }).subscribe(response => {
      this.getPageFilterData(1);
      Object(response).data;
      this.loading = false;
    });
  }

}
