import { Component, OnInit, Input } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { DiarioVendasService } from '../../report-at/vendas/diario-vendas.service';
import { ResumoRecebimentoPorCaixaService } from 'src/app/components/report-at/vendas/resumo-recebimento-por-caixa.service'
@Component({
  selector: 'app-fecho',
  templateUrl: './fecho.component.html',
  styleUrls: ['./fecho.component.css']
})

export class FechoComponent implements OnInit {

  @Input() fechoCaixaModal: string = "fechoCaixaModal";


  public currentUser: any;

  submitted = false;
  simpleForm: FormGroup;

  validar = true;
  imprimir = {
    user_id: null,
    data_abertura: null
  };

  public vendas: any = []
  public teste: any = []
  public valorTotalVenda: string;

  @Input() caixas: any = [];
  private caixa_id: any = null;

  constructor(private http: HttpService, private configService: ConfigService, private DiarioVendasService: DiarioVendasService, private recVenda: ResumoRecebimentoPorCaixaService, private formBuilder: FormBuilder, private auth: AuthService) {
    this.currentUser = this.auth.currentUserValue;
    this.createForm();
  }

  ngOnInit() {

  }
  public Total_Recebimento_anulados: number;






  createForm() {//

    this.simpleForm = this.formBuilder.group({
      valor_fecho: [null, Validators.required],
      valor_venda: [null, Validators.required],
      valor_factura: [null, Validators.required],
      valor_recibo: [null, Validators.required],

      total_devolvido: [null],

      troco_recibo: [null],
      troco_factura: [null],
      caixa_id: [null, Validators.required],
      observacao: [null]
    });


  }
  // convenience getter for easy access to form fields
  get f() {
    return this.simpleForm.controls;
  }

  onReset() {
    this.submitted = false;
    this.simpleForm.reset();
    this.vendas = [];
  }

  onSubmit() {
    this.submitted = true;
    // parar aquei se o simpleFormulário for inválido
    if (this.simpleForm.invalid) {
      return;
    }


    const uri = 'caixa/fecho';
    this.createOrEdit(uri, this.simpleForm, true);

  }

  createOrEdit(uri: any, simpleFormulario: FormGroup, isCreate: boolean) {


    this.configService.loaddinStarter('start');
    // TODO: usado para fazer a requisição com a api de criação
    this.http.__call(uri, simpleFormulario.value).subscribe(
      res => {

        if (Object(res).code == 200) {
          this.submitted = false;
          if (isCreate) {
            this.configService.getCaixas();
            simpleFormulario.reset();
          }
          this.imprimir.user_id = Object(res).data.user_id
          this.imprimir.data_abertura = Object(res).data.data_abertura
          this.caixa_id = Object(res).data.id
          this.validar = false;
          this.configService.showAlert(Object(res).message, 'alert-success', true);
        } else {
          this.configService.showAlert(Object(res).message, 'alert-danger', true);
        }
      }
    );
    this.configService.loaddinStarter('stop');
  }


  public fechoCaixaResumoRecebimentoVendas() {
    if (this.f.caixa_id.value == null) {
      this.f.valor_fecho.reset();
      this.f.valor_factura.reset();
      this.f.valor_recibo.reset();
      this.f.valor_venda.reset();
    }

    this.Total_Recebimento_anulados = 0;
    this.vendas = []

    this.configService.loaddinStarter('start');
    this.http.__call('vendas/fechoCaixaResumoRecebimentoVendas', { caixa_id: this.f.caixa_id.value }).subscribe(
      response => {

        this.vendas = (Object(response).data != null ? Object(response).data.dados.recebimentos[0].vendas : []);
        this.configService.loaddinStarter('stop');
        this.teste = this.vendas[3];


        if (this.teste != undefined) {
          for (let i = 0; i < this.teste.length; i++) {
            this.Total_Recebimento_anulados += this.teste[i].total;
          }
        }

        if (Object(response).data) {
          this.resetCaixa()
          this.getValorVendas(this.Total_Recebimento_anulados, Object(response).data.total_caixa);
        }
      }
    );
  }

  resetCaixa() {
    //if (this.f.caixa_id.value == null) {
    this.f.valor_fecho.reset();
    this.f.valor_factura.reset();
    this.f.valor_recibo.reset();
    this.f.valor_venda.reset();
    //}
  }
  public getValorVendas(total: number, total_caixa: any) {
    this.http.__call('caixa/totalVendas', { caixa_id: this.f.caixa_id.value, data: this.caixas.data_abertura_formated }).subscribe(
      response => {
        var _valor_venda = total_caixa ? (Object(response).data.total - total) - (total_caixa) : (Object(response).data.total - total)
        this.simpleForm.patchValue({
          valor_venda: (Object(response).data == null ? 0.00 : (_valor_venda).toFixed(2)),
          valor_factura: (Object(response).data == null ? 0.00 : Object(response).data.factura.total),
          valor_recibo: (Object(response).data == null ? 0.00 : ((Object(response).data.recibo.total) - (total))),
          troco_recibo: (Object(response).data == null ? 0.00 : -(Object(response).data.recibo.troco)),
          troco_factura: (Object(response).data == null ? 0.00 : -(Object(response).data.factura.troco)),
          total_devolvido: (total_caixa ? (total_caixa).toFixed(2) : 0)
        });
      }
    );

  }

  private btnImprimirDiarioVendas() {
    //console.log(1)
    this.DiarioVendasService.DiarioVendas(this.imprimir.user_id, this.imprimir.data_abertura, this.imprimir.data_abertura, this.caixa_id);
  }

  private btnimprimirResumo() {
    this.recVenda.imprimirResumoRecebimentoPorCaixa(this.imprimir.data_abertura, 1, this.imprimir.user_id, this.caixa_id);
  }

  private btnClose() {
    this.imprimir.user_id = null,
      this.imprimir.data_abertura = null
  }






  private totalFactura = 0;
  private calcularTotalFactura(valor) {
    this.totalFactura += valor
  }

  calcTroco(valor, troco) {
    return valor - (troco * (-1));
  }

}
