
import { Component, Input, Output, EventEmitter, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { ConfigModuloService } from 'src/app/services/config-modulo.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { Pagination } from 'src/app/models/pagination';
import { ActivatedRoute, Router } from '@angular/router'
import { RotaService } from '../../rotas.service';
import { CdkDragEnd, CdkDragStart, CdkDragMove, CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Sortable } from '@shopify/draggable';
import { HostListener } from '@angular/core';
declare var google;
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'

@Component({
  selector: 'app-create-roteiro',
  templateUrl: './create-roteiro.html',
  styleUrls: ['./create-roteiro.css']
})

export class CreateRoteiroComponent implements OnInit {

  public pagination = new Pagination();


  @ViewChild('dDnovos_locais') dDnovos_locais;

  dias = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
    11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31
  ]

  has_quarteirao: boolean = false
  quarteiroes: any = []
  distritos: any = []
  bairros: any = []
  ruas: any = []
  locais: any = []
  novos_locais: any = [];
  locais_processar: any = []
  periodicidades: any = []
  view_local_list: boolean = false;

  private contador_id: any;
  private contrato_id: any;

  new_periodo: any = {
    periodicidade_roteiro_id: null,
    ciclo: null,
    dia_mes: 1
  }

  isToExpand = false;
  public isSearch = false;
  public _novos_locais: any[];
  private saveAndprocessReading = false;

  public isSearchLocais = false;
  public _locais: any[];

  public _searchedlocais: any[];

  currentIds = {
    bairoId: null,
    quarteiraoId: null,
    distritoId: null,
  }

  public newPerPage = 1;
  public totalSelected = 0;
  check_all: boolean
  isCheckAllNewsPlaces: boolean
  header_id: number
  rota_header: any = {}
  municipio: any = {}
  searchText = ''
  public itemsToMove = [];
  private rotas;
  rota: any;

  @ViewChild('mapContainer') gmap: ElementRef;

  marker: any;
  map: any = google.maps.Map;
  lat = null;
  lng = null;
  markers: any = [];
  loading: boolean
  public search: any;
  public _search: any;

  constructor(
    private configService: ConfigService,
    private config: ConfigModuloService,
    private _rotaService: RotaService,
    private http: HttpService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.route.params.subscribe(params => {
      this.header_id = params['slug']
    })

    this.getPageFilterData(1)
  }

  ngOnInit() {
    this.check_all = false
    this.http.filters.search = null
    this.mapInitializer();
  }


  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      this.http.filters.pagination.page = 1;
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1;
    this.loadingMethods();
  }


  loadingMethods() {
    this.configService.loaddinStarter('start');

    this._rotaService.getPeriodicidades()
      .subscribe(response => {
        this.periodicidades = response.data.data
      })

    this.http.call_get(`rota-header/${this.header_id}`, null)
      .subscribe(
        response => {
          this.rota = Object(response).data
          this.rota_header = this.rota
          this.municipio = this.rota.municipio
          this._getDistritoOfMunicipio(this.rota.municipio.id)
          //this._getLocaisByMunicipio(this.rota.municipio.id)

        }
      )
  }

  _resetLoais() {
    this.locais = [];
  }

  _changeDistrito(id) {
    this.view_local_list = false
    if (id != 'null') {
      this._resetLoais();
      this.currentIds.bairoId = null;
      this.currentIds.quarteiraoId = null;

      this.currentIds.distritoId = id;
      this.newPerPage = 1;
      //this._getLocaisByDistrito(id)

      this.http.call_get(`bairro/getBairrosByDistrito/${id}`, null)
        .subscribe(
          (response: any) => {
            this.bairros = response.data
          }
        )
    }
  }

  _getDistritoOfMunicipio(id) {
    this.http.call_get(`distrito/getDistritosByMunicipio/${id}`, null)
      .subscribe(
        response => {
          let data = Object(response).data
          this.distritos = data
        }
      )
  }

  private bairro_id: any;
  _changeBairro(id) {
    this.bairro_id = id;
    this.view_local_list = true
    if (id != 'null') {
      this._resetLoais();
      this.currentIds.distritoId = null;
      this.currentIds.quarteiraoId = null;

      this.currentIds.bairoId = id;
      this.newPerPage = 1;
      this._getLocaisByBairro(id)

      this.http.call_get(`bairro/getBairroById/${id}`, null)
        .subscribe(
          (response: any) => {
            this.has_quarteirao = response.data.has_quarteirao

            if (response.data.has_quarteirao) {
              this._getQuarteirosDoBairro(id)
            } else {
              this._getRuasDoBairro(id)
            }
          }
        )
    }
  }

  _getQuarteirosDoBairro(id) {
    this.http.call_get(`quarteirao/getQuarteiraosByBairro/${id}`, null)
      .subscribe(
        response => {
          this.quarteiroes = Object(response).data
        }
      )
  }

  _getRuasDoBairro(id) {
    this.http.call_get(`rua/selectBoxByBairro/${id}`, null)
      .subscribe(
        response => {
          this.ruas = Object(response)
        }
      )
  }

  _changeQuarteirao(id) {
    if (id != 'null') {
      this._resetLoais();
      this.currentIds.distritoId = null;
      this.currentIds.bairoId = null;

      this.currentIds.quarteiraoId = id;
      this.newPerPage = 1;
      this._getLocaisByQuarteirao(id)

      this.http.call_get(`rua/getRuasByQuarteirao/${id}`, null)
        .subscribe(
          response => {
            this.ruas = Object(response).data
          }
        )
    }
  }

  _getLocaisByMunicipio(municipio_id) {
    this._rotaService.getLocaisByMunicipio(municipio_id, { newPerPage: this.newPerPage, search: this.http.filters.search })
      .subscribe(response => {
        this._init_locais(response.data)
      }),
      (error) => {
        if (!error.ok) {
          console.log(error)
        }
      }
  }

  _getLocaisByDistrito(distrito_id) {
    this._rotaService.getLocaisByDistrito(distrito_id, { newPerPage: this.newPerPage, search: this.http.filters.search })
      .subscribe(response => {
        //this._init_locais(response.data)
      }),
      (error) => {
        if (!error.ok) {
          console.log(error)
        }
      }
  }


  _getLocaisByBairro(bairro_id) {
    this.configService.loaddinStarter('start');
    this._rotaService.getLocaisByBairro(bairro_id, { contador_id: this.contador_id, contrato_id: this.contrato_id, newPerPage: this.newPerPage, search: this.http.filters.search })
      .subscribe(response => {
        this._init_locais(response.data)
        this.configService.loaddinStarter('stop');
      }),
      (error) => {
        if (!error.ok) {
          console.log(error)
        }
      }
  }

  _filterByCtOrCtt() {
    this._changeBairro(this.bairro_id);
  }

  _getLocaisByQuarteirao(quarteirao_id) {
    this.configService.loaddinStarter('start');
    this._rotaService.getLocaisByQuarteirao(quarteirao_id, { newPerPage: this.newPerPage, search: this.http.filters.search })
      .subscribe(response => {
        this._init_locais(response.data)
        this.configService.loaddinStarter('stop');
      }),
      (error) => {
        if (!error.ok) {
          console.log(error)
        }
      }
  }

  _getLocaisByRua(rua_id) {
    this._rotaService.getLocaisByRua(rua_id, { newPerPage: this.newPerPage, search: this.http.filters.search })
      .subscribe(response => {
        this._init_locais(response.data)
      }),
      (error) => {
        if (!error.ok) {
          console.log(error)
        }
      }
  }

  _init_locais(locais) {
    this.configService.loaddinStarter('start')

    let locais_merge = []

    for (let local of locais.data) {
      var prop = { checkbox: false }
      locais_merge.push({ ...local, ...prop })
    }

    locais_merge.forEach(local => {
      this.locais.push(local);
    });
    this.pagination.total = locais.data.length

    this.http.filters.pagination.lastPage = locais.lastPage;
    this.http.filters.pagination.page = locais.page;
    this.http.filters.pagination.total = locais.total;
    this.http.filters.pagination.perPage = locais.perPage;

    this.configService.loaddinStarter('stop')
  }

  //===========================================================================

  _addMultiples() {
    for (let c = 0; c < 30; c++) {
      for (let local of this._search ? this._locais : this.locais) {
        if (local.checkbox) {
          this._addNewRota(local)
        }
      }
    }
    setTimeout(() => {
      this.updateIndex();
    }, 0);

  }

  _remove_multiples() {
    for (let c = 0; c < 30; c++) {
      for (let local of this.isSearch ? this._novos_locais : this.novos_locais) {
        if (local.checkbox) {
          this._removeRota(local);
        }
      }
    }
  }

  _checkAll(event) {
    this.check_all = event.target.checked
    for (let local of this._search ? this._locais : this.locais) {
      local.checkbox = event.target.checked
    }
    this.totalPlacesMarked();
  }

  _check_novos_Locais(event) {
    this.isCheckAllNewsPlaces = event.target.checked
    for (let local of this.novos_locais) {
      local.checkbox = event.target.checked;
    }
  }

  _removeRota(local) {
    local.checkbox = false;
    this.locais.unshift(local)
    for (let l = 0; l < this.novos_locais.length; l++) {
      if (local.local_consumo_id === this.novos_locais[l].local_consumo_id) {
        this.novos_locais.splice(l, 1)
      }
    }
  }

  _addNewRota(local) {
    local.checkbox = false;
    this.novos_locais.push(this._initNewColletion(local))
    for (let l = 0; l < this.locais.length; l++) {
      if (local.local_consumo_id === this.locais[l].local_consumo_id) {
        this.locais.splice(l, 1)
      }
    }
    if (this._search) {
      for (let l = 0; l < this._locais.length; l++) {
        if (local.local_consumo_id === this._locais[l].local_consumo_id) {
          this._locais.splice(l, 1)
        }
      }
    }
    this.totalPlacesMarked();
  }

  markNewPlacesOrNot(e: any, local: any) {
    for (let l = 0; l < this.locais.length; l++) {
      if (this.locais[l].local_consumo_id === local.local_consumo_id) {
        if (e.target.checked) {
          this.locais[l].checkbox = e.target.checked;
        } else {
          this.locais[l].checkbox = e.target.checked;
        }
      }
    }

    this.totalPlacesMarked();
  }

  totalPlacesMarked() {
    this.totalSelected = 0;
    for (const local of this._search ? this._locais : this.locais) {
      if (local.checkbox) { this.totalSelected++ }
    }
  }

  _checkOrUncheck_novos_Locais(e: any, local: any) {
    for (let l = 0; l < this.novos_locais.length; l++) {
      if (this.novos_locais[l].local_consumo_id === local.local_consumo_id) {
        if (e.target.checked) {
          this.novos_locais[l].checkbox = e.target.checked;
        } else {
          this.novos_locais[l].checkbox = e.target.checked;
        }
      }
    }
  }


  reOrderLocais(locais) {
    this.novos_locais = []

    for (let i = 0; i < locais.length; i++) {
      let local = locais[i]
      local.ordem = i + 1
      this.novos_locais.push(this._initNewColletion(local))
    }
  }

  _initNewColletion(local) {

    let new_local = {
      cil: local.cil,
      rua: local.rua,
      bairro: local.bairro,
      contaDescricao: local.contaDescricao,
      is_predio: local.is_predio,
      latitude: local.latitude,
      local_consumo_id: local.local_consumo_id,
      local_instalacao_id: local.local_instalacao_id,
      longitude: local.longitude,
      ordem: local.ordem,
      morada_format: local.morada_format,
      moradia_numero: local.moradia_numero,
      numero_conta: local.numero_conta,
      predio_andar: local.predio_andar,
      predio_nome: local.predio_nome,
    }
    return new_local
  }

  _saveAndContinue(rota_header_id) {
    this.saveAndprocessReading = false;
    this._createProcess(rota_header_id)
    this._closeModal("closeModal")
  }

  _saveAndProcessReadings(rota_header_id) {
    this.saveAndprocessReading = true;
    this._createProcess(rota_header_id)
    this._closeModal("closeModal")
  }

  _createProcess(rota_header_id) {
    this.configService.loaddinStarter('start');

    this.http.__call('rota-run/create', {
      locais: this.novos_locais,
      dia_mes: this.new_periodo.dia_mes,
      rota_header_id: rota_header_id,
      periodicidade_roteiro_id: this.new_periodo.periodicidade_roteiro_id
    }).subscribe(
      res => {
        if (Object(res).code == 200) {
          this.configService.showAlert("Roteiros registadas com sucesso", 'alert-success', true)

          this.configService.loaddinStarter('stop')
          this.novos_locais = []
          this._updateListRotasRun(rota_header_id)
          if (this.saveAndprocessReading) { this.router.navigate(['/processar-leituras/' + rota_header_id]) }

        } else {
          this.configService.showAlert("Erro ao registar roteiros", 'alert-danger', true);
        }
      }
    )

  }

  _updateListRotasRun(id: number) {
    this.http.call_get(`rota-run/getRotasRunByRotaHeader/${id}`, null).subscribe(
      response => {
        let rotaRuns = Object(response).data
        this._rotaService.listRotasHeadersChange.emit(rotaRuns)
      }
    )
  }

  _closeModal(closeModal) {
    var action = document.getElementById(closeModal);
    action.click();
  }


  mapInitializer() {

    this.lat = -7.739212;
    this.lng = 21.251341;


    const coordinates = new google.maps.LatLng(this.lat, this.lng);

    const mapOptions: any = google.maps.MapOptions = {
      center: coordinates,
      zoom: 8,
    };

    this.marker = new google.maps.Marker({
      position: coordinates,
      map: this.map,
    });


    this.map = new google.maps.Map(this.gmap.nativeElement,
      mapOptions);

    this.marker.addListener("click", () => {
      const infoWindow = new google.maps.InfoWindow({
        content: this.marker.getTitle()
      });
      infoWindow.open(this.marker.getMap(), this.marker);
    });

    this.marker.setMap(this.map);

    //Adding other markers
    //this.loadAllMarkers();

  }


  async getRotas(callback: Function) {
    this.http.call_get('local-instalacao/getLocaisMapa', null).subscribe(
      response => {
        this.rotas = Object(response);
        callback();
      }
    )
  }


  @HostListener('scroll', ['$event'])
  onScroll(evento: any) {
    if (evento.target.offsetHeight + evento.target.scrollTop >= evento.target.scrollHeight - 1) {
      ++this.newPerPage;

      if (this.currentIds.distritoId) {
        this._getLocaisByDistrito(this.currentIds.distritoId);
      } else if (this.currentIds.bairoId) {
        this._getLocaisByBairro(this.currentIds.bairoId);
      } else if (this.currentIds.quarteiraoId) {
        this._getLocaisByQuarteirao(this.currentIds.quarteiraoId);
      } else {
        this._getLocaisByMunicipio(this.rota.municipio.id)
      }
    }
  }


  searchNovosLocais(e) {
    this.isSearch = false;

    if (this.search != "") {
      this.isSearch = true;

      this._novos_locais = this.novos_locais.filter((res) =>
        String(res.moradia_numero).toLocaleLowerCase().includes(this.search.toLocaleLowerCase())
        || String(res.cil).toLocaleLowerCase().includes(this.search.toLocaleLowerCase())
        || String(res.predio_nome).toLocaleLowerCase().includes(this.search.toLocaleLowerCase())
        || String(res.local_consumo_id).toLocaleLowerCase().includes(this.search.toLocaleLowerCase())
      );
    }
  }


  searchLocais(e) {
    this.isSearchLocais = false;

    if (this._search != "") {
      this.isSearchLocais = true;

      this._locais = this.locais.filter((res) =>
        String(res.moradia_numero).toLocaleLowerCase().includes(this._search.toLocaleLowerCase())
        || String(res.cil).toLocaleLowerCase().includes(this._search.toLocaleLowerCase())
        || String(res.predio_nome).toLocaleLowerCase().includes(this._search.toLocaleLowerCase())
        || String(res.local_consumo_id).toLocaleLowerCase().includes(this._search.toLocaleLowerCase())
      );
    }
  }

  key: string = 'id';
  reverse: boolean = false;
  sort(key: any) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  key_nc: string = 'id';
  reverse_nc: boolean = false;

  sortNovosLocais(key: any) {
    this.key_nc = key;
    this.reverse_nc = !this.reverse_nc

    setTimeout(() => {
      this.updateIndex();
    }, 0);
  }

  expandirMapa(e) {
    if (e.target.checked) {
      this.isToExpand = e.target.checked
    }
    else {
      this.isToExpand = e.target.checked
    }
  }

  private _currentIndex: any;
  mouseover(i) { this._currentIndex = i };

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.novos_locais, event.previousIndex, this._currentIndex);
    setTimeout(() => {
      this.updateIndex();
    }, 0);
  }

  updateIndex() {
    for (let index = 0; index < this.novos_locais.length; index++) {
      let local = this.novos_locais[index];
      local.ordem = index + 1;
    }
  }

  gotoRotas() {
    this.router.navigate(['/Rotas'])
  }

}
