import { Cliente } from './../../models/cliente/cliente.model';
import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { BsModalService, BsModalRef, ModalDirective, ModalModule } from 'ngx-bootstrap/modal';
import { ConfigService } from 'src/app/providers/config/config.service';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'

@Component({
  selector: 'app-lista-incumprimento',
  templateUrl: './lista-incumprimento.component.html',
  styleUrls: ['./lista-incumprimento.component.css']
})
export class ListaIncumprimentoComponent implements OnInit {

  @ViewChild('closebutton') closebutton;
  @ViewChild('modalAppendServico') modalAppendServico: ModalDirective;

  loadingListarFacturas = false;

  @ViewChild('modalShowEvent') public modalShowEvent: ModalDirective;

  private filter = {
    municipio_id: null,
    distrito_id: null,
    bairro_id: null,
    quarteirao_id: null,
    estado_contrato_id: null,
    tipologia_cliente_id: null,
    grau_sencivel_id:null,
    doc_suspenso:null,
    plano_prestacao:null,
    contencioso:null,
    contrato_id:null,
    cliente_id:null,
    total_divida:0,
    email:null,
    factura_id:null,
    dias_atraso:null,
    divida_atraso:null
  }
  _checked:boolean=false
  loading:boolean=false
  public filters = {
    search: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    pagination: {
      perPage: 10,// Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima,
      lastPage: null,
      total: null // defini o total de registo da BD
    },
    filter: null // subelemente do filter
  }
  private incumprimetos: any = [];
  private getAllInvalidateConbranca: any = [];
  checkedAll: boolean = true;
  private facturasIncumprimento: any = [];

  private dadosClienteSelecionado = {
    nome: null,
    totaldivida: null
  }
  getEstadoContencioso: Object = {}
  private cliente: any = {}


  private dashboard = {
    totalContratos: null,
    totalFacturas: null,
    totaldividas: null,
  };
  distritos: any[]
  municipios: any[]
  bairros: any[]
  quarteiroes: any[]

  constructor(
    private auth: AuthService,
    private http: HttpService,
    private configService: ConfigService
  ) { }

  ngOnInit() {
    this.getPageFilterData(1);
    this.dashboardFacturacao();
    this.listaIncumprimentos();
    this.grauSencibilidade()
    this.http.filters.pagination.perPage = 5
    this.http.filters.pagination.page = 5
    this.getMunicipios()
    this.getEstadoOfContrato()
    this.getTipologiaCliente()
  }

  private getMunicipios() {

    this.municipios = []
    this.filter.distrito_id = null
    this.filter.bairro_id = null
    this.http.call_get('municipio/selectBox', null)
      .subscribe(
        response => {
          this.municipios = Object(response).data;
        }
      );
  }


  private selectBoxDistritosByMunicipio(id) {
    this.distritos = [];
    this.filter.bairro_id = null
    if (!id || id == 'null') return
    this.http.call_get('distrito/getDistritosByMunicipio/' + id, null).subscribe(
      response => {
        this.distritos = Object(response).data;
      }
    );
  }

  private selectBoxBairrosByDistrito(id) {
    this.bairros = []


    if (!id || id == 'null') return
    this.http.call_get('bairro/selectBoxByDistrito/' + id, null).subscribe(
      response => {
        this.bairros = Object(response);
      }
    );
  }

  _getQuarteirosDoBairro(id) {
    this.http.call_get(`quarteirao/getQuarteiraosByBairro/${id}`, null)
      .subscribe(
        response => {
          this.quarteiroes = Object(response).data
        }
      )
  }


  private dashboardFacturacao() {
    this.http.call_get('contrato/dashboardIncumprimentos', null).subscribe(
      response => {
        this.somaTotaisDoDashboard(Object(response).data)
      }
    )
  }

  async retornarFacturaIncumprimetoPorContrato(contrato_id: any, nome: any) {
    this.loadingListarFacturas = true
    this.dadosClienteSelecionado.nome = nome + ' - ' + contrato_id;
    await this.http.call_get('facturasIncumprimento/contratos/' + contrato_id, null).subscribe(
      response => {
        this.facturasIncumprimento = Object(response).data
        this.somaTotais(this.facturasIncumprimento)
        this.loadingListarFacturas = false
      }
    )
  }

  private get incumprimentosChecked(): any[] {
    return this.incumprimetos.filter(val => val.checked === true);
  }

  setCheckedAll(checked: boolean) {
    //this._checked=true
    this.checkedAll = checked;
    this.incumprimetos.forEach(f => f.checked = checked);
  }

  updateChecked(f: any,event:any) {

    this.incumprimetos.forEach(element => {

     if(element.factura_id === f.factura_id){
       element.checked=event
      }
      Object.assign(element);
    });

    const lengthFalse = this.incumprimetos.filter(val => val.checked === true);
    this.checkedAll = lengthFalse.length === this.incumprimetos.length ? true : false;
  }

  private async criarOrdemServico() {

    if (this.incumprimetos === 0) {
      this.configService.showAlert("Nenhuma linha seleccionada", "alert-error", true);
      return;
    }
    if (this.incumprimentosChecked.length == 1) {
      this.configService.showAlert("Seleccione no minimo duas ou mais linhas", "alert-error", true);
      return;
    }

    this.configService.showAlert("A processar...", "alert-info", true);
    this.modalShowEvent.show();
    const incumprimentos = this.incumprimentosChecked;
  }

  private closeModal() {
    this.modalAppendServico.hide();
  }

  private contrato: any = [];

  private setDataContrato(item) {

    this.contrato = item;


  }

  private notificarSMS() {
    const data = {
      cliente_id: this.cliente.cliente_id,
      contrato_id: this.cliente.contrato_id,
      factura_id: this.cliente.factura_id
    }

    this.loading = true
    this.http.__call('api/v1/notifications/customer/sms', data)
      .subscribe(response => {
        this.loading = false
      })
  }

  private notificarEMAIL() {

    const data = {
      cliente_id: this.cliente.cliente_id,
      contrato_id: this.cliente.contrato_id,
      factura_id: this.cliente.factura_id
    }

    this.loading = true
    this.http.__call('api/v1/notifications/customer/email', data)
      .subscribe(response => {
        this.loading = false
      })
  }

  private notificarClienteModal(item) {
    this.cliente = item
  }

  getPageFilterData(page: number) {
    if (this.filters.pagination.perPage == null) {
      return;
    }
    this.filters.pagination.page = Number(page);
    this.listaIncumprimentos();
  }
  private listaIncumprimentos() {
    this.filters.filter = this.filter
    this.loading = true

    this.http.__call('cliente/listaDeIncumprimentos', this.filters).subscribe(
      response => {
        this.filters.pagination.lastPage = Object(response).data.lastPage;
        this.filters.pagination.page = Object(response).data.page;
        this.filters.pagination.total = Object(response).data.total;
        this.filters.pagination.perPage = Object(response).data.perPage;
        this.incumprimetos = Object(response).data.data;
        for(let i=0;i<this.incumprimetos.length; i++){
          this.incumprimetos[i]={
             ...this.incumprimetos[i],
             checked:false
          }
        }
        this.loading = false
      }
    );
  }

  private estados_contrato: any = []
  private getEstadoOfContrato() {

    this.http.call_get("estado-contrato/selectBox")
      .subscribe(res => {
        this.estados_contrato = Object(res)
      })
  }

  private tipologias_cliente: any = []
  private getTipologiaCliente() {
    this.http.call_get('tipologia-cliente/selectBox')
      .subscribe(res => {
        this.tipologias_cliente = Object(res)
      })
  }

  private grau_sencivel: any = []
  private grauSencibilidade() {
    this.http.call_get('nivel-sensibilidade/selectBox')
      .subscribe(res => {
        this.grau_sencivel = Object(res)
      })
  }

  private somaTotais(dados) {
    var total_divida: number = 0;
    dados.forEach(element => {
      total_divida += element.valor_aberto;
    });
    this.dadosClienteSelecionado.totaldivida = total_divida;
  }

  private somaTotaisDoDashboard(dados) {
    var total_contratos: number = 0;
    var total_facturas: number = 0;
    var total_Dividas: number = 0;
    dados.forEach(element => {
      total_facturas += element.numero_facturas;
      total_Dividas += element.total_divida;
    });
    total_contratos = dados.length;
    this.dashboard.totalContratos = total_contratos;
    this.dashboard.totalFacturas = total_facturas;
    this.dashboard.totaldividas = total_Dividas;

  }

  private imprimirCartaNotificacao(dados) {
    dados['divida'] = this.configService.numberFormat(dados.total_divida)
    this.configService.imprimirAvisoDeCorte(dados, "1ª Via", "imprimir")
  }
  private NotificarContenciosoEstado(flag,lista):any {

    this.filter.cliente_id=lista.cliente_id
    this.filter.contrato_id=lista.contrato_id
    this.filter.email=lista.email
    this.filter.factura_id=lista.factura_id
    this.filter.total_divida=lista.total_divida


    this.http.__call("estado_contenciosoByFlag", { flag }).subscribe(
      res => {
        if (Object(res).id) {
           this.loading = false;
           this.NotificarContencioso(res.id,lista.factura_id)
        }
      });
      this.loading = false;
    }


  private _NotificarContenciosoEstado(flag):any {

    this.http.__call("estado_contenciosoByFlag", { flag }).subscribe(
      res => {
        if (Object(res).id) {
           this.loading = false;
           this.NotificarContenciosoMassiva(res.id)
        }
      });
      this.loading = false;
    }
    private updateIsContenciosoFlag(id):any {
      this.http.__call("update_contencioso_flag/"+id, null).subscribe(
        res => {

          this.listaIncumprimentos()
          if (Object(res).id) {
             this.loading = false;
          }
        });
        this.loading = false;
      }


      private NotificarContenciosoMassiva(estado_id){
        let getIn=0

         let request=[]
         let arrayIncuprimentoGrouped=[]
         let contenciosoFactura=[]
         let groupObject={}
         this.loading=true
         let total_divida=0
         let allFacturas=[]
         let _allFacturas=[]
         for(let item of this.incumprimetos){
              if(item.checked){
                 console.log(item)
                allFacturas.push(item.factura_id)
                  contenciosoFactura.push(this.http.__call("contencioso_factura", {
                    cliente_id: item.cliente_id,
                    contrato_id: item.contrato_id,
                    factura_id:item.factura_id,
                  }))
                  this.updateIsContenciosoFlag(item.factura_id)
              }

             if(item.checked){
                if(groupObject[item.contrato_id]){
                    total_divida +=item.total_divida
                    groupObject[item.contrato_id]={
                              ...item,
                              total_divida:total_divida
                      }

                }else{
                  total_divida = item.total_divida
                  groupObject[item.contrato_id]=item

                }
             }

         }
         if(contenciosoFactura){
             console.log('allFacturas',allFacturas,contenciosoFactura)
             forkJoin(contenciosoFactura).subscribe(
                res => {
                  if (Object(res).id) {
                    this.loading = false;
                  }
              });
         }


        for (const item in groupObject) {
          arrayIncuprimentoGrouped.push(groupObject[item])
        }

        for(let item of arrayIncuprimentoGrouped){


        if(this.checkedAll){

            request.push(
                          this.http.__call("create_contencioso", {
                              estado_contencioso_id:estado_id,
                              cliente_id: item.cliente_id,
                              factura_id:item.factura_id,
                              contrato_id: item.contrato_id,
                              email:item.email,
                              total_divida:item.total_divida,
                              allFacturas
                            })
                        )
                   this.updateIsContenciosoFlag(item.contrato_id)
        }else{
           if(item.checked){

              request.push(
                            this.http.__call("create_contencioso", {
                                estado_contencioso_id:estado_id,
                                cliente_id: item.cliente_id,
                                contrato_id: item.contrato_id,
                                factura_id:item.factura_id,
                                email:item.email,
                                total_divida:item.total_divida,
                                allFacturas
                                })
                          )
                          this.updateIsContenciosoFlag(item.contrato_id)
           }
        }

         }

         if(request){
            forkJoin(request).subscribe(
              res=>{
                if (Object(res).code == 200) {
                  getIn <=0 && this.configService.showAlert("Notificação Contencioso Enviado", 'alert-success', true);
                //this.updateIsContenciosoFlag(config_id)
                this.loading = false;
                getIn=1
              } else {
                  this.loading = false;
                  this.configService.showAlert(Object(res).message, 'alert-danger', true);
              }
              this.loading = false;
              getIn=1
              }
            )
         }




        // this.loading = false;
     }

  private NotificarContencioso(id,config_id){

    this.loading=true
    this.http.__call("create_contencioso", {
         estado_contencioso_id:id,
         cliente_id: this.filter.cliente_id,
         contrato_id: this.filter.contrato_id,
         factura_id:this.filter.factura_id,
         email:this.filter.email,
         total_divida:this.filter.total_divida
        }).subscribe(
      res => {
        if (Object(res).code == 200) {
          this.configService.showAlert("Notificação Contencioso Enviado", 'alert-success', true);
          this.updateIsContenciosoFlag(config_id)

          this.http.__call("contencioso_factura", {
            cliente_id: this.filter.cliente_id,
            contrato_id: this.filter.contrato_id,
            factura_id:this.filter.factura_id,
            contencioso_id:Object(res).data.id
          }).subscribe(
            res => {
              if (Object(res).id) {
                this.loading = false;
              }
          });

        } else {
          this.loading = false;
          this.configService.showAlert(Object(res).message, 'alert-danger', true);
        }
      });
    this.loading = false;
  }

  hendleInvalidarCobrancaFactura(facturasIncobraveis){

   this.loading=true
    this.http.__call("api/v1/facturas/processo-incobraveis", { factura_ids: facturasIncobraveis }).subscribe(
      res => {
        if (Object(res).code == 200) {
          this.configService.showAlert(Object(res).message, 'alert-success', true)
        } else {
          this.loading = false;
          this.configService.showAlert(Object(res).message, 'alert-danger', true);
        }
      });
    this.loading = false;
  }

  invalidarCobrancaFactura(){
    this.incumprimetos.filter(val => val.checked === true);
    this.getAllInvalidateConbranca=[]
    for(let item of this.incumprimetos){
        if(item.checked){
          this.getAllInvalidateConbranca.push(item.factura_id)
        }
    }

    if(!this.getAllInvalidateConbranca.length){
         this.configService.showAlert("Porfavor seleccione facturas incobráveis", 'alert-danger', true);
         return
     }

    Swal.fire({
      title: 'Aviso!',
      text: "Deseja não cobrar as factura seleccionadas?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, não cobrar',
      cancelButtonText: 'Não',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
         this.hendleInvalidarCobrancaFactura(this.getAllInvalidateConbranca)

      }
    })



  }

}
