import { Injectable, EventEmitter } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ApiService } from 'src/app/providers/http/api.service';
import * as moment from 'moment';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';

@Injectable({
  providedIn: 'root'
})
export class RescisaoService {

  public pdfEvent = new EventEmitter<Object>();

  constructor(private http: ApiService) { }

  validateSigla:any

  public imprimirRescisao(id, report = 'imprimir', via = '1º Via') {
    this.http.get('report/rescisao/' + id).subscribe(
      response => {
        const dados = Object(response);
        this.validate(dados,report,via)
      }, error => {
      }
    );
  }
    private validate(dados,report,via){

/*     console.log(dados.user.empresa.sigla_empresa) */
      if(dados.user.empresa.sigla_empresa=="EPASN")
      {
          this.PDFRescisaoN(
            dados.cliente,
            dados.tipos_identidades,
            dados.user,
            dados.contrato,
            report,
            via,
            dados.morada_correspondencia,
          )
      }
      else if(dados.user.empresa.sigla_empresa=="EPASLS")
      {
        this.PDFRescisaoLS(
          dados.cliente,
          dados.tipos_identidades,
          dados.user,
          dados.contrato,
          report,
          via,
          dados.morada_correspondencia,
        )
      }else
      {
        dados.user.empresa.sigla_empresa=="EPASLN"?this.PDFRescisaoLN(
          dados.cliente,
          dados.tipos_identidades,
          dados.user,
          dados.contrato,
          report,
          via,
          dados.morada_correspondencia
          )
          :null
      }
    }

  public PDFRescisaoN(
    cliente: any,
    tipos_identidades: any,
    user: any,
    contrato: any,
    report: string = 'imprimir',
    original: any,
    morada_correspondencia: any
  )
  {
    var img_logotipo = user.empresa.logotipo;
    var doc = new jsPDF()
    doc.addImage(img_logotipo, 'JPEG', 10, 17, 24, 20)
    doc.setFontSize(9);
    doc.setFont("arial");
    doc.setTextColor(0);
    doc.text('' + (user.empresa.companyName || ''), 37, 20);
    doc.text('' + (user.empresa.addressDetail || ''), 37, 25);
    doc.text('NIF: ' + (user.empresa.taxRegistrationNumber || ''), 37, 30);
    doc.text('Email: ' + (user.empresa.email || ''), 37, 34);
    doc.text('Telefone: ' +(user.empresa.telefone || ''), 100, 29);
    doc.text('WebSite: ', 100, 31);
    doc.setTextColor(0, 0, 255);
    doc.text('' + (user.empresa.site || ''), 113, 31);
    doc.setFontSize(8);
    doc.setDrawColor(0);
    doc.setFillColor(255);
    doc.rect(153, 16, 45, 15, 'B');
    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.text('CONTRATO N.º ', 155, 20);
    doc.text('CIL: ', 155, 28);
    doc.setFontSize(8);
    doc.setFontType("normal");
    doc.text('' + contrato.contrato_id, 177, 20);
    doc.text('' + (contrato.cil || ''), 162, 28);

    doc.setFontSize(11);
    doc.setFontType("bold");
    doc.text('RESCISÃO DE CONTRATO DE ABASTECIMENTO DE ' + contrato.objecto_contrato.toUpperCase(), 26, 46);
    doc.setFontSize(8);
    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.setFont("arial");
    doc.text('LOCAL DE CONSUMO: ', 10, 62);
    doc.text('' + contrato.tipo_contrato, 42, 62);
    doc.text('CIL: ' + (contrato.cil||''), 10, 65.5);
    if(contrato.bairro!=null){
      doc.text('Morada Local de Consumo:', 10, 69);
      doc.setFontType("normal");
      doc.text('Bairro/Zona ' + (contrato.bairro || '') + ', Quarteirão ' + (contrato.quarteirao || ''), 10, 72);
      doc.text(((contrato.is_predio) ? 'Prédio ' + (contrato.predio_nome || '') + ', ' + (contrato.predio_andar || '') + 'º Andar - Porta ' : 'Residência ') + (contrato.moradia_numero || ''), 10, 75);
      doc.text('' + contrato.municipio, 10, 78);
    }
    doc.setFontType("bold");
    doc.text('CLIENTE N.º: ' + contrato.cliente_id, 85, 62);
    doc.text('NIF: ', 85, 69);
    doc.text('Telefone: ', 85, 72);
    doc.setFontType("bold");
    doc.text('EMAIL: ', 85, 75);
    doc.setFontType("normal");
    doc.text('' + contrato.cliente_nome, 85, 65.5);

    let nif = tipos_identidades.find(obj => obj.nome == 'NIF')
    doc.text(' ' + (nif ? nif.numero_identidade : ""), 92, 69);

    doc.text('' + (contrato.cliente_telefone||''), 97, 72);
    doc.setTextColor(0, 0, 255);
    doc.text('' + (contrato.cliente_email||''), 96, 75);

    doc.setFontSize(8)
    doc.setTextColor(0);
    doc.setFont("arial");
    doc.setFontType("normal");


    let identidade_text = nif ? `, portador do ${nif.nome} n.º ${nif.numero_identidade}, ` : ", "

    doc.text('Solicitação de rescisão do contrato de Abastecimento de Água e Saneamento de Águas Residuais celebrado entre a ' + user.empresa.companyName + ', NIF ' + user.empresa.taxRegistrationNumber + ', e o Cliente ' + contrato.cliente_nome + identidade_text + ' para o Local de Consumo acima identificado.', 10, 88, { maxWidth: 175, align: 'justify' });

    doc.setFontType("bold");
    doc.text('Tarifa: ', 10, 103);
    doc.text('Contador: ', 10, 109);
    doc.text('Morada de Correspondência: ', 10, 115);
    doc.text('Tipo de Utilização: ', 72, 103);
    doc.text('Leitura: ', 72, 109);
    doc.text('Data Início Contrato: ', 144, 103);
    doc.text('Data Rescisão: ', 144, 109);
    doc.text('Motivo da Rescisão: ', 72, 115);

    doc.setFontType("normal");
    doc.text('' + (contrato.tarifa||''), 20, 103);
    doc.text('' + (contrato.contador||''), 24, 109);
    if (morada_correspondencia.morada_correspondencia_id == null) {
      doc.text('' + (contrato.cliente_morada||''), 10, 119);
      doc.text('' + (contrato.municipio||''), 10, 122);
    }
    else {
      doc.text('Bairro/Zona ' + morada_correspondencia.bairro + ', Quarteirão ' + morada_correspondencia.quarteirao, 10, 119);
      doc.text(((morada_correspondencia.is_predio) ? 'Prédio ' + morada_correspondencia.predio_nome + ', ' + (morada_correspondencia.predio_andar ? morada_correspondencia.predio_andar + ' º Andar - ' : '') + 'Porta ' : 'Residência ') + morada_correspondencia.numero_moradia, 10, 122);
      doc.text('' + (morada_correspondencia.municipio||''), 10, 125);
    }

    doc.text('' + (contrato.tipo_contrato||''), 98, 103);
    doc.text('' + (contrato.ultima_leitura||''), 84, 109);
    doc.text('' + (contrato.data_inicio == null ? '' : moment(contrato.data_inicio).format("DD/MM/YYYY")), 173, 103);
    doc.text('' + moment(contrato.data_rescisao).format("DD/MM/YYYY"), 164, 109);
    doc.text('' + (contrato.motivo_rescisao||''), 99, 115);

    doc.setFontSize(8)
    doc.setTextColor(0);
    doc.setFont("arial");
    doc.setFontType("bold");
    doc.text('O Cliente declara ter conhecimento que será da sua responsabilidade o pagamento das tarifas aplicáveis até ao momento da efectivação da rescisão contratual.', 10, 135, { maxWidth: 175, align: 'justify' });
    doc.setTextColor(0);
    var dataActual = moment(new Date()).format("DD/MM/YYYY");
    doc.text(user.empresa.city + ', ' + dataActual, 10, 154);
    doc.text('O Cliente ', 10, 164);
    doc.text('_____________________________________________________________________________________________________________', 24, 164);
    doc.text(user.empresa.companyName, 10, 190);

    doc.text('_________________________________________________________', 10, 215);
    doc.setFontType("normal");
    doc.text('(Eng. André Camilo)', 13, 223);
    doc.text('Presidente do Conselho de Administração', 13, 230);

/*     doc.setFontType("bold");
    doc.text('___________________________________', 125, 215);
    doc.setFontType("normal");
    doc.text('(Dr. Floridy Fortuna Pinto Cassinda)', 125, 223);
    doc.text('Administradora Área Administrativa e Financeira', 125, 230); */

    doc.setFontSize(8);
    doc.setDrawColor(0);
    doc.setFillColor(0);

    doc.line(10, doc.internal.pageSize.height - 9, 194, doc.internal.pageSize.height - 9); // vertical line

    doc.setFontSize(6);
    doc.text("NIF: " + user.empresa.taxRegistrationNumber + " - " + user.empresa.companyName + " / " + user.empresa.addressDetail + " / " + (user.empresa.email || '') + " / " + (user.empresa.email || ''), 105, doc.internal.pageSize.height - 6, null, null, 'center');
    doc.setFontSize(7);
    doc.text('Processado por programa validado nº 4/AGT119', 105, doc.internal.pageSize.height - 3, null, null, 'center');

    doc.autoPrint();
    window.open(doc.output("bloburl")); //opens the data uri in new window
  }


  public PDFRescisaoLN(
    cliente: any,
    tipos_identidades: any,
    user: any,
    contrato: any,
    report: string = 'imprimir',
    original: any,
    morada_correspondencia: any
  )
  {
    var img_logotipo = user.empresa.logotipo;
    var doc = new jsPDF()
    doc.addImage(img_logotipo, 'JPEG', 10, 17, 24, 20)
    doc.setFontSize(9);
    doc.setFont("arial");
    doc.setTextColor(0);
    doc.text('' + (user.empresa.companyName || ''), 37, 20);
    doc.text('' + (user.empresa.addressDetail || ''), 37, 25);
    doc.text('NIF: ' + (user.empresa.taxRegistrationNumber || ''), 37, 30);
    doc.text('Email: ' + (user.empresa.email || ''), 37, 34);
    doc.text('Telefone: ' + (user.empresa.telefone || ''), 100, 30);
    doc.text('WebSite: ', 100, 34);
    doc.setTextColor(0, 0, 255);
    doc.text('' + (user.empresa.site || ''), 113, 31);
    doc.setFontSize(8);
    doc.setDrawColor(0);
    doc.setFillColor(255);
    doc.rect(153, 16, 45, 15, 'B');
    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.text('CONTRATO N.º ', 155, 20);
    doc.text('CIL: ', 155, 28);
    doc.setFontSize(8);
    doc.setFontType("normal");
    doc.text('' + contrato.contrato_id, 177, 20);
    doc.text('' + (contrato.cil || ''), 162, 28);

    doc.setFontSize(11);
    doc.setFontType("bold");
    doc.text('RESCISÃO DE CONTRATO DE ABASTECIMENTO DE ' + contrato.objecto_contrato.toUpperCase(), 26, 46);
    doc.setFontSize(8);
    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.setFont("arial");
    doc.text('LOCAL DE CONSUMO: ', 10, 62);
    doc.text('' + contrato.tipo_contrato, 42, 62);
    doc.text('CIL: ' + (contrato.cil||''), 10, 65.5);
    if(contrato.bairro!=null){
      doc.text('Morada Local de Consumo:', 10, 69);
      doc.setFontType("normal");
      doc.text('Bairro/Zona ' + (contrato.bairro || '') + ', Quarteirão ' + (contrato.quarteirao || ''), 10, 72);
      doc.text(((contrato.is_predio) ? 'Prédio ' + (contrato.predio_nome || '') + ', ' + (contrato.predio_andar || '') + 'º Andar - Porta ' : 'Residência ') + (contrato.moradia_numero || ''), 10, 75);
      doc.text('' + contrato.municipio, 10, 78);
    }
    doc.setFontType("bold");
    doc.text('CLIENTE N.º: ' + contrato.cliente_id, 85, 62);
    doc.text('NIF: ', 85, 69);
    doc.text('Telefone: ', 85, 72);
    doc.setFontType("bold");
    doc.text('EMAIL: ', 85, 75);
    doc.setFontType("normal");
    doc.text('' + contrato.cliente_nome, 85, 65.5);

    let nif = tipos_identidades.find(obj => obj.nome == 'NIF')
    doc.text(' ' + (nif ? nif.numero_identidade : ""), 92, 69);

    doc.text('' + (contrato.cliente_telefone||''), 97, 72);
    doc.setTextColor(0, 0, 255);
    doc.text('' + (contrato.cliente_email||''), 96, 75);

    doc.setFontSize(8)
    doc.setTextColor(0);
    doc.setFont("arial");
    doc.setFontType("normal");


    let identidade_text = nif ? `, portador do ${nif.nome} n.º ${nif.numero_identidade}, ` : ", "

    doc.text('Solicitação de rescisão do contrato de Abastecimento de Água e Saneamento de Águas Residuais celebrado entre a ' + user.empresa.companyName + ', NIF ' + user.empresa.taxRegistrationNumber + ', e o Cliente ' + contrato.cliente_nome + identidade_text + ' para o Local de Consumo acima identificado.', 10, 88, { maxWidth: 175, align: 'justify' });

    doc.setFontType("bold");
    doc.text('Tarifa: ', 10, 103);
    doc.text('Contador: ', 10, 109);
    doc.text('Morada de Correspondência: ', 10, 115);
    doc.text('Tipo de Utilização: ', 72, 103);
    doc.text('Leitura: ', 72, 109);
    doc.text('Data Início Contrato: ', 144, 103);
    doc.text('Data Rescisão: ', 144, 109);
    doc.text('Motivo da Rescisão: ', 72, 115);

    doc.setFontType("normal");
    doc.text('' + (contrato.tarifa||''), 20, 103);
    doc.text('' + (contrato.contador||''), 24, 109);
    if (morada_correspondencia.morada_correspondencia_id == null) {
      doc.text('' + (contrato.cliente_morada||''), 10, 119);
      doc.text('' + (contrato.municipio||''), 10, 122);
    }
    else {
      doc.text('Bairro/Zona ' + morada_correspondencia.bairro + ', Quarteirão ' + morada_correspondencia.quarteirao, 10, 119);
      doc.text(((morada_correspondencia.is_predio) ? 'Prédio ' + morada_correspondencia.predio_nome + ', ' + (morada_correspondencia.predio_andar == null ? '' : morada_correspondencia.predio_andar) + 'º Andar - Porta ' : 'Residência ') + morada_correspondencia.numero_moradia, 10, 122);
      doc.text('' + (morada_correspondencia.municipio||''), 10, 125);
    }

    doc.text('' + (contrato.tipo_contrato||''), 98, 103);
    doc.text('' + (contrato.ultima_leitura||''), 84, 109);
    doc.text('' + moment(contrato.data_inicio).format("DD/MM/YYYY"), 173, 103);
    doc.text('' + moment(contrato.data_rescisao).format("DD/MM/YYYY"), 164, 109);
    doc.text('' + (contrato.motivo_rescisao||''), 99, 115);

    doc.setFontSize(8)
    doc.setTextColor(0);
    doc.setFont("arial");
    doc.setFontType("bold");
    doc.text('O Consumidor declara ter conhecimento que será da sua responsabilidade o pagamento das tarifas aplicáveis até ao momento da efectivação da rescisão contratual.', 10, 135, { maxWidth: 175, align: 'justify' });
    doc.setTextColor(0);
    var dataActual = moment(new Date()).format("DD/MM/YYYY");
    doc.text(user.empresa.city + ', ' + dataActual, 10, 154);
/*     doc.text('O Cliente ', 10, 164);
    doc.text('_____________________________________________________________________________________________________________', 24, 164); */
    doc.text(user.empresa.companyName, 10, 190);

    doc.setFontType("bold");
    doc.text('Pelo Fornecedor', 35, 215);
    doc.text('___________________________________________________', 10, 222);
    doc.setFontType("normal");
    doc.text('(André Camilo)', 35, 227);

    doc.setFontType("bold");
    doc.text('Pelo Consumidor', 150, 215);
    doc.text('___________________________________________________', 125, 222);
    doc.setFontType("normal");
    doc.text('(' + contrato.cliente_nome + ')', 150, 227);

    doc.setFontSize(8);
    doc.setDrawColor(0);
    doc.setFillColor(0);

    doc.line(10, doc.internal.pageSize.height - 9, 194, doc.internal.pageSize.height - 9); // vertical line

    doc.setFontSize(6);
    doc.text("NIF: " + user.empresa.taxRegistrationNumber + " - " + user.empresa.companyName + " / " + user.empresa.addressDetail + " / " + (user.empresa.telefone == null ? '' : user.empresa.telefone) + " / " + (user.empresa.email == null ?'': user.empresa.email), 105, doc.internal.pageSize.height - 6, null, null, 'center');
    doc.setFontSize(7);
    doc.text('Processado por programa validado nº 4/AGT119', 105, doc.internal.pageSize.height - 3, null, null, 'center');

    doc.autoPrint();
    window.open(doc.output("bloburl")); //opens the data uri in new window
  }

  public PDFRescisaoLS(
    cliente: any,
    tipos_identidades: any,
    user: any,
    contrato: any,
    report: string = 'imprimir',
    original: any,
    morada_correspondencia: any
  )
  {
    var img_logotipo = user.empresa.logotipo;
    var doc = new jsPDF()
    doc.addImage(img_logotipo, 'JPEG', 10, 17, 24, 20)
    doc.setFontSize(9);
    doc.setFont("arial");
    doc.setTextColor(0);
    doc.text('' + (user.empresa.companyName || ''), 37, 20);
    doc.text('' + (user.empresa.addressDetail || ''), 37, 25);
    doc.text('NIF: ' + (user.empresa.taxRegistrationNumber || ''), 37, 30);
    doc.text('Email: ' + (user.empresa.email || ''), 37, 34);
    doc.text('Telefone: ' + (user.empresa.telefone || ''), 106, 27);
    doc.text('WebSite: ', 100, 31);
    doc.setTextColor(0, 0, 255);
    doc.text('' + (user.empresa.site || ''), 113, 31);
    doc.setFontSize(8);
    doc.setDrawColor(0);
    doc.setFillColor(255);
    doc.rect(153, 16, 45, 15, 'B');
    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.text('CONTRATO N.º ', 155, 20);
    doc.text('CIL: ', 155, 28);
    doc.setFontSize(8);
    doc.setFontType("normal");
    doc.text('' + contrato.contrato_id, 177, 20);
    doc.text('' + (contrato.cil || ''), 162, 28);

    doc.setFontSize(11);
    doc.setFontType("bold");
    doc.text('RESCISÃO DE CONTRATO DE ABASTECIMENTO DE ' + contrato.objecto_contrato.toUpperCase(), 26, 46);
    doc.setFontSize(8);
    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.setFont("arial");
    doc.text('LOCAL DE CONSUMO: ', 10, 62);
    doc.text('' + contrato.tipo_contrato, 42, 62);
    doc.text('CIL: ' + (contrato.cil||''), 10, 65.5);
    if(contrato.bairro!=null){
      doc.text('Morada Local de Consumo:', 10, 69);
      doc.setFontType("normal");
      doc.text('Bairro/Zona ' + contrato.bairro + ', Quarteirão ' + contrato.quarteirao, 10, 72);
      doc.text(((contrato.is_predio) ? 'Prédio ' + contrato.predio_nome + ', ' + (contrato.predio_andar ? contrato.predio_andar + ' º Andar - ' : '') + 'Porta ' : 'Residência ') + contrato.moradia_numero, 10, 75);
      doc.text('' + contrato.municipio, 10, 78);
    }
    doc.setFontType("bold");
    doc.text('CLIENTE N.º: ' + contrato.cliente_id, 85, 62);
    doc.text('NIF: ', 85, 69);
    doc.text('Telefone: ', 85, 72);
    doc.setFontType("bold");
    doc.text('EMAIL: ', 85, 75);
    doc.setFontType("normal");
    doc.text('' + contrato.cliente_nome, 85, 65.5);

    let nif = tipos_identidades.find(obj => obj.nome == 'NIF')
    doc.text(' ' + (nif ? nif.numero_identidade : ""), 92, 69);

    doc.text('' + (contrato.cliente_telefone||''), 97, 72);
    doc.setTextColor(0, 0, 255);
    doc.text('' + (contrato.cliente_email||''), 96, 75);

    doc.setFontSize(8)
    doc.setTextColor(0);
    doc.setFont("arial");
    doc.setFontType("normal");


    let identidade_text = nif ? `, portador do ${nif.nome} n.º ${nif.numero_identidade}, ` : ", "

    doc.text('Solicitação de rescisão do contrato de Abastecimento de Água e Saneamento de Águas Residuais celebrado entre a ' + user.empresa.companyName + ', NIF ' + user.empresa.taxRegistrationNumber + ', e o Cliente ' + contrato.cliente_nome + identidade_text + ' para o Local de Consumo acima identificado.', 10, 88, { maxWidth: 175, align: 'justify' });

    doc.setFontType("bold");
    doc.text('Tarifa: ', 10, 103);
    doc.text('Contador: ', 10, 109);
    doc.text('Morada de Correspondência: ', 10, 115);
    doc.text('Tipo de Utilização: ', 72, 103);
    doc.text('Leitura: ', 72, 109);
    doc.text('Data Início Contrato: ', 144, 103);
    doc.text('Data Rescisão: ', 144, 109);
    doc.text('Motivo da Rescisão: ', 72, 115);

    doc.setFontType("normal");
    doc.text('' + (contrato.tarifa||''), 20, 103);
    doc.text('' + (contrato.contador||''), 24, 109);
    if (morada_correspondencia.morada_correspondencia_id == null) {
      doc.text('' + (contrato.cliente_morada||''), 10, 119);
      doc.text('' + (contrato.municipio||''), 10, 122);
    }
    else {
      doc.text('Bairro/Zona ' + morada_correspondencia.bairro + ', Quarteirão ' + morada_correspondencia.quarteirao, 10, 119);
      doc.text(((morada_correspondencia.is_predio) ? 'Prédio ' + morada_correspondencia.predio_nome + ', ' + (morada_correspondencia.predio_andar ? morada_correspondencia.predio_andar + ' º Andar - ' : '') + 'Porta ' : 'Residência ') + morada_correspondencia.numero_moradia, 10, 122);
      doc.text('' + (morada_correspondencia.municipio||''), 10, 125);
    }

    doc.text('' + (contrato.tipo_contrato||''), 98, 103);
    doc.text('' + (contrato.ultima_leitura||''), 84, 109);
    doc.text('' + moment(contrato.data_inicio).format("DD/MM/YYYY"), 173, 103);
    doc.text('' + moment(contrato.data_rescisao).format("DD/MM/YYYY"), 164, 109);
    doc.text('' + (contrato.motivo_rescisao||''), 99, 115);

    doc.setFontSize(8)
    doc.setTextColor(0);
    doc.setFont("arial");
    doc.setFontType("bold");
    doc.text('O Cliente declara ter conhecimento que será da sua responsabilidade o pagamento das tarifas aplicáveis até ao momento da efectivação da rescisão contratual.', 10, 135, { maxWidth: 175, align: 'justify' });
    doc.setTextColor(0);
    var dataActual = moment(new Date()).format("DD/MM/YYYY");
    doc.text(user.empresa.city + ', ' + dataActual, 10, 154);
    doc.text('O Cliente ', 10, 164);
    doc.text('_____________________________________________________________________________________________________________', 24, 164);
    doc.text(user.empresa.companyName, 10, 190);

    doc.text('___________________________________', 10, 215);
    doc.setFontType("normal");
/*     doc.text('(Eng. André Camilo)', 10, 223);
    doc.text('Presidente do Conselho de Administração', 10, 230);

    doc.setFontType("bold");
    doc.text('___________________________________', 125, 215);
    doc.setFontType("normal");
    doc.text('(Dr. Floridy Fortuna Pinto Cassinda)', 125, 223);
    doc.text('Administradora Área Administrativa e Financeira', 125, 230); */


    doc.setFontType("bold");
    doc.text('Pelo Fornecedor', 35, 215);
    doc.text('___________________________________________________', 10, 222);
    doc.setFontType("normal");
    doc.text('(André Camilo)', 35, 227);

    doc.setFontType("bold");
    doc.text('Pelo Consumidor', 150, 215);
    doc.text('___________________________________________________', 125, 222);
    doc.setFontType("normal");
    doc.text('(' + contrato.cliente_nome + ')', 140, 227);

    doc.setFontSize(8);
    doc.setDrawColor(0);
    doc.setFillColor(0);

    doc.line(10, doc.internal.pageSize.height - 9, 194, doc.internal.pageSize.height - 9); // vertical line

    doc.setFontSize(6);
    doc.text("NIF: " + user.empresa.taxRegistrationNumber + " - " + user.empresa.companyName + " / " + user.empresa.addressDetail + " / " + user.empresa.telefone + " / " + user.empresa.email, 105, doc.internal.pageSize.height - 6, null, null, 'center');
    doc.setFontSize(7);
    doc.text('Processado por programa validado nº 4/AGT119', 105, doc.internal.pageSize.height - 3, null, null, 'center');

    doc.autoPrint();
    window.open(doc.output("bloburl")); //opens the data uri in new window
  }
}
