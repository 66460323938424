import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../../providers/http/http.service';
import { AuthService } from '../../../providers/auth/auth.service';
import { ConfigModuloService } from '../../../services/config-modulo.service';
import { ConfigService } from '../../../providers/config/config.service';
import { RoteiroService } from '../../reports/roteiro/roteiro.service';
import { RotaService } from '../rotas.service';
import { ExcelAutoService } from 'src/app/services/excel/excel-auto.service';
import * as moment from 'moment';
import { alphaNumericValidatorExtension } from '@rxweb/reactive-form-validators/validators-extension';
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-processar-leituras',
  templateUrl: './processar-leituras.component.html',
  styleUrls: ['./processar-leituras.component.css']
})
export class ProcessarLeiturasComponent implements OnInit {

  public currentUser: any;

  private title: string = "Registar Rota"
  private rota_header = {
    id: null,
    descricao: null,
    provincia_id: null,
    municipio_id: null,
    leitor_id: null,
    user_nome: null,
    estado: null,
  }


  private lista = {
    id: null
  }


  private tipo_medicaos: any = [];
  public data_default: any;
  public max_date = moment(new Date()).format('YYYY-MM-DD');
  public search: any;
  public search_marker: any;
  public rotaRunsCopy: any[];
  public markersCopy: any[];

  private corridasLeiturasArray = [];
  private total_leituras_negativas: any = 0;
  roteiro: any = {}
  motivos_nao_leitura_list: any = []
  private DadosEmpresa: any = [];
  header_id: number
  loading: any = false;

  userFilter: any = { cliente: '', contrato_id: '' };
  public rotaRuns: any = [];
  public roteirosToExport: any = [];

  markers: marker[] = [
    {
      lat: null,
      lng: null,
      cliente: null,
      morada_format: null,
      contrato_id: null,
      draggable: false,
      iconUrl: null,
    },
  ];


  lat = -8.996737;
  lng = 13.268137;
  zoom = 11;
  markerAnterior: any;


  constructor(
    private auth: AuthService,
    private http: HttpService,
    private configService: ConfigService,
    private _rotaService: RotaService,
    private config: ConfigModuloService,
    private router: Router,
    private reportRoteiro: RoteiroService,
    private route: ActivatedRoute,
    private excelService: ExcelAutoService,
  ) {

    this.route.params.subscribe(params => {
      this.header_id = params['slug']
    })

    this.loadingMethods();
    this.currentUser = this.auth.currentUserValue;
    this._loading();
    this.getTiposMedicaos()

    //filterPipe.transform(this.rotaRuns, { cliente: '', contrato_id : ''});
  }

  ngOnInit() {


  }

  _loading() {
    this.http.filters.search = null
    this._rotaService.getMotivosNaoLeitura()
      .subscribe(
        response => {
          this.motivos_nao_leitura_list = response.data
        }
      )

    this._rotaService.listRotasHeadersChange.subscribe(
      response => {
        this.rotaRuns = response
      }
    )
    this.empresaUser();
  }

  async loadingMethods() {
    await this.listarRotaRun(this.header_id);
    this.http.call_get(`rota-header/${this.header_id}`, null)
      .subscribe(
        response => {
          this.rota_header = Object(response).data
        }
      )
  }

  private getTiposMedicaos() {
    this.http.call_get("tipo-medicao/selectBox", null).subscribe((response) => {
      this.tipo_medicaos = Object(response);
    });
  }

  gotTodEdit() {
    this.router.navigate(['/editar-roteiros/' + this.header_id])
  }

  _closeModal() {
    const modal = document.querySelector('.modal-backdrop')
    modal.classList.remove('modal-backdrop', 'fade', 'show')
  }

  markerDragEnd(m: marker) { }


  exportAsPDF(): void {
    var file = document.getElementsByClassName("tablePDF")[0]
    this.reportRoteiro.reportRoteiro(file, this.DadosEmpresa, 'print', this.rota_header, this.rotaRuns.length);
  }

  exportAsXLSX(): void {
    var CurrentDate = new Date();

    var keys = [
      { key: 'cliente', width: 35, style: { font: { name: 'Calibri' } } },
      { key: 'contrato_id', width: 25 },
      { key: 'cil', width: 30 },
      { key: 'morada_format', width: 55 },
      { key: 'numero_serie', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'leitura', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'data', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'nao_leitura', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'motivo', width: 20, style: { font: { name: 'Calibri' } } },
    ];

    var Cols = ["Cliente", "Contrato", "CIL", "Morada", "Contador", "Leitura", "Data", "Não Leitura", "Motivo"]
    var title = 'Roteiros da Rota ' + this.rota_header.descricao + '';
    var nameFile = "Lista de Roteiros -" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    this.excelService.excels(this.rotaRuns, nameFile, this.DadosEmpresa.logotipo, keys, Cols, title, 5, 9, 30, 3)
  }


  view_residencia = false;


  private setDataLocalInstalacao() {
    //this.local_instalacao.provincia_id =
  }


  view_user = true;
  users: any = [];

  private getUtilizador() {

    if (this.rota_header.user_nome == "") {
      this.setNullUser();
    }
    this.view_user = true;
    this.http.__call('search-utilizador', { start: 1, end: 10, search: this.rota_header.user_nome })
      .subscribe(
        response => {
          this.users = Object(response).data.data;
          this.configService.loaddinStarter('stop');
        }
      );
  }

  private setUtilizador(id, nome) {
    this.rota_header.leitor_id = id;
    this.rota_header.user_nome = nome;
    this.view_user = false;
  }

  private setNullUser() {
    this.rota_header.leitor_id = null;
    this.rota_header.user_nome = null;
    this.view_user = false;
  }



  private setNullCliente() {

    this.rota_header.user_nome = null;
    this.view_user = false;
  }

  private contas: any = [];

  private carregando = {
    filial: 'Seleccione a filial',
    conta: 'Seleccione a conta',
    servico: 'Seleccione o serviço',
    periodo: 'Seleccione o Fim'
  }

  private empresaUser() {
    this.configService.loaddinStarter('start');

    this.http.call_get('empresa/empresa-user', null).subscribe(
      response => {
        this.DadosEmpresa = Object(response).data;
      }
    );
  }

  private realizado: number;
  private pendente: number;


  async listarRotaRun(id: any) {

    this.lista.id = id;

    this.configService.loaddinStarter('start');
    this.http.call_get('rota-run/getRotasRunByRotaHeader/' + id + '/' + this.http.filters.tipo_medicao_id, null).subscribe(
      response => {
        let data = Object(response).data
        this.rotaRuns = [];
        this.roteirosToExport = [];
        this.markers = [];
        this.realizado = 0;
        this.pendente = 0;

        this.realizado = data.filter((objt: any) => objt.processada == 1).length;
        this.pendente = data.filter((objt: any) => objt.processada == 0).length;

        if (Object(response).data != null) {
          this.addRotaRuns(data);
          this.addRotaRunsMarker(data);
        }

        this.configService.loaddinStarter('stop')
      }
    );
  }

  private setRoteiros: any;
  private addRotaRuns(data: any) {
    data.forEach((roteiro: any) => {
      var prop = { classificacao: 'EMPRESA' }

      roteiro.data_leitura = roteiro.data_leitura ? moment(roteiro.data_leitura).format("YYYY-MM-DD") : moment(new Date()).format("YYYY-MM-DD")
      this.rotaRuns.push({ ...roteiro, ...prop })

      //add modelo de exportação para excel
      this.setRoteiros = JSON.parse(JSON.stringify(roteiro))
      this.setRoteiros.leitura = ''
      this.roteirosToExport.push(this.setRoteiros)
    })
  }

  private addRotaRunsMarker(data: any) {
    data.forEach((roteiro: any) => {
      var rota_run = {
        lat: roteiro.latitude || -8.893460,
        lng: roteiro.longitude || 13.355975,
        cliente: roteiro.cliente,
        morada_format: roteiro.morada_format,
        contrato_id: roteiro.contrato_id,
        draggable: true,
        iconUrl: "http://maps.google.com/mapfiles/ms/icons/orange-dot.png"
      }
      this.markers.push(rota_run);
    })
  }

  public markerClicking(marker) {
    this.markerAnterior ? this.markerAnterior.close() : null;

    this.markerAnterior = marker;
    setTimeout(() => {
      $(".gm-style-iw").css({ "max-height": "", height: "auto" });
    }, 500);
  }


  private clearFormInputs() {
    this.rota_header.id = null,
      this.rota_header.leitor_id = null,
      this.rota_header.user_nome = null
  }

  private setDataLeiturasRotaRun() {

    if (this.total_leituras_negativas > 0) {
      return this.configService.showAlert(
        'Deve-se resolver antes todas leituras negativas, para processar as leituras.',
        'alert-error', true);
    }

    this.corridasLeiturasArray = []

    for (let index = 0; index < this.rotaRuns.length; index++) {
      var data = {}

      if (!this.rotaRuns[index].processada && this.rotaRuns[index].leitura != null && this.rotaRuns[index].data_leitura != null) {
        data = {
          rota_run_id: this.rotaRuns[index].rota_run_id,
          rota_header_id: this.rotaRuns[index].rota_header_id,
          contrato_id: this.rotaRuns[index].contrato_id,
          cil: this.rotaRuns[index].cil,
          nao_leitura: this.rotaRuns[index].nao_leitura,
          motivo: "",
          classificacao: this.rotaRuns[index].classificacao,
          rota_run_agendamento_id: this.rotaRuns[index].id,
          leitor_id: this.rotaRuns[index].leitor_id,
          local_consumo_id: this.rotaRuns[index].local_consumo_id,
          contador_id: this.rotaRuns[index].contador_id,
          numero_serie: this.rotaRuns[index].numero_serie,
          local_instalacao_id: this.rotaRuns[index].local_instalacao_id,
          conta_id: this.rotaRuns[index].conta_id,
          moradia_numero: this.rotaRuns[index].moradia_numero,
          rua: this.rotaRuns[index].rua,
          bairro: this.rotaRuns[index].bairro,
          is_predio: this.rotaRuns[index].is_predio,
          predio_id: this.rotaRuns[index].predio_id,
          predio_nome: this.rotaRuns[index].predio_nome,
          cliente_id: this.rotaRuns[index].cliente_id,
          cliente: this.rotaRuns[index].cliente,
          cliente_morada: this.rotaRuns[index].cliente_morada,
          cliente_telefone: this.rotaRuns[index].cliente_telefone,
          leitura: this.rotaRuns[index].leitura,
          ultima_leitura: this.rotaRuns[index].ultima_leitura,
          periodo: this.rotaRuns[index].periodo,
          data_leitura: this.rotaRuns[index].data_leitura,
          estado_rota_id: this.rotaRuns[index].estado_rota_id,
          estado_rota: this.rotaRuns[index].estado_rota,
          estado_rota_slug: this.rotaRuns[index].estado_rota_slug
        }

        this.corridasLeiturasArray.push(data)
      }

      //Inserir os não leitura
      if (!this.rotaRuns[index].processada && this.rotaRuns[index].nao_leitura && this.rotaRuns[index].motivo != null && this.rotaRuns[index].data_leitura != null) {
        data = {
          rota_run_id: this.rotaRuns[index].rota_run_id,
          rota_header_id: this.rotaRuns[index].rota_header_id,
          contrato_id: this.rotaRuns[index].contrato_id,
          cil: this.rotaRuns[index].cil,
          nao_leitura: this.rotaRuns[index].nao_leitura,
          motivo: this.rotaRuns[index].motivo,
          classificacao: this.rotaRuns[index].classificacao,
          rota_run_agendamento_id: this.rotaRuns[index].id,
          leitor_id: this.rotaRuns[index].leitor_id,
          local_consumo_id: this.rotaRuns[index].local_consumo_id,
          contador_id: this.rotaRuns[index].contador_id,
          numero_serie: this.rotaRuns[index].numero_serie,
          local_instalacao_id: this.rotaRuns[index].local_instalacao_id,
          conta_id: this.rotaRuns[index].conta_id,
          moradia_numero: this.rotaRuns[index].moradia_numero,
          rua: this.rotaRuns[index].rua,
          bairro: this.rotaRuns[index].bairro,
          is_predio: this.rotaRuns[index].is_predio,
          predio_id: this.rotaRuns[index].predio_id,
          predio_nome: this.rotaRuns[index].predio_nome,
          cliente_id: this.rotaRuns[index].cliente_id,
          cliente: this.rotaRuns[index].cliente,
          cliente_morada: this.rotaRuns[index].cliente_morada,
          cliente_telefone: this.rotaRuns[index].cliente_telefone,
          leitura: 0,
          ultima_leitura: this.rotaRuns[index].ultima_leitura,
          periodo: this.rotaRuns[index].periodo,
          data_leitura: this.rotaRuns[index].data_leitura,
          estado_rota_id: this.rotaRuns[index].estado_rota_id,
          estado_rota: this.rotaRuns[index].estado_rota,
          estado_rota_slug: this.rotaRuns[index].estado_rota_slugz
        }

        this.corridasLeiturasArray.push(data)
      }
    }
  }

  private selectedLeitura: any;
  hasDate(event, currentLeitura) {
    this.selectedLeitura = currentLeitura;
    var date = $('.data_leitura-' + currentLeitura.id)
    date.css('border', '1px solid transparent');

    if (currentLeitura.leitura && !currentLeitura.data_leitura) {
      date.css('border', '2px solid red');
    }
    this.run_leituras_negativas(currentLeitura);
  }

  run_leituras_negativas(currentLeitura: any) {
    this.total_leituras_negativas = 0;
    var leitura = $('.leitura-' + currentLeitura.id)
    leitura.css('border', '1px solid transparent');
    console.log(leitura)

    for (const roteiros of this.rotaRuns) {
      if ((currentLeitura.id == roteiros.id) && (roteiros.processada == 0) && (roteiros.leitura < 0)) {
        leitura.css('border', '2px solid red');
        console.log(true)
      }
    }
    this.refreshContador();
  }

  refreshContador() {
    for (const roteiros of this.rotaRuns) {
      if ((roteiros.processada == 0) && (roteiros.leitura < 0)) {
        this.total_leituras_negativas++;
      }
    }
  }

  hasDateOncheck(event, value) {
    this.selectedLeitura = value;
    var data_leitura = $('.data_leitura-' + value.rota_run_id)
    var motivo = $('.motivo-' + value.rota_run_id)
    data_leitura.css('border', '1px solid transparent')
    motivo.css('border', '1px solid transparent')

    if (event.target.checked && !value.data_leitura) {
      data_leitura.css('border', '2px solid red')
      motivo.css('border', '2px solid red')
    }
    this.limparColunaLeitura();
  }

  limparCssOfData(event, value) {
    var data_leitura = $('.data_leitura-' + value.id);
    data_leitura.css('border', '1px solid transparent');
  }

  limparColunaLeitura() {
    for (let l = 0; l < this.rotaRuns.length; l++) {
      if (this.selectedLeitura.rota_run_id == this.rotaRuns[l].rota_run_id) {
        this.rotaRuns[l].leitura = null;
      }
    }
  }

  changeDate(event: any) {
    for (const roteito of this.rotaRuns) {
      if (!roteito.processada) {
        roteito.data_leitura = event.target.value
      }
    }
  }


  validateDate(event: any, rotaRuns: any) {
    for (var index = 0; index < this.rotaRuns.length; index++) {

      if (rotaRuns.id == this.rotaRuns[index].id) {
        if (moment(event.target.value).format('YYYY-MM-DD') > moment(new Date()).format('YYYY-MM-DD')) {
          this.rotaRuns[index].data_leitura = null;

          this.configService.showAlert(
            'A data da leitura não deve ser maior que actual.',
            'alert-error', true);
          return;
        }
      }

    }
  }

  _initi_disables() {
    this.loading = true;
    this.total_leituras_negativas = 0;
  }


  private saveLeiturasRotaRun() {
    this.configService.loaddinStarter('start');
    this._initi_disables();

    const url = "leitura/create";
    const requesicoes = [];

    for (const currentReading of this.corridasLeiturasArray) {
      let struture = {
        leituras: [],
        rota_header_id: this.header_id
      }

      struture.leituras.push(currentReading)
      requesicoes.push(this.http.__call(url, struture));
    }

    forkJoin(requesicoes)
      .subscribe(
        res => {
          if (Object(res)) {

            this.swalAlert('success');
            this.clearFormInputs()
            this.listarRotaRun(this.lista.id)
            this.loadingMethods();
            this.corridasLeiturasArray = []
            this.configService.loaddinStarter('stop');
            this.loading = false;
          } else {
            this.configService.loaddinStarter('stop');
          }
        }
      ), error => {
        this.swalAlert('error');
        console.log(error)
      }

  }

  private swalAlert(type: any) {
    return Swal.fire({
      position: 'center',
      type: type,
      title: type == 'success' ? this.corridasLeiturasArray.length + ' Leitura(s) processada(s) com sucesso!' : 'Erro ao processar leitura(s), porfavor contacte o admnistrador!',
      showConfirmButton: false,
      timer: 2000
    })
  }

  _initModalCriarRoteiro(rota_header) {
    this.roteiro = rota_header
  }

  private ini() {
    this.rota_header = {
      id: null,
      descricao: null,
      provincia_id: null,
      municipio_id: null,
      leitor_id: null,
      user_nome: null,
      estado: null
    }
  }

  searchRtr(e: any) {
    if (this.search != "") {

      this.rotaRunsCopy = this.rotaRuns.filter((res) =>
        String(res.cliente).toLocaleLowerCase().includes(this.search.toLocaleLowerCase())
        || String(res.cil).toLocaleLowerCase().includes(this.search.toLocaleLowerCase())
        || String(res.contrato_id).toLocaleLowerCase().includes(this.search.toLocaleLowerCase())
        || String(res.moradia_numero).toLocaleLowerCase().includes(this.search.toLocaleLowerCase())
      );
    }
  }

  searchMarker(e: any) {
    if (this.search_marker != "") {

      this.markersCopy = this.markers.filter((res) =>
        String(res.cliente).toLocaleLowerCase().includes(this.search_marker.toLocaleLowerCase())
        || String(res.contrato_id).toLocaleLowerCase().includes(this.search_marker.toLocaleLowerCase())
      );
    }
  }

  _closeModalAction(closeModal) {
    var action = document.getElementById(closeModal)
    action.click();
  }

  key: string = 'id';
  reverse: boolean = false;
  sort(key: any) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  exportAsXLSXmodeloExport(): void {
    var CurrentDate = new Date();

    var keys = [
      { key: 'cil', width: 35, style: { font: { name: 'Calibri' } } },
      { key: 'contrato_id', width: 25 },
      { key: 'morada_format', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'leitura', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'data_leitura', width: 20, style: { font: { name: 'Calibri' } } },
    ];

    var Cols = ["cil", "contrato_id", "morada", "leitura", "data_leitura"]
    var title = '';
    var nameFile = "-" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    this.excelService.excels(this.roteirosToExport, nameFile, this.DadosEmpresa.logotipo, keys, Cols, title, 5, 5, 30, 3)
  }

  public formData = new FormData();
  private doc: any;
  documentChange(files) {

    this.doc = files.item(0)
    console.log(this.doc)

    for (let i = 0; i < files.length; i++) {
      this.formData.append("file", files[i], files[i]['name']);
    }
  }

  uploadFile() {
    this.loading = true;
    console.log(this.doc)
    this._rotaService
      .uploadDocument(this.doc)
      .subscribe((res: any) => {
        this.loading = false;
      })
  }

}


//Google Maps
interface marker {
  lat: number;
  lng: number;
  label?: string;
  cliente: string;
  morada_format: string;
  contrato_id: string;
  draggable: boolean;
  iconUrl: any;
}
