import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange, NgModule } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpService } from 'src/app/providers/http/http.service';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { first } from 'rxjs/operators';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-create-or-edit-quarteirao',
  templateUrl: './create-or-edit-quarteirao.component.html',
  styleUrls: ['./create-or-edit-quarteirao.component.css']
})
export class CreateOrEditQuarteiraoComponent implements OnInit {

  public currentUser: any;
  @Input() title: string = "Registar Quarteirão";

  @Input() distrito_view: boolean = false;

  @Input() quarteirao = {
    id: null,
    nome: null,
    abreviatura: null,
    bairro_id: null,
    bairro: null,
    has_distrito: false,
    municipio_id: null,
    distrito_id: null,
    provincia_id: null,
    is_active: null,
    user_id: null
  };


  /*   @Input() municipio = {
      id: null,
      nome: null
    }; */

  @Input() quarteiraos: any = [];

  private provincias: any = [];
  @Input() municipios: any = [];
  @Input() distritos: any[];
  @Input() bairros: any[];

  private quarteiraosArray = [];
  private quarteiraoRow = {
    id: null,
    nome: null,
    abreviatura: null,
    bairro_id: null,
    bairro: null,
    has_distrito: null,
    distrito_id: null,
    distrito: null,
    municipio_id: null,
    municipio: null,
    provincia_id: null,
    provincia: null,
    user_id: null,
    user: null

  };
  private quarteiraoRowValid: boolean = false;
  @Input() addRows: boolean = true;

  @Input() showCreateQuarteirao: boolean = true;

  @Input() quarteiraoModal: boolean = false;


  //@Input() simpleFormQuarteirao: FormGroup;

  submitted = false;
  private loading: boolean = false;

  //@Output() private loadListQuarteirao = new EventEmitter<any>();

  constructor(private auth: AuthService, private http: HttpService, private configService: ConfigService, private formBuilder: FormBuilder) {
    //this.createForm();
    this.currentUser = this.auth.currentUserValue;
  }


  ngOnInit() {

    //console.log(this.quarteirao);
    //console.log(this.municipios);

    if (this.provincias.length <= 0) {
      this.quarteirao.provincia_id = 29;
      this.selectBoxProvincias();
    }

    if (this.addRows == false) {
      this.selectBoxProvincias();
    }

    this.onReset();
    this.quarteirao.user_id = this.currentUser.user.id;

    //console.log(this.quarteirao);
  }

  save() {

    if (!this.addRows) {
      console.log(this.quarteirao);
      this.quarteiraosArray.unshift({ ...this.quarteirao });

      console.log(this.quarteiraosArray);
    }

    for (let i = 0; i < this.quarteiraosArray.length; ++i) {

      this.quarteiraosArray[i].user_id=this.currentUser.user.id;
      if (this.quarteiraosArray[i].id == null) {
        this.http.__call('quarteirao/create', this.quarteiraosArray[i]).subscribe(
          response => {

            if (Object(response).code == 200) {
              this.configService.showAlert(Object(response).message, "alert-success", true);
              this.quarteiraosArray = [];
              this.onReset();

            } else {
              this.configService.showAlert(Object(response).message, "alert-danger", true);
            }

          }
        );

      }
      else {

        this.http.__call('quarteirao/update/' + this.quarteiraosArray[i].id, this.quarteiraosArray[i]).subscribe(
          response => {

            if (Object(response).code == 200) {
              this.configService.showAlert(Object(response).message, "alert-success", true);
              this.quarteiraosArray = [];
            } else {
              this.configService.showAlert(Object(response).message, "alert-danger", true);
            }

          }
        );
      }
    }

  }


  createOrEdit(uri: any, formulario: any, isCreate: boolean) {

    // TODO: usado para fazer a requisição com a api de criação de object
    this.http.__call(uri, formulario).pipe(first()).subscribe(
      response => {
        this.submitted = false;
        this.loading = false;

        if (Object(response).code == 200) {
          this.openEndQuarteiraoModal(true);
          this.reloadQuarteiraos();
        }

        if (isCreate) {
          formulario.reset();
        }

        //this.quarteiraos = Object(response).data;
      },
      error => {
        this.submitted = false;
        this.loading = false;
      });
  }

  setDataQuarteirao({ item }) {

    if (this.provincias.length <= 0) {
      this.selectBoxProvincias();
    }
    //console.log(this.quarteirao);
    //console.log(item);

    if (item.id > 0) {
      this.title = "Editar Quarteirão";

      this.quarteirao.id = item.id;
      this.quarteirao.nome = item.nome;
      this.quarteirao.abreviatura = item.abreviatura;
      this.quarteirao.bairro_id = item.bairro_id;
      this.quarteirao.bairro = item.bairro;
      this.quarteirao.has_distrito = item.has_distrito;
      this.quarteirao.distrito_id = item.distrito_id;
      this.quarteirao.municipio_id = item.municipio_id;
      this.quarteirao.provincia_id = item.provincia_id;
      this.quarteirao.is_active = item.is_active;
      this.quarteirao.user_id = item.user_id;

    } else {
      this.title = "Registar Quarteirao";
      this.onReset();
    }

    //console.log(this.quarteirao);
  }

  onReset() {
    this.submitted = false;

    this.quarteirao.id = null;
    this.quarteirao.nome = null;
    this.quarteirao.abreviatura = null;
    this.quarteirao.bairro_id = null;
    this.quarteirao.bairro = null;
    this.quarteirao.has_distrito = false;
    this.quarteirao.distrito_id = null;
    this.quarteirao.municipio_id = null;
    this.quarteirao.is_active = null;
    this.quarteirao.user_id = null;
  }

  private async appendRowQuarteirao() {

    const loadedStateCallback = () => {
      //console.log(this.quarteirao);
      this.quarteiraoRow.nome = this.quarteirao.nome;
      this.quarteiraoRow.abreviatura = this.quarteirao.abreviatura;

      this.quarteiraoRow.user_id = this.currentUser.user.id;

      console.log(this.quarteiraoRow);

      this.quarteiraoRowValid = this.quarteiraoRowValidation(this.quarteiraoRow);
      if (this.quarteiraoRowValid)
        this.quarteiraosArray.unshift({ ...this.quarteiraoRow });
    }

    await this.getBairroById(this.quarteirao.bairro_id, loadedStateCallback);

    //console.log(this.quarteiraosArray);

  }

  private deleteRowQuarteiraoAppended(row): void {

    for (let i = 0; i < this.quarteiraosArray.length; ++i) {

      if ((this.quarteiraosArray[i].bairro_id == row.bairro_id) && (this.quarteiraosArray[i].nome == row.nome)) {
        this.quarteiraosArray.splice(i, 1);
        //console.log(this.quarteiraosArray[i]);
      }

    }


  }


  private quarteiraoRowValidation(row): boolean {

    if (this.quarteiraosArray.length == 0)
      return true;

    for (let i = 0; i < this.quarteiraosArray.length; ++i) {
      //console.log(this.quarteiraosArray[i]);
      if (this.quarteiraosArray[i].bairro_id == row.bairro_id && this.quarteiraosArray[i].nome == row.nome) {
        return false;
      }
    }

    return true;
  }


  private reloadQuarteiraos() {

    /*     this.http.call_get('quarteirao/listagem/', null).subscribe(
          response => {

            this.quarteiraos = Object(response).data;
          }
        ); */
  }

  private selectBoxProvincias() {
    this.http.call_get('provincia/selectBox', null).subscribe(
      response => {
        //console.log(Object(response).data);

        this.provincias = Object(response).data;
      }
    );
  }

  private getBairroById(id: any, callback: Function) {


    this.http.call_get('bairro/getBairroById/' + this.quarteirao.bairro_id, null).subscribe(
      response => {

        //console.log(Object(response));

        this.quarteiraoRow.bairro_id = Object(response).data.id;
        this.quarteiraoRow.bairro = Object(response).data.nome;

        this.quarteiraoRow.distrito_id = Object(response).data.distrito_id;
        this.quarteiraoRow.distrito = Object(response).data.distrito;
        this.quarteiraoRow.has_distrito = Object(response).data.has_distrito;
        this.quarteiraoRow.municipio_id = Object(response).data.municipio_id;
        this.quarteiraoRow.municipio = Object(response).data.municipio;
        this.quarteiraoRow.provincia_id = Object(response).data.provincia_id;
        this.quarteiraoRow.provincia = Object(response).data.provincia;

        if (!this.quarteiraoRow.has_distrito) this.quarteiraoRow.distrito_id = null;

        callback();

        //console.log(this.quarteiraoRow);
      }
    );

  }


  private selectBoxMunicipiosByProvincia() {

    //console.log(this.quarteirao);

    this.http.call_get('municipio/getMunicipiosByProvincia/' + this.quarteirao.provincia_id, null).subscribe(
      response => {
        //console.log(Object(response).data);

        this.municipios = Object(response).data;
      }
    );
  }

  private selectBoxDistritosByMunicipio(id) {

  
    this.http.call_get('distrito/getDistritosByMunicipio/' + id, null).subscribe(
      response => {
        this.distritos = Object(response).data;
        if (this.distritos.length) this.quarteirao.has_distrito = true;
      }
    );
  }

  private selectBoxBairrosByDistrito(id) {
    this.bairros=[]
    this.http.call_get('bairro/selectBoxByDistrito/' + id, null).subscribe(
      response => {
        this.bairros = Object(response);
      }
    );

  }

  private selectBoxBairrosByMunicipio(id) {
    this.bairros=[]
    this.http.call_get('bairro/selectBoxByMunicipio/' + id, null).subscribe(
      response => {

        this.bairros = Object(response);
      }
    );
  }

  private openEndQuarteiraoModal(flag: boolean): void {
    this.quarteiraoModal = flag;
  }

  public loadListEstabelecimentos(quarteiraos) {
    this.quarteiraos = quarteiraos;
  }

}
