import { Inject, Injectable } from '@angular/core';
import * as moment from 'moment';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImpressaoPdfService {

  constructor(
    private http: HttpClient) {}

  
  public relatorioStock(p = 'print', file: any,imgData) {


    var today = moment().format("DD-MM-YYYY H:mm:ss");
    var doc = new jsPDF('l', '', 'a4')
    doc.setProperties({
      title: 'LISTA_DE_STOCK_EXISTENTE_'+today,
      subject: 'Report',
      author: 'Unig',
      keywords: '',
      creator: 'bewater'
    });
    doc.addImage(imgData, 'JPEG',10, 10, 28, 18)
    const totalPagesExp = "{total_pages_count_string}";

    doc.setFontType('bold')
    doc.setFontSize(15)
    // doc.text(145, 60, 'Relatório', 'center')
    doc.setFontSize(15)
    doc.text(140, 25, 'LISTA DE STOCK EXISTENTE', 'center')
    // doc.setFontSize(9)
    // doc.text('Data início:', 375, 20)
    // doc.text('Data fim:', 375, 25)
// /*  */
//     doc.setFontType('normal')
//     doc.text('' + (filtros.data1), 393, 20);
//     doc.text('' + (filtros.data2),389 , 25);


    doc.autoTable({
      html: file,
      didParseCell: function (data) {
        var rows = data.table.body;
        if (data.row.index === 0) {
          data.cell.styles.fontStyle = 'bold';
          data.cell.styles.textColor = "white";
          data.cell.styles.fillColor = [32, 95, 190];
        }
      },
      didDrawPage: data => {
        let footerStr = "Página " + doc.internal.getNumberOfPages();
        if (typeof doc.putTotalPages === 'function') {
          footerStr = footerStr + " de " + totalPagesExp;
        }
        doc.setFontType('bold')
        doc.setFontSize(10);
        doc.text(today,data.settings.margin.left, doc.internal.pageSize.height - 2)
        doc.setFontSize(10);
        doc.text(footerStr, 260, doc.internal.pageSize.height - 2, 'left');
      },
      styles: { textColor: [0, 0, 0] },
    tableWidth: 10,
      columnStyles: {
        0: {cellWidth: 15,halign: 'center'},
        1: {cellWidth: 50,halign: 'center'},
        2: {cellWidth: 50,halign: 'center'},
        3: {cellWidth: 50,halign: 'center'},
        4: {cellWidth: 50,halign: 'center'},
        5: {cellWidth: 50,halign: 'center'},
        6: {cellWidth: 50,halign: 'center'},
        7: {cellWidth: 50,halign: 'center'},  
      },
      rowsStyles: {},
      startY: 48,
      theme: 'grid',

    })

    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }

    if (p === 'save') {
      doc.save();
    } else {
      doc.autoPrint();
      doc.output("dataurlnewwindow");
    }


  }

 

}
