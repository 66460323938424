import { Component, OnInit } from '@angular/core';
import { TipoClienteService } from './tipo-de-cliente.service';

@Component({
    selector: 'app-tipo-de-cliente',
    templateUrl: './tipo-de-cliente.component.html',
})

export class TipoClienteComponent implements OnInit {

    tipo_cliente: any = {
        id: null,
        tipoClienteDesc: null,
        mensagem : null
    }

    tipos_clientes: any = []

    constructor(
        private _tipoClienteService: TipoClienteService
    ){
        this.loadingMethods()
    }

    ngOnInit() {

    }

    _initModal(tipo_cliente){
        this.tipo_cliente = tipo_cliente
    }

    loadingMethods(){
        this._tipoClienteService.getTipoCliientes()
        .subscribe((response) => {
            this.tipos_clientes = response.data.data
        })
    }
}
