import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

@Component({​
    selector: 'app-conf-motivo-denuncia',
    templateUrl: './conf-motivo-denuncia.component.html',
    styleUrls: ['./conf-motivo-denuncia.component.css']
  }​)
  export class ConfMotivoDenunciaComponent implements OnInit, OnDestroy {​​

  private motivodenuncia = {
    id: null,
    nome: null,
    slug: null
  }


  private items: any = [];

  constructor(private http: HttpService, private configService: ConfigService) { }

  ngOnInit() {
    this.http.filters.search = null
    this.getPageFilterData(1);
  }

  ngOnDestroy(){
    this.http.filters.orderBy = null
  }

  private listamotivodenuncia() {

    this.configService.loaddinStarter('start');

    this.http.__call('motivo-recisao/listagem', this.http.filters).subscribe(

      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.configService.loaddinStarter('stop');

      }
    );
  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page =Number.isInteger(page) ? page : 1 ;
    this.listamotivodenuncia();
  }


  private register(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (this.motivodenuncia.nome == "") {
      this.configService.showAlert("O campo Descrição é obrigatório", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    } else {
    this.http.__call('motivo-recisao/create', this.motivodenuncia).subscribe(
      res => {
        if (Object(res).code == 201) {
          this.configService.showAlert(Object(res).message, 'alert-danger', true);
          this.configService.loaddinStarter('stop');
        } else {
          this.configService.showAlert(Object(res).message, 'alert-success', true);
          this.clearFormInputs(e);
          this.listamotivodenuncia()
          this.configService.loaddinStarter('stop');
        }
      }
    )
  };
}


  private clearFormInputs(e) {
    e.target.elements[0].value = null;
    e.target.elements[1].value = null;
    e.target.elements[2].value = null;
  }

  private refresh(id, nome, slug) {
    this.motivodenuncia.id = id;
    this.motivodenuncia.nome = nome;
    this.motivodenuncia.slug = slug;
  }

  private editar(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (this.motivodenuncia.nome == "") {
      this.configService.showAlert("O campo Descrição é obrigatório", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    } else {
      this.http.__call('motivo-recisao/update/' + this.motivodenuncia.id, this.motivodenuncia).subscribe(
        res => {
          if (Object(res).code == 201) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            //this.configService.clearFormInputs(e);
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.listamotivodenuncia();

          }
        }
      );
    }
    this.configService.loaddinStarter('stop');
  }


  private ini() {
    this.motivodenuncia = {
      id: null,
      nome: null,
      slug: null
    }
  }





}
