import { Component, OnInit, ViewChild } from "@angular/core";
import { HttpService } from "src/app/providers/http/http.service";
import { ConfigService } from "src/app/providers/config/config.service";
import { ExcelService } from "src/app/services/excel.service";
import * as moment from "moment";
import { Router, ActivatedRoute } from "@angular/router";
import { ExcelAutoService } from "src/app/services/excel/excel-auto.service";
import { ImpressaoPdfService } from "./service/impressao-pdf.service";

@Component({
  selector: 'app-stock-existente',
  templateUrl: './stock-existente.component.html',
  styleUrls: ['./stock-existente.component.css']
})
export class StockExistenteComponent implements OnInit {


  public movimentos: any = [];
  public serie: any;
  loading: boolean = false;
  artigo_id: any = null;
  data: any = null;

  public filters = {
    search: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    pagination: {
      perPage: 5,// Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima,
      lastPage: null,
      total: 0 // defini o total de registo da BD
    },
    filter: null, // subelemente do filter
    is_allexel: false
  }
  localUrl: any
  altura: any
  largura: any
  public armazens = [];

  view_material = false;

  private produtoCategorias: any = [];
  public artigos = [];
  public armazem_id: any = null;
  public categoria_id: any = null;
  public descricao_material: any = null;
  public artigoId: any = null;
  loadings = {
    artigo: false,
    armazem: true,
  };

  constructor(
    public http: HttpService,
    public configService: ConfigService,
    public excelService: ExcelService,
    public route: ActivatedRoute,
    public _route: Router,
    private excelsService: ExcelAutoService,
    private relatorioStock: ImpressaoPdfService
  ) {
    /*  this.route.paramMap.subscribe((params) => {
       this.artigo_id = +params.get("artigo_id");

     }); */
  }

  ngOnInit() {
    this.filters.search = null
    this.getPageFilterData(1);
    this.getArmazens();
    this.getCategoriaByStock()
    this.searchMaterial()
    this.getProdutos();
    this.empresaUser()
  }

  exportAsXLSX(): void {
    var CurrentDate = new Date();
    var nameFile =
      "movimento_stock-" +
      moment(CurrentDate).format("DD") +
      "-" +
      moment(CurrentDate).format("MM") +
      "-" +
      moment(CurrentDate).format("YYYY") +
      " " +
      moment(CurrentDate).format("H") +
      ":" +
      moment(CurrentDate).format("m");
    this.excelService.exportAsExcelFile(
      document.getElementsByClassName("exportAsXLSXStock")[0],
      nameFile
    );
  }
  exportAsPDF(): void {
    var file = document.getElementsByClassName("exportAsXLSXStock")[0]
    this.relatorioStock.relatorioStock('save', file, this.localUrl);
  }

  imprimirPDF(): void {
    var file = document.getElementsByClassName("exportAsXLSXStock")[0]
    this.relatorioStock.relatorioStock('print', file, this.localUrl);
  }
  private empresaUser() {
    this.configService.loaddinStarter('start');

    this.http.call_get('empresa/empresa-user', null).subscribe(
      response => {
        this.localUrl = Object(response).data.logotipo
        this.altura = Object(response).data.width
        this.largura = Object(response).data.height
        this.configService.loaddinStarter('stop');
      }
    );
  }

  setTrue() {
    this.filters.pagination.page = Number.isInteger(this.filters.pagination.page) ? this.filters.pagination.page : 1;
    this.filters.is_allexel = true
    this.getExistenciaStock()
  }
  public getExistenciaStock() {
    this.loading = true;
    this.http
      .__call("stock_movimento/armazem/existencia", { filter: this.filters, armazem_id: this.armazem_id, artigo_id: this.artigo_id })
      .subscribe((response) => {
        this.filters.pagination.lastPage = Object(response).data.lastPage;
        this.filters.pagination.page = Object(response).data.page;
        this.filters.pagination.total = Object(response).data.total;
        this.filters.pagination.perPage = Object(response).data.perPage;
        this.movimentos = Object(response).data.data;
        console.log(this.filters)
        this.loading = false;
        if (this.filters.is_allexel == true) {
          this.exportAsXLSXs(this.movimentos)
        }
        this.filters.is_allexel = false
      });
  }

  exportAsXLSXs(data: any): void {
    var CurrentDate = new Date();
    for (let i = 0; i < data.length; i++) {
      if (data[i].is_active == 1) {
        data[i].is_active = "Aberto"
      } else {
        data[i].is_active = "Fechado"
      }
    }
    var keys = [
      { key: 'armazem', width: 30 },
      { key: 'categoria', width: 30 },
      { key: 'material', width: 70, style: { font: { name: 'Calibri' } } },
      { key: 'codigo_produto', width: 30, style: { font: { name: 'Calibri' } } },
      { key: 'quantidade', width: 30 },
    ];

    var Cols = [
      'Armazem',
      'Categoria',
      'Material',
      'Código',
      'Stock Existente',
    ]

    var title = 'LISTA DE STOCK EXISTENTE'
    var nameFile = "LISTA_DE_STOCK_EXISTENTE_" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    this.excelsService.excels(data, nameFile, this.localUrl, keys, Cols, title, 5, 5, 50, 3)
  }

  getPageFilterData(page: number) {
    if (this.filters.pagination.perPage == null) {
      return;
    }
    this.filters.pagination.page = Number.isInteger(page) ? page : 1;
    this.getExistenciaStock();
  }

  public setDataSerie(serie: any) {
    this.serie = serie;
  }

  goToPageLink(artigo: any) {
    //this._route.navigate(["/stock/movimentacao/view/artigo", artigo.artigo_id]);
  }

  public getArmazens() {
    this.loadings.armazem = true;
    this.http.__call("armazem/selectBox", null).subscribe((response) => {
      this.armazens = Object(response).data;
      this.loadings.armazem = false;
    });
  }

  private carregando = {
    material: 'Seleccione o material',
    categoria: 'Seleccione a categoria'
  }

  private getCategoriaByStock() {

    this.categoria_id = null,
      this.produtoCategorias = []
    this.artigos = [];

    this.carregando.categoria = 'Carregando...';
    this.http
      .__call("categoria/by/stock", {
        armazem_id: this.armazem_id
      }).subscribe((res: any) => {
        this.produtoCategorias = Object(res).data;
        this.carregando.categoria = 'Seleccione a categoria';
      });
  }

  _searchMaterial() {
    this.view_material = false
    this.searchMaterial()
    this.view_material = true;
  }

  private searchMaterial() {
    this.artigo_id = null;

    this.artigos = [];

    if (this.categoria_id == null) {
      this.descricao_material = null;
        //this.view_material = false
    }

    this.http.__call('search-material/by/stock', {
      start: 1,
      end: 500,
      armazem_id: this.armazem_id,
      categoria_id: this.categoria_id,
      search: this.descricao_material
    }).subscribe(
      response => {
        this.artigos = Object(response).data.data;
      }
    );
  }

  private setMaterial(item) {
    this.artigo_id = item.id;
    this.descricao_material = item.codigo_produto + ' - ' + item.descricao;
    this.view_material = false;
  }

  getProdutos() {
    this.loadings.artigo = true;
    this.http.__call("artigos/prod/selectBox", { pagination: { page: 1, perPage: 10 } })
      .subscribe((res: any) => {
        this.artigos = Object(res).data;
        this.loadings.artigo = false;
      });
  }





}
