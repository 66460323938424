import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-produto-fornecedor',
  templateUrl: './produto-fornecedor.component.html',
  styleUrls: ['./produto-fornecedor.component.css']
})
export class ProdutoFornecedorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
