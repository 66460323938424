import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { ExcelService } from 'src/app/services/excel.service';
import Swal from 'sweetalert2';
import { ExcelAutoService } from 'src/app/services/excel/excel-auto.service';
import * as moment from 'moment';
import { toDate, format } from 'date-fns-tz'
import { saveAs } from 'file-saver';
import { HttpEvent, HttpEventType } from '@angular/common/http';
const axios = require('axios')
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-lista-geral-intervencoes',
  templateUrl: './lista-geral-intervencoes.component.html',
  styleUrls: ['./lista-geral-intervencoes.component.css']
})
export class ListaGeralIntervencoesComponent implements OnInit {


  public tipos: any;
  public estados: any;
  public prioridades: any;
  private loading: boolean = false;
  public filters = {
    search: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    estado_id: null, // Ordenação
    prioridade_id: null, // Ordenação
    classificacao_id: null, // Ordenação
    agendamento: null, // Ordenação
    pagination: {
      perPage: 25,// Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima, 
      lastPage: null,
      total: null // defini o total de registo da BD
    },
    filter: null, // subelemente do filter
    startDate: null,
    endDate: null,
    is_allexel:false
  }

  private coordenada = {
    latitude: null,
    longitude: null,
    equipamentoDescricao: null
  }

  private dash = {
    abertoCount: 0,
    executadoCount: 0,
    agendadoCount: 0,
    noAgendadoCount: 0
  }

  progress: any = 0;
  progress_1: string = null;

  constructor(private auth: AuthService,
             private http: HttpService, 
             private configService: ConfigService,
             private excelService: ExcelService,
             private excelsService: ExcelAutoService,
             ) { }
 
  private items: any = [];
  
  private resultados: any = [];
  private local_consumo: any = [];
  private classificacoes: any = [];
  private res: any = [];
  private localUrl: any;

  ngOnInit() {
    this.getPageFilterData(1);
    this.getEstados();
    this.getClassificacao();
    this.getPrioridades();
    this.empresaUser()
  }
  timeOutput:any=null
  getTimeZone(date) {
    if(date==null)return
    const parisDate = toDate(date)
    const pattern = "dd/MM/yyyy"
    this.timeOutput = format(parisDate, pattern, { timeZone: 'UTC/GMT+1' })
    return this.timeOutput
  }
private empresaUser() {
    this.configService.loaddinStarter('start');

    this.http.call_get('empresa/empresa-user', null).subscribe(
      response => {
        this.localUrl = Object(response).data.logotipo
        this.configService.loaddinStarter('stop');
      }
    );
  }

  setTrue(){
    this.filters.pagination.page=this.filters.pagination.page
    this.filters.is_allexel=true
    //console.log(this.filters.is_allexel)
    this.listar()
  }

  private listar() {

    //this.configService.loaddinStarter('start');

    this.loading = true
    this.http.__call('manutencao-preventiva/intervencoes/lista-geral', this.filters).subscribe(
      response => {
        this.filters.pagination.lastPage = Object(response).data.lastPage;
        this.filters.pagination.page = Object(response).data.page;
        this.filters.pagination.total = Object(response).data.total;
        this.filters.pagination.perPage = Object(response).data.perPage;
        //console.log(this.filters.is_allexel)
        this.items = Object(response).data.data;
        if(this.filters.is_allexel)this.exportAsXLSX()
        this.loading = false
        this.filters.is_allexel=false
      }
    );
    

  }

  exportAsXLSX(): void {
    var CurrentDate = new Date();
    for (let i = 0; i < this.items.length; i++) {
      this.items[i].agendamento=this.items[i].agendamento != null?
                      this.items[i].agendamento = "SIM": 
                      this.items[i].agendamento = "NÃO"
       this.items[i].fim_execucao=this.items[i].estadoFlag != 'FECHADA' ?
                     '-----' : this.getTimeZone(this.items[i].fim_execucao)
    }
    var keys = [
      { key: 'osCodigo', width: 50 },
      { key: 'osTitulo', width: 50 },
      { key: 'prioridadeDescricao', width: 30, style: { font: { name: 'Calibri' } } },
      { key: 'tipoDescricao', width: 30 },
      { key: 'estadoDescricao', width: 30 },
      { key: 'clienteNome', width: 30, style: { font: { name: 'Calibri' } } },
      { key: 'equipamentoCodigo', width: 30, style: { font: { name: 'Calibri' } } },
      { key: 'data_previsao', width: 50 },
      { key: 'fim_execucao', width: 50},
      { key: 'agendamento', width: 30 },

    ];

    var Cols = [
      'Código',
      'Título',	
      'Prioridade',
      'Tipo',
      'Estado',	
      'Cliente',	
      'Equipamento',	
      'Data prevista',
      'Data Execução',	
      'Agendamento',
    ]

    var title = 'LISTA INTERVENÇÕES'
    var nameFile = "LISTA_INTERVENCOES_" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    this.excelsService.excels(this.items, nameFile, this.localUrl, keys, Cols, title, 5, 10, 40, 3)
  }

  

  private getEstados() {
    this.http.call_get('ordemservico/estado/selectBox', null).subscribe(
      response => {
        this.estados = Object(response).data;
      }
    );
  }

  private getPrioridades() {
    this.http.call_get('ordemservico/prioridade/selectBox', null).subscribe(
      response => {
        this.prioridades = Object(response).data;
      }
    );
  }

  private dashboard() {

    this.http.call_get('ordemservico/ordem-servico/dashBoard', null).subscribe(
      response => { 
        this.dash.abertoCount = Object(response).data.aberto;
        this.dash.executadoCount = Object(response).data.executado;
        this.dash.agendadoCount = Object(response).data.agendado;
        this.dash.noAgendadoCount = Object(response).data.noAgendado;

      }
    );
  }

 
  getPageFilterData(page: number) {

    if (this.filters.pagination.perPage == null) {
      return;
    }

    if (this.filters.startDate != null && this.filters.endDate != null) {
      if (this.filters.startDate > this.filters.endDate) {
        this.configService.showAlert("Data Início não pode ser superior a Data Fim", 'alert-danger', true);
        return;
      }
    }

    if (this.filters.startDate != null && this.filters.endDate == null) {
      this.configService.showAlert("Digite a Data Fim", 'alert-danger', true);
      return;
    }

    if (this.filters.startDate == null && this.filters.endDate != null) {
      this.configService.showAlert("Digite a Data Início", 'alert-danger', true);
      return;
    }

    if (this.filters.pagination.perPage == null) {
      return;
    }

    this.filters.pagination.page = page;
    this.listar();
  }

  private clearFilters() {
    this.filters = {
      search: null,
      orderBy: null,
      estado_id: null, // Ordenação
      prioridade_id: null, // Ordenação
      classificacao_id: null, // Ordenação
      agendamento: null, // OT com agendamento "true" ou não "false"
      pagination: {
        perPage: 25,
        page: 1,
        lastPage: null,
        total: null
      },
      filter: null,
      startDate: null,
      endDate: null,
      is_allexel:false
    }

    this.getPageFilterData(1);
  }

  
  private setData(item) {
    this.tipos = item
  }

  private getClassificacao() {
    this.http.call_get('ordemservico/classificacao/selectBox', null).subscribe(
      response => {
        this.classificacoes = Object(response).data;
      }
    );
  }

  private getLatitudeLongitude(id) {

    this.coordenada.latitude = null;
    this.coordenada.longitude = null;

   
      this.configService.loaddinStarter('start');
      this.http.call_get('ordemservico/equipamento/getCoordenadas/' + id, null).subscribe(
        response => {
          this.coordenada.latitude = Object(response).data.latitude;
          this.coordenada.longitude = Object(response).data.longitude;
          this.coordenada.equipamentoDescricao = Object(response).data.descricao;
          this.configService.loaddinStarter('stop');
        }
      );
    

  }

}
