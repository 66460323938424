import { Component, Inject, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';

import { download, Download , ignoreNotFound} from 'ngx-operators';
 import { Observable, pipe } from 'rxjs';
 import { environment } from '../../../environments/environment';
import { SAVER, Saver } from '../../services/saver.provider'
import { first, finalize, map, tap, last } from 'rxjs/operators';

 @Component({
  selector: 'app-saft',
  templateUrl: './saft.component.html',
  styleUrls: ['./saft.component.css']
})
export class SaftComponent implements OnInit {

  private data = {
    de: null,
    para: null,
    ano: null
  }

  private anos: any = [];

  fileUrl;
  public saft = {
    id:null
  }

  isLoading =true;

  constructor(private http: HttpService, private configService: ConfigService, private sanitizer: DomSanitizer, private _http: HttpClient, @Inject(SAVER) private save: Saver) { }

  download$: Observable<Download>

  download(anexo) {
    this.saft = anexo
    var _count = true;
    const urlServer = environment.app_url+'saftAo/getstream/' + anexo.id;
    this.download$ = this._http.get(urlServer, {
                           reportProgress: true,
                           observe: 'events',
                           responseType: 'blob'
                         }).pipe(ignoreNotFound(),download(blob =>{
                          console.log('_count::::: ',_count)
                          console.log('blob::::: ',blob)
                            if(_count)this.save(blob, anexo.nome_saft);
                          }),tap(message => console.log(message)), finalize(()=>{_count = false}))
  }



  ngOnInit() {
    this.gerarAno();

    //const data = 'some text';
    //const blob = new Blob([data], { type: 'application/octet-stream' });

    //this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
    this.http.filters.search = null
    this.getPageFilterData(1);
    console.log(this.mesPorExtenso('2019', '02'))


  }
  private mesPorExtenso(ano, mes) {
    var CurrentDate = new Date(ano + '-' + mes + '-01');
    return moment(CurrentDate).locale('pt-br').format('MMMM')
  }
  private gerarAno() {
    var fecha = new Date();
    var anyo = fecha.getFullYear();

    let j = 0;
    for (let i = 2019; i <= anyo; i++) {
      this.anos[j] = i;
      j++;
    }
  }
  private message: number;

  private btnSaft() {

    if (this.data.ano == null || this.data.ano == "null" || this.data.para == null || this.data.para == "null" || this.data.de == null || this.data.de == "null") {
      this.configService.showAlert("Todos os campos são de caracter obrigatorio.", 'alert-danger', true);
      return;
    }
    this.configService.loaddinStarter('start');
    this.http.__call('saftAo', this.data).subscribe(
      response => {
        if (Object(response).code === 200) {
          this.configService.showAlert(Object(response).message, 'alert-success', true);
          this.getPageFilterData(1);
        } else {
          this.configService.showAlert(Object(response).message, 'alert-danger', true);
        }
        this.configService.loaddinStarter('stop');
      },error=>{
        this.configService.loaddinStarter('stop');
      }
    );
  }


  downloadSaftXml(anexo) {
    const urlServer = 'saftAo/getstream/' + anexo.id;
    return this.http._getFileFromServerSaft(urlServer)
      .subscribe(
        (res) => {
          //this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(res));
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(res);
          link.download = anexo.nome_saft + ".xml";
          link.click();
        },
        error=>{
          this.configService.loaddinStarter('stop');
        })
  }



  private safts = []
  loading = false

  private listarSafts() {

    this.loading = true

    this.http.__call('safts', this.http.filters).subscribe(

      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;
        this.safts = Object(response).data.data;
        this.loading = false

      }
    );
  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
    this.listarSafts();
  }



}

