
import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

import { Router,ActivatedRoute } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from "src/app/providers/http/api.service";
import {  FacturacaoDetalhadaPosPagoService } from 'src/app/components/report-at/relatorios/financeira/facturacao-detalhada-pos-pago.service';
import { RelDetalhadaPosPagoService } from 'src/app/components/report-at/relatorios/financeira/rel-detalhada-pos-pago.service';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators'
import { ExcelAutoService } from 'src/app/services/excel/excel-auto.service';
import { parse } from 'querystring';
import { ImportDataService } from 'src/app/services/ExportExcel/exportAllData.service';


export class Filter {
 
  municipio_id:string="";
  distrito_id:string=""
  bairro_id: string="";
  constructor() {
  }
}
@Component({
  selector: 'app-facturacao-detalhada-produto',
  templateUrl: './facturacao-detalhada-produto.component.html',
  styleUrls: ['./facturacao-detalhada-produto.component.css']
})
export class FacturacaoDetalhadaProdutoComponent implements OnInit {

  public filter = new Filter();
  public titulo = "Facturação Detalhada ";
  loading: boolean = false;
  private loadings = {
    municipios:false,
    distritos: false,
    bairros: false
  };
  produtos:any[]=[]
  municipios:any = [];
  distritos:any = [];
  bairros:any = [];

  private meses = [
    { nome: "Janeiro", numero: "01" },
    { nome: "Fevereiro", numero: "02" },
    { nome: "Março", numero: "03" },
    { nome: "Abril", numero: "04" },
    { nome: "Maio", numero: "05" },
    { nome: "Junho", numero: "06" },
    { nome: "Julho", numero: "07" },
    { nome: "Agosto", numero: "08" },
    { nome: "Setembro", numero: "09" },
    { nome: "Outubro", numero: "10" },
    { nome: "Novembro", numero: "11" },
    { nome: "Dezembro", numero: "12" }
  ];
  private meses_ = [];

  private filtros = {
    status: null,
    data2:null,
    data1:null,
    municipio_id:null,
    distrito_id:null,
    bairro_id:null,
  }
  public filters = {
    search: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    pagination: {
      perPage: 5,// Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima,
      lastPage: null,
      total: null // defini o total de registo da BD
    },
    filter: null // subelemente do filter
    ,is_allexel:false
  }

  private localUrl: any;
  private largura: any;
  private altura: any;
  private cobrancas: any = [];
  private arrayCobranca: any = [];
  
  operadores:any = [];
  documents:any = [];
  _lojas:any = [];
  loadingLojas:string;
  submitted = false;
  simpleForm: FormGroup;
  disabledButton = false;



  constructor(private formBuilder: FormBuilder,private api: ApiService, private http: HttpService,
     private route: ActivatedRoute, private configService: ConfigService,
      private excelServices: FacturacaoDetalhadaPosPagoService,
      private relCobracaGlobal: RelDetalhadaPosPagoService,
      private getAllData: ImportDataService,
      private excelService: ExcelAutoService
  ) {

    this.createForm();
  }

  ngOnInit() {
    this.empresaUser()
    this.filtros = null;
    this.getMunicipios();
    this._getLojas() 
    this.getDocuments()
    this.getProdutos()
  }

  getAllDatas() {

    this.filters.filter = this.filter
    this.disabledButton = true
    let title = "FATURAÇÃO DETALHADA POR PRODUTO"
     this.getAllData.getData('relatorio/financeira/detalhadaProduto', title, this.simpleForm.value, this.filters, (data: any)=>this.exportAsXLSX(data))
  
   } 

  private _getLojas() {
    this.http.call_get('loja/selectBox',null).subscribe(
      response => {
        this._lojas = Object(response).data
        const id = this.simpleForm.getRawValue().loja;
        this._lojas.map(res=>{
          if(res.id==id){
            this.getoperadores(res.id)
          }else{
            return
          }
       })
      }
    );
  }

  private getoperadores(id) {
    this.loadingLojas = 'Carregando...';
    this.http.call_get('user/selectBox/'+id, null).subscribe(
      response => {
        this.operadores = Object(response).data
      }
    )
   
  }

  createForm() {
    this.simpleForm = this.formBuilder.group({
      data1: [null, Validators.required],
      data2: [null, [this.matchValidator.bind(this)]],
      status:null,
      municipio_id: [null],
      distrito_id: [null],
      bairro_id: [null],
      loja:[null],
      operador:[null],
      produto_id:[null],
      tipo_documento:[null]
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.simpleForm.controls;
  }
  matchValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const fromValue = control.value;
    if(fromValue) {

     // console.log(this.simpleForm.controls)
      const startDate = (<FormGroup>this.simpleForm.get('data1')).value;
      const endDate = (<FormGroup>this.simpleForm.get('data2')).value;
      if (startDate <= endDate) {
        //console.log('Control: ', control);
       return null;
      }
      //console.log('Control: ', control);
      return { 'invalidDate' : true };
    }

  }
  private getProdutos() {
    this.http.call_get('artigo/selectProdutos', null).subscribe(
      response => {
        this.produtos = Object(response).data
      }
    );
  }
  getDocuments(){
    this.http.__call('documento/show', null).subscribe(
      response => {
        this.documents = Object(response)
      }
    );
  }
  onSubmit() {

    this.submitted = true;
    // parar aquei se o simpleFormulário for inválido
    if (this.simpleForm.invalid) {
      return;
    }

    /* this.simpleForm.patchValue({ tipoFacturacao: this.tipoFacturacao}); */
    const uri = 'relatorio/financeira/detalhadaProduto';
    this.createOrEdit(uri, this.simpleForm);

  }
  getValuesFatura(data:any){
    this.simpleForm.patchValue({
      tipoFacturacao: data
    });
  }

  private totais = {
    totals: 0,
    valor_iva: 0,
    valor_sem_iva: 0,
    credito: 0,
    debito: 0,
    valor_unitario: 0,
    }
    private somaTotais() {
      
      this.cobrancas.forEach(element => {
           this.totais.valor_iva+= element.totalComImposto ;
           this.totais.valor_sem_iva+=element.totalSemImposto ;
           this.totais.credito+= element.credito!=1?element.credito:0 ;
           this.totais.debito+= element.debito!=1?element.debito:0;
           this.totais.valor_unitario+= element.valor;
      });
      //this.totais.totals = totals;
    }

  private empresaUser() {
    this.configService.loaddinStarter('start');

    this.http.call_get('empresa/empresa-user', null).subscribe(
      response => {
        this.localUrl = Object(response).data.logotipo
        this.altura = Object(response).data.width
        this.largura = Object(response).data.height
        this.configService.loaddinStarter('stop');
      }
    );
  }

  setTrue(){
    this.filters.pagination.page=this.filters.pagination.page
    this.filters.is_allexel=true
    this.onSubmit()
  }
  createOrEdit(uri: any, simpleFormulario: FormGroup) {
    this.filtros = this.simpleForm.value;
    this.cobrancas = []
    this.arrayCobranca = []
    this.loading = true;
    this.disabledButton = true;
    this.totais.valor_iva= 0;
    this.totais.valor_sem_iva= 0;
    this.totais.credito= 0;
    this.totais.debito= 0;
    this.totais.valor_unitario= 0;
    // TODO: usado para fazer a requisição com a api de criação de organismo
    this.http.__call(uri, simpleFormulario.value).subscribe(
      response => {
        this.cobrancas = Object(response).data;
        this.loading = false;
        if (this.cobrancas.length != 0) {
          this.disabledButton = false;

          this.somaTotais();
           
        }

        this.filters.is_allexel=false
        this.loading = false;
      }
    );
  }

  exportAsXLSX(data):void {
    let datas= data ? data : JSON.parse(JSON.stringify(this.cobrancas))
     datas=datas.map(res=>{
      res.credito=res.credito!=1?res.credito:'---'
      res.debito=res.debito!=1?res.debito:'---'
      return res

    })
    var CurrentDate = new Date();

 var keys= [
    { key: 'cliente_id', width:25, style: { font: { name: 'Calibri' } } },
    { key: 'contrato_id', width:30, style: { font: { name: 'Calibri' } } },
    { key: 'cliente', width:50, style: { font: { name: 'Calibri' } } },
    { key: 'morada', width: 50 },
    { key: 'descricao', width: 30 },
    { key: 'municipio', width:50, style: { font: { name: 'Calibri' } } },
    { key: 'bairro', width:50, style: { font: { name: 'Calibri' } } },
    { key: 'ruas', width:50, style: { font: { name: 'Calibri' } } },
    { key: 'cil', width:50, style: { font: { name: 'Calibri' } } },
    { key: 'factura_sigla', width:50, style: { font: { name: 'Calibri' } } },
    { key: 'documento', width: 25 },
    { key: 'produto', width:50, style: { font: { name: 'Calibri' } } },
    { key: 'abreviacao', width:25, style: { font: { name: 'Calibri' },numFmt: '#,##0.00' } },
    { key: 'data', width:30, style: { font: { name: 'Calibri' },numFmt: '#,##0.00' } },
    { key: 'user', width:30, style: { font: { name: 'Calibri' },numFmt: '#,##0.00' } },
    { key: 'loja', width:20, style: { font: { name: 'Calibri' } } },
    { key: 'status', width: 15 },
    { key: 'quantidade', width: 56 },
    { key: 'valor', width: 50 },
    { key: 'totalComImposto', width:30, style: { font: { name: 'Calibri' } } },
    { key: 'totalSemImposto', width: 30,style:{numFmt: '#,##0.00'} },
    { key: 'debito', width: 30,style:{numFmt: '#,##0.00'} },
    { key: 'credito', width: 30,style:{numFmt: '#,##0.00'} },
  ];
    var Cols = [
      "N.º Cliente",
      "N.º Contrato",
      "Nome Cliente",
      "Morada Cliente",
      "Tarifário",
      "Município (LC)",
      "Bairro/Zona (LC)",
      "Rua (LC)",
      "CIL",
      "Documento",
      "Tipo Documento",
      "Artigo",
      "Unidade",
      "Data",
      "Operador",
      "Loja",
      "Estado",
      "Quantidade",
      "Valor Unitário",
      "VALOR IVA",
      "VALOR SEM IVA",
      "VALOR DÉBITO",
      "VALOR CRÉDITO",
    ]
    var title='FATURAÇÃO DETALHADA POR PRODUTO'
    var nameFile = "facturação_detalhada_produto -" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
     this.excelService.excels(datas,nameFile,this.localUrl,keys,Cols,title,5,23,50,3,[19,20,21,22,23])
  }

  exportAsPDF(): void {
    var file = document.getElementsByClassName("tableCobranca")[0]
    this.relCobracaGlobal.relatorioFacturacaoPorProduto(file, 'save', this.filtros,this.localUrl);
  }

  imprimirPDF(): void {
    var file = document.getElementsByClassName("tableCobranca")[0]
    this.relCobracaGlobal.relatorioFacturacaoPorProduto(file, 'print', this.filtros,this.localUrl);
  }

  private getMunicipios() {
    this.distritos = []; 
    this.loadings.municipios = true;
    this.api.get('municipio/selectBox')
    .pipe(finalize(() => {this.loadings.municipios = false;}))
    .subscribe(response => {this.municipios = Object(response).data;},error=>{this.loadings.municipios = false;});
  }

  private getDistritos() {
    this.bairros = [];
    this.loadings.distritos = true; 
    this.filter.distrito_id = "";
    this.filter.bairro_id = "";
    this.api.get(`distrito/selectBoxDistritosByMunicipio/${this.filter.municipio_id}`)
    .pipe(finalize(() => {this.loadings.distritos = false;}))
    .subscribe(response => { this.distritos = Object(response).data;}
            ,error=>{this.loadings.distritos = false;}
    );
  }
  private getBairrosByDistritos() {
    this.filter.bairro_id = "";
    this.loadings.bairros = true;
    if(this.filter.distrito_id==""){this.filter.distrito_id = null};
    this.api.get(`bairro/getBairrosByDistrito/${this.filter.distrito_id}`)
    .pipe(finalize(() => {this.loadings.bairros = false;}))
    .subscribe(response => {this.bairros = Object(response).data;}
              ,error=>{this.loadings.bairros = false;}
    );
  }

 
}

