import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';


@Component({
  selector: 'app-produto-classe-tarifario',
  templateUrl: './produto-classe-tarifario.component.html',
  styleUrls: ['./produto-classe-tarifario.component.css']
})
export class ProdutoclasseTarifarioComponent implements OnInit, OnDestroy {

  private produtoclasseTarifario = {
    produto_id: null,
    tarifario_id: null,
    classe_tarifario_id: null,
    valor: null,
    valor_fixo: null
  }

  private items: any = [];
  private tarifarios: any = [];
  private classeTarifarios: any = [];
  private classetarifarios: any = [];
  private produtos: any = [];
  selectedItems = [];
  dropdownSettings = {};

  private produtosArray: any = [];

  constructor(private http: HttpService, private configService: ConfigService) { }

  ngOnInit() {
    this.http.filters.search = null
    this.getPageFilterData(1);

    this.getClasseTarifario();
    /*  this.getProdutos(); */

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'nome',
      selectAllText: 'Marcar Todos',
      unSelectAllText: 'Desmarcar Todos',
      itemsShowLimit: 8,
      allowSearchFilter: true
    };

  }

  ngOnDestroy(){
    this.http.filters.orderBy = null
  }

  onItemSelect(item: any) {
    /*  console.log(item); */
    this.produtosArray.push(item);
  }

  onSelectAll(items: any) {
    /*     console.log(items); */

    this.produtosArray = [];

    for (let index = 0; index < items.length; index++) {

      this.produtosArray.push({
        id: items[index].id,
        nome: items[index].nome
      });
    }
    /*     console.log(this.produtosArray); */
  }

  private carregando = {
    tarifario: 'Seleccione o tarifário',
    classeTarifario: 'Seleccione a classe tarifário'
  }

  private getTarifarios() {

    this.carregando.tarifario = 'Carregando...';

    this.http.call_get('tarifario/selectBox', null).subscribe(
      response => {
        this.tarifarios = Object(response).data;

        this.carregando.tarifario = 'Seleccione o tarifário';
      }
    );
  }

  private getClasseTarifario() {
    this.http.call_get('produto-classe-tarifario/selectBoxCT', null).subscribe(
      response => {
        this.classetarifarios = Object(response).data
      }
    );
  }

  private loadingData = {
    produto: "Carregando..."
  }

  private getProdutos() {
    this.loadingData.produto = "Carregando..."
    this.http.call_get('artigo/selectProdutos', null).subscribe(
      response => {
        this.produtos = Object(response).data
        this.loadingData.produto = "Seleccione o Produto"
      }
    );
  }

  private listaProdutoclasseTarifario() {

    this.configService.loaddinStarter('start');

    this.http.__call('produto-classe-tarifario/listagem', this.http.filters).subscribe(

      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.configService.loaddinStarter('stop');

      }
    );
  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
    this.listaProdutoclasseTarifario();
  }

  private loadingEstado = {
    estado: 'Seleccione o estado',
  }

  private classe_tarifarios: any = [];

  private getClasseTarifariobyTarifario(id: number) {
    this.loadingEstado.estado = 'Carregando...';

    this.http.call_get('/produto-classe-tarifario/' + id, null).subscribe(
      response => {
        this.classe_tarifarios = Object(response).data
        this.loadingEstado.estado = 'Seleccione o estado';
      }
    );
  }


  private register(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (this.produtoclasseTarifario.tarifario_id == null) {
      this.configService.showAlert("O campo Tarifário é obrigatório", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    } else {
      if (this.produtoclasseTarifario.classe_tarifario_id == null) {
        this.configService.showAlert("O campo Classe Tarifário é obrigatório", 'alert-danger', true);
        this.configService.loaddinStarter('stop');
      } else {

        if (this.produtoclasseTarifario.produto_id == null) {
          this.configService.showAlert("É obrigatório seleccionar Produto", 'alert-danger', true);
          this.configService.loaddinStarter('stop');
        } else {
          if (this.produtoclasseTarifario.valor_fixo <= 0) {
            this.configService.showAlert("O Valor Fixo não pode ser igual ou menor que 0", 'alert-danger', true);
            this.configService.loaddinStarter('stop');
          } else {
            this.http.__call('produto-classe-tarifario/create', this.produtoclasseTarifario).subscribe(
              res => {
                if (Object(res).code != 200) {
                  this.configService.showAlert(Object(res).message, 'alert-danger', true);
                  this.configService.loaddinStarter('stop');
                } else {
                  this.configService.showAlert(Object(res).message, 'alert-success', true);
                  this.clearFormInputs(e);
                  this.listaProdutoclasseTarifario()
                  this.configService.loaddinStarter('stop');
                }
              }
            )
          }
        }
      }
    }
  }

  /*  private register(e) {
     this.configService.loaddinStarter('start');
     e.preventDefault();
     if (this.produtoclasseTarifario.tarifario_id == null ) {
       this.configService.showAlert("O campo Tarifário é obrigatório", 'alert-danger', true);
       this.configService.loaddinStarter('stop');
     } else {
     if (this.produtoclasseTarifario.classe_tarifario_id == null ) {
       this.configService.showAlert("O campo Classe Tarifário é obrigatório", 'alert-danger', true);
       this.configService.loaddinStarter('stop');
     } else {

      if (this.produtosArray.length <= 0 ) {
       this.configService.showAlert("É obrigatório Seleccionar produto", 'alert-danger', true);
       this.configService.loaddinStarter('stop');
     } else {
     this.http.__call('produto-classe-tarifario/create',
     { tarifario_id: this.produtoclasseTarifario.tarifario_id,
       classe_tarifario_id: this.produtoclasseTarifario.classe_tarifario_id,
       produtos: this.produtosArray
     }).subscribe(
       res => {
         if (Object(res).code != 200) {
           this.configService.showAlert(Object(res).message, 'alert-danger', true);
           this.configService.loaddinStarter('stop');
         } else {
           this.configService.showAlert(Object(res).message, 'alert-success', true);
           this.clearFormInputs(e);
           this.listaProdutoclasseTarifario()
           this.configService.loaddinStarter('stop');
         }
       }
     )
       }
     }
   }
 } */

  private clearFormInputs(e) {
    e.target.elements[0].value = null;
    e.target.elements[1].value = null;
    e.target.elements[2].value = null;
  }

  private refresh(valor) {
    this.produtoclasseTarifario.valor = valor
  }


  /*   private editar(e) {
      this.configService.loaddinStarter('start');
      e.preventDefault();
      this.http.__call('produto-classe-tarifario/update/' + this.produtoclasseTarifario.id, this.produtoclasseTarifario).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            //this.configService.clearFormInputs(e);
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.listaProdutoclasseTarifario();

          }
        }
      );
       this.configService.loaddinStarter('stop');
    }*/

  private classeTarifarioBytarifario() {

    this.carregando.classeTarifario = 'Carregando...';

    this.http.call_get('selectBox/classe-tarifarioBytarifario/' + this.produtoclasseTarifario.tarifario_id, null).subscribe(
      data => {
        this.classeTarifarios = Object(data).data;
        this.carregando.classeTarifario = 'Seleccione a classe tarifário';
      }
    );
  }

  private arrayteste: any = [];

  private ValorByProduto() {

    this.carregando.classeTarifario = 'Carregando...';

    this.http.call_get('selectBox/artigo-valorByProduto/' + this.produtoclasseTarifario.produto_id, null).subscribe(
      data => {
        this.produtoclasseTarifario.valor_fixo = Object(data).data.valor;
      }
    );
  }


  private ini() {
    this.getProdutos();
    this.refresh(this.produtoclasseTarifario.valor_fixo);
    this.produtoclasseTarifario = {
      produto_id: null,
      tarifario_id: null,
      classe_tarifario_id: null,
      valor: null,
      valor_fixo: null
    }
  }







}
