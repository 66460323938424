import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/providers/config/config.service';
import { HttpService } from 'src/app/providers/http/http.service';

@Component({
  selector: 'app-tipo-objecto',
  templateUrl: './tipo-objecto.component.html',
  styleUrls: ['./tipo-objecto.component.css']
})
export class TipoObjectoComponent implements OnInit, OnDestroy {

  private tipoObjecto = {
    id: null,
    descricao: null,
    slug: null
  }


  private items: any = [];

  constructor(private http: HttpService, private configService: ConfigService) { }

  ngOnInit() {
    this.http.filters.search = null
    this.getPageFilterData(1);
  }
  ngOnDestroy(){
    this.http.filters.orderBy = null
  }


  private listaTipoObjecto() {

    this.configService.loaddinStarter('start');

    this.http.__call('tipo-objecto-tecnico/listagem', this.http.filters).subscribe(

      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.configService.loaddinStarter('stop');

      }
    );
  }
  /*   private getTiposRamal() {
      this.http.call_get('tipo-objecto-tecnico/selectBox', null).subscribe(
        response => {
          this.tipo_ramals = response;
        }
      );
    } */

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
    this.listaTipoObjecto();
  }


  private register(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (this.tipoObjecto.descricao == "" || this.tipoObjecto.slug == null) {
      this.configService.showAlert("Os campos Estado e Slug são obrigatórios", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    } else {
      this.http.__call('tipo-objecto-tecnico/create', this.tipoObjecto).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
            this.configService.loaddinStarter('stop');
          } else {
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.clearFormInputs(e);
            this.listaTipoObjecto()
            this.configService.loaddinStarter('stop');
          }
        }
      )
    };
  }


  private clearFormInputs(e) {
    e.target.elements[0].value = null;
    e.target.elements[1].value = null;
    e.target.elements[2].value = null;
  }

  private refresh(id, descricao, slug) {
    this.tipoObjecto.id = id;
    this.tipoObjecto.descricao = descricao;
    this.tipoObjecto.slug = slug;
  }

  private editar(e) {
    console.log(this.tipoObjecto)
    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (this.tipoObjecto.descricao == "" || this.tipoObjecto.slug == null) {
      this.configService.showAlert("Os campos Estado e Slug são obrigatórios", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    } else {
      this.http.__call('tipo-objecto-tecnico/update/' + this.tipoObjecto.id, this.tipoObjecto).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            //this.configService.clearFormInputs(e);
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.listaTipoObjecto();

          }
        }
      );
    }
    this.configService.loaddinStarter('stop');
  }


  private ini() {
    this.tipoObjecto = {
      id: null,
      descricao: null,
      slug: null
    }
  }





}
