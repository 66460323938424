import { Injectable } from '@angular/core';
import * as moment from 'moment';
import * as Excel from "exceljs/dist/exceljs.min.js";

@Injectable({
  providedIn: 'root'
})
export class ExcelAutoService {

  constructor() { }

  blobType: string = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

  excels(data: any, excelname, img, key, col, title, headerNum, numLopp, imgSize, x, column_align = []) {

    console.log(data)
    var footer = ["UNIG -[ BWATER]"];

    var workbook = new Excel.Workbook();
    workbook.creator = 'Web';
    workbook.lastModifiedBy = 'Web';
    workbook.created = new Date();
    workbook.modified = new Date();
    workbook.addWorksheet(title, { views: [{ state: 'frozen', ySplit: 5, xSplit: x, activeCell: 'C2', showGridLines: true }] })
    var sheet = workbook.getWorksheet(1);

    if (title != '') {
      var imageId1 = workbook.addImage({
        base64: img,
        extension: 'png',
      });

      if (imgSize > 40) {
        sheet.addImage(imageId1, {
          tl: { col: 0, row: 1.3 },
          ext: { width: 120, height: 80 }
        })
      } else {
        sheet.addImage(imageId1, {
          tl: { col: 0, row: 1.3 },
          ext: { width: 110, height: 80 }
        })
      }
    }

    sheet.getCell('B3').value = title
    //sheet.getCell('A1').value = " "
    /*  sheet.getCell('A2').value = " "
      sheet.getCell('A3').value = " "
      
      sheet.getCell('A4').value = " "
      sheet.getCell('D2').value = " "
      if(x>=5){
        sheet.mergeCells('B3','E3')
      }else{
        sheet.mergeCells('B3','C3')
      }
      */
    /*
      sheet.addBackgroundImage(imageId1); */
    sheet.addRow("");
    sheet.getRow(5).values = col;
    sheet.columns = key;
    sheet.getRow(5).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'ffffff' },
      size: 16
    }

    /* sheet.getRow(3).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'ffffff' },
      size: 26
    } */

    sheet.addRows(data);


    sheet.addRow('').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'ffffff' },
      size: 26
    };

    sheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
      row.eachCell(function (cell, colNumber) {
        cell.font = {
          name: 'Arial',
          family: 2,
          bold: true,
          size: 26,

        };
        cell.alignment = {
          vertical: 'middle', horizontal: 'center'
        };
        if (rowNumber < headerNum) {
          for (var i = 0; i < headerNum; i++) {
            sheet.getRow(i).fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'ffffff' },
              size: 26
            }
          }
        }

        if (rowNumber <= headerNum + 1) {
          row.height = 20;
          cell.font = {
            bold: true,
            size: 20,
            color: { argb: '0099FF' },
          };
          cell.alignment = {
            vertical: 'middle', horizontal: 'center'
          };
        }

        if (rowNumber >= headerNum) {


          for (var i = 1; i < numLopp + 1; i++) {
            if (rowNumber < headerNum) {
              cell.font = {
                color: { argb: '0099FF' },
                bold: true,
                size: 14
              };
              row.height = 25;
              row.getCell(i).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'ffffff' }
              };

              cell.alignment = {
                vertical: 'middle', horizontal: 'center'
              };
            }
            if (rowNumber == headerNum && rowNumber < headerNum + 1) {
              cell.font = {
                color: { argb: 'ffffff' },
                bold: true,
                size: 14
              };
              row.height = 25;
              row.getCell(i).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '0099FF' }
              };

              cell.alignment = {
                vertical: 'middle', horizontal: 'center'
              };
            } else {
              row.getCell(i).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'ffffff' }
              };

              cell.font = {
                color: { argb: '2e2e2f' },
                bold: false,
                size: 12
              };
              cell.alignment = {
                vertical: 'middle', horizontal: 'center'
              };
            }

            row.getCell(i).border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' }
            };
          }
        }

        if (rowNumber >= 6) {

          /*  for (let index = 0; index < column_align.length; index++) {
              const element = column_align[index];
              console.log(element)
              row.getCell(element).alignment = {
                vertical: 'middle', horizontal: 'right', 'color': { 'argb': 'FFFF6600' }
              };
            }*/

          column_align.map(key => {
            row.getCell(key).alignment = {
              vertical: 'middle', horizontal: 'right', 'color': { 'argb': 'FFFF6600' }
            };
          });


        }
      });
    });
    /*
    sheet.addRow(footer) */
    //gera-o no formato execel
    workbook.xlsx.writeBuffer().then(Data => {
      var blob = new Blob([Data], { type: this.blobType });

      var url = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.href = url;
      a.download = excelname;
      a.click();
    });
  }

  multitableexcels(data: any, excelname, img, key, col, title, headerNum, numLopp, imgSize, x, array = [], dates = [], titles = []) {

    var footer = ["UNIG -[ BWATER]"];
    var sheet1 = null;
    var sheet2 = null;
    var sheet = [sheet1, sheet2];

    var workbook = new Excel.Workbook();
    workbook.creator = 'Web';
    workbook.lastModifiedBy = 'Web';
    workbook.created = new Date();
    workbook.modified = new Date();

    for (let index = 0; index < array.length; index++) {
      const element = array[index];
      const date = dates[index];



      workbook.addWorksheet(titles[index], { views: [{ state: 'frozen', ySplit: 5, xSplit: x, activeCell: 'C2', showGridLines: true }] })
      sheet[index] = workbook.getWorksheet(index + 1);
      var imageId1 = workbook.addImage({
        base64: img,
        extension: 'png',
      });

      if (imgSize > 40) {
        sheet[index].addImage(imageId1, {
          tl: { col: 0, row: 1.3 },
          ext: { width: 120, height: 80 }
        })
      } else {
        sheet[index].addImage(imageId1, {
          tl: { col: 0, row: 1.3 },
          ext: { width: 110, height: 80 }
        })
      }


      sheet[index].getCell('A1').value = " "
      sheet[index].getCell('A2').value = " "
      sheet[index].getCell('A3').value = " "
      sheet[index].getCell('B3').value = title
      sheet[index].getCell('A4').value = " "
      sheet[index].getCell('D2').value = " "
      if (x >= 5) {
        sheet[index].mergeCells('B3', 'E3')
      } else {
        sheet[index].mergeCells('B3', 'C3')
      }
      /*
        sheet[index] .addBackgroundImage(imageId1); */
      sheet[index].addRow("");
      sheet[index].getRow(5).values = col;
      sheet[index].columns = element;
      sheet[index].getRow(5).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'ffffff' },
        size: 16
      }

      /* sheet[index] .getRow(3).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'ffffff' },
        size: 26
      } */

      sheet[index].addRows(date);


      sheet[index].addRow('').fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'ffffff' },
        size: 26
      };

      sheet[index].eachRow({ includeEmpty: true }, function (row, rowNumber) {
        row.eachCell(function (cell, colNumber) {
          cell.font = {
            name: 'Arial',
            family: 2,
            bold: true,
            size: 26,

          };
          cell.alignment = {
            vertical: 'middle', horizontal: 'center'
          };
          if (rowNumber < headerNum) {
            for (var i = 0; i < headerNum; i++) {
              sheet[index].getRow(i).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'ffffff' },
                size: 26
              }
            }
          }

          if (rowNumber <= headerNum + 1) {
            row.height = 20;
            cell.font = {
              bold: true,
              size: 20,
              color: { argb: '0099FF' },
            };
            cell.alignment = {
              vertical: 'middle', horizontal: 'center'
            };
          }

          if (rowNumber >= headerNum) {


            for (var i = 1; i < numLopp + 1; i++) {
              if (rowNumber < headerNum) {
                cell.font = {
                  color: { argb: '0099FF' },
                  bold: true,
                  size: 14
                };
                row.height = 25;
                row.getCell(i).fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: 'ffffff' }
                };

                cell.alignment = {
                  vertical: 'middle', horizontal: 'center'
                };
              }
              if (rowNumber == headerNum && rowNumber < headerNum + 1) {
                cell.font = {
                  color: { argb: 'ffffff' },
                  bold: true,
                  size: 14
                };
                row.height = 25;
                row.getCell(i).fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: '0099FF' }
                };

                cell.alignment = {
                  vertical: 'middle', horizontal: 'center'
                };
              } else {
                row.getCell(i).fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: 'ffffff' }
                };

                cell.font = {
                  color: { argb: '2e2e2f' },
                  bold: false,
                  size: 12
                };
                cell.alignment = {
                  vertical: 'middle', horizontal: 'center'
                };
              }

              row.getCell(i).border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
              };
            }
          }
        });
      });
      /*
      sheet[index] .addRow(footer) */
      //gera-o no formato execel
    }
    workbook.xlsx.writeBuffer().then(Data => {
      var blob = new Blob([Data], { type: this.blobType });

      var url = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.href = url;
      a.download = excelname;
      a.click();
    });

  }


}
