import { download } from 'ngx-operators';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  NgZone,
  SimpleChange,
  ViewChild,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { HttpService } from "src/app/providers/http/http.service";
import { ConfigService } from "src/app/providers/config/config.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgxImageCompressService } from "ngx-image-compress";
import { first } from "rxjs/operators";
import { MouseEvent } from "@agm/core";
import { Router, ActivatedRoute } from "@angular/router";
import { google } from "@agm/core/services/google-maps-types";

import 'ol/ol.css';
import GeoJSON from 'ol/format/GeoJSON';
import Map from 'ol/Map';
import VectorSource from 'ol/source/Vector';
import View from 'ol/View';
import XYZ from 'ol/source/XYZ';
import {Stroke, Style} from 'ol/style';
import { transform } from 'ol/proj';
import {Tile, Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import {bbox as bboxStrategy} from 'ol/loadingstrategy';

import Overlay from 'ol/Overlay';
import { createStringXY, toStringHDMS } from 'ol/coordinate.js';
import { toLonLat } from 'ol/proj.js';
import OSM from 'ol/source/OSM';
import MousePosition from 'ol/control/MousePosition';
import {defaults as defaultControls} from 'ol/control';
import TileWMS from 'ol/source/TileWMS';
import { saveAs } from 'file-saver';

@Component({
  selector: "app-create-or-edit-equipamento",
  templateUrl: "./create-or-edit-equipamento.component.html",
  styleUrls: ["./create-or-edit-equipamento.component.css"],
})
export class CreateOrEditEquipamentoComponent implements OnInit,OnChanges {
  @Input() modal: string = "modalCreateOrEdit";
  @Input() title: string = "Registar Equipamento";
  @Input() equipamentos: any = undefined;

  @ViewChild("search") search;

  submitted = false;
  formErrors: any;
  private loading: boolean = false;
  private ver: boolean = true;

  private success_new: boolean = false;

  routerParamId: any;

  @Input() simpleFormCreateOrEdit: FormGroup;

  @Output() private loadList = new EventEmitter<any>();

  tournament: any = {
    venue: {
      address: "test",
    },
  };

  private primeiros_niveis: any = [];
  private estados: any = [];
  private criticidades: any = [];
  private contadores: any = [];
  private tipoAquisicoes: any = [];
  private estadosConservacao: any = [];
  private segundos_niveis: any = [];
  bindInfoOpenLayer:string=''

  constructor(
    private http: HttpService,
    private configService: ConfigService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private imageCompress: NgxImageCompressService,
    private ngZone: NgZone
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.getPrimeiroNivel();
    this.getEstados();
    this.getContadores();
    this.getCriticidade();
    this.getTipoAquisicao();
    this.getEstadoConservacao();
    this.getRouterParamId();
    //this.hendleOpenLayer();
    this.simpleFormCreateOrEdit.patchValue({
      latitude: 2226253.9857,
      longitude: -933143.263,
    });
  }

 /*  ngOnChanges(changes: SimpleChanges) {
    this.hendleOpenLayer();
  } */
  hendleCheker(){
    this.check=true
    this.sizeMap++
  }
  hendleOpenLayer() {

    const container = document.getElementById('popup');
    const content = document.getElementById('popup-content');
    const closer = document.getElementById('popup-closer');
    document.getElementById('map').innerHTML = '';

    const overlay = new Overlay({
      element: container,
      autoPan: {
        animation: {
          duration: 250,
        },
      },
    });

    const mousePositionControl = new MousePosition({
      coordinateFormat: createStringXY(4),
      projection: 'EPSG:4326',
      className: 'custom-mouse-position',
      target: document.getElementById('mouse-position'),
    });

    const wmsSource = new TileWMS({
       url: 'http://88.157.83.182:1000/WMSAbastecimento_Cadastro/service.svc/get?request=GetCapabilities&service=WMS',
       params: {'LAYERS':
                 'VACHAFARIZ,VAFILTRO,VAFURO,VAGRELECTROBOMBA,VAHIDRANTE,VAJUNTACEGA,VAMEDIDORCAUDAL,VANOALTERACAO,VAPONTOENTREGA,VAPONTONOTAVEL,VAPOSTOCLORAGEM,VAVALDESCARGA,VAVALREGULACAO,VAVALRETENCAO,VAVALSUSPENSAO,VAVENTOSA,VARAMAL,VATROCOADUTOR,VATROCOTUBAGEM,VACAPTACAO,VACELULA,VAESTACAOELEVATORIA,VAETA,VARECINTO,VARESERVATORIO',
                 'TILED': true
              },
      serverType: "geoserver",
      //crossOrigin: "anonymous",
    });

    let wmsLayer: any = new Tile({
      source: new OSM()
    })

    let _wmsLayer: any = new Tile({
      source: wmsSource
    })
  console.log('cordenate =>',[
    this.simpleFormCreateOrEdit.value.latitude,
    -this.simpleFormCreateOrEdit.value.longitude
   ])
    const view = new View({

      center: [
               this.simpleFormCreateOrEdit.value.latitude,
               -this.simpleFormCreateOrEdit.value.longitude
              ],
      zoom: 8.5,
    });

     let map = new Map({
      controls: defaultControls().extend([mousePositionControl]),
      layers: [
        wmsLayer,
        _wmsLayer
      ],
      overlays: [overlay],
      target: "map",
      view: view,
    });
    console.log('map =>',map, _wmsLayer)
    map.on("singleclick", function (evt) {
      //document.getElementById('info').innerHTML = '';
      document.getElementById('info').innerHTML = '';

      const viewResolution =  view.getResolution();
      const url = wmsSource.getFeatureInfoUrl(
        evt.coordinate,
        viewResolution,
        "EPSG:3857",
        { INFO_FORMAT: "text/html" }
      );
      if (url) {
            document.getElementById('info').innerHTML =
            '<iframe seamless src="' + url + '"></iframe>';
         fetch(url)
          .then((response) => response.text())
          .then((html) => {
           /*  document.getElementById('info').innerHTML = html;
            this.bindInfoOpenLayer=html
            console.log('url = >',html) */
          });
      }
    });

   map.on('click', function (evt) {
      const coordinate = evt.coordinate;
      const hdms = toStringHDMS(toLonLat(coordinate));

      content.innerHTML = '<p>You clicked here:</p><code>' + hdms + '</code>';
      overlay.setPosition(coordinate);
    });

    map.on("pointermove", function (evt) {
      if (evt.dragging) {
        return;
      }
       const data = wmsLayer.getData(evt.pixel)
      const hit = data && data[3] > 0; // transparent pixels have zero for data[3]
      map.getTargetElement().style.cursor = hit ? "pointer" : "";
    });

   // console.log("info", map);
  }

  public getRouterParamId() {
    this.routerParamId = this.route.snapshot.params.id;

    if (this.routerParamId != undefined) {
      this.getDados();
    }
  }

  private getSegundoNivelEdit() {
    this.http
      .call_get("ordemservico/equipamento/getSegundoNivelEdit", null)
      .subscribe((response) => {
        this.segundos_niveis = Object(response).data;
      });
  }

  private getDados() {
    this.http
      .call_get("ordemservico/equipamento/getDados/" + this.routerParamId, null)
      .subscribe((response) => {
        this.equipamentos = Object(response).data;

        if (this.equipamentos == undefined) {
          this.router.navigate(["/equipamento/novo"]);
        }

        if (this.equipamentos.segundo_nivel_id != null) {
          this.getSegundoNivelEdit();
        }

        if (this.equipamentos != undefined) {
          this.ngOnEdit();
        }
      });
  }

  private getPrimeiroNivel() {
    this.http
      .call_get("ordemservico/equipamento/getPrimeiro-niveis", null)
      .subscribe((response) => {
        this.primeiros_niveis = Object(response).data;
      });
  }

  private getEstados() {
    this.http
      .call_get("ordemservico/equipamento/estado/selectBox", null)
      .subscribe((response) => {
        this.estados = Object(response).data;
      });
  }

  private getCriticidade() {
    this.http
      .call_get("ordemservico/criticidade/selectBox", null)
      .subscribe((response) => {
        this.criticidades = Object(response).data;
      });
  }

  private getContadores() {
    this.http
      .call_get("manutencao-preventiva/contador/selectBox", null)
      .subscribe((response) => {
        this.contadores = Object(response).data;
      });
  }

  private getEstadoConservacao() {
    this.http
      .call_get("ordemservico/estadoConservacao/selectBox", null)
      .subscribe((response) => {
        this.estadosConservacao = Object(response).data;
      });
  }

  private getTipoAquisicao() {
    this.http
      .call_get("ordemservico/tipoAquisicao/selectBox", null)
      .subscribe((response) => {
        this.tipoAquisicoes = Object(response).data;
      });
  }

  private getSegundoNivel() {
    this.http
      .call_get(
        "ordemservico/equipamento/segundoNivelByPrimeiro/" +
          this.simpleFormCreateOrEdit.value.nivel_1,
        null
      )
      .subscribe((response) => {
        this.segundos_niveis = Object(response).data;
      });
  }

  createForm() {
    this.simpleFormCreateOrEdit = this.formBuilder.group({
      index: [{ value: null, disabled: true }],
      descricao: [null, Validators.required],
      estado_id: [null, Validators.required],
      nivel_1: [null],
      nivel_2: [null],
      latitude: [null],
      longitude: [null],
      diametro: [null],
      codigo_registo: [null, Validators.required],
      abreviatura: [null],
      tipo_aquisicao_id: [null],
      valor_aquisicao: [null],
      data_aquisicao: [null],
      data_instalacao: [null],
      fornecedor: [null],
      numero_fabrico: [null],
      data_fabrico: [null],
      marca_fabricante: [null],
      modelo: [null],
      redutor: [null],
      pressao: [null],
      caudal: [null],
      altura_manometrica: [null],
      rotacao: [null],
      tensao: [null],
      pecas_reserva: [null],
      eficiencia: [null],
      ficha_equipamento: [null],
      factor_potencia: [null],
      npsh: [null],
      tipo_oleo: [null],
      quantidade_oleo: [null],
      rolamentos: [null],
      numero_serie: [null],
      caracteristica_tecnica: [null],
      alimentacao: [null],
      frequencia_potencia: [null],
      intensidade_corrente: [null],
      peso_aproximado: [null],
      comprimento: [null],
      largura: [null],
      profundidade: [null],
      grau_criticidade_id: [null],
      estado_conservacao_id: [null],
      observacao: [null],
      centro_custo: [null],
      search_location: [null],
      imagem: [null],
    });
  }

  check:boolean=false
  sizeMap:number=0
  // convenience getter for easy access to form fields
  get f() {
    return this.simpleFormCreateOrEdit.controls;
  }

  onReset() {
    this.submitted = false;
    this.simpleFormCreateOrEdit.reset();
  }

  cancelar() {
    this.router.navigate(["/equipamento/listagem"]);
  }

  texto: string = "Equipamento";
  lat = -8.996737;
  lng = 13.268137;
  zoom = 10;

  mapClicked($event: MouseEvent) {
    this.simpleFormCreateOrEdit.patchValue({
      latitude: $event.coords.lat,
      longitude: $event.coords.lng,
    });

    this.markers.length = 0;
    this.markers.push({
      lat: $event.coords.lat,
      lng: $event.coords.lng,
      draggable: true,
    });
  }

  markerDragEnd(lat, lng, $event: MouseEvent) {
    this.lat = $event.coords.lat;
    this.lng = $event.coords.lng;
    /* const geocoder = new google.maps.Geocoder();
    const latlng = new google.maps.LatLng(this.lat, this.lng);
    const request = {
      latLng: latlng
    };

    geocoder.geocode(request, (results, status) => {
      this.ngZone.run(() => {
       // this.tournament.venue.address = results[0].formatted_address;
       this.simpleFormCreateOrEdit.patchValue({
        search_location: results[0].formatted_address
      })
      })
    });  */

    //console.log(this.simpleFormCreateOrEdit.value.search_location)
  }

  filterPlaceLatitude() {
    var latitude: number = +this.simpleFormCreateOrEdit.value.latitude;
    this.lat = latitude;
   // this.hendleOpenLayer();
  }

  filterPlaceLongitude() {
    var longitude: number = +this.simpleFormCreateOrEdit.value.longitude;
    this.lng = longitude;
    //this.hendleOpenLayer();
  }

  markers: marker[] = [
    {
      lat: null,
      lng: null,
      draggable: true,
    },
  ];

  onSubmit() {
    this.submitted = true;

    // parar aquei se o formulário for inválido
    if (this.simpleFormCreateOrEdit.invalid) {
      return;
    }
    this.loading = true;
    const index = this.simpleFormCreateOrEdit.getRawValue().index;
    // TODO: usado para fazer a requisição com a api de criação de objsct or update
    const uri =
      index === null
        ? "ordemservico/equipamento/register"
        : "ordemservico/equipamento/update/" + index;
    this.createOrEdit(
      uri,
      this.simpleFormCreateOrEdit,
      index === null ? true : false
    );
  }

  createOrEdit(uri: any, formulario: FormGroup, isCreate: boolean) {
    // TODO: usado para fazer a requisição com a api de criação de object
    this.http
      .__call(uri, formulario.value)
      .pipe(first())
      .subscribe(
        (response) => {
          this.submitted = false;
          this.loading = false;
          if (isCreate && Object(response).code == 200) {
            formulario.reset();
          }

          if (Object(response).code == 200) {
            this.success_new = true;
            this.loadList_item(Object(response).data);
          }
        },
        (error) => {
          this.submitted = false;
          this.loading = false;
          this.success_new = false;
        }
      );
  }

  private reload() {
    location.reload();
  }

  file: any;
  localUrl: any;
  save: any;
  urlCarregada: any;
  localCompressedURl: any;
  sizeOfOriginalImage: number;
  sizeOFCompressedImage: number;

  private widithImage: any;
  private heightImage: any;

  selectFile(event: any) {
    this.widithImage = 290;
    this.heightImage = 280;

    var fileName: any;
    var larg: any;
    this.file = event.target.files[0];
    let img = new Image();
    img.src = window.URL.createObjectURL(event.target.files[0]);

    img.onload = () => {
      this.save = true;
      //}
    };

    fileName = this.file["name"];
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.urlCarregada = event.target.result;
        this.compressFile(this.urlCarregada, fileName);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  imgResultBeforeCompress: string;
  imgResultAfterCompress: string;

  compressFile(image, fileName) {
    var orientation = -1;
    this.sizeOfOriginalImage =
      this.imageCompress.byteCount(image) / (1024 * 1024);
    // console.warn('Size in bytes is now:', this.sizeOfOriginalImage);
    this.imageCompress
      .compressFile(image, orientation, 30, 30)
      .then((result) => {
        this.imgResultAfterCompress = result;
        this.localCompressedURl = this.imgResultAfterCompress;
        this.sizeOFCompressedImage =
          this.imageCompress.byteCount(result) / (1024 * 1024);
        // console.warn('Size in bytes after compression:', this.sizeOFCompressedImage);
        const imageName = fileName;
        document.getElementById("customFileLabel").innerHTML = imageName;
        const imageBlob = this.dataURItoBlob(
          this.imgResultAfterCompress.split(",")[1]
        );
        const imageFile = new File([result], imageName, { type: "image/jpeg" });
        this.simpleFormCreateOrEdit.patchValue({
          imagem: this.localCompressedURl,
        });
      });
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: "image/jpeg" });
    return blob;
  }

  ngOnEdit() {
    if (this.equipamentos !== undefined) {
      this.title = "Editar Equipamento";
      //this.onReset()

      this.lat = this.equipamentos.latitude;
      this.lng = this.equipamentos.longitude;

      this.simpleFormCreateOrEdit.patchValue({
        index: this.equipamentos.id,
        descricao: this.equipamentos.descricao,
        estado_id: this.equipamentos.estado_id,
        nivel_1: this.equipamentos.primeiro_nivel_id,
        diametro: this.equipamentos.diametro,
        nivel_2: this.equipamentos.segundo_nivel_id,
        latitude: this.equipamentos.latitude,
        longitude: this.equipamentos.longitude,
        codigo_registo: this.equipamentos.codigo_registo,
        abreviatura: this.equipamentos.abreviatura,
        tipo_aquisicao_id: this.equipamentos.tipo_aquisicao_id,
        valor_aquisicao: this.equipamentos.valor_aquisicao,
        data_aquisicao: this.equipamentos.data_aquisicao,
        data_instalacao: this.equipamentos.data_instalacao,
        fornecedor: this.equipamentos.fornecedor,
        numero_fabrico: this.equipamentos.numero_fabrico,
        data_fabrico: this.equipamentos.data_fabrico,
        marca_fabricante: this.equipamentos.marca_fabricante,
        modelo: this.equipamentos.modelo,
        redutor: this.equipamentos.redutor,
        pressao: this.equipamentos.pressao,
        caudal: this.equipamentos.caudal,
        altura_manometrica: this.equipamentos.altura_manometrica,
        rotacao: this.equipamentos.rotacao,
        factor_potencia: this.equipamentos.factor_potencia,
        npsh: this.equipamentos.npsh,
        tensao: this.equipamentos.tensao,
        pecas_reserva: this.equipamentos.pecas_reserva,

        eficiencia: this.equipamentos.eficiencia,
        ficha_equipamento: this.equipamentos.ficha_equipamento,
        tipo_oleo: this.equipamentos.tipo_oleo,
        quantidade_oleo: this.equipamentos.quantidade_oleo,
        rolamentos: this.equipamentos.rolamentos,
        numero_serie: this.equipamentos.numero_serie,
        caracteristica_tecnica: this.equipamentos.caracteristica_tecnica,
        alimentacao: this.equipamentos.alimentacao,
        frequencia_potencia: this.equipamentos.frequencia_potencia,
        intensidade_corrente: this.equipamentos.intensidade_corrente,
        peso_aproximado: this.equipamentos.peso_aproximado,
        comprimento: this.equipamentos.comprimento,
        largura: this.equipamentos.largura,
        profundidade: this.equipamentos.profundidade,
        grau_criticidade_id: this.equipamentos.grau_criticidade_id,
        estado_conservacao_id: this.equipamentos.estado_conservacao_id,
        observacao: this.equipamentos.observacao,
        centro_custo: this.equipamentos.centro_custo,
      });
    } else {
      this.onReset();
      this.title = "Registar Equipamento";
    }
  }
  ngDoCheck() {

    if (this.check===true && this.sizeMap==1) {

     setTimeout(()=>{
         this.hendleOpenLayer();
         this.check=false

     },1000)

    }


}


  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {

    if (this.equipamentos !== undefined) {
      this.title = "Editar Equipamento";
      //this.onReset()


      this.lat = this.equipamentos.latitude;
      this.lng = this.equipamentos.longitude;

      this.simpleFormCreateOrEdit.patchValue({
        index: this.equipamentos.id,
        descricao: this.equipamentos.descricao,
        estado_id: this.equipamentos.estado_id,
        nivel_1: this.equipamentos.primeiro_nivel_id,
        nivel_2: this.equipamentos.segundo_nivel_id,
        latitude: this.equipamentos.latitude,
        longitude: this.equipamentos.longitude,
      });
    } else {
      this.onReset();
      this.title = "Registar Equipamento";
    }
  }

  public loadList_item(equipamentos) {
    this.loadList.emit(equipamentos);
  }

  downloadFile(image: any, item: any) {
    return saveAs(new Blob(image, { type: image }), item.id)
  }
  previewFile(image: any, item: any) {
    const fileURL = window.URL.createObjectURL(new Blob(image, { type: image }))
    window.open(fileURL, '_blank');
  }
}

interface marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}
