
import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { ResumoRecebimentoPorCaixaService } from 'src/app/components/report-at/vendas/resumo-recebimento-por-caixa.service'
import { DiarioVendasService } from '../../report-at/vendas/diario-vendas.service';
import { ExcelService } from 'src/app/services/excel.service';
import { MovimentoCaixasService } from 'src/app/services/ExportExcel/movimento-caixa.service';
import { MovimentoCaixaService } from 'src/app/components/reports/Movimento-caixa/movimento-caixa.service'
import * as moment from 'moment';
import { MovimentoRecebimentoPdfService } from './movimento-recebimento-pdf.service';

@Component({
  selector: 'app-movimento-caixa',
  templateUrl: './movimento-caixa.component.html',
  styleUrls: ['./movimento-caixa.component.css']
})
export class MovimentoCaixaComponent implements OnInit {

  private caixas: any = [];
  private loading: boolean = false;
  private DadosEmpresa: any;

  constructor(
    private http: HttpService,
    private configService: ConfigService,
    private DiarioVendasService: DiarioVendasService,
    private resumoCaixa: ResumoRecebimentoPorCaixaService,
    private excelService: ExcelService,
    private MovimentoCaixa: MovimentoCaixaService,
    private excel: MovimentoCaixasService,
    private printMovimento: MovimentoRecebimentoPdfService
  ) { }

  ngOnInit() {
    this.http.filters.search = null
    this.getPageFilterData(1);
    this.empresaUser();
  }
  exportAsXLSX(): void {
    var CurrentDate = new Date();
    var nameFile = "Lista_Movimentos_Caixa-" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')

    this.excel.ExcelExportmovimentosCaixa(this.caixas, this.DadosEmpresa, nameFile + '.xlsx')
  }
  exportAsPDF(): void {
    var table = document.getElementsByClassName("exportPDF")[0];
    this.MovimentoCaixa.ReportMovimentoCaixa(table, this.DadosEmpresa, 'save');
    //this.reportLoja.relatorioLoja(this.cobrancas, this.simpleForm.value, 'save');
  }

  imprimirPDF(): void {
    var table = document.getElementsByClassName("exportPDF")[0];
    this.MovimentoCaixa.ReportMovimentoCaixa(table, this.DadosEmpresa, 'imprimir');
    //this.reportLoja.relatorioLoja(this.cobrancas, this.simpleForm.value);
  }


  /**
   * @name "Listar facturação"
   * @descriptio "Esta Função permite Listar todas facturações"
   * @author "caniggia.moreira@itgest.pt"
   * @param start
   * @param end
   */
  private listarMovimentoCaixa() {
    this.loading = true
    this.configService.loaddinStarter('start');
    this.http.__call('caixa/movimentoCaixa', this.http.filters).subscribe(
      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;
        this.caixas = Object(response).data.data;
        this.configService.loaddinStarter('stop');
        this.loading = false;
      }
    );
  }

  private empresaUser() {
    this.configService.loaddinStarter('start');

    this.http.call_get('empresa/empresa-user', null).subscribe(
      response => {
        // console.log( Object(response).data)
        this.DadosEmpresa = Object(response).data;
      }
    );
  }

  private btnImprimirMovimetocaixa(user_id: number, abertura: any, data_abertura: any, caixa_id: number) {

    // this.ReportDiarioVendasService.imprimirDiarioVendaPorCaixa();
    this.DiarioVendasService.DiarioVendas(user_id, abertura, data_abertura, caixa_id);
  }

  private btnimprimirResumo(data_abertura, user, caixa_id) {

    //this.printMovimento.printMovimentoRecebimento({})
    this.resumoCaixa.imprimirResumoRecebimentoPorCaixa(data_abertura, 1, user, caixa_id);
  }
  //--------------------------------------------------------------------------

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1;
    this.listarMovimentoCaixa();
  }

}
