import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'

@Component({
  selector: 'app-create-os-massiva',
  templateUrl: './create-os-massiva.component.html',
  styleUrls: ['./create-os-massiva.component.css']
})
export class CreateOsMassivaComponent implements OnInit {



  @Input() modal: string = "modalCreateOrdemServicoMassiva";
  @Input() title: string = "Criar Ordem de Serviço Massiva";
  @Input() contratos: any = [];

  submitted = false;
  formErrors: any;
  private loading: boolean = false;
  private disabledBotton: boolean = false;
  private ver: boolean = true;

  private prioridades: any = [];
  private classificacoes: any = [];
  private causas: any = [];
  private sintomas: any = [];
  private tipos: any = [];
  private contas: any = [];

  private causasArray: any = [];
  private sintomasArray: any = [];
  private local_consumo: any = [];

  private cliente_contrato_id = null;
  private is_sintoma: boolean = false;
  private is_causa: boolean = false;
  private is_titulo: boolean = false;
  private editable: boolean = false;

  dropdownSettings = {};
  dropdownSettingsSintomas = {};

  dropdownSettingsDepartamentos = {};
  private departamentoArray: any = [];
  private dropdownDepartamentos: any = [];

  @Input() simpleFormCreateOrEdit: FormGroup;

  @Output() private loadList = new EventEmitter<any>();


  constructor(private http: HttpService, private configService: ConfigService, private formBuilder: FormBuilder) {
    this.createForm();
  }

  ngOnInit() {
    this.getPrioridade();
    this.getClassificacao();
    this.getDropDownDepartamentos();
  }

  createForm() {
    this.simpleFormCreateOrEdit = this.formBuilder.group({
      index: [{ value: null, disabled: true }],
      cliente_nome: [null],
      titulo: [null, Validators.required],
      data_prevista: [null, Validators.required],
      prioridade_id: [null, Validators.required],
      classificacao_id: [null, Validators.required],
      tipo_id: [null, Validators.required],
      descricao: [null, Validators.required],
      categoria_descricao: [null],
      email: [null],
      nivel_3: [null],
      nivel_2: [null],
      nivel_1: [null],
      causa_id: [null],
      sintoma_id: [null],
      sla: [null],
      is_massiva: false
    })
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.simpleFormCreateOrEdit.controls;
  }

  onReset() {
    this.submitted = false;
    this.simpleFormCreateOrEdit.reset();
  }

  private getPrioridade() {
    this.http.call_get('ordemservico/prioridade/selectBox', null).subscribe(
      response => {
        this.prioridades = Object(response).data;
      }
    );
  }

  private getDropDownDepartamentos() {
    this.http.call_get('departamento/selectBox', null).subscribe(
      response => {
        this.dropdownDepartamentos = Object(response).data;

        this.dropdownSettingsDepartamentos = {
          singleSelection: false,
          idField: 'id',
          textField: 'descricao',
          selectAllText: 'Seleccione Todos',
          unSelectAllText: 'DeSeleccionar Todos',
          itemsShowLimit: 10,
          allowSearchFilter: true
        };

      }
    );
  }

  onItemSelectDepartamento(item: any) {
    this.departamentoArray.push(item);
  }
  onSelectAllDepartamentos(items: any) {
    this.departamentoArray = items;
  }

  private getClassificacao() {
    this.http.call_get('ordemservico/classificacao-comercial/selectBox', null).subscribe(
      response => {
        this.classificacoes = Object(response).data;
      }
    );
  }

  private getAssociacao() {

    this.simpleFormCreateOrEdit.patchValue({
      titulo: this.simpleFormCreateOrEdit.value.titulo
    });

    this.is_titulo = false;

    this.simpleFormCreateOrEdit.patchValue({
      categoria_descricao: null
    });

    if (this.simpleFormCreateOrEdit.value.tipo_id != null) {
      this.configService.loaddinStarter('start');
      this.http.call_get('ordemservico/ostipo/associacao/' + this.simpleFormCreateOrEdit.value.tipo_id, null).subscribe(
        response => {

          this.simpleFormCreateOrEdit.patchValue({
            categoria_descricao: Object(response).data.categoria_descricao,
            sla: Object(response).data.sla
          });

          this.is_sintoma = Object(response).data.sintoma;
          this.is_causa = Object(response).data.causa;
          this.is_titulo = Object(response).data.is_titulo;

          if (this.is_titulo) {

            this.simpleFormCreateOrEdit.patchValue({
              titulo: Object(response).data.descricao
            });


          }
          this.configService.loaddinStarter('stop');
        }

      );
    }

    this.is_titulo = false

  }

  private carregando = {
    tipo: 'Seleccione o tipo'
  }

  private getTipoByClassificacao() {
    this.carregando.tipo = 'Carregando...';
    this.http.call_get('ordemservico/ostipo-by-classificacao/selectBox/' + this.simpleFormCreateOrEdit.value.classificacao_id, null).subscribe(
      response => {
        this.tipos = Object(response).data;
        this.carregando.tipo = 'Seleccione o tipo';
      }
    );

    this.getCausasByClassificacao();
    this.getSintomasByClassificacao();
  }

  private getCausasByClassificacao() {

    this.http.call_get('ordemservico/oscausas-by-classificacao/selectBox/'+ this.simpleFormCreateOrEdit.value.classificacao_id, null).subscribe(
      response => {
        this.causas = Object(response).data;

        this.dropdownSettings = {
          singleSelection: false,
          idField: 'id',
          textField: 'descricao',
          selectAllText: 'Seleccione Todos',
          unSelectAllText: 'DeSeleccionar Todos',
          itemsShowLimit: 10,
          allowSearchFilter: true
        };

      }
    );
  }

  onResetSome() {

    this.contratos = [];

    this.causasArray = [];
    this.sintomasArray = [];
    this.departamentoArray = [];

    this.disabledBotton = false;

    this.is_sintoma = false;
    this.is_causa = false;
    this.is_titulo = false;

    this.simpleFormCreateOrEdit.patchValue({
      titulo: null,
      sla: null,
      data_prevista: null,
      prioridade_id: null,
      classificacao_id: null,
      categoria_descricao: null,
      tipo_id: null,
      descricao: null,
      is_massiva: false
    });
  }

  onItemSelect(item: any) {
    //console.log(item);
    this.causasArray.push(item);
  }
  onSelectAll(items: any) {
    this.causasArray = items;
  }

  private getSintomasByClassificacao() {
    this.http.call_get('ordemservico/ossintomas-by-classificacao/selectBox/' + this.simpleFormCreateOrEdit.value.classificacao_id, null).subscribe(
      response => {
        this.sintomas = Object(response).data;

        this.dropdownSettingsSintomas = {
          singleSelection: false,
          idField: 'id',
          textField: 'descricao',
          selectAllText: 'Seleccione Todos',
          unSelectAllText: 'DeSeleccionar Todos',
          itemsShowLimit: 10,
          allowSearchFilter: true
        };

      }
    );
  }

  onItemSelectSintoma(item: any) {
    this.sintomasArray.push(item);
  }
  onSelectAllSintomas(items: any) {
    this.sintomasArray = items;
  }

  onSubmit() {

    this.submitted = true;

    // parar aquei se o formulário for inválido
    if (this.simpleFormCreateOrEdit.invalid) {
      return;
    }
    this.loading = true;
    const index = this.simpleFormCreateOrEdit.getRawValue().index;
    // TODO: usado para fazer a requisição com a api de criação de objsct or update
    const uri = 'ordemservico/ordem-servico/create';
    this.createOrEdit(uri, this.simpleFormCreateOrEdit, (index === null ? true : false));

  }

  createOrEdit(uri: any, formulario: FormGroup, isCreate: boolean) {

    // TODO: usado para fazer a requisição com a api de criação de object
    let uploadData = new FormData();

    this.disabledBotton = true;

    uploadData.append("ordemServico", JSON.stringify(formulario.value));
    uploadData.append("causas", JSON.stringify(this.causasArray));
    uploadData.append("sintomas", JSON.stringify(this.sintomasArray));
    uploadData.append("departamentos", JSON.stringify(this.departamentoArray));
    uploadData.append("contratos", JSON.stringify(this.contratos));

    this.http.$__call(uri, uploadData).subscribe(
      response => {
        this.submitted = false;
        this.loading = false;
        if (isCreate && Object(response).code == 200) {
          formulario.reset();
          this.onReset()
        }

        if ((Object(response).body || {}).code ==200) {
          this.alertPrintOrdemServico((Object(response).body || {}).data.ordem.id)

          this.onReset()
        }
      },
      error => {
        this.submitted = false;
        this.loading = false;
      });
  }


  alertPrintOrdemServico(id){
   
    if(!id) return
     Swal.fire({
         title: 'Imprimir Ordem de Serviço!',
         text: "Ordem de Serviço!",
         type: 'success',
         showCancelButton: true,
         confirmButtonText: 'Imprimir',
         cancelButtonText: 'Cancelar!',
         reverseButtons: true
     }).then((result) => {
         if (result.value) {
           this.imprimirOrdemServico(id)
         }
     })
 
 }
 private imprimirOrdemServico(id) {
   this.configService.imprimirOrdemServico(id, "2ª Via", "imprimir")
 }


  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {

    if (this.contratos != undefined) {

      this.title = "Criar Ordem de Serviço Massiva";

      this.simpleFormCreateOrEdit.patchValue({
        is_massiva: true
      });

    }
  }

  public loadList_item(contratos) {
    this.loadList.emit(contratos);
  }


}
