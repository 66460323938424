import { Injectable, Input, EventEmitter } from '@angular/core';
import { ApiService } from 'src/app/providers/http/api.service';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import * as extenso from 'extenso';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import { ReciboService } from './recibo.service';

@Injectable({
  providedIn: 'root'
})
export class PlanoPrestacaoService {

  @Input() app_environment: null;

  constructor(
    private http: ApiService,
    private auth: AuthService,
    private reciboService: ReciboService
  ) {
    this.app_environment = this.auth.getAppEnvironment();
    if (this.app_environment == null) {
      var url = require('url');
      var app_url = url.parse(environment.app_url, true);
      this.app_environment = app_url.host;
    }
  }

  public imprimirReciboPlanoPrestacao(id, via = 'Original', report = 'imprimir') {
    this.http.get('recibos/printerPlanoPrestacao/' + id).subscribe(
      response => {

        const dados = Object(response).data;
        console.log(dados.plano_prestacao)
        this.imprimirPDFRecibo(
          dados.plano_prestacao,
          dados.linhas_prestacao,
          dados.facturas,
          dados.user,
          report,
          via
        )
      }, error => {

      }
    );
  }



  public imprimirPDFRecibo(
    plano_prestacao: any,
    linhas_prestacao: any[],
    facturas: any[],
    user: any,
    report: string = 'imprimir',
    original: any = 'Original'
  ) {
    var numberPage = 1;
    var img_logotipo = user.logotipo;
    var doc = new jsPDF();
    doc.addImage(img_logotipo, 'JPEG', 8, 14, 28, 24)
    var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
    var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
    var date = new Date();
    const meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
    console.log(plano_prestacao)

    doc.setFontSize(8);
    doc.setFont("calibri");
    doc.setTextColor(0);
    doc.text('' + (user.companyName || ''), 37, 20);
    doc.setTextColor(0);
    doc.text('' + (user.addressDetail || ''), 37, 25);
    doc.text('NIF: ' + (user.taxRegistrationNumber || ''), 37, 30);
    doc.text('Email: ' + (user.company_email || ''), 37, 35);
    doc.text('Telefone: ', 95, 30);
    doc.text('' + (user.company_telefone || ''), 107, 30);
    doc.text('WebSite: ', 95, 35);
    doc.setTextColor(0, 0, 255);
    doc.text('' + (user.site || ''), 107, 35);

    doc.setFontSize(8);
    doc.setDrawColor(0);
    doc.setFillColor(255);
    doc.rect(145, 14, 55, 23, 'B');

    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.text('CONTRATO N.º', 148, 20);
    doc.text('CIL:', 148, 25);
    doc.text('DATA: ', 148, 30);

    doc.setFontSize(8);
    doc.setFontType("normal");
    doc.text('' + (plano_prestacao.contrato || ''), 168, 20);
    doc.text('' + (plano_prestacao.cil || ''), 158, 25);
    doc.text('' + moment(plano_prestacao.data_criacao).format("DD/MM/YYYY"), 158, 30);

    doc.setFontSize(15);
    doc.setFont("calibri");
    doc.setFontType("bold");
    doc.text('ACORDO PLANO DE PAGAMENTO', 60, 55);

    doc.setFontSize(12);
    doc.setFont("calibri");
    doc.setFontType("normal");
    doc.text('Caro ' + plano_prestacao.nome_cliente + ',', 10, 65);

    doc.text('O presente documento atesta que concordou que deve a nossa empresa o valor total de ' + plano_prestacao.total + ' AOA, e que irá', 10, 75);
    doc.text(' efectuar o pagamento nas seguintes condições:', 10, 83);
    var posY = 91

    for (var i = 0; i < linhas_prestacao.length; i++) {
      var element = linhas_prestacao[i];

      doc.text(element.numero_prestacao + ' ª prestação no valor de ' + element.valor + ' AOA no periodo até ' + moment(element.data_fim).format("DD/MM/YYYY") + '.', 15, posY);
      posY += 8;

    }
    var pos = posY + 15
    doc.text('Agradecemos a sua compreensão neste assunto. Em caso de incapacidade de cumprir com este acordo, deverá entrar', 10, pos);

    doc.text('em contacto com a nossa empresa antes do vencimento do plano de pagamento.', 10, pos + 10);

    posY = posY + 12;
    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.setFontSize(8);
    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.rect(12, posY + 34, 182, 5, "B");
    doc.text("FATURAS ASSOCIADAS AO PLANO", 13, posY + 38);
    doc.text("SIGLA FACTURA", 30, posY + 42);
    doc.text("VALOR EM ABERTO", 110, posY + 42);
    doc.setDrawColor(0, 0, 0);
    doc.setLineWidth(0.1);
    doc.line(12, posY + 43, 194, posY + 43); // horizontal line

    for (let i = 0; i < (facturas || []).length; i++) {
      doc.text(
        "" + ((facturas[i] || {}).factura_sigla || ""),
        27,
        posY + 47
      ); // adicionar o valor total sem o IVA
      doc.text(
        "" + ((facturas[i] || {}).valor_aberto || ""),
        105,
        posY + 47
      );
      posY += 5;
    }

    doc.setFontSize(12);
    doc.setFont("calibri");
    doc.setFontType("normal");
    doc.text(10, posY + 65, 'Tendo Pago como Valor de Entrada : '+ plano_prestacao.valor_entrada + ' , AOA')

    doc.text(10, posY + 92, user.municipio == null ? '' : user.municipio + ' , aos ' + date.getDate() + ' de ' + meses[date.getMonth()] + ' de ' + date.getFullYear())


    assinaturas(pageHeight - 25, this.reciboService.siglaEmpresa);

    function assinaturas(posy, siglaEmpresa: any) {
      doc.setFontSize(9);
      doc.line(20, posy + 2, 80, posy + 2);
      doc.text('O Cliente', 45, posy + 5.5);

      doc.text(siglaEmpresa, 150, posy + 5.5);
      doc.line(130, posy + 2, 190, posy + 2);

    }


    footer()


    function footer() {
      var dataActual = moment(new Date()).format("DD/MM/YYYY");
      //doc.text(dataActual + ' ' + moment().format("H:m") + '/ Versão: ' + original, 11, 285);
      /*     doc.text(moment(recibo.created_at).format("DD/MM/YYYY") + ' ' + moment(recibo.created_at).format("H:m") + '/ Versão: ' + original, 11, 285); */
      // doc.text('Página: ' + numberPage, 184, 285);

      doc.setFontSize(8);
      doc.setDrawColor(0);
      doc.setFillColor(0);


      doc.line(10, doc.internal.pageSize.height - 9, 194, doc.internal.pageSize.height - 9); // vertical line
      /*
      var hash = recibo == null ? '' : recibo.hash ? recibo.hash.substring(0, 1) : ''
      hash += recibo == null ? '' : recibo.hash ? recibo.hash.substring(10, 11) : ''
      hash += recibo == null ? '' : recibo.hash ? recibo.hash.substring(20, 21) : ''
      hash += recibo == null ? '' : recibo.hash ? recibo.hash.substring(30, 31) : ''
      */
      doc.setFontSize(6);

      doc.text("NIF: " + user.taxRegistrationNumber + " - " + user.companyName + " / " + user.addressDetail + " / " + (user.company_telefone || '') + " / " + (user.company_email || ''), 105, doc.internal.pageSize.height - 6, null, null, 'center');

      doc.setFontSize(8);

    }



    if (report == 'imprimir') {
      doc.autoPrint();
      window.open(doc.output("bloburl")); //opens the data uri in new window
    } else {
      doc.save('Plano_prestacao' + '.pdf'); /* download the file immediately on loading */
    }

  }
  public numberFormat(number) {
    return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2 }).format(number || 0).replace('€', '').trim();
  }


}
