import { Component, OnInit, Input,Output, EventEmitter,OnChanges, SimpleChange } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms'; 
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-create-or-edit-colaborador',
  templateUrl: './create-or-edit-colaborador.component.html',
  styleUrls: ['./create-or-edit-colaborador.component.css']
})
export class CreateOrEditColaboradorComponent implements OnInit {

  @Input() modal: string = "modalCreateOrEdit";
  @Input() title: string = "Registar";
  @Input() colaboradores:any;

  submitted = false;

  validate = 0;

  formErrors: any;
  private loading: boolean = false;
  private ver: boolean = true;
  @Input() simpleFormCreateOrEdit: FormGroup; 

  @Output() private loadList = new EventEmitter<any>();
  
  private departamentos: any = [];
  private funcoes: any = [];
  private empresas: any = [];

  constructor(private http: HttpService, private configService: ConfigService, private formBuilder: FormBuilder) { 
    this.createForm();
  }

  ngOnInit() {
    this.getDepartamento();
  }

  private getDepartamento() {
    this.http.call_get('departamento/selectBox', null).subscribe(
      response => {
        this.departamentos = Object(response).data;
      }
    );
  }

  private validateExterno(){
     this.validate = this.simpleFormCreateOrEdit.value.is_externo
    if(this.validate == 1){
      this.getEmpresa();
    }

  }

  private getEmpresa() {
    this.http.call_get('ordemservico/empresa/selectBox', null).subscribe(
      response => {
        this.empresas = Object(response).data;
      }
    );
  }

  private getFuncaoByDepartamento() {
    this.http.call_get('funcao/funcaoesByDepartamento/'+ this.simpleFormCreateOrEdit.value.departamento_id, null).subscribe(
      response => {
        this.funcoes = Object(response).data;
      }
    );
  }

  createForm() {
    this.simpleFormCreateOrEdit = this.formBuilder.group({
      index: [{ value: null, disabled: true }],
      nome: [null, Validators.required],
      telefone: [null],
      identidade: [null],
      custo: [null],
      departamento_id: [null],
      funcao_id: [null],
      is_externo: [null],
      empresa_id: [null]
    })
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.simpleFormCreateOrEdit.controls;
  }

  onReset() {
    this.submitted = false;
    this.simpleFormCreateOrEdit.reset();
  }


  onSubmit() {

    this.submitted = true;

    // parar aquei se o formulário for inválido
    if (this.simpleFormCreateOrEdit.invalid) {
      return;
    }
    this.loading = true;
    const index = this.simpleFormCreateOrEdit.getRawValue().index;
    // TODO: usado para fazer a requisição com a api de criação de objsct or update
    const uri = (index === null ? 'colaborador/register' : 'colaborador/update/' + index);
    this.createOrEdit(uri, this.simpleFormCreateOrEdit, (index === null ? true : false));

  }

  createOrEdit(uri: any, formulario: FormGroup, isCreate: boolean) {

    // TODO: usado para fazer a requisição com a api de criação de object
    this.http.__call(uri, formulario.value).pipe(first()).subscribe(
      response => {
        this.submitted = false;
        this.loading = false;
        if (isCreate && Object(response).code ==200) {
          formulario.reset(); 
        }  
        
        if (Object(response).code ==200) {
          this.loadList_colaborador(Object(response).data);
        }
      },
      error => {
        this.submitted = false;
        this.loading = false;
      });
  }


  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
     
    if (this.colaboradores !== undefined ) {
      
      this.title = "Editar";
      //this.onReset()
      
      this.simpleFormCreateOrEdit.patchValue({
        index: this.colaboradores.id,
        nome: this.colaboradores.colaborador_nome,
        telefone: this.colaboradores.colaborador_telefone,
        identidade: this.colaboradores.colaborador_identidade,
        custo: this.colaboradores.custo,
        departamento_id: this.colaboradores.departamento_id,
        funcao_id: this.colaboradores.funcao_id,
        is_externo: this.colaboradores.is_externo,
        empresa_id: this.colaboradores.empresa_id
      });

      this.validate = this.colaboradores.is_externo

      if(this.validate == 1){
        this.getEmpresa();
      }
      
      this.getFuncaoByDepartamento();
      
    } else {
      this.onReset()
      this.title = "Registar";
      
    }
  }

  public loadList_colaborador(colaboradores) { 
    this.loadList.emit(colaboradores);
  }





}
