import { FacturaService } from './../factura.service';

import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/providers/http/api.service";
import { ConfigService } from "src/app/providers/config/config.service"; 
 
import { finalize, map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import { Observable } from "rxjs";
@Component({
  selector: 'app-facturacao-automatica',
  templateUrl: './facturacao-automatica.component.html',
  styleUrls: ['./facturacao-automatica.component.css']
})
export class FacturacaoAutomaticaComponent implements OnInit {

  private series:any = [];
  rotas:any = [];

  private series_nc:any = [];
  private loadings = {
    series:false, 
    process: false
  };
  private rota_id:number = null
  private serie_id:number = null

  constructor(
    private configService: ConfigService,
    private api: ApiService,
    private _facturaService: FacturaService
  ) { }

  ngOnInit() {
     this.getSeries('FT');
     this.getSeries_nc('NC')
     this.get_rotas()
  }

  private getSeries(type_doc) {
    this.loadings.series = true;
    this.api.post('serie/selectBoxSeries-activas',{ documentos: [type_doc], status:[false, true] })
    .pipe(finalize(() => {this.loadings.series = false;}))
    .subscribe( response => {  
      this.series = Object(response)
    });
  }

  private getSeries_nc(type_doc) {
    this.loadings.series = true;
    this.api.post('serie/selectBoxSeries',{ documentos: [type_doc], status:[false, true] })
    .pipe(finalize(() => {this.loadings.series = false;}))
    .subscribe( response => {  
      this.series_nc = Object(response)
    });
  }

  get_rotas(){
    this._facturaService.getRotas()
    .subscribe(
      response => {
        this.rotas = response.data.data
      })
  }
 

  _initProcess(){ 
    if(!this.serie_id){
      this.configService.showAlert(`O campo serie para factura é obrigatório`, "alert-error", true);
        return;
    }

    if(!this.rota_id){
      this.configService.showAlert(`Seleccione a Rota, por favor`, "alert-error", true);
        return;
    }

    this.loadings.process = true;
    this.api.post("facturas/automatic",{
      serie_id: this.serie_id, 
      rota_id: this.rota_id
    })
    .pipe(
      finalize(() => {
        this.loadings.process = false;
      })
    )
    .subscribe(res => {
      if (Object(res).code == 200) {
        this.configService.showAlert( Object(res).message, "alert-success", true)
      } else {
        this.configService.showAlert( Object(res).message, "alert-danger", true )
      }
      
    }, error => {
      this.loadings.process = false;
    })
  }

}
