import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthService } from '../../../providers/auth/auth.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigModuloService } from 'src/app/services/config-modulo.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html',
  styleUrls: [
    'login.component.css'],
  encapsulation: ViewEncapsulation.None,
  //providers: [ToasterService]
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;

  private app_environment: null;

  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private configService: ConfigService,
    private config: ConfigModuloService
    //private toastService: ToasterService
  ) {
    if (this.auth.currentUserValue) {
      this.router.navigate(['/']);
    }
  }

  /*public toasterconfig: ToasterConfig =
    new ToasterConfig({
      tapToDismiss: true,
      timeout: 5000
    });*/

  ngOnInit() {
    this.app_environment = this.auth.getAppEnvironment();

    if(this.app_environment == null) {

      var url = require('url');
      var app_url = url.parse(environment.app_url, true);

      this.app_environment = app_url.host;
    }

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard';
  }

  get f() {
    return this.loginForm.controls;
  }

  public autenticate() {

    this.submitted = true;

    if (this.loginForm.invalid) {return }

    this.loading = true;
    this.configService.loaddinStarter('start');
    this.auth.login(this.f.username.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        data => {
          this.router.navigate([this.returnUrl]);
          this.configService.loaddinStarter('stop');
        },
        error => {
          this.configService.loaddinStarter('stop');
          if (error.error.title != undefined) {
            this.configService.showAlert(error.error.title + ", " + error.error.message, "alert-danger", true);
          }
          this.loading = false;
        });
  }




}
