import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as excelprop from "exceljs";
import * as Excel from "exceljs/dist/exceljs.min.js";

@Injectable({
  providedIn: 'root'
})
export class FacturacaoDetalhadaPosPagoService {

  blobType: string = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

  constructor() { }


  public ExcelExportFacturacaoDetalhada( Data:any,filtros,excelFileName){

    var footer = ["UNIG - AT"];
    var header = ["FACTURAÇÃO DETALHADA POS-PAGO"];
    var  keys =[
      { key: 'direccao' ,width: 15, style: { font: { name: 'Calibri' } }},
      { key: 'gestor_conta',width: 20},
      { key: 'numero_cliente',width: 20 },
      { key: 'nome_cliente',width: 45 },
      { key: 'conta' ,width: 16},
      { key: 'id_servico',width: 30},
      { key: 'nome_servico',width: 40},
      { key: 'factura',width: 20},
      { key: 'moeda',width: 20,},
      { key: 'data',width: 17 },
      { key: 'pagos',width: 20},
      { key: 'valor_SemImposto',width: 20,style: { numFmt: '#,##0.00', }},
      { key: 'total',width: 20,style: { numFmt: '#,##0.00', }},
     
    ];

    var  Cols =['Direcção','Gestor Conta','Número Cliente','Nome Cliente','Conta','Chave de Serviço','Nome Serviço','Factura','Moeda','Data Factura','Saldado?','VALOR SEM IMPOSTO','VALOR TOTAL']

      var workbook = new Excel.Workbook();
      workbook.creator = 'Web';
      workbook.lastModifiedBy ='Web';
      workbook.created = new Date();
      workbook.modified = new Date();

      workbook.addWorksheet('FACTURAÇÃO DETALHADA', { views: [{ state: 'frozen', ySplit: 8, xSplit: 3, activeCell: 'C2', showGridLines: true }] })

      var sheet_FT = workbook.getWorksheet(1);

      sheet_FT.addRow(header);
      sheet_FT.getCell('A2').value = "Ano: "+filtros.ano
      sheet_FT.getCell('A3').value = "Mês: "+(filtros.mes == 'T'      || filtros.mes == null      ? 'Todos' : filtros.mes_nome)
      sheet_FT.getCell('A4').value = "Moeda: "+(filtros.moeda == 'T' || filtros.moeda == null ? 'Todas' : filtros.moeda)
      sheet_FT.getCell('A5').value = "Direcção: "+(filtros.direccao == 'T' || filtros.direccao == null ? 'Todas' : filtros.direccao)
      sheet_FT.getCell('A6').value = "Nome Cliente: "+(filtros.cliente == 'T'  || filtros.cliente == null  ? 'Todos' : filtros.cliente)
      sheet_FT.getCell('A7').value = "Gestor Conta: "+(filtros.gestor == 'T'   || filtros.gestor == null   ? 'Todos' : filtros.gestor)
      sheet_FT.getCell('A8').value = "Nome Serviço: "+(filtros.servico == 'T'  || filtros.servico == null ? 'Todos' : filtros.servico)

      sheet_FT.getCell('A1').font = { family: 4,name: 'Calibri', size: 25, bold: true ,underline: true};
      sheet_FT.addRow("");
      sheet_FT.getRow(9).values = Cols;
      sheet_FT.columns = keys;
      sheet_FT.getRow(9).fill = {
        type: 'pattern',
        pattern:'solid',
        fgColor:{ argb:'cccccc' }
        }
      sheet_FT.addRows(Data);
      
      
      let totalRows:number = sheet_FT.rowCount
      //console.log("total number of rows : "+totalRows)
      totalRows = totalRows + 1;
      sheet_FT.getCell('L'+totalRows).value = {formula:'SUM(L7:L'+(totalRows-1)+')'};
      sheet_FT.getCell('M'+totalRows).value = {formula:'SUM(M7:M'+(totalRows-1)+')'};
  
      sheet_FT.eachRow({ includeEmpty: true }, function(row, rowNumber){
        row.eachCell(function(cell, colNumber){
         cell.font = {
           name: 'Arial',
           family: 2,
           bold: false,
           size: 10,
         };
         cell.alignment = {
           vertical: 'middle', horizontal: 'left'
         };
         if (rowNumber <= 9) {
           row.height = 20;
           cell.font = {
             bold: true
           };
          }
          if (rowNumber >= 9 ) {
            
          
            cell.alignment = {
              vertical: 'middle', horizontal: 'center'
            };
            if (rowNumber >= 9  ) {
             
            row.getCell(12).alignment = {
            vertical: 'middle', horizontal: 'right','color': {'argb': 'FFFF6600'}
          };

          row.getCell(13).alignment = {
            vertical: 'middle', horizontal: 'right','color': {'argb': 'FFFF6600'}
          };
        }
           for (var i = 1; i < 14; i++) {
             if (rowNumber == 9 ) {
              cell.font = {
              color:{argb:'FFFFFF'},
              bold: true
              };
               row.height = 25;
               row.getCell(i).fill = {
                 type: 'pattern',
                 pattern:'solid',
                 fgColor:{argb:'0066CC'}
               };
             }
             if (rowNumber == totalRows ) {
              cell.font = {
              bold: true
              };
               row.height = 25;
              
               sheet_FT.getCell('A'+totalRows).value = "TOTAL";
               sheet_FT.getCell('A'+totalRows).font = { family: 3,name: 'Calibri', size: 11, bold: true };
               sheet_FT.getCell('A'+totalRows).alignment = { vertical: 'middle', horizontal: 'center' };
              // sheet_FT.getCell('G'+totalRows).value = total;
               row.getCell(i).fill = {
                 type: 'pattern',
                 pattern:'solid',
                 fgColor:{argb:'CCCCCC'}
               };
             }
             row.getCell(i).border = {
             top: {style:'thin'},
             left: {style:'thin'},
             bottom: {style:'thin'},
             right: {style:'thin'}
           };
         }
        }
       });
      });
      sheet_FT.addRow(footer);

        workbook.xlsx.writeBuffer().then(Data => {
        var blob = new Blob([Data], { type: this.blobType });
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.href = url;
        a.download = excelFileName;
        a.click();
      });
    

  }

}
