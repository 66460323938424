import { Component, OnInit, Input } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { ResumoRecebimentoPorCaixaService } from 'src/app/components/report-at/vendas/resumo-recebimento-por-caixa.service'

@Component({
  selector: 'app-deposito-caixa',
  templateUrl: './deposito-caixa.component.html',
  styleUrls: ['./deposito-caixa.component.css']
})
export class DepositoCaixaComponent implements OnInit {


  

  public caixas_fechados: any = []

  public caixas: any = []

  @Input() depositoCaixaModal: string = "depositoCaixaModal";


  public currentUser: any;

  //private caixas_fechados = [];

  submitted = false;
  simpleDepositoForm: FormGroup;

  validar = true;

  public valorTotalVenda: string;

  private loading = {
    caixa: 'Seleccione o caixa'
  }

  

  constructor(private http: HttpService, private configService: ConfigService, private formBuilder: FormBuilder, private auth: AuthService, private recVenda: ResumoRecebimentoPorCaixaService) {
    this.currentUser = this.auth.currentUserValue;
    this.createForm();
  }

  ngOnInit() {
  }

 
  createForm() {//


    this.simpleDepositoForm = this.formBuilder.group({
      data: [null, Validators.required],
      valor_venda: [null],
      valor_deposito: [null, Validators.required],
      data_deposito: [null, Validators.required],
      referencia_banco: [null, Validators.required],
      observacao: [null],
      banco_id: [null, Validators.required],
      caixas: [null],

    });


  }
  // convenience getter for easy access to form fields
  get f() {
    return this.simpleDepositoForm.controls;
  }

  onReset() {
    this.submitted = false;
    this.simpleDepositoForm.reset();
    this.caixas = []
    this.caixas_fechados = []
  }

  onSubmit() {





    this.submitted = true;
    // parar aquei se o simpleDepositoFormulário for inválido
    if (this.simpleDepositoForm.invalid) {
      return;
    }

    if (this.caixas.length == 0) {
      this.configService.showAlert('Nenhum caixa seleccionado', 'alert-danger', true);
      return;
    }

    this.simpleDepositoForm.patchValue({
      caixas: this.caixas
    });

    const uri = 'caixa/deposito';
    this.createOrEdit(uri, this.simpleDepositoForm, true);

  }

  createOrEdit(uri: any, simpleFormulario: FormGroup, isCreate: boolean) {

    this.configService.loaddinStarter('start');
    // TODO: usado para fazer a requisição com a api de criação de organismo
    this.http.__call(uri, simpleFormulario.value).subscribe(
      res => {

        if (Object(res).code == 200) {
          this.submitted = false;
          if (isCreate) {
            this.caixas = []
            this.caixas_fechados = []
            simpleFormulario.reset();
          }
          this.validar = false;
          this.configService.showAlert(Object(res).message, 'alert-success', true);
        } else {
          this.configService.showAlert(Object(res).message, 'alert-danger', true);
        }
      }
    );
    this.configService.loaddinStarter('stop');
  }

  public getValorVendas(caixa_id) {

    this.http.__call('caixa/totalVendas', { caixa_id: caixa_id }).subscribe(
      response => {
        //(Object(response).data == null ? 0.00 : Object(response).data);
      }
    );

  }



  private getCaixasFechados() {
    this.configService.loaddinStarter('start');
    const data = this.simpleDepositoForm.getRawValue().data;
    this.http.call_get('caixa/selectBoxCaixasFechados/' + data, null).subscribe(
      response => {
        this.caixas = [];
        this.caixas_fechados = Object(response).data
        this.configService.loaddinStarter('stop');
      }
    );
  }

  SeleccionarCaixa(id: number) {

    let validar = 0;
    if (this.caixas.length >= 1) {
      for (let i = 0; i < this.caixas.length; i++) {
        if (this.caixas[i] === id) {
          this.caixas.splice(i, 1);
          validar = 1;
        }
      }
      if (validar == 0) {
        this.caixas.push(id);
      }
    } else {
      this.caixas.push(id);
    }
  }



  getNameUser(name: string){
    var fullName = name.split(' ')
    return fullName[0]+' '+fullName[fullName.length - 1];
  }
  private imprimirResumo(){
    this.recVenda.imprimirResumoRecebimentoPorCaixa(this.simpleDepositoForm.getRawValue().data);
  }



}
