import { map } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/providers/config/config.service';
import { HttpService } from 'src/app/providers/http/http.service';
import { ExcelService } from 'src/app/services/excel.service';
import { ExcelAutoService } from 'src/app/services/excel/excel-auto.service';
import { ReportClienteService } from '../../report-at/relatorios/report-cliente.service';
import * as moment from 'moment';
import { ReportAdiantamentosService } from '../../report-at/relatorios/report-adiantamnetos.service';


@Component({
  selector: 'app-relatorio-contadores',
  templateUrl: './relatorio-contadores.component.html',
  styleUrls: ['./relatorio-contadores.component.css']
})
export class RelatorioContadoresComponent implements OnInit {



  constructor(private http: HttpService,
    private configService: ConfigService,
    private excelService: ExcelService,
    private reportAdiantamento: ReportAdiantamentosService,
    private excelsService: ExcelAutoService
  ) {

  }
  loading: boolean = false;
  disableButton: boolean = true;

  private filter = {
    cliente: null,
    cliente_id: null
  }

  submitted = false;
  public filters = {
    search: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    pagination: {
      perPage: 5,// Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima,
      lastPage: null,
      total: null // defini o total de registo da BD
    },
    filter: null // subelemente do filter
    , is_allexel: false,
    tipo_contador: null,
    estado: null,
    tipo:null
  }

  private localUrl: any;
  private largura: any;
  private altura: any;
  items: any = [];
  direccoes: any = [];
  gestores: any = [];
  generos: any = [];
  tipoclientes: any = [];
  municipios: any = [];

  bairros: any = [];
  estados : any = []
  ruas: any = [];
  tipoidentidades: any = [];
  clientes: any = [];



  ngOnInit() {
    this.getPageFilterData(1),
      this.empresaUser(),
      this.getMunicipio()
  }



  exportAsPDF(): void {
    var file = document.getElementsByClassName("exportAsXLSXCliente")[0]
    this.reportAdiantamento.relatorioAdiantamentos('save', file, this.localUrl);
  }


  private empresaUser() {
    this.configService.loaddinStarter('start');

    this.http.call_get('empresa/empresa-user', null).subscribe(
      response => {
        this.localUrl = Object(response).data.logotipo
        this.altura = Object(response).data.width
        this.largura = Object(response).data.height
        this.configService.loaddinStarter('stop');
      }
    );
  }

  private getMunicipio() {
    this.http.__call('municipio/listagems', null).subscribe(
      response => {
        this.municipios = Object(response).data;
      }
    );
  }

  getEstado(id) {
    this.estados=[]
    this.filters.tipo_contador=null
    this.filters.estado=null
    this.http.__call('get-estado-contador/' + id, null).subscribe(
      response => {
        this.estados = Object(response);
        this.filters.tipo_contador=Object(response).map((item)=>{
           return item.descricao
        })
      }
    );
  }

  private getBairro(id) {

    this.http.call_get('bairro/getBairrosByDistrito/' + id, null).subscribe(
      response => {
        this.bairros = Object(response).data;
      }
    );

  }


  imprimirPDF(): void {
    var file = document.getElementsByClassName("exportAsXLSXCliente")[0];
    this.reportAdiantamento.relatorioAdiantamentos('print', file, this.localUrl);
  }


  setTrue() {
    this.filters.pagination.page = this.filters.pagination.page
    this.filters.is_allexel = true
    this.getTotalDeContadores()
  }

  getPageFilterData(page: number) {
    if (this.filters.pagination.perPage == null) {
      return;
    }
    console.log('page=>',page)
    this.filters.pagination.page = Number.isInteger(page) ? page : 1;
    this.getTotalDeContadores();
  }
    private totais = {
       totals: 0
    }

    private somaTotais() {
      this.totais.totals=0
       this.items.forEach((res)=>{
        this.totais.totals +=res.contador_quantidade
      })

    }
  private getTotalDeContadores() {
    this.configService.loaddinStarter('start');
    this.loading = true;
    this.filters.search = this.filter.cliente;
    this.filters.tipo_contador = this.filters.tipo_contador;
    this.filters.estado = this.filters.estado;
    this.items=[]

    this.http.__call('relatorio/contadores', this.filters).subscribe(
      response => {
        this.filters.pagination.lastPage = Object(response).data.lastPage;
        this.filters.pagination.page = Object(response).data.page;
        this.filters.pagination.total = Object(response).data.total;
        this.filters.pagination.perPage = Object(response).data.perPage;
        this.items = Object(response).data
        this.loading = false;
        if (this.items != 0) {
           this.disableButton = false;
           this.somaTotais()
        }

        this.loading = false;
      }
    );
    this.filters.is_allexel = false
    this.configService.loaddinStarter('stop');
  }

  exportAsXLSX(): void {
    var CurrentDate = new Date();

    var keys = [
      { key: 'estadoContador', width: 30, style: { font: { name: 'Calibri' } } },
      { key: 'contador_quantidade', width: 30, style: { font: { name: 'Calibri' } } },
    ];

    var Cols = [
      'Estado Do Contador',
      'Quantidade'
    ]

    var title = 'Relatório de Contadores'
    var nameFile = "Relatório de Contadores -" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    this.excelsService.excels(this.items, nameFile, this.localUrl, keys, Cols, title, 5, 2, 30, 3)
  }

  view_client = false;
  private getCliente(search: any) {
    search = search.target.value;
    this.view_client = true;
    if (search == '') {
      this.view_client = false;
      this.filter.cliente_id = null;

    }
    this.http.__call('cliente/search-cliente', { start: 1, end: 10, search: search }).subscribe(
      response => {
        this.clientes = Object(response).data.data;
      }
    );
  }

  private setCliente(client: any) {
    this.view_client = false;
    this.filter.cliente_id = client.id,
      this.filter.cliente = client.nome
  }

}
