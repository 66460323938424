import { identifierModuleUrl, ThrowStmt } from '@angular/compiler';
import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild } from '@angular/core';
import { iterateListLike } from '@angular/core/src/change_detection/change_detection_util';
import { FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import { forceClassName } from '@fullcalendar/core/util/dom-manip';
import * as moment from 'moment';
import { ConfigService } from 'src/app/providers/config/config.service';
import { HttpService } from 'src/app/providers/http/http.service';
import { ExcelService } from 'src/app/services/excel.service';
import { ExcelAutoService } from 'src/app/services/excel/excel-auto.service';
import { ReportClienteService } from '../../report-at/relatorios/report-cliente.service';

@Component({
  selector: 'app-confi-tipo-facturacao',
  templateUrl: './confi-tipo-facturacao.component.html',
  styleUrls: ['./confi-tipo-facturacao.component.css']
})
export class ConfiTipoFacturacaoComponent implements OnInit, OnDestroy {


  loading: boolean = true;
  disableButton: boolean = true;
  @ViewChild("clear") clears:ElementRef

  submitted = false;
  public filters = {
    search: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    pagination: {
      perPage: 5,// Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima,
      lastPage: null,
      total: null // defini o total de registo da BD
    },
    filter: null // subelemente do filter
    ,is_allexel:false
  }

  private tipo_consumo:any={}
  private IfIsCheckedArray:any=[]
  private localUrl: any;
  private largura: any;
  private altura: any;
  items: any = [];
  produtoSelected: any = [];
  estados: any = [];
  produtos: any = [];
  simpleFormCliente: FormGroup
  check:boolean=false
  frequencyCounter:any={}
  constructor(private http: HttpService,
    private configService: ConfigService,
     private excelService: ExcelService,
     private reportClientes: ReportClienteService,
     private excelsService: ExcelAutoService,
     private _formBuilder: FormBuilder,
     ) {
      this.createForm()
  }

  ngOnInit() {
    this.empresaUser()
    this.getList()
    this.getPageFilterData(1);
    this.getEstado()
    this.getProduto()
    this.getPageFilterDataPage(1)
  }
  ngOnDestroy(){
    this.http.filters.orderBy = null
  }

  createForm() {
    this.simpleFormCliente = this._formBuilder.group({
      id: null,
      descricao: [null, [Validators.required]],
      flag: [null, [Validators.required]],
      tipo_consumo: [],
      estado_id: null
    })
  }
  get f() {
    return this.simpleFormCliente.controls;
  }
  exportAsPDF(): void {
    var file = document.getElementsByClassName("exportAsXLSXCliente")[0]
    this.reportClientes.relatorioClientes('save', file,this.localUrl);

  }
    removeProduto(value) {
        const index = this.produtoSelected.indexOf(value)
        this.produtoSelected.splice(index, 1);
    }

   pushProduto(id){

    if(this.frequencyCounter[id]){
      this.removeProduto(id)
      delete this.frequencyCounter[id]
    }else{
      this.produtoSelected.push(id)
      this.frequencyCounter[id]=id
    }

   }

  imprimirPDF(): void {
    var file = document.getElementsByClassName("exportAsXLSXCliente")[0];
    this.reportClientes.relatorioClientes('print', file,this.localUrl);
  }
  private getProduto() {
    this.http.call_get('produtos/listar',null).subscribe(
      response => {
        this.produtos = Object(response).data
      }
    );
  }
  private empresaUser() {
    this.configService.loaddinStarter('start');

    this.http.call_get('empresa/empresa-user', null).subscribe(
      response => {
        this.localUrl = Object(response).data.logotipo
        this.altura = Object(response).data.width
        this.largura = Object(response).data.height
        this.configService.loaddinStarter('stop');
      }
    );
  }
  private getEstado(){
    this.http.__call('comunicacoes/estado_listagem',null).subscribe(
      response => {
        this.estados = Object(response).data;
      })
  }

  setTrue(){
    this.filters.pagination.page=this.filters.pagination.page
    this.filters.is_allexel=true
    this.getList()
  }
  private getList() {
    this.items=[]
    this.loading = true
    this.http.__call('list_tipo_facturacao',this.filters).subscribe(
      response => {
        this.filters.pagination.lastPage = Object(response).data.lastPage;
        this.filters.pagination.page = Object(response).data.page;
        this.filters.pagination.total = Object(response).data.total;
        this.filters.pagination.perPage = Object(response).data.perPage;
        this.items = Object(response).data.data;
        this.loading = false;
        if (this.items != 0) {
          this.disableButton = false;
        }

        this.filters.is_allexel=false
        this.loading = false;
      }
    );
  }

  exportAsXLSX(data:any):void {
  }

  onSubmit() {

    this.submitted = true
    if(this.simpleFormCliente.value.id==null){
       this.simpleFormCliente.patchValue({
      estado_id:1,
      tipo_consumo:this.produtoSelected
    })
    }else{
          this.produtoSelected=[]
          var file = this.clears.nativeElement.children
        for(let item of file){
            if(item.children[0].checked){
              this.produtoSelected.push(item.children[0].value)
            }
        }
        console.log('edite',this.produtoSelected)
    }
    this.simpleFormCliente.patchValue({
      tipo_consumo:this.produtoSelected
    })
    if (this.simpleFormCliente.invalid) { return }
    let cliente_value = this.simpleFormCliente.value
    let url_action = cliente_value.id ? `config_tipo_facturacao/${cliente_value.id}` : 'config_tipo_facturacao'
    this._createOrUpdateCliente(url_action, cliente_value)
  }
  _createOrUpdateCliente(url, cliente) {
    this.http.__call(url, cliente)
      .subscribe(
        response => {

          this.submitted = false
          this.loading = false

          this.getList();
          this.configService.showAlert(Object(response).message, 'success', true)
          this.getPageFilterData(1)
          console.log("delete_object",this.frequencyCounter)
          if(this.simpleFormCliente.value.id==null){
             this.onReset()
            }
        },
        error => {
          this.configService.showAlert("Alguma coisa correu mal", 'warning', true);
          this.submitted = false;
        }

      )
    this.loading = false
  }

  iniCliente(cliente) {
    this.tipo_consumo={}
    let data=JSON.parse(cliente?cliente.tipo_consumo:null)?
                        JSON.parse(cliente?cliente.tipo_consumo:null).data:null
    for (const iterator of data) {
      this.tipo_consumo[iterator]=iterator
    }
    console.log('tipo_consumo',this.tipo_consumo)
    this.simpleFormCliente.patchValue({
      id:cliente.id,
      descricao:cliente.descricao,
      estado_id:cliente.estado,
      flag:cliente.flag.replace(/\_+/g, ' ')

    })
    this.CheckTrue()
  }

  _closeModal(closeModal) {
    var action = document.getElementById(closeModal)
    action.click()
  }
  clearCheck(){
    var file = this.clears.nativeElement.children
    for(let item of file){
      if(item.children[0].checked){
        item.children[0].checked = false;
      }
    }
  }

  CheckTrue(){
    var file = this.clears.nativeElement.children
    for(let item  of file){
      this.IfIsCheckedArray.push(item)
     if(this.tipo_consumo[item.children[0].value]){
        item.children[0].checked = true;
      }
     }
  }
  onReset() {
    this.submitted = false;
    this.simpleFormCliente.reset();
    this.produtoSelected=[]
    this.frequencyCounter={}
    this.clearCheck()
    // file
  }


  getPageFilterData(page: number) {
     if (this.filters.pagination.perPage == null) {
       return;
     }
     this.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
     this.getList();
   }
  getPageFilterDataPage(page: number) {
     if (this.filters.pagination.perPage == null) {
       return;
     }
     this.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
     this.getList();
   }



}
