import { Cliente } from './../../../models/cliente/cliente.model';
import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { ConfigModuloService } from 'src/app/services/config-modulo.service';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { RescisaoService } from "src/app/components/reports/rescisao/rescisao.service";
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';

import { ContratoService } from "src/app/components/reports/contrato/contrato.service";

import { Router } from '@angular/router';

import { ReportClienteService } from 'src/app/components/report-at/relatorios/report-cliente.service';
import { ExcelAutoService } from 'src/app/services/excel/excel-auto.service';
import * as moment from 'moment';
import { ClienteService } from './cliente.service';
import { PedidoReportService } from '../../report-at/pedido/pedido-report.service';
import { GestorContaService } from '../gestores-contas/gestor-conta.service';
import { GeneroService } from '../generos/genero.service';
import { DireccaoService } from '../../direccao/direccao.service';
import { TipoIdentificacaoService } from '../tipo-de-identificacao/tipo-de-identificacao.service';
import { ContratoLundasulService } from '../../reports/contrato-lundasul/contrato-lundasul.service';
import { ContratoLundanorteService } from '../../reports/contrato-lundanorte/contrato-lundanorte.service';
import { RescisaoLundanorteService } from '../../reports/rescisao-lundanorte/rescisao-lundanorte.service';
import { finalize } from 'rxjs/operators';
import { ApiService } from 'src/app/providers/http/api.service';
import { toDate, format } from 'date-fns-tz'
import Swal from 'sweetalert2';
import { RotaService } from '../../config-rotas/rotas.service';

@Component({
    selector: 'app-terceiro',
    templateUrl: './terceiro.component.html',
    styleUrls: ['./terceiro.component.css']
})
export class TerceiroComponent implements OnInit {


    public currentUser: any;

    @ViewChild("orderBy") orderBy;
    @ViewChild("searchData") searchData;
    @ViewChild("mostrarResultado") mostrarResultado;
    @ViewChild("orderAscOrDesc") orderAscOrDesc;
    @ViewChild('closebutton') closebutton;


    childTitle: string = 'This text is passed to child';
    loading: boolean = false;
    submitted: boolean = false;
    tipo_cliente_view: boolean = false;

    private gestor_cliente_view: boolean = false;
    private direccao_view: boolean = false;

    private clienteEdit: any;
    private disableButton: boolean = false
    private contador_title: string = "Associar";
    private contadores: any = [];
    cliente = {
        id: null,
        nome: null,
        telefone: null,
        morada: null,
        numero_identidade: null,
        tipo_identidade_id: null,
        tipo_cliente_id: null,
        email: null,
        nif: null,
        provincia_id: null,
        provincia: null,
        municipio_id: null,
        municipio: null,
        genero_id: null,
        genero: null,

        conta_id: null,
        numero_conta: null,
        direccao_id: null,
        direccao: null,

        tipo_identidade: null,
        numero_digitos: null,
        numero_identificacao: null,
        tipo_cliente: null,

        gestor_cliente_id: null,
        gestor_cliente: null,
        gestor_cliente_telefone: null
    }

    private totalDivida: any = 0

    private contrato = {
        id: null,
        tipo_contracto_id: null,
        tipo_contrato: null,
        tipo_medicao_id: null,
        tipo_medicao: null,
        tipo_medicao_slug: null,
        tipo_facturacao_id: null,
        tipo_facturacao: null,
        tipologia_cliente_id: null,
        tipologia_cliente: null,
        tipologia_cliente_juro_mora: null,
        tipologia_cliente_sujeito_corte: null,
        tipologia_cliente_caucao: null,
        nivel_sensibilidade_id: null,
        nivel_sensibilidade: null,
        objecto_contrato_id: null,
        objecto_contrato: null,
        tarifario: null,
        classe_tarifario: null,
        classe_tarifario_consumo_minimo: null,
        classe_tarifario_consumo_maximo: null,
        numero_habitantes: null,
        data_inicio: null,
        data_fim: null,
        morada_correspondencia_flag: false,
        estado_contrato_id: null,
        estado_contrato: null,
        estado_contrato_slug: null,

        data_rescisao: null,
        motivo_recisao_id: null,
        estado_rescisao_id: null,
        motivo_recisao: null,
        motivo_recisao_flag: null,

        contador_id: null,
        numero_serie: null,

        instalacao_sanitaria_qtd: null,
        reservatorio_flag: null,
        reservatorio_capacidade: null,
        piscina_flag: null,
        piscina_capacidade: null,
        jardim_flag: null,
        campo_jardim_id: null,
        campo_jardim: null,
        poco_alternativo_flag: null,
        fossa_flag: null,
        fossa_capacidade: null,
        acesso_camiao_flag: null,
        anexo_flag: null,
        anexo_quantidade: null,
        caixa_contador_flag: null,
        abastecimento_cil_id: null,
        abastecimento_cil: null,

    };


    private motivo_rescisao_id: null;
    disableEstado: boolean = false
    private local_consumo: any;
    private contador_join: boolean = true;
    /*   private contador = {
          marca: null,
          modelo: null,
          numero_serie: null,
          precisao: null,
          medicao: null,
          calibre: null,
          leitura: null,
          data_leitura: null,
          leitura_origem: null,
      } */


    private contador = {
        contador_antigo: null,
        id: null,
        numero_serie: null,
        marca: null,
        ultima_leitura: null,
        modelo: null,
        data_leitura: null,
        tipo_contador: null,
        classe_precisao: null,
        medicao: null,
        calibre: null,
        digitos: null,
        fabricante: null,
        centro_distribuicao: null,
        precisao: null,
        leitura: null,
        leitura_origem: null,
        contrato_id: null,
        contador_id: null,
        is_leitura_inicial: false,
        new_leitura_by_contrato: false,
        observacao: null
    }

    private localConsumoInfo = {
        municipio: null,
        distritoComuna: null,
        bairro: null,
        rua: null,
        residenciaNome: null,
        residenciaNumero: null
    }
    private isAlterarMedicao: boolean = false;
    private servico = {
        id: null,
        conta_id: null,
        numero_conta: null,
        tipo_facturacao_id: null,
        tipo_facturacao: null,

        chaveServico: null,
        tarifario_id: null,
        contrato_id: null,
        tipoServico: null,
        estado: null,
        estado_actual: null,
        estado_actual_id: null,
        dataEstado: null,
        tecnologia: null,
        wimax_id: null,
        numero_serie: null,
        imsi_sim: null,
        serie_sim: null,
        cdma_equipamento_id: null,
        sim_card_id: null
    }

    private cdmaServico = {
        cdma_numero: null,
        numero_serie: null,
        fabricante: null,
        modelo: null
    }

    private LTECPES = {
        id: null,
        numero_serie: null,
        fabricante: null,
        modelo: null,
        tipo: null,
        numero_telefone: null,
        serie_sim: null,
        imsi_sim: null,
        sim_card_id: null
    }

    private pedido = {
        id: null,
        cliente_id: null,
        tipoPedido: null,
        observacao: null,
        dataPedido: null,
        telefone: null,
        tarifario: null,
        capacidade: null,
        clienteNome: null,
        conta_id: null,
        origem: null,
        destino: null
    }

    private localInstalacao = {
        id: null,
        municipio: null,
        distritoComuna: null,
        bairro: null,
        rua: null,
        residenciaNumero: null,
        residenciaNome: null
    }

    private search: string;
    private dividas: any = []
    private mudanca = {
        chave_servico: null,
        cliente_nome: null,
        conta_id: null,
        cliente_search: null,
        mudanca_tipo: null,
        servico_id: null,
        conta_antiga: null
    }

    private reclamacao = {
        id: null,
        tipo_reclamacao_id: null,
        prioridade_id: null,
        cliente_id: null,
        observacao: null,

    }
    private view_serie_contador: boolean = false;
    private contacto = {
        id: null,
        cliente_id: null,
        nome: null,
        tipo_contacto: null,
        telefone: null,
        email: null
    }

    private pagination = {
        total: null,
        perPage: 5,
        page: 1,
        lastPage: null,

        start: 1,
        end: 10,
        search: null,
        order: "created_at"
    };

    private filtroServicos = {
        search: null, // ordem de pesquisa de elemento
        orderBy: null, // Ordenação
        pagination: {
            perPage: 5,// Entrada - define o limite de resultados a serem gerados
            page: 1, //  define a pagina inicial ou proxima,
            lastPage: null,
            total: null // defini o total de registo da BD
        },
        filter: null // subelemente do filter
    }

    private filtroLocalConsumo = {
        search: null, // ordem de pesquisa de elemento
        orderBy: null, // Ordenação
        pagination: {
            perPage: 5,// Entrada - define o limite de resultados a serem gerados
            page: 1, //  define a pagina inicial ou proxima,
            lastPage: null,
            total: null // defini o total de registo da BD
        },
        filter: null // subelemente do filter
    }


    private conta = {
        id: null,
        contrato_id: null,
        cliente_id: null,
        numero_conta: null,
        descricao: " ",
        agencia_id: null,
        moeda_id: null,
        estado: null,
        tipo_facturacao_id: null,
        dataEstado: null,
        estado_actual: null
    }

    private rotaForm = {
        id: null
    }

    private items: any = [];
    private itemsAll: any = [];
    private localConsumos: any = [];
    private distritos: any = [];
    private quarteiraos: any = [];
    private bairros: any = [];
    public estado: any = []

    private rescisao_saved: boolean = false;
    private predio_flag: boolean = false;
    private moradia_title: string = "Moradia";


    private facturacao = {
        cliente: {
            id: null,
            nome: null,
            contribuinte: null,
            morada: null,
            telefone: null,
            conta_id: null,
            contrato_id: null,
            genero: null,
            email: null,
            tipo_cliente_id: null,
            tipo_cliente: null,
            tipo_cliente_slug: null,
            documento_id: null,
            gestor_cliente_id: null,
            servico_id: null,
        },
        serie: {
            id: null,
            sigla: null,
            nome: null,
            proximo_numero: null,
            documento_id: null,
            documento: null,
            movimento: null,
            tipo_movimento: null

        },
        produto: [],
        servicos: [],
        pagamento: {
            forma: null,
            valor_recebido: 0.0,
            total_valor_recebido: 0.0,
            troco: 0.0,
            total_pago: null,
            referencia: null,
            data_pagamento: null,
            forma_pagamento_id: null,
            bancos: [],
            adiantamentos: [],
            is_adiantamento: 1,
            linha_pagamentos: [],
        },
        totalComImposto: 0.0,
        totalSemImposto: 0.0,
        total: 0.0,
        success_facturacao: false,
        facturaGerada: null,
        data_vencimento: moment(Date()).format("YYYY-MM-DD").toString(),
        moeda: null,
        total_com_imposto: 0.0,
        total_sem_imposto: 0.0,
        valor_total_imposto: 0.0,

        total_facturar: 0.0,

        divida_cliente: 0,
        divida_local_consumo: 0,
        divida_total: 0,

        processado: false,
    };
    contratos: any = []
    _clientes: any = []
    view_client: boolean = true;
    contas_clientes: any = []
    private residencia = {
        id: null,
        numero: null,
        is_predio: null,
        predio_nome: null,
        predio_andar: null,
        nome: null,
        rua_id: null,
        rua: null,
        quarteirao_id: null,
        quarteirao: null,
        has_quarteirao: null,
        bairro_id: null,
        bairro: null,
        has_distrito: false,
        municipio_id: null,
        distrito_id: null,
        provincia_id: null,
        is_active: null,
        user_id: null

    };

    private prioridades: any = [];
    private tipos: any = [];
    private _tipos: any = [];
    private reclamacoes: any = [];
    private ruas: any[];

    private contas: any = [];
    private clienteContas: any = [];
    private contactos: any = [];
    private tarifarios: any = [];

    private contratos_conta: any = [];
    private motivo_rescisaos: any = [];
    private estado_rescisaos: any = [];
    is_contrato_id: boolean = false
    private chaveServicos: any = [];
    private chaves: any = [];
    private lojas: any = [];
    private carregamentos: any = [];
    private pedidos: any = [];
    private tipoPedidos: any = [];
    private direccoes: any = [];
    wimaxMessage: string = null;
    classMessage: string = null;
    cdmaMessage: string = null;
    cdmaClassMessage: string = null;
    lteClassMessage: string = null;
    private lteMessage: string = null;
    private isFinalizar: boolean = true;
    gestores: any = []

    private filter = {
        telefone: null,
        numero_identidade: null,
        email: null,
        nif: null,
        genero: null,
        direccao: null,
        municipio_id: null,
        estado: null,
        tipo_cliente_id: null,
    }
    private EditarPedido = {
        pedidoId: null,
        tipoPedido: null,
        tarifario: null,
        telefone: null,
        client_id: null
    }
    // variavel de filtração de dados
    public filters = {
        search: null, // ordem de pesquisa de elemento
        keyFilter: 'nome_cliente',
        orderBy: null, // Ordenação
        pagination: {
            perPage: 5,// Entrada - define o limite de resultados a serem gerados
            page: 1, //  define a pagina inicial ou proxima,
            lastPage: null,
            total: null // defini o total de registo da BD
        },
        filter: null // subelemente do filter
        , is_allexel: false
    }

    private empresa: any;
    public simpleFormNovaLigacao: FormGroup
    private localUrl: any;
    private sigla_empresa: any;
    private largura: any;
    private altura: any;

    tipos_identidades: any = []
    tipo_clientes: any = []
    generos: any = []
    tipo_identidades: any = []
    provincias: any = []
    municipios: any = []

    simpleFormCliente: FormGroup
    formErrors: any

    default_id_province: number
    default_id_tipo_identificacao: number

    cliente_tipo_identidade: any = {
        nome: null,
        lista_identidades: []
    }

    cliente_identidade: any = {
        id: null,
        cliente_id: null,
        tipo_identidade_id: null,
        numero_identidade: null,
        numero_digitos: null
    }
    loadings = {
        municipios: false
    }

    constructor(
        private auth: AuthService,
        private http: HttpService,
        private configService: ConfigService,
        private PedidoReportService: PedidoReportService,
        private excelService: ExcelAutoService,
        private _route: Router,
        private reportCliente: ReportClienteService,
        private config: ConfigModuloService,
        private _gestor_conta_idService: GestorContaService,
        private reportRescisao: RescisaoService,
        private reportRescisaolundanorte: RescisaoLundanorteService,
        private _formBuilder: FormBuilder,

        private _generoService: GeneroService,
        private _gestorContaService: GestorContaService,
        private _direccaoService: DireccaoService,
        private _tipoIdentidadeService: TipoIdentificacaoService,
        private _clienteService: ClienteService,
        private _rotaService: RotaService,
        private contratoService: ContratoService,
        private contratoLundasulService: ContratoLundasulService,
        private contratoLundanorteService: ContratoLundanorteService,
        private api: ApiService,
    ) {
        this.currentUser = this.auth.currentUserValue;
        this.createForm()
        this.createFormNovaLigacao()
        if (this.gestor_cliente_view) {
            this._gestor_conta_idService.getGestoresContas()
                .subscribe((response) => {
                    this.gestores = response.data
                });
        }

        this._loadingDependences()
    }

    ngOnInit() {
        this.resetName('')
        this.getPageFilterData(1);
        this.getConfiguracaos();
        this.empresaUser()
        this.getMunicipios()
        this.getTipo()
    }

    private getMunicipios() {
        this.distritos = [];
        this.loadings.municipios = true;
        this.api.get('municipio/selectBox')
            .pipe(finalize(() => { this.loadings.municipios = false; }))
            .subscribe(response => { this.municipios = Object(response).data; }, error => { this.loadings.municipios = false; });
    }


    private getContratoID() {

        if (this.simpleFormNovaLigacao.value.contrato_id != null) {
            this.is_contrato_id = true
            this.getContratosByConta(this.simpleFormNovaLigacao.value.contrato_id,);
            this.cliente.nome = this.simpleFormNovaLigacao.value.cliente;
        } else {
            this.is_contrato_id = false;
        }

    }

    setDisableButton(value) {
        this.disableButton = value
    }

    _loadingDependences() {
        /*        this._tipoIdentidadeService.getTiposIdentificacao()
                    .subscribe((response) => {
                        let default_tipo_identidade = response.data.find(obj => obj.nome == 'NIF')
                        let def_id = default_tipo_identidade.id
                        this.cliente.tipo_identidade_id = def_id

                        this.default_id_tipo_identificacao = def_id
                        this.simpleFormCliente.patchValue({ tipo_identidade_id: def_id })
                        this.tipos_identidades = response.data
                    })
        */
        this._direccaoService.getDireccoes()
            .subscribe((response) => {
                this.direccoes = response.data
            })

        this._generoService.getGenres()
            .subscribe((response) => {
                this.generos = response.data.data
            })

        this._gestorContaService.getGestoresContas()
            .subscribe((response) => {
                this.gestores = response.data
            })

        this.http.call_get('tipo-cliente/selectBox', null)
            .subscribe(
                response => {
                    this.tipo_clientes = Object(response);
                }
            )

        this.http.call_get('provincia/selectBox', null)
            .subscribe(
                response => {
                    this.provincias = Object(response).data
                }
            )
    }

    private getTipoIdentidades() {
        this._tipoIdentidadeService.getTiposIdentificacao()
            .subscribe((response) => {
                let default_tipo_identidade = response.data.find(obj => obj.nome == 'NIF')
                let def_id = default_tipo_identidade.id
                this.cliente.tipo_identidade_id = def_id

                this.default_id_tipo_identificacao = def_id
                this.simpleFormCliente.patchValue({ tipo_identidade_id: def_id })
                this.tipos_identidades = response.data
            })
    }

    createForm() {
        this.simpleFormCliente = this._formBuilder.group({
            id: null,
            telefone: null,
            telefone2: null,
            banco: null,
            conta_bancaria: null,
            email: null,
            nif: null,
            nome: ['', [Validators.required]],
            is_active: ['', [Validators.required]],
            morada: ['', [Validators.required]],
            tipo_identidade_id: null, //['', [Validators.required]],
            numero_identificacao: null, //['', RxwebValidators.required({conditionalExpression:(x) => x.tipo_identidade_id != 19})],
            genero_id: ['', [Validators.required]],
            tipo_cliente_id: ['', [Validators.required]],
            provincia_id: ['', [Validators.required]],
            municipio_id: ['', [Validators.required]],
            distrito_id: [''],
            bairro_id: ['', [Validators.required]]
        })
    }

    DateTimeZone() {
        const event = new Date();
        const parisDate = toDate(event.toISOString())
        const pattern = "yyyy-MM-dd'T'HH:mm"
        const timeOutput = format(parisDate, pattern, { timeZone: 'UTC/GMT+1' })
        return timeOutput.slice(0, 16);
    }

    createFormNovaLigacao() {

        this.simpleFormNovaLigacao = this._formBuilder.group({
            id: null,
            historico_id: null,
            cliente: [null, [Validators.required]],
            cliente_id: [null],
            cliente_telefone: [null],
            estado_id: [null, [this.matchValidator.bind(this)]],
            estado_orcamento_id: [null],
            descricao: ['',
                [this.matchValidator.bind(this)]
            ],
            data: [this.DateTimeZone(), [Validators.required]],
            time: this.DateTimeZone(),
            conta: [null],
            contrato_id: [null],
            hostory_id: null,
            tipo_ligacao_id: [null],
            motivo: [null, [this.matchValidator.bind(this)]],
            conta_id: [null],
            orcamento_id: null,
            factura_id: null,
        })
    }


    matchValidator(control: AbstractControl): { [key: string]: boolean } | null {
        const fromValue = control.value;
        if (fromValue) {

            const estado = this.simpleFormNovaLigacao.controls.estado_id.value

            if (estado != 'null') {
                return null;
            }
            return { invalidDate: true };
        }
    }

    _initIdentidade(id) {

        //let tipos_names = this.cliente_tipo_identidade.lista_identidades.map(obj => obj.nome)
        //this.tipos_identidades = this.tipos_identidades.filter(obj => !(tipos_names.includes(obj.nome)))

        this.cliente_identidade.cliente_id = this.cliente.id;

        this._tipoIdentidadeService.getTiposIdentificacao()
            .subscribe((response) => {
                this.tipos_identidades = response.data.data
            });

        this._clienteService.getIdentidades(id)
            .subscribe((response) => {

                this.cliente_tipo_identidade.lista_identidades = response.data
            });


        //this.getTipoIdentidades();
    }


    _resetIdentidade() {

        this.cliente_identidade = {
            id: null,
            cliente_id: null,
            tipo_identidade_id: null,
            numero_identidade: null,
            numero_digitos: null
        }

    }

    _initUpdateTipoIdentidade(entidade) {
        this.cliente_identidade = entidade
    }

    deleteTipoIdentidade(id) {
        this._clienteService.DeleteTipoIdentidade(id)
            .subscribe((response) => {

                this._initIdentidade(id)
            })
    }


    saveClienteIdentidade() {

        //console.log(this.cliente_tipo_identidade.lista_identidades)
        //console.log(this.tipos_identidades)

        let tipo = this.tipos_identidades.find(tipo => tipo.id == this.cliente_identidade.tipo_identidade_id)
        /*
                if (Number(tipo.numero_digitos) > this.cliente_identidade.numero_identidade.length) {
                    this.configService.showAlert(`O tipo de Identidade ${tipo.nome}, têm de ter no mínimo ${tipo.numero_digitos} digitos`, 'alert-danger', true);
                    return
                }
        */


        this.cliente_identidade.cliente_id = this.simpleFormCliente['value']['id'];

        if (!this.cliente_identidade.tipo_identidade_id) {
            this.configService.showAlert(`O tipo de Identidade não foi seleccionado!`, 'alert-danger', true);
            return
        }
        if (!this.cliente_identidade.numero_identidade) {
            this.configService.showAlert(`O número de Identificação não foi inserido!`, 'alert-danger', true);
            return
        }

        //this.cliente_identidade.tipo_identidade_id = this.tipo_identidades[0];

        if (this.cliente_identidade.id) {
            this._clienteService.UpdateTipoIdentidade(this.cliente_identidade)
                .subscribe((response) => {
                    if (Object(response).code == 200) {
                        this._closeModal('closeModalAssociateTipoIdentidade')

                        this._initIdentidade(this.simpleFormCliente['value']['id'])
                        this._resetIdentidade();
                    }
                })
        } else {
            this._clienteService.CreateTipoIdentidade(this.cliente_identidade)
                .subscribe((response) => {
                    if (Object(response).code == 200) {
                        this._closeModal('closeModalAssociateTipoIdentidade')

                        this.cliente_identidade.numero_identidade = null;

                        this._initIdentidade(this.simpleFormCliente['value']['id'])
                        this._resetIdentidade();
                    }

                })
        }

    }

    get f() { return this.simpleFormCliente.controls; }
    get g() { return this.simpleFormNovaLigacao.controls; }


    onReset() {
        this.submitted = false;
        this.simpleFormCliente.reset();
    }

    onSubmit() {
        this.submitted = true
        if (this.simpleFormCliente.invalid) { return }
        let cliente_value = this.simpleFormCliente.value
        let url_action = cliente_value.id ? `cliente/update/${cliente_value.id}` : 'cliente/register'
        this._createOrUpdateCliente(url_action, cliente_value)
        this.setDisableButton(true)
    }

    _createOrUpdateCliente(url, cliente) {
        this.http.__call(url, cliente)
            .subscribe(
                response => {
                    if (Object(response).code == 200) {
                        this.submitted = false
                        this.loading = false

                        this.configService.showAlert(Object(response).message, 'success', true)
                        this.getPageFilterData(1)
                        this._closeModal('closeModalCliente')
                        this.simpleFormCliente.reset()
                        this.after_reset()
                        this._closeModal('register');

                    } else {
                        this.configService.showAlert(Object(response).message, 'success', true)
                    }

                },
                error => {
                    this.configService.showAlert("Alguma coisa correu mal", 'warning', true);
                    this.submitted = false;
                }
            )
        this.loading = false


    }

    onResetNovaLigacao() {
        this.submitted = false;
        this.simpleFormNovaLigacao.reset();
    }
    onSubmitNovaLigacao() {

        this.submitted = true
        if (this.simpleFormNovaLigacao.value.id == null) {
            this.simpleFormNovaLigacao.patchValue({
                estado_id: 1
            })
        }

        if (this.simpleFormNovaLigacao.invalid) { return }
        let cliente_value = this.simpleFormNovaLigacao.getRawValue()

        let create_registo = 'nova-ligacao/ligacao'

        let url_action = null
        /* if (this.flag == 1) {
          url_action = add_registo
        } else { }*/
        url_action = create_registo


        this._createNovaLigacao(url_action, cliente_value)
    }

    changeValueOfMotivoOption(value) {
        if (value == null || value == 'null') {
            this.disableEstado = false
            this.simpleFormCliente.controls.estado_id.disable();
        } else {
            this.simpleFormCliente.controls.estado_id.enable();
        }
    }
    _createNovaLigacao(url, cliente) {
        this.http.__call(url, cliente)
            .subscribe(
                response => {
                    if (Object(response).code == 200) {
                        this.submitted = false
                        this.loading = false

                        this.configService.showAlert(Object(response).message, 'success', true)
                        this.simpleFormNovaLigacao.reset()
                        this.after_reset()
                        this._closeModal('register');

                    } else {
                        this.configService.showAlert(Object(response).message, 'success', true)
                    }

                },
                error => {
                    this.configService.showAlert("Alguma coisa correu mal", 'warning', true);
                    this.submitted = false;
                }
            )
        this.loading = false


    }

    iniCliente(cliente) {


        this.simpleFormCliente.patchValue(cliente)
        this.setCliente(cliente)
        console.log('cliente', cliente)

        this.cliente = cliente;

        this.selectBoxBairrosByMunicipio(null);
    }

    _closeModal(closeModal) {
        var action = document.getElementById(closeModal)
        action.click()
    }

    after_reset() {
        this.simpleFormCliente.patchValue({
            provincia_id: this.default_id_province,
            tipo_identidade_id: this.default_id_tipo_identificacao
        })
    }
    resetName(nome) {
        var newNome = nome.toLowerCase();
        newNome = newNome.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        newNome = newNome.toUpperCase();
        this.simpleFormCliente.patchValue({
            nome: newNome
        })
    }





    //=====================================================================================
    //=====================================================================================


    exportAsPDF(): void {
        // this.reportCliente.relatorioClientes(this.items, 'save');
    }

    imprimirPDF(): void {
        // this.reportCliente.relatorioClientes(this.items);
    }

    private empresaUser() {
        this.configService.loaddinStarter('start');

        this.http.call_get('empresa/empresa-user', null).subscribe(
            response => {
                this.localUrl = Object(response).data.logotipo
                this.altura = Object(response).data.width
                this.largura = Object(response).data.height
                this.configService.loaddinStarter('stop');

                this.sigla_empresa = Object(response).data.sigla_empresa
                this.empresa = Object(response);
            }
        );
    }

    setTrue() {
        this.filters.pagination.page = Number.isInteger(this.filters.pagination.page) ? this.filters.pagination.page : 1;
        this.filters.is_allexel = true
        this.listClientes()
    }

    private listClientes() {
        this.loading = true
        this.filters.filter = this.filter;
        this.http.__call('cliente/listagem', this.filters)
            .subscribe(
                response => {
                    this.filters.pagination.lastPage = Object(response).data.lastPage;
                    this.filters.pagination.page = Object(response).data.page;
                    this.filters.pagination.total = Object(response).data.total;
                    this.filters.pagination.perPage = Object(response).data.perPage;
                    if (this.filters.is_allexel == false) {
                        this.items = Object(response).data.data;

                    } else {
                        this.itemsAll = Object(response).data.data;
                        this.exportAsXLSX(this.itemsAll)
                    }
                    this.filters.is_allexel = false
                    this.loading = false;
                }
            );
    }


    exportAsXLSX(data: any): void {
        var CurrentDate = new Date();
        var keys = [
            { key: 'id', width: 40, style: { font: { name: 'Calibri' } } },
            { key: 'nome', width: 50 },
            { key: 'genero', width: 30 },
            { key: 'numero_identificacao', width: 40, style: { font: { name: 'Calibri' } } },
            { key: 'tipo_cliente', width: 50 },
            { key: 'telefone', width: 30 },
            { key: 'municipio_nome', width: 40, style: { font: { name: 'Calibri' } } },
            { key: 'morada', width: 50 },
        ];

        var Cols = ['ID', 'Nome', 'Genero', 'Identificação', 'Tipo Cliente', 'Telefone', 'Município', 'Morada']
        var title = 'LISTA DE CLIENTES'
        var nameFile = "Lista_de_clientes -" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
            + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
        this.excelService.excels(data, nameFile, this.localUrl, keys, Cols, title, 5, 8, 40, 3)
    }

    private viewContador(id: number) {
        this.http.__call('contador/viewinfo/' + id, null).subscribe(
            response => {
                let dados = Object(response).data;
                this.contador.marca = dados.marca;
                this.contador.modelo = dados.modelo;
                this.contador.numero_serie = dados.numero_serie;
                this.contador.precisao = dados.precisao;
                this.contador.medicao = dados.medicao;
                this.contador.calibre = dados.calibre;
            }
        );
    }

    /*
    private selectBoxGeneros() {
      this.configService.loaddinStarter('start');
      this.http.call_get('genero/selectBox', null).subscribe(
        response => {
          this.generos = Object(response);
          this.configService.loaddinStarter('stop');
        }
      );
    }


    private selectBoxTipoClientes() {
      this.configService.loaddinStarter('start');
      this.http.call_get('tipo-cliente/selectBox', null).subscribe(
        response => {
          this.tipo_clientes = Object(response);
          this.configService.loaddinStarter('stop');
        }
      );
    }
    */

    public listarPedidosClientes(client: any) {
        this.pedido.cliente_id = client.id;
        this.cliente.nome = client.nome;
        this.cliente.id = client.id;

        this.configService.loaddinStarter('start');
        this.http.call_get('pedido/listar/' + client.id, null).subscribe(
            response => {
                this.pedidos = Object(response).data;
                this.configService.loaddinStarter('stop');
            }
        );
    }

    private listarReclamacoesClientes(client: any) {

        this.reclamacao.cliente_id = client.id;
        this.cliente.nome = client.nome;

        this.configService.loaddinStarter('start');
        this.http.call_get('reclamacao/listar/' + client.id, null).subscribe(
            response => {
                this.reclamacoes = Object(response).data;
                this.configService.loaddinStarter('stop');
            }
        );
    }

    private registerReclamacao() {
        if (this.reclamacao.tipo_reclamacao_id == null) {
            this.configService.showAlert('Seleccione o tipo de reclamação', 'alert-danger', true);
        } else if (this.reclamacao.prioridade_id == null) {
            this.configService.showAlert('Seleccione a prioridade', 'alert-danger', true);
        }
        else {
            this.configService.loaddinStarter('start');
            this.http.__call('reclamacao/register', this.reclamacao).subscribe(
                res => {
                    if (Object(res).code == 500) {
                        this.configService.showAlert(Object(res).message, 'alert-danger', true);
                    } else {
                        this.configService.showAlert(Object(res).message, 'alert-success', true);
                        this.configService.loaddinStarter('stop');
                        this.listarReclamacoesClientes({ nome: this.cliente.nome, id: this.reclamacao.cliente_id });

                        this.reclamacao.observacao = null;
                        this.reclamacao.prioridade_id = null;
                        this.reclamacao.tipo_reclamacao_id = null
                    }
                }
            );
        }
    }

    private listarLojas() {

        this.http.call_get('loja/selectBox', null).subscribe(
            response => {
                this.lojas = Object(response).data;
            }
        );
    }

    private listarTipoPedidos() {
        this.http.call_get('tipoPedidos/selectBox', null).subscribe(
            response => {
                this.tipoPedidos = Object(response).data;
            }
        );
    }

    private reclamacaoPrioridade() {
        this.listReclamacao();
        this.listPrioridade();
    }

    private getTipo() {
        this.http.__call('nova-ligacao/tipo-ligacao-listarAll', null).subscribe(
            response => {
                this._tipos = Object(response).data;
            })
    }

    private listReclamacao() {

        this.configService.loaddinStarter('start');
        this.http.call_get('tipoReclamacao/selectBox', null).subscribe(
            response => {
                this.tipos = Object(response).data;
                this.configService.loaddinStarter('stop');
            }
        );
    }

    private listPrioridade() {

        this.http.call_get('prioridade/selectBox', null).subscribe(
            response => {
                this.prioridades = Object(response).data;
            }
        );
    }


    private listarCMDNumero() {

        this.http.call_get('cdma/selectBox', null).subscribe(
            response => {
                if (Object(response).status == 302) {
                    this.configService.showAlert(Object(response).message, 'alert-danger', true);
                } else {
                    this.chaves = Object(response).data;
                }
            }
        );
    }



    view_cdma_numero = false;

    private getCMDNumero() {
        //this.chaves=[];
        this.view_cdma_numero = true;
        this.http.__call('cdma/search-cdma-numero', { start: 1, end: 10, search: this.cdmaServico.cdma_numero }).subscribe(
            response => {
                this.chaves = Object(response).data.data;

                this.configService.loaddinStarter('stop');
            }
        );
    }

    private setChave(chave: any) {
        this.cdmaServico.cdma_numero = chave;
        this.view_cdma_numero = false;
    }


    view_lte_numero_serie = false;
    private lteSeries: any = [];

    private getLTENumeroSerie() {

        if (this.LTECPES.numero_serie == "") {
            this.setNullNumeroSerie();
        }
        //this.chaves=[];
        this.view_lte_numero_serie = true;
        this.http.__call('lte-search/numero-serie', { start: 1, end: 10, search: this.LTECPES.numero_serie }).subscribe(
            response => {
                this.lteSeries = Object(response).data.data;

                this.configService.loaddinStarter('stop');
            }
        );
    }

    private setNullNumeroSerie() {
        this.LTECPES.numero_serie = null
        this.LTECPES.id = null;
        this.LTECPES.fabricante = null;
        this.LTECPES.modelo = null;
        this.LTECPES.tipo = null;
        this.view_lte_numero_serie = false;
    }


    private setLTESerie(lte_cep_id, serie, fabricante, modelo, tipo) {
        this.LTECPES.id = lte_cep_id;
        this.LTECPES.numero_serie = serie;
        this.LTECPES.fabricante = fabricante;
        this.LTECPES.modelo = modelo;
        this.LTECPES.tipo = tipo;
        this.view_lte_numero_serie = false;

    }


    view_serie_sim = false;
    private lteSeriesSims: any = [];

    private getLTESerieSim() {

        if (this.LTECPES.serie_sim == "") {
            this.setNullSerieSim();
        }
        //this.chaves=[];
        this.view_serie_sim = true;
        this.http.__call('search-serie-sim', { start: 1, end: 10, search: this.LTECPES.serie_sim }).subscribe(
            response => {
                this.lteSeriesSims = Object(response).data.data;

                this.configService.loaddinStarter('stop');
            }
        );
    }

    private setLTESerieSim(sim_card_id, serie_sim, imsi_sim) {
        this.LTECPES.sim_card_id = sim_card_id;
        this.LTECPES.serie_sim = serie_sim;
        this.LTECPES.imsi_sim = imsi_sim;
        this.view_serie_sim = false;

    }


    private setNullSerieSim() {
        this.LTECPES.sim_card_id = null
        this.LTECPES.serie_sim = null
        this.LTECPES.imsi_sim = null;
        this.view_serie_sim = false;
    }


    view_lte_numero_telefone = false;
    private lteTelefones: any = [];


    private getLTETelefone() {

        this.view_lte_numero_telefone = true;
        this.http.__call('lte-search/telefone', { start: 1, end: 10, search: this.LTECPES.numero_telefone }).subscribe(
            response => {

                if (Object(response).status == 302) {
                    this.configService.showAlert(Object(response).message, 'alert-danger', true);

                } else {
                    this.lteTelefones = Object(response).data.data;
                }
                //this.lteTelefones = Object(response).data.data;

                //this.configService.loaddinStarter('stop');
            }
        );
    }

    private getCliente(nomeCliente: any, conta: number) {
        this.pedido.clienteNome = nomeCliente;
        this.pedido.conta_id = conta;
    }

    private _getCliente() {
        this.contas = []
        this.contratos = []
        this.view_client = true;
        this.http.__call('cliente/search-cliente', { start: 1, end: 10, search: this.simpleFormCliente.getRawValue().cliente }).subscribe(
            response => {
                this._clientes = Object(response).data.data;
            })
    }

    private getInstalacaoDetalhe(residencia_id) {

        this.configService.loaddinStarter('start');
        this.http.__call('pedido/detalheLocalInstalacao/' + residencia_id, null).subscribe(
            response => {

                let dados = Object(response).data;

                this.localInstalacao.id = dados.id;
                this.localInstalacao.municipio = dados.municipio;
                this.localInstalacao.distritoComuna = dados.distritoComuna;
                this.localInstalacao.bairro = dados.bairro;
                this.localInstalacao.rua = dados.rua;
                this.localInstalacao.residenciaNumero = dados.residenciaNumero;
                this.localInstalacao.residenciaNome = dados.residenciaNome;

                this.configService.loaddinStarter('stop');
            }
        );
    }


    private setLTETelefone(telefone) {
        this.LTECPES.numero_telefone = telefone;
        this.view_lte_numero_telefone = false;
        this.lteMessage = "Sucesso";

    }


    private getSelectBoxResidenciasByRua() {

        this.residencia.rua_id;
        this.getResidencia();
    }

    view_residencia = false;
    private residencias: any = [];

    private getResidencia() {

        if (this.residencia.numero == "") {
            this.setNullResidencia();
        }

        this.view_residencia = true;
        this.http.__call('local-instalacao/getSelectLocalInstalacaosByRua', { rua_id: this.residencia.rua_id, start: 1, end: 10, search: this.residencia.numero }).subscribe(
            response => {
                this.residencias = Object(response).data.data;

                this.configService.loaddinStarter('stop');
            }
        );
    }

    private setResidencia(codigo, numero, nome) {
        this.residencia.id = codigo;
        this.residencia.numero = numero;
        this.residencia.nome = nome;
        this.view_residencia = false;

    }

    private setNullResidencia() {
        this.residencia.id = null;
        this.residencia.numero = null;
        this.residencia.nome = null;
        this.view_residencia = false;
    }


    view_lte_modelo = false;
    private lteModelos: any = [];

    private getLTEModelo() {

        this.view_lte_modelo = true;
        this.http.__call('lte-search/modelo', { start: 1, end: 10, search: this.LTECPES.modelo }).subscribe(
            response => {
                this.lteModelos = Object(response).data.data;

                this.configService.loaddinStarter('stop');
            }
        );
    }

    private setLTEModelo(modelo: any) {
        this.LTECPES.modelo = modelo;
        this.view_lte_modelo = false;

    }


    view_lte_tipo = false;
    private lteTipos: any = [];

    private getLTETipos() {

        this.view_lte_tipo = true;
        this.http.__call('lte-search/tipo', { start: 1, end: 10, search: this.LTECPES.tipo }).subscribe(
            response => {
                this.lteTipos = Object(response).data.data;

                this.configService.loaddinStarter('stop');
            }
        );
    }

    private setLTETipos(tipo: any) {
        this.LTECPES.tipo = tipo;
        this.view_lte_tipo = false;

    }




    private clientes: any = [];

    private listarChaveServicos() {

        if (this.servico.chaveServico == null || this.servico.chaveServico == "") {
            this.wimaxMessage = "Digite o telefone / Série!";
            this.classMessage = "text-danger";
        } else {
            this.configService.loaddinStarter('start');
            this.http.__call('servico/chaveServicos', this.servico).subscribe(
                data => {
                    if (data.code === 200) {
                        this.wimaxMessage = "Sucesso";
                        this.classMessage = "text-success";
                        this.servico.wimax_id = data.data.id;
                        this.configService.loaddinStarter('stop');
                    }
                }, error => {

                    if (error.status === 302) {
                        this.wimaxMessage = "Ocupado!";
                        this.classMessage = "text-warning";
                    } else if (error.status === 404) {
                        this.wimaxMessage = "Não encontrado";
                        this.classMessage = "text-warning";
                    } else {
                        this.wimaxMessage = "Não definido";
                        this.classMessage = "text-warning";
                    }
                    this.configService.loaddinStarter('stop');
                }
            );
        }
    }

    private validateSerie() {

        if (this.cdmaServico.cdma_numero == null) {
            this.cdmaMessage = "Seleccione primeiro o número!";
            this.cdmaClassMessage = "text-danger";
        }
        else if (this.cdmaServico.numero_serie == null || this.cdmaServico.numero_serie == "") {
            this.cdmaMessage = "Digite a Série!";
            this.cdmaClassMessage = "text-danger";
        } else {
            this.configService.loaddinStarter('start');
            this.http.__call('cdmaEquip/validate/serie', this.cdmaServico).subscribe(
                data => {

                    if (data.code === 200) {
                        this.cdmaMessage = "Sucesso";
                        this.cdmaClassMessage = "text-success";
                        this.cdmaServico.fabricante = data.data.fabricante;
                        this.cdmaServico.modelo = data.data.modelo;
                        this.servico.cdma_equipamento_id = data.data.idCdmaEquip;
                        //this.classMessage = "glyphicon glyphicon-ok";
                        this.configService.loaddinStarter('stop');
                    }
                }, error => {
                    if (error.status === 302) {
                        this.cdmaMessage = "Ocupado!";
                        this.cdmaClassMessage = "text-warning";
                    } else if (error.status === 404) {
                        this.cdmaMessage = "Não encontrado";
                        this.cdmaClassMessage = "text-warning";
                    } else {
                        this.cdmaMessage = "Não definido";
                        this.cdmaClassMessage = "text-warning";
                    }
                    this.configService.loaddinStarter('stop');
                }
            );
        }
    }

    private validateSerieContador() {

        if (this.LTECPES.numero_serie == null) {
            this.lteMessage = "Digite o Número de Série!";
            this.lteClassMessage = "text-danger";
        } else {
            this.configService.loaddinStarter('start');
            this.http.__call('contador/validate/serie', this.LTECPES).subscribe(
                data => {

                    if (data.code === 200) {
                        this.LTECPES.id = data.data.id;
                        this.LTECPES.numero_serie = data.data.numero_serie;
                        this.LTECPES.fabricante = data.data.marca;
                        this.LTECPES.modelo = data.data.modelo;
                        this.LTECPES.tipo = data.data.medicao;
                        this.lteMessage = "Sucesso";
                        this.lteClassMessage = "text-success";

                        //this.classMessage = "glyphicon glyphicon-ok";
                        this.configService.loaddinStarter('stop');
                    }
                }, error => {
                    if (error.status === 302) {
                        this.lteMessage = "Usado!";
                        this.lteClassMessage = "text-warning";
                    } else if (error.status === 404) {
                        this.lteMessage = "Esse número não existe";
                        this.lteClassMessage = "text-warning";
                    } else {
                        this.lteMessage = "Não definido";
                        this.lteClassMessage = "text-warning";
                    }
                    this.configService.loaddinStarter('stop');
                }
            );
        }
    }

    private validateTelefoneLTE() {

        if (this.LTECPES.numero_telefone == null) {
            this.lteMessage = "Digite o telefone!";
            this.lteClassMessage = "text-danger";
        } else {
            this.configService.loaddinStarter('start');
            this.http.__call('lteTelefone/validate/telefone', this.LTECPES).subscribe(
                data => {

                    if (data.code === 200) {

                        this.lteMessage = "Sucesso";
                        this.lteClassMessage = "text-success";

                        //this.classMessage = "glyphicon glyphicon-ok";
                        this.configService.loaddinStarter('stop');
                    }
                }, error => {

                    if (error.status === 302) {
                        this.lteMessage = "Usado!";
                        this.lteClassMessage = "text-warning";
                    } else if (error.status === 404) {
                        this.lteMessage = "Esse número não existe";
                        this.lteClassMessage = "text-warning";
                    } else {
                        this.lteMessage = "Não definido";
                        this.lteClassMessage = "text-warning";
                    }
                    this.configService.loaddinStarter('stop');
                }
            );
        }
    }

    private getContasByCliente(client: any) {
        this.cliente = client;

        this.conta.cliente_id = client.id
        this.conta.contrato_id = client.contrato_id

        this.configService.loaddinStarter('start');
        this.http.call_get('cliente/conta/listar/' + client.id, null).subscribe(
            response => {
                this.contas = Object(response).data;
                this.configService.loaddinStarter('stop');
            }
        );

    }

    private listarContactosClientes(client: any) {
        this.configService.listarMoedas();

        this.contacto.cliente_id = client.id;
        this.cliente.nome = client.nome;

        this.configService.loaddinStarter('start');
        this.http.call_get('cliente/contacto/listar/' + client.id, null).subscribe(
            response => {
                this.contactos = Object(response).data;
                this.configService.loaddinStarter('stop');
            }
        );
    }




    private showSubmit() {
        this.isFinalizar = true;
    }


    private preenchAuto() {
        this.configService.loaddinStarter('start');

        if (this.cliente.numero_identidade.length > 8) {
            this.http.call_get('cliente/search-cliente-agt/' + this.cliente.numero_identidade, null).subscribe(
                response => {
                    if (Object(response).data != null) {
                        this.cliente.nome = Object(response).data.nomeContribuinte;
                        this.cliente.telefone = (Object(response).data.telefone == 'NA' ? '' : Object(response).data.telefone);
                    } else {
                        this.cliente.nome = null
                        this.cliente.telefone = null
                    }

                    this.configService.loaddinStarter('stop');
                }
            );
        }

    }


    private refreshDataCliente(id, nome, numero_identidade, telefone, morada, observacao, genero, gestor_cliente_id, direccao_id, tipo_cliente_id, tipo_identidade_id) {
        this.cliente.id = id;
        this.cliente.nome = nome;
        this.cliente.tipo_cliente_id = tipo_cliente_id;
        this.cliente.tipo_identidade_id = tipo_identidade_id;
        this.cliente.numero_identidade = numero_identidade;
        this.cliente.telefone = telefone;
        this.cliente.morada = morada;
        this.cliente.genero = genero;
        this.cliente.gestor_cliente_id = gestor_cliente_id;
        this.cliente.direccao_id = direccao_id;

    }

    private dataCliente(cliente: any) {
        this.cliente.id = cliente.id;
        this.cliente.nome = cliente.nome;
        this.cliente.tipo_cliente_id = cliente.tipo_cliente_id;
        this.cliente.tipo_identidade_id = cliente.tipo_identidade_id;
        this.cliente.numero_identidade = cliente.numero_identidade;
        this.cliente.telefone = cliente.telefone;
        this.cliente.morada = cliente.morada;
        this.cliente.genero = cliente.genero
        this.cliente.email = cliente.email;
        this.cliente.nif = cliente.nif;
        this.cliente.municipio_id = cliente.municipio_id;
        this.cliente.tipo_cliente_id = cliente.tipo_cliente_id;
        this.cliente.tipo_identidade_id = cliente.tipo_identidade_id;
        this.cliente.municipio_id = cliente.municipio_id;
    }


    private mostrarResultados() {
        this.pagination.perPage = this.mostrarResultado.nativeElement.value;
        this.listClientes();

    }

    private pages = [];
    private nextProvPageNumber = 1;

    private gerarPages() {
        for (var i = 1; i <= this.pagination.lastPage; i++) {
            this.pages.push(i);
        }
    }
    private nextPage() {
        this.nextProvPageNumber++;
        if (this.nextProvPageNumber < 0) {
            this.nextProvPageNumber = this.pagination.page;
        }
        this.listClientes();

    }
    private prevPage() {
        this.nextProvPageNumber--
        if (this.nextProvPageNumber > 0) {
            this.listClientes();
            this.nextProvPageNumber = this.pagination.page;
        }
    }

    private refreshPaginate(page) {
        this.pagination.page = page;
        this.listClientes();
    }


    public listarTarifarios(id: number) {
        this.servico.conta_id = id;
    }

    public setConta(item: any) {
        this.servico.conta_id = item.id;
        this.servico.numero_conta = item.numero_conta;
        this.servico.tipo_facturacao_id = item.tipo_facturacao_id;
        this.servico.tipo_facturacao = item.tipo_facturacao;

    }



    private setDataCliente(item) {

        this.cliente.id = item.id;
        this.cliente.nome = item.nome;
        this.cliente.telefone = item.telefone;
        this.cliente.email = item.email;
        this.cliente.nif = item.nif;
        this.cliente.genero_id = item.genero_id;
        this.cliente.genero = item.genero;
        this.cliente.municipio_id = item.municipio_id;
        this.cliente.municipio = item.municipio;
        this.cliente.provincia_id = item.provincia_id;
        this.cliente.morada = item.morada;
        this.cliente.tipo_identidade_id = item.tipo_identidade_id;
        this.cliente.tipo_identidade = item.tipo_identidade;
        this.cliente.numero_digitos = item.numero_digitos;
        this.cliente.numero_identificacao = item.numero_identificacao;
        this.cliente.tipo_cliente_id = item.tipo_cliente_id;
        this.cliente.tipo_cliente = item.tipo_cliente;
        this.cliente.direccao_id = item.direccao_id;
        this.cliente.direccao = item.direccao;
        this.cliente.gestor_cliente_id = item.gestor_cliente_id;
        this.cliente.gestor_cliente = item.gestor_cliente;
        this.cliente.gestor_cliente_telefone = item.gestor_cliente_telefone;

    }


    private setDataContrato(item) {
        const aux = this.contrato.estado_rescisao_id;
        this.contrato = item;
        this.contrato.estado_rescisao_id = aux;
    }


    public getEditContrato(item) {

        this._route.navigate(['/contratos/Update/' + item.id + '/' + item.conta_id + '/' + item.cliente_id + '/' + item.tarifario_id + '/' + item.classe_tarifario_id]);

    }

    public getEditContratoFromLocalConsumo(item) {

        const id = item.contrato_id;

        this._route.navigate(['/contratos/Update/' + id + '/' + item.conta_id + '/' + item.cliente_id + '/' + item.tarifario_id + '/' + item.classe_tarifario_id]);

    }


    public getNewContrato(id) {
        this._route.navigate(['/contratos/New/' + id]);
    }


    private setDataClienteToEdit(client: any) {
        this.clienteEdit = client;
    }

    public setCliente(client: any) {
        this.cliente.nome = client.nome;
        this.cliente.id = client.id;
        this.simpleFormNovaLigacao.patchValue({
            cliente_id: client.id,
            cliente: client.nome,
            cliente_telefone: client.telefone
        });
        this.getConta(client)
        this.view_client = false;
        this._clientes = []
    }
    private getConta(cliente) {

        this.http.call_get('cliente/conta/selectBox/' + cliente.id, null).subscribe(
            response => {
                this.contas = Object(response).data
                this.contas_clientes = Object(response).data;

                if (this.contas_clientes.length == 1) {
                    this.facturacao.cliente.conta_id = this.contas_clientes[0].numero_conta;
                }
            })
    }

    public getContaCodigo(id: number) {
        this.conta.id = id;
    }

    public listarPedidos(id: number) {
        this.servico.conta_id = id;
        if (this.filtroServicos.pagination.perPage == null) {
            return;
        }
        this.filtroServicos.pagination.page = this.filtroServicos.pagination.page;

        this.configService.loaddinStarter('start');
        this.http.__call('conta/pedidosByConta/' + id, this.filtroServicos).subscribe(
            response => {

                this.filtroServicos.pagination.lastPage = Object(response).data.lastPage;
                this.filtroServicos.pagination.page = Object(response).data.page;
                this.filtroServicos.pagination.total = Object(response).data.total;
                this.filtroServicos.pagination.perPage = Object(response).data.perPage;

                this.pedidos = Object(response).data.data;
                this.configService.loaddinStarter('stop');
            }
        );

    }


    public getLocaisConsumo(id: number) {
        this.servico.conta_id = id;

        if (this.filtroLocalConsumo.pagination.perPage == null) {
            return;
        }

        this.filtroLocalConsumo.pagination.page = this.filtroLocalConsumo.pagination.page;

        this.configService.loaddinStarter('start');
        this.http.__call('local-consumo/getLocalConsumoByContaId/' + id, null).subscribe(
            response => {
                this.localConsumos = Object(response);
                this.configService.loaddinStarter('stop');
            }
        );

    }


    getPageFilterDataLocalConsumo(page: number) {
        if (this.filtroLocalConsumo.pagination.perPage == null) {
            return;
        }
        this.filtroLocalConsumo.pagination.page = page;
        this.getLocaisConsumo(this.servico.conta_id);
    }


    public getContratosByConta(id: number) {
        this.servico.conta_id = id;
        this.contratos = []
        this.is_contrato_id = null;
        this.configService.loaddinStarter('start');
        this.http.__call('conta/contratoByConta/' + id, null).subscribe(
            response => {
                this.contratos_conta = Object(response);
                this.contratos = Object(response);
                this.configService.loaddinStarter('stop');
            }
        );
    }

    public getMotivosRescisao() {
        this.configService.loaddinStarter('start');
        this.http.call_get('motivo-rescisao/selectBox/', null).subscribe(
            response => {
                this.motivo_rescisaos = Object(response);
                this.configService.loaddinStarter('stop');
            }
        );
    }

    public getEstadosRescisao() {
        this.configService.loaddinStarter('start');
        this.http.call_get('motivo-rescisao/selectBoxEstado/', null).subscribe(
            response => {
                this.estado_rescisaos = Object(response);
                this.configService.loaddinStarter('stop');
            }
        );
    }



    public getContratosByLocalConsumo(id: number) {

        this.configService.loaddinStarter('start');
        this.http.__call('contratoByLocalConsumo/' + id, null).subscribe(
            response => {
                this.contratos_conta = Object(response).data;
                this.configService.loaddinStarter('stop');
            }
        );

    }

    private saveRescisaoContrato(create_ordem_trabalho = false, alterar_estado: boolean) {

        this.rescisao_saved = false;

        this.http.__call('contrato/denunciar-rescindir/' + this.contrato.id, {
            contrato_id: this.contrato.id,
            morada_correspondencia_flag: this.contrato.morada_correspondencia_flag,
            tipo_medicao_slug: this.contrato.tipo_medicao_slug,
            contador_id: this.contrato.contador_id,
            motivo_recisao_id: this.contrato.motivo_recisao_id,
            estado_rescisao_id: this.contrato.estado_rescisao_id,
            data_rescisao: this.contrato.data_rescisao,

            rua_id: this.residencia.rua_id,
            numero_moradia: this.residencia.numero,
            is_predio: this.residencia.is_predio,
            predio_nome: this.residencia.predio_nome,
            predio_andar: this.residencia.predio_andar,

            leitura: this.contador.leitura,
            data_leitura: this.contador.data_leitura,
            leitura_origem: this.contador.leitura_origem,
            isAlteracaoEstado: alterar_estado,
            user_id: this.currentUser.user.id

        }).subscribe(
            res => {
                if (Object(res).code == 500) {
                    this.configService.showAlert(Object(res).message, 'alert-danger', true);
                    this.isFinalizar = true;
                } else if (Object(res).code == 201 || Object(res).code == 200) {
                    this.getContratosByConta(this.servico.conta_id)
                    this.rescisao_saved = true;
                    this.isFinalizar = true;
                    this.configService.showAlert(Object(res).message, 'alert-warning', true);
                    this.configService.loaddinStarter('stop');

                } else {
                    this.getContratosByConta(this.servico.conta_id)
                    this.configService.showAlert(Object(res).message, 'alert-success', true);
                    this.configService.loaddinStarter('stop');

                }
            });
    }

    private getInfoLocal(municipio, distritoComuna, bairro, rua, residenciaNome, residenciaNumero) {
        this.localConsumoInfo.municipio = municipio;
        this.localConsumoInfo.distritoComuna = distritoComuna;
        this.localConsumoInfo.bairro = bairro;
        this.localConsumoInfo.rua = rua;
        this.localConsumoInfo.residenciaNome = residenciaNome;
        this.localConsumoInfo.residenciaNumero = residenciaNumero;
    }

    private estadosConta: any = [];
    private carregandoEstado = {
        estado: 'Seleccione o estado',
    }

    private selectBoxEstadoConta() {
        this.carregandoEstado.estado = 'Carregando...';

        this.http.call_get('estado-conta/selectBox', null).subscribe(
            response => {
                this.estadosConta = Object(response);
                this.carregandoEstado.estado = 'Seleccione o estado';
            }
        );
    }


    private tiposFacturacao: any = [];
    private loadTipoFacturacao = {
        tipo_facturacao: 'Seleccione o tipo',
    }

    private selectBoxTipoFacturacao() {
        this.loadTipoFacturacao.tipo_facturacao = 'Carregando...';

        this.http.call_get('tipo-facturacao/selectBox', null).subscribe(
            response => {
                this.tiposFacturacao = Object(response);
                this.loadTipoFacturacao.tipo_facturacao = 'Seleccione o tipo';

            }
        );

    }

    public listarCarregamentos(id: number) {
        this.servico.chaveServico = id;
        this.configService.loaddinStarter('start');
        this.http.call_get('servicos/ver/carregamentos/' + id, null).subscribe(
            response => {
                this.carregamentos = Object(response).data;
                this.configService.loaddinStarter('stop');
            }
        );

    }
    private getEstado(data) {
        this.estado = []
        if (!data) return
        this.http.__call('nova-ligacao/estado-listar-ativo', data).subscribe(
            response => {
                this.estado = Object(response).data;
                if (this.simpleFormCliente.value.estado_orcamento_id == '0') {
                    this.estado = this.estado.filter(item => item.flag == 'CANCELADO')
                }
            })
    }
    private btnImprimirFactura(id) {
        //this.configService.imprimirFactura(id, "2ª Via");
    }

    private getServico(id: number) {
        this.getEstadosServicos()
        this.configService.loaddinStarter('start');

        this.http.call_get('getServico/' + id, null).subscribe(
            response => {
                let dado = Object(response).data;
                this.servico.id = dado.servicoID;
                this.servico.chaveServico = dado.chaveServico;
                this.servico.estado_actual = dado.estadoDescricao;
                this.servico.estado_actual_id = dado.estadoActualID;
                this.servico.conta_id = dado.conta_id;

                this.configService.loaddinStarter('stop');
            }
        )
    }

    /*   private imprimirContrato(item) {
        this.contratoService.imprimirPDFContrato(item.id)

      } */

    private imprimirContrato(id) {

        if (this.sigla_empresa == "EPASN") {
            this.contratoService.imprimirPDFContrato(id)
        }

        if (this.sigla_empresa == "EPASLS") {
            this.contratoLundasulService.imprimirPDFContratoLundaSul(id)
        }

        if (this.sigla_empresa == "EPASLN") {
            this.contratoLundanorteService.imprimirPDFContrato(id)
        }
    }

    private imprimirPDFRescisao(id) {
        /*     this.reportRescisao.imprimirRescisao(id); */
        this.reportRescisaolundanorte.imprimirRescisaolundanorte(id);
    }

    private getEstadoConta(id: number) {
        this.http.call_get('getContaEstado/' + id, null).subscribe(
            response => {
                let dado = Object(response).data;
                this.conta.estado = dado.estado;
                this.conta.estado_actual = dado.estado;
                this.conta.id = dado.id;
                this.configService.loaddinStarter('stop');
            }
        )

    }

    private setDataConta(item: any) {

        this.conta.id = item.id;
        this.conta.descricao = item.descricao;
        this.conta.estado = item.estado;
        this.conta.moeda_id = item.moeda_id;
        this.conta.numero_conta = item.numero_conta;
        this.conta.tipo_facturacao_id = item.tipo_facturacao_id;

    }

    private updateEstadoConta(item: any) {

        this.setDataConta(item);
        this.closebutton.nativeElement.click();


        this.http.__call('cliente/conta/update/' + item.id, {
            numero_conta: this.conta.numero_conta,
            descricao: this.conta.descricao,
            cliente_id: this.conta.cliente_id,
            estado: !this.conta.estado,
            tipo_facturacao_id: this.conta.tipo_facturacao_id,

        }).subscribe(
            response => {
                if (Object(response).code == 200) {
                    var update = (item.estado == true) ? "Activado" : "Desactivado";
                    this.configService.showAlert("Conta " + item.numero_conta + " foi " + update, "alert-success", true);
                    this.getContasByCliente({ nome: this.cliente.nome, id: this.conta.cliente_id })

                    this.conta = {
                        id: null,
                        contrato_id: null,
                        cliente_id: null,
                        numero_conta: null,
                        descricao: " ",
                        agencia_id: null,
                        moeda_id: null,
                        estado: null,
                        tipo_facturacao_id: null,
                        dataEstado: null,
                        estado_actual: null
                    }
                }
            }
        );



        for (let i = 0; i < this.contas.length; ++i) {
            if (this.contas[i].id == item.id) {
                this.contas[i].estado = item.estado;
            }
        }


    }

    private editarEstadoServico() {
        if (this.servico.estado == null || this.servico.estado == "null") {
            this.configService.showAlert('Seleccione o novo estado', 'alert-danger', true);
        }
        else {

            this.configService.loaddinStarter('start');
            this.http.__call('servico/update/estado/' + this.servico.id, this.servico).subscribe(
                res => {
                    if (Object(res).code == 500) {
                        this.configService.showAlert(Object(res).message, 'alert-danger', true);
                    } else {
                        this.configService.showAlert(Object(res).message, 'alert-success', true);
                        this.configService.loaddinStarter('stop');
                        this.getServico(this.servico.id)
                        this.listarPedidos(this.servico.conta_id)

                    }
                }
            );
        }


    }

    private tarifarioBYCategoria() {
        this.isFinalizar = true;
        let tecnology = this.servico.tecnologia
        this.getTarifarioBYTecnologia(tecnology);

    }

    private getByMudanca() {
        this.mudanca.cliente_nome = null;
        this.clienteContas = null;
        if (this.mudanca.mudanca_tipo == 'MC') {
            this.mudanca.servico_id = this.servico.id;
            this.mudanca.conta_antiga = this.servico.conta_id;
            this.getClienteConta(this.servico.id);
        } else {
            this.mudanca.cliente_nome = null;
            this.clienteContas = null;
        }

    }

    private resetMudanca() {
        this.mudanca.cliente_nome = null;
        this.mudanca.cliente_search = null;
        this.mudanca.conta_id = null;
        this.wimaxMessage = null;
        this.lteMessage = null;
        this.mudanca.mudanca_tipo = null;
        this.clienteContas = null;
    }

    private searchCliente() {
        this.mudanca.servico_id = this.servico.id;
        this.mudanca.conta_antiga = this.servico.conta_id;

        if (this.mudanca.mudanca_tipo == null && this.mudanca.cliente_search == null) {
            this.wimaxMessage = "Digite um valor!";
            this.classMessage = "text-danger";
        } else {
            this.configService.loaddinStarter('start');
            this.http.__call('search/cliente/' + this.mudanca.cliente_search, null).subscribe(
                data => {
                    this.configService.loaddinStarter('start');
                    if (data.code === 200) {
                        this.wimaxMessage = "Sucesso";
                        this.classMessage = "text-success";
                        let dados = Object(data).data;
                        this.mudanca.cliente_nome = dados.cliente.nome;
                        this.clienteContas = dados.contas;
                        this.configService.loaddinStarter('stop');
                    }
                }, error => {
                    this.configService.loaddinStarter('start');
                    if (error.status === 404) {
                        this.wimaxMessage = "Não encontrado";
                        this.classMessage = "text-danger";
                        this.mudanca.cliente_nome = null;
                        this.clienteContas = null;
                        this.configService.loaddinStarter('stop');
                    } else {
                        this.wimaxMessage = "Não definido";
                        this.classMessage = "text-danger";
                    }
                    this.configService.loaddinStarter('stop');
                }
            );
        }
    }

    private editTitularidadeConta() {
        if (this.mudanca.cliente_nome == null) {
            this.configService.showAlert('Nome do Cliente não pode estar vazio', 'alert-danger', true);
        } else if (this.mudanca.conta_id == null) {
            this.configService.showAlert('Seleccione uma conta', 'alert-danger', true);
        }
        else {

            this.configService.loaddinStarter('start');
            this.http.__call('mudanca/conta/servico', this.mudanca).subscribe(
                res => {
                    if (Object(res).code == 500) {
                        this.configService.showAlert(Object(res).message, 'alert-danger', true);
                    } else {
                        this.configService.showAlert(Object(res).message, 'alert-success', true);
                        this.configService.loaddinStarter('stop');
                        this.listarPedidos(this.servico.conta_id)

                        this.mudanca.cliente_nome = null;
                        this.mudanca.cliente_search = null;
                        this.clienteContas = null;
                        this.wimaxMessage = null;
                        this.mudanca.mudanca_tipo = null;
                        this.clienteContas = null;
                    }
                }
            );
        }


    }

    private getClienteConta(id: any) {
        this.configService.loaddinStarter('start');
        this.http.__call('get/cliente/contas/' + id, this.mudanca).subscribe(
            response => {
                let dados = Object(response).data;
                this.mudanca.cliente_nome = dados.cliente.clienteNome;
                this.clienteContas = dados.contas;
                this.configService.loaddinStarter('stop');
            }
        );
    }


    public getTarifarioBYTecnologia(tecnology) {
        this.http.call_get('tarifario/tecnologia/' + tecnology, null).subscribe(
            response => {
                this.tarifarios = Object(response).data;
            }
        );
    }


    private registerPedido() {

        if (this.servicoPosPago.tecnologia == null) {
            this.configService.showAlert('Seleccione o serviço', 'alert-danger', true);
        } else if (this.servico.tarifario_id == null) {
            this.configService.showAlert('Seleccione o tarifário', 'alert-danger', true);
        } else if (this.residencia.numero == null) {
            this.configService.showAlert('Seleccione a residência', 'alert-danger', true);
        } else {
            this.isFinalizar = false;

            this.configService.loaddinStarter('start');
            this.http.__call('pedido/register', {
                conta_id: this.servico.conta_id,
                tecnologia_id: this.servicoPosPago.tecnologia,
                tarifario_id: this.servico.tarifario_id,
                residencia_id: this.residencia.id
            }).subscribe(
                res => {
                    if (Object(res).code == 500) {
                        this.configService.showAlert(Object(res).message, 'alert-danger', true);
                        this.isFinalizar = true;
                    } else if (Object(res).code == 201) {
                        this.isFinalizar = true;
                        this.configService.showAlert(Object(res).message, 'alert-warning', true);
                        this.configService.loaddinStarter('stop');

                    } else {
                        this.configService.showAlert(Object(res).message, 'alert-success', true);
                        this.configService.loaddinStarter('stop');
                        this.resetFormNovoServico()
                    }
                }
            );
        }

    }

    private resetFormMessage() {
        this.cdmaMessage = null;
        this.wimaxMessage = null;
        this.lteMessage = null;
    }

    private resetAfterChageTecnology() {

        this.servico.dataEstado = null;
        this.servico.estado = null;
        this.cdmaMessage = null;
        this.servico.chaveServico = null;
        this.wimaxMessage = null;
        this.lteMessage = null;
        this.servico.chaveServico = null;
        this.cdmaServico.numero_serie = null;
        this.cdmaServico.fabricante = null;
        this.cdmaServico.cdma_numero = null;
        this.cdmaServico.modelo = null;
        this.servico.numero_serie = null;
        this.servico.imsi_sim = null;
        this.servico.serie_sim = null;
        this.cdmaServico.numero_serie = null
        this.isFinalizar = true;
        this.view_cdma_numero = false;

        this.LTECPES = {
            id: null,
            numero_serie: null,
            fabricante: null,
            modelo: null,
            tipo: null,
            numero_telefone: null,
            serie_sim: null,
            imsi_sim: null,
            sim_card_id: null
        }

    }

    private resetFormNovoServico() {
        this.servico.tarifario_id = null;
        this.servico.dataEstado = null;
        this.servico.estado = null;
        this.servico.tecnologia = null;
        this.cdmaMessage = null;
        this.servico.chaveServico = null;
        this.wimaxMessage = null;
        this.lteMessage = null;
        this.servico.chaveServico = null;
        this.cdmaServico.numero_serie = null;
        this.cdmaServico.fabricante = null;
        this.cdmaServico.cdma_numero = null;
        this.cdmaServico.modelo = null;
        this.servico.numero_serie = null;
        this.servico.imsi_sim = null;
        this.servico.serie_sim = null;
        this.cdmaServico.numero_serie = null
        this.isFinalizar = true;

        this.residencia = {
            id: null,
            numero: null,
            is_predio: null,
            predio_nome: null,
            predio_andar: null,
            nome: null,
            rua_id: null,
            rua: null,
            quarteirao_id: null,
            quarteirao: null,
            has_quarteirao: null,
            bairro_id: null,
            bairro: null,
            has_distrito: false,
            municipio_id: null,
            distrito_id: null,
            provincia_id: null,
            is_active: null,
            user_id: null
        };

        this.servicoPosPago = {
            id: null,
            nome: null,
            descricao_operacao: null,
            dataContrato: null,
            conta_id: null,
            estado: null,
            tecnologia: null,
            ligacao_cacti: null,
            observacao: null,
            tarifario_id: null,
            moeda_id: null,
            imposto_id: null,
            valor: null,
            origem: null,
            destino: null,
            capacidade: null
        }

    }


    getPageFilterData(page: number) {
        if (this.filters.pagination.perPage == null) {
            return;
        }
        this.filters.pagination.page = Number.isInteger(page) ? page : 1;
        this.listClientes();
    }

    getPageFilterDataServicos(page: number) {
        if (this.filtroServicos.pagination.perPage == null) {
            return;
        }
        this.filtroServicos.pagination.page = Number.isInteger(page) ? page : 1;
        this.listarPedidos(this.servico.conta_id);
    }

    private registerConta() {
        if (this.conta.tipo_facturacao_id == null) {
            this.configService.showAlert('Seleccione o tipo de facturação', 'alert-danger', true);

        } else {
            var url = 'cliente/conta/register';
            if (this.conta.id != null) {
                url = 'cliente/conta/update/' + this.conta.id;
            }

            this.configService.loaddinStarter('start');

            try {
                this.http.__call(url, this.conta)
                    .subscribe(
                        res => {
                            if (Object(res).code == 500) {
                                this.configService.showAlert(Object(res).message, 'alert-danger', true);
                            } else {
                                this.configService.showAlert(Object(res).message, 'alert-success', true);
                                this._closeModal('closeModalconta');
                                this.configService.loaddinStarter('stop');
                                this.conta.numero_conta = null;
                                this.conta.agencia_id = null;
                                this.conta.descricao = null;
                                this.conta.estado = null;
                                this.conta.tipo_facturacao_id = null
                                this.conta.moeda_id = null
                                this.conta.dataEstado = null
                                this.getContasByCliente({ nome: this.cliente.nome, id: this.conta.cliente_id })

                            }
                        }
                    )
            } catch (error) {
            }
        }
    }

    private registerContacto() {
        if (this.contacto.nome == null) {
            this.configService.showAlert('Digite o Nome', 'alert-danger', true);
        }
        else if (this.contacto.telefone == null) {
            this.configService.showAlert('Digite o Telefone', 'alert-danger', true);
        } else {
            let url = 'cliente/contacto/register';
            if (this.conta.id != null) {
                url = 'cliente/contacto/update/' + this.contacto.id;
            }
            this.configService.loaddinStarter('start');
            this.http.__call(url, this.contacto).subscribe(
                res => {
                    if (Object(res).code == 500) {
                        this.configService.showAlert(Object(res).message, 'alert-danger', true);
                    } else {
                        this.configService.showAlert(Object(res).message, 'alert-success', true);
                        this.configService.loaddinStarter('stop');
                        this.contacto.nome = null;
                        this.contacto.telefone = null;
                        this.contacto.email = null;
                        this.contacto.tipo_contacto = null
                        this.listarContactosClientes({ nome: this.cliente.nome, id: this.contacto.cliente_id });

                    }
                }
            );
        }
    }

    /*
    private getProvincias() {
      this.http.call_get('provincia/selectBox', null).subscribe(
        response => {
          this.provincias = Object(response).data
        }
      );

    }
    */


    private selectBoxMunicipiosByProvincia(id) {

        /* console.log(id); */

        this.residencia.distrito_id = null;
        this.residencia.bairro_id = null;
        this.residencia.quarteirao_id = null;
        this.residencia.rua_id = null;

        this.http.call_get('municipio/getMunicipiosByProvincia/' + id, null).subscribe(
            response => {
                /* console.log(Object(response)); */
                this.municipios = Object(response).data;
            }
        );
    }


    private selectBoxDistritosByMunicipio(id) {

        this.distritos = null;

        if (!id) id = this.simpleFormCliente['value']['municipio_id'];

        this.residencia.bairro_id = null;
        this.residencia.quarteirao_id = null;
        this.residencia.rua_id = null;

        for (let i = 0; i < this.municipios.length; ++i) {
            if (this.municipios[i].id == id)
                this.residencia.has_distrito = this.municipios[i].has_distrito;
        }

        if (this.residencia.has_distrito) {
            this.http.call_get('distrito/getDistritosByMunicipio/' + id, null).subscribe(
                response => {
                    this.distritos = Object(response).data;
                }
            );
        }

        if (!this.residencia.has_distrito || !this.distritos) {
            this.selectBoxBairrosByMunicipio(id);
        }

    }


    private selectBoxQuarteiraosByBairro() {

        this.residencia.quarteirao_id = null;
        this.residencia.rua_id = null;

        for (let i = 0; i < this.bairros.length; ++i) {
            if (this.bairros[i].id == this.residencia.bairro_id)
                this.residencia.has_quarteirao = this.bairros[i].has_quarteirao;
        }

        if (this.residencia.has_quarteirao) {
            this.http.call_get('quarteirao/getQuarteiraosByBairro/' + this.residencia.bairro_id, null).subscribe(
                response => {
                    this.quarteiraos = Object(response).data;
                }
            );
        }

        if (!this.residencia.has_quarteirao) {
            this.selectBoxRuasByBairro();
        }

    }

    private selectBoxRuasByBairro() {
        this.http.call_get('rua/selectBoxByBairro/' + this.residencia.bairro_id, null).subscribe(
            response => {
                this.ruas = Object(response);
            }
        );
    }

    private selectBoxRuasByQuarteirao() {
        this.http.call_get('rua/getRuasByQuarteirao/' + this.residencia.quarteirao_id, null).subscribe(
            response => {
                this.ruas = Object(response).data;
            }
        );
    }


    private selectBoxBairrosByMunicipio(id) {

        if (!id) id = this.simpleFormCliente['value']['municipio_id'];

        this.http.call_get('bairro/selectBoxByMunicipio/' + id, null).subscribe(
            response => {
                this.bairros = Object(response);
            }
        );
    }

    private selectBoxBairrosByDistrito(id) {

        if (!id) id = this.simpleFormCliente['value']['distrito_id'];

        this.http.call_get('bairro/getBairrosByDistrito/' + id, null).subscribe(
            response => {
                this.bairros = Object(response).data;
            }
        );
    }

    private setPredioValue() {
        this.predio_flag = !this.predio_flag;
        this.moradia_title = (this.predio_flag) ? "Porta" : "Moradia";
    }

    private getRotaForm() {
        if (this.pedido.tipoPedido != null) {
            this.getRotaTipoPedido(this.pedido.tipoPedido)
        }
    }

    public getRotaTipoPedido(slug) {
        this.configService.loaddinStarter('start');

        this.http.call_get('rotaTipoPedido/' + slug, null).subscribe(
            response => {
                let dado = Object(response).data;
                this.rotaForm.id = dado.rota_crm_inicial;
                this.configService.loaddinStarter('stop');
            }
        )
    }

    public hideBottonAvancar() {
        this.rotaForm.id = null;
        this.pedido.tipoPedido = null;
    }

    private listTecnology() {
        let tecnology;

        if (this.pedido.tipoPedido == "COBRE_ADSL") {
            tecnology = "Cobre+ADSL";
        } else if (this.pedido.tipoPedido == "COBRE") {
            tecnology = "Cobre";
        } else {
            tecnology = this.pedido.tipoPedido;
        }

        this.getTarifarioTecnologia(tecnology)
    }

    public getTarifarioTecnologia(tecnology) {
        this.http.call_get('tarifario/tecnologia/' + tecnology, null).subscribe(
            response => {
                this.tarifarios = Object(response).data;
                this.configService.loaddinStarter('stop');
            }
        );
    }

    private planoPrecos = []

    public getPlanoPrecos() {
        this.http.call_get('planoPreco/selectBox', null).subscribe(
            response => {
                this.planoPrecos = Object(response).data;
                this.configService.loaddinStarter('stop');
            }
        );
    }

    private carregando = {
        tarifario: 'Seleccione o tarifario',
        tecnologia: 'Seleccione a tecnologia',
    }

    public getTecnologias() {
        this.carregando.tarifario = 'Carregando...';
        this.http.call_get('tarifario/planoPrecoTecnologiaSelectBox/' + this.servicoPosPago.tecnologia, null)
            .subscribe(
                response => {
                    this.tarifarios = Object(response).data;
                    this.carregando.tarifario = 'Seleccione o tarifario';
                }
            );
    }

    public getTecnologiaNome() {
        this.http.call_get('tecnologia/nome/' + this.servicoPosPago.tecnologia, null)
            .subscribe(
                response => {
                    if (this.servicoPosPago.tecnologia == null) {
                        this.servico.tecnologia = null;
                        this.resetFormNovoServico();
                    } else {

                        this.servico.tecnologia = Object(response).data.nome;
                    }
                }
            )
    }


    imponstos = [
        { id: 0, descricao: null }
    ]

    private getImposto() {
        this.http.__call('imposto/getall', null).subscribe(
            data => {
                var count = Object.keys(data).length;
                for (var i = 0; i < count; i++) {
                    this.imponstos[i] = data[i];
                }
            }
        );
    }

    private tecnologiaNull() {
        this.servicoPosPago.tecnologia == null
    }

    private btnImprimirContrato(id) {
    }

    private btnImprimirPedido(id) {
        this.PedidoReportService.imprimirPedido(id);
    }

    private estadosServicos = []
    private getEstadosServicos() {
        this.http.call_get('servico/estado/selectBox', null).subscribe(
            response => {
                this.estadosServicos = Object(response).data
            }
        );

    }


    private servicoPosPago = {
        id: null,
        nome: null,
        descricao_operacao: null,
        dataContrato: null,
        conta_id: null,
        estado: null,
        tecnologia: null,
        ligacao_cacti: null,
        observacao: null,
        tarifario_id: null,
        moeda_id: null,
        imposto_id: null,
        valor: null,
        origem: null,
        destino: null,
        capacidade: null
    }

    loadingServicoPosPago = false;

    private registerServicoPosPago() {

        this.servicoPosPago.conta_id = this.servico.conta_id
        if (this.servicoPosPago.nome == null || this.servicoPosPago.tecnologia == null
            || this.servicoPosPago.tecnologia == null
            || this.servicoPosPago.dataContrato == null || this.servicoPosPago.estado == null || this.servicoPosPago.tarifario_id == null
            || this.servicoPosPago.ligacao_cacti == null || this.servicoPosPago.moeda_id == null || this.servicoPosPago.imposto_id == null
            || this.servicoPosPago.capacidade == null || this.servicoPosPago.destino == null || this.servicoPosPago.origem == null) {
            this.configService.showAlert("Todos os campos são de caracter obrigatorio", 'alert-danger', true);
            return;
        }

        this.loadingServicoPosPago = true;
        this.http.__call("servico/storeServicoPospago", this.servicoPosPago).subscribe(
            res => {
                if (Object(res).code == 200) {
                    this.configService.showAlert(Object(res).message, 'alert-success', true);
                    this.resetFormNovoServico();
                } else {
                    this.configService.showAlert(Object(res).message, 'alert-danger', true);
                }
                this.loadingServicoPosPago = false;
            }
        );
    }

    private tecnologias = [];

    private selectBoxTecnologias() {
        this.carregando.tecnologia = 'Carregando...'
        this.http.__call("tecnologia/selectBox", { tipo_facturacao_id: ['POS-PAGO'] }).subscribe(
            res => {
                this.tecnologias = Object(res).data
                this.carregando.tecnologia = 'Seleccione o serviço'
            }
        );
    }

    private selectBoxTecnologiasPrePago() {
        this.carregando.tecnologia = 'Carregando...'
        this.http.__call("tecnologia/selectBox", { tipo_facturacao_id: ['PRE-PAGO'] }).subscribe(
            res => {
                this.tecnologias = Object(res).data
                this.carregando.tecnologia = 'Seleccione o serviço'
            }
        );
    }

    tarifario_servico = {
        tarifario_nome: null,
        tarifario_id: null,
        tarifario_id_novo: null,
        servico_id: null,
        tecnologia_id: null,
        chaveServico: null,
        conta_id: null,
    }
    private setServico(servico) {
        this.tarifario_servico.chaveServico = servico.chaveServico
        this.tarifario_servico.tarifario_id = servico.tarifario_id
        this.tarifario_servico.servico_id = servico.servicoID
        this.tarifario_servico.conta_id = servico.conta_id
        this.tarifario_servico.tarifario_nome = servico.tarifario + " (" + servico.tecnologia + ")";
        this.selectBoxTarifarioNovo(servico);
    }

    private tarifarioNovo = []
    private selectBoxTarifarioNovo(servico) {
        this.carregando.tarifario = 'Carregando...'
        this.http.__call("tarifario/selectBoxTarifarioNovo", { tipo_facturacao_id: ['PRE-PAGO'], tecnologia: servico.tecnologia, tarifario: servico.tarifario_id }).subscribe(
            res => {
                this.tarifarioNovo = Object(res).data
                this.carregando.tarifario = 'Seleccione o tarifario'
            }
        );
    }

    private editarTarifarioServico() {
        if (this.tarifario_servico.tarifario_id_novo == null) {
            this.configService.showAlert("Todos os campos são de caracter obrigatorio", 'alert-danger', true);
            return;
        }
        this.http.__call("servico/mudarTarifarioServico", this.tarifario_servico).subscribe(
            res => {
                if (Object(res).code === 200) {
                    this.listarPedidos(this.tarifario_servico.conta_id);
                    this.configService.showAlert(Object(res).message, 'alert-success', true);
                    this.tarifario_servico = {
                        tarifario_nome: null,
                        tarifario_id: null,
                        tarifario_id_novo: null,
                        servico_id: null,
                        tecnologia_id: null,
                        chaveServico: null,
                        conta_id: null,
                    }
                } else {
                    this.configService.showAlert(Object(res).message, 'alert-danger', true);
                }
            }
        );
    }

    private interconexao = {
        chaveServico: null,
        cliente: null,
        moeda_id: null,
        codigo: null,
        tarifario_id: null,
        estado: null
    }

    private createParceriaCliente() {
        this.interconexao.cliente = this.cliente;

        if (this.interconexao.cliente == null || this.interconexao.moeda_id == null || this.interconexao.codigo == null || this.interconexao.estado == null || this.interconexao.chaveServico == null) {
            this.configService.showAlert("Todos os campos são de caracter obrigatorio", 'alert-danger', true);
            return;
        }
        this.http.__call("cliente/parceria/create", this.interconexao).subscribe(
            res => {
                if (Object(res).code === 200) {
                    this.configService.showAlert(Object(res).message, 'alert-success', true);
                    this.interconexao = {
                        chaveServico: null,
                        cliente: null,
                        moeda_id: null,
                        codigo: null,
                        tarifario_id: null,
                        estado: null
                    }
                } else {
                    this.configService.showAlert(Object(res).message, 'alert-danger', true);
                }
            }
        );
    }

    private tarifariosInterconexao: any = []

    private selectBoxTarifarioTecnologiaInterconexao() {
        this.tarifariosInterconexao = [];
        this.carregando.tarifario = 'Carregando...';
        this.http.call_get('selectBoxTarifarioTecnologiaInterconexao', null)
            .subscribe(
                response => {
                    this.tarifariosInterconexao = Object(response).data;
                    this.carregando.tarifario = 'Seleccione o tarifario';
                }
            );
    }

    private adiantamento = {
        valor: null,
        cliente_id: null,
        banco_id: null,
        referencia: null,
        data_pagamento: null
    };


    private registarAdiantamento() {
        this.adiantamento.cliente_id = this.cliente.id;
        if (this.adiantamento.cliente_id == null || this.adiantamento.valor == null || this.adiantamento.banco_id == null || this.adiantamento.referencia == null || this.adiantamento.data_pagamento == null) {
            this.configService.showAlert("Todos os campos são de caracter obrigatorio", 'alert-danger', true);
            return;
        }
        this.http.__call("adiantamento/create", this.adiantamento).subscribe(
            res => {
                if (Object(res).code === 200) {
                    this.configService.showAlert(Object(res).message, 'alert-success', true);
                    this.adiantamento = {
                        valor: null,
                        cliente_id: null,
                        banco_id: null,
                        referencia: null,
                        data_pagamento: null
                    };
                } else {
                    this.configService.showAlert(Object(res).message, 'alert-danger', true);
                }
            }
        );
    }

    //EditarPedido
    EditarPedidoFunction(pedidoId, tipoPedido, client_id) {

        this.EditarPedido.pedidoId = pedidoId
        this.EditarPedido.client_id = client_id
        this.getTarifarioBYTecnologia(tipoPedido)
        this.listarTipoPedidos()
    }

    LimparCampos() {
        this.EditarPedido.tarifario = null;
        this.EditarPedido.telefone = null;
        this.pedido.tipoPedido = null;
    }
    atualizarPedido() {
        this.submitted = true

        if (this.EditarPedido.tarifario == null || this.pedido.tipoPedido == null || this.EditarPedido.telefone == null) {
            this.configService.showAlert('Preecha todos os campos', 'alert-danger', true);
        } else {

            this.http.__call('pedido/update_test', { pedidoId: this.EditarPedido.pedidoId, tipoPedido: this.pedido.tipoPedido, tarifario: this.EditarPedido.tarifario, telefone: this.EditarPedido.telefone }).subscribe(response => {
                this.configService.showAlert('Preecha todos os campos', 'alert-success', true);
            })
        }
    }


    private getCaucaoById(id) {
        this.http.call_get('caucao/getCaucaoById/' + id, null).subscribe(
            response => {
                this.tipo_cliente_view = (Object(response).slug == "ACTIVO") ? true : false;
            }
        );
    }

    public getConfiguracaos() {
        let result = null;

        const slugs = [
            this.config.provincia_default,
            this.config.tipo_identidade_default,
            this.config.estado_rescisao_default,
            this.config.caucao_default,
            this.config.gestor_cliente_view,
            this.config.direccao_view
        ];

        let cliente = {
            id: null,
            nome: null,
            numero_identificacao: null,
            email: null,
            nif: null,
            tipo_identidade_id: null,
            tipo_cliente_id: null,
            genero_id: null,
            telefone: null,
            provincia_id: null,
            municipio_id: null,
            morada: null,
            gestor_cliente_id: null,
            direccao_id: null
        }


        for (let index = 0; index < slugs.length; index++) {

            this.http.__call('configuracao/getConfiguracaobySlug/' + slugs[index], null).subscribe(
                response => {
                    if (Object(response).code != 200) {
                        result = null;
                    }
                    else {

                        result = Object(response).data;

                        if (slugs[index] == this.config.provincia_default) {
                            this.default_id_province = result.valor
                            this.simpleFormCliente.patchValue({ provincia_id: result.valor })
                            cliente.provincia_id = result.valor;
                            this.residencia.provincia_id = result.valor;
                            this.selectBoxMunicipiosByProvincia(result.valor);
                        }

                        if (slugs[index] == this.config.tipo_identidade_default) {
                            cliente.tipo_identidade_id = result.valor;
                        }

                        if (slugs[index] == this.config.estado_rescisao_default) {
                            this.contrato.estado_rescisao_id = result.valor;
                        }

                        if (slugs[index] == this.config.caucao_default) this.getCaucaoById(result.valor);

                        if (slugs[index] == this.config.gestor_cliente_view) this.gestor_cliente_view = Boolean(result.valor);

                        if (slugs[index] == this.config.direccao_view) this.direccao_view = Boolean(result.valor);
                    }
                });
        }

        this.clienteEdit = cliente;
    }


    private movimentoAdiantamentos: any = null;

    loadingMovimentoAdiantamento: boolean = false;
    private listarMovimentoAdiantamentos(cliente: any) {
        this.loadingMovimentoAdiantamento = true;
        this.cliente = cliente;
        this.http.call_get("clientes/adiantamentos/" + cliente.id, null)
            .subscribe((response) => {
                this.loadingMovimentoAdiantamento = false;
                this.movimentoAdiantamentos = Object(response).data;
            });
    }


    public cleanContador() {

        //console.log(item);

        this.contador = {
            ...this.contador,
            id: null,
            numero_serie: null,
            marca: null,
            ultima_leitura: null,
            modelo: null,

            tipo_contador: null,
            classe_precisao: null,
            medicao: null,
            calibre: null,
            digitos: null,
            fabricante: null,
            centro_distribuicao: null,

            precisao: null,
            leitura: 0,
            data_leitura: null,
            leitura_origem: null,
            contrato_id: null,
            contador_id: null,
            is_leitura_inicial: false,
            new_leitura_by_contrato: false,
            observacao: null
        }

        this.view_serie_contador = false;
    }

    darAcesso() {
        if ((this.cliente.email) && (this.cliente.telefone)) {
            Swal.fire({
                title: `Deseja dar acesso ao cliente ${this.cliente.nome}`,
                text: "Dar Acesso!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Dar Acesso!',
                cancelButtonText: 'Cancelar!',
                reverseButtons: true
            }).then((result) => {
                if ((result.value)) {
                    this.confirmarAcesso()
                } else {
                    Swal.fire('Operação Cancelada!', 'info')
                }
            })
        } else {
            this.swalMessage()
        }
    }

    confirmarAcesso() {
        this.http.__call("cliente/darAcesso", this.cliente)
            .subscribe(res => {

            })
    }

    swalMessage() {
        Swal.fire({
            position: 'center',
            type: 'info',
            title: 'Cliente sem email ou telefone!',
            showConfirmButton: false,
            timer: 2000
        })
    }
    public getContadoresDisponiveisByNumeroSerie(flag = true) {


        this.view_serie_contador = false;
        let aux_serie = this.contador.numero_serie;

        this.contador_title = "Associar";
        this.contador_join = true;

        this.http.__call('contador/getContadoresDisponiveisByNumeroSerie', { start: 1, end: 15, search: this.contador.numero_serie }).subscribe(
            response => {

                this.contadores = Object(response).data.data;

                //console.log(Object(response));
                this.configService.loaddinStarter('stop');
            }
        );

        this.cleanContador();
        this.contador.numero_serie = aux_serie;

        //console.log(this.contadores);

    }

    private getContratoById(id, contrato = null) {
        this.contador.contador_antigo = contrato ? contrato.contador_id : null
        this.configService.loaddinStarter('start');

        this.http.call_get('contrato/getContratoById/' + id, null).subscribe(
            res => {
                this.contrato = Object(res).contrato;
                this.dividas = Object(res).dividas;
                this.dividasV()
                this.configService.loaddinStarter('stop');

            }
        );

    }

    public dividasV() {

        this.totalDivida = this.dividas.reduce(function (acumulador, atual) {
            let temDivida
            if (atual.valor_aberto) {
                temDivida = true
            }
            return {
                totalDivida: acumulador.totalDivida + atual.valor_aberto,
                temDivida
            }
        }, {
            totalDivida: 0,
            temDivida: false
        })

    }

    public getEstadosAlteracao(contrato_id: any) {
        this.getContratoById(contrato_id)
        this.estado_rescisaos = []
        this.configService.loaddinStarter('start');
        this.http.call_get('motivo-alteracao/selectBoxEstado', null).subscribe(
            response => {
                this.estado_rescisaos = Object(response);

                this.configService.loaddinStarter('stop');
            }
        );
    }

    private getLocalConsumoByContratoId(contrato_id) {

        this.configService.loaddinStarter('start');

        this.http.call_get('local-consumo/getLocalConsumoByContratoId/' + contrato_id, null).subscribe(
            response => {

                this.local_consumo = Object(response);

                this.local_consumo.cil_flag = (Object(response) != null) ? true : false;
                this.local_consumo.predio_nome = ((this.local_consumo.cil != null) ? '[' + this.local_consumo.cil + '] ' : '[0000-000-000] ') + ((this.local_consumo.is_predio) ? 'Prédio ' + this.local_consumo.predio_nome + ', ' + (this.local_consumo.predio_andar ? this.local_consumo.predio_andar + ' º Andar - ' : '') + 'Porta ' : 'Residência ') + this.local_consumo.moradia_numero;
                this.configService.loaddinStarter('stop');
            }
        );
    }


    public setContador(item: any) {

        console.log(item);

        this.contador = {
            ...this.contador,
            id: item.id,
            numero_serie: item.numero_serie,
            marca: item.marca,
            ultima_leitura: item.ultima_leitura,
            modelo: item.modelo,

            tipo_contador: item.tipo_contador,
            classe_precisao: item.classe_precisao,
            medicao: item.medicao,
            calibre: item.calibre,
            digitos: item.digitos,
            fabricante: item.fabricante,
            centro_distribuicao: item.centro_distribuicao,

            precisao: null,
            leitura: 0,
            data_leitura: moment(new Date()).format('YYYY-MM-DD'),
            leitura_origem: null,
            contrato_id: this.contrato.id,
            is_leitura_inicial: true,
            contador_id: item.id,
            new_leitura_by_contrato: true,
            observacao: null
        }

        this.view_serie_contador = false;
    }


    public saveContador() {

        Swal.fire({
            title: 'Aviso!',
            text: "Deseja alterar a medição do contrato para leitura ?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim, Alterar',
            cancelButtonText: 'Não, não Alterar!',
            reverseButtons: true
        }).then((result) => {
            console.log('contador=>', this.contador)
            if (result.value) {
                this.isAlterarMedicao = true;
            }
            this.configService.loaddinStarter('start');
            this.http.__call('local-consumo/updateLocalConsumoByContador/' + this.contrato.id, {
                contador_id: this.contador.id,
                contador_antigo: this.contador.contador_antigo,
                contador_join: this.contador_join,
                data_leitura: this.contador.data_leitura,
                ultima_leitura: this.contador.leitura,
                isAlterarMedicao: this.isAlterarMedicao,
                user_id: this.currentUser.user.id
            }).subscribe(
                response => {

                    if (Object(response).code == 200) {
                        this.getContratosByConta(this.servico.conta_id);

                        if ((this.contador.leitura != null) && (this.contador.new_leitura_by_contrato == true)) {
                            this.create_leitura_inicial();
                        }
                        this.cleanContador();
                        this.view_serie_contador = false;
                    }

                    this.getContadoresDisponiveisByNumeroSerie(false);
                    this.configService.loaddinStarter('stop');
                }
            );
        })

    }

    create_leitura_inicial() {
        this._rotaService.CreateLeitura(this.contador)
            .subscribe(
                response => { }
            )
    }

    convertNumberToPositive(event) {
        this.contador.leitura = Math.abs(this.contador.leitura);
    }

    clearSearch() {
        this.filters.search = ""
    }
}


