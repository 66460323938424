import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { HttpService } from "src/app/providers/http/http.service";
import { ConfigService } from "src/app/providers/config/config.service";
import { AuthService } from "src/app/providers/auth/auth.service";
import { Router, ActivatedRoute } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { debounceTime, finalize } from "rxjs/operators";
import Swal from "sweetalert2";
import { ApiService } from "src/app/providers/http/api.service";
import * as moment from "moment";
import { RotaService } from "../../config-rotas/rotas.service";
import { LocalInstalacaoService } from "./local-instalacao.service";
@Component({
  selector: "app-executar-ordem-servico",
  templateUrl: "./executar-ordem-servico.component.html",
  styleUrls: ["./executar-ordem-servico.component.css"],
})
export class ExecutarOrdemServicoComponent implements OnInit {

  private primeiros_niveis: any = [];
  private segundo_niveis: any = [];
  private terceiro_niveis: any = [];
  private departamentos: any = [];
  private estado_rescisaos: any = []
  private duracoes: any = [];
  private funcoes: any = [];
  private artigos: any = [];
  private produtoCategorias: any = [];
  private recursos: any = [];
  private tipos: any = [];
  private classificacoes: any = [];
  private prioridades: any = [];
  private causas: any = [];
  private sintomas: any = [];
  private clientes: any = [];
  private distritos: any = []
  private bairros: any = []
  private local_consumo: any = [];
  private isAlterarMedicao: boolean = false;
  public currentUser: any;
  triggerConsequencia: any = null
  tipo_medicaos: any = []
  _leitura: any = {
    contador_id: null,
    contrato_id: null,
    leitura: null,
    ultima_leitura: null,
    data_leitura: null,
    nao_leitura: false,
    motivo: null,
    foi_pago: false,
    classificacao: null,
    //is_leitura_inicial: this.is_leitura_inicial
  }
  private colaboradores: any = [];

  private contas: any = [];
  private contratos: any = [];

  private isFinalizar: boolean = true;
  private isDisabled: boolean = false;
  private is_sintoma: boolean = false;
  private is_causa: boolean = false;
  private validateButton: any = null;
  private is_responsavel: boolean = false;
  private qtdUtilizadoDecimal: boolean = true;
  private view_client = true;
  dropdownSettings = {};
  dropdownSettingsSintomas = {};

  private dayInicio: any;
  private hourInicio: any;
  private minutesInicio: any;
  private timeInicio: any;

  routerParamId: any;
  routerParamAgendamentoId: any;
  routerParamOsTipoId: any
  private addRows: boolean = true;
  private title: string = "Registar Local de Instalação";
  private causasArray: any = [];
  private sintomasArray: any = [];

  private agendamentodayFim: any;
  private agendamentohourFim: any;
  private agendamentominutesFim: any;

  private hourFimAgendamento: any;
  private minutesFimAgendamento: any;

  private associacao_cliente = null;
  private facturacao_posterior: boolean = false;

  private set_contador: boolean = false;
  private view_serie_contador: boolean = false;
  private contador_join: boolean = true;
  private contador_title: string = "associação";
  private leitura_antiga: number

  private permission: string = null;
  @Input() ordem_sigla: string = "OT";

  private showContadores_flag: boolean = false;

  private contadores: any[];
  private contrato = {

    id: null,
    tipo_contracto_id: null,
    tipo_contrato: null,
    tipo_medicao_id: null,
    tipo_medicao: null,
    tipo_medicao_slug: null,
    tipo_facturacao_id: null,
    tipo_facturacao: null,
    tipologia_cliente_id: null,
    tipologia_cliente: null,
    tipologia_cliente_juro_mora: null,
    tipologia_cliente_sujeito_corte: null,
    tipologia_cliente_caucao: null,
    nivel_sensibilidade_id: null,
    nivel_sensibilidade: null,
    objecto_contrato_id: null,
    objecto_contrato: null,
    tarifario: null,
    classe_tarifario: null,
    classe_tarifario_consumo_minimo: null,
    classe_tarifario_consumo_maximo: null,
    numero_habitantes: null,
    data_inicio: null,
    data_fim: null,
    rua_id: null,
    morada_correspondencia_flag: false,
    estado_contrato_id: null,
    estado_contrato: null,
    estado_contrato_slug: null,

    data_rescisao: null,
    motivo_recisao_id: null,
    estado_rescisao_id: null,
    motivo_recisao: null,
    motivo_recisao_flag: null,
    conta_id: null,

    contador_id: null,
    numero_serie: null,

    instalacao_sanitaria_qtd: null,
    reservatorio_flag: null,
    reservatorio_capacidade: null,
    piscina_flag: null,
    piscina_capacidade: null,
    jardim_flag: null,
    campo_jardim_id: null,
    campo_jardim: null,
    poco_alternativo_flag: null,
    fossa_flag: null,
    fossa_capacidade: null,
    acesso_camiao_flag: null,
    anexo_flag: null,
    anexo_quantidade: null,
    caixa_contador_flag: null,
    abastecimento_cil_id: null,
    abastecimento_cil: null,

    cliente_id: null,
    cliente: null,
    info_source: null,

    local_consumo_id: null,
    local_instalacao_id: null,
  };


  private contador = {
    id: null,
    numero_serie: null,
    marca: null,
    ultima_leitura: null,
    modelo: null,
    leitura_actual: null,
    data_leitura: null,
    tipo_contador: null,
    classe_precisao: null,
    medicao: null,
    calibre: null,
    digitos: null,
    fabricante: null,
    centro_distribuicao: null,

    observacao: null,
  };

  private agendamento = {
    id: null,
    data_previsao_inicio: null,
    fim_execucao: null,
    inicio_execucao: null,
    data_previsao_fim: null,
    dif_dias_prevista: null,
    dif_horas_prevista: null,
    dif_minutos_prevista: null,
    dif_dias_definida: null,
    dif_horas_definida: null,
    dif_minutos_definida: null,
    descricao: null,
    descricao_intervencao: null,
    codigo_agendamento: null,
    latitude: null,
    longitude: null
  };

  private recursosHumanos = {
    departamento_id: null,
    qtd_colaborador: null,
    funcao_id: null,
    colaborador_id: null,
    colaborador_nome: null,
    qtdRh: null,
  };

  private consumiveis = {
    armazem_id: null,
    artigo_id: null,
    qtdArtigo: null,
    qtd_stock: null,
    categoria_id: null,
    reserva: null,
    unidade: null,
    descricao_material: null,
  };

  private ordemServico = {
    descricao: null,
    descricao_old: null,
    cliente_nome: null,
    success_ordem_servico: false,
    sintoma_id: null,
    cliente_id: null,
    classificacao_id: null,
    cliente_contrato_id: null,
    cliente_conta_id: null,
    classificacao_descricao: null,
    prioridade_id: null,
    prioridade_descricao: null,
    facturacao_posterior: false,
    factura_id: null,
    tipo_descricao: null,
    tipo_cliente_flag: null,
    responsavel_nome: null,
    data_prevista: null,
    agendamentoGerado: null,
    codigo: null,
    titulo: null,

    contrato_id: null,
    local_consumo_id: null,
    contador_id: null,
    local_instalacao_id: null,

    ot_trigger_descricao: null,
    ot_trigger_flag: null,

    contador: null,
    leitura: null
  };

  private pagination = {
    start: 1,
    end: 10,
    search: null,
    contrato: null,
    municipio_id: "T"
  };

  currtentPage: number;

  dropdownSettingsDepartamentos = {};
  private departamentoArray: any = [];
  private dropdownDepartamentos: any = [];

  //START FACTURAÇÃO
  @ViewChild("search") search;
  @ViewChild("qtd") qtd;

  @ViewChild("valor") valor;
  @ViewChild("serie") serie;
  @ViewChild("observacao") observacao;
  @ViewChild("leitura") leitura;
  @ViewChild('closebutton') closebutton;

  getProvincia: any = localStorage.getItem('_token')

  private desconto: number = 0;

  private dataActual = new Date();
  private totalFacturacao = 0;
  private totalSemImposto = 0;
  private totalComImposto = 0;
  private produts: any = [];

  private quantidade = 1;
  private produtoId: number;
  private clienteId: number;
  private serieId: number;
  private formasPagamentos: any = [];
  private validarFacturaRecibo: boolean = false;


  private contas_clientes: any = [];
  private contratos_conta_cliente: any = [];

  private numero_telefone: string;
  private searchCliente: string;


  private default: any = [];

  tarifario_descricao = "";

  private facturacao = {
    cliente: {
      id: null,
      nome: null,
      contribuinte: null,
      morada: null,
      telefone: null,
      conta_id: null,
      contrato_id: null,
    },
    serie: {
      id: null,
      sigla: null,
      nome: null,
      proximo_numero: null,
    },
    produto: [],
    pagamento: {
      forma: null,
      valor_recebido: 0.0,
      total_valor_recebido: 0.0,
      troco: 0.0,
      total_pago: null,
      referencia: null,
      data_pagamento: null,
      forma_pagamento_id: null,
      bancos: [],
      adiantamentos: [],
      is_adiantamento: 1,
      linha_pagamentos: [],
    },
    totalComImposto: 0.0,
    totalSemImposto: 0.0,
    total: 0.0,
    success_facturacao: false,
    facturaGerada: null,
    data_vencimento: moment(Date()).format("YYYY-MM-DD").toString(),
    moeda: null,
  };

  private disabledPagamento = true;
  private disabledPagamentoAdiantamento = true;

  private series: any = [];
  private activeButtom = {
    cliente: false,
    produto: false,
  };
  private municipio_id: "T"
  private items_produtos: any = [];


  private produto_selecionado = null;
  private preco: number;
  private municipios: []
  private objecto_contratos: any = []
  //END FACTURAÇÃO

  constructor(
    private auth: AuthService,
    private http: HttpService,
    private route: ActivatedRoute,
    private router: Router,
    private _rotaService: RotaService,
    private configService: ConfigService,
    private api: ApiService,
    private loiService: LocalInstalacaoService
  ) {
    this.currentUser = this.auth.currentUserValue;
    this.getProvincia = JSON.parse(this.getProvincia)
    //this.route.params.subscribe(params => this.currtentPage = params['page']);
  }

  ngOnInit() {
    this.getObjectosContratos()
    this.getSuspenderContrato()
    this.getEstadosRescisao()
    this.getRouterParamId();
    this.getEstadosRescisao()
    this.getTiposMedicaos()
    this.selectBox_by_name()

  }

  private carregando = {
    nivel_2: "Seleccione o equipamento",
    funcao: "Seleccione a função",
    material: "Seleccione o material",
    categoria: "Seleccione a categoria",
    tipo: "Seleccione o tipo",
  };

  public getRouterParamId() {
    this.routerParamId = this.route.snapshot.params.id;
    this.routerParamAgendamentoId = this.route.snapshot.params.agendamento_id;
    this.routerParamOsTipoId = this.route.snapshot.params.os_tipo_id;
    this.loiService.os_id = this.routerParamId;

    console.log("this.loiService.os_id", this.loiService.os_id)

    this.permission = localStorage.getItem('permission');

    if (this.permission == "listagem_ot_comercial") {
      this.getDropDownDepartamentos();
      this.ordem_sigla = "OS";
    } else {
      this.ordem_sigla = "OT";
    }

    //console.log(this.routerParamId)

    if (this.routerParamId != undefined) {
      this.getArmazens();
      this.getAgendamentoById(this.routerParamAgendamentoId);
      this.getOrdemById(this.routerParamId);


      setTimeout(() => {

        if (this.ordemServico.ot_trigger_flag == "REMOCAO-CONTADOR" && this.ordemServico.contador != null) {
          this.contador_title = "remoção";
          this.contador_join = false;
        }
        else {
          this.contador_title = "associação";
          this.contador_join = true;
        }

        console.log(this.contador_join)

        console.log("contador_join")
        // console.log(this.contador_join)
      }, 2000);
    }
  }

  provincia: any = null

  private selectBox_by_name() {
    console.log('get provincia', this.getProvincia)
    this.http.__call('provincia/selectBox_by_name', {
      flag: this.getProvincia.user.empresa.city
    }).subscribe(
      response => {
        this.provincia = Object(response).data;
        console.log('this.provincia===>', this.provincia, this.provincia.id)
      }
    );
  }

  private getDropDownDepartamentos() {
    this.http.call_get('departamento/selectBox', null).subscribe(
      response => {
        this.dropdownDepartamentos = Object(response).data;

        this.dropdownSettingsDepartamentos = {
          singleSelection: false,
          idField: 'id',
          textField: 'descricao',
          selectAllText: 'Seleccione Todos',
          unSelectAllText: 'DeSeleccionar Todos',
          itemsShowLimit: 10,
          allowSearchFilter: true
        };

      }
    );
  }

  onItemSelectDepartamento(item: any) {
    this.departamentoArray.push(item);
  }
  onSelectAllDepartamentos(items: any) {
    this.departamentoArray = items;
  }

  private getPermission() {
    this.permission = localStorage.getItem('permission');
  }

  private getOrdemById(id) {
    this.http
      .call_get("ordemservico/ordem-by-id/" + id, null)
      .subscribe((response) => {
        this.ordemServico.codigo = Object(response).data.codigo;
        this.ordemServico.titulo = Object(response).data.titulo;
        this.ordemServico.descricao_old = Object(response).data.descricao;
        this.ordemServico.data_prevista = Object(response).data.data_prevista;
        this.ordemServico.prioridade_descricao =
          Object(response).data.prioridade_descricao;
        this.ordemServico.responsavel_nome =
          Object(response).data.responsavel_nome;
        this.ordemServico.tipo_descricao = Object(response).data.tipo_descricao;
        this.ordemServico.cliente_id = Object(response).data.cliente_id;
        this.ordemServico.cliente_conta_id = Object(response).data.cliente_conta_id;
        this.ordemServico.cliente_contrato_id = Object(response).data.contrato_id;
        this.ordemServico.tipo_cliente_flag =
          Object(response).data.tipo_cliente_flag;
        this.ordemServico.ot_trigger_descricao =
          Object(response).data.ot_trigger_descricao;
        this.ordemServico.ot_trigger_flag =
          Object(response).data.ot_trigger_flag;
        this.ordemServico.classificacao_descricao =
          Object(response).data.classificacao_descricao;
        this.ordemServico.facturacao_posterior = Object(response).data.facturacao_posterior;
        this.associacao_cliente = Object(response).data.tipo_cliente_flag;
        this.facturacao_posterior = Object(response).data.facturacao_posterior;

        this.facturacao.cliente.id = Object(response).data.cliente_id;
        this.facturacao.cliente.nome = Object(response).data.cliente_nome;
        this.facturacao.cliente.contribuinte = Object(response).data.numero_identificacao;
        this.facturacao.cliente.telefone = Object(response).data.cliente_telefone;
        this.facturacao.cliente.morada = Object(response).data.cliente_morada;


        if (this.facturacao_posterior) {
          //FACTURAÇÃO
          this.getMunicipios()
          this.listarseries();
          this.configService.listarBancos();
          this.configService.listarMoedas();
          this.listarFormaPagamentos();
          this.getServicos(" ");
          this.getContas(this.ordemServico.cliente_id);
          //END FACTURAÇÃO
        }

        this.ordemServico.contrato_id = Object(response).data.contrato_id;
        this.ordemServico.contador = Object(response).data.contador;
        this.ordemServico.leitura = Object(response).data.contador_ultima_leitura;
        this.leitura_antiga = this.ordemServico.leitura
        this.ordemServico.local_instalacao_id =
          Object(response).data.local_instalacao_id;
        this.ordemServico.local_consumo_id =
          Object(response).data.local_consumo_id;

        // console.log(Object(response).data);
      });
  }

  private getConsumiveisByAgendamento(id) {
    this.http
      .call_get("ordemservico/consumiveis-by-agendamento/" + id, null)
      .subscribe((response) => {
        this.consumiveisSelecionados = Object(response).data;
      });
  }

  private getColaboradoresByAgendamento(id) {
    this.http
      .call_get("ordemservico/colaboradores-by-agendamento/" + id, null)
      .subscribe((response) => {
        this.recursosSelecionados = Object(response).data;

      });
  }

  /*  keyPress(event, item) {
     var x = event.key;
     var y: number = +x;

     if (y < 0) {
       this.configService.showAlert('Hora gasta não pode ser menor que 0', "alert-danger", true);
     } else if (!y) {
       this.configService.showAlert('Digite apenas número', "alert-danger", true);
     } else {
       this.adicionarHoraGasta(event.key, item)
     }

   } */

  adicionarInicioEst(item) {
    var replace = item.data_definida_inicio.replace("T", " ");
    var date = new Date(replace);

    this.dayInicio = date.getDay();
    this.hourInicio = date.getHours();
    this.minutesInicio = date.getMinutes();
    this.timeInicio = date.getTime();

    this.adicionarInicioGasta(item);
  }

  adicionarFimEst(item) {
    var replace1 = item.data_definida_inicio.replace("T", " ");
    var date1 = new Date(replace1);

    this.dayInicio = date1.getDay();
    this.hourInicio = date1.getHours();
    this.minutesInicio = date1.getMinutes();
    this.timeInicio = date1.getTime();

    var replace = item.data_definida_fim.replace("T", " ");

    if (item.data_definida_inicio == null || item.data_definida_inicio == "") {
      this.configService.showAlert(
        "Digite a Data definida de início do(a) " + item.nome_colaborador,
        "alert-danger",
        true
      );
    } else if (item.data_definida_fim == null || item.data_definida_fim == "") {
      this.configService.showAlert(
        "Digite a Data definida de fim do(a) " + item.nome_colaborador,
        "alert-danger",
        true
      );
    } else if (item.data_definida_inicio >= item.data_definida_fim) {
      this.configService.showAlert(
        "A data fim definida não pode ser igual ou inferior a data de início definida - Colaborador(a): " +
        item.nome_colaborador,
        "alert-danger",
        true
      );
    } else {
      var date = new Date(replace);

      var dayFim = date.getDay();
      var hourFim = date.getHours();
      var minutesFim = date.getMinutes();
      var timeFim = date.getTime();

      var data = this.hourInicio * 3600 + this.minutesInicio * 60;
      var data2 = hourFim * 3600 + minutesFim * 60;
      var diferenca = data2 - data;

      this.hourInicio = Math.floor(diferenca / 3600);
      this.minutesInicio = Math.floor(
        (diferenca - this.hourInicio * 3600) / 60
      );

      var condincao =
        this.hourInicio +
        "" +
        (this.minutesInicio.toString().length < 2
          ? "0" + "" + this.minutesInicio
          : this.minutesInicio);

      var diffHoras =
        parseInt(condincao) > 759
          ? parseInt(this.hourInicio) - 8
          : this.hourInicio;
      var diffDias = dayFim - this.dayInicio;
      diffDias = parseInt(condincao) > 759 ? diffDias + 1 : diffDias;

      this.adicionarHoraGasta(item, diffDias, diffHoras, this.minutesInicio);
    }
  }

  public adicionarHoraGasta(item, diffDias, diffHoras, diffMinutos) {
    var validar = 0;

    var diffDiasReplace = diffDias.toString();
    var diffHorasReplace = diffHoras.toString();
    var diffMinutosReplace = diffMinutos.toString();

    var recursos = {
      nome_colaborador: item.nome_colaborador,
      os_agendamento_colaborador_id: item.os_agendamento_colaborador_id,
      colaborador_id: item.colaborador_id,
      agendamento_id: item.agendamento_id,
      nome_departamento: item.nome_departamento,
      is_responsavel: item.is_responsavel,
      nome_funcao: item.nome_funcao,
      data_prevista_inicio: item.data_prevista_inicio,
      data_prevista_fim: item.data_prevista_fim,
      dif_dias_prevista: item.dif_dias_prevista,
      dif_horas_prevista: item.dif_horas_prevista,
      dif_minutos_prevista: item.dif_minutos_prevista,
      data_definida_inicio: item.data_definida_inicio,
      data_definida_fim: item.data_definida_fim,
      dif_dias_definida: parseInt(diffDiasReplace.replace("-", "")),
      dif_horas_definida: parseInt(diffHorasReplace.replace("-", "")),
      dif_minutos_definida: parseInt(diffMinutosReplace.replace("-", "")),
    };

    if (this.recursosSelecionados.length >= 1) {
      for (let index = 0; index < this.recursosSelecionados.length; index++) {
        const l = this.recursosSelecionados[index];
        if (l.colaborador_id == recursos.colaborador_id) {
          this.recursosSelecionados.splice(index, 1);
          this.recursosSelecionados.splice(index, 0, recursos);
          validar = 1;
        }
      }
      if (validar == 0) {
        this.recursosSelecionados.push(recursos);
      }
    } else {
      this.recursosSelecionados.push(recursos);
    }
  }

  public adicionarInicioGasta(item) {
    var validar = 0;

    var recursos = {
      nome_colaborador: item.nome_colaborador,
      os_agendamento_colaborador_id: item.os_agendamento_colaborador_id,
      colaborador_id: item.colaborador_id,
      is_responsavel: item.is_responsavel,
      agendamento_id: item.agendamento_id,
      nome_departamento: item.nome_departamento,
      nome_funcao: item.nome_funcao,
      data_prevista_inicio: item.data_prevista_inicio,
      data_prevista_fim: item.data_prevista_fim,
      dif_dias_prevista: item.dif_dias_prevista,
      dif_horas_prevista: item.dif_horas_prevista,
      dif_minutos_prevista: item.dif_minutos_prevista,
      data_definida_inicio: item.data_definida_inicio /* ,
      data_definida_fim: item.data_definida_fim */,
    };

    if (this.recursosSelecionados.length >= 1) {
      for (let index = 0; index < this.recursosSelecionados.length; index++) {
        const l = this.recursosSelecionados[index];
        if (l.colaborador_id == recursos.colaborador_id) {
          this.recursosSelecionados.splice(index, 1);
          this.recursosSelecionados.splice(index, 0, recursos);
          validar = 1;
        }
      }
      if (validar == 0) {
        this.recursosSelecionados.push(recursos);
      }
    } else {
      this.recursosSelecionados.push(recursos);
    }
  }

  private getAgendamentoById(id) {
    this.http
      .call_get("ordemservico/agendamento-by-Ordem/" + id, null)
      .subscribe((response) => {
        this.agendamento.data_previsao_inicio =
          Object(response).data.res.data_previsao_inicio;
        this.agendamento.data_previsao_fim =
          Object(response).data.res.data_previsao_fim;
        this.agendamento.dif_dias_prevista =
          Object(response).data.res.dif_dias_prevista;
        this.agendamento.dif_horas_prevista =
          Object(response).data.res.dif_horas_prevista;
        this.agendamento.dif_minutos_prevista =
          Object(response).data.res.dif_minutos_prevista;
        this.agendamento.descricao = Object(response).data.historico
          ? Object(response).data.historico.historico_agendamento_descricao
          : null;
        this.agendamento.id = Object(response).data.res.id;
        this.agendamento.codigo_agendamento =
          Object(response).data.res.codigo_agendamento;
      });
  }

  private getDepartamento() {
    this.http.call_get("departamento/selectBox", null).subscribe((response) => {
      this.departamentos = Object(response).data;
    });

    this.getDuracao();
  }

  private getDuracao() {
    this.http.call_get("duracao/selectBox", null).subscribe((response) => {
      this.duracoes = Object(response).data;
    });
  }

  private getFuncaoByDepartamento() {
    this.carregando.funcao = "Carregando...";
    this.http
      .call_get(
        "funcao/funcaoesByDepartamento/" + this.recursosHumanos.departamento_id,
        null
      )
      .subscribe((response) => {
        this.funcoes = Object(response).data;
        this.carregando.funcao = "Seleccione a função";
      });
  }

  private getColaboradoresByFuncao() {
    this.colaboradores = [];

    if (this.recursosHumanos.funcao_id != null) {
      this.configService.loaddinStarter("start");
      this.http
        .__call("colaborador/colaboradoresByFuncao", {
          start: 1,
          end: 10,
          search: null,
          funcao_id: this.recursosHumanos.funcao_id,
        })
        .subscribe((response) => {
          this.colaboradores = Object(response).data.data;
          this.configService.loaddinStarter("stop");
        });
    }
  }

  public armazens = [];

  public getArmazens() {
    this.http.__call("armazem/selectBox", null).subscribe((response) => {
      this.armazens = Object(response).data;
    });
  }

  private getCategoriaByArmazem() {
    this.consumiveis.qtd_stock = 0;
    (this.consumiveis.categoria_id = null), (this.produtoCategorias = []);
    this.artigos = [];

    this.carregando.categoria = "Carregando...";
    this.http
      .__call("categoria/by/armazem", {
        armazem_id: this.consumiveis.armazem_id,
      })
      .subscribe((res: any) => {
        this.produtoCategorias = Object(res).data;
        this.carregando.categoria = "Seleccione a categoria";
      });
  }

  private getMaterialByCategoriaArmazem() {
    this.consumiveis.qtd_stock = 0;
    this.consumiveis.artigo_id = null;
    this.artigos = [];

    this.carregando.material = "Carregando...";
    this.http
      .__call("material/by/categoria-armazem", {
        armazem_id: this.consumiveis.armazem_id,
        categoria_id: this.consumiveis.categoria_id,
      })
      .subscribe((res: any) => {
        this.artigos = Object(res).data;
        this.carregando.material = "Seleccione o material";
      });
  }

  view_material = false;

  private setMaterial(item) {
    this.consumiveis.artigo_id = item.id;
    this.consumiveis.descricao_material =
      item.codigo_produto + " - " + item.descricao;
    this.view_material = false;
  }

  //SEARCH MATERIAL

  private searchMaterial() {
    /*  if (this.consumiveis.descricao_material == "" || this.consumiveis.descricao_material == null) {
      this.consumiveis.artigo_id = null;
      this.consumiveis.descricao_material = null;
      this.view_material = false;
    } */

    this.consumiveis.unidade = null;
    this.consumiveis.qtd_stock = 0;
    this.consumiveis.artigo_id = null;

    this.artigos = [];

    if (this.consumiveis.categoria_id == null) {
      (this.consumiveis.descricao_material = null),
        (this.view_material = false);
    }

    this.view_material = true;
    this.http
      .__call("search-material/by/categoria-armazem", {
        start: 1,
        end: 500,
        armazem_id: this.consumiveis.armazem_id,
        categoria_id: this.consumiveis.categoria_id,
        search: this.consumiveis.descricao_material,
      })
      .subscribe((response) => {
        this.artigos = Object(response).data.data;
      });
  }

  public consumiveisSelecionados = [];
  public recursosSelecionados = [];
  public qtd_stock = 0;

  private getQtdProduto() {
    //Quantidade de artigo em Stock

    this.consumiveis.qtd_stock = 0;

    if (this.consumiveis.artigo_id != null) {
      this.http
        .__call("stock/existencia/armazem/qtd_produto", {
          armazem_id: this.consumiveis.armazem_id,
          artigo_id: this.consumiveis.artigo_id,
        })
        .subscribe((response) => {
          this.consumiveis.qtd_stock = Object(response).data[0].quantidade;
        });
    }
    //-------
  }

  private getUnidadeByMaterial() {
    this.consumiveis.unidade = null;

    if (this.consumiveis.artigo_id != null) {
      this.http
        .call_get("unidade-by-material/" + this.consumiveis.artigo_id, null)
        .subscribe((response) => {
          this.consumiveis.unidade = Object(response).data.unidade;
        });
    }
    //-------
  }

  subjectObj = new Subject<number>();

  private getQtColaborador() {
    this.http
      .__call("colaborador/get-total", {
        funcao_id: this.recursosHumanos.funcao_id,
      })
      .subscribe((response) => {
        this.recursosHumanos.qtd_colaborador = Object(response).data.total;
      });
    //-------
  }

  keyUpQtdUtilizado(item) {
    this.isNumeric(item);
  }
  validateNumberQuantity: any = {
    isInt: function (number: number) {
      return Number(number) === number && number >= 0;
    },
    isFloat: function (number: number) {
      return Number(number) === number && number % 1 !== 0;
    },
  };
  private convertNumberQuantity = ({ quantidade_utilizada }) => {
    quantidade_utilizada = this.replaceNumberQuantity(quantidade_utilizada);
    if (!quantidade_utilizada) return null;
    if (quantidade_utilizada.search(/\./))
      return parseFloat(quantidade_utilizada);
    return Number(quantidade_utilizada);
  };
  private replaceNumberQuantity = (number) => {
    if (number < 0) return null;
    number = number ? number.replace(",", ".") : null;
    return number;
  };
  verifyQuantityIfIsIntOrFloat(number: Number): Number {
    if (
      !this.validateNumberQuantity.isInt(number) &&
      !this.validateNumberQuantity.isFloat(number)
    ) {
      this.configService.showAlert(
        "Número decimal inválido! Quantidade utilizado! Ex. 0 ou 0.5",
        "alert-danger",
        true
      );
      return (number = null);
    }
    return number;
  }
  private isNumeric(item): boolean | any {
    //console.log(item.quantidade_utilizada);
    let convertedNumber = this.convertNumberQuantity(item);

    //return
    if (this.verifyQuantityIfIsIntOrFloat(convertedNumber) == null) {
      return this.adicionarQtdConsumiveis(
        item,
        this.verifyQuantityIfIsIntOrFloat(convertedNumber)
      );
    }
    return this.adicionarQtdConsumiveis(
      item,
      this.verifyQuantityIfIsIntOrFloat(convertedNumber)
    );
  }

  public adicionarQtdConsumiveis(item, str) {
    let validar = 0;

    let quantidade_utilizada = str;
    if (quantidade_utilizada < 0) {
      quantidade_utilizada = null;
    }

    let artigo = {
      os_agendamento_consumiveil_id: item.os_agendamento_consumiveil_id,
      nome_artigo: item.nome_artigo,
      codigo_produto: item.codigo_produto,
      armazem_nome: item.armazem_nome,
      categoria_descricao: item.categoria_descricao,
      artigo_id: item.artigo_id,
      armazem_id: item.armazem_id,
      agendamento_id: item.agendamento_id,
      quantidade: item.quantidade,
      unidade: item.unidade,
      quantidade_utilizada: quantidade_utilizada,
    };

    if (this.consumiveisSelecionados.length >= 1) {
      for (
        let index = 0;
        index < this.consumiveisSelecionados.length;
        index++
      ) {
        const l = this.consumiveisSelecionados[index];
        if (l.artigo_id == artigo.artigo_id) {
          // this.consumiveisSelecionados.splice(index, 1);
          this.consumiveisSelecionados.splice(index, 1, artigo);
          validar = 1;
        }
      }
      if (validar == 0) {
        this.consumiveisSelecionados.push(artigo);
      }
    } else {
      this.consumiveisSelecionados.push(artigo);
    }
  }

  public adicionarArtigos() {
    var validar = 0;
    var nome_artigo = "";
    var codigo_artigo = "";
    var armazem_nome = "";
    var categoria_descricao = "";

    this.artigos.forEach((element) => {
      if (element.id == this.consumiveis.artigo_id) {
        nome_artigo = element.descricao;
        codigo_artigo = element.codigo_produto;
        armazem_nome = element.armazem_nome;
        categoria_descricao = element.categoria_descricao;
      }
    });

    var artigo = {
      armazem_id: this.consumiveis.armazem_id,
      armazem_nome: armazem_nome,
      categoria_descricao: categoria_descricao,
      nome_artigo: nome_artigo,
      codigo_produto: codigo_artigo,
      artigo_id: this.consumiveis.artigo_id,
      quantidade: 0,
      agendamento_id: null,
      quantidade_utilizada: null,
      qtd_stock: this.qtd_stock,
      unidade: this.consumiveis.unidade,
    };

    if (this.consumiveisSelecionados.length >= 1) {
      for (
        let index = 0;
        index < this.consumiveisSelecionados.length;
        index++
      ) {
        const l = this.consumiveisSelecionados[index];
        if (l.artigo_id == artigo.artigo_id) {
          this.configService.showAlert(
            "Material já se econtra na lista",
            "alert-danger",
            true
          );

          this.consumiveisSelecionados.splice(index, 0, artigo);
          this.consumiveisSelecionados.splice(index, 1);
          validar = 1;
        }
      }
      if (validar == 0) {
        this.consumiveisSelecionados.push(artigo);
      }
    } else {
      this.consumiveisSelecionados.push(artigo);
    }

    (this.consumiveis.artigo_id = null),
      (this.consumiveis.qtdArtigo = null),
      (this.consumiveis.qtd_stock = null);
  }

  public adicionarRecursos(item) {
    var validar = 0;
    var nome_funcao = "";
    var nome_departamento = "";

    this.funcoes.forEach((element) => {
      if (element.id == this.recursosHumanos.funcao_id) {
        nome_funcao = element.descricao;
      }
    });

    this.departamentos.forEach((element) => {
      if (element.id == this.recursosHumanos.departamento_id) {
        nome_departamento = element.descricao;
      }
    });

    this.departamentos.forEach((element) => {
      if (element.id == this.recursosHumanos.departamento_id) {
        nome_departamento = element.descricao;
      }
    });

    var recursos = {
      nome_colaborador: item.colaboradorNome,
      colaborador_id: item.id,
      agendamento_id: null,
      nome_departamento: nome_departamento,
      nome_funcao: nome_funcao,
      data_definida_inicio: null,
    };

    if (this.recursosSelecionados.length >= 1) {
      for (let index = 0; index < this.recursosSelecionados.length; index++) {
        const l = this.recursosSelecionados[index];
        if (l.colaborador_id == recursos.colaborador_id) {
          this.configService.showAlert(
            "Colaborador já se econtra na lista",
            "alert-danger",
            true
          );

          this.recursosSelecionados.splice(index, 0, recursos);
          this.recursosSelecionados.splice(index, 1);
          validar = 1;
        }
      }
      if (validar == 0) {
        this.recursosSelecionados.push(recursos);
      }
    } else {
      this.recursosSelecionados.push(recursos);
    }
  }

  deleteRow(item: any) {
    for (let i = 0; i < this.consumiveisSelecionados.length; ++i) {
      if (this.consumiveisSelecionados[i].artigo_id === item.artigo_id) {
        this.consumiveisSelecionados.splice(i, 1);
      }
    }
  }

  deleteRecursoRow(item: any) {
    for (let i = 0; i < this.recursosSelecionados.length; ++i) {
      if (this.recursosSelecionados[i].colaborador_id === item.colaborador_id) {
        this.recursosSelecionados.splice(i, 1);
      }
    }
  }

  private reloadOrdemServico() {
    location.reload();
  }

  private validateConsumiveis() {
    var validar = 0;

    if (this.consumiveisSelecionados.length > 0) {
      for (
        let index = 0;
        index < this.consumiveisSelecionados.length;
        index++
      ) {
        const l = this.consumiveisSelecionados[index];

        if (l.quantidade_utilizada == null) {
          this.configService.showAlert(
            "Digite a quatidade utilizada para o material " + l.nome_artigo,
            "alert-danger",
            true
          );
          validar = 1;
        }
      }
    }

    if (validar == 0) {
      const nextElement: HTMLElement = document.querySelector(
        "#next-step-colaboradores"
      );
      nextElement.click();
    }
  }

  private formatDate(date) {
    var dia = date.getDate();
    var ano = date.getFullYear();
    var hora = date.getHours();
    var minuto = date.getMinutes();
    var mes = date.getMonth() + 1;

    return (
      ano +
      "-" +
      (mes.toString().length < 2 ? "0" + mes : mes) +
      "-" +
      (dia.toString().length < 2 ? "0" + dia : dia) +
      "T" +
      (hora.toString().length < 2 ? "0" + hora : hora) +
      ":" +
      (minuto.toString() < 2 ? "0" + minuto : minuto)
    );
  }

  private validateColaboradores() {
    var validar = 0;

    for (let index = 0; index < this.recursosSelecionados.length; index++) {
      const l = this.recursosSelecionados[index];

      if (l.data_definida_inicio == null) {
        this.configService.showAlert(
          "Digite a Data definida de início do(a) " + l.nome_colaborador,
          "alert-danger",
          true
        );
        validar = 1;
      }

      if (l.data_definida_fim == null) {
        this.configService.showAlert(
          "Digite a Data definida de fim do(a) " + l.nome_colaborador,
          "alert-danger",
          true
        );
        validar = 1;
      }

      if (l.data_definida_inicio >= l.data_definida_fim) {
        this.configService.showAlert(
          "A data fim previsto não pode ser igual ou inferior a data de início previsto - Colaborador(a): " +
          l.nome_colaborador,
          "alert-danger",
          true
        );
      }
    }

    var dates_definida_inicio = [];
    var dates_definida_fim = [];

    for (let index = 0; index < this.recursosSelecionados.length; index++) {
      dates_definida_inicio.push(
        new Date(this.recursosSelecionados[index].data_definida_inicio)
      );
      dates_definida_fim.push(
        new Date(this.recursosSelecionados[index].data_definida_fim)
      );
    }

    var maxDate = Math.max.apply(null, dates_definida_fim);
    var mimDate = Math.min.apply(null, dates_definida_inicio);
    // convert back to date object
    maxDate = new Date(maxDate);
    mimDate = new Date(mimDate);

    this.agendamento.inicio_execucao = this.formatDate(mimDate);
    this.agendamento.fim_execucao = this.formatDate(maxDate);

    this.agendamentoAddInicio();
    this.agendamentoAddFim();

    if (validar == 0) {
      const nextElement: HTMLElement = document.querySelector(
        "#next-step-agendamento"
      );
      nextElement.click();
    }
  }

  agendamentoAddInicio() {
    var replace = this.agendamento.inicio_execucao.replace("T", " ");
    var date = new Date(replace);

    this.agendamentodayFim = date.getDay();
    this.agendamentohourFim = date.getHours();
    this.agendamentominutesFim = date.getMinutes();
  }

  agendamentoAddFim() {
    var replace1 = this.agendamento.fim_execucao.replace("T", " ");

    if (
      this.agendamento.inicio_execucao == null ||
      this.agendamento.inicio_execucao == ""
    ) {
      this.configService.showAlert(
        "Digite a Data definida de início",
        "alert-danger",
        true
      );
    } else if (
      this.agendamento.fim_execucao == null ||
      this.agendamento.fim_execucao == ""
    ) {
      this.configService.showAlert(
        "Digite a Data definida de fim",
        "alert-danger",
        true
      );
    } else if (
      this.agendamento.inicio_execucao >= this.agendamento.fim_execucao
    ) {
      this.configService.showAlert(
        "A data fim definida não pode ser igual ou inferior a data de início definida",
        "alert-danger",
        true
      );
    } else {
      var date1 = new Date(replace1);

      var dayFim = date1.getDay();
      var hourFim = date1.getHours();
      var minutesFim = date1.getMinutes();

      var data =
        this.agendamentohourFim * 3600 + this.agendamentominutesFim * 60;
      var data2 = hourFim * 3600 + minutesFim * 60;
      var diferenca = data2 - data;

      this.hourFimAgendamento = Math.floor(diferenca / 3600);
      this.minutesFimAgendamento = Math.floor(
        (diferenca - this.hourFimAgendamento * 3600) / 60
      );

      var condincao =
        this.hourFimAgendamento +
        "" +
        (this.minutesFimAgendamento.toString().length < 2
          ? "0" + "" + this.minutesFimAgendamento
          : this.minutesFimAgendamento);

      var diffHoras =
        parseInt(condincao) > 759
          ? parseInt(this.hourFimAgendamento) - 8
          : this.hourFimAgendamento;
      var diffDias = dayFim - this.agendamentodayFim;
      diffDias = parseInt(condincao) > 759 ? diffDias + 1 : diffDias;

      this.agendamento.dif_dias_definida = diffDias;
      this.agendamento.dif_horas_definida = diffHoras;
      this.agendamento.dif_minutos_definida = this.minutesFimAgendamento;
    }
  }

  private getTiposMedicaos() {
    this.http.call_get("tipo-medicao/selectBox", null).subscribe((response) => {
      this.tipo_medicaos = Object(response);
    });
  }

  private saveContrato(is_fechar_agendamento, is_fechar_ordem, is_new_ordem) {

    const data = {
      cliente_id: this.facturacao.cliente.id,
      tipo_contracto_id: this.contrato.tipo_contracto_id,
      tipo_medicao_id: this.contrato.tipo_medicao_id,
      tipo_facturacao_id: this.contrato.tipo_facturacao_id,
      tipologia_cliente_id: this.contrato.tipologia_cliente_id,
      nivel_sensibilidade_id: this.contrato.nivel_sensibilidade_id,
      objecto_contrato_id: this.contrato.objecto_contrato_id,
      motivo_recisao_id: this.contrato.motivo_recisao_id,
      data_inicio: this.contrato.data_inicio,
      data_fim: this.contrato.data_fim,
      morada_correspondencia_flag: this.contrato.morada_correspondencia_flag,
      numero_habitantes: this.contrato.numero_habitantes,

      local_consumo_id: this.local_consumo.id,
      instalacao_sanitaria_qtd: this.contrato.instalacao_sanitaria_qtd,
      reservatorio_flag: this.contrato.reservatorio_flag,
      reservatorio_capacidade: this.contrato.reservatorio_capacidade,
      piscina_flag: this.contrato.piscina_flag,
      piscina_capacidade: this.contrato.piscina_capacidade,
      jardim_flag: this.contrato.jardim_flag,
      campo_jardim_id: this.contrato.campo_jardim_id,
      poco_alternativo_flag: this.contrato.poco_alternativo_flag,
      fossa_flag: this.contrato.fossa_flag,
      fossa_capacidade: this.contrato.fossa_capacidade,
      acesso_camiao_flag: this.contrato.acesso_camiao_flag,
      anexo_flag: this.contrato.anexo_flag,
      anexo_quantidade: this.contrato.anexo_quantidade,
      caixa_contador_flag: this.contrato.caixa_contador_flag,
      abastecimento_cil_id: this.contrato.abastecimento_cil_id
    };

    console.log(data.local_consumo_id)
    console.log(data)



    this.http
      .__call("contrato/save/" + this.ordemServico.contrato_id, data)
      .subscribe((res) => {
        if (Object(res).code == 500) {
          this.configService.showAlert(
            Object(res).message,
            "alert-danger",
            true
          );
        } else if (Object(res).code == 201) {
          this.configService.showAlert(
            Object(res).message,
            "alert-warning",
            true
          );
          this.configService.loaddinStarter("stop");
          this.executeOrdem = true
          this.execute()
        } else {
          this.configService.showAlert(
            Object(res).message,
            "alert-success",
            true
          );
          this.execute()
          this.executeOrdem = true
          this.configService.loaddinStarter("stop");
        }
      });


  }
  private getObjectosContratos() {
    this.http
      .call_get("objecto-contrato/selectBox", null)
      .subscribe((response) => {
        this.objecto_contratos = Object(response);
      });
  }

  public getEstadosRescisao() {
    this.estado_rescisaos = []
    this.configService.loaddinStarter('start');
    this.http.call_get('motivo-rescisao/selectBoxEstado', null).subscribe(
      response => {
        this.estado_rescisaos = Object(response);
        this.configService.loaddinStarter('stop');
      }
    );
  }

  _createLeitura(is_fechar_agendamento, is_fechar_ordem, is_new_ordem) {
    this.configService.loaddinStarter('start')

    //====================== VALIDATION ==========================
    if (this._leitura.leitura < 0) {
      this.configService.showAlert('O valor da leitura não deve ser negativo', 'alert-danger', true)
      this.configService.loaddinStarter('stop')
      return
    }

    this._rotaService.CreateLeitura(this._leitura)
      .subscribe(
        response => {
          /*  this._rotaService._closeModal("closeModalChooseConta")
           this._rotaService._closeModal("closeModalCreateLeitura") */
          this.execute()
          this.configService.loaddinStarter('stop')
          this.executeOrdem = true
        }
      )
  }

  public getSuspenderContrato() {
    this.estado_rescisaos = []
    this.configService.loaddinStarter('start');
    this.http.call_get('motivo-suspenso/selectBoxEstado', null).subscribe(
      response => {
        this.estado_rescisaos = Object(response);
        this.configService.loaddinStarter('stop');
      }
    );
  }
  loadingRescisao = false;
  private saveRescisaoContrato(rota) {

    this.configService.loaddinStarter("start");
    let mensagem = "rescisão"


    this.loadingRescisao = true;

    const uri = 'ordemservico/ordem-servico/create';
    // if (ordemServico) this.createOrEdit(uri, this.ordemServico, true);


    this.http.__call('contrato/' + rota + '/' + this.ordemServico.contrato_id, {

      estado_rescisao_id: this.contrato.estado_rescisao_id,


    }).subscribe(
      res => {
        console.log("now getting in method", Object(res))
        if (Object(res).code == 500) {
          this.configService.showAlert(Object(res).message, 'alert-danger', true);
        } else if (Object(res).code == 201) {
          this.configService.showAlert(Object(res).message, 'alert-warning', true);
          this.configService.loaddinStarter('stop');
          this.executeOrdem = true
          this.execute()
        } else {
          this.configService.showAlert(Object(res).message, 'alert-success', true);
          this.closebutton.nativeElement.click();
          this.configService.loaddinStarter('stop');
          // this.listarContratos();
          //this.onReset();
          this.execute()
          this.executeOrdem = true
        }
        this.configService.loaddinStarter("stop");
        this.executeOrdem = true
        this.loadingRescisao = false;
      }, error => {
        this.configService.loaddinStarter("stop");
        this.loadingRescisao = false;
      }
    );
  }
  private getTipoByClassificacao() {
    this.carregando.tipo = "Carregando...";
    this.http
      .__call(
        "ordemservico/ostipo-trigger-by-flag/selectBox/" +
        this.ordemServico.classificacao_id,
        { flag: "MANUAL" }
      )
      .subscribe((response) => {
        this.tipos = Object(response).data;
        this.carregando.tipo = "Seleccione o tipo";
      });

  }

  openModal(modal = '') {
    ($(modal) as any).modal("show")
  }
  closeModal(modal = '') {
    ($(modal) as any).modal("hide")
  }

  getOsTriggerConsequencia() {
    console.log(this.routerParamOsTipoId, this.routerParamId)
    this.http
      .call_get("ordemservico/trigger-consequencia/selectBox/" + this.routerParamOsTipoId, null)
      .subscribe((response) => {
        this.triggerConsequencia = Object(response).data

        console.log("triggerConsequencia", this.triggerConsequencia)
      });
  }
  flagConsequencia: string = ''
  executeOrdem = false
  is_fechar_agendamento: any = ''
  is_fechar_ordem: any = ''
  is_new_ordem: any = ''
  execute() {
    this.executeOrdem = false


    if (
      this.agendamento.inicio_execucao == null ||
      this.agendamento.inicio_execucao == ""
    ) {
      this.configService.showAlert(
        "Digite a Data Início / Efectiva",
        "alert-danger",
        true
      );
    } else if (
      this.agendamento.fim_execucao == null ||
      this.agendamento.fim_execucao == ""
    ) {
      this.configService.showAlert(
        "Digite a Data Término / Efectiva",
        "alert-danger",
        true
      );
    } else if (this.agendamento.inicio_execucao >= this.agendamento.fim_execucao) {
      this.configService.showAlert(
        "Data de início / Efectiva não pode ser superior ou igual a Data de término / Efectiva",
        "alert-danger",
        true
      );
    } else if (this.ordemServico.ot_trigger_flag == 'INSTALACAO-CONTADOR' && !this.set_contador) {
      this.configService.showAlert(
        "OS de Instalação de Contador: Selecione o Contador",
        "alert-danger",
        true
      );
    } else if (
      this.agendamento.descricao_intervencao == null ||
      this.agendamento.descricao_intervencao == ""
    ) {
      this.configService.showAlert(
        "Digite a descrição..",
        "alert-danger",
        true
      );
    } else {
      this.isFinalizar = false;
      this.isDisabled = true;
      this.configService.loaddinStarter("start");

      this.closebutton.nativeElement.click();

      this.http
        .__call("ordemservico/execucao/create", {
          ordemServico: this.ordemServico,
          routerParamId: this.routerParamId,
          agendamento: this.agendamento,
          consumiveis: this.consumiveisSelecionados,
          is_fechar_ordem: this.is_fechar_ordem,
          recursosHumanos: this.recursosSelecionados,
          departamentos: this.departamentoArray,
        })
        .subscribe(
          (res) => {
            if (Object(res).code == 200) {
              this.isDisabled = true;
              this.configService.showAlert(
                Object(res).message,
                "alert-success",
                true
              );

              if (this.ordemServico.tipo_descricao === "Substituição de Contador") {
                this.updadeContador()

              }
              else if (this.ordemServico.contador_id != null && (this.ordemServico.ot_trigger_flag == 'INSTALACAO-CONTADOR' && this.set_contador)) {
                this.saveContador();

              }
              else if (this.ordemServico.ot_trigger_flag === "REMOCAO-CONTADOR") {
                this.contador_join = false

                this.saveContador();

              }

              if (this.is_new_ordem == true) {
                this.router.navigate([
                  "/ordemservico/agendar",
                  this.routerParamId,
                ]);
              }

              this.validateButton = Object(res).code;

              this.ordemServico.success_ordem_servico = true;

              this.ordemServico.agendamentoGerado = this.agendamento.id;
            } else {
              this.configService.showAlert(
                Object(res).message,
                "alert-danger",
                true
              );
              this.isFinalizar = false;
              this.isDisabled = false;
            }
            this.configService.loaddinStarter("stop");
          },
          (error) => {
            this.configService.loaddinStarter("stop");
            this.isFinalizar = false;
            this.isDisabled = false;
          }
        );
    }


  }

  private selectBoxDistritosByMunicipio(id) {

    //console.log(this.local_instalacao);

    this.http.call_get('distrito/getDistritosByMunicipio/' + id, null).subscribe(
      response => {
        console.log(Object(response).data);

        this.distritos = Object(response).data;
      }
    );
  }

  private selectBoxBairrosByMunicipio(id) {

    //console.log(this.local_instalacao);

    this.http.call_get('bairro/getBairrosByMunicipio/' + id, null).subscribe(
      response => {
        //console.log(Object(response).data);

        this.bairros = Object(response).data;
      }
    );
  }

  private selectBoxMunicipiosByProvincia(id) {
    this.http.call_get('municipio/getMunicipiosByProvincia/' + id, null).subscribe(
      response => {
        this.municipios = Object(response).data;
      }
    );
  }


  private finalizar(is_fechar_agendamento, is_fechar_ordem, is_new_ordem) {
    let finalizarOrden = false
    this.is_fechar_agendamento = is_fechar_agendamento
    this.is_fechar_ordem = is_fechar_ordem
    this.is_new_ordem = is_new_ordem
    console.log(this.triggerConsequencia)
    if ((this.triggerConsequencia[0] || {}).flag == "Activar_contrato") {
      this.saveRescisaoContrato('activar-only')
    } else if ((this.triggerConsequencia[0] || {}).flag == "alterar_tipo_medicao") {
      this.openModal('#alteracaoMedicao')
      this.flagConsequencia = "alterar_tipo_medicao"
    }
    else if ((this.triggerConsequencia[0] || {}).flag == "alterar_objecto_contrato") {
      this.openModal('#alteracaoMedicao')
      this.flagConsequencia = "alterar_objecto_contrato"
    }
    else if ((this.triggerConsequencia[0] || {}).flag == "suspender_contrato") {
      this.saveRescisaoContrato('suspender-only')
    }
    else if ((this.triggerConsequencia[0] || {}).flag == "registar_cil") {
      console.log('#modalCreateOrEditLocalInstalacao')
      this.openModal('#modalCreateOrEditLocalInstalacao')
      finalizarOrden = true
    }
    else if ((this.triggerConsequencia[0] || {}).flag == "inactivar_cils") {
      Swal.fire({
        title: 'Aviso!',
        text: "Deseja Inactivar cil ?",
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, Alterar',
        cancelButtonText: 'Não, não Alterar!',
        reverseButtons: true
      }).then((result) => {

        this.http
          .__call("inactivar-cil-contrato/" + this.ordemServico.contrato_id, null)
          .subscribe(
            (res) => {
              if (Object(res).code == 200) {
                this.isDisabled = true;
                this.configService.showAlert(
                  Object(res).message,
                  "alert-success",
                  true
                );
                this.execute()
                this.executeOrdem = true
              }
            },
            (error) => {
              this.configService.loaddinStarter("stop");
              this.isFinalizar = false;
              this.isDisabled = false;
            }
          );

      })
      this.flagConsequencia = "inactivar_cils"
    }
    else if ((this.triggerConsequencia[0] || {}).flag == "definir_leitura_inicial") {
      this.openModal('#modalCreateLeituraInicial')
      this.flagConsequencia = "definir_leitura_inicial"
    } else {
      this.execute()
    }

    if ((this.triggerConsequencia[0] || {}).flag == "registar_cil" && finalizarOrden) {
      this.execute()
    }

  }

  public saveContador() {
    this.configService.loaddinStarter("start");

    this.http
      .__call(
        "local-consumo/updateLocalConsumoByContador/" +
        this.ordemServico.contrato_id,
        {
          contador_id: this.ordemServico.contador_id,
          contador_join: this.contador_join,
          user_id: this.currentUser.user.id,
          ultima_leitura: this.contador.ultima_leitura,
          data_leitura: this.contador.data_leitura,
          num_serie: this.ordemServico.contador,
          isAlterarMedicao: this.isAlterarMedicao,

        }
      )
      .subscribe((response) => {
        if (Object(response).code == 200) {
          this.cleanContador();
          this.execute()
          this.view_serie_contador = false;
        }

        this.getContadoresDisponiveisByNumeroSerie(false);
        this.configService.loaddinStarter("stop");
      });
  }

  public updadeContador() {
    this.configService.loaddinStarter("start");

    this.http
      .__call(
        "local-consumo/updateLocalConsumoByContador/" +
        this.ordemServico.contrato_id,
        {
          contador_id: this.ordemServico.contador_id,
          contador_join: this.contador_join,
          user_id: this.currentUser.user.id,
          ultima_leitura: this.contador.ultima_leitura,
          leitura_antiga: this.leitura_antiga,
          contador_antigo: this.ordemServico.contador
        }
      )
      .subscribe((response) => {
        if (Object(response).code == 200) {
          this.cleanContador();

          this.view_serie_contador = false;
        }

        this.getContadoresDisponiveisByNumeroSerie(false);
        this.configService.loaddinStarter("stop");
      });
  }

  private imprimirAgendamento(id) {
    this.configService.imprimirAgendamento(id, "2ª Via", "imprimir");
  }

  public getContadoresDisponiveisByNumeroSerie(flag = true) {
    this.view_serie_contador = false;

    let aux_serie = this.contador.numero_serie;
    //console.log(this.ordemServico.local_consumo_id);

    this.http
      .__call("contador/getContadoresDisponiveisByNumeroSerie", {
        start: 1,
        end: 15,
        search: this.contador.numero_serie,
      })
      .subscribe((response) => {
        this.contadores = Object(response).data.data;

        //console.log(Object(response));
        this.configService.loaddinStarter("stop");
      });

    this.cleanContador();
    this.contador.numero_serie = aux_serie;

    //console.log(this.contadores);
  }

  public setContador(item: any) {
    // console.log(item);
    this.set_contador = true;

    this.ordemServico.contador_id = item.id;

    this.contador = {
      id: item.id,
      numero_serie: item.numero_serie,
      marca: item.marca,
      leitura_actual: item.ultima_leitura,

      ultima_leitura: item.ultima_leitura,
      modelo: item.modelo,
      data_leitura: item.data_leitura,
      tipo_contador: item.tipo_contador,
      classe_precisao: item.classe_precisao,
      medicao: item.medicao,
      calibre: item.calibre,
      digitos: item.digitos,
      fabricante: item.fabricante,
      centro_distribuicao: item.centro_distribuicao,

      observacao: null,
    };

    this.view_serie_contador = false;
  }

  public cleanContador() {
    //console.log(item);

    this.contador = {
      id: null,
      numero_serie: null,
      marca: null,
      ultima_leitura: null,
      modelo: null,
      leitura_actual: null,
      data_leitura: null,
      tipo_contador: null,
      classe_precisao: null,
      medicao: null,
      calibre: null,
      digitos: null,
      fabricante: null,
      centro_distribuicao: null,

      observacao: null,
    };

    this.view_serie_contador = false;
  }

  private getColaboradores() {
    this.getAllColaboradores();
  }

  private getAllColaboradores() {
    this.colaboradores = [];

    this.configService.loaddinStarter("start");
    //this.pagination.search = (search == "" || search == null ? null : search);
    this.http
      .__call("colaborador/colaboradoresByFuncao", {
        start: 1,
        end: 10,
        search:
          this.recursosHumanos.colaborador_nome == "" ||
            this.recursosHumanos.colaborador_nome == null
            ? null
            : this.recursosHumanos.colaborador_nome,
        funcao_id: this.recursosHumanos.funcao_id,
      })
      .subscribe((res) => {
        this.colaboradores = Object(res).data.data;
        this.configService.loaddinStarter("stop");
      });
  }

  //START FACTURAÇÃO
  getProduto(search) {
    this.getServicos(search);
  }

  private listarProdutos(response: any) {
    /* if (response.total == 0 || response.total == null) {
      this.configService.showAlert("Não foi informado nenhum preço no produto", "alert-danger", true);
      return;
    } */
    this.facturacao.total = 0;
    let validar = 0;
    if (response.code == 400) {
      this.configService.showAlert(response.message, "alert-danger", true);
    } else {
      if (this.facturacao.produto.length >= 1) {
        if (response.valor_original) {
          for (let index = 0; index < this.facturacao.produto.length; index++) {
            const produt = this.facturacao.produto[index];
            if (produt.produto_id == response.produto_id) {
              this.facturacao.produto.splice(index, 1);
              this.facturacao.produto.splice(index, 0, response);
              validar = 1;
            }
          }
        }
        if (validar == 0) {
          this.facturacao.produto.push(response);
        }
      } else {
        this.facturacao.produto.push(response);
      }
      this.calcularTotal();
    }
  }

  private getMunicipios() {
    this.api.get('municipio/selectBox').subscribe(
      response => {
        this.municipios = Object(response).data
      }
    )
  }
  private calcularTotal() {
    this.facturacao.total = 0;
    this.facturacao.totalSemImposto = 0;
    this.facturacao.totalComImposto = 0;
    //Calcula o Total da Factura
    for (let index = 0; index < this.facturacao.produto.length; index++) {
      this.facturacao.totalSemImposto +=
        this.facturacao.produto[index].linhaTotalSemImposto;
      this.facturacao.totalComImposto +=
        this.facturacao.produto[index].valorImposto;

      this.facturacao.total += this.facturacao.produto[index].total;
    }
    this.facturacao.pagamento.total_pago = this.facturacao.total;

    if (this.facturacao.total >= 0) {
      this.activeButtom.produto = true;
    } else {
      this.activeButtom.produto = false;
    }
  }

  deleteRowProdut(produto: any) {
    for (let i = 0; i < this.facturacao.produto.length; ++i) {
      if (this.facturacao.produto[i].produto_id === produto.produto_id) {
        this.facturacao.produto.splice(i, 1);
        this.calcularTotal();
      }
    }
  }

  private refreshProdutoId(produto: any) {
    this.produtoId = produto.servico_id;
    this.produto_selecionado = produto.servico_valor;
  }

  private refreshSerieId() {
    this.serieId = this.serie.nativeElement.value;
    if (Object(this.serieId) == "") {
      this.serieId = 0;
    }
  }

  private selectedSerie() {
    this.series.forEach((element) => {
      if (this.facturacao.serie.id == element.id) {
        if (element.sigla == "FT") {
          this.configService.listarBancos();
          this.configService.listarMoedas();
        }
        this.facturacao.serie.id = element.id;
        this.facturacao.serie.nome = element.nome;
        this.facturacao.serie.sigla = element.sigla;
        this.facturacao.serie.proximo_numero = element.proximo_numero;
      }
    });
  }

  private getServicos(search) {
    this.items_produtos = [];
    this.configService.loaddinStarter("start");
    this.pagination.search = search == "" || search == null ? "a" : search;
    this.pagination.contrato =
      this.facturacao.cliente.contrato_id == "" ||
        this.facturacao.cliente.contrato_id == null
        ? null
        : this.facturacao.cliente.contrato_id;

    this.http
      .__call("artigo/search", {
        pagination: this.pagination,
        search: search,
      })
      .subscribe((res) => {
        this.items_produtos = Object(res);
        this.configService.loaddinStarter("stop");
      });
  }

  private updateBancList(banco: any) {
    this.facturacao.pagamento.bancos.push(banco);
  }

  getAddProduto() {
    if (this.quantidade <= 0 || isNaN(this.quantidade)) {
      this.configService.showAlert(
        "Não foi informado uma quantidade valida",
        "alert-danger",
        true
      );
      return;
    }
    if (this.http.canActivateRouterLink("atribuir_desconto")) {
      if (!(this.desconto <= 100 && this.desconto >= 0)) {
        this.configService.showAlert(
          "Desconto valido, informe um valor no intervalo entre 0 e 100%",
          "alert-danger",
          true
        );
        return;
      }
    }
    this.configService.loaddinStarter("start");
    this.http
      .__call("artigo/pesquisar", {
        produto_id: this.produtoId,
        quantidade:
          this.quantidade < 0 || isNaN(this.quantidade) ? 1 : this.quantidade,
        desconto: this.desconto,
        cliente_id: this.clienteId,
        observacao: null,
        preco: this.preco,
      })
      .subscribe((res) => {
        this.listarProdutos(Object(res).data);
        if (this.produto_selecionado == 0) {
          //this.getServicos(this.search.nativeElement.value);
        }
        this.quantidade = 1;
        this.desconto = 0;
        this.configService.loaddinStarter("stop");
      });
  }

  isPagamento() {
    this.disabledPagamentoAdiantamento = false;
    this.facturacao.pagamento.valor_recebido = this.facturacao.total;
    this.facturacao.pagamento.referencia = null;
    this.facturacao.pagamento.data_pagamento = null;

    if (this.facturacao.serie.sigla == "FR") {
      var a: string = null;
      this.formasPagamentos.forEach((element) => {
        if (this.facturacao.pagamento.forma_pagamento_id == element.id) {
          if (
            element.designacao === "Adiantamento" ||
            element.designacao === "adiantamento"
          ) {
            a = element.designacao;
          }
        }
      });

      if (
        this.facturacao.pagamento.forma_pagamento_id != 1 &&
        this.facturacao.pagamento.referencia == null
      ) {
        if (a !== "Adiantamento") {
          this.disabledPagamento = false;
        } else {
          this.disabledPagamento = true;
          this.disabledPagamentoAdiantamento = true;
          this.facturacao.pagamento.is_adiantamento = 1;
        }
      } else {
        this.disabledPagamento = true;
      }
    }
  }

  private modal(id) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        html: `
              <h1><b>Deseja Continuar sem contrato</b></h1>
              <button awNextStep>continuar</button>
           `,
        title: "<strong></strong>",
        text: "Você não poderá reverter isso!",
        type: "error",
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: "Continuar",
      })
      .then((result) => {
        if (result.value) {
          swalWithBootstrapButtons.fire(
            "Deleted!",
            "Your file has been deleted.",
            "success"
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "Your imaginary file is safe :)",
            "error"
          );
        }
      });
  }

  private listarseries() {
    this.http
      .call_get("serie/loja/selectSerieLojaFacturaBox", null)
      .subscribe((data) => {
        let serie = Object(data).data.find((obj) => obj.sigla == "FT");

        this.facturacao.serie.id = serie.id;
        this.facturacao.serie = serie;

        this.series = Object(data).data;
        if (this.series.length == 1) {
          this.series.forEach((element) => {
            if (element.sigla == "FT") {
              this.configService.listarBancos();
              this.configService.listarMoedas();
            }

            this.facturacao.serie.id = element.id;
            this.facturacao.serie.nome = element.nome;
            this.facturacao.serie.sigla = element.sigla;
            this.facturacao.serie.proximo_numero = element.proximo_numero;
          });
        }
      });
  }

  private listarFormaPagamentos() {
    this.configService.loaddinStarter("start");
    this.http.call_get("formaPagamento/formas", null).subscribe((response) => {
      this.formasPagamentos = Object(response).data;
      this.configService.loaddinStarter("stop");
    });
  }

  private iniFacturacao() {
    this.facturacao = {
      cliente: {
        id: null,
        nome: null,
        contribuinte: null,
        morada: null,
        telefone: null,
        conta_id: null,
        contrato_id: null,
      },
      serie: {
        id: null,
        sigla: null,
        nome: null,
        proximo_numero: null,
      },
      produto: [],
      pagamento: {
        forma: null,
        valor_recebido: 0.0,
        total_valor_recebido: 0.0,
        troco: 0.0,
        total_pago: null,
        referencia: null,
        data_pagamento: null,
        forma_pagamento_id: null,
        bancos: null,
        adiantamentos: null,
        is_adiantamento: 0,
        linha_pagamentos: [],
      },
      totalComImposto: 0.0,
      totalSemImposto: 0.0,
      total: 0.0,
      success_facturacao: false,
      facturaGerada: null,
      data_vencimento: moment(Date()).format("YYYY-MM-DD").toString(),
      moeda: null,
    };
  }

  private reloadFacturacao() {
    location.reload();
  }

  private getContas(cliente_id) {

    this.contas = [];

    this.local_consumo.cil = null,
      this.local_consumo.numero_serie = null,
      this.local_consumo.predio_nome = null

    this.http.call_get('cliente/conta/selectBox/' + cliente_id, null).subscribe(
      response => {
        this.contas = Object(response).data;
        this.contas_clientes = Object(response).data;

        if (this.contas_clientes.length == 1) {
          this.default = this.contas_clientes[0].numero_conta;
          this.facturacao.cliente.conta_id = this.default;
        }

        this.getContaContrato();

      })
  }

  private getContaContrato() {
    this.activeButtom.cliente = false;
    this.configService.loaddinStarter("start");
    this.http
      .call_get("conta/contratos/" + this.ordemServico.cliente_conta_id, null)
      .subscribe((response) => {
        this.contratos_conta_cliente = Object(response).data;

        if (this.contratos_conta_cliente.length == 1) {
          this.default = this.contratos_conta_cliente[0].id;

        }

        this.configService.loaddinStarter("stop");

        this.contaServicoSeleciona()
      });
  }



  private _contrato = null; // conta a ser visualizada no ecra das operaçoes

  private contaServicoSeleciona() {

    this.facturacao.cliente.contrato_id = this.ordemServico.cliente_contrato_id;

    this._contrato = this.contratos_conta_cliente.find(
      (element) => element.id == this.ordemServico.cliente_contrato_id
    );

  }

  private clienteSemOrComConta = 0;

  selectedClienteComConta(n: number) {
    this.clienteSemOrComConta = n;
    this.listarseries();
    this.listarFormaPagamentos();
    //this.getServicos(null);
    this.getMovimentoAdiantamento();
    this.facturacao.produto = [];
  }

  private addLinhaPagamentos() {
    var validar = 0;

    if (this.facturacao.serie.sigla == "FR") {
      var a: string = null;
      this.formasPagamentos.forEach((element) => {
        if (this.facturacao.pagamento.forma_pagamento_id == element.id) {
          if (
            element.designacao === "Adiantamento" ||
            element.designacao === "adiantamento"
          ) {
            a = element.designacao;
            this.facturacao.pagamento.referencia = "000000";
            this.facturacao.pagamento.data_pagamento = moment(
              new Date()
            ).format("YYYY-MM-DD");
          }
        }
      });

      if (this.facturacao.pagamento.valor_recebido <= 0) {
        this.configService.showAlert(
          "Informa um valor de superior a zero",
          "alert-danger",
          true
        );
      } else if (
        this.facturacao.pagamento.forma_pagamento_id == null ||
        this.facturacao.pagamento.forma_pagamento_id == "null"
      ) {
        this.configService.showAlert(
          "Informa uma forma pagamento valida",
          "alert-danger",
          true
        );
      } else if (
        this.facturacao.pagamento.forma_pagamento_id != 1 &&
        this.facturacao.pagamento.referencia == null
      ) {
        if (a !== "Adiantamento") {
          this.configService.showAlert(
            "Referencia a bancaria",
            "alert-danger",
            true
          );
        }
      } else if (
        this.facturacao.pagamento.forma_pagamento_id != 1 &&
        this.facturacao.pagamento.data_pagamento == null
      ) {
        if (a !== "Adiantamento") {
          this.configService.showAlert(
            "Informa a data de pagamento valida!",
            "alert-danger",
            true
          );
        }
      } else {
        this.formasPagamentos.forEach((element) => {
          if (this.facturacao.pagamento.forma_pagamento_id == element.id) {
            this.facturacao.pagamento.forma = element.designacao;
          }
        });

        var linha = {
          designacao: this.facturacao.pagamento.forma,
          valor_recebido: this.facturacao.pagamento.valor_recebido,
          data_pagamento: this.facturacao.pagamento.data_pagamento,
          forma_pagamento_id: this.facturacao.pagamento.forma_pagamento_id,
          referencia: this.facturacao.pagamento.referencia,
        };

        if (this.facturacao.pagamento.linha_pagamentos.length >= 1) {
          for (
            let index = 0;
            index < this.facturacao.pagamento.linha_pagamentos.length;
            index++
          ) {
            const l = this.facturacao.pagamento.linha_pagamentos[index];
            if (l.forma_pagamento_id == linha.forma_pagamento_id) {
              this.facturacao.pagamento.linha_pagamentos.splice(index, 1);
              this.facturacao.pagamento.linha_pagamentos.splice(
                index,
                0,
                linha
              );
              validar = 1;
            }
          }
          if (validar == 0) {
            this.facturacao.pagamento.linha_pagamentos.push(linha);
          }
        } else {
          this.facturacao.pagamento.linha_pagamentos.push(linha);
        }

        this.calcularTroco();
        this.facturacao.pagamento.forma = null;
        this.facturacao.pagamento.valor_recebido = 0.0;
        this.facturacao.pagamento.referencia = null;
        this.facturacao.pagamento.data_pagamento = null;
        this.facturacao.pagamento.forma_pagamento_id = null;
        this.adiantamento == null;
      }
    }
  }

  private calcularTroco() {
    this.facturacao.pagamento.troco = 0;
    var valor = 0;
    this.facturacao.pagamento.linha_pagamentos.forEach((element) => {
      valor += element.valor_recebido;
    });
    if (valor > 0) {
      this.facturacao.pagamento.troco = valor - this.facturacao.total;
      if (valor <= this.facturacao.total) {
        this.facturacao.pagamento.troco = 0;
      }
    } else {
      this.facturacao.pagamento.troco = 0;
    }
    this.facturacao.pagamento.total_valor_recebido = valor;
  }

  deleteRowFormaPagamento(linha: any) {
    for (
      let i = 0;
      i < this.facturacao.pagamento.linha_pagamentos.length;
      ++i
    ) {
      if (
        this.facturacao.pagamento.linha_pagamentos[i].forma_pagamento_id ===
        linha.forma_pagamento_id
      ) {
        if (
          this.facturacao.pagamento.linha_pagamentos[i].designacao !==
          "Adiantamento"
        ) {
          this.facturacao.pagamento.adiantamentos = [];
        }
        this.facturacao.pagamento.linha_pagamentos.splice(i, 1);
        this.facturacao.pagamento.total_valor_recebido -= linha.valor_recebido;
        this.facturacao.pagamento.troco =
          this.facturacao.pagamento.total_valor_recebido <
            this.facturacao.total ||
            this.facturacao.pagamento.total_valor_recebido == 0
            ? 0
            : this.facturacao.pagamento.total_valor_recebido -
            this.facturacao.total;
        this.calcularTotal();
      }
    }
  }

  private listMovimento: any = [];
  private adiantamento: string = null;

  private listMovimentoAdiantamentoCliente() {
    this.adiantamento = null;
    this.formasPagamentos.forEach((element) => {
      if (this.facturacao.pagamento.forma_pagamento_id == element.id) {
        if (
          element.designacao === "Adiantamento" ||
          element.designacao === "adiantamento"
        ) {
          this.adiantamento = element.designacao;
        }
      }
    });
  }

  private getMovimentoAdiantamento() {
    this.facturacao.pagamento.adiantamentos = [];
    this.configService.loaddinStarter("start");
    this.http
      .__call("adiantamento/factura/adiantamentoFactura", {
        cliente_id: this.facturacao.cliente.id,
      })
      .subscribe((response) => {
        this.listMovimento = Object(response).data;
        this.configService.loaddinStarter("stop");
      });
  }

  setAdianta(ad: any) {
    if (this.facturacao.pagamento.adiantamentos.length == 0) {
      this.facturacao.pagamento.adiantamentos.push(ad);
    } else {
      var validar = 0;
      for (let i = 0; i < this.facturacao.pagamento.adiantamentos.length; ++i) {
        if (this.facturacao.pagamento.adiantamentos[i].id === ad.id) {
          this.facturacao.pagamento.adiantamentos.splice(i, 1);
          validar = 1;
        }
      }

      if (validar === 0) {
        this.facturacao.pagamento.adiantamentos.push(ad);
      }
    }
    var valor = 0;
    for (let i = 0; i < this.facturacao.pagamento.adiantamentos.length; ++i) {
      valor += this.facturacao.pagamento.adiantamentos[i].valor;
    }
    this.facturacao.pagamento.valor_recebido = valor;
  }

  private facturar() {
    if (this.facturacao.cliente.id == null) {
      this.configService.showAlert(
        "É obrigatório fornecer um cliente",
        "alert-danger",
        true
      );
    } else if (this.facturacao.serie.id == null) {
      this.configService.showAlert(
        "É obrigatório fornecer uma serie",
        "alert-danger",
        true
      );
    } else if (this.facturacao.produto.length == 0) {
      this.configService.showAlert(
        "É obrigatório fornecer produtos para a factura",
        "alert-danger",
        true
      );
    } else {
      if (this.facturacao.serie.sigla == "FR") {
        if (this.facturacao.pagamento.linha_pagamentos.length == 0) {
          this.configService.showAlert(
            "É obrigatório fornecer uma forma de pagamento",
            "alert-danger",
            true
          );
        } else if (
          this.facturacao.pagamento.total_valor_recebido <
          this.facturacao.total ||
          this.facturacao.pagamento.total_valor_recebido == 0
        ) {
          this.configService.showAlert(
            "Informa um valor de entrada superir ou igual ao valor total a pagar",
            "alert-danger",
            true
          );
        } else {
          this.validateIsAgOpen();
        }
      } else {
        this.validateIsAgOpen();
      }
    }
  }

  private validateIsAgOpen() {
    this.http
      .__call("ordemservico/execucao/validateIsAgOpen", {
        routerParamId: this.routerParamId,
        agendamento: this.agendamento

      })
      .subscribe(
        (res) => {
          if (Object(res).code == 200) {
            this.guardarFactura();
          }

        }
      );
  }


  private guardarFactura() {
    this.isFinalizar = false;
    this.configService.loaddinStarter("start");
    this.closebutton.nativeElement.disabled = true

    let dataActual = moment(Date()).format("YYYY-MM-DD");

    if (this.facturacao.data_vencimento < dataActual) {
      this.configService.showAlert(
        "Data de Vencimento não pode ser inferior que a Data Actual",
        "alert-danger",
        true
      );
      this.configService.loaddinStarter("stop");
      this.isFinalizar = true;
    } else {
      const _validarAd = this.facturacao.pagamento.linha_pagamentos.find(
        (l) => l.designacao === "Adiantamento"
      );
      this.facturacao.pagamento.adiantamentos =
        _validarAd == null ? [] : this.facturacao.pagamento.adiantamentos;

      let data = {
        produtos: this.facturacao.produto,
        documento: "Factura",
        cliente_id: this.facturacao.cliente.id,
        total: this.facturacao.total,
        serie_id: this.facturacao.serie.id,
        totalComImposto: this.facturacao.totalComImposto,
        totalSemImposto: this.facturacao.totalSemImposto,
        observacao: this.observacao.nativeElement.value,
        leitura: this.leitura.nativeElement.value,
        numero_origem_factura: null,
        data_origem_factura: null,
        data_vencimento: this.facturacao.data_vencimento,
        pagamento: this.facturacao.pagamento,
        moeda: this.facturacao.moeda,
        valor_aberto: this.facturacao.total,
        conta_id: this.facturacao.cliente.conta_id,
        contrato_id: this.facturacao.cliente.contrato_id,
      };

      this.http
        .__call("factura/create", data)
        .pipe(
          finalize(() => {
            this.configService.loaddinStarter("stop");
            this.validateButton = 200;
          })
        )
        .subscribe(
          (res) => {
            this.iniFacturacao();
            this.facturacao.success_facturacao = true;
            this.facturacao.facturaGerada = Object(res).data.id;
            this.ordemServico.factura_id = this.facturacao.facturaGerada;

            this.finalizar(true, true, false);

          },
          (error) => {
            this.configService.loaddinStarter("stop");
            this.isFinalizar = true;
          }
        );
    }
  }
  //END FACTURAÇÃO
}
