import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

@Component({​
    selector: 'app-conf-conta-bancaria',
    templateUrl: './conf-conta-bancaria.component.html',
    styleUrls: ['./conf-conta-bancaria.component.css']
  }​)
  export class ConfContaBancariaComponent implements OnInit {​

  private contabancaria = {
    id: null,
    muicipio_id: null,
    banco_id: null,
    iban: null
  }

  private items: any = [];
  private Provincias: any = [];
  private Municipios: any = [];
  private Bancos: any = [];


  constructor(private http: HttpService, private configService: ConfigService) { }

  ngOnInit() {
    this.getPageFilterData(1);
    this.listarMunicipios();
    this.listarProvincias();
    this.listarBancos();
  }

  private listacontabancarias() {

    this.configService.loaddinStarter('start');

    this.http.__call('conta-bancaria/listagem', this.http.filters).subscribe(

      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.configService.loaddinStarter('stop');

      }
    );
  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page =Number.isInteger(page) ? page : 1 ;
    this.listacontabancarias();
  }

  private listarProvincias() {
    this.http.call_get('provincia/selectBox', null).subscribe(
      response => {
        this.Provincias = Object(response).data;
      }
    );
  }

  private listarMunicipios() {
    this.http.call_get('municipio/selectBox', null).subscribe(
      response => {
        this.Municipios = Object(response).data;
      }
    );
  }

  private listarBancos() {
    this.http.call_get('banco/listar', null).subscribe(
      response => {
        this.Bancos = Object(response).data;
      }
    );
  }

  private SetDataContaBancaria(contabancaria: any) {
    this.contabancaria = contabancaria;
  }
}
