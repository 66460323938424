import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange } from "@angular/core";
import { HttpService } from "src/app/providers/http/http.service";
import { ConfigService } from 'src/app/providers/config/config.service';
import { ExcelService } from 'src/app/services/excel.service';
import * as moment from 'moment';

@Component({
  selector: 'movimento-adiantamento',
  templateUrl: './movimento-adiantamento.component.html',
  styleUrls: ['./movimento-adiantamento.component.css']
})
export class MovimentoAdiantamentoComponent implements OnInit {
 
  @Input() modal: string = "modalVisualFlateRate";
  @Input() selectForms: any; 
  @Input() movimentos:any = null 
  @Input() loading:boolean = false;
  @Input() cliente:any 

  private adiantamento = {
    valor: null,
    cliente_id: null,
    banco_id: null,
    referencia: null,
    data_pagamento: null
  };
 
  constructor(private http: HttpService, private configService: ConfigService, private excelService: ExcelService) {

  }


  ngOnInit() {
  }

  private listarMovimentoAdiantamentos(cliente: any) { 
    this.loading = true  
    this.http.call_get('clientes/adiantamentos/'+cliente.id,null).subscribe(
      response => { 
        this.loading = false;
        this.movimentos = Object(response).data; 
      });
  } 

  private registarAdiantamento() {
    this.adiantamento.cliente_id = this.cliente.id;
    if (this.adiantamento.cliente_id == null || this.adiantamento.valor == null || this.adiantamento.banco_id == null || this.adiantamento.referencia == null || this.adiantamento.data_pagamento == null) {
      this.configService.showAlert("Todos os campos são de caracter obrigatorio", 'alert-danger', true);
      return;
    }
    this.http.__call("clientes/adiantamentos", this.adiantamento).subscribe(
      res => {
        if (Object(res).code === 200) {
          this.configService.showAlert(Object(res).message, 'alert-success', true);
          this.adiantamento = {
            valor: null,
            cliente_id: null,
            banco_id: null,
            referencia: null,
            data_pagamento: null
          };
          this.listarMovimentoAdiantamentos(this.adiantamento.cliente_id )
        } else {
          this.configService.showAlert(Object(res).message, 'alert-danger', true);
        }
      }
    );
  }
}
