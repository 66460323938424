import { Injectable } from '@angular/core';
import * as moment from 'moment';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import { ConfigService } from 'src/app/providers/config/config.service';

@Injectable({
  providedIn: 'root'
})
export class PdfAutoService {
  constructor(private configService: ConfigService) { }

  public auto_pdf(file, p = 'print',title = '',imgData, collumn = {}) {

    var doc = new jsPDF('l', '', 'a4')
    doc.setProperties({
      title: title,
      subject: 'Report',
      author: 'Unig',
      keywords: '',
      creator: 'Be_Water'
    });
    doc.addImage(imgData, 'JPEG', 8, 10, 28, 18)
    const totalPagesExp = "{total_pages_count_string}";
    doc.setFontType('bold')
    doc.setFontSize(15)
    doc.setFontSize(15)
    doc.text(150, 25, title, 'center')

    doc.autoTable({
      html: file,
      didParseCell: function (data) {
        var rows = data.table.body;
        if (data.row.index === 0) {
          data.cell.styles.fontStyle = 'bold';
          data.cell.styles.textColor = "white";
          data.cell.styles.fillColor = [32, 95, 190];
          data.cell.styles.halign = 'center';
        } if (data.row.index === rows.length - 1) {
          data.cell.styles.fontStyle = 'bold';
        }
      },
      didDrawPage: data => {
        let footerStr = "Página " + doc.internal.getNumberOfPages();
        if (typeof doc.putTotalPages === 'function') {
          footerStr = footerStr + " de " + totalPagesExp;
        }
        doc.setFontSize(10);
        doc.text(footerStr, 260, 200, 'left');
      },
      styles: { textColor: [0, 0, 0] }, margin: { bottom: 20 },
      columnStyles: collumn,
      rowsStyles: {},
      startY: 42,
      theme: 'grid',
    })

    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }

    if (p === 'save') {
      doc.save(title + '.pdf');
    } else {
      doc.autoPrint();
      doc.output("dataurlnewwindow");
    }


  }
}
