
import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/providers/http/api.service";
import { ConfigService } from "src/app/providers/config/config.service";

import { Observable, Subject } from "rxjs";
import {
    debounceTime,
    finalize
} from "rxjs/operators";
import { HttpParams } from "@angular/common/http";

export class Filter {
    search: string = "";
    contrato_id: string = "";
    orderBy: string = "";
    typeOrderBy: string = "";
    typeFilter: string = "";
    is_facturado: string = "0";
    constructor() {}
}

export class Pagination {
    lastPage: number;
    page: number = 1;
    perPage: number = 10;
    total: number;
    deserialize(input): this {
        return Object.assign(this, input);
    }
}

export class Charge {
    charge_id: number;
    contrato_id: number;
    produto: string;
    cliente: string;
    valor_new: number;
    valor_old: number;
    quantidade: number;
    quantidade_new:number;
    observacao: string;
}
@Component({
    selector: "app-facturacao-charge",
    templateUrl: "./facturacao-charge.component.html",
    styleUrls: ["./facturacao-charge.component.css"],
})
export class FacturacaoChargeComponent implements OnInit {
    public pagination = new Pagination();
    public filter = new Filter();
    public charge = new Charge();

    total_facturas: number = 0
    total_facturacao: number = 0
    total_nota_credito: number = 0


    submitted = false;
    loading = false;

    private charges: any;

    observableObj: Observable<any>;
    subjectObj = new Subject<number>();

    constructor(
        private http: ApiService,
        private configService: ConfigService,
    ) {}

    ngOnInit() {
      this.subjectObj.pipe(debounceTime(1000)).subscribe({
        next: () => this.listarCharge(),
      });
      this.subjectObj.next(1);
    }

    private listarCharge() {
        var params = new HttpParams();
        params = this.http.params
            .set("page",(Number.isInteger(this.pagination.page)  || 1).toString())
            .set("perPage", (this.pagination.perPage || 5).toString())
            .set("search", this.filter.search.toString())
            .set("orderBy", this.filter.orderBy.toString())
            .set("typeOrderBy", this.filter.typeOrderBy.toString())
            .set("typeFilter", this.filter.typeFilter.toString())
            .set("is_facturado", this.filter.is_facturado.toString())

            this.http
            .get(`charge/listar`, params)
            .pipe(
              debounceTime(1000),
              finalize(() => {
                this.loading = false;
              })
            ).subscribe((response) => {
                let data = Object(response).data.charges
                let totals = Object(response).data.total

                this.pagination.lastPage = data.lastPage;
                this.pagination.page = data.page;
                this.pagination.total = data.total;
                this.pagination.perPage = data.perPage;
                this.charges = data.data;

                this.total_facturacao = totals.totalFacturacao
                this.total_facturas = totals.totalFacturas.length
                this.total_nota_credito = totals.totalNotaCredito.length
            });

    }

    getPageFilterData(page: number) {
      if (this.pagination.perPage == null) {
        return;
      }
      this.pagination.page = page;
      this.listarCharge()
    }

    private updateValorCharge() {
        if (this.charge.valor_new == null) {
            this.configService.showAlert(
                "É obrigatório fornecer Valor Novo",
                "alert-danger",
                true
            );
            return;
        } else if (this.charge.valor_new < 0) {
            this.configService.showAlert(
                "É obrigatório fornecer Valor Novo superior que zero",
                "alert-danger",
                true
            );
            return;
        } else if (this.charge.valor_new == this.charge.valor_old) {
            this.configService.showAlert(
                "É obrigatório fornecer Valor Novo diferente do valor Actual",
                "alert-danger",
                true
            );
            return;
        } else if (this.charge.observacao == null) {
            this.configService.showAlert(
                "É obrigatório motivo de actualização do valor.",
                "alert-danger",
                true
            );
            return;
        }
        this.loading = true;
        this.http
            .put(`charge/update/${this.charge.charge_id}`, this.charge)
            .subscribe(
                (response) => {
                  this.charge.valor_old = this.charge.valor_new;
                  this.charge.quantidade = this.charge.quantidade_new;
                    this.charge.valor_new = null;
                    this.charge.quantidade_new = null;
                    this.listarCharge();
                    this.loading = false;
                },
                (error) => {
                    this.loading = false;
                }
            );
    }

    setCharge(charge) {
        this.charge.charge_id = charge.id;
        this.charge.valor_old = charge.valor;
        this.charge.quantidade = charge.quantidade;
        this.charge.produto = charge.produto.nome;
        this.charge.cliente = charge.contrato.conta.cliente.nome;
        this.charge.contrato_id = charge.contrato.id;
    }

    private anularCharge() {
        if (this.charge.observacao == null) {
            this.configService.showAlert(
                "É obrigatório Escrever o motivo da anulação",
                "alert-danger",
                true
            );
            return;
        }
        this.loading = true;
        this.http
            .put(`charge/anular/${this.charge.charge_id}`, this.charge)
            .subscribe(
                (response) => {
                    this.listarCharge();
                    this.loading = false;
                },
                (error) => {
                    this.loading = false;
                }
            );
    }

    private revert_anulacao() {
        this.loading = true;
        this.http
            .put(`charge/revert_anulacao/${this.charge.charge_id}`, this.charge)
            .subscribe(
                (response) => {
                    this.listarCharge();
                    this.loading = false;
                },
                (error) => {
                    this.loading = false;
                }
            );
    }
    reset(){
      this.charge = new Charge();
    }
}
