import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service'

import { ConfigService } from 'src/app/providers/config/config.service';
import { AuthService } from 'src/app/providers/auth/auth.service';

import { RotaService } from '../../rotas.service';
import * as moment from 'moment';
import { RoteiroService } from 'src/app/components/reports/roteiro/roteiro.service';
import { ExcelAutoService } from 'src/app/services/excel/excel-auto.service';
import { ModalOrdemServicoService } from 'src/app/components/ordemservico/modal-create-ordem-trabalho/modal-ordem-servico.service';

@Component({
  selector: 'app-registar-leitura',
  templateUrl: './registar-leitura.component.html',
  styleUrls: ['./registar-leitura.component.css']
})
export class RegistarLeituraComponent implements OnInit, OnDestroy {
  public filters = {
    search: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    direction: null,
    municipio_id: null,
    bairro_id: null,
    rua_id: null,
    quarteirao_id: null,
    distrito_id: null,
    hasAgendamento: null,
    motivo_id: null,
    rota_header_id: null,
    is_facturada: null,
    estado_id: null,
    leitor_id: null,
    data1: null,
    data2: null,
    status: null,
    tipo_objecto_id: null,
    nao_leitura: null,
    estado_contrato_id: null,

    tipo_ramal_id: null,
    diametro_ramal_id: null,
    material_ramal_id: null,

    pagination: {
      perPage: 5,// Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima,
      lastPage: null,
      total: null // defini o total de registo da BD
    },
    filter: null // subelemente do filter
  }
  private estadoActual: string = null;

  private _contrato = {

    id: null,
    tipo_contracto_id: null,
    tipo_contrato: null,
    tipo_medicao_id: null,
    tipo_medicao: null,
    tipo_medicao_slug: null,
    tipo_facturacao_id: null,
    tipo_facturacao: null,
    tipologia_cliente_id: null,
    tipologia_cliente: null,
    tipologia_cliente_juro_mora: null,
    tipologia_cliente_sujeito_corte: null,
    tipologia_cliente_caucao: null,
    nivel_sensibilidade_id: null,
    nivel_sensibilidade: null,
    objecto_contrato_id: null,
    objecto_contrato: null,
    tarifario: null,
    classe_tarifario: null,
    classe_tarifario_consumo_minimo: null,
    classe_tarifario_consumo_maximo: null,
    numero_habitantes: null,
    data_inicio: null,
    data_fim: null,
    rua_id: null,
    morada_correspondencia_flag: false,
    estado_contrato_id: null,
    estado_contrato: null,
    estado_contrato_slug: null,

    data_rescisao: null,
    motivo_recisao_id: null,
    estado_rescisao_id: null,
    motivo_recisao: null,
    motivo_recisao_flag: null,
    conta_id: null,

    contador_id: null,
    numero_serie: null,

    instalacao_sanitaria_qtd: null,
    reservatorio_flag: null,
    reservatorio_capacidade: null,
    piscina_flag: null,
    piscina_capacidade: null,
    jardim_flag: null,
    campo_jardim_id: null,
    campo_jardim: null,
    poco_alternativo_flag: null,
    fossa_flag: null,
    fossa_capacidade: null,
    acesso_camiao_flag: null,
    anexo_flag: null,
    anexo_quantidade: null,
    caixa_contador_flag: null,
    abastecimento_cil_id: null,
    abastecimento_cil: null,

    cliente_id: null,
    cliente: null,
    info_source: null,

    local_consumo_id: null,
    local_instalacao_id: null,
    contrato_id: null
  };
  public contratoHistorico = null
  is_leitura_inicial = false;
  text_contador = null
  loading: boolean
  show_btn_continuar: boolean = false
  clientes: any = []
  readings: any = [];
  cliente: any = {}
  contas: any = []
  contratos: any = []
  leituras: any = []
  contrato: any = {}
  local_consumo: any = {}
  countTheLengthOfContrato: number = 0
  conditionLeituraInicial: boolean = true

  leitura = {
    contador_id: null,
    contrato_id: null,
    leitura: null,
    ultima_leitura: null,
    data_leitura: null,
    nao_leitura: false,
    motivo: null,
    foi_pago: false,
    classificacao: null,
    is_leitura_inicial: this.is_leitura_inicial
  }

  private current_cliente = {
    cliente: null,
    contrato: null
  }

  estado_contratos: any = []

  _bairros: any = [];
  _distritos: any = [];
  _municipios: any = [];
  quarteiroes: any = []
  ruas: any = []

  motivos_nao_leitura_list: any = []
  private DadosEmpresa: any = [];
  histirico_consumo: any = []

  constructor(
    private configService: ConfigService,
    private _rotaService: RotaService,
    private reportRoteiro: RoteiroService,
    private excelService: ExcelAutoService,
    private flag:ModalOrdemServicoService,
    private http: HttpService,

  ) { this.leitura.classificacao = "EMPRESA"; }



  ngOnInit() {
    this.filters.orderBy = null
    this.filters.search = null
    this.show_btn_continuar = false
    this.getPageFilterData(1);
    this.empresaUser();
    this.getMunicipio()
    this.getEstadoContratos()
    this.flag.flag = "ANOMALIAS_DE_LEITURAS"
  }

  ngOnDestroy() { this.filters.orderBy = null }


  async listClientes() {
    this.loading = true
    console.log(this.filters)
    this._rotaService.getClientes(this.filters)
      .subscribe(
        response => {
          this.clientes = [];
          this.filters.pagination.lastPage = response.lastPage
          this.filters.pagination.page = response.page
          this.filters.pagination.total = response.total
          this.filters.pagination.perPage = response.perPage
          this.clientes = response.data
          this.loading = false
        }
      )
  }


  _resetFilters() {
    this.filters.estado_contrato_id = null
    this.filters.search=null
    this.filters.data1 = null;
    this.filters.data2 = null;
    this.filters.municipio_id = null;
    this.filters.distrito_id = null;
    this.filters.bairro_id = null;
    this.filters.quarteirao_id = null;
    this.filters.rua_id = null;
    this.listClientes()
  }

  private getMunicipio() {
    this.http.__call('municipio/listagems', null).subscribe(
      response => {
        this._municipios = Object(response).data;
      }
    );
  }

  private getDistrito(id) {
    this._bairros = [];
    this.quarteiroes = []
    this.ruas = []
    this.http.call_get(`distrito/selectBoxByMunicipio/${id}`, null).subscribe(
      response => {
        this._distritos = Object(response);
      }
    );
  }

  private getBairro(id) {
    this.http.call_get(`bairro/selectBoxByDistrito/${id}`, null).subscribe(
      response => {
        this._bairros = Object(response);
        console.log(this._bairros)
      }
    );
  }

  _changeQuarteirao(bairro_id) {
    this.quarteiroes = []
    if (bairro_id) {
      this._getQuarteirosDoBairro(bairro_id)
    }
  }

  _changeRuas(quarteirao_id) {
    this.ruas = []
    if (quarteirao_id) {
      this._getRuasDoQuarteirao(quarteirao_id)
    }
  }

  _getQuarteirosDoBairro(id) {
    this.http.call_get(`quarteirao/getQuarteiraosByBairro/${id}`, null)
      .subscribe(
        response => {
          this.quarteiroes = Object(response).data
        }
      )
  }

  _getRuasDoQuarteirao(id) {
    this.http.call_get(`rua/getRuasByQuarteirao/${id}`, null)
      .subscribe(
        response => {
          this.ruas = Object(response).data
        }
      )
  }

  _createLeitura() {
    this.configService.loaddinStarter('start')

    //====================== VALIDATION ==========================
    if (this.leitura.leitura < 0) {
      this.configService.showAlert('O valor da leitura não deve ser negativo', 'alert-danger', true)
      this.configService.loaddinStarter('stop')
      return
    }

    if (this.leitura.data_leitura == null) {
      this.configService.showAlert('A data da leitura é obrigatória', 'alert-danger', true)
      this.configService.loaddinStarter('stop')
      return
    }

    this._rotaService.CreateLeitura(this.leitura)
      .subscribe(
        response => {
          this._rotaService._closeModal("closeModalChooseConta")
          this._rotaService._closeModal("closeModalCreateLeitura")
          this.configService.loaddinStarter('stop')
        }
      )
  }

  _changeConta(id) {
    this.countTheLengthOfContrato = 0
    this.http.call_get(`contratos-com-leituras/${id}`, null)
      .subscribe(
        response => {
          this.contratos = Object(response).data
          for (const iterator of this.contratos) {
            this.show_btn_continuar = true
            this._changeContrato(iterator.id)
            this.countTheLengthOfContrato++
          }
        }
      )
  }

  validateLeitura(item) {
    if (item.estado && item.foi_facturado && !item.is_annulled) {
      this.show_btn_continuar = false
      this.configService.showAlert('Este contrato já têm leitura associada', 'alert-error', true);
      for (; ;)break
    }
  }

  //===== GET LOCAL DE CONSUMO BY CONTRATO_ID======
  _changeContrato(contrato_id: any) {

    this.show_btn_continuar = true;
    if (Boolean(contrato_id)) {

      this.configService.loaddinStarter('start')
      this._rotaService.getLocalConsumo(contrato_id)
        .subscribe(
          response => {
            if (response.contrato_id) {
              for (const iterator of this.leituras) {
                if (!response.contador_id) {
                  this.show_btn_continuar = false
                  return
                }
                if (iterator.contador_id == response.contador_id) {
                  this.validateLeitura(iterator)
                }
              }

              this.local_consumo = response
              this.leitura.contador_id = response.contador_id
              this.text_contador = null
            }

            if (response.contrato_id == null) {
              this.show_btn_continuar = false
              this.text_contador = 'Este contrato não têm contador associado'
            }
            this.configService.loaddinStarter('stop')
          }
        )
    }
  }

  _getLeiturasByCliente(cliente) {
    this.loading = true
    this.configService.loaddinStarter('start')

    this.cliente = cliente
    this._rotaService.getLeiturasByCliente(cliente.id)
      .subscribe(
        response => {
          this.leituras = response.data
          this.loading = false
          this.configService.loaddinStarter('stop')
        }
      )
  }

  _getLeiturasByContrato(cliente: any) {
    this.contrato = cliente.contrato_id;
    this.leitura.contrato_id = cliente.contrato_id
    this.current_cliente.cliente = cliente.nome
    this.current_cliente.contrato = cliente.contrato_id

    this.loading = true
    this.configService.loaddinStarter('start')

    this._rotaService.getLeiturasByContrato(cliente.contrato_id)
      .subscribe(
        response => {
          this.leituras = response.data
          this.loading = false
          this.configService.loaddinStarter('stop')
        }
      )
  }

  getHistoricoConsumoByContrato(cliente: any) {
    this.contratoHistorico = cliente.contrato_id
    this.contrato = cliente.contrato_id;
    this.leitura.contrato_id = cliente.contrato_id
    this.current_cliente.cliente = cliente.nome
    this.current_cliente.contrato = cliente.contrato_id
    this.loading = true
    this.configService.loaddinStarter('start')

    this._rotaService.getHistoricoConsumoByContrato(cliente.contrato_id)
      .subscribe(
        response => {
          this.histirico_consumo = response
          this.loading = false
          this.configService.loaddinStarter('stop')
        }
      )
  }

  _initLeitura() {
    this.leitura = {
      contador_id: null,
      leitura: null,
      ultima_leitura: null,
      data_leitura: null,
      nao_leitura: false,
      motivo: null,
      contrato_id: null,
      classificacao: null,
      foi_pago: false,
      is_leitura_inicial: false
    }
  }

  _initContaAndContrato() {
    this.cliente = {}
    this.contas = []
    this.contratos = []
    this.contrato = {}
    this.local_consumo = {}
    this.text_contador = null
    this.loading = false
    this.show_btn_continuar = false
  }


  selectedCliente(cliente) {
    this.cliente = cliente
    this.getContas(cliente.id)
  }

  getContas(id) {
    this.http.call_get(`cliente/conta/selectBox/${id}`, null)
      .subscribe(
        response => {
          this.contas = Object(response).data
        })
  }

  getPageFilterData(page: number) {
    if (this.filters.pagination.perPage == null) {
      return;
    }
    this.filters.pagination.page = Number.isInteger(page) ? page : 1;
    this.listClientes()
  }


  validateDate(data_leitura: any) {
    if (moment(data_leitura).format('YYYY-MM-DD') > moment(new Date()).format('YYYY-MM-DD')) {
      this.leitura.data_leitura = null;
      this.configService.showAlert('A data da leitura não deve ser maior que actual.', 'alert-error', true);
      return;
    }
  }

  private empresaUser() {
    this.configService.loaddinStarter('start');
    this.http.call_get('empresa/empresa-user', null).subscribe(
      response => {
        this.DadosEmpresa = Object(response).data;
      }
    );
  }

  private setDataContrato(item) {
    this._contrato = {...item, id: item.contrato_id};
    this.cliente = item
  }

  private setDataContratoCliente(contrato) {

    this.contrato = contrato;
    this.contrato.info_source = contrato.info_source;
    this.contrato.estado_contrato_id = null;

    this.estadoActual = contrato.estado_contrato;

  }

  exportAsPDF(): void {
    var file = document.getElementsByClassName("tablePDF")[0]
    this.reportRoteiro.reportHistoricoLeituras(file, this.DadosEmpresa, 'save', 'Rotas', this.leituras.length, this.current_cliente);
  }

  exportConsumoAsPDF(): void {
    var file = document.getElementsByClassName("tableConsumoPDF")[0]
    this.reportRoteiro.reportHistoricoConsumo(file, this.DadosEmpresa, 'save', 'Rotas', this.histirico_consumo.length, this.current_cliente);
  }
  exportAsXLSXAsHistorico(): void {
    var CurrentDate = new Date();

    var keys = [
      { key: 'cil', width: 35, style: { font: { name: 'Calibri' } } },
      { key: 'morada_format', width: 25 },
      { key: 'ultima_leitura', width: 55 },
      { key: 'leitura', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'consumo', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'is_facturada', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'tipo_facturacao', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'numero_serie', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'data_leitura', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'is_estado', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'data_registo', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'leitor', width: 20, style: { font: { name: 'Calibri' } } },
    ];

    var Cols = ["Cil", "Morada", "Última Leitura", "Leitura", "Consumo", "Facturado?", "Tipo De Facturação", "Contador", "Data de Leitura	", "Estado", "Data Registo	", "Registado Por"]
    var title = 'Histórico de Leitura';
    var nameFile = "Histórico de Leitura -" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    this.excelService.excels(this.leituras, nameFile, this.DadosEmpresa.logotipo, keys, Cols, title, 5, 12, 30, 3)
  }


  exportAsXLSXAsHistoricoConsumo(): void {
    var CurrentDate = new Date();

    var keys = [
      { key: 'cil', width: 35, style: { font: { name: 'Calibri' } } },
      { key: 'morada', width: 25 },
      { key: 'consumo', width: 55 },
      { key: 'consumo_facturado', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'data_inicio', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'data_fim', width: 20, style: { font: { name: 'Calibri' } } },
    ];

    var Cols = [
      "Cil",
      "Morada",
      "Consumo medido",
      "Consumo Facturado",
      "Data Inicio",
      "Data Fim",
     ]
    var title = 'Histórico de Consumo';
    var nameFile = "Histórico de Consumo -" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    this.excelService.excels(this.histirico_consumo, nameFile, this.DadosEmpresa.logotipo, keys, Cols, title, 5, 6, 30, 3)
  }

  private getEstadoContratos() {
    this.http.call_get('estado-contrato/selectBoxNotAnulado', null).subscribe(
      response => {
        this.estado_contratos = Object(response);
      }
    );
  }


}
