import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ExcelService } from 'src/app/services/excel.service';
import { ReportIVAService } from 'src/app/components/report-at/relatorios/financeira/report-iva.service';

import * as moment from 'moment';
@Component({
  selector: 'app-report-iva',
  templateUrl: './report-iva.component.html',
  styleUrls: ['./report-iva.component.css']
})
export class ReportIVAComponent implements OnInit {


  loading: boolean = false;
  private dias = []
  private meses = [
    { nome: "Janeiro", numero: "01" },
    { nome: "Fevereiro", numero: "02" },
    { nome: "Março", numero: "03" },
    { nome: "Abril", numero: "04" },
    { nome: "Maio", numero: "05" },
    { nome: "Junho", numero: "06" },
    { nome: "Julho", numero: "07" },
    { nome: "Agosto", numero: "08" },
    { nome: "Setembro", numero: "09" },
    { nome: "Outubro", numero: "10" },
    { nome: "Novembro", numero: "11" },
    { nome: "Dezembro", numero: "12" }
  ];

  private direccaos: any=[];
  private cobrancas: any = [];
  private lojas: any = [];
  private documentos: any = [];
  private anos: any = [];
  private loadingDocumentos: string = "Documento"



  private disabledButton = true

  submitted = false;
  simpleForm: FormGroup;

  constructor(private formBuilder: FormBuilder, private http: HttpService, private configService: ConfigService, private excelService: ExcelService, private reportIVA: ReportIVAService) {
    this.createForm();
  }

  ngOnInit() {

    this.gerarAno();
    this.getDocumentos();
    this.direccaosSelectBox();
  }



  createForm() {
    this.simpleForm = this.formBuilder.group({
      ano: [null, Validators.required],
      mes: [null, Validators.required],
      direccao: [null, Validators.required],
      dia: [null, Validators.required],
      documento: [null, Validators.required],
      estado: [null, Validators.required],
      documento_nome: [null],
    });
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.simpleForm.controls;
  }
  onSubmit() {

    this.submitted = true;
    // parar aquei se o simpleFormulário for inválido
    if (this.simpleForm.invalid) {
      return;
    }
    const uri = 'relatorio/financeira/iva';
    this.createOrEdit(uri, this.simpleForm);
  }

  /**
  * @name "relatorioFacturacaoRealizadaCobrancaGlobal"
  * @descriptio "Esta Função permite gerar relatorio Facturacao Realizada Cobrança Global"
  * @author "caniggiamoreira@gmail.com"
  * @param start
  * @param end
  */
  createOrEdit(uri: any, simpleFormulario: FormGroup) {
    this.cobrancas = []
    this.loading = true;
    this.totais.total = 0;
    this.totais.valor_total_aberto = 0
    this.disabledButton = true;
    // TODO: usado para fazer a requisição com a api de criação de organismo
    this.http.__call(uri, simpleFormulario.value).subscribe(
      response => {
        this.cobrancas = Object(response).data;
        this.loading = false;
        if (this.cobrancas.length != 0) {
          this.disabledButton = false;
          this.somaTotais();
        }
      }
    );
  }

  exportAsXLSX(): void {
    var CurrentDate = new Date();
    var nameFile = "RL" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    console.log(document.getElementsByClassName("tableCobranca")[0]);
    this.excelService.exportAsExcelFile(document.getElementsByClassName("tableCobranca")[0], nameFile);
  }
  exportAsPDF(): void {
    //this.reportIVA.relatorioIVA(this.cobrancas, this.simpleForm.value, 'save');
  }

  imprimirPDF(): void {
    //this.reportIVA.relatorioIVA(this.cobrancas, this.simpleForm.value);
  }



  private gerarAno() {
    var fecha = new Date();
    var anyo = fecha.getFullYear();

    let j = 0;
    for (let i = anyo; i >= 2000; i--) {
      this.anos[j] = i;
      j++;
    }
    j = 0;
    for (let i = 1; i <= 31; i++) {
      this.dias[j] = i;
      j++;
    }
  }



  private getLojas() {
    this.loadingDocumentos = 'Carregando...';
    const id = this.simpleForm.getRawValue().documento;
    if (id != "" || id != null || id != "T") {
      this.http.call_get('documento/selectBoxdocumentoPorLojas/' + id, null).subscribe(
        response => {
          this.lojas = Object(response).data
          this.loadingDocumentos = 'Loja';
        }
      );
    }
  }

  /**
  * @name "Listar Documentos"
  * @descriptio "Esta Função permite Listar todos Documentos"
  * @author "caniggia.moreira@itgest.pt"
  * @param start 
  * @param end 
  */
  private getDocumentos() {
    this.loadingDocumentos = 'Carregando...';
    this.http.__call('documento/show', null).subscribe(
      data => {
        this.documentos = data;
        this.loadingDocumentos = 'Documento';
      }
    );
  }

  private direccaosSelectBox() {
    this.http.call_get('direccao/selectBox', null).subscribe(
      response => {
        this.direccaos = Object(response).data;
      }
    );
  }


  private totais = {
    total: 0,
    total_iva: 0,
    valor_total_aberto: 0
  }
  private somaTotais() {
    var valor_aberto: number = 0;
    var total: number = 0;
    var total_iva: number = 0;
    this.cobrancas.forEach(element => {
      total += element.total;
      valor_aberto += (element.pago == 1 ? (element.total) : (element.total - element.valor_aberto));
      total_iva += element.totalComImposto;
    });
    this.totais.total = total;
    this.totais.valor_total_aberto = valor_aberto
    this.totais.total_iva = total_iva;
  }



}
