import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

@Component({
  selector: 'app-serie',
  templateUrl: './serie.component.html',
  styleUrls: ['./serie.component.css']
})
export class SerieComponent implements OnInit, OnDestroy {

  private items: any = [];
  private serie:any;
  loadingSerie:boolean = false;

  constructor(private http: HttpService, private configService: ConfigService) { }

  ngOnInit() {
    this.http.filters.search = null
    this.getPageFilterData(1);
  }

  ngOnDestroy(){
    this.http.filters.orderBy = null
  }

  /**
   * @name "Listar series"
   * @descriptio "Esta Função permite Listar todas series"
   * @author "caniggia.moreira@itgest.pt"
   * @param start
   * @param end
   */

  private listarseries() {
    this.loadingSerie = true;
    this.configService.loaddinStarter('start');

    this.http.__call('serie/listagem', this.http.filters).subscribe(

      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.configService.loaddinStarter('stop');
        this.loadingSerie = false;

      }
    );
  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page =Number.isInteger(page) ? page : 1 ;
    this.listarseries();
  }

  private setDataSerie(serie:any){
    this.serie = serie;
  }


}

