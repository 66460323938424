import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {Location} from '@angular/common'

import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import {} from '@angular/material/progress-spinner'
import { finalize } from 'rxjs/operators';

@Component({
  selector: "app-emitir-nota-credito",
  templateUrl: "./emitir-nota-credito.component.html",
  styleUrls: ["./emitir-nota-credito.component.css"],
})
export class EmitirNotaCreditoComponent implements OnInit {
  private factura_id: number;
  private factura: any = null;
  private loading: boolean = false;

  private sucesso: boolean = false;

  private series: any = [];
  private serie: number = null;
  private observacao: string;
  total: number = 0;
  totalComImposto: number = 0;
  totalSemImposto: number = 0;
  private imprimir: any;

  valida_code: number = 0;
  message: string = "";

  private line: any;

  private item: any = {
    id: null,
    quantidade: null,
  };


  constructor(
    private http: HttpService,
    private configService: ConfigService,
    private route: ActivatedRoute,
    private _route: Router,
    private location : Location
  ) {
    this.route.paramMap.subscribe((params) => {
      this.factura_id = +params.get("id");
    });
  }

  ngOnInit() {
    this.getFactura(this.factura_id);
    this.getSeries();
  }

  ngOnDestroy() {}

  private getFactura(factura_id) {
    this.loading = true;
    this.http
      .__call("factura/findFactFromNc/" + factura_id, null)
      .subscribe((response) => {
        this.valida_code = Object(response).code;
         this.message = Object(response).message;
        if (Object(response).code === 200) {
          this.factura = Object(response).data;
          this.factura.produtos.forEach((element) => {
            Object.assign(element, { checked: false });
          });
        }
        this.loading = false;
      });
  }

  /**
   * @name "Listar series"
   * @descriptio "Esta Função permite Listar todas series"
   * @author "caniggia.moreira@itgest.pt"
   * @param start
   * @param end
   */
  private getSeries() {
    this.loading = true;

    this.series = [];
    this.http
      .__call("serie/selectBoxSeries-activas", { documentos: ["NC"] })
      .subscribe((response) => {
        this.series = Object(response);
        this.loading = false;
      });
  }

  setProduto(p: any) {
    this.line = p;
    this.item.id = p.id;
    this.item.quantidade = p.quantidade;
  }

  getCalcularLine() {
    /* if (this.item.quantidade > this.line.qtd_fixo ) {
        this.configService.showAlert("A nova quantidade não pode ser maior que a quantidade original", "alert-danger", true);
      return;
    }*/
    if (
      this.item.quantidade <= 0 ||
      isNaN(this.item.quantidade) ||
      Number.isInteger(this.item.quantidade) == false ||
      this.item.quantidade > this.line.qtd_fixo
    ) {
      this.configService.showAlert(
        "Não foi informado uma quantidade valida",
        "alert-danger",
        true
      );
      return;
    }

    for (let index = 0; index < this.factura.produtos.length; index++) {
      const l = this.factura.produtos[index];
      if (l.id === this.item.id) {
        l.quantidade = this.item.quantidade;

        l.valor_desconto =
          l.valor_desconto == 0
            ? 0
            : parseFloat(
                (l.valor * (l.valor / l.valor_desconto / 100)).toFixed(2)
              );

        var desc = l.valor - l.valor_desconto;

        l.valorImposto = parseFloat(
          (l.quantidade * (desc * (l.imposto.valor / 100))).toFixed(2)
        );

        l.linhaTotalSemImposto = parseFloat((desc * l.quantidade).toFixed(2));

        l.total = l.linhaTotalSemImposto + l.valorImposto;

        this.factura.produtos.splice(index, 1);
        this.factura.produtos.splice(index, 0, l);
      }
    }
    this.calcularTotal();
    console.log(this.factura.produtos);
  }

  private finalizarNotaCredito() {

    this.validationLineCheckbox();

    this.configService.loaddinStarter("start");
    if (this.serie == undefined) {
      this.configService.showAlert("É obrigatório fornecer uma serie","alert-danger",true);
      return;
    } else if (this.validation === 0) {
      this.configService.showAlert("É obrigatório seleccionar pelo menos uma linha da factura","alert-danger",true);
      return;
    } else {
      var produts = [];
      for (let index = 0; index < this.factura.produtos.length; index++) {
        const l = this.factura.produtos[index];
        if (l.checked === true) {
          produts.push({
            total: l.total,
            quantidade: l.quantidade,
            valor: l.valor,
            desconto:l.valor_desconto,
            valorImposto: l.valorImposto, // l.valor_imposto,
            linhaTotalSemImposto: l.linhaTotalSemImposto,
            produto_id:l.artigo_id,
            imposto_id:l.imposto_id,
          });
        }
      }
      this.loading = true;

      this.http
        .__call("factura/nota_credito", {
          factura_id:  this.factura_id ,
          produtos: produts,
          documento: "Nota de Crédito",
          cliente_id: this.factura.cliente.id,
          total: this.total,
          serie_id: this.serie,
          totalComImposto: this.totalComImposto,
          totalSemImposto: this.totalSemImposto,
          observacao: this.observacao,
          numero_origem_factura: this.factura.factura.factura_sigla,
          data_origem_factura: this.factura.factura.created_at,
          contrato_id: this.factura.contrato_id,
          contas_cliente:{conta_id: this.factura.conta_id, contrato_id: this.factura.contrato_id},
          moeda: null,
          is_iplc: this.factura.factura.is_iplc,
        }).pipe(finalize(()=>{this.loading = false}))
        .subscribe((res) => { 
          this.sucesso = true;
          this.imprimir = Object(res).data.id;
          this.configService.showAlert( Object(res).message, "alert-success", true ); 
        },
        erro=>{
          this.loading = false;
        } );
        return
    }
  }

  private calcularTotal() {
    this.total = 0;
    this.totalSemImposto = 0;
    this.totalComImposto = 0;
    //Calcula o Total da Factura
    for (let index = 0; index < this.factura.produtos.length; index++) {
      if (this.factura.produtos[index].checked === true) {
        this.totalSemImposto += this.factura.produtos[
          index
        ].linhaTotalSemImposto;
        this.totalComImposto += this.factura.produtos[index].valorImposto;
        this.total += this.factura.produtos[index].total;
      }
    }
  }

  private linesChecked: any = [];

  setLineCheckbox(produt: any) {
    this.factura.produtos.forEach((element) => {
      if (element.id === produt.id) {
        if (element.checked == false) {
          Object.assign(element, { checked: true });
        } else {
          Object.assign(element, { checked: false });
        }
      }
    });
    this.calcularTotal();
  }

  validation: number = 0;

  validationLineCheckbox() {
    this.validation = 0;
    this.factura.produtos.forEach((element) => {
      if (element.checked === true) {
        this.validation++;
      }
    });
  }

  back() {
    this.location.back()
  }
}

