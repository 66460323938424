import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from 'src/environments/environment'


@Injectable({
    providedIn: 'root'
})

export class ContratoService {

    private token = sessionStorage.getItem('sessionToken')

    private headers = new HttpHeaders()
    .set('content-type', 'application/json')
    .set('Access-Control-Allow-Origin', '*')
    .set('Authorization', `Bearer ${this.token}`)

    constructor(
        private _http_client: HttpClient
    ){}

    getContratos() {
        return   this._http_client.get<any>(`${environment.app_url}api/${environment.apiVersion}/contratos`, { 'headers': this.headers })
    }

}