import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

@Component({
  selector: 'app-leitura-contrador',
  templateUrl: './leitura-contrador.component.html',
  styleUrls: ['./leitura-contrador.component.css']
})
export class LeituraContradorComponent implements OnInit {

  submitted = false;
  loading = false;
  loadingCharge = false;
  private charges: any;

  constructor(private http: HttpService, private configService: ConfigService) {
    this.ListarCharge();
  } 

  ngOnInit() {
  }


  /**
   * @name "Listar Charges"
   * @descriptio "Esta Função permite Listar todas Charges a serem facturadas"
   * @author "caniggia.moreira@itgest.pt"
   * @param start
   * @param end
   */
  private ListarCharge() {
    this.loading = true
    this.http.__call('charge/listar', this.http.filters).subscribe(
      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;
        this.charges = Object(response).data.data;
        this.loading = false
      }
    );
  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
    this.ListarCharge();
  }


  private charge = {
    charge_id: null,
    valor_new: null,
    valor_old: null,
    observacao: null
  }
  private setCharge(charge: any) {
    this.charge.charge_id = charge.id;
    this.charge.valor_old = charge.valor;
  }
  private updateValorCharge() {

    console.log(this.charge)
    if (this.charge.valor_new == null) {
      this.configService.showAlert('É obrigatório fornecer Valor Novo', "alert-danger", true);
      return;
    } else if (this.charge.valor_new < 0) {
      this.configService.showAlert('É obrigatório fornecer Valor Novo superior que zero', "alert-danger", true);
      return;
    } else if (this.charge.valor_new == this.charge.valor_old) {
      this.configService.showAlert('É obrigatório fornecer Valor Novo diferente do valor Actual', "alert-danger", true);
      return;
    }
    else if (this.charge.observacao == null) {
      this.configService.showAlert('É obrigatório motivo de actualização do valor.', "alert-danger", true);
      return;
    }
    this.loadingCharge = true
    this.http.__call('charge/update', this.charge).subscribe(
      response => {
        this.charge.valor_old = this.charge.valor_new;
        this.charge.valor_new = null
        this.ListarCharge();
        this.loadingCharge = false
      }
    );
  }

}

