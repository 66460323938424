import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

@Component({
  selector: 'app-plano-preco',
  templateUrl: './plano-preco.component.html',
  styleUrls: ['./plano-preco.component.css']
})
export class PlanoPrecoComponent implements OnInit {

  private planoPrecos: any = [];
  private impostos: any = [];

  private plano_preco = {
    id: null,
    precoDescricao: null,
    precoItemCod: null,
    rateTableCod: null,    
    imposto_id: null,
    dataEstado: null,
    estado: null,
    
    
  }



  constructor(private http: HttpService, private configService: ConfigService) { }

  ngOnInit() {
    this.getImposto();
    this.listarPlanoPrecos();
  }
 
  public listarPlanoPrecos() {

    this.configService.loaddinStarter('start');
    this.http.call_get('planoPreco/listar', null).subscribe(
      response => {
        this.planoPrecos = Object(response).data;
        console.log(this.planoPrecos);
        this.configService.loaddinStarter('stop');
      }
    );
  }

  private registerPlanoPreco() {
 
    if (this.plano_preco.precoDescricao == null) {
      this.configService.showAlert('Todos os campos são de caracter obrigatorios', 'alert-danger', true);
    } else {
      var url = 'planoPreco/register';
      if (this.plano_preco.id != null) {
        url = 'planoPreco/update/' + this.plano_preco.id;
      }
      this.configService.loaddinStarter('start');
      this.http.__call(url, this.plano_preco).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
            
          } else {
            this.configService.showAlert(Object(res).message, 'alert-success', true); 
            this.configService.loaddinStarter('stop');
            this.listarPlanoPrecos();
          }
        }
      );
    }
  }
 
  private getImposto() {

    this.http.__call('imposto/getall', null).subscribe(
      data => {
        var count = Object.keys(data).length;
        for (var i = 0; i < count; i++) {
          this.impostos[i] = data[i];
        }
      }
    );
  }

}
