
import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

import { Router,ActivatedRoute } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { ExcelService } from 'src/app/services/excel.service';
import { RelCobracaGlobalService } from 'src/app/components/report-at/relatorios/financeira/rel-cobraca-global.service';
import {  FacturacaoDetalhadaPosPagoService } from 'src/app/components/report-at/relatorios/financeira/facturacao-detalhada-pos-pago.service';
import { RelDetalhadaPosPagoService } from 'src/app/components/report-at/relatorios/financeira/rel-detalhada-pos-pago.service';
import * as moment from 'moment';
import { ApiService } from "src/app/providers/http/api.service";
import { ReportLojaService } from 'src/app/components/report-at/relatorios/financeira/report-loja.service';
import { ExcelAutoService } from 'src/app/services/excel/excel-auto.service';
import * as WSClient from "@adonisjs/websocket-client"
import { environment } from 'src/environments/environment'
import { DividaService } from './divida.service'
export class Filter {
 
  municipio_id:string="";
  distrito_id:string=""
  bairro_id: string="";
  constructor() {
  }
}
@Component({
  selector: 'app-relatorio-dividas',
  templateUrl: './relatorio-dividas.component.html',
  styleUrls: ['./relatorio-dividas.component.css']
})
export class RelatorioDividasComponent implements OnInit {

  public filter = new Filter();
  public titulo = "Facturação Detalhada ";
  loading: boolean = false;

  private filtros = {
    servico: null,
    servico_id: null,
    gestor: null,
    ano: null,
    mes: null,
    tipoFacturacao:null,
    mes_nome: null,
    cliente: null,
    cliente_id: null,
    direccao: null,
    moeda_id: null,
    moeda: null
  }
  public filters = {
    search: null, // ordem de pesquisa de elemento
    // Ordenação
    orderBy: null,
    pagination: {
      perPage: 5,// Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima,
      lastPage: null,
      total: null // defini o total de registo da BD
    },
    filter: null // subelemente do filter
    ,is_allexel:false
  }

  private localUrl: any;
  private largura: any;
  private altura: any;
  private cobrancas: any = [];  //debitos
  private creditos: any = [];
  private _lojas: any = [];
  private loadingLojas: string;
  private lojas: any = [];
  private moedas: any = []
  private provincias: any = [];
  private servicos: any = []
  private anos: any = []
  private direccaos: any = [];
  private gestores: any = [];
  private mesesbill: any = [];
  private mesesbill2: any = [];
  private disabledButton = true;
  private resetFlag = 0;
  municipios:any = [];
  distritos:any = [];
  bairros:any = [];
  operadores:any = [];
  submitted = false;
  private loadings = {
    municipios:false,
    distritos: false,
    bairros: false
  };
  simpleForm: FormGroup;
  socket:any
  ws_channel:any


  constructor(private formBuilder: FormBuilder, private http: HttpService, 
    private route: ActivatedRoute, private configService: ConfigService,
     private excelServices: FacturacaoDetalhadaPosPagoService,
     private relCobracaGlobal: RelDetalhadaPosPagoService,
     private excelService: ExcelAutoService,
     private api: ApiService,
     private dividaService:DividaService
  ) {

    this.createForm();
  }

  ngOnInit() {
    this.empresaUser()
    this.getMunicipios();
    this.filtros = null;
    this.simpleForm.get('mes');
    this.simpleForm.get('mes_nome');
     this.simpleForm.get('status')
    this.simpleForm.get('direccao');
   this.simpleForm.get('tipoFacturacao');
    this.simpleForm.get('cliente');
    this.simpleForm.get('cliente_id');
    this._getLojas()
  }

  private _getLojas() {
    this.http.call_get('loja/selectBox',null).subscribe(
      response => {
        this._lojas = Object(response).data
        const id = this.simpleForm.getRawValue().loja;
        this._lojas.map(res=>{
          if(res.id==id){
            this.getoperadores(res.id)
          }else{
            return
          }
       })
      }
    );
  }

  private getoperadores(id) {
    this.loadingLojas = 'Carregando...';
    this.http.call_get('user/selectBox/'+id, null).subscribe(
      response => {
        this.operadores = Object(response).data
      }
    )
   
  }

  createForm() {
    this.simpleForm = this.formBuilder.group({
      ano: [null],
      mes: [null],
      mes_nome: [null],
      moeda: [null],
      tipoFacturacao: [null],
      data1: [null, Validators.required],
      data2: [null, [this.matchValidator.bind(this)]], 
      status:'null',
      /*
      gestor: [null],
      servico: [null],
      servico_id: [null], */
      municipio_id: [null],
      distrito_id: [null],
      bairro_id: [null],
      cliente: [null],
      cliente_id: [null],
      direccao: [null],
      moeda_id: [null],
      loja:null,
      operador:null
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.simpleForm.controls;
  }
  matchValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const fromValue = control.value;
    if(fromValue) {

      const startDate = (<FormGroup>this.simpleForm.get('data1')).value;
      const endDate = (<FormGroup>this.simpleForm.get('data2')).value;
      if (startDate <= endDate) {
       return null;
      }
      return { 'invalidDate' : true };
    }

  }
  onSubmit() {

   // this.submitted = true;
    
 
    /* this.simpleForm.patchValue({ tipoFacturacao: this.tipoFacturacao}); */
    const uri = 'relatorio/dividas';
    this.createOrEdit(uri,this.simpleForm,this.filters);
    this.resetFlag = 1;
  }
  getValuesFatura(data:any){
    this.simpleForm.patchValue({
      tipoFacturacao: data
    });
  }

  private totais = {
    debito: 0,
    semImposto: 0,
    imposto: 0,
    credito: 0,
    valor: 0,
    }
    private somaTotais(array:Array<any>) {
      var totals: number = 0;
      array.forEach(element => {
           this.totais.imposto+= element?element.totalComImposto:0;
           this.totais.semImposto+= element?element.totalSemImposto:0;
           this.totais.valor+= element?element.valor_aberto:0;
           this.totais.debito+= element.debito!=1?element.debito:0;
           this.totais.credito+= element.credito!=1?element.credito:0
           this.disabledButton = false;
         
      });
      return this.totais;
    }

  private empresaUser() {
    this.configService.loaddinStarter('start');

    this.http.call_get('empresa/empresa-user', null).subscribe(
      response => {
        this.localUrl = Object(response).data.logotipo
        this.altura = Object(response).data.width
        this.largura = Object(response).data.height
        this.configService.loaddinStarter('stop');
      }
    );
  }
  getPageFilterData(page: number) {
    if (this.filters.pagination.perPage == null) {
        return;
        
    }
    this.onSubmit()
    this.filters.pagination.page = page;
}

  setTrue(){
    this.filters.pagination.page=this.filters.pagination.page
    this.filters.is_allexel=true
    this.onSubmit()
  }
  createOrEdit(uri: any, simpleFormulario: FormGroup, filters ) {
    this.filtros = this.simpleForm.value;
    this.cobrancas = [];
    this.creditos= [];
  //  this.loading = true;
    this.disabledButton = true;
    this.totais.imposto=0;
    this.totais.semImposto=0;
    this.totais.valor=0;
    this.totais.debito=0;
    this.totais.credito=0


     this.dividaService.createOrEdite(uri, simpleFormulario, 
          filters,
          array=>this.somaTotais(array)
          )
     this.disabledButton=this.dividaService.disabledButton
  }

   exportAsXLSX(array):void {
    
    if(!array)return
    var CurrentDate = new Date();
    let datas=JSON.parse(JSON.stringify(array))
     datas=datas.map(res=>{
     res.credito=res.credito!=1?res.credito:'---'
     res.debito=res.debito!=1?res.debito:'---'
     return res

   }) 

 var keys= [
    { key: 'cliente_id', width:30, style: { font: { name: 'Calibri' } } },
    { key: 'contrato_id', width: 25 },
    { key: 'cliente', width: 50 },
    { key: 'morada', width: 50 },
    { key: 'descricao', width: 30 },
    { key: 'municipio', width: 30 },
    { key: 'bairro', width: 25 },
    { key: 'ruas', width: 50 },
    { key: 'cil', width: 25 },
    { key: 'documento', width: 30 },
    { key: 'factura_sigla', width: 30 },
    { key: 'factura_credito', width: 30 },
    { key: 'tipo_factura', width: 30 },
    { key: 'factura_data_nc', width: 30 },
    { key: 'data', width:20, style: { font: { name: 'Calibri' } } },
    { key: 'leitura', width: 25 },
    { key: 'observacao', width: 60 },
    { key: 'loja', width: 35 },
    { key: 'user', width: 35 },
    { key: 'pagos', width:15, style: { font: { name: 'Calibri' },numFmt: '#,##0.00' } },
    { key: 'status', width: 15 },
    { key: 'totalComImposto', width: 30,style: { numFmt: '#,##0.00', } },
    { key: 'totalSemImposto', width: 30,style: { numFmt: '#,##0.00', } },
    { key: 'valor_aberto', width:30,style: { numFmt: '#,##0.00', } },
   /*  { key: 'total', width: 30, style:{numFmt: '#,##0.00'} }, */
   { key: 'debito', width:30,style: { numFmt: '#,##0.00', } },
   { key: 'credito', width:30,style: { numFmt: '#,##0.00', } },
  ];
  
  
  
    var Cols = [
    "Número Cliente",
    "N.º Contrato",
    "Nome Cliente",
    "Morada Cliente",
    "Tarifário",
    "Município (LC)",
    "Bairro/Zona (LC)",
    "Rua (LC)",
    "CIL",
    "Tipo Documento",
    "Documento",
    "Factura Creditada",
    "Tipo Factura",
    "Data de FT Emissão (NC)",
    "Data",
    "Leitura",
    "Observações",
    "Loja",
    "Operador",
    "Saldado",
    "estado",
    "VALOR IVA",
    "VALOR SEM IVA",
    "VALOR EM ABERTO",
    "VALOR DÉBITO",
    "VALOR CRÉDITO",
  ]
    var title='RELATÓRIO DE DÍVIDAS'
    var nameFile = "relatorio-dividas -" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
     this.excelService.excels(datas,nameFile,this.localUrl,keys,Cols,title,5,26,50,3,[22,23,24,25,26])
  }

  exportAsPDF(): void {
    var file = document.getElementsByClassName("tableDebito")[0]
    var file2 = document.getElementsByClassName("tableCredito")[0]
    this.relCobracaGlobal.relatorioFacturacaoPospago(file,file2, 'save', this.filtros,this.localUrl);
  }

  imprimirPDF(): void {
    var file = document.getElementsByClassName("tableDebito")[0]
    var file2 = document.getElementsByClassName("tableCredito")[0]
    this.relCobracaGlobal.relatorioFacturacaoPospago(file,file2, 'print', this.filtros,this.localUrl);
  }

  private getMunicipios() {
    this.distritos = []; 
    this.loadings.municipios = true;
    this.api.get('municipio/selectBox')
    .pipe(finalize(() => {this.loadings.municipios = false;}))
    .subscribe(response => {this.municipios = Object(response).data;},error=>{this.loadings.municipios = false;});
  }

  private getDistritos() {
    this.bairros = [];
    this.loadings.distritos = true; 
    this.filter.distrito_id = "";
    this.filter.bairro_id = "";
    this.api.get(`distrito/selectBoxDistritosByMunicipio/${this.filter.municipio_id}`)
    .pipe(finalize(() => {this.loadings.distritos = false;}))
    .subscribe(response => { this.distritos = Object(response).data;}
            ,error=>{this.loadings.distritos = false;}
    );
  }
  private getBairrosByDistritos() {
    this.filter.bairro_id = "";
    this.loadings.bairros = true;
    if(this.filter.distrito_id==""){this.filter.distrito_id = null};
    this.api.get(`bairro/getBairrosByDistrito/${this.filter.distrito_id}`)
    .pipe(finalize(() => {this.loadings.bairros = false;}))
    .subscribe(response => {this.bairros = Object(response).data;}
              ,error=>{this.loadings.bairros = false;}
    );
  }
 /*  exportAsXLSX(): void {
    var CurrentDate = new Date();
    var nameFile = "FDP" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    this.excelService.ExcelExportFacturacaoDetalhada(this.cobrancas, this.filtros, nameFile);
  }




  /* private getServicos() {
    this.filtros = this.simpleForm.value;
    this.http.__call('artigo/selectServicos', { filtros: this.simpleForm.value }).subscribe(
      response => {
        this.servicos = Object(response).data
      }
    );
  } */
/*
  private getmeses() {

    //var  ano = this.simpleForm.value.ano;

    this.http.__call('billRunHeader/meses', this.simpleForm.value.ano).subscribe(
      response => {
        this.mesesbill = Object(response).data

        this.meses.forEach(element => {
          if (element.numero == this.mesesbill.mes) {
            this.mesesbill2 = this.meses[element.numero]
          }
        });

      }
    );
  }*/

  /* changeServico() {
    this.simpleForm.patchValue({
      servico: null
    });
    this.servicos.forEach(element => {
      if (element.id == this.simpleForm.getRawValue().servico_id) {
        this.simpleForm.patchValue({
          servico: element.nome
        });
      }
    });
  } */
/*
  changeMoeda() {
    this.simpleForm.patchValue({
      moeda: null
    });
    this.moedas.forEach(element => {
      if (element.id == this.simpleForm.getRawValue().moeda_id) {
        this.simpleForm.patchValue({
          moeda: element.nome
        });
      }
    });
  }

  change_mes() {
    this.simpleForm.patchValue({
      mes_nome: null
    });
    this.meses.forEach(element => {
      if (element.numero == this.simpleForm.getRawValue().mes) {
        this.simpleForm.patchValue({
          mes_nome: element.nome
        });
      }
    });
  }

  private gerarAno() {
    var fecha = new Date();
    var anyo = fecha.getFullYear();

    let j = 0;
    for (let i = anyo; i >= 2019; i--) {
      this.anos[j] = i;
      j++;
    }

  }

  private direccaosSelectBox() {
    //this.filtros = this.simpleForm.value;
    this.configService.loaddinStarter('start');
    this.http.call_get('direccao/selectBox', null).subscribe(
      response => {
        this.direccaos = Object(response);
        this.configService.loaddinStarter('stop');
      }
    );
  }

  private clientes: any = [];
  view_client = false;

  private getCliente() {
    // this.filtros = this.simpleForm.value;
    if (this.simpleForm.getRawValue().cliente == "") {
      this.view_client = false;
      this.simpleForm.get('cliente_id').reset();
      this.simpleForm.get('cliente').reset();
      this.clientes = [];
    } else {
      this.view_client = true;
      this.http.__call('cliente/searchClienteFacturaEmail', { start: 1, end: 10, search: this.simpleForm.getRawValue().cliente, filtros: this.simpleForm.value }).subscribe(
        response => {
          this.clientes = Object(response).data.data;
        }
      );
    }
  }

  private setCliente(client: any) {
    this.view_client = false;
    this.simpleForm.patchValue({
      cliente_id: client.id,
      cliente: client.nome
    });
    this.clientes = [];
  }

  private setMes(mes: any) {
    this.simpleForm.patchValue({
      mes_nome: mes,
    });
  }


  private setServico(servico: any) {
    this.simpleForm.patchValue({
      servico: servico.nome,
    });
  }


  private getoperadores() {
    this.http.call_get('user/operador', null).subscribe(
      response => {
        this.operadores = Object(response).data
      }
    );
  }
  private getMoedas() {
    //this.filtros = this.simpleForm.value;
    this.http.call_get('moeda/moeda',null).subscribe(
      response => {
        this.moedas = Object(response).data
      }
    );
  }
   private getGestores() {
    // this.filtros = this.simpleForm.value;
    this.http.__call('gestor/selectBox', { filtros: this.simpleForm.value }).subscribe(
      response => {
        this.gestores = Object(response).data
      }
    );
  }
 */
}

