import { Inject, Injectable } from '@angular/core';
import * as moment from 'moment';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImpressaoPdfService {

  constructor(
    private http: HttpClient) {}

    public relatorioRegistoPdf(report = 'imprimir', data: any,imgData) {
     
        let doc = new jsPDF('p');
        let today = moment().format("DD-MM-YYYY H:mm:ss");
        let posX = 1;
        var posY = 5;
        var logo_width = 35;
        var logo_height = 40;
        var pos_body = logo_height +20;
        var nota_resolucao = 'Rolamento danificado por rolamento novo. É necessário de lubrificação Necessário proceder a subistituição de rolamento danificado por rolamento novo. É necessário de lubrificação Necessário proceder a subistituição de rolamento danificado por rolamento novo. É necessário de lubrificação Necessário proceder a subistituição de rolamento danificado por rolamento novo. É necessário de lubrificação';
        var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
        var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
        var endereco = ''+(data[0]==null?'':data[0].empresa!=null?data[0].empresa.addressDetail:'');
        var nif = '5000330620';
        var  telefone = '935 812 635';
        var email = '';
        var estado_ordem = 'EXECUÇÃO';// AGENDAMENTO ou EXECUÇÃO
              
        doc.addImage(imgData, 'JPEG', posX, posY, logo_width, logo_height);
        doc.setFontSize(8);
        doc.setFontType('Arial');

        if (endereco.length <=62){
            doc.text(posX+logo_width+5, 30, 'NIF: ');
            doc.text(posX+logo_width+13, 30, ' 5000330624');
            doc.text(posX+logo_width+5, 35, 'Email: ');
            doc.text(''+(data[0]==null?'':data[0].empresa!=null?data[0].empresa.email?data[0].empresa.email:'----':''),posX+logo_width+16, 35);
            doc.text(posX+logo_width+50, 30, 'Telefone : ');
            doc.text(''+(data[0]==null?'':data[0].empresa!=null?data[0].empresa.taxRegistrationNumber?data[0].empresa.taxRegistrationNumber:'----':''),posX+logo_width+65, 30);
            doc.text(posX+logo_width+50, 35, 'Website: ');
            doc.text(''+(data[0]==null?'':data[0].empresa!=null?data[0].empresa.site?data[0].empresa.site:'----':''),posX+logo_width+65, 35);
        } else {
            doc.text(posX+logo_width+5, 33, 'NIF: ');
            doc.text(posX+logo_width+13, 30, ' 5000330624');
            doc.text(posX+logo_width+5, 38, 'Email: ');
            doc.text(''+(data[0]==null?'':data[0].empresa!=null?data[0].empresa.email?data[0].empresa.email:'----':''),posX+logo_width+16, 38);
            doc.text(posX+logo_width+60, 33, 'Telefone : ');
            doc.text(''+(data[0]==null?'':data[0].empresa!=null?data[0].empresa.taxRegistrationNumber?data[0].empresa.taxRegistrationNumber:'----':''),posX+logo_width+80, 33);
            doc.text(posX+logo_width+60, 38, 'Website: ');
            doc.text(''+(data[0]==null?'':data[0].empresa!=null?data[0].empresa.site?data[0].empresa.site:'':''),posX+logo_width+80, 38);
        }
        doc.text(''+(data[0]==null?'':data[0].empresa!=null?data[0].empresa.companyName:''),posX+logo_width+5, 20);
        // lines = doc.splitTextToSize(endereco, 62);
        doc.text(''+(data[0]==null?'':data[0].empresa!=null?data[0].empresa.addressDetail:''),posX+logo_width+5, 25);
        doc.setFontSize(8);
        doc.setFontType('bold');
        doc.setFontType('Normal');
        let pos_rectangulo = posX+logo_width+110;
        doc.rect(pos_rectangulo,15,pageWidth/3-20,25)
        doc.setFontSize(9);
        doc.text(pos_rectangulo+5,logo_height-20,'CMR:'+'-'+(data[0]==null?'----':data[0].codigo_pdf));
        doc.text(pos_rectangulo+5,logo_height-15,'Data:'+today);
        var splitText_agencia = doc.splitTextToSize('Agência: '+''+(data[0]==null?'':data[0].loja?data[0].loja:'----'), 50)
        doc.text(pos_rectangulo+5,logo_height-10,splitText_agencia);
        var splitText_user = doc.splitTextToSize('Utilizador: '+''+(data[0]==null?'':data[0].user!==null?data[0].user?data[0].user:'----':''), 50)
        doc.text(pos_rectangulo+5,logo_height-5,splitText_user);

        // CORPO DO RELATÓRIO ........
        doc.setFontSize(8);
        doc.setFontType('bold');
        doc.rect(10,pos_body,pageWidth-20,5);
        logo_height = pos_body-5;
        doc.text(posX+10,logo_height+8.5,'DADOS DO CLIENTE');
        var pos_left = posX+10;
        var corpo_cliente = logo_height+16;
        //TITULOS DOS DADOS DO CLIENTE
        doc.text(pos_left,corpo_cliente,'Nº CLIENTE');
        doc.text(pos_left+70,corpo_cliente,'CONTA');
        doc.text(pos_left+150,corpo_cliente,'CONTRATO');
        doc.text(pos_left,corpo_cliente+10,'NOME');
        doc.text(pos_left,corpo_cliente+25,'MUNICIPIO');
        doc.text(pos_left+70,corpo_cliente+25,'DISTRITO');
        doc.text(pos_left+150,corpo_cliente+25,'BAIRRO');
        doc.text(pos_left,corpo_cliente+40,'MORADA');

        doc.setFontType('normal');
        doc.text(''+(data[0]==null?'':data[0].cliente_id?data[0].cliente_id:'----'),pos_left,corpo_cliente+4);
        doc.text(''+(data[0]==null?'':data[0].conta_id?data[0].conta_id:'----'),pos_left+70,corpo_cliente+4);
        doc.text(''+(data[0]==null?'':data[0].contrato_id?data[0].contrato_id:'----'),pos_left+150,corpo_cliente+4);
        doc.text(''+(data[0]==null?'':data[0].cliente?data[0].cliente.nome?data[0].cliente.nome:'----':''),pos_left,corpo_cliente+14);
        doc.text(''+(data[0]==null?'':data[0].cliente?data[0].cliente.municipio?data[0].cliente.municipio:'----':''),pos_left,corpo_cliente+29);
        doc.text(''+(data[0]==null?'':data[0].cliente?data[0].cliente.distrito?data[0].cliente.distrito:'----':''),pos_left+70,corpo_cliente+29);
        doc.text(''+(data[0]==null?'':data[0].cliente?data[0].cliente.bairro?data[0].cliente.bairro:'----':''),pos_left+150,corpo_cliente+29);
        doc.text(''+(data[0]==null?'':data[0].cliente?data[0].cliente.morada?data[0].cliente.morada:'----':''),pos_left,corpo_cliente+44);

        //DADOS DOS RECURSO DE COMUNICAÇÃO
        var dados_rec = corpo_cliente + 55;
        doc.setFontType('bold');
        doc.rect(10,dados_rec,pageWidth-20,5);
        doc.text(pos_left,dados_rec+3.5,'DADOS DO REGISTO DE COMUNICAÇÃO');
        doc.text(pos_left,dados_rec+10,'CATEGORIA');
        doc.text(pos_left+50,dados_rec+10,'ORIGEM');
        doc.text(pos_left+100,dados_rec+10,'NÍVEL SUPERIOR');
        doc.text(pos_left+150,dados_rec+10,'NÍVEL INFERIOR');
        doc.text(pos_left,dados_rec+22,'TÍTULO');
        doc.setFontType('normal');
        doc.text(''+(data[0]==null?'':data[0].categoria?data[0].categoria:'-----'),pos_left,dados_rec+14);
        doc.text(''+(data[0]==null?'':data[0].origem?data[0].origem:'-----'),pos_left+50,dados_rec+14);
        doc.text(''+(data[0]==null?'':data[0].nivel_desc?data[0].nivel_desc:'-----'),pos_left+100,dados_rec+14);
        doc.text(''+(data[0]==null?'':data[0].sub_nivel?data[0].sub_nivel:'-----'),pos_left+150,dados_rec+14);
        doc.text(''+(data[0]==null?'':data[0].title?data[0].title:'-----'),pos_left,dados_rec+25);

        var pos_descricao = dados_rec + 30;
        doc.setFontType('bold');
        doc.rect(10,pos_descricao,pageWidth-20,5);
        doc.text(pos_left,pos_descricao+3.5,'MOTIVO DA COMUNICAÇÃO');
        doc.setFontType('normal');
        var splitText_descricao = doc.splitTextToSize(''+(data[0]==null?'':data[0].descricao?data[0].descricao:'----'), 190)
        doc.text(splitText_descricao, posX+10,pos_descricao+9);
       
        if (data[0].descricao.length <=396){
          var pos_notas = pos_descricao + 20;

          if(data[0].historico.length>1){ 
            doc.setFontStyle('bold');
            doc.setFontSize(10);
            // doc.rect(posX+15,pos_notas-7, 40, 4);
            doc.text('Motivos Adicionais', posX+10,pos_notas-4);
            doc.setFontStyle('normal');
            doc.setFontSize(8);
            for(let i = 1 ; i<data[0].historico.length;i++){
              
                // var splitText_submotivo = doc.splitTextToSize(''+(data[0].historico[i].descricao), 180)
                var splitText_submotivo = doc.splitTextToSize(''+(data[0]==null?'':data[0].historico[i]?data[0].historico[i].descricao?data[0].historico[i].descricao:'----':'----'), 180)
                doc.text(''+(i)+ ') ', posX+12,pos_notas+1);
                doc.text(splitText_submotivo, posX+15,pos_notas+1);
               
                if (data[0].historico[i].descricao.length <=396){
                    pos_notas +=12;
                    if(pos_notas >= pageHeight-17){
                        doc.addPage();
                        pos_notas = 10;
                    }
                }else {
                    pos_notas +=40;
                    if(pos_notas >= pageHeight-17){
                        doc.addPage();
                        pos_notas = 10;
                    }
                }
              }   
            }
        } else {
          var pos_notas = pos_descricao + 47;
          console.log('data[0].historico.length=',data[0].historico.length)
         if(data[0].historico.length>1){ 
          doc.setFontStyle('bold');
          doc.setFontSize(10);
          // doc.rect(posX+15,pos_notas-7, 40, 4);
          doc.text('Motivos Adicionais', posX+10,pos_notas-4);
          doc.setFontStyle('normal');
          doc.setFontSize(8);
          for(let i = 1 ; i<data[0].historico.length;i++){
            
              // var splitText_submotivo = doc.splitTextToSize(''+(data[0].historico[i].descricao), 180)
              var splitText_submotivo = doc.splitTextToSize(''+(data[0]==null?'':data[0].historico[i]?data[0].historico[i].descricao?data[0].historico[i].descricao:'----':'----'), 180)
              doc.text(''+(i)+ ') ', posX+12,pos_notas+1);
              doc.text(splitText_submotivo, posX+15,pos_notas+1);
             
              if (data[0].historico[i].descricao.length <=396){
                  pos_notas +=12;
                  if(pos_notas >= pageHeight-17){
                      doc.addPage();
                      pos_notas = 10;
                  }
              }else {
                  pos_notas +=40;
                  if(pos_notas >= pageHeight-17){
                      doc.addPage();
                      pos_notas = 10;
                  }
              }
            }   
          }
      }
      
      doc.setFontType('bold');
      if(pos_notas >= pageHeight-17){
          doc.addPage();
          pos_notas = 10;
      }
     // 
      if(data[0].flag=="FECHADO"){
          doc.setFontType('bold');
          doc.rect(10,pos_notas, pageWidth-20,5);
          doc.text(pos_left,pos_notas+3.5,'NOTAS DE RESOLUÇÃO');
          doc.setFontType('normal');
          var splitText_nota = doc.splitTextToSize(data[0].historico?data[0].historico[data[0].historico.length-1].descricao:'----', 190)
          doc.text(splitText_nota, posX+10,pos_notas+12);
          }
        /*RODAPÉ*/
            doc.setFontType('normal');
            doc.setFontStyle('italic');
            doc.setFontSize(6);
            var pageCount = doc.internal.getNumberOfPages();
            for (var i = 0; i <= pageCount; i++) {
            doc.line(10, pageHeight-10, pageWidth-10, pageHeight-10);
            doc.text('Processado por programa validado 4/AGT119', 105, doc.internal.pageSize.height - 7, null, null, 'center');
            doc.setPage(i);
            doc.text(10,pageHeight-15,'Versão');
            //doc.text('Página: ' + doc.internal.getCurrentPageInfo().pageNumber + '/' + pageCount, pageWidth-10, pageHeight-15, null, null, 'right');
            doc.text(pageWidth-10,pageHeight-12,'','right');
            doc.text("NIF: " + nif + " - "+(data[0]==null?'':data[0].empresa!=null?data[0].empresa.companyName?data[0].empresa.companyName:'---':'') + " / " + endereco + " / " +(data[0]==null?'':data[0].empresa!=null?data[0].empresa.email?data[0].empresa.email:'---':'')  + " / " +(data[0]==null?'':data[0].empresa!=null?data[0].empresa.taxRegistrationNumber?data[0].empresa.taxRegistrationNumber:'-----':'') , 105, doc.internal.pageSize.height - 11, null, null, 'center');
            if (report == 'imprimir') {
              doc.autoPrint();
              window.open(doc.output("bloburl")); //opens the data[0] uri in new window
            } else {
              doc.save('comunicacao' + '.pdf'); /* download the file immediately on loading */
            }
        }
}       



  
  public relatorioRegisto(p = 'print', file: any,imgData) {


    var today = moment().format("DD-MM-YYYY H:mm:ss");
    var doc = new jsPDF('l', '', 'a3')
    doc.setProperties({
      title: 'Registo_comunicação_'+today,
      subject: 'Report',
      author: 'Unig',
      keywords: '',
      creator: 'bewater'
    });
    doc.addImage(imgData, 'JPEG',10, 10, 28, 18)
    const totalPagesExp = "{total_pages_count_string}";

    doc.setFontType('bold')
    doc.setFontSize(15)
    // doc.text(145, 60, 'Relatório', 'center')
    doc.setFontSize(15)
    doc.text(190, 25, 'Registo De Comunicação', 'center')
    // doc.setFontSize(9)
    // doc.text('Data início:', 375, 20)
    // doc.text('Data fim:', 375, 25)
// /*  */
//     doc.setFontType('normal')
//     doc.text('' + (filtros.data1), 393, 20);
//     doc.text('' + (filtros.data2),389 , 25);



    doc.autoTable({
      html: file,
      didParseCell: function (data) {
        var rows = data.table.body;
        if (data.row.index === 0) {
          data.cell.styles.fontStyle = 'bold';
          data.cell.styles.textColor = "white";
          data.cell.styles.fillColor = [32, 95, 190];
        }
      },
      didDrawPage: data => {
        let footerStr = "Página " + doc.internal.getNumberOfPages();
        if (typeof doc.putTotalPages === 'function') {
          footerStr = footerStr + " de " + totalPagesExp;
        }
        doc.setFontType('bold')
        doc.setFontSize(10);
        doc.text(today,data.settings.margin.left, doc.internal.pageSize.height - 2)
        doc.setFontSize(10);
        doc.text(footerStr, 393, doc.internal.pageSize.height - 2, 'left');
      },
      styles: { textColor: [0, 0, 0] },
    tableWidth: 10,
      columnStyles: {
        0: {cellWidth: 15,halign: 'center'},
        1: {cellWidth: 30,halign: 'center'},
        2: {cellWidth: 30,halign: 'center'},
        3: {cellWidth: 30,halign: 'center'},
        4: {cellWidth: 25,halign: 'center'},
        5: {cellWidth: 25,halign: 'center'},
        6: {cellWidth: 25,halign: 'center'},
        7: {cellWidth: 25,halign: 'center'},
        8: {cellWidth: 25,halign: 'center'},
        9: {cellWidth: 33,halign: 'center'},
        10: {cellWidth: 25,halign: 'center'},
        11: {cellWidth: 25,halign: 'center'},
        12: {cellWidth:20,halign: 'center'},
        13: {cellWidth:25,halign: 'center'},
        14: {cellWidth:25,halign: 'center'},
        15: {cellWidth:25,halign: 'center'},
      },
      rowsStyles: {},
      startY: 48,
      theme: 'grid',

    })

    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }

    if (p === 'save') {
      doc.save();
    } else {
      doc.autoPrint();
      doc.output("dataurlnewwindow");
    }


  }

 

}
