import { Component, OnInit, ViewChild } from "@angular/core";
import { HttpService } from "src/app/providers/http/http.service";
import { ApiService } from "src/app/providers/http/api.service";
import { ConfigService } from "src/app/providers/config/config.service";

import { FacturaCicloService } from "src/app/components/reports/factura-ciclo/factura-ciclo.service";

import { HttpParams } from "@angular/common/http";
import { ExcelService } from "src/app/services/excel.service";
import * as moment from "moment";
import { Subject } from 'rxjs';
import { debounceTime, finalize } from 'rxjs/operators';
import { Router,ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs/Rx";
import {PdfService} from "./service/pdf.service";

export class Filter {
  search: string = "";
  orderBy: string = "";
  typeOrderBy: string = "DESC";
  startDate: Date;
  endDate: Date;
  typeFilter: string = "";
  documents: string = "OR"
  municipio_id: string = 'null';
  constructor() {
    var CurrentDate = new Date();
    var lastDayMonth = moment(
      new Date(CurrentDate.getFullYear(), CurrentDate.getMonth() + 1, 0)
    ).format("DD");
    this.startDate = new Date(
      CurrentDate.getFullYear() + "-" + (CurrentDate.getMonth() + 1) + "-01"
    );
    this.endDate = new Date(
      CurrentDate.getFullYear() +
        "-" +
        (CurrentDate.getMonth() + 1) +
        "-" +
        lastDayMonth
    );
  }
}

export class Pagination {
  lastPage: number;
  page: number = 1;
  perPage: number = 5;
  total: number;
  deserialize(input): this {
    return Object.assign(this, input);
  }
}

@Component({
  selector: "app-lista-orcamento",
  templateUrl: "./lista-orcamento.component.html",
  styleUrls: ["./lista-orcamento.component.css"]
})
export class ListaOrcamentoComponent implements OnInit {
  public pagination = new Pagination();
  public filter = new Filter();
  private factura: any = null;
  loading = false;

  private localUrl: any;
  private empresa: any[]=[];
  private facturas: any = [];
  private municipios: any = [];
  subjectListFacts: Subject<number> = new Subject();
  mes = moment(new Date()).format("MM");

  status_reason: string = null;

  loading_anular: boolean = false;
  private dashboard = {
    countRecibos: null,
    countFacturas: null,
    countFacturasVencidas: null,
    countFacturasContaCorrente: null,
  };

  observableObj: Observable<any>;
  subjectObj = new Subject<number>();
  loadings={
    municipios:false
 }
  constructor(
    private http: HttpService,
    private reportFactura: FacturaCicloService,
    private configService: ConfigService,
    private _route: Router,
    private excelService: ExcelService,
    private api: ApiService,
    private PdfService:PdfService
  ) {}

  ngOnInit() {
    this.filter.orderBy = "created_at";

    this.subjectObj.pipe(debounceTime(1000)).subscribe({
      next: () => this.listarFacturacao(),
    });
    this.subjectObj.next(1);
    this.getMunicipios()
    this.empresaUser()
  }
  private empresaUser() {
    this.configService.loaddinStarter('start');

    this.http.call_get('empresa/empresa-user', null).subscribe(
      response => {
        this.empresa=Object(response).data
        this.localUrl = Object(response).data.logotipo
        this.configService.loaddinStarter('stop');
      }
    );
  }

  public gerarFacturaOrcamento(id){
    this._route.navigate(['facturacao/'], { queryParams: { id:id } });
    //this._route.navigate(["/facturacao/",orcamento_id]);

  }

  private getMunicipios() {
      this.loadings.municipios = true;
      this.api.get('municipio/selectBox')
      .pipe(finalize(() => {this.loadings.municipios = false;}))
      .subscribe(response => {this.municipios = Object(response).data;},error=>{this.loadings.municipios = false;});
    }
  exportAsXLSX(): void {
    var CurrentDate = new Date();
    var nameFile =
      "Lista_orcamento-" +
      moment(CurrentDate).format("DD") +
      "-" +
      moment(CurrentDate).format("MM") +
      "-" +
      moment(CurrentDate).format("YYYY") +
      " " +
      moment(CurrentDate).format("H") +
      ":" +
      moment(CurrentDate).format("m");
    this.excelService.exportAsExcelFile(
      document.getElementsByClassName("exportAsXLSX")[0],
      nameFile
    );
  }
  exportAsPDF(): void {
    var file = document.getElementsByClassName("exportAsXLSXCliente")[0];
    this.PdfService.exportAsPDF(file,this.localUrl,'save');
  }

  imprimirPDF(): void {
    var file = document.getElementsByClassName("exportAsXLSXCliente")[0];
    this.PdfService.exportAsPDF(file,this.localUrl,'print');
  }

  /**
   * @name "Breve estatistica de facturação"
   * @descriptio "Esta Função permite Estatistica todas facturações"
   * @author "caniggia.moreira@itgest.pt"
   * @param start
   * @param end
   */
  private dashboardFacturacao() {
    this.http.call_get("factura/dashboard", null).subscribe((response) => {
      this.dashboard.countRecibos = this.configService.numberFormat(
        Object(response).data.countRecibos
      );
      this.dashboard.countFacturas = this.configService.numberFormat(
        Object(response).data.countFacturas
      );
      this.dashboard.countFacturasVencidas = this.configService.numberFormat(
        Object(response).data.countFacturasVencidas
      );
      this.dashboard.countFacturasContaCorrente = this.configService.numberFormat(
        Object(response).data.countFacturasContaCorrente
      );
    });
  }

  /**
   * @name "Listar facturação"
   * @descriptio "Esta Função permite Listar todas facturações"
   * @author "caniggia.moreira@itgest.pt"
   * @param start
   * @param end
   */
  private listarFacturacao() {
    this.loading = true;
    var params = new HttpParams();
    params = this.api.params
      .set("page", (Number.isInteger(this.pagination.page ) || 1).toString())
      .set("perPage", (this.pagination.perPage || 5).toString())
      .set("search", this.filter.search.toString())
      .set(
        "startDate",
        moment(this.filter.startDate).format("YYYY-MM-DD").toString()
      )
      .set(
        "endDate",
        moment(this.filter.endDate).format("YYYY-MM-DD").toString()
      )
      .set("orderBy", this.filter.orderBy.toString())
      .set("typeOrderBy", this.filter.typeOrderBy.toString())
      .set("typeFilter", this.filter.typeFilter.toString())
      .set("documents", this.filter.documents.toString())
      .set("municipio_id", this.filter.municipio_id)
    this.api
      .get(`facturas`, params)
      .pipe(
        debounceTime(1000),
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe((response) => {
        this.pagination.lastPage = Object(response).data.lastPage;
        this.pagination.page = Object(response).data.page;
        this.pagination.total = Object(response).data.total;
        this.pagination.perPage = Object(response).data.perPage;
        this.facturas = Object(response).data.data;
        console.log( this.facturas)
      });
  }

  //--------------------------------------------------------------------------

  getPageFilterData(page: number) {
    if (this.pagination.perPage == null) {
      return;
    }
    this.pagination.page = page;
    this.subjectObj.next(this.pagination.page);
  }
  //--------------------------------------------------------------------------

  private getFactura(factura: any) {
    this.factura = factura;
  }

  /**
   * @name "Anualar Factura"
   * @descriptio "Esta Função permite fazer a anulação de uma deteminada factura"
   * @author "caniggia.moreira@itgest.pt"
   * @param start
   * @param end
   */
  private anularFactura(e) {
    if (this.status_reason == "" || this.status_reason == null) {
      this.configService.showAlert(
        "É obrigatório fornecer o nº da factura e o motivo",
        "alert-danger",
        true
      );
    } else {
      this.loading_anular = true;
      this.api
        .put("facturas/anular/" + this.factura.id, {
          status_reason: this.status_reason,
        })
        .pipe(
          finalize(() => {
            this.loading_anular = false;
          })
        )
        .subscribe((res) => {
          if (Object(res).code == 400) {
            this.configService.showAlert(
              Object(res).message,
              "alert-danger",
              true
            );
          } else {
            this.configService.showAlert(
              Object(res).message,
              "alert-success",
              true
            );
            this.getPageFilterData(this.pagination.page);
            this.dashboardFacturacao();
            this.status_reason = "";
            this.factura = null;
          }
          this.loading_anular = false;
        });
    }
  }

  goToPageCreateNotaCredito(factura: any) {
    this._route.navigate(["/orcamento/emitir-nota-de-credito", factura.id]);
  }

  private imprimirFactura(id) {
    this.configService.imprimirOrcamento(id,"print")

    //this.reportFactura.imprimirFactura(id);
  }

  private DownloadFactura(id) {
    this.configService.imprimirOrcamento(id, "download");
  }

  calcularPercentual(f: any) {
    var calc = f.total - f.valor_aberto;
    return f.serie.documento.sigla == "NC"
      ? 100
      : f.pago === 0 && f.status === "N" && f.valor_aberto == null
      ? 0
      : (calc * 100) / f.total;

    //(f.total === f.valor_aberto ? 0 : f.pago == 1 ? 100 : (calc * 100) / f.total);
  }

  ncLengthAnulado = 0;
  public showFactura(id: number) {
    this.ncLengthAnulado = 0;
    this.factura = null;
    this.loading_anular = true;
    this.api
      .get("facturas/" + id)
      .pipe(
        finalize(() => {
          this.loading_anular = false;
        })
      )
      .subscribe((response) => {
        if (Object(response).code == 200) {
          this.factura = Object(response).data;
          for (
            let index = 0;
            index < this.factura.notas_credito.length;
            index++
          ) {
            const element = this.factura.notas_credito[index];
            if (element.status == "N") {
              this.ncLengthAnulado++;
            }
          }
        } else {
          this.configService.showAlert(
            Object(response).message,
            "alert-danger",
            true
          );
        }
      });
  }

  documentos = [];

  /**
   * @name "Listar Documentos"
   * @descriptio "Esta Função permite Listar todos Documentos"
   * @author "caniggia.moreira@itgest.pt"
   * @param start
   * @param end
   *
  private getDocumentos() {
    this.http.__call("documento/show", null).subscribe((data) => {
      this.documentos = data.filter(function (documento) {
        return (
          documento.sigla === "FT" ||
          documento.sigla === "NC" ||
          documento.sigla === "FR"
        );
      });
    });
  }*/

  clearSearch() {
    this.filter.search = "";
  }



}
