import { Component, OnInit } from '@angular/core';
import { TipoIdentificacaoService } from './tipo-de-identificacao.service';

@Component({
    selector: 'app-tipo-de-identificacao',
    templateUrl: './tipo-de-identificacao.component.html',
})

export class TipoIdentificacaoComponent implements OnInit {

    tipo_identificacao: any = {
        nome: null,
        numero_digitos: 0,
        is_default: false
    }

    tipos_identificacoes: any = []

    constructor(
        private _tipoIdentificacaoService: TipoIdentificacaoService
    ) {
        this.loadingMethods()
    }

    ngOnInit() {

    }

    _save() {

        this._tipoIdentificacaoService.CreateTipoIdentificacao(this.tipo_identificacao)
            .subscribe(response => {
                this.loadingMethods()
            }),
            (error) => {
                console.log(error)

                if (!error.ok) {
                }
            }
    }

    _edit() {

        this._tipoIdentificacaoService.EditTipoIdentificacao(this.tipo_identificacao)
            .subscribe(response => {
                this.loadingMethods()
            }),
            (error) => {
                console.log(error)

                if (!error.ok) {
                }
            }
    }

    _initModal(tipo_identificacao) {
        console.log(tipo_identificacao)
        this.tipo_identificacao = tipo_identificacao
    }

    loadingMethods() {
        this._tipoIdentificacaoService.getTiposIdentificacao()
            .subscribe((response) => {
                this.tipos_identificacoes = response.data.data
            })
    }
}
