import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { toDate, format } from 'date-fns-tz'
import { Router,ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl  } from '@angular/forms';
import { ApiService } from "src/app/providers/http/api.service";
import { ExcelService } from 'src/app/services/excel.service';
import { ReportResumoContaCorrenteService } from 'src/app/components/report-at/relatorios/financeira/report-resumo-conta-corrente.service';
import { finalize } from 'rxjs/operators';
import * as moment from 'moment';
import { ExcelAutoService } from 'src/app/services/excel/excel-auto.service';
import { ConfTiposTipologiaComponent } from 'src/app/components/conf-tipos-tipologia/conf-tipos-tipologia.component';

export class Filter {
 
  municipio_id:string="T";
  distrito_id:string="T"
  bairro_id: string="T";
  constructor() {
  }
}
@Component({
  selector: 'app-cobranca-detalhada-valor-recebido',
  templateUrl: './cobranca-detalhada-valor-recebido.component.html',
  styleUrls: ['./cobranca-detalhada-valor-recebido.component.css']
})
export class CobrancaDetalhadaValorRecebidoComponent implements OnInit {


  public filter = new Filter();
  public titulo="Facturação Detalhada ";
  loading: boolean = false;
  municipios:any = [];
  distritos:any = [];
  bairros:any = [];

  private loadings = {
    municipios:false,
    distritos: false,
    bairros: false
  };
  private meses = [
    { nome: "Janeiro", numero: "01" },
    { nome: "Fevereiro", numero: "02" },
    { nome: "Março", numero: "03" },
    { nome: "Abril", numero: "04" },
    { nome: "Maio", numero: "05" },
    { nome: "Junho", numero: "06" },
    { nome: "Julho", numero: "07" },
    { nome: "Agosto", numero: "08" },
    { nome: "Setembro", numero: "09" },
    { nome: "Outubro", numero: "10" },
    { nome: "Novembro", numero: "11" },
    { nome: "Dezembro", numero: "12" }
  ];
  private cobrancas: any = [];
  private recibidos: any = [];
  private _lojas: any = [];
  private operadores: any = [];
  private arrayCobranca: any = [];
  private lojas: any = []
  private filials: any = []
  private provincias: any = [];
  private servicos: any = []
  private anos: any = []
  private direccaos: any=[];
  private gestores:any=[];

  private disabledButton = true

  submitted = false;
  simpleForm: FormGroup;
  tipoFacturacao:string;
  loadingLojas: string;
  private filtros = {
    servico: null,
    servico_id: null,
    gestor: null,
    ano: null,
    mes: null,
    tipoFacturacao:null,
    mes_nome: null,
    cliente: null,
    cliente_id: null,
    direccao: null,
    moeda_id: null,
    moeda: null,
    data1:null,
    data:null
  }
  timeOutput: any
  public filters = {
    search: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    pagination: {
      perPage: 5,// Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima,
      lastPage: null,
      total: null // defini o total de registo da BD
    },
    filter: null // subelemente do filter
    ,is_allexel:false
  }

  private localUrl: any;
  private largura: any;
  private altura: any;

  constructor(private formBuilder: FormBuilder, private api: ApiService, private http: HttpService, private route: ActivatedRoute,private configService: ConfigService, private excelService: ExcelAutoService, private RelResumoContaCorrrente: ReportResumoContaCorrenteService) {
    this.createForm();
  }

  ngOnInit() {
    this.getMunicipios();
    this.getProvincias();
    this.getLojas();
    /* this.getServicos(); */
    this.gerarAno();
    this.empresaUser()
    this._getLojas()
    this.direccaosSelectBox();
  /*   this.getGestores() */
  }

  getTimeZone(date) {
    if(date==null)return
    const parisDate = toDate(date)
    const pattern = 'dd-MM-yyyy'
    this.timeOutput = format(parisDate, pattern, { timeZone: 'UTC/GMT+1' })
    return this.timeOutput
  }

  private totais = {
    totals: 0,
    valor_saldado: 0,
    valor_recebido: 0,
    }

    private somaTotais() {
      console.log("filters")
      this.totais.valor_saldado=0
      this.totais.totals=0
      let count=0
      let countReciboTotal=0
      this.totais.valor_recebido=0
      let countRecibo={}
      let Recibo={}
       this.arrayCobranca.forEach((res,index)=>{
          countRecibo[res.recibo_sigla] = (countRecibo[res.recibo_sigla] || 0)+1;
      }) 

      for(let element of this.arrayCobranca){
          this.totais.totals+= element.total;
          this.totais.valor_recebido+= element.valor_recebido;
          this.totais.valor_saldado+=element.valor_saldado;
          
      }

      this.totais.valor_saldado
      this.totais.totals;
      this.totais.valor_recebido;
      //console.log(Recibo)
    }

  createForm() {
    this.simpleForm = this.formBuilder.group({/*
      gestor: [null, Validators.required], */
      municipio: null,
      cliente: [null],
      cliente_id: [null],
      data1: [null, Validators.required],
      data2: [null, [this.matchValidator.bind(this)]],
       direccao: [null],
       status:'T',
       municipio_id: null,
       distrito_id: null,
       bairro_id: null,
      loja:'T',
      operador:'T'
    });
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.simpleForm.controls;
  }
  matchValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const fromValue = control.value;
    if(fromValue) {

     // console.log(this.simpleForm.controls)
      const startDate = (<FormGroup>this.simpleForm.get('data1')).value;
      const endDate = (<FormGroup>this.simpleForm.get('data2')).value;
      if (startDate <= endDate) {
        //console.log('Control: ', control);
       return null;
      }
      //console.log('Control: ', control);
      return { 'invalidDate' : true };
    }

  }

  private _getLojas() {
    this.http.call_get('loja/selectBox',null).subscribe(
      response => {
        this._lojas = Object(response).data
        const id = this.simpleForm.getRawValue().loja;
        this._lojas.map(res=>{
          if(res.id==id){
            this.getoperadores(res.id)
          }else{
            return
          }
       })
      }
    );
  }

  private getoperadores(id) {
    this.loadingLojas = 'Carregando...';
    this.http.call_get('user/selectBox/'+id, null).subscribe(
      response => {
        this.operadores = Object(response).data
      }
    )
   
  }
  private empresaUser() {
    this.configService.loaddinStarter('start');

    this.http.call_get('empresa/empresa-user', null).subscribe(
      response => {
        this.localUrl = Object(response).data.logotipo
        this.altura = Object(response).data.width
        this.largura = Object(response).data.height
        this.configService.loaddinStarter('stop');
      }
    );
  }
  setFalse(){
    this.filters.is_allexel=false
  }
  setTrue(){
    this.filters.pagination.page=this.filters.pagination.page
    this.filters.is_allexel=true
    this.exportAsXLSX(this.arrayCobranca)
  }
  onSubmit() {

    this.submitted = true;
    // parar aquei se o simpleFormulário for inválido
    if (this.simpleForm.invalid) {
      return;
    }
    const uri = 'relatorio/financeira/resumocontacorrente';
    this.createOrEdit(uri, this.simpleForm);
  }

  createOrEdit(uri: any, simpleFormulario: FormGroup) {
    this.cobrancas = []
    this.recibidos = []
    this.arrayCobranca = []
    this.loading = true;
    this.disabledButton = true;
    this.totais.valor_saldado=0
    this.totais.totals=0
    this.totais.valor_recebido=0
    // TODO: usado para fazer a requisição com a api de criação de organismo  datas    datasPagamento
    this.http.__call(uri, simpleFormulario.value).subscribe(
      response => {
        this.cobrancas = Object(response).data.datas;
        this.recibidos = Object(response).data.datasPagamento;
        this.loading = false;
       
          let countRecibo={}
          let Recibo={}
          let  index=0
         /*  this.cobrancas.forEach((res)=>{
                countRecibo[res.recibo_sigla] = (countRecibo[res.recibo_sigla] || 0)+1;
            })  */
         const formatData=(array)=>{
            for(const item of array) {
               /* if(countRecibo[item.recibo_sigla]>=2){
                     if(!Recibo[item.recibo_sigla]){
                      let total=item.total
                      let valor_recebido=item.valor_recebido
                      this.arrayCobranca.push({...item,total:total,valor_recebido:valor_recebido})
                      Recibo[item.recibo_sigla]=item.recibo_sigla
                    }else{
                      let total=null
                      let valor_recebido=null
                      this.arrayCobranca.push({...item,total:total,valor_recebido:valor_recebido})
                     
                    } 
              }else{ */
                this.arrayCobranca.push(item)
              //}
            }
          } 
         formatData(this.cobrancas)

         if (this.arrayCobranca.length != 0) {
          this.disabledButton = false;
          this.somaTotais();
        } 
       
        
        if(this.filters.is_allexel==false){
          let cobranca=[]
          this.cobrancas = Object(response).data;
          // formatData(this.arrayCobranca)
       
        }else{
          this.exportAsXLSX(this.arrayCobranca)
        }
        this.filters.is_allexel=false
        this.loading = false;
      }
    );
  }

  exportAsXLSX(data:any):void {
    var CurrentDate = new Date();
    data=data.map(res=>{
     return{
       ...res,
       //data:this.getTimeZone(res.data),
       total:res.total==null?0:res.total,
       valor_recebido:res.pagamento.lines[0]==null?0:res.pagamento.lines[0].valor_recebido
      // cobranca?.pagamento?.lines[0]?.valor_recebido
     }
   })
 var keys= [
    { key: 'cliente_id', width:30, style: { font: { name: 'Calibri' } } },
    { key: 'nome', width: 50 },
    { key: 'morada', width:50, style: { font: { name: 'Calibri' } } },
    { key: 'descricao', width:30},
    { key: 'municipio', width:50, style: { font: { name: 'Calibri' } } },
    { key: 'distrito', width:50, style: { font: { name: 'Calibri' } } },
    { key: 'bairro', width:50, style: { font: { name: 'Calibri' } } },
    { key: 'ruas', width:50, style: { font: { name: 'Calibri' } } },
    { key: 'contrato_id', width: 50 },
    { key: 'cil', width: 50 },
    { key: 'recibo_sigla', width: 50 },
    { key: 'factura_sigla', width: 50 },
    { key: 'user', width:50, style: { font: { name: 'Calibri' } } },
    { key: 'loja', width:50, style: { font: { name: 'Calibri' } } },
    { key: 'data', width:20, style: { font: { name: 'Calibri' } } },
    { key: 'statu', width:20, style: { font: { name: 'Calibri' } } },
    { key: 'valor_saldado', width:30, style: { font: { name: 'Calibri' },numFmt: '#,##0.00' } },
    { key: 'total', width:20, style: { font: { name: 'Calibri' },numFmt: '#,##0.00' } },
    { key: 'valor_recebido', width:20, style: { font: { name: 'Calibri' },numFmt: '#,##0.00' } },
  ];


    var Cols = ["Nº Cliente", "Nome Cliente","Morada Cliente","Tarifário","Município","Distrito","Bairro/Zona","Rua","Nº Contrato",	"Cil",	"Documento","Documento Referência",	"Operador","Agência","Data","estado","VALOR SALDADO (FT)","VALOR RECIBO","VALOR RECEBIDO"]
    var title='Cobrança Detalhada'
    var nameFile = "Cobrança Detalhada-" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
     this.excelService.excels(data,nameFile,this.localUrl,keys,Cols,title,5,19,30,3,[17,18,19])
  }


  exportAsPDF(): void {
  var file =  document.getElementsByClassName("tableCobrancaPDF")[0]
  this.RelResumoContaCorrrente.relatorioContaCorrente('save',file,this.localUrl,this.simpleForm.value);
  }

  imprimirPDF(): void {
  var file =  document.getElementsByClassName("tableCobrancaPDF")[0]
  this.RelResumoContaCorrrente.relatorioContaCorrente('imprimir',file,this.localUrl,this.simpleForm.value);
  }

  private getLojas() {
    this.loadingLojas = 'Carregando...';
    const id = this.simpleForm.getRawValue().filial;
    if (id != "" || id != null || id != "T") {
      this.http.call_get('lojas/selectBox/' + id, null).subscribe(
        response => {
          this.lojas = Object(response)
          this.loadingLojas = 'Loja';
        }
      );
    }
  }


  changeLoja() {
    this.simpleForm.patchValue({
      loja_nome: null
    });
    this.lojas.forEach(element => {
      if (element.id == this.simpleForm.getRawValue().loja) {
        this.simpleForm.patchValue({
          loja_nome: element.nome
        });
      }
    });
  }

  private getMunicipios() {
    this.distritos = []; 
    this.loadings.municipios = true;
    this.api.get('municipio/selectBox')
    .pipe(finalize(() => {this.loadings.municipios = false;}))
    .subscribe(response => {this.municipios = Object(response).data;},error=>{this.loadings.municipios = false;});
  }

  private getDistritos() {
    this.bairros = [];
    this.loadings.distritos = true; 
    this.filter.distrito_id = "";
    this.filter.bairro_id = "";
    this.api.get(`distrito/selectBoxDistritosByMunicipio/${this.filter.municipio_id}`)
    .pipe(finalize(() => {this.loadings.distritos = false;}))
    .subscribe(response => { this.distritos = Object(response).data;}
            ,error=>{this.loadings.distritos = false;}
    );
  }
  private getBairrosByDistritos() {
    this.filter.bairro_id = "";
    this.loadings.bairros = true;
    if(this.filter.distrito_id==""){this.filter.distrito_id = null};
    this.api.get(`bairro/getBairrosByDistrito/${this.filter.distrito_id}`)
    .pipe(finalize(() => {this.loadings.bairros = false;}))
    .subscribe(response => {this.bairros = Object(response).data;}
              ,error=>{this.loadings.bairros = false;}
    );
  }
  private gerarAno() {
    var fecha = new Date();
    var anyo = fecha.getFullYear();

    let j = 0;
    for (let i = anyo; i >= 2000; i--) {
      this.anos[j] = i;
      j++;
    }
  }

  private getProvincias() {
    this.http.call_get('provincia/selectBox', null).subscribe(
      response => {
        this.provincias = Object(response).data
      }
    );
  }

  private direccaosSelectBox() {
    this.http.call_get('direccao/selectBox', null).subscribe(
      response => {
        this.direccaos = Object(response);
      }
    );
  }

  private clientes: any = [];
  view_client = false;

  private getCliente() {
    this.view_client = true;
    this.http.__call('cliente/search-cliente', { start: 1, end: 10, search: this.simpleForm.getRawValue().cliente }).subscribe(
      response => {
        this.clientes = Object(response).data.data;
      }
    );
  }

  private setCliente(client: any) {
    this.view_client = false;
    this.simpleForm.patchValue({
        cliente_id: client.id,
        cliente: client.nome
    });
/*
    this.getFilials(client.municipio_id) */
  }



 /*  private getGestores() {
    this.http.call_get('gestor/selectBox', null).subscribe(
      response => {
        this.gestores = Object(response).data
        console.log(this.gestores)
      }
    );
  } */

}




