

export class Pagination {
  lastPage: number;
  page: number = 1;
  perPage: number = 5;
  total: number;
  deserialize(input): this {
    return Object.assign(this, input);
  }
}


/*
export class Pagination {
  page: number = 1;
  itemsPerPage: number = 10
  total: number;
}
*/
