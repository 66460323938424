

import { Component, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { UtilizadorFormValidationService } from '../utilizador-form-validation.service';
 
import { first } from 'rxjs/operators';   
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

/** passwords must match - custom validator */
export const password_confirmationValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const password = control.get('password');
  const confirm = control.get('password_confirmation');
  return password && confirm && password.value === confirm.value ? null : { 'passwordMismatch': true };
};

@Component({
  selector: 'app-utilizador-alterar-password',
  templateUrl: './utilizador-alterar-password.component.html',
  styleUrls: ['./utilizador-alterar-password.component.css',
  ],
  providers: [UtilizadorFormValidationService],
  encapsulation: ViewEncapsulation.None
})
export class UtilizadorAlterarPasswordComponent {


  simpleForm: FormGroup;
  submitted = false;
  formErrors: any;
  loading = false;


  constructor(
    private fb: FormBuilder,
    private http: HttpService,
    public vf: UtilizadorFormValidationService,
    private configService: ConfigService
  ) {
    this.formErrors = this.vf.errorMessages;
    this.createForm();

  }




  createForm() {
    this.simpleForm = this.fb.group({
      old_password: ['',
        [Validators.required,
          //Validators.minLength(this.vf.formRegras.passwordMin),
          //Validators.pattern(this.vf.formRegras.passwordPattern)
        ]],
      password: ['',
        [Validators.required,
        Validators.minLength(this.vf.formRegras.passwordMin),
        Validators.pattern(this.vf.formRegras.passwordPattern)
        ]],
      password_confirmation: ['', [Validators.required]]
    }, { validator: password_confirmationValidator });
  }





  // convenience getter for easy access to form fields
  get f() {
    return this.simpleForm.controls;
  }

  onReset() {

    this.submitted = false;
    this.simpleForm.reset();

  }

  onSubmit() {

    this.submitted = true;
    // stop here if form is invalid
    if (this.simpleForm.invalid) {
      return;
    }
    this.configService.loaddinStarter('start');
    this.loading = true;

    this.http.__call('password', this.simpleForm.value)
      .pipe(first())
      .subscribe(
        res => {
          this.submitted = false;
          this.loading = false;
          this.simpleForm.reset();
        },
        error => {
          this.submitted = false;
          this.loading = false;
        });
		this.configService.loaddinStarter('stop');

  }

  /**
   * redefinirPassword
   */
  private redefinirPassword(id: number) {
    this.http.__call('/redefinir-password/' + id, { password: 'Mtti-2019', password_confirmation: 'Mtti-2019' })
      .pipe(first())
      .subscribe(
        res => {
        },
        error => {
        });

  }



}
