import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-report-movimentos-caixa',
  templateUrl: './report-movimentos-caixa.component.html',
  styleUrls: ['./report-movimentos-caixa.component.css']
})
export class ReportMovimentosCaixaComponent implements OnInit {
   
  

  ngOnInit() {
    
  }

}
