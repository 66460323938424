import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-editar-ordem-trabalho',
  templateUrl: './editar-ordem-trabalho.component.html',
  styleUrls: ['./editar-ordem-trabalho.component.css']
})
export class EditarOrdemTrabalhoComponent implements OnInit {


  @ViewChild("search") search;

 
  @Input() ordem_sigla: string = "OT";
  @Input() class_size: string = "col-md-3";

  private primeiros_niveis: any = [];
  private segundo_niveis: any = [];
  private terceiro_niveis: any = [];
  private departamentos: any = [];
  private funcoes: any = [];
  private artigos: any = [];
  private produtoCategorias: any = [];
  private recursos: any = [];
  private tipos: any = [];
  private classificacoes: any = [];
  private prioridades: any = [];
  private causas: any = [];
  private sintomas: any = [];
  private clientes: any = [];
  private local_consumo: any = [];

  private contas: any = [];
  private contratos: any = [];

  private isFinalizar: boolean = true;
  private isDisabled: boolean = false;
  private is_sintoma: boolean = false;
  private is_causa: boolean = false;
  private is_titulo: boolean = false;
  private validateButton: any = null;

  private view_client = true;
  dropdownSettings = {};
  dropdownSettingsSintomas = {};

  @Input() title: string = "Editar Ordem de Trabalho";
  @Input() stepTitle: string = "Ordem de Trabalho";
  private permission: string = null;

  routerParamId: any;

  private causasArray: any = [];
  private sintomasArray: any = [];

  private associacao_cliente = null;
  private associacao_equipamento = null;

  private associacao = {
    descricao: null,
    categoria_descricao: null
  }

  private ordemServico = {
    descricao: null,
    nivel_1: null,
    nivel_2: null,
    nivel_3: null,
    nivel_um_descricao: null,
    nivel_dois_descricao: null,
    nivel_tres_descricao: null,
    tipo_id: null,
    cliente_conta_id: null,
    cliente_contrato_id: null,
    cliente_nome: null,
    success_ordem_servico: false,
    success_ot_sem_agendamento: false,
    sintoma_id: null,
    classificacao_id: null,
    prioridade_id: null,
    data_prevista: null,
    cliente_id: null,
    causa_id: null,
    email: null,
    sla: null,
    titulo: null,
    codigo: null,
    agendamentoGerado: null,
    origem: "TECNICA"
  }

  private coordenada = {
    latitude: null,
    longitude: null,
    equipamentoDescricao: null,
    codigo_equipamento: null
  }


  private pagination = {
    start: 1,
    end: 10,
    search: null
  };

  currtentPage: number

  constructor(private auth: AuthService, private router: Router, private http: HttpService, private route: ActivatedRoute, private configService: ConfigService) {
    //this.route.params.subscribe(params => this.currtentPage = params['page']);
  }

  ngOnInit() {
    //this.getPrimeiroNivel();
    this.getClassificacao();
    this.getTipo();
    this.getPrioridade();
    //this.getSintomas();
    //this.getCausas();
    this.getRouterParamId();

  }

  private carregando = {
    nivel_2: 'Seleccione o equipamento',
    funcao: 'Seleccione a função',
    material: 'Seleccione o material',
    categoria: 'Seleccione a categoria',
    tipo: 'Seleccione o tipo',
  }

  public getRouterParamId() {

    this.routerParamId = this.route.snapshot.params.id;
    this.permission = localStorage.getItem('permission');

    //console.log(this.routerParamId)

    if (this.routerParamId != undefined) {

      this.getOrdemById(this.routerParamId)

      if(this.permission === 'listagem_ot_comercial'){
        this.title = " Editar Ordem de Serviço";
        this.stepTitle = "Ordem de Serviço";
        this.ordem_sigla = "OS";
      }else{
        this.title = "Editar Ordem de Trabalho";
        this.stepTitle = "Ordem de Trabalho";
        this.ordem_sigla = "OT";
      }

    }

  }

  private getOrdemById(id) {
    this.http.call_get('ordemservico/get-info-ordem-by-id/' + id, null).subscribe(
      response => {

        if (Object(response).data == undefined) {
          this.router.navigate(['/ordemservico/listagem']);
        }

        this.ordemServico.codigo = Object(response).data.codigo;
        this.ordemServico.descricao = Object(response).data.descricao;
        this.ordemServico.titulo = Object(response).data.titulo;
        this.ordemServico.classificacao_id = Object(response).data.classificacao_id;
        this.ordemServico.tipo_id = Object(response).data.tipo_id;
        this.ordemServico.prioridade_id = Object(response).data.prioridade_id;
        this.ordemServico.data_prevista = Object(response).data.data_prevista_format;
        this.ordemServico.sla = Object(response).data.sla;
        this.associacao.categoria_descricao = Object(response).data.categoria_descricao;

        this.getAssociacao()
      }
    );
  }

  private setNivelUm(item) {
    this.ordemServico.nivel_1 = item.id;
    this.ordemServico.nivel_um_descricao = item.descricao;
    this.view_nivel_um = false;

  }

  //SEARCH NÍVEL UM
  view_nivel_um = false;

  private searchNivelUm() {

    this.ordemServico.nivel_2 = null;
    this.ordemServico.nivel_dois_descricao = null;
    this.ordemServico.nivel_3 = null;
    this.ordemServico.nivel_tres_descricao = null;

    if (this.ordemServico.nivel_um_descricao == "" || this.ordemServico.nivel_um_descricao == null) {
      this.ordemServico.nivel_1 = null;
      this.ordemServico.nivel_um_descricao = null;
      this.view_nivel_um = false;
    }

    this.primeiros_niveis = [];

    this.view_nivel_um = true;
    this.http.__call('ordemservico/equipamento/search-primeiro-nivel', { start: 1, end: 10, search: this.ordemServico.nivel_um_descricao }).subscribe(
      response => {

        this.primeiros_niveis = Object(response).data.data;

      }
    );
  }


  private setNiveldois(item) {
    this.ordemServico.nivel_2 = item.id;
    this.ordemServico.nivel_dois_descricao = item.descricao;
    this.view_nivel_dois = false;

  }

  //SEARCH NÍVEL 2
  view_nivel_dois = false;

  private searchNiveldois() {

    this.ordemServico.nivel_3 = null;
    this.ordemServico.nivel_tres_descricao = null;

    if (this.ordemServico.nivel_um_descricao == "" || this.ordemServico.nivel_um_descricao == null) {
      this.ordemServico.nivel_2 = null;
      this.ordemServico.nivel_dois_descricao = null;
      this.view_nivel_dois = false;
    }

    this.segundo_niveis = [];

    this.view_nivel_dois = true;
    this.http.__call('ordemservico/equipamento/search-segundo-nivel-by-primeiro/' + this.ordemServico.nivel_1, { start: 1, end: 10, search: this.ordemServico.nivel_dois_descricao }).subscribe(
      response => {

        this.segundo_niveis = Object(response).data.data;
        this.carregando.nivel_2 = 'Selecione o equipamento';

      }
    );
  }

  private getLatitudeLongitude() {

    this.coordenada.latitude = null;
    this.coordenada.longitude = null;
    this.coordenada.codigo_equipamento = null;

    let id = (this.ordemServico.nivel_3 != null ? this.ordemServico.nivel_3 : this.ordemServico.nivel_3 == null && this.ordemServico.nivel_2 != null ? this.ordemServico.nivel_2 : this.ordemServico.nivel_1)

    if (id != null) {
      this.configService.loaddinStarter('start');
      this.http.call_get('ordemservico/equipamento/getCoordenadas/' + id, null).subscribe(
        response => {
          this.coordenada.latitude = Object(response).data.latitude;
          this.coordenada.longitude = Object(response).data.longitude;
          this.coordenada.equipamentoDescricao = Object(response).data.descricao;
          this.coordenada.codigo_equipamento = Object(response).data.codigo_registo;
          this.configService.loaddinStarter('stop');
        }
      );
    }

  }

  private getAssociacao() {


    this.is_titulo = false;
    this.associacao.categoria_descricao = null

    this.clearFormInputs();

    if (this.ordemServico.tipo_id != null) {
      this.configService.loaddinStarter('start');
      this.http.call_get('ordemservico/ostipo/associacao/' + this.ordemServico.tipo_id, null).subscribe(
        response => {
          this.associacao_cliente = Object(response).data.cliente;
          if (this.associacao_cliente) {
            this.getClienteByOrdemTrabalho()
          }
          this.associacao_equipamento = Object(response).data.equipamento;
          if (this.associacao_equipamento) {
            this.getEquipamentoByOrdemTrabalho()
          }
          this.associacao.descricao = Object(response).data.descricao;

          this.is_sintoma = Object(response).data.sintoma;
          this.is_causa = Object(response).data.causa;

          if(this.is_causa){
             this.getCausasToEdit();
          }

          if(this.is_sintoma){
            this.getSintomaToEdit();
         }

          this.is_titulo = Object(response).data.is_titulo;

          if (this.is_titulo) {

            this.ordemServico.titulo = this.associacao.descricao
          }
          this.configService.loaddinStarter('stop');
        }

      );
    }

    this.is_titulo = false

  }

  private getCausasToEdit() {

    this.http.call_get('ordemservico/causaToEdit/selectBox/'+ this.routerParamId, null).subscribe(
      response => {
        this.causas = Object(response).data;

        this.causasArray = Object(response).data;

        this.dropdownSettings = {
          singleSelection: false,
          idField: 'id',
          textField: 'descricao',
          selectAllText: 'Seleccione Todos',
          unSelectAllText: 'DeSeleccionar Todos',
          itemsShowLimit: 10,
          allowSearchFilter: true
        };

      }
    );
  }

  private getSintomaToEdit() {

    this.http.call_get('ordemservico/sintomaToEdit/selectBox/'+ this.routerParamId, null).subscribe(
      response => {
        this.sintomas = Object(response).data;
        this.sintomasArray = Object(response).data;

        this.dropdownSettingsSintomas = {
          singleSelection: false,
          idField: 'id',
          textField: 'descricao',
          selectAllText: 'Seleccione Todos',
          unSelectAllText: 'DeSeleccionar Todos',
          itemsShowLimit: 10,
          allowSearchFilter: true
        };

      }
    );
  }

  private getEquipamentoByOrdemTrabalho() {
    this.http.call_get('ordemservico/get-equipamento-by-ordem-trabalho/' + this.routerParamId, null).subscribe(
      response => {

        this.ordemServico.nivel_1 = Object(response).data.primeiro_nivel_id;
        this.ordemServico.nivel_2 = Object(response).data.segundo_nivel_id;
        this.ordemServico.nivel_3 = Object(response).data.terceiro_nivel_id;

        if (this.ordemServico.nivel_1 == null) {
          this.ordemServico.nivel_um_descricao = Object(response).data.terceriro_nivel;
          this.ordemServico.nivel_1 = Object(response).data.terceiro_nivel_id;
          this.ordemServico.nivel_2 = null;
          this.ordemServico.nivel_3 = null;
        }else if (this.ordemServico.nivel_1 != null && this.ordemServico.nivel_2 == null) {
          this.ordemServico.nivel_um_descricao = Object(response).data.primeiro_nivel;
          this.ordemServico.nivel_dois_descricao = Object(response).data.terceriro_nivel;

          this.ordemServico.nivel_1 = Object(response).data.primeiro_nivel_id;
          this.ordemServico.nivel_2 = Object(response).data.terceiro_nivel_id;
          this.ordemServico.nivel_3 = null;
        }else if ((this.ordemServico.nivel_1 != null && this.ordemServico.nivel_2 != null) && this.ordemServico.nivel_3 == null) {
          this.ordemServico.nivel_um_descricao = Object(response).data.primeiro_nivel;
          this.ordemServico.nivel_dois_descricao = Object(response).data.segundo_nivel;
          this.ordemServico.nivel_tres_descricao = Object(response).data.terceriro_nivel;

          this.ordemServico.nivel_1 = Object(response).data.primeiro_nivel_id;
          this.ordemServico.nivel_2 = Object(response).data.segundo_nivel_id;
          this.ordemServico.nivel_3 = Object(response).data.terceiro_nivel_id;


        }else if ((this.ordemServico.nivel_1 != null && this.ordemServico.nivel_2 != null) && this.ordemServico.nivel_3 != null) {
          this.ordemServico.nivel_um_descricao = Object(response).data.primeiro_nivel;
          this.ordemServico.nivel_dois_descricao = Object(response).data.segundo_nivel;
          this.ordemServico.nivel_tres_descricao = Object(response).data.terceriro_nivel;

          this.ordemServico.nivel_1 = Object(response).data.primeiro_nivel_id;
        this.ordemServico.nivel_2 = Object(response).data.segundo_nivel_id;
        this.ordemServico.nivel_3 = Object(response).data.terceiro_nivel_id;


        }

        this.getNiveldois();
        this.getNivelTres();
        this.getLatitudeLongitude()

      }
    );
  }

  private getClienteByOrdemTrabalho() {
    this.http.call_get('ordemservico/get-cliente-by-ordem-trabalho/' + this.routerParamId, null).subscribe(
      response => {

        this.ordemServico.cliente_nome = Object(response).data.cliente_nome;
        this.ordemServico.cliente_id = Object(response).data.cliente_id;
        this.ordemServico.cliente_conta_id = Object(response).data.conta_id;
        this.ordemServico.cliente_contrato_id = Object(response).data.contrato_id;

        this.getContaByCliente(this.ordemServico.cliente_id);
        this.getContratosByConta();


      }
    );
  }

  private getContaByCliente(id) {

    this.contas = [];

    this.local_consumo.cil = null,
      this.local_consumo.numero_serie = null,
      this.local_consumo.predio_nome = null

    this.http.call_get('cliente/conta/selectBox/' + id, null).subscribe(
      response => {
        this.contas = Object(response).data
      })
  }

  private setNivelTres(item) {
    this.ordemServico.nivel_3 = item.id;
    this.ordemServico.nivel_tres_descricao = item.descricao;
    this.view_nivel_treis = false;

  }

  //SEARCH NÍVEL 3
  view_nivel_treis = false;

  private searchNivelTres() {

    if (this.ordemServico.nivel_tres_descricao == "" || this.ordemServico.nivel_tres_descricao == null) {
      this.ordemServico.nivel_3 = null;
      this.ordemServico.nivel_tres_descricao = null;
      this.view_nivel_treis = false;
    }

    this.terceiro_niveis = [];

    this.view_nivel_treis = true;
    this.http.__call('ordemservico/equipamento/search-terceiro-nivel-by-segundo/' + this.ordemServico.nivel_2, { start: 1, end: 10, search: this.ordemServico.nivel_tres_descricao }).subscribe(
      response => {

        this.terceiro_niveis = Object(response).data.data;

      }
    );
  }


  private getNiveldois() {

    this.segundo_niveis = [];

    if (this.ordemServico.nivel_1 != null) {
      this.http.__call('ordemservico/equipamento/search-segundo-nivel-by-primeiro/' + this.ordemServico.nivel_1, { start: 1, end: 10, search: null }).subscribe(
        response => {

          this.segundo_niveis = Object(response).data.data;

        }
      );
    }

  }

  private getNivelTres() {

    this.terceiro_niveis = [];

    if (this.ordemServico.nivel_2 != null) {

      this.http.__call('ordemservico/equipamento/search-terceiro-nivel-by-segundo/' + this.ordemServico.nivel_2, { start: 1, end: 10, search: null }).subscribe(
        response => {

          this.terceiro_niveis = Object(response).data.data;

        }
      );

    }
  }

  private getPrioridade() {
    this.http.call_get('ordemservico/prioridade/selectBox', null).subscribe(
      response => {
        this.prioridades = Object(response).data;
      }
    );
  }

  private getClassificacao() {
    this.ordemServico.classificacao_id=null
    this.tipos =[]
    this.http.call_get('ordemservico/classificacao/selectBox', null).subscribe(
      response => {
        this.classificacoes = Object(response).data;
      }
    );
  }

  private getTipoByClassificacao() {
    if(!this.ordemServico.classificacao_id)return
    this.carregando.tipo = 'Carregando...';
    this.http.__call('ordemservico/ostipo-trigger-by-flag/selectBox/' + this.ordemServico.classificacao_id, {flag:"MANUAL"}).subscribe(
      response => {
        this.tipos = Object(response).data;
        this.carregando.tipo = 'Seleccione o tipo';
      }
    );

    /* this.getCausasByClassificacao();
    this.getSintomasByClassificacao(); */
  }

  private getTipo() {

    this.http.call_get('ordemservico/ostipo/selectBox', null).subscribe(
      response => {
      //  this.tipos = Object(response).data;

      }
    );
  }
 

  private getCausasByClassificacao() {

    this.http.call_get('ordemservico/oscausas-by-classificacao/selectBox/'+ + this.ordemServico.classificacao_id).subscribe(
      response => {
        this.causas = Object(response).data;

        this.dropdownSettings = {
          singleSelection: false,
          idField: 'id',
          textField: 'descricao',
          selectAllText: 'Seleccione Todos',
          unSelectAllText: 'DeSeleccionar Todos',
          itemsShowLimit: 10,
          allowSearchFilter: true
        };

      }
    );
  }

  onItemSelect(item: any) {
    //console.log(item);
    this.causasArray.push(item);
  }
  onSelectAll(items: any) {
    this.causasArray = items;
  }

  private getSintomasByClassificacao() {
    this.http.call_get('ordemservico/ossintomas-by-classificacao/selectBox/' + this.ordemServico.classificacao_id).subscribe(
      response => {
        this.sintomas = Object(response).data;

        this.dropdownSettingsSintomas = {
          singleSelection: false,
          idField: 'id',
          textField: 'descricao',
          selectAllText: 'Seleccione Todos',
          unSelectAllText: 'DeSeleccionar Todos',
          itemsShowLimit: 10,
          allowSearchFilter: true
        };

      }
    );
  }

  onItemSelectSintoma(item: any) {
    this.sintomasArray.push(item);
  }
  onSelectAllSintomas(items: any) {
    this.sintomasArray = items;
  }

  private getCliente() {
    this.contas = []
    this.contratos = []
    this.view_client = true;
    this.http.__call('cliente/search-cliente', { start: 1, end: 10, search: this.ordemServico.cliente_nome }).subscribe(
      response => {
        this.clientes = Object(response).data.data;
      })
  }

  private setCliente(client: any) {
    this.getContas(client)

    this.ordemServico.cliente_nome = client.nome,

      this.view_client = false;
  }

  private getContas(cliente) {

    this.contas = [];

    this.local_consumo.cil = null,
      this.local_consumo.numero_serie = null,
      this.local_consumo.predio_nome = null

    this.http.call_get('cliente/conta/selectBox/' + cliente.id, null).subscribe(
      response => {
        this.contas = Object(response).data
      })
  }

  public getContratosByConta() {

    this.contratos = [];

    if (this.ordemServico.cliente_conta_id != null) {

      this.configService.loaddinStarter('start');
      this.http.__call('conta/contratoByConta/' + this.ordemServico.cliente_conta_id, null).subscribe(
        response => {
          this.contratos = Object(response);
          this.configService.loaddinStarter('stop');
        }
      );

    }
  }

  private getLocalConsumoByContratoId() {

    this.local_consumo.cil = null;
    this.local_consumo.numero_serie = null;
    this.local_consumo.predio_nome = null;

    if (this.ordemServico.cliente_contrato_id != null) {


      this.http.call_get('local-consumo/getLocalConsumoByContratoId/' + this.ordemServico.cliente_contrato_id, null).subscribe(
        response => {

          this.local_consumo = Object(response);
          this.local_consumo.cil = this.local_consumo.cil == null ? '' : this.local_consumo.cil;
          this.local_consumo.numero_serie = this.local_consumo.numero_serie == null ? '' : this.local_consumo.numero_serie;
          this.local_consumo.predio_nome = ((this.local_consumo.cil != null) ? '[' + this.local_consumo.cil + '] ' : '[0000-000-000] ') + ((this.local_consumo.is_predio) ? 'Prédio ' + this.local_consumo.predio_nome + ', ' + this.local_consumo.predio_andar + 'ºAndar - Porta ' : 'Residência ') + this.local_consumo.moradia_numero;

        }
      );
    }

  }


  private createOrdemServico() {

    if (this.ordemServico.titulo == null || this.ordemServico.titulo == "") {
      this.configService.showAlert("Digite o Título", 'alert-danger', true);
    } else if (this.ordemServico.data_prevista == null || this.ordemServico.data_prevista == "") {
      this.configService.showAlert("Digite a Data prevista", 'alert-danger', true);
    } else if (this.ordemServico.prioridade_id == null) {
      this.configService.showAlert("Seleccione a prioridade", 'alert-danger', true);
    } else if (this.ordemServico.classificacao_id == null) {
      this.configService.showAlert("Seleccione a classificação", 'alert-danger', true);
    } else if (this.ordemServico.tipo_id == null) {
      this.configService.showAlert("Seleccione o tipo", 'alert-danger', true);
    } else if ((this.associacao_equipamento && this.ordemServico.tipo_id != null) && this.ordemServico.nivel_1 == null) {
      this.configService.showAlert("Seleccione o equipamento", 'alert-danger', true);
    } else if ((this.associacao_cliente && this.ordemServico.tipo_id != null) && (this.ordemServico.cliente_nome == null || this.ordemServico.cliente_nome == "")) {
      this.configService.showAlert("Seleccione o Cliente", 'alert-danger', true);
    } else if ((this.associacao_cliente && this.ordemServico.tipo_id != null) && this.ordemServico.cliente_conta_id == null) {
      this.configService.showAlert("Seleccione a Conta", 'alert-danger', true);
    } else if ((this.associacao_cliente && this.ordemServico.tipo_id != null) && this.ordemServico.cliente_contrato_id == null) {
      this.configService.showAlert("Seleccione o Contrato", 'alert-danger', true);
    } else if (this.ordemServico.descricao == null || this.ordemServico.descricao == "") {
      this.configService.showAlert("Digite a descrição", 'alert-danger', true);
    } else {
      this.configService.loaddinStarter('start');
      this.isFinalizar = true;
      this.isDisabled = true;
      this.http.__call('ordemservico/ordem-servico/update', {
        ordemServico: this.ordemServico,
        causas: this.causasArray,
        sintomas: this.sintomasArray,
        routerParamId: this.routerParamId,
      }).subscribe(
        res => {
          if (Object(res).code == 200) {
            this.isDisabled = true;
            this.validateButton = Object(res).code
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.clearFormInputs();
            this.ordemServico.success_ordem_servico = true;

            this.configService.loaddinStarter('stop');
          } else {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
            this.isFinalizar = false;
            this.isDisabled = false;
            this.configService.loaddinStarter('stop');
          }
        }
      )
    };
  }

  private clearFormInputs() {

    this.ordemServico.nivel_1 = null,
      this.ordemServico.nivel_2 = null,
      this.ordemServico.nivel_3 = null,
      this.ordemServico.cliente_conta_id = null,
      this.ordemServico.cliente_contrato_id = null,
      this.ordemServico.cliente_nome = null,
      this.ordemServico.success_ordem_servico = false,
      this.is_sintoma = false,
      this.is_causa = false,
      this.ordemServico.agendamentoGerado = null

  }

  private reloadOrdemServico() {
    location.reload();
  }


}
