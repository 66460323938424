import { Component, OnInit, ViewChild, forwardRef, SimpleChange  } from '@angular/core';
import { FullCalendarComponent  } from '@fullcalendar/angular';
import { EventInput, Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGrigPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction'; // for dateClick
import { BsModalService, BsModalRef, ModalDirective, ModalModule } from 'ngx-bootstrap/modal';
import { HttpService } from 'src/app/providers/http/http.service';


@Component({
  selector: 'app-mp-calendario-tarefas',
  templateUrl: './mp-calendario-tarefas.component.html',
  styleUrls: ['./mp-calendario-tarefas.component.css']
})
export class MpCalendarioTarefasComponent implements OnInit {

  private loading: boolean = false;
  events: any;

  @ViewChild('modalShowEvent') public modalShowEvent: ModalDirective;
  @ViewChild('calendar') calendarComponent: FullCalendarComponent; // the #calendar in the template

  //items: Array<CalendarEvent<{ time: any }>> = [];

  //calendarEvents: EventInput[];

  calendarVisible = true;
  calendarPlugins = [dayGridPlugin, timeGrigPlugin, interactionPlugin];
  calendarWeekends = true;
  calendarEvents: EventInput[] = [];

  data: any = [];
  eventsCalendar: any = [];

  private dadoModal = {
    tarefaDescricao: null,
    equipamentoDescricao: null,
    equipamentoCodigo: null,
    estadoDescricao: null,
    estadoFlag: null
  }
  constructor(private http: HttpService) { }

  ngOnInit() {
     this.loadEvents();
     //this.calendarEvents = this.data;
     this.calendarEvents = this.eventsCalendar;
 
  }

  loadEvents() {

    //this.loading = true;
    var validar = 0;
    this.http.call_get('manutencao-preventiva/tarefas/calendario', null).subscribe(
      response => {
        this.data = Object(response).data;


        var data_actual = new Date(this.data.data_inicio);
          var date = new Date();
          var sub_result = this.data.periodicidade - this.data.contador; 
          date.setDate(data_actual.getDate() + sub_result); //Adiciona dias

          var dia = date.getDate();
          var ano = date.getFullYear();
          var mes = date.getMonth() + 1;

          let format_mes = mes.toString().length < 2 ? "0" + mes : mes;
          let format_dia = dia.toString().length < 2 ? "0" + dia : dia;

          var format_data = ano + "-" + format_mes + "-" + format_dia;

        let calendarevent = {
          id: this.data.id,
          title: this.data.codigo_primeiro_nivel +"\n"+(this.data.codigoEquipamento == null ? '' : this.data.codigoEquipamento) +"\n"+this.data.descricao,
          date: format_data, //'2021-10-23',
          color: "#fff"
        };

        if (this.data.length >= 1) {
        for (let i = 0; i < this.data.length; i++) {

          var data_actual = new Date(this.data[i].data_inicio);
          var date = new Date();

          var sub_result = this.data[i].periodicidade - this.data[i].contador; 
          date.setDate(data_actual.getDate() + sub_result); //Adiciona dias

          var dia = date.getDate();
          var ano = date.getFullYear();
          var mes = date.getMonth() + 1;

          let format_mes = mes.toString().length < 2 ? "0" + mes : mes;
          let format_dia = dia.toString().length < 2 ? "0" + dia : dia;

          var format_data = ano + "-" + format_mes + "-" + format_dia;

          let calendarevent = {
            id: this.data[i].id,
            title: this.data[i].codigo_primeiro_nivel +"\n"+(this.data[i].codigoEquipamento == null ? '' : this.data[i].codigoEquipamento) +"\n"+this.data[i].descricao,
            date: format_data,
            color: "#fff"
          };

          const l = this.data[i];

          if (l.id == calendarevent.id) {
            this.data.splice(i, 1);
            this.data.splice(i, 0, calendarevent);
            validar = 1;
          }

         //this.eventsCalendar.push(calendarevent);

        }if (validar == 0) {
          this.data.push(calendarevent);
        }
        }else {
          this.data.push(calendarevent);
        }

        this.calendarEvents = this.data;

        //console.log(this.calendarEvents)

        //this.calendarEvents = this.eventsCalendar;

        
        //this.loading = false

      }
      
    );
  }
 
  toggleVisible() {
    this.calendarVisible = !this.calendarVisible;
    this.loadEvents()
  }

  toggleWeekends() {
    this.calendarWeekends = !this.calendarWeekends;
  }

  gotoPast() {
    let calendarApi = this.calendarComponent.getApi();
    calendarApi.gotoDate('2000-01-01'); // call a method on the Calendar object
  }

  eventClick(arg) {
    //alert(arg);
    let id = parseInt(arg.event._def.publicId);

    this.getInfoModal(id);
    this.modalShowEvent.show();
  }
  
  getInfoModal(id){
    this.http.call_get('manutencao-preventiva/calendario-modal-info/' +id, null).subscribe(
      response => {
        this.dadoModal.equipamentoCodigo = Object(response).data.codigo_registo;
        this.dadoModal.equipamentoDescricao = Object(response).data.equipamentoDescricao;
        this.dadoModal.tarefaDescricao = Object(response).data.tarefaDescricao;
        this.dadoModal.estadoDescricao = Object(response).data.estadoDescricao;
        this.dadoModal.estadoFlag = Object(response).data.estadoFlag;
  
      }
      
    );
  }

  clearModalInfo(){
    this.dadoModal.equipamentoCodigo = null;
    this.dadoModal.equipamentoDescricao = null;
    this.dadoModal.tarefaDescricao = null
    this.dadoModal.estadoDescricao = null
    this.dadoModal.estadoFlag = null
  }
  
  private closeModalShowEvent() {
    this.modalShowEvent.hide();
    this.clearModalInfo()
  }

  handleDateClick(arg) {
    if (confirm('Would you like to add an event to ' + arg.dateStr + ' ?')) {
      this.calendarEvents = this.calendarEvents.concat({ // add new event data. must create new array
        title: 'New Event',
        start: arg.date,
        allDay: arg.allDay
      })
    }
  }

}
