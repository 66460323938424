import { Component, OnInit, Input,ViewChild } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-view-clientes-by-ordem',
  templateUrl: './view-clientes-by-ordem.component.html',
  styleUrls: ['./view-clientes-by-ordem.component.css']
})
export class ViewClientesByOrdemComponent implements OnInit {


  @Input() modal: string = "modalViewClientesByOrdem";
  @Input() res: any;
  @Input() item: any;

  public filters = {
    search: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    pagination: {
      perPage: 5,// Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima, 
      lastPage: null,
      total: null // defini o total de registo da BD
    },
    filter: null // subelemente do filter
  }

  constructor(private http: HttpService, private configService: ConfigService, private formBuilder: FormBuilder) { 
    
  }

  ngOnInit() {
  }

}
