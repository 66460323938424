import { Component, OnInit, SimpleChange, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ConfigService } from 'src/app/providers/config/config.service';
import { HttpService } from 'src/app/providers/http/http.service';
var moment = require("moment");

@Component({
  selector: 'app-create-or-edit-conta-bancaria',
  templateUrl: './create-or-edit-conta-bancaria.component.html',
  styleUrls: ['./create-or-edit-conta-bancaria.component.css']
})
export class CreateOrEditContaBancariaComponent implements OnInit {

  @Input() modalContaBancariaCreateOrEdit: string = "modalContaBancariaCreateOrEdit";
  @Input() title: string = "Registar Conta Bancária";
  @Input() contabancaria: any;

  @Input() provincias: any = [];
  @Input() municipios: any = [];
  @Input() bancos: any = [];

  submitted = false;
  private loading: boolean = false;

  @Input() simpleFormContaBancaria: FormGroup;
  @Input() selectForms: any = [];

  @Output() private loadListcontabancaria = new EventEmitter<any>();
  

  constructor(private http: HttpService, private configService: ConfigService, private formBuilder: FormBuilder) {
    this.createForm();
  }

  ngOnInit() {
  }

  createForm() {
    this.simpleFormContaBancaria = this.formBuilder.group({
      index: [{ value: null, disabled: true }],
      provincia_id: [null, Validators.required],
      municipio_id: [null, Validators.required],
      banco_id: [null, Validators.required],
      iban: [null, Validators.required],


    });
  }

  get f() {
    return this.simpleFormContaBancaria.controls;
  }

  onReset() {
    this.submitted = false;
    this.simpleFormContaBancaria.reset();
  }


  onSubmit() {

    this.submitted = true;

    if (this.simpleFormContaBancaria.invalid) {
      return;
    }
    this.loading = true;
    const index = this.simpleFormContaBancaria.getRawValue().index;
    // TODO: usado para fazer a requisição com a api de criação or update
    const uri = (index === null ? 'conta-bancaria/create' : 'conta-bancaria/update/' + index);
    this.createOrEdit(uri, this.simpleFormContaBancaria, (index === null ? true : false));

  }

  createOrEdit(uri: any, formulario: FormGroup, isCreate: boolean) {

    // TODO: usado para fazer a requisição com a api de criação de object
    this.http.__call(uri, formulario.value).pipe(first()).subscribe(
      response => {
        this.submitted = false;
        this.loading = false;

        if (Object(response).code == 201) {
          this.loadListcontabancarias(Object(response).data);

          this.openCreateModal(true);
        }

        else if (isCreate) {
          formulario.reset();

          this.loadListcontabancarias(Object(response).data);
        }

        if (Object(response).code == 200) {
          this.loadListcontabancarias(Object(response).data);

          this.openCreateModal(true);
        }

        this.configService.showAlert(Object(response).message, 'alert-info', true);
      },
      error => {
        this.submitted = false;
        this.loading = false;
      });
  }

  private createModal: boolean = false;
  private openCreateModal(open: boolean): void {
    this.createModal = open;
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {

     if (this.contabancaria.id > 0) { 
/*         if (this.contabancaria !== undefined ) {  */
      this.title = "Editar Conta Bancária";
      this.simpleFormContaBancaria.patchValue({
        index: this.contabancaria.id,
        municipio_id: this.contabancaria.municipio_id,
        banco_id: this.contabancaria.banco_id,
        iban: this.contabancaria.iban,
      });
    } else {
      this.onReset();
      this.title = "Registar Conta Bancária";
    }
  }

  private id_municipio: number

  private selectBoxMunicipiosByProvincia(id) {

    this.http.call_get('municipio/getMunicipiosByProvincia/' + this.id_municipio, null).subscribe(
      response => {
        this.municipios = Object(response).data;
      }
    );
  }

  public loadListcontabancarias(contabancaria) {
    this.loadListcontabancaria.emit(contabancaria);
  }
}
