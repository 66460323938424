
import { Component, OnInit, Input } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-abertura',
  templateUrl: './abertura.component.html',
  styleUrls: ['./abertura.component.css']
})
export class AberturaComponent implements OnInit {

  @Input() aberutaCaixaModal: string = "aberutaCaixaModal";

  submitted = false;
  simpleForm: FormGroup;
  loading: boolean = false;

  validar = true;

  constructor(private http: HttpService, private configService: ConfigService, private formBuilder: FormBuilder) {
    this.createForm();
  }

  ngOnInit() {

  }




  createForm() {
    this.simpleForm = this.formBuilder.group({
      index: [{ value: null, disabled: true }],
      valor_abertura: ['', Validators.required]
    });
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.simpleForm.controls;
  }

  onReset() {
    this.submitted = false;
    this.simpleForm.reset();
  }

  onSubmit() {
    this.submitted = true;
    // parar aquei se o simpleFormulário for inválido
    if (this.simpleForm.invalid) {
      return;
    }

    this.loading = true;
    const uri = 'caixa/abertura';
    this.createOrEdit(uri, this.simpleForm, true);

  }

  createOrEdit(uri: any, simpleFormulario: FormGroup, isCreate: boolean) { 
    // TODO: usado para fazer a requisição com a api de criação de organismo
    this.http.__call(uri, simpleFormulario.value).subscribe(
      res => {

        if (Object(res).code == 200) {
          this.submitted = false;
          if (isCreate) {
            simpleFormulario.reset();
          }
          this.validar = false; 
        }  
        this.loading = false;
      }); 
  }
}
