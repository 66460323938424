import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable()
export class NotificacaoService {

    private token = sessionStorage.getItem('sessionToken')
  
    private headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization', `Bearer ${this.token}`)

    constructor(private http: HttpClient) {  }


    getNotificacoes(){
        this.http.get("/api/v1/notifications/config",{headers : this.headers})
    }

    
   
}