import { Injectable, EventEmitter } from '@angular/core';
import { ApiService } from 'src/app/providers/http/api.service';
import { HttpService } from 'src/app/providers/http/http.service';
import * as moment from 'moment';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';

@Injectable({
  providedIn: 'root'
})
export class ContratoService {

  public pdfEvent = new EventEmitter<Object>();

  private report: any;

  constructor(
    private http: HttpService,
  ) { }

  public imprimirPDFContrato(id, report = 'imprimir') {
    this.http._get('contrato/imprimir/' + id).subscribe(
      response => {
        const dados = Object(response).data;
        this.imprimirContrato(dados, report)
      }, error => {
        console.log(error)
      }
    );
  }

  public numberFormat(number) {
    return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(number).replace('€', '').trim();
  }

  public imprimirReciboCaucao(item, report, img_logotipo, empresa) {

    console.log("empresa", empresa)

    // You'll need to make your image into a Data URL
    // Use http://dataurl.net/#dataurlmaker
    var imgData = img_logotipo;

    /* ===== CONTEN EMPRESA =====*/
    var _empresa = {
      nif: empresa.taxRegistrationNumber || '---',
      email: empresa.email || '---',
      telefone: empresa.telefone || '---',
      site: empresa.site || '---',
      sigla_empresa: empresa.sigla_empresa || '---',
      addressDetail: empresa.addressDetail || '---',
      companyName: empresa.companyName || '---'
    }

    var doc = new jsPDF()

    doc.setFontSize(8)
    doc.addImage(imgData, 'JPEG', 10, 17, 24, 20)
    doc.setFontStyle("bold")
    doc.text(40, 22, '' + _empresa.companyName)

    doc.setFontStyle("normal")
    doc.text(40, 30, '' + _empresa.addressDetail)
    doc.text(40, 36, 'Nif: ' + _empresa.nif)
    doc.text(40, 42, 'Email: ' + _empresa.email)

    doc.text(100, 36, 'Telefone: ' + _empresa.telefone)
    doc.text(100, 42, 'Website: ' + _empresa.site)

    /* ===== CONTEN CONTRACT =====*/
    var contrato = {
      contrato: item.id || '---',
      cil: item.cil || '---'
    }

    doc.setFillColor(0);
    doc.setFillColor(255);
    doc.rect(155, 19, 50, 20, 'B');

    doc.setTextColor(0);
    doc.setFontStyle("bold")
    doc.text(160, 27, 'Contrato: ' + contrato.contrato)
    doc.text(160, 35, 'Cil: ' + contrato.cil)

    var locais = {
      numero: item.local_consumo_id || '---',
      cil: item.cil || '---',
      localidade: item.municipio || "---",
      bairo_zona: item.bairro || '---',
      rua: item.rua || '---',
      apartamento: (item.moradia_numero || '') || "---",
      predio: (item.predio_nome || '') || '---',
    }

    doc.setFontSize(18)
    doc.text(10, 55, 'RECIBO DE CAUÇÃO')

    /* ===== CONTENT LEFT ===== */
    doc.setFontSize(8)
    doc.text(10, 70, 'LOCAL CONSUMO:')
    doc.text(50, 70, '' + locais.numero)
    doc.text(10, 80, 'Cil:')
    doc.text(50, 80, locais.cil)
    doc.text(10, 87, 'Morada Local de Consumo:')

    doc.setFontStyle("normal")
    doc.text(10, 95, 'Localidade:')
    doc.text(50, 95, locais.localidade)
    doc.text(10, 102, 'Bairro/Zona:')
    doc.text(50, 102, locais.bairo_zona)
    doc.text(10, 108, 'Rua:')
    doc.text(50, 108, locais.rua)
    doc.text(10, 112, 'Apartamento/Moradia/Caixa:')
    doc.text(50, 112, locais.apartamento)
    doc.text(10, 118, 'Prédio:')
    doc.text(50, 118, locais.predio)

    var cliente = {
      numero: item.cliente_id || '---',
      nome: item.cliente_nome || '---',
      nif: item.nif || '---',
      telefone: item.cliente_telefone || '---',
      email: item.cliente_email || '---',
    }

    /* ===== CONTENT RIGHT ===== */
    doc.setFontStyle("bold")
    doc.text(100, 70, 'Cliente Nº:')
    doc.text(115, 70, '' + cliente.numero)
    doc.text(100, 80, '' + cliente.nome)

    doc.setFontStyle("normal")
    doc.text(100, 85, 'Nif:')
    doc.text(115, 85, '' + cliente.nif)
    doc.text(100, 92, 'Telefone:')
    doc.text(115, 92, '' + cliente.telefone)
    doc.text(100, 100, 'Email:')
    doc.text(115, 100, '' + cliente.email)

    var caucao = this.numberFormat(item.caucao_recebida) || 0.0

    doc.text(10, 140, 'Recebi AOA ' + caucao + ' da Sr(a) ' + cliente.nome + ', portadora do Nif: ' + cliente.nif + '.', { maxWidth: 180 })
    doc.text(10, 160, 'Sendo, AOA ' + caucao + ' da caução do contrato celebrado, com o código de identificação do local nº: ' + locais.cil + '', { maxWidth: 180 })

    doc.text(10, 175, 'Para maior clareza firmam o presente,')

    doc.setFontStyle("bold")
    doc.text(23, 190, '__________________________________');
    doc.text(30, 198, 'Em representação da ' + _empresa.sigla_empresa)
    doc.text(120, 190, '__________________________________');
    doc.text(144, 198, 'Cliente')

    /* ===== FOOTER ===== */
    var footer = {
      data_actual: moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
    }

    doc.text(10, 268, '' + footer.data_actual)
    doc.text(180, 268, 'Página: 1/1')
    doc.text(10, 270, '_____________________________________________________________________________________________________________________');
    doc.setFontSize(6)
    doc.text(12, 275, 'Nif: ' + _empresa.nif + ' -  ' + _empresa.companyName + ' / ' + _empresa.addressDetail + ' / ' + _empresa.telefone + ' / / ' + _empresa.email, { maxWidth: 190 })
    doc.text(70, 278, 'Processado por programa validado nº 4/AGT119')

    if (report == 'imprimir') {
      doc.autoPrint();
      window.open(doc.output("bloburl")); //opens the data uri in new window
    } else {
      doc.save(item.id + '.pdf'); /* download the file immediately on loading */
    }
  }

  public imprimirContrato(item, report = 'imprimir') {

    const contrato = item.contrato;
    const tipo_identidades = item.tipo_identidades;
    const empresa = item.empresa;
    const historico_contrato = item.historico_contrato

    var img_logotipo = empresa.logotipo;
    var doc = new jsPDF()
    doc.addImage(img_logotipo, 'JPEG', 10, 17, 24, 20)

    doc.setFontSize(8);
    doc.setFont("calibri");
    doc.setTextColor(0);
    doc.text('' + empresa.companyName, 37, 20);
    doc.setTextColor(0);
    doc.text('' + empresa.addressDetail, 37, 25);
    doc.text('NIF: ' + empresa.taxRegistrationNumber, 37, 30);
    doc.text('Email: ' + empresa.email, 37, 35);
    doc.text('Telefone: ', 95, 30);
    doc.text('' + empresa.telefone, 107, 30);
    doc.text('WebSite: ', 95, 35);
    doc.setTextColor(0, 0, 255);
    doc.text('' + (empresa.site == null ? '' : empresa.site), 107, 35);

    doc.setFontSize(8);
    doc.setDrawColor(0);
    doc.setFillColor(255);
    doc.rect(145, 14, 48, 23, 'B');
    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.text('CONTRATO N.º', 148, 20);
    doc.text('CIL', 148, 30);
    doc.setFontSize(8);
    doc.setFontType("normal");
    doc.text('' + contrato.id, 170, 20);
    doc.text('' + (contrato.cil == null ? '' : contrato.cil), 155, 30);
    doc.setFontSize(11);
    doc.setFontType("bold");

    if (historico_contrato == undefined) {
      doc.text('CONTRATO DE ' + contrato.objecto_contrato.toUpperCase(), 60, 46);
    } else if (historico_contrato.contrato_id != undefined || historico_contrato.contrato_id != null) {
      doc.text('CONTRATO DE ' + contrato.objecto_contrato.toUpperCase() + ' (CONTRATO ALTERADO)', 35, 46)
    } else {
      doc.text('CONTRATO DE ' + contrato.objecto_contrato.toUpperCase(), 60, 46);
    }

    /*     console.log(historico_contrato) */

    doc.setFontSize(8);
    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.setFont("arial");
    doc.text('LOCAL DE CONSUMO: ' + contrato.id, 10, 58);
    doc.text('CIL: ' + (contrato.cil || ''), 10, 61.5);
    doc.text('Morada Local de Consumo:', 10, 65);
    doc.setFontType("normal");
    doc.text('Bairro/Zona ' + (contrato.bairro || '') + ', Quarteirão ' + (contrato.quarteirao || ''), 10, 68);
    doc.text('' + ((contrato.is_predio ? 'Prédio ' + (contrato.predio_nome || '') + ', ' + (contrato.predio_andar || '') + 'º Andar - Porta ' : 'Residência ') + (contrato.moradia_numero || '')), 10, 71);
    doc.text('' + (contrato.distrito || ''), 10, 74);

    doc.setFontType("bold");
    doc.text('CLIENTE N.º: ' + contrato.cliente_id, 85, 58);
    doc.text('NIF: ', 85, 65);
    doc.text('Telefone: ' + (contrato.cliente_telefone || ''), 85, 69);
    doc.setFontType("normal");
    doc.text('EMAIL: ' + (contrato.cliente_email || ''), 85, 73);
    doc.setFontType("normal");
    doc.text('' + contrato.cliente_nome.toUpperCase(), 85, 61.5);

    let nif = tipo_identidades.find(obj => obj.nome == 'NIF')
    doc.text(' ' + (nif ? nif.numero_identidade : ""), 91, 65);

    doc.setFontSize(8)
    doc.setTextColor(0);
    doc.setFont("arial");
    doc.setFontType("normal");

    let identidade_text = nif ? `, portador do ${nif.nome} n.º ${nif.numero_identidade}, ` : ", "
    doc.text('O presente contrato de ' + contrato.objecto_contrato.toUpperCase() + ' é celebrado entre a ' + empresa.companyName + ', NIF 5161162392, e o Cliente ' + contrato.cliente_nome.toUpperCase() + identidade_text + ' para o Local de Consumo acima identificado.', 10, 88, { maxWidth: 170, align: 'justify' });

    doc.setFontType("bold");
    doc.text('Tarifa: ', 10, 103);
    doc.text((contrato.numero_serie != null) ? 'Contador: ' : '', 10, 109);

    if (contrato.morada_correspondencia_id == null) doc.text('Morada de Correspondência:', 10, 115);

    doc.text('Tipo de Utilização: ', 72, 103);
    doc.text('Leitura: ', 72, 109);
    doc.text('Tipo de Facturação: ', 72, 115);
    doc.text('Data Início Contrato:', 144, 103);

    if (historico_contrato == undefined) {
      doc.setFontType("bold");
      doc.text('Consumo Máximo Previsto:', 144, 109);
      doc.text('Período de Facturação:', 144, 115);
      doc.setFontType("Normal");
      doc.text(((contrato.consumo_maximo) == null ? 'Sem Limite' : String(contrato.consumo_maximo + ' m3')), 180, 109);
      doc.text('Mensal', 174, 115);

    } else if (historico_contrato.data_actualizacao !== contrato.data_alteracao) {
      doc.setFontType("bold");
      doc.text('Data Alteração Contrato:', 144, 109);
      doc.text('Consumo Máximo Previsto:', 144, 115);
      doc.text('Período de Facturação:', 144, 121);
      doc.setFontType("Normal");
      doc.text('' + moment(historico_contrato.data_actualizacao).format("DD/MM/YYYY"), 176, 109);
      doc.text(((contrato.consumo_maximo) == null ? 'Sem Limite' : String(contrato.consumo_maximo + ' m3')), 180, 115);
      doc.text('Mensal', 174, 121);

    } else {
      doc.setFontType("bold");
      doc.text('Consumo Máximo Previsto:', 144, 109);
      doc.text('Período de Facturação:', 144, 115);
      doc.setFontType("Normal");
      doc.text(((contrato.consumo_maximo) == null ? 'Sem Limite' : String(contrato.consumo_maximo + ' m3')), 180, 109);
      doc.text('Mensal', 174, 115);
    }

    doc.setFontType("Normal");
    doc.text('' + contrato.tarifario, 19, 103);
    doc.text((contrato.numero_serie != null) ? contrato.numero_serie : '', 24, 109);
    doc.text('' + contrato.tipo_contrato, 98, 103);
    doc.text('' + contrato.tipo_facturacao, 98, 115);

    if (contrato.morada_correspondencia_id == null) {
      doc.text('Província de ' + contrato.cliente_provincia + ', Município de ' + contrato.cliente_municipio, 10, 119);
      doc.text(contrato.cliente_morada, 10, 122);
    }

    doc.text('' + (contrato.tipo_medicao_slug == 'LEITURA') ? (contrato.ultima_leitura === null ? '' : contrato.ultima_leitura + ' m³') : '', 83, 109);
    doc.text('' + moment(contrato.data_inicio).format("DD/MM/YYYY"), 171, 103);

    doc.setFontSize(8)
    doc.setTextColor(0);
    doc.setFont("arial");
    doc.setFontType("bold");
    if (contrato.cliente_email !== null) {
      doc.text('O Cliente declara que deseja receber as suas Facturas no seguinte email: ', 10, 135);
      doc.setFontType("normal");
      doc.setTextColor(0, 0, 255);
      doc.text('' + (contrato.cliente_email || ''), 105, 135);
    }

    doc.setTextColor(0);
    doc.setFontType("bold");
    /*     doc.text('O Cliente declara que deseja pagar por Débito Directo através da sua conta com IBAN ', 10, 140); */
    doc.setFontType("normal");
    /*     doc.text('AO06 0044 0000 0500085215555', 124, 140); */
    doc.setFontType("bold");
    doc.text('O Cliente declara ter conhecimento das condições gerais do presente contrato que constam no seu verso.', 10, 140);
    /*     doc.text('O Cliente declara ter conhecimento das condições gerais do presente contrato que constam no seu verso.', 10, 145); */
    doc.text(contrato.cliente_municipio + ', ', 10, 154);
    doc.text('' + moment(contrato.data_inicio).format("DD/MM/YYYY"), 27, 154);
    doc.text('O Cliente ', 10, 164);
    doc.text('_____________________________________________________________________________________________________________', 24, 164);
    doc.text('A EPASNAMIBE-E.P. ', 10, 190);

    doc.text('___________________________________', 10, 215);
    doc.setFontType("normal");
    doc.text('(Eng. Nilton Celestino Ucuanjongo)', 10, 223);
    doc.text('Presidente do Conselho de Administração', 10, 230);

    doc.setFontType("bold");
    doc.text('___________________________________', 125, 215);
    doc.setFontType("normal");
    doc.text('(Dr. Floridy Fortuna Pinto Cassinda)', 125, 223);
    doc.text('Administrador Área Administrativa e Financeira', 125, 230);

    var numberPage = 1;
    var totalNumberPage = 1;
    var dataActual = moment(new Date()).format("DD/MM/YYYY");

    doc.setFontType("bold");
    doc.line(10, doc.internal.pageSize.height - 9, 194, doc.internal.pageSize.height - 9); // vertical line
    doc.text(dataActual + ' ' + moment().format("H:m"), 11, 285);
    doc.text('Página: ' + numberPage + '/1', 180, 285);
    doc.setFontSize(6);

    doc.text("NIF: " + empresa.taxRegistrationNumber + " - " + empresa.companyName + " / " + empresa.addressDetail + " / " + (empresa.telefone || '') + " / " + (empresa.email || ''), 105, doc.internal.pageSize.height - 6, null, null, 'center');
    doc.setFontSize(7);
    doc.text('Processado por programa validado nº 4/AGT119', 105, doc.internal.pageSize.height - 3, null, null, 'center');


    doc.addPage();
    // PAG 2

    doc.setFontSize(9);
    doc.setFont("arial");
    doc.setTextColor(0);
    doc.text('EPASN - ' + empresa.companyName, 50, 13);

    var dataActual = moment(new Date()).format("DD/MM/YYYY");

    doc.setFontSize(7)
    doc.setTextColor(0);
    doc.setFont("arial");
    doc.setFontType("bold");
    doc.text('CLÁUSULAS GERAIS DO CONTRATO', 80, 25);

    // Coluna 1
    doc.setFontSize(8)
    doc.setFontType("bold");
    doc.text('Cláusula 1ª Contrato', 10, 29);
    doc.setFontType("normal");
    doc.text('1) O presente contrato tem por objectivo o fornecimento de água por parte da EPASNAMIBE-E.P..', 10, 32, { maxWidth: 45, align: 'left' });
    doc.text('2) A instalação de contador é decidida pela EPASNAMIBE-E.P., em lugar escolhido pela EPASNAMIBE-E.P. e, sempre que possível, acessível a uma fácil leitura regular, com protecção adequada à sua eficiente conservação e normal funcionamento, não podendo ser mudado de local, em quaisquer circunstâncias, pelo consumidor.', 10, 42, { maxWidth: 45, align: 'left' });
    doc.text('3) O contador instalado é propriedade da EPASNAMIBE-E.P., devendo o Consumidor, na qualidade de fiel depositário, comunicar à EPASNAMIBE E.P. anomalias que verificar.', 10, 72, { maxWidth: 45, align: 'left' });
    doc.text('4) O Consumidor responde por danos ou perda do contador, excepto se a deterioração ou perda resultar da normal utilização ou de facto que não lhe seja imputável, desde que dê conhecimento da situação à EPASNAMIBE E.P. até 5 dias após a verificação do facto.', 10, 91, { maxWidth: 45, align: 'left' });

    doc.setFontType("bold");
    doc.text('Cláusula 2ª Duração', 10, 118);
    doc.setFontType("normal");
    doc.text('O contrato será celebrado por tempo indeterminado.', 10, 121, { maxWidth: 45, align: 'left' });

    doc.setFontType("bold");
    doc.text('Cláusula 3ª Deveres do Consumidor', 10, 127);
    doc.setFontType("normal");
    doc.text('Os consumidores estão obrigados a:', 10, 130, { maxWidth: 45, align: 'left' });
    doc.text('1) Cumprir as disposições do contrato em vigor, relacionado com os sistemas públicos e prediais de distribuição de água bem como as recomendações e orientações dadas pela EPASNAMIBE-E.P.;', 10, 133.5, { maxWidth: 45, align: 'left' });
    doc.text('2) Pagar pontualmente a importância devida, nos termos do presente contrato;', 10, 153, { maxWidth: 45, align: 'left' });
    doc.text('3) Não fazer uso indevido ou danificar qualquer obra ou equipamento dos sistemas públicos;', 10, 162, { maxWidth: 45, align: 'left' });
    doc.text('4) Não proceder à execução de ligação ao sistema público sem autorização da EPASNAMIBE-E.P.;', 10, 172, { maxWidth: 45, align: 'left' });
    doc.text('5) Não alterar o ramal de ligação da água de abastecimento, estabelecido entre rede geral e rede predial;', 10, 182, { maxWidth: 45, align: 'left' });
    doc.text('6) Abster-se de actos que possam provocar contaminação da água;', 10, 192, { maxWidth: 45, align: 'left' });
    doc.text('7) Informar a EPASNAMIBE-E.P., sempre que se aperceba de mau funcionamento da instalação, ou seja, efectuar comunicação de anomalias detectadas;', 10, 198, { maxWidth: 45, align: 'left' });
    doc.text('8) Permitir o acesso de colaboradores da EPASNAMIBE-E.P., devidamente credenciados, para recolha de leituras, substituição de contador, fiscalização, colheita de amostras de controlo e outros fins relacionados com o âmbito do presente contrato;', 10, 215, { maxWidth: 45, align: 'left' });
    doc.text('9) Entregar na EPASNAMIBE-E.P., caso ainda não o tenha feito, os documentos em falta (croquis de localização, título de propriedade ou contrato de arrendamento, certidão matricial).', 10, 237, { maxWidth: 45, align: 'left' });

    doc.setFontType("bold");
    doc.text('Cláusula 4ª Rescisão de contrato', 10, 256);
    doc.setFontType("normal");
    doc.text('1) A rescisão de contrato deve ser obrigatoriamente comunicada pelo consumidor à EPASNAMIBE-E.P. com pelo menos, 30 dias de antecedência sobre a data da respectiva produção de efeitos.', 10, 259, { maxWidth: 45, align: 'left' });

    //Coluna 2
    doc.text('2) O consumidor mantém-se obrigado ao pagamento das facturas até efectiva rescisão do contrato, o que implica acesso ao contador e suspensão de abastecimento.', 60, 32, { maxWidth: 45, align: 'left' });
    doc.text('3) Caso não seja cumprido o estipulado no número 9 da cláusula anterior, a EPASNAMIBE-E.P. poderá proceder à rescisão unilateral deste contrato e consequentemente interromper o abastecimento de água.', 60, 48, { maxWidth: 45, align: 'left' });

    doc.setFontType("bold");
    doc.text('Cláusula 5ª Continuidade e suspensão do fornecimento', 60, 67.5, { maxWidth: 45, align: 'left' });
    doc.setFontType("normal");
    doc.text('1) O fornecimento de água poderá ser suspenso nas seguintes situações:', 60, 74, { maxWidth: 45, align: 'left' });
    doc.text('a) Exigência de serviço público;', 60, 80, { maxWidth: 45, align: 'left' });
    doc.text('b) Existência de avarias ou obras nas redes gerais de distribuição ou nas canalizações anteriores;', 60, 83.5, { maxWidth: 45, align: 'left' });
    doc.text('c) Casos de força maior;', 60, 92, { maxWidth: 45, align: 'left' });
    doc.text('d) Actos imputáveis ao consumidor.', 60, 95, { maxWidth: 45, align: 'left' });
    doc.text('2) Para efeitos da ultima alínea do ponto anterior, são considerados actos imputáveis ao consumidor:', 60, 98, { maxWidth: 45, align: 'left' });
    doc.text('a) Falta de condições de salubridade das canalizações;', 60, 107, { maxWidth: 45, align: 'left' });
    doc.text('b) Falta de pagamentos das contas de consumo ou outras dívidas relacionadas com o abastecimento ou com o contrato estabelecido;', 60, 112.5, { maxWidth: 45, align: 'left' });
    doc.text('c) Recusa da entrada do funcionário da EPASNAMIBE-E.P. devidamente credenciado para a inspecção das canalizações interiores e impedimento do mesmo para leitura, verificação, substituição ou levantamento do contador;', 60, 125, { maxWidth: 45, align: 'left' });
    doc.text('d) Viciação do contador ou utilização do meio fraudulento para consumir água;', 60, 147.5, { maxWidth: 45, align: 'left' });
    doc.text('e) Efectuar venda de água a terceiros sem o consentimento da EPASNAMIBE-E.P.;', 60, 156.5, { maxWidth: 45, align: 'left' });
    doc.text('3) A suspensão do fornecimento por causas imputáveis ao consumidor não o isenta do pagamento da tarifa fixa mensal;', 60, 167, { maxWidth: 45, align: 'left' });
    doc.text('4) A suspensão do fornecimento da EPASNAMIBE-E.P. por falta de pagamentos das contas de consumo ou por outras dívidas relacionadas com o contrato é obrigatoriamente antecedida de advertência notificada por escrito ao consumidor com antecedência mínima de 5 dias da data em que a suspensão venha a ter lugar.', 60, 179, { maxWidth: 45, align: 'left' });

    doc.setFontType("bold");
    doc.text('Cláusula 6ª Leitura', 60, 208, { maxWidth: 45, align: 'left' });
    doc.setFontType("normal");
    doc.text('1) Para efeitos de leitura ou verificação deve ser permitido ao pessoal credenciado da EPASNAMIBE-E.P., livre acesso ao contador.', 60, 211, { maxWidth: 45, align: 'left' });
    doc.text('2) A leitura ou verificação será efectuada dentro do horário normal de trabalho da EPASNAMIBE-E.P..', 60, 224, { maxWidth: 45, align: 'left' });
    doc.text('3) Quando, por motivo imputável ao consumidor, não tenha sido efectuada leitura do contador, é-lhe aplicável um valor por estimativa.', 60, 234, { maxWidth: 45, align: 'left' });

    doc.setFontType("bold");
    doc.text('Cláusula 7ª Determinação do consumo por estimativa', 60, 247, { maxWidth: 45, align: 'left' });
    doc.setFontType("normal");
    doc.text('1) O consumo por estimativa aplicar-se-á quando não for possível a leitura real do contador pela EPASNAMIBE-E.P..', 60, 253, { maxWidth: 45, align: 'left' });
    doc.text('2) A estimativa é calculada face ao histórico de consumos do consumidor (média obtida das duas últimas leituras) sendo o acerto realizado na factura seguinte, com base na leitura real.', 60, 266, { maxWidth: 45, align: 'left' });

    // Coluna 3

    doc.text('3) Sempre que não exista histórico de consumo, a estimativa a aplicar será calculada com base da média de consumo dos consumidores da mesma tipologia.', 108, 32, { maxWidth: 45, align: 'left' });

    doc.setFontType("bold");
    doc.text('Cláusula 8ª Preços', 108, 49, { maxWidth: 45, align: 'left' });
    doc.setFontType("normal");
    doc.text('A EPASNAMIBE-E.P. informará ao consumidor os preços da água fornecida de acordo com o sistema tarifário aprovado pela Entidade competente.', 108, 52, { maxWidth: 45, align: 'left' });

    doc.setFontType("bold");
    doc.text('Cláusula 9ª Facturas', 108, 68, { maxWidth: 45, align: 'left' });
    doc.setFontType("normal");
    doc.text('As facturas são emitidas com periodicidade mensal, devendo o consumidor dirigir-se aos serviços de atendimento, para entrega e pagamento da mesma.', 108, 71, { maxWidth: 45, align: 'left' });

    doc.setFontType("bold");
    doc.text('Cláusula 10ª Pagamentos', 108, 86.5, { maxWidth: 45, align: 'left' });
    doc.setFontType("normal");
    doc.text('1) O pagamento da factura deve ser efectuado de acordo com o valor da respectiva factura dentro do prazo limite de pagamento, nos locais e modalidades especificamente definidas para o efeito pela EPASNAMIBE-E.P..', 108, 89, { maxWidth: 45, align: 'left' });
    doc.text('2) A liquidação da factura poderá ser feita nas seguintes modalidades:', 108, 108, { maxWidth: 45, align: 'left' });
    doc.text('a) Pagamento em dinheiro nos serviços de atendimento da EPASNAMIBE-E.P.;', 108, 114, { maxWidth: 45, align: 'left' });
    doc.text('b) Pagamento no terminal multicaixa disponível nos serviços de atendimento da EPASNAMIBE-E.P.;', 108, 124, { maxWidth: 45, align: 'left' });
    doc.text('c) Pagamento num terminal multicaixa, utilizando para o efeito a referência multicaixa indicada na respectiva factura;', 108, 134, { maxWidth: 45, align: 'left' });
    doc.text('d) Pagamento por transferência bancária para a conta indicada na factura, com apresentação do respectivo comprovativo nos serviços de atendimento da EPASNAMIBE-E.P...', 108, 146, { maxWidth: 45, align: 'left' });

    doc.setFontType("bold");
    doc.text('Cláusula 11ª Multas', 108, 166, { maxWidth: 45, align: 'left' });
    doc.setFontType("normal");
    doc.text('1) Para além das penalizações imputáveis ao consumidor previstas nos números anteriores, está também o consumidor sujeito ao pagamento de uma multa sempre que se verifiquem situações de esbanjamento ou desperdício de água por parte deste, bem como nos casos de danificação do contador ou da rede pública de água. O valor da multa a aplicar será conforme a gravidade dos danos causados.', 108, 169, { maxWidth: 45, align: 'left' });
    doc.text('2) Está igualmente prevista uma multa definida como taxa de religação após corte por atraso de pagamento, conforme tarifário em vigor.', 108, 205, { maxWidth: 45, align: 'left' });
    doc.text('3) Para além das multas referidas nos números anteriores, o consumidor pode também estar sujeito a um processo judicial instaurado pela EPASNAMIBE-E.P. contra si.', 108, 218, { maxWidth: 45, align: 'left' });

    doc.setFontType("bold");
    doc.text('Cláusula 12ª Reclamações', 108, 235, { maxWidth: 45, align: 'left' });
    doc.setFontType("normal");
    doc.text('1) Aos consumidores assiste o direito de reclamar, por qualquer meio, perante a EPASNAMIBE-E.P., contra qualquer acto ou omissão desta ou dos respectivos serviços ou agentes, que tenham lesado os seus direitos ou interesses legítimos legalmente protegidos.', 108, 238, { maxWidth: 45, align: 'left' });
    doc.text('2) Os serviços de atendimento ao público dispõem de formulário próprio onde os consumidores podem apresentar as suas reclamações.', 108, 264, { maxWidth: 45, align: 'left' });
    //Coluna 3
    doc.text('3) A reclamação é apreciada pela EPASNAMIBE-E.P. no prazo de 22 dias úteis, informando o reclamante da respectiva resposta devidamente fundamentada.', 160, 32, { maxWidth: 45, align: 'left' });

    doc.setFontType("bold");
    doc.text('Cláusula 13ª Vigência', 160, 48, { maxWidth: 45, align: 'left' });
    doc.setFontType("normal");
    doc.text('O presente contrato considera-se em vigor a partir da data da sua assinatura, da ligação da água ou da compra ou arrendamento do local de consumo (este último apenas aplicável a transferências de titularidade).', 160, 51, { maxWidth: 45, align: 'left' });

    doc.setFontType("bold");
    doc.text('Cláusula 14ª Disposições finais', 160, 70, { maxWidth: 45, align: 'left' });
    doc.setFontType("normal");
    doc.text('As partes obrigam-se ao cumprimento do presente contrato.', 160, 73, { maxWidth: 45, align: 'left' });

    doc.setFontType("bold");
    doc.text('Cláusula 15ª Foro', 160, 79, { maxWidth: 45, align: 'left' });
    doc.setFontType("normal");
    doc.text('1) Em caso de eventuais litígios, as partes primarão pela resolução amigável.', 160, 82, { maxWidth: 45, align: 'left' });
    doc.text('2) Na impossibilidade do disposto no número anterior, será competente o tribunal de Moçâmedes.', 160, 91, { maxWidth: 45, align: 'left' });
    numberPage = 2;
    doc.setFontType("bold");
    doc.line(10, doc.internal.pageSize.height - 9, 194, doc.internal.pageSize.height - 9); // vertical line
    doc.text('Página: ' + numberPage + '/2', 180, 285);
    doc.setFontSize(6);

    doc.text("NIF: " + empresa.taxRegistrationNumber + " - " + empresa.companyName + " / " + empresa.addressDetail + " / " + empresa.telefone + " / " + empresa.email, 105, doc.internal.pageSize.height - 6, null, null, 'center');
    doc.setFontSize(7);
    doc.text('Processado por programa validado nº 4/AGT119', 105, doc.internal.pageSize.height - 3, null, null, 'center');
    if (report == 'imprimir') {
      doc.autoPrint();
      window.open(doc.output("bloburl")); //opens the data uri in new window
    } else {
      doc.save(contrato.id + '.pdf'); /* download the file immediately on loading */
    }
  }
}
