import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

@Component({
  selector: 'app-tecnologias',
  templateUrl: './tecnologias.component.html',
  styleUrls: ['./tecnologias.component.css']
})
export class TecnologiasComponent implements OnInit {

  private tecnologia = {
    id: null,
    nome: null,
    opcao: null,
    tipoFacturacao: null,
    rota_list_dispositivos: null
  }


  private items: any = [];

  constructor(private http: HttpService, private configService: ConfigService) { }

  ngOnInit() {
    this.getPageFilterData(1);
  }


  private listaTecnologias() {

    this.configService.loaddinStarter('start');

    this.http.__call('tecnologias/listagem', this.http.filters).subscribe(

      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.configService.loaddinStarter('stop');

      }
    );
  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
    this.listaTecnologias();
  }


  private register(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (this.tecnologia.nome == "" || this.tecnologia.nome == null) {
      this.configService.showAlert("O campo nome é obrigatorio", 'alert-danger', true);
    } else {
    this.http.__call('tecnologia/create', this.tecnologia).subscribe(
      res => {
        if (Object(res).code == 500) {
          this.configService.showAlert(Object(res).message, 'alert-danger', true);
          this.configService.loaddinStarter('stop');
        } else {
          this.configService.showAlert(Object(res).message, 'alert-success', true);
          this.clearFormInputs(e);
          this.listaTecnologias()
          this.configService.loaddinStarter('stop');
        }
      }
    )
  }
      ;
  }


  private clearFormInputs(e) {
    e.target.elements[0].value = null;
    e.target.elements[1].value = null;
    e.target.elements[2].value = null;
  }

  private refresh(id, nome, opcao, tipoFacturacao, rota_list_dispositivos) {

    this.tecnologia.id = id;
    this.tecnologia.nome = nome;
    this.tecnologia.opcao = opcao;
    this.tecnologia.tipoFacturacao = tipoFacturacao;
    this.tecnologia.rota_list_dispositivos = rota_list_dispositivos;

  }

  private editar(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (this.tecnologia.nome == "" || this.tecnologia.nome == null) {
      this.configService.showAlert("O campo designação é obrigatorio", 'alert-danger', true);
    } else {
      this.http.__call('tecnologia/update/' + this.tecnologia.id, this.tecnologia).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            //this.configService.clearFormInputs(e);
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.listaTecnologias();

          }
        }
      );
    }
    this.configService.loaddinStarter('stop');
  }


  private ini() {
    this.tecnologia = {
      id: null,
      nome: null,
      opcao: null,
      tipoFacturacao: null,
      rota_list_dispositivos: null
    }
  }





}
