
import { Component, OnInit, Input } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-abertura-de-loja',
  templateUrl: './abertura-de-loja.component.html',
  styleUrls: ['./abertura-de-loja.component.css']
})
export class AberturaDeLojaComponent implements OnInit {

  @Input() aberutaLojaModal: string = "aberutaLojaModal";
  @Input() fechoLojaModal: string = "fechoLojaModal";
  @Input() setting: string = "";
  @Input() lojasList: any = [];


  submitted = false;
  simpleForm: FormGroup;
  loading: boolean = false;
  private lojas:any[] = []
  private loja = {
    abertura_loja: null
  }
  validar = true;
  private user_chefe_loja:Object = null

  constructor(private http: HttpService, private configService: ConfigService, private formBuilder: FormBuilder) {
    this.createForm();
    //this.getLojas()
    this.user_chefe_loja = (
      (
        (JSON.parse(localStorage.getItem('_token')) || {}).user || {}
        ).user_chefe_loja || {}
      ).id
  }

  ngOnInit() {
   // this.loja.abertura_loja = null
    this.getLojas()
  }
  setLoja(value:number, abertura_loja: number) {
    this.user_chefe_loja = value
    this.loja.abertura_loja = abertura_loja
  }
  getLojas() {
    const uri = 'loja/abertura-lista/'+ this.user_chefe_loja;
       // TODO: usado para fazer a requisição com a api de criação de organismo
       this.http.__call(uri, null).subscribe(
        res => {

          if (Object(res).code == 200) {
            this.submitted = false;
            this.validar = false;
            this.lojas = Object(res)
          }
          this.loading = false;
        });
  }


  createForm() {
    this.simpleForm = this.formBuilder.group({
      index: [{ value: null, disabled: true }],
      abertura_loja: [null]
    });
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.simpleForm.controls;
  }

  onReset() {
    this.submitted = false;
    this.simpleForm.reset();
  }

  onSubmit(is_abertura: boolean) {
    this.simpleForm.patchValue({
      abertura_loja:  this.loja.abertura_loja
    })
    this.submitted = true;
    if(this.simpleForm.value.abertura_loja === null) return
    this.loading = true;
    const uri = is_abertura ? `loja/abertura/${ this.user_chefe_loja}` : `loja/fecho/${ this.user_chefe_loja}`;
    this.createOrEdit(uri, this.simpleForm, true);

  }

  createOrEdit(uri: any, simpleFormulario: FormGroup, isCreate: boolean) {
    // TODO: usado para fazer a requisição com a api de criação de organismo
    this.http.__call(uri, simpleFormulario.value).subscribe(
      res => {

        if (Object(res).code == 200) {
          this.submitted = false;
          if(simpleFormulario.value.abertura_loja == 1)this.closeModalFist()
          else this.closeModalSecond()

          if (isCreate) {
            simpleFormulario.reset();
          }

          this.validar = false;
        }
        this.loading = false;
      });
  }

  closeModalFist(modal=''){
    ($("#aberutaLojaModal") as any).modal("hide")
  }
  closeModalSecond(modal=''){
    ($("#fechoLojaModal") as any).modal("hide")
  }


}
