import { Component, OnInit, Input } from "@angular/core";
import { HttpService } from "src/app/providers/http/http.service";
import { ConfigService } from "src/app/providers/config/config.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "src/app/providers/auth/auth.service";

@Component({
  selector: 'app-saida-stock',
  templateUrl: './saida-stock.component.html',
  styleUrls: ['./saida-stock.component.css']
})
export class SaidaStockComponent implements OnInit {


  submitted = false;
  simpleForm: FormGroup;
  loading = false;
  validar = false;

  artigos: any = [];
  categorias: any = [];

  codigo_produto: any;

  constructor(
    public http: HttpService,
    public configService: ConfigService,
    public formBuilder: FormBuilder,
    public auth: AuthService
  ) {
    this.createForm();
  }

  ngOnInit() {
   
    this.getArmazens();
  }

  createForm() {
    this.simpleForm = this.formBuilder.group({
      artigo_id: [null],
      armazem_id: [null, Validators.required],
      categoria_id: [null, Validators.required],
      qtd_recebida: [null],
      codigo_produto: [null],
      artigos: [null, Validators.required],
      qtd_stock: [null],
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.simpleForm.controls;
  }

  onReset() {
    this.submitted = false;
    this.loading = false;
    this.simpleForm.reset();
    this.artigos = [];
    this.artigosSelecionados = [];
    this.simpleForm.patchValue({
      artigo_id: null,
      qtd_recebida: null,
    });
  }

  onSubmit() {
    this.validar = false;
    this.submitted = true;
    this.simpleForm.patchValue({
      artigos: this.artigosSelecionados,
    });

    // parar aquei se o simpleFormulário for inválido
    if (this.simpleForm.invalid) {
      return;
    }

    const uri = "stock_movimento/nova_saida";
    this.createOrEdit(uri, this.simpleForm, true);
  }

  createOrEdit(uri: any, simpleFormulario: FormGroup, isCreate: boolean) {
    this.loading = true;
    // TODO: usado para fazer a requisição com a api de criação de organismo
    this.http.__call(uri, simpleFormulario.value).subscribe((res) => {
      if (Object(res).code == 200) {
        this.submitted = false;
        
        this.getArmazens();
        this.onReset();
      } else {
        this.configService.showAlert(Object(res).message, "alert-danger", true);
      }
      this.loading = false;
    });
  }

  public armazens = [];
  public getArmazens() {
    this.loading = true;
    this.http.__call("armazem/selectBox", null).subscribe((response) => {
      this.armazens = Object(response).data;
      this.loading = false;
    });
  }

  public artigosSelecionados = [];
  public qtdStock = 0;

  private getQtdProduto(){

    this.qtdStock = 0;
 
    this.simpleForm.patchValue({
      qtd_stock: this.qtdStock
    });

      //Quantidade do artigo em Stock
      if(this.simpleForm.getRawValue().artigo_id !=null){
      this.http
      .__call("stock/existencia/armazem/qtd_produto", {
        armazem_id: this.simpleForm.getRawValue().armazem_id,
        artigo_id: this.simpleForm.getRawValue().artigo_id
      })
      .subscribe((response) => {
        this.qtdStock = Object(response).data[0].quantidade;
        this.simpleForm.patchValue({
          qtd_stock: this.qtdStock
        });
        
      });
    }
    //-------
  }


  public adicionarArtigos() {
    var validar = 0;
    var nome_artigo = "";

    if (this.simpleForm.getRawValue().qtd_recebida == null) {
      this.configService.showAlert('Digite a quantidade a sair', "alert-danger", true);
    }else if (this.simpleForm.getRawValue().qtd_recebida == 0) {
      this.configService.showAlert('Quantidade não pode ser menor que 1', "alert-danger", true);
    }else if (this.simpleForm.getRawValue().qtd_recebida > this.simpleForm.getRawValue().qtd_stock) {
      this.configService.showAlert('Quantidade a sair não pode ser superior a quantidade em Stock', "alert-danger", true);
    } else {

    this.artigos.forEach((element) => {
      if (element.id == this.simpleForm.getRawValue().artigo_id) {
        nome_artigo = element.descricao;
      }
    }); 

    var artigo = {
      nome_artigo: nome_artigo,
      artigo_id: this.simpleForm.getRawValue().artigo_id,
      valor: 0,
      total: 0,
      quantidade: this.simpleForm.getRawValue().qtd_recebida,
      qtd_stock: this.qtdStock,
      codigo_produto: this.codigo_produto,
    };

    if (this.artigosSelecionados.length >= 1) {
      for (let index = 0; index < this.artigosSelecionados.length; index++) {
        const l = this.artigosSelecionados[index];
        if (l.artigo_id == artigo.artigo_id) {
          this.artigosSelecionados.splice(index, 1);
          this.artigosSelecionados.splice(index, 0, artigo);
          validar = 1;
        }
      }
      if (validar == 0) {
        this.artigosSelecionados.push(artigo);
      }
    } else {
      this.artigosSelecionados.push(artigo);
    }

    this.simpleForm.patchValue({
      artigo_id: null,
      qtd_recebida: null,
      qtd_stock: null,
      codigo_produto: null
    });

  }
  }

  deleteRow(artigo: any) {
    for (let i = 0; i < this.artigosSelecionados.length; ++i) {
      if (this.artigosSelecionados[i].artigo_id === artigo.artigo_id) {
        this.artigosSelecionados.splice(i, 1);
      }
    }
  }

  private getCategoriaByArmazem() {
   
    this.qtdStock = 0;
    this.categorias = [];
    this.codigo_produto = null;

    this.simpleForm.patchValue({
      qtd_stock: this.qtdStock,
      categoria_id: null,
      artigo_id: null,
      codigo_produto: null
    });

    
    this.http
      .__call("categoria/by/armazem", {
        armazem_id:  this.simpleForm.getRawValue().armazem_id
      }).subscribe((res: any) => {
        this.categorias = Object(res).data;
        
      });
  }

  public getInfoMaterila(){

    this.simpleForm.patchValue({ codigo_produto: null });
    this.codigo_produto = null;

    if (this.simpleForm.getRawValue().artigo_id != null){
      this.http.call_get("unidade-by-material/" + this.simpleForm.getRawValue().artigo_id, null).subscribe((res: any) => {
        this.codigo_produto = Object(res).data.codigo_produto;

        this.simpleForm.patchValue({ codigo_produto: this.codigo_produto });
        
      });
    }
    
  }

  private getMaterialByCategoriaArmazem() {
    
    this.qtdStock = 0;
    this.artigos = [];

    this.codigo_produto = null;

    this.simpleForm.patchValue({
      qtd_stock: this.qtdStock,
      artigo_id: null,
      codigo_produto: null
    });
    

    this.http
      .__call("material/by/categoria-armazem", {
        armazem_id: this.simpleForm.getRawValue().armazem_id,
        categoria_id: this.simpleForm.getRawValue().categoria_id
      }).subscribe((res: any) => {
        this.artigos = Object(res).data;
        
      });
  }

 /*  private getMaterialByArmazem() {
    this.loading = true;
    this.http
      .__call("material/by/armazem", {
        armazem_id: this.simpleForm.getRawValue().armazem_id
      })
      .subscribe((res: any) => {
        this.artigos = Object(res).data;
        this.loading = false;
      });
  } */




}
