import { Component, OnInit, Input } from "@angular/core";
import { HttpService } from "src/app/providers/http/http.service";
import { ConfigService } from "src/app/providers/config/config.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "src/app/providers/auth/auth.service";

@Component({
  selector: 'app-entrada-stock',
  templateUrl: './entrada-stock.component.html',
  styleUrls: ['./entrada-stock.component.css']
})
export class EntradaStockComponent implements OnInit {

  submitted = false;
  simpleForm: FormGroup;
  loading = false;
  validar = false;
  private isDisabled: boolean = false;

  artigos: any = [];
  categorias: any = [];
  codigo_produto: any;
  stkActual_primavera: any;
  stkActual_unig: any;

  constructor(
    public http: HttpService,
    public configService: ConfigService,
    public formBuilder: FormBuilder,
    public auth: AuthService
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.getArmazens();
  }

  createForm() {
    this.simpleForm = this.formBuilder.group({
      artigo_id: [null],
      armazem_id: [null, Validators.required],
      categoria_id: [null, Validators.required],
      qtd_recebida: [null],
      codigo_produto: [null],
      stk_actual_primavera: [null],
      stk_actual_unig: [null],
      artigos: [null, Validators.required],
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.simpleForm.controls;
  }

  onReset() {
    this.submitted = false;
    this.loading = false;
    this.simpleForm.reset();
    this.artigos = [];
    this.artigosSelecionados = [];
    this.simpleForm.patchValue({
      artigo_id: null,
      qtd_recebida: null,
      codigo_produto: null,
      stk_actual_primavera: null,
      stk_actual_unig: null
    });
  }

  onSubmit() {
    this.validar = false;
    this.submitted = true;
    this.simpleForm.patchValue({
      artigos: this.artigosSelecionados,
    });

    // parar aquei se o simpleFormulário for inválido
    if (this.simpleForm.invalid) {
      return;
    }

    const uri = "stockMovimento/nova_entrada";
    this.createOrEdit(uri, this.simpleForm, true);
  }

  createOrEdit(uri: any, simpleFormulario: FormGroup, isCreate: boolean) {
    this.loading = true;
    // TODO: usado para fazer a requisição com a api de criação de organismo
    this.http.__call(uri, simpleFormulario.value).subscribe((res) => {
      if (Object(res).code == 200) {
        this.submitted = false;
        this.getCategoria();
        this.getArmazens();
        this.onReset();
      } else {
        this.configService.showAlert(Object(res).message, "alert-danger", true);
      }
      this.loading = false;
    });
  }

  public armazens = [];
  public getArmazens() {
    this.loading = true;
    this.http.__call("armazem/selectBox", null).subscribe((response) => {
      this.armazens = Object(response).data;
      this.loading = false;
    });
  }

  private loadingCategoria = {
    categoria: 'Selecione a categoria',
  }

  private getCategoria() {

    this.loadingCategoria.categoria = 'Carregando...';

    this.http.call_get("stk-categoria/select-option", null).subscribe((res: any) => {
      this.categorias = Object(res).data;
      this.loadingCategoria.categoria = 'Selecione a categoria';

    });
  }

  private loadingMaterial = {
    material: 'Selecione a categoria',
  }

  public getMaterialByCategoria() {

    this.loadingMaterial.material = 'Carregando...';

    this.simpleForm.patchValue({ codigo_produto: null });
    this.codigo_produto = null;

    if (this.simpleForm.getRawValue().categoria_id != null) {
      this.http.call_get("material/by/categoria/" + this.simpleForm.getRawValue().categoria_id, null).subscribe((res: any) => {
        this.artigos = Object(res).data;
        this.loadingMaterial.material = 'Seleccione o Material';
      });
    }

  }


  public getInfoMaterial() {

    if (this.simpleForm.getRawValue().artigo_id != null) {

      this.configService.loaddinStarter('start');
      
      this.http.call_get("unidade-by-material/" + this.simpleForm.getRawValue().artigo_id, null).subscribe((res: any) => {
        this.codigo_produto = Object(res).data.codigo_produto;

        this.simpleForm.patchValue({ codigo_produto: this.codigo_produto });

      });
      this.getQTDPrimavera();
      this.getQTDunig();
    }

    this.configService.loaddinStarter('stop');



  }

  private validateArmazemPrimavera() {

    this.categorias = [];

    this.simpleForm.patchValue({
      qtd_recebida: null,
      codigo_produto: null,
      categoria_id: null,
      artigo_id: null,
      stk_actual_primavera: null,
      stk_actual_unig: null
    });

    this.getArmazemPrimavera();
  }


  private getArmazemPrimavera() {

    if (this.simpleForm.getRawValue().armazem_id != null) {

      this.http.__call("search-armazem-primavera", {
        armazem_id: this.simpleForm.getRawValue().armazem_id
      }).subscribe((res: any) => {

        if (Object(res).code == 200) {
          this.getCategoria();
        }
      });

    }

  }

  private getCategoriaPrimavera() {

    this.artigos = [];

    this.simpleForm.patchValue({
      qtd_recebida: null,
      codigo_produto: null,
      artigo_id: null,
      stk_actual_primavera: null,
      stk_actual_unig: null
    });

    if (this.simpleForm.getRawValue().armazem_id != null && this.simpleForm.getRawValue().categoria_id != null) {

    this.http.__call("search-categoria-primavera", {
      armazem_id: this.simpleForm.getRawValue().armazem_id,
      categoria_id: this.simpleForm.getRawValue().categoria_id,
    }).subscribe((res: any) => {

      if (Object(res).code == 200) {
        this.getMaterialByCategoria();
      }
    });

  }
  }

  private getMaterialPrimavera() {

    this.simpleForm.patchValue({
      qtd_recebida: null,
      codigo_produto: null,
      stk_actual_primavera: null,
      stk_actual_unig: null
    });

    if (this.simpleForm.getRawValue().armazem_id != null && this.simpleForm.getRawValue().categoria_id != null && this.simpleForm.getRawValue().artigo_id != null) {
      
    this.http.__call("search-material-primavera", {
      armazem_id: this.simpleForm.getRawValue().armazem_id,
      categoria_id: this.simpleForm.getRawValue().categoria_id,
      artigo_id: this.simpleForm.getRawValue().artigo_id
    }).subscribe((res: any) => {

      if (Object(res).code == 200) {
        this.getInfoMaterial();
      }
    });
  }

  }

  private getQTDPrimavera() {
    this.http.__call("search-from-primavera", {
      armazem_id: this.simpleForm.getRawValue().armazem_id,
      categoria_id: this.simpleForm.getRawValue().categoria_id,
      artigo_id: this.simpleForm.getRawValue().artigo_id
    }).subscribe((res: any) => {
      this.stkActual_primavera = Object(res).data.StkActual;

      this.simpleForm.patchValue({ stk_actual_primavera: this.stkActual_primavera });

    });
  }

  private getQTDunig() {
    this.http
      .__call("stock/existencia/armazem/qtd_produto", {
        armazem_id: this.simpleForm.getRawValue().armazem_id,
        artigo_id: this.simpleForm.getRawValue().artigo_id
      })
      .subscribe((response) => {
        this.stkActual_unig = Object(response).data[0] ? Object(response).data[0].quantidade : 0;
        this.simpleForm.patchValue({ stk_actual_unig: this.stkActual_unig });


      });
  }

  private entradaStock() {
   
    this.isDisabled = true;
    this.loading = true;

      this.http.call_get("entrada-stock-primavera", null).subscribe((res: any) => {
        if (Object(res).code == 200) {

          this.isDisabled = false;
          this.loading = false;
        }
        
      });
  }

  public artigosSelecionados = [];

  public adicionarArtigos() {
    var validar = 0;
    var nome_artigo = "";
    var diferenca = this.simpleForm.getRawValue().stk_actual_primavera - this.simpleForm.getRawValue().stk_actual_unig;

    if (this.simpleForm.getRawValue().qtd_recebida <= 0) {
      this.configService.showAlert('Quantidade a entrar não pode ser menor ou igual a 0', "alert-danger", true);
    } else if (this.simpleForm.getRawValue().qtd_recebida > diferenca) {
      this.configService.showAlert("Quantidade a entrar não pode ser maior que " + diferenca, 'alert-danger', true);
    } else {

      this.artigos.forEach((element) => {
        if (element.id == this.simpleForm.getRawValue().artigo_id) {
          nome_artigo = element.descricao;
        }
      });
      var artigo = {
        nome_artigo: nome_artigo,
        artigo_id: this.simpleForm.getRawValue().artigo_id,
        valor: 0,
        total: 0,
        quantidade: this.simpleForm.getRawValue().qtd_recebida,
        codigo_produto: this.codigo_produto,
      };

      if (this.artigosSelecionados.length >= 1) {
        for (let index = 0; index < this.artigosSelecionados.length; index++) {
          const l = this.artigosSelecionados[index];
          if (l.artigo_id == artigo.artigo_id) {
            this.artigosSelecionados.splice(index, 1);
            this.artigosSelecionados.splice(index, 0, artigo);
            validar = 1;
          }
        }
        if (validar == 0) {
          this.artigosSelecionados.push(artigo);
        }
      } else {
        this.artigosSelecionados.push(artigo);
      }

      this.simpleForm.patchValue({
        artigo_id: null,
        qtd_recebida: null,
        codigo_produto: null
      });

    }

    
  }

  deleteRow(artigo: any) {
    for (let i = 0; i < this.artigosSelecionados.length; ++i) {
      if (this.artigosSelecionados[i].artigo_id === artigo.artigo_id) {
        this.artigosSelecionados.splice(i, 1);
      }
    }
  }


}
