import { Component, OnInit, Input,Output, EventEmitter,OnChanges, SimpleChange } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms'; 
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-create-or-edit-os-sintomas',
  templateUrl: './create-or-edit-os-sintomas.component.html',
  styleUrls: ['./create-or-edit-os-sintomas.component.css']
})
export class CreateOrEditOsSintomasComponent implements OnInit {



  @Input() modal: string = "modalCreateOrEdit";
  @Input() title: string = "Registar";
  @Input() sintomas:any;

  submitted = false;
  formErrors: any;
  private loading: boolean = false;
  private ver: boolean = true;
  @Input() simpleFormCreateOrEdit: FormGroup; 

  @Output() private loadList = new EventEmitter<any>();
  
  private departamentos: any = [];
  private funcoes: any = [];
  private classificacoes: any = [];

  constructor(private http: HttpService, private configService: ConfigService, private formBuilder: FormBuilder) { 
    this.createForm();
  }

  ngOnInit() {
    this.getClassificacao();
  }

  createForm() {
    this.simpleFormCreateOrEdit = this.formBuilder.group({
      index: [{ value: null, disabled: true }],
      descricao: [null, Validators.required],
      classificacao_id: [null, Validators.required]
    })
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.simpleFormCreateOrEdit.controls;
  }

  onReset() {
    this.submitted = false;
    this.simpleFormCreateOrEdit.reset();
  }


  onSubmit() {

    this.submitted = true;

    // parar aquei se o formulário for inválido
    if (this.simpleFormCreateOrEdit.invalid) {
      return;
    }
    this.loading = true;
    const index = this.simpleFormCreateOrEdit.getRawValue().index;
    // TODO: usado para fazer a requisição com a api de criação de objsct or update
    const uri = (index === null ? 'ordemservico/sintoma/register' : 'ordemservico/sintoma/update/' + index);
    this.createOrEdit(uri, this.simpleFormCreateOrEdit, (index === null ? true : false));

  }

  createOrEdit(uri: any, formulario: FormGroup, isCreate: boolean) {

    // TODO: usado para fazer a requisição com a api de criação de object
    this.http.__call(uri, formulario.value).pipe(first()).subscribe(
      response => {
        this.submitted = false;
        this.loading = false;
        if (isCreate && Object(response).code ==200) {
          formulario.reset(); 
        }  
        
        if (Object(response).code ==200) {
          this.loadList_item(Object(response).data);
        }
      },
      error => {
        this.submitted = false;
        this.loading = false;
      });
  }
  private getClassificacao() {
    this.http.call_get('ordemservico/classificacao/selectBox', null).subscribe(
      response => {
        this.classificacoes = Object(response).data;
      }
    );
  }


  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
     
    if (this.sintomas !== undefined ) {
      
      this.title = "Editar";
      //this.onReset()
      
      this.simpleFormCreateOrEdit.patchValue({
        index: this.sintomas.id,
        descricao: this.sintomas.descricao,
        classificacao_id: this.sintomas.classificacao_id
      });
      
    } else {
      this.onReset()
      this.title = "Registar";
      
    }
  }

  public loadList_item(sintomas) { 
    this.loadList.emit(sintomas);
  }



}
