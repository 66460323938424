import { Component, Inject, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';

import { SAVER, Saver } from '../../services/saver.provider'
@Component({
  selector: 'app-logs-referencia-emis',
  templateUrl: './logs-referencia-emis.component.html',
  styleUrls: ['./logs-referencia-emis.component.css']
})
export class LogsReferenciaEmisComponent implements OnInit {


  private data = {
    data: moment(new Date()).format('YYYY-MM')
  }

  lista_sincronizada = []
  lista_linhas_logs: []

  loading = false
  private anos: any = [];

  fileUrl;

  public saft = {
    id: null
  }

  private max_date = moment(new Date()).format('YYYY-MM');
  private min_date = moment(new Date()).format('YYYY-MM');

  isLoading = true;

  constructor(
    private http: HttpService,
    private configService: ConfigService,
    private _http: HttpClient,
    @Inject(SAVER) private save: Saver
  ) { }



  ngOnInit() {
    this.http.filters.search = null
    this.getPageFilterData(1);
  }


  mesPorExtenso(ano, mes) {
    var CurrentDate = new Date(ano + '-' + mes + '-01');
    return moment(CurrentDate).locale('pt-br').format('MMMM')
  }


  private message: number;

  private sincronizar_agora() {
    if (this.data.data == null) {
      this.configService.showAlert("Selecione o mês de referencia.", 'alert-danger', true);
      return;
    }
    this.configService.loaddinStarter('start');

    this.http.__call('api/v1/primavera/entrada_facturas_notas_credito', this.data).subscribe(
      response => {
        if (Object(response).code === 200) {
          this.configService.showAlert(Object(response).message, 'alert-success', true);
          this.getPageFilterData(1);
        } else {
          this.configService.showAlert(Object(response).message, 'alert-danger', true);
        }
        this.configService.loaddinStarter('stop');
      }, error => {
        this.configService.loaddinStarter('stop');
      }
    );
  }

  listarLogsPrimavera() {
    this.loading = false
    this.http.call_get('api/v1/primavera/get_primavera_logs')
      .subscribe(
        response => {
          this.lista_sincronizada = Object(response);
          this.loading = false
        }
      )
  }

  get_linhas_log_primavera(id: number) {
    this.loading = false
    this.http.call_get(`api/v1/primavera/get_linhas_primavera_logs/${id}`)
      .subscribe(
        response => {
          this.lista_linhas_logs = Object(response);
          this.loading = false
        }
      )
  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1;
    this.listarLogsPrimavera();
  }


}
