import { Component, OnInit, Input, createPlatformFactory, OnDestroy } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { ExcelService } from 'src/app/services/excel.service';
import { ConfigModuloService } from 'src/app/services/config-modulo.service';
import * as moment from 'moment';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-configuracao',
  templateUrl: './configuracao.component.html',
  styleUrls: ['./configuracao.component.css']
})
export class ConfiguracaoComponent implements OnInit, OnDestroy {

  @Input() simpleFormConfiguracao: FormGroup;

  private configuracao = {
    id: null,
    slug: null,
    descricao: null,
    modulo: null,
    modulo_id: null,
    valor: null,
    required: null,
    default: null,
    contexto_configuracao_id: null,
    is_active: null,
    is_boolean: null,
    user_id: null
  };

  public currentUser: any;

  private title: string = "Registar Configuração";

  private input_default: boolean = false;
  private input_required: boolean = false;
  private input_contexto: boolean = false;
  private input_boolean: boolean = false;


  private items: any = [];
  private modulos: any = [];
  private contexto_configuracaos: any = [];
  private contexto_valores: any = [];
  /*  private estados: any = []; */

  private contexto = {
    table: null,
    column: null
  };

  constructor(
    private http: HttpService,
    private configService: ConfigService,
    private excelService: ExcelService,
    private auth: AuthService,
    private config: ConfigModuloService
  ) {

    this.currentUser = this.auth.currentUserValue;

    this.modulos = config.modulo;
    console.log(this.modulos);
  }



  exportAsXLSX(): void {
    var CurrentDate = new Date();
    var nameFile = "lista_configuracoes -" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    this.excelService.exportAsExcelFile(document.getElementsByClassName("exportAsXLSXCliente")[0], nameFile);
  }
  exportAsPDF(): void {
    //this.reportLoja.relatorioLoja(this.cobrancas, this.simpleForm.value, 'save');
  }

  imprimirPDF(): void {
    //this.reportLoja.relatorioLoja(this.cobrancas, this.simpleForm.value);
  }

  ngOnInit() {
    this.http.filters.search = null
    this.getPageFilterData(1);
  }
  ngOnDestroy(){
    this.http.filters.orderBy = null
  }

  private getDadosModal() {

  }

  private getConfiguracaos() {

    this.configService.loaddinStarter('start');

    this.http.__call('configuracao/listagem', this.http.filters).subscribe(

      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.configService.loaddinStarter('stop');

      }
    );
  }


  view_configuracao = false;

  private getModulos() {

    if (this.configuracao.modulo == "") {
      this.setModuloNull();
    }

    this.view_configuracao = true;

    this.http.call_get('configuracao/getSelectBoxModulos', null).subscribe(
      response => {
        this.modulos = Object(response).data;

        this.configService.loaddinStarter('stop');
      }
    );
  }


  private saveConfiguracao() {
    //console.log(this.configuracao);

    if (this.configuracao.id == null) {

      this.http.__call('configuracao/create', {
        slug: this.configuracao.slug,
        descricao: this.configuracao.descricao,
        modulo: this.configuracao.modulo,
        valor: this.configuracao.valor,
        required: this.input_required,
        default_conf: this.input_default,
        contexto_configuracao_id: this.configuracao.contexto_configuracao_id,
        user_id: this.currentUser.user.id
      }).subscribe(
        res => {
          if (Object(res).code == 200) {
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            /*    this.clearFormInputs();
               this.listarRotaRunByRotaHeader(); */

            this.items = Object(res).data;

            this.configService.loaddinStarter('stop');
          } else {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
            this.configService.loaddinStarter('stop');
          }
        }
      )
    }
    else {

      this.http.__call('configuracao/update/' + this.configuracao.id, {
        slug: this.configuracao.slug,
        descricao: this.configuracao.descricao,
        modulo: this.configuracao.modulo,
        contexto_configuracao_id: this.configuracao.contexto_configuracao_id,
        valor: this.configuracao.valor,
        required: this.input_required,
        default_conf: this.input_default,
        is_active: this.configuracao.is_active,
        is_boolean: this.input_boolean,
        user_id: this.currentUser.user.id
      }).subscribe(
        res => {
          if (Object(res).code == 200) {
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            /*    this.clearFormInputs();
               this.listarRotaRunByRotaHeader(); */

            this.items = Object(res).data;

            this.configService.loaddinStarter('stop');
          } else {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
            this.configService.loaddinStarter('stop');
          }
        }
      )
    }

  }


  private setModulo(id, modulo, modulo_id) {
    //this.configuracao.id = id;
    this.configuracao.modulo = modulo;
    this.configuracao.modulo_id = modulo_id;

    this.view_configuracao = false;

  }

  private setModuloNull() {

    this.configuracao.modulo = null;
    this.configuracao.modulo_id = null;

    this.view_configuracao = false;
  }

  private onReset() {
    this.configuracao.id = null;
    this.configuracao.slug = null;
    this.configuracao.descricao = null;
    this.configuracao.modulo = null;
    this.configuracao.modulo_id = null;
    this.configuracao.valor = null;
    this.configuracao.required = null;
    this.configuracao.default = null;
    this.configuracao.contexto_configuracao_id = null;
    this.configuracao.is_active = null;
    this.configuracao.is_boolean = null;
    this.configuracao.user_id = null;

    this.input_contexto = false;
    this.input_boolean = false;
  }

  private async setDataConfiguracao(item) {

    if (this.contexto_configuracaos.length <= 0)
      this.selectBoxContextoConfiguracaos();

    if (item !== undefined) {

      this.title = "Editar Configuração";
      const loadedStateCallback = () => {

        this.configuracao.id = item.id;
        this.configuracao.slug = item.slug;
        this.configuracao.descricao = item.descricao;
        this.configuracao.modulo = item.modulo;
        this.configuracao.modulo_id = item.modulo_id;
        this.configuracao.valor = item.valor;
        this.configuracao.required = item.required;
        this.configuracao.default = item.default;
        this.configuracao.contexto_configuracao_id = item.contexto_configuracao_id;
        this.configuracao.is_active = item.is_active;
        this.configuracao.is_boolean = item.is_boolean;
        this.configuracao.user_id = item.user_id;

        this.input_contexto = (item.contexto_configuracao_id == null) ? false : true;

        this.input_default = this.configuracao.default;
        this.input_required = this.configuracao.required;
        this.input_boolean = this.configuracao.is_boolean;

        /* console.log("load");
        console.log(this.configuracao); */

      }

      this.setItem(item, loadedStateCallback);

      if (this.input_contexto) this.getDataContexto(item.contexto_configuracao_id);




    }
  }

  private setItem(item, callback: Function) {

    console.log("setItem");
    console.log(this.configuracao);

    this.configuracao.id = item.id;
    this.configuracao.slug = item.slug;
    this.configuracao.descricao = item.descricao;
    this.configuracao.modulo = item.modulo;
    this.configuracao.modulo_id = item.modulo_id;
    this.configuracao.valor = item.valor;
    this.configuracao.required = item.required;
    this.configuracao.default = item.default;
    this.configuracao.contexto_configuracao_id = item.contexto_configuracao_id;
    this.configuracao.is_active = item.is_active;
    this.configuracao.is_boolean = item.is_boolean;
    this.configuracao.user_id = item.user_id;

    if (this.configuracao.id != null) {

      console.log("callback");
      callback();
    }

  }

  private getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page =Number.isInteger(page) ? page : 1 ;
    this.getConfiguracaos()
  }


  private selectBoxContextoConfiguracaos() {
    this.http.call_get('contexto-configuracao/selectBoxContextoConfiguracaos', null).subscribe(
      response => {
        console.log(Object(response));

        this.contexto_configuracaos = Object(response);
      }
    );
  }

  private async getDataContexto(id) {

    this.http.__call('contexto-configuracao/getDataContextoById/' + id, null).subscribe(
      response => {
        //console.log(Object(response));

        this.contexto_valores = Object(response);
      }
    );

  }

  private setDefault() {
    this.input_default = !this.input_default;
  }

  private setRequired() {
    this.input_required = !this.input_required;
  }

  private setBoolean() {
    this.input_boolean = !this.input_boolean;

    if(this.input_boolean) this.input_contexto = false;
  }

  private setContexto() {

    //console.log("contexto")
    this.input_contexto = !this.input_contexto;

    if (this.input_contexto) {
      this.configuracao.contexto_configuracao_id = null;
      this.input_boolean = false;
    }
  }

}
