import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

@Component({
  selector: 'app-conf-dados-facturacao',
  templateUrl: './conf-dados-facturacao.component.html',
  styleUrls: ['./conf-dados-facturacao.component.css']
})
export class ConfDadosFacturacaoComponent implements OnInit {

  private dadosfacturacao = {
    id: null,
    municipio_id: null,
    provincia_id: null,
    morada: null,
    nif: null
  }

  private dadosfacturacao_lojas = {
    loja_id: null,
    horario: null
  }

  private contabancaria = {
    iban: null,
    municipio_id: null,
    banco_id: null,
  }

  private Lojas: any = [];
  private LojasAssociadas: any = [];
  private items: any = [];
  private itemscontabancaria: any = [];
  private municipios: any = [];
  private provincias: any = [];
  private Municipios: any = [];
  private bancos: any = [];

  constructor(private http: HttpService, private configService: ConfigService) { }

  ngOnInit() {
    this.getPageFilterData(1);
    this.listarProvincias()
    this.listarMunicipios()
    this.getLojas()
    this.listarBancos();
  }


  private listadadosfacturacao() {

    this.configService.loaddinStarter('start');

    this.http.__call('dados-facturacao/listagem', this.http.filters).subscribe(

      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.configService.loaddinStarter('stop');

      }
    );
  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
    this.listadadosfacturacao();
  }


  private register(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (this.dadosfacturacao.municipio_id == "" || this.dadosfacturacao.nif == null) {
      this.configService.showAlert("Os campos Descrição e nif são obrigatórios", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    } else {
    this.http.__call('dados-facturacao/create', this.dadosfacturacao).subscribe(
      res => {
        if (Object(res).code == 201) {
          this.configService.showAlert(Object(res).message, 'alert-danger', true);
          this.configService.loaddinStarter('stop');
        } else {
          this.configService.showAlert(Object(res).message, 'alert-success', true);
          this.clearFormInputs(e);
          this.listadadosfacturacao()
          this.configService.loaddinStarter('stop');
        }
      }
    )
  };
}

  private clearFormInputs(e) {
    e.target.elements[0].value = null;
    e.target.elements[1].value = null;
    e.target.elements[2].value = null;
  }

  private refresh(id, municipio_id, nif, morada) {
    this.dadosfacturacao.id = id;
    this.dadosfacturacao.municipio_id = municipio_id;
    this.dadosfacturacao.nif = nif;
    this.dadosfacturacao.morada = morada;
  }

  private editar(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (this.dadosfacturacao.municipio_id == "" || this.dadosfacturacao.nif == null) {
      this.configService.showAlert("Os campos Descrição e nif são obrigatórios", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    } else {
      this.http.__call('dados-facturacao/update/' + this.dadosfacturacao.id, this.dadosfacturacao).subscribe(
        res => {
          if (Object(res).code == 201) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.listadadosfacturacao();

          }
        }
      );
    }
    this.configService.loaddinStarter('stop');
  }

  private listarProvincias() {
    this.http.call_get('provincia/selectBox', null).subscribe(
      response => {
        this.provincias = Object(response).data;
      }
    );
  }

  private listarMunicipios() {
    this.http.call_get('municipio/selectBox', null).subscribe(
      response => {
        this.Municipios = Object(response).data;
      }
    );
  }

  private selectBoxMunicipiosByProvincia(id) {

    this.http.call_get('municipio/getMunicipiosByProvincia/' + this.dadosfacturacao.provincia_id, null).subscribe(
      response => {
        this.municipios = Object(response).data;
      }
    );
  }


  private ini() {
    this.dadosfacturacao = {
      id: null,
      municipio_id: null,
      provincia_id: null,
      morada: null,
      nif: null
    }
  }

  private associarlojadadosfacturacao(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
   
      this.http.__call('dados-facturacao/relacao/' + this.dadosfacturacao.id, this.dadosfacturacao_lojas).subscribe(
        res => {
          if (Object(res).code == 201) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            //this.configService.clearFormInputs(e);
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.clearFormInputs(e);
            this.getLojasAssociadas();
          }
        }
      );
    this.configService.loaddinStarter('stop');
  }

  private loadingLoja = {
    loja: 'Seleccione a Loja',
  }

  private getLojas() {
    this.loadingLoja.loja = 'Carregando...';
    
      this.http.call_get('loja/selectBox' , null).subscribe(
      response => {
        this.Lojas = Object(response).data;
        this.loadingLoja.loja;
      }
    );
  }

  private listarBancos() {
    this.http.call_get('banco/listar', null).subscribe(
      response => {
        this.bancos = Object(response).data;
      }
    );
  }

  private getLojasAssociadas() {
    this.http.__call('dados-facturacao/update/getlojas/' + this.dadosfacturacao.id, null).subscribe(
      response => {
        this.LojasAssociadas = Object(response).data;
      }
    );
  }

/*   private listacontabancarias() {

    this.configService.loaddinStarter('start');

    this.http.__call('conta-bancaria/listagem', this.http.filters).subscribe(

      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.itemscontabancaria = Object(response).data.data;
        this.configService.loaddinStarter('stop');
      }
    );
  } */

  
  private getContasAssociadas() {

    this.configService.loaddinStarter('start');

    this.http.__call('dados-facturacao/update/getContasBancarias/' + this.dadosfacturacao.municipio_id, null).subscribe(
      response => {
        this.itemscontabancaria = Object(response).data;
        this.configService.loaddinStarter('stop');
      }
    )
  }

  private storecontasbancarias(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();

    this.contabancaria.municipio_id = this.dadosfacturacao.municipio_id
   
      this.http.__call('conta-bancaria/create', this.contabancaria).subscribe(
        res => {
          if (Object(res).code == 201) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            //this.configService.clearFormInputs(e);
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.clearFormInputs(e);
            this.getContasAssociadas()
          }
        }
      );
    this.configService.loaddinStarter('stop');
  }

}
