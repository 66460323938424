import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-tipificacao-anomalias',
  templateUrl: './tipificacao-anomalias.component.html',
  styleUrls: ['./tipificacao-anomalias.component.css']
})

export class TipificacaoAnomaliasComponent implements OnInit, OnDestroy {

  @Input() simpleFormTipoAnomalia: FormGroup;

  private tipo_anomalia = {
    id: null,
    nome: null,
    slug: null
  };


  private title: string = "Registar Tipo De Anomalia";

  private input_default: boolean = false;
  private input_required: boolean = false;

  private items: any = [];


  constructor(private http: HttpService, private configService: ConfigService) {
  }



  ngOnInit() {
    this.http.filters.search = null
    this.getPageFilterData(1);
    this.getTipoAnomalias()
  }

  ngOnDestroy(){
    this.http.filters.orderBy = null
  }

  private getTipoAnomalias() {

    this.configService.loaddinStarter('start');

    this.http._get('api/v1/motivos_anomalias/index').subscribe(

      response => {
        // this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        // this.http.filters.pagination.page = Object(response).data.page;
        // this.http.filters.pagination.total = Object(response).data.total;
        // this.http.filters.pagination.perPage = Object(response).data.perPage;
        this.items = Object(response);
        this.configService.loaddinStarter('stop');

      }
    );
  }


  view_tipo_anomalia = false;



  private saveTipoAnomalia() {

    if (this.tipo_anomalia.id == null) {

      this.http.__call('api/v1/motivos_anomalias/create', {
        nome: this.tipo_anomalia.nome,
        slug: this.tipo_anomalia.slug
      }).subscribe(
        res => {
          this.getPageFilterData(1);
          if (Object(res).code == 200) {
            this.configService.showAlert(Object(res).message, 'alert-success', true);


            this.configService.loaddinStarter('stop');
          } else {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
            this.configService.loaddinStarter('stop');
          }
        }
      )
    }
    else {

      this.http.__call('api/v1/motivos_anomalias/update/' + this.tipo_anomalia.id, {
        nome: this.tipo_anomalia.nome,
        slug: this.tipo_anomalia.slug
      }).subscribe(
        res => {
          this.getPageFilterData(1);

          if (Object(res).code == 200) {
            this.configService.showAlert(Object(res).message, 'alert-success', true)
            this.configService.loaddinStarter('stop');
          } else {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
            this.configService.loaddinStarter('stop');
          }
        }
      )
    }

    this.getTipoAnomalias()

  }

  private onReset() {
    this.tipo_anomalia.id = null;
    this.tipo_anomalia.nome = null;
    this.tipo_anomalia.slug = null;

  }


  private actualizarTipoAnomalia(item) {



    this.http.__call('api/v1/motivos_anomalias/update/' + this.tipo_anomalia.id, {
      nome: item.id,
      slug: item.slug
    }).subscribe(
      response => {
        this.getPageFilterData(1);


        if (Object(response).code == 200) {

          this.configService.showAlert("Tipo De Anomalia " + this.tipo_anomalia.nome + " foi actualizado ", "alert-success", true);
        }

      }
    );

    for (let i = 0; i < this.items.length; ++i) {
      if (this.items[i].id == this.tipo_anomalia.id) {
      }
    }
  }


  private setDataTipoAnomalia(item) {

    if (item !== undefined) {
      this.title = "Editar Tipo De Anomalia";

      this.tipo_anomalia.id = item.id;
      this.tipo_anomalia.nome = item.nome;
      this.tipo_anomalia.slug = item.slug;

    }
  }

  private DeleteTipoAnomalia(item) {
    this.tipo_anomalia.id = item.id;

    this.http.__delete('api/v1/motivos_anomalias/delete/' + this.tipo_anomalia.id, {}).subscribe(
      response => {
        this.getPageFilterData(1);

        if (Object(response).code == 200) {
          this.configService.showAlert("Tipo De Anomalia " + this.tipo_anomalia.nome + " foi Deletada", "alert-success", true);
        }
      }
    );
  }



  private getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page =Number.isInteger(page) ? page : 1 ;
    this.getTipoAnomalias();
  }

  private setDefault() {
    this.input_default = !this.input_default;
  }

  private setRequired() {
    this.input_required = !this.input_required;
  }

}
