import { Component, OnInit, Input } from "@angular/core";
import { ConfigService } from "src/app/providers/config/config.service";
import { HttpService } from "src/app/providers/http/http.service";
import { FacturaService } from "../facturacao/factura.service";

@Component({
  selector: "app-facturacao-conta",
  templateUrl: "./facturacao-conta.component.html",
  styleUrls: ["./facturacao-conta.component.css"]
})
export class FacturacaoContaComponent implements OnInit {
  @Input() facturacaoContaClientesModal: string = "facturacaoContaClientesModal";

  loading: boolean
  rotas: any = []
  charges: any = []
  rota: any = {}

  constructor(
    private http: HttpService,
    private configService: ConfigService,
    private _facturaService: FacturaService,
  ) {
    this.loadingMethods()
  }

  ngOnInit() {
    this.loading = false
  }

  _initProcess(){
    let data = { rota_header_id: this.rota.id }
    console.log( this.rota.id )

    this.loading = true;
    this.http.__call("factura/posPago", data)
    .subscribe(res => {
      if (Object(res).code == 200) {
        this.configService.showAlert( Object(res).message, "alert-success", true)
      } else {
        this.configService.showAlert( Object(res).message, "alert-danger", true )
      }
      this.loading = false;
    }, error => {
      this.loading = false;
    })
  }

  _getRoteirosByRotaId(id: number){
    let rota = this.rotas.find(obj => obj.id == id)
    this.rota = rota
    this.charges = rota.charges
  }

  loadingMethods(){
    this._facturaService.getCharges()
    .subscribe(
      response => {
        let data = response.data
        this.rotas = data.filter(item => item.charges.length)
      }
    )
  }

}
