import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange, NgModule } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpService } from 'src/app/providers/http/http.service';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { first } from 'rxjs/operators';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-create-or-edit-rua',
  templateUrl: './create-or-edit-rua.component.html',
  styleUrls: ['./create-or-edit-rua.component.css']
})
export class CreateOrEditRuaComponent implements OnInit {

  public currentUser: any;
  @Input() title: string = "Registar Rua";
  @Input() quarteirao_view: boolean = false;
  @Input() distrito_view: boolean = false;


  @Input() rua = {
    id: null,
    nome: null,
    has_quarteirao: null,
    quarteirao_id: null,
    quarteirao: null,
    bairro_id: null,
    bairro: null,
    has_distrito: false,
    municipio_id: null,
    distrito_id: null,
    provincia_id: null,
    is_active: null,
    user_id: null,
  };


  /*   @Input() municipio = {
      id: null,
      nome: null
    }; */

  @Input() ruas: any = [];

  private provincias: any = [];
  @Input() municipios: any = [];
  @Input() distritos: any[];
  @Input() bairros: any[];
  @Input() quarteiraos: any[];

  private ruasArray = [];
  private ruaRow = {
    id: null,
    nome: null,
    has_quarteirao: null,
    quarteirao_id: null,
    quarteirao: null,
    bairro_id: null,
    bairro: null,
    has_distrito: null,
    distrito_id: null,
    distrito: null,
    municipio_id: null,
    municipio: null,
    provincia_id: null,
    provincia: null,
    user_id: null,
    user: null

  };
  private ruaRowValid: boolean = false;
  @Input() addRows: boolean = true;

  @Input() showCreateRua: boolean = true;

  @Input() ruaModal: boolean = false;


  //@Input() simpleFormRua: FormGroup;

  submitted = false;
  private loading: boolean = false;

  //@Output() private loadListRua = new EventEmitter<any>();

  constructor(private auth: AuthService, private http: HttpService, private configService: ConfigService, private formBuilder: FormBuilder) {
    //this.createForm();
    this.currentUser = this.auth.currentUserValue;
  }


  ngOnInit() {


    if (this.provincias.length <= 0) {
      this.rua.provincia_id = 29;
      this.selectBoxProvincias();
    }

    if (this.addRows == false) {
      this.selectBoxProvincias();
    }

    this.onReset();
    this.rua.user_id = this.currentUser.user.id;

  }

  save() {

    if (!this.addRows) {
      this.ruasArray.unshift({ ...this.rua });

    }

    for (let i = 0; i < this.ruasArray.length; ++i) {

      this.ruasArray[i].user_id = this.currentUser.user.id;
      if (this.ruasArray[i].id == null) {
        this.http.__call('rua/create', this.ruasArray[i]).subscribe(
          response => {

            if (Object(response).code == 200) {
              this.configService.showAlert(Object(response).message, "alert-success", true);
              this.ruasArray = [];
              this.onReset();

            } else {
              this.configService.showAlert(Object(response).message, "alert-danger", true);
            }

          }
        );

      }
      else {

        this.http.__call('rua/update/' + this.ruasArray[i].id, this.ruasArray[i]).subscribe(
          response => {

            if (Object(response).code == 200) {
              this.configService.showAlert(Object(response).message, "alert-success", true);
              this.ruasArray = [];
            } else {
              this.configService.showAlert(Object(response).message, "alert-danger", true);
            }

          }
        );
      }
    }

  }


  createOrEdit(uri: any, formulario: any, isCreate: boolean) {

    // TODO: usado para fazer a requisição com a api de criação de object
    this.http.__call(uri, formulario).pipe(first()).subscribe(
      response => {
        this.submitted = false;
        this.loading = false;

        if (Object(response).code == 200) {
          this.openEndRuaModal(true);
          this.reloadRuas();
        }

        if (isCreate) {
          formulario.reset();
        }

        //this.ruas = Object(response).data;
      },
      error => {
        this.submitted = false;
        this.loading = false;
      });
  }

  setDataRua({ item }) {

    if (this.provincias.length <= 0) {
      this.selectBoxProvincias();
    }
    if (item.id > 0) {
      this.title = "Editar Rua";

      this.rua.id = item.id;
      this.rua.nome = item.nome;
      this.rua.has_quarteirao = (this.quarteirao_view) ? item.has_quarteirao : false;
      this.rua.quarteirao_id = item.quarteirao_id;
      this.rua.bairro_id = item.bairro_id;
      this.rua.bairro = item.bairro;
      this.rua.has_distrito = (this.distrito_view) ? item.has_distrito : false;
      this.rua.distrito_id = item.distrito_id;
      this.rua.municipio_id = item.municipio_id;
      this.rua.provincia_id = item.provincia_id;
      this.rua.is_active = item.is_active;
      this.rua.user_id = item.user_id;

    } else {
      this.title = "Registar Rua";
      this.onReset();
    }

    //console.log(this.rua);
  }

  onReset() {
    this.submitted = false;

    this.rua.id = null;
    this.rua.nome = null;
    this.rua.has_quarteirao = null,
      this.rua.quarteirao_id = null;
    this.rua.bairro_id = null;
    this.rua.bairro = null;
    this.rua.has_distrito = false;
    this.rua.distrito_id = null;
    this.rua.municipio_id = null;
    this.rua.is_active = null;
    this.rua.user_id = null;
  }

  private async appendRowRua() {

    const loadedStateCallback = () => {
      //console.log(this.rua);
      this.ruaRow.nome = this.rua.nome;
      this.ruaRow.user_id = this.currentUser.user.id;

      this.ruaRowValid = this.ruaRowValidation(this.ruaRow);
      if (this.ruaRowValid)
        this.ruasArray.unshift({ ...this.ruaRow });
    }

    await this.getQuarteiraoById(this.rua.quarteirao_id, loadedStateCallback);

    //console.log(this.ruasArray);

  }

  private deleteRowRuaAppended(row): void {

    for (let i = 0; i < this.ruasArray.length; ++i) {

      if ((this.ruasArray[i].bairro_id == row.bairro_id) && (this.ruasArray[i].nome == row.nome)) {
        this.ruasArray.splice(i, 1);
        //console.log(this.ruasArray[i]);
      }

    }


  }


  private ruaRowValidation(row): boolean {

    if (this.ruasArray.length == 0)
      return true;

    for (let i = 0; i < this.ruasArray.length; ++i) {
      //console.log(this.ruasArray[i]);
      if (this.ruasArray[i].bairro_id == row.bairro_id && this.ruasArray[i].nome == row.nome) {
        return false;
      }
    }

    return true;
  }


  private reloadRuas() {

    /*     this.http.call_get('rua/listagem/', null).subscribe(
          response => {

            this.ruas = Object(response).data;
          }
        ); */
  }

  private selectBoxProvincias() {
    this.http.call_get('provincia/selectBox', null).subscribe(
      response => {

        this.provincias = Object(response).data;
      }
    );
  }

  private getQuarteiraoById(id: any, callback: Function) {

    this.http.__call('quarteirao/getQuarteiraoById/' + ((this.rua.has_quarteirao) ? id : 0),
      {
        has_quarteirao: (this.quarteirao_view) ? this.rua.has_quarteirao : false,
        bairro_id: this.rua.bairro_id
      }).subscribe(
        response => {

          if (this.rua.has_quarteirao) {
            this.ruaRow.quarteirao_id = Object(response).data.id;
            this.ruaRow.quarteirao = Object(response).data.nome;
            this.ruaRow.has_quarteirao = Object(response).data.has_quarteirao;
            this.ruaRow.bairro_id = Object(response).data.bairro_id;
            this.ruaRow.bairro = Object(response).data.bairro;
          }
          else {
            this.ruaRow.bairro_id = Object(response).data.id;
            this.ruaRow.bairro = Object(response).data.nome;
          }

          this.ruaRow.distrito_id = Object(response).data.distrito_id;
          this.ruaRow.distrito = Object(response).data.distrito;
          this.ruaRow.has_distrito = (this.distrito_view) ? Object(response).data.has_distrito : false;
          this.ruaRow.municipio_id = Object(response).data.municipio_id;
          this.ruaRow.municipio = Object(response).data.municipio;
          this.ruaRow.provincia_id = Object(response).data.provincia_id;
          this.ruaRow.provincia = Object(response).data.provincia;

          if (!this.ruaRow.has_quarteirao) this.ruaRow.quarteirao_id = null;
          if (!this.ruaRow.has_distrito) this.ruaRow.distrito_id = null;

          callback();

        }
      );

  }


  private selectBoxMunicipiosByProvincia() {

    this.http.call_get('municipio/getMunicipiosByProvincia/' + this.rua.provincia_id, null).subscribe(
      response => {
        this.municipios = Object(response).data;
      }
    );
  }

  private selectBoxDistritosByMunicipio() {

    this.rua.has_distrito = this.distrito_view;

    if (this.distrito_view) {
      for (let i = 0; i < this.municipios.length; ++i) {
        if (this.municipios[i].id == this.rua.municipio_id)
          this.rua.has_distrito = this.municipios[i].has_distrito;
      }
    }
    if (this.rua.has_distrito) {
      this.http.call_get('distrito/getDistritosByMunicipio/' + this.rua.municipio_id, null).subscribe(
        response => {
          this.distritos = Object(response).data;
        }
      );
    }

    if (!this.rua.has_distrito) {
      this.selectBoxBairrosByMunicipio();
    }

  }

  private selectBoxBairrosByMunicipio() {

    this.http.call_get('bairro/selectBoxByMunicipio/' + this.rua.municipio_id, null).subscribe(
      response => {
        this.bairros = Object(response);
      }
    );
  }

  private selectBoxBairrosByDistrito() {

    this.http.call_get('bairro/getBairrosByDistrito/' + this.rua.distrito_id, null).subscribe(
      response => {
        this.bairros = Object(response).data;
      }
    );
  }



  private selectBoxQuarteiraosByBairro() {

    this.rua.has_quarteirao = this.quarteirao_view;

    if (this.quarteirao_view) {
      for (let i = 0; i < this.bairros.length; ++i) {
        if (this.bairros[i].id == this.rua.bairro_id)
          this.rua.has_quarteirao = this.bairros[i].has_quarteirao;
      }
    }
    if (this.rua.has_quarteirao) {
      this.http.call_get('quarteirao/getQuarteiraosByBairro/' + this.rua.bairro_id, null).subscribe(
        response => {

          this.quarteiraos = Object(response).data;
        }
      );
    }

    if (!this.rua.has_distrito) {
      this.selectBoxBairrosByMunicipio();
    }

  }


  private openEndRuaModal(flag: boolean): void {
    this.ruaModal = flag;
  }

  public loadListEstabelecimentos(ruas) {
    this.ruas = ruas;
  }

}
