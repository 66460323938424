import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { PedidoReportService } from 'src/app/components/report-at/pedido/pedido-report.service';

import { ExcelService } from 'src/app/services/excel.service';
import * as moment from 'moment';


@Component({
  selector: 'app-pedidos',
  templateUrl: './pedidos.component.html',
  styleUrls: ['./pedidos.component.css']
})
export class PedidosComponent implements OnInit {
  private loading: boolean = false;

  private color: string = "";

  private pedido = {
    id: null,
    estado: null,
    estado_actual: null,
    tarifario_id: null,
    tarifario_descricao: null,
    observacao_old: null,
    dataReclamacao: null,
    origem: null,
    dataPedido: null,
    residencia_id: null,
    capacidade: null,
    destino: null,

    tipo_ordem_servico_id: null,
    tipo_ordem_servico: null,
    contrato_id: null,
    tipo_contracto_id: null,
    tipo_contrato: null,
    tipo_medicao_id: null,
    tipo_medicao: null,
    tipo_medicao_slug: null,
    conta_id: null,
    numero_conta: null,
    estado_ordem_servico_id: null,
    estado_ordem_servico: null,
    estado_ordem_servico_slug: null,
    local_instalacao_id: null,
    tarifario: null,
    observacao: null,
    cliente_id: null,
    cliente: null,
    cliente_morada: null,
    cliente_telefone: null,

  }

  private conta = {
    id: null,
    contaDescricao: null,
    cliente_id: null,
    tipoServico: null,
    agencia_id: null,
    estado: null
  }

  private contrato = {
    tipo_medicao_slug: null
  }


  private contador = {
    id: null,
    numero_serie: null,
    marca: null,
    ultima_leitura: null,
    modelo: null
  }

  private contadorMessage: string = null;
  private contadorClassMessage: string = null;
  private showContadorDetail: boolean = false;
  private estado_changed: boolean = false;

  private localInstalacao = {
    id: null,
    municipio: null,
    distritoComuna: null,
    bairro: null,
    rua: null,
    residenciaNumero: null,
    residenciaNome: null
  }

  private servico = {
    id: null,
    chaveServico: null,
    conta_id: null,
    estado: null,
    tarifario_id: null,
    adsl_username: null,
    adsl_password: null,
    cliente_id: null,
    contaDescricao: null,

    agencia_id: null,
    estado_pedido: null,
    estado_pedido_id: null,
    pedido_id: null,
    observacao_pedido: null,
    estado_actual_pedido: null,

    par_cabo: null,
    armario: null,
    central: null,
    caixa: null,
    cabo_id: null,
    armario_primario: null,
    armario_secundario: null,
    par_caixa: null,
    par_adsl: null,
    tipo_pedido: null,
    tipoPedido: null,
    //******** */
    servico: null,
    servico_class: null,
    servico_omg: null,
    nivel_servico: null,
    descricao_servico: null,
    debito_binario: null,
    interface_dte: null,
    redundancia: null,
    cpe_sede_a: null,
    cpe_sede_b: null,
    endereco_ponto_a: null,
    endereco_ponto_b: null,
    latitude_ponto_a: null,
    longitude_ponto_a: null,
    latitude_ponto_b: null,
    longitude_ponto_b: null
  }

  private estado = {
    estado_id: null
  }

  private interest = {
    id: null
  }

  private items: any = [];
  private historicos: any = [];
  private estado_ordem_servicos: any = [];
  private estados_fnrj: any = [];
  private clienteContas: any = [];
  private isFinalizar: boolean = true


  private tipoPedidos: any = [];

  constructor(private http: HttpService, private configService: ConfigService, private PedidoReportService: PedidoReportService, private excelService: ExcelService) { }

  ngOnInit() {
    this.http.filters.search = null
    this.getPageFilterData(1);
    //this.interest.id = 'modalMoeda';
  }

  exportAsXLSX(): void {
    var CurrentDate = new Date();
    var nameFile = "Lista_Pedidos-" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    this.excelService.exportAsExcelFile(document.getElementsByClassName("exportAsXLSXCliente")[0], nameFile);
  }
  exportAsPDF(): void {
    //this.reportLoja.relatorioLoja(this.cobrancas, this.simpleForm.value, 'save');
  }

  imprimirPDF(): void {
    //this.reportLoja.relatorioLoja(this.cobrancas, this.simpleForm.value);
  }


  private listarPedidos() {
    this.loading = true
    this.configService.loaddinStarter('start');

    this.http.__call('pedido/listagem', this.http.filters).subscribe(

      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.loading = false;


        // console.log(this.items)
        this.configService.loaddinStarter('stop');

      }
    );
  }

  private getHistoricoEstado(id: number, dataPedido: any) {

    this.pedido.dataPedido = dataPedido;

    this.configService.loaddinStarter('start');

    this.http.call_get('historico/estado/pedido/' + id, null).subscribe(
      response => {
        this.historicos = Object(response).data;
        //console.log(this.historicos)
        this.configService.loaddinStarter('stop');
      }
    )
  }

  private btnImprimirPedido(id) {
    //console.log(1)
    this.PedidoReportService.imprimirPedido(id);
  }


  private getEstadoByPedido(id: number) {

    this.configService.loaddinStarter('start');

    this.http.call_get('estado-ordem-servico/selectGroupBox/', null).subscribe(
      response => {

        this.estado_ordem_servicos = Object(response)
      }
    )

    this.configService.loaddinStarter('stop');
  }

  private carregando = {
    estado: 'Seleccione o estado',
  }

  private getEstadosOrdensServico() {
    this.carregando.estado = 'Carregando...';
    this.configService.loaddinStarter('start');

    this.http.call_get('estado-ordem-servico/selectGroupBox', null).subscribe(
      response => {

        //this.estados_fnrj = Object(response).data
        this.estado_ordem_servicos = Object(response);
        this.carregando.estado = 'Seleccione o estado';
      }
    )

    this.configService.loaddinStarter('stop');
  }

  private clearForm() {
    this.isFinalizar = true;
    this.servico.estado_pedido = null;
    this.servico.observacao_pedido = null;
    this.servico.chaveServico = null;
    this.servico.adsl_password = null;
    this.servico.adsl_username = null;
    this.servico.conta_id = null;
    this.servico.agencia_id = null;
    this.servico.central = null;
    this.servico.par_cabo = null;
    this.servico.armario = null;
    this.servico.caixa = null;
    this.servico.par_caixa = null;
    this.servico.armario_primario = null;
    this.servico.armario_secundario = null;
  }

/*   private showSubmit() {
    this.isFinalizar = true;
  } */



  private listarTipoPedidos() {

    this.http.call_get('tipoPedidos/selectBox', null).subscribe(
      response => {
        this.tipoPedidos = Object(response).data;
      }
    );
  }

  private setDataPedido(item: any) {

    this.pedido.id = item.id,
    this.pedido.tipo_ordem_servico_id = item.tipo_ordem_servico_id,
    this.pedido.tipo_ordem_servico = item.tipo_ordem_servico,
    this.pedido.contrato_id = item.contrato_id,
    this.pedido.tipo_contracto_id = item.tipo_contracto_id,
    this.pedido.tipo_contrato = item.tipo_contrato,
    this.pedido.tipo_medicao_id = item.tipo_medicao_id,
    this.pedido.tipo_medicao = item.tipo_medicao,
    this.pedido.tipo_medicao_slug = item.tipo_medicao_slug,
    this.pedido.conta_id = item.conta_id,
    this.pedido.numero_conta = item.numero_conta,
    this.pedido.estado_ordem_servico_id = item.estado_ordem_servico_id,
    this.pedido.estado_ordem_servico = item.estado_ordem_servico,
    this.pedido.estado_ordem_servico_slug = item.estado_ordem_servico_slug,
    this.pedido.local_instalacao_id = item.local_instalacao_id,
    this.pedido.tarifario_id = item.tarifario_id,
    this.pedido.tarifario = item.tarifario,
    this.pedido.observacao = item.observacao,
    this.pedido.cliente_id = item.cliente_id,
    this.pedido.cliente = item.cliente,
    this.pedido.cliente_morada = item.cliente_morada,
    this.pedido.cliente_telefone = item.cliente_telefone

    //console.log(this.pedido);

    if(this.estado_ordem_servicos.length == 0) {
      this.getEstadosOrdensServico();
    }
  }

  private getPedidoInfo(contrato: number, residencia_id: number, idPedido: number, tipo_medicao_slug: any) {

    this.pedido.contrato_id = contrato;
    this.pedido.residencia_id = residencia_id;
    this.pedido.id = idPedido;
    this.contrato.tipo_medicao_slug = tipo_medicao_slug;

    //console.log(this.pedido.id )
  }

  private getInstalacaoDetalhe(residencia_id) {

    this.configService.loaddinStarter('start');
    this.http.__call('pedido/detalheLocalInstalacao/' + residencia_id, null).subscribe(
      response => {

        let dados = Object(response).data;

        this.localInstalacao.id = dados.id;
        this.localInstalacao.municipio = dados.municipio;
        this.localInstalacao.distritoComuna = dados.distritoComuna;
        this.localInstalacao.bairro = dados.bairro;
        this.localInstalacao.rua = dados.rua;
        this.localInstalacao.residenciaNumero = dados.residenciaNumero;
        this.localInstalacao.residenciaNome = dados.residenciaNome;
        //console.log(this.localInstalacao.residenciaNome)
        this.configService.loaddinStarter('stop');
      }
    );
  }

  private getClienteConta(id: any) {
    this.configService.loaddinStarter('start');
    this.http.__call('get/cliente/contas/pedido/' + id, null).subscribe(
      response => {

        let dados = Object(response).data;

        this.clienteContas = dados.contas;
        this.servico.cliente_id = dados.cliente.clienteID;
        //console.log(Object(response).data)
        this.configService.loaddinStarter('stop');
      }
    );
  }


  private validateSerieContador() {

    this.isFinalizar = false;

    if (this.contador.numero_serie == null) {
      this.contadorMessage = "Insira o número de série!";
      this.contadorClassMessage = "text-danger";

    } else {
      this.configService.loaddinStarter('start');
      this.http.__call('contador/validate/serie', this.contador).subscribe(
        data => {
          if (data.code === 200) {
            this.contador.id = data.data.id;
            this.contador.numero_serie = data.data.numero_serie;
            this.contador.marca = data.data.marca;
            this.contador.modelo = data.data.modelo;
            this.contador.ultima_leitura = data.data.ultima_leitura;
            this.contadorMessage = "Sucesso";
            this.contadorClassMessage = "text-success";

            //this.classMessage = "glyphicon glyphicon-ok";
            this.configService.loaddinStarter('stop');
            this.showContadorDetail = true;

          }
        }, error => {
          //console.log(error.status);
          if (error.status === 302) {
            this.contadorMessage = "Contador em uso!";
            this.contadorClassMessage = "text-warning";
            this.contador.id = 302;
          } else if (error.status === 404) {
            this.contadorMessage = "Este número de série não existe";
            this.contadorClassMessage = "text-warning";
            this.contador.id = 404;
          } else {
            this.contadorMessage = "Não definido";
            this.contadorClassMessage = "text-warning";
          }
          this.configService.loaddinStarter('stop');
        }
      );

      this.isFinalizar = true;
    }
  }


  private savePedido() {

    if ((this.pedido.estado_ordem_servico_slug == 'FECHADA' && this.contador.numero_serie == null) && this.contrato.tipo_medicao_slug == "LEITURA") {
      this.configService.showAlert('Digite o número de Série do contador', 'alert-danger', true);
    }else if ((this.contador.id == null || this.contador.id == "") && this.contrato.tipo_medicao_slug == "LEITURA") {
      this.configService.showAlert('Clique no ícone para validar', "alert-danger", true);
    } else if (this.contador.id == 302) {
      this.configService.showAlert('Esse Contador já está em uso', "alert-danger", true);
    } else if (this.contador.id == 404) {
      this.configService.showAlert('Esse número de série não existe', "alert-danger", true);
    } else {

      this.isFinalizar = false;

      this.showContadorDetail = false;

      this.configService.loaddinStarter('start');
      this.http.__call('pedido/update/', {
        contrato_id: this.pedido.contrato_id,
        pedido_id: this.pedido.id,
        conta_id: this.pedido.conta_id,
        local_instalacao_id: this.pedido.local_instalacao_id,
        contador_id: this.contador.id,
        estado_ordem_servico_id: this.pedido.estado_ordem_servico_id,
        estado_ordem_servico_slug: this.pedido.estado_ordem_servico_slug,
        observacao: this.pedido.observacao
      }).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
            this.isFinalizar = true;
          } else {
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.configService.loaddinStarter('stop');
            this.pedido.id = null;
            this.contador.numero_serie = null;
            this.contador.id = null;
            this.pedido.observacao = null;
            this.pedido.local_instalacao_id = null;
            this.pedido.estado_ordem_servico_slug = null;
            this.pedido.contrato_id = null;
            this.pedido.conta_id = null;
            this.contadorMessage = null;
            this.contadorClassMessage = null;
            this.getPageFilterData(1);

          }
        }
      );
    }
  }


  private editarEstadoPedido() {
    //console.log(this.pedido)

    if (this.pedido.estado == null || this.pedido.estado == "null") {
      this.configService.showAlert('Seleccione o estado', 'alert-danger', true);
    }
    else {

      this.configService.loaddinStarter('start');
      this.http.__call('pedido/update/estado/' + this.pedido.id, this.pedido).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.configService.loaddinStarter('stop');
            this.getPageFilterData(1);

          }
        }
      );
    }
  }



  private getDetailEstadoOrdemServico() {
    console.log(this.pedido.estado_ordem_servico_id)

    this.configService.loaddinStarter('start');

    this.http.call_get('estado-ordem-servico/getEstadoOrdemServicoById/' + this.pedido.estado_ordem_servico_id, null).subscribe(
      response => {
        let result = Object(response);

        this.pedido.estado_ordem_servico = result.nome;
        this.pedido.estado_ordem_servico_slug = result.slug;

        this.estado_changed = false;
        this.showContadorDetail = true;

      }
    )

    this.configService.loaddinStarter('stop');

    console.log(this.pedido)
  }


  public hideBottonAvancar() {
    this.interest.id = null;
    this.pedido.estado_ordem_servico_id = null;
  }

  private changeEstadoOrdem() {
    this.estado_changed = true;
  }



  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
    this.listarTipoPedidos();
  }

  generateAdslUsername(event: any) {
    this.servico.adsl_username = event.target.value;
  }

}
