import { Injectable, EventEmitter } from '@angular/core';
import { ApiService } from 'src/app/providers/http/api.service';
import { HttpService } from 'src/app/providers/http/http.service';
import * as moment from 'moment';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';

@Injectable({
  providedIn: 'root'
})
export class ContratoLundanorteService {

  public pdfEvent = new EventEmitter<Object>();

  private report: any;

  constructor(private http: HttpService) { }

  public imprimirPDFContrato(id, report = 'imprimir') {
    this.http._get('contrato/imprimir/' + id).subscribe(
      response => {
        const dados = Object(response).data;
        console.log(dados)
        this.imprimirContratoV2(dados, report)
      }, error => {
        console.log(error)
      }
    );
  }




  public imprimirContrato(item, report = 'imprimir') {

    const contrato = item.contrato;
    const tipo_identidades = item.tipo_identidades;
    const empresa = item.empresa;


    var img_logotipo = empresa.logotipo;
    var doc = new jsPDF()
    doc.addImage(img_logotipo, 'JPEG', 10, 17, 24, 20)

    doc.setFontSize(8);
    doc.setFont("calibri");
    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.text('' + (empresa.companyName || ''), 37, 20);
    doc.setTextColor(0);
    doc.setFontType("normal");
    doc.text('' + (empresa.addressDetail || ''), 37, 25);
    doc.text('NIF: ' + (empresa.taxRegistrationNumber || ''), 37, 30);
    doc.text('Email: ' + (empresa.email || ''), 37, 35);
    doc.text('Telefone: ', 95, 30);
    doc.text('' + (empresa.telefone || ''), 107, 30);
    doc.text('WebSite: ', 95, 35);
    doc.setTextColor(0, 0, 255);
    doc.text('' + (empresa.site || ''), 107, 35);

    doc.setFontSize(8);
    doc.setDrawColor(0);
    doc.setFillColor(255);
    doc.rect(145, 14, 48, 23, 'B');
    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.text('CONTRATO N.º', 148, 20);
    doc.text('CIL', 148, 30);
    doc.setFontSize(8);
    doc.setFontType("normal");
    doc.text('' + contrato.id, 170, 20);
    doc.text('' + (contrato.cil || ''), 155, 30);
    doc.setFontSize(11);
    doc.setFontType("bold");
    doc.text('CONTRATO DE ' + (contrato.objecto_contrato.toUpperCase() == 'ÁGUA' ? 'ABASTECIMENTO DE ' + contrato.objecto_contrato.toUpperCase() : contrato.objecto_contrato.toUpperCase()), 10, 46);

    doc.setFontSize(8);
    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.setFont("arial");
    doc.text('LOCAL DE CONSUMO: ', 10, 58);
    doc.text('CIL: ', 10, 61.5);
    doc.text('Morada Local de Consumo:', 10, 65);
    doc.setFontType("normal");
    doc.text(' ' + contrato.id, 42, 58);
    doc.text(' ' + (contrato.cil || ''), 42, 61.5);
    var PosYLocalizacao = 68
    /*  doc.text('' + (contrato.municipio == null ? '' : contrato.municipio + ', ') + (contrato.bairro == null ? '' : contrato.bairro + ', ') + (contrato.rua == null ? '' : ', ' + contrato.rua), 10, PosYLocalizacao, { maxWidth: 60, align: 'left'});
     doc.text('' + (contrato.predio_nome == null ? '' : contrato.predio_nome + ', ')  + (contrato.predio_andar == null ? '' : contrato.predio_andar), 10, PosYLocalizacao + 6); */
    doc.text('Localidade: ' + (contrato.municipio || ''), 10, 69);
    doc.text('Bairro/Zona: ' + (contrato.bairro || ''), 10, 73);
    doc.text('Rua: ' + (contrato.rua || ''), 10, 77);
    doc.text('Apartamento/Moradia/Caixa : ' + (contrato.moradia_numero || ''), 10, 81);
    doc.text('Prédio: ' + (contrato.predio_nome || ''), 10, 85);

    doc.setFontType("bold");
    doc.text('CLIENTE N.º: ' + contrato.cliente_id, 85, 58);
    doc.text('' + contrato.cliente_nome.toUpperCase(), 85, 61.5);
    doc.setFontType("normal");
    doc.text('NIF: ', 85, 65);
    doc.text('Telefone: ' + (contrato.cliente_telefone || ''), 85, 69);
    doc.text('EMAIL: ' + (contrato.cliente_email || ''), 85, 73);

    let nif = tipo_identidades.find(obj => obj.nome == 'NIF')
    doc.text(' ' + (nif ? nif.numero_identidade : ""), 91, 65);

    doc.setFontSize(8)
    doc.setTextColor(0);
    doc.setFont("arial");
    doc.setFontType("normal");

    let identidade_text = nif ? `, portador do ${nif.nome} n.º ${nif.numero_identidade}, ` : ", "
    doc.text('O presente contrato de ' + contrato.objecto_contrato.toUpperCase() + ' é celebrado entre a ' + empresa.companyName + ', NIPC 5000330620, e o/a Cliente ' + contrato.cliente_nome.toUpperCase() + identidade_text + ' para o Local de Consumo acima identificado.', 10, 91, { maxWidth: 170, align: 'justify' });

    doc.setFontType("normal");
    doc.text('Categoria Cliente: ', 10, 103);
    doc.text('Tarifário: ', 10, 109);
    doc.text('Classe Tarifário: ', 10, 115);
    doc.text('Tipo de Utilização: ', 144, 103);
    doc.text('Tipo de Medição: ', 144, 109);


    doc.setFontType("Normal");
    doc.text('' + (contrato.tipologia_cliente || ''), 35, 103);
    doc.text('' + (contrato.tarifario || ''), 21, 109);
    doc.text('' + (contrato.classe_tarifario || ''), 33, 115);
    doc.text('' + (contrato.tipo_contrato || ''), 168, 103);
    doc.text('' + (contrato.tipo_medicao || ''), 168, 109);
    /*     doc.text('' + (contrato.tipo_medicao_slug == 'LEITURA') ? contrato.ultima_leitura == null ? '' : contrato.ultima_leitura + ' m³' : '', 83, 109); */
    /* doc.text('' + moment(contrato.data_inicio).format("DD/MM/YYYY"), 173, 103); */
    /*  doc.setFontSize(8)
     doc.setTextColor(0);
     doc.setFont("arial");
     doc.setFontType("bold"); */
    doc.text('Declaro que pretendo contratar com a empresa EPASLN, nas condições específicas referidas e expressas nesse contrato, cumprir com as demais condições em vigor e que tomei conhecimento e aceito.', 10, 128, { maxWidth: 170, align: 'justify' });
    /*     doc.text('Neste acto consumidor declara agir na qualidade de: ', 10, 127); */


    /*     doc.text('Proprietário', 10, 137);
        doc.setFillColor(0, 0, 0, 0);
        doc.rect(27, 133, 7, 6, 'B');

        doc.text('Arrendatário', 40, 137);
        doc.setFillColor(0, 0, 0, 0);
        doc.rect(59, 133, 7, 6, 'B');

        doc.text('Imóvel', 74, 137);
        doc.setFillColor(0, 0, 0, 0);
        doc.rect(85, 133, 7, 6, 'B');

        doc.text('Promitente Comprador do Imóvel', 100, 137);
        doc.setFillColor(0, 0, 0, 0);
        doc.rect(146, 133, 7, 6, 'B'); */

    /*     doc.text('Declara que aceita receber as facturas por via de email e aplicação web.', 10, 145);
        doc.text('O Cliente declara que deseja pagar Débito Directo atráves da sua conta com IBAN AO06 0044 0000 0500085215555.', 10, 148); */

    doc.text('Declaro ainda sob compromisso de honra que:', 10, 137);
    doc.text('a) As declarações por mim reproduzidas, correspondem a verdade.', 10, 140);
    doc.text('b) Assumo todas responsabilidades inerentes à prestação das mesmas no âmbito do presente contrato.', 10, 143);
    doc.text('c) Tenho conhecimento que a prestação de falsas declarações implicará a participação às entidades competentes para efeitos de procedimento penal.', 10, 146, { maxWidth: 145, align: 'left' });

    doc.text('Data Início Contrato:', 10, 170);
    // doc.text('' + moment(contrato.data_inicio).format("DD/MM/YYYY"), 35, 170);
    doc.text('' + moment(contrato.data_inicio == "Invalid date" || contrato.data_inicio == null ? contrato.data_criacao : contrato.data_inicio).format("DD/MM/YYYY"), 35, 170);

    doc.setFontType("bold");
    var dataActual = moment(new Date()).format("DD/MM/YYYY");
    doc.text(contrato.cliente_municipio + ', ' + dataActual, 10, 190);

    doc.text('___________________________________', 10, 215);
    doc.text('Em representação da EPASLN', 15, 223);

    doc.text('___________________________________', 125, 215);
    doc.text('O Cliente', 145, 223);

    var numberPage = 1;
    var totalNumberPage = 1;


    doc.line(10, doc.internal.pageSize.height - 9, 194, doc.internal.pageSize.height - 9); // vertical line
    doc.text(dataActual + ' ' + moment().format("H:m"), 11, 285);
    doc.text('Página: ' + numberPage + '/1', 180, 285);
    doc.setFontSize(6);

    doc.text("NIF: " + empresa.taxRegistrationNumber + " - " + empresa.companyName + " / " + empresa.addressDetail + " / " + (empresa.telefone == null ? '' : empresa.telefone) + " / " + " / " + (empresa.email == null ? '' : empresa.email), 105, doc.internal.pageSize.height - 6, null, null, 'center');
    doc.setFontSize(7);
    doc.text('Processado por programa validado nº 4/AGT119', 105, doc.internal.pageSize.height - 3, null, null, 'center');


    doc.addPage();
    // PAG 2

    doc.setFontSize(9);
    doc.setFont("arial");
    doc.setTextColor(0);
    doc.text('EPASLN - ' + empresa.companyName, 50, 13);

    var dataActual = moment(new Date()).format("DD/MM/YYYY");
    /* doc.text(dataActual, 7, 13); */

    doc.setFontSize(7)
    doc.setTextColor(0);
    doc.setFont("arial");
    doc.setFontType("bold");
    doc.text('CONTRATO', 10, 25);
    doc.setFontSize(6)
    doc.text('Regulamento de Contratos de Fornecimento de Água e Transporte e Tratamento de Águas Residuais', 10, 29, { maxWidth: 45, align: 'left' });
    doc.setFontType("normal");
    doc.text('A Empresa Pública de Águas e Saneamento da Lunda-Norte, E. P., tem por objecto a gestão e exploração de sistemas públicos de abastecimento de água e de saneamento de águas residuais na Província da Lunda-Norte e dispõe de todos os direitos e obrigações decorrentes da exploração dos respectivos sistemas.', 10, 36, { maxWidth: 45, align: 'left' });
    doc.setFontType("bold");
    doc.text('Artigo 1º', 10, 53);
    doc.text('(Objecto)', 10, 55);
    doc.setFontType("normal");
    doc.text('1- O presente Regulamento tem por objecto o fornecimento de água potável por parte da EPASLN e a prestação de serviço de saneamento de águas residuais aos seus clientes;', 10, 57.5, { maxWidth: 45, align: 'left' });
    doc.text('2- São estabelecidas as regras a que deve obedecer o estabelecimento de contrato de fornecimento de água potável para consumo humano bem como a prestação de serviço de recolha, drenagem e tratamento de águas residuais, sempre que o sistema existir.', 10, 67.5, { maxWidth: 45, align: 'left' });

    doc.setFontType("bold");
    doc.text('Artigo 2º', 10, 82);
    doc.text('(Âmbito)', 10, 84.5);
    doc.setFontType("normal");
    doc.text('O presente Regulamento aplica-se em toda a área da Província da Lunda-Norte, às actividades de concepção, projeto, construção e exploração dos sistemas públicos provinciais de abastecimento de água para consumo humano e saneamento de águas residuais urbanas.', 10, 87, { maxWidth: 45, align: 'left' });

    doc.setFontType("bold");
    doc.text('Artigo 3º', 10, 101.5);
    doc.text('(Duração do Contrato)', 10, 103.5);
    doc.setFontType("normal");
    doc.text('A duração do presente Contrato é pelo período de um ano e renovável automaticamente, tendo como marco inicial a data da sua assinatura, ficando ressalvado que as partes têm o direito de rescindir ou denunciar o mesmo, nos termos estabelecidos no Artigo Quinto.', 10, 106, { maxWidth: 45, align: 'left' });

    doc.setFontType("bold");
    doc.text('Artigo 4º', 10, 120.5);
    doc.text('(Deveres dos Consumidores)', 10, 122.5);
    doc.setFontType("normal");
    doc.text('a) Contratualizar o serviço de abastecimento de água e/ou recolha de águas residuais sempre que o mesmo estiver disponível e o imóvel ocupado;', 10, 125, { maxWidth: 45, align: 'left' });
    doc.text('b) Cumprir com as disposições do presente Regulamento;', 10, 132.5, { maxWidth: 50, align: 'left' });
    doc.text('c) Pagar pontualmente as importâncias devidas e facturadas;', 10, 135, { maxWidth: 55, align: 'left' });
    doc.text('d) Não fazer uso indevido ou danificar qualquer componente dos sistemas públicos de água e /ou águas residuais;', 10, 138, { maxWidth: 45, align: 'left' });
    doc.text('e) Não alterar e abster-se de permitir que procedam à alteração do ramal de ligação de abastecimento estabelecido entre a rede geral e a rede predial, estando impedido de realizar derivações das suas canalizações seja de que tipo ou espécie for;', 10, 146, { maxWidth: 45, align: 'left' });
    doc.text('f) Não proceder à execução de ligação ao sistema público sem autorização da Entidade Gestora;', 10, 158.5, { maxWidth: 45, align: 'left' });
    doc.text('g) Não fazer uso indevido ou danificar as redes prediais e assegurar a sua conservação e manutenção;', 10, 163.5, { maxWidth: 47, align: 'left' });
    doc.text('h) Manter em bom estado de funcionamento os aparelhos sanitários e os dispositivos de utilização;', 10, 168.5, { maxWidth: 45, align: 'left' });
    doc.text('i) O titular do contrato não pode ceder a sua posição contratual, uma vez que, sempre que haja alteração da titularidade, é realizado um novo contrato mediante a apresentação dos documentos necessários.', 10, 173.5, { maxWidth: 47, align: 'left' });
    doc.text('j) Abster-se de praticar actos que possam provocar contaminação da água de consumo humano ou que cause impacto nas condições de recolha de águas residuais existentes;', 10, 183.5, { maxWidth: 45, align: 'left' });
    doc.text('k) Pagar as importâncias devidas resultantes de dano, fraude ou avaria que lhe sejam imputáveis;', 10, 193.5, { maxWidth: 45, align: 'left' });
    doc.text('l) Não violar os selos de segurança colocados pelo pessoal ao serviço da EPASLN ou organismos competentes, designadamente nos contadores ou quaisquer outros dispositivos;', 10, 198.5, { maxWidth: 45, align: 'left' });
    doc.text('m) Permitir o acesso do pessoal afeto à Entidade Gestora às instalações prediais para realização de colheitas de água, no âmbito do controlo regulamentar definido pela Entidade Reguladora;', 10, 207.5, { maxWidth: 45, align: 'left' });
    doc.text('n) Informar a EPASLN sempre que se aperceba de mau funcionamento da instalação predial ou da rede de água de abastecimento na via pública bem como o colapso da rede de águas residuais, mediante a comunicação de anomalia.', 10, 217.5, { maxWidth: 45, align: 'left' });

    doc.setFontType("bold");
    doc.text('Artigo 5º', 10, 230);
    doc.text('(Rescisão e Denúncia de Contrato)', 10, 232.5);
    doc.setFontType("normal");
    doc.text('1- A rescisão de contrato deve ser obrigatoriamente comunicada pelo consumidor à EPASLN com, pelo menos, 30 dias de antecedência sobre a data da respectiva produção de efeitos;', 10, 235, { maxWidth: 45, align: 'left' });
    doc.text('2- O consumidor mantém-se obrigado ao pagamento do consumo, serviços de águas residuais e tarifa fixa até que o contador seja retirado pela EPASLN, que não poderá ocorrer depois de 30 (trinta) dias sobre a data da efectiva rescisão de contrato;', 10, 244.5, { maxWidth: 45, align: 'left' });
    doc.text('3- O Consumidor pode denunciar, a todo o tempo, o Contrato que tenha subscrito, por motivo de desocupação do local de consumo, desde que o comunique, por escrito, à EPASLN com 10 (dez) dias de antecedência, indicando a sua nova morada para regularização final das obrigações contratuais;', 10, 256.5, { maxWidth: 45, align: 'left' });
    doc.text('4- Num prazo de 10 (dez) Dias, o Consumidor deve facultar acesso a leitura final, fecho de água ou, quando aplicável, retirada do contador instalado, produzindo a denúncia efeitos a partir dessa data;', 10, 271.5, { maxWidth: 45, align: 'left' });

    // PARTE DIREITA DA FOLHA
    doc.text('5- Não sendo possível a realização da leitura e/ou acesso ao contador no prazo referido no número anterior, por motivo imputável ao utilizador, este continua responsável pelos encargos entretanto decorrentes.', 60, 29, { maxWidth: 45, align: 'left' })

    doc.setFontType("bold");
    doc.text('Artigo 6º', 60, 41.5);
    doc.text('(Continuidade e Suspensão do Fornecimento)', 60, 43.5);
    doc.setFontType("normal");
    doc.text('1- O fornecimento da água é permanente e contínuo, ressalvando os seguintes casos de suspensão do fornecimento:', 60, 46, { maxWidth: 40, align: 'left' });
    doc.text('a) Exigência de serviço público;', 60, 53.5, { maxWidth: 45, align: 'left' });
    doc.text('b) Existência de avarias ou obras nas instalações das redes de distribuição ou nas prediais;', 60, 55.5, { maxWidth: 45, align: 'left' });
    doc.text('c) Casos fortuitos ou de força maior, tais como atos de guerra, subversão, greves gerais ou sectoriais, reduções imprevistas de caudal no caso de captações próprias, contaminação temporariamente incontrolável de captações da água bruta, epidemias, ciclones, tremores de terra, inundações, fogo e raios;', 60, 60.5, { maxWidth: 45, align: 'left' });
    doc.text('d) Actos imputáveis ao consumidor;', 60, 75.5, { maxWidth: 45, align: 'left' });
    doc.text('2- Nas situações de suspensão por exigência de serviços públicos ou por obras nas instalações das redes de distribuição de água potável, a suspensão do fornecimento será anunciada pela EPASLN, publicamente, sempre que possível, com a antecedência mínima de 24 horas;', 60, 78.5, { maxWidth: 45, align: 'left' });
    doc.text('3- São considerados actos imputáveis ao consumidor para a suspensão do fornecimento:', 60, 93.5, { maxWidth: 45, align: 'left' });
    doc.text('a) Interdição;', 60, 99.5, { maxWidth: 45, align: 'left' });
    doc.text('b) Paralisação da construção;', 60, 102.5, { maxWidth: 45, align: 'left' });
    doc.text('c) Desperdício de água;', 60, 105.5, { maxWidth: 45, align: 'left' });
    doc.text('d) Falta de pagamento das facturas dos consumos de água e de serviços de esgotos e associados, se os houver;', 60, 108.5, { maxWidth: 45, align: 'left' });
    doc.text('e) Por impedir o livre acesso para inspecção das instalações e para leitura, verificação, substituição ou levantamento de hidrómetro, após aviso;', 60, 114.7, { maxWidth: 45, align: 'left' });
    doc.text('f) Irregularidades nas instalações prediais, que possam afectar a eficiência dos serviços;', 60, 122, { maxWidth: 45, align: 'left' });
    doc.text('g) Derivação do ramal predial antes do quadro;', 60, 126.7, { maxWidth: 45, align: 'left' });
    doc.text('h) Derivação ou ligação interna de água ou esgoto para outro imóvel;', 60, 130, { maxWidth: 45, align: 'left' });
    doc.text('i) Emprego de bombas de sucção directamente ligadas a hidrómetros, ramais ou distribuidores, salvo excepções estabelecidas em norma própria;', 60, 137.5, { maxWidth: 45, align: 'left' });
    doc.text('j) Interconexões perigosas, susceptíveis de contaminar as redes distribuidoras de água e de esgotos e causar danos à saúde de terceiros;', 60, 144.5, { maxWidth: 45, align: 'left' });
    doc.text('k) Violação do limitador de vazão;', 60, 152, { maxWidth: 45, align: 'left' });
    doc.text('l) A pedido expresso do cliente ou proprietário, tratando-se de imóvel não condominial, comprovadamente desocupado (sem móveis);', 60, 154, { maxWidth: 45, align: 'left' });
    doc.text('m) Obras nas canalizações interiores dos imóveis, sempre que os trabalhos justifiquem essa suspensão;', 60, 161, { maxWidth: 45, align: 'left' });
    doc.text('n) Ausência de condições de salubridade no sistema predial do cliente ou na área circundante ao imóvel do cliente.', 60, 166, { maxWidth: 45, align: 'left' });
    doc.text('o) Viciação do contador ou utilização de meio fraudulento para consumir água, sem medição adequada;', 60, 174, { maxWidth: 45, align: 'left' });
    doc.text('p) Efectuar venda de água sem consentimento da EPASLN;', 60, 181, { maxWidth: 45, align: 'left' });
    doc.text('4- A suspensão do fornecimento por causas imputáveis ao consumidor não o isenta do pagamento da Tarifa fixa se o contador não tiver sido retirado ou o contrato rescindido;', 60, 186, { maxWidth: 45, align: 'left' });
    doc.text('5- A suspensão do fornecimento de água por parte da EPASLN, por falta de pagamento das contas de consumo ou por outras dívidas relacionadas com o abastecimento deve ser antecedida de advertência ao consumidor, com antecedência mínima de 2 dias da data prevista para a efectivação da suspensão do abastecimento.', 60, 195, { maxWidth: 45, align: 'left' });

    doc.setFontType("bold");
    doc.text('Artigo 7º', 60, 211.5);
    doc.text('Supressão de Ramal', 60, 213.5);
    doc.setFontType("normal");
    doc.text('A supressão do ramal de abastecimento de água e ou ramal de ligação de águas residuais pode ser objecto de supressão nas seguintes situações:', 60, 216, { maxWidth: 45, align: 'left' });
    doc.text('1- Ligação clandestina;', 60, 223, { maxWidth: 45, align: 'left' });
    doc.text('2- Demolição ou ruína;', 60, 225, { maxWidth: 45, align: 'left' });
    doc.text('3- Sinistro;', 60, 228, { maxWidth: 45, align: 'left' });
    doc.text('4- Quando for comprovada a fusão de duas ou mais economias, que venham a constituir-se numa única economia;', 60, 231, { maxWidth: 45, align: 'left' });
    doc.text('5- Em imóvel desocupado, comprovadamente em condições de falta de habitabilidade;', 60, 238, { maxWidth: 45, align: 'left' });
    doc.text('6- Em imóvel unifamiliar, não condominial, a pedido expresso do cliente ou proprietário, mediante o pagamento de uma remuneração pelo serviço prestado de supressão do ramal predial, além da comprovação, por documento do serviço de vigilância sanitária local de que a ligação de água ao imóvel pode ser interrompida;', 60, 243, { maxWidth: 45, align: 'left' });
    doc.text('7- Quando for utilizado meio fraudulento para o consumo de água e colecta de esgotos;', 60, 260, { maxWidth: 45, align: 'left' });
    doc.text('8- Quando o sistema interior do imóvel tiver sido modificado sem prévia aprovação do seu traçado.', 60, 264.5, { maxWidth: 45, align: 'left' });


    doc.setFontType("bold");
    doc.text('Artigo 8º', 60, 269);
    doc.text('(Leitura, Manutenção e Fiscalização)', 60, 272);
    doc.setFontType("normal");
    doc.text('As leituras dos contadores serão efectuadas periodicamente por pessoal ao serviço da EPASLN, devidamente credenciado para o efeito.', 60, 275, { maxWidth: 45, align: 'left' });
    doc.text('1- A leitura do contador será feita, pelo menos, uma vez em cada semestre;', 108, 29, { maxWidth: 45, align: 'left' });
    doc.text('2- Para efeitos de leitura, manutenção/reparação e/ou substituição de contadores, fiscalizar as canalizações, verificar o controlo de qualidade da água de abastecimento, efectuar aberturas e/ou fechos de água e efectuar colheitas de água no âmbito do controlo de qualidade de água distribuída, deve ser permitida a entrada e livre acesso ao contador, por parte do pessoal credenciado da EPASLN;', 108, 34, { maxWidth: 45, align: 'left' });

    doc.setFontType("bold");
    doc.text('Artigo 9º', 108, 56);
    doc.text('(Avaliação dos Consumos)', 108, 58);
    doc.setFontType("normal");
    doc.text('Nos períodos em que não haja leitura, o consumo é estimado:', 108, 60, { maxWidth: 45, align: 'left' });
    doc.text('a) Em função do consumo médio apurado entre as quatro últimas leituras reais efetuadas pela Entidade Gestora;', 108, 64.5, { maxWidth: 45, align: 'left' });
    doc.text('b) Em função do consumo médio de utilizadores com características similares no âmbito do território provincial verificado no ano anterior, na ausência de qualquer leitura subsequente à instalação do contador.', 108, 71.5, { maxWidth: 45, align: 'left' });

    doc.setFontType("bold");
    doc.text('Artigo 10º', 108, 83.5);
    doc.text('(Tarifas)', 108, 86);
    doc.setFontType("normal");
    doc.text('As tarifas a cobrar pela EPASLN constam do Tarifário em vigor, aprovado pela Entidade Competente, devendo as alterações ao mesmo ser publicitadas ao Consumidor;', 108, 89, { maxWidth: 45, align: 'left' });

    doc.setFontType("bold");
    doc.text('Artigo 11º', 108, 99);
    doc.text('(Facturas)', 108, 101);
    doc.setFontType("normal");
    doc.text('As facturas são emitidas com periodicidade mensal.', 108, 103, { maxWidth: 45, align: 'left' });

    doc.setFontType("bold");
    doc.text('Artigo 12º', 108, 106);
    doc.text('(Prazo, Forma e Local de Pagamento)', 108, 108);
    doc.setFontType("normal");
    doc.text('1- A EPASLN indicará na factura a data limite de pagamento;', 108, 110, { maxWidth: 45, align: 'left' });
    doc.text('2- O pagamento da factura deve ser efectuado de acordo com o valor da respectiva factura no prazo, na forma e nos locais nela indicados;', 108, 115, { maxWidth: 45, align: 'left' });
    doc.text('3- Após o pagamento das facturas por depósito ou transferência bancária, deverá o cliente apresentar nas Agências Comerciais da EPASLN o talão de depósito ou comprovativo da transferência bancária efectuada, para efeitos de confirmação de pagamento e validação no sistema.', 108, 122, { maxWidth: 45, align: 'left' });

    doc.setFontType("bold");
    doc.text('Artigo 13º', 108, 137);
    doc.text('(Multas)', 108, 140);
    doc.setFontType("normal");
    doc.text('1- Sempre que por indisponibilidade do Consumidor, se revele por 2 (duas) vezes impossível o acesso ao contador, será aplicada uma multa correspondente a 25% do valor do consumo médio do cliente;', 108, 143, { maxWidth: 45, align: 'left' });
    doc.text('2- Para além das multas e das penalizações descritas nos números anteriores, o cliente pode também estar sujeito a um processo judicial instaurado pela EPASLN contra si, dependendo da gravidade da situação.', 108, 156, { maxWidth: 45, align: 'left' });

    doc.setFontType("bold");
    doc.text('Artigo 14º', 108, 169);
    doc.text('(Reclamações)', 108, 172);
    doc.setFontType("normal");
    doc.text('1- Aos consumidores assiste o direito de reclamar, por qualquer meio, perante a EPASLN, contra qualquer acto ou omissão desta ou dos respectivos serviços ou agentes, que tenham lesado os seus interesses legítimos legalmente protegidos;', 108, 175, { maxWidth: 45, align: 'left' });
    doc.text('2- Os serviços de atendimento ao público dispõem de formulário próprio, nos termos previstos na legislação em vigor, onde os consumidores podem apresentar as suas reclamações;', 108, 188, { maxWidth: 45, align: 'left' });
    doc.text('3- A reclamação é apreciada pela EPASLN no prazo de 20 dias úteis, notificando o reclamante do teor da sua decisão e respectiva fundamentação;', 108, 198, { maxWidth: 45, align: 'left' });
    doc.text('4- A apresentação de reclamação não suspende, exceto o constante no número seguinte, o prazo de pagamento das faturas;', 108, 206, { maxWidth: 45, align: 'left' });
    doc.text('5- A reclamação escrita alegando erros de medição do consumo de água suspende o prazo de pagamento da respetiva fatura caso o utilizador solicite a verificação extraordinária do contador, após ter sido informado da tarifa aplicável;', 108, 213, { maxWidth: 45, align: 'left' });
    doc.text('6- O dever de resposta da entidade gestora cessa perante uma reclamação, do mesmo utilizador, que apresente conteúdo repetido, não tendo qualquer elemento novo ou reiterando conteúdo de reclamação anterior já respondida.', 108, 226, { maxWidth: 45, align: 'left' });

    doc.setFontType("bold");
    doc.text('Artigo 15º', 108, 238);
    doc.text('(Vigência)', 108, 240);
    doc.setFontType("normal");
    doc.text('Os contratos consideram-se em vigor a partir da data de ligação do ramal predial correspondente.', 108, 244, { maxWidth: 45, align: 'left' });

    doc.setFontType("bold");
    doc.text('Artigo 16º', 108, 249);
    doc.text('(Disposições Finais)', 108, 252);
    doc.setFontType("normal");
    doc.text('1- As partes obrigam-se ao cumprimento escrupuloso do presente contrato, assumindo que qualquer introdução de elementos novos no seu conteúdo deverá ser feita de comum acordo;', 108, 255, { maxWidth: 45, align: 'left' });
    doc.text('2- Na data da celebração do contrato, o signatário confirma que tomou conhecimento do Tarifário da EPASLN aplicado ao contrato estabelecido, assim como das cláusulas contratuais.', 108, 265, { maxWidth: 45, align: 'left' });

    doc.setFontType("bold");
    doc.text('Artigo 17º', 160, 29);
    doc.text('(Foro)', 160, 32);
    doc.setFontType("normal");
    doc.text('1- Em caso de eventuais litígios, as partes primarão pela resolução amigável;', 160, 35, { maxWidth: 40, align: 'left' });
    doc.text('2- Na impossibilidade do preceituado no número anterior é eleito o Tribunal Provincial da Lunda Norte para a resolução dos referentes litígios com renuncia expressa a qualquer outro foro.', 160, 40, { maxWidth: 40, align: 'left' });

    doc.line(10, doc.internal.pageSize.height - 9, 194, doc.internal.pageSize.height - 9); // vertical line

    numberPage = 2
    doc.setFontType("bold");

    doc.line(10, doc.internal.pageSize.height - 9, 194, doc.internal.pageSize.height - 9); // vertical line
    doc.text('Página: ' + numberPage + '/2', 180, 285);

    doc.text("NIF: " + empresa.taxRegistrationNumber + " - " + empresa.companyName + " / " + empresa.addressDetail + " / " + (empresa.telefone == null ? '' : empresa.telefone) + " / " + (empresa.email == null ? '' : empresa.email), 105, doc.internal.pageSize.height - 6, null, null, 'center');
    doc.setFontSize(7);
    doc.text('Processado por programa validado nº 4/AGT119', 105, doc.internal.pageSize.height - 3, null, null, 'center');

    if (report == 'imprimir') {
      doc.autoPrint();
      window.open(doc.output("bloburl")); //opens the data uri in new window
    } else {
      doc.save(contrato.id + '.pdf'); /* download the file immediately on loading */
    }
  }
  public imprimirContratoV2(item, report = 'imprimir') {

    console.log({ item })

    const contrato = item.contrato;
    const tipo_identidades = item.tipo_identidades;
    const empresa = item.empresa;

    var img_logotipo = empresa.logotipo;
    var doc = new jsPDF()

    doc.addImage(img_logotipo, 'JPEG', 5, 4, 50, 20)
    doc.setFontSize(7)
    doc.setFontStyle('normal')
    doc.text(10, 25, '' + (empresa.companyName || ''))
    doc.text(10, 30, '' + (empresa.addressDetail || ''))
    doc.text(10, 35, 'NIF')
    doc.text(10, 40, 'Telefone')

    doc.text(40, 35, '' + (empresa.taxRegistrationNumber || ''))
    doc.text(40, 40, '' + (empresa.telefone || ''))
    //doc.text(60, 40, 'Fax:')
    doc.setFontSize(12)

    doc.setFillColor(255);
    doc.rect(122, 8, 80, 15, 'B');
    doc.setFontSize(7)
    doc.setFontStyle('bold')
    doc.text(125, 15, 'Nº CLIENTE')
    doc.text(125, 20, '' + (contrato.cliente_id || ''))
    doc.text(175, 15, 'Nº: CONTADOR')
    doc.text(175, 20, '' + (contrato.contador_id || ''))

    doc.setFontSize(8)
    doc.text(12, 60, 'CONTRATO DE LIGAÇÃO, FORNECIMENTO DE ÁGUA E INSTALAÇÃO DE CONTADOR')

    doc.setFontSize(5)
    doc.setFontStyle('normal')
    doc.text(12, 64, 'IDENTIFICAÇÃO DO CLIENTE')

    doc.setFillColor(255);
    doc.rect(12, 66, 190, 15, 'B');

    doc.setFontSize(7)
    doc.setFontStyle('normal')
    doc.text(14, 70, 'Nome ' + contrato.cliente_nome.toUpperCase())
    doc.text(14, 73, 'Portador do BI -, nº fiscal 50000030 celebra com a Empresa Pública de Água, um contrato de fornecicmento de água para o local e nos temos abaixo indicados e cláusulas definidas no verso do presente contrato às quais adere.', { maxWidth: 175, align: "justify" })

    doc.text(14, 86, 'TIPO DE CONTADOR')
    doc.setFillColor(255);
    doc.rect(12, 88, 190, 25, 'B');
    doc.text(14, 91, 'A Empresa Pública de Água, establece co o consumidor acima mencionado um contrato de ligação e fornecimento de água e colocação de contaor do tipo.')

    doc.setFillColor(255);
    doc.rect(25, 97, 5, 5, 'B');
    doc.text(32, 100, 'Doméstico')
    doc.setFillColor(255);
    doc.rect(55, 97, 5, 5, 'B');
    doc.text(62, 100, 'Comércio/Serviço')
    doc.setFillColor(255);
    doc.rect(85, 97, 5, 5, 'B');
    doc.text(92, 100, 'Obras')
    doc.setFillColor(255);
    doc.rect(110, 97, 5, 5, 'B');
    doc.text(118, 100, 'Industria')
    doc.setFillColor(255);
    doc.rect(140, 97, 5, 5, 'B');
    doc.text(148, 100, 'Público')
    doc.setFillColor(255);
    doc.rect(25, 105, 5, 5, 'B');
    doc.text(32, 108, 'Outro a especificar')

    doc.text(14, 118, 'ENDEREÇO PARA LIGAÇÃO E INSTALAÇÃO')
    doc.setFillColor(255);
    doc.rect(12, 122, 190, 25, 'B');

    doc.text(14, 125, 'Zona: ')
    doc.text(14, 130, 'Bairro: ')
    doc.text(14, 135, 'Rua: ')
    doc.text(14, 140, 'Telefone: ')
    doc.text(14, 145, 'Cód. Postal: ')

    doc.text(54, 125, 'Corporate-Cacuaco')
    doc.text(54, 130, 'BAIRRO MULEMBA')
    doc.text(54, 135, 'RUA DIRETITA DE CACUACO')
    doc.text(54, 140, '943 322 455')
    doc.text(54, 145, '0000')

    doc.text(94, 125, 'Zona: ')
    doc.text(94, 130, 'Bairro: ')
    doc.text(94, 135, 'Rua: ')
    doc.text(94, 140, 'Telefone: ')
    doc.text(94, 145, 'Cód. Postal: ')

    doc.text(110, 125, 'Área 30')
    doc.text(110, 130, '---------')
    doc.text(110, 135, '---------')
    doc.text(110, 140, '933 432 221')
    doc.text(110, 145, '--------')

    doc.text(14, 152, 'DECLARAÇÕES')
    doc.setFillColor(255);
    doc.rect(12, 153, 190, 70, 'B');
    doc.text(14, 157, 'Neste acto o consumidor declara agir na qualidade de :')

    doc.setFillColor(255);
    doc.rect(25, 164, 5, 5, 'B');
    doc.text(32, 168, 'Proprietário')
    doc.setFillColor(255);
    doc.rect(65, 164, 5, 5, 'B');
    doc.text(72, 168, 'Arrendatário')
    doc.setFillColor(255);
    doc.rect(108, 164, 5, 5, 'B');
    doc.text(115, 168, 'Usofrutuário')

    doc.setFillColor(255);
    doc.rect(150, 164, 5, 5, 'B');
    doc.text(158, 168, 'Promitente comprador do imóvel')

    doc.text(14, 173, `Declaro que pretende contratar com a Empresa de Águas, ${empresa.sigla_empresa}, nas condições especificadas referidas e expressas neste contrato de fornecimento de água e instalação de contador, cumprir com as demais e legislação em vigor e que tomei conhecimento e aceito`, { maxWidth: 175, align: "justify" })
    doc.text(14, 180, 'Declaro ainda dob comprimisso de honra, que :')
    doc.text(14, 185, 'a) As declarações por mim produzidas, correnspondem a verdade.')
    doc.text(14, 190, 'b) Assumo todas as responsabilidades inerentes à prestação das mesmas n âmbito do presente contrato.')
    doc.text(14, 195, 'c) Tenho conhecimento que a prestação de falsas declarações implicará a participação às entidades compotetentes para efeito de procedimentos penal.')

    doc.setFontSize(7)
    doc.text(44, 202, 'O Cliente.')
    doc.text(14, 205, '_________________________________________________')
    doc.text(14, 210, '(ASSINATURA CONFORME DOCUMENTO DE IDENTIFICACAÇÃO)')

    doc.text(100, 205, 'Data: _____/_____/_____')

    doc.text(14, 228, `ASSINATURAS (Reservado ${empresa.sigla_empresa})`)
    doc.setFillColor(255);
    doc.rect(12, 230, 190, 20, 'B');

    doc.text(30, 240, `Em represantação da ${empresa.sigla_empresa}.`)
    doc.text(14, 242, '_________________________________________________')

    doc.text(100, 242, 'Data: _____/_____/_____')

    doc.setFontStyle('bold')
    doc.text(12, 255, 'Este contrato só é válido com a assinatura do Director Comecial dou chefe  da Agência Comercial, certificada com o respetivo carimbo em uso.', { maxWidth: 175, align: "justify" })

    doc.addPage()
    doc.setFontSize(8)
    doc.text(12, 10, 'ANEXOS')
    doc.text(12, 15, 'CLÁUSULAS GERIAS')

    doc.setFontSize(7)
    doc.text(12, 25, 'Cláusula 1ª')
    doc.text(12, 28, 'Objectivo do contrato')

    doc.setFontStyle('normal')
    doc.text(12, 32, `1. O presente contrato tem por objectivo o fornecimento de água potável canalizada por parte da ${empresa.sigla_empresa} ao consumidor contraente através de contadores, devidamente selados e por si instalados.`, { maxWidth: 105, align: "justify" })
    doc.text(12, 40, `2. Os contadores são colocados em local escolhido pela ${empresa.sigla_empresa}, acessível a uma facil leitura regular, com protecção adequada a sua eficiente conservação e normal fucnonamento, não podendo ser mudados de local, em quaisquer circusntências, pelo consumidor.`, { maxWidth: 105, align: "justify" })
    doc.text(12, 50, `3. Os contadores instalados são propriedades da ${empresa.sigla_empresa}.`)
    doc.text(12, 53, '4. O consumidor pagará uma taxa pela utilização do contador, que lhe será adicionada mensalmente na sua factura de consumo de água.', { maxWidth: 100, align: "justify" })
    doc.text(12, 59, '5. A taxa que se refere o número anteriror será cobrada de acordo ao tipo de cliente.')


    doc.setFontStyle('bold')
    doc.text(12, 75, 'Cláusula 2ª')
    doc.text(12, 78, 'Duração do contrato')

    doc.setFontStyle('normal')
    doc.text(12, 85, '1. O contrato será por tempo indeterminado')

    doc.setFontStyle('bold')
    doc.text(12, 92, 'Cláusula 3ª')
    doc.text(12, 95, 'Deveres do consumidor')

    doc.setFontStyle('normal')
    doc.text(12, 100, '1. Os consumidores estão obrigado a:')
    doc.text(12, 103, `a. Cumprir  as disposições do contrato em viIgor relacioandoas com os sistemas públicos e predias de distribuição de água bem como as recomendações e orientações dadas pela ${empresa.sigla_empresa};`, { maxWidth: 107, align: "justify" })
    doc.text(12, 111, 'b. Pagar pontualmente a importancia devida, nos termos do presente contrato;', { maxWidth: 110 })
    doc.text(12, 114, 'c. Não fazer uso indevido ou danificar qualquer obra ou equipamento dos sistemas públicos;', { maxWidth: 110, align: "justify" })
    doc.text(12, 117, 'd. Não proceder à execução de ligações ao sistema público sem autorização da entidade gestora;', { maxWidth: 105, align: "justify" })
    doc.text(12, 122, 'e. Não alterar o ramal de ligação de água de abastecimento estabelecido entre a rade geral e a rede predial;', { maxWidth: 110, align: "justify" })
    doc.text(12, 127, 'f. Abster-se de actos que possam provocar a contamninação de água;', { maxWidth: 110, align: "justify" })
    doc.text(12, 130, `g. Infomar a ${empresa.sigla_empresa} sempre que se aperceba que o contador apresenta algum problema;`, { maxWidth: 110, align: "justify" })
    doc.text(12, 133, `h. Cooperar com a ${empresa.sigla_empresa}, para o bom funcionamento do sistema;`, { maxWidth: 110, align: "justify" })

    doc.setFontStyle('bold')
    doc.text(12, 138, 'Cláusula 4ª')
    doc.text(12, 142, 'Rescisão do contrato')

    doc.setFontStyle('normal')
    doc.text(12, 148, `1. A rescisão do cantrato deve obrigatoriamente comunicada pelo consumidodor à ${empresa.sigla_empresa} com, palo menos, 30 dias antecedência sobre a data da respectiva produção de efeitos.;`, { maxWidth: 102, align: "justify" })
    doc.text(12, 154, `2. O consumidor mandem-se obrigado ao pagamento do aluguer do contador até que este seja retirado pela ${empresa.sigla_empresa}, bem como ao pagamento do fornecimento de água a que se refere o nº 5 de cláusula 10 ;`, { maxWidth: 102, align: "justify" })


    doc.setFontStyle('bold')
    doc.text(12, 168, 'Cláusula 5ª')
    doc.text(12, 172, 'Continuidade e suspensão do fornecimento')

    doc.setFontStyle('normal')
    doc.text(12, 175, '1. O fornecimento de água é permanente e continuo, ressalvados os seguintes canoa de suspensão do fornecimento.;', { maxWidth: 102, align: "justify" })

    doc.text(12, 181, 'a. Exigência: da serviço publico;', { maxWidth: 102, align: "justify" })
    doc.text(12, 184, 'b. Mairlarraia de avarias ou obras mu instalações doa redes gerais de distribuição ou nas canaliaaça129 anteriores;', { maxWidth: 102, align: "justify" })
    doc.text(12, 190, 'c. Casos de força maior;', { maxWidth: 102, align: "justify" })
    doc.text(12, 193, 'd. Actos imputáveis ao consumidor;', { maxWidth: 102, align: "justify" })

    doc.text(12, 197, `2. Nas situações de suspeitado por exiaancias de serviço publico ou por obras nas instalações das redes parais do disttibuiçSío a "itapema) do fornecimento será anunciada pela ${empresa.sigla_empresa}, publicamente, com a antecedência mínima de 24 horas.`, { maxWidth: 102 })
    doc.text(12, 207, '3. Para RB atuações de suspensão do fornecimento de água por existência de avarias nas redimo gerais de distribuição ou nas canalizações interiores, o anúncio prévio com antecedência mínima, só será feito caso aravidaela da avaria o permita.', { maxWidth: 102 })
    doc.text(12, 216, '4. São consideradas actos imputáveis ao consinnidur na suspensão do fornecimento: a Falta de condições de salubridade das canalizações interiores.', { maxWidth: 102, align: "justify" })

    doc.text(12, 222, 'a. Falta de condições de salubridade das canalizações interiores;', { maxWidth: 102, align: "justify" })
    doc.text(12, 225, 'b. Falta de pagamento das contas de consuma ou outras dividas relacionadas como abastecimento ou coam o contrato de fornecimento;', { maxWidth: 102, align: "justify" })
    doc.text(12, 231, `c. Recusa da entrada do funcionara-, da ${empresa.sigla_empresa} detidamente credenciado para inspecção da. .ainalia.açam interiores ou para leitura, verificação, substituição ou levantamento da contador;`, { maxWidth: 102, align: "justify" })
    doc.text(12, 240, `d. Viciação do ramtadar ou utilização de meio fraudulento para emanare água; o	Efectuar a venda cie água sem o consentimento da ${empresa.sigla_empresa}; E Qualquer outro acto que lese as normas deste contrato;`, { maxWidth: 102, align: "justify" })
    doc.text(12, 249, `e. Efectuar a venda cie água sem o consentimento da ${empresa.sigla_empresa};`, { maxWidth: 102, align: "justify" })
    doc.text(12, 252, 'f. Qualquer outro acto que lese as normas deste contrato;', { maxWidth: 102, align: "justify" })

    doc.text(12, 256, '5. A suspensão do fornecimento pra causas imputáveis ao consumidor não o isenta do pagamento do aluguer do contador se este não for retirado, nem do pagamento dos prejuizos, danos e coimai; a que haja dado causa.', { maxWidth: 102, align: "justify" })
    doc.text(12, 265, `6. A anispansão do fornecimento da ${empresa.sigla_empresa} por falta de pagamento das contar) de consuma ou par outras dívidas relacionadas com o abastecimento ou com o contrato de fornecimento é obrigatoriamente antecedida de advertência notificada por escrito ao consumidor, com a antacedência mínima de 8 dias relataawarite a data em que a suspensão venha a ter lugar.`, { maxWidth: 102 })

    doc.setFontStyle('bold')
    doc.text(12, 282, 'Cláusula 6ª')
    doc.text(12, 285, 'Leitura')

    doc.setFontStyle('normal')
    doc.text(12, 290, '1. A leitura do contador será feita, pela meros, uma vez em cada semestre')

    doc.text(130, 20, `2. Para efeitos de leitura ou verificação, deve ser facultado ao pessoal credenciado da ${empresa.sigla_empresa} o livre acesso ao contador`, { maxWidth: 75, align: "justify" })
    doc.text(130, 27, `3. A leitura ou verifiaação será efectuada dentro do horário normal de trabalho ou em horário diferente quando acordado entra a ${empresa.sigla_empresa} e o consumidor`, { maxWidth: 75, align: "justify" })
    doc.text(130, 36, '4. Quando por motivo imputável ao consumidor não tardia sido efectuada a leitura do contador a-lhe aplicável um valor por estimativa relativamente ao valor do consumo do período anterior acrescida de uma multa de 25% nobre o mesmo valor', { maxWidth: 75, align: "justify" })

    doc.setFontStyle('bold')
    doc.text(130, 50, 'Cláusula 7ª')
    doc.text(130, 53, 'Determinação do consumo por estimativa')

    doc.setFontStyle('normal')
    doc.text(130, 58, '1. Na contagem por defeito ou por excesso, o 00/1811M0 será avaliado em funsao da média a partir dos elementos estatísticos existentes relativos nos consumos em e01.159 ( agregado familiar, existência de reservatórios a montante do contador, ra de casas de banho, moa.)', { maxWidth: 75, align: "justify" })
    doc.text(130, 72, '2. A estimativa da cannuno será feita através de contagem por defeito por contagem por excesso', { maxWidth: 75, align: "justify" })

    doc.setFontStyle('bold')
    doc.text(130, 77, 'Cláusula 8ª')
    doc.text(130, 82, 'Preços')

    doc.setFontStyle('normal')
    doc.text(130, 86, `A ${empresa.sigla_empresa} informará aos consumidores dos preços da água formicida e que serão os constantes dei sistema tarifaria aprovado pela entidade competente.`, { maxWidth: 70, align: "justify" })


    doc.setFontStyle('bold')
    doc.text(130, 97, 'Cláusula 9ª')
    doc.text(130, 102, 'Facturas')

    doc.setFontStyle('normal')
    doc.text(130, 105, 'As facturas são emitidas com periodicidade mensal.', { maxWidth: 70, align: "justify" })

    doc.setFontStyle('bold')
    doc.text(150, 110, 'Cláusula 10ª')
    doc.text(150, 113, 'Pagamentos')

    doc.setFontStyle('normal')
    doc.text(130, 117, `1. A ${empresa.sigla_empresa} indicara na factura a data limite do pagamento.`, { maxWidth: 75, align: "justify" })
    doc.text(130, 120, `2. O pagamento da factura deve ser efectuado de acordo com o valor da respectiva factura dentro do prazo limite de pastartento, noa locais especificamente definidos para o efeito pela ${empresa.sigla_empresa}.`, { maxWidth: 75, align: "justify" })
    doc.text(130, 132, `3. A liquidação da factura poderá ser feita nas instalações aentrais da ${empresa.sigla_empresa}, ou em qualquer das suas agências caruma-as, nulo-agência e postos de cobrama existentes ou ainda por depósito bancário numa das cantas domiciliadas soe traguintes bancos:`, { maxWidth: 75, align: "justify" })

    doc.text(130, 148, 'BAI conta nº A0060040000000132525910196', { maxWidth: 70, align: "justify" })
    doc.text(130, 151, 'RCA conta nº A006004300000155394110111', { maxWidth: 70, align: "justify" })
    doc.text(130, 154, 'BCI conta nº A046000500000296888010197', { maxWidth: 70, align: "justify" })
    doc.text(130, 157, 'BCS conta nº A0060070000900537144101427', { maxWidth: 70, align: "justify" })


    doc.setFontStyle('bold')
    doc.text(130, 162, 'Cláusula 11ª')
    doc.text(130, 165, 'Multa')

    doc.setFontStyle('normal')
    doc.text(130, 169, '1. Para além das penalizações imputáveis as cliente, previstas nos números anteriores está tamban a cliente sujeito ao pagamento de urna multa sempre que se verifiquem situações de esbanjamento ou desperdício de água por parta desde, danificação do contador ou da rede pública de água.', { maxWidth: 75, align: "justify" })
    doc.text(130, 184, '2. O valor da multa varia conforme o valor do dana', { maxWidth: 75, align: "justify" })
    doc.text(130, 188, `3. Para aléni das multas nos números anteriores, o cliente pode também estar aujaito a um processo judicial inetatuado pela ${empresa.sigla_empresa} entra si, dependendo da gravidade da situação`, { maxWidth: 75, align: "justify" })

    doc.setFontStyle('bold')
    doc.text(130, 198, 'Cláusula 12ª')
    doc.text(130, 202, 'Viga/leia')

    doc.setFontStyle('normal')
    doc.text(130, 206, 'O contrata coserdes-se em vigor a partir da data da sua assinatura, ou da hgaçao.', { maxWidth: 75, align: "justify" })


    doc.setFontStyle('bold')
    doc.text(130, 213, 'Cláusula 13ª')
    doc.text(130, 216, 'Disposições finais do contrato')

    doc.setFontStyle('normal')
    doc.text(130, 220, '1. Na acto da assinatura do contrato, no impedimento do EXMO, Sr. Director Comercial e do Chefe da Agência Comercial, o miemo poderá ser assinado pelo Director Geral Adjunto.', { maxWidth: 75, align: "justify" })
    doc.text(130, 232, '2. As partes acordem que a introdução unilateral de qualquer elemento novo no presente contrato constitua em relação a outra parta um atributo na base da qual poderá rescindir incondicionalmente O contrato após um prévio aviso.', { maxWidth: 75, align: "justify" })
    doc.text(130, 244, '3. O cliente nau deverá ceder em todo ou em parte os direitos ou obrigações que lhe advém do presente contrato sem que tenha sido previamente autorizado por escrito pelo fornecedor.', { maxWidth: 75, align: "justify" })
    doc.text(130, 253, '4. As partes obrigam-se ao cumprimento escrupuloso do presente contrato, assumindo que qualquer introdução de elementos novos sio seu emaçado deverá ser feita de comum acordo.', { maxWidth: 75, align: "justify" })

    doc.setFontStyle('bold')
    doc.text(130, 266, 'Cláusula 14ª')
    doc.text(130, 270, 'Disposições finais do contrato')

    doc.setFontStyle('normal')
    doc.text(130, 274, '1. Em caso de eventuais litígios, as partas primarao pela resolução amigável.', { maxWidth: 75, align: "justify" })
    doc.text(130, 280, '2. Na impossibilidade do preceituado no número anterior é eleito o tribunal provincial de Luanda para a resolução dos referentes litigios com renuncia expressa a qualquer outro foro.', { maxWidth: 75, align: "justify" })

    //numberPage = 2
    doc.setFontType("bold");

    //doc.line(10, doc.internal.pageSize.height - 9, 194, doc.internal.pageSize.height - 9); // vertical line
    //    doc.text('Página: ' + numberPage + '/2', 180, 285);

    //doc.text("NIF: " + empresa.taxRegistrationNumber + " - " + empresa.companyName + " / " + empresa.addressDetail + " / " + (empresa.telefone == null ? '' : empresa.telefone) + " / " + (empresa.email == null ? '' : empresa.email), 105, doc.internal.pageSize.height - 6, null, null, 'center');
    doc.setFontSize(7);
    //doc.text('Processado por programa validado nº 4/AGT119', 105, doc.internal.pageSize.height - 3, null, null, 'center');

    if (report == 'imprimir') {
      doc.autoPrint();
      window.open(doc.output("bloburl")); //opens the data uri in new window
    } else {
      doc.save(contrato.id + '.pdf'); /* download the file immediately on loading */
    }
  }
}
