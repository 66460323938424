import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Select2OptionData } from 'ng2-select2';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

@Component({
  selector: 'app-produto',
  templateUrl: './produto.component.html',
  styleUrls: ['./produto.component.css']
})

export class ProdutoComponent implements OnInit {

  @ViewChild("loadding") loadding;
  
  private produto = {
    nome: null,
    valor: null,
    quantidade: null,
    barcode: null,
    tipo: null,
    imposto: null,
    moeda_id:null,
    is_trigger:null,
    observacao:null
  }
  private items: any = [];
  private adidcionado = false;

  options = [
    { name: "Artigo", value: 1 },
    { name: "Servico", value: 2 }
  ]
  imponstos = [
    { id: 0, descricao: null }
  ]

  private alert = {
    message: null,
    show: false,
    class: ''
  }

  public barcode = false;

  constructor(private http: HttpService, private toastr: ToastrService, private configService: ConfigService) { }

  ngOnInit() {
    this.configService.listarMoedas(); 
    this.getImposto();
  }

  private register(e) {
    this.loadding.nativeElement.classList.remove("d-none");
    e.preventDefault(); 
    if (this.produto.nome ==null || this.produto.valor == null || this.produto.imposto ==null || this.produto.moeda_id ==null || this.produto.is_trigger==null || this.produto.tipo==null) {
      this.alerts('É obrigatório fornecer o nome,  tipo, valor, imposto, moeda, Executar Trigger', "alert-danger");
    } else {

      if (!this.produto.barcode) {
        if (this.produto.tipo == 'Artigo') {
          this.alert.message = "É obrigatório fornecer o código de barra do produto";
          this.alert.show = true;
          setTimeout(() => {
            this.alert.show = false;
          }, 5000);
          this.loadding.nativeElement.classList.add("d-none");
          return false;
        } else {
          this.produto.quantidade = null;
        }
      }

      this.http.__call('artigo/create', this.produto).subscribe(
        res => {
          this.loadding.nativeElement.classList.add("d-none");
          this.alerts(Object(res).message, 'alert-success');
          this.listarProdutos(Object(res).data);
        }
      );

  }

    this.loadding.nativeElement.classList.add("d-none");
  }

  private showBarcode() {
    if (this.produto.tipo == 'Artigo') {
      this.barcode = true;
    } else {
      this.barcode = false;
      this.produto.barcode = null;
      this.produto.quantidade = null;
    }

  }

  private generateAlert() {
    this.toastr.success('Hello world!', 'Toastr fun!');
  }

  private listarProdutos(data: any) {
    this.adidcionado = true;
    this.items.push(data);
  }

  /**
   * @name "get Retornar Impostos"
   * @descriptio "Esta Função permite Listar todos imposto"
   * @author "vitorino.pedro@itgest.ao.co"
   */

  private getImposto() {

    this.http.__call('imposto/getall', null).subscribe(
      data => {
        var count = Object.keys(data).length;
        for (var i = 0; i < count; i++) {
          this.imponstos[i] = data[i];
        }
      }
    );
  }
  
  private alerts(message, classs) {
    this.alert.message = message;
    this.alert.class = classs;
    this.alert.show = true;
    setTimeout(() => {
      this.alert.show = false;
    }, 5000);
  }

}