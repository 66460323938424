import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange, NgModule } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpService } from 'src/app/providers/http/http.service';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { first } from 'rxjs/operators';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-create-or-edit-provincia',
  templateUrl: './create-or-edit-provincia.component.html',
  styleUrls: ['./create-or-edit-provincia.component.css']
})
export class CreateOrEditProvinciaComponent implements OnInit {

  public currentUser: any;
  @Input() title: string = "Registar Província";
  @Input() listagem: Function ;

  @Input() provincia = {
    id: null,
    nome: null,
    abreviatura: null,
    is_active: null,
    user_id: null,
  };

  @Input() provincias: any = [];

  @Input() showCreateProvincia: boolean = true;
  @Input() provinciaModal: boolean = false;


  @Input() simpleFormProvincia: FormGroup;

  submitted = false;
  private loading: boolean = false;

  //@Output() private loadListProvincia = new EventEmitter<any>();

  constructor(private auth: AuthService, private http: HttpService, private configService: ConfigService, private formBuilder: FormBuilder) {
    this.createForm();/*
    this.currentUser = this.auth.currentUserValue; */
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
  }


  ngOnInit() {

    this.provincia.user_id = this.currentUser.user.id;


  }
  createForm() {
    this.simpleFormProvincia = this.formBuilder.group({
      index: [{ value: null, disabled: false }],
      nome: [null, Validators.required],
      abreviatura: [null, Validators.required],
      is_active: [{ value: null, disabled: false }],
      user_id: [null, Validators.required]
    });
  }

  get f() {
    return this.simpleFormProvincia.controls;
  }
  onReset() {
    this.submitted = false;
    this.simpleFormProvincia.reset();
    this.provincia.id = null
  }


  onSubmit() {

    this.submitted = true;
    // parar aqui se o formulário for inválido
    if (this.simpleFormProvincia.invalid) {
      return;
    }

    this.loading = true;
    this.resetUser(this.currentUser.user.id)
    const index = this.simpleFormProvincia.getRawValue().index;

    // TODO: usado para fazer a requisição com a api de criação de objsct or update
    const uri = (index === null ? 'provincia/create' : 'provincia/update/' + index);
    this.createOrEdit(uri, this.simpleFormProvincia, (index === null ? true : false));

  }

  createOrEdit(uri: any, formulario: FormGroup, isCreate: boolean) {


    // TODO: usado para fazer a requisição com a api de criação de object
    this.http.__call(uri, formulario.value).pipe(first()).subscribe(
      response => {
        this.submitted = false;
        this.loading = false;

        if (Object(response).code == 200) {
          this.openEndProvinciaModal(true);
          this.reloadProvincias();
        }

         if (isCreate) {
          formulario.reset();
        }

        //this.provincias = Object(response).data;
      },
      error => {
        this.submitted = false;
        this.loading = false;
      });
  }


  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {


    if (this.provincia.id > 0) {
      this.title = "Editar Província";
      this.simpleFormProvincia.patchValue({
        index: this.provincia.id,
        nome: this.provincia.nome,
        abreviatura: this.provincia.abreviatura,
        is_active: this.provincia.is_active,
        user_id: this.provincia.user_id
      });

    } else {
      this.onReset()
      this.title = "Registar Província";
      this.simpleFormProvincia.patchValue({
        user_id: this.currentUser.user.id,
      });

    }

  }


  private reloadProvincias() {

/*     this.http.call_get('provincia/listagem/', null).subscribe(
      response => {

        this.provincias = Object(response).data;
      }
    ); */
  }


  private openEndProvinciaModal(flag : boolean) : void {
    this.provinciaModal = flag;
  }

  public loadListEstabelecimentos(provincias) {
    this.provincias = provincias;
  }
  resetUser(id){
  this.simpleFormProvincia.patchValue({
    user_id: id,
  })
}

}
