import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-detalhe-agendamento',
  templateUrl: './detalhe-agendamento.component.html',
  styleUrls: ['./detalhe-agendamento.component.css']
})
export class DetalheAgendamentoComponent implements OnInit {

  @ViewChild("search") search;

  private contas: any = [];
  private contratos: any = [];
  routerParamAgendamentoId: any;

  private associacao_cliente = null;

  private agendamento = {
    id: null,
    data_previsao_inicio: null,
    fim_execucao: null,
    inicio_execucao: null,
    data_previsao_fim: null,
    dif_dias_prevista: null,
    dif_horas_prevista: null,
    dif_minutos_prevista: null,
    dif_dias_definida: null,
    dif_horas_definida: null,
    dif_minutos_definida: null,
    descricao: null,
    ordem_trabalho_codigo: null,
    descricao_intervencao: null,
    codigo_agendamento: null
  }

  private pagination = {
    start: 1,
    end: 10,
    search: null
  };

  currtentPage: number

  constructor(private auth: AuthService, private http: HttpService, private route: ActivatedRoute, private router: Router, private configService: ConfigService) {
    //this.route.params.subscribe(params => this.currtentPage = params['page']);
  }

  ngOnInit() {
    this.getRouterParamId();

  }

  public getRouterParamId() {

    this.routerParamAgendamentoId = this.route.snapshot.params.agendamento_id;
    this.getConsumiveisByAgendamento(this.routerParamAgendamentoId)
    this.getAgendamentoById(this.routerParamAgendamentoId )
  }


  private getConsumiveisByAgendamento(id) {
    this.http.call_get('ordemservico/consumiveis-by-agendamento/' + id, null).subscribe(
      response => {

        this.consumiveisSelecionados = Object(response).data;
      }
    );
  }

  private getColaboradoresByAgendamento(id) {
    this.http.call_get('ordemservico/colaboradores-by-agendamento/' + id, null).subscribe(
      response => {

        this.recursosSelecionados = Object(response).data;
      }
    );
  }

  private getAgendamentoById(id) {
    this.http.call_get('ordemservico/agendamento-by-Ordem/' + id, null).subscribe(
      response => {

        this.agendamento.ordem_trabalho_codigo = Object(response).data.res.ordem_trabalho_codigo;
        this.agendamento.data_previsao_inicio = Object(response).data.res.data_previsao_inicio;
        this.agendamento.data_previsao_fim = Object(response).data.res.data_previsao_fim;
        this.agendamento.dif_dias_prevista = Object(response).data.res.dif_dias_prevista;
        this.agendamento.dif_horas_prevista = Object(response).data.res.dif_horas_prevista;
        this.agendamento.dif_minutos_prevista = Object(response).data.res.dif_minutos_prevista;
        this.agendamento.descricao = Object(response).data.historico ? Object(response).data.historico.historico_agendamento_descricao : null;
        this.agendamento.id = Object(response).data.res.id;
        this.agendamento.codigo_agendamento = Object(response).data.res.codigo_agendamento;
        this.agendamento.descricao_intervencao = Object(response).data.historico_execucao ? Object(response).data.historico_execucao.descricao_intervencao : null;
       
        this.agendamento.inicio_execucao = Object(response).data.res.inicio_execucao;
        this.agendamento.fim_execucao = Object(response).data.res.fim_execucao;
        this.agendamento.dif_dias_definida = Object(response).data.res.dif_dias_definida;
        this.agendamento.dif_horas_definida = Object(response).data.res.dif_horas_definida;
        this.agendamento.dif_minutos_definida= Object(response).data.res.dif_minutos_definida;
 

      }
    );
  }

  public consumiveisSelecionados = [];
  public recursosSelecionados = [];
  public qtd_stock = 0;


}
