import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';

@Component({
  selector: 'app-report-manutencao',
  templateUrl: './report-manutencao.component.html',
  styleUrls: ['./report-manutencao.component.css']
})
export class ReportManutencaoComponent implements OnInit {

  public empresa: any;

  constructor(private http: HttpService) {
    this.getEmpresa();
  }

  ngOnInit() { }

  private getEmpresa() {
    this.http.call_get('empresa/empresa-user', null).subscribe(
      response => {
        this.empresa = Object(response).data
      }
    )
  }

}
