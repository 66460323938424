import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

@Component({
  selector: 'app-inventario',
  templateUrl: './inventario.component.html',
  styleUrls: ['./inventario.component.css']
})
export class InventarioComponent implements OnInit {
  @ViewChild("search") search;
  @ViewChild("orderBy") orderBy;
  @ViewChild("searchData") searchData;
  @ViewChild("mostrarResultado") mostrarResultado;
  @ViewChild("qtd") qtd;
 
  @ViewChild("serie") serie; 
  private serieId: number;

  
  private movimentos: any = [];
  private pagination = {
    total: null,
    perPage: 5,
    page: 1,
    lastPage: null,

    start: 1,
    end: 10,
    search: "a",
    order: "stoke_movimentos.created_at",
    searchData: ''
  };

  private inventario = {
     artigos: [], 
    serie_id:null
  };

  constructor(private http: HttpService, private configService: ConfigService) { }

  ngOnInit() {
   
    this.listarseries(1, 210); 
    this.produtos(1, 30, null);
    this.listarStockMovimentos(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.searchData.nativeElement.value, this.orderBy.nativeElement.value);
  }

  /**
 * @name "Listar facturação"
 * @descriptio "Esta Função permite Listar todas facturações"
 * @author "caniggia.moreira@itgest.pt"
 * @param start 
 * @param end 
 */
  private listarStockMovimentos(start, end, search, searchData, orderBy) {

    this.configService.loaddinStarter('start');
    this.pages = [];
    this.pagination.start = start;
    this.pagination.end = end;
    this.pagination.search = (search == "" ? "a" : search);
    this.pagination.order = orderBy;
    this.pagination.searchData = searchData;
    this.http.__call('inventario/listar', this.pagination).subscribe(
      response => {
        this.pagination.lastPage = Object(response).data.lastPage;
        this.pagination.page = Object(response).data.page;
        this.pagination.total = Object(response).data.total;
        this.pagination.perPage = Object(response).data.perPage;

        this.movimentos = Object(response).data.data;
        console.log(this.movimentos);
        this.gerarPages();
        this.configService.loaddinStarter('stop');

      }
    );
  }

  private searchStockMovimento(e) {
    this.pagination.page = 1;
    this.listarStockMovimentos(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.searchData.nativeElement.value, this.orderBy.nativeElement.value);

  }

  private mostrarResultados() {
    this.pagination.perPage = this.mostrarResultado.nativeElement.value;
    this.listarStockMovimentos(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.searchData.nativeElement.value, this.orderBy.nativeElement.value);

  }

  private pages = [];
  private nextProvPageNumber = 1;

  private gerarPages() {
    for (var i = 1; i <= this.pagination.lastPage; i++) {
      this.pages.push(i);
    }
  }
  private nextPage() {
    this.nextProvPageNumber++;
    if (this.nextProvPageNumber < 0) {
      this.nextProvPageNumber = this.pagination.page;
    }
    this.listarStockMovimentos(this.nextProvPageNumber, this.pagination.perPage, this.search.nativeElement.value, this.searchData.nativeElement.value, this.orderBy.nativeElement.value);

  }
  private prevPage() {
    this.nextProvPageNumber--
    if (this.nextProvPageNumber > 0) {
      this.listarStockMovimentos(this.nextProvPageNumber, this.pagination.perPage, this.search.nativeElement.value, this.searchData.nativeElement.value, this.orderBy.nativeElement.value);
      this.nextProvPageNumber = this.pagination.page;
    }
  }

  private refreshPaginate(page) {
    this.listarStockMovimentos(page, this.pagination.perPage, this.search.nativeElement.value, this.searchData.nativeElement.value, this.orderBy.nativeElement.value);
  }


  private series: any = [];
  private items_produtos: any = [];

  private produtos(start, end, search) {
    this.configService.loaddinStarter('start');
    this.pagination.start = start;
    this.pagination.end = end;
    this.pagination.search = search;
    this.http.call_get('artigo/search-artigo', this.pagination).subscribe(
      res => {
        this.items_produtos = Object(res).data;
        this.configService.loaddinStarter('stop');
      }
    );
  }

  /**
   * @name "Listar series"
   * @descriptio "Esta Função permite Listar todas series"
   * @author "caniggia.moreira@itgest.pt"
   * @param start 
   * @param end 
   */
  private listarseries(start, end) {
    this.pagination.start = start;
    this.pagination.end = end;
    this.http.__call('serie/listar', this.pagination).subscribe(
      data => {
        data.forEach(element => {
          if (element.sigla == 'INV') {
            this.series.push(element);
          }
        });
      }
    );
  } 


  /**
    * @name "Registar series"
    * @description "Esta Função permite Registar series"
    * @author "caniggia.moreira@itgest.pt"
    * @param start 
    * @param end 
    */
  private register() {
    this.configService.loaddinStarter('start'); 
    this.inventario.artigos = this.produts;
    this.http.__call('inventario/register', this.inventario).subscribe(
      res => {
        if (Object(res).code == 500) {
          this.configService.showAlert(Object(res).message, 'alert-danger', true);
        } else {
          this.inventario.artigos = [];
          this.produts=[];
          this.inventario.serie_id=null
          this.configService.showAlert(Object(res).message, 'alert-success', true); 
          this.listarStockMovimentos(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.searchData.nativeElement.value, this.orderBy.nativeElement.value);
          this.configService.loaddinStarter('stop');
        }
      }
    )
      ;
  }

  private produts: any = [];

  private listarProdutos(response: any) { 
    let validar = 0;
    if (response.code == 400) {
      this.configService.showAlert(response.message, "alert-danger", true);
    } else {
      if (this.produts.length >= 1) {
        for (let index = 0; index < this.produts.length; index++) {
          const produt = this.produts[index];
          if (produt.produto_id == response.produto_id) {

            this.produts.splice(index, 1);
            this.produts.splice(index, 0, response);
            validar = 1;
          }

        }
        if (validar == 0) {
          this.produts.push(response);
        }
      } else {
        this.produts.push(response);
      } 
    }
  }
  private produtoId: number;
  private refreshProdutoId(id) {
    this.produtoId = id;
  }

  getAddProduto() {

    this.http.__call('artigo/pesquisar',
      {
        produto_id: this.produtoId,
        quantidade: (this.qtd.nativeElement.value == "" || isNaN(this.qtd.nativeElement.value) ? 1 : this.qtd.nativeElement.value)
      }
    ).subscribe(
      res => {
        this.listarProdutos(Object(res).data);
        this.qtd.nativeElement.value = ""; 
      }
    );

  }

  private getProduto(e) {
    this.produtos(1, 30, this.search.nativeElement.value);
  }

  
 

  private refreshSerieId() {
    this.inventario.serie_id = this.serie.nativeElement.value; 
  }

  deleteRow(id) { 
    for (let i = 0; i < this.produts.length; ++i) {
      if (this.produts[i].id == id) {
        this.produts.splice(i, 1); 
      }
    }
  }
}
