import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as excelprop from "exceljs";
import * as Excel from "exceljs/dist/exceljs.min.js";

@Injectable({
  providedIn: 'root'
})
export class ContaCorrenteService {

  blobType: string = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

  constructor() { }


  public ExcelExportContaCorrente(Data: any, total, cliente, empresa, excelFileName) {

    //var footer = ["UNIG -[ BWATER]"];
    var header = 'CONTA CORRENTE - ' + cliente;
    var keys = [
      { key: 'contrato', width: 30 },
      { key: 'sigla_documento', width: 40, style: { font: { name: 'Calibri' } } },
      { key: 'data_format', width: 20, style: { numFmt: 'dd/mm/yyyy', } },
      { key: 'observacao', width: 23 },
      { key: 'estado', width: 16 },
      { key: 'debito_exc', width: 20, style: { numFmt: '#,##0.00', } },
      { key: 'credito_exc', width: 20, style: { numFmt: '#,##0.00', } },
      { key: 'valor_aberto', width: 23, style: { numFmt: '#,##0.00', } },
      { key: 'saldo', width: 23, style: { numFmt: '#,##0.00', } },
    ];

    var Cols_FT = ['Contrato' ,'Documento', 'Data','Observação', 'Estado', 'Débito','Crédito', 'Valor Em Aberto','Saldo']
    
    var workbook = new Excel.Workbook();
    workbook.creator = 'Web';
    workbook.lastModifiedBy = 'Web';
    workbook.created = new Date();
    workbook.modified = new Date();
    var imageId1 = workbook.addImage({
      base64: empresa.logotipo,
      extension: 'png',
    });

    workbook.addWorksheet('Conta Corrente', { views: [{ state: 'frozen', ySplit: 6, xSplit: 1, activeCell: 'A6', showGridLines: true }] })
   

    var sheet_FT = workbook.getWorksheet(1);

    sheet_FT.addImage(imageId1, 'A1:A5');
    //sheet_FT.addRow(header);
    sheet_FT.mergeCells('A1', 'J5')
    sheet_FT.getCell('B3').value = header;
    sheet_FT.getCell('B3').font = { family: 4, name: 'Calibri', size: 25, bold: true, underline: true };
    sheet_FT.addRow("");
    sheet_FT.getRow(6).values = Cols_FT;
    sheet_FT.columns = keys;
    sheet_FT.addRows(Data.facturas);


   // let totalRows: number = sheet_FT.rowCount
    //console.log("total number of rows : "+totalRows)
    //totalRows = totalRows + 1;
    //sheet_FT.getCell('H' + totalRows).value = { formula: 'SUM(H7:H' + (totalRows - 1) + ')' };
    //sheet_FT.getCell('I' + totalRows).value = { formula: 'SUM(I7:I' + (totalRows - 1) + ')' };
    //sheet_FT.getCell('J' + totalRows).value = { formula: 'SUM(J7:J' + (totalRows - 1) + ')' };

    sheet_FT.eachRow({ includeEmpty: true }, function (row, rowNumber) {
      row.eachCell(function (cell, colNumber) {
        cell.font = {
          name: 'Arial',
          family: 2,
          bold: false,
          size: 10,
        };
        cell.alignment = {
          vertical: 'middle', horizontal: 'left'
        };
        if (rowNumber <= 6) {
          row.height = 20;
          cell.font = {
            bold: true,
            size: 11,
          };
          cell.alignment = {
            vertical: 'middle', horizontal: 'center'
          };
        }
        if (rowNumber >= 6) {


          cell.alignment = {
            vertical: 'middle', horizontal: 'center'
          };
          if (rowNumber >= 7) {

            row.getCell(6).alignment = {
              vertical: 'middle', horizontal: 'right', 'color': { 'argb': 'FFFF6600' }
            };

            row.getCell(7).alignment = {
              vertical: 'middle', horizontal: 'right', 'color': { 'argb': 'FFFF6600' }
            };
            row.getCell(8).alignment = {
              vertical: 'middle', horizontal: 'right', 'color': { 'argb': 'FFFF6600' }
            };

            row.getCell(9).alignment = {
              vertical: 'middle', horizontal: 'right', 'color': { 'argb': 'FFFF6600' }
            };
          }
          for (var i = 1; i < 10; i++) {
            if (rowNumber == 6) {
              cell.font = {
                color: { argb: 'FFFFFF' },
                bold: true
              };
              row.height = 25;
              row.getCell(i).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '0099FF' }
              };
            }
          /*  if (rowNumber == totalRows) {
              cell.font = {
                bold: true
              };
              row.height = 25;

              sheet_FT.getCell('A' + totalRows).value = "TOTAL";
              sheet_FT.getCell('A' + totalRows).font = { family: 3, name: 'Calibri', size: 11, bold: true };
              sheet_FT.getCell('A' + totalRows).alignment = { vertical: 'middle', horizontal: 'center' };
              // sheet_FT.getCell('G'+totalRows).value = total;
              row.getCell(i).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'CCCCCC' }
              };
            }*/
            row.getCell(i).border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' }
            };
          }
        }
      });
    });

    workbook.xlsx.writeBuffer().then(Data => {
      var blob = new Blob([Data], { type: this.blobType });
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.href = url;
      a.download = excelFileName;
      a.click();
    });


  }

}
