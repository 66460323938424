import { Injectable, EventEmitter } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from 'src/environments/environment'
import { HttpService } from 'src/app/providers/http/http.service'


@Injectable({
  providedIn: 'root'
})

export class RotaService {

  private token = sessionStorage.getItem('sessionToken')
  listRotasHeadersChange = new EventEmitter<any>()

  private headers = new HttpHeaders()
    .set('content-type', 'application/json')
    .set('Access-Control-Allow-Origin', '*')
    .set('Authorization', `Bearer ${this.token}`)

  constructor(
    private http: HttpService,
    private _http_client: HttpClient
  ) { }


  getConfig(slug) {
    return this.http.__call(`configuracao/getConfiguracaobySlug/${slug}`, null)
  }


  uploadDocument(formData: File) {
    return this.http.__call(`api/${environment.apiVersion}/leituras/carregamento_em_massa/`, formData)
  }

  getMunicipio(id: number) {
    return this._http_client.get(`municipio/getMunicipioById/${id}`)
  }

  getLocaisByProvincia(id: number, filters: any) {
    return this.http.__call(`api/${environment.apiVersion}/locais_consumo/getByProvincia/${id}`, filters)
  }

  getLocaisByMunicipio(id: number, filters: any) {
    return this.http.__call(`api/${environment.apiVersion}/locais_consumo/getByMunicipio/${id}`, filters)
  }

  getLocaisByDistrito(id: number, filters: any) {
    return this.http.__call(`api/${environment.apiVersion}/locais_consumo/getByDistrito/${id}`, filters)
  }

  getLocaisByQuarteirao(id: number, filters: any) {
    return this.http.__call(`api/${environment.apiVersion}/locais_consumo/getByQuarterao/${id}`, filters)
  }

  getLocaisByBairro(id: number, filters: any) {
    return this.http.__call(`api/${environment.apiVersion}/locais_consumo/getByBairro/${id}`, filters)
  }

  getLocaisByRua(id: number, filters: any) {
    return this.http.__call(`api/${environment.apiVersion}/locais_consumo/getByRua/${id}`, filters)
  }

  //=================================================================================================

  getPeriodicidades() {
    return this._http_client.get<any>(
      `${environment.app_url}api/${environment.apiVersion}/periodicidade_dos_roteiros`,
      { 'headers': this.headers }
    )
  }

  CreatePeriodicidade(object: any) {
    return this._http_client.post<any>(
      `${environment.app_url}api/${environment.apiVersion}/periodicidade_dos_roteiros`,
      object,
      { 'headers': this.headers }
    )
  }

  getPeriodicidade(id: number) {
    return this._http_client.get<any>(
      `${environment.app_url}api/${environment.apiVersion}/periodicidade_dos_roteiros/${id}`,
      { 'headers': this.headers }
    )
  }

  getPeriodicidadeBySlug(slug: string) {
    return this._http_client.get<any>(
      `${environment.app_url}api/${environment.apiVersion}/periodicidade_dos_roteiros/by_slug/${slug}`,
      { 'headers': this.headers }
    )
  }

  updatePeriodicidade(object: any) {
    return this._http_client.patch<any>(
      `${environment.app_url}api/${environment.apiVersion}/periodicidade_dos_roteiros/${object.id}`,
      object,
      { 'headers': this.headers }
    )
  }

  //=================================================================================================

  getAgendamentos() {
    return this._http_client.get<any>(
      `${environment.app_url}api/${environment.apiVersion}/agendamentos_dos_roteiros`,
      { 'headers': this.headers }
    )
  }

  CreateAgendamento(object: any) {
    return this._http_client.post<any>(
      `${environment.app_url}api/${environment.apiVersion}/agendamentos_dos_roteiros`,
      object,
      { 'headers': this.headers }
    )
  }

  getAgendamento(id: number) {
    return this._http_client.get<any>(
      `${environment.app_url}api/${environment.apiVersion}/agendamentos_dos_roteiros/${id}`,
      { 'headers': this.headers }
    )
  }

  updateAgendamento(object: any) {
    return this._http_client.patch<any>(
      `${environment.app_url}api/${environment.apiVersion}/agendamentos_dos_roteiros/${object.id}`,
      object,
      { 'headers': this.headers }
    )
  }

  updateLeitura(object: any) {
    return this._http_client.patch<any>(
      `${environment.app_url}api/${environment.apiVersion}/leituras/${object.id}`,
      object,
      { 'headers': this.headers }
    )
  }

  validateAnomalia(object: number) {
    return this._http_client.patch<any>(
      `${environment.app_url}api/${environment.apiVersion}/leituras/validate_anomalia`,
      object,
      { 'headers': this.headers }
    )
  }

  getMotivosNaoLeitura() {
    return this._http_client.get<any>(
      `${environment.app_url}api/${environment.apiVersion}/leituras/motivos_nao_leitura`,
      { 'headers': this.headers }
    )
  }


  _closeModal(closeModal) {
    var action = document.getElementById(closeModal);
    action.click();
  }



  CreateLeitura(object: any) {
    return this._http_client.post<any>(
      `${environment.app_url}api/${environment.apiVersion}/leituras/create`,
      object,
      { 'headers': this.headers }
    )
  }

  motivoAlteracaoLeituraCraeteOrUpdate(object: any, id: number) {
    if (id) {
      return this._http_client.put<any>(
        `${environment.app_url}api/${environment.apiVersion}/leituras/motivo_alteracao_leitura/${id}`,
        object,
        { 'headers': this.headers }
      )
    } else {
      return this._http_client.post<any>(
        `${environment.app_url}api/${environment.apiVersion}/leituras/motivo_alteracao_leitura`,
        object,
        { 'headers': this.headers }
      )
    }
  }

  motivoAlteracaoLeitura(object: any) {
    return this._http_client.post<any>(
      `${environment.app_url}api/${environment.apiVersion}/leituras/motivo_alteracao_leituraList`, object, { 'headers': this.headers }
    )
  }
  motivoAlteracaoLeituraForSelect() {
    return this._http_client.get<any>(
      `${environment.app_url}api/${environment.apiVersion}/leituras/motivo_alteracao_leituraList`, { 'headers': this.headers }
    )
  }
  motivoAnomaliaLeitura() {
    return this._http_client.get<any>(
      `${environment.app_url}api/${environment.apiVersion}/motivos_anomalias/index`, { 'headers': this.headers }
    )
  }
  tipoAlteracaoLeituraCraeteOrUpdate(object: any, id: number) {
    if (id) {
      return this._http_client.put<any>(
        `${environment.app_url}api/${environment.apiVersion}/tipo_anomalias/update/${id}`,
        object,
        { 'headers': this.headers }
      )
    } else {
      return this._http_client.post<any>(
        `${environment.app_url}api/${environment.apiVersion}/tipo_anomalias/create`,
        object,
        { 'headers': this.headers }
      )
    }
  }

  tipoAlteracaoLeitura(object: any) {
    return this._http_client.post<any>(
      `${environment.app_url}api/${environment.apiVersion}/tipo_anomalias/index`, object, { 'headers': this.headers }
    )
  }
  tipoAlteracaoLeituraForSelect() {
    return this._http_client.get<any>(
      `${environment.app_url}api/${environment.apiVersion}/tipo_anomalias/indexAll`, { 'headers': this.headers }
    )
  }
  tipoAnomaliaLeitura() {
    return this._http_client.get<any>(
      `${environment.app_url}api/${environment.apiVersion}/motivos_anomalias/index`, { 'headers': this.headers }
    )
  }

  getLocalConsumo(contrato_id) {
    return this._http_client.get<any>(
      `${environment.app_url}api/${environment.apiVersion}/leituras/local_consumo/${contrato_id}`,
      { 'headers': this.headers }
    )
  }

  getLeiturasByCliente(cliente_id) {
    return this._http_client.get<any>(
      `${environment.app_url}api/${environment.apiVersion}/leituras/getLeiturasByCliente/${cliente_id}`,
      { 'headers': this.headers }
    )
  }
  getLeiturasByContrato(contrato_id) {
    return this._http_client.get<any>(
      `${environment.app_url}api/${environment.apiVersion}/leituras/getLeiturasByContrato/${contrato_id}`,
      { 'headers': this.headers }
    )
  }

getHistoricoConsumoByContrato(id: any) {
  return this._http_client.get<any>(
    `${environment.app_url}historico_consumo/${id}`,
    { 'headers': this.headers }
  )
}

  getClientes(filters: any) {
    return this._http_client.post<any>(
      `${environment.app_url}api/${environment.apiVersion}/leituras/getClientes`,
      { filters: filters },
      { 'headers': this.headers }
    )
  }


}
