

import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { PedidoReportService } from 'src/app/components/report-at/pedido/pedido-report.service';

@Component({
  selector: 'app-entidade-cativadora',
  templateUrl: './entidade-cativadora.component.html',
  styleUrls: ['./entidade-cativadora.component.css']
})
export class EntidadeCativadoraComponent implements OnInit {


  private items: any = [];
  private tipos: any = [];
  private clientes: any = [];

  private entidade: any = {
    nome: null,
    valor: null,
    estado: null,
    tipo_id: null,
    cliente_id: null
  }

  constructor(private http: HttpService, private configService: ConfigService) { }

  ngOnInit() {
    this.tipoEntidadeCativadoraSelectBox();
    this.getPageFilterData(1);
   
  }


  private tipoEntidadeCativadoraSelectBox() { 
    this.http.call_get('entidade-cativadora/tipoEntidadeCativadoraSelectBox',null).subscribe(
      response => { 
        this.tipos = Object(response).data; 
      }
    );
  }

  private listarEntidadeCativadoras() {
    this.configService.loaddinStarter('start');

    this.http.__call('entidade-cativadora/listar', this.http.filters).subscribe(
      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;
        this.items = Object(response).data.data;

        //console.log( this.items)
        this.configService.loaddinStarter('stop');
      }
    );
  }


  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page =Number.isInteger(page) ? page : 1 ;
    this.listarEntidadeCativadoras();
  }

  /**
  * @name "Registar series"
  * @description "Esta Função permite Registar entidade-cativadora"
  * @author "caniggia.moreira@itgest.pt"
  * @param start 
  * @param end 
  */
  private register() {
    if (this.entidade.nome == null || this.entidade.estado == null || this.entidade.tipo_id == null) {
      this.configService.showAlert('tipo, nome, valor, estado, são de caracter obrigatório', 'alert-danger', true);
      return;
    }
    this.configService.loaddinStarter('start');
    this.http.__call('entidade-cativadora/registar', this.entidade).subscribe(
      res => {
        if (Object(res).code == 500) {
          this.configService.showAlert(Object(res).message, 'alert-danger', true);
        } else {
          this.configService.showAlert(Object(res).message, 'alert-success', true);
          this.listarEntidadeCativadoras()
          this.entidade = {
            nome: null,
            //valor: null,
            estado: null,
            tipo_id: null
          }
        }
        this.configService.loaddinStarter('stop');
      });
  }

  view_cliente = false;

  private getClientes() {
    
    this.view_cliente = true;
    this.http.__call('entidade-cativadora/search-cliente', { start: 1, end: 10, search: this.entidade.nome }).subscribe(
      response => {
        this.clientes = Object(response).data.data;

        this.configService.loaddinStarter('stop');
      }
    );
  }

  private setCliente(client: any) {
    this.entidade.nome = client.nome;
    this.entidade.cliente_id = client.id;
    this.view_cliente = false;
    
  }


  private registerTipoEntidade() {
    if (this.entidade.nome == null) {
      this.configService.showAlert(' o campo nome é obrigatório', 'alert-danger', true);
      return;
    }
    this.configService.loaddinStarter('start');
    this.http.__call('tipo-entidade-cativadora/registar', this.entidade).subscribe(
      res => {
        if (Object(res).code == 500) {
          this.configService.showAlert(Object(res).message, 'alert-danger', true);
        } else {
          this.configService.showAlert(Object(res).message, 'alert-success', true);
          this.tipoEntidadeCativadoraSelectBox();
          this.entidade = {
            nome: null
          }
        }
        this.configService.loaddinStarter('stop');
      });
  }
}
