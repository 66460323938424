import { Component, OnInit, Input, AfterContentChecked } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
@Component({
  selector: 'app-ponto-venda',
  templateUrl: './ponto-venda.component.html',
  styleUrls: ['./ponto-venda.component.css']
})
export class PontoVendaComponent implements OnInit {

  private totalFacturacao = 0;
  private items: any = [];
  private produts: any = [];

  private facturacao = {
    produtos: []
  }

  private cliente = {
    nome: null,
    telefone: null,
    contribuente: null,
    morada: null
  };


  constructor(private http: HttpService) { }

  ngOnInit() {

  }

  private getCliente(e) {
    e.preventDefault();
    this.cliente.contribuente = e.target.elements[0].value;
    this.http.__call('cliente/pesquisar', this.cliente).subscribe(
      data => {
        this.items = data;
      }
    );
  }

  private getProduto(e) {
    e.preventDefault();
    this.http.__call('artigo/pesquisar',
      {
        barcode: e.target.elements[0].value,
        quantidade: e.target.elements[1].value
      }
    ).subscribe(data => {

      this.listarProdutos(data[0]);
    }
    );

  }

  private listarProdutos(data: any) {
    this.totalFacturacao = 0;
    let validar = 0;
    if (this.produts.length >= 1) {
      for (let index = 0; index < this.produts.length; index++) {
        let retorno = { id: null, nome: null, valor: null, barcode: null, quantidade: 0, total: 0 };
        const produt = this.produts[index];
        if (produt.barcode == data.barcode) {
          retorno.nome = produt.nome;
          retorno.id = produt.id;
          retorno.valor = produt.valor;
          retorno.barcode = produt.barcode;
          retorno.quantidade = Number(data.quantidade);
          retorno.total = produt.valor * retorno.quantidade;
          this.produts.splice(index, 1);
          this.produts.splice(index, 0, retorno);
          validar = 1;
        }

      }
      if (validar == 0) {
        this.produts.push(data);
      }
    } else {
      this.produts.push(data);
    }
    this.calcularTotal();

  }


  private calcularTotal() {
    this.totalFacturacao=0;
    //Calcula o Total da Factura
    for (let index = 0; index < this.produts.length; index++) {
      this.totalFacturacao += Number(this.produts[index].total);
    }
  }

  values = '';
  tecladoNumerico = "";

  onKey(event: any) { // without type info
    this.values = '';
    this.values += event.target.value;
    this.tecladoNumerico = '';
    this.tecladoNumerico = this.values;
    console.log(this.tecladoNumerico);
  }
  tecladoClick(e) {
    this.tecladoNumerico += e.target.innerHTML;
  }

  deleteRow(id) {
    for (let i = 0; i < this.produts.length; ++i) {
      if (this.produts[i].id === id) {
        this.produts.splice(i, 1);
        this.calcularTotal();
      }
    }

  }
}

