import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { ExcelService } from 'src/app/services/excel.service';
import { ReportClienteService } from 'src/app/components/report-at/relatorios/report-cliente.service';
import * as moment from 'moment';
import { ExcelAutoService } from 'src/app/services/excel/excel-auto.service';
import { NullVisitor } from '@angular/compiler/src/render3/r3_ast';
import { identifierName } from '@angular/compiler';

@Component({
  selector: 'app-extraccao-clientes',
  templateUrl: './extraccao-clientes.component.html',
  styleUrls: ['./extraccao-clientes.component.css']
})
export class ExtraccaoClientesComponent implements OnInit {

  loading: boolean = false;
  disableButton: boolean = true;

   private filter = {
    tipo_identificacao: "T",
    tipo_cliente: "T",
    tipo_medicao: "T",
    bairro:"T",
    distrito:"T",
    tipo_facturacao: "T",
    tipo_contrato: "T",
    estado_contrato: "T",
    objecto_contrato: "T",
    cliente_id:null,
    cliente:null,
    genero: null,
    data2:null,
    data1:null
  }

  private localUrl: any;
  private altura: any;
  private largura: any;
  private estado: boolean = false;
  items: any = [];
  clientes: any = [];
  generos: any = [];
  tipoclientes: any = [];
  tipoidentidades: any = [];
  tipofacturacao: any = [];
  tipocontrato: any = [];
  estadocontrato: any = [];
  objectocontrato: any = [];
  bairros: any = [];
  distritos: any = [];
  tipoMedicaos: any = [];
  public checkoutDate:boolean=false

  public filters = {
    search: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    pagination: {
      perPage: 5,// Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima,
      lastPage: null,
      total: null // defini o total de registo da BD
    },
    filter: null // subelemente do filter
    , is_allexel: false,
  }

  constructor(private http: HttpService,
     private configService: ConfigService,
     private excelService: ExcelAutoService,
     private reportClientes: ReportClienteService
     ) { }

  ngOnInit() {
    this. setTipoContrato('T')
    this.getGeneros();
    this.getTipoCliente();
    this.getTipoIdentidade();
    this.getTipoFacturacao();
    this.getTipoContrato();
    this.getEstadoContrato();
    this.getObjectoContrato();
    // this.getPageFilterData(1);
    //this.getBairro()
    this.filters
    this.getDistrito()
    this.getTipoMedicao()
    this.empresaUser()
  }

  exportAsPDF(): void {
    var file = document.getElementsByClassName("exportAsXLSXExtraccaoCliente")[0]
    this.reportClientes.relatorioExtraccaoClientes('save', file,this.localUrl);
  }

  imprimirPDF(): void {
    var file = document.getElementsByClassName("exportAsXLSXExtraccaoCliente")[0];
    this.reportClientes.relatorioExtraccaoClientes('print', file,this.localUrl);
  }



  private empresaUser() {

    this.configService.loaddinStarter('start');

    this.http.call_get('empresa/empresa-user', null).subscribe(

      response => {
        this.localUrl = Object(response).data.logotipo
        this.altura = Object(response).data.width
        this.largura = Object(response).data.height

        this.configService.loaddinStarter('stop');
      }
    );
  }
  private getBairro(id){
    this.http.call_get('bairro/selectBoxByDistrito/'+id, null).subscribe(
      response => {
        let data= Object(response)
       if(Array.isArray(data)) this.bairros = Object(response)
      }
    );
  }

  private getDistrito(){
    this.http.call_get('distrito/selectBox', null).subscribe(
      response => {
        this.distritos = Object(response).data

      }
    );
  }
  private getTipoMedicao(){
    this.http.call_get('tipo-medicao/selectBox', null).subscribe(
      response => {
        this.tipoMedicaos = Object(response)
      }
    );
  }
  setTrue() {
    this.filters.pagination.page =  Number.isInteger(this.filters.pagination.page) ? this.filters.pagination.page : 1 ;
    this.estado = true
    this.filters.is_allexel=true
    this.listaextraccao_cliente()
  }
  view_client = false;
  private getCliente(search: any) {
    search = search.target.value;
    this.view_client = true;
    if(search == ''){
      this.view_client = false;
      this.filter.cliente_id = null;

    }
    this.http.__call('cliente/search-cliente', { start: 1, end: 10, search: search }).subscribe(
      response => {
        this.clientes = Object(response).data.data;
      }
    );
  }
  private setCliente(client: any) {
    this.view_client = false;
        this.filter.cliente_id = client.id,
        this.filter.cliente = client.nome
  }

  hendleDateValidate=()=>{

    let format = "YYYY-MM-DD"
    let data1 = moment( this.filter.data1 ).format(format);
    let data2 = moment( this.filter.data2 ).format(format);

    let condition= ( data1 > data2 )

    return condition
 }

 resetDate(){
  this.checkoutDate=this.hendleDateValidate()
 }

  private listaextraccao_cliente() {
    this.configService.loaddinStarter('start');
    const arrayFormaPaga=[]
    this.loading = true

    this.filters.filter = this.filter
    this.http.__call('relatorio-extraccao/clientes', this.filters).subscribe(
      response => {

          this.items = Object(response).data;
          if(!this.estado){
               this.configService.loaddinStarter('stop');
            }

        this.estado = false
        this.loading = false
        this.configService.loaddinStarter('stop');
        this.filters.is_allexel=false
      }
    );
  }

  exportAsXLSX(): void {

    var CurrentDate = new Date();
    var keys = [
      { key: 'cliente_id', width: 30 },
      { key: 'conta_id', width: 30 },
      { key: 'contrato_id', width: 30 },
      { key: 'cliente', width: 50 },
      { key: 'municipio_cliente', width: 30 },
      { key: 'tipo_cliente', width: 50 },
      { key: 'data_cliente', width: 40 },
      { key: 'cil', width: 30 },
      { key: 'municipio_cil', width: 30 },
      { key: 'distrito_cil', width: 30 },
      { key: 'bairro_cil', width: 30 },
      { key: 'quarteirao', width: 30 },
      { key: 'rua', width: 30 },
      { key: 'morada', width: 60 },
      { key: 'nif', width: 60 },
      { key: 'tipo_medicao', width: 60 },
      { key: 'objecto_contrato', width: 30 },
      { key: 'tarifario', width: 30 },
      { key: 'media_consumo', width: 30 },
      { key: 'data_inicio', width: 30 },
      { key: 'data_fim', width: 30 },
      { key: 'nivel_sensibilidade', width: 30 },
      { key: 'estado_contrato', width: 30 },
      { key: 'data_rescisao', width: 30 },
      { key: 'motivo_recisao', width: 30 },
      { key: 'created_at', width: 50 },
      { key: 'contador', width: 30 },
      { key: 'calibre', width: 30 },
      { key: 'agencia', width: 30 },
      { key: 'user', width: 30 },
    ];


    var Cols = [
      "Nº Cliente",
      "Nº Conta",
      "Nº Contrato",
      "Cliente",
      "Município cliente",
      "Tipo Cliente",
      "Data registo cliente",
      "Cil",
      "Município Cil",
      "Distrito Cil",
      "Bairro Cil",
      "Quarteirão",
      "Rua",
      "Moradia/Apt/Caixa",
      "Nif",
      "Tipo Medição",
      "Objecto Contrato",
      "Tarifário",
      "Média Consumo",
      "Data Início prevista",
      "Data Fim prevista",
      "Nível Sensibilidade",
      "Estado",
      "Data rescisão",
      "Motivo rescisão",
      "Data registo contrato",
      "Contador",
      "Calibre",
      "Agência",
      "Operador"

   ]

    var title ="Relatório Clientes Contas"

    var nameFile ="Relatorio de Extracao " + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + ""
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    this.excelService.excels(this.items , nameFile, this.localUrl, keys, Cols, title, 5, 30, 40,3)
  }

  getPageFilterData(page: any) {
    if (this.filters.pagination.perPage == null) {
      return;
    }
    this.filters.pagination.page = Number.isInteger(page) ? page : 1 ;

    if(page=='T'){
         this.filters.is_allexel=true
          this.estado = false
    }
      // this.listaextraccao_cliente();

  }

  private getGeneros() {
    this.http.call_get('genero/selectBox', null).subscribe(
      response => {
        this.generos = Object(response);
      }
    );
  }

  private getTipoCliente() {
    this.http.call_get('tipo-cliente/listagem', null).subscribe(
      response => {
        this.tipoclientes = Object(response).data;
      }
    );
  }

  private getTipoIdentidade() {
    this.http.call_get('tipo-identidade/listagem', null).subscribe(
      response => {
        this.tipoidentidades = Object(response).data;
      }
    );
  }

  private getTipoFacturacao() {
    this.http.call_get('tipo-facturacao/selectBox', null).subscribe(
      response => {
        this.tipofacturacao = Object(response);
      }
    );
  }

  private getTipoContrato() {
    this.http.call_get('tipo-contrato/selectBox', null).subscribe(
      response => {
        this.tipocontrato = Object(response)
      }
    );
  }
  setTipoContrato(dado:any){
    this.filter.tipo_contrato=dado
  }
  private getEstadoContrato() {
    this.http.call_get('estado-contrato/selectBox', null).subscribe(
      response => {
        this.estadocontrato = Object(response);
      }
    );
  }

  private getObjectoContrato() {
    this.http.call_get('objecto-contrato/selectBox', null).subscribe(
      response => {
        this.objectocontrato = Object(response);
      }
    );
  }





}
