import { ModalListOrdemServicoService } from './../ordemservico/listar-ordem-servico/modal-ordem-servico.service';
import { ApiService } from 'src/app/providers/http/api.service';
import { ExcelService } from 'src/app/services/excel.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { HttpService } from 'src/app/providers/http/http.service';
import { finalize } from 'rxjs/operators';
import { PlanoPrestacaoService } from '../reports/recibo/planoPrestacao.service';
import { ReciboService } from '../reports/recibo/recibo.service';


@Component({
  selector: 'app-listar-plano-prestacao',
  templateUrl: './listar-plano-prestacao.component.html',
  styleUrls: ['./listar-plano-prestacao.component.css']
})
export class ListarPlanoPrestacaoComponent {

  disabledButton = false;
  private loading: boolean = false;
  checkedAll: boolean = true;
  public estados: any;
  public plano_selecionados: any = []
  private ordens = []
  private items: any = [];
  private res: any = [];
  private facturas: any = [];
  planoPrestacaoSelecionado: any
  public facturas_selecionada: any = [];

  private plano_prestacso = {
    id: null,
    tipo_contracto_id: null,
  }

  public filters = {
    search: null,
    estado_id: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    pagination: {
      perPage: 5,// Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima,
      lastPage: null,
      total: null // defini o total de registo da BD
    },
    filter: null, // subelemente do filter
  }

  private empresa: any = [];

  constructor(private auth: AuthService,
    private http: HttpService,
    private configService: ConfigService,
    private excelService: ExcelService,
    private api: ApiService,
    private checkOrdemServico: ModalListOrdemServicoService,
    private PDFreciboPlanoPrestacao: PlanoPrestacaoService,
    private reciboService: ReciboService
  ) { }

  ngOnInit() {
    this.getPageFilterData(1)
    this.getEstados();
    this.getEmpresa()
  }

  async getEmpresa() {
    await this.http
      .call_get("empresa/empresa-user", null)
      .subscribe((response) => {
        this.empresa = Object(response);
        this.empresa.sigla_empresa = Object(response).data.sigla_empresa;
        this.reciboService.siglaEmpresa = this.empresa.sigla_empresa
      });
  }

  private setLinhaPrestacao(plano) {

  }

  private setFacturas(plano) {

  }

  private getEstados() {
    this.http.call_get('plano-prestacao/estado/selectBox', null).subscribe(
      response => {
        this.estados = Object(response).data;
      }
    );
  }


  private listar() {
    this.loading = true
    this.http.__call('plano-prestacao/listagem', { ...this.filters }).subscribe(
      response => {
        this.filters.pagination.lastPage = Object(response).data.lastPage;
        this.filters.pagination.page = Object(response).data.page;
        this.filters.pagination.total = Object(response).data.total;
        this.filters.pagination.perPage = Object(response).data.perPage;
        this.items = Object(response).data.data;
        this.loading = false
      }
    );
  }

  getPageFilterData(page: number) {

    if (this.filters.pagination.perPage == null) {
      return;
    }

    if (this.filters.pagination.perPage == null) {
      return;
    }
    this.filters.pagination.page = Number.isInteger(page) ? page : 1;
    this.listar();
  }
  getAnularPlanoPrestaco(id) {
    this.loading = true;
    this.http.call_get("plano-prestacao/anular/" + id, null)
      .subscribe((res) => {
        this.getPageFilterData(1)
        this.loading = false;
      })
  }

  public getPagamentoPrestacao(item) {
    item['valor'] = item['valor_entrada']
    this.planoPrestacaoSelecionado = item

    this.api
      .get("plano-prestacao/factura-prestacao/" + item.id)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe((response) => {
        if (Object(response).code == 200) {
          this.facturas_selecionada = Object(response).data;
        } else {
          this.configService.showAlert
            (
              Object(response).message,
              "alert-danger",
              true
            );
        }
      });
  }


  private plano_header: any;
  private getLinhaPrestacao(item) {
    this.plano_header = item
    this.configService.loaddinStarter('start');
    this.http.call_get('plano-prestacao/linha-prestacao/' + item.id, null).subscribe(
      response => {
        this.res = Object(response).data;
        this.configService.loaddinStarter('stop');
      }
    );
  }

  private getFacturasPrestacao(item) {
    this.configService.loaddinStarter('start');
    this.http.call_get('plano-prestacao/factura-prestacao/' + item.id, null).subscribe(
      response => {
        this.facturas = Object(response).data;
        this.configService.loaddinStarter('stop');
      }
    );
  }

  private btnImprimirPlanoPrestacao(planoPrestacao: any) {
    this.PDFreciboPlanoPrestacao.imprimirReciboPlanoPrestacao(planoPrestacao.id, '2ª Via');
  }



  updateChecked(f: any) {
    this.items.forEach(element => {
      element.checked = element.id === f.id ? !f.checked : element.checked;
      Object.assign(element);
    });
    const lengthFalse = this.items.filter(val => val.checked === true);
    this.checkedAll = lengthFalse.length === this.items.length ? true : false;
  }

  setCheckedAll(checked: boolean) {
    this.checkedAll = checked;
    this.items.forEach(f => f.checked = checked);
  }


  private get planoPrestacaoChecked(): any[] {
    return this.items.filter(val => val.checked === true);
  }

  private cancelarPlanoemMassa() {
    if (this.planoPrestacaoChecked.length > 0) {
      this.http.__call('anulacao/massiva/plano', this.planoPrestacaoChecked).subscribe(
        res => {
          if (Object(res).code == 201) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            this.configService.showAlert(Object(res).message, 'alert-success', true);


          }
        }
      );
    }

  }
}
