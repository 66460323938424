import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/providers/config/config.service';
import { ClassificacaoService } from './classificacao.service';

@Component({​
    selector: 'app-classificacao-produto',
    templateUrl: './classificacao-produto.component.html',
    styleUrls: ['./classificacao-produto.component.css']
}​)

export class ClassificacaoProdutoComponent implements OnInit {​
    
    classificacao: any = {
        id: null,
        descricao: null
    }
    
    classificacoes: any = []
    
    constructor(
        private configService: ConfigService,
        private _classificacaoService: ClassificacaoService
    ) { }
    
    ngOnInit() {
        this.loadingMethods()
    }
    
    loadingMethods(){
        this._classificacaoService.getClassificacoes()
        .subscribe((response) => {
            this.classificacoes = response.data.data
        })
    }

    _initModal(classificacao){
        console.log( classificacao )
        this.classificacao = classificacao
    }

    

}
