import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

@Component({
  selector: 'app-listar-parceria',
  templateUrl: './listar-parceria.component.html',
  styleUrls: ['./listar-parceria.component.css']
})
export class ListarParceriaComponent implements OnInit {

  private parcerias: any = []
  private loading: boolean = false;

  constructor(private http: HttpService, private configService: ConfigService) { 
    this.getPageFilterData(1);
  }

  ngOnInit() {
  }


  /**
   * @name "Listar facturação"
   * @descriptio "Esta Função permite Listar todas facturações"
   * @author "caniggia.moreira@itgest.pt"
   * @param start
   * @param end
   */
  private listarParcerias() {

    this.loading = true
    this.http.__call('cliente/parceria/listar', this.http.filters).subscribe(
      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;
        this.parcerias = Object(response).data.data;
        this.loading = false
      }
    );
  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page =Number.isInteger(page) ? page : 1 ;
    this.listarParcerias();
  }
}
