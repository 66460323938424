import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';

import * as moment from 'moment';
import { ExcelAutoService } from 'src/app/services/excel/excel-auto.service';
import { PdfAutoService } from 'src/app/services/pdf/pdf-auto.service';

@Component({
  selector: 'app-relatorio-resumo-contratos',
  templateUrl: './relatorio-resumo-contratos.component.html',
  styleUrls: ['./relatorio-resumo-contratos.component.css']
})
export class RelatorioResumoContratosComponent implements OnInit {

  loading: boolean = false;
  items: any = [];
  distritos: any = [];
  estadoContratos: any = [];
  private disabledButton = true;

  submitted = false;


  private localUrl: any;
  private largura: any;
  private altura: any;
  simpleForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private http: HttpService,
    private excelService: ExcelAutoService,
    private configService: ConfigService,
    private pdfService: PdfAutoService
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.empresaUser()
    this.getDistritos()
    this.getEstadoContratos()
  }

  createForm() {
    this.simpleForm = this.fb.group({
      distrito_id: [null, Validators.required],
      estado_id: [null, Validators.required],
      data1: [null],
      data2: [null],
    });
  }

  get f() {
    return this.simpleForm.controls;
  }
  onSubmit() {

    this.submitted = true;
    if (this.simpleForm.invalid) {
      return;
    }
    const uri = 'relatorio/financeira/resumo_contratos';
    this.createOrEdit(uri, this.simpleForm);
  }

  private empresaUser() {
    this.configService.loaddinStarter('start');
    this.http.call_get('empresa/empresa-user', null).subscribe(
      response => {
        this.localUrl = Object(response).data.logotipo
        this.altura = Object(response).data.width
        this.largura = Object(response).data.height
        this.configService.loaddinStarter('stop');
      }
    );
  }

  createOrEdit(uri: any, simpleFormulario: FormGroup) {
    this.items = []
    this.loading = true;
    this.disabledButton = true;
    this.totais.contratos = 0;


    this.http.__call(uri, simpleFormulario.value).subscribe(
      response => {
        this.items = Object(response).data;
        this.loading = false;
        if (this.items.length != 0) {
          this.disabledButton = false;
          this.somaTotais();
        }
        this.loading = false;
      }
    );
  }

  exportAsXLSX(): void {
    var CurrentDate = new Date();

    var keys = [
      { key: 'distrito', width: 50, style: { font: { name: 'Calibri' } } },
      { key: 'estado', width: 50 },
      { key: 'numero_contratos', width: 50 },
    ];
    var Cols = ["Distrito", "Estado", "Nº de Contratos"]
    var title = 'Relatório Resumo de Contratos'
    var nameFile = "Resumo_Facturacao_Distrito -" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    this.excelService.excels(this.items, nameFile, this.localUrl, keys, Cols, title, 5, 3, 40, 3)
  }

  exportAsPDF(): void {
    var collumns = {
      0: { cellWidth: 90, halign: 'center' },
      1: { cellWidth: 90, halign: 'center' },
      2: { cellWidth: 80, halign: 'center' }
    };

    var file = document.getElementsByClassName("table_resumo_contratos")[0]
    this.pdfService.auto_pdf(file, 'save', 'Relatório Resumo de Contratos', this.localUrl, collumns);
  }

  imprimirPDF(): void {
    var collumns = {
      0: { cellWidth: 95, halign: 'center' },
      1: { cellWidth: 95, halign: 'center' },
      2: { cellWidth: 80, halign: 'center' }
    };

    var file = document.getElementsByClassName("table_resumo_contratos")[0]
    this.pdfService.auto_pdf(file, 'print', 'Relatório Resumo de Contratos', this.localUrl, collumns);
  }



  private totais = {
    contratos: 0,
  }

  private somaTotais() {

    var contratos: number = 0;
    this.items.forEach(element => {
      contratos += element.numero_contratos;
    });
    this.totais.contratos = contratos;
  }

  private getDistritos() {
    this.http.call_get('distrito/selectBox', null).subscribe(
      response => {
        this.distritos = Object(response).data;
      }
    );
  }

  private getEstadoContratos() {
    this.http.call_get('estado-contrato/selectBox', null).subscribe(
      response => {
        this.estadoContratos = Object(response);
      }
    );
  }
}

