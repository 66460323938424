import { Injectable } from '@angular/core';
import * as moment from 'moment';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import { ConfigService } from 'src/app/providers/config/config.service';

@Injectable({
  providedIn: 'root'
})
export class RelDetalhadaPosPagoService {

  constructor(private configService: ConfigService) { }

  public relatorioFacturacaoPospago(file,file2, p = 'print',filtros,imgData) {

    var today = moment().format("DD-MM-YYYY H:mm:ss");

    var doc = new jsPDF('l', '', 'a3')
        doc.setProperties({
        title: 'Facturacao_Detalhada_Pos-Pago'+today,
        subject: 'Report',
        author: 'Unig',
        keywords: '',
        creator: 'Angola Telecom'
        });
    var versao = 'F.D.P 1.0.0';
    doc.addImage(imgData, 'JPEG',5, 10, 28, 18)
    const totalPagesExp = "{total_pages_count_string}";

        doc.setFontType('bold')
        doc.setFontSize(15)
  //#215ebf
        doc.setFontSize(15)
        doc.text(190, 25, 'FACURAÇÃO DETALHADA', 'center')
        doc.setFontSize(9)
        doc.text('Data início:', 375, 20)
        doc.text('Data fim:', 375, 25)
/*  */
        doc.setFontType('normal')
        doc.text('' + (filtros.data1), 393, 20);
        doc.text('' + (filtros.data2),389 , 25);

        doc.setFontType('bold')
    doc.autoTable({ html: file ,
    didParseCell: function (data) {
    var rows = data.table.body;
    if (data.row.index === 0) {
      data.cell.styles.fontStyle = 'bold';
      data.cell.styles.textColor = "white";
      data.cell.styles.fillColor = [32, 95, 190];
    }
    if (data.row.index === 0) {
      data.cell.styles.fontStyle = 'bold';
    } //if (data.row.index === rows.length - 1) {data.cell.styles.fontStyle = 'bold';}
    },
    didDrawPage : data => {
      let footerStr = "Página " + doc.internal.getNumberOfPages();
      if (typeof doc.putTotalPages === 'function') {
        footerStr = footerStr + " de " + totalPagesExp;
      }
           doc.setFontType('bold')
        doc.setFontSize(10);
        doc.text(today,data.settings.margin.left, doc.internal.pageSize.height - 2)
        doc.setFontSize(10);
        doc.text(footerStr, 393, doc.internal.pageSize.height - 2, 'left');
    },
    styles: {
       textColor: [0, 0, 0],
      },
    margin: {left:3,right:7},
    tableWidth: 10,
    columnStyles: {
      0: {cellWidth: 14,halign: 'center'},
      1: {cellWidth: 14,halign: 'center'},
      2: {cellWidth: 14,halign: 'center'},
      3: {cellWidth: 14,halign: 'center'},
      4: {cellWidth: 10,halign: 'center'},
      5: {cellWidth: 14,halign: 'center'},
      6: {cellWidth: 14,halign: 'center'},
      7: {cellWidth: 10,halign: 'center'},
      8: {cellWidth: 10,halign: 'center'},
      9: {cellWidth: 10,halign: 'center'},
      10: {cellWidth: 18,halign: 'center'},
      11: {cellWidth: 18,halign: 'center'},
      12: {cellWidth:18,halign: 'center'},
      13: {cellWidth:18,halign: 'center'},
      14: {cellWidth:18,halign: 'center'},
      15: {cellWidth:18,halign: 'center'},
      16: {cellWidth:18,halign: 'center'},
      17: {cellWidth:18,halign: 'center'},
      18: {cellWidth:18,halign: 'center'},
      19: {cellWidth:18,halign: 'center'},
      20: {cellWidth:18,halign: 'center'},
      21: {cellWidth:18,halign: 'center'},
      22: {cellWidth:18,halign: 'center'},
      23: {cellWidth:18,halign: 'center'},
      24: {cellWidth:18,halign: 'center'},
      25: {cellWidth:18,halign: 'center'},

  },
    rowsStyles:{},
  startY: 40,
  theme: 'grid',

  })

  doc.autoTable({ html: file2 ,
    didParseCell: function (data) {
    var rows = data.table.body;
    if (data.row.index === 0 ||data.row.index === 1) {
      data.cell.styles.fontStyle = 'bold';
      data.cell.styles.textColor = "white";
      data.cell.styles.fillColor = [32, 95, 190];
    }
    if (data.row.index === 0) {
      data.cell.styles.fontStyle = 'bold';
    } //if (data.row.index === rows.length - 1) {data.cell.styles.fontStyle = 'bold';}
    },
    didDrawPage : data => {
      let footerStr = "Página " + doc.internal.getNumberOfPages();
      if (typeof doc.putTotalPages === 'function') {
        footerStr = footerStr + " de " + totalPagesExp;
      }
           doc.setFontType('bold')
        doc.setFontSize(10);
        doc.text(today,data.settings.margin.left, doc.internal.pageSize.height - 2)
        doc.setFontSize(10);
        doc.text(footerStr, 393, doc.internal.pageSize.height - 2, 'left');
    },
    styles: {
       textColor: [0, 0, 0],
      },
    margin: {left:3,right:7},
    tableWidth: 10,
    columnStyles: {
      0: {cellWidth: 20,halign: 'center'},
      1: {cellWidth: 20,halign: 'center'},
      2: {cellWidth: 14,halign: 'center'},
      3: {cellWidth: 20,halign: 'center'},
      4: {cellWidth: 20,halign: 'center'},
      5: {cellWidth: 20,halign: 'center'},
      6: {cellWidth: 20,halign: 'center'},
      7: {cellWidth: 20,halign: 'center'},
      8: {cellWidth: 20,halign: 'center'},
      9: {cellWidth: 20,halign: 'center'},
      10: {cellWidth: 20,halign: 'center'},
      11: {cellWidth: 20,halign: 'center'},
      12: {cellWidth:20,halign: 'center'},
      13: {cellWidth:20,halign: 'center'},
      14: {cellWidth:20,halign: 'center'},
      15: {cellWidth:20,halign: 'center'},
      16: {cellWidth:20,halign: 'center'},
      17: {cellWidth:20,halign: 'center'},
      18: {cellWidth:20,halign: 'center'},
      19: {cellWidth:20,halign: 'center'},
      20: {cellWidth:20,halign: 'center'},

  },
    rowsStyles:{},
  startY: doc.lastAutoTable.finalY + 10,
  theme: 'grid',

  })

  if (typeof doc.putTotalPages === 'function') {
    doc.putTotalPages(totalPagesExp);
  }

  if (p === 'save') {
    doc.save('Facturacao_Detalhada_Pos-Pago'+today+'.pdf');
  } else {
    doc.autoPrint();
    doc.output("dataurlnewwindow");
  }


  }


  public relatorioFacturacaoPorProduto(file, p = 'print',filtros,imgData) {

    var today = moment().format("DD-MM-YYYY H:mm:ss");

    var doc = new jsPDF('l', '', 'a3')
        doc.setProperties({
        title: 'Facturacao_Detalhada_Por_produto'+today,
        subject: 'Report',
        author: 'Unig',
        keywords: '',
        creator: 'Angola Telecom'
        });
    var versao = 'F.D.P 1.0.0';
    doc.addImage(imgData, 'JPEG',5, 10, 28, 18)
    const totalPagesExp = "{total_pages_count_string}";

        doc.setFontType('bold')
        doc.setFontSize(15)
  //#215ebf
        doc.setFontSize(15)
        doc.text(190, 25, 'FACURAÇÃO DETALHADA POR PRODUTO', 'center')
        doc.setFontSize(9)
        doc.text('Data início:', 375, 20)
        doc.text('Data fim:', 375, 25)
/*  */
        doc.setFontType('normal')
        doc.text('' + (filtros.data1), 393, 20);
        doc.text('' + (filtros.data2),389 , 25);

        doc.setFontType('bold')
    doc.autoTable({ html: file ,
    didParseCell: function (data) {
    var rows = data.table.body;
    if (data.row.index === 0) {
      data.cell.styles.fontStyle = 'bold';
      data.cell.styles.textColor = "white";
      data.cell.styles.fillColor = [32, 95, 190];
    }
    /*  if (data.row.index === rows.length - 1) {
      data.cell.styles.fontStyle = 'bold';
    } */
    },
    didDrawPage : data => {
      let footerStr = "Página " + doc.internal.getNumberOfPages();
      if (typeof doc.putTotalPages === 'function') {
        footerStr = footerStr + " de " + totalPagesExp;
      }
      doc.setFontSize(10);
      doc.text(today,data.settings.margin.left, doc.internal.pageSize.height - 2)
      doc.setFontType('bold')
        doc.setFontSize(10);
        doc.text(footerStr, 393, doc.internal.pageSize.height - 2, 'left');
    },
    styles: { textColor: [0, 0, 0] },margin: {left:3,right:7},
    tableWidth: 10,

    columnStyles: {
    0: {cellWidth: 18,halign: 'center'},
    1: {cellWidth: 18,halign: 'center'},
    2: {cellWidth: 18,halign: 'center'},
    3: {cellWidth: 18,halign: 'center'},
    4: {cellWidth: 18,halign: 'center'},
    5: {cellWidth: 18,halign: 'center'},
    6: {cellWidth: 18,halign: 'center'},
    7: {cellWidth: 18,halign: 'center'},
    8: {cellWidth: 18,halign: 'center'},
    9: {cellWidth: 18,halign: 'center'},
    10: {cellWidth: 18,halign: 'center'},
    11: {cellWidth: 18,halign: 'center'},
    12: {cellWidth:18,halign: 'center'},
    13: {cellWidth:18,halign: 'center'},
    14: {cellWidth:18,halign: 'center'},
    15: {cellWidth:18,halign: 'center'},
    16: {cellWidth:18,halign: 'center'},
    17: {cellWidth:18,halign: 'center'},
    18: {cellWidth:18,halign: 'center'},
    19: {cellWidth:18,halign: 'center'},
    20: {cellWidth:18,halign: 'center'},
    21: {cellWidth:18,halign: 'center'},
    22: {cellWidth:18,halign: 'center'},
    23: {cellWidth:18,halign: 'center'},

     },
    rowsStyles:{},
  startY: 40,
  theme: 'grid',

  })

  if (typeof doc.putTotalPages === 'function') {
    doc.putTotalPages(totalPagesExp);
  }

  if (p === 'save') {
    doc.save('Facturacao_Detalhada_Por-Produto'+today+'.pdf');
  } else {
    doc.autoPrint();
    doc.output("dataurlnewwindow");
  }


  }

}
