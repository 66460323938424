import { Injectable, EventEmitter } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
//import { environment } from '@env/environment'

import { Router } from '@angular/router'
import { environment } from 'src/environments/environment'


@Injectable({
  providedIn: 'root'
})

export class ClienteService {

  private token = localStorage.getItem('sessionToken')

  private headers = new HttpHeaders()
  .set('content-type', 'application/json')
  .set('Access-Control-Allow-Origin', '*')
  .set('Authorization', `Bearer ${this.token}`)

  constructor(
    private router: Router,
    private _http_client: HttpClient
  ){}

  getTipoIdentidades() {
    return this._http_client.get<any>(
      `${environment.app_url}api/${environment.apiVersion}/clientes-identidades/getTipoIdentidades`,
      { 'headers': this.headers })
  }

  getTiposIdentidades(client_id: number) {
    return this._http_client.get<any>(
      `${environment.app_url}api/${environment.apiVersion}/clientes-identidades/cliente/${client_id}`,
      { 'headers': this.headers })
  }
  
  getIdentidades(client_id: number) {
    return this._http_client.get<any>(
      `${environment.app_url}api/${environment.apiVersion}/clientes-identidades/getIdentidades/${client_id}`,
      { 'headers': this.headers })
  }

  CreateTipoIdentidade(tipo: any) {
    return this._http_client.post<any>(
      `${environment.app_url}api/${environment.apiVersion}/clientes-identidades`,
      tipo,
      { 'headers': this.headers }
    )
  }

  UpdateTipoIdentidade(tipo: any) {
    return this._http_client.patch<any>(
      `${environment.app_url}api/${environment.apiVersion}/clientes-identidades/${tipo.id}`,
      tipo,
      { 'headers': this.headers }
    )
  }

  DeleteTipoIdentidade(id: number) {
    return this._http_client.delete<any>(
      `${environment.app_url}api/${environment.apiVersion}/clientes-identidades/${id}`,
      {'headers': this.headers}
    )
  }

}
