import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { ConfigModuloService } from 'src/app/services/config-modulo.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { RotaService } from '../rotas.service';
import { finalize } from 'rxjs/operators';
import * as moment from 'moment';
import { ApiService } from 'src/app/providers/http/api.service';
import { RoteiroService } from '../../reports/roteiro/roteiro.service';
import { ExcelAutoService } from 'src/app/services/excel/excel-auto.service';
@Component({
  selector: 'app-listagem-roteiros',
  templateUrl: './listagem-roteiros.component.html',
  styleUrls: ['./listagem-roteiros.component.css']
})
export class ListagemRoteirosComponent implements OnInit, OnDestroy {

  public currentUser: any;

  private title: string = "Registar Rota"
  private rota_header = {
    id: null,
    descricao: null,
    data_inicio: null,
    data_fim: null,
    provincia_id: null,
    municipio_id: null,
    leitor_id: null,
    user_nome: null,
    estado: null,
  }

  private DadosEmpresa: any = [];

  private rotarun = {
    id: null,
    nome_cliente: null,
    rota_header_id: null,
    conta_id: null,
    servico_id: null,
    id_servico: null,
    provincia_id: null,
    municipio_id: null,
    bairro_id: null,
    has_distrito: null,
  }

  private lista = {
    id: null
  }

  private residencia = {
    id: null,
    numero: null,
    nome: null,
    predio_nome: null,
    is_predio: null,
    rua_id: null,
    rua: null,
    bairro_id: null,
    bairro: null,
    has_distrito: false,
    municipio_id: null,
    distrito_id: null,
    provincia_id: null,
    is_active: null,
    user_id: null,
  };


  private rotas: any[];

  private corridasLeiturasArray = [];
  private leituraToProcess: boolean = true;

  private local_instalacaosArray = [];

  private local_instalacaoRow = {
    id: null,
    moradia_numero: null,
    is_predio: null,
    //predio_id: null,
    predio_nome: null,
    rota_header_id: null,
    local_consumo_id: null,

    rua_id: null,
    rua: null,
    bairro_id: null,
    bairro: null,
    has_distrito: null,
    distrito_id: null,
    distrito: null,
    municipio_id: null,
    municipio: null,
    provincia_id: null,
    provincia: null,
    latitude: null,
    longitude: null,
    user_id: null,
    user: null

  };

  private predio_flag: boolean = false;
  private local_instalacaoRowValid: boolean = false;
  private addRows: boolean = true;


  private provincias: any = [];
  private items: any = [];
  private distritos: any[];
  private bairros: any[];
  private residencias: any[];
  private municipios: any[];
  private ruas: any[];
  private periodo_inicio: any = [];
  private periodo_fim: any = [];
  roteiro: any = {}
  motivos_nao_leitura_list: any = []
  public loadings = { municipios: null }

  constructor(
    private auth: AuthService,
    private http: HttpService,
    private configService: ConfigService,
    private _rotaService: RotaService,
    private api: ApiService,
    private config: ConfigModuloService,
    private reportRoteiro: RoteiroService,
    private excelService: ExcelAutoService,
  ) {
    this.currentUser = this.auth.currentUserValue;
    this.getPageFilterData(1);
    this.getRotas();
    this.empresaUser();
  }

  ngOnInit() {

    this.selectBoxProvincias();
    this.gerarPeriodoInicial();
    this.getConfiguracaos();

    this._rotaService.getMotivosNaoLeitura()
      .subscribe(
        response => {
          this.motivos_nao_leitura_list = response.data
        }
      )

    this._rotaService.listRotasHeadersChange.subscribe(
      response => {
        this.rotaRuns = response
      }
    )
    this.getMunicipios()
  }

  ngOnDestroy() {
    this.http.filters.orderBy = null
  }

  private getMunicipios() {
    this.distritos = [];
    this.loadings.municipios = true;
    this.api.get('municipio/selectBox')
      .pipe(finalize(() => { this.loadings.municipios = false; }))
      .subscribe(response => { this.municipios = Object(response).data; }, error => { this.loadings.municipios = false; });
  }
  _closeModal() {
    const modal = document.querySelector('.modal-backdrop')
    modal.classList.remove('modal-backdrop', 'fade', 'show')
  }

  private listaRotaRuns() {
    this.configService.loaddinStarter('start');
    this.http.__call('rota-run/listagem', { filters: this.http.filters }).subscribe(

      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.configService.loaddinStarter('stop');

      }
    );
  }

  private selectBoxProvincias() {
    this.http.call_get('provincia/selectBox', null).subscribe(
      response => {
        this.provincias = Object(response).data;
      }
    );
  }


  private selectBoxMunicipiosByProvincia(id) {

    this.http.call_get('municipio/getMunicipiosByProvincia/' + id, null).subscribe(
      response => {
        this.municipios = Object(response).data;
      }
    );
  }

  private selectBoxDistritosByMunicipio() {

    for (let i = 0; i < this.municipios.length; ++i) {
      if (this.municipios[i].id == this.residencia.municipio_id)
        this.residencia.has_distrito = this.municipios[i].has_distrito;
    }

    if (this.residencia.has_distrito) {
      this.http.call_get('distrito/getDistritosByMunicipio/' + this.residencia.municipio_id, null).subscribe(
        response => {
          this.distritos = Object(response).data;
        }
      );
    }

    if (!this.residencia.has_distrito) {
      this.selectBoxBairrosByMunicipio();
    }

  }

  private selectBoxRuasByBairro() {
    this.http.call_get('rua/selectBoxByBairro/' + this.residencia.bairro_id, null).subscribe(
      response => {
        this.ruas = Object(response);
      }
    );
  }

  private selectBoxBairrosByMunicipio() {
    this.http.call_get('bairro/selectBoxByMunicipio/' + this.residencia.municipio_id, null).subscribe(
      response => {
        this.bairros = Object(response);
      }
    );
  }


  view_residencia = false;

  private getSelectBoxResidenciasByRua() {

    if (this.residencia.nome == "") {
      this.setNullResidencia();
    }

    this.view_residencia = true;
    this.http.__call('local-instalacao/getSelectResidenciaInLocalConsumoByRua', { rua_id: this.residencia.rua_id, start: 1, end: 10, search: null }).subscribe(
      response => {
        this.residencias = Object(response).data.data;

        this.configService.loaddinStarter('stop');
      }
    );
  }


  private setDataLocalInstalacao() {
    //this.local_instalacao.provincia_id =
  }


  private setResidencia(codigo, numero, nome, andar, is_predio) {
    this.residencia.id = codigo;
    this.residencia.numero = numero;
    this.residencia.nome = ((is_predio) ? 'Prédio ' + nome + ', ' + (andar ? andar + ' º Andar - ' : '') + 'Porta ' : 'Residência ') + numero;
    this.residencia.predio_nome = nome;
    this.residencia.is_predio = is_predio;
    this.view_residencia = false;

  }

  private setNullResidencia() {
    this.residencia.id = null;
    this.residencia.numero = null;
    this.residencia.nome = null;
    this.residencia.predio_nome = null;
    this.residencia.is_predio = null,
      this.view_residencia = false;
  }

  view_user = false;
  users: any = [];

  private getUtilizador() {

    if (this.rota_header.user_nome == "") {
      this.setNullUser();
    }
    this.view_user = true;
    this.http.__call('search-utilizador', { start: 1, end: 10, search: this.rota_header.user_nome })
      .subscribe(
        response => {
          this.users = Object(response).data.data;
          this.configService.loaddinStarter('stop');
        }
      );
  }

  private setUtilizador(id, nome) {
    this.rota_header.leitor_id = id;
    this.rota_header.user_nome = nome;
    this.view_user = false;
  }

  private setNullUser() {
    this.rota_header.leitor_id = null;
    this.rota_header.user_nome = null;
    this.view_user = false;
  }

  // CLIENTE SEARCH

  view_cliente = false;
  private clientes: any = [];

  private getCliente() {
    if (this.rotarun.nome_cliente == "") {
      this.setNullCliente();
    }
    //this.chaves=[];
    this.view_cliente = true;
    this.http.__call('cliente/search-cliente', { start: 1, end: 10, search: this.rotarun.nome_cliente }).subscribe(
      response => {
        this.clientes = Object(response).data.data;
        this.configService.loaddinStarter('stop');
      }
    );
  }

  private setCliente(id, nome) {
    this.rotarun.nome_cliente = nome;
    this.view_cliente = false;

    this.listarContasClientes(id)

  }

  private setNullCliente() {

    this.rota_header.user_nome = null;
    this.view_user = false;
  }

  private contas: any = [];

  private listarContasClientes(id) {
    this.carregando.conta = 'Carregando...';
    this.http.call_get('cliente/conta/listar/' + id, null).subscribe(
      response => {
        this.contas = Object(response).data;
        this.carregando.conta = 'Seleccione a Conta';
      }
    );
  }


  private async appendRowLocalInstalacao() {

    const loadedStateCallback = () => {
      this.local_instalacaoRow.rua_id = this.residencia.rua_id;

      this.local_instalacaoRow.is_predio = this.residencia.is_predio;
      this.local_instalacaoRow.predio_nome = (this.residencia.is_predio) ? this.residencia.predio_nome : null;
      this.local_instalacaoRow.predio_nome = this.residencia.nome;

      this.local_instalacaoRow.moradia_numero = this.residencia.numero;
      this.local_instalacaoRow.user_id = this.currentUser.user.id;

      this.local_instalacaoRowValid = this.localInstalacaoRowValidation(this.local_instalacaoRow);
      if (this.local_instalacaoRowValid)
        this.local_instalacaosArray.unshift({ ...this.local_instalacaoRow });

    }
    await this.getLocalInstalacaoByRuaAndMoradia(loadedStateCallback);

  }


  private localInstalacaoRowValidation(row): boolean {

    if (this.local_instalacaosArray.length == 0)
      return true;

    for (let i = 0; i < this.local_instalacaosArray.length; ++i) {
      if (this.local_instalacaosArray[i].rua_id == row.rua_id && this.local_instalacaosArray[i].moradia_numero == row.moradia_numero && this.local_instalacaosArray[i].is_predio == row.is_predio) {
        return false;
      }
    }

    return true;
  }


  private deleteRowLocalInstalacaoAppended(row): void {

    for (let i = 0; i < this.local_instalacaosArray.length; ++i) {

      if ((this.local_instalacaosArray[i].rua_id == row.rua_id) && (this.local_instalacaosArray[i].moradia_numero == row.moradia_numero && this.local_instalacaosArray[i].is_predio == row.is_predio)) {
        this.local_instalacaosArray.splice(i, 1);
      }

    }


  }


  private getLocalInstalacaoByRuaAndMoradia(callback: Function) {


    this.http.__call('local-instalacao/getLocalInstalacaoByRuaAndMoradia/', {
      rua_id: this.residencia.rua_id,
      moradia_numero: this.residencia.numero
    }).subscribe(
      response => {
        const result = Object(response);

        this.local_instalacaoRow.local_consumo_id = result.id;
        this.local_instalacaoRow.moradia_numero = result.moradia_numero;
        this.local_instalacaoRow.is_predio = result.is_predio;
        this.local_instalacaoRow.predio_nome = result.predio_nome;
        this.local_instalacaoRow.latitude = result.latitude;
        this.local_instalacaoRow.longitude = result.longitude;

        this.local_instalacaoRow.rua_id = result.rua_id;
        this.local_instalacaoRow.rua = result.rua;
        this.local_instalacaoRow.bairro_id = result.bairro_id;
        this.local_instalacaoRow.bairro = result.bairro;
        this.local_instalacaoRow.distrito_id = result.distrito_id;
        this.local_instalacaoRow.distrito = result.distrito;
        this.local_instalacaoRow.has_distrito = result.has_distrito;
        this.local_instalacaoRow.municipio_id = result.municipio_id;
        this.local_instalacaoRow.municipio = result.municipio;
        this.local_instalacaoRow.provincia_id = result.provincia_id;
        this.local_instalacaoRow.provincia = result.provincia;

        if (!this.local_instalacaoRow.has_distrito) this.local_instalacaoRow.distrito_id = null;

        callback();
      }
    );

  }

  private servicos: any = [];

  private listarServicos() {

    const id = this.rotarun.conta_id

    this.carregando.servico = 'Carregando...';
    this.http.call_get('tarifario/servico/selectBoxServicosConta/' + id, null).subscribe(
      response => {
        this.servicos = Object(response).data;
        this.carregando.servico = 'Seleccione o serviço';
      }
    );
  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1;
    this.listaRotaRuns();
    //this._resetFilters();
  }

  _resetFilters() {
    this.http.filters.rota_header_id = null;
    this.http.filters.municipio_id = null;
    this.http.filters.status = null;
  }

  private carregando = {
    filial: 'Seleccione a filial',
    conta: 'Seleccione a conta',
    servico: 'Seleccione o serviço',
    periodo: 'Seleccione o Fim'
  }

  private filiais: any = [];

  private selectBox() {
    this.listarFilial();
  }

  private listarFilial() {
    this.carregando.filial = 'Carregando...';
    this.http.call_get('filial/selectBox', null).subscribe(
      response => {
        this.filiais = Object(response).data;
        this.carregando.filial = 'Seleccione a Filial';
      }
    );
  }


  private saveRotaHeader(e) {

    this.configService.loaddinStarter('start');
    e.preventDefault();

    if (this.rota_header.descricao == null) {
      this.configService.showAlert("O campo Descrição é obrigatório", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    }
    else if (this.rota_header.estado == null) {
      this.configService.showAlert("O campo Estado é obrigatório", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    }
    else if (this.rota_header.leitor_id == null) {
      this.configService.showAlert("O campo Leitor é obrigatório", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    }
    else if (this.rota_header.provincia_id == null) {
      this.configService.showAlert("O campo Província é obrigatório", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    }
    else if (this.rota_header.municipio_id == null) {
      this.configService.showAlert("O campo Munícipio é obrigatório", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    }
    else {

      if (this.rota_header.id <= 0) {
        this.http.__call('rota-header/create', this.rota_header).subscribe(
          res => {
            if (Object(res).code == 201) {
              this.configService.showAlert(Object(res).message, 'alert-danger', true);
              this.configService.loaddinStarter('stop');
            } else {
              this.configService.showAlert(Object(res).message, 'alert-success', true);
              this.clearFormInputs();
              this.listaRotaRuns()
              this.configService.loaddinStarter('stop')
              this._closeModalAction("closeModalRegistarRota")

            }
          })
      } else {

        this.http.__call('rota-header/update/' + this.rota_header.id, this.rota_header).subscribe(
          res => {
            if (Object(res).code == 201) {
              this.configService.showAlert(Object(res).message, 'alert-danger', true);
              this.configService.loaddinStarter('stop');
            } else {
              this.configService.showAlert(Object(res).message, 'alert-success', true);
              this.clearFormInputs();
              this.listaRotaRuns()
              this.configService.loaddinStarter('stop')
              this._closeModalAction("closeModalRegistarRota")
            }
          })
      }
    }
    ;
  }

  private rotaRuns: any = [];

  private listarRotaRun(id: any) {

    this.lista.id = id;

    this.configService.loaddinStarter('start');
    this.http.call_get('rota-run/getRotasRunByRotaHeader/' + id, null).subscribe(
      response => {
        this.rotaRuns = Object(response).data
        this.configService.loaddinStarter('stop')
      }
    );
  }

  private clearFormInputs() {
    this.rota_header.id = null,
      this.rota_header.descricao = null,
      this.rota_header.data_inicio = null,
      this.rota_header.data_fim = null,
      this.rota_header.leitor_id = null,
      this.rota_header.user_nome = null
  }

  private clearFormRotaRun() {
    this.rotarun.nome_cliente = null,
      this.rotarun.conta_id = null,
      this.rotarun.servico_id = null,
      this.rotarun.id_servico = null
  }


  private setDataLeiturasRotaRun() {

    this.corridasLeiturasArray = [];

    for (let index = 0; index < this.rotaRuns.length; index++) {
      var data = {}

      if (this.rotaRuns[index].estado_rota_slug == "PENDENTE" && this.rotaRuns[index].leitura != null && this.rotaRuns[index].data_leitura != null) {
        data = {
          id: this.rotaRuns[index].id,
          rota_header_id: this.rotaRuns[index].rota_header_id,
          nao_leitura: this.rotaRuns[index].nao_leitura,
          motivo: "",
          classificacao: this.rotaRuns[index].classificacao,
          rota_header: this.rotaRuns[index].rota_header,
          leitor_id: this.rotaRuns[index].leitor_id,
          local_consumo_id: this.rotaRuns[index].local_consumo_id,
          contador_id: this.rotaRuns[index].contador_id,
          numero_serie: this.rotaRuns[index].numero_serie,
          local_instalacao_id: this.rotaRuns[index].local_instalacao_id,
          conta_id: this.rotaRuns[index].conta_id,
          moradia_numero: this.rotaRuns[index].moradia_numero,
          is_predio: this.rotaRuns[index].is_predio,
          predio_id: this.rotaRuns[index].predio_id,
          predio_nome: this.rotaRuns[index].predio_nome,
          cliente_id: this.rotaRuns[index].cliente_id,
          cliente: this.rotaRuns[index].cliente,
          cliente_morada: this.rotaRuns[index].cliente_morada,
          cliente_telefone: this.rotaRuns[index].cliente_telefone,
          leitura: this.rotaRuns[index].leitura,
          ultima_leitura: this.rotaRuns[index].ultima_leitura,
          periodo: this.rotaRuns[index].periodo,
          data_leitura: this.rotaRuns[index].data_leitura,
          estado_rota_id: this.rotaRuns[index].estado_rota_id,
          estado_rota: this.rotaRuns[index].estado_rota,
          estado_rota_slug: this.rotaRuns[index].estado_rota_slug
        }

        this.corridasLeiturasArray.push(data)
      }

      //Inserir os não leitura
      if (this.rotaRuns[index].estado_rota_slug == "PENDENTE" && this.rotaRuns[index].nao_leitura && this.rotaRuns[index].motivo != null && this.rotaRuns[index].data_leitura != null) {
        data = {
          id: this.rotaRuns[index].id,
          rota_header_id: this.rotaRuns[index].rota_header_id,
          nao_leitura: this.rotaRuns[index].nao_leitura,
          motivo: this.rotaRuns[index].motivo,
          classificacao: this.rotaRuns[index].classificacao,
          rota_header: this.rotaRuns[index].rota_header,
          leitor_id: this.rotaRuns[index].leitor_id,
          local_consumo_id: this.rotaRuns[index].local_consumo_id,
          contador_id: this.rotaRuns[index].contador_id,
          numero_serie: this.rotaRuns[index].numero_serie,
          local_instalacao_id: this.rotaRuns[index].local_instalacao_id,
          conta_id: this.rotaRuns[index].conta_id,
          moradia_numero: this.rotaRuns[index].moradia_numero,
          is_predio: this.rotaRuns[index].is_predio,
          predio_id: this.rotaRuns[index].predio_id,
          predio_nome: this.rotaRuns[index].predio_nome,
          cliente_id: this.rotaRuns[index].cliente_id,
          cliente: this.rotaRuns[index].cliente,
          cliente_morada: this.rotaRuns[index].cliente_morada,
          cliente_telefone: this.rotaRuns[index].cliente_telefone,
          leitura: 0,
          ultima_leitura: this.rotaRuns[index].ultima_leitura,
          periodo: this.rotaRuns[index].periodo,
          data_leitura: this.rotaRuns[index].data_leitura,
          estado_rota_id: this.rotaRuns[index].estado_rota_id,
          estado_rota: this.rotaRuns[index].estado_rota,
          estado_rota_slug: this.rotaRuns[index].estado_rota_slug
        }

        this.corridasLeiturasArray.push(data)
      }
    }
  }

  private saveLeiturasRotaRun() {
    this.http.__call('leitura/create', { leituras: this.corridasLeiturasArray })
      .subscribe(
        res => {
          if (Object(res).code == 200) {
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.clearFormInputs()
            this.listaRotaRuns()
            this.listarRotaRun(this.lista.id)

            this.corridasLeiturasArray = []
            this.configService.loaddinStarter('stop');
          } else {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
            this.configService.loaddinStarter('stop');
          }
        }
      ), error => {
        console.log(error)
      }

  }

  private setDataLeitura(item: any) {
  }


  private setDataRotaHeader(item: any) {
    this.title = "Editar Rota";

    this.rota_header.id = item.id;
    this.rota_header.descricao = item.descricao;
    this.rota_header.data_inicio = item.data_inicio;
    this.rota_header.data_fim = item.data_fim;
    this.rota_header.provincia_id = item.provincia_id;
    this.rota_header.municipio_id = item.municipio_id;
    this.rota_header.leitor_id = item.leitor_id;
    this.rota_header.estado = item.estado;

    this.selectBoxMunicipiosByProvincia(item.provincia_id);

    this.gerarPeriodoFinal();

  }

  _initModalCriarRoteiro(rota_header) {
    this.roteiro = rota_header
  }

  private setDataRotaRun(item: any) {

  }

  private gerarPeriodoInicial() {
    let dia = 1
    this.carregando.periodo = 'Carregando...';

    let j = 0;
    for (let i = dia; i <= 31; i++) {
      this.periodo_inicio[j] = i;
      j++;
      this.carregando.periodo = 'Seleccione o Início';
    }
  }
  private gerarPeriodoFinal() {
    this.periodo_fim = [];
    let dia = this.rota_header.data_inicio;
    this.carregando.periodo = 'Carregando...';

    let j = 0;
    for (let i = dia; i <= 31; i++) {
      this.periodo_fim[j] = i;
      j++;
      this.carregando.periodo = 'Seleccione o Fim';
    }
  }


  private ini() {
    this.rota_header = {
      id: null,
      descricao: null,
      data_inicio: null,
      data_fim: null,
      provincia_id: null,
      municipio_id: null,
      leitor_id: null,
      user_nome: null,
      estado: null
    }
  }



  public getConfiguracaos() {

    let result = null;

    const slugs = [
      this.config.provincia_default
    ];

    for (let index = 0; index < slugs.length; index++) {

      this.http.__call('configuracao/getConfiguracaobySlug/' + slugs[index], null)
        .subscribe(response => {
          if (Object(response).code != 200) {
            result = null;
          } else {
            result = Object(response).data;

            if (slugs[index] == this.config.provincia_default) {
              this.rota_header.provincia_id = result.valor;
              this.rotarun.provincia_id = result.valor;
              this.selectBoxMunicipiosByProvincia(result.valor);
            }
          }
        })
    }
  }

  public getRotas() {
    this.http.__call('get/rotas', null)
      .subscribe(res => {
        console.log(res)
        this.rotas = Object(res).data;
      })

  }
  _closeModalAction(closeModal) {
    var action = document.getElementById(closeModal)
    action.click();
  }

  private empresaUser() {
    this.configService.loaddinStarter('start');
    this.http.call_get('empresa/empresa-user', null).subscribe(
      response => {
        this.DadosEmpresa = Object(response).data;
      }
    );
  }

  exportAsPDF(): void {
    var file = document.getElementsByClassName("tablePDF")[0]
    this.reportRoteiro.reportRoteiro(file, this.DadosEmpresa, 'save', 'Rotas', this.items.length);
  }

  exportAsXLSX(): void {
    var CurrentDate = new Date();

    var keys = [
      { key: 'rota_header', width: 35, style: { font: { name: 'Calibri' } } },
      { key: 'contrato_id', width: 25 },
      { key: 'cliente', width: 30 },
      { key: 'municipio', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'cil', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'morada_format', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'numero_serie', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'status', width: 20, style: { font: { name: 'Calibri' } } },
    ];

    var Cols = ["Rota", "Contrato", "Cliente", "Município", "Cil", "Morada", "Contador", "Estado",]
    var title = 'Lista de Roteiros';
    var nameFile = "Lista de Roteiros -" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    this.excelService.excels(this.items, nameFile, this.DadosEmpresa.logotipo, keys, Cols, title, 5, 8, 30, 3)
  }

}
