import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange, NgModule } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpService } from 'src/app/providers/http/http.service';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-create-or-edit-distrito',
  templateUrl: './create-or-edit-distrito.component.html',
  styleUrls: ['./create-or-edit-distrito.component.css']
})
export class CreateOrEditDistritoComponent implements OnInit {

  public currentUser: any;
  @Input() title: string = "Registar Distrito";

  @Input() distrito = {
    id: null,
    nome: null,
    municipio_id: null,
    provincia_id: null,
    abreviatura: null,
    is_active: null,
    user_id: null,
  };


  @Input() municipio = {
    id: null,
    nome: null
  };

  @Input() distritos: any = [];

  private provincias: any = [];
  @Input() municipios: any = [];

  private distritosArray = [];
  private distritoRow = {
    id: null,
    nome: null,
    abreviatura: null,
    has_distrito: null,
    municipio_id: null,
    municipio: null,
    provincia_id: null,
    provincia: null,
    user_id: null,
    user: null

  };
  private distritoRowValid: boolean = false;
  @Input() addRows: boolean = true;

  @Input() showCreateDistrito: boolean = true;

  @Input() distritoModal: boolean = false;


  //@Input() simpleFormDistrito: FormGroup;

  submitted = false;
  private loading: boolean = false;

  //@Output() private loadListDistrito = new EventEmitter<any>();

  constructor(private auth: AuthService, private http: HttpService, private configService: ConfigService, private formBuilder: FormBuilder) {
    //this.createForm();
    this.currentUser = this.auth.currentUserValue;
  }


  ngOnInit() {

    //console.log(this.distrito);
    //console.log(this.municipios);
    if (this.provincias.length <= 0) {
      this.selectBoxProvincias();
    }

    if (this.addRows == false) {
      this.selectBoxProvincias();
    }

    this.onReset();
    this.distrito.user_id = this.currentUser.user.id;

    //console.log(this.distrito);
  }

  save() {

    if (!this.addRows) {
      this.distritosArray.unshift({ ...this.distrito });

      console.log(this.distritosArray);
    }

    for (let i = 0; i < this.distritosArray.length; ++i) {
      this.distritosArray[i].user_id = this.currentUser.user.id;
      if (this.distritosArray[i].id == null) {
        this.http.__call('distrito/create', this.distritosArray[i]).subscribe(
          response => {

            if (Object(response).code == 200) {
              this.configService.showAlert(Object(response).message, "alert-success", true);
              this.distritosArray = [];
              this.onReset();

            } else {
              this.configService.showAlert(Object(response).message, "alert-danger", true);
            }

          }
        );

      }
      else {

        this.http.__call('distrito/update/' + this.distritosArray[i].id, this.distritosArray[i]).subscribe(
          response => {

            if (Object(response).code == 200) {
              this.configService.showAlert(Object(response).message, "alert-success", true);
              this.distritosArray = [];
            } else {
              this.configService.showAlert(Object(response).message, "alert-danger", true);
            }

          }
        );
      }
    }

  }
  /*
    createDistrito(item) {
      this.http.__call('distrito/create', item).subscribe(
        response => {
  
          if (Object(response).code == 200) {
            this.configService.showAlert(Object(response).message, "alert-success", true);
            this.distritosArray = [];
            this.onReset();
  
          } else {
            this.configService.showAlert(Object(response).message, "alert-danger", true);
          }
  
  
        }
      );
    }
   */
  createOrEdit(uri: any, formulario: any, isCreate: boolean) {

    // TODO: usado para fazer a requisição com a api de criação de object
    this.http.__call(uri, formulario).pipe(first()).subscribe(
      response => {
        this.submitted = false;
        this.loading = false;

        if (Object(response).code == 200) {
          this.openEndDistritoModal(true);
          this.reloadDistritos();
        }

        if (isCreate) {
          formulario.reset();
        }

        //this.distritos = Object(response).data;
      },
      error => {
        this.submitted = false;
        this.loading = false;
      });
  }

  setDataDistrito({ item }) {

    if (this.provincias.length <= 0) {
      this.selectBoxProvincias();
    }
    //console.log(this.distrito);
    //console.log(item);

    if (item.id > 0) {
      this.title = "Editar Distrito";

      this.distrito.id = item.id;
      this.distrito.nome = item.nome;
      this.distrito.abreviatura = item.abreviatura;
      this.distrito.municipio_id = item.municipio_id;
      this.distrito.is_active = item.is_active;
      this.distrito.user_id = this.currentUser.user.id;

    } else {
      this.title = "Registar Distrito";
      this.onReset();
    }

    //console.log(this.distrito);
  }

  onReset() {
    this.submitted = false;

    this.distrito.id = null;
    this.distrito.nome = null;
    this.distrito.abreviatura = null;
    this.distrito.municipio_id = null;
    this.distrito.is_active = null;
    this.distrito.user_id = this.currentUser.user.id;
  }

  private async appendRowDistrito() {

    var validar = 0;

    const loadedStateCallback = () => {
      //console.log(this.distrito);
      this.distritoRow.nome = this.distrito.nome;
      this.distritoRow.abreviatura = this.distrito.abreviatura;
      this.distritoRow.user_id = this.currentUser.user.id;

      //console.log(this.distritoRow);

      this.distritoRowValid = this.distritoRowValidation(this.distritoRow);
      if (this.distritoRowValid)
        this.distritosArray.unshift({ ...this.distritoRow });
    }

    await this.getMunicipioById(this.distrito.municipio_id, loadedStateCallback);

    //console.log(this.distritosArray);

  }

  private deleteRowDistritoAppended(row): void {

    for (let i = 0; i < this.distritosArray.length; ++i) {

      if ((this.distritosArray[i].municipio_id == row.municipio_id) && (this.distritosArray[i].nome == row.nome)) {
        this.distritosArray.splice(i, 1);
        //console.log(this.distritosArray[i]);
      }

    }


  }


  private distritoRowValidation(row): boolean {

    if (this.distritosArray.length == 0)
      return true;

    for (let i = 0; i < this.distritosArray.length; ++i) {
      //console.log(this.distritosArray[i]);
      if (this.distritosArray[i].municipio_id == row.municipio_id && this.distritosArray[i].nome == row.nome) {
        return false;
      }
    }

    return true;
  }


  private reloadDistritos() {

    /*     this.http.call_get('distrito/listagem/', null).subscribe(
          response => {

            this.distritos = Object(response).data;
          }
        ); */
  }

  private selectBoxProvincias() {
    this.http.call_get('provincia/selectBox', null).subscribe(
      response => {
        //console.log(Object(response).data);

        this.provincias = Object(response).data;
      }
    );
  }

  private getMunicipioById(id: any, callback: Function) {

    this.http.call_get('municipio/getMunicipioById/' + id, null).subscribe(
      response => {
        this.distritoRow.municipio_id = Object(response).data.id;
        this.distritoRow.municipio = Object(response).data.nome;
        this.distritoRow.provincia_id = Object(response).data.provincia_id;
        this.distritoRow.provincia = Object(response).data.provincia;
        this.distritoRow.has_distrito = Object(response).data.has_distrito;
        callback()
      }
    );

  }


  private selectBoxMunicipiosByProvincia() {

    //console.log(this.distrito);

    this.http.call_get('municipio/getMunicipiosByProvincia/' + this.distrito.provincia_id, null).subscribe(
      response => {
        //console.log(Object(response).data);

        this.municipios = Object(response).data;
      }
    );
  }

  private openEndDistritoModal(flag: boolean): void {
    this.distritoModal = flag;
  }

  public loadListEstabelecimentos(distritos) {
    this.distritos = distritos;
  }
  /*   resetUser(id){
      this.simpleFormMunicipio.patchValue({
        user_id: id,
      })
    } */
}
