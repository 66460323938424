import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

import { Router,ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ExcelService } from 'src/app/services/excel.service';
import { RelCobracaGlobalService } from 'src/app/components/report-at/relatorios/financeira/rel-cobraca-global.service';

import * as moment from 'moment';

@Component({
  selector: 'app-report-pagamento-global',
  templateUrl: './report-pagamento-global.component.html',
  styleUrls: ['./report-pagamento-global.component.css']
})
export class ReportPagamentoGlobalComponent implements OnInit {

 public titulo = "Facturação Pagamento ";
  loading: boolean = false;
  private meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

  private cobrancas: any = [];
  private direccaos: any = []
  private lojas: any = []
  private produtos: any = []
  private anos: any = []
  private provincias: any = [];

  private disabledButton = true
  submitted = false;
  simpleForm: FormGroup;
  tipoPagamento:string;

  constructor(private formBuilder: FormBuilder, private http: HttpService, private route: ActivatedRoute,private configService: ConfigService, private excelService: ExcelService, private relCobracaGlobal: RelCobracaGlobalService) 
  { this.route.paramMap.subscribe(params => {
    this.tipoPagamento =(params.get('name') =='pre-pago'? 'PRE-PAGO' : 'POS-PAGO'); 
  }); 
  this.createForm(); }

  ngOnInit() {
    this.getProvincias();
    this.gerarAno();
    this.getLojas();
    this.getProdutos();
    this.direccaosSelectBox();
  }

  
  createForm() {
    this.simpleForm = this.formBuilder.group({
      ano: [null, Validators.required],
      direccao: [null, Validators.required],
      tipoFacturacao: [null],
      loja: [null, Validators.required],
      produto: [null, Validators.required],
      loja_nome: [null],
      produto_nome: [null],
      province: [null, Validators.required]
    });
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.simpleForm.controls;
  }
  onSubmit() {

    this.submitted = true;
    // parar aquei se o simpleFormulário for inválido
    if (this.simpleForm.invalid) {
      return;
    }
    this.simpleForm.patchValue({ tipoFacturacao: this.tipoPagamento});
    const uri = 'relatorio/financeira/pagamento';
    this.createOrEdit(uri, this.simpleForm);
  }

  /**
  * @name "relatorioFacturacaoRealizadaCobrancaGlobal"
  * @descriptio "Esta Função permite gerar relatorio Facturacao Realizada Cobrança Global"
  * @author "caniggiamoreira@gmail.com"
  * @param start
  * @param end
  */
  createOrEdit(uri: any, simpleFormulario: FormGroup) {
    this.cobrancas = []
    this.loading = true;
    this.disabledButton = true;
    // TODO: usado para fazer a requisição com a api de criação de organismo
    this.http.__call(uri, simpleFormulario.value).subscribe(
      response => {
        this.cobrancas = Object(response).data;
        this.loading = false;
        if (this.cobrancas.length != 0) {
          this.disabledButton = false;
        }
      }
    );
  }

  exportAsXLSX(): void {
    var CurrentDate = new Date();
    var nameFile = "FP" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " " 
      + moment(CurrentDate).format('H') + ":"+moment(CurrentDate).format('m')
    console.log(document.getElementsByClassName("tableCobranca")[0]);
    this.excelService.exportAsExcelFile(document.getElementsByClassName("tableCobranca")[0], nameFile);
  }
  exportAsPDF(): void {
    this.relCobracaGlobal.relatorioCobracaGlobal(this.cobrancas, this.simpleForm.value,this.titulo,'save');
  }

  imprimirPDF(): void {
    this.relCobracaGlobal.relatorioCobracaGlobal(this.cobrancas, this.simpleForm.value, this.titulo);
  }


  private direccaosSelectBox() {
    this.http.call_get('direccao/selectBox', null).subscribe(
      response => {
        this.direccaos = Object(response).data;
      }
    );
  }

  private gerarAno() {
    var fecha = new Date();
    var anyo = fecha.getFullYear();

    let j = 0;
    for (let i = anyo; i >= 2000; i--) {
      this.anos[j] = i;
      j++;
    }
  }

  private getLojas() {
    this.http.call_get('loja/selectBox', null).subscribe(
      response => {
        this.lojas = Object(response).data
      }
    );
  }

  private getProdutos() {
    this.http.call_get('artigo/selectProdutos', null).subscribe(
      response => {
        this.produtos = Object(response).data
      }
    );
  }

  changeProduto() {
    this.simpleForm.patchValue({
      loja_nome: null
    });
    this.produtos.forEach(element => {
      if (element.id == this.simpleForm.getRawValue().produto) {
        this.simpleForm.patchValue({
          produto_nome: element.nome
        });
      }
    });
  }
  changeLoja() {
    this.simpleForm.patchValue({
      loja_nome: null
    });
    this.lojas.forEach(element => {
      if (element.id == this.simpleForm.getRawValue().loja) {
        this.simpleForm.patchValue({
          loja_nome: element.nome
        });
      }
    });
  }

  private getProvincias() {
    this.http.call_get('provincia/selectBox', null).subscribe(
      response => {
        this.provincias = Object(response).data
      }
    );
  }



}
