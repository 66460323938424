import { Injectable, Input, EventEmitter } from '@angular/core';
import { ApiService } from 'src/app/providers/http/api.service';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import * as extenso from 'extenso';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Container, removePercent, setGlobalHeight } from '../../../services/styled-pdf'
import { toDate, format } from 'date-fns-tz'

@Injectable({
  providedIn: 'root'
})
export class ReciboService {

  @Input() app_environment: null;

  public siglaEmpresa: any

  constructor(private http: ApiService, private auth: AuthService) {
    this.app_environment = this.auth.getAppEnvironment();
    if (this.app_environment == null) {
      var url = require('url');
      var app_url = url.parse(environment.app_url, true);
      this.app_environment = app_url.host;
    }
  }

  public imprimirRecibo(id, via = 'Original', report = 'imprimir') {
    this.http.get('recibos/printerRecibo/' + id).subscribe(
      response => {

        const dados = Object(response).data;
        this._imprimirPDFRecibo(
          dados.localconsumo,
          dados.recibo,
          dados.factura,
          dados.cliente,
          dados.tipos_identidades,
          dados.user,
          dados.linha_pagamentos,
          dados.contrato,
          dados.leituras,
          dados.contas_bancarias,
          dados.lojas,
          report,
          via
        )
      }, error => {

      }
    );
  }

  public _imprimirPDFRecibo(
    localconsumo: any,
    recibo: any,
    facturas: any[],
    cliente: any,
    tipos_identidades: any,
    user: any,
    linha_pagamentos: any,
    contrato: any,
    leituras: [],
    contas_bancarias: any,
    lojas: any,
    report: string = 'imprimir',
    original: any = 'Original'
  ) { 
    let page = 1, totalPage = [1]
    let container = new Container()
    let img_logotipo = user.logotipo;
    function numberFormat(number) {
      return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2 }).format(number || 0).replace('€', '').trim();
    }
    function line(top, bottom, marginRight = "100%") {
      container.hr({
        top: top + "%",
        marginLeft: "2%",
        marginRight: marginRight,
        bottom: bottom + "%",
      });
    }
    function img(imgData, container) {
      let style = {
        marginTop: "2%",
        width: "15%",
        height: "13%",
        marginLeft: "3%",
      };
      container.Image(imgData, style);
    }
    img(img_logotipo, container);
    
    function facturaTitle(container, text) {
        let style = {
            fontWeigth: 'bold',
            fontSize: 8,
            marginTop: '4%',
            marginLeft: '70%',
            subTitle: {
                    fontWeigth: 'bold',
                    fontSize: 7,
                     marginTop: '8%',
                     marginLeft: '70%',
            },
        }
        container.p(text, style)
        style.subTitle.fontWeigth = 'normal'
        container.p('ORIGINAL', style.subTitle)
    }
    facturaTitle(container, 'RECIBO CLIENTE')
    
    function header(data, container) {
        let style = {
            companyTitle: {
                marginTop: '16%',
                marginLeft: '3%',
                fontWeigth: 'bold',
                fontSize: 8,
            },
             streat: {
                marginTop: '18%',
                marginLeft: '3%',
                fontWeigth: 'bold',
                fontSize: 8,
            },
             nif: {
                marginTop: '20%',
                marginLeft: '3%',
                fontWeigth: 'bold',
                fontSize: 8,
                value: {
                    marginTop: '20%',
                    marginLeft: '7%',
                    fontWeigth: 'bold',
                    fontSize: 8
               }
            }, 
            phone: {
                marginTop: '22%',
                marginLeft: '3%',
                fontWeigth: 'bold',
                fontSize: 8,
                value: {
                    marginTop: '22%',
                    marginLeft: '11%',
                    fontWeigth: 'bold',
                    fontSize: 8
               }
             }, 
             fax: {
                marginTop: '22%',
                marginLeft: '24%',
                fontWeigth: 'bold',
                fontSize: 8,
                value: {
                    marginTop: '22%',
                    marginLeft: '29%',
                    fontWeigth: 'bold',
                    fontSize: 8
               }
             }, 
        }
        container.p(data.companyName, style.streat)
        container.p(data.addressDetail, style.companyTitle)
        container.p('NIF:', style.nif)

        let nif = tipos_identidades.find(obj => obj.nome == 'NIF')
        container.p('' + (nif ? nif.numero_identidade : ""), style.nif.value)
         container.p('Telefone:', style.phone)
        container.p(data.company_telefone, style.phone.value)
        container.p('Fax:', style.fax)
        container.p(data.telefone, style.fax.value)
    }
     header(user, container) 
    
    function bordInfo(data, container) {
         let style = {
            div: {
                marginLeft: '45.5%',
                width: '35%',
                heigth: '13%',
                padding: '15%',
                border: [0,0,0],
                text: {
                    width:0,
                    marginTop: '15%',
                    marginLeft: '67%',
                    fontSize: 8,
                    fontWeigth: 'bold'
                },
            },
         }
   
         container.div(style.div)
         container.p('Exmo(a) Senhor(a)', style.div.text)
         style.div.text.marginTop = '17%'
         style.div.text.fontWeigth = 'normal'
         container.p(data.nome_do_cliente, style.div.text)

          style.div.text.marginTop = '18.5%'
          container.p('RUA B', style.div.text)
          style.div.text.marginTop = '20%'
          let morada = (data.municipio ? data.municipio.nome : '') + ' ' + (data.bairro ? data.bairro : '')
          container.p(morada, style.div.text)

           style.div.text.marginTop = '18.5%'
           style.div.text.marginLeft = '75%'
         container.p(data.morada_do_cliente, style.div.text)
     }
    bordInfo({...recibo, ...cliente}, container)
    line(26, 26)
    function footer() {
     let style = {
         th: {
                marginLeft: '2%',
                width: '97%',
                heigth: '80%',
                padding: '4%',
                border: [201, 201, 201],
                bgColor: [201, 201, 201],
                text: {
                    width: 195,
                    marginTop: '82%',
                    marginLeft: '5%',
                    fontSize: 8,
                    fontWeigth: 'bold'
                }
         },
         td: {
            marginLeft: '2%',
            width: '97%',
            heigth: '54%',
            padding: '4%',
            border: [250, 245, 245],
            text: {
                width:0,
                marginTop: '56%',
                marginLeft: '4%',
                fontSize: 8,
                fontWeigth: 'normal'
            }
         },
            pagination: {
                fontWeigth: 'bold',
                fontSize: 7,
                marginTop: '85%',
                marginLeft: '90%',
            }
     }
     container.div(style.th)
    container.div(style.td)
    
    
    container.p('REFERENTE A:', style.th.text)
    
    
    style.th.text.marginLeft = '88%'
    container.p(String(recibo.valor_recebido) + ' AOA', style.th.text)
     container.p(`Página ${page} de ${totalPage}`, style.pagination)
     
    style.th.text.marginLeft = '3%'
    style.th.text.marginTop = '88%'
    style.th.text.fontWeigth = 'normal'
    let footerText = `A EPAL - EP declina quaisquer responsabilidades que possam advir por prejuizos causados por roturas ou torneiras abertas em caso de
    restabelecimento de consumo na ausência do Cliente. Se qualquer serviço não puder ser efectuado por motivo estranho à EPAL - EP, só será levado
    a efeito após nova requisição e mediante pagamento do respectivo custo..`

    container.p(footerText, style.th.text)
    style.th.text.marginTop = '94%'
    style.th.text.marginLeft = '45%'
    style.th.text.fontWeigth = 'bold'
     container.p('Processado por computador', style.th.text)
    }
    footer()
    function abastecimentoSpace(data) {
        let style = {
             marginTop: '26%',
             marginLeft: '3%',
             fontWeigth: 'bold',
             fontSize: 8,
        }
        container.p('LOCAL DO ABASTECIMENTO', style)
        style.marginTop = '28%'
         style.fontWeigth = 'normal'
        container.p('RUA B', style)
         style.marginTop = '30%'
        container.p('2ª APT 23(B12)-QT MARACA', style)
          style.marginTop = '28%'
          style.marginLeft = '30%'
        container.p(data.morada_do_cliente, style)
    }
   // abastecimentoSpace(recibo) 

   function tablePagamento() {

   }
    
    function table1(data) {
     
      let style = {
        th: {
               marginLeft: '2%',
               width: '14.3%',
               heigth: '30%',
               padding: '4%',
               border: [0,0,0],
               bgColor: [201, 201, 201],
               text: {
                   width:0,
                   marginTop: '32%',
                   marginLeft: '5%',
                   fontSize: 8,
                   fontWeigth: 'bold'
               }
        },
        td: {
           marginLeft: '2%',
           width: '14.3%',
           heigth: '32.9%',
           padding: '6%',
           border: [0,0,0],
           text: {
               width: 0,
               marginTop: '34.5%',
               marginLeft: '4%',
               fontSize: 8,
               fontWeigth: 'normal'
           },
        }
    }
     container.div(style.th)
    container.div(style.td)
     style.th.marginLeft = '12%'
      style.td.marginLeft = '12%'
     container.div(style.th)
    container.div(style.td)
     style.th.marginLeft = '22%'
      style.td.marginLeft = '22%'
     container.div(style.th)
    container.div(style.td)
    
    
    container.p('CÓD. CLIENTE',style.th.text)
    //style.td.text.width = 26
    container.p(String(data.cliente_id), style.td.text)

    style.td.text.width = 0
    style.th.text.marginLeft = '18%'
    style.td.text.marginLeft = '18%'
     container.p('CÓD. ENTIDADE', style.th.text)
     style.td.text.width = 26
     container.p(data.nome_do_cliente, style.td.text)
    
     style.td.text.width = 0
    style.th.text.marginLeft = '32%'
    container.p('Nº CONTRIBUINTE', style.th.text)
    //style.td.text.width = 26
    style.td.text.marginLeft = '32%'
    container.p(data.taxRegistrationNumber, style.td.text)
    
    style.td.text.width = 0
    }

    function dateTimeZone(date) {
      if(!date) return '';
      const parisDate = toDate(date)
      const pattern = "yyyy-MM-dd"
      const timeOutput = format(parisDate, pattern, { timeZone: 'UTC/GMT+1' })
      return timeOutput.slice(0, 16);
    }

    table1({...recibo, ...user, ...{ cliente_id: cliente.id }})
    function table2(data) {
      let style = {
        th: {
               marginLeft: '40%',
               width: '14.3%',
               heigth: '30%',
               padding: '4%',
               border: [0,0,0],
               bgColor: [201, 201, 201],
               text: {
                   width:0,
                   marginTop: '32%',
                   marginLeft: '60%',
                   fontSize: 8,
                   fontWeigth: 'bold'
               }
        },
        td: {
           marginLeft: '40%',
           width: '14.3%',
           heigth: '32.9%',
           padding: '4%',
           border: [0,0,0],
           text: {
               width:0,
               alignText: 'center',
               marginTop: '34.5%',
               marginLeft: '61%',
               fontSize: 8,
               fontWeigth: 'normal'
           },
        }
    }
     container.div(style.th)
    container.div(style.td)
     style.th.marginLeft = '50.1%'
      style.td.marginLeft = '50.1%'
     container.div(style.th)
    container.div(style.td)
     style.th.marginLeft = '60.1%'
      style.td.marginLeft = '60.1%'
     container.div(style.th)
    container.div(style.td)
    
    
    container.p('VALOR',style.th.text)
    container.p(String(data.valor_recebido), style.td.text)
    
    style.th.text.marginLeft = '74%'
    style.td.text.marginLeft = '74%'
     container.p('RECIBO Nº', style.th.text)
     container.p(String(data.recibo_id), style.td.text)
    
    style.th.text.marginLeft = '86%'
    container.p('DATA DE EMISSÃO', style.th.text)
    style.td.text.marginLeft = '90%'
    container.p(dateTimeZone(recibo.created_at), style.td.text)
    
    
    }
    table2(recibo)

    function pagamentoTable() {
      let style = {
        th: {
               marginLeft: '2%',
               width: '97%',
               heigth: '39%',
               padding: '4%',
               border: [201, 201, 201],
               bgColor: [201, 201, 201],
               text: {
                   marginTop: '41%',
                   marginLeft: '5%',
                   fontSize: 8,
                   fontWeigth: 'bold'
               }
        },
        td: {
           marginLeft: '2%',
           width: '97%',
           heigth: '54%',
           padding: '4%',
           text: {
               marginTop: '53.5%',
               marginLeft: '4%',
               fontSize: 8,
               fontWeigth: 'normal'
           },
        }
       }
       let facturas = [
           { 
               factura_sigla: 'ft', 
               factura_id: 44,
               created_at: "31/04/2022",
               novo_valor_aberto: 299,
               totalComImposto: 500,
               valor_saldado: 400,
               total: 900
           }
           ]
      style.th.padding = '2%',
      style.th.heigth = '38.5%',
      style.th.text.marginTop = '39.5%'
      container.div(style.th)
      container.p('MEIOS DE PAGAMENTOS', style.th.text)
      style.th.padding = '4%',
      style.th.heigth = '41%',
      style.th.text.marginTop = '43%',
      container.div(style.th)
      container.div( style.td)
       container.p('DESCRIÇÃO', style.th.text)
     
       style.th.text.marginLeft = '35%'
       container.p('BANCO', style.th.text)
   
       style.th.text.marginLeft = '54%'
       container.p('REFERÊNCIA', style.th.text)
   
       style.th.text.marginLeft = '84%'
       container.p('VALOR RECEBIDO', style.th.text)
   
   
       let header =  removePercent(style.th.heigth) + 2.9
       let content = removePercent(style.th.text.marginTop) + 2.9 
       let marginTable = 0
     

        for (let linha of linha_pagamentos) {
         style.td.heigth = (header )  + '%'
         style.td.text.marginTop = (content)  + '%'
         style.td.text.marginLeft = '4%'
         container.p('' + linha.designacao,  style.td.text)
   
         style.td.text.marginLeft = '36%'
         container.p('' + (linha.abreviatura == null ? '' : linha.abreviatura), style.td.text)
   
         style.td.text.marginLeft = '55%'
         container.p('' + (linha.referencia == null ? '' : linha.referencia), style.td.text)
   
         style.td.text.marginLeft = '86%'
         container.p('' + (numberFormat(linha.valor_recebido)), style.td.text)
   
         //end content
          content += 2.9
          header += 2.9
          marginTable += 2.9

          if(Math.ceil(header) >= 60) {
            container.newPageMiddle()
            footer()
            header = 4
            content = 4
           }
       }
       console.log('getGlobalHeight()',Math.ceil(container.getGlobalHeight()), header)

        container.setGlobalHeight(header)
         style.th.text.marginTop = '3%',
        style.th.text.marginLeft = '3%'
       container.p(`Adiantamento registado no valor de: ${numberFormat(recibo.valorCriadoAdiantamento)}`, style.th.text)

   }
   pagamentoTable()
    
    function table3() {
      let style = {
        th: {
               marginLeft: '2%',
               width: '97%',
               heigth: '4%',
               padding: '4%',
               border: [201, 201, 201],
               bgColor: [201, 201, 201],
               text: {
                   marginTop: '6%',
                   marginLeft: '5%',
                   fontSize: 8,
                   fontWeigth: 'bold'
               }
        },
        td: {
           marginLeft: '2%',
           width: '97%',
           heigth: '8%',
           padding: '4%',
           text: {
               marginTop: '8.5%',
               marginLeft: '4%',
               fontSize: 8,
               fontWeigth: 'normal'
           },
        }
    }

     container.div(style.th)
    container.div(style.td)
    container.p('REFERENTE A:', style.th.text)

    style.th.text.marginLeft = '49%'
    container.p('BASE', style.th.text)

    style.th.text.marginLeft = '60%'
    container.p('IVA', style.th.text)

    style.th.text.marginLeft = '74%'
    container.p('VALOR', style.th.text)


    style.th.text.marginLeft = '88%'
    container.p('VALOR PAGO', style.th.text)

    let header =  removePercent(style.th.heigth) + 2.9
    let content = removePercent(style.th.text.marginTop) + 2.9 

    for(let i = 0; i < facturas.length; i++) {
      style.td.heigth = (header )  + '%'
      style.td.text.marginTop = (content)  + '%'
      style.td.text.marginLeft = '4%'
      //content
      container.p(facturas[i].factura_sigla,  style.td.text)
      style.td.text.marginLeft = '18%'
      container.p(String(facturas[i].factura_id), style.td.text)
      
      style.td.text.marginLeft = '25%'
      container.p('Periodo de Facturação ' + dateTimeZone(facturas[i].created_at), style.td.text)

      style.td.text.marginLeft = '49%'
      container.p(String(facturas[i].novo_valor_aberto), style.td.text)

      style.td.text.marginLeft = '60%'
      container.p(String(facturas[i].totalComImposto), style.td.text)

      style.td.text.marginLeft = '74%'
      container.p(String(facturas[i].valor_saldado), style.td.text)
      
      style.td.text.marginLeft = '88%'
      container.p(String(facturas[i].total + ' AOA'), style.td.text)

      //end content
       content+=2.9
       header +=2.9
      if(header >= 75) {
          container.addPage()
          header = 4
          content = 4
          footer()
          page ++
          totalPage[0]++
        }
    }
   
    //globalHeight = header
    container.setGlobalHeight(header)
    
    style.td.text.marginTop = '2%'
    style.td.text.marginLeft = '3%'
    style.td.text.fontWeigth = 'bold'
    container.p('Recebemos o valor de: ', style.td.text)
    style.td.text.fontWeigth = 'normal'
    style.td.text.marginLeft = '18%'

    let valor = extenso('' + numberFormat(recibo.total), { locale: 'pt', mode: 'currency', currency: { type: 'EUR' }, decimal: 'formal' });
   
    container.p(valor.replace(/(euros?)/gi, 'kwanzas'), style.td.text)
    
    style.td.text.fontWeigth = 'bold'
    style.td.text.marginTop = '4%'
    style.td.text.marginLeft = '3%'
    container.p('IVA aplicado a 14%', style.td.text)
    
    }
    table3()
    container.save(recibo.recibo_sigla + '.pdf', 'print')
  }

  public imprimirPDFRecibo(
    localconsumo: any,
    recibo: any,
    facturas: any[],
    cliente: any,
    tipos_identidades: any,
    user: any,
    linha_pagamentos: any,
    contrato: any,
    leituras: [],
    contas_bancarias: any,
    lojas: any,
    report: string = 'imprimir',
    original: any = 'Original'
  ) {
    var numberPage = 1;
    var img_logotipo = user.logotipo;
    var doc = new jsPDF();
    doc.addImage(img_logotipo, 'JPEG', 8, 14, 28, 24)

    doc.setFontSize(8);
    doc.setFont("calibri");
    doc.setTextColor(0);
    doc.text('' + (user.companyName || ''), 37, 20);
    doc.setTextColor(0);
    doc.text('' + (user.addressDetail || ''), 37, 25);
    doc.text('NIF: ' + (user.taxRegistrationNumber || ''), 37, 30);
    doc.text('Email: ' + (user.company_email || ''), 37, 35);
    doc.text('Telefone: ', 95, 30);
    doc.text('' + (user.company_telefone || ''), 107, 30);
    doc.text('WebSite: ', 95, 35);
    doc.setTextColor(0, 0, 255);
    doc.text('' + (user.site || ''), 107, 35);

    doc.setFontSize(8);
    doc.setDrawColor(0);
    doc.setFillColor(255);
    doc.rect(145, 14, 55, 23, 'B');

    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.text('RECIBO N.º', 148, 20);
    doc.text('Data Emissão:', 148, 25);
    doc.text('Via: ' + original, 148, 35);

    doc.setFontSize(8);
    doc.setFontType("normal");
    doc.text('' + (recibo.recibo_sigla || ''), 168, 20);
    doc.text('' + moment(recibo.created_at).format("DD/MM/YYYY"), 168, 25);

    doc.setFontSize(8);
    doc.setFont("calibri");
    doc.setFontType("normal");
    doc.text(recibo.nome_do_cliente ? recibo.nome_do_cliente.trimStart() : '', 120, 52);
    let text_local = "";// local_consumo.is_predio ? `, Prédio ${local_consumo.predio_nome ? local_consumo.predio_andar : ''}  ${local_consumo.moradia_numero}` : ` ${local_consumo.moradia_numero ? `, Residência nº ${local_consumo.moradia_numero}` : ''}`

    let full_morada = `${recibo.morada_do_cliente} ${text_local}`
    doc.text((recibo.morada_do_cliente == null ? '' : full_morada), 120, 57, { maxWidth: 80 });

    let desc_municipio = cliente.municipio ? cliente.municipio.nome : ''
    doc.text('' + desc_municipio, 120, 66.5);
    /*
    doc.text(recibo.nome_do_cliente.trimStart(), 148, 52);
    doc.text(recibo.morada_do_cliente, 148, 57);
    */

    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.rect(12, 75, 40.5, 3.5, 'B');
    doc.setFontType("bold");
    doc.text('CLIENTE N.º: ' + cliente.id, 13, 77.5);
    doc.text(recibo.nome_do_cliente ? recibo.nome_do_cliente.trimStart() : '', 13, 83);
    doc.text('NIF: ', 13, 87);
    doc.setFontType("normal");
    let nif = tipos_identidades.find(obj => obj.nome == 'NIF')
    doc.text('' + (nif ? nif.numero_identidade : ""), 21, 87);

    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.rect(12, 100, 182, 5, 'B');
    doc.setFontSize(8);
    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.text('RECIBO  N.º', 13, 103.5);
    doc.text(' ' + recibo.recibo_sigla + ' de  ' + moment(recibo.created_at).format("DD/MM/YYYY"), 32, 103.5);
    doc.setFontType("normal");

    doc.setDrawColor(0, 0, 0);
    doc.setLineWidth(0.1);
    doc.line(12, 104.5, 194, 104.5); // horizontal line

    doc.setFontType("bold");
    doc.text('Nº Factura', 13, 108);

    //doc.text('Contrato', 45, 108);
    //doc.text('Cil', 65, 108);

    doc.text('Valor Imposto.', 92, 108);
    doc.text('Valor Em Aberto', 115, 108);

    doc.text('Total Pago', 145, 108);
    doc.text('Estado Factura', 175, 108);
    doc.setTextColor(0);
    doc.setFontType("normal");

    doc.setDrawColor(0, 0, 0);
    doc.setLineWidth(0.1);
    doc.line(12, 109, 194, 109); // horizontal line
    doc.setTextColor(0);

    var posY = 115;
    var totalImposto = 0;

    for (var i = 0; i < facturas.length; i++) {
      const element = facturas[i];

      doc.text('' + element.factura_sigla, 13, posY);
      //doc.text('' + (element.contrato_id == null ? '' : element.contrato_id), 45, posY);
      //doc.text('' + (localconsumo == null ? '' : localconsumo.cil), 65, posY);
      doc.text('' + this.numberFormat(element.totalComImposto), 100, posY, 'right');
      doc.text('' + this.numberFormat((element.novo_valor_aberto == null ? element.valor_aberto : element.novo_valor_aberto)), 128, posY, 'right');
      doc.text('' + this.numberFormat(element.valor_saldado), 155, posY, 'right');
      doc.text('' + (element.novo_valor_aberto == null ? (element.pago == 0 ? 'Não Saldado' : 'Saldado') : (element.novo_valor_aberto == 0 ? 'Saldado' : 'Não Saldado')), 190, posY, 'right');
      posY += 5;

      totalImposto += element.valor_imposto;

      const hasReachedPageLimit = (posY) >= 275

      if (hasReachedPageLimit) {
        //doc.line//(12, posY + 4.5, 194, posY + 4.5); // horizontal line
        posY = 10;
        footer()
        doc.addPage();
      }

    }


    doc.setDrawColor(0, 0, 0);
    doc.setLineWidth(0.1);
    doc.line(12, posY + 4.5, 194, posY + 4.5); // horizontal line

    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.rect(12, posY + 5, 182, 5, 'B');
    doc.text('TOTAL RECIBO', 13, posY + 8);
    doc.text('' + this.numberFormat(recibo.total), 155, posY + 8, 'right');
    // TOTAL RECEBIDO EM EXTENSO
    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.rect(12, posY + 12, 182, 5, 'B');
    doc.text(13, posY + 15, doc.splitTextToSize('VALOR POR EXTENSO', 50));
    var valor = extenso('' + this.numberFormat(recibo.total), { locale: 'pt', mode: 'currency', currency: { type: 'EUR' }, decimal: 'formal' });
    doc.text('' + valor.replace(/(euros?)/gi, 'kwanzas') + '', 155, posY + 15, 'right')
    //RESUMO DOS IMPOSTOS

    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.rect(12, posY + 22, 182, 5, 'B');
    doc.text('MEIOS DE PAGAMENTOS', 13, posY + 25);
    doc.text('DESCRIÇÃO', 13, posY + 30);
    doc.text('BANCO', 65, posY + 30);
    doc.text('REFERÊNCIA', 95, posY + 30);
    doc.text('VALOR RECEBIDO', 155, posY + 30, 'right');
    doc.setDrawColor(0, 0, 0);
    doc.setLineWidth(0.1);
    doc.line(12, posY + 31, 194, posY + 31); // horizontal line
    var posYLinhaPagamento = posY + 1;


    for (let linha of linha_pagamentos) {
      doc.text('' + linha.designacao, 13, posYLinhaPagamento + 35);
      doc.text('' + (linha.abreviatura == null ? '' : linha.abreviatura), 65, posYLinhaPagamento + 35);
      doc.text('' + (linha.referencia == null ? '' : linha.referencia), 95, posYLinhaPagamento + 35);
      doc.text('' + (this.numberFormat(linha.valor_recebido)), 155, (posYLinhaPagamento + 35), 'right');       

      //doc.text('' + (linha.designacao == "Numerário" ? this.numberFormat(linha.valor_recebido - recibo.troco) : this.numberFormat(linha.valor_recebido)) , 155, (posYLinhaPagamento + 35), 'right');

      posYLinhaPagamento += 4

      /*var pageHeight = doc.internal.pageSize.height;

      if (posYLinhaPagamento + 165 > pageHeight) {
        posYLinhaPagamento = 0
        posY = 0
        footer()
        doc.addPage();
      }*/
    }

    doc.setFontType("normal");

    if (recibo.isTrocoAdiantamento) {
      let text_adiantamento = `Adiantamento registado no valor de: ${this.numberFormat(recibo.valorCriadoAdiantamento)} AOA`
      doc.text((text_adiantamento), 15, posYLinhaPagamento + 38, null, null, 'left');
    }

    if (!recibo.isTrocoAdiantamento && recibo.troco > 0) {
      let text_adiantamento = `Valor do troco: ${this.numberFormat(recibo.troco)} AOA`
      doc.text((text_adiantamento), 15, posYLinhaPagamento + 38, null, null, 'left');
    }

    //doc.text((recibo.saldado_recibo == null ? '' : 'Adiantamento registado no valor de: '+ this.numberFormat(recibo.troco) ), 15, posYLinhaPagamento + 38, null, null, 'left');
    doc.setFontType("bold");




    posYLinhaPagamento += 18
    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.rect(12, posYLinhaPagamento + 25, 182, 5, 'B');
    doc.text('TOTAL RECEBIDO', 13, posYLinhaPagamento + 28);
    if (recibo.isTrocoAdiantamento) {
      doc.text('' + this.numberFormat(recibo.valor_recebido), 155, posYLinhaPagamento + 28, 'right');
    }
    else {
      doc.text('' + this.numberFormat(recibo.valor_recebido - recibo.troco), 155, posYLinhaPagamento + 28, 'right');
    }

    // TOTAL RECEBIDO EM EXTENSO
    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.rect(12, posYLinhaPagamento + 31.5, 182, 5, 'B');
    doc.text(13, posYLinhaPagamento + 35, doc.splitTextToSize('VALOR POR EXTENSO', 50));
    var valor = extenso('' + this.numberFormat(recibo.valor_recebido - recibo.troco), { locale: 'pt', mode: 'currency', currency: { type: 'EUR' }, decimal: 'formal' });
    doc.text('' + valor.replace(/(euros?)/gi, 'kwanzas') + '', 155, posYLinhaPagamento + 35, 'right')

    let text_obs = `OBSERVAÇÕES: ${recibo.observacao ? recibo.observacao : ''}`
    doc.text(text_obs, 13, posYLinhaPagamento + 45, { maxWidth: 182 });

    // END RESUMO DOS IMPOSTOS
    posY = 130; // MOVE POSY

    //ANULAÇÃO
    if (recibo.status == 'A') {
      doc.setDrawColor(0);
      doc.setFillColor(220, 220, 220);
      doc.rect(10, posY + 99, 52, 5, 'B');
      doc.setTextColor(0);
      doc.setFontType("bold");
      doc.text('Estado: ', 12, posY + 102.5);
      doc.setTextColor('red');
      doc.text('Anulação', 24, posY + 102.5);
      doc.setTextColor(0);
    }

    //REGIME DE IMPOSTO
    doc.setDrawColor(0);
    doc.setFillColor(220, 220, 220);
    doc.rect(160, posY + 99, 35, 5, 'B');
    doc.setTextColor(0);
    doc.setFontType("bold");

    let tipo_regime = (user.sigla_empresa == 'EPASN' || user.sigla_empresa == 'EPASLS') ? 'Regime Simplificado' : 'IVA - Regime de Caixa'

    if (user.sigla_empresa == 'EPASN') {
      if (new Date(recibo.created_at) > new Date('2021-05-31')) {
        tipo_regime = 'IVA - Regime de Caixa'
      } else {
        tipo_regime = 'Regime Simplificado'
      }
    }

    doc.text(tipo_regime, 192, posY + 102.5, 'right');
    doc.setTextColor(0);

    doc.setFontType("bold")
    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.rect(10, posY + 106, 185.5, 5, 'B');
    doc.setTextColor(0);
    doc.setFontType("bold");

    doc.setDrawColor(0);
    doc.rect(10, posY + 105, 185, 20, 'B');

    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);

    doc.setTextColor(0);
    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.text('Contas Bancárias (IBAN):', 12, posY + 109.5);
    doc.setFontSize(8);
    doc.setDrawColor(0);
    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.setFontSize(8);
    doc.setDrawColor(0);
    doc.setFillColor(255);
    doc.rect(10, posY + 111, 185, 20, 'B');
    doc.setTextColor(0);
    doc.setFontType("normal");
    const ContasFiltradas = [];

    contas_bancarias.forEach((item) => {
      var duplicated = ContasFiltradas.findIndex(redItem => {
        return item.iban == redItem.iban;
      }) > -1;

      if (!duplicated) {
        ContasFiltradas.push(item);
      }
    });
    var posX = 12;
    let posYY = 115;
    for (var i = 0; i < ContasFiltradas.length; i++) {
      if (i !== 0 && i % 3 === 0) {
        posX = 12;
        posYY += 4;
      }
      doc.text('' + ContasFiltradas[i].banco, posX, posY + posYY);
      doc.text('' + ContasFiltradas[i].iban, posX + 7, posY + posYY);
      posX += 60;
    }

    doc.text('Sempre que efectuar uma transferência ou depósito, deve indicar o número das recibos a liquidar.', 12, posY + 125.7);

    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.text('Lojas EPAS:', 10, posY + 135);
    doc.setFontType("normal");
    for (let loja of lojas) {
      doc.text('' + loja.nome + ', ' + loja.endereco + ', ' + (loja.horario ? loja.horario : ''), 11, posY + 138);
      posY += 3;
    }

    footer()


    function footer() {
      var dataActual = moment(new Date()).format("DD/MM/YYYY");
      doc.text(dataActual + ' ' + moment().format("H:m") + '/ Versão: ' + original, 11, 285);
      /*     doc.text(moment(recibo.created_at).format("DD/MM/YYYY") + ' ' + moment(recibo.created_at).format("H:m") + '/ Versão: ' + original, 11, 285); */
      // doc.text('Página: ' + numberPage, 184, 285);

      doc.setFontSize(8);
      doc.setDrawColor(0);
      doc.setFillColor(0);


      doc.line(10, doc.internal.pageSize.height - 9, 194, doc.internal.pageSize.height - 9); // vertical line
      var hash = recibo == null ? '' : recibo.hash ? recibo.hash.substring(0, 1) : ''
      hash += recibo == null ? '' : recibo.hash ? recibo.hash.substring(10, 11) : ''
      hash += recibo == null ? '' : recibo.hash ? recibo.hash.substring(20, 21) : ''
      hash += recibo == null ? '' : recibo.hash ? recibo.hash.substring(30, 31) : ''
      doc.setFontSize(6);

      doc.text("NIF: " + user.taxRegistrationNumber + " - " + user.companyName + " / " + user.addressDetail + " / " + (user.company_telefone || '') + " / " + (user.company_email || ''), 105, doc.internal.pageSize.height - 6, null, null, 'center');

      doc.setFontSize(8);
      doc.text(hash + '-Processado por programa validado nº 4/AGT119', 105, doc.internal.pageSize.height - 3, null, null, 'center');
    }



    if (report == 'imprimir') {
      doc.autoPrint();
      window.open(doc.output("bloburl")); //opens the data uri in new window
    } else {
      doc.save(recibo.recibo_sigla + '.pdf'); /* download the file immediately on loading */
    }

  }
  public numberFormat(number) {
    return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2 }).format(number || 0).replace('€', '').trim();
  }


}
