import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { HttpService } from "src/app/providers/http/http.service";
import { ConfigService } from "src/app/providers/config/config.service";
import { AuthService } from "src/app/providers/auth/auth.service";
import { Router, ActivatedRoute } from "@angular/router";
import Swal from 'sweetalert2';
import { Observable, Subject } from "rxjs";

@Component({
  selector: 'app-executar-ot-preventiva',
  templateUrl: './executar-ot-preventiva.component.html',
  styleUrls: ['./executar-ot-preventiva.component.css']
})
export class ExecutarOtPreventivaComponent implements OnInit {

  @ViewChild("search") search;
  @ViewChild('closebutton') closebutton;

  private primeiros_niveis: any = [];
  private segundo_niveis: any = [];
  private terceiro_niveis: any = [];
  private departamentos: any = [];
  private colaboradoresToDelete=[]
  private duracoes: any = [];
  private funcoes: any = [];
  private artigos: any = [];
  private produtoCategorias: any = [];
  private recursos: any = [];
  private tipos: any = [];
  private classificacoes: any = [];
  private prioridades: any = [];
  private causas: any = [];
  private sintomas: any = [];
  private clientes: any = [];
  private local_consumo: any = [];
  coloborador_nome:string=''
  public currentUser: any;

  private colaboradores: any = [];

  private contas: any = [];
  private contratos: any = [];

  private isFinalizar: boolean = true;
  private isDisabled: boolean = false;
  private isAddTarefa: boolean = true;
  private is_sintoma: boolean = false;
  private is_causa: boolean = false;
  private validateButton: any = null;
  private is_responsavel: boolean = false;
  private qtdUtilizadoDecimal: boolean = true;
  private view_client = true;
  dropdownSettings = {};
  dropdownSettingsSintomas = {};

  private dayInicio: any;
  private hourInicio: any;
  private minutesInicio: any;
  private timeInicio: any;

  routerParamId: any;
  routerParamAgendamentoId: any;

  private causasArray: any = [];
  private sintomasArray: any = [];

  private agendamentodayFim: any;
  private agendamentohourFim: any;
  private agendamentominutesFim: any;

  private hourFimAgendamento: any;
  private minutesFimAgendamento: any;

  private associacao_cliente = null;

  private view_serie_contador: boolean = false;
  private contador_join: boolean = true;
  private contador_title: string = "associação";

  private permission: string = null;
  @Input() ordem_sigla: string = "OT";

  private showContadores_flag: boolean = false;

  private contadores: any[];

  private contador = {
    id: null,
    numero_serie: null,
    marca: null,
    ultima_leitura: null,
    modelo: null,

    tipo_contador: null,
    classe_precisao: null,
    medicao: null,
    calibre: null,
    digitos: null,
    fabricante: null,
    centro_distribuicao: null,

    observacao: null,
  };

  private colaborador = {
    coloborador_nome: null,
    colaborador_id: null
  }

  public tarefasSelecionados = [];

  public tarefasToDelete = [];

  private tarefasToAdd: any = [];
  private tarefas: any = [];

  private agendamento = {
    id: null,
    data_previsao_inicio: null,
    fim_execucao: null,
    inicio_execucao: null,
    data_previsao_fim: null,
    dif_dias_prevista: null,
    dif_horas_prevista: null,
    dif_minutos_prevista: null,
    dif_dias_definida: null,
    dif_horas_definida: null,
    dif_minutos_definida: null,
    descricao: null,
    descricao_intervencao: null,
    codigo_agendamento: null,
  };

  private tarefa = {
    id: null,
    descricao: null,
    fim_execucao: null,
    inicio_execucao: null
  }

  private recursosHumanos = {
    departamento_id: null,
    qtd_colaborador: null,
    funcao_id: null,
    colaborador_id: null,
    colaborador_nome: null,
    qtdRh: null,
  };

  private consumiveis = {
    armazem_id: null,
    artigo_id: null,
    qtdArtigo: null,
    qtd_stock: null,
    categoria_id: null,
    tarefa_plano_id: null,
    reserva: null,
    unidade: null,
    descricao_material: null,
  };

  private ordemServico = {
    descricao: null,
    descricao_old: null,
    cliente_nome: null,
    success_ordem_servico: false,
    sintoma_id: null,
    classificacao_id: null,
    classificacao_descricao: null,
    prioridade_id: null,
    prioridade_descricao: null,
    tipo_descricao: null,
    tipo_cliente_flag: null,
    responsavel_nome: null,
    data_prevista: null,
    agendamentoGerado: null,
    codigo: null,
    titulo: null,

    contrato_id: null,
    local_consumo_id: null,
    contador_id: null,
    local_instalacao_id: null,

    ot_trigger_descricao: null,
    ot_trigger_flag: null,

    contador: null,
  };

  private pagination = {
    start: 1,
    end: 10,
    search: null,
  };

  currtentPage: number;

  dropdownSettingsDepartamentos = {};
  private departamentoArray: any = [];
  private dropdownDepartamentos: any = [];

  constructor(
    private auth: AuthService,
    private http: HttpService,
    private route: ActivatedRoute,
    private router: Router,
    private configService: ConfigService
  ) {
    this.currentUser = this.auth.currentUserValue;
    //this.route.params.subscribe(params => this.currtentPage = params['page']);
  }

  ngOnInit() {
    this.getRouterParamId();
  }

  private carregando = {
    nivel_2: "Seleccione o equipamento",
    funcao: "Seleccione a função",
    material: "Seleccione o material",
    categoria: "Seleccione a categoria",
    tipo: "Seleccione o tipo",
  };

  public getRouterParamId() {
    this.routerParamId = this.route.snapshot.params.id;
    this.routerParamAgendamentoId = this.route.snapshot.params.agendamento_id;

    this.permission = localStorage.getItem('permission');

    if(this.permission == "listagem_ot_comercial"){
      this.getDropDownDepartamentos();
      this.ordem_sigla = "OS";
    }else{
      this.ordem_sigla = "OT";
    }

    //console.log(this.routerParamId)

    if (this.routerParamId != undefined) {
      this.getArmazens();
      this.getAgendamentoById(this.routerParamAgendamentoId);
      this.getOrdemById(this.routerParamId);
      this.getInicioFimExecAgendamento(this.routerParamAgendamentoId)


      setTimeout(() => {

      if (this.ordemServico.ot_trigger_flag == "REMOCAO-CONTADOR") {
        this.contador_join = false;
        this.contador_title = "remoção";
      }
      else {

      this.contador_title = "associação";
      this.contador_join = true;
      }

      console.log("contador_join")
     // console.log(this.contador_join)
      }, 2000);
    }
  }

  private getDropDownDepartamentos() {
    this.http.call_get('departamento/selectBox', null).subscribe(
      response => {
        this.dropdownDepartamentos = Object(response).data;

        this.dropdownSettingsDepartamentos = {
          singleSelection: false,
          idField: 'id',
          textField: 'descricao',
          selectAllText: 'Seleccione Todos',
          unSelectAllText: 'DeSeleccionar Todos',
          itemsShowLimit: 10,
          allowSearchFilter: true
        };

      }
    );
  }

  onItemSelectDepartamento(item: any) {
    this.departamentoArray.push(item);
  }
  onSelectAllDepartamentos(items: any) {
    this.departamentoArray = items;
  }

  private getPermission(){
    this.permission = localStorage.getItem('permission');
  }

  private getOrdemById(id) {
    this.http
      .call_get("ordemservico/ordem-by-id/" + id, null)
      .subscribe((response) => {
        this.ordemServico.codigo = Object(response).data.codigo;
        this.ordemServico.titulo = Object(response).data.titulo;
        this.ordemServico.descricao_old = Object(response).data.descricao;
        this.ordemServico.data_prevista = Object(response).data.data_prevista_prev;
        this.ordemServico.prioridade_descricao =
          Object(response).data.prioridade_descricao;
        this.ordemServico.responsavel_nome =
          Object(response).data.responsavel_nome;
        this.ordemServico.tipo_descricao = Object(response).data.tipo_descricao;
        this.ordemServico.tipo_cliente_flag =
          Object(response).data.tipo_cliente_flag;
        this.ordemServico.ot_trigger_descricao =
          Object(response).data.ot_trigger_descricao;
        this.ordemServico.ot_trigger_flag =
          Object(response).data.ot_trigger_flag;
        this.ordemServico.classificacao_descricao =
          Object(response).data.classificacao_descricao;

        this.ordemServico.contrato_id = Object(response).data.contrato_id;
        this.ordemServico.contador = Object(response).data.contador;
        this.ordemServico.local_instalacao_id =
          Object(response).data.local_instalacao_id;
        this.ordemServico.local_consumo_id =
          Object(response).data.local_consumo_id;

       // console.log(Object(response).data);
      });
  }

  private getConsumiveisByAgendamento(id) {
    this.http
      .call_get("ordemservico/consumiveis-by-agendamento/" + id, null)
      .subscribe((response) => {
        this.consumiveisSelecionados = Object(response).data;
      });

      this.getTarefasByAgendamento(id)
      this.getTarefasAbertaByAgendamento(id)
  }

  private getTarefasAbertaByAgendamento(id) {
    this.http.call_get('ordemservico/tarefas-aberta-by-agendamento/' + id, null).subscribe(
      response => {

        this.tarefaSelectBox = Object(response).data;
      }
    );
  }

  private getTarefasByAgendamento(id) {
    this.http.call_get('ordemservico/tarefas-by-agendamento/' + id, null).subscribe(
      response => {

        this.tarefas = Object(response).data;
      }
    );
  }

  private getColaboradoresByAgendamento(id) {
    this.http
      .call_get("ordemservico/colaboradores-by-agendamento/" + id, null)
      .subscribe((response) => {
        this.recursosSelecionados = Object(response).data;
      });
  }

  /*  keyPress(event, item) {
     var x = event.key;
     var y: number = +x;

     if (y < 0) {
       this.configService.showAlert('Hora gasta não pode ser menor que 0', "alert-danger", true);
     } else if (!y) {
       this.configService.showAlert('Digite apenas número', "alert-danger", true);
     } else {
       this.adicionarHoraGasta(event.key, item)
     }

   } */

  adicionarInicioEst(item) {
    var replace = item.data_definida_inicio.replace("T", " ");
    var date = new Date(replace);

    this.dayInicio = date.getDay();
    this.hourInicio = date.getHours();
    this.minutesInicio = date.getMinutes();
    this.timeInicio = date.getTime();

    this.adicionarInicioGasta(item);
  }

  adicionarFimEst(item) {
    var replace1 = item.data_definida_inicio.replace("T", " ");
    var date1 = new Date(replace1);

    this.dayInicio = date1.getDay();
    this.hourInicio = date1.getHours();
    this.minutesInicio = date1.getMinutes();
    this.timeInicio = date1.getTime();

    var replace = item.data_definida_fim.replace("T", " ");

    if (item.data_definida_inicio == null || item.data_definida_inicio == "") {
      this.configService.showAlert(
        "Digite a Data definida de início do(a) " + item.nome_colaborador,
        "alert-danger",
        true
      );
    } else if (item.data_definida_fim == null || item.data_definida_fim == "") {
      this.configService.showAlert(
        "Digite a Data definida de fim do(a) " + item.nome_colaborador,
        "alert-danger",
        true
      );
    } else if (item.data_definida_inicio >= item.data_definida_fim) {
      this.configService.showAlert(
        "A data fim definida não pode ser igual ou inferior a data de início definida - Colaborador(a): " +
          item.nome_colaborador,
        "alert-danger",
        true
      );
    } else {
      var date = new Date(replace);

      var dayFim = date.getDay();
      var hourFim = date.getHours();
      var minutesFim = date.getMinutes();
      var timeFim = date.getTime();

      var data = this.hourInicio * 3600 + this.minutesInicio * 60;
      var data2 = hourFim * 3600 + minutesFim * 60;
      var diferenca = data2 - data;

      this.hourInicio = Math.floor(diferenca / 3600);
      this.minutesInicio = Math.floor(
        (diferenca - this.hourInicio * 3600) / 60
      );

      var condincao =
        this.hourInicio +
        "" +
        (this.minutesInicio.toString().length < 2
          ? "0" + "" + this.minutesInicio
          : this.minutesInicio);

      var diffHoras =
        parseInt(condincao) > 759
          ? parseInt(this.hourInicio) - 8
          : this.hourInicio;
      var diffDias = dayFim - this.dayInicio;
      diffDias = parseInt(condincao) > 759 ? diffDias + 1 : diffDias;

      this.adicionarHoraGasta(item, diffDias, diffHoras, this.minutesInicio);
    }
  }

  public adicionarHoraGasta(item, diffDias, diffHoras, diffMinutos) {
    var validar = 0;

    var diffDiasReplace = diffDias.toString();
    var diffHorasReplace = diffHoras.toString();
    var diffMinutosReplace = diffMinutos.toString();

    var recursos = {
      nome_colaborador: item.nome_colaborador,
      os_agendamento_colaborador_id: item.os_agendamento_colaborador_id,
      colaborador_id: item.colaborador_id,
      agendamento_id: item.agendamento_id,
      nome_departamento: item.nome_departamento,
      is_responsavel: item.is_responsavel,
      nome_funcao: item.nome_funcao,
      data_prevista_inicio: item.data_prevista_inicio,
      data_prevista_fim: item.data_prevista_fim,
      dif_dias_prevista: item.dif_dias_prevista,
      dif_horas_prevista: item.dif_horas_prevista,
      dif_minutos_prevista: item.dif_minutos_prevista,
      data_definida_inicio: item.data_definida_inicio,
      data_definida_fim: item.data_definida_fim,
      dif_dias_definida: parseInt(diffDiasReplace.replace("-", "")),
      dif_horas_definida: parseInt(diffHorasReplace.replace("-", "")),
      dif_minutos_definida: parseInt(diffMinutosReplace.replace("-", "")),
    };

    if (this.recursosSelecionados.length >= 1) {
      for (let index = 0; index < this.recursosSelecionados.length; index++) {
        const l = this.recursosSelecionados[index];
        if (l.colaborador_id == recursos.colaborador_id) {
          this.recursosSelecionados.splice(index, 1);
          this.recursosSelecionados.splice(index, 0, recursos);
          validar = 1;
        }
      }
      if (validar == 0) {
        this.recursosSelecionados.push(recursos);
      }
    } else {
      this.recursosSelecionados.push(recursos);
    }
  }

  public adicionarInicioGasta(item) {
    var validar = 0;

    var recursos = {
      nome_colaborador: item.nome_colaborador,
      os_agendamento_colaborador_id: item.os_agendamento_colaborador_id,
      colaborador_id: item.colaborador_id,
      is_responsavel: item.is_responsavel,
      agendamento_id: item.agendamento_id,
      nome_departamento: item.nome_departamento,
      nome_funcao: item.nome_funcao,
      data_prevista_inicio: item.data_prevista_inicio,
      data_prevista_fim: item.data_prevista_fim,
      dif_dias_prevista: item.dif_dias_prevista,
      dif_horas_prevista: item.dif_horas_prevista,
      dif_minutos_prevista: item.dif_minutos_prevista,
      data_definida_inicio: item.data_definida_inicio /* ,
      data_definida_fim: item.data_definida_fim */,
    };

    if (this.recursosSelecionados.length >= 1) {
      for (let index = 0; index < this.recursosSelecionados.length; index++) {
        const l = this.recursosSelecionados[index];
        if (l.colaborador_id == recursos.colaborador_id) {
          this.recursosSelecionados.splice(index, 1);
          this.recursosSelecionados.splice(index, 0, recursos);
          validar = 1;
        }
      }
      if (validar == 0) {
        this.recursosSelecionados.push(recursos);
      }
    } else {
      this.recursosSelecionados.push(recursos);
    }
  }

  private getAgendamentoById(id) {
    this.http
      .call_get("ordemservico/agendamento-by-Ordem/" + id, null)
      .subscribe((response) => {
        this.agendamento.data_previsao_inicio =
          Object(response).data.res.data_previsao_inicio;
        this.agendamento.data_previsao_fim =
          Object(response).data.res.data_previsao_fim;
        this.agendamento.dif_dias_prevista =
          Object(response).data.res.dif_dias_prevista;
        this.agendamento.dif_horas_prevista =
          Object(response).data.res.dif_horas_prevista;
        this.agendamento.dif_minutos_prevista =
          Object(response).data.res.dif_minutos_prevista;
        this.agendamento.descricao = Object(response).data.historico
          ? Object(response).data.historico.historico_agendamento_descricao
          : null;
        this.agendamento.id = Object(response).data.res.id;
        this.agendamento.codigo_agendamento =
          Object(response).data.res.codigo_agendamento;
      });
  }

  private getDepartamento() {
    this.http.call_get("departamento/selectBox", null).subscribe((response) => {
      this.departamentos = Object(response).data;
    });

    this.getDuracao();
  }

  private getDuracao() {
    this.http.call_get("duracao/selectBox", null).subscribe((response) => {
      this.duracoes = Object(response).data;
    });
  }

  private getFuncaoByDepartamento() {
    this.carregando.funcao = "Carregando...";
    this.http
      .call_get(
        "funcao/funcaoesByDepartamento/" + this.recursosHumanos.departamento_id,
        null
      )
      .subscribe((response) => {
        this.funcoes = Object(response).data;
        this.carregando.funcao = "Seleccione a função";
      });
  }

  private getColaboradoresByFuncao() {
    this.colaboradores = [];

    if (this.recursosHumanos.funcao_id != null) {
      this.configService.loaddinStarter("start");
      this.http
        .__call("colaborador/colaboradoresByFuncao", {
          start: 1,
          end: 10,
          search: null,
          funcao_id: this.recursosHumanos.funcao_id,
        })
        .subscribe((response) => {
          this.colaboradores = Object(response).data.data;
          this.configService.loaddinStarter("stop");
        });
    }
  }

  public armazens = [];

  public getArmazens() {
    this.http.__call("armazem/selectBox", null).subscribe((response) => {
      this.armazens = Object(response).data;
    });
  }

  private getCategoriaByArmazem() {
    this.consumiveis.qtd_stock = 0;
    (this.consumiveis.categoria_id = null), (this.produtoCategorias = []);
    this.artigos = [];

    this.carregando.categoria = "Carregando...";
    this.http
      .__call("categoria/by/armazem", {
        armazem_id: this.consumiveis.armazem_id,
      })
      .subscribe((res: any) => {
        this.produtoCategorias = Object(res).data;
        this.carregando.categoria = "Seleccione a categoria";
      });
  }

  private getMaterialByCategoriaArmazem() {
    this.consumiveis.qtd_stock = 0;
    this.consumiveis.artigo_id = null;
    this.artigos = [];

    this.carregando.material = "Carregando...";
    this.http
      .__call("material/by/categoria-armazem", {
        armazem_id: this.consumiveis.armazem_id,
        categoria_id: this.consumiveis.categoria_id,
      })
      .subscribe((res: any) => {
        this.artigos = Object(res).data;
        this.carregando.material = "Seleccione o material";
      });
  }

  view_material = false;

  private setMaterial(item) {
    this.consumiveis.artigo_id = item.id;
    this.consumiveis.descricao_material =
      item.codigo_produto + " - " + item.descricao;
    this.view_material = false;
  }

  //SEARCH MATERIAL

  private searchMaterial() {
    /*  if (this.consumiveis.descricao_material == "" || this.consumiveis.descricao_material == null) {
      this.consumiveis.artigo_id = null;
      this.consumiveis.descricao_material = null;
      this.view_material = false;
    } */

    this.consumiveis.unidade = null;
    this.consumiveis.qtd_stock = 0;
    this.consumiveis.artigo_id = null;

    this.artigos = [];

    if (this.consumiveis.categoria_id == null) {
      (this.consumiveis.descricao_material = null),
        (this.view_material = false);
    }

    this.view_material = true;
    this.http
      .__call("search-material/by/categoria-armazem", {
        start: 1,
        end: 500,
        armazem_id: this.consumiveis.armazem_id,
        categoria_id: this.consumiveis.categoria_id,
        search: this.consumiveis.descricao_material,
      })
      .subscribe((response) => {
        this.artigos = Object(response).data.data;
      });
  }

  public consumiveisSelecionados = [];
  public tarefaSelectBox = [];
  public recursosSelecionados = [];
  public qtd_stock = 0;

  private getQtdProduto() {
    //Quantidade de artigo em Stock

    this.consumiveis.qtd_stock = 0;

    if (this.consumiveis.artigo_id != null) {
      this.http
        .__call("stock/existencia/armazem/qtd_produto", {
          armazem_id: this.consumiveis.armazem_id,
          artigo_id: this.consumiveis.artigo_id,
        })
        .subscribe((response) => {
          this.consumiveis.qtd_stock = Object(response).data[0].quantidade;
        });
    }
    //-------
  }

  private getUnidadeByMaterial() {
    this.consumiveis.unidade = null;

    if (this.consumiveis.artigo_id != null) {
      this.http
        .call_get("unidade-by-material/" + this.consumiveis.artigo_id, null)
        .subscribe((response) => {
          this.consumiveis.unidade = Object(response).data.unidade;
        });
    }
    //-------
  }

  subjectObj = new Subject<number>();

  private getQtColaborador() {
    this.http
      .__call("colaborador/get-total", {
        funcao_id: this.recursosHumanos.funcao_id,
      })
      .subscribe((response) => {
        this.recursosHumanos.qtd_colaborador = Object(response).data.total;
      });
    //-------
  }

  keyUpQtdUtilizado(item) {
    this.isNumeric(item);
  }
  validateNumberQuantity: any = {
    isInt: function (number: number) {
      return Number(number) === number && number % 1 === 0;
    },
    isFloat: function (number: number) {
      return Number(number) === number && number % 1 !== 0;
    },
  };
  private convertNumberQuantity = ({ quantidade_utilizada }) => {
    quantidade_utilizada = this.replaceNumberQuantity(quantidade_utilizada);
    if (!quantidade_utilizada) return;
    if (quantidade_utilizada.search(/\./))
      return parseFloat(quantidade_utilizada);
    return Number(quantidade_utilizada);
  };
  private replaceNumberQuantity = (number) => {
    if (!number) return;
    number = number ? number.replace(",", ".") : null;
    return number;
  };
  verifyQuantityIfIsIntOrFloat(number: Number): Number {
    if (
      !this.validateNumberQuantity.isInt(number) &&
      !this.validateNumberQuantity.isFloat(number)
    ) {
      this.configService.showAlert(
        "Número decimal inválido! Quantidade utilizado! Ex. 1 ou 1.5",
        "alert-danger",
        true
      );
      return (number = null);
    }
    return number;
  }
  private isNumeric(item): boolean | any {
    //console.log(item.quantidade_utilizada);
    let convertedNumber = this.convertNumberQuantity(item);
    if (this.verifyQuantityIfIsIntOrFloat(convertedNumber) == null) {
      return this.adicionarQtdConsumiveis(
        item,
        this.verifyQuantityIfIsIntOrFloat(convertedNumber)
      );
    }
    return this.adicionarQtdConsumiveis(
      item,
      this.verifyQuantityIfIsIntOrFloat(convertedNumber)
    );
  }

  public adicionarQtdConsumiveis(item, str) {
    let validar = 0;

    let quantidade_utilizada = str;

    if (quantidade_utilizada == "") {
      quantidade_utilizada = null;
    }

    let artigo = {
      os_agendamento_consumiveil_id: item.os_agendamento_consumiveil_id,
      nome_artigo: item.nome_artigo,
      codigo_produto: item.codigo_produto,
      armazem_nome: item.armazem_nome,
      categoria_descricao: item.categoria_descricao,
      artigo_id: item.artigo_id,
      armazem_id: item.armazem_id,
      agendamento_id: item.agendamento_id,
      quantidade: item.quantidade,
      tarefa: item.tarefa,
      unidade: item.unidade,
      quantidade_utilizada: quantidade_utilizada,
    };

    if (this.consumiveisSelecionados.length >= 1) {
      for (
        let index = 0;
        index < this.consumiveisSelecionados.length;
        index++
      ) {
        const l = this.consumiveisSelecionados[index];
        if (l.artigo_id == artigo.artigo_id) {
          // this.consumiveisSelecionados.splice(index, 1);
          this.consumiveisSelecionados.splice(index, 1, artigo);
          validar = 1;
        }
      }
      if (validar == 0) {
        this.consumiveisSelecionados.push(artigo);
      }
    } else {
      this.consumiveisSelecionados.push(artigo);
    }
  }

  public adicionarArtigos() {
    var validar = 0;
    var nome_artigo = "";
    var codigo_artigo = "";
    var armazem_nome = "";
    var categoria_descricao = "";
    let tarefa=''
    if (this.consumiveis.artigo_id == null) {
      this.configService.showAlert("Seleccione o Material", "alert-danger", true );
    } else if (this.consumiveis.tarefa_plano_id == null) {
      this.configService.showAlert("Escolhe a tarefa","alert-danger", true);
    } else {
    this.artigos.forEach((element) => {
      if (element.id == this.consumiveis.artigo_id) {
        nome_artigo = element.descricao;
        codigo_artigo = element.codigo_produto;
        armazem_nome = element.armazem_nome;
        categoria_descricao = element.categoria_descricao;
      }
    });

    this.tarefaSelectBox.forEach((element) => {
      if (element.tarefa_plano_id == this.consumiveis.tarefa_plano_id) {
        tarefa = element.descricao;
      }
    });

    var artigo = {
      armazem_id: this.consumiveis.armazem_id,
      armazem_nome: armazem_nome,
      categoria_descricao: categoria_descricao,
      nome_artigo: nome_artigo,
      codigo_produto: codigo_artigo,
      artigo_id: this.consumiveis.artigo_id,
      tarefa_plano_id: this.consumiveis.tarefa_plano_id ,
      tarefa:tarefa,
      quantidade: 0,
      agendamento_id: null,
      quantidade_utilizada: null,
      qtd_stock: this.qtd_stock,
      unidade: this.consumiveis.unidade,
    };

    if (this.consumiveisSelecionados.length >= 1) {
      for (
        let index = 0;
        index < this.consumiveisSelecionados.length;
        index++
      ) {
        const l = this.consumiveisSelecionados[index];
        if (l.artigo_id == artigo.artigo_id) {
          this.configService.showAlert(
            "Material já se econtra na lista",
            "alert-danger",
            true
          );

          this.consumiveisSelecionados.splice(index, 0, artigo);
          this.consumiveisSelecionados.splice(index, 1);
          validar = 1;
        }
      }
      if (validar == 0) {
        this.consumiveisSelecionados.push(artigo);
      }
    } else {
      this.consumiveisSelecionados.push(artigo);
    }

    (this.consumiveis.artigo_id = null),
      (this.consumiveis.qtdArtigo = null),
      (this.consumiveis.qtd_stock = null);

  }
  }

   isResponsavelObject:any={}

  public adicionarRecursos(item) {

    var validar = 0;
    var nome_funcao = "";
    var nome_departamento = "";

    this.funcoes.forEach((element) => {
      if (element.id == this.recursosHumanos.funcao_id) {
        nome_funcao = element.descricao;
      }
    });

    this.departamentos.forEach((element) => {
      if (element.id == this.recursosHumanos.departamento_id) {
        nome_departamento = element.descricao;
      }
    });

    this.departamentos.forEach((element) => {
      if (element.id == this.recursosHumanos.departamento_id) {
        nome_departamento = element.descricao;
      }
    });

for(let i=0;i<this.colaboradores.length;i++){
  if(this.colaboradores[i].id == item.id){
     var recursos = {
      nome_colaborador: item.colaboradorNome,
      colaborador_id: item.id,
      agendamento_id: null,
      nome_departamento: nome_departamento,
      nome_funcao: nome_funcao,
      is_responsavel:this.colaboradores[i].is_responsavel,
      data_definida_inicio: null,
    };
  }

}


    console.log('isResponsavelObject',this.colaboradores,this.isResponsavelObject)

    if (this.recursosSelecionados.length >= 1) {
      for (let index = 0; index < this.recursosSelecionados.length; index++) {
        const l = this.recursosSelecionados[index];

        if(l.is_responsavel){
          recursos.is_responsavel=false
        }
        if (l.colaborador_id == recursos.colaborador_id) {
          this.configService.showAlert(
            "Colaborador já se econtra na lista",
            "alert-danger",
            true
          );

          this.recursosSelecionados.splice(index, 0, recursos);
          this.recursosSelecionados.splice(index, 1);
          validar = 1;
        }
      }
      if (validar == 0) {
        this.recursosSelecionados.push(recursos);
      }
    } else {
      this.recursosSelecionados.push(recursos);
    }

    console.log('recursosSelecionados',this.recursosSelecionados)
  }

  deleteRow(item: any) {
    for (let i = 0; i < this.consumiveisSelecionados.length; ++i) {
      if (this.consumiveisSelecionados[i].artigo_id === item.artigo_id) {
        this.consumiveisSelecionados.splice(i, 1);
      }
    }
  }

  deleteRecursoRow(item: any) {
    var deletedRow = {
      nome_colaborador: item.nome_colaborador,
      colaborador_id: item.colaborador_id,
    };
    this.colaboradoresToDelete.push(deletedRow);
    for (let i = 0; i < this.recursosSelecionados.length; ++i) {
      if (this.recursosSelecionados[i].colaborador_id === item.colaborador_id) {
        this.recursosSelecionados.splice(i, 1);
      }
    }
    this.editarColoborador()
    this.is_responsavel=false
  }

  private reloadOrdemServico() {
    location.reload();
  }

  private validateConsumiveis() {
    var validar = 0;

    if (this.consumiveisSelecionados.length > 0) {
      for (
        let index = 0;
        index < this.consumiveisSelecionados.length;
        index++
      ) {
        const l = this.consumiveisSelecionados[index];

        if (l.quantidade_utilizada == null) {
          this.configService.showAlert(
            "Digite a quatidade utilizada para o material " + l.nome_artigo,
            "alert-danger",
            true
          );
          validar = 1;
        }
      }
    }

    if (validar == 0) {
      const nextElement: HTMLElement = document.querySelector(
        "#next-step-colaboradores"
      );
      nextElement.click();
    }
  }

  private formatDate(date) {
    var dia = date.getDate();
    var ano = date.getFullYear();
    var hora = date.getHours();
    var minuto = date.getMinutes();
    var mes = date.getMonth() + 1;

    return (
      ano +
      "-" +
      (mes.toString().length < 2 ? "0" + mes : mes) +
      "-" +
      (dia.toString().length < 2 ? "0" + dia : dia) +
      "T" +
      (hora.toString().length < 2 ? "0" + hora : hora) +
      ":" +
      (minuto.toString() < 2 ? "0" + minuto : minuto)
    );
  }

  private confirmFecharTarefa(item) {
    const selectedTarefa=this.tarefasSelecionados.filter(items=>items.tarefa_nome===item.descricao)
    console.log('selectedTarefa=>',selectedTarefa,item)
   if(selectedTarefa.length==0){
    Swal.fire({
      html: `
      <h3><b>Não é possível fechar esta tarefa!</b></h3>
      Tarefa: `+item.descricao+`
   `,
      //title: '<strong></strong>',
      text: "Porfavor registe um data de execussão",
      showCloseButton: true,
     // showCancelButton: true,
      /* confirmButtonText: 'Sim',
      cancelButtonText: 'Não', */
      confirmButtonColor: '#d33'

    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      Swal.fire('', '', 'info')
    })

     return
   }

    Swal.fire({
      html: `
      <h3><b>Pretende fechar a tarefa?</b></h3>
      Tarefa: `+item.descricao+`
   `,
      //title: '<strong></strong>',
      text: "Você não poderá reverter isso!",
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      confirmButtonColor: '#d33'

    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.value) {
       this.fecharTarefa(item.os_preventiva_id)
        Swal.fire('Sucesso!', '', 'success')
      } else if (!result.value) {
        Swal.fire('', '', 'info')
      }
    })

  }

  private fecharTarefa(id) {

    this.http.__call('ordemservico/fechar-tarefa', {
      os_preventiva_id : id,
      colaboradores:this.recursosSelecionados,
      agendamento : this.agendamento,
      consumiveis: this.consumiveisSelecionados
    }).subscribe(
      response => {
        if (Object(response).code == 200) {
          this.getConsumiveisByAgendamento(this.routerParamAgendamentoId)
          this.getTarefasByAgendamento(this.routerParamAgendamentoId);
        } else {
          this.configService.showAlert(Object(response).message, "alert-danger", true);
          this.getTarefasByAgendamento(this.routerParamAgendamentoId)
        }
      }
    );
  }

  private confirmFecharAgendamento() {

    Swal.fire({
      html: `
      <h3><b>Pretende fechar a Ordem de Trabalho?</b></h3>
      Você não poderá reverter isso!
   `,
      //title: '<strong></strong>',
      text: "Você não poderá reverter isso!",
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      confirmButtonColor: '#d33'

    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.value) {
       this.finalizar(true,true,false)
        Swal.fire('Sucesso!', '', 'success')
      } else if (!result.value) {
        Swal.fire('', '', 'info')
      }
    })

  }

  private validateColaboradores() {
    var validar = 0;

   /*  for (let index = 0; index < this.recursosSelecionados.length; index++) {
      const l = this.recursosSelecionados[index];

      if (l.data_definida_inicio == null) {
        this.configService.showAlert(
          "Digite a Data definida de início do(a) " + l.nome_colaborador,
          "alert-danger",
          true
        );
        validar = 1;
      }

      if (l.data_definida_fim == null) {
        this.configService.showAlert(
          "Digite a Data definida de fim do(a) " + l.nome_colaborador,
          "alert-danger",
          true
        );
        validar = 1;
      }

      if (l.data_definida_inicio >= l.data_definida_fim) {
        this.configService.showAlert(
          "A data fim previsto não pode ser igual ou inferior a date de início previsto - Colaborador(a): " +
            l.nome_colaborador,
          "alert-danger",
          true
        );
      }
    } */

    var dates_definida_inicio = [];
    var dates_definida_fim = [];

    for (let index = 0; index < this.recursosSelecionados.length; index++) {
      dates_definida_inicio.push(
        new Date(this.recursosSelecionados[index].data_definida_inicio)
      );
      dates_definida_fim.push(
        new Date(this.recursosSelecionados[index].data_definida_fim)
      );
    }

    var maxDate = Math.max.apply(null, dates_definida_fim);
    var mimDate = Math.min.apply(null, dates_definida_inicio);
    // convert back to date object
    maxDate = new Date(maxDate);
    mimDate = new Date(mimDate);

    this.agendamento.inicio_execucao = this.formatDate(mimDate);
    this.agendamento.fim_execucao = this.formatDate(maxDate);

    //this.agendamentoAddInicio();
    //this.agendamentoAddFim();

    if (validar == 0) {
      const nextElement: HTMLElement = document.querySelector(
        "#next-step-colaboradores"
      );
      nextElement.click();
    }
  }

  agendamentoAddInicio() {
    var replace = this.agendamento.inicio_execucao.replace("T", " ");
    var date = new Date(replace);

    this.agendamentodayFim = date.getDay();
    this.agendamentohourFim = date.getHours();
    this.agendamentominutesFim = date.getMinutes();
  }

  agendamentoAddFim() {
    var replace1 = this.agendamento.fim_execucao.replace("T", " ");

    if (
      this.agendamento.inicio_execucao == null ||
      this.agendamento.inicio_execucao == ""
    ) {
      this.configService.showAlert(
        "Digite a Data definida de início",
        "alert-danger",
        true
      );
    } else if (
      this.agendamento.fim_execucao == null ||
      this.agendamento.fim_execucao == ""
    ) {
      this.configService.showAlert(
        "Digite a Data definida de fim",
        "alert-danger",
        true
      );
    } else if (
      this.agendamento.inicio_execucao >= this.agendamento.fim_execucao
    ) {
      this.configService.showAlert(
        "A data fim definida não pode ser igual ou inferior a data de início definida",
        "alert-danger",
        true
      );
    } else {
      var date1 = new Date(replace1);

      var dayFim = date1.getDay();
      var hourFim = date1.getHours();
      var minutesFim = date1.getMinutes();

      var data =
        this.agendamentohourFim * 3600 + this.agendamentominutesFim * 60;
      var data2 = hourFim * 3600 + minutesFim * 60;
      var diferenca = data2 - data;

      this.hourFimAgendamento = Math.floor(diferenca / 3600);
      this.minutesFimAgendamento = Math.floor(
        (diferenca - this.hourFimAgendamento * 3600) / 60
      );

      var condincao =
        this.hourFimAgendamento +
        "" +
        (this.minutesFimAgendamento.toString().length < 2
          ? "0" + "" + this.minutesFimAgendamento
          : this.minutesFimAgendamento);

      var diffHoras =
        parseInt(condincao) > 759
          ? parseInt(this.hourFimAgendamento) - 8
          : this.hourFimAgendamento;
      var diffDias = dayFim - this.agendamentodayFim;
      diffDias = parseInt(condincao) > 759 ? diffDias + 1 : diffDias;

      this.agendamento.dif_dias_definida = parseInt(diffDias.toString().replace("-", ""));
      this.agendamento.dif_horas_definida = diffHoras;
      this.agendamento.dif_minutos_definida = this.minutesFimAgendamento;
    }
  }

  selectToAddTarefas(item: any) {
    this.configService.loaddinStarter('start');
      this.colaborador.coloborador_nome = item.nome_colaborador;
      this.colaborador.colaborador_id = item.colaborador_id;

      this.http.__call('ordemservico/tarefa-by-colaborador-execucao' , {
        agendamento_id : this.routerParamAgendamentoId,
        colaborador_id : item.colaborador_id
      }).subscribe(
        response => {

          this.tarefasSelecionados = Object(response).data;

          this.configService.loaddinStarter('stop');
        }
      );


  }  private selectedResponsavel(id) {
     console.log('toggle change 1')
    this.recursosHumanos.colaborador_id = id;
    this.colaboradores.forEach((element,index) => {
      if (this.recursosHumanos.colaborador_id == element.id) {
        this.recursosHumanos.colaborador_id = element.id;
        this.colaboradores[index]={...element,is_responsavel:!this.is_responsavel}
        this.is_responsavel = !this.is_responsavel;
        console.log('toggle change 2',this.is_responsavel)
      }else{
        this.colaboradores[index]={...element,is_responsavel:false}
      }
    });
    this.is_responsavel=false
  }
  public adicionarTarefas(item) {
    var validar = 0;
    var tarefa_nome = "";
    var tarefa_id = "";
    var tarefa_plano_id = "";
    let tarefa_colaborador_id=null
    var agendamento_id = "";
    this.colaborador.coloborador_nome = item;
    //this.colaborador.colaborador_id = item.colaborador_id;
    if (this.tarefa.inicio_execucao == null) {
      this.configService.showAlert('Digite a Data início execução', "alert-danger", true);
    } else if (this.tarefa.fim_execucao == null) {
      this.configService.showAlert("Digite a Data fim execução", 'alert-danger', true);
    } else if (this.tarefa.inicio_execucao >= this.tarefa.fim_execucao) {
      this.configService.showAlert("A data fim não pode ser igual ou inferior a data de início - Colaborador(a): " + this.colaborador.coloborador_nome, 'alert-danger', true);
    } else {
      console.log(this.tarefaSelectBox,'tarefaSelectBox')
      this.tarefaSelectBox.forEach((element) => {
        if (element.id == this.tarefa.id) {
          tarefa_nome = element.descricao;
          tarefa_id = element.id;
          tarefa_colaborador_id=element.tarefa_colaborador_id
          tarefa_plano_id = element.tarefa_plano_id;
          agendamento_id = element.agendamento_id;

        }
      });
     console.log(this.colaborador)
      var tarefaList = {
        tarefa_id: tarefa_id,
        tarefa_nome: tarefa_nome,
        tarefa_colaborador_id:tarefa_colaborador_id,
        tarefa_plano_id: tarefa_plano_id,
        agendamento_id: agendamento_id,
        nome_colaborador: this.colaborador.coloborador_nome,
        inicio_execucao: this.tarefa.inicio_execucao.replace('T', ' '),
        fim_execucao: this.tarefa.fim_execucao.replace('T', ' '),
        colaborador_id: this.colaborador.colaborador_id
      };

      if (this.tarefasSelecionados.length >= 1) {
     /*    for (let index = 0; index < this.tarefasSelecionados.length; index++) {
          const l = this.tarefasSelecionados[index];
          if ((l.tarefa_id == tarefaList.tarefa_id) && (tarefaList.colaborador_id == l.colaborador_id)) {
            this.tarefasSelecionados.splice(index, 1);
            this.tarefasSelecionados.splice(index, 0, tarefaList);
            validar = 1;
          }
        } */
        if (validar == 0) {
          this.insertTarefa(tarefaList);
          this.tarefasSelecionados.push(tarefaList);
        }else{
          this.insertTarefa(tarefaList)
          this.tarefasSelecionados.push(tarefaList);
        }
      } else {

        this.insertTarefa(tarefaList)
        this.tarefasSelecionados.push(tarefaList);
      }

  }
  }

  deleteTarefa(i:any,item: any) {

    var validar = 0;

    var deletedTarefas = {
      tarefa_nome: item.tarefa_nome,
      tarefa_id: item.tarefa_id,
      colaborador_id: item.colaborador_id,
    };
  console.log(i,item)
    if (this.tarefasToDelete.length >= 1) {

      console.log('upper the hood')
      this.tarefasToDelete.splice(i,1);
      if (validar == 0) {
        this.updateTarefa(item.tarefa_colaborador_id)
        this.tarefasToDelete.push(deletedTarefas);
      }
    } else {
      this.updateTarefa(item.tarefa_colaborador_id)
      this.tarefasToDelete.push(deletedTarefas);
    }
     this.tarefasToDelete.splice(i,1);
     console.log('under the hood')
     this.tarefasSelecionados.splice(i, 1);

  }

  private updateTarefa(id) {

    this.http.__call('ordemservico/delete-tarefa-colaborador-execucao/' + id, null).subscribe(
      res => {
        if (Object(res).code == 200) {

        } else {

        }
      }
    )

}

  private insertTarefa(tarefaList) {

    this.http.__call('ordemservico/tarefa-colaborador-store-execucao', {
      tarefa_colaborador: tarefaList
    }).subscribe(
      res => {
        if (Object(res).code == 200) {
      //   this.selectToAddTarefas(tarefaList)
         this.getInicioFimExecAgendamento(this.routerParamAgendamentoId)
        } else {

        }
      }
    )

}

validateIfTarefaIsnull(){

  if(this.tarefa.id != null){
    this.isAddTarefa = false;
  }else{
    this.isAddTarefa = true;
  }
}


public resTarefasExec = [];

getInicioFimExecAgendamento(id){


  var dates_prevista_inicio = [];
  var dates_prevista_fim = [];

  this.http.call_get('ordemservico/data-inicio-fimExcecAgendamento/' + id, null).subscribe(
    response => {

      this.resTarefasExec = Object(response).data;
    }
  );

  for (let index = 0; index < this.resTarefasExec.length; index++) {
    dates_prevista_inicio.push(new Date(this.resTarefasExec[index].inicio_execucao))
    dates_prevista_fim.push(new Date(this.resTarefasExec[index].fim_execucao))
  }

  var maxDate = Math.max.apply(null, dates_prevista_fim)
  var mimDate = Math.min.apply(null, dates_prevista_inicio)
  // convert back to date object
  maxDate = new Date(maxDate)
  mimDate = new Date(mimDate)


  this.agendamento.inicio_execucao = this.formatDate(mimDate);
    this.agendamento.fim_execucao = this.formatDate(maxDate);

    this.agendamentoAddInicio();
    this.agendamentoAddFim();


}

  private getTarefasToAdd(id,item) {
    this.coloborador_nome=item.nome_colaborador;
    this.colaborador.coloborador_nome = item.nome_colaborador;
    this.colaborador.colaborador_id = item.colaborador_id;
    this.http.call_get('ordemservico/tarefas-aberta-by-agendamento/' + id, null).subscribe(
      response => {

        this.tarefasToAdd = Object(response).data;
      }
    );
  }

/*   private selectedResponsavel(id) {

    this.recursosHumanos.colaborador_id = id;
    this.colaboradores.forEach(element => {
      if (this.recursosHumanos.colaborador_id == element.id) {
        this.recursosHumanos.colaborador_id = element.id;
        this.is_responsavel = !this.is_responsavel;
      }
    });

  } */

  private editarColoborador() {

      this.configService.loaddinStarter('start');

      this.http.__call('ordemservico/agendamento/update', {
        ordemServico: this.ordemServico,
        routerParamId: this.routerParamId,
        agendamento: this.agendamento,
        consumiveis: this.consumiveisSelecionados,
        recursosHumanos: this.recursosSelecionados,
        colaboradoresToDelete: this.colaboradoresToDelete,
        consumiveisToDelete: []
      }).subscribe(
        res => {
          if (Object(res).code == 200) {
           // this.isDisabled = true;
            this.configService.showAlert("colaborador eliminado", "alert-success", true);

          }
          this.configService.loaddinStarter('stop');
        }
      )

   // }
  }

  private finalizar(is_fechar_agendamento, is_fechar_ordem, is_new_ordem) {
    if (
      this.agendamento.inicio_execucao == null ||
      this.agendamento.inicio_execucao == ""
    ) {
      this.configService.showAlert(
        "Digite a Data Início / Efectiva",
        "alert-danger",
        true
      );
    }

    if (
      this.agendamento.fim_execucao == null ||
      this.agendamento.fim_execucao == ""
    ) {
      this.configService.showAlert(
        "Digite a Data Término / Efectiva",
        "alert-danger",
        true
      );
    } else if (
      this.agendamento.inicio_execucao >= this.agendamento.fim_execucao
    ) {
      this.configService.showAlert(
        "Data de início / Efectiva não pode ser superior ou igual a Data de término / Efectiva",
        "alert-danger",
        true
      );
    } else if (
      this.agendamento.descricao_intervencao == null ||
      this.agendamento.descricao_intervencao == ""
    ) {
      this.configService.showAlert(
        "Digite a descrição..",
        "alert-danger",
        true
      );
    } /* else if (this.agendamento.data_previsao_inicio > this.agendamento.inicio_execucao) {
      this.configService.showAlert("Data Início / Prevista não pode ser superior que a Data início Efetiva", 'alert-danger', true);
    } */ else {
      this.isFinalizar = false;
      this.isDisabled = true;
      this.configService.loaddinStarter("start");

      this.http
        .__call("ordemservico/execucao/create", {
          ordemServico: this.ordemServico,
          routerParamId: this.routerParamId,
          agendamento: this.agendamento,
          consumiveis: this.consumiveisSelecionados,
          is_fechar_ordem: is_fechar_ordem,
          recursosHumanos: this.recursosSelecionados,
          departamentos: this.departamentoArray,
        })
        .subscribe(
          (res) => {
            if (Object(res).code == 200) {
              this.isDisabled = true;
              this.configService.showAlert(
                Object(res).message,
                "alert-success",
                true
              );

              if (
                this.ordemServico.tipo_cliente_flag &&
                this.ordemServico.local_consumo_id
              )


              if (is_new_ordem == true) {
                this.router.navigate([
                  "/ordemservico/agendar",
                  this.routerParamId,
                ]);
              }

              this.validateButton = Object(res).code;

              this.ordemServico.success_ordem_servico = true;

              this.ordemServico.agendamentoGerado = this.agendamento.id;
              this.getTarefasByAgendamento(this.routerParamAgendamentoId);
            } else {
              this.configService.showAlert(
                Object(res).message,
                "alert-danger",
                true
              );
              this.isFinalizar = false;
              this.isDisabled = false;
            }
            this.configService.loaddinStarter("stop");
          },
          (error) => {
            this.configService.loaddinStarter("stop");
            this.isFinalizar = false;
            this.isDisabled = false;
          }
        );
    }
  }


  private imprimirAgendamento(id) {
    this.configService.imprimirAgendamento(id, "2ª Via", "imprimir");
  }

  private imprimirAgendamentoPreventivo(id) {
    this.configService.imprimirAgendamentoPreventivo(id, "2ª Via", "imprimir");
  }



  public getContadoresDisponiveisByNumeroSerie(flag = true) {
    this.view_serie_contador = false;

    let aux_serie = this.contador.numero_serie;
    //console.log(this.ordemServico.local_consumo_id);

    this.http
      .__call("contador/getContadoresDisponiveisByNumeroSerie", {
        start: 1,
        end: 15,
        search: this.contador.numero_serie,
      })
      .subscribe((response) => {
        this.contadores = Object(response).data.data;

        //console.log(Object(response));
        this.configService.loaddinStarter("stop");
      });


    this.contador.numero_serie = aux_serie;

    //console.log(this.contadores);
  }


  private getColaboradores() {
    this.getAllColaboradores();
  }

  private getAllColaboradores() {
    this.colaboradores = [];

    this.configService.loaddinStarter("start");
    //this.pagination.search = (search == "" || search == null ? null : search);
    this.http
      .__call("colaborador/colaboradoresByFuncao", {
        start: 1,
        end: 10,
        search:
          this.recursosHumanos.colaborador_nome == "" ||
          this.recursosHumanos.colaborador_nome == null
            ? null
            : this.recursosHumanos.colaborador_nome,
        funcao_id: this.recursosHumanos.funcao_id,
      })
      .subscribe((res) => {
        this.colaboradores = Object(res).data.data;
        this.configService.loaddinStarter("stop");
      });
  }

}
