import { Component, OnInit, Input, Output, EventEmitter, SimpleChange } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-create-or-edit-mp-especialista',
  templateUrl: './create-or-edit-mp-especialista.component.html',
  styleUrls: ['./create-or-edit-mp-especialista.component.css']
})
export class CreateOrEditMpEspecialistaComponent implements OnInit {

  @Input() modal: string = "modalEspecialistaCreateOrEdit";
  @Input() title: string = "Registar Especialista";
  @Input() resultados: any;

  submitted = false;
  formErrors: any;
  private loading: boolean = false;

  @Input() EspecialistaForm: FormGroup;

  @Output() private loadListunidades = new EventEmitter<any>();


  constructor(
    private http: HttpService,
    private configService: ConfigService,
    private formBuilder: FormBuilder
  ) {
    this.createForm();
  }

  ngOnInit() { }

  createForm() {
    this.EspecialistaForm = this.formBuilder.group({
      index: [{ value: null, disabled: true }],
      descricao: [null, Validators.required],
      designacao: [null, Validators.required],
    })
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.EspecialistaForm.controls;
  }

  onReset() {
    this.submitted = false;
    this.EspecialistaForm.reset();
  }


  onSubmit() {
    this.submitted = true;
    // parar aquei se o formulário for inválido
    if (this.EspecialistaForm.invalid) {
      return;
    }
    this.loading = true;
    const index = this.EspecialistaForm.getRawValue().index;
    // TODO: usado para fazer a requisição com a api de criação de objsct or update
    const uri = (index === null ? 'manutencao-preventiva/especialista/register' : 'manutencao-preventiva/especialista/update/' + index);
    this.createOrEdit(uri, this.EspecialistaForm, (index === null ? true : false));
  }

  createOrEdit(uri: any, formulario: FormGroup, isCreate: boolean) {
    // TODO: usado para fazer a requisição com a api de criação de object
    this.http.__call(uri, formulario.value).pipe(first()).subscribe(
      response => {
        this.submitted = false;
        this.loading = false;
        if (isCreate && Object(response).code == 200) {
          formulario.reset();
        }
        if (Object(response).code == 200) {
          this.loadList_unidades(Object(response).data);
        }
      },
      error => {
        this.submitted = false;
        this.loading = false;
      });
  }


  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {

    if (this.resultados !== undefined) {

      this.title = "Editar Especialista";
      this.EspecialistaForm.patchValue({
        index: this.resultados.id,
        descricao: this.resultados.descricao,
        designacao: this.resultados.designacao
      });
      
    } else {
      this.onReset()
      this.title = "Registar Especialista";
    }
  }

  public loadList_unidades(resultados) {
    this.loadListunidades.emit(resultados);
  }
}
