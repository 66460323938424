import { Component, OnInit, Input, createPlatformFactory, OnDestroy } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { ExcelService } from 'src/app/services/excel.service';
import { ConfigModuloService } from 'src/app/services/config-modulo.service';
import * as moment from 'moment';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-conf-municipio',
  templateUrl: './conf-municipio.component.html',
  styleUrls: ['./conf-municipio.component.css']
})
export class ConfMunicipioComponent implements OnInit, OnDestroy {

  @Input() simpleFormMunicipio: FormGroup;

  private municipio = {
    id: null,
    nome: null,
    has_distrito: null,
    provincia_id: null,
    user_id: null,
    //municipioModal: false
  };

  private title: string = null;

  private items: any = [];
  private distritos: any = [];
  private provincias: any = [];
/*  private estados: any = []; */

  constructor(private http: HttpService, private configService: ConfigService,private excelService: ExcelService, private config: ConfigModuloService) {
  }



  exportAsXLSX(): void {
    var CurrentDate = new Date();
    var nameFile = "lista_municipios -" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    this.excelService.exportAsExcelFile(document.getElementsByClassName("exportAsXLSXCliente")[0], nameFile);
  }
  exportAsPDF(): void {
    //this.reportLoja.relatorioLoja(this.cobrancas, this.simpleForm.value, 'save');
  }

  imprimirPDF(): void {
    //this.reportLoja.relatorioLoja(this.cobrancas, this.simpleForm.value);
  }

  ngOnInit() {
    this.http.filters.search = null
    this.getPageFilterData(1);
  }
  ngOnDestroy(){
    this.http.filters.orderBy = null
  }

  private getDadosModal() {

  }

  private getMunicipios() {

    this.configService.loaddinStarter('start');

    this.http.__call('municipio/listagem', this.http.filters).subscribe(

      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.configService.loaddinStarter('stop');

      }
    );
  }

  private setDataMunicipio(item) {

    if (item !== undefined) {
      this.title = "Editar Munícipio";

      this.municipio.id = item.id;
      this.municipio.nome = item.nome;
      this.municipio.has_distrito = item.has_distrito;
      this.municipio.provincia_id = item.provincia_id;
      this.municipio.user_id = item.user_id;
    }
  }


  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page =Number.isInteger(page) ? page : 1 ;
    this.getMunicipios();
  }

  private getDistritosByMunicipio(id){

    this.configService.loaddinStarter('start');

    this.http.call_get('distrito/getDistritosByMunicipio/' + id, this.http.filters).subscribe(

      response => {

        this.distritos = Object(response).data.data;
        this.configService.loaddinStarter('stop');

      }
    );
  }


  private selectBoxProvincias() {
    this.http.call_get('provincia/selectBox', null).subscribe(
      response => {

        this.provincias = Object(response).data;

        //console.log(this.provincias)
      }
    );
  }



  public getConfiguracaos() {

    let result = null;

    const slugs = [
      this.config.provincia_default
    ];
    //console.log(slugs);

    for (let index = 0; index < slugs.length; index++) {

      //console.log(slugs[index]);
      this.http.__call('configuracao/getConfiguracaobySlug/' + slugs[index], null).subscribe(
        response => {

          //console.log(Object(response));

          if (Object(response).code != 200) {
            //this.config.saveConfig(slugs[index], this.config.modulo.CONFIGURACOES, null);
            result = null;
          }
          else {

            result = Object(response).data;

            if (slugs[index] == this.config.provincia_default)
            {
              this.municipio.provincia_id = result.valor;

              //console.log("this.bairro.provincia_id " + this.municipio.provincia_id)
            }

          }
        });
    }
  }

}
