import { Component, OnInit, Input } from "@angular/core";
import { HttpService } from "src/app/providers/http/http.service";
import { ConfigService } from "src/app/providers/config/config.service"; 

@Component({
  selector: 'app-ciclo-facturacao',
  templateUrl: './ciclo-facturacao.component.html',
  styleUrls: ['./ciclo-facturacao.component.css']
})
export class CicloFacturacaoComponent implements OnInit {
  private ciclo_facturacao = {
    id: null,
    ano: null,
    mes: null,
    serie: null,
    dia_facturacao: null,
    dia_ultima_leitura: null,
    estado: null,
    serie_id: null

  }


  private meses = [
    { nome: "Janeiro", id: "1" }, 
    { nome: "Fevereiro", id: "2" },
    { nome: "Março", id: "3" }, 
    { nome: "Abril", id: "4" },
    { nome: "Maio", id: "5" }, 
    { nome: "Junho", id: "6" },
    { nome: "Julho", id: "7" }, 
    { nome: "Agosto", id: "8" },
    { nome: "Setembro", id: "9" }, 
    { nome: "Outubro", id: "10" },
    { nome: "Novembro", id: "11" }, 
    { nome: "Dezembro", id: "12" }
  ];

  private dias = [
    { dia: "01" }, { dia: "02" }, { dia: "03" }, { dia: "04" }, { dia: "05" },
    { dia: "06" }, { dia: "07" }, { dia: "08" }, { dia: "09" }, { dia: "10" },
    { dia: "11" }, { dia: "12" }, { dia: "13" }, { dia: "14" }, { dia: "15" },
    { dia: "16" }, { dia: "17" }, { dia: "18" }, { dia: "19" }, { dia: "20" },
    { dia: "21" }, { dia: "22" }, { dia: "23" }, { dia: "24" }, { dia: "25" },
    { dia: "26" }, { dia: "27" }, { dia: "28" }, { dia: "29" }, { dia: "30" },
    { dia: "31" },
  ];

  private items: any = [];
  private contador: any = [];
  private estados: any = [];
  private anos: any = []
  private series: any = []

  constructor(private http: HttpService, private configService: ConfigService) { }

  ngOnInit() {
    this.getPageFilterData(1);

  }

  private getEstado() {
    this.http.call_get('estado-ciclo-facturacao/selectBox', null).subscribe(
      response => {
        this.estados = Object(response);
      }
    );
  }

  private getSelectBox() {
    //this.getEstado();
    this.gerarAno();
  }

  private gerarAno() {
    var fecha = new Date();
    var anyo = fecha.getFullYear();

    let j = 0;
    for (let i = anyo; i >= 2000; i--) {
      this.anos[j] = i;
      j++;
    }
  }

  private setSerie(id, serie, sigla) {

    this.ciclo_facturacao.serie_id = id;
    this.ciclo_facturacao.serie = sigla + " " + serie;

    this.view_serie = false;

  }

  view_serie = true;

  private setNullSerie() {
    this.ciclo_facturacao.id = null;
    this.ciclo_facturacao.serie = null;
    this.view_serie = false;

  }
  private getSerie() {

    if (this.ciclo_facturacao.serie == "" || this.ciclo_facturacao.serie == null) {
      this.setNullSerie();
    }

    this.view_serie = true;
    this.http.__call('serie/searchSerie', { start: 1, end: 10, search: this.ciclo_facturacao.serie }).subscribe(
      response => {
        this.series = Object(response).data.data;

        this.configService.loaddinStarter('stop');
      }
    );
  }


  private getCicloFactura() {

    this.configService.loaddinStarter('start');

    this.http.__call('ciclo-facturacao/listagem', this.http.filters).subscribe(

      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.configService.loaddinStarter('stop');

      }
    );
  }


  private updateStateCicloFacturacao(item) {

    this.setDataCicloFacturacao(item);

    //console.log(item);
    this.http.__call('ciclo-facturacao/update/' + this.ciclo_facturacao.id, {

      dia_facturacao: this.ciclo_facturacao.dia_facturacao,
      ano: this.ciclo_facturacao.ano,
      mes: this.ciclo_facturacao.mes,
      serie_id: this.ciclo_facturacao.serie_id,
      estado: this.ciclo_facturacao.estado,
      dia_ultima_leitura: this.ciclo_facturacao.dia_ultima_leitura

    }).subscribe(
      response => {

        if (Object(response).code == 200) {
          var update = (this.ciclo_facturacao.estado == true) ? "Activado" : "Desactivado";

          this.configService.showAlert("Ciclo de Facturação " + this.ciclo_facturacao.serie + " foi " + update, "alert-success", true);
        }

      }
    );

    for (let i = 0; i < this.items.length; ++i) {
      if (this.items[i].id == this.ciclo_facturacao.id) {
        this.items[i].estado = this.ciclo_facturacao.estado;
      }
    }
  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
    this.getCicloFactura();
  }


  private register() {
    this.configService.loaddinStarter('start');

    if (this.ciclo_facturacao.ano == null) {
      this.configService.showAlert("Seleccione o Ano", 'alert-danger', true);
    } else if (this.ciclo_facturacao.mes == null) {
      this.configService.showAlert("Seleccione o Mês", 'alert-danger', true);
    } else if (this.ciclo_facturacao.dia_facturacao == null) {
      this.configService.showAlert("Seleccione o dia da facturação", 'alert-danger', true);
    } else if (this.ciclo_facturacao.dia_ultima_leitura == null) {
      this.configService.showAlert("Seleccione o dia da última leitura", 'alert-danger', true);
    }
    else {

      if (this.ciclo_facturacao.id == null) {

        this.http.__call('ciclo-facturacao/create',
          {

            dia_facturacao: this.ciclo_facturacao.dia_facturacao,
            ano: this.ciclo_facturacao.ano,
            mes: this.ciclo_facturacao.mes,
            serie_id: this.ciclo_facturacao.serie_id,
            dia_ultima_leitura: this.ciclo_facturacao.dia_ultima_leitura

          }).subscribe(
            response => {
              if (Object(response).code == 500) {
                this.configService.showAlert(Object(response).message, 'alert-danger', true);
                this.configService.loaddinStarter('stop');
              }
              else {
                this.configService.showAlert(Object(response).message, 'alert-success', true);
                this.ini();
                this.getCicloFactura()

                this.configService.loaddinStarter('stop');
              }
            }
          )
      } else {

        this.http.__call('ciclo-facturacao/update/' + this.ciclo_facturacao.id, {

          dia_facturacao: this.ciclo_facturacao.dia_facturacao,
          ano: this.ciclo_facturacao.ano,
          mes: this.ciclo_facturacao.mes,
          serie_id: this.ciclo_facturacao.serie_id,
          estado: this.ciclo_facturacao.estado,
          dia_ultima_leitura: this.ciclo_facturacao.dia_ultima_leitura

        }).subscribe(
          response => {

            if (Object(response).code == 200) {
              this.configService.showAlert(Object(response).message, 'alert-success', true);
                this.ini();
                this.getCicloFactura()

                this.configService.loaddinStarter('stop');
            }

          }
        );

      }
    };
  }


  private refresh(id, ano, dia_facturacao, dia_ultima_leitura, estado) {
    this.ciclo_facturacao.id = id;
    this.ciclo_facturacao.ano = ano;
    this.ciclo_facturacao.dia_facturacao = dia_facturacao;
    this.ciclo_facturacao.dia_ultima_leitura = dia_ultima_leitura;
    this.ciclo_facturacao.estado = estado;
  }

  private setDataCicloFacturacao(item: any) {

    this.ciclo_facturacao.id = item.id;
    this.ciclo_facturacao.dia_facturacao = item.dia_facturacao;
    this.ciclo_facturacao.ano = item.ano;
    this.ciclo_facturacao.mes = item.mes;
    this.ciclo_facturacao.serie_id = item.serie_id;
    this.ciclo_facturacao.serie = item.sigla + " " + item.serieDescricao;
    this.ciclo_facturacao.dia_ultima_leitura = item.dia_ultima_leitura;
    this.ciclo_facturacao.estado = !item.estado;

  }


  private ini() {
    this.ciclo_facturacao = {
      id: null,
      ano: null,
      mes: null,
      serie: null,
      dia_facturacao: null,
      dia_ultima_leitura: null,
      estado: null,
      serie_id: null
    }
  }

}
