import { Component, OnInit, Input, createPlatformFactory, OnDestroy } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { ExcelService } from 'src/app/services/excel.service';
import * as moment from 'moment';
import { FormGroup } from '@angular/forms';
import { ConfigModuloService } from 'src/app/services/config-modulo.service';

@Component({
  selector: 'app-conf-distrito',
  templateUrl: './conf-distrito.component.html',
  styleUrls: ['./conf-distrito.component.css']
})
export class ConfDistritoComponent implements OnInit, OnDestroy {

  @Input() simpleFormDistrito: FormGroup;

  private distrito = {
    id: null,
    nome: null,
    is_active: null,
    municipio_id: null,
    provincia_id: null,
    user_id: null,
    //distritoModal: false
  };

  @Input() municipios: any = [];

  private municipio = {
    id: null,
    nome: null
  };

  private addRows: boolean = true;
  private title: string = null;

  private items: any = [];
  private distritos: any = [];
  /*  private estados: any = []; */

  constructor(
    private http: HttpService,
    private configService: ConfigService,
    private excelService: ExcelService,
    private config: ConfigModuloService) {
  }



  exportAsXLSX(): void {
    var CurrentDate = new Date();
    var nameFile = "lista_distritos -" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    this.excelService.exportAsExcelFile(document.getElementsByClassName("exportAsXLSXCliente")[0], nameFile);
  }
  exportAsPDF(): void {
    //this.reportLoja.relatorioLoja(this.cobrancas, this.simpleForm.value, 'save');
  }

  imprimirPDF(): void {
    //this.reportLoja.relatorioLoja(this.cobrancas, this.simpleForm.value);
  }

  ngOnInit() {
    this.http.filters.search = null
    this.getPageFilterData(1);
  }
  ngOnDestroy(){
    this.http.filters.orderBy = null
  }

  private getDadosModal() {

  }

  private getDistritos() {

    this.configService.loaddinStarter('start');

    this.http.__call('distrito/listagem', this.http.filters).subscribe(

      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.configService.loaddinStarter('stop');

      }
    );
  }

  private initDistrito() {

    this.distrito.id = null;
    this.distrito.nome = null;
    this.distrito.municipio_id = null;
    this.distrito.provincia_id = null;
    this.distrito.is_active = null;
    this.distrito.user_id = null;

    this.municipios = null;

    this.addRows = true;
  }

  private setDataDistrito(item) {

    if (item !== undefined) {
      this.title = "Editar Munícipio";

      this.distrito.id = item.id;
      this.distrito.nome = item.nome;
      this.distrito.municipio_id = item.municipio_id;
      this.distrito.provincia_id = item.provincia_id;
      this.distrito.is_active = item.is_active;
      this.distrito.user_id = item.user_id;

      this.selectBoxMunicipiosByProvincia(item.provincia_id);

      this.municipio.id = item.municipio_id;
      this.municipio.nome = item.municipio;

      this.addRows = false;

      //console.log(item);
    }


  }

  private updateStateDistrito(item) {

    this.distrito.id = item.id;
    this.distrito.nome = item.nome;
    this.distrito.municipio_id = item.municipio_id;
    this.distrito.provincia_id = item.provincia_id;
    this.distrito.is_active = !item.is_active;
    this.distrito.user_id = item.user_id;

      //console.log(item);
    this.http.__call('distrito/update/' + this.distrito.id, this.distrito).subscribe(
      response => {

        if (Object(response).code == 200) {
          var update = (this.distrito.is_active == true) ? "Activado" : "Desactivado";

          this.configService.showAlert("Distrito " + this.distrito.nome + " foi " + update, "alert-success", true);
        }

      }
    );

    for (let i = 0; i < this.items.length; ++i) {
      if (this.items[i].id == this.distrito.id) {
        this.items[i].is_active = this.distrito.is_active;
      }
    }
  }


  private selectBoxMunicipiosByProvincia(id) {

    //console.log(this.distrito);

    this.http.call_get('municipio/getMunicipiosByProvincia/' + id, null).subscribe(
      response => {
        //console.log(Object(response).data);

        this.municipios = Object(response).data;
      }
    );
  }


  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
    this.getDistritos();
  }

  private getBairrosByDistrito(id) {

    this.configService.loaddinStarter('start');

    this.http.call_get('distrito/getBairrosByDistrito/' + id, this.http.filters).subscribe(

      response => {

        this.distritos = Object(response).data.data;
        this.configService.loaddinStarter('stop');

      }
    );
  }

  public getConfiguracaos() {

    let result = null;

    const slugs = [
      this.config.provincia_default
    ];
    //console.log(slugs);

    for (let index = 0; index < slugs.length; index++) {

      //console.log(slugs[index]);
      this.http.__call('configuracao/getConfiguracaobySlug/' + slugs[index], null).subscribe(
        response => {

          //console.log(Object(response));

          if (Object(response).code != 200) {
            //this.config.saveConfig(slugs[index], this.config.modulo.CONFIGURACOES, null);
            result = null;
          }
          else {

            result = Object(response).data;

            if (slugs[index] == this.config.provincia_default) {
              this.distrito.provincia_id = result.valor;
              this.selectBoxMunicipiosByProvincia(result.valor);
            }
          }
        });
    }
  }

}
