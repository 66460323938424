import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService } from 'src/app/providers/config/config.service';
import { ContaCorrenteReportService } from 'src/app/components/conta-corrente/conta-corrente-report.service';
import { ExcelService } from 'src/app/services/excel.service';
import { ContaCorrenteService } from 'src/app/services/ExportExcel/conta-corrente.service';
import * as moment from 'moment';
import { Chart, ChartOptions } from 'chart.js';
import { ApiService } from "src/app/providers/http/api.service";
import { debounceTime, finalize } from 'rxjs/operators';
import { resolve } from 'url';
import { ReciboService } from 'src/app/components/reports/recibo/recibo.service'
import { RoteiroService } from '../reports/roteiro/roteiro.service';
import { RotaService } from '../config-rotas/rotas.service';
import { ExcelAutoService } from 'src/app/services/excel/excel-auto.service';
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'
import { DevolucaoService } from '../reports/devolucao.service';
import { ModalOrdemServicoService } from '../ordemservico/modal-create-ordem-trabalho/modal-ordem-servico.service';

@Component({
  selector: 'app-conta-corrente',
  templateUrl: './conta-corrente.component.html',
  styleUrls: ['./conta-corrente.component.css']
})

export class ContaCorrenteComponent implements OnInit {

  //public barChartPlugins = [ChartDataLabels];

  @ViewChild("serie") serie;
  @ViewChild("orderBy") orderBy;
  @ViewChild("observacao") observacao;
  @ViewChild("serieRecibo") serieRecibo;
  private totalFacturacao = 0;
  private totalSemImposto = 0;
  private totalComImposto = 0;
  private serieId: number;
  private serieReciboId: number;
  private series: any = [];
  private totalAdiantamento: any;
  private facturas_nota_credito: any = null;
  public contratoHistorico = null
  public histirico_consumo = []
  private series_nc: any = [];
  private serie_id: any;

  private btnClickBoleon = false;
  CurrentDate = new Date();
  mes = moment(this.CurrentDate).format("MM")
  loading_factura: boolean = false;
  private factura = {
    factura_id: null,
    factura_sigla: null,
    status_reason: null,
    status_date: null,
    status: null,
    numero: null,
    pago: null,
    created_at: null,
    cliente_id: null,
    cliente_nome: null,
    serie: null,
    documento: null,
    sigla: null,
    total: null,
    totalComImposto: null,
    totalSemImposto: null,
  }
  private cliente = {
    id: null,
    nome: null,
    telefone: null,
    morada: null,
    contribuente: null
  }

  private pagination = {
    total: null,
    perPage: 20,
    page: 1,
    lastPage: null,

    start: 1,
    end: 10,
    search: "a",
    order: "created_at",
    municipio_id: "T"
  };

  private conta_corrente = {
    cliente: {
      id: null,
      nome: null,
      telefone: null,
      morada: null,
      contribuente: null
    },
    keyFilter: "",
    search: "",
    municipio_id: "T"

  }

  private DadosEmpresa: any = [];

  private selectBox = {
    pago: null
  }

  private contrato = {

    id: null,
    tipo_contracto_id: null,
    tipo_contrato: null,
    tipo_medicao_id: null,
    tipo_medicao: null,
    tipo_medicao_slug: null,
    tipo_facturacao_id: null,
    tipo_facturacao: null,
    tipologia_cliente_id: null,
    tipologia_cliente: null,
    tipologia_cliente_juro_mora: null,
    tipologia_cliente_sujeito_corte: null,
    tipologia_cliente_caucao: null,
    nivel_sensibilidade_id: null,
    nivel_sensibilidade: null,
    objecto_contrato_id: null,
    objecto_contrato: null,
    tarifario: null,
    classe_tarifario: null,
    classe_tarifario_consumo_minimo: null,
    classe_tarifario_consumo_maximo: null,
    numero_habitantes: null,
    data_inicio: null,
    data_fim: null,
    rua_id: null,
    morada_correspondencia_flag: false,
    estado_contrato_id: null,
    estado_contrato: null,
    estado_contrato_slug: null,

    data_rescisao: null,
    motivo_recisao_id: null,
    estado_rescisao_id: null,
    motivo_recisao: null,
    motivo_recisao_flag: null,
    conta_id: null,

    contador_id: null,
    numero_serie: null,

    instalacao_sanitaria_qtd: null,
    reservatorio_flag: null,
    reservatorio_capacidade: null,
    piscina_flag: null,
    piscina_capacidade: null,
    jardim_flag: null,
    campo_jardim_id: null,
    campo_jardim: null,
    poco_alternativo_flag: null,
    fossa_flag: null,
    fossa_capacidade: null,
    acesso_camiao_flag: null,
    anexo_flag: null,
    anexo_quantidade: null,
    caixa_contador_flag: null,
    abastecimento_cil_id: null,
    abastecimento_cil: null,

    cliente_id: null,
    cliente: null,
    info_source: null,

    local_consumo_id: null,
    local_instalacao_id: null,
    contrato_id: null
  };

  private _conta = {
    id: null
  }
  private datas = {
    data1: null,
    data2: null
  }

  data_caucao: any = []

  caucao = {
    valor_caucao: 0.0,
    factura_ref_caucao: null
  }
  private estadoActual: string = null;

  private loadingContrato: string = "Contrato"
  private clientes: any = [];
  private municipios: any[] = []
  private contas: any = [];
  private contas_caucao: any = [];
  private conta: any = [];
  private contratos: any = [];
  // private FacturasExportExcel: any = [];
  private facturas: any = [];
  private recibosCount = 0;
  private nota_creditoCount = 0;
  private facturasCount = 0;
  private dados_factura = {
    cliente: null,
    sigla_documento: null
  };
  private dadosFacturaId: any = [];
  private produtosNotaCredito: any = [];

  private contaCorrente;
  private clienteNome;
  private saldocliente;
  private clienteContribuinte;
  private contaCorrenteDados: any = [];
  private arr: any = []
  private saldo: any = []

  private somaNotacredito = 0.0;
  private somaFactura = 0.0;
  private somaRecibo = 0.0;
  private somaFacturas_Recibo = 0.0;

  private somaNotacreditoExcel = 0.0;
  private somaFacturaExcel = 0.0;
  private somaReciboExcel = 0.0;
  private totalAberto = 0.0;

  private valorTotal = 0.0;
  private factura_aberto = 0;
  private movimentoAdiantamento: any = [];
  _cliente: any = {}
  private current_cliente = {
    cliente: null,
    contrato: null
  }

  private validacao = false;

  tipofiltro = 0;

  public pieChartLabels = ['Sales Q1', 'Sales Q2', 'Sales Q3', 'Sales Q4'];
  public pieChartData = [120, 150, 180, 90];
  public pieChartType = 'pie';

  myChart: any = []
  id;
  constructor(private http: HttpService,
    private configService: ConfigService,
    private PDFrecibo: ReciboService,
    private PDFdevolucao: DevolucaoService,
    private ContaCorrenteReportService: ContaCorrenteReportService,
    private _route: Router, private route: ActivatedRoute,
    private execel: ContaCorrenteService,
    private reportRoteiro: RoteiroService,
    private flag: ModalOrdemServicoService,
    private _rotaService: RotaService,
    private excelService: ExcelAutoService,
    private api: ApiService) {
    this.route.paramMap.subscribe(params => {
      this.id = +params.get('id');
    });
  }

  ngOnInit() {
    this.ListarClientes(this.pagination.page, this.pagination.perPage, this.conta_corrente.search, 'nome', this.conta_corrente.municipio_id);
    this.listarseries(1, 1000);
    this.getMunicipios()
    this.empresaUser()
    this.getSeries()
    this.flag.flag = "CONTA_CORRENTE"
  }

  private setDataContrato(item) {
    this.contrato = { ...item, id: item.contrato_id, conta_id: this._conta.id };
  }

  private setDataContratoCliente(contrato) {

    this.contrato = contrato;
    this.contrato.info_source = contrato.info_source;
    this.contrato.estado_contrato_id = null;

    this.estadoActual = contrato.estado_contrato;

  }

  private chartts() {

    let factura_nao_paga = 0;
    let facturas = 0;
    let factura_nuladas = 0
    let nota_credito = 0;
    let recibo = 0;

    let facturas_recibo = 0;
    this.factura_aberto = 0;

    //console.log(this.contas.facturas_recibo);
    // facturas
    if (this.contas.facturas.length == 0) {
      facturas = 0;
      factura_nao_paga = 0;
      factura_nuladas = 0;
      var fact = 0;

    } else {


      for (let index = 0; index < this.contas.facturas.length; index++) {

        //console.log(this.contas.facturas[index].pago);
        if (this.contas.facturas[index].pago == 0 && this.contas.facturas[index].sigla == "FT") {
          factura_nao_paga++;
          this.factura_aberto = factura_nao_paga;

        } if (this.contas.facturas[index].sigla == "FT") {
          facturas++;
        }
        if (this.contas.facturas[index].status == "A") {
          factura_nuladas++;
        }
      }
    }
    // Notas de Credito
    if (this.somaNotacredito == 0) {
      nota_credito = 0;
    } else {
      nota_credito = this.somaNotacredito;
    }

    // recibos
    if (this.somaRecibo == 0) {
      recibo = 0
    } else {
      recibo = this.somaRecibo;
    }

    if (this.contas.facturas_recibo.length == 0) {
      facturas_recibo = 0
    } else {
      facturas_recibo = this.contas.facturas_recibo.length;
    }

    function ConvertNum(num) {
      return (
        num
          .toFixed(2) // duas casas decimais
          .replace('.', ',') // separadores,
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
      ) // use . as a separador
    }

    var somas = [ConvertNum(this.somaFacturas_Recibo), ConvertNum(this.somaFactura), ConvertNum(this.somaRecibo), ConvertNum(this.somaNotacredito)]
    this.myChart = new Chart('myChart', {
      type: 'bar',
      data: {
        labels: ['Facturas Recibo', 'Facturas', 'Recibos', 'Notas de Credito'],
        datasets: [{

          data: [this.somaFacturas_Recibo, this.somaFactura, this.somaRecibo, this.somaNotacredito],
          backgroundColor: [
            'rgb(0, 102, 255)',
            'rgb(143, 170, 220)',
            'rgba(0, 112, 192)',
            'rgba(255, 99, 132, 1)'],
          borderColor: [
            'rgb(0, 102, 255)',
            'rgb(143, 170, 220)',
            'rgba(0, 112, 192)',
            'rgba(255, 99, 132, 1)'],
          borderWidth: 1
        }]
      },
      options: {
        tooltips: {
          enabled: true
        },
        animation: {

          onComplete: function () {
            var chartInstance = this.chart,
              ctx = chartInstance.ctx;
            ctx.textAlign = 'center';
            ctx.fillStyle = "rgba(0, 0, 0, 1)";
            ctx.textBaseline = 'bottom';
            ctx.font = '14px calibri';

            this.data.datasets.forEach(function (dataset, i) {
              var meta = chartInstance.controller.getDatasetMeta(i);
              meta.data.forEach(function (bar, index) {
                var data = somas[index];
                ctx.fillText(data, bar._model.x, bar._model.y - 5);

              });
            });
          }
        },
        title: {
          display: true,
          text: 'Totais de Facturação (em AOA)',
          fontSize: 16,
          padding: 20
        },
        legend: { display: false },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    });
  }
  private pesquisarCliente(e) {
    this.pagination.page = 1;
    this.tipofiltro = 0;
    this.ListarClientes(this.pagination.page, this.pagination.perPage, this.conta_corrente.search, 'nome', this.conta_corrente.municipio_id);
  }

  private getFactura(factura: any) {
    this.dados_factura = factura;
  }

  private DownloadFactura(id) {
    this.configService.imprimirFactura(id, "2ª Via", "download");
  }

  ncLengthAnulado = 0;
  public showFactura(id: number) {
    this.ncLengthAnulado = 0;
    this.facturas_nota_credito = null;
    this.loading_factura = true;
    this.api
      .get("facturas/" + id)
      .pipe(
        finalize(() => {
          this.loading_factura = false;
        })
      )
      .subscribe((response) => {
        if (Object(response).code == 200) {
          this.facturas_nota_credito = Object(response).data;
          for (
            let index = 0;
            index < this.facturas_nota_credito.notas_credito.length;
            index++
          ) {
            const element = this.facturas_nota_credito.notas_credito[index];
            if (element.status == "N") {
              this.ncLengthAnulado++;
            }
          }
        } else {
          this.configService.showAlert(
            Object(response).message,
            "alert-danger",
            true
          );
        }
      });
  }
  getHistoricoConsumoByContrato(contrato_id: any) {
    this.contratoHistorico = contrato_id
    this.loading = true
    this.configService.loaddinStarter('start')

    this._rotaService.getHistoricoConsumoByContrato(contrato_id)
      .subscribe(
        response => {
          this.histirico_consumo = response
          this.loading = false
          this.configService.loaddinStarter('stop')
        }
      )
  }

  private getClienteByConta(id) {
    this.configService.loaddinStarter("start");

    this.http
      .call_get("cliente/getClienteByConta/" + id, null)
      .subscribe((res) => {
        this._cliente = Object(res);
        console.log('_cliente =>', this._cliente)
        this.configService.loaddinStarter("stop");
      });
  }

  clearSearch() {
    this.conta_corrente.search = ""
  }

  private ListarClientes(start, end, search, orderBy, municipio_id) {
    this.validacao = false;

    if (this.id != 0 && search == "") {
      this.configService.loaddinStarter('start');
      this.http.__call('cliente/search-cliente', { start: start, end: end, search: this.id, municipio_id: municipio_id }).subscribe(
        response => {

          console.log(response)
          this.clientes = Object(response).data.data;
          console.log(this.clientes)
          this.configService.loaddinStarter('stop');
          this.refreshClienteId(this.id);
          this.adiantamentos();

        }
      );

    }
    if (search != "") {
      this.configService.loaddinStarter('start');
      this.http.__call('cliente/search-clienteByKey', { start: start, end: end, search: search, keyFilter: this.conta_corrente.keyFilter, municipio_id: municipio_id }).subscribe(
        response => {

          this.clientes = Object(response).data ? Object(response).data.data : [];
          this.configService.loaddinStarter('stop');
        }
      );
    } else {
      this.clientes = [];
    }
  }

  private getMunicipios() {
    this.api.get('municipio/selectBox').subscribe(
      response => {
        this.municipios = Object(response).data
      }
    )
  }

  private ListarClientess(start, end, search, orderBy, municipio_id) {

    this.configService.loaddinStarter('start');
    this.pages = [];
    this.pagination.start = start;
    this.pagination.end = end;
    this.pagination.search = search;
    this.pagination.order = orderBy;
    this.pagination.municipio_id = municipio_id;

    this.http.__call('cliente/listar', this.pagination).subscribe(

      response => {
        this.pagination.lastPage = Object(response).data.lastPage;
        this.pagination.page = Object(response).data.page;
        this.pagination.total = Object(response).data.total;
        this.pagination.perPage = Object(response).data.perPage;

        this.clientes = Object(response).data.data;
        this.gerarPages();
        this.configService.loaddinStarter('stop');

      }
    );
  }

  private refreshDataCliente(id, nome, contribuente, telefone, morada) {
    this.cliente.id = id;
    this.cliente.nome = nome;
    this.cliente.contribuente = contribuente;
    this.cliente.telefone = telefone;
    this.cliente.morada = morada;
  }

  private mostrarResultados() {
    this.ListarClientes(this.pagination.page, this.pagination.perPage, this.conta_corrente.search, 'nome', this.conta_corrente.municipio_id);

  }

  private pages = [];
  private nextProvPageNumber = 1;

  private gerarPages() {
    for (var i = 1; i <= this.pagination.lastPage; i++) {
      this.pages.push(i);
    }
  }
  private nextPage() {
    this.nextProvPageNumber++;
    if (this.nextProvPageNumber < 0) {
      this.nextProvPageNumber = this.pagination.page;
    }
    this.ListarClientes(this.pagination.page, this.pagination.perPage, this.conta_corrente.search, 'nome', this.conta_corrente.municipio_id);

  }
  private prevPage() {
    this.nextProvPageNumber--
    if (this.nextProvPageNumber > 0) {
      this.ListarClientes(this.pagination.page, this.pagination.perPage, this.conta_corrente.search, 'nome', this.conta_corrente.municipio_id);
      this.nextProvPageNumber = this.pagination.page;
    }
  }

  private refreshPaginate(page) {
    this.pagination.page = page;
    this.ListarClientes(this.pagination.page, this.pagination.perPage, this.conta_corrente.search, 'nome', this.conta_corrente.municipio_id);
  }

  private deleteClienteNoSeleted(id) {

    this.clientes.forEach(element => {
      if (element.id == id) {
        this.conta_corrente.cliente.id = element.id;
        this.conta_corrente.cliente.nome = element.nome;
        this.conta_corrente.cliente.contribuente = element.contribuente;
        this.conta_corrente.cliente.telefone = element.telefone;
        this.conta_corrente.cliente.morada = element.morada;
        this.conta_corrente.search = element.nome;
      }
    });
    /*for (let i = 0; i < this.clientes.length; ++i) {
      if (this.clientes[i].id == id) {
      this.clientes.splice(i, 1);
      }
    }*/
    this.clientes = [];
  }

  private changeSelectValue() {
    this.selectBox.pago = this.selectBox.pago;
    this.datas = this.datas;
    this.contrato.contrato_id = this.contrato.contrato_id;

    if (this.contrato.contrato_id != null) {
      this.verificarContratoWithDivida(this.contrato.contrato_id)
      this.getCaucaoOfContrato();
    }
    //console.log(this.selectBox.pago)
  }

  private refreshClienteId(id) {
    this.cliente.id = id;
    this.tipofiltro = 1;
    this.contrato.contrato_id = null;
    this.datas.data1 = null;
    this.datas.data2 = null;
    this.selectBox.pago = null;
    this.conta.id = null
    //console.log(this.selectBox.pago)
    this.deleteClienteNoSeleted(id);
    this.contaCorrentes();
    //this.ExportaContaCorrente();
  }

  private filtrar() {
    if (this.datas.data1 > this.datas.data2) {

      this.configService.showAlert(Object(' A Data Inicial deve ser Maior ou igual a Data Final'), "alert-danger", true);
    } else {
      this.contaCorrentes();
    }
    //this.ExportaContaCorrente();
  }


  private contaCorrentes() {
    this.configService.loaddinStarter('start');
    this.http.__call('contaCorrente/contas', {
      cliente_id: this.cliente.id,
      estado_factura: this.selectBox.pago,
      contrato_id: this.contrato.contrato_id,
      datas: this.datas,
      conta_id: this._conta.id
    }).subscribe(
      res => {
        if (Object(res).code == 500) {
          this.configService.showAlert(Object(res).message, "alert-danger", true);
          this.configService.loaddinStarter('stop');
        } else {
          this.validacao = true;
          this.contas = Object(res).data;
          // this.FacturasExportExcel = Object(res).data.exportcontacorrentexcel;
          // console.log(this.contas.facturas);

          this.clienteNome = Object(res).data.cliente.nome;
          this.saldocliente = Object(res).data.cliente.saldo;
          this.clienteContribuinte = Object(res).data.cliente.contribuente;

          this.calcular();
          this.chartts();
          this.configService.loaddinStarter('stop');
        }
      }
    );
  }

  changeGetLeiturasByContrato() {
    this.getLeiturasByContrato();
  }

  leituras: any = []
  loading: boolean

  getLeiturasByContrato() {
    this.loading = true
    this.configService.loaddinStarter('start')

    this._rotaService.getLeiturasByContrato(this.contrato.contrato_id)
      .subscribe(
        response => {
          this.leituras = response.data
          this.loading = false
          this.configService.loaddinStarter('stop')
        }
      )
  }


  private empresaUser() {
    this.configService.loaddinStarter('start');
    this.http.call_get('empresa/empresa-user', null).subscribe(
      response => {
        this.DadosEmpresa = Object(response).data;
      }
    );
  }

  exportAsPDF(): void {
    this.current_cliente.cliente = this.clienteNome
    this.current_cliente.contrato = this.contrato.contrato_id

    var file = document.getElementsByClassName("tablePDF")[0]
    this.reportRoteiro.reportHistoricoLeituras(file, this.DadosEmpresa, 'save', 'Rotas', this.leituras.length, this.current_cliente);
  }

  exportAsXLSXAsHistorico(): void {
    var CurrentDate = new Date();

    var keys = [
      { key: 'cil', width: 35, style: { font: { name: 'Calibri' } } },
      { key: 'morada_format', width: 25 },
      { key: 'ultima_leitura', width: 55 },
      { key: 'leitura', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'consumo', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'is_facturada', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'tipo_facturacao', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'numero_serie', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'data_leitura', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'is_estado', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'data_registo', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'leitor', width: 20, style: { font: { name: 'Calibri' } } },
    ];

    var Cols = ["Cil", "Morada", "Última Leitura", "Leitura", "Consumo", "Facturado?", "Tipo De Facturação", "Contador", "Data de Leitura	", "Estado", "Data Registo	", "Registado Por"]
    var title = 'Histórico de Leitura';
    var nameFile = "Histórico de Leitura -" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    this.excelService.excels(this.leituras, nameFile, this.DadosEmpresa.logotipo, keys, Cols, title, 5, 12, 30, 3)
  }

  private getContasByCliente(id) {

    this.configService.loaddinStarter('start');
    this.http.call_get('cliente/conta/listar/' + id, null).subscribe(
      response => {
        this.conta = Object(response).data;
        this.configService.loaddinStarter('stop');
      }
    );

  }

  public getContratosByConta(id) {
    this.loadingContrato = 'Carregando...';
    id = id.target.value;
    //this.configService.loaddinStarter('start');
    this.getClienteByConta(id)
    this.http.__call('conta/GETcontratoIDByConta/' + id, null).subscribe(
      response => {
        this.contratos = Object(response);
        //console.log(this.contratos);
        this.loadingContrato = 'Contrato';
        this.configService.loaddinStarter('stop');
      }
    );
  }

  private verificarContratoWithDivida(id) {
    this.http.call_get('api/v1/dividas/with-contrato/' + id)
      .subscribe(res => {

      })
  }


  private ExportaContaCorrente() {
    this.http.__call('contaCorrente/report ', { cliente_id: this.cliente.id, estado_factura: this.selectBox.pago, contrato_id: this.contrato.contrato_id }).subscribe(response => {
      this.contaCorrente = Object(response).data.facturas;
      this.clienteNome = Object(response).data.cliente.nome;
      this.clienteContribuinte = Object(response).data.cliente.contribuente;
      this.contaCorrenteDados = [];
      this.arr = [];
      for (let i = 0; i < this.contaCorrente.length; i++) {

        for (let index = 0; index < this.contaCorrente[i].contacorrente.length; index++) {



          this.contaCorrenteDados.push(this.contaCorrente[i].contacorrente[index])
          this.arr.push(this.contaCorrente[i].clientefilhos)
          this.contaCorrenteDados[index].contas = this.arr[index];

          this.somaReciboExcel += (this.contaCorrente[i].contacorrente[index].sigla == 'RC' ? this.contaCorrente[i].contacorrente[index].valor : 0)
          this.somaFacturaExcel += (this.contaCorrente[i].contacorrente[index].sigla == 'FT' ? this.contaCorrente[i].contacorrente[index].valor : 0)
          this.somaNotacreditoExcel += (this.contaCorrente[i].contacorrente[index].sigla == 'NC' ? this.contaCorrente[i].contacorrente[index].valor : 0)


        }



      }

      this.valorTotal = ((this.somaFacturaExcel - this.somaNotacreditoExcel) - this.somaReciboExcel)
      //console.log(this.contaCorrente)




    })

  }

  exportAsXLSX(): void {

    var total = ((this.somaFactura - this.somaNotacredito) - this.somaRecibo)
    var CurrentDate = new Date();
    var nameFile = "Conta_Corrente-" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')

    /* for (let a = this.contas.facturas.length - 1; a >= 0; a--) {
       const element = this.contas.facturas[a];
       element.saldo = this.saldo[a];
     }*/
    // console.log(this.contas.facturas);
    this.execel.ExcelExportContaCorrente(this.contas, this.totalAberto, this.clienteNome, this.contas.empresa, nameFile + '.xlsx')
  }

  private adiantamentos() {
    //console.log(this.contas);

    this.http.__call("adiantamento/factura/adiantamentoContaCliente", { cliente_id: this.cliente.id }).subscribe(response => {
      if (Object(response).data.length == 0) {
        this.totalAdiantamento = 0;
        this.movimentoAdiantamento = [];
      } else {
        this.totalAdiantamento = Object(response).data[0].valor;
        this.movimentoAdiantamento = Object(response).data[0].movimentosAdiantamentos;
      }
    })
  }

  goToPageCreateNotaCredito(factura: any) {
    this._route.navigate(["/facturacao/emitir-nota-de-credito", factura.id]);
  }

  private calcular() {
    this.somaRecibo = 0.0;
    this.somaFactura = 0.0;
    this.somaNotacredito = 0.0;
    this.somaFacturas_Recibo = 0.0;
    this.totalAberto = 0.0;
    this.recibosCount = 0;
    this.nota_creditoCount = 0;
    this.facturasCount = 0;
    let saldos = 0;
    this.saldo = [];
    let index = [];
    let indexs = [];

    this.contas.facturas.forEach(element => {
      //saldos = Math.round(saldos,2)
      saldos = element.Total_bruto + saldos;
      this.saldo.push(saldos);
      element.saldo = saldos

      if (element.sigla == 'RC') {
        this.somaRecibo += element.total;
        this.recibosCount++;
      }

      if (element.sigla == 'FT') {
        this.somaFactura += element.total;
        this.facturasCount++;
      }

      if (element.sigla == 'NC') {
        this.somaNotacredito += element.total;
        this.nota_creditoCount++;
      }

    });

    this.contas.facturas_recibo.forEach(element => {
      this.somaFacturas_Recibo += element.total;
    });
    this.contas.facturas_em_Aberto.forEach(element => {
      this.totalAberto += element.valor_aberto;
    });


  }


  private anularFactura(e) {

    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (e.target.elements[0].value == "" || e.target.elements[2].value == "") {
      this.configService.showAlert('É obrigatório fornecer o nº da factura e o motivo', 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    } else {
      this.factura.status_reason = e.target.elements[2].value;
      this.http.__call('factura/anular/' + this.factura.factura_id, this.factura).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            this.contaCorrentes()
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.configService.clearFormInputs(e);
          }
          this.configService.loaddinStarter('stop');
        }
      );
    }
  }


  private btnImprimirFactura(id) {
    //console.log(this.contas)
    this.configService.imprimirFactura(id, "2ª Via");

  }

  private btnImprimirRecibo(recibo_id: any) {
    this.PDFrecibo.imprimirRecibo(recibo_id, '2ª Via');
  }


  private btnImprimirDevoluao(devolucao: any) {
    this.PDFdevolucao.imprimirDevolucao(devolucao, '2ª Via');
  }

  private ImprimirContaCorrente(cliente) {
    var table = document.getElementsByClassName("exportAsXLSXContaCorrent")[0];
    this.ContaCorrenteReportService.contaCorrenteReport(cliente, this.selectBox.pago, table, this.contas.empresa, this.totalAberto, 'print');
  }

  private ExportarContaCorrente(cliente) {
    var table = document.getElementsByClassName("exportAsXLSXContaCorrent")[0];
    this.ContaCorrenteReportService.contaCorrenteReport(cliente, this.selectBox.pago, table, this.contas.empresa, this.totalAberto, 'save');
  }



  refreshFacturaAnual(facturas: any) {
    this.factura = facturas;
    this.factura.factura_id = facturas.id;
  }

  // nota de credito
  private closeModal() {
    this.totalFacturacao = 0;
    this.totalComImposto = 0;
    this.dadosFacturaId = [];
    this.produtosNotaCredito = [];
    this.btnClickBoleon = false;

    this.configService.loaddinStarter('stop');
  }

  private refreshFacturaNotaCredito(factura_id) {

    // console.log(this.series);
    this.configService.loaddinStarter('start');
    this.http.__call('factura/gerarFactura/' + factura_id, null).subscribe(
      response => {
        //this.listarseries(1, 100);
        this.dadosFacturaId = Object(response).data;
        this.btnClickBoleon = true;
        this.configService.loaddinStarter('stop');
      }
    );

  }

  private btnAddProdutosNotaCredito(produto_id) {
    this.dadosFacturaId.produtos.forEach(element => {
      if (element.produto_id == produto_id) {
        this.addProdutosNotaCredito(element);
      }
    });
  }

  private addProdutosNotaCredito(produto: any) {
    this.totalFacturacao = 0;
    let validar = 0;
    if (this.produtosNotaCredito.length >= 1) {
      for (let index = 0; index < this.produtosNotaCredito.length; index++) {
        const produt = this.produtosNotaCredito[index];
        if (produt.produto_id == produto.produto_id) {
          this.produtosNotaCredito.splice(index, 1);
          this.produtosNotaCredito.splice(index, 0, produto);
          validar = 1;
        }

      }
      if (validar == 0) {
        this.produtosNotaCredito.push(produto);
      }
    } else {
      this.produtosNotaCredito.push(produto);
    }
    //Calcula o Total da Factura
    this.calcularTotal();
  }

  private calcularTotal() {
    this.totalFacturacao = 0;
    this.totalSemImposto = 0;
    this.totalComImposto = 0;
    //Calcula o Total da Factura
    for (let index = 0; index < this.produtosNotaCredito.length; index++) {
      this.totalSemImposto += this.produtosNotaCredito[index].linhaTotalSemImposto;
      this.totalComImposto += this.produtosNotaCredito[index].valorImposto;

      this.totalFacturacao += this.produtosNotaCredito[index].total;
    }
  }


  deleteRow(id) {

    for (let i = 0; i < this.produtosNotaCredito.length; ++i) {
      if (this.produtosNotaCredito[i].produto_id === id) {
        this.produtosNotaCredito.splice(i, 1);
        this.calcularTotal();
      }
    }
  }


  private refreshSerieId() {
    this.serieId = this.serie.nativeElement.value;
    if (Object(this.serieId) == "") {
      this.serieId = 0;
    }

  }
  private refreshSerieReciboId() {
    this.serieReciboId = this.serieRecibo.nativeElement.value;
    if (Object(this.serieReciboId) == "") {
      this.serieReciboId = 0;
    }

  }

  /**
   * @name "Listar series"
   * @descriptio "Esta Função permite Listar todas series"
   * @author "caniggia.moreira@itgest.pt"
   * @param start
   * @param end
   */
  private listarseries(start, end) {
    this.http.__call('serie/listar', { start: start, end: end }).subscribe(
      data => {
        data.forEach(element => {
          // console.log(element)
          if (element.sigla == 'NC') {
            this.series.push(element);
            //console.log(this.series);

          }


        });

      }
    );
  }

  opcaofiltro(tipo) {
    if (tipo.target.value == 1) {
      //this.simpleForm.get('cliente').reset();
      //this.simpleForm.get('cliente_id').reset();
    } else {
      //this.simpleForm.get('direccao').reset();
    }
    this.tipofiltro = tipo.target.value;
  }

  private finalizarNotaCredito() {

    this.configService.loaddinStarter('start');
    if (this.serieId == undefined) {
      this.configService.showAlert('É obrigatório fornecer uma serie', "alert-danger", true);
    } else if (this.produtosNotaCredito.length == 0) {
      this.configService.showAlert('É obrigatório fornecer produtos para a nota de crédito', "alert-danger", true);
    } else {
      this.http.__call('factura/create', {
        produtos: this.produtosNotaCredito, documento: 'Nota de Crédito',
        cliente: this.dadosFacturaId.cliente.id, total: this.totalFacturacao,
        serie_id: this.serieId, totalComImposto: this.totalComImposto, totalSemImposto: this.totalSemImposto,
        observacao: this.observacao.nativeElement.value,
        numero_origem_factura: this.dadosFacturaId.factura.factura_sigla,
        data_origem_factura: this.dadosFacturaId.factura.created_at,
        moeda: null
      }).subscribe(
        res => {
          if (Object(res).code == 200) {
            this.contaCorrentes();
            this.configService.showAlert(Object(res).message, "alert-success", true);
            this.btnClickBoleon = false;
            this.configService.imprimirFactura(Object(res).data.id, "Original");

            this.totalFacturacao = 0;
            this.totalComImposto = 0;
            this.dadosFacturaId = [];
            this.produtosNotaCredito = [];

          } else {
            this.configService.showAlert(Object(res).message, "alert-danger", true);
          }
        }
      );

    }
    this.configService.loaddinStarter('stop');
  }

  routerGerarRecibo(cliente_id: number, name: string) {
    this._route.navigate(['/vendas/recibo/gera-recibo'], { queryParams: { c: cliente_id, n: name } });
  }

  routerPlanoPrestacao() {
    this._route.navigate(['/criarPlanoPrestacao/' + this.contrato.contrato_id + '/' + this._conta.id + '/' + this.cliente.id]);
  }


  /* ===== CONTA CORRENTE CAUÇÃO =====*/

  private getCaucaoOfContrato() {
    this.http.call_get('contrato/caucao/' + this.contrato.contrato_id)
      .subscribe(res => {
        this.data_caucao = Object(res)
        this.caucao.valor_caucao = this.data_caucao.valor_caucao
        this.caucao.factura_ref_caucao = this.data_caucao.factura_ref_caucao
        this.contaCorrenteCaucao()
      })
  }

  private contaCorrenteCaucao() {
    this.loading = true
    this.configService.loaddinStarter('start');
    this.http.__call('contaCorrente/caucao', {
      cliente_id: this.cliente.id,
      estado_factura: this.selectBox.pago,
      factura_ref_caucao: this.caucao.factura_ref_caucao,
      contrato_id: this.contrato.contrato_id,
      datas: this.datas,
      conta_id: this._conta.id
    }).subscribe(
      res => {
        if (Object(res).code == 500) {
          this.configService.showAlert(Object(res).message, "alert-danger", true);
          this.configService.loaddinStarter('stop');
        } else {
          this.validacao = true;
          this.contas_caucao = Object(res).data;

          this.clienteNome = Object(res).data.cliente.nome;
          this.saldocliente = Object(res).data.cliente.saldo;
          this.clienteContribuinte = Object(res).data.cliente.contribuente;
          this.configService.loaddinStarter('stop');
          this.loading = false
        }
      }
    );
  }

  private factura_recibo: any;
  setFacturaRecibo(fr: any) {
    this.factura_recibo = fr;
  }

  private text_alert: any = 'Tens certeza que deseja converter o valor da caução em Adiantamento?';

  converterCaucaoEmAdiantamento() {

    if (this.serie_id == null) {
      return this.configService.showAlert('Selecione uma série', "alert-danger", true);
    }

    this.loading = true;
    this.http.__call('factura/converter_caucao_adiantamento', {
      factura_id: this.factura_recibo.id,
      serie_id: this.serie_id
    }).subscribe(res => {
      this.loading = false;
    })
  }

  swalAlert(title: any, type: any) {
    Swal.fire({
      position: 'center',
      type: type,
      title: title,
      showConfirmButton: false,
      timer: 2000
    })
  }

  /**
 * @name "Listar series"
 * @descriptio "Esta Função permite Listar todas series"
 * @author "caniggia.moreira@itgest.pt"
 * @param start
 * @param end
 */
  private getSeries() {
    this.series_nc = [];
    this.http
      .__call("serie/selectBoxSeries-activas", { documentos: ["NC"] })
      .subscribe((response) => {
        this.series_nc = Object(response);
      });
  }

  private plano_prestacao: any = []
  private getPlanoPrestacaoOfContrato() {
    if (this.contrato.contrato_id == null || this.contrato.contrato_id == 'null') {
      this.configService.showAlert("Selecione um contrato!", "alert-danger", true);
    } else {
      this.loading = true
      this.configService.loaddinStarter('start');
      this.http.__call('plano-prestacao/Ofcontrato', { contrato_id: this.contrato.contrato_id }).subscribe(
        response => {
          this.plano_prestacao = Object(response).data;
          ($("#planoPrestacaoListModal") as any).modal("show")
          this.loading = false
          this.configService.loaddinStarter('stop');
        }
      );
    }
  }

  private linha_prestacao: any = []
  private plano_header: any;
  private getLinhaPrestacao(item) {
    this.plano_header = item
    this.configService.loaddinStarter('start');
    this.http.call_get('plano-prestacao/linha-prestacao/' + item.id, null).subscribe(
      response => {
        this.linha_prestacao = Object(response).data;
        this.configService.loaddinStarter('stop');
      }
    );
  }

}

