import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ConfigService } from 'src/app/providers/config/config.service';
import { HttpService } from 'src/app/providers/http/http.service';

@Component({
  selector: 'app-conf-notificacao-novas-ligacoes',
  templateUrl: './conf-notificacao-novas-ligacoes.component.html',
  styleUrls: ['./conf-notificacao-novas-ligacoes.component.css']
})
export class ConfNotificacaoNovasLigacoesComponent implements OnInit {

  @ViewChild('closebutton') closebutton;


  private Notificacao = {
    id: null,
    tempo_suspensao: null,
    sms: false,
    mailer: false,
    tipo_cliente_id: null,
    content_sms: null,
    content_mailer: null,
    assunto: null,
    status: false
  }

  private tiposclientes: any = []



  private items: any = [];

  constructor(private http: HttpService, private configService: ConfigService) {
    this.getPageFilterData(1);
  }

  ngOnInit() {
    this.http.filters.search = null
    this.listarTipoClientes()
  }
  ngOnDestroy() {
    this.http.filters.orderBy = null
  }


  private listarNotificacao() {

    this.configService.loaddinStarter('start');

    this.http.call_get('api/v1/notifications/novasLigacoes/config', this.http.filters).subscribe(

      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data;

        this.configService.loaddinStarter('stop');

      }
    );
  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1;
    this.listarNotificacao();
  }


  private register(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (this.Notificacao.assunto == "" || this.Notificacao.tempo_suspensao == null) {
      this.configService.showAlert("Os campos Descrição e Slug são obrigatórios", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    } else {
      this.http.__call('api/v1/notifications/novasLigacoes/create', this.Notificacao).subscribe(
        res => {
          if (Object(res).code == 201) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
            this.configService.loaddinStarter('stop');
          } else {
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.clearFormInputs(e);
            this.listarNotificacao()
            this.configService.loaddinStarter('stop');
          }
        }
      )
    };
    this.closebutton.nativeElement.click();
  }


  private clearFormInputs(e) {
    e.target.elements[0].value = null;
    e.target.elements[1].value = null;
    e.target.elements[2].value = null;
  }

  private refresh(item) {
    if (item !== undefined) {

      this.Notificacao.id = item.id;
      this.Notificacao.assunto = item.assunto;
      this.Notificacao.content_sms = item.content_sms;
      this.Notificacao.content_mailer = item.content_mailer;
      this.Notificacao.mailer = item.mailer
      this.Notificacao.sms = item.sms
      this.Notificacao.tempo_suspensao = item.tempo_suspensao
      this.Notificacao.tipo_cliente_id = item.tipo_cliente_id
      this.Notificacao.status = item.status

    }
  }

  private editar(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (this.Notificacao.assunto == "" || this.Notificacao.tempo_suspensao == null) {
      this.configService.showAlert("Os campos Descrição e Slug são obrigatórios", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    } else {
      this.http.__call('api/v1/notifications/novasLigacoes/update/' + this.Notificacao.id, this.Notificacao).subscribe(
        res => {
          if (Object(res).code == 201) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.listarNotificacao();

          }
        }
      );
    }
    this.configService.loaddinStarter('stop');
  }

  private listarTipoClientes() {
    this.http.call_get('mensagem_cliente/selectBox', null).subscribe(
      response => {
        this.tiposclientes = Object(response);
      }
    );
  }

  private emailClick() {
    this.Notificacao.mailer = !this.Notificacao.mailer
  }
  private smsClick() {
    this.Notificacao.sms = !this.Notificacao.sms
  }

  private statusActiveClick() {
    this.Notificacao.status = true
  }

  private statusDesactiveClick() {
    this.Notificacao.status = false
  }



  private ini() {
    this.Notificacao = {
      id: null,
      tempo_suspensao: null,
      sms: false,
      mailer: false,
      tipo_cliente_id: null,
      content_sms: null,
      content_mailer: null,
      assunto: null,
      status: true
    }
  }
}
