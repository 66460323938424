import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { Response } from 'selenium-webdriver/http';
import { FormBuilder, FormGroup, Validators,AbstractControl  } from '@angular/forms';
/*
import { RelMovimentoCaixaService } from 'src/app/components/report-at/relatorios/financeira/rel-movimento-caixa.service'; */
import { ExcelService } from 'src/app/services/excel.service';
import * as moment from 'moment';
import { ExcelAutoService } from 'src/app/services/excel/excel-auto.service';
import { ReportLojaService } from 'src/app/components/report-at/relatorios/financeira/report-loja.service';
import { RelMovimentoCaixaService } from 'src/app/components/report-at/relatorios/financeira/rel-movimento-caixa.service';
import { FilterCaixaService } from '../filter-caixa.service';

@Component({
  selector: 'app-relatorio-movimento-caixa',
  templateUrl: './relatorio-movimento-caixa.component.html',
  styleUrls: ['./relatorio-movimento-caixa.component.css']
})
export class RelatorioMovimentoCaixaComponent implements OnInit {

 
  loading: boolean = false;

  items:any = [];
  dias:any = [];
  operadores:any = [];
  lojas:any = [];
  movimentos:any = [];

  private disabledButton = true

  submitted = false;
  public filters = {
    search: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    pagination: {
      perPage: 5,// Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima,
      lastPage: null,
      total: null // defini o total de registo da BD
    },
    filter: null // subelemente do filter
    ,is_allexel:false
  }

  private localUrl: any;
  private largura: any;
  private altura: any;
  simpleForm: FormGroup;
  tipoFacturacao:string;
  private loadingLojas: string = "Agência"

  constructor(private formBuilder: FormBuilder,private http: HttpService,
    private excelService: ExcelAutoService, private configService: ConfigService,
    private reportMovimentoCaixa: RelMovimentoCaixaService,
    private filterService:FilterCaixaService
    ) {
    this.createForm();
  }

  ngOnInit() {
    /* this.getLojas(); */
    this.getLojas()
    this.empresaUser()

  }

  createForm() {
    this.simpleForm = this.formBuilder.group({
      data1: [null, Validators.required],
      data2: [null, [this.matchValidator.bind(this)]],
      operador: null,
      loja: null,
      dia: null,
      loja_nome: null,
    });
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.simpleForm.controls;
  }
  onSubmit() {

    this.submitted = true;
    // parar aquei se o simpleFormulário for inválido
    if (this.simpleForm.invalid) {
      return;
    }
    const uri = 'relatorio/financeira/movimentocaixa';
    this.simpleForm.patchValue({
      data1: this.filterService.filter.data1,
      data2: this.filterService.filter.data2,
      operador: this.filterService.filter.operador,
      loja: this.filterService.filter.loja,
      loja_nome: this.filterService.filter.loja_nome,
    });
    this.createOrEdit(uri, this.simpleForm);
  }
  private empresaUser() {
    this.configService.loaddinStarter('start');

    this.http.call_get('empresa/empresa-user', null).subscribe(
      response => {
        this.localUrl = Object(response).data.logotipo
        this.altura = Object(response).data.width
        this.largura = Object(response).data.height
        this.configService.loaddinStarter('stop');
      }
    );
  }

  setTrue(){
    this.filters.pagination.page=this.filters.pagination.page
    this.filters.is_allexel=true
    this.onSubmit()
  }
  createOrEdit(uri: any, simpleFormulario: FormGroup) {
    this.movimentos = []
    this.loading = true;
    this.disabledButton = true;
    this.totais.valor_abertura = 0;
    this.totais.total = 0;
    this.totais.valor_fecho = 0
    // TODO: usado para fazer a requisição com a api de criação de organismo
    this.http.__call(uri, simpleFormulario.value).subscribe(
      response => {
        this.movimentos = Object(response).data;
        console.log(this.movimentos)
        this.loading = false;
        if (this.movimentos.length != 0) {
          this.disabledButton = false;
          this.somaTotais();
        }
        if(this.filters.is_allexel==false){
          this.movimentos = Object(response).data;
        }else{
          this.movimentos = Object(response).data;
          this.exportAsXLSX(this.movimentos)
        }
        this.filters.is_allexel=false
        this.loading = false;
      }
    );
  }

  exportAsXLSX(data:any):void {
    var CurrentDate = new Date();
     for(let i=0;i<data.length;i++){
       if(data[i].is_active==1){
           data[i].is_active="Aberto"
       }else{
           data[i].is_active="Fechado"
       }
     }
    var keys = [
      { key: 'loja', width:40, style: { font: { name: 'Calibri' } } },
      { key: 'operador', width: 50 },
      { key: 'is_active', width: 15 },
      { key: 'data_abertura', width:20, style: { font: { name: 'Calibri' } } },
      { key: 'hora_abertura', width:20, style: { font: { name: 'Calibri' } } },
      { key: 'data_fecho', width: 20 },
      { key: 'hora_fecho', width: 20 },
      { key: 'valor_abertura', width: 30,style: { numFmt: '#,##0.00', } },
      { key: 'total', width:40, style: { numFmt: '#,##0.00', } },
      { key: 'valor_fecho', width: 50 ,style: { numFmt: '#,##0.00', }},
    ];

    var Cols = ["Loja","Operador","Estado","Data Abertura","hora Abertura","Data Fecho","hora Fecho","Valor Abertura","Total Recebimento","Valor Fecho"]
    var title='MOVIMENTOS DE CAIXA'
    var nameFile = "MOVIMENTOS_DE_CAIXA -" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
     this.excelService.excels(data,nameFile,this.localUrl,keys,Cols,title,5,10,40,3,[8,9,10,11])
  }
  exportAsPDF(): void {
    var file =  document.getElementsByClassName("exportAsXLSXCliente")[0]
    this.reportMovimentoCaixa.relatoriomovimentocaixa(file,'save', this.localUrl);
  }

  imprimirPDF(): void {
    var file =  document.getElementsByClassName("exportAsXLSXCliente")[0]
    this.reportMovimentoCaixa.relatoriomovimentocaixa(file,'print', this.localUrl);
  }
  matchValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const fromValue = control.value;
    if(fromValue) {

     // console.log(this.simpleForm.controls)
      const startDate = (<FormGroup>this.simpleForm.get('data1')).value;
      const endDate = (<FormGroup>this.simpleForm.get('data2')).value;
      if (startDate <= endDate) {
        //console.log('Control: ', control);
       return null;
      }
      //console.log('Control: ', control);
      return { 'invalidDate' : true };
    }

  }

  changeProduto() {
    this.simpleForm.patchValue({
      loja_nome: null
    });
    this.operadores.forEach(element => {
      if (element.id == this.simpleForm.getRawValue().produto) {
        this.simpleForm.patchValue({
          produto_nome: element.nome
        });
      }
    });
  }

  changeLoja() {
    this.simpleForm.patchValue({
      loja_nome: null
    });
    this.lojas.forEach(element => {
      if (element.id == this.simpleForm.getRawValue().loja) {
        this.simpleForm.patchValue({
          loja_nome: element.nome
        });
      }
    });
  }

  private getLojas() {
    this.http.call_get('loja/selectBox',null).subscribe(
      response => {
        this.lojas = Object(response).data
        const id = this.simpleForm.getRawValue().loja;
        this.lojas.map(res=>{
          if(res.id==id){
            this.getoperadores(res.id)
          }else{
            return
          }
       })
      }
    );
  }

  private getoperadores(id) {
    this.loadingLojas = 'Carregando...';
    this.http.call_get('user/selectBox/'+id, null).subscribe(
      response => {
        this.operadores = Object(response).data
      }
    )
   
  }
  private totais = {
    valor_abertura: 0,
    total: 0,
    valor_fecho: 0
  }
  private somaTotais() {
    var totalva: number = 0;
    var totalvv: number = 0;
    var totalvf: number = 0;
    this.movimentos.forEach(element => {
      totalva += element.valor_abertura;
      totalvv += element.total;
      totalvf += element.valor_fecho;
    });
    this.totais.valor_abertura = totalva;
    this.totais.total = totalvv;
    this.totais.valor_fecho = totalvf
  }
}

