import { Component, OnInit, ViewChild } from "@angular/core";
import { HttpService } from "../../providers/http/http.service";
import { ConfigService } from "src/app/providers/config/config.service";
import * as moment from "moment";
@Component({
  selector: 'app-ligar-ramals',
  templateUrl: './ligar-ramals.component.html',
  styleUrls: ['./ligar-ramals.component.css']
})
export class LigarRamalsComponent implements OnInit {

  @ViewChild("search") search;
  @ViewChild("qtd") qtd;

  @ViewChild("valor") valor;
  @ViewChild("serie") serie;
  @ViewChild("observacao") observacao;

  private desconto: number = 0;

  private dataActual = new Date();

  private pontoB: any = [];

  private objectoRamalInicial = {
    id: null,
    descricao: null,
  };

  private objectoRamalFinal = {
    id: null,
    descricao: null,
  };

  private activeButtom = {
    cliente: false,
    produto: false,
  };


  quarteiroes: any = []
  distritos: any = []
  bairros: any = []
  municipios: any = [];

  view_local_consumo_cil = false;
  view_objecto_ramal = false;
  private local_consumos_inicial: any[];
  private local_consumos_final: any[];

  private local_consumo_inicial = {
    id: null,
    moradia_numero: null,
    is_predio: null,
    predio_nome: null,
    predio_andar: null,
    cil: null,
    cil_flag: true,
    rua_id: null,
    rua: null,
    bairro_id: null,
    bairro: null,
    has_quarteirao: false,
    quarteirao_id: null,
    quarteirao: null,
    has_distrito: false,
    distrito_id: null,
    distrito: null,
    municipio_id: null,
    municipio: null,
    provincia_id: null,
    is_active: null,
    user_id: null,
  };

  private local_consumo_final = {
    id: null,
    moradia_numero: null,
    is_predio: null,
    predio_nome: null,
    predio_andar: null,
    cil: null,
    cil_flag: true,
    rua_id: null,
    rua: null,
    bairro_id: null,
    bairro: null,
    has_quarteirao: false,
    quarteirao_id: null,
    quarteirao: null,
    has_distrito: false,
    distrito_id: null,
    distrito: null,
    municipio_id: null,
    municipio: null,
    provincia_id: null,
    is_active: null,
    user_id: null,
  };

  private ligacao_ramal = {
    id: null,
    ponto_a_id: null,
    ponto_b_id: null,
    estado_ligacao_ramal: null,
    gestao_zmc_id: null,
    pontoA: null,
    pontoB: null,

    comprimento: null,
    profundidade: null,
    codigo_remal: null,
    morada_ramals: null,
    tipo_ramal_id: null,

    bairro_id: null,
    distrito_id: null,
    rua_id: null,
    municipio_id: null,

    diametro_ramal_id: null,
    material_ramal_id: null,
    estado_ramal_id: null,
    fita_sinalizacao: null,
    valvula_ramal: null,
    latitude: null,
    longitude: null,
  };


  private pontoInicial = {
    id: null,
    value: null,
    rota: null,
    slug: null,
  };

  private pontoFinal = {
    id: null,
    value: null,
    rota: null,
    slug: null,
  };

  private itemList = {
    item: {
      linhas: [],
    },
  };

  view_ponto_b = false;
  private loading: boolean = false;

  private validarFacturaRecibo: boolean = false;

  private isFinalizar: boolean = false;

  private tipoObjecto = {
    id: null,
    descricao: null,
    tipo_objecto_b_id: null,
  };

  private interest = {
    id: null,
  };

  private selected_objecto = {
    id: null,
    descricao: null,
    latitude: null,
    longitude: null,
  };

  private items_inicial: any = [];
  private items_final: any = [];

  private pagination = {
    start: 1,
    end: 10,
    search: null,
    servico: null,
  };

  private ruas: any[];
  private tipo_ramals: any[];
  private diametro_ramals: any[];
  private material_ramals: any[];
  private tipo_objectos: any = [];
  private zmcs: any = [];

  private produto_selecionado = null;
  private preco: number;

  constructor(
    private http: HttpService,
    private configService: ConfigService
  ) { }

  ngOnInit() {
    this.loadSelectOptions();
  }

  private loadSelectOptions() {
    this.getTiposRamal();
    this.selectBoxTipoRamals();
    this.selectBoxDiametroRamals();
    this.selectBoxMaterialRamals();
    this.selectBoxZmc();
    this._getMunicipios();
  }

  private getTiposRamal() {
    this.http
      .call_get("tipo-objecto-tecnico/selectBox", null)
      .subscribe((response) => {
        this.tipo_objectos = Object(response).data;
      });
  }

  private selectBoxZmc() {
    this.http
      .call_get("api/v1/gestao-zmc/selectBox", null)
      .subscribe((response) => {
        this.zmcs = Object(response);
      });
  }

  private selectBoxTipoRamals() {
    this.http.call_get("tipo-ramal/selectBox", null).subscribe((response) => {
      this.tipo_ramals = Object(response);
    });
  }

  private selectBoxDiametroRamals() {
    this.http
      .call_get("diametro-ramal/selectBox", null)
      .subscribe((response) => {
        this.diametro_ramals = Object(response);
      });
  }

  private selectBoxMaterialRamals() {
    this.http
      .call_get("material-ramal/selectBox", null)
      .subscribe((response) => {
        this.material_ramals = Object(response);
      });
  }

  private selectedTipoObjecto(item) {
    setTimeout(() => {
      console.log(item);
    }, 1500);

    this.selected_objecto.id = null;
    this.selected_objecto.descricao = null;
    this.selected_objecto.latitude = null;
    this.selected_objecto.longitude = null;

    this.itemList = {
      item: {
        linhas: [],
      },
    };
    //this.getObjectoRamal();
  }

  private selectedObjectoRamal(item) {
    this.selected_objecto.id = item.id;
    this.selected_objecto.descricao = item.descricao;
    this.selected_objecto.latitude = item.latitude;
    this.selected_objecto.longitude = item.longitude;
  }

  private setPontoB(id, descricao) {
    this.ligacao_ramal.ponto_b_id = id;
    this.ligacao_ramal.pontoB = descricao;

    this.view_ponto_b = false;
  }

  private getObjectoRamalOptInicial() {
    this.items_inicial = [];
    this.view_objecto_ramal = true
    this.configService.loaddinStarter("start");

    this.http
      .__call("search/inicio/ligacao", {
        start: 1,
        end: 10,
        tipo_objecto_id: this.tipoObjecto.id,
        search: this.objectoRamalInicial.descricao,
      })
      .subscribe((res) => {
        this.items_inicial = Object(res).data.data;
        this.configService.loaddinStarter("stop");
      });
  }

  private getObjectoRamalOptFinal() {
    this.items_final = [];
    this.view_objecto_ramal = true
    this.configService.loaddinStarter("start");

    this.http
      .__call("search/inicio/ligacao", {
        start: 1,
        end: 10,
        tipo_objecto_id: this.tipoObjecto.id,
        search: this.objectoRamalFinal.descricao,
      })
      .subscribe((res) => {
        this.items_final = Object(res).data.data;
        this.configService.loaddinStarter("stop");
      });
  }



  private pontoInicialOpt() {
    this.interest.id = this.pontoInicial.rota;

    if (this.interest.id == "pontoBObjectoRamalModal") {
      this.pontoInicial.slug = "OBJECTO-DO-RAMAL";
    } else {
      this.pontoInicial.slug = "LOCAL-INSTALACAO";
    }
  }

  private pontoFinalOpt() {
    this.interest.id = this.pontoFinal.rota;

    if (this.interest.id == "pontoBObjectoRamalModal") {
      this.pontoFinal.slug = "OBJECTO-DO-RAMAL";
    } else {
      this.pontoFinal.slug = "LOCAL-INSTALACAO";
    }
  }

  private getLocalInstalacaosByCILAndMoradiaOptInicial() {
    if (this.local_consumo_inicial.cil == "") {
      //this.setNullResidencia();
    }

    this.view_local_consumo_cil = true;
    this.http
      .__call("local-instalacao/getLocalInstalacaosByRamal", {
        start: 1,
        end: 15,
        search: this.local_consumo_inicial.cil,
      })
      .subscribe((response) => {
        this.local_consumos_inicial = Object(response).data.data;

        this.configService.loaddinStarter("stop");
      });
  }


  private getLocalInstalacaosByCILAndMoradiaOptFinal() {
    if (this.local_consumo_final.cil == "") {
      //this.setNullResidencia();
    }

    this.view_local_consumo_cil = true;
    this.http
      .__call("local-instalacao/getLocalInstalacaosByRamal", {
        start: 1,
        end: 15,
        search: this.local_consumo_final.cil,
      })
      .subscribe((response) => {
        this.local_consumos_final = Object(response).data.data;

        this.configService.loaddinStarter("stop");
      });
  }


  _getMunicipios() {
    this.http.__call('municipio/listagem', this.http.filters)
      .subscribe(response => {
        this.municipios = Object(response).data.data
        console.log(response);
      }),
      (error) => {
        if (!error.ok) {
          console.log(error)
        }
      }
  }

  _changeDistrito(municipio_id) {
    if (municipio_id) {
      this._getComuna(municipio_id);
    }
  }
  _changeBairros(distrito_id) {
    if (distrito_id) {
      this._getBairros(distrito_id);
    }
  }

  _changeRua(bairro_id) {
    if (bairro_id) {
      this._getRuasDoBairro(bairro_id);
    }
  }
  _getBairros(id) {
    this.http.__call('get-bairros/' + id, this.http.filters)
      .subscribe(response => {
        this.bairros = Object(response).data
      }),
      (error) => {
        if (!error.ok) {
          console.log(error)
        }
      }
  }

  _getComuna(id) {
    this.http.__call('get-distritos/' + id, this.http.filters)
      .subscribe(response => {
        this.distritos = Object(response).data
      }),
      (error) => {
        if (!error.ok) {
        }
      }
  }


  _getRuasDoBairro(id) {
    this.http.call_get(`rua/selectBoxByBairro/${id}`, null)
      .subscribe(
        response => {
          this.ruas = Object(response)
        }
      )
  }


  setObjectoRamalInicial(item: any) {
    this.objectoRamalInicial.id = item.id;
    this.objectoRamalInicial.descricao = item.descricao

    this.ligacao_ramal.ponto_a_id = item.id
    this.ligacao_ramal.pontoA = this.pontoInicial.slug

    this.view_objecto_ramal = false
  }

  setObjectoRamalFinal(item: any) {
    this.objectoRamalFinal.id = item.id;
    this.objectoRamalFinal.descricao = item.descricao

    this.ligacao_ramal.ponto_b_id = item.id
    this.ligacao_ramal.pontoB = this.pontoFinal.slug

    this.view_objecto_ramal = false
  }

  private setLocalConsumo_inicial(item: any) {
    this.local_consumo_inicial.id = item.id;
    this.local_consumo_inicial.moradia_numero = item.moradia_numero;

    this.ligacao_ramal.ponto_a_id = item.id
    this.ligacao_ramal.pontoA = this.pontoInicial.slug

    this.local_consumo_inicial.predio_nome =
      (item.cil != null ? "[" + item.cil + "] " : "[0000-000-000] ") +
      (item.is_predio
        ? "Prédio " +
        item.predio_nome +
        ", " +
        this.configService.decimalFormat(item.predio_andar) +
        "º Andar - Porta "
        : "Residência ") +
      this.configService.decimalFormat(item.moradia_numero);
    this.local_consumo_inicial.is_predio = item.is_predio;
    this.local_consumo_inicial.cil = item.cil;

    this.local_consumo_inicial.provincia_id = item.provincia_id;

    this.local_consumo_inicial.municipio_id = item.municipio_id;
    this.local_consumo_inicial.municipio = item.municipio;
    this.local_consumo_inicial.distrito_id = item.distrito_id;
    this.local_consumo_inicial.distrito = item.distrito;
    this.local_consumo_inicial.bairro_id = item.bairro_id;
    this.local_consumo_inicial.bairro = item.bairro;
    this.local_consumo_inicial.quarteirao_id = item.quarteirao_id;
    this.local_consumo_inicial.quarteirao = item.quarteirao;
    this.local_consumo_inicial.rua_id = item.rua_id;
    this.local_consumo_inicial.rua = item.rua;

    this.view_local_consumo_cil = false
  }

  private setLocalConsumo_final(item: any) {
    this.local_consumo_final.id = item.id;
    this.local_consumo_final.moradia_numero = item.moradia_numero;

    this.ligacao_ramal.ponto_b_id = item.id
    this.ligacao_ramal.pontoB = this.pontoFinal.slug

    this.local_consumo_final.predio_nome =
      (item.cil != null ? "[" + item.cil + "] " : "[0000-000-000] ") +
      (item.is_predio
        ? "Prédio " +
        item.predio_nome +
        ", " +
        this.configService.decimalFormat(item.predio_andar) +
        "º Andar - Porta "
        : "Residência ") +
      this.configService.decimalFormat(item.moradia_numero);
    this.local_consumo_final.is_predio = item.is_predio;
    this.local_consumo_final.cil = item.cil;

    this.local_consumo_final.provincia_id = item.provincia_id;

    this.local_consumo_final.municipio_id = item.municipio_id;
    this.local_consumo_final.municipio = item.municipio;
    this.local_consumo_final.distrito_id = item.distrito_id;
    this.local_consumo_final.distrito = item.distrito;
    this.local_consumo_final.bairro_id = item.bairro_id;
    this.local_consumo_final.bairro = item.bairro;
    this.local_consumo_final.quarteirao_id = item.quarteirao_id;
    this.local_consumo_final.quarteirao = item.quarteirao;
    this.local_consumo_final.rua_id = item.rua_id;
    this.local_consumo_final.rua = item.rua;

    this.view_local_consumo_cil = false
  }

  resetDados() {
    this.ligacao_ramal.id = null,
      this.ligacao_ramal.ponto_a_id = null,
      this.ligacao_ramal.ponto_b_id = null,
      this.ligacao_ramal.estado_ligacao_ramal = null,
      this.ligacao_ramal.pontoA = null,
      this.ligacao_ramal.pontoB = null,
      this.ligacao_ramal.comprimento = null,
      this.ligacao_ramal.profundidade = null,
      this.ligacao_ramal.codigo_remal = null,
      this.ligacao_ramal.morada_ramals = null,
      this.ligacao_ramal.tipo_ramal_id = null,
      this.ligacao_ramal.diametro_ramal_id = null,
      this.ligacao_ramal.material_ramal_id = null,
      this.ligacao_ramal.estado_ramal_id = null,
      this.ligacao_ramal.fita_sinalizacao = null,
      this.ligacao_ramal.valvula_ramal = null,
      this.ligacao_ramal.latitude = null,
      this.ligacao_ramal.longitude = null,
      this.ligacao_ramal.gestao_zmc_id = null
  }

  resetObjectos() {
    this.objectoRamalInicial.id = null
    this.objectoRamalInicial.descricao = null

    this.objectoRamalFinal.id = null
    this.objectoRamalFinal.descricao = null
  }

  resetLocalIntalacao() {
    this.local_consumo_inicial.cil;
    this.local_consumo_final.cil;
  }

  private guardarDados() {

    this.isFinalizar = false;
    this.loading = true
    this.configService.loaddinStarter("start");

    this.http
      .__call("gestao-ramal/create", {
        ligacao: this.ligacao_ramal,
      })
      .subscribe((res) => {
        if (Object(res).code == 200) {
          this.configService.showAlert(
            Object(res).message,
            "alert-success",
            true
          );
          this.resetDados();
          this.resetObjectos();
          this.resetLocalIntalacao();
          this.isFinalizar = false;
          this.loading = false
        } else {
          this.configService.showAlert(
            Object(res).message,
            "alert-danger",
            true
          );
          this.isFinalizar = true;
        }

        this.configService.loaddinStarter("stop");
      });
  }

}


