import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { ApiService } from "src/app/providers/http/api.service";
import { ExcelService } from 'src/app/services/excel.service';
import { ReportResumoContaCorrenteService } from 'src/app/components/report-at/relatorios/financeira/report-resumo-conta-corrente.service';
import { finalize } from 'rxjs/operators';
import * as moment from 'moment';
import { ExcelAutoService } from 'src/app/services/excel/excel-auto.service';
import { ConfTiposTipologiaComponent } from 'src/app/components/conf-tipos-tipologia/conf-tipos-tipologia.component';
import { ImportDataService } from 'src/app/services/ExportExcel/exportAllData.service';
export class Filter {

  municipio_id: string = "null";
  distrito_id: string = "null"
  bairro_id: string = "null";
  constructor() {
  }
}

@Component({
  selector: 'app-cobranca-produto',
  templateUrl: './cobranca-produto.component.html',
  styleUrls: ['./cobranca-produto.component.css']
})
export class CobrancaProdutoComponent implements OnInit {
  public filter = new Filter();
  public titulo = "Facturação Detalhada ";
  loading: boolean = false;
  municipios: any = [];
  distritos: any = [];
  bairros: any = [];

  private loadings = {
    municipios: false,
    distritos: false,
    bairros: false
  };
  private meses = [
    { nome: "Janeiro", numero: "01" },
    { nome: "Fevereiro", numero: "02" },
    { nome: "Março", numero: "03" },
    { nome: "Abril", numero: "04" },
    { nome: "Maio", numero: "05" },
    { nome: "Junho", numero: "06" },
    { nome: "Julho", numero: "07" },
    { nome: "Agosto", numero: "08" },
    { nome: "Setembro", numero: "09" },
    { nome: "Outubro", numero: "10" },
    { nome: "Novembro", numero: "11" },
    { nome: "Dezembro", numero: "12" }
  ];
  private cobrancas: any = [];
  private arrayCobranca: any = [];
  private lojas: any = []
  private _lojas: any = []
  private operadores: any = []
  private filials: any = []
  private provincias: any = [];
  private servicos: any = []
  private anos: any = []
  private direccaos: any = [];
  private gestores: any = [];

  private disabledButton = true

  submitted = false;
  simpleForm: FormGroup;
  tipoFacturacao: string;
  loadingLojas: string;
  private filtros = {
    servico: null,
    servico_id: null,
    gestor: null,
    ano: null,
    mes: null,
    tipoFacturacao: null,
    mes_nome: null,
    cliente: null,
    cliente_id: null,
    direccao: null,
    moeda_id: null,
    moeda: null,
    data1: null,
    data: null
  }

  public filters = {
    search: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    pagination: {
      perPage: 5,// Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima,
      lastPage: null,
      total: null // defini o total de registo da BD
    },
    filter: null // subelemente do filter
    , is_allexel: false
  }

  private localUrl: any;
  private largura: any;
  private altura: any;

  constructor(
    private formBuilder: FormBuilder, 
    private api: ApiService, 
    private http: HttpService, 
    private route: ActivatedRoute, 
    private configService: ConfigService,
     private excelService: ExcelAutoService, 
     private getAllData: ImportDataService,
    private RelResumoContaCorrrente: ReportResumoContaCorrenteService) {
    this.createForm();
  }

  ngOnInit() {
    this.getMunicipios();
    this.getProvincias();
    this.getLojas();
    /* this.getServicos(); */
    this.gerarAno();
    this.empresaUser()
    this.direccaosSelectBox();
   this._getLojas()
  }

  private totais = {
    totals: 0,
  }

  private somaTotais() {
    this.totais.totals = 0

    for (let element of this.cobrancas) {
      if (element.status == 'N') {
        this.totais.totals += element.total_pago;
      }
    }
    this.totais.totals;
  }

  createForm() {
    this.simpleForm = this.formBuilder.group({/*
      gestor: [null, Validators.required], */
      municipio: [null],
      cliente: [null],
      cliente_id: [null],
      data1: [null, Validators.required],
      data2: [null, [this.matchValidator.bind(this)]],
      direccao: [null],
      status: 'null',
      municipio_id: [null],
      distrito_id: [null],
      bairro_id: [null],
      loja:null,
      operador:null
    });
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.simpleForm.controls;
  }
  matchValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const fromValue = control.value;
    if (fromValue) {

      // console.log(this.simpleForm.controls)
      const startDate = (<FormGroup>this.simpleForm.get('data1')).value;
      const endDate = (<FormGroup>this.simpleForm.get('data2')).value;
      if (startDate <= endDate) {
        //console.log('Control: ', control);
        return null;
      }
      //console.log('Control: ', control);
      return { 'invalidDate': true };
    }

  }
  private empresaUser() {
    this.configService.loaddinStarter('start');

    this.http.call_get('empresa/empresa-user', null).subscribe(
      response => {
        this.localUrl = Object(response).data.logotipo
        this.altura = Object(response).data.width
        this.largura = Object(response).data.height
        this.configService.loaddinStarter('stop');
      }
    );
  }

  setTrue() {
    this.filters.pagination.page = Number.isInteger(this.filters.pagination.page) ? this.filters.pagination.page : 1 ;
    this.filters.is_allexel = true
    this.exportAsXLSX(this.arrayCobranca)
  }

  getAllDatas() {
  
    this.filters.filter = this.filter
    let title = "Forma Pagamento"
      this.getAllData.getData('relatorio/financeira/relatorioCobrancaPorduto',
      title,  
      this.simpleForm.value, 
      {},
      (data: any)=>this.exportAsXLSX(data))
  
   }

  onSubmit() {

    this.submitted = true;
    // parar aquei se o simpleFormulário for inválido
    if (this.simpleForm.invalid) {
      return;
    }
    const uri = 'relatorio/financeira/relatorioCobrancaPorduto';
    this.createOrEdit(uri, this.simpleForm);
  }
  private _getLojas() {
    this.http.call_get('loja/selectBox',null).subscribe(
      response => {
        this._lojas = Object(response).data
        const id = this.simpleForm.getRawValue().loja;
        this._lojas.map(res=>{
          if(res.id==id){
            this.getoperadores(res.id)
          }else{
            return
          }
       })
      }
    );
  }

  private getoperadores(id) {
    this.loadingLojas = 'Carregando...';
    this.http.call_get('user/selectBox/'+id, null).subscribe(
      response => {
        this.operadores = Object(response).data
      }
    )
   
  }
  createOrEdit(uri: any, simpleFormulario: FormGroup) {
    this.cobrancas = []
    this.loading = true;
    this.disabledButton = true;
    this.totais.totals = 0
    // TODO: usado para fazer a requisição com a api de criação de organismo
    this.http.__call(uri, simpleFormulario.value).subscribe(
      response => {
        this.cobrancas = Object(response).data;
        this.arrayCobranca = []
        this.loading = false;
        if (this.cobrancas.length != 0) {
          this.disabledButton = false;

          this.somaTotais();

        }

        let countRecibo={}
        let countFactura={}
        let Recibo={}
        let Fatura={}
        this.cobrancas.forEach((res)=>{
              countRecibo[res.recibo_sigla] = (countRecibo[res.recibo_sigla] || 0)+1;
              countFactura[res.factura_sigla] = (countFactura[res.factura_sigla] || 0)+1;
          }) 
      const formatData=(array)=>{
          for(const item of array) {
            if(countRecibo[item.recibo_sigla]>=2){
                  if(!Recibo[item.recibo_sigla]){
                    let total=item.total
                    this.arrayCobranca.push({...item,total:total})
                    Fatura[item.factura_sigla]=item.factura_sigla
                    Recibo[item.recibo_sigla]=item.recibo_sigla
                    /* Algorithm written by Matondo Pedro */
                  }else{
                    let total=null
                    let factura_total=countFactura[item.factura_sigla]>=2?!Fatura[item.factura_sigla]?item.factura_total:null:item.factura_total
                    this.arrayCobranca.push({...item,total:total,factura_total:factura_total})
                   }
            }else{
              this.arrayCobranca.push(item)
            }
            
          }
        }
        formatData(this.cobrancas)

        if (this.filters.is_allexel == false) {
          let cobranca = []
          this.cobrancas = Object(response).data;

        } else {
          this.exportAsXLSX(this.arrayCobranca)
        }
        this.filters.is_allexel = false
        this.loading = false;
      }
    );
  }

  exportAsXLSX(data: any): void {
    data=data.map(item=>{
         return {
                 ...item,
                 total:item.total==null?'-----':item.total,
                 factura_total:item.factura_total==null?'-----':item.factura_total
                }
             })
    var CurrentDate = new Date();

    var keys = [
      { key: 'id', width: 30, style: { font: { name: 'Calibri' } } },
      { key: 'contrato_id', width: 50 },
      { key: 'nome', width: 50, style: { font: { name: 'Calibri' } } },
      { key: 'cil', width: 30 },
      { key: 'descricao', width: 50, style: { font: { name: 'Calibri' } } },
      { key: 'morada', width: 50, style: { font: { name: 'Calibri' } } },
      { key: 'municipio', width: 50, style: { font: { name: 'Calibri' } } },
      { key: 'distrito', width: 50, style: { font: { name: 'Calibri' } } },
      { key: 'bairro', width: 50 },
      { key: 'moradia_numero', width: 50 },
      { key: 'recibo_sigla', width: 50 },
      { key: 'status', width: 50 },
      { key: 'data', width: 50, style: { font: { name: 'Calibri' } } },
      { key: 'factura_sigla', width: 50, style: { font: { name: 'Calibri' } } },
      { key: 'produto', width: 50, style: { font: { name: 'Calibri' } } },
      { key: 'quantidade', width: 30, style: { font: { name: 'Calibri' } } },
      { key: 'user', width: 30, style: { font: { name: 'Calibri' } } },
      { key: 'loja', width: 30, style: { font: { name: 'Calibri' } } },
      { key: 'total', width: 30, style: { font: { name: 'Calibri' }, numFmt: '#,##0.00' } },
      { key: 'factura_total', width: 30, style: { font: { name: 'Calibri' }, numFmt: '#,##0.00' } },
      { key: 'valor_saldado', width: 30, style: { font: { name: 'Calibri' }, numFmt: '#,##0.00' } },
      { key: 'percentual_pago', width: 30, style: { font: { name: 'Calibri' }, numFmt: '#,##0.00' } },
      { key: 'quantidade_pago', width: 30, style: { font: { name: 'Calibri' }, numFmt: '#,##0.00' } },
      { key: 'linha_facturas_total', width: 30, style: { font: { name: 'Calibri' }, numFmt: '#,##0.00' } },
      { key: 'total_pago', width: 30, style: { font: { name: 'Calibri' }, numFmt: '#,##0.00' } },
    ];


    var Cols = [
        "Nº Cliente",	
        "Nº Contrato",	
        "Nome Cliente",
       	"Cil",
       	"Tarifário",
       	"Morada Cliente",
       	"Município",	
        "Distrito",
       	"Bairro / Zona",	
        "Alocação",	
        "Documento",	
        "Estado",
       	"Data	",
        "Documento Referência",
       	"Artigo",
       	"Quantidade",
       	"Operador",	
        "Agência",	
        "TOTAL RECIBO",
        "TOTAL FACTURA",	
        "VALOR SALDADO(FT)",
        "PAGO FACTURA(%)",
        "QTD PAGO ARTIGO",	
        "VALOR ARTIGO",	
        "TOTAL PAGO ARTIGO",
    ]
    var title = 'Cobrança Por Produto'
    var nameFile = "Cobrança Por Produto-" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    this.excelService.excels(data, nameFile, this.localUrl, keys, Cols, title, 5, 25, 30, 3,[19,20,21,22,23,24,25])
  }

/* 
  exportAsPDF(): void {
    var file = document.getElementsByClassName("tableCobrancaPDF")[0]
    this.RelResumoContaCorrrente.relatorioContaCorrente('save', file, this.localUrl, this.simpleForm.value);
  }

  imprimirPDF(): void {
    var file = document.getElementsByClassName("tableCobrancaPDF")[0]
    this.RelResumoContaCorrrente.relatorioContaCorrente('imprimir', file, this.localUrl, this.simpleForm.value);
  } */

  private getLojas() {
    this.loadingLojas = 'Carregando...';
    const id = this.simpleForm.getRawValue().filial;
    if (id != "" || id != null || id != "T") {
      this.http.call_get('lojas/selectBox/' + id, null).subscribe(
        response => {
          this.lojas = Object(response)
          this.loadingLojas = 'Loja';
        }
      );
    }
  }


  changeLoja() {
    this.simpleForm.patchValue({
      loja_nome: null
    });
    this.lojas.forEach(element => {
      if (element.id == this.simpleForm.getRawValue().loja) {
        this.simpleForm.patchValue({
          loja_nome: element.nome
        });
      }
    });
  }

  private getMunicipios() {
    this.distritos = [];
    this.loadings.municipios = true;
    this.api.get('municipio/selectBox')
      .pipe(finalize(() => { this.loadings.municipios = false; }))
      .subscribe(response => { this.municipios = Object(response).data; }, error => { this.loadings.municipios = false; });
  }

  private getDistritos() {
    this.bairros = [];
    this.loadings.distritos = true;
    this.filter.distrito_id = "";
    this.filter.bairro_id = "";
    this.api.get(`distrito/selectBoxDistritosByMunicipio/${this.filter.municipio_id}`)
      .pipe(finalize(() => { this.loadings.distritos = false; }))
      .subscribe(response => { this.distritos = Object(response).data; }
        , error => { this.loadings.distritos = false; }
      );
  }
  private getBairrosByDistritos() {
    this.filter.bairro_id = "";
    this.loadings.bairros = true;
    if (this.filter.distrito_id == "") { this.filter.distrito_id = null };
    this.api.get(`bairro/getBairrosByDistrito/${this.filter.distrito_id}`)
      .pipe(finalize(() => { this.loadings.bairros = false; }))
      .subscribe(response => { this.bairros = Object(response).data; }
        , error => { this.loadings.bairros = false; }
      );
  }
  private gerarAno() {
    var fecha = new Date();
    var anyo = fecha.getFullYear();

    let j = 0;
    for (let i = anyo; i >= 2000; i--) {
      this.anos[j] = i;
      j++;
    }
  }

  private getProvincias() {
    this.http.call_get('provincia/selectBox', null).subscribe(
      response => {
        this.provincias = Object(response).data
      }
    );
  }

  private direccaosSelectBox() {
    this.http.call_get('direccao/selectBox', null).subscribe(
      response => {
        this.direccaos = Object(response);
      }
    );
  }

  private clientes: any = [];
  view_client = false;

  private getCliente() {
    this.view_client = true;
    this.http.__call('cliente/search-cliente', { start: 1, end: 10, search: this.simpleForm.getRawValue().cliente }).subscribe(
      response => {
        this.clientes = Object(response).data.data;
      }
    );
  }

  private setCliente(client: any) {
    this.view_client = false;
    this.simpleForm.patchValue({
      cliente_id: client.id,
      cliente: client.nome
    });
    /*
        this.getFilials(client.municipio_id) */
  }



  /*  private getGestores() {
     this.http.call_get('gestor/selectBox', null).subscribe(
       response => {
         this.gestores = Object(response).data
         console.log(this.gestores)
       }
     );
   } */

}




