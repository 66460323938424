import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import * as moment from 'moment';
import { ConfigService } from 'src/app/providers/config/config.service';
import { HttpService } from 'src/app/providers/http/http.service';
import { ExcelService } from 'src/app/services/excel.service';
import { ExcelAutoService } from 'src/app/services/excel/excel-auto.service';

@Component({
  selector: 'app-despesas-contencioso',
  templateUrl: './despesas-contencioso.component.html',
  styleUrls: ['./despesas-contencioso.component.css']
})
export class DespesasContenciosoComponent implements OnInit, OnDestroy {


  loading: boolean = false;
  disableButton: boolean = true;


  submitted = false;
  public filters = {
    search: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    pagination: {
      perPage: 5,// Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima,
      lastPage: null,
      total: null // defini o total de registo da BD
    },
    filter: null // subelemente do filter
    ,is_allexel:false
  }

  private localUrl: any;
  private largura: any;
  private altura: any;
  items: any = [];
  estado: any = [];
  simpleFormCliente: FormGroup

  constructor(private http: HttpService,
    private configService: ConfigService,
     private excelService: ExcelService,
     private excelsService: ExcelAutoService,
     private _formBuilder: FormBuilder,
     ) {
      this.createForm()
  }

  ngOnInit() {
    this.empresaUser()
    this.getPageFilterData(1);
    this.getEstado()
    this.getPageFilterDataPage(1)
  }
  ngOnDestroy(){
    this.http.filters.orderBy = null
  }

  get f() {
    return this.simpleFormCliente.controls;
  }
  createForm() {
    this.simpleFormCliente = this._formBuilder.group({
      id: null,
      tipo_despesa: [null, [Validators.required]],
      valor_processo: [null, [Validators.required]],
      estado: null,
    })

  }
  private createFlagKeyup(value){
    this.simpleFormCliente.patchValue({flag:value})
  }

/*   exportAsPDF(): void {
    var file = document.getElementsByClassName("exportAsXLSXCliente")[0]
    this.reportClientes.relatorioClientes('save', file,this.localUrl);

  } */

 /*  imprimirPDF(): void {
    var file = document.getElementsByClassName("exportAsXLSXCliente")[0];
    this.reportClientes.relatorioClientes('print', file,this.localUrl);
  } */

  private empresaUser() {
    this.configService.loaddinStarter('start');

    this.http.call_get('empresa/empresa-user', null).subscribe(
      response => {
        this.localUrl = Object(response).data.logotipo
        this.altura = Object(response).data.width
        this.largura = Object(response).data.height
        this.configService.loaddinStarter('stop');
      }
    );
  }
/*   private getEstado(){
    this.http.__call('comunicacoes/estado_listagem',null).subscribe(
      response => {
        this.estado = Object(response).data;
      })
  } */
  setTrue(){
    this.filters.pagination.page=this.filters.pagination.page
    this.filters.is_allexel=true
    this.getEstado()
  }
  private getEstado() {
    console.log(this.filters)
    this.loading = true
    this.http.__call('listagem_despesa_contencioso', this.filters).subscribe(
      response => {
         this.filters.pagination.lastPage = Object(response).lastPage;
         this.filters.pagination.page = Object(response).page;
         this.filters.pagination.total = Object(response).total;
         this.filters.pagination.perPage = Object(response).perPage;

        this.items = Object(response).data;
        console.log(this.items)
        this.loading = false;
        if (this.items != 0) {
          this.disableButton = false;
        }

        this.filters.is_allexel=false
        this.loading = false;
      }
    );
  }




  onSubmit() {
    this.submitted = true
    if (this.simpleFormCliente.invalid) { return }
    let cliente_value = this.simpleFormCliente.getRawValue()
    let url_action = cliente_value.id ? `edit_despesa_contencioso/${cliente_value.id}` : 'despesa_contencioso'
    this._createOrUpdateCliente(url_action, cliente_value)
  }
  _createOrUpdateCliente(url, cliente) {
    console.log(this.simpleFormCliente)
    this.http.__call(url, cliente)
      .subscribe(
        response => {

          this.submitted = false
          this.loading = false

          this.getEstado();
          this.configService.showAlert(Object(response).message, 'success', true)
          this.getPageFilterData(1)
          if(this.simpleFormCliente.value.id==null)this.simpleFormCliente.reset()
          //this.after_reset()
        },
        error => {
          this.configService.showAlert("Alguma coisa correu mal", 'warning', true);
          this.submitted = false;
        }

      )
    this.loading = false
  }

  iniCliente(cliente:any) {
    this.simpleFormCliente.patchValue({
      id: cliente.id,
      tipo_despesa: cliente.tipo_despesas,
      valor_processo: cliente.valor_despesas,
      estado: null,
    })

  }

  _closeModal(closeModal) {
    var action = document.getElementById(closeModal)
    action.click()
  }

  onReset() {
    this.submitted = false;
    this.simpleFormCliente.reset();
  }


  getPageFilterData(page: number) {
     if (this.filters.pagination.perPage == null) {
       return;
     }
     this.filters.pagination.page = Number.isInteger(page) ? page : 1 ;

     this.getEstado();
   }
  getPageFilterDataPage(page: number) {
     if (this.filters.pagination.perPage == null) {
       return;
     }
     this.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
     this.getEstado();
   }

   private refreshData({descricao}) {
    this.simpleFormCliente.value.tipo_despesa
  }

}



