import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

@Component({
  selector: 'app-conf-tipologia-cliente',
  templateUrl: './conf-tipologia-cliente.component.html',
  styleUrls: ['./conf-tipologia-cliente.component.css']
})
export class ConfTipologiaClienteComponent implements OnInit, OnDestroy {

  private tipologia_title: string = "Registar Tipologia de Contratos";
  private subnivel_tipologia_title: string = "Registar Subnível";

  private tipologia_cliente = {
    id: null,
    descricao: null,
    slug: null,
    juro_mora: null,
    sujeito_corte: null,
    caucao: null,
    nivel_sensibilidade_id: null,
    nivel_sensibilidade: null,
    estado_id: null,
    tipo_juro_id:null,
    has_caucao: null
  }

  private subnivel_tipologia = {
    id: null,
    descricao: null,
    slug: null,
    juro_mora: null,
    sujeito_corte: null,
    parent_id: null,
    caucao: null,
    nivel_sensibilidade_id: null,
    nivel_sensibilidade: null,
    estado_id: null,
    has_caucao: null
  }


  private items: any = [];
  private NivelSensibilidade: any = [];
  private tipoJuros: any = [];
  private subnivel_tipologias: any = [];
  private has_caucao: boolean = false;
  private has_juro: boolean = false;
  private has_corte: boolean = false;

  constructor(private http: HttpService, private configService: ConfigService) { }

  ngOnInit() {
    this.http.filters.search = null
    this.getPageFilterData(1);
    this.listarNivelSensibilidade();
    this.getTipoJuros()
  }
  ngOnDestroy() {
    this.http.filters.orderBy = null
  }


  private listatipologia_cliente() {

    this.configService.loaddinStarter('start');

    this.http.__call('tipologia-cliente/listagem', this.http.filters).subscribe(

      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.configService.loaddinStarter('stop');

      }
    );
  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1;
    this.listatipologia_cliente();
  }


  private save() {
     console.log(this.tipologia_cliente)

     console.log("has_caucao", this.has_caucao)
    this.tipologia_cliente.has_caucao = this.has_caucao;

    if (this.tipologia_cliente.descricao === null) {
      this.configService.showAlert("O campo Descrição é obrigatório", 'alert-danger', true);
      return
    } else if (this.tipologia_cliente.nivel_sensibilidade_id === null) {
      this.configService.showAlert("O campo nível de sensibilidade é obrigatório", 'alert-danger', true);
      return
    } else if ((this.has_caucao) && (this.tipologia_cliente.caucao === null)) {
      this.configService.showAlert("O campo caução é obrigatório", 'alert-danger', true);
      return
    } else {

      this.configService.loaddinStarter('start');
      const uri = (!this.tipologia_cliente.id) ? 'tipologia-cliente/create' : 'tipologia-cliente/update/' + this.tipologia_cliente.id;

      this.http.__call(uri, this.tipologia_cliente).subscribe(
        res => {
          if (Object(res).code == 201) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
            this.configService.loaddinStarter('stop');
          } else {
            this.configService.showAlert(Object(res).message, 'alert-success', true);

            this.listatipologia_cliente()
            this.configService.loaddinStarter('stop');
          }
        }
      )
    };

  }


  private setDataTipologiaClientes(item) {


    this.tipologia_cliente.id = item.id;
    this.tipologia_cliente.descricao = item.descricao;
    this.tipologia_cliente.estado_id = item.estado;
    this.tipologia_cliente.slug = item.slug;
    this.tipologia_cliente.caucao = item.caucao;
    this.tipologia_cliente.sujeito_corte = item.sujeito_corte;
    this.tipologia_cliente.juro_mora = item.juro_mora;
    this.tipologia_cliente.nivel_sensibilidade_id = item.nivel_sensibilidade_id;
    this.tipologia_cliente.nivel_sensibilidade = item.nivel_sensibilidade;

    this.has_juro = (item.juro_mora) ? true : false;
    this.has_caucao = (item.caucao) ? true : false;

    this.tipologia_title = (item.id) ? "Registar Tipologia de Contratos" : "Editar Tipologia de Contratos";
  }


  private setDataSubnivelTipologia(item: any, id) {

    this.subnivel_tipologia.id = item.id;
    this.subnivel_tipologia.estado_id = item.estado;
    this.subnivel_tipologia.descricao = item.descricao;
    this.subnivel_tipologia.slug = item.slug;
    this.subnivel_tipologia.caucao = item.caucao;
    this.subnivel_tipologia.sujeito_corte = item.sujeito_corte;
    this.subnivel_tipologia.juro_mora = item.juro_mora;
    this.subnivel_tipologia.nivel_sensibilidade_id = item.nivel_sensibilidade_id;
    this.subnivel_tipologia.nivel_sensibilidade = item.nivel_sensibilidade;

    this.has_juro = (item.juro_mora) ? true : false;
    this.has_caucao = (item.caucao) ? true : false;

    this.subnivel_tipologia_title = (item.id) ? "Registar Subnível" : "Editar Subnível";

    this.subnivel_tipologia.parent_id = id;
  }


  private saveSubnivel() {

    this.subnivel_tipologia.parent_id = this.tipologia_cliente.id;
    this.subnivel_tipologia.has_caucao = this.has_caucao;

    if (this.subnivel_tipologia.descricao === null) {
      this.configService.showAlert("Os campos Descrição e Slug são obrigatórios", 'alert-danger', true);
      return
    } else if ((this.has_caucao) && (this.subnivel_tipologia.caucao === null)) {
      this.configService.showAlert("O campo caução é obrigatório", 'alert-danger', true);
      return
    } else {

      this.configService.loaddinStarter('start');
      const uri = (!this.subnivel_tipologia.id) ? 'tipologia-cliente/appendSubnivelTipologia/' : 'tipologia-cliente/update/' + this.subnivel_tipologia.id;

      this.http.__call(uri, this.subnivel_tipologia).subscribe(
        res => {
          if (Object(res).code == 201) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
            this.configService.loaddinStarter('stop');
          } else {
            this.configService.showAlert(Object(res).message, 'alert-success', true);

            this.getSubniveis()
            this.configService.loaddinStarter('stop');
          }
        }
      )
    };
  }

  private listarNivelSensibilidade() {
    this.http.call_get('nivel-sensibilidade/selectBox', null).subscribe(
      response => {
        this.NivelSensibilidade = Object(response);
      }
    );
  }

  private getTipoJuros() {
    this.http.call_get('tipo-juro/selectBox', null).subscribe(
      response => {
        this.tipoJuros = Object(response);
        console.log(this.tipoJuros)
      }
    );
  }

  private setCaucao(): void {
    this.has_caucao = !this.has_caucao;
  }
  private setCorte(): void {
    this.has_corte = !this.has_corte;
  }


  onReset() {
    this.has_caucao = null;
    this.has_juro = null;
    this.has_corte = null;
    this.tipologia_cliente.descricao = null;
    this.tipologia_cliente.slug = null;
    this.tipologia_cliente.estado_id = null;
  }

  /*   private ValorByProduto() {

      this.http.call_get('selectBox/artigo-valorByProduto/' + this.tipologia_cliente.id, null).subscribe(
        data => {
          this.subnivel_tipologia.juro_mora = Object(data).data.juro_mora;
          this.subnivel_tipologia.sujeito_corte = Object(data).data.sujeito_corte;
          this.subnivel_tipologia.caucao = Object(data).data.caucao;
        }
      );
    } */

  private getSubniveis() {
    this.http.call_get('tipologia-cliente/getSubniveis/' + this.tipologia_cliente.id, null).subscribe(
      response => {
        this.subnivel_tipologias = Object(response);
      }
    );
  }

  private ini() {
    this.tipologia_cliente = {
      id: null,
      descricao: null,
      slug: null,
      juro_mora: null,
      sujeito_corte: null,
      caucao: null,
      nivel_sensibilidade_id: null,
      nivel_sensibilidade: null,
      estado_id: null,
      tipo_juro_id:null,
      has_caucao: null
    }
  }

  private initSubnivel() {
    this.subnivel_tipologia = {
      id: null,
      descricao: null,
      slug: null,
      juro_mora: null,
      sujeito_corte: null,
      parent_id: null,
      caucao: null,
      nivel_sensibilidade_id: null,
      nivel_sensibilidade: null,
      estado_id: null,
      has_caucao: null
    }
  }



}
