import { Component, OnInit,  Input,Output, EventEmitter,OnChanges, SimpleChange} from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { HttpService } from 'src/app/providers/http/http.service';



@Component({
  selector: 'app-update-fate-rate',
  templateUrl: './update-fate-rate.component.html',
  styleUrls: ['./update-fate-rate.component.css']
})
export class UpdateFateRateComponent implements OnInit {
  @Input() modalUpdateFlateRate: string = "modalUpdateFlateRate";
  @Input() flateRate: any;
  submitted=false
  flateRatForm= new FormGroup({
     valor: new FormControl('', Validators.required),
     origem: new FormControl('', Validators.required),
     capacidade: new FormControl('', Validators.required)
  })

  get formControl():any{
    return this.flateRatForm.controls;
  }

  constructor( private http: HttpService) { }

  ngOnInit() {
  }

  ngOnChanges(){
    if(this.flateRate.servico_id==undefined){
      console.log('o valor esta null')
    }else{
      this.flateRatForm.controls['valor'].setValue(this.flateRate.valor)
      this.flateRatForm.controls['origem'].setValue(this.flateRate.origem);
      this.flateRatForm.controls['capacidade'].setValue(this.flateRate.capacidade);

    }
    console.log(this.flateRate)
  }

  updateFlate(){
    this.submitted=true
    console.log(this.formControl.valor.value)
    if(this.flateRatForm.invalid){
      console.log("invalid");
      return 
    }else{
      this.http.__call("flate_rate/update",{ id:this.flateRate.servico_id, valor : this.formControl.valor.value, capacidade: this.formControl.capacidade.value, origem: this.formControl.origem.value}).subscribe(response=>{

      })
    }
 
  }
}
