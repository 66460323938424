import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute }     from '@angular/router';
//import { Observable }         from 'rxjs/Observable';
import * as jsPDF from 'jspdf';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service'

@Component({
  selector: 'app-get-pdf-email',
  templateUrl: './get-pdf-email.component.html',
  styleUrls: ['./get-pdf-email.component.css']
})
export class GetPdfEmailComponent implements OnInit {

  private idFactura: any;

  constructor( private route: ActivatedRoute, private configService: ConfigService) { }

  ngOnInit() {

    this.route.paramMap.subscribe(params => { 
      //console.log(params.get('id')); 
      this.idFactura = params.get('id');
    });

    var doc = new jsPDF()
 
doc.text('Hello world!', 10, 10)

//window.open(doc.output('bloburl'))
//doc.output('datauristring');        //returns the data uri string
//doc.output('datauri');              //opens the data uri in current window
//doc.output('dataurlnewwindow');     //opens the data uri in new window
//document.location.href =('http://localhost:4200/getPdfEmail/179080');
//window.open(doc.output('datauristring'));



  //this.configService.imprimirFactura(this.idFactura, "2ª Via");
  }

}
