import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { ConfigModuloService } from 'src/app/services/config-modulo.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { RotaService } from '../rotas.service';
import { RoteiroService } from 'src/app/components/reports/roteiro/roteiro.service';
import * as moment from 'moment';
import { alphaNumericValidatorExtension } from '@rxweb/reactive-form-validators/validators-extension';
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'
import { finalize } from 'rxjs/operators';
import { ApiService } from 'src/app/providers/http/api.service';
import { rtrim } from '@rxweb/reactive-form-validators';
import { ExcelAutoService } from 'src/app/services/excel/excel-auto.service';

@Component({
  selector: 'app-rota-header',
  templateUrl: './rota-header.component.html',
  styleUrls: ['./rota-header.component.css']
})
export class RotaHeaderComponent implements OnInit, OnDestroy {

  public currentUser: any;

  private title: string = "Registar Rota"
  private rota_header = {
    id: null,
    descricao: null,
    provincia_id: null,
    municipio_id: null,
    data_inicio: null,
    data_fim: null,
    leitor_id: null,
    user_nome: null,
    estado: null,
  }


  private rotarun = {
    id: null,
    nome_cliente: null,
    rota_header_id: null,
    conta_id: null,
    servico_id: null,
    id_servico: null,
    provincia_id: null,
    municipio_id: null,
    bairro_id: null,
    has_distrito: null,
  }

  private lista = {
    id: null
  }

  private residencia = {
    id: null,
    numero: null,
    nome: null,
    predio_nome: null,
    is_predio: null,
    rua_id: null,
    rua: null,
    bairro_id: null,
    bairro: null,
    has_distrito: false,
    municipio_id: null,
    distrito_id: null,
    provincia_id: null,
    is_active: null,
    user_id: null,
  };


  private corridasLeiturasArray = [];
  private leituraToProcess: boolean = true;

  private local_instalacaosArray = [];

  private local_instalacaoRow = {
    id: null,
    moradia_numero: null,
    is_predio: null,
    //predio_id: null,
    predio_nome: null,
    rota_header_id: null,
    local_consumo_id: null,

    rua_id: null,
    rua: null,
    bairro_id: null,
    bairro: null,
    has_distrito: null,
    distrito_id: null,
    distrito: null,
    municipio_id: null,
    municipio: null,
    provincia_id: null,
    provincia: null,
    latitude: null,
    longitude: null,
    user_id: null,
    user: null

  };

  private predio_flag: boolean = false;
  private local_instalacaoRowValid: boolean = false;
  private addRows: boolean = true;


  private provincias: any = [];
  private items: any = [];
  private distritos: any[];
  private bairros: any[];
  private residencias: any[];
  private municipios: any[];
  private ruas: any[];
  private periodo_inicio: any = [];
  private periodo_fim: any = [];
  roteiro: any = {}
  motivos_nao_leitura_list: any = []
  private DadosEmpresa: any = [];

  constructor(
    private auth: AuthService,
    private http: HttpService,
    private configService: ConfigService,
    private _rotaService: RotaService,
    private config: ConfigModuloService,
    private excelService: ExcelAutoService,
    private reportRoteiro: RoteiroService,
    private api: ApiService,
  ) {
    this.getPageFilterData(1);
    this.currentUser = this.auth.currentUserValue;
    this._loading();
  }
  loadings = {
    municipios: false
  }
  public filter = {
    municipio_id: null,
    status: null
  }
  ngOnInit() {
    this.getMunicipios()
  }
  ngOnDestroy() {
    this.http.filters.orderBy = null
  }

  private getMunicipios() {
    this.distritos = [];
    this.loadings.municipios = true;
    this.api.get('municipio/selectBox')
      .pipe(finalize(() => { this.loadings.municipios = false; }))
      .subscribe(response => {
        this.municipios = Object(response).data;
      })
      , ((error: any) => {
        this.loadings.municipios = false;
      });
  }

  _loading() {
    this.http.filters.search = null
    this.selectBoxProvincias();
    this.gerarPeriodoInicial();
    this.getConfiguracaos();
    this.empresaUser();
  }

  _closeModal() {
    const modal = document.querySelector('.modal-backdrop')
    modal.classList.remove('modal-backdrop', 'fade', 'show')
  }

  private listagemRotaHeader() {
    this.http.filters.status = this.filter.status
    this.configService.loaddinStarter('start');
    this.http.__call('rota-header/listagem', { filters: this.http.filters })
      .subscribe(response => {

        this.items = Object(response).data.data;
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;
        this.configService.loaddinStarter('stop');

      });
  }

  private selectBoxProvincias() {
    this.http.call_get('provincia/selectBox', null).subscribe(
      response => {
        this.provincias = Object(response).data;
      }
    );
  }

  private selectBoxMunicipiosByProvincia(id) {

    this.http.call_get('municipio/getMunicipiosByProvincia/' + id, null).subscribe(
      response => {
        this.municipios = Object(response).data;
      }
    );
  }

  private selectBoxDistritosByMunicipio() {

    for (let i = 0; i < this.municipios.length; ++i) {
      if (this.municipios[i].id == this.residencia.municipio_id)
        this.residencia.has_distrito = this.municipios[i].has_distrito;
    }

    if (this.residencia.has_distrito) {
      this.http.call_get('distrito/getDistritosByMunicipio/' + this.residencia.municipio_id, null).subscribe(
        response => {
          this.distritos = Object(response).data;
        }
      );
    }

    if (!this.residencia.has_distrito) {
      this.selectBoxBairrosByMunicipio();
    }

  }

  private selectBoxRuasByBairro() {
    this.http.call_get('rua/selectBoxByBairro/' + this.residencia.bairro_id, null).subscribe(
      response => {
        this.ruas = Object(response);
      }
    );
  }

  private selectBoxBairrosByMunicipio() {
    this.http.call_get('bairro/selectBoxByMunicipio/' + this.residencia.municipio_id, null).subscribe(
      response => {
        this.bairros = Object(response);
      }
    );
  }

  exportAsPDF(): void {
    var file = document.getElementsByClassName("tablePDF")[0]
    //  this.reportRoteiro.reportRoteiro(file, 'save', this.filtros,this.localUrl,'');
    this.reportRoteiro.reportRoteiro(file, this.DadosEmpresa, 'print', this.rota_header.descricao);
  }

  exportAsRotasPDF(): void {
    var file = document.getElementsByClassName("tablePDF")[0]
    this.reportRoteiro.reportRotas(file, this.DadosEmpresa, 'save', 'Rotas', this.items.length);
  }


  view_residencia = false;

  private getSelectBoxResidenciasByRua() {

    if (this.residencia.nome == "") {
      this.setNullResidencia();
    }

    this.view_residencia = true;
    this.http.__call('local-instalacao/getSelectResidenciaInLocalConsumoByRua', { rua_id: this.residencia.rua_id, start: 1, end: 10, search: null }).subscribe(
      response => {
        this.residencias = Object(response).data.data;

        this.configService.loaddinStarter('stop');
      }
    );
  }


  private setDataLocalInstalacao() {
    //this.local_instalacao.provincia_id =
  }


  private setResidencia(codigo, numero, nome, andar, is_predio) {
    this.residencia.id = codigo;
    this.residencia.numero = numero;
    this.residencia.nome = ((is_predio) ? 'Prédio ' + nome + ', ' + (andar ? andar + ' º Andar - ' : '') + 'Porta ' : 'Residência ') + numero;
    this.residencia.predio_nome = nome;
    this.residencia.is_predio = is_predio;
    this.view_residencia = false;

  }

  private setNullResidencia() {
    this.residencia.id = null;
    this.residencia.numero = null;
    this.residencia.nome = null;
    this.residencia.predio_nome = null;
    this.residencia.is_predio = null,
      this.view_residencia = false;
  }

  view_user = true;
  users: any = [];

  private getUtilizador() {

    if (this.rota_header.user_nome == "") {
      this.setNullUser();
    }
    this.view_user = true;
    this.http.__call('search-utilizador', { start: 1, end: 10, search: this.rota_header.user_nome })
      .subscribe(
        response => {
          this.users = Object(response).data.data;
          this.configService.loaddinStarter('stop');
        }
      );
  }

  private setUtilizador(id, nome) {
    this.rota_header.leitor_id = id;
    this.rota_header.user_nome = nome;
    this.view_user = false;
  }

  private setNullUser() {
    this.rota_header.leitor_id = null;
    this.rota_header.user_nome = null;
    this.view_user = false;
  }

  // CLIENTE SEARCH

  view_cliente = false;
  private clientes: any = [];

  private getCliente() {
    if (this.rotarun.nome_cliente == "") {
      this.setNullCliente();
    }
    //this.chaves=[];
    this.view_cliente = true;
    this.http.__call('cliente/search-cliente', { start: 1, end: 10, search: this.rotarun.nome_cliente }).subscribe(
      response => {
        this.clientes = Object(response).data.data;
        this.configService.loaddinStarter('stop');
      }
    );
  }

  private setCliente(id, nome) {
    this.rotarun.nome_cliente = nome;
    this.view_cliente = false;

    this.listarContasClientes(id)

  }

  private setNullCliente() {

    this.rota_header.user_nome = null;
    this.view_user = false;
  }

  private contas: any = [];

  private listarContasClientes(id) {
    this.carregando.conta = 'Carregando...';
    this.http.call_get('cliente/conta/listar/' + id, null).subscribe(
      response => {
        this.contas = Object(response).data;
        this.carregando.conta = 'Seleccione a Conta';
      }
    );
  }


  private async appendRowLocalInstalacao() {

    const loadedStateCallback = () => {
      this.local_instalacaoRow.rua_id = this.residencia.rua_id;

      this.local_instalacaoRow.is_predio = this.residencia.is_predio;
      this.local_instalacaoRow.predio_nome = (this.residencia.is_predio) ? this.residencia.predio_nome : null;
      this.local_instalacaoRow.predio_nome = this.residencia.nome;

      this.local_instalacaoRow.moradia_numero = this.residencia.numero;
      this.local_instalacaoRow.user_id = this.currentUser.user.id;

      this.local_instalacaoRowValid = this.localInstalacaoRowValidation(this.local_instalacaoRow);
      if (this.local_instalacaoRowValid)
        this.local_instalacaosArray.unshift({ ...this.local_instalacaoRow });

    }
    await this.getLocalInstalacaoByRuaAndMoradia(loadedStateCallback);

  }


  private localInstalacaoRowValidation(row): boolean {

    if (this.local_instalacaosArray.length == 0)
      return true;

    for (let i = 0; i < this.local_instalacaosArray.length; ++i) {
      if (this.local_instalacaosArray[i].rua_id == row.rua_id && this.local_instalacaosArray[i].moradia_numero == row.moradia_numero && this.local_instalacaosArray[i].is_predio == row.is_predio) {
        return false;
      }
    }

    return true;
  }


  private deleteRowLocalInstalacaoAppended(row): void {

    for (let i = 0; i < this.local_instalacaosArray.length; ++i) {

      if ((this.local_instalacaosArray[i].rua_id == row.rua_id) && (this.local_instalacaosArray[i].moradia_numero == row.moradia_numero && this.local_instalacaosArray[i].is_predio == row.is_predio)) {
        this.local_instalacaosArray.splice(i, 1);
      }

    }


  }


  private getLocalInstalacaoByRuaAndMoradia(callback: Function) {


    this.http.__call('local-instalacao/getLocalInstalacaoByRuaAndMoradia/', {
      rua_id: this.residencia.rua_id,
      moradia_numero: this.residencia.numero
    }).subscribe(
      response => {
        const result = Object(response);

        this.local_instalacaoRow.local_consumo_id = result.id;
        this.local_instalacaoRow.moradia_numero = result.moradia_numero;
        this.local_instalacaoRow.is_predio = result.is_predio;
        this.local_instalacaoRow.predio_nome = result.predio_nome;
        this.local_instalacaoRow.latitude = result.latitude;
        this.local_instalacaoRow.longitude = result.longitude;

        this.local_instalacaoRow.rua_id = result.rua_id;
        this.local_instalacaoRow.rua = result.rua;
        this.local_instalacaoRow.bairro_id = result.bairro_id;
        this.local_instalacaoRow.bairro = result.bairro;
        this.local_instalacaoRow.distrito_id = result.distrito_id;
        this.local_instalacaoRow.distrito = result.distrito;
        this.local_instalacaoRow.has_distrito = result.has_distrito;
        this.local_instalacaoRow.municipio_id = result.municipio_id;
        this.local_instalacaoRow.municipio = result.municipio;
        this.local_instalacaoRow.provincia_id = result.provincia_id;
        this.local_instalacaoRow.provincia = result.provincia;

        if (!this.local_instalacaoRow.has_distrito) this.local_instalacaoRow.distrito_id = null;

        callback();
      }
    );

  }

  private servicos: any = [];

  private listarServicos() {

    const id = this.rotarun.conta_id

    this.carregando.servico = 'Carregando...';
    this.http.call_get('tarifario/servico/selectBoxServicosConta/' + id, null).subscribe(
      response => {
        this.servicos = Object(response).data;
        this.carregando.servico = 'Seleccione o serviço';
      }
    );
  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      this.http.filters.pagination.perPage = 5
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1;
    this.listagemRotaHeader();
    //this._resetFilters();
  }

  _resetFilters() {
    this.http.filters.municipio_id = null;
    this.http.filters.status = null;
  }

  private carregando = {
    filial: 'Seleccione a filial',
    conta: 'Seleccione a conta',
    servico: 'Seleccione o serviço',
    periodo: 'Seleccione o Fim'
  }

  private filiais: any = [];

  private selectBox() {
    this.listarFilial();
  }

  private listarFilial() {
    this.carregando.filial = 'Carregando...';
    this.http.call_get('filial/selectBox', null).subscribe(
      response => {
        this.filiais = Object(response).data;
        this.carregando.filial = 'Seleccione a Filial';
      }
    );
  }


  private saveRotaHeader(e) {

    this.configService.loaddinStarter('start');
    e.preventDefault();

    if (this.rota_header.descricao == null) {
      this.configService.showAlert("O campo Descrição é obrigatório", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    }
    else if (this.rota_header.estado == null) {
      this.configService.showAlert("O campo Estado é obrigatório", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    }
    else if (this.rota_header.leitor_id == null) {
      this.configService.showAlert("O campo Leitor é obrigatório", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    }
    else if (this.rota_header.provincia_id == null) {
      this.configService.showAlert("O campo Província é obrigatório", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    }
    else if (this.rota_header.municipio_id == null) {
      this.configService.showAlert("O campo Munícipio é obrigatório", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    }
    else {

      if (this.rota_header.id <= 0) {
        this.http.__call('rota-header/create', this.rota_header).subscribe(
          res => {
            if (Object(res).code == 201) {
              this.configService.showAlert(Object(res).message, 'alert-danger', true);
              this.configService.loaddinStarter('stop');
            } else {
              this.configService.showAlert(Object(res).message, 'alert-success', true);
              this.clearFormInputs();
              this.listagemRotaHeader()
              this.configService.loaddinStarter('stop')
              this._closeModalAction("closeModalRegistarRota")

            }
          })
      } else {

        this.http.__call('rota-header/update/' + this.rota_header.id, this.rota_header).subscribe(
          res => {
            if (Object(res).code == 201) {
              this.configService.showAlert(Object(res).message, 'alert-danger', true);
              this.configService.loaddinStarter('stop');
            } else {
              this.configService.showAlert(Object(res).message, 'alert-success', true);
              this.clearFormInputs();
              this.listagemRotaHeader()
              this.configService.loaddinStarter('stop')
              this._closeModalAction("closeModalRegistarRota")
            }
          })
      }
    }
    ;
  }


  private empresaUser() {
    this.configService.loaddinStarter('start');

    this.http.call_get('empresa/empresa-user', null).subscribe(
      response => {
        this.DadosEmpresa = Object(response).data;
      }
    );
  }


  private clearFormInputs() {
    this.rota_header.id = null,
      this.rota_header.leitor_id = null,
      this.rota_header.user_nome = null
  }

  private clearFormRotaRun() {
    this.rotarun.nome_cliente = null,
      this.rotarun.conta_id = null,
      this.rotarun.servico_id = null,
      this.rotarun.id_servico = null
  }


  private setDataLeitura(item: any) {
  }


  private setDataRotaHeader(item: any) {
    this.title = "Editar Rota";

    this.rota_header.id = item.id;
    this.rota_header.descricao = item.descricao;
    this.rota_header.provincia_id = item.provincia_id;
    this.rota_header.municipio_id = item.municipio_id;
    this.rota_header.data_inicio = moment(item.data_inicio).format("YYYY-MM-DD");
    this.rota_header.data_fim = moment(item.data_fim).format("YYYY-MM-DD");
    this.rota_header.leitor_id = item.leitor_id;
    this.rota_header.user_nome = item.user_nome;
    this.rota_header.estado = item.estado_id;

    this.selectBoxMunicipiosByProvincia(item.provincia_id);
    this.gerarPeriodoFinal();

  }

  _initModalCriarRoteiro(rota_header) {
    this.roteiro = rota_header
  }

  private setDataRotaRun(item: any) {

  }

  private gerarPeriodoInicial() {
    let dia = 1
    this.carregando.periodo = 'Carregando...';

    let j = 0;
    for (let i = dia; i <= 31; i++) {
      this.periodo_inicio[j] = i;
      j++;
      this.carregando.periodo = 'Seleccione o Início';
    }
  }
  private gerarPeriodoFinal() {
    /*this.periodo_fim = [];
    let dia = this.rota_header.data_inicio;
    this.carregando.periodo = 'Carregando...';

    let j = 0;
    for (let i = dia; i <= 31; i++) {
      this.periodo_fim[j] = i;
      j++;
      this.carregando.periodo = 'Seleccione o Fim';
    }*/
  }


  private ini() {
    this.rota_header = {
      id: null,
      descricao: null,
      data_inicio: null,
      data_fim: null,
      provincia_id: null,
      municipio_id: null,
      leitor_id: null,
      user_nome: null,
      estado: null
    }
  }



  public getConfiguracaos() {

    this.title = "Registar Rota";
    let result = null;

    const slugs = [
      this.config.provincia_default
    ];

    for (let index = 0; index < slugs.length; index++) {

      this.http.__call('configuracao/getConfiguracaobySlug/' + slugs[index], null)
        .subscribe(response => {
          if (Object(response).code != 200) {
            result = null;
          } else {
            result = Object(response).data;

            if (slugs[index] == this.config.provincia_default) {
              this.rota_header.provincia_id = result.valor;
              this.rotarun.provincia_id = result.valor;
              this.selectBoxMunicipiosByProvincia(result.valor);
            }
          }
        })
    }
  }


  _closeModalAction(closeModal) {
    var action = document.getElementById(closeModal)
    action.click();
  }

  exportAsXLSX(): void {
    var CurrentDate = new Date();

    var keys = [
      { key: 'descricao', width: 35, style: { font: { name: 'Calibri' } } },
      { key: 'municipio', width: 25 },
      { key: 'data_inicio', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'data_fim', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'ultimo_processamento_at', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'estado', width: 30 },
      { key: 'realizado', width: 55 },
      { key: 'pendente', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'user_nome', width: 20, style: { font: { name: 'Calibri' } } },
    ];

    var Cols = ["Descrição", "Município", "Período de", "Período Até", "Ultimo Processamento", "Estado", "Leituras Realizada(s)", "Leituras Pendente(s)", "Leitor"]
    var title = 'Lista das Rotas';
    var nameFile = "Lista das Rotas -" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    this.excelService.excels(this.items, nameFile, this.DadosEmpresa.logotipo, keys, Cols, title, 5, 16, 30, 3)
  }

  clearSearch() {
    this.http.filters.search = ''
  }
}
