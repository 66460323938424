import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-float-link',
  templateUrl: './float-link.component.html',
  styleUrls: ['./float-link.component.css']
})
export class FloatLinkComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
