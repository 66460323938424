import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/providers/config/config.service';
import { HttpService } from 'src/app/providers/http/http.service';

@Component({
  selector: 'app-subsistema',
  templateUrl: './subsistema.component.html',
  styleUrls: ['./subsistema.component.css']
})
export class SubsistemaComponent implements OnInit {
  private subsistema = {
    id: null,
    descricao: null
  }

  private items: any = [];

  constructor(private http: HttpService, private configService: ConfigService) { }

  ngOnInit() {
    this.http.filters.search = null
    this.getPageFilterData(1);
  }
  ngOnDestroy() {
    this.http.filters.orderBy = null
  }


  private ListarSubsistema() {
    this.configService.loaddinStarter('start');

    this.http.__call('subsistema/listagem', this.http.filters).subscribe(

      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data;
        this.configService.loaddinStarter('stop');

      }
    );
  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1;
    this.ListarSubsistema();
  }


  private register(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (this.subsistema.descricao == "") {
      this.configService.showAlert("O campo descrição é obrigatório", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    }
    else {
      this.http.__call('subsistema/create', this.subsistema).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
            this.configService.loaddinStarter('stop');
          }
          else {
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.clearFormInputs(e);
            this.ListarSubsistema()
            this.configService.loaddinStarter('stop');
          }
        }
      )
    };
  }


  private clearFormInputs(e) {
    e.target.elements[0].value = null;
    e.target.elements[1].value = null;
    e.target.elements[2].value = null;
  }

  private refresh(item: any) {
    this.subsistema.id = item.id;
    this.subsistema.descricao = item.descricao;
  }

  private editar(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (this.subsistema.descricao == "" || this.subsistema.descricao == null) {
      this.configService.showAlert("O campo Material é obrigatórios", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    }
    else {
      this.http.__call('subsistema/update/' + this.subsistema.id, this.subsistema).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          }
          else {
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.ListarSubsistema();

          }
        }
      );
    }
    this.configService.loaddinStarter('stop');
  }

  private ini() {
    this.subsistema = {
      id: null,
      descricao: null
    }
  }

}
