import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalListOrdemServicoService {

  public flag:string=null
   checkIfIsOrdemServico:boolean

  constructor(
    
  ) { 
   
  }
}
