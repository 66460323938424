import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { ConfigModuloService } from 'src/app/services/config-modulo.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { RotaService } from '../rotas.service';
import { finalize } from 'rxjs/operators';
import * as moment from 'moment';
import { ApiService } from 'src/app/providers/http/api.service';
import { ExcelAutoService } from 'src/app/services/excel/excel-auto.service';
import { RoteiroService } from '../../reports/roteiro/roteiro.service';

@Component({
  selector: 'app-clientes-sem-rotas',
  templateUrl: './clientes-sem-rotas.component.html',
  styleUrls: ['./clientes-sem-rotas.component.css']
})
export class ClientesSemRotasComponent implements OnInit {


  private current_rota = {
    rota_header_id: null,
    descricao: null,
    local_consumo_id: null
  };

  private view_rotas: boolean = false;

  public currentUser: any;
  private DadosEmpresa: any = [];
  private rotas: any[];
  private provincias: any = [];
  private items: any = [];

  private quarteiroes: any = []
  private ruas: any = []
  private distritos: any = [];
  private bairros: any = [];
  private residencias: any = [];
  private municipios: any = [];

  private periodo_inicio: any = [];
  private periodo_fim: any = [];
  roteiro: any = {}
  motivos_nao_leitura_list: any = []
  public loadings = { municipios: null }
  private view_serie_rotas: boolean = false;

  constructor(
    private auth: AuthService,
    private http: HttpService,
    private configService: ConfigService,
    private reportRoteiro: RoteiroService,
    private _rotaService: RotaService,
    private api: ApiService,
    private excelService: ExcelAutoService,
    private config: ConfigModuloService
  ) {
    this.currentUser = this.auth.currentUserValue;
    this.getPageFilterData(1);
    this.searchRotas();
    this.empresaUser();
  }

  ngOnInit() {
    this.selectBoxProvincias();
    this._rotaService.getMotivosNaoLeitura()
      .subscribe(
        response => {
          this.motivos_nao_leitura_list = response.data
        }
      )
    this._rotaService.listRotasHeadersChange.subscribe(
      response => {
      }
    )
    this.getMunicipios()
  }

  ngOnDestroy() {
    this.http.filters.orderBy = null
  }

  _changeQuarteirao(bairro_id) {
    this.quarteiroes = []
    if (bairro_id) {
      this._getQuarteirosDoBairro(bairro_id)
    }
  }

  _changeRuas(quarteirao_id) {
    this.ruas = []
    if (quarteirao_id) {
      this._getRuasDoQuarteirao(quarteirao_id)
    }
  }

  _reset_filters() {
    this.http.filters.distrito_id = null;
    this.http.filters.bairro_id = null;
    this.http.filters.rua_id = null;
    this.http.filters.quarteirao_id = null;
  }


  private getMunicipios() {
    this.loadings.municipios = true;
    this.api.get('municipio/selectBox')
      .pipe(finalize(() => { this.loadings.municipios = false; }))
      .subscribe(response => { this.municipios = Object(response).data; }, error => { this.loadings.municipios = false; });
  }

  getDistrito(id) {
    this._reset_filters()
    this.distritos = [];
    this.bairros = [];
    this.quarteiroes = []
    this.ruas = []
    this.http.__call('get-distritos/' + id, null).subscribe(
      response => {
        this.distritos = Object(response).data;
      }
    );
  }

  private getBairro(id) {

    this.http.call_get('bairro/getBairrosByDistrito/' + id, null).subscribe(
      response => {
        this.bairros = Object(response).data;
      }
    );
  }

  _getQuarteirosDoBairro(id) {
    this.http.call_get(`quarteirao/getQuarteiraosByBairro/${id}`, null)
      .subscribe(
        response => {
          this.quarteiroes = Object(response).data
        }
      )
  }

  _getRuasDoQuarteirao(id) {
    this.http.call_get(`rua/getRuasByQuarteirao/${id}`, null)
      .subscribe(
        response => {
          this.ruas = Object(response).data
        }
      )
  }

  _closeModal() {
    const modal = document.querySelector('.modal-backdrop')
    modal.classList.remove('modal-backdrop', 'fade', 'show')
  }

  private listaRotaRuns() {
    this.configService.loaddinStarter('start');
    this.http.__call('listagem/clientes-sem-rotas', { filters: this.http.filters }).subscribe(

      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.configService.loaddinStarter('stop');
      }
    );
  }

  private selectBoxProvincias() {
    this.http.call_get('provincia/selectBox', null).subscribe(
      response => {
        this.provincias = Object(response).data;
      }
    );
  }

  private selectBoxMunicipiosByProvincia(id) {
    this.http.call_get('municipio/getMunicipiosByProvincia/' + id, null).subscribe(
      response => {
        this.municipios = Object(response).data;
      }
    );
  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1;
    this.listaRotaRuns();
    //this._resetFilters();
  }

  _resetFilters() {
    this.http.filters.rota_header_id = null;
    this.http.filters.municipio_id = null;
    this.http.filters.status = null;
  }

  private carregando = {
    filial: 'Seleccione a filial',
    conta: 'Seleccione a conta',
    servico: 'Seleccione o serviço',
    periodo: 'Seleccione o Fim'
  }

  private filiais: any = [];

  private selectBox() {
    this.listarFilial();
  }

  private listarFilial() {
    this.carregando.filial = 'Carregando...';
    this.http.call_get('filial/selectBox', null).subscribe(
      response => {
        this.filiais = Object(response).data;
        this.carregando.filial = 'Seleccione a Filial';
      }
    );
  }

  public getRotas(search) {
    this.http.__call('get/rotas', { search: search })
      .subscribe(res => {
        this.rotas = Object(res).data;
      })
  }

  searchRotas() {
    this.view_rotas = true;
    this.getRotas(this.current_rota.descricao)
  }
  _closeModalAction(closeModal) {
    var action = document.getElementById(closeModal)
    action.click();
  }

  set_cliente_sem_rotas(item: any) {
    this.current_rota.local_consumo_id = item.local_consumo_id
    this.view_rotas = false
    this.current_rota.rota_header_id = null
    this.current_rota.descricao = null
  }

  set_view_rotas(item: any) {
    this.current_rota.rota_header_id = item.id
    this.current_rota.descricao = item.descricao
    console.log(this.current_rota)
    this.view_rotas = false;
  }

  associar_roteiro_a_rota() {
    this.http.__call("associar-roteiro-a-rota", this.current_rota)
      .subscribe(res => {
        this.listaRotaRuns();
      })
  }

  private empresaUser() {
    this.configService.loaddinStarter('start');

    this.http.call_get('empresa/empresa-user', null).subscribe(
      response => {
        this.DadosEmpresa = Object(response).data;
      }
    );
  }

  exportAsPDF(): void {
    var file = document.getElementsByClassName("tablePDF")[0]
    this.reportRoteiro.reportClienteSemRotas(file, this.DadosEmpresa, 'save', 'Rotas', this.items.length);
  }

  exportAsXLSX(): void {
    var CurrentDate = new Date();

    var keys = [
      { key: 'cliente', width: 35, style: { font: { name: 'Calibri' } } },
      { key: 'contrato_id', width: 25 },
      { key: 'cil', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'municipio', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'cliente_morada', width: 20, style: { font: { name: 'Calibri' } } },
    ];

    var Cols = ["Cliente", "Contrato", "Cil", "Município", "Morada"]
    var title = 'Lista de clientes sem rota';
    var nameFile = "Lista de clientes sem rota -" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    this.excelService.excels(this.items, nameFile, this.DadosEmpresa.logotipo, keys, Cols, title, 5, 5, 30, 3)
  }

}
