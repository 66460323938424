import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-create-or-edit-os-tipo',
  templateUrl: './create-or-edit-os-tipo.component.html',
  styleUrls: ['./create-or-edit-os-tipo.component.css']
})
export class CreateOrEditOsTipoComponent implements OnInit {




  @Input() modal: string = "modalCreateOrEdit";
  @Input() title: string = "Registar";
  @Input() tipos: any;

  private classificacoes: any = [];
  private categorias: any = [];
  private triggers: any = [];
  private dropdownSettingsTriggers:Object={}
  private departamentoArray: any = [];
  triggersConsequencia:any[]=[]
  consequenciaArray:any[]=[]
  dropdownSettingsTriggersConsequencia={}
  submitted = false;
  formErrors: any;
  private loading: boolean = false;
  private ver: boolean = true;
  private is_equipamento: boolean = false;
  private is_facturacao_posterior: boolean = false;
  private is_titulo: boolean = false;
  private is_local_instalacao: boolean = false
  private is_facturacao_antecipada: boolean = false;
  private is_cliente: boolean = false;
  private triggerArray:any=[]
  triggerConsequenciaArray:any[]=[]
  private is_active_cliente: boolean = false;

  private is_sintoma: boolean = false;
  private is_causa: boolean = false;
  private is_leitura: boolean = false;
  private is_leitura_contador: boolean = false;

  @Input() simpleFormCreateOrEdit: FormGroup;

  @Output() private loadList = new EventEmitter<any>();


  constructor(private http: HttpService, private configService: ConfigService, private formBuilder: FormBuilder) {
    this.createForm();
  }

  ngOnInit() {

    this.getClassificacao();
    this.getCategoria();
    this.getTrigger();
    this.getTriggerConsequencia()
  }

  private getClassificacao() {
    this.http.call_get('ordemservico/classificacao/selectBox', null).subscribe(
      response => {
        this.classificacoes = Object(response).data;
      }
    );
  }

  private getCategoria() {
    this.http.call_get('ordemservico/categoria/selectBox', null).subscribe(
      response => {
        this.categorias = Object(response).data;
      }
    );
  }

  onItemSelectDepartamento(item: any) {
    this.departamentoArray.push(item);
    this.triggerArray=this.simpleFormCreateOrEdit.value.trigger_id.map(item=>item.id)
    console.log('1',this.simpleFormCreateOrEdit.value)
  }
  onSelectAllDepartamentos(items: any) {
    this.departamentoArray = items;
  }

  onItemSelectConsequencia(item: any) {
    this.consequenciaArray.push(item);
    this.triggerConsequenciaArray=this.simpleFormCreateOrEdit.value.ot_trigger_consequencia_id.map(item=>item.id)
    console.log('2',this.simpleFormCreateOrEdit.value)
  }
  onSelectAllConsequencia(items: any) {
    this.consequenciaArray = items;
  }

  private getTrigger() {
    this.http.call_get('ordemservico/trigger/selectBox', null).subscribe(
      response => {
        this.triggers = Object(response).data;
        this.dropdownSettingsTriggers = {
          singleSelection: false,
          idField: 'id',
          textField: 'descricao',
          selectAllText: 'Seleccione Todos',
          unSelectAllText: 'DeSeleccionar Todos',
          itemsShowLimit: 10,
          allowSearchFilter: true
        };
      }
    );
  }

   getTriggerConsequencia() {
    this.http.call_get('ordemservico/trigger-consequencia/selectBox', null).subscribe(
      response => {
        this.triggersConsequencia = Object(response).data;
        this.dropdownSettingsTriggersConsequencia = {
          singleSelection: false,
          idField: 'id',
          textField: 'descricao',
          selectAllText: 'Seleccione Todos',
          unSelectAllText: 'DeSeleccionar Todos',
          itemsShowLimit: 10,
          allowSearchFilter: true
        };
      }
    );
  }

  createForm() {
    this.simpleFormCreateOrEdit = this.formBuilder.group({
      index: [{ value: null, disabled: true }],
      descricao: [null, Validators.required],
      classificacao_id: [null, Validators.required],
      categoria_id: [null],
      centro_custo: [null],
      sla: [null],
      equipamento: [false],
      facturacao_posterior: [false],
      assume_titulo: [false],
      facturacao_antecipada: [false],
      leitura: [false],
      local_instalacao: [false],
      leitura_contador: [false],
      cliente: [false],
      sintoma: [false],
      trigger_id: [null],
      causa: [false],
      ot_trigger_consequencia_id:[null]
    })
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.simpleFormCreateOrEdit.controls;
  }

  onReset() {
    this.submitted = false;
    //this.simpleFormCreateOrEdit.reset();
    this.createForm();
  }

  private setEquipamento(): void {
    //this.is_equipamento = !this.is_equipamento;

    this.simpleFormCreateOrEdit.patchValue({
      is_equipamento: !this.is_equipamento
    });

  }

  private setFacturacaoPosterior(): void {

    this.simpleFormCreateOrEdit.patchValue({
      is_facturacao_posterior: !this.is_facturacao_posterior
    });

  }

  private setAssumeTitulo(): void {

    this.simpleFormCreateOrEdit.patchValue({
      is_titulo: !this.is_titulo
    });

  }

  private setFacturacaoAntecipada(): void {

    this.simpleFormCreateOrEdit.patchValue({
      is_facturacao_antecipada: !this.is_facturacao_antecipada
    });

  }

  private setLeitura(): void {

    this.simpleFormCreateOrEdit.patchValue({
      is_leitura: !this.is_leitura
    });

  }
  private setLocalInstalacao(): void {

    this.simpleFormCreateOrEdit.patchValue({
      is_local_instalacao: !this.is_local_instalacao
    });

  }

  private setLeituraContador(): void {

    this.simpleFormCreateOrEdit.patchValue({
      is_leitura_contador: !this.is_leitura_contador
    });

  }

  private setCliente(): void {

    this.simpleFormCreateOrEdit.patchValue({
      is_cliente: !this.is_cliente
    });

    this.is_active_cliente = !this.is_active_cliente;

  }

  private setSintoma(): void {

    this.simpleFormCreateOrEdit.patchValue({
      is_sintoma: !this.is_sintoma
    });

  }

  private setCausa(): void {

    this.simpleFormCreateOrEdit.patchValue({
      is_causa: !this.is_causa
    });
  }

  getSelectedTrigger(tipo_id,trigger_id){
      let unique={}
      this.http.call_get(`ordemservico/os_tipo_trigger/${tipo_id}`)
      .subscribe(
            response => {
                console.log("get trigger datas=>",Object(response).data)
                 let data=Object(response).data
                let newArray=[]
                for (let i=0 ;i<this.triggers.length;i++) {
                  console.log(this.triggers)
                    for (let y=0;y<data.length;y++) {
                       if(this.triggers[i].id==data[y].ot_trigger_id){

                          if(!unique[this.triggers[i].id]){
                            newArray.push(this.triggers[i])
                            unique[this.triggers[i].id]=this.triggers[i].id
                          }
                        }
                    }

                }

                this.simpleFormCreateOrEdit.patchValue({
                     trigger_id:newArray
                  })


            }
        )
  }

  getSelectedTriggerConsequencia(tipo_id,trigger_id){
    let unique={}
    this.http.call_get(`ordemservico/os_tipo_trigger/${tipo_id}`)
    .subscribe(
          response => {
              console.log("get trigger datas=>",Object(response).data)
               let data=Object(response).data
              let newArray=[]
              for (let i=0 ;i<this.triggersConsequencia.length;i++) {
                console.log(this.triggersConsequencia)
                  for (let y=0;y<data.length;y++) {
                     if(this.triggersConsequencia[i].id==data[y].ot_trigger_consequencia_id){
                        if(!unique[this.triggersConsequencia[i].id]){
                          newArray.push(this.triggersConsequencia[i])
                          unique[this.triggersConsequencia[i].id]=this.triggersConsequencia[i].id
                        }
                      }
                  }

              }
              console.log('newArray',newArray)
              this.simpleFormCreateOrEdit.patchValue({
                    ot_trigger_consequencia_id: newArray
                })


          }
      )
}

  hendleOsTipoTrigger(
        is_delete=0,
        os_tipo_id,
        formulario
    ){
        console.log('trigger_id',this.simpleFormCreateOrEdit.value.trigger_id)
        let ot_trigger_ids=(this.simpleFormCreateOrEdit.value.trigger_id || []).map(item=>item.id)
        let consequencia_ids=(this.simpleFormCreateOrEdit.value.ot_trigger_consequencia_id || []).map(item=>item.id)
        console.log(this.triggerArray,this.triggerConsequenciaArray)
        console.log('trigger_id',ot_trigger_ids,consequencia_ids,this.simpleFormCreateOrEdit.value)

        this.http.__call('ordemservico/os_tipo_trigger', {
          is_delete,
          ot_trigger_id:ot_trigger_ids,
          ot_trigger_consequencia_id: consequencia_ids,
          os_tipo_id
        }).pipe(first()).subscribe(
          response => {
              console.log(response)
              this.onReset()
              this.triggerArray=[]
              this.triggerConsequenciaArray=[]
          })
  }

  onSubmit() {

    this.submitted = true;

    // parar aquei se o formulário for inválido
    if (this.simpleFormCreateOrEdit.invalid) {
      return;
    }
    this.loading = true;
    const index = this.simpleFormCreateOrEdit.getRawValue().index;
    // TODO: usado para fazer a requisição com a api de criação de objsct or update
    const uri = (index === null ? 'ordemservico/ostipo/register' : 'ordemservico/ostipo/update/' + index);
    this.createOrEdit(uri, this.simpleFormCreateOrEdit, (index === null ? true : false));

  }

  createOrEdit(uri: any, formulario: FormGroup, isCreate: boolean) {

    // TODO: usado para fazer a requisição com a api de criação de object
    this.http.__call(uri, formulario.value).pipe(first()).subscribe(
      response => {
        this.submitted = false;
        this.loading = false;
        if (isCreate) {
          console.log('isCreate',isCreate)
        }

        if (Object(response).code == 200) {
          this.loadList_item(Object(response).data);
            console.log('os_tipo',Object(response).data)
            this.hendleOsTipoTrigger(
              0,
              Object(response).data.id,
              formulario
            )
        }
      },
      error => {
        this.submitted = false;
        this.loading = false;
      });
  }

  initForm(){
    this.triggersConsequencia=[]
    this.triggers=[]
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {

    if (this.tipos !== undefined) {

      this.title = "Editar";
      //this.onReset()


      this.simpleFormCreateOrEdit.patchValue({
        index: this.tipos.id,
        descricao: this.tipos.descricao,
        categoria_id: this.tipos.categoria_id,
        classificacao_id: this.tipos.classificacao_id,
        equipamento: this.tipos.equipamento,
        cliente: this.tipos.cliente,
        sintoma: this.tipos.sintoma,
        causa: this.tipos.causa,
        centro_custo: this.tipos.centro_custo,
        sla: this.tipos.sla,
        facturacao_posterior: this.tipos.facturacao_posterior,
        assume_titulo: this.tipos.is_titulo,
        facturacao_antecipada: this.tipos.facturacao_antecipada,
        leitura: this.tipos.leitura,
        trigger_id: this.tipos.trigger_id,
        leitura_contador: this.tipos.leitura_contador
      });
      this.getSelectedTrigger(this.tipos.id,this.tipos.trigger_id)
      this.getSelectedTriggerConsequencia(this.tipos.id,this.tipos.trigger_id)
      this.is_equipamento = this.tipos.equipamento,
        this.is_cliente = this.tipos.cliente,
        this.is_causa = this.tipos.causa,
        this.is_sintoma = this.tipos.sintoma,
        this.is_facturacao_posterior = this.tipos.facturacao_posterior,
        this.is_titulo = this.tipos.is_titulo,
        this.is_facturacao_antecipada = this.tipos.facturacao_antecipada,
        this.is_leitura = this.tipos.leitura,
        this.is_leitura_contador = this.tipos.leitura_contador

    } else {
      //this.onReset()
      //this.initForm()
      this.title = "Registar";

    }
  }

  public loadList_item(tipos) {
    this.loadList.emit(tipos);
  }











}
