import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpEvent, HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-agendar-os-massiva',
  templateUrl: './agendar-os-massiva.component.html',
  styleUrls: ['./agendar-os-massiva.component.css']
})
export class AgendarOsMassivaComponent implements OnInit {
  

  @ViewChild("search") search;

  @Input() title: string = "Ordem de Trabalho";
  @Input() ordem_sigla: string = "OT";

  private departamentos: any = [];
  private duracoes: any = [];
  private funcoes: any = [];
  private artigos: any = [];
  private produtoCategorias: any = [];
  private recursos: any = [];
  private tipos: any = [];
  private classificacoes: any = [];
  
  private colaboradores: any = [];
  private colaborador = {
    coloborador_nome: null,
    colaborador_id: null
  }

  private contas: any = [];
  private contratos: any = [];
  private tarefaSelectBox: any = [];
  private tarefasToAdd: any = [];

  private dayInicio: any;
  private hourInicio: any;
  private minutesInicio: any;
  private timeInicio: any;

  private isFinalizar: boolean = true;
  private isAddTarefa: boolean = true;
  private isDisabled: boolean = false;
  private is_sintoma: boolean = false;
  private is_causa: boolean = false;
  private validateButton: any = null;
  private is_responsavel: boolean = false;
  private qtdPrevistaDecimal: boolean = true;
  private view_client = true;
  dropdownSettings = {};
  dropdownSettingsSintomas = {};

  routerParamId: any;
  routerParamAgendamentoId: any;

  private agendamentodayInicio: any;
  private agendamentohourInicio: any;
  private agendamentominutesInicio: any;
  private hourInicioAgendamento: any;
  private minutesInicioAgendamento: any;

  private associacao_cliente = null;

  private agendamento = {
    id: null,
    data_previsao_inicio: null,
    data_previsao_fim: null,
    dif_dias_prevista: null,
    dif_horas_prevista: null,
    dif_minutos_prevista: null,
    descricao: null,
    historico_agendamento_id: null,
    codigo_agendamento: null

  }

  private recursosHumanos = {
    departamento_id: null,
    qtd_colaborador: null,
    funcao_id: null,
    colaborador_id: null,
    colaborador_nome: null,
    qtdRh: null
  }

  private consumiveis = {
    armazem_id: null,
    artigo_id: null,
    qtdArtigo: null,
    qtd_stock: null,
    categoria_id: null,
    reserva: null,
    unidade: null,
    tarefa_plano_id: null,
    descricao_material: null,
    qtd_stock_reserva: null
  }

  private tarefa = {
    id: null,
    descricao: null,
    fim_previsto: null,
    inicio_previsto: null
  }

  private ordemServico = {
    descricao: null,
    descricao_old: null,
    cliente_nome: null,
    success_ordem_servico: false,
    sintoma_id: null,
    classificacao_id: null,
    classificacao_descricao: null,
    prioridade_id: null,
    prioridade_descricao: null,
    tipo_descricao: null,
    responsavel_nome: null,
    success_ot_sem_agendamento: false,
    data_prevista: null,
    agendamentoGerado: null,
    codigo: null,
    titulo: null
  }

  private causasArray: any = [];
  private sintomasArray: any = [];
  private departamentoArray: any = [];

  fileAgendamento:any=[]
  fileList:any=[]
  progress: any = 0;

  private pagination = {
    start: 1,
    end: 10,
    search: null
  };

  currtentPage: number

  constructor(private auth: AuthService, private http: HttpService, private route: ActivatedRoute, private router: Router, private configService: ConfigService) {

  }

  ngOnInit() {
    this.getRouterParamId();

  }

  private carregando = {
    nivel_2: 'Seleccione o equipamento',
    funcao: 'Seleccione a função',
    material: 'Seleccione o material',
    categoria: 'Seleccione a categoria',
    tipo: 'Seleccione o tipo',
  }

  public getRouterParamId() {

    this.routerParamId = this.route.snapshot.params.id;
      this.getArmazens();
      this.getOrdemById(this.routerParamId);
      this.getInicioFimPrevAgendamento(this.routerParamId )
    
  }

  private getOrdemById(id) {
    this.http.call_get('ordemservico/get-info-ordem-by-id/' + id, null).subscribe(
      response => {

        if(Object(response).data == undefined){
          this.router.navigate(['/ordemTrabalho/listagem']);
        }

        this.ordemServico.codigo = Object(response).data.codigo;
        this.ordemServico.titulo = Object(response).data.titulo;
      }
    );
  }

  
  private getTarefasByAgendamento(id) {
    this.http.call_get('ordemservico/tarefas-aberta-by-ordem/' + id, null).subscribe(
      response => {

        this.tarefaSelectBox = Object(response).data;
        
      }
    );
  }


  private getDepartamento() {
    this.http.call_get('departamento/selectBox', null).subscribe(
      response => {
        this.departamentos = Object(response).data;
      }
    );

    this.getDuracao();
  }

  private getDuracao() {

    this.http.call_get('duracao/selectBox', null).subscribe(
      response => {
        this.duracoes = Object(response).data;
      }
    );
  }

  private getFuncaoByDepartamento() {
    this.carregando.funcao = 'Carregando...';
    this.http.call_get('funcao/funcaoesByDepartamento/' + this.recursosHumanos.departamento_id, null).subscribe(
      response => {
        this.funcoes = Object(response).data;
        this.carregando.funcao = 'Seleccione a função';
      }
    );
  }

  private getColaboradoresByFuncao() {

    this.colaboradores = [];

    if (this.recursosHumanos.funcao_id != null) {
      this.configService.loaddinStarter('start');
      this.http.__call('colaborador/colaboradoresByFuncao', {
        start: 1,
        end: 10,
        search: null,
        funcao_id: this.recursosHumanos.funcao_id
      }).subscribe(
        response => {
          this.colaboradores = Object(response).data.data;
          this.configService.loaddinStarter('stop');
        }
      );

    }
  }

  public armazens = [];

  public getArmazens() {

    this.http.__call("armazem/selectBox", null).subscribe((response) => {
      this.armazens = Object(response).data;

    });
  }


  private getCategoriaByArmazem() {

    this.consumiveis.qtd_stock = 0;
    this.consumiveis.categoria_id = null,
      this.produtoCategorias = []
    this.artigos = [];

    this.carregando.categoria = 'Carregando...';
    this.http
      .__call("categoria/by/armazem", {
        armazem_id: this.consumiveis.armazem_id
      }).subscribe((res: any) => {
        this.produtoCategorias = Object(res).data;
        this.carregando.categoria = 'Seleccione a categoria';
      });
  }

  view_material = false;

  private getMaterialByCategoriaArmazem() {

    this.consumiveis.qtd_stock = 0;
    this.consumiveis.artigo_id = null;
    this.artigos = [];

    this.carregando.material = 'Carregando...';
    this.http
      .__call("material/by/categoria-armazem", {
        armazem_id: this.consumiveis.armazem_id,
        categoria_id: this.consumiveis.categoria_id
      }).subscribe((res: any) => {
        this.artigos = Object(res).data;
        this.carregando.material = 'Seleccione o material';
      });
  }

  private setMaterial(item) {
    this.consumiveis.artigo_id = item.id;
    this.consumiveis.descricao_material = item.codigo_produto +' - '+ item.descricao;
    this.view_material = false;

  }

  //SEARCH MATERIAL

  private searchMaterial() {

    this.consumiveis.unidade = null;
    this.consumiveis.qtd_stock = 0;
    this.consumiveis.qtd_stock_reserva = 0;
    this.consumiveis.artigo_id = null;

    this.artigos = [];

    if (this.consumiveis.categoria_id == null) {
      this.consumiveis.descricao_material = null,
        this.view_material = false
    }

    this.view_material = true;
    this.http.__call('search-material/by/categoria-armazem', {
      start: 1,
      end: 10,
      armazem_id: this.consumiveis.armazem_id,
      categoria_id: this.consumiveis.categoria_id,
      search: this.consumiveis.descricao_material
    }).subscribe(
      response => {

        this.artigos = Object(response).data.data;

      }
    );
  }

  public consumiveisSelecionados = [];
  public recursosSelecionados = [];
  public resTarefasPrev = [];
  public tarefasSelecionados = [];
  public qtd_stock = 0;

  private getQtdProduto() {
    //Quantidade de artigo em Stock
    this.getQtdProdutoReserva();

    this.consumiveis.qtd_stock = 0;

    if (this.consumiveis.artigo_id != null) {

      this.http
        .__call("stock/existencia/armazem/qtd_produto", {
          armazem_id: this.consumiveis.armazem_id,
          artigo_id: this.consumiveis.artigo_id
        })
        .subscribe((response) => {
          this.consumiveis.qtd_stock = Object(response).data[0].quantidade;


        });

    }
    //-------
  }

  private getQtdProdutoReserva() {

    this.consumiveis.qtd_stock_reserva = 0;

    if (this.consumiveis.artigo_id != null) {

      this.http
        .__call("stock-reserva/existencia/armazem/qtd_produto", {
          armazem_id: this.consumiveis.armazem_id,
          artigo_id: this.consumiveis.artigo_id
        })
        .subscribe((response) => {
          this.consumiveis.qtd_stock_reserva = Object(response).data[0].quantidade;

        });

    }
    //-------
  }

  private getUnidadeByMaterial() {

    this.consumiveis.unidade = null;

    if (this.consumiveis.artigo_id != null) {

      this.http.call_get("unidade-by-material/" + this.consumiveis.artigo_id, null)
        .subscribe((response) => {
          this.consumiveis.unidade = Object(response).data.unidade;
        });

    }
    //-------
  }

  private getQtColaborador() {

    this.http
      .__call("colaborador/get-total", {
        funcao_id: this.recursosHumanos.funcao_id
      })
      .subscribe((response) => {

        this.recursosHumanos.qtd_colaborador = Object(response).data.total;


      });
    //-------
  }

  private isNumericPercentagem(value: any): boolean {

    value = this.consumiveis.qtdArtigo;

    value = !isNaN(value - parseFloat(value));
    this.qtdPrevistaDecimal = value;

    if (!this.qtdPrevistaDecimal) {
      this.configService.showAlert("Número decimal inválido! Quantidade(EST)! Ex. 1 ou 1.5", 'alert-danger', true);
    }
    return !isNaN(value - parseFloat(value));

  }

  public consumiveisToDelete = [];
  public tarefasToDelete = [];
  public colaboradoresToDelete = [];

  public adicionarArtigos() {
    var validar = 0;
    var nome_artigo = "";
    var codigo_artigo = "";
    var armazem_nome = "";
    var tarefa_nome = "";
    var categoria_descricao = "";

    if (this.consumiveis.qtdArtigo == null) {
      this.configService.showAlert('Digite a quantidade a sair', "alert-danger", true);
    } else if (!this.qtdPrevistaDecimal) {
      this.configService.showAlert("Número decimal inválido! Separe por .", 'alert-danger', true);
    } else if (this.consumiveis.qtdArtigo == 0) {
      this.configService.showAlert('Quantidade não pode ser menor que 1', "alert-danger", true);
    } else if (this.consumiveis.qtdArtigo > this.consumiveis.qtd_stock) {
      this.configService.showAlert('Quantidade a sair não pode ser superior a quantidade em Stock', "alert-danger", true);
    } else {

      this.artigos.forEach((element) => {
        if (element.id == this.consumiveis.artigo_id) {
          nome_artigo = element.descricao;
          codigo_artigo = element.codigo_produto;
          armazem_nome = element.armazem_nome;
          categoria_descricao = element.categoria_descricao;
        }
      });

      this.tarefaSelectBox.forEach((element) => {
        if (element.tarefa_plano_id == this.consumiveis.tarefa_plano_id) {
          tarefa_nome = element.descricao;
        }
      });

      var artigo = {
        armazem_id: this.consumiveis.armazem_id,
        armazem_nome: armazem_nome,
        categoria_descricao: categoria_descricao,
        nome_artigo: nome_artigo,
        codigo_produto: codigo_artigo,
        artigo_id: this.consumiveis.artigo_id,
        quantidade: this.consumiveis.qtdArtigo,
        qtd_stock: this.qtd_stock,
        tarefa_plano_id: this.consumiveis.tarefa_plano_id,
        tarefa: tarefa_nome,
        agendamento_id: null,
        unidade: this.consumiveis.unidade
      };

      if (this.consumiveisSelecionados.length >= 1) {
        for (let index = 0; index < this.consumiveisSelecionados.length; index++) {
          const l = this.consumiveisSelecionados[index];
          if (l.artigo_id == artigo.artigo_id) {
            this.consumiveisSelecionados.splice(index, 1);
            this.consumiveisSelecionados.splice(index, 0, artigo);
            validar = 1;
          }
        }
        if (validar == 0) {
          this.consumiveisSelecionados.push(artigo);
        }
      } else {
        this.consumiveisSelecionados.push(artigo);
      }

      for (let index = 0; index < this.consumiveisToDelete.length; index++) {
        if (artigo.artigo_id === this.consumiveisToDelete[index].artigo_id) {
          this.consumiveisToDelete.splice(index, 1);
        }
      }

      this.tarefaSelectBox = [];
      this.artigos = [];
      this.consumiveis.tarefa_plano_id = null;
      this.consumiveis.descricao_material = null;
      this.consumiveis.artigo_id = null;
      this.consumiveis.qtdArtigo = null;
      this.consumiveis.qtd_stock = null;
      this.consumiveis.qtd_stock_reserva = null;
      this.consumiveis.unidade = null

    }
  }

  public adicionarTempoPrev() {
    var validar = 0;
  
    if (this.colaborador.colaborador_id == null) {
      this.configService.showAlert('Selecione o Colaborador', "alert-danger", true);
    } else if (this.tarefa.inicio_previsto == null) {
      this.configService.showAlert('Digite a Data início prevista', "alert-danger", true);
    } else if (this.tarefa.fim_previsto == null) {
      this.configService.showAlert("Digite a Data fim prevista", 'alert-danger', true);
    } else if (this.tarefa.inicio_previsto >= this.tarefa.fim_previsto) {
      this.configService.showAlert("A data fim previsto não pode ser igual ou inferior a data de início previsto - Colaborador(a): " + this.colaborador.coloborador_nome, 'alert-danger', true);
    }  else {
     
     
      var tarefaList = {
        ordem_trabalho_id: this.routerParamId,
        nome_colaborador: this.colaborador.coloborador_nome,
        inicio_previsto: this.tarefa.inicio_previsto.replace('T', ' '),
        fim_previsto: this.tarefa.fim_previsto.replace('T', ' '),
        colaborador_id: this.colaborador.colaborador_id
      };

      if (this.tarefasSelecionados.length >= 1) {
        /* for (let index = 0; index < this.tarefasSelecionados.length; index++) {
          const l = this.tarefasSelecionados[index];
          if ((l.tarefa_id == tarefaList.tarefa_id) && (tarefaList.colaborador_id == l.colaborador_id)) {
            this.tarefasSelecionados.splice(index, 1);
            this.tarefasSelecionados.splice(index, 0, tarefaList);
            validar = 1;
          }
        } */
        if (validar == 0) {
          this.insertTempoPrev(tarefaList);
          this.tarefasSelecionados.push(tarefaList);
        }
      } else {

        this.insertTempoPrev(tarefaList)
        this.tarefasSelecionados.push(tarefaList);
      }

  }
  }

  deleteTarefa(item: any) {

    var validar = 0;

    var deletedTarefas = {
      tarefa_colaborador_id: item.tarefa_colaborador_id,
      colaborador_id: item.colaborador_id,
    };

    if (this.tarefasToDelete.length >= 1) {
      for (let index = 0; index < this.tarefasToDelete.length; index++) {
        const l = this.tarefasToDelete[index];
        if ((l.tarefa_colaborador_id == deletedTarefas.tarefa_colaborador_id) && (l.colaborador_id == deletedTarefas.colaborador_id)) {
          this.tarefasToDelete.splice(index, 1);
          this.tarefasToDelete.splice(index, 0, deletedTarefas);
          validar = 1;
        }
      }
      if (validar == 0) {
        this.updateTarefa(item.tarefa_colaborador_id)
        this.tarefasToDelete.push(deletedTarefas);
      }
    } else {
      this.updateTarefa(item.tarefa_colaborador_id)
      this.tarefasToDelete.push(deletedTarefas);
    }

    for (let i = 0; i < this.tarefasSelecionados.length; ++i) {
      if (this.tarefasSelecionados[i].tarefa_id === item.tarefa_id) {
        this.tarefasSelecionados.splice(i, 1);
      }
    }
  }

  public adicionarRecursos(item) {
    var validar = 0;
    var nome_funcao = "";
    var nome_departamento = "";


    this.funcoes.forEach((element) => {
      if (element.id == this.recursosHumanos.funcao_id) {
        nome_funcao = element.descricao;
      }
    });

    this.departamentos.forEach((element) => {
      if (element.id == this.recursosHumanos.departamento_id) {
        nome_departamento = element.descricao;
      }
    });

    this.departamentos.forEach((element) => {
      if (element.id == this.recursosHumanos.departamento_id) {
        nome_departamento = element.descricao;
      }
    });

    var recursos = {
      nome_colaborador: item.colaboradorNome,
      colaborador_id: item.id,
      nome_departamento: nome_departamento,
      nome_funcao: nome_funcao,
      agendamento_id: null,
      data_prevista_inicio: null,
      is_responsavel: this.is_responsavel
    };

    this.is_responsavel = false;

    if (this.recursosSelecionados.length >= 1) {
      for (let index = 0; index < this.recursosSelecionados.length; index++) {
        const l = this.recursosSelecionados[index];
        if (l.colaborador_id == recursos.colaborador_id) {
          this.recursosSelecionados.splice(index, 1);
          this.recursosSelecionados.splice(index, 0, recursos);
          validar = 1;
        }
      }
      if (validar == 0) {
        this.recursosSelecionados.push(recursos);
      }
    } else {
      this.recursosSelecionados.push(recursos);
    }

    for (let index = 0; index < this.colaboradoresToDelete.length; index++) {
      if (recursos.colaborador_id === this.colaboradoresToDelete[index].colaborador_id) {
        this.colaboradoresToDelete.splice(index, 1);
      }
    }


  }

  private selectedResponsavel(id) {

    this.recursosHumanos.colaborador_id = id;
    this.colaboradores.forEach(element => {
      if (this.recursosHumanos.colaborador_id == element.id) {
        this.recursosHumanos.colaborador_id = element.id;
        this.is_responsavel = !this.is_responsavel;
      }
    });

  }


  deleteRow(item: any) {

    var validar = 0;

    var deletedConsumiveis = {
      nome_artigo: item.nome_artigo,
      artigo_id: item.artigo_id,
    };

    if (this.consumiveisToDelete.length >= 1) {
      for (let index = 0; index < this.consumiveisToDelete.length; index++) {
        const l = this.consumiveisToDelete[index];
        if (l.artigo_id == deletedConsumiveis.artigo_id) {
          this.consumiveisToDelete.splice(index, 1);
          this.consumiveisToDelete.splice(index, 0, deletedConsumiveis);
          validar = 1;
        }
      }
      if (validar == 0) {
        this.consumiveisToDelete.push(deletedConsumiveis);
      }
    } else {
      this.consumiveisToDelete.push(deletedConsumiveis);
    }

    for (let i = 0; i < this.consumiveisSelecionados.length; ++i) {
      if (this.consumiveisSelecionados[i].artigo_id === item.artigo_id) {
        this.consumiveisSelecionados.splice(i, 1);
      }
    }
  }

  deleteRecursoRow(item: any) {

    var validar = 0;

    var deletedRow = {
      nome_colaborador: item.nome_colaborador,
      colaborador_id: item.colaborador_id,
    };

    if (this.colaboradoresToDelete.length >= 1) {
      for (let index = 0; index < this.colaboradoresToDelete.length; index++) {
        const l = this.colaboradoresToDelete[index];
        if (l.colaborador_id == deletedRow.colaborador_id) {
          this.colaboradoresToDelete.splice(index, 1);
          this.colaboradoresToDelete.splice(index, 0, deletedRow);
          validar = 1;
        }
      }
      if (validar == 0) {
        this.colaboradoresToDelete.push(deletedRow);
      }
    } else {
      this.colaboradoresToDelete.push(deletedRow);
    }

    for (let i = 0; i < this.recursosSelecionados.length; ++i) {
      if (this.recursosSelecionados[i].colaborador_id === item.colaborador_id) {
        this.recursosSelecionados.splice(i, 1);
      }
    }

    this.is_responsavel = false;
  }

  selectToAddTarefas(item: any) {
    this.configService.loaddinStarter('start');
      this.colaborador.coloborador_nome = item.nome_colaborador;
      this.colaborador.colaborador_id = item.colaborador_id;

      this.http.__call('ordemservico/tarefa-by-colaborador-ordem-trabalho' , {
        ordem_trabalho_id : this.routerParamId,
        colaborador_id : item.colaborador_id
      }).subscribe(
        response => {
  
          this.tarefasSelecionados = Object(response).data;
          
          this.configService.loaddinStarter('stop');
        }
      );

  
  }

  private reloadOrdemServico() {
    location.reload();
  }

  private validateColaboradores() {

    var next = 0;
    var start = 1;
    var validar = 0;

    if (this.recursosSelecionados.length == 0) {
      this.configService.showAlert("Adiciona pelo menos um Colaborador na lista", 'alert-danger', true);
    } else if (this.recursosSelecionados.length >= 1) {
      for (let index = 0; index < this.recursosSelecionados.length; index++) {
        const l = this.recursosSelecionados[index];

        if (l.is_responsavel == true) {
          validar = ++start;
        }

        if (validar > 2) {
          this.configService.showAlert("Seleccione apenas um Responsável", 'alert-danger', true);
          next = 1;
        } else if (validar == 0) {
          this.configService.showAlert("Seleccione um Responsável", 'alert-danger', true);
          next = 1;
        }
      }
    
      if (next == 0) {
        const nextElement: HTMLElement = document.querySelector('#next-step-agendamento')
        nextElement.click()
      }

    }

  }

  getInicioFimPrevAgendamento(id){
    
    
    var dates_prevista_inicio = [];
    var dates_prevista_fim = [];
   
    this.http.call_get('ordemservico/data-inicio-fimPrevOrdem/' + id, null).subscribe(
      response => {

        this.resTarefasPrev = Object(response).data;
      }
    );

    for (let index = 0; index < this.resTarefasPrev.length; index++) {
      dates_prevista_inicio.push(new Date(this.resTarefasPrev[index].inicio_previsto))
      dates_prevista_fim.push(new Date(this.resTarefasPrev[index].fim_previsto))
    }

    var maxDate = Math.max.apply(null, dates_prevista_fim)
    var mimDate = Math.min.apply(null, dates_prevista_inicio)
    // convert back to date object
    maxDate = new Date(maxDate)
    mimDate = new Date(mimDate)


    this.agendamento.data_previsao_inicio = this.formatDate(mimDate);
    this.agendamento.data_previsao_fim = this.formatDate(maxDate);
    

    this.agendamentoAddInicioPrev();
    this.agendamentoAddFimPrev();

    
  }

  private formatDate(date) {

    var dia = date.getDate();
    var ano = date.getFullYear();
    var hora = date.getHours();
    var minuto = date.getMinutes();
    var mes = date.getMonth() + 1

    return (ano + "-" + (mes.toString().length < 2 ? "0" + mes : mes) + "-" + (dia.toString().length < 2 ? "0" + dia : dia) + "T" + (hora.toString().length < 2 ? "0" + hora : hora) + ":" + (minuto.toString() < 2 ? "0" + minuto : minuto));

  }

  agendamentoAddInicioPrev() {

   
    var replace = this.agendamento.data_previsao_inicio.replace('T', ' ');
    var date = new Date(replace);

    this.agendamentodayInicio = date.getDay();
    this.agendamentohourInicio = date.getHours();
    this.agendamentominutesInicio = date.getMinutes();
    
  }

  agendamentoAddFimPrev() {

    var replace1 = this.agendamento.data_previsao_fim.replace('T', ' ');
    
    if (this.agendamento.data_previsao_inicio == null || this.agendamento.data_previsao_fim == "") {
      this.configService.showAlert("Digite a Data prevista início", 'alert-danger', true);
    } else if (this.agendamento.data_previsao_fim == null || this.agendamento.data_previsao_fim == "") {
      this.configService.showAlert("Digite a Data prevista fim", 'alert-danger', true);
    } else if (this.agendamento.data_previsao_inicio >= this.agendamento.data_previsao_fim) {
      this.configService.showAlert("A data fim previsto não pode ser igual ou inferior a date de início previsto", 'alert-danger', true);
    } else {

      var date1 = new Date(replace1);

      var dayFim = date1.getDay();
      var hourFim = date1.getHours();
      var minutesFim = date1.getMinutes();

      var data = (this.agendamentohourInicio * 3600) + (this.agendamentominutesInicio * 60);
      var data2 = (hourFim * 3600) + (minutesFim * 60);
      var diferenca = data2 - data;

      this.hourInicioAgendamento = Math.floor(diferenca / 3600);
      this.minutesInicioAgendamento = Math.floor((diferenca - (this.hourInicioAgendamento * 3600)) / 60);


      var condincao = this.hourInicioAgendamento + '' + (this.minutesInicioAgendamento.toString().length < 2 ? '0' + '' + this.minutesInicioAgendamento : this.minutesInicioAgendamento);

      var diffHoras = (parseInt(condincao) > 759 ? parseInt(this.hourInicioAgendamento) - 8 : this.hourInicioAgendamento)
      var diffDias = dayFim - this.agendamentodayInicio;
      diffDias = (parseInt(condincao) > 759 ? diffDias + 1 : diffDias)

      this.agendamento.dif_dias_prevista = parseInt(diffDias.toString().replace("-", ""));
      this.agendamento.dif_horas_prevista = diffHoras;
      this.agendamento.dif_minutos_prevista = this.minutesInicioAgendamento

    }

   

  }

  private imprimirAgendamento(id) {
    this.configService.imprimirAgendamento(id, "2ª Via", "imprimir")
  }

  private insertTempoPrev(tarefaList) {

    this.tarefa.inicio_previsto = null;
    this.tarefa.fim_previsto = null;

      this.http.__call('ordemservico/tarefa-colaborador-store', {
        tarefa_colaborador: tarefaList
      }).subscribe(
        res => {
          if (Object(res).code == 200) {
           this.selectToAddTarefas(tarefaList)
           this.getInicioFimPrevAgendamento(this.routerParamId)
          } else {
            
          }
        }
      )

  }

  private updateTarefa(id) {

    this.http.__call('ordemservico/delete-tarefa-colaborador/' + id, null).subscribe(
      res => {
        if (Object(res).code == 200) {
         
        } else {
          
        }
      }
    )

}


  private guardarAgendamento() {

    if (this.agendamento.data_previsao_inicio == null || this.agendamento.data_previsao_inicio == "") {
      this.configService.showAlert("Digite a Data Início / Prevista", 'alert-danger', true);
    } else if (this.agendamento.data_previsao_fim == null || this.agendamento.data_previsao_fim == "") {
      this.configService.showAlert("Digite a Data Término / Prevista", 'alert-danger', true);
    } else if (this.agendamento.data_previsao_inicio >= this.agendamento.data_previsao_fim) {
      this.configService.showAlert("Data de início não pode ser superior ou igual a Data de término", 'alert-danger', true);

    } else if (this.agendamento.descricao == null || this.agendamento.descricao == "") {
      this.configService.showAlert("Digite a descrição do agendamento", 'alert-danger', true);

    } else {

      this.isFinalizar = false;
      this.isDisabled = true;
      this.configService.loaddinStarter('start');

      let uploadData = new FormData();

      uploadData.append("ordemServico", JSON.stringify(this.ordemServico));
      uploadData.append("routerParamId", JSON.stringify(this.routerParamId));
      uploadData.append("agendamento", JSON.stringify(this.agendamento));
      uploadData.append("recursosHumanos", JSON.stringify(this.recursosSelecionados));
      uploadData.append("consumiveis", JSON.stringify(this.consumiveisSelecionados));
      uploadData.append("causas", JSON.stringify(this.causasArray));
      uploadData.append("sintomas", JSON.stringify(this.sintomasArray));
      uploadData.append("departamentos", JSON.stringify(this.departamentoArray));
      for (let file of this.fileAgendamento) {
        uploadData.append("fileAgendamento", file.file);
      }
      for (let file of this.fileList) {
        uploadData.append("file", file.file);
      }
      
      this.http.$__call('ordemservico/agendamento/create',uploadData)
      .subscribe(
        (res: HttpEvent<any>) => {
      
         
          switch (res.type) {
            case HttpEventType.Sent:
              console.log('Request has been made!');
              break;
            case HttpEventType.ResponseHeader:
              console.log('Response header has been received!');
              break;
            case HttpEventType.UploadProgress:
              this.progress = Math.round(res.loaded / res.total * 100);
              break;
            case HttpEventType.Response:
              setTimeout(() => {
                this.progress = 0;
                this.fileList=[]
              }, 1500);
  
          }
          if(Object(Object(res).body).code) {
              if (Object(Object(res).body).code == 200) {
                this.isDisabled = true;
                this.validateButton = Object(Object(res).body).code

                this.configService.showAlert(Object(Object(res).body).message, "alert-success", true);
                
                this.ordemServico.success_ordem_servico = true;
                this.ordemServico.success_ot_sem_agendamento = true;
                this.ordemServico.agendamentoGerado = Object(Object(res).body).data.agendamentos.id

              } else {
                this.configService.showAlert(Object(Object(res).body).message, "alert-danger", true);
                this.isFinalizar = false;
                this.isDisabled = false;
              }
          }
          this.configService.loaddinStarter('stop');
        },
        error => {
          this.configService.loaddinStarter("stop");
          this.isFinalizar = false;
          this.isDisabled = false;
        }
      )

    }
  }

  private getColaboradores() {
    this.getAllColaboradores();
  }

  private getAllColaboradores() {
    this.colaboradores = [];

    this.configService.loaddinStarter('start');
    //this.pagination.search = (search == "" || search == null ? null : search);
    this.http.__call('colaborador/colaboradoresByFuncao', {
      start: 1,
      end: 10,
      search: (this.recursosHumanos.colaborador_nome == "" || this.recursosHumanos.colaborador_nome == null ? null : this.recursosHumanos.colaborador_nome),
      funcao_id: this.recursosHumanos.funcao_id
    }).subscribe(
      res => {
        this.colaboradores = Object(res).data.data;
        this.configService.loaddinStarter('stop');
      }
    );
  }


}
