import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { HttpService } from "src/app/providers/http/http.service";
import { ConfigService } from "src/app/providers/config/config.service";
import { AuthService } from "src/app/providers/auth/auth.service";
import { Router, ActivatedRoute } from "@angular/router";
const axios = require("axios");
import { environment } from "src/environments/environment";
import { saveAs } from "file-saver";
import { HttpEvent, HttpEventType } from "@angular/common/http";
import * as moment from "moment";
import Swal from "sweetalert2";
import { finalize } from "rxjs/operators";
import { ApiService } from "src/app/providers/http/api.service";
/* OPEN LAYERS */
import "ol/ol.css";
import GeoJSON from "ol/format/GeoJSON";
import Map from "ol/Map";
import VectorSource from "ol/source/Vector";
import View from "ol/View";
import XYZ from "ol/source/XYZ";
import { Stroke, Style } from "ol/style";
import { transform } from "ol/proj";
import { Tile, Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { bbox as bboxStrategy } from "ol/loadingstrategy";

import Overlay from "ol/Overlay";
import { createStringXY, toStringHDMS } from "ol/coordinate.js";
import { toLonLat } from "ol/proj.js";
import OSM from "ol/source/OSM";
import MousePosition from 'ol/control/MousePosition';
import {defaults as defaultControls} from 'ol/control';
import TileWMS from "ol/source/TileWMS";

interface Ifile {
  ext: string;
  nome: any;
  url: string;
  id: number;
  flag: string;
}
@Component({
  selector: "app-criar-ordem-servico",
  templateUrl: "./criar-ordem-servico.component.html",
  styleUrls: ["./criar-ordem-servico.component.css"],
})
export class CriarOrdemServicoComponent implements OnInit {
  private primeiros_niveis: any = [];
  private segundo_niveis: any = [];
  private terceiro_niveis: any = [];
  private departamentos: any = [];
  private funcoes: any = [];
  private artigos: any = [];
  private produtoCategorias: any = [];
  private recursos: any = [];
  private tipos: any = [];
  private classificacoes: any = [];
  private prioridades: any = [];
  private causas: any = [];
  private sintomas: any = [];
  private dropdownDepartamentos: any = [];
  private clientes: any = [];
  private local_consumo: any = [];
  private local_consumos: any = []

  private dayInicio: any;
  private permission: string = null;
  private hourInicio: any;
  private minutesInicio: any;
  private timeInicio: any;

  private agendamentodayInicio: any;
  private agendamentohourInicio: any;
  private agendamentominutesInicio: any;

  private hourInicioAgendamento: any;
  private minutesInicioAgendamento: any;

  private colaboradores: any = [];
  private duracoes: any = [];
  private validateLocalInstalacao:any = 0
  progress: any = 0;
  progress_1: string = null;
  private contas: any = [];
  private contratos: any = [];
  fileList: any = [];
  fileListAndamento: any = [];
  fileAgendamento: any = [];
  filenout;
  check:boolean=false
  private isFinalizar: boolean = true;
  private isDisabled: boolean = false;
  private is_sintoma: boolean = false;
  private is_causa: boolean = false;
  private is_titulo: boolean = false;
  private validateButton: any = null;
  private is_responsavel: boolean = false;
  private qtdPrevistaDecimal: boolean = true;
  private view_client = true;
  dropdownSettings = {};
  dropdownSettingsSintomas = {};
  dropdownSettingsDepartamentos = {};

  @Input() title: string = "Ordem de Trabalho";
  @Input() ordem_sigla: string = "OT";
  @Input() class_size: string = "col-md-3";
  @Input() stepTitle: string = "Ordem de Trabalho";

  routerParamId: any;

  private causasArray: any = [];
  private sintomasArray: any = [];
  private departamentoArray: any = [];

  private associacao_cliente = null;
  private facturacao_antecipada: boolean = false;
  private facturacao_posterior: boolean = false;
  private associacao_equipamento = null;

  private agendamento = {
    data_previsao_inicio: null,
    data_previsao_fim: null,
    dif_dias_prevista: null,
    dif_horas_prevista: null,
    dif_minutos_prevista: null,
    descricao: null,
  };

  private associacao = {
    descricao: null,
    categoria_descricao: null,
  };

  private recursosHumanos = {
    departamento_id: null,
    qtd_colaborador: null,
    funcao_id: null,
    colaborador_id: null,
    colaborador_nome: null,
    qtdRh: null,
  };

  private consumiveis = {
    armazem_id: null,
    artigo_id: null,
    qtdArtigo: null,
    qtd_stock: null,
    qtd_stock_reserva: null,
    categoria_id: null,
    reserva: null,
    unidade: null,
    descricao_material: null,
  };

  private ordemServico = {
    descricao: null,
    nivel_1: null,
    nivel_2: null,
    nivel_3: null,
    cliente_id: null,
    nivel_um_descricao: null,
    nivel_dois_descricao: null,
    nivel_tres_descricao: null,
    tipo_id: null,
    cliente_conta_id: null,
    cliente_contrato_id: null,
    cliente_nome: null,
    ot_trigger_flag: null,
    success_ordem_servico: false,
    success_ot_sem_agendamento: false,
    facturacao_antecipada: false,
    factura_id: false,
    sintoma_id: null,
    classificacao_id: null,
    prioridade_id: null,
    data_prevista: null,
    causa_id: null,
    sla: null,
    titulo: null,
    codigo: null,
    agendamentoGerado: null,
    origem: "TECNICA",
    file: null,
    quarteirao_id: null,
    local_consumo_id: null
  };

  private coordenada = {
    latitude: null,
    longitude: null,
    equipamentoDescricao: null,
    codigo_equipamento: null,
  };

  private pagination = {
    start: 1,
    end: 10,
    search: null,
    contrato: null,
    municipio_id: "T",
  };

  //START FACTURAÇÃO
  @ViewChild("search") search;
  @ViewChild("qtd") qtd;

  @ViewChild("valor") valor;
  @ViewChild("serie") serie;
  @ViewChild("observacao") observacao;
  @ViewChild("leitura") leitura;
  @ViewChild("closebutton") closebutton;

  map: Map;
  /*
  initilizeMap(){

    const container = document.getElementById('popup');
    const content = document.getElementById('popup-content');
    const closer = document.getElementById('popup-closer');
     console.log(container,content,closer)
    const overlay = new Overlay({
      element: container,
      autoPan: true,
      autoPanAnimation: {
        duration: 250
      }
    });

    var vectorSource = new VectorSource({
      format: new GeoJSON(),
      url: function (extent) {
        return (
          'https://ahocevar.com/geoserver/wfs?service=WFS&' +
          'version=1.1.0&request=GetFeature&typename=osm:water_areas&' +
          'outputFormat=application/json&srsname=EPSG:3857&' +
          'bbox=' +
          extent.join(',') +
          ',EPSG:3857'
        );
      },
      strategy: bboxStrategy,
    });

    var vector = new VectorLayer({
      source: vectorSource,
      style: new Style({
        stroke: new Stroke({
          color: 'rgba(0, 0, 255, 1.0)',
          width: 2,
        }),
      }),
    });

    var key = 'eKMYeVfLU2zK6ZjTsRFl';
    var attributions =
      '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> ' +
      '<a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>';

    var raster = new TileLayer({
      source: new XYZ({
        attributions: attributions,
        //url: 'http://83.240.217.33/WFSRegadio/service.svc/get?request=GetCapabilities&service=WFS',
        url: 'https://api.maptiler.com/tiles/satellite/{z}/{x}/{y}.jpg?key=' + key,
        maxZoom: 20,
      }),
    });

    var map = new Map({
      layers: [raster, vector],
      target: 'ol-map',
      overlays: [overlay], //
      view: new View({
        center: [-8908887.277395891, 5381918.072437216],
        maxZoom: 19,
        zoom: 12,
      }),
    });

    map.on('singleclick', function (evt) {

      const coordinate = evt.coordinate;
    const hdms = toStringHDMS(toLonLat(coordinate));

    content.innerHTML = '<p>Coordenadas actuais :</p><code>' + hdms +
      '</code>';
    overlay.setPosition(coordinate);

      console.log(evt.coordinate);

      // convert coordinate to EPSG-4326
      console.log(transform(evt.coordinate, 'EPSG:3857', 'EPSG:4326'));
  });

  closer.onclick = function () {
    overlay.setPosition(undefined);
    closer.blur();
    return false;
  };

  } */

  private desconto: number = 0;

  private dataActual = new Date();
  private totalFacturacao = 0;
  private totalSemImposto = 0;
  private totalComImposto = 0;
  private produts: any = [];

  private quantidade = 1;
  private produtoId: number;
  private clienteId: number;
  private serieId: number;
  private formasPagamentos: any = [];
  private validarFacturaRecibo: boolean = false;

  private contas_clientes: any = [];
  private contratos_conta_cliente: any = [];

  private numero_telefone: string;
  private searchCliente: string;

  private default: any = [];

  public currentUser: any;
  tarifario_descricao = "";

  private facturacao = {
    cliente: {
      id: null,
      nome: null,
      contribuinte: null,
      morada: null,
      telefone: null,
      conta_id: null,
      contrato_id: null,
    },
    serie: {
      id: null,
      sigla: null,
      nome: null,
      proximo_numero: null,
    },
    produto: [],
    pagamento: {
      forma: null,
      valor_recebido: 0.0,
      total_valor_recebido: 0.0,
      troco: 0.0,
      total_pago: null,
      referencia: null,
      data_pagamento: null,
      forma_pagamento_id: null,
      bancos: [],
      adiantamentos: [],
      is_adiantamento: 1,
      linha_pagamentos: [],
    },
    totalComImposto: 0.0,
    totalSemImposto: 0.0,
    total: 0.0,
    success_facturacao: false,
    facturaGerada: null,
    data_vencimento: moment(Date()).format("YYYY-MM-DD").toString(),
    moeda: null,
  };

  private _local_consumo = {
    id: null,
    moradia_numero: null,
    is_predio: null,
    predio_nome: null,
    predio_andar: null,
    cil: null,
    cil_flag: true,
    rua_id: null,
    rua: null,
    bairro_id: null,
    bairro: null,
    has_quarteirao: false,
    quarteirao_id: null,
    quarteirao: null,
    has_distrito: false,
    distrito_id: null,
    distrito: null,
    municipio_id: null,
    municipio: null,
    provincia_id: null,
    is_active: null,
    user_id: null,
  };
  private disabledPagamento = true;
  private disabledPagamentoAdiantamento = true;
  view_local_consumo: boolean = false
  private series: any = [];
  private activeButtom = {
    cliente: false,
    produto: false,
  };
  private municipio_id: "T";
  private items_produtos: any = [];

  private produto_selecionado = null;
  private preco: number;
  private municipios: [];

  //END FACTURAÇÃO
  bindInfoOpenLayer:string=''
  distritos:any[] = []
  bairros:any[] = []
  contrato_edit
  quarteiraos:any[] = []
  ruas:any[] = []
  currtentPage: number;

  constructor(
    private auth: AuthService,
    private router: Router,
    private http: HttpService,
    private route: ActivatedRoute,
    private configService: ConfigService,
    private api: ApiService
  ) {
    //this.route.params.subscribe(params => this.currtentPage = params['page']);
    this.currentUser = this.auth.currentUserValue;
  }
  sizeMap:number=0
  ngOnInit() {
    //this.getPrimeiroNivel();
    this.filenout = document.getElementById("file-input");
    this.getPrioridade();
    this.getMunicipios();
    this.getRouterParamId();
   // this.initilizeMap();
    this.sizeMap=0
  }
  hendleCheker(){
    this.check=true
    this.sizeMap++
  }
  hendleOpenLayer() {

    document.getElementById('map').innerHTML = '';

    const mousePositionControl = new MousePosition({
      coordinateFormat: createStringXY(4),
      projection: 'EPSG:4326',
      // comment the following two lines to have the mouse position
      // be placed within the map.
      className: 'custom-mouse-position',
      target: document.getElementById('mouse-position'),
    });
    const wmsSource = new TileWMS({
       url: 'http://192.168.1.207/WMSAbast/service.svc/get?request=GetCapabilities&service=WMS',
       params: {'LAYERS':
                 'VACHAFARIZ,VAFILTRO,VAFURO,VAGRELECTROBOMBA,VAHIDRANTE,VAJUNTACEGA,VAMEDIDORCAUDAL,VANOALTERACAO,VAPONTOENTREGA,VAPONTONOTAVEL,VAPOSTOCLORAGEM,VAVALDESCARGA,VAVALREGULACAO,VAVALRETENCAO,VAVALSUSPENSAO,VAVENTOSA,VARAMAL,VATROCOADUTOR,VATROCOTUBAGEM,VACAPTACAO,VACELULA,VAESTACAOELEVATORIA,VAETA,VARECINTO,VARESERVATORIO',
                 'TILED': true
              },
      serverType: "geoserver",
      //crossOrigin: "anonymous",
    });

    let wmsLayer: any = new Tile({
      source: new OSM()
    })

    let _wmsLayer: any = new Tile({
      source: wmsSource
    })

    const view = new View({
      center: [
               this.coordenada.latitude,
               -this.coordenada.longitude
              ],
      zoom: 8.5,
    });

     let map = new Map({
      controls: defaultControls().extend([mousePositionControl]),
      layers: [
        wmsLayer,
        _wmsLayer
      ],
      target: "map",
      view: view,
    });
    map.on("singleclick", function (evt) {
      document.getElementById('info').innerHTML = '';
     // ($("#info") as any).innerHTML = "";

      const viewResolution = /** @type {number} */ view.getResolution();
      const url = wmsSource.getFeatureInfoUrl(
        evt.coordinate,
        viewResolution,
        "EPSG:3857",
        { INFO_FORMAT: "text/html" }
      );
      if (url) {
        fetch(url)
          .then((response) => response.text())
          .then((html) => {
            document.getElementById('info').innerHTML = html;
            this.bindInfoOpenLayer=html
            //($("#info") as any).innerHTML = html;
          });
      }
    });

    map.on("pointermove", function (evt) {
      if (evt.dragging) {
        return;
      }
      const data = wmsLayer.getData(evt.pixel);
      const hit = data && data[3] > 0; // transparent pixels have zero for data[3]
      map.getTargetElement().style.cursor = hit ? "pointer" : "";
    });

   }
 openModal(modal=''){
   ($("#modalMapaEquipamentoLocation") as any).modal("show")

  }
  closeModal(modal=''){
    ($("#modalMapaEquipamentoLocation") as any).modal("hide")
    this.check=false
  }

  ngDoCheck() {

    if (this.check===true && this.sizeMap==1) {

     setTimeout(()=>{
         this.hendleOpenLayer();
         this.check=false

     },1000)
     this.check=false
    }


  }


  private selectBoxDistritosByMunicipio(id) {
    this.http
      .call_get("distrito/getDistritosByMunicipio/" + id, null)
      .subscribe((response) => {
        this.distritos = Object(response).data;
        if (this.distritos.length) this._local_consumo.has_distrito = true;
      });

    if (!this._local_consumo.has_distrito) {
      this.selectBoxBairrosByMunicipio(id);
    } else {
      this.selectBoxBairrosByDistrito(this._local_consumo.distrito_id);
    }
  }

  private selectBoxBairrosByDistrito(id) {
    this._local_consumo.cil = null;
    this._local_consumo.quarteirao_id = null;
    this._local_consumo.rua_id = null;

    this.http
      .call_get("bairro/selectBoxByDistrito/" + id, null)
      .subscribe((response) => {
        this.bairros = Object(response);
      });
  }

  private selectBoxQuarteiraosByBairro(id) {
    if (this._local_consumo.cil_flag == false && !this.contrato_edit) {
      this._local_consumo.quarteirao_id = null;
      this._local_consumo.rua_id = null;
    }

    if (!this.contrato_edit) {
      for (let i = 0; i < this.bairros.length; ++i) {
        if (this.bairros[i].id == id)
          this._local_consumo.has_quarteirao = this.bairros[i].has_quarteirao;
      }
    }

    if (this._local_consumo.has_quarteirao || this.contrato_edit) {
      this.http
        .call_get("quarteirao/getQuarteiraosByBairro/" + id, null)
        .subscribe((response) => {
          this.quarteiraos = Object(response).data;

          if (this.quarteiraos.length) this._local_consumo.has_quarteirao = true;
        });
    }

    if (!this._local_consumo.has_quarteirao || this.contrato_edit) {
      this.selectBoxRuasByBairro();
    }
  }

  private selectBoxRuasByQuarteirao(id) {
    this.http
      .call_get("rua/getRuasByQuarteirao/" + id, null)
      .subscribe((response) => {
        this.ruas = Object(response).data;
      });
  }

  private selectBoxRuasByBairro() {
    this.http
      .call_get("rua/selectBoxByBairro/" + this._local_consumo.bairro_id, null)
      .subscribe((response) => {
        this.ruas = Object(response);
      });
  }

  private selectBoxBairrosByMunicipio(id) {
    if (this._local_consumo.cil_flag == false && !this.contrato_edit) {
      this._local_consumo.quarteirao_id = null;
      this._local_consumo.rua_id = null;
    }

    this.http
      .call_get("bairro/selectBoxByMunicipio/" + id, null)
      .subscribe((response) => {
        this.bairros = Object(response)
      });
  }

  private carregando = {
    nivel_2: "Seleccione o equipamento",
    funcao: "Seleccione a função",
    material: "Seleccione o material",
    categoria: "Seleccione a categoria",
    tipo: "Seleccione o tipo",
  };

  public getRouterParamId() {
    this.routerParamId = this.route.snapshot.params.id;

    this.permission = localStorage.getItem("permission");

    if (this.permission === "listagem_ot_comercial") {
      this.title = "Ordem de Serviço";
      this.ordem_sigla = "OS";
      this.class_size = "col-md-3";
      this.getDropDownDepartamentos();
    } else {
      this.title = "Ordem de Trabalho";
      this.ordem_sigla = "OT";
      this.class_size = "col-md-4";
    }

    //console.log(this.routerParamId)

    if (this.routerParamId != undefined) {
      this.title = "Criar Agendamento";
      this.getOrdemById(this.routerParamId);
      this.getArmazens();
    }

    this.getClassificacao();
  }

  private getOrdemById(id) {
    this.http
      .call_get("ordemservico/get-info-ordem-by-id/" + id, null)
      .subscribe((response) => {
        if (Object(response).data == undefined) {
          this.router.navigate(["/ordemservico/listagem"]);
        }

        this.ordemServico.codigo = Object(response).data.codigo;
        this.ordemServico.titulo = Object(response).data.titulo;
      });
  }

  private setNivelUm(item) {
    this.ordemServico.nivel_1 = item.id;
    this.ordemServico.nivel_um_descricao = item.descricao;
    this.view_nivel_um = false;
  }

  //SEARCH NÍVEL UM
  view_nivel_um = false;

  private searchNivelUm() {
    this.ordemServico.nivel_2 = null;
    this.ordemServico.nivel_dois_descricao = null;
    this.ordemServico.nivel_3 = null;
    this.ordemServico.nivel_tres_descricao = null;

    if (
      this.ordemServico.nivel_um_descricao == "" ||
      this.ordemServico.nivel_um_descricao == null
    ) {
      this.ordemServico.nivel_1 = null;
      this.ordemServico.nivel_um_descricao = null;
      this.view_nivel_um = false;
    }

    this.primeiros_niveis = [];

    this.view_nivel_um = true;
    this.http
      .__call("ordemservico/equipamento/search-primeiro-nivel", {
        start: 1,
        end: 500,
        search: this.ordemServico.nivel_um_descricao,
      })
      .subscribe((response) => {
        this.primeiros_niveis = Object(response).data.data;
      });
  }

  private setNiveldois(item) {
    this.ordemServico.nivel_2 = item.id;
    this.ordemServico.nivel_dois_descricao = item.descricao;
    this.view_nivel_dois = false;
  }

  //SEARCH NÍVEL 2
  view_nivel_dois = false;

  private searchNiveldois() {
    this.ordemServico.nivel_3 = null;
    this.ordemServico.nivel_tres_descricao = null;

    if (
      this.ordemServico.nivel_um_descricao == "" ||
      this.ordemServico.nivel_um_descricao == null
    ) {
      this.ordemServico.nivel_2 = null;
      this.ordemServico.nivel_dois_descricao = null;
      this.view_nivel_dois = false;
    }

    this.segundo_niveis = [];

    this.view_nivel_dois = true;
    this.http
      .__call(
        "ordemservico/equipamento/search-segundo-nivel-by-primeiro/" +
          this.ordemServico.nivel_1,
        { start: 1, end: 500, search: this.ordemServico.nivel_dois_descricao }
      )
      .subscribe((response) => {
        this.segundo_niveis = Object(response).data.data;
        this.carregando.nivel_2 = "Selecione o equipamento";
      });
  }

  private getLatitudeLongitude() {
    this.coordenada.latitude = null;
    this.coordenada.longitude = null;
    this.coordenada.codigo_equipamento = null;

    let id =
      this.ordemServico.nivel_3 != null
        ? this.ordemServico.nivel_3
        : this.ordemServico.nivel_3 == null && this.ordemServico.nivel_2 != null
        ? this.ordemServico.nivel_2
        : this.ordemServico.nivel_1;

    if (id != null) {
      this.configService.loaddinStarter("start");
      this.http
        .call_get("ordemservico/equipamento/getCoordenadas/" + id, null)
        .subscribe((response) => {
          this.coordenada.latitude = Object(response).data.latitude;
          this.coordenada.longitude = Object(response).data.longitude;
          this.coordenada.equipamentoDescricao =
            Object(response).data.descricao;
          this.coordenada.codigo_equipamento =
            Object(response).data.codigo_registo;
          this.configService.loaddinStarter("stop");
        });
    }
  }

  private getAssociacao() {
    this.is_titulo = false;
    this.associacao.categoria_descricao = null;

    this.clearFormInputs();

    if (this.ordemServico.tipo_id != null) {
      this.configService.loaddinStarter("start");
      this.http
        .call_get(
          "ordemservico/ostipo/associacao/" + this.ordemServico.tipo_id,
          null
        )
        .subscribe((response) => {
          this.associacao_cliente = Object(response).data.cliente;
          this.associacao_equipamento = Object(response).data.equipamento;
          this.facturacao_antecipada =
            Object(response).data.facturacao_antecipada;
          this.ordemServico.facturacao_antecipada =
            Object(response).data.facturacao_antecipada;
          this.ordemServico.ot_trigger_flag =
            Object(response).data.ot_trigger_flag;
          this.facturacao_posterior =
            Object(response).data.facturacao_posterior;
          this.validateLocalInstalacao = Object(response).data.local_instalacao
          console.log('get os tipos', response)
          if (this.facturacao_antecipada) {
            //FACTURAÇÃO
            this.getMunicipios();
            this.listarseries();
            this.configService.listarBancos();
            this.configService.listarMoedas();
            this.listarFormaPagamentos();
            this.getServicos(" ");
            //END FACTURAÇÃO
          }
          this.associacao.descricao = Object(response).data.descricao;
          this.associacao.categoria_descricao =
            Object(response).data.categoria_descricao;
          this.is_sintoma = Object(response).data.sintoma;
          this.is_causa = Object(response).data.causa;
          this.is_titulo = Object(response).data.is_titulo;
          this.ordemServico.sla = Object(response).data.sla;
          if (this.is_titulo) {
            this.ordemServico.titulo = this.associacao.descricao;
          }
          this.configService.loaddinStarter("stop");
        });
    }

    this.is_titulo = false;
  }

  private setNivelTres(item) {
    this.ordemServico.nivel_3 = item.id;
    this.ordemServico.nivel_tres_descricao = item.descricao;
    this.view_nivel_treis = false;
  }

  //SEARCH NÍVEL 3
  view_nivel_treis = false;

  private searchNivelTres() {
    if (
      this.ordemServico.nivel_tres_descricao == "" ||
      this.ordemServico.nivel_tres_descricao == null
    ) {
      this.ordemServico.nivel_3 = null;
      this.ordemServico.nivel_tres_descricao = null;
      this.view_nivel_treis = false;
    }

    this.terceiro_niveis = [];

    this.view_nivel_treis = true;
    this.http
      .__call(
        "ordemservico/equipamento/search-terceiro-nivel-by-segundo/" +
          this.ordemServico.nivel_2,
        { start: 1, end: 500, search: this.ordemServico.nivel_tres_descricao }
      )
      .subscribe((response) => {
        this.terceiro_niveis = Object(response).data.data;
      });
  }

  private getPrioridade() {
    this.http
      .call_get("ordemservico/prioridade/selectBox", null)
      .subscribe((response) => {
        this.prioridades = Object(response).data;
      });
  }

  private getClassificacao() {
    const uri =
      this.permission === "listagem_ot_comercial"
        ? "ordemservico/classificacao-comercial/selectBox"
        : this.permission === "listagem_ot_tecnico"
        ? "ordemservico/classificacao/selectBox-tecnica"
        : "ordemservico/classificacao/selectBox";

    this.http.call_get(uri, null).subscribe((response) => {
      this.classificacoes = Object(response).data;
    });
  }

  private getTipoByClassificacao() {
    this.carregando.tipo = "Carregando...";
    this.http
      .__call(
        "ordemservico/ostipo-trigger-by-flag/selectBox/" +
          this.ordemServico.classificacao_id,
        { flag: "MANUAL" }
      )
      .subscribe((response) => {
        this.tipos = Object(response).data;
        this.carregando.tipo = "Seleccione o tipo";
      });

    this.getCausasByClassificacao();
    this.getSintomasByClassificacao();
  }

  private getCausasByClassificacao() {
    this.http
      .call_get(
        "ordemservico/oscausas-by-classificacao/selectBox/" +
          +this.ordemServico.classificacao_id
      )
      .subscribe((response) => {
        this.causas = Object(response).data;

        this.dropdownSettings = {
          singleSelection: false,
          idField: "id",
          textField: "descricao",
          selectAllText: "Seleccione Todos",
          unSelectAllText: "DeSeleccionar Todos",
          itemsShowLimit: 10,
          allowSearchFilter: true,
        };
      });
  }

  onItemSelect(item: any) {
    //console.log(item);
    this.causasArray.push(item);
  }
  onSelectAll(items: any) {
    this.causasArray = items;
  }

  private getSintomasByClassificacao() {
    this.http
      .call_get(
        "ordemservico/ossintomas-by-classificacao/selectBox/" +
          this.ordemServico.classificacao_id
      )
      .subscribe((response) => {
        this.sintomas = Object(response).data;

        this.dropdownSettingsSintomas = {
          singleSelection: false,
          idField: "id",
          textField: "descricao",
          selectAllText: "Seleccione Todos",
          unSelectAllText: "DeSeleccionar Todos",
          itemsShowLimit: 10,
          allowSearchFilter: true,
        };
      });
  }

  private getDropDownDepartamentos() {
    this.http.call_get("departamento/selectBox", null).subscribe((response) => {
      this.dropdownDepartamentos = Object(response).data;

      this.dropdownSettingsDepartamentos = {
        singleSelection: false,
        idField: "id",
        textField: "descricao",
        selectAllText: "Seleccione Todos",
        unSelectAllText: "DeSeleccionar Todos",
        itemsShowLimit: 10,
        allowSearchFilter: true,
      };
    });
  }

  onItemSelectSintoma(item: any) {
    this.sintomasArray.push(item);
  }
  onSelectAllSintomas(items: any) {
    this.sintomasArray = items;
  }

  onItemSelectDepartamento(item: any) {
    this.departamentoArray.push(item);
  }
  onSelectAllDepartamentos(items: any) {
    this.departamentoArray = items;
  }

  private getCliente() {
    this.contas = [];
    this.contratos = [];
    this.view_client = true;
    this.http
      .__call("cliente/search-cliente", {
        start: 1,
        end: 10,
        search: this.ordemServico.cliente_nome,
      })
      .subscribe((response) => {
        this.clientes = Object(response).data.data;
      });
  }

  public getContratosByConta() {
    this.contratos = [];

    if (this.ordemServico.cliente_conta_id != null) {
      this.configService.loaddinStarter("start");
      this.http
        .__call(
          "conta/contratoByConta/" + this.ordemServico.cliente_conta_id,
          null
        )
        .subscribe((response) => {
          this.contratos = Object(response);
          this.configService.loaddinStarter("stop");
        });

      this.getContaContrato();
    }
  }

  private getLocalConsumoByContratoId(id: any) {
    this.local_consumo.cil = null;
    this.local_consumo.numero_serie = null;
    this.local_consumo.predio_nome = null;
     console.log(id)
    if (id != null) {
      this.http
        .call_get(
          "local-consumo/getLocalConsumoByContratoId/" +
           id,
          null
        )
        .subscribe((response) => {
          this.local_consumo = Object(response);
          console.log(Object(response))
          this.local_consumo.cil = this.local_consumo.cil == null ? "" : this.local_consumo.cil;
          this.local_consumo.numero_serie = this.local_consumo.numero_serie == null ? ""
          : this.local_consumo.numero_serie;

          this.local_consumo.predio_nome = (this.local_consumo.cil != null
              ? "[" + this.local_consumo.cil + "] "  : "[0000-000-000]") + (this.local_consumo.is_predio ? "Prédio " +
              this.local_consumo.predio_nome + ", " + (this.local_consumo.predio_andar ?
                this.local_consumo.predio_andar + " º Andar - " : "") + "Porta "  : "Residência ") + this.local_consumo.moradia_numero;
        });
    }
  }

  private getDepartamento() {
    this.http.call_get("departamento/selectBox", null).subscribe((response) => {
      this.departamentos = Object(response).data;
    });

    this.getDuracao();
  }

  private getFuncaoByDepartamento() {
    this.carregando.funcao = "Carregando...";
    this.http
      .call_get(
        "funcao/funcaoesByDepartamento/" + this.recursosHumanos.departamento_id,
        null
      )
      .subscribe((response) => {
        this.funcoes = Object(response).data;
        this.carregando.funcao = "Seleccione a função";
      });
  }

  private getDuracao() {
    this.http.call_get("duracao/selectBox", null).subscribe((response) => {
      this.duracoes = Object(response).data;
    });
  }

  private getColaboradoresByFuncao() {
    this.colaboradores = [];

    if (this.recursosHumanos.funcao_id != null) {
      this.configService.loaddinStarter("start");
      this.http
        .__call("colaborador/colaboradoresByFuncao", {
          start: 1,
          end: 10,
          search: null,
          funcao_id: this.recursosHumanos.funcao_id,
        })
        .subscribe((response) => {
          this.colaboradores = Object(response).data.data;
          this.configService.loaddinStarter("stop");
        });
    }
  }

  public armazens = [];

  public getArmazens() {
    this.http.__call("armazem/selectBox", null).subscribe((response) => {
      this.armazens = Object(response).data;
    });
  }

  private getCategoriaByArmazem() {
    this.consumiveis.unidade = null;
    this.consumiveis.qtd_stock = 0;
    (this.consumiveis.categoria_id = null), (this.produtoCategorias = []);
    this.artigos = [];

    this.carregando.categoria = "Carregando...";
    this.http
      .__call("categoria/by/armazem", {
        armazem_id: this.consumiveis.armazem_id,
      })
      .subscribe((res: any) => {
        this.produtoCategorias = Object(res).data;
        this.carregando.categoria = "Seleccione a categoria";
      });
  }

  view_material = false;

  private getMaterialByCategoriaArmazem() {
    this.consumiveis.unidade = null;
    this.consumiveis.qtd_stock = 0;
    this.consumiveis.artigo_id = null;
    this.artigos = [];

    this.carregando.material = "Carregando...";
    this.http
      .__call("material/by/categoria-armazem", {
        armazem_id: this.consumiveis.armazem_id,
        categoria_id: this.consumiveis.categoria_id,
      })
      .subscribe((res: any) => {
        this.artigos = Object(res).data;
        this.carregando.material = "Seleccione o material";
      });
  }

  private setMaterial(item) {
    this.consumiveis.artigo_id = item.id;
    this.consumiveis.descricao_material =
      item.codigo_produto + " - " + item.descricao;
    this.view_material = false;
  }

  //SEARCH MATERIAL

  private searchMaterial() {
    /*  if (this.consumiveis.descricao_material == "" || this.consumiveis.descricao_material == null) {
       this.consumiveis.artigo_id = null;
       this.consumiveis.descricao_material = null;
       this.view_material = false;
     } */

    this.consumiveis.unidade = null;
    this.consumiveis.qtd_stock = 0;
    this.consumiveis.qtd_stock_reserva = 0;
    this.consumiveis.artigo_id = null;

    this.artigos = [];

    if (this.consumiveis.categoria_id == null) {
      (this.consumiveis.descricao_material = null),
        (this.view_material = false);
    }

    this.view_material = true;
    this.http
      .__call("search-material/by/categoria-armazem", {
        start: 1,
        end: 500,
        armazem_id: this.consumiveis.armazem_id,
        categoria_id: this.consumiveis.categoria_id,
        search: this.consumiveis.descricao_material,
      })
      .subscribe((response) => {
        this.artigos = Object(response).data.data;
      });
  }

  public consumiveisSelecionados = [];
  public recursosSelecionados = [];
  public qtd_stock = 0;

  private getQtdProduto() {
    this.getQtdProdutoReserva();

    this.consumiveis.qtd_stock = 0;

    if (this.consumiveis.artigo_id != null) {
      this.http
        .__call("stock/existencia/armazem/qtd_produto", {
          armazem_id: this.consumiveis.armazem_id,
          artigo_id: this.consumiveis.artigo_id,
        })
        .subscribe((response) => {
          this.consumiveis.qtd_stock = Object(response).data[0]
            ? Object(response).data[0].quantidade
            : 0;
        });
    }
    //-------
  }

  public armazemDisponiveis = [];

  private getArmazemWithProduto() {
    if (this.consumiveis.artigo_id != null) {
      this.http
        .__call("stock/existencia/armazem/by_produto", {
          artigo_id: this.consumiveis.artigo_id,
        })
        .subscribe((response) => {
          this.armazemDisponiveis = Object(response).data[0];
        });
    }
  }

  private getQtdProdutoReserva() {
    this.consumiveis.qtd_stock_reserva = 0;

    if (this.consumiveis.artigo_id != null) {
      this.http
        .__call("stock-reserva/existencia/armazem/qtd_produto", {
          armazem_id: this.consumiveis.armazem_id,
          artigo_id: this.consumiveis.artigo_id,
        })
        .subscribe((response) => {
          this.consumiveis.qtd_stock_reserva = Object(response).data[0]
            ? Object(response).data[0].quantidade
            : 0;
        });
    }
    //-------
  }

  private getUnidadeByMaterial() {
    this.consumiveis.unidade = null;

    if (this.consumiveis.artigo_id != null) {
      this.http
        .call_get("unidade-by-material/" + this.consumiveis.artigo_id, null)
        .subscribe((response) => {
          this.consumiveis.unidade = Object(response).data.unidade;
        });
    }
    //-------
  }

  private getQtColaborador() {
    this.http
      .__call("colaborador/get-total", {
        funcao_id: this.recursosHumanos.funcao_id,
      })
      .subscribe((response) => {
        this.recursosHumanos.qtd_colaborador = Object(response).data.total;
      });
    //-------
  }

  private isNumeric(value: any): boolean {
    value = this.consumiveis.qtdArtigo;

    value = !isNaN(value - parseFloat(value));
    this.qtdPrevistaDecimal = value;

    if (!this.qtdPrevistaDecimal) {
      this.configService.showAlert(
        "Número decimal inválido! Quantidade(EST)! Ex. 1 ou 1.5",
        "alert-danger",
        true
      );
    }
    return !isNaN(value - parseFloat(value));
  }

  public adicionarArtigos() {
    var validar = 0;
    var nome_artigo = "";
    var codigo_artigo = "";
    var armazem_nome = "";
    var categoria_descricao = "";

    if (this.consumiveis.qtdArtigo == null) {
      this.configService.showAlert(
        "Digite a quantidade a sair",
        "alert-danger",
        true
      );
    } else if (!this.qtdPrevistaDecimal) {
      this.configService.showAlert(
        "Número decimal inválido! Separe por .",
        "alert-danger",
        true
      );
    } else if (this.consumiveis.qtdArtigo <= 0) {
      this.configService.showAlert(
        "Quantidade não pode ser menor que 1",
        "alert-danger",
        true
      );
    } else if (this.consumiveis.qtdArtigo > this.consumiveis.qtd_stock) {
      this.configService.showAlert(
        "Quantidade a sair não pode ser superior a quantidade em Stock",
        "alert-danger",
        true
      );
    } else {
      this.artigos.forEach((element) => {
        if (element.id == this.consumiveis.artigo_id) {
          nome_artigo = element.descricao;
          codigo_artigo = element.codigo_produto;
          armazem_nome = element.armazem_nome;
          categoria_descricao = element.categoria_descricao;
        }
      });

      var artigo = {
        armazem_id: this.consumiveis.armazem_id,
        armazem_nome: armazem_nome,
        categoria_descricao: categoria_descricao,
        nome_artigo: codigo_artigo + " - " + nome_artigo,
        artigo_id: this.consumiveis.artigo_id,
        quantidade: this.consumiveis.qtdArtigo,
        qtd_stock: this.qtd_stock,
        unidade: this.consumiveis.unidade,
      };

      if (this.consumiveisSelecionados.length >= 1) {
        for (
          let index = 0;
          index < this.consumiveisSelecionados.length;
          index++
        ) {
          const l = this.consumiveisSelecionados[index];
          if (l.artigo_id == artigo.artigo_id) {
            this.consumiveisSelecionados.splice(index, 1);
            this.consumiveisSelecionados.splice(index, 0, artigo);
            validar = 1;
          }
        }
        if (validar == 0) {
          this.consumiveisSelecionados.push(artigo);
        }
      } else {
        this.consumiveisSelecionados.push(artigo);
      }

      (this.consumiveis.artigo_id = null),
        (this.consumiveis.qtdArtigo = null),
        (this.consumiveis.qtd_stock = null),
        (this.consumiveis.qtd_stock_reserva = null),
        (this.consumiveis.unidade = null);
    }
  }

  adicionarInicioEst(item) {
    var replace = item.data_prevista_inicio.replace("T", " ");
    var date = new Date(replace);

    this.dayInicio = date.getDay();
    this.hourInicio = date.getHours();
    this.minutesInicio = date.getMinutes();
    this.timeInicio = date.getTime();

    this.adicionarHoraPrevista(item);
  }

  adicionarFimEst(item) {
    var replace1 = item.data_prevista_inicio.replace("T", " ");
    var date1 = new Date(replace1);

    this.dayInicio = date1.getDay();
    this.hourInicio = date1.getHours();
    this.minutesInicio = date1.getMinutes();
    this.timeInicio = date1.getTime();

    var replace = item.data_prevista_fim.replace("T", " ");

    if (item.data_prevista_inicio == null || item.data_prevista_inicio == "") {
      this.configService.showAlert(
        "Digite a Data prevista de início do(a) " + item.nome_colaborador,
        "alert-danger",
        true
      );
    } else if (item.data_prevista_fim == null || item.data_prevista_fim == "") {
      this.configService.showAlert(
        "Digite a Data prevista de fim do(a) " + item.nome_colaborador,
        "alert-danger",
        true
      );
    } else if (item.data_prevista_inicio >= item.data_prevista_fim) {
      this.configService.showAlert(
        "A data fim previsto não pode ser igual ou inferior a data de início previsto - Colaborador(a): " +
          item.nome_colaborador,
        "alert-danger",
        true
      );
    } else {
      var date = new Date(replace);

      var dayFim = date.getDay();
      var hourFim = date.getHours();
      var minutesFim = date.getMinutes();
      var timeFim = date.getTime();

      var data = this.hourInicio * 3600 + this.minutesInicio * 60;
      var data2 = hourFim * 3600 + minutesFim * 60;
      var diferenca = data2 - data;

      this.hourInicio = Math.floor(diferenca / 3600);
      this.minutesInicio = Math.floor(
        (diferenca - this.hourInicio * 3600) / 60
      );

      /* console.log('length : '+this.minutesInicio.toString().length)
       */
      var condincao =
        this.hourInicio +
        "" +
        (this.minutesInicio.toString().length < 2
          ? "0" + "" + this.minutesInicio
          : this.minutesInicio);
      //var condicao_2 = (this.minutesInicio.length == 0 ? condincao+'0' : condincao);

      /*  console.log(parseInt(condincao))
      console.log(this.hourInicio + "h "+ (this.minutesInicio.toString().length < 2 ? '0'+''+this.minutesInicio : this.minutesInicio) + "min ")
       */

      var diffHoras =
        parseInt(condincao) > 759
          ? parseInt(this.hourInicio) - 8
          : this.hourInicio;
      var diffDias = dayFim - this.dayInicio;
      diffDias = parseInt(condincao) > 759 ? diffDias + 1 : diffDias;

      this.adicionarFimPrevista(item, diffDias, diffHoras, this.minutesInicio);
    }
  }

  public adicionarFimPrevista(item, diffDias, diffHoras, diffMinutos) {
    var validar = 0;

    var diffDiasReplace = diffDias.toString();
    var diffHorasReplace = diffHoras.toString();
    var diffMinutosReplace = diffMinutos.toString();
    //for(let i=0;i<this.colaboradores.length;i++){
    //  if(this.colaboradores[i].id == item.id){
    var recursos = {
      nome_colaborador: item.nome_colaborador,
      colaborador_id: item.colaborador_id,
      nome_departamento: item.nome_departamento,
      nome_funcao: item.nome_funcao,
      is_responsavel: item.is_responsavel,
      data_prevista_inicio: item.data_prevista_inicio,
      data_prevista_fim: item.data_prevista_fim,
      dif_dias_prevista: parseInt(diffDiasReplace.replace("-", "")),
      dif_horas_prevista: parseInt(diffHorasReplace.replace("-", "")),
      dif_minutos_prevista: parseInt(diffMinutosReplace.replace("-", "")),
    };
    // }

    // }

    if (this.recursosSelecionados.length >= 1) {
      for (let index = 0; index < this.recursosSelecionados.length; index++) {
        const l = this.recursosSelecionados[index];

        /*  if(l.is_responsavel){
          recursos.is_responsavel=false
        } */

        if (l.colaborador_id == recursos.colaborador_id) {
          this.recursosSelecionados.splice(index, 1);
          this.recursosSelecionados.splice(index, 0, recursos);
          validar = 1;
        }
      }
      if (validar == 0) {
        this.recursosSelecionados.push(recursos);
      }
    } else {
      this.recursosSelecionados.push(recursos);
    }
  }

  public adicionarHoraPrevista(item) {
    var validar = 0;

    var recursos = {
      nome_colaborador: item.nome_colaborador,
      colaborador_id: item.colaborador_id,
      nome_departamento: item.nome_departamento,
      nome_funcao: item.nome_funcao,
      is_responsavel: item.is_responsavel,
      data_prevista_inicio: item.data_prevista_inicio,
    };

    if (this.recursosSelecionados.length >= 1) {
      for (let index = 0; index < this.recursosSelecionados.length; index++) {
        const l = this.recursosSelecionados[index];
        if (l.colaborador_id == recursos.colaborador_id) {
          this.recursosSelecionados.splice(index, 1);
          this.recursosSelecionados.splice(index, 0, recursos);
          validar = 1;
        }
      }
      if (validar == 0) {
        this.recursosSelecionados.push(recursos);
      }
    } else {
      this.recursosSelecionados.push(recursos);
    }
  }

  public adicionarRecursos(item) {
    var validar = 0;
    var nome_funcao = "";
    var nome_departamento = "";

    this.funcoes.forEach((element) => {
      if (element.id == this.recursosHumanos.funcao_id) {
        nome_funcao = element.descricao;
      }
    });

    this.departamentos.forEach((element) => {
      if (element.id == this.recursosHumanos.departamento_id) {
        nome_departamento = element.descricao;
      }
    });

    this.departamentos.forEach((element) => {
      if (element.id == this.recursosHumanos.departamento_id) {
        nome_departamento = element.descricao;
      }
    });

    var recursos = {
      nome_colaborador: item.colaboradorNome,
      colaborador_id: item.id,
      nome_departamento: nome_departamento,
      nome_funcao: nome_funcao,
      data_prevista_inicio: null,
      is_responsavel: this.is_responsavel,
    };

    this.is_responsavel = false;

    if (this.recursosSelecionados.length >= 1) {
      for (let index = 0; index < this.recursosSelecionados.length; index++) {
        const l = this.recursosSelecionados[index];

        if (l.is_responsavel) {
          recursos.is_responsavel = false;
        }

        if (l.colaborador_id == recursos.colaborador_id) {
          this.recursosSelecionados.splice(index, 1);
          this.recursosSelecionados.splice(index, 0, recursos);
          validar = 1;
        }
      }
      if (validar == 0) {
        this.recursosSelecionados.push(recursos);
      }
    } else {
      this.recursosSelecionados.push(recursos);
    }
  }

  deleteRow(item: any) {
    for (let i = 0; i < this.consumiveisSelecionados.length; ++i) {
      if (this.consumiveisSelecionados[i].artigo_id === item.artigo_id) {
        this.consumiveisSelecionados.splice(i, 1);
      }
    }
  }

  deleteRecursoRow(item: any) {
    for (let i = 0; i < this.recursosSelecionados.length; ++i) {
      if (this.recursosSelecionados[i].colaborador_id === item.colaborador_id) {
        this.recursosSelecionados.splice(i, 1);
      }
    }

    this.is_responsavel = false;
  }

  private selectedResponsavel(id) {
    this.recursosHumanos.colaborador_id = id;
    this.colaboradores.forEach((element) => {
      if (this.recursosHumanos.colaborador_id == element.id) {
        this.recursosHumanos.colaborador_id = element.id;
        this.is_responsavel = true;
      }
    });
  }

  agendamentoAddInicioPrev() {
    /*  this.agendamento.dif_dias_prevista = null,
       this.agendamento.dif_horas_prevista = null,
       this.agendamento.dif_minutos_prevista = null, */

    var replace = this.agendamento.data_previsao_inicio.replace("T", " ");
    var date = new Date(replace);

    this.agendamentodayInicio = date.getDay();
    this.agendamentohourInicio = date.getHours();
    this.agendamentominutesInicio = date.getMinutes();
  }

  agendamentoAddFimPrev() {
    /*  this.agendamento.dif_dias_prevista = null,
       this.agendamento.dif_horas_prevista = null,
       this.agendamento.dif_minutos_prevista = null */

    var replace1 = this.agendamento.data_previsao_fim.replace("T", " ");

    if (
      this.agendamento.data_previsao_inicio == null ||
      this.agendamento.data_previsao_fim == ""
    ) {
      this.configService.showAlert(
        "Digite a Data prevista de início",
        "alert-danger",
        true
      );
    } else if (
      this.agendamento.data_previsao_fim == null ||
      this.agendamento.data_previsao_fim == ""
    ) {
      this.configService.showAlert(
        "Digite a Data prevista de fim",
        "alert-danger",
        true
      );
    } else if (
      this.agendamento.data_previsao_inicio >=
      this.agendamento.data_previsao_fim
    ) {
      this.configService.showAlert(
        "A data fim previsto não pode ser igual ou inferior a data de início previsto",
        "alert-danger",
        true
      );
    } else {
      var date1 = new Date(replace1);

      var dayFim = date1.getDay();
      var hourFim = date1.getHours();
      var minutesFim = date1.getMinutes();

      var data =
        this.agendamentohourInicio * 3600 + this.agendamentominutesInicio * 60;
      var data2 = hourFim * 3600 + minutesFim * 60;
      var diferenca = data2 - data;

      this.hourInicioAgendamento = Math.floor(diferenca / 3600);
      this.minutesInicioAgendamento = Math.floor(
        (diferenca - this.hourInicioAgendamento * 3600) / 60
      );

      var condincao =
        this.hourInicioAgendamento +
        "" +
        (this.minutesInicioAgendamento.toString().length < 2
          ? "0" + "" + this.minutesInicioAgendamento
          : this.minutesInicioAgendamento);

      var diffHoras =
        parseInt(condincao) > 759
          ? parseInt(this.hourInicioAgendamento) - 8
          : this.hourInicioAgendamento;
      var diffDias = dayFim - this.agendamentodayInicio;
      diffDias = parseInt(condincao) > 759 ? diffDias + 1 : diffDias;

      this.agendamento.dif_dias_prevista = diffDias;
      this.agendamento.dif_horas_prevista = diffHoras;
      this.agendamento.dif_minutos_prevista = this.minutesInicioAgendamento;
    }
  }

  private createOrdemServico() {
    this.ordemServico = {
      ...this.ordemServico,
      quarteirao_id: this._local_consumo.quarteirao_id !== null?
      this._local_consumo.quarteirao_id : this.local_consumo.quarteirao_id,
      local_consumo_id: this._local_consumo.id !== null?
      this._local_consumo.id : this.local_consumo.id,
    }
    if (this.ordemServico.titulo == null || this.ordemServico.titulo == "") {
      this.configService.showAlert("Digite o Título", "alert-danger", true);
    } else if (
      this.ordemServico.data_prevista == null ||
      this.ordemServico.data_prevista == ""
    ) {
      this.configService.showAlert(
        "Digite a Data prevista",
        "alert-danger",
        true
      );
    } else if (this.ordemServico.prioridade_id == null) {
      this.configService.showAlert(
        "Seleccione a prioridade",
        "alert-danger",
        true
      );
    } else if (this.ordemServico.classificacao_id == null) {
      this.configService.showAlert(
        "Seleccione a classificação",
        "alert-danger",
        true
      );
    } else if (this.ordemServico.tipo_id == null) {
      this.configService.showAlert("Seleccione o tipo", "alert-danger", true);
    } else if (
      this.associacao_equipamento &&
      this.ordemServico.tipo_id != null &&
      this.ordemServico.nivel_1 == null
    ) {
      this.configService.showAlert(
        "Seleccione o equipamento",
        "alert-danger",
        true
      );
    } else if (
      this.associacao_cliente &&
      this.ordemServico.tipo_id != null &&
      (this.ordemServico.cliente_nome == null ||
        this.ordemServico.cliente_nome == "")
    ) {
      this.configService.showAlert(
        "Seleccione o Cliente",
        "alert-danger",
        true
      );
    }
     else if (
      this.associacao_cliente &&
      this.ordemServico.tipo_id != null &&
      this.ordemServico.cliente_conta_id != null &&
      this.ordemServico.cliente_contrato_id == null
    ) {
      this.configService.showAlert(
        "Seleccione o Contrato",
        "alert-danger",
        true
      );
    } else if (
      this.ordemServico.descricao == null ||
      this.ordemServico.descricao == ""
    ) {
      this.configService.showAlert("Digite a descrição", "alert-danger", true);
    }  else if (
        this.validateLocalInstalacao === 1 && (
          !this._local_consumo.municipio_id || !this._local_consumo.bairro_id
          || !this._local_consumo.distrito_id || !this._local_consumo.quarteirao_id
        )
    ) {
      this.configService.showAlert("Digite o município, bairro, distrito ou quarteirão", "alert-danger", true);
    }else {
      this.configService.loaddinStarter("start");
      this.isFinalizar = true;
      this.isDisabled = true;

      let uploadData = new FormData();

      uploadData.append("ordemServico", JSON.stringify(this.ordemServico));
      uploadData.append("causas", JSON.stringify(this.causasArray));
      uploadData.append("sintomas", JSON.stringify(this.sintomasArray));
      uploadData.append(
        "departamentos",
        JSON.stringify(this.departamentoArray)
      );
      for (let file of this.fileList) {
        uploadData.append("file", file.file);
      }
      /* this.configService.loaddinStarter('stop');
    return */
      this.http
        .$__call("ordemservico/ordem-servico/create", uploadData)
        .subscribe((res: HttpEvent<any>) => {
          switch (res.type) {
            case HttpEventType.Sent:
              console.log("Request has been made!");
              break;
            case HttpEventType.ResponseHeader:
              console.log("Response header has been received!");
              break;
            case HttpEventType.UploadProgress:
              this.progress = Math.round((res.loaded / res.total) * 100);
              break;
            case HttpEventType.Response:
              setTimeout(() => {
                this.progress = 0;
                this.fileList = [];
              }, 1500);
          }
          if (Object(Object(res).body).code) {
            if (Object(Object(res).body).code == 200) {
              this.isDisabled = true;
              this.validateButton = Object(Object(res).body).code;
              this.configService.showAlert(
                Object(Object(res).body).message,
                "alert-success",
                true
              );
              this.clearFormInputs();
              this.ordemServico.success_ot_sem_agendamento = true;

              this.configService.loaddinStarter("stop");
            } else {
              this.configService.showAlert(
                Object(Object(res).body).message,
                "alert-danger",
                true
              );
              this.isFinalizar = false;
              this.isDisabled = false;
              this.configService.loaddinStarter("stop");
            }
          }
        });
    }
  }

  private clearFormInputs() {
    (this.ordemServico.nivel_1 = null),
      (this.ordemServico.nivel_2 = null),
      (this.ordemServico.nivel_3 = null),
      (this.ordemServico.cliente_conta_id = null),
      (this.ordemServico.cliente_contrato_id = null),
      (this.ordemServico.cliente_nome = null),
      (this.ordemServico.success_ordem_servico = false),
      (this.is_sintoma = false),
      (this.is_causa = false),
      (this.ordemServico.agendamentoGerado = null);
  }

  private reloadOrdemServico() {
    location.reload();
  }

  private validateOrdemServico() {
    if (this.ordemServico.titulo == null || this.ordemServico.titulo == "") {
      this.configService.showAlert("Digite o Título", "alert-danger", true);
    } else if (
      this.ordemServico.data_prevista == null ||
      this.ordemServico.data_prevista == ""
    ) {
      this.configService.showAlert(
        "Digite a Data prevista",
        "alert-danger",
        true
      );
    } else if (this.ordemServico.prioridade_id == null) {
      this.configService.showAlert(
        "Seleccione a prioridade",
        "alert-danger",
        true
      );
    } else if (this.ordemServico.classificacao_id == null) {
      this.configService.showAlert(
        "Seleccione a classificação",
        "alert-danger",
        true
      );
    } else if (this.ordemServico.tipo_id == null) {
      this.configService.showAlert("Seleccione o tipo", "alert-danger", true);
    } else if (
      this.associacao_equipamento &&
      this.ordemServico.tipo_id != null &&
      this.ordemServico.nivel_1 == null
    ) {
      this.configService.showAlert(
        "Seleccione o equipamento",
        "alert-danger",
        true
      );
    } else if (
      this.associacao_cliente &&
      this.ordemServico.tipo_id != null &&
      (this.ordemServico.cliente_nome == null ||
        this.ordemServico.cliente_nome == "")
    ) {
      this.configService.showAlert(
        "Seleccione o Cliente",
        "alert-danger",
        true
      );
    } else if (
      this.associacao_cliente &&
      this.ordemServico.tipo_id != null &&
      this.ordemServico.cliente_conta_id == null
    ) {
      this.configService.showAlert("Seleccione a Conta", "alert-danger", true);
    } else if (
      this.associacao_cliente &&
      this.ordemServico.tipo_id != null &&
      this.ordemServico.cliente_contrato_id == null
    ) {
      this.configService.showAlert(
        "Seleccione o Contrato",
        "alert-danger",
        true
      );
    } else if (
      this.ordemServico.descricao == null ||
      this.ordemServico.descricao == ""
    ) {
      this.configService.showAlert("Digite a descrição", "alert-danger", true);
    } else if (
      this.ordemServico.ot_trigger_flag == "INSTALACAO-CONTADOR" &&
      (this.local_consumo.cil == "" || this.local_consumo.cil == null)
    ) {
      this.configService.showAlert("Contrato sem CIL", "alert-danger", true);
    } else if (
      this.ordemServico.ot_trigger_flag == "REMOCAO-CONTADOR" &&
      (this.local_consumo.cil == "" || this.local_consumo.cil == null)
    ) {
      this.configService.showAlert("Contrato sem CIL", "alert-danger", true);
    } else {
      const nextElement: HTMLElement = document.querySelector(
        "#next-step-consumiveis"
      );
      nextElement.click();
    }
  }

  private validateConsumiveis() {
    if (this.consumiveisSelecionados.length == 0) {
      this.configService.showAlert(
        "Adiciona pelo menos um Material na lista",
        "alert-danger",
        true
      );
    } else {
      const nextElement: HTMLElement = document.querySelector(
        "#next-step-colaboradores"
      );
      nextElement.click();
    }
  }

  private formatDate(date) {
    var dia = date.getDate();
    var ano = date.getFullYear();
    var hora = date.getHours();
    var minuto = date.getMinutes();
    var mes = date.getMonth() + 1;

    return (
      ano +
      "-" +
      (mes.toString().length < 2 ? "0" + mes : mes) +
      "-" +
      (dia.toString().length < 2 ? "0" + dia : dia) +
      "T" +
      (hora.toString().length < 2 ? "0" + hora : hora) +
      ":" +
      (minuto.toString() < 2 ? "0" + minuto : minuto)
    );
  }

  private validateColaboradores() {
    var next = 0;
    var start = 1;
    var validar = 0;

    if (this.recursosSelecionados.length == 0) {
      this.configService.showAlert(
        "Adiciona pelo menos um Colaborador na lista",
        "alert-danger",
        true
      );
    } else if (this.recursosSelecionados.length >= 1) {
      for (let index = 0; index < this.recursosSelecionados.length; index++) {
        const l = this.recursosSelecionados[index];

        if (l.data_prevista_inicio == null) {
          this.configService.showAlert(
            "Digite a Data prevista de início do(a) " + l.nome_colaborador,
            "alert-danger",
            true
          );
          next = 1;
        }

        if (l.data_prevista_fim == null) {
          this.configService.showAlert(
            "Digite a Data prevista de fim do(a) " + l.nome_colaborador,
            "alert-danger",
            true
          );
          next = 1;
        }

        if (l.data_prevista_inicio >= l.data_prevista_fim) {
          this.configService.showAlert(
            "A data fim previsto não pode ser igual ou inferior a data de início previsto - Colaborador(a): " +
              l.nome_colaborador,
            "alert-danger",
            true
          );
          next = 1;
        }

        if (l.is_responsavel == true) {
          validar = ++start;
        }

        if (validar > 2) {
          this.configService.showAlert(
            "Seleccione apenas um Responsável",
            "alert-danger",
            true
          );
          next = 1;
        } else if (validar == 0) {
          this.configService.showAlert(
            "Seleccione um Responsável",
            "alert-danger",
            true
          );
          next = 1;
        }
      }

      var dates_prevista_inicio = [];
      var dates_prevista_fim = [];

      for (let index = 0; index < this.recursosSelecionados.length; index++) {
        dates_prevista_inicio.push(
          new Date(this.recursosSelecionados[index].data_prevista_inicio)
        );
        dates_prevista_fim.push(
          new Date(this.recursosSelecionados[index].data_prevista_fim)
        );
      }

      var maxDate = Math.max.apply(null, dates_prevista_fim);
      var mimDate = Math.min.apply(null, dates_prevista_inicio);
      // convert back to date object
      maxDate = new Date(maxDate);
      mimDate = new Date(mimDate);

      this.agendamento.data_previsao_inicio = this.formatDate(mimDate);
      this.agendamento.data_previsao_fim = this.formatDate(maxDate);

      this.agendamentoAddInicioPrev();
      this.agendamentoAddFimPrev();

      if (next == 0) {
        const nextElement: HTMLElement = document.querySelector(
          "#next-step-agendamento"
        );
        nextElement.click();
      }
    }
  }

  private guardarAgendamento() {
    if (
      this.agendamento.data_previsao_inicio == null ||
      this.agendamento.data_previsao_inicio == ""
    ) {
      this.configService.showAlert(
        "Digite a Data Início / Prevista",
        "alert-danger",
        true
      );
    } else if (
      this.agendamento.data_previsao_fim == null ||
      this.agendamento.data_previsao_fim == ""
    ) {
      this.configService.showAlert(
        "Digite a Data Término / Prevista",
        "alert-danger",
        true
      );
    } else if (
      this.agendamento.data_previsao_inicio >=
      this.agendamento.data_previsao_fim
    ) {
      this.configService.showAlert(
        "Data de início não pode ser superior ou igual a Data de término",
        "alert-danger",
        true
      );
    } else if (
      this.agendamento.descricao == null ||
      this.agendamento.descricao == ""
    ) {
      this.configService.showAlert(
        "Digite a descrição do agendamento",
        "alert-danger",
        true
      );
    } else {
      this.isFinalizar = false;
      this.isDisabled = true;
      this.configService.loaddinStarter("start");

      let uploadData = new FormData();

      uploadData.append("ordemServico", JSON.stringify(this.ordemServico));
      uploadData.append("routerParamId", JSON.stringify(this.routerParamId));
      uploadData.append("agendamento", JSON.stringify(this.agendamento));
      uploadData.append(
        "recursosHumanos",
        JSON.stringify(this.recursosSelecionados)
      );
      uploadData.append(
        "consumiveis",
        JSON.stringify(this.consumiveisSelecionados)
      );
      uploadData.append("causas", JSON.stringify(this.causasArray));
      uploadData.append("sintomas", JSON.stringify(this.sintomasArray));
      uploadData.append(
        "departamentos",
        JSON.stringify(this.departamentoArray)
      );
      for (let file of this.fileAgendamento) {
        uploadData.append("fileAgendamento", file.file);
      }
      for (let file of this.fileList) {
        uploadData.append("file", file.file);
      }

      this.http
        .$__call("ordemservico/agendamento/create", uploadData)
        .subscribe(
          (res: HttpEvent<any>) => {

            switch (res.type) {
              case HttpEventType.Sent:
                console.log("Request has been made!");
                break;
              case HttpEventType.ResponseHeader:
                console.log("Response header has been received!");
                break;
              case HttpEventType.UploadProgress:
                this.progress = Math.round((res.loaded / res.total) * 100);
                break;
              case HttpEventType.Response:
                setTimeout(() => {
                  this.progress = 0;
                  this.fileList = [];
                }, 1500);
            }
            if (Object(Object(res).body).code) {
              if (Object(Object(res).body).code == 200) {
                this.isDisabled = true;
                this.validateButton = Object(Object(res).body).code;

                this.configService.showAlert(
                  Object(Object(res).body).message,
                  "alert-success",
                  true
                );
                this.clearFormInputs();
                this.ordemServico.success_ordem_servico = true;
                this.ordemServico.success_ot_sem_agendamento = true;
                this.ordemServico.agendamentoGerado = Object(
                  Object(res).body
                ).data.agendamentos.id;
              } else {
                this.configService.showAlert(
                  Object(Object(res).body).message,
                  "alert-danger",
                  true
                );
                this.isFinalizar = false;
                this.isDisabled = false;
              }
            }
            this.configService.loaddinStarter("stop");
          },
          (error) => {
            this.configService.loaddinStarter("stop");
            this.isFinalizar = false;
            this.isDisabled = false;
          }
        );
    }
  }

  private imprimirAgendamento(id) {
    this.configService.imprimirAgendamento(id, "Original", "imprimir");
  }

  private getColaboradores() {
    this.getAllColaboradores();
  }

  private getAllColaboradores() {
    this.colaboradores = [];

    this.configService.loaddinStarter("start");
    //this.pagination.search = (search == "" || search == null ? null : search);
    this.http
      .__call("colaborador/colaboradoresByFuncao", {
        start: 1,
        end: 10,
        search:
          this.recursosHumanos.colaborador_nome == "" ||
          this.recursosHumanos.colaborador_nome == null
            ? null
            : this.recursosHumanos.colaborador_nome,
        funcao_id: this.recursosHumanos.funcao_id,
      })
      .subscribe((res) => {
        this.colaboradores = Object(res).data.data;
        this.configService.loaddinStarter("stop");
      });
  }

  files: File[] = [];

  file() {
    this.filenout = document.getElementById("file-input");

    this.files.push(this.filenout.files);
    for (var i = 0; i < this.filenout.files.length; i++) {
      this.fileList.push({
        name: this.filenout.files[i].name,
        size: this.filenout.files[i].size,
        file: this.filenout.files[i],
      });
    }
  }

  fileAgendamentos() {
    this.filenout = document.getElementById("file-agendamento");

    this.files.push(this.filenout.files);
    for (var i = 0; i < this.filenout.files.length; i++) {
      this.fileAgendamento.push({
        name: this.filenout.files[i].name,
        size: this.filenout.files[i].size,
        file: this.filenout.files[i],
      });
    }
  }
  removeFileAgendamentos(value) {
    const index = this.fileAgendamento.indexOf(value);
    this.fileAgendamento.splice(index, 1);
  }

  removeFile(value) {
    const index = this.fileList.indexOf(value);
    this.fileList.splice(index, 1);
  }

  async downloads({ ext, url, nome, id, flag }: Ifile) {
    const documentStyles = document.documentElement.style;

    this.progress = id;
    this.progress_1 = flag;
    url = environment.app_url + url;

    axios({
      url: url,
      method: "post",
      responseType: "blob",
      async onDownloadProgress(progressEvent) {
        if (flag == "no") {
          this.progress = await Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          documentStyles.setProperty("--progress", `${this.progress}%`);
        } else {
          this.progress = await Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          documentStyles.setProperty("--progress", `${this.progress}%`);
        }
      },
    }).then((response) => {
      var binaryData = [];
      var index = nome.indexOf(".");
      var afterComa = nome.substr(index, nome.length);

      if (ext == "vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        nome = nome.replace(afterComa, ".xlsx");

      } else if (ext == "vnd.oasis.opendocument.text") {
        nome = nome.replace(afterComa, ".odt");
      }

      const res = response;

      binaryData.push(res.data);
      if (flag == "no") {
        this.progress = 0;
        documentStyles.setProperty("--progress", `${this.progress}%`);
        return saveAs(new Blob(binaryData, { type: res.data.type }), nome);
      }
      this.progress = 0;
      documentStyles.setProperty("--progress", `${this.progress}%`);
      const fileURL = window.URL.createObjectURL(
        new Blob(binaryData, { type: res.data.type })
      );
      window.open(fileURL, "_blank");
    });
  }

  private setCliente(client: any) {
    this.getContas(client);

    this.ordemServico.cliente_id = client.id;
    this.selectedCliente(client.id);
    (this.ordemServico.cliente_nome = client.nome), (this.view_client = false);
  }

  //START FACTURAÇÃO
  getProduto(search) {
    this.getServicos(search);
  }

  private listarProdutos(response: any) {
    /* if (response.total == 0 || response.total == null) {
      this.configService.showAlert("Não foi informado nenhum preço no produto", "alert-danger", true);
      return;
    } */
    this.facturacao.total = 0;
    let validar = 0;
    if (response.code == 400) {
      this.configService.showAlert(response.message, "alert-danger", true);
    } else {
      if (this.facturacao.produto.length >= 1) {
        if (response.valor_original) {
          for (let index = 0; index < this.facturacao.produto.length; index++) {
            const produt = this.facturacao.produto[index];
            if (produt.produto_id == response.produto_id) {
              this.facturacao.produto.splice(index, 1);
              this.facturacao.produto.splice(index, 0, response);
              validar = 1;
            }
          }
        }
        if (validar == 0) {
          this.facturacao.produto.push(response);
        }
      } else {
        this.facturacao.produto.push(response);
      }
      this.calcularTotal();
    }
  }

  private getMunicipios() {
    this.api.get("municipio/selectBox").subscribe((response) => {
      this.municipios = Object(response).data;
    });
  }
/*   private getResidencia() {

    this.view_local_consumo = true;

    this.http
      .__call("local-instalacao/getSelectLocalInstalacaosByRua", {
        rua_id: this._local_consumo.rua_id,
        start: 1,
        end: 15,
        search: this._local_consumo.moradia_numero,
      })
      .subscribe((response) => {
        this.local_consumos = Object(response).data.data;
        console.log(this.local_consumos)
        this.configService.loaddinStarter("stop");
      });
  } */

  private getResidencia() {

    this.view_local_consumo = true;

    this.http
      .__call("local-local-consumo/getSelectLocalConsumoByRua", {
        rua_id: this._local_consumo.rua_id,
        start: 1,
        end: 15,
        search: this._local_consumo.moradia_numero,
      })
      .subscribe((response) => {
        this.local_consumos = Object(response).data.data;
        console.log(this.local_consumos)
        this.configService.loaddinStarter("stop");
      });
  }

  private setLocalConsumo(item: any) {
    this._local_consumo.id = item.local_consumo_id;
    this._local_consumo.moradia_numero = item.moradia_numero;
    this._local_consumo.predio_nome =
      (item.cil != null ? "[" + item.cil + "] " : "[0000-000-000] ") +
      (item.is_predio
        ? "Prédio " +
        item.predio_nome +
        ", " +
        this.configService.decimalFormat(item.predio_andar) +
        "º Andar - Porta "
        : "Residência ") +
      this.configService.decimalFormat(item.moradia_numero);
    this._local_consumo.is_predio = item.is_predio;
    this._local_consumo.cil = item.cil;

    this._local_consumo.provincia_id = item.provincia_id;

    this._local_consumo.municipio_id = item.municipio_id;
    this._local_consumo.municipio = item.municipio;
    this._local_consumo.distrito_id = item.distrito_id;
    this._local_consumo.distrito = item.distrito;
    this._local_consumo.bairro_id = item.bairro_id;
    this._local_consumo.bairro = item.bairro;
    this._local_consumo.quarteirao_id = item.quarteirao_id;
    this._local_consumo.quarteirao = item.quarteirao;
    this._local_consumo.rua_id = item.rua_id;
    this._local_consumo.rua = item.rua;

    this.view_local_consumo = false;

    this.contrato.instalacao_sanitaria_qtd = item.instalacao_sanitaria_qtd;
    this.contrato.reservatorio_flag = item.reservatorio_flag;
    this.contrato.reservatorio_capacidade = item.reservatorio_capacidade;
    this.contrato.piscina_flag = item.piscina_flag;
    this.contrato.piscina_capacidade = item.piscina_capacidade;
    this.contrato.jardim_flag = item.jardim_flag;
    this.contrato.campo_jardim_id = item.campo_jardim_id;
    this.contrato.poco_alternativo_flag = item.poco_alternativo_flag;
    this.contrato.fossa_flag = item.fossa_flag;
    this.contrato.fossa_capacidade = item.fossa_capacidade;
    this.contrato.acesso_camiao_flag = item.acesso_camiao_flag;
    this.contrato.anexo_flag = item.anexo_flag;
    this.contrato.anexo_quantidade = item.anexo_quantidade;
    this.contrato.caixa_contador_flag = item.caixa_contador_flag;
    this.contrato.abastecimento_cil_id = item.abastecimento_cil_id;

    setTimeout(() => {
      this.selectBoxRuasByBairro();

      this.selectBoxDistritosByMunicipio(item.municipio_id);
      this.selectBoxQuarteiraosByBairro(item.bairro_id);
      this.selectBoxRuasByQuarteirao(item.quarteirao_id);

      this._local_consumo.quarteirao_id = item.quarteirao_id;
      this._local_consumo.rua_id = item.rua_id;

      this._local_consumo.cil = this._local_consumo.cil_flag
        ? this._local_consumo.predio_nome
        : item.cil;

    }, 1500);
  }

  private calcularTotal() {
    this.facturacao.total = 0;
    this.facturacao.totalSemImposto = 0;
    this.facturacao.totalComImposto = 0;
    //Calcula o Total da Factura
    for (let index = 0; index < this.facturacao.produto.length; index++) {
      this.facturacao.totalSemImposto +=
        this.facturacao.produto[index].linhaTotalSemImposto;
      this.facturacao.totalComImposto +=
        this.facturacao.produto[index].valorImposto;

      this.facturacao.total += this.facturacao.produto[index].total;
    }
    this.facturacao.pagamento.total_pago = this.facturacao.total;

    if (this.facturacao.total >= 0) {
      this.activeButtom.produto = true;
    } else {
      this.activeButtom.produto = false;
    }
  }

  deleteRowProdut(produto: any) {
    for (let i = 0; i < this.facturacao.produto.length; ++i) {
      if (this.facturacao.produto[i].produto_id === produto.produto_id) {
        this.facturacao.produto.splice(i, 1);
        this.calcularTotal();
      }
    }
  }

  private refreshProdutoId(produto: any) {
    this.produtoId = produto.servico_id;
    this.produto_selecionado = produto.servico_valor;
  }

  private refreshSerieId() {
    this.serieId = this.serie.nativeElement.value;
    if (Object(this.serieId) == "") {
      this.serieId = 0;
    }
  }

  private selectedSerie() {
    this.series.forEach((element) => {
      if (this.facturacao.serie.id == element.id) {
        if (element.sigla == "FT") {
          this.configService.listarBancos();
          this.configService.listarMoedas();
        }
        this.facturacao.serie.id = element.id;
        this.facturacao.serie.nome = element.nome;
        this.facturacao.serie.sigla = element.sigla;
        this.facturacao.serie.proximo_numero = element.proximo_numero;
      }
    });
  }

  private getServicos(search) {
    this.items_produtos = [];
    this.configService.loaddinStarter("start");
    this.pagination.search = search == "" || search == null ? "a" : search;
    this.pagination.contrato =
      this.facturacao.cliente.contrato_id == "" ||
      this.facturacao.cliente.contrato_id == null
        ? null
        : this.facturacao.cliente.contrato_id;

    this.http
      .__call("artigo/search", {
        pagination: this.pagination,
        search: search,
      })
      .subscribe((res) => {
        this.items_produtos = Object(res);
        this.configService.loaddinStarter("stop");
      });
  }

  private updateBancList(banco: any) {
    this.facturacao.pagamento.bancos.push(banco);
  }

  getAddProduto() {
    if (this.quantidade <= 0 || isNaN(this.quantidade)) {
      this.configService.showAlert(
        "Não foi informado uma quantidade valida",
        "alert-danger",
        true
      );
      return;
    }
    if (this.http.canActivateRouterLink("atribuir_desconto")) {
      if (!(this.desconto <= 100 && this.desconto >= 0)) {
        this.configService.showAlert(
          "Desconto valido, informe um valor no intervalo entre 0 e 100%",
          "alert-danger",
          true
        );
        return;
      }
    }
    this.configService.loaddinStarter("start");
    this.http
      .__call("artigo/pesquisar", {
        produto_id: this.produtoId,
        quantidade:
          this.quantidade < 0 || isNaN(this.quantidade) ? 1 : this.quantidade,
        desconto: this.desconto,
        cliente_id: this.clienteId,
        observacao: null,
        preco: this.preco,
      })
      .subscribe((res) => {
        this.listarProdutos(Object(res).data);
        if (this.produto_selecionado == 0) {
          //this.getServicos(this.search.nativeElement.value);
        }
        this.quantidade = 1;
        this.desconto = 0;
        this.configService.loaddinStarter("stop");
      });
  }

  isPagamento() {
    this.disabledPagamentoAdiantamento = false;
    this.facturacao.pagamento.valor_recebido = this.facturacao.total;
    this.facturacao.pagamento.referencia = null;
    this.facturacao.pagamento.data_pagamento = null;

    if (this.facturacao.serie.sigla == "FR") {
      var a: string = null;
      this.formasPagamentos.forEach((element) => {
        if (this.facturacao.pagamento.forma_pagamento_id == element.id) {
          if (
            element.designacao === "Adiantamento" ||
            element.designacao === "adiantamento"
          ) {
            a = element.designacao;
          }
        }
      });

      if (
        this.facturacao.pagamento.forma_pagamento_id != 1 &&
        this.facturacao.pagamento.referencia == null
      ) {
        if (a !== "Adiantamento") {
          this.disabledPagamento = false;
        } else {
          this.disabledPagamento = true;
          this.disabledPagamentoAdiantamento = true;
          this.facturacao.pagamento.is_adiantamento = 1;
        }
      } else {
        this.disabledPagamento = true;
      }
    }
  }

  private modal(id) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        html: `
              <h1><b>Deseja Continuar sem contrato</b></h1>
              <button awNextStep>continuar</button>
           `,
        title: "<strong></strong>",
        text: "Você não poderá reverter isso!",
        type: "error",
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: "Continuar",
      })
      .then((result) => {
        if (result.value) {
          swalWithBootstrapButtons.fire(
            "Deleted!",
            "Your file has been deleted.",
            "success"
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "Your imaginary file is safe :)",
            "error"
          );
        }
      });
  }

  private listarseries() {
    this.http
      .call_get("serie/loja/selectSerieLojaFacturaBox", null)
      .subscribe((data) => {
        let serie = Object(data).data.find((obj) => obj.sigla == "FT");

        this.facturacao.serie.id = serie.id;
        this.facturacao.serie = serie;

        this.series = Object(data).data;
        if (this.series.length == 1) {
          this.series.forEach((element) => {
            if (element.sigla == "FT") {
              this.configService.listarBancos();
              this.configService.listarMoedas();
            }

            this.facturacao.serie.id = element.id;
            this.facturacao.serie.nome = element.nome;
            this.facturacao.serie.sigla = element.sigla;
            this.facturacao.serie.proximo_numero = element.proximo_numero;
          });
        }
      });
  }

  private listarFormaPagamentos() {
    this.configService.loaddinStarter("start");
    this.http.call_get("formaPagamento/formas", null).subscribe((response) => {
      this.formasPagamentos = Object(response).data;
      this.configService.loaddinStarter("stop");
    });
  }

  private iniFacturacao() {
    this.facturacao = {
      cliente: {
        id: null,
        nome: null,
        contribuinte: null,
        morada: null,
        telefone: null,
        conta_id: null,
        contrato_id: null,
      },
      serie: {
        id: null,
        sigla: null,
        nome: null,
        proximo_numero: null,
      },
      produto: [],
      pagamento: {
        forma: null,
        valor_recebido: 0.0,
        total_valor_recebido: 0.0,
        troco: 0.0,
        total_pago: null,
        referencia: null,
        data_pagamento: null,
        forma_pagamento_id: null,
        bancos: null,
        adiantamentos: null,
        is_adiantamento: 0,
        linha_pagamentos: [],
      },
      totalComImposto: 0.0,
      totalSemImposto: 0.0,
      total: 0.0,
      success_facturacao: false,
      facturaGerada: null,
      data_vencimento: moment(Date()).format("YYYY-MM-DD").toString(),
      moeda: null,
    };
  }

  private reloadFacturacao() {
    location.reload();
  }

  private getContas(cliente) {
    this.contas = [];

    (this.local_consumo.cil = null),
      (this.local_consumo.numero_serie = null),
      (this.local_consumo.predio_nome = null);

    this.http
      .call_get("cliente/conta/selectBox/" + cliente.id, null)
      .subscribe((response) => {
        this.contas = Object(response).data;
        this.contas_clientes = Object(response).data;

        if (this.contas_clientes.length == 1) {
          this.default = this.contas_clientes[0].numero_conta;
          this.facturacao.cliente.conta_id = this.default;
        }

        //this.getContaContrato();
      });
  }

  private getContaContrato() {
    this.activeButtom.cliente = false;
    this.configService.loaddinStarter("start");
    this.http
      .call_get("conta/contratos/" + this.ordemServico.cliente_conta_id, null)
      .subscribe((response) => {
        this.contratos_conta_cliente = Object(response).data;

        if (this.contratos_conta_cliente.length == 1) {
          this.default = this.contratos_conta_cliente[0].id;
        }

        this.configService.loaddinStarter("stop");
      });
  }

  private contrato = null; // conta a ser visualizada no ecra das operaçoes

  private contaServicoSeleciona() {
    this.facturacao.cliente.contrato_id = this.ordemServico.cliente_contrato_id;

    this.contrato = this.contratos_conta_cliente.find(
      (element) => element.id == this.ordemServico.cliente_contrato_id
    );
  }

  private selectedCliente(id) {
    this.facturacao.cliente.id = id;
    this.clientes.forEach((element) => {
      if (this.facturacao.cliente.id == element.id) {
        this.facturacao.cliente.id = element.id;
        this.facturacao.cliente.nome = element.nome;
        this.facturacao.cliente.contribuinte =
          element.numero_identificacao || element.nif;
        this.facturacao.cliente.telefone = element.telefone;
        this.facturacao.cliente.morada = element.morada;
        this.activeButtom.cliente = true;
      }
    });
  }

  private clienteSemOrComConta = 0;

  selectedClienteComConta(n: number) {
    this.clienteSemOrComConta = n;
    this.listarseries();
    this.listarFormaPagamentos();
    //this.getServicos(null);
    this.getMovimentoAdiantamento();
    this.facturacao.produto = [];
  }

  private addLinhaPagamentos() {
    var validar = 0;

    if (this.facturacao.serie.sigla == "FR") {
      var a: string = null;
      this.formasPagamentos.forEach((element) => {
        if (this.facturacao.pagamento.forma_pagamento_id == element.id) {
          if (
            element.designacao === "Adiantamento" ||
            element.designacao === "adiantamento"
          ) {
            a = element.designacao;
            this.facturacao.pagamento.referencia = "000000";
            this.facturacao.pagamento.data_pagamento = moment(
              new Date()
            ).format("YYYY-MM-DD");
          }
        }
      });

      if (this.facturacao.pagamento.valor_recebido <= 0) {
        this.configService.showAlert(
          "Informa um valor de superior a zero",
          "alert-danger",
          true
        );
      } else if (
        this.facturacao.pagamento.forma_pagamento_id == null ||
        this.facturacao.pagamento.forma_pagamento_id == "null"
      ) {
        this.configService.showAlert(
          "Informa uma forma pagamento valida",
          "alert-danger",
          true
        );
      } else if (
        this.facturacao.pagamento.forma_pagamento_id != 1 &&
        this.facturacao.pagamento.referencia == null
      ) {
        if (a !== "Adiantamento") {
          this.configService.showAlert(
            "Referencia a bancaria",
            "alert-danger",
            true
          );
        }
      } else if (
        this.facturacao.pagamento.forma_pagamento_id != 1 &&
        this.facturacao.pagamento.data_pagamento == null
      ) {
        if (a !== "Adiantamento") {
          this.configService.showAlert(
            "Informa a data de pagamento valida!",
            "alert-danger",
            true
          );
        }
      } else {
        this.formasPagamentos.forEach((element) => {
          if (this.facturacao.pagamento.forma_pagamento_id == element.id) {
            this.facturacao.pagamento.forma = element.designacao;
          }
        });

        var linha = {
          designacao: this.facturacao.pagamento.forma,
          valor_recebido: this.facturacao.pagamento.valor_recebido,
          data_pagamento: this.facturacao.pagamento.data_pagamento,
          forma_pagamento_id: this.facturacao.pagamento.forma_pagamento_id,
          referencia: this.facturacao.pagamento.referencia,
        };

        if (this.facturacao.pagamento.linha_pagamentos.length >= 1) {
          for (
            let index = 0;
            index < this.facturacao.pagamento.linha_pagamentos.length;
            index++
          ) {
            const l = this.facturacao.pagamento.linha_pagamentos[index];
            if (l.forma_pagamento_id == linha.forma_pagamento_id) {
              this.facturacao.pagamento.linha_pagamentos.splice(index, 1);
              this.facturacao.pagamento.linha_pagamentos.splice(
                index,
                0,
                linha
              );
              validar = 1;
            }
          }
          if (validar == 0) {
            this.facturacao.pagamento.linha_pagamentos.push(linha);
          }
        } else {
          this.facturacao.pagamento.linha_pagamentos.push(linha);
        }

        this.calcularTroco();
        this.facturacao.pagamento.forma = null;
        this.facturacao.pagamento.valor_recebido = 0.0;
        this.facturacao.pagamento.referencia = null;
        this.facturacao.pagamento.data_pagamento = null;
        this.facturacao.pagamento.forma_pagamento_id = null;
        this.adiantamento == null;
      }
    }
  }

  private calcularTroco() {
    this.facturacao.pagamento.troco = 0;
    var valor = 0;
    this.facturacao.pagamento.linha_pagamentos.forEach((element) => {
      valor += element.valor_recebido;
    });
    if (valor > 0) {
      this.facturacao.pagamento.troco = valor - this.facturacao.total;
      if (valor <= this.facturacao.total) {
        this.facturacao.pagamento.troco = 0;
      }
    } else {
      this.facturacao.pagamento.troco = 0;
    }
    this.facturacao.pagamento.total_valor_recebido = valor;
  }

  deleteRowFormaPagamento(linha: any) {
    for (
      let i = 0;
      i < this.facturacao.pagamento.linha_pagamentos.length;
      ++i
    ) {
      if (
        this.facturacao.pagamento.linha_pagamentos[i].forma_pagamento_id ===
        linha.forma_pagamento_id
      ) {
        if (
          this.facturacao.pagamento.linha_pagamentos[i].designacao !==
          "Adiantamento"
        ) {
          this.facturacao.pagamento.adiantamentos = [];
        }
        this.facturacao.pagamento.linha_pagamentos.splice(i, 1);
        this.facturacao.pagamento.total_valor_recebido -= linha.valor_recebido;
        this.facturacao.pagamento.troco =
          this.facturacao.pagamento.total_valor_recebido <
            this.facturacao.total ||
          this.facturacao.pagamento.total_valor_recebido == 0
            ? 0
            : this.facturacao.pagamento.total_valor_recebido -
              this.facturacao.total;
        this.calcularTotal();
      }
    }
  }

  private listMovimento: any = [];
  private adiantamento: string = null;

  private listMovimentoAdiantamentoCliente() {
    this.adiantamento = null;
    this.formasPagamentos.forEach((element) => {
      if (this.facturacao.pagamento.forma_pagamento_id == element.id) {
        if (
          element.designacao === "Adiantamento" ||
          element.designacao === "adiantamento"
        ) {
          this.adiantamento = element.designacao;
        }
      }
    });
  }

  private getMovimentoAdiantamento() {
    this.facturacao.pagamento.adiantamentos = [];
    this.configService.loaddinStarter("start");
    this.http
      .__call("adiantamento/factura/adiantamentoFactura", {
        cliente_id: this.facturacao.cliente.id,
      })
      .subscribe((response) => {
        this.listMovimento = Object(response).data;
        this.configService.loaddinStarter("stop");
      });
  }

  setAdianta(ad: any) {
    if (this.facturacao.pagamento.adiantamentos.length == 0) {
      this.facturacao.pagamento.adiantamentos.push(ad);
    } else {
      var validar = 0;
      for (let i = 0; i < this.facturacao.pagamento.adiantamentos.length; ++i) {
        if (this.facturacao.pagamento.adiantamentos[i].id === ad.id) {
          this.facturacao.pagamento.adiantamentos.splice(i, 1);
          validar = 1;
        }
      }

      if (validar === 0) {
        this.facturacao.pagamento.adiantamentos.push(ad);
      }
    }
    var valor = 0;
    for (let i = 0; i < this.facturacao.pagamento.adiantamentos.length; ++i) {
      valor += this.facturacao.pagamento.adiantamentos[i].valor;
    }
    this.facturacao.pagamento.valor_recebido = valor;
  }

  private facturar() {
    if (this.facturacao.cliente.id == null) {
      this.configService.showAlert(
        "É obrigatório fornecer um cliente",
        "alert-danger",
        true
      );
    } else if (this.facturacao.serie.id == null) {
      this.configService.showAlert(
        "É obrigatório fornecer uma serie",
        "alert-danger",
        true
      );
    } else if (this.facturacao.produto.length == 0) {
      this.configService.showAlert(
        "É obrigatório fornecer produtos para a factura",
        "alert-danger",
        true
      );
    } else {
      if (this.facturacao.serie.sigla == "FR") {
        if (this.facturacao.pagamento.linha_pagamentos.length == 0) {
          this.configService.showAlert(
            "É obrigatório fornecer uma forma de pagamento",
            "alert-danger",
            true
          );
        } else if (
          this.facturacao.pagamento.total_valor_recebido <
            this.facturacao.total ||
          this.facturacao.pagamento.total_valor_recebido == 0
        ) {
          this.configService.showAlert(
            "Informa um valor de entrada superir ou igual ao valor total a pagar",
            "alert-danger",
            true
          );
        } else {
          this.guardarFactura();
        }
      } else {
        this.guardarFactura();
      }
    }
  }

  private guardarFactura() {
    this.isFinalizar = false;
    this.configService.loaddinStarter("start");
    this.closebutton.nativeElement.disabled = true;

    let dataActual = moment(Date()).format("YYYY-MM-DD");

    if (this.facturacao.data_vencimento < dataActual) {
      this.configService.showAlert(
        "Data de Vencimento não pode ser inferior que a Data Actual",
        "alert-danger",
        true
      );
      this.configService.loaddinStarter("stop");
      this.isFinalizar = true;
    } else {
      const _validarAd = this.facturacao.pagamento.linha_pagamentos.find(
        (l) => l.designacao === "Adiantamento"
      );
      this.facturacao.pagamento.adiantamentos =
        _validarAd == null ? [] : this.facturacao.pagamento.adiantamentos;

      let data = {
        produtos: this.facturacao.produto,
        documento: "Factura",
        cliente_id: this.facturacao.cliente.id,
        total: this.facturacao.total,
        serie_id: this.facturacao.serie.id,
        totalComImposto: this.facturacao.totalComImposto,
        totalSemImposto: this.facturacao.totalSemImposto,
        observacao: this.observacao.nativeElement.value,
        leitura: this.leitura.nativeElement.value,
        numero_origem_factura: null,
        data_origem_factura: null,
        data_vencimento: this.facturacao.data_vencimento,
        pagamento: this.facturacao.pagamento,
        moeda: this.facturacao.moeda,
        valor_aberto: this.facturacao.total,
        conta_id: this.facturacao.cliente.conta_id,
        contrato_id: this.facturacao.cliente.contrato_id,
      };

      this.http
        .__call("factura/create", data)
        .pipe(
          finalize(() => {
            this.configService.loaddinStarter("stop");
            this.validateButton = 200;
          })
        )
        .subscribe(
          (res) => {
            this.iniFacturacao();
            this.facturacao.success_facturacao = true;
            this.facturacao.facturaGerada = Object(res).data.id;
            this.ordemServico.factura_id = this.facturacao.facturaGerada;

            this.createOrdemServico();
          },
          (error) => {
            this.configService.loaddinStarter("stop");
            this.isFinalizar = true;
          }
        );
    }
  }
  //END FACTURAÇÃO
}
