import { Component, OnInit,  Input,Output, EventEmitter,OnChanges, SimpleChange} from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';

@Component({
  selector: 'app-visualizar-flat-rate',
  templateUrl: './visualizar-flat-rate.component.html',
  styleUrls: ['./visualizar-flat-rate.component.css']
})
export class VisualizarFlatRateComponent implements OnInit {
  @Input() modalVisualFlateRate: string = "modalVisualFlateRate";
  @Input() selectForms: any;
  private flateRate=[];
  private servico_id
  flatRateValor
  flatRateOrigem
  flatRateCapacidade
  constructor(private http: HttpService) { 

  }


  ngOnInit() {
    
    

  }
  ngOnChanges(){
    if(this.selectForms.length==0){
      console.log(" é nullo");
    }else{
      console.log(this.selectForms)
        this.selectForms.forEach(element => {

          this.http.__call("flate_rate/visualizar", {servicoId:element.servicoID}).subscribe(response=>{
           
        
            if(Object(response).data.length==0){

            }else{
              this.flateRate=[];
              this.flateRate.push(Object(response).data)
            }    
          
      
          })
          
        });
    
    }


 
  }

  getID(id, valor, origem, capacidade){
           this.flatRateValor= valor;
          this.flatRateOrigem= origem;
          this.flatRateCapacidade= capacidade;

    this.servico_id= id

  }


}

