import { Component,ElementRef,OnInit, ViewChild } from '@angular/core';
import { ConfigService } from 'src/app/providers/config/config.service';
import { HttpService } from 'src/app/providers/http/http.service';

@Component({
  selector: 'app-estrutura-moradas',
  templateUrl: './estrutura-moradas.component.html',
  styleUrls: ['./estrutura-moradas.component.css']
})
export class EstruturaMoradasComponent implements OnInit {
  municipios:any=[]
  distritos:any=[]
   ruas:any=[]
  bairros:any=[]
  quarteiroes:any=[]

  titleMunicipio:string="-----"
  titleDistrito:string="-----"
  titleBairro:string="-----"
  titleQuarteirao:string="-----"
  @ViewChild("distrito") distrito:ElementRef
  @ViewChild("bairro") bairro:ElementRef
  @ViewChild("quarteirao") quarteirao:ElementRef
  
  
  constructor(private http: HttpService,
    private configService: ConfigService) {
    this.titleMunicipio
    this.titleDistrito
    this.titleBairro
    this.titleQuarteirao
   }
  
  ngOnInit() {
   this.getMunicipios()
  }

  runTitle:object={
    distrito:(title)=>{
      this.titleDistrito=title
      this.titleBairro="------"
      this.titleQuarteirao="------"
    },
    bairro:(title)=>{
      this.titleBairro=title
      this.titleQuarteirao="------"
    },
    quarteirao:(title)=>{
      this.titleQuarteirao=title
    }
  }

  adicionarTituloMinicipio({nome,id}){
    this.titleMunicipio=nome
    this.ruas=[]
    this.bairros=[]
    this.quarteiroes=[]
    this.getDistritosByMunicipio(id)
  }
  adicionarTitulo({nome},title){ 
   
    this.runTitle[title](nome)
    console.log(nome,title)
   
  }
  
  private getMunicipios() {
    this.configService.loaddinStarter('start');
    this.http.call_get('municipio/selectBox',null)
    .subscribe(
      response => {
             this.municipios = Object(response).data;
             this.configService.loaddinStarter('stop');
      }
    );
    
  }

  private getDistritosByMunicipio(id){
    this.configService.loaddinStarter('start');
    this.ruas=[]
    this.http.call_get('distrito/getDistritosByMunicipio/' + id, this.http.filters).subscribe(
      response => {
        this.distritos = Object(response).data;
        this.configService.loaddinStarter('stop');
      }
    );
    
  }

  private getRuasByQuarteiraos(id) {
    this.configService.loaddinStarter('start');
    this.http.call_get('rua/getRuasByBairro/' + id,null).subscribe(
      response => {
        this.ruas = Object(response).data;
        this.configService.loaddinStarter('stop');
      }
    );
    
  }
  
  private getBairrosByDistrito(id) {
    this.configService.loaddinStarter('start');
    this.ruas=[]
    this.quarteiroes=[]
    this.http.call_get('bairro/getBairrosByDistrito/' + id, null).subscribe(
      response => {
        this.bairros = Object(response).data;
        this.configService.loaddinStarter('stop');
      }
    );
    
  }

  private getQuarteiraosByBairro(id) {
    this.configService.loaddinStarter('start');
    this.ruas=[]
    this.http.call_get('quarteirao/getQuarteiraosByBairro/' + id, null).subscribe(
      response => {
        this.quarteiroes = Object(response).data;
        this.configService.loaddinStarter('stop');
      }
    );
    
  }


  toggleMenuSecound(event: any): void {
    let currentAction = event.currentTarget;

    $(".report-nav").removeClass("active");
    $(".direct-chat-text").removeClass("active");
    
    $(currentAction).addClass("active");
    $(currentAction).children(":first").children(":first").addClass("active");
  }

   objectoMorada:Object={
      distrito:(currentAction)=>{
      var distrito = this.distrito.nativeElement.children
     
      for (const iterator of distrito) {
        iterator.classList.remove("active")
      }
      $(currentAction).addClass("active")
      
     },
     bairro:(currentAction)=>{
      var bairro = this.bairro.nativeElement.children
      for (const iterator of bairro) {
        iterator.classList.remove("active")
      }
      $(currentAction).addClass("active")
      
    },
    quarteirao:(currentAction)=>{
      var quarteirao = this.quarteirao.nativeElement.children
      for (const iterator of quarteirao) {
        iterator.classList.remove("active")
      }
      $(currentAction).addClass("active")
      
    }
  }

  toggleMenuItem(event: any,flag): void {
    let currentAction = event.currentTarget;

      if(this.objectoMorada[flag]){
        this.objectoMorada[flag](currentAction)
      }
   
    $(currentAction).addClass("active")  
  }
}
