import { User } from './../../../models/user';
import { Idle } from '@ng-idle/core';
import { ReportFacturacaoGestorComponent } from './../../relatorios/financeiro/report-facturacao-gestor/report-facturacao-gestor.component';
import { DOCUMENT, Time } from '@angular/common';
import { Component, ElementRef, Inject, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ConfigService } from 'src/app/providers/config/config.service';
import { HttpService } from 'src/app/providers/http/http.service';
import { ExcelService } from 'src/app/services/excel.service';
import { ExcelAutoService } from 'src/app/services/excel/excel-auto.service';
import { toDate, format } from 'date-fns-tz'
import Swal from 'sweetalert2';
import { forkJoin, Observable } from 'rxjs';
import { ImpressaoPdfService } from './service/impressao-pdf.service';
import { saveAs } from 'file-saver';
import { HttpEvent, HttpEventType } from '@angular/common/http';
const axios = require('axios')
import { environment } from 'src/environments/environment';
import { finalize } from 'rxjs/operators';
import { ApiService } from 'src/app/providers/http/api.service';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ConfigModuloService } from 'src/app/services/config-modulo.service';
import { ContratoService } from '../../reports/contrato/contrato.service';
import { NovaLigacaoService } from './nova-ligacao.service';
import { th } from 'date-fns/locale';
import { ContratoLundasulService } from '../../reports/contrato-lundasul/contrato-lundasul.service';
import { ContratoLundanorteService } from '../../reports/contrato-lundanorte/contrato-lundanorte.service';
import { LocalInstalacaoService } from '../../ordemservico/executar-ordem-servico/local-instalacao.service';

//import 'sweetalert2/src/sweetalert2.scss';
interface Ifile {
  ext: string,
  nome: any,
  url: string,
  id: number,
  flag: string
}
@Component({
  selector: 'app-registro-nova-ligacao',
  templateUrl: './registro-nova-ligacao.component.html',
  styleUrls: ['./registro-nova-ligacao.component.css']
})
export class RegistroNovaLigacaoComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild("observacao") observacao;
  @ViewChild("leitura") leitura;
  @ViewChild("qtd") qtd;
  @ViewChild("search") search;
  @Input() minusculaTitle: string = "Ordem de Trabalho";
  @Input() maiusculaTitle: string = "ORDEM DE TRABALHO";

  @Input() ordem_url: string = "ordemservico";


  loading: boolean = false;
  disableButton: boolean = true;
  private isDisabled: boolean = false;
  createForm() {

    this.simpleFormCliente = this._formBuilder.group({
      id: null,
      historico_id: null,
      cliente: [null, [Validators.required]],
      cliente_id: [null],
      cliente_telefone: [null],
      estado_id: [null, [this.matchValidator.bind(this)]],
      estado_orcamento_id: [null],
      descricao: ['',
        [this.matchValidator.bind(this)]
      ],
      data: [this.DateTimeZone(), [Validators.required]],
      time: this.DateTimeZone(),
      conta: [null],
      contrato_id: [null],
      hostory_id: null,
      tipo_ligacao_id: [null],
      motivo: [null, [this.matchValidator.bind(this)]],
      conta_id: [null],
      orcamento_id: null,
      factura_id: null,
    })
  }

  filenout
  fileList = []
  lojas = []
  title = 'templent';
  formDate: FormData;
  orderForm
  submitted = false;
  private estadosConta = []
  private historicoNovaLigacao = []
  private predio_flag: boolean = false;
  private sem_produto_show: boolean = false;

  private showCheckServicos_flag: boolean = false;
  private showCheckOT_flag: boolean = false;
  private checkAllServicos_flag: boolean = false;
  private checkConfirmServico_flag: boolean = false;
  is_orcameto: boolean = true
  private isFinalizar: boolean = true;
  //private contrato = null;
  public filter = {
    municipio_id: null,
  }
  public filters = {
    permission: null,
    search: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    pagination: {
      perPage: 5,// Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima,
      lastPage: null,
      total: null // defini o total de registo da
    },
    filter: null // subelemente do filter
    , is_allexel: false
  }
  public filtersComunca = {
    search: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    pagination: {
      perPage: 5,// Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima,
      lastPage: null,
      total: null // defini o total de registo da BD
    },
    filter: null // subelemente do filter
    , is_allexel: false
  }

  @ViewChild("valor") valor;
  @ViewChild("serie") serie;

  @ViewChild("modalAppendServico") modalAppendServico: ModalDirective;
  @ViewChild("confirmFacturacaoModal") confirmFacturacaoModal: ModalDirective;
  @ViewChild("mediaConsumoModal") mediaConsumoModal: ModalDirective;

  public currentUser: any;
  private dataContratoSaved: any;
  private tarifa_fixa_modal_view: boolean = false;
  private cil_disabled_view: boolean = false;
  nova_ligacao_selecionada : any

  private clientes: any = [];
  private cliente: any;
  private _cliente: any = {
    nome: ''
  }
  private tipo_contratos: any = [];
  private tipo_medicaos: any = [];
  private niveis_sensibilidade: any = [];
  private tarifarios: any = [];
  private tipologia_clientes: any = [];
  private centro_custo: any = [];
  private objecto_contratos: any = [];
  private motivo_recisaos: any = [];

  private tipo_mensagems: any = [];
  private campo_jardims: any = [];
  private abastecimento_cils: any = [];

  private mensagem_title: string = "Mensagem";
  private servico_modal_title: string = "Associar Serviço";

  private edit_servico_flag: boolean = false;
  private clienteId: number;
  private serieId: number;
  private formasPagamentos: any = [];
  private validarFacturaRecibo: boolean = false;
  private addValorClasse: boolean = true;
  private empresa: any;

  private url_params: any;

  private local_instalacao = {
    id: null,
    moradia_numero: null,
    is_predio: null,
    predio_id: null,
    predio_nome: null,
    latitude: null,
    longitude: null,
    rua_id: null,
    is_active: null,
    user_id: null,
  };

  private provincias: any = [];
  private municipios: any[];
  private distritos: any[];
  private quarteiraos: any[];
  private bairros: any[];

  private series: any[];
  private servicos: any = [];

  private local_consumos: any = [];
  private ruas: any[];
  private contas_clientes: any = [];
  private _contas_clientes: any = [];
  private servicos_conta_cliente: any = [];
  private numero_telefone: string;
  private searchCliente: string;
  private contrato_validated: boolean = false;
  private contrato_edit: boolean = false;
  TipoArray: any[] = []
  private dados_validated: boolean = false;

  private contrato = {
    id: null,
    cliente_id: null,
    cliente_nome: null,
    cliente_morada: null,
    cliente_provincia: null,
    cliente_telefone: null,

    cliente_email: null,
    tipo_identidade: null,
    numero_identificacao: null,
    centro_custo_id: null,

    genero: null,
    conta_id: null,
    tipo_contracto_id: null,
    tipo_contrato: null,
    tipo_medicao_id: null,
    tipo_medicao: null,
    tipo_medicao_slug: null,
    media_consumo: 0,
    tipo_facturacao_id: null,
    tipo_facturacao: null,
    tipologia_cliente_id: null,
    tipologia_cliente: null,
    tipologia_cliente_juro_mora: null,
    tipologia_cliente_sujeito_corte: null,
    tipologia_cliente_caucao: null,
    nivel_sensibilidade_id: null,
    nivel_sensibilidade: null,
    objecto_contrato_id: null,
    objecto_contrato: null,
    tarifario: null,
    tarifario_id: null,
    classe_tarifario: null,
    classe_tarifario_consumo_minimo: null,
    classe_tarifario_consumo_maximo: null,
    numero_habitantes: null,
    numero_utilizadores: null,
    data_inicio: null,
    data_fim: null,
    morada_correspondencia_flag: false,
    morada_contrato: null,

    cil: null,

    provincia_id: null,
    municipio_id: null,
    bairro_id: null,
    rua_id: null,

    classe_tarifario_id: null,

    motivo_recisao_id: null,
    motivo_recisao: null,
    motivo_recisao_flag: null,
    novo_contrato_cil: null,
    rescindir_contrato_cil: null,

    tipo_mensagem_id: null,
    tipo_mensagem: null,
    tipo_mensagem_slug: null,
    mensagem: null,

    instalacao_sanitaria_qtd: null,
    reservatorio_flag: null,
    reservatorio_capacidade: null,
    piscina_flag: null,
    piscina_capacidade: null,
    jardim_flag: null,
    campo_jardim_id: null,
    campo_jardim: null,
    poco_alternativo_flag: null,
    fossa_flag: null,
    fossa_capacidade: null,
    acesso_camiao_flag: null,
    anexo_flag: null,
    anexo_quantidade: null,
    caixa_contador_flag: null,
    abastecimento_cil_id: null,
    abastecimento_cil: null,
    caucao: null,
    caucao_recebida: null,
  };

  private dadosContratoValid: boolean = false;
  private dadosLocalConsumoValid: boolean = false;


  private view_local_consumo_propierties: boolean = false;

  private conta = {
    id: null,
    numero_conta: null,
    descricao: " ",
    agencia_id: null,
    moeda_id: null,
    estado: 1,
    tipo_facturacao_id: null,
    dataEstado: null,
    estado_actual: null,
    cliente_id: null,
    contrato_id: null
  };

  private tarifario = {
    id: null,
    classe_tarifario_id: null,
  };

  private facturacao = {
    cliente: {
      id: null,
      nome: null,
      contribuinte: null,
      morada: null,
      telefone: null,
      conta_id: null,
      contrato_id: null,
      genero: null,
      email: null,
      tipo_cliente_id: null,
      tipo_cliente: null,
      tipo_cliente_slug: null,
      documento_id: null,
      gestor_cliente_id: null,
      servico_id: null,
    },
    serie: {
      id: null,
      sigla: null,
      nome: null,
      proximo_numero: null,
      documento_id: null,
      documento: null,
      movimento: null,
      tipo_movimento: null

    },
    produto: [],
    servicos: [],
    pagamento: {
      forma: null,
      valor_recebido: 0.0,
      total_valor_recebido: 0.0,
      troco: 0.0,
      total_pago: null,
      referencia: null,
      data_pagamento: null,
      forma_pagamento_id: null,
      bancos: [],
      adiantamentos: [],
      is_adiantamento: 1,
      linha_pagamentos: [],
    },
    totalComImposto: 0.0,
    totalSemImposto: 0.0,
    total: 0.0,
    success_facturacao: false,
    facturaGerada: null,
    data_vencimento: moment(Date()).format("YYYY-MM-DD").toString(),
    moeda: null,
    total_com_imposto: 0.0,
    total_sem_imposto: 0.0,
    valor_total_imposto: 0.0,

    total_facturar: 0.0,

    divida_cliente: 0,
    divida_local_consumo: 0,
    divida_total: 0,

    processado: false,
  };

  private orcamento = {
    cliente: {
      id: null,
      nome: null,
      contribuinte: null,
      morada: null,
      telefone: null,

      conta_id: null,
      contrato_id: null
    },
    serie: {
      id: null,
      sigla: null,
      nome: null,
      proximo_numero: null
    },
    produto: [],
    pagamento: {
      forma: null,
      valor_recebido: 0.0,
      total_valor_recebido: 0.0,
      troco: 0.0,
      total_pago: null,
      referencia: null,
      data_pagamento: null,
      forma_pagamento_id: null,
      bancos: [],
      adiantamento: [],
      is_adiantamento: 0,
      linha_pagamentos: []
    },
    totalComImposto: 0.0,
    totalSemImposto: 0.0,
    total: 0.0,
    success_orcamento: false,
    facturaGerada: null,
    data_vencimento: null,
    moeda: null
  }

  private ordemServico = {
    descricao: null,
    nivel_1: null,
    nivel_2: null,
    nivel_3: null,
    nivel_um_descricao: null,
    nivel_dois_descricao: null,
    nivel_tres_descricao: null,
    tipo_id: null,
    cliente_conta_id: null,
    cliente_contrato_id: null,
    cliente_nome: null,
    ot_trigger_flag: null,
    success_ordem_servico: false,
    success_ot_sem_agendamento: false,
    facturacao_antecipada: false,
    factura_id: false,
    sintoma_id: null,
    classificacao_id: null,
    prioridade_id: null,
    data_prevista: null,
    causa_id: null,
    sla: null,
    titulo: null,
    codigo: null,
    agendamentoGerado: null,
    origem: "TECNICA",
    file: null
  }




  private classe_tarifarios: any = [];
  private local_consumo = {
    id: null,
    moradia_numero: null,
    is_predio: null,
    predio_nome: null,
    predio_andar: null,
    numero_serie: null,
    cil: null,
    cil_flag: true,
    rua_id: null,
    rua: null,
    bairro_id: null,
    bairro: null,
    has_quarteirao: false,
    quarteirao_id: null,
    quarteirao: null,
    has_distrito: false,
    distrito_id: null,
    distrito: null,
    municipio_id: null,
    municipio: null,
    provincia_id: null,
    is_active: null,
    user_id: null,
  };


  private _facturacao = {
    cliente: {
      id: null,
      nome: null,
      telefone: null,
      tipo_identidade_id: null,
      tipo_identidade: null,
      tipo_facturacao_id: null,
      tipo_facturacao: null,
      numero_identificacao: null,
      genero: null,
      email: null,
      tipo_cliente_id: null,
      tipo_cliente: null,
      tipo_cliente_slug: null,
      municipio: null,
      morada: null,
      direccao: null,
      direccao_id: null,
      gestor_cliente_id: null,
      gestor_cliente: null,
      gestor_cliente_telefone: null,

      conta_id: null,
      servico_id: null,
    },
    servicos: [],
    pagamento: {
      forma: null,
      valor_recebido: 0.0,
      total_valor_recebido: 0.0,
      troco: 0.0,
      total_pago: null,
      referencia: null,
      data_pagamento: null,
      forma_pagamento_id: null,
      bancos: [],
      adiantamento: [],
      is_adiantamento: 0,
      linha_pagamentos: [],
    },
    serie: {
      id: null,
      nome: null,
      proximo_numero: null,
      documento_id: null,
      documento: null,
      sigla: null,
      movimento: null,
      tipo_movimento: null,
    },
    total_com_imposto: 0.0,
    total_sem_imposto: 0.0,
    valor_total_imposto: 0.0,

    total_facturar: 0.0,

    divida_cliente: 0,
    divida_local_consumo: 0,
    divida_total: 0,

    processado: false,
    facturaGerada: null,
    data_vencimento: null,
    moeda: null,
  };

  private detalhe_contrato = {
    flag: false,
    collapse: "row collapsed",
    class: "panel-collapse collapse",
  };

  private servico = {
    servico_id: null,
    servico: null,
    servico_valor: null,
    servico_valor_load: null,
    quantidade: 1,
    imposto_id: null,
    imposto: null,
    imposto_codigo: null,
    imposto_valor: null,
    is_editable: false,
    facturado: false,
    tipo_produto_id: null,
    tipo_produto: null,
    incidencia_id: null,
    incidencia: null,
  };

  private classe_tarifario = {
    id: null,
    tarifario_id: null,
    produto_id: null,
    descricao: null,
    valor: null,
    ordem: null,
    tarifa_variavel: null,
    tarifa_fixa_mensal: null,
    tarifa_intervalo: null,
    consumo_minimo: null,
    consumo_maximo: null,
  };

  private causasArray: any = [];
  private sintomasArray: any = [];
  private localUrl: any;
  private largura: any;
  private altura: any;
  private associacao_cliente = null;
  private items_produtos: any = [];
  private _items_produtos: any = [];
  items: any = [];
  items1: any = [];
  estado: any = [];
  download: any = [];
  nivels: any = [];
  tipos: any = [];
  motivo: any = []
  encamiamento: any = [];
  origems: any = [];
  categorias: any = [];
  contas: any = [];
  ot_tipos: any = [];
  anexos: any = [];
  private associacao_equipamento = null;
  private cliente_contrato_id = null;
  dropdownSettingsDepartamentos = {};
  private departamentoArray: any = [];
  private dropdownDepartamentos: any = [];
  public estado_flag: string = ''
  public _estado_flag: string = ''
  dropdownSettingsTipo: any = {}
  private itemsOrdem: any = []
  private is_sintoma: boolean = false;
  private is_causa: boolean = false;
  private is_titulo: boolean = false;
  private editable: boolean = false;
  contratos: any = [];
  prioridades: any = [];
  classificacoes: any = [];
  nivel_ids: any = [];
  simpleFormCliente: FormGroup
  simpleFormCreateOrEdit: FormGroup
  formHistorico: FormGroup
  validateState: any = []
  historico: any = []
  CurrentDate = new Date();
  view_client = true;
  stateValidate: boolean
  valiateOsFild: Boolean
  timeOutput: any
  now: string
  today: string
  flag: number
  flags: string = ''
  private _series: any = []
  disableEstado: boolean = true
  private is_contrato_id: boolean = false;
  datatime: string
  times: string = moment(this.CurrentDate).format('H') + ":" + moment(this.CurrentDate).format('m')
  private contratos_conta_cliente: any = [];
  private pagination = {
    start: 1,
    end: 10,
    search: null,
    contrato: null,
    municipio_id: "T"
  };
  private validateButton: any = null;
  private produto_selecionado = null;
  private factura_local_consumo_dividas: any = [];
  private clienteSemOrComConta = 0
  private produtoId: number;
  private activeButtom = {
    cliente: false,
    produto: false,
  };
  private factura_cliente_dividas: any = [];
  private orcamento_flag: boolean
  private quantidade = 1;
  private facturas = []
  private orcamentos = []
  private preco: number;
  constructor(
    private auth: AuthService,
    private http: HttpService,
    private configService: ConfigService,
    private excelService: ExcelService,
    private _route: Router,
    private relatorioRegisto: ImpressaoPdfService,
    private excelsService: ExcelAutoService,
    private contratoService: ContratoService,
    private contratoLundasulService: ContratoLundasulService,
    private contratoLundanorteService: ContratoLundanorteService,
    private _formBuilder: FormBuilder,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private api: ApiService,
    private config: ConfigModuloService,
    private nova_ligacao_service: NovaLigacaoService,
  ) {
    this.createForm()
    this.createFormOT()
    this.createHistory()
    this.currentUser = this.auth.currentUserValue;
  }
  private listMovimento: any = [];
  private default: any = [];
  private desconto: number = 0;
  progress: any = 0;
  progress_1: string = null;
  userName: Object = JSON.parse(localStorage.getItem('_token')) ?
    JSON.parse(localStorage.getItem('_token')).user ?
      JSON.parse(localStorage.getItem('_token')).user.nome : null : null
  userStoregeId: Object = JSON.parse(localStorage.getItem('_token')) ?
    JSON.parse(localStorage.getItem('_token')).user ?
      JSON.parse(localStorage.getItem('_token')).user.id : null : null
  loadings = { municipios: false }
  ngOnInit() {

    this.getServicos(null)
    this._getServicos(null)
    this.progress
    this.progress_1
    this.filenout = document.getElementById('file-input')
    this.empresaUser()
    this.getTipo()
    this.getMotivo()
    this.getTiposMedicaos();
    //this.getcategoria()
    //this.getCliente()
    this.getPageFilterDataPage(1);
    this.getDropDownDepartamentos();
    //this.getEstado()
    this.changeValue(true)
    this.DateTimeZone()
    this.listarseries()
    this.listarseries_ocr()
    /*this.getHistoricoId(1) */
    // this.getCliente()
    //this.listarOrdemServico()
    this.getProvincias();
    //this.selectBoxRuasByBairro();

    this.getTarifarios();

    this.getConfiguracaos();
    this.getTiposContratos();
    this.getTiposMedicaos();
    this.getObjectosContratos();
    this.getNiveisSensibilidade();
    this.getTipologiaClientes();
    this.getCentroCusto();

    this.getContratoByCilRescindido();
    this.getClassificacao();
    this.getDropDownDepartamentos();
    this.filters.permission = localStorage.getItem('permission');

    this.minusculaTitle = this.filters.permission == 'listagem_ot_comercial' ? 'Ordem de Serviço' : 'Ordem de Trabalho';
    this.maiusculaTitle = this.filters.permission == 'listagem_ot_comercial' ? 'ORDEM DE SERVIÇO' : 'ORDEM DE TRABALHO';

    if (this.filters.permission == 'listagem_ot_comercial') {
      this.ordem_url = "ordemservico";
    } else {
      this.ordem_url = "ordemTrabalho";
    }
    const param = this.route.queryParams.subscribe(params => {
      const id = params['c'] || 0;
      const name = params['n'] || 0;
      if (id > 0 && name) {
        this.setCliente({ id: id, nome: name });
        this.simpleFormCliente.get('cliente').disable();
      }
    });
    /*  this.getNivel() */
    this.getMunicipios()
  }


  validateOrcamento(value) {
    this.orcamento_flag = value
  }

  changeFlage(flag: string) {
    this.flags = flag
    this.ot_tipos = []
  }
  private getTipoByClassificacao() {

    const uri = 'ordemservico/ostipo-trigger-by-flag/selectBox/' + this.simpleFormCreateOrEdit.getRawValue().classificacao_id
    //"NOVA_LIGACAO " const uri = (this.is_alterar_titularidade === true ? 'ordemservico/ostipo-alteracao-titularidade/selectBox/' + this.ordemServico.classificacao_id : 'ordemservico/ostipo-remocao-contador/selectBox/' + this.ordemServico.classificacao_id);

    this.carregando.tipo = 'Carregando...';
    this.http.__call(uri, { flag: this.flags }).subscribe(
      response => {
        this.ot_tipos = Object(response).data;
        this.carregando.tipo = 'Seleccione o tipo';
        this.dropdownSettingsTipo = {
          singleSelection: false,
          idField: 'id',
          textField: 'descricao',
          selectAllText: 'Seleccione Todos',
          unSelectAllText: 'DeSeleccionar Todos',
          itemsShowLimit: 10,
          allowSearchFilter: true
        };

      }
    );
  }
  private showCheckOT() {
    this.showCheckOT_flag = !this.showCheckOT_flag;

    if (this.showCheckOT_flag) {
      this.getPrioridade();
      this.getClassificacao();
    }
  }



  private closeConfirmFacturacaoModal() {
    //this.checkAllServicos_flag = false;
    this.confirmFacturacaoModal.hide();
  }

  getContratoByCilRescindido() {
    this.reset_details_rescendidos();
    if (this.configService.contrato.cliente_id) {
      this.facturacao.cliente.nome = this.configService.contrato.cliente_nome
      this.facturacao.cliente.id = this.configService.contrato.cliente_id
      this.contrato.cliente_id = this.configService.contrato.cliente_id
      this.contrato.conta_id = this.configService.contrato.conta_id
      this.conta.id = this.configService.contrato.conta_id
      this.local_consumo.cil = this.configService.contrato.cil_disponivel
      this.configService.contrato.isVisible = true
      this.getLocalConsumoByContratoId()
      this.getContaById(this.configService.contrato.conta_id)
    }
  }

  private getContaById(id) {
    this.http
      .call_get("conta/getContaById/" + id, null)
      .subscribe((response) => {
        let item = Object(response);
        this.conta.numero_conta = item.numero_conta;
        this.facturacao.cliente.conta_id = item.id;
        this.contrato.tipo_facturacao_id = item.tipo_facturacao_id;
        this.contrato.tipo_facturacao = item.tipo_facturacao;

        this.facturacao.cliente.nome = item.cliente_nome;
        this.facturacao.cliente.id = item.cliente_id;
        this.facturacao.cliente.telefone = item.cliente_telefone;
        this.facturacao.cliente.genero = item.genero;
        this.facturacao.cliente.email = item.cliente_email;
        this.facturacao.cliente.tipo_cliente_id = item.tipo_cliente_id;
        this.facturacao.cliente.tipo_cliente = item.tipo_cliente;

        this.getDividaAnteriorByClienteId(item.cliente_id);
      });
  }


  goToPageCreateNotaCredito(factura: any) {
    this._route.navigate(["/facturacao/emitir-nota-de-credito", factura.id]);
  }

  private DownloadFactura(id) {
    this.configService.imprimirOrcamento(id, "download");
  }

  private imprimirFactura(id) {
    this.configService.imprimirOrcamento(id, "imprimir")

    //this.reportFactura.imprimirFactura(id);
  }

  private _imprimirFactura(id) {
    this.configService.imprimirFactura(id, "2ª Via", "imprimir")
  }

  private _DownloadFactura(id) {
    this.configService.imprimirFactura(id, "2ª Via", "download");
  }

  private saveContrato() {
    if (this.validateDadosContrato() && this.validateDadosLocalConsumo()) {
      this.configService.loaddinStarter("start");

      const data = {
        conta_id: this.conta.id,
        cliente_id: this.facturacao.cliente.id,
        tipo_contracto_id: this.contrato.tipo_contracto_id,
        tipo_medicao_id: this.contrato.tipo_medicao_id,
        media_consumo: this.contrato.media_consumo,
        tipo_facturacao_id: this.contrato.tipo_facturacao_id,
        tipologia_cliente_id: this.contrato.tipologia_cliente_id,
        nivel_sensibilidade_id: this.contrato.nivel_sensibilidade_id,
        objecto_contrato_id: this.contrato.objecto_contrato_id,
        motivo_recisao_id: this.contrato.motivo_recisao_id,
        data_inicio: this.contrato.data_inicio,
        data_fim: this.contrato.data_fim,
        morada_correspondencia_flag: this.contrato.morada_correspondencia_flag,
        numero_habitantes: this.contrato.numero_habitantes,
        numero_utilizadores: this.contrato.numero_utilizadores,

        morada_contrato: this.contrato.morada_contrato,
        classe_tarifario_id: this.contrato.classe_tarifario_id,

        tipo_mensagem_id: this.contrato.tipo_mensagem_id,
        mensagem: this.contrato.mensagem,

        tarifario_id: this.tarifario.id,

        local_consumo_id: this.local_consumo.id,
        instalacao_sanitaria_qtd: this.contrato.instalacao_sanitaria_qtd,
        reservatorio_flag: this.contrato.reservatorio_flag,
        reservatorio_capacidade: this.contrato.reservatorio_capacidade,
        piscina_flag: this.contrato.piscina_flag,
        piscina_capacidade: this.contrato.piscina_capacidade,
        jardim_flag: this.contrato.jardim_flag,
        campo_jardim_id: this.contrato.campo_jardim_id,
        poco_alternativo_flag: this.contrato.poco_alternativo_flag,
        fossa_flag: this.contrato.fossa_flag,
        fossa_capacidade: this.contrato.fossa_capacidade,
        acesso_camiao_flag: this.contrato.acesso_camiao_flag,
        anexo_flag: this.contrato.anexo_flag,
        anexo_quantidade: this.contrato.anexo_quantidade,
        caixa_contador_flag: this.contrato.caixa_contador_flag,
        abastecimento_cil_id: this.contrato.abastecimento_cil_id,
        is_ordem_trabalho: this.showCheckOT_flag,
        ordem_trabalho: this.ordemServico,
        departamentos: this.departamentoArray,
        caucao: this.contrato.caucao,
        caucao_recebida: this.contrato.caucao_recebida,
      };

      if (this.contrato.id == null) {
        this.closebutton.nativeElement.click();

        this.http.__call("contrato/create", data).subscribe((res) => {
          if (Object(res).code == 500) {
            this.configService.showAlert(
              Object(res).message,
              "alert-danger",
              true
            );
          } else if (Object(res).code == 201) {
            this.configService.showAlert(
              Object(res).message,
              "alert-warning",
              true
            );
            this.configService.loaddinStarter("stop");

            this.contrato_validated = false;

            this.dataContratoSaved = Object(res).data;
          } else {
            this.configService.showAlert(
              Object(res).message,
              "alert-success",
              true
            );

            this.simpleFormCliente.patchValue({
              contrato_id: Object(res).data.id
            })
            if (Object(res).data.contrato.id) {
              let cliente_value = this.simpleFormCliente.getRawValue()
              this._createOrUpdateCliente(`nova-ligacao/ligacao/${this.simpleFormCliente.value.id}`, { ...cliente_value, contrato_id: Object(res).data.contrato.id })
            }

            this.facturacao.processado = true;
            this.configService.loaddinStarter("stop");

            this.dataContratoSaved = Object(res).data;
          }
        });
      }

      if (this.contrato.id > 0) {
        this.http
          .__call("contrato/update/" + this.contrato.id, data)
          .subscribe((res) => {
            if (Object(res).code == 500) {
              this.configService.showAlert(
                Object(res).message,
                "alert-danger",
                true
              );
            } else if (Object(res).code == 201) {
              this.configService.showAlert(
                Object(res).message,
                "alert-warning",
                true
              );
              this.configService.loaddinStarter("stop");

              this.contrato_validated = false;

              this.dataContratoSaved = Object(res).data;
            } else {
              this.configService.showAlert(
                Object(res).message,
                "alert-success",
                true
              );


              this.facturacao.processado = true;
              this.configService.loaddinStarter("stop");

              this.dataContratoSaved = Object(res).data;
            }
          });
      }

      this.facturarServicos();
      this.getDividaAnteriorByClienteId(this.facturacao.cliente.id);
    }
  }

  private revertValidateDadosContrato() {
    this.dados_validated = false;
  }

  private getDividaAnteriorByClienteId(id) {
    let divida_cliente = 0;
    let divida_local_consumo = 0;
    this.configService.loaddinStarter("start");

    this.http
      .call_get("factura/getDividaAnteriorByClienteId/" + id, null)
      .subscribe((res) => {
        this.factura_cliente_dividas = Object(res);
        this.configService.loaddinStarter("stop");
        this.validacaoDivida();
      });
  }

  private validateDadosContrato() {
    if (this.contrato.data_inicio != null && this.contrato.data_fim != null) {
      var tokenize_date_1 = this.contrato.data_inicio.split("-");
      var tokenize_date_2 = this.contrato.data_fim.split("-");

      var data_1 = new Date(
        tokenize_date_1[2],
        tokenize_date_1[1] - 1,
        tokenize_date_1[0]
      );
      var data_2 = new Date(
        tokenize_date_2[2],
        tokenize_date_2[1] - 1,
        tokenize_date_2[0]
      );
    }

    if (this.contrato.data_inicio != null) {
      var tokenize_date_1 = this.contrato.data_inicio.split("-");
      var data_1 = new Date(
        tokenize_date_1[2],
        tokenize_date_1[1] - 1,
        tokenize_date_1[0]
      );
    }

    var result: boolean = true;

    if (this.contrato.caucao_recebida < this.contrato.caucao) {
      this.configService.showAlert(
        "O valor inserido não deve ser inferior que a caução!",
        "alert-danger",
        true
      );
      result = false;
    } else if (this.contrato.tipo_medicao_id == null) {
      this.configService.showAlert(
        "O tipo de medição não foi seleccionado!",
        "alert-danger",
        true
      );
      result = false;
    } else if (this.contrato.tipo_contracto_id == null) {
      this.configService.showAlert(
        "O tipo de contrato não foi seleccionado!",
        "alert-danger",
        true
      );
      result = false;
    } else if (
      this.contrato.tipo_facturacao_id == null &&
      !this.contrato_edit
    ) {
      this.configService.showAlert(
        "O tipo de facturação não foi seleccionado!",
        "alert-danger",
        true
      );
      result = false;
    } else if (
      this.contrato.tipologia_cliente_id == null &&
      !this.contrato_edit
    ) {
      this.configService.showAlert(
        "A tipologia de cliente não foi seleccionado!",
        "alert-danger",
        true
      );
      result = false;
    } else if (
      this.contrato.objecto_contrato_id == null &&
      !this.contrato_edit
    ) {
      this.configService.showAlert(
        "O objecto de contrato não foi seleccionado!",
        "alert-danger",
        true
      );
      result = false;
    } else if (this.contrato.data_inicio == null && !this.contrato_edit) {
      this.configService.showAlert(
        "A data Início não foi inserida!",
        "alert-danger",
        true
      );

      result = false;
    } else if (data_2 != null && data_1 > data_2) {
      this.configService.showAlert(
        "A data Início não pode ser maior que a data fim",
        "alert-danger",
        true
      );
      result = false
    } else if (this.tarifario.id == null) {
      this.configService.showAlert(
        "A categoria do tarifário não foi seleccionado!",
        "alert-danger",
        true
      );
      result = false;
    } else if (this.tarifario.classe_tarifario_id == null) {
      this.configService.showAlert(
        "A classe tarifário não foi seleccionado!",
        "alert-danger",
        true
      );
      result = false;
    }
    else if (
      this.showCheckOT_flag == true &&
      this.ordemServico.data_prevista == null
    ) {
      this.configService.showAlert(
        "Digite a Data Prevista da Ordem de Trabalho!",
        "alert-danger",
        true
      );
      result = false;
    } else if (
      this.showCheckOT_flag == true &&
      this.ordemServico.prioridade_id == null
    ) {
      this.configService.showAlert(
        "Seleccione a Prioridade da Ordem de Trabalho!",
        "alert-danger",
        true
      );
      result = false;
    } else if (
      this.showCheckOT_flag == true &&
      this.ordemServico.classificacao_id == null
    ) {
      this.configService.showAlert(
        "Seleccione a Classificação da Ordem de Trabalho!",
        "alert-danger",
        true
      );
      result = false;
    } else if (
      this.showCheckOT_flag == true &&
      this.ordemServico.tipo_id == null
    ) {
      this.configService.showAlert(
        "Seleccione o Tipo de Ordem de Trabalho!",
        "alert-danger",
        true
      );
      result = false;
    } else if (
      this.showCheckOT_flag == true &&
      this.ordemServico.descricao == null
    ) {
      this.configService.showAlert(
        "Escreve a Descrição para Ordem de Trabalho!",
        "alert-danger",
        true
      );
      result = false;
    }

    else {
      this.configService.showAlert(
        "Os dados estão válidos",
        "alert-success",
        true
      );
    }

    this.dados_validated = result;

    return result;
  }

  private validateDadosContratoCil() {


    let result: boolean = true;

    if (
      this.local_consumo.cil == null &&
      this.empresa.sigla_empresa == "EPASLN"
    ) {
      this.configService.showAlert(
        "Cil Obrigatório ",
        "alert-danger",
        true
      );

      result = false;
    }

    else {
      this.configService.showAlert(
        "Os dados estão válidos",
        "alert-success",
        true
      );
    }

    this.dados_validated = result;

    return result;
  }

  private validateDadosLocalConsumo() {
    var result: boolean = true;
    return result;
  }

  private validateLocalConsumo() {
    this.http
      .__call("local-consumo/getLocalConsumoWithContrato", {
        id: this.local_consumo.id,
        conta_id: this.conta.id,
      })
      .subscribe((response) => {
        let res = Object(response);
        if (res != null) this.contrato.motivo_recisao_flag = true;
        this.configService.loaddinStarter("stop");
      });
  }

  private cilAvailable(flag) {
    if (flag) {
      this.contrato.rescindir_contrato_cil = false;
      this.contrato.novo_contrato_cil = true;
    } else {
      this.contrato.rescindir_contrato_cil = true;
      this.contrato.novo_contrato_cil = false;
    }
  }

  private checkConfirmServico() {
    this.checkConfirmServico_flag = true; // provisório
  }

  private getServicoValorFacturar() {
    this.facturacao.total_facturar = 0;

    for (let index = 0; index < this.facturacao.servicos.length; index++) {
      let item = this.facturacao.servicos[index];

      if (item.facturar) {
        let servico_valor = item.servico_valor
          ? item.servico_valor * item.quantidade
          : 0;
        let valor_imposto =
          (item.imposto_valor ? item.imposto_valor : 0) * servico_valor;

        this.facturacao.total_facturar += servico_valor + valor_imposto;
      }
    }
  }

  selectSerie() {
    for (let i = 0; i < this.series.length; ++i) {
      if (this.series[i].id == this.facturacao.serie.id) {
        this.facturacao.serie = {
          id: this.series[i].id,
          nome: this.series[i].nome,
          proximo_numero: this.series[i].proximo_numero,
          documento_id: this.series[i].documento_id,
          documento: this.series[i].documento,
          sigla: this.series[i].sigla,
          movimento: this.series[i].momento,
          tipo_movimento: this.series[i].tipo_movimento,
        };
      }
    }
  }

  private carregandoEstado = {
    estado: 'Seleccione o estado',
  }


  private selectBoxEstadoConta() {
    this.carregandoEstado.estado = 'Carregando...';

    this.http.call_get('estado-conta/selectBox', null).subscribe(
      response => {
        this.estadosConta = Object(response);
        this.carregandoEstado.estado = 'Seleccione o estado';
      }
    );
  }


  private facturarServicos() {
    this.selectSerie();

    let valor_total_imposto = 0;
    let total_sem_imposto = 0;
    let total_com_imposto = 0;

    for (let index = 0; index < this.facturacao.servicos.length; index++) {
      let item = this.facturacao.servicos[index];

      //if (item.facturar) {

      let servico_valor = item.servico_valor ? item.servico_valor : 0;
      let valor_imposto =
        (item.imposto_valor ? item.imposto_valor : 0) * servico_valor;

      total_sem_imposto += servico_valor * item.quantidade;
      valor_total_imposto += valor_imposto * item.quantidade;
      total_com_imposto += (servico_valor + valor_imposto) * item.quantidade;
      //}
    }

    const data = {
      contrato_id: this.dataContratoSaved.contrato.id,
      cliente_id: this.facturacao.cliente.id,
      gestor_cliente_id: this.facturacao.cliente.gestor_cliente_id,
      conta_id: this.facturacao.cliente.conta_id,
      servicos: this.facturacao.servicos,
      total_sem_imposto: total_sem_imposto,
      total_com_imposto: total_com_imposto,
      valor_total_imposto: valor_total_imposto,
      valor_aberto: total_com_imposto,
      serie: this.facturacao.serie,
    };

    this.http
      .__call("factura/facturarServicosContrato", data)
      .subscribe((res) => {
        if (Object(res).code == 200) {
          this.showCheckServicos_flag = false;

          this.facturacao.servicos = Object(res).data.servicos;
          this.confirmFacturacaoModal.hide();
        }

        if (Object(res).code == 507) {
          this.showCheckServicos_flag = true;
          this.configService.showAlert(
            Object(res).message,
            "alert-success",
            true
          );
        }
      });

    this.getDividaAnteriorByClienteId(this.facturacao.cliente.id);
  }

  private getSeries() {
    this.http
      .call_get("serie/loja/selectSerieLojaFacturaBox", null)
      .subscribe((data) => {
        let serie = Object(data).data.find((obj) => obj.sigla == "FT");

        this.facturacao.serie.id = serie.id;
        this.facturacao.serie = serie;

        this.series = Object(data).data;
      });
  }

  private validacaoDivida() {
    let divida_cliente = 0;
    let divida_local_consumo = 0;

    for (let index = 0; index < this.factura_cliente_dividas.length; index++) {
      const item = this.factura_cliente_dividas[index];

      divida_cliente = divida_cliente + item.valor_aberto;
    }

    for (
      let index = 0;
      index < this.factura_local_consumo_dividas.length;
      index++
    ) {
      const item = this.factura_local_consumo_dividas[index];

      divida_local_consumo = divida_local_consumo + item.valor_aberto;
    }

    this.facturacao.divida_total = divida_cliente + divida_local_consumo;

    this.facturacao.divida_cliente = divida_cliente;
    this.facturacao.divida_local_consumo = divida_local_consumo;
  }
  reset_details_rescendidos() {
    this.facturacao.cliente.nome = null
    this.facturacao.cliente.id = null
    this.conta.id = null
    this.local_consumo.cil = null
  }

  public getConfiguracaos() {
    let result = null;

    const slugs = [
      this.config.provincia_default,
      this.config.tipo_facturacao_default,
      this.config.media_consumo_default,
      this.config.nivel_sensibilidade_default,
      this.config.tarifa_fixa_modal_view,
      this.config.cil_disabled_view,
    ];

    for (let index = 0; index < slugs.length; index++) {
      this.http
        .__call("configuracao/getConfiguracaobySlug/" + slugs[index], null)
        .subscribe((response) => {

          if (Object(response).code != 200) {
            result = null;
          } else {
            result = Object(response).data;

            if (slugs[index] == this.config.provincia_default) {
              this.local_consumo.provincia_id = result.valor;
              this.selectBoxMunicipiosByProvincia(result.valor);
            }

            if (slugs[index] == this.config.tipo_facturacao_default) {
              this.contrato.tipo_facturacao_id = result.valor;
            }

            if (slugs[index] == this.config.nivel_sensibilidade_default) {
              this.contrato.nivel_sensibilidade_id = result.valor;
            }

            if (slugs[index] == this.config.tarifa_fixa_modal_view)
              this.tarifa_fixa_modal_view = Boolean(result.valor);

            if (slugs[index] == this.config.cil_disabled_view)
              this.cil_disabled_view = Boolean(result.valor);
          }
        });
    }
  }


  private selectedSerie() {
    this.series.forEach((element) => {
      if (this.facturacao.serie.id == element.id) {
        if (element.sigla == "FT") {
          this.configService.listarBancos();
          this.configService.listarMoedas();
        }
        this.facturacao.serie.id = element.id;
        this.facturacao.serie.nome = element.nome;
        this.facturacao.serie.sigla = element.sigla;
        this.facturacao.serie.proximo_numero = element.proximo_numero;
      }
    });
  }
  private _selectedSerie() {
    this.series.forEach((element) => {
      if (this.facturacao.serie.id == element.id) {
        if (element.sigla == "FT") {
          this.configService.listarBancos();
          this.configService.listarMoedas();
        }
        this.facturacao.serie.id = element.id;
        this.facturacao.serie.nome = element.nome;
        this.facturacao.serie.sigla = element.sigla;
        this.facturacao.serie.proximo_numero = element.proximo_numero;
      }
    });
  }

  private isForOrcamentoOrFactura: boolean

  private facturaOrOrcamento: any;
  private facturar() {

    this.facturaOrOrcamento = "FACTURA";

    this.isForOrcamentoOrFactura = true
    if (this.facturacao.cliente.id == null) {
      this.configService.showAlert(
        "É obrigatório fornecer um cliente",
        "alert-danger",
        true
      );
    } else if (this.facturacao.serie.id == null) {
      this.configService.showAlert(
        "É obrigatório fornecer uma serie",
        "alert-danger",
        true
      );
    } else if (this.facturacao.produto.length == 0) {
      this.configService.showAlert(
        "É obrigatório fornecer produtos para a factura",
        "alert-danger",
        true
      );
    } else {
      if (this.facturacao.serie.sigla == "FR") {
        if (this.facturacao.pagamento.linha_pagamentos.length == 0) {
          this.configService.showAlert(
            "É obrigatório fornecer uma forma de pagamento",
            "alert-danger",
            true
          );
        } else if (
          this.facturacao.pagamento.total_valor_recebido <
          this.facturacao.total ||
          this.facturacao.pagamento.total_valor_recebido == 0
        ) {
          this.configService.showAlert(
            "Informa um valor de entrada superir ou igual ao valor total a pagar",
            "alert-danger",
            true
          );
        } else {
          this.guardarFactura();
        }
      } else {
        this.guardarFactura();
      }
    }
  }

  private _facturar() {

    this.facturaOrOrcamento = "ORCAMENTO";

    if (this.orcamento.cliente.id == null) {
      this.configService.showAlert('É obrigatório fornecer um cliente', "alert-danger", true);
    } else if (this.orcamento.serie.id == null) {
      this.configService.showAlert('É obrigatório fornecer uma serie', "alert-danger", true);
    } else if (this.orcamento.produto.length == 0) {
      this.configService.showAlert('É obrigatório fornecer produtos para a factura', "alert-danger", true);
    } else {
      if (this.orcamento.serie.sigla == 'FR') {
        if (this.orcamento.pagamento.linha_pagamentos.length == 0) {
          this.configService.showAlert('É obrigatório fornecer uma forma de pagamento', "alert-danger", true);
        } else if (this.orcamento.pagamento.total_valor_recebido < this.orcamento.total || this.orcamento.pagamento.total_valor_recebido == 0) {
          this.configService.showAlert('Informa um valor de entrada superir ou igual ao valor total a pagar', "alert-danger", true);
        } else {
          this._guardarFactura();
        }
      } else {
        this._guardarFactura();
      }
    }
  }

  cancelarConta() {
    this.activeButtom.cliente = false;
    this.facturacao.cliente.conta_id = null;
    this.facturacao.cliente.servico_id = null;
    this.facturacao.cliente.id = null;
    this.servico = null;
  }


  confirmarConta() {
    this.activeButtom.cliente = false;
    if (this.conta.id == null) {
      this.configService.showAlert("Seleccione a conta", "alert-danger", true);
    } else {
      this.activeButtom.cliente = true;
    }

    this.contas_clientes.forEach((e) => {
      if (e.id == this.conta.id) this.conta.numero_conta = e.numero_conta;

      this.facturacao.cliente.conta_id = e.id;
      this.contrato.tipo_facturacao_id = e.tipo_facturacao_id;
      this.contrato.tipo_facturacao = e.tipo_facturacao;
    });
  }

  selectedClienteComConta(n: number) {
    this.clienteSemOrComConta = n;
    this.getTarifarios();

    this.getTiposContratos();

    this.getProvincias();
    this.facturacao.servicos = [];
    this.showCheckServicos_flag = true
  }

  private iniorcamento() {


    this.orcamento = {
      cliente: {
        id: null,
        nome: null,
        contribuinte: null,
        morada: null,
        telefone: null,

        conta_id: null,
        contrato_id: null
      },
      serie: {
        id: null,
        sigla: null,
        nome: null,
        proximo_numero: null
      },
      produto: [],
      pagamento: {
        forma: null,
        valor_recebido: 0.0,
        total_valor_recebido: 0.0,
        troco: 0.0,
        total_pago: null,
        referencia: null,
        data_pagamento: null,
        forma_pagamento_id: null,
        bancos: null,
        adiantamento: null,
        is_adiantamento: 0,
        linha_pagamentos: []

      },
      totalComImposto: 0.0,
      totalSemImposto: 0.0,
      total: 0.0,
      success_orcamento: false,
      facturaGerada: null,
      data_vencimento: null,
      moeda: null
    }
  }

  private _guardarFactura() {
    this.isFinalizar = false;
    this.configService.loaddinStarter('start');
    this.http.__call('factura/create', {
      produtos: this.orcamento.produto,
      documento: 'Factura',
      cliente_id: this.orcamento.cliente.id,
      total: this.orcamento.total,
      serie_id: this.orcamento.serie.id,
      totalComImposto: this.orcamento.totalComImposto,
      totalSemImposto: this.orcamento.totalSemImposto,
      observacao: this.observacao.nativeElement.value,
      numero_origem_factura: null,
      data_origem_factura: null,
      data_vencimento: this.orcamento.data_vencimento,
      pagamento: this.orcamento.pagamento,
      contas_cliente: this.orcamento.cliente,
      moeda: this.orcamento.moeda,
      nova_ligacao_id: this.nova_ligacao_service.id,
      facturaOrOrcamento: this.facturaOrOrcamento
    }).pipe(
      finalize(() => {
        this.configService.loaddinStarter("stop");
        //this.validateButton = 200;
      })
    ).subscribe(
      (res) => {
        this.iniorcamento();
        this.orcamento.success_orcamento = true;
        this.orcamento.facturaGerada = Object(res).data.id
        this.simpleFormCliente.patchValue({
          orcamento_id: Object(res).data.id
        })
        if (this.simpleFormCliente.value.orcamento_id) {
        }
      }, (error) => {
        this.configService.loaddinStarter('stop');
        this.isFinalizar = true;
      }
    );
  }

  deleteRowProdut(produto: any) {
    for (let i = 0; i < this.facturacao.produto.length; ++i) {
      if (this.facturacao.produto[i].produto_id === produto.produto_id) {
        this.facturacao.produto.splice(i, 1);
        this.calcularTotal();
      }
    }
  }

  deleteRowOrcamento(produto: any) {
    for (let i = 0; i < this.orcamento.produto.length; ++i) {
      if (this.orcamento.produto[i].produto_id === produto.produto_id) {
        this.orcamento.produto.splice(i, 1);
        this._calcularTotal();
      }
    }
  }

  deleteRow(servico: any) {
    for (let i = 0; i < this.facturacao.servicos.length; ++i) {
      if (this.facturacao.servicos[i].servico_id === servico.servico_id) {
        this.facturacao.servicos.splice(i, 1);

        this.getValorTotalServicos();
      }
    }
    this.getServicoValorFacturar();
  }

  private _calcularTotal() {
    this.orcamento.total = 0;
    this.orcamento.totalSemImposto = 0;
    this.orcamento.totalComImposto = 0;
    //Calcula o Total da Factura
    for (let index = 0; index < this.orcamento.produto.length; index++) {
      this.orcamento.totalSemImposto +=
        this.orcamento.produto[index].linhaTotalSemImposto;
      this.orcamento.totalComImposto +=
        this.orcamento.produto[index].valorImposto;

      this.orcamento.total += this.orcamento.produto[index].total;
    }
    this.orcamento.pagamento.total_pago = this.orcamento.total;

    if (this.orcamento.total >= 0) {
      this.activeButtom.produto = true;
    } else {
      this.activeButtom.produto = false;
    }
  }

  private calcularTotal() {
    this.facturacao.total = 0;
    this.facturacao.totalSemImposto = 0;
    this.facturacao.totalComImposto = 0;
    //Calcula o Total da Factura
    for (let index = 0; index < this.facturacao.produto.length; index++) {
      this.facturacao.totalSemImposto +=
        this.facturacao.produto[index].linhaTotalSemImposto;
      this.facturacao.totalComImposto +=
        this.facturacao.produto[index].valorImposto;

      this.facturacao.total += this.facturacao.produto[index].total;
    }
    this.facturacao.pagamento.total_pago = this.facturacao.total;

    if (this.facturacao.total >= 0) {
      this.activeButtom.produto = true;
    } else {
      this.activeButtom.produto = false;
    }
  }

  private listarProdutos(response: any) {
    /* if (response.total == 0 || response.total == null) {
      this.configService.showAlert("Não foi informado nenhum preço no produto", "alert-danger", true);
      return;
    } */
    this.facturacao.total = 0;
    let validar = 0;
    if (response.code == 400) {
      this.configService.showAlert(response.message, "alert-danger", true);
    } else {
      if (this.facturacao.produto.length >= 1) {
        if (response.valor_original) {
          for (let index = 0; index < this.facturacao.produto.length; index++) {
            const produt = this.facturacao.produto[index];
            if (produt.produto_id == response.produto_id) {
              this.facturacao.produto.splice(index, 1);
              this.facturacao.produto.splice(index, 0, response);
              validar = 1;
            }
          }
        }
        if (validar == 0) {
          this.facturacao.produto.push(response);
        }
      } else {
        this.facturacao.produto.push(response);
      }
      this.calcularTotal();
    }
  }


  private _listarProdutos(response: any) {
    this.orcamento.total = 0;
    let validar = 0;
    if (response.code == 400) {
      this.configService.showAlert(response.message, "alert-danger", true);
    } else {
      if (this.orcamento.produto.length >= 1) {
        for (let index = 0; index < this.orcamento.produto.length; index++) {
          const produt = this.orcamento.produto[index];
          if (produt.produto_id == response.produto_id) {

            this.orcamento.produto.splice(index, 1);
            this.orcamento.produto.splice(index, 0, response);
            validar = 1;
          }

        }
        if (validar == 0) {
          this.orcamento.produto.push(response);
        }
      } else {
        this.orcamento.produto.push(response);
      }
      //Calcula o Total da Factura
      this._calcularTotal();
    }

  }

  _getAddProduto() {

    this.configService.loaddinStarter('start');
    this.http.__call('artigo/pesquisar',
      {
        produto_id: this.produtoId,
        quantidade: this.quantidade < 0 || isNaN(this.quantidade) ? 1 : this.quantidade,
        desconto: 0,
        cliente_id: this.clienteId,
        observacao: null,
        preco: this.preco
      }
    ).subscribe(
      res => {
        this._listarProdutos(Object(res).data);
        if (this.produto_selecionado == 0) {
          this.produtos(this.search.nativeElement.value);
          this.configService.loaddinStarter('stop');
        }
        this.qtd.nativeElement.value = 1;
        this.configService.loaddinStarter('stop');
      }
    );
    this.configService.loaddinStarter('stop');
  }



  getAddProduto() {
    if (this.quantidade <= 0 || isNaN(this.quantidade)) {
      this.configService.showAlert(
        "Não foi informado uma quantidade valida",
        "alert-danger",
        true
      );
      return;
    }
    if (this.http.canActivateRouterLink("atribuir_desconto")) {
      if (!(this.desconto <= 100 && this.desconto >= 0)) {
        this.configService.showAlert(
          "Desconto valido, informe um valor no intervalo entre 0 e 100%",
          "alert-danger",
          true
        );
        return;
      }
    }
    this.configService.loaddinStarter("start");
    this.http
      .__call("artigo/pesquisar", {
        produto_id: this.produtoId,
        quantidade:
          this.quantidade < 0 || isNaN(this.quantidade) ? 1 : this.quantidade,
        desconto: this.desconto,
        cliente_id: this.clienteId,
        observacao: null,
        preco: this.preco,
      })
      .subscribe((res) => {
        this.listarProdutos(Object(res).data);
        if (this.produto_selecionado == 0) {
          //this.getServicos(this.search.nativeElement.value);
        }
        this.quantidade = 1;
        this.desconto = 0;
        this.configService.loaddinStarter("stop");
      });
  }

  private clearFormInputs() {

    this.ordemServico.nivel_1 = null,
      this.ordemServico.nivel_2 = null,
      this.ordemServico.nivel_3 = null,
      this.ordemServico.cliente_conta_id = null,
      this.ordemServico.cliente_contrato_id = null,
      this.ordemServico.cliente_nome = null,
      this.ordemServico.success_ordem_servico = false,
      this.is_sintoma = false,
      this.is_causa = false,
      this.ordemServico.agendamentoGerado = null

  }

  private createOrdemServico() {

    if (this.ordemServico.titulo == null || this.ordemServico.titulo == "") {
      this.configService.showAlert("Digite o Título", 'alert-danger', true);
    } else if (this.ordemServico.data_prevista == null || this.ordemServico.data_prevista == "") {
      this.configService.showAlert("Digite a Data prevista", 'alert-danger', true);
    } else if (this.ordemServico.prioridade_id == null) {
      this.configService.showAlert("Seleccione a prioridade", 'alert-danger', true);
    } else if (this.ordemServico.classificacao_id == null) {
      this.configService.showAlert("Seleccione a classificação", 'alert-danger', true);
    } else if (this.ordemServico.tipo_id == null) {
      this.configService.showAlert("Seleccione o tipo", 'alert-danger', true);
    } else if ((this.associacao_equipamento && this.ordemServico.tipo_id != null) && this.ordemServico.nivel_1 == null) {
      this.configService.showAlert("Seleccione o equipamento", 'alert-danger', true);
    } else if ((this.associacao_cliente && this.ordemServico.tipo_id != null) && (this.ordemServico.cliente_nome == null || this.ordemServico.cliente_nome == "")) {
      this.configService.showAlert("Seleccione o Cliente", 'alert-danger', true);
    } else if ((this.associacao_cliente && this.ordemServico.tipo_id != null) && this.ordemServico.cliente_conta_id == null) {
      this.configService.showAlert("Seleccione a Conta", 'alert-danger', true);
    } else if ((this.associacao_cliente && this.ordemServico.tipo_id != null) && this.ordemServico.cliente_contrato_id == null) {
      this.configService.showAlert("Seleccione o Contrato", 'alert-danger', true);
    } else if (this.ordemServico.descricao == null || this.ordemServico.descricao == "") {
      this.configService.showAlert("Digite a descrição", 'alert-danger', true);
    } else {
      this.configService.loaddinStarter('start');
      this.isFinalizar = true;
      this.isDisabled = true;

      let uploadData = new FormData();

      uploadData.append("ordemServico", JSON.stringify(this.ordemServico));
      uploadData.append("causas", JSON.stringify(this.causasArray));
      uploadData.append("sintomas", JSON.stringify(this.sintomasArray));
      uploadData.append("departamentos", JSON.stringify(this.departamentoArray));
      for (let file of this.fileList) {
        uploadData.append("file", file.file);
      }
      /* this.configService.loaddinStarter('stop');
    return */
      this.http.$__call('ordemservico/ordem-servico/create', uploadData).subscribe(
        (res: HttpEvent<any>) => {
          switch (res.type) {
            case HttpEventType.Sent:
              break;
            case HttpEventType.ResponseHeader:
              break;
            case HttpEventType.UploadProgress:
              this.progress = Math.round(res.loaded / res.total * 100);
              break;
            case HttpEventType.Response:
              setTimeout(() => {
                this.progress = 0;
                this.fileList = []
              }, 1500);

          }
          if (Object(Object(res).body).code) {
            if (Object(Object(res).body).code == 200) {
              this.isDisabled = true;
              this.validateButton = Object(Object(res).body).code
              this.configService.showAlert(Object(Object(res).body).message, 'alert-success', true);
              this.clearFormInputs();
              this.ordemServico.success_ot_sem_agendamento = true;

              this.configService.loaddinStarter('stop');
            } else {
              this.configService.showAlert(Object(Object(res).body).message, 'alert-danger', true);
              this.isFinalizar = false;
              this.isDisabled = false;
              this.configService.loaddinStarter('stop');
            }
          }
        },

      )
    };
  }

  private setClasseTarifario() {
    this.addValorClasse = true;

    for (let index = 0; index < this.classe_tarifarios.length; index++) {
      if (
        this.classe_tarifarios[index].id == this.tarifario.classe_tarifario_id
      ) {
        this.classe_tarifario.id = this.classe_tarifarios[index].id;
        this.classe_tarifario.tarifario_id =
          this.classe_tarifarios[index].tarifario_id;
        this.classe_tarifario.produto_id =
          this.classe_tarifarios[index].produto_id;
        this.classe_tarifario.descricao =
          this.classe_tarifarios[index].descricao;
        this.classe_tarifario.valor = this.classe_tarifarios[index].valor;
        this.classe_tarifario.ordem = this.classe_tarifarios[index].ordem;
        this.classe_tarifario.tarifa_variavel =
          this.classe_tarifarios[index].tarifa_variavel;
        this.classe_tarifario.tarifa_fixa_mensal =
          this.classe_tarifarios[index].tarifa_fixa_mensal;
        this.classe_tarifario.tarifa_intervalo =
          this.classe_tarifarios[index].tarifa_intervalo;
        this.classe_tarifario.consumo_minimo =
          this.classe_tarifarios[index].consumo_minimo;
        this.classe_tarifario.consumo_maximo =
          this.classe_tarifarios[index].consumo_maximo;
      }
    }

    this.servico_modal_title = "Editar Serviço " + this.servico.servico;

    this.edit_servico_flag = true;
  }


  private carregando = {
    tarifario: "Seleccione o tarifário",
    classeTarifario: "Seleccione a classe tarifário",
    tipo: 'Seleccione o tipo'
  };

  private getValorTotalServicos() {
    this.facturacao.valor_total_imposto = 0;
    this.facturacao.total_sem_imposto = 0;
    this.facturacao.total_com_imposto = 0;

    //Calcula o Total da Factura
    for (let index = 0; index < this.facturacao.servicos.length; index++) {
      let item = this.facturacao.servicos[index];

      let valor = item.servico_valor ? item.servico_valor * item.quantidade : 0;
      let valor_imposto = (item.imposto_valor ? item.imposto_valor : 0) * valor;

      this.facturacao.total_sem_imposto += valor;
      this.facturacao.valor_total_imposto += valor_imposto;
      this.facturacao.total_com_imposto += valor + valor_imposto;
    }

    this.facturacao.pagamento.total_pago = this.facturacao.valor_total_imposto;
  }

  private classeTarifarioBytarifario() {
    this.carregando.classeTarifario = "Carregando...";
    this.http
      .call_get(
        "selectBox/classe-tarifarioBytarifario/" + this.tarifario.id,
        null
      )
      .subscribe((response) => {
        this.classe_tarifarios = Object(response).data;

        if (this.tarifario.classe_tarifario_id) this.setClasseTarifario();

        this.carregando.classeTarifario = "Seleccione a classe tarifário";
        this.facturacao.valor_total_imposto = 0;
        this.facturacao.servicos = [];
      });
  }

  private getTiposContratos() {
    this.http
      .call_get("tipo-contrato/selectBox", null)
      .subscribe((response) => {
        this.tipo_contratos = Object(response);
      });
  }

  private getTiposMedicaos() {
    this.http.call_get("tipo-medicao/selectBox", null).subscribe((response) => {
      this.tipo_medicaos = Object(response);
    });
  }

  private getNiveisSensibilidade() {
    this.http
      .call_get("nivel-sensibilidade/selectBox", null)
      .subscribe((response) => {
        this.niveis_sensibilidade = Object(response);
      });
  }

  private setarNovaLigacao(item){
    this.nova_ligacao_selecionada = item.id
  }

  private getAbastecimentoCILs() {
    this.http
      .call_get("abastecimento-cil/selectBox", null)
      .subscribe((response) => {
        this.abastecimento_cils = Object(response);
      });
  }

  private getTipoMensagems() {
    this.http
      .call_get("tipo-mensagem/selectBox", null)
      .subscribe((response) => {
        this.tipo_mensagems = Object(response);
      });
  }

  private getCampoJardims() {
    this.http.call_get("campo-jardim/selectBox", null).subscribe((response) => {
      this.campo_jardims = Object(response);
    });
  }

  private getTarifarios() {
    this.http.call_get("tarifario/selectBox", null).subscribe((response) => {
      this.tarifarios = Object(response).data;
    });
  }

  private setTipologiasContrato(id: any) {
    var child: any
    for (const parent of this.tipologia_clientes) {
      child = parent.childs.filter((obj: any) => (obj.id == id) && (obj.has_caucao == 1))
      if (child.length) {
        this.contrato.caucao = child.length ? child[0].caucao : null;
        ($("#confirmarCaucaoModal") as any).modal("show")
        return
      }
    }
  }

  changeClasseTarifario(classe_tarifario_id) {
    this.contrato.classe_tarifario_id = classe_tarifario_id;

    this.setClasseTarifario();
  }

  private is_maior = false;
  private validarCaucao() {
    this.is_maior = false;
    if (this.contrato.caucao_recebida > this.contrato.caucao) {
      this.is_maior = true;
      this.contrato.caucao_recebida = null;
    }
    if (!this.is_maior) {
      ($("#confirmarCaucaoModal") as any).modal("hide")
    }
  }

  private getTipologiaClientes() {
    this.http
      .call_get("tipologia-cliente/selectGroupBox", null)
      .subscribe((response) => {
        this.tipologia_clientes = Object(response);
      });
  }

  private getCentroCusto() {
    this.http.call_get("centro-custo/getAll", null).subscribe((response) => {
      this.centro_custo = Object(response);
    });
  }

  private getObjectosContratos() {
    this.http
      .call_get("objecto-contrato/selectBox", null)
      .subscribe((response) => {
        this.objecto_contratos = Object(response);
      });
  }

  private getMotivosRecisaos() {
    this.http
      .call_get("motivo-recisao/selectBox", null)
      .subscribe((response) => {
        this.motivo_recisaos = Object(response);
      });
  }

  private produtos(search) {
    this.items_produtos = [];
    this.configService.loaddinStarter('start');
    this.pagination.search = (search == "" || search == null ? 'a' : search);

    /*     this.pagination.contrato = (this.orcamento.cliente.contrato_id == "" || this.orcamento.cliente.contrato_id == null ? null : this.orcamento.cliente.contrato_id); */


    this.http.__call('artigo/search', this.pagination).subscribe(
      res => {

        this.items_produtos = Object(res)
        this.configService.loaddinStarter('stop');
      }
    );

  }


  private listarFormaPagamentos() {

    this.configService.loaddinStarter('start');
    this.http.call_get('formaPagamento/formas', null).subscribe(
      response => {
        this.formasPagamentos = Object(response).data;
        this.configService.loaddinStarter('stop');

      }
    );
  }

  private getMovimentoAdiantamento() {
    this.orcamento.pagamento.adiantamento = [];
    this.configService.loaddinStarter('start');
    this.http.__call('adiantamento/factura/adiantamentoFactura', { cliente_id: this.orcamento.cliente.id }).subscribe(
      response => {
        this.listMovimento = Object(response).data;
        this.configService.loaddinStarter('stop');
      });
  }

  selectedClienteSemConta(n: number) {
    this.clienteSemOrComConta = n
    this.listarseries_ocr();
    this.listarFormaPagamentos();
    this.orcamento.produto = []
    this.contrato = null
    this.orcamento.cliente.conta_id = null
    this.orcamento.cliente.contrato_id = null
    this.produtos(null);
    this.getMovimentoAdiantamento();

  }



  private selectedCliente_orc(id) {

    this.orcamento.cliente.id = id;

    this.clientes.forEach(element => {
      if (this.orcamento.cliente.id == element.id) {

        this.orcamento.cliente.id = element.id;
        this.orcamento.cliente.nome = element.nome;
        this.orcamento.cliente.contribuinte = element.contribuente;
        this.orcamento.cliente.telefone = element.telefone;
        this.orcamento.cliente.morada = element.morada;

        this.activeButtom.cliente = true;
      }
    });

    // this.getContas();
  }

  private selectedClientes(id) {
    this.facturacao.cliente.id = id;
    this.clientes.forEach((element) => {
      if (this.facturacao.cliente.id == element.id) {
        this.facturacao.cliente.id = element.id;
        this.facturacao.cliente.nome = element.nome;
        this.facturacao.cliente.contribuinte =
          element.numero_identificacao || element.nif;
        this.facturacao.cliente.telefone = element.telefone;
        this.facturacao.cliente.morada = element.morada;
        this.activeButtom.cliente = true;
      }
    });


  }

  private guardarFactura() {
    this.isFinalizar = false;
    this.configService.loaddinStarter("start");
    this.closebutton.nativeElement.disabled = true

    let dataActual = moment(Date()).format("YYYY-MM-DD");

    if (this.facturacao.data_vencimento < dataActual) {
      this.configService.showAlert(
        "Data de Vencimento não pode ser inferior que a Data Actual",
        "alert-danger",
        true
      );
      this.configService.loaddinStarter("stop");
      this.isFinalizar = true;
    } else {
      const _validarAd = this.facturacao.pagamento.linha_pagamentos.find(
        (l) => l.designacao === "Adiantamento"
      );
      this.facturacao.pagamento.adiantamentos =
        _validarAd == null ? [] : this.facturacao.pagamento.adiantamentos;

      let data = {
        produtos: this.facturacao.produto,
        documento: "Factura",
        cliente_id: this.facturacao.cliente.id,
        total: this.facturacao.total,
        serie_id: this.facturacao.serie.id,
        totalComImposto: this.facturacao.totalComImposto,
        totalSemImposto: this.facturacao.totalSemImposto,
        observacao: this.observacao.nativeElement.value,
        leitura: this.leitura.nativeElement.value,
        numero_origem_factura: null,
        data_origem_factura: null,
        data_vencimento: this.facturacao.data_vencimento,
        pagamento: this.facturacao.pagamento,
        moeda: this.facturacao.moeda,
        valor_aberto: this.facturacao.total,
        conta_id: this.facturacao.cliente.conta_id,
        contrato_id: this.facturacao.cliente.contrato_id,
        nova_ligacao_id: this.nova_ligacao_service.id,
        facturaOrOrcamento: this.facturaOrOrcamento
      };

      this.http
        .__call("factura/create", data)
        .pipe(
          finalize(() => {
            this.configService.loaddinStarter("stop");
            this.validateButton = 200;
          })
        )
        .subscribe(
          (res) => {
            this.iniFacturacao();
            this.facturacao.success_facturacao = true;
            this.facturacao.facturaGerada = Object(res).data.id;
            this.ordemServico.factura_id = this.facturacao.facturaGerada;
            this.simpleFormCliente.patchValue({
              factura_id: this.facturacao.facturaGerada,
            })

            this.createOrdemServico();
            if (this.simpleFormCliente.value.factura_id) {
              // this.onSubmit()
            }
          },
          (error) => {
            this.configService.loaddinStarter("stop");
            this.isFinalizar = true;
          }
        );
    }
  }

  private contaServicoSeleciona() {

    this.facturacao.cliente.contrato_id = this.ordemServico.cliente_contrato_id;

    this.contrato = this.contratos_conta_cliente.find(
      (element) => element.id == this.ordemServico.cliente_contrato_id
    );

  }


  private getDetailTipoMedicao() {
    for (let index = 0; index < this.tipo_medicaos.length; index++) {
      if (this.contrato.tipo_medicao_id == this.tipo_medicaos[index].id) {
        this.contrato.tipo_medicao = this.tipo_medicaos[index].nome;
        this.contrato.tipo_medicao_slug = this.tipo_medicaos[index].slug;
      }
    }
  }
  private getMunicipios() {
    this.loadings.municipios = true;
    this.api.get('municipio/selectBox')
      .pipe(finalize(() => { this.loadings.municipios = false; }))
      .subscribe(response => { this.municipios = Object(response).data; }, error => { this.loadings.municipios = false; });
  }

  DateTimeZone() {
    const event = new Date();
    const parisDate = toDate(event.toISOString())
    const pattern = "yyyy-MM-dd'T'HH:mm"
    const timeOutput = format(parisDate, pattern, { timeZone: 'UTC/GMT+1' })
    return this.datatime = timeOutput.slice(0, 16);
  }

  getTimeZone(date) {
    const parisDate = toDate(date)
    const pattern = 'HH:mm'
    this.timeOutput = format(parisDate, pattern, { timeZone: 'UTC/GMT+1' })
    return this.timeOutput
  }

  private getDropDownDepartamentos() {
    this.http.call_get('departamento/selectBox', null).subscribe(
      response => {
        this.dropdownDepartamentos = Object(response).data;

        this.dropdownSettingsDepartamentos = {
          singleSelection: false,
          idField: 'id',
          textField: 'descricao',
          selectAllText: 'Seleccione Todos',
          unSelectAllText: 'DeSeleccionar Todos',
          itemsShowLimit: 10,
          allowSearchFilter: true
        };

      }
    );
  }


  private validateOrdemServico() {

    if (this.ordemServico.titulo == null || this.ordemServico.titulo == "") {
      this.configService.showAlert("Digite o Título", 'alert-danger', true);
    } else if (this.ordemServico.data_prevista == null || this.ordemServico.data_prevista == "") {
      this.configService.showAlert("Digite a Data prevista", 'alert-danger', true);
    } else if (this.ordemServico.prioridade_id == null) {
      this.configService.showAlert("Seleccione a prioridade", 'alert-danger', true);
    } else if (this.ordemServico.classificacao_id == null) {
      this.configService.showAlert("Seleccione a classificação", 'alert-danger', true);
    } else if (this.ordemServico.tipo_id == null) {
      this.configService.showAlert("Seleccione o tipo", 'alert-danger', true);
    } else if ((this.associacao_equipamento && this.ordemServico.tipo_id != null) && this.ordemServico.nivel_1 == null) {
      this.configService.showAlert("Seleccione o equipamento", 'alert-danger', true);
    } else if ((this.associacao_cliente && this.ordemServico.tipo_id != null) && (this.ordemServico.cliente_nome == null || this.ordemServico.cliente_nome == "")) {
      this.configService.showAlert("Seleccione o Cliente", 'alert-danger', true);
    } else if ((this.associacao_cliente && this.ordemServico.tipo_id != null) && this.ordemServico.cliente_conta_id == null) {
      this.configService.showAlert("Seleccione a Conta", 'alert-danger', true);
    } else if ((this.associacao_cliente && this.ordemServico.tipo_id != null) && this.ordemServico.cliente_contrato_id == null) {
      this.configService.showAlert("Seleccione o Contrato", 'alert-danger', true);
    } else if (this.ordemServico.descricao == null || this.ordemServico.descricao == "") {
      this.configService.showAlert("Digite a descrição", 'alert-danger', true);
    } else if (this.ordemServico.ot_trigger_flag == 'INSTALACAO-CONTADOR' && (this.local_consumo.cil == '' || this.local_consumo.cil == null)) {
      this.configService.showAlert("Contrato sem CIL", 'alert-danger', true);
    } else if (this.ordemServico.ot_trigger_flag == 'REMOCAO-CONTADOR' && (this.local_consumo.cil == '' || this.local_consumo.cil == null)) {
      this.configService.showAlert("Contrato sem CIL", 'alert-danger', true);
    } else {
      const nextElement: HTMLElement = document.querySelector('#next-step-consumiveis')
      nextElement.click()

    }
  }

  private iniFacturacao() {
    this.facturacao = {
      cliente: {
        id: null,
        nome: null,
        contribuinte: null,
        morada: null,
        telefone: null,
        conta_id: null,
        contrato_id: null,
        email: null,
        genero: null,
        tipo_cliente: null,
        tipo_cliente_id: null,
        tipo_cliente_slug: null,
        documento_id: null,
        gestor_cliente_id: null,
        servico_id: null,
      },
      serie: {
        id: null,
        sigla: null,
        nome: null,
        proximo_numero: null,
        documento: null,
        documento_id: null,
        movimento: null,
        tipo_movimento: null
      },
      produto: [],
      pagamento: {
        forma: null,
        valor_recebido: 0.0,
        total_valor_recebido: 0.0,
        troco: 0.0,
        total_pago: null,
        referencia: null,
        data_pagamento: null,
        forma_pagamento_id: null,
        bancos: null,
        adiantamentos: null,
        is_adiantamento: 0,
        linha_pagamentos: [],
      },
      totalComImposto: 0.0,
      totalSemImposto: 0.0,
      total_com_imposto: 0.0,
      total_sem_imposto: 0.0,
      valor_total_imposto: 0.0,
      total: 0.0,
      success_facturacao: false,
      facturaGerada: null,
      data_vencimento: moment(Date()).format("YYYY-MM-DD").toString(),
      moeda: null,
      divida_cliente: null,
      divida_local_consumo: null,
      divida_total: null,
      processado: null,
      servicos: null,
      total_facturar: null
    };
  }
  private imprimirContrato(id) {
    if (this.empresa.sigla_empresa == "EPASN") {
      this.contratoService.imprimirPDFContrato(id);
    }

    if (this.empresa.sigla_empresa == "EPASLS") {
      this.contratoLundasulService.imprimirPDFContratoLundaSul(id);
    }

    if (this.empresa.sigla_empresa == "EPASLN") {
      this.contratoLundanorteService.imprimirPDFContrato(id);
    }
  }

  private reloadFacturacao() {
    this.contrato_edit = false;
    location.reload();
  }
  private refreshProdutoId(produto: any) {
    this.produtoId = produto.servico_id;
    this.produto_selecionado = produto.servico_valor;
    // this.getAddProduto()
  }
  private _refreshProdutoId(produto: any) {
    this.produtoId = produto.servico_id;
    this.produto_selecionado = produto.servico_valor;
    //this._getAddProduto()
  }

  getProduto(search) {
    this.getServicos(search);
  }

  _getProduto(search) {
    this._getServicos(search);
  }

  private _getServicos(search) {
    this._items_produtos = [];
    this.configService.loaddinStarter("start");
    this.pagination.search = search == "" || search == null ? "a" : search;
    this.pagination.contrato =
      this.orcamento.cliente.contrato_id == "" ||
        this.orcamento.cliente.contrato_id == null
        ? null
        : this.orcamento.cliente.contrato_id;
    this.http
      .__call("artigo/search", {
        pagination: this.pagination,
        search: search,
      })
      .subscribe((res) => {
        this._items_produtos = Object(res);
        this.configService.loaddinStarter("stop");
      });
  }

  private getServicos(search) {
    this.items_produtos = [];
    this.configService.loaddinStarter("start");
    this.pagination.search = search == "" || search == null ? "a" : search;
    this.pagination.contrato =
      this.facturacao.cliente.contrato_id == "" ||
        this.facturacao.cliente.contrato_id == null
        ? null
        : this.facturacao.cliente.contrato_id;

    this.http
      .__call("artigo/search", {
        pagination: this.pagination,
        search: search,
      })
      .subscribe((res) => {
        this.items_produtos = Object(res);
        this.configService.loaddinStarter("stop");
      });
  }
  onItemSelectDepartamento(item: any) {
    this.departamentoArray.push(item);
  }
  onSelectAllDepartamentos(items: any) {
    this.departamentoArray = items;
  }


  onItemSelectTipo(item: any) {
    this.TipoArray.push(item);
  }
  onSelectAllTipos(items: any) {
    this.TipoArray = items;
  }


  private validateOs(value) {
    this.valiateOsFild = value
  }
  createHistory() {
    this.formHistorico = this._formBuilder.group({
      sub_descricao: ['', [Validators.required]],
      title: [null, [Validators.required]],
      sub_estado_id: null,
      hostory_id: [null],
      data: [this.DateTimeZone(), [Validators.required]],
      // user: this.userName.user.nome
    })
  }


  refreshServico(item) {
    this.http
      .__call("produto-classe-tarifario/getServicoById/" + item.servico_id, {
        conta_id: this.facturacao.cliente.conta_id,
        contrato_id: this.contrato.id,
      })
      .subscribe((response) => {
        this.servico = Object(response);

        this.configService.loaddinStarter("stop");
      });
  }

  private checkAllServicos() {
    this.checkAllServicos_flag = !this.checkAllServicos_flag;
    for (let index = 0; index < this.facturacao.servicos.length; index++) {
      if (!this.facturacao.servicos[index].facturado)
        this.facturacao.servicos[index].facturar = this.checkAllServicos_flag;
    }
  }

  private notExistServico(id) {
    for (let index = 0; index < this.facturacao.servicos.length; index++) {
      if (this.facturacao.servicos[index].servico_id == id) return false;
    }

    return true;
  }

  appendServico() {
    this.configService.loaddinStarter("start");

    let quantidade = this.servico.quantidade;
    this.servico.quantidade = 1;

    if (quantidade <= 0 || isNaN(quantidade)) {
      this.configService.showAlert(
        "Não foi informado uma quantidade valida",
        "alert-danger",
        true
      );
      return;
    }

    this.http
      .__call(
        "produto-classe-tarifario/getServicoById/" + this.servico.servico_id,
        {
          conta_id: this.facturacao.cliente.conta_id,
          contrato_id: this.contrato.id,
        }
      )
      .subscribe((response) => {
        let servico = Object(response);

        if (servico != null && this.notExistServico(servico.servico_id)) {
          servico.quantidade = quantidade;

          this.facturacao.servicos.push(servico);

          let valor = servico.servico_valor
            ? servico.servico_valor * quantidade
            : 0;
          this.facturacao.total_sem_imposto += valor;
          let valor_imposto =
            (servico.imposto_valor ? servico.imposto_valor : 0) * valor;
          this.facturacao.valor_total_imposto += valor_imposto;
          this.facturacao.total_com_imposto += valor + valor_imposto;
        }

        this.configService.loaddinStarter("stop");
      });

    this.getValorTotalServicos();
    this.getServicoValorFacturar();
  }

  private getContratoID() {

    if (this.simpleFormCliente.value.contrato_id != null) {
      this.is_contrato_id = true;
      this.getContas(this.simpleFormCliente.value.cliente_id);
      this.simpleFormCreateOrEdit.value.conta_id = this.simpleFormCliente.value.conta;

      this.getContratosByConta();

      this.cliente.nome = this.simpleFormCliente.value.cliente;
      this.editable = true;

      this.simpleFormCreateOrEdit.patchValue({
        cliente_nome: this.simpleFormCliente.value.cliente,
        conta_id: this.simpleFormCliente.value.conta,
        cliente_contrato_id: this.simpleFormCliente.value.contrato_id,
        origem: "COMERCIAL"
      });

    } else {
      this.is_contrato_id = false;
    }

  }

  private setClienteData(item) {

    this.getContas(item.cliente_id);

    this.getContratosByConta();

    this._cliente.nome = item.cliente;
    this.editable = true;

    this.simpleFormCreateOrEdit.patchValue({
      nova_ligacao_id: item.id,
      cliente_nome: item.cliente,
      conta_id: item.conta_id,
      cliente_contrato_id: item.contrato_id,
      origem: "COMERCIAL"
    });

  }


  onResetSome() {
    this.simpleFormCreateOrEdit.patchValue({
      titulo: null,
      sla: null,
      data_prevista: null,
      prioridade_id: null,
      classificacao_id: null,
      email: null,
      descricao: null,
      categoria_descricao: null,
      tipo_id: null
    });

    this.isDisabled = false;
  }



  createFormOT() {
    this.simpleFormCreateOrEdit = this.formBuilder.group({
      index: [{ value: null, disabled: true }],
      cliente_nome: [null],
      titulo: [null],
      data_prevista: [null, Validators.required],
      prioridade_id: [null, Validators.required],
      classificacao_id: [null, Validators.required],
      tipo_id: [null, Validators.required],
      conta_id: [null],
      cliente_contrato_id: [null],
      descricao: [null, Validators.required],
      categoria_descricao: [null],
      predio_nome: [null],
      numero_serie: [null],
      cil: [null],
      origem: [null],
      email: [null],
      nivel_3: [null],
      nivel_2: [null],
      nivel_1: [null],
      causa_id: [null],
      sintoma_id: [null],
      sla: [null],
      nova_ligacao_id: null
    })
  }
  // convenience getter for easy access to form fields
  get g() {
    return this.simpleFormCreateOrEdit.controls;
  }

  exportAsPDF(): void {
    var file = document.getElementsByClassName("exportAsXLSXCliente")[0]
    this.relatorioRegisto.relatorioRegisto('save', file, this.localUrl);

  }

  imprimirPDF(): void {
    var file = document.getElementsByClassName("exportAsXLSXCliente")[0];
    this.relatorioRegisto.relatorioRegisto('print', file, this.localUrl);
  }

  imprimir(data): void {
    this.http.call_get('cliente/getClienteById/' + data.cliente_id, null).subscribe(

      response => {
        let clientes = []
        let datas = []
        clientes.push(Object(response));
        datas.push({ ...data, cliente: Object(response), empresa: this.empresa, user: this.userName })
        this.relatorioRegisto.relatorioRegistoPdf('imprimir', datas, this.localUrl);
      })


  }
  private setCliente(client: any) {
    this.simpleFormCliente.patchValue({
      cliente_id: client.id,
      cliente: client.nome,
      cliente_telefone: client.telefone
    });
    this.selectedCliente(client.id);
    this.selectedCliente_orc(client.id)
    this.getTiposMedicaos();
    this.getConta(client)
    this.getCliente()
    this.getDividaAnteriorByClienteId(client.id)
    this.view_client = false;
    this.clientes = []
  }
  private setHistory(history: any) {
    this.formHistorico.patchValue({
      hostory_id: history.id,
      sub_descricao: history.descricao,
      title: history.title,
      sub_estado_id: history.estado_id,
      data: this.DateTimeZone(),
      time: this.DateTimeZone(),
      user: this.userName,
    });
    this.simpleFormCliente.patchValue(
      {
        estado_id: history.id_estado,
        data: this.DateTimeZone(),
        time: this.DateTimeZone(),
        user: this.userName,
      });
    //this.iniCliente(history)
    //this.getTipo()
    this.view_client = false;
  }
  private resetHistory() {
    this.formHistorico.reset();
  }

  private listarseries_ocr() {
    this.http.call_get('serie/selectBoxSerieOrcamento', null).subscribe(
      data => {

        this._series = Object(data).data
        if (this._series.length == 1) {
          this._series.forEach(element => {

            if (element.sigla == 'FT') {
              this.configService.listarBancos();
              this.configService.listarMoedas();
            }
            if (element.sigla == 'OR') {

              this.orcamento.serie.id = element.id
              this.orcamento.serie.nome = element.nome
              this.orcamento.serie.sigla = element.sigla
              this.orcamento.serie.proximo_numero = element.proximo_numero
            }
          });
        }
      }
    );
  }

  private listarseries() {
    this.http
      .call_get("serie/loja/selectSerieLojaFacturaBox", null)
      .subscribe((data) => {
        let serie = Object(data).data.find((obj) => obj.sigla == "FT");

        this.facturacao.serie.id = serie.id;
        this.facturacao.serie = serie;

        this.series = Object(data).data;
        if (this.series.length == 1) {
          this.series.forEach((element) => {
            if (element.sigla == "FT") {
              this.configService.listarBancos();
              this.configService.listarMoedas();
            }

            this.facturacao.serie.id = element.id;
            this.facturacao.serie.nome = element.nome;
            this.facturacao.serie.sigla = element.sigla;
            this.facturacao.serie.proximo_numero = element.proximo_numero;
          });
        }
      });
  }


  private empresaUser() {
    this.configService.loaddinStarter('start');

    this.http.call_get('empresa/empresa-user', null).subscribe(
      response => {
        this.configService.loaddinStarter('stop');
        this.empresa = Object(response).data
        this.localUrl = Object(response).data.logotipo
        this.altura = Object(response).data.width
        this.largura = Object(response).data.height
      }
    );
  }

  private listarOrdemServico(id) {


    this.filters.permission = localStorage.getItem('permission');
    this.simpleFormCreateOrEdit.patchValue({ nova_ligacao_id: id })

    this.loading = true
    this.http.__call('ordemservico/ordem-by-ligacao/' + id, this.filters)
      .subscribe(
        response => {
          this.loading = false
          this.itemsOrdem = Object(response).data;
        }
      );
  }

  calcularPercentual(f: any) {
    var calc = f.total - f.valor_aberto;
    return f.serie.documento.sigla == "NC"
      ? 100
      : f.pago === 0 && f.status === "N" && f.valor_aberto == null
        ? 0
        : (calc * 100) / f.total;

    //(f.total === f.valor_aberto ? 0 : f.pago == 1 ? 100 : (calc * 100) / f.total);
  }

  private listarFacturacao(id) {
    this.loading = true;

    this.http.__call(`lista_factura/` + id, null)
      .subscribe((response) => {
        this.loading = false;
        this.facturas = Object(response).data;
      });
  }

  private listarOrcamento(id) {
    this.loading = true;

    this.http.__call(`lista_orcamento/` + id, null)
      .subscribe((response) => {
        this.loading = false;
        this.orcamentos = Object(response).data;
      });
  }



  private validateEstado(estado_id) {
    const res = this.estado.map(res => {
      if (res.id == estado_id) {
        return res.descricao
      }

      this.validateState.push(res.descricao)
      return res.descricao
    })
  }


  private getContaContrato() {
    this.activeButtom.cliente = false;
    this.configService.loaddinStarter("start");
    this.http
      .call_get("conta/contratos/" + this.ordemServico.cliente_conta_id, null)
      .subscribe((response) => {
        this.contratos_conta_cliente = Object(response).data;

        if (this.contratos_conta_cliente.length == 1) {
          this.default = this.contratos_conta_cliente[0].id;

        }

        this.configService.loaddinStarter("stop");
      });
  }

  private selectedCliente(id) {
    this.facturacao.cliente.id = id;
    this.clientes.forEach((element) => {
      if (this.facturacao.cliente.id == element.id) {
        this.facturacao.cliente.id = element.id;
        this.facturacao.cliente.nome = element.nome;
        this.facturacao.cliente.contribuinte =
          element.numero_identificacao || element.nif;
        this.facturacao.cliente.telefone = element.telefone;
        this.facturacao.cliente.morada = element.morada;
        this.activeButtom.cliente = true;
      }
    });

  }


  private getAssociacao() {

    this.simpleFormCreateOrEdit.patchValue({
      titulo: this.simpleFormCreateOrEdit.value.titulo
    });

    this.is_titulo = false;

    this.simpleFormCreateOrEdit.patchValue({
      categoria_descricao: null
    });

    if (this.simpleFormCreateOrEdit.value.tipo_id != null) {
      this.configService.loaddinStarter('start');
      this.http.call_get('ordemservico/ostipo/associacao/' + this.simpleFormCreateOrEdit.value.tipo_id, null).subscribe(
        response => {

          this.simpleFormCreateOrEdit.patchValue({
            categoria_descricao: Object(response).data.categoria_descricao,
            sla: Object(response).data.sla
          });

          this.is_sintoma = Object(response).data.sintoma;
          this.is_causa = Object(response).data.causa;
          this.is_titulo = Object(response).data.is_titulo;

          if (this.is_titulo) {

            this.simpleFormCreateOrEdit.patchValue({
              titulo: Object(response).data.descricao
            });


          }
          this.configService.loaddinStarter('stop');
        }

      );
    }

    this.is_titulo = false

  }



  /* private getTipoByClassificacao() {
    this.carregando.tipo = 'Carregando...';
    this.http.call_get('ordemservico/ostipo-by-classificacao/selectBox/' + this.simpleFormCreateOrEdit.value.classificacao_id, null).subscribe(
      response => {
        this.ot_tipos = Object(response).data;
        this.carregando.tipo = 'Seleccione o tipo';
      }
    );
  } */

  private getCliente() {
    this.contas = []
    this.contratos = []
    this.view_client = true;
    this.http.__call('cliente/search-cliente', { start: 1, end: 10, search: this.simpleFormCliente.getRawValue().cliente }).subscribe(
      response => {
        this.clientes = Object(response).data.data;
      })
  }


  private tiposFacturacao: any = [];
  private loadTipoFacturacao = {
    tipo_facturacao: 'Seleccione o tipo',
  }

  private selectBoxTipoFacturacao() {
    this.loadTipoFacturacao.tipo_facturacao = 'Carregando...';

    this.http.call_get('tipo-facturacao/selectBox', null).subscribe(
      response => {
        this.tiposFacturacao = Object(response);
        this.loadTipoFacturacao.tipo_facturacao = 'Seleccione o tipo';

      }
    );

  }

  private getContasByCliente(client: any) {
    this.cliente = client;

    this.conta.cliente_id = client.id
    this.conta.contrato_id = client.contrato_id

    this.configService.loaddinStarter('start');
    this.http.call_get('cliente/conta/listar/' + client.id, null).subscribe(
      response => {
        this.contas = Object(response).data;
        this.configService.loaddinStarter('stop');
      }
    );

  }

  private setMediaConsumo() {
    this.contrato.media_consumo = 0;
    this.mediaConsumoModal.show();
  }
  private closeMediaConsumoModal() {
    this.mediaConsumoModal.hide();
  }

  private listarLojas() {

    this.http.call_get('loja/selectBox', null).subscribe(
      response => {
        this.lojas = Object(response).data;
      }
    );
  }
  private registerConta() {
    if (this.conta.tipo_facturacao_id == null) {
      this.configService.showAlert('Seleccione o tipo de facturação', 'alert-danger', true);

    } else {
      var url = 'cliente/conta/register';
      if (this.conta.id != null) {
        url = 'cliente/conta/update/' + this.conta.id;
      }

      this.configService.loaddinStarter('start');

      try {
        this.http.__call(url, this.conta)
          .subscribe(
            res => {
              if (Object(res).code == 500) {
                this.configService.showAlert(Object(res).message, 'alert-danger', true);
              } else {
                this.configService.showAlert(Object(res).message, 'alert-success', true);
                this._closeModal('closeModalconta');
                this.configService.loaddinStarter('stop');
                this.conta.numero_conta = null;
                this.conta.agencia_id = null;
                this.conta.descricao = null;
                this.conta.estado = null;
                this.conta.tipo_facturacao_id = null
                this.conta.moeda_id = null
                this.conta.dataEstado = null

                this.simpleFormCliente.patchValue({
                  conta_id: Object(res).data.id
                })
                if (this.simpleFormCliente.value.conta_id) {
                  let cliente_value = this.simpleFormCliente.getRawValue()
                  this._createOrUpdateCliente(`nova-ligacao/ligacao/${this.simpleFormCliente.value.id}`, cliente_value)
                }
                this.getContasByCliente({ nome: this._cliente.nome, id: this.conta.cliente_id })

              }
            }
          )
      } catch (error) {
      }
    }
  }

  private _getConta(cliente, ligacao_id = null) {
    this._contas_clientes = []
    this.http.call_get("cliente/conta/selectBox-nova-ligacao/" + cliente.conta_id + "/" + ligacao_id).subscribe(
      response => {
        this.contas = Object(response).data
        this._contas_clientes = Object(response).data;
        if (this._contas_clientes.length == 1) {
          this.default = this._contas_clientes[0].numero_conta;
          this.facturacao.cliente.conta_id = this.default;
        }
      })
  }
  private getConta(cliente) {

    this.http.call_get('cliente/conta/selectBox/' + cliente.id, null).subscribe(
      response => {
        this.contas = Object(response).data
        this.contas_clientes = Object(response).data;

        if (this.contas_clientes.length == 1) {
          this.default = this.contas_clientes[0].numero_conta;
          this.facturacao.cliente.conta_id = this.default;
        }
      })
  }
  private getAnexo(item) {
    this.anexos = item.comunicacaoAnexo
  }

  validateIfIsFlag: boolean = false

  matchValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const fromValue = control.value;
    if (fromValue) {

      const estado = this.simpleFormCliente.controls.estado_id.value

      if (estado != 'null') {
        return null;
      }
      return { invalidDate: true };
    }
  }

  public estadoFlag(id) {
    this.estado_flag = null
    this.http.__call('nova-ligacao/estado-by-id/' + id, null).subscribe(
      response => {
        this.estado_flag = (Object(response).data || {}).flag;
      })

  }

  public getContratosByConta() {
    this.configService.loaddinStarter('start');
    this.contratos = []
    this.is_contrato_id = null;
    let conta_id = this.simpleFormCliente.get('conta').value

    if (conta_id) {
      this.http.__call('conta/contratoByConta/' + conta_id, null).subscribe(
        response => {
          this.contratos = Object(response);
          this.configService.loaddinStarter('stop');
        }
      );
    }
  }

  private getPrioridade() {
    this.http.call_get('ordemservico/prioridade/selectBox', null).subscribe(
      response => {
        this.prioridades = Object(response).data;
      }
    );
  }

  private getClassificacao() {

    const uri = 'ordemservico/classificacao-comercial/selectBox';

    this.http.call_get(uri, null).subscribe(
      response => {
        this.classificacoes = Object(response).data;
      }
    );
  }

  private getEstado(data) {
    this.estado = []
    if (!data) return
    this.http.__call('nova-ligacao/estado-listar-ativo', data).subscribe(
      response => {
        this.estado = Object(response).data;
        if (this.simpleFormCliente.value.estado_orcamento_id == '0') {
          this.estado = this.estado.filter(item => item.flag == 'CANCELADO')
        }
      })
  }




  private getTipo() {
    this.http.__call('nova-ligacao/tipo-ligacao-listarAll', null).subscribe(
      response => {
        this.tipos = Object(response).data;
      })
  }
  private getMotivo() {
    this.http.__call('nova-ligacao/motivo-ligacao-listarAll', null).subscribe(
      response => {
        this.motivo = Object(response).data;
      })
  }


  private getLocalConsumoByContratoId() {

    this.simpleFormCreateOrEdit.patchValue({
      cil: null,
      numero_serie: null,
      predio_nome: null
    });

    if (this.simpleFormCreateOrEdit.value.cliente_contrato_id != null) {

      this.cliente_contrato_id = this.simpleFormCreateOrEdit.value.cliente_contrato_id;

      this.http.call_get('local-consumo/getLocalConsumoByContratoId/' + this.simpleFormCreateOrEdit.value.cliente_contrato_id, null).subscribe(
        response => {

          this.local_consumo = Object(response);
          this.simpleFormCreateOrEdit.patchValue({
            cil: this.local_consumo.cil == null ? '' : this.local_consumo.cil,
            numero_serie: this.local_consumo.numero_serie == null ? '' : this.local_consumo.numero_serie,
            predio_nome: ((this.local_consumo.cil != null) ? '[' + this.local_consumo.cil + '] ' : '[0000-000-000] ') + ((this.local_consumo.is_predio) ? 'Prédio ' + this.local_consumo.predio_nome + ', ' + (this.local_consumo.predio_andar ? this.local_consumo.predio_andar + ' º Andar - ' : '') + 'Porta ' : 'Residência ') + this.local_consumo.moradia_numero
          });

        }
      );
    } else {
      this.simpleFormCreateOrEdit.patchValue({
        cil: null,
        numero_serie: null,
        predio_nome: null
      });

      this.cliente_contrato_id = null
    }



  }


  private getOrigem(id) {
    this.http.__call('comunicacoes/origem/edite/' + id, null).subscribe(
      response => {
        this.origems = Object(response).data;
      })
  }
  private getcategoria() {
    this.refreshNivelId()
    this.nivels = []
    this.origems = [],
      this.nivel_ids = [],
      this.encamiamento = []
    this.http.__call('comunicacoes/categorias_listagem_all', null).subscribe(
      response => {
        this.categorias = Object(response).data;
      })
  }

  private getNivel() {
    this.refreshNivelId()
    this.nivels = []
    this.origems = [],
      this.nivel_ids = [],
      this.encamiamento = []
    const id = this.simpleFormCliente.get('categoria').value
    this.http.__call('comunicacoes/categoriasByNivel/' + id, null).subscribe(
      response => {
        this.nivels = Object(response).data;
      })
  }

  refreshNivelId() {
    const data = () => {
      this.origems = [],
        this.nivel_ids = [],
        this.encamiamento = []
    }
    return data
  }


  changeValue(value) {
    this.stateValidate = value
    this.DateTimeZone()
  }

  private getHistoricoId(id) {
    this.simpleFormCliente.patchValue({
      id: id
    })
    // this.getHistorico()
  }

  private resetHistorico() {
    this.formHistorico.reset()
  }

  private createHistorico() {
    const url = this.stateValidate == true ?
      `historico/${this.simpleFormCliente.value.id}`
      : `historico/update/${this.formHistorico.value.hostory_id}/${this.simpleFormCliente.value.id}`
    this.getHistorico(this.simpleFormCliente.value.id)
    this.http.__call(url, this.formHistorico.value).subscribe(
      response => {
        this.submitted = false
        this.loading = false
        this.configService.showAlert(Object(response).message, 'success', true)

        this.getRegisto()
        if (this.stateValidate == true) this.resetHistory()
      },
      error => {
        this.configService.showAlert("Alguma coisa correu mal", 'warning', true);
        this.submitted = false;
      })
  }

  private getHistorico(id) {


    this.http.__call(`historico/list/${id}`, this.filtersComunca).subscribe(
      response => {
        this.filtersComunca.pagination.lastPage = Object(response).data.lastPage;
        this.filtersComunca.pagination.page = Object(response).data.page;
        this.filtersComunca.pagination.total = Object(response).data.total;
        this.filtersComunca.pagination.perPage = Object(response).data.perPage;
        this.historico = Object(response).data.data;
      }

    )
  }

  setTrue() {
    this.filters.pagination.page = this.filters.pagination.page
    this.filters.is_allexel = true
    this.getRegisto()
  }

  changeValueOfMotivoOption(value) {
    if (value == null || value == 'null') {
      this.disableEstado = false
      this.simpleFormCliente.controls.estado_id.disable();
    } else {
      this.simpleFormCliente.controls.estado_id.enable();
    }
  }


  private getRegisto() {
    this.loading = true
    this.filters.filter = this.filter
    this.http.__call('nova-ligacao/ligacao-listar', this.filters).subscribe(
      response => {
        this.filters.pagination.lastPage = Object(response).data.lastPage;
        this.filters.pagination.page = Object(response).data.page;
        this.filters.pagination.total = Object(response).data.total;
        this.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.loading = false;
        /*  if (this.items != 0) {
           this.disableButton = false;
         } */
        if (this.filters.is_allexel == false) {
          this.items = Object(response).data.data;
        } else {
          this.items = Object(response).data.data;
          this.exportAsXLSX(this.items)
        }
        this.filters.is_allexel = false

      }
    );

    this.loading = false;
  }

  private getRegistoById(id) {
    this.loading = true
    this.http.__call('comunicacoes/registro_listagem/' + id, this.filters).subscribe(
      response => {
        this.filters.pagination.lastPage = Object(response).data.lastPage;
        this.filters.pagination.page = Object(response).data.page;
        this.filters.pagination.total = Object(response).data.total;
        this.filters.pagination.perPage = Object(response).data.perPage;

        this.items1 = Object(response).data.data;
        this.loading = false;

        if (this.filters.is_allexel == false) {
          this.items1 = Object(response).data.data;
        } else {
          this.items1 = Object(response).data.data;
          this.exportAsXLSX(this.items1)
        }
        this.filters.is_allexel = false
        this.loading = false;
      }
    );
  }

  private getContas(cliente_id) {
    //  this.loading = true
    this.contas = [];

    this.simpleFormCreateOrEdit.patchValue({
      cil: null,
      numero_serie: null,
      predio_nome: null
    });

    this.http.call_get('cliente/conta/selectBox/' + cliente_id, null).subscribe(
      response => {
        this.contas = Object(response).data

        //  this.loading = false
      })
  }


  exportAsXLSX(data: any): void {
    var CurrentDate = new Date();
    for (let i = 0; i < data.length; i++) {
      if (data[i].is_active == 1) {
        data[i].is_active = "Aberto"
      } else {
        data[i].is_active = "Fechado"
      }
    }
    var keys = [
      { key: 'categoria', width: 50 },
      { key: 'descricao', width: 50 },
      { key: 'cliente_id', width: 30, style: { font: { name: 'Calibri' } } },
      { key: 'contaDescricao', width: 30 },
      { key: 'contrato_id', width: 30 },
      { key: 'cliente', width: 30, style: { font: { name: 'Calibri' } } },
      { key: 'municipio_nome', width: 30, style: { font: { name: 'Calibri' } } },
      { key: 'cil', width: 30, style: { font: { name: 'Calibri' } } },
      { key: 'desc', width: 30 },
      { key: 'encaminhamento', width: 30 },
      { key: 'origem', width: 30 },
      { key: 'nivel_desc', width: 30 },
      { key: 'data', width: 50, style: { numFmt: 'dd/mm/yyyy' } },
      { key: 'data', width: 50, style: { numFmt: 'HH:MM' } },

    ];

    var Cols = [
      'Categoria Descrição',
      'Descrição',
      'Nº Cliente',
      'Conta',
      'Contrato',
      'Cliente',
      'Município',
      'Cil',
      'Estado',
      'Encaminhamento',
      'Origem',
      'Nível',
      'Data',
      'Hora',
    ]

    var title = 'RELATÓRIOS COMUNICAÇÃO REGISTO'
    var nameFile = "RELATORIOS_COMUNICACAO_REGISTO -" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    this.excelsService.excels(data, nameFile, this.localUrl, keys, Cols, title, 5, 14, 40, 3)
  }

  private setFlag(flag) {
    return this.flag = flag
  }
  hendleHistoricoNovaLigacao(data) {
    this.http.__call('nova-ligacao/historico', data).subscribe(
      response => { })
  }
  onSubmit() {

    this.submitted = true
    if (this.simpleFormCliente.value.id == null) {
      this.simpleFormCliente.patchValue({
        estado_id: 1
      })
    }

    if (this.simpleFormCliente.invalid) { return }
    let cliente_value = this.simpleFormCliente.getRawValue()

    let create_registo = (cliente_value.id ? (`nova-ligacao/ligacao/${cliente_value.id}`) : ('nova-ligacao/ligacao'))
    this.nova_ligacao_service.id = this.simpleFormCliente.getRawValue().id;

    let url_action = null
    /* if (this.flag == 1) {
      url_action = add_registo
    } else { }*/
    url_action = create_registo


    this._createOrUpdateCliente(url_action, cliente_value)
  }

  onSubmitCriarOT() {

    this.submitted = true;

    // parar aquei se o formulário for inválido
    if (this.simpleFormCreateOrEdit.invalid) {
      return;
    }
    this.loading = true;
    const index = this.simpleFormCreateOrEdit.getRawValue().index;
    // TODO: usado para fazer a requisição com a api de criação de objsct or update
    const uri = 'ordemservico/ordem-servico/create';
    this.createNewOT(uri, this.simpleFormCreateOrEdit, (index === null ? true : false));

  }

  createNewOT(uri: any, formulario: FormGroup, isCreate: boolean) {

    this.isDisabled = true;
    // TODO: usado para fazer a requisição com a api de criação de object
    let uploadData = new FormData();

    uploadData.append("ordemServico", JSON.stringify(formulario.value));
    uploadData.append("causas", JSON.stringify(this.causasArray));
    uploadData.append("sintomas", JSON.stringify(this.sintomasArray));
    uploadData.append("departamentos", JSON.stringify(this.departamentoArray));

    this.http.$__call(uri, uploadData).subscribe(
      response => {
        this.submitted = false;
        this.loading = false;


        //this.closebutton.nativeElement.click();

        this.onReset()

        if (isCreate && Object(response).code == 200) {
          formulario.reset();
          this.onReset()
        }

         if ((Object(response).body || {}).code == 200) {
           this.alertPrintOrdemServico((Object(response).body || {}).data.ordem.id)
          formulario.reset();
          this.onReset()

        }
      },
      error => {
        this.submitted = false;
        this.loading = false;
      });
  }

  alertPrintOrdemServico(id){
   
    if(!id) return
     Swal.fire({
         title: 'Imprimir Ordem de Serviço!',
         text: "Ordem de Serviço!",
         type: 'success',
         showCancelButton: true,
         confirmButtonText: 'Imprimir',
         cancelButtonText: 'Cancelar!',
         reverseButtons: true
     }).then((result) => {
         if (result.value) {
           this.imprimirOrdemServico(id)
         }
     })
 
 }
 private imprimirOrdemServico(id) {
   this.configService.imprimirOrdemServico(id, "2ª Via", "imprimir")
 }
  _createOrUpdateCliente(url, cliente_value) {
    let uploadData = new FormData();

    this.http.$__call(url, cliente_value).subscribe(
      (event: HttpEvent<any>) => {
        if (this.simpleFormCliente.value.id == null) {
          this.onReset()
        }

        switch (event.type) {
          case HttpEventType.Sent:
            break;
          case HttpEventType.ResponseHeader:
            break;
          case HttpEventType.UploadProgress:
            this.progress = Math.round(event.loaded / event.total * 100);
            break;
          case HttpEventType.Response:
            this.hendleHistoricoNovaLigacao({
              ...cliente_value,
              user_id: this.userStoregeId
            })

            setTimeout(() => {
              this.progress = 0;
              this.fileList = []
            }, 1500);

        }
        this.submitted = false
        this.loading = false
        this.getRegisto()
        this.getHistorico(this.simpleFormCliente.value.id)
      },
      error => {
        this.configService.showAlert("Alguma coisa correu mal", 'warning', true);
        this.submitted = false;
      },
    ),
      /*

            this.getPageFilterDataPage(1) */
      this.loading = false
  }

  getHistoricoNovaLigacao(id) {
    //  this.loading = true
    this.http.__call('nova-ligacao/historico-listar/' + id, null).subscribe(
      response => {

        this.historicoNovaLigacao = Object(response).data;
      })
  }

  isCreateToContrato() {
    this.local_consumo.cil = this.configService.contrato.cil_disponivel;
    this.getLocalInstalacaosByCILAndMoradia()
  }

  private orcamento_id: any;
  private nova_ligacao_id: any;
  iniCliente(cliente) {
    this.orcamento_id = cliente.orcamento_id
    this.nova_ligacao_id = cliente.id
    this.getHistoricoNovaLigacao(cliente.novaligacao_id)
    this.validateButton = null
    this.conta.id = cliente.conta_id
    this.conta.cliente_id = cliente.cliente_id
    this.configService.contrato.cil_disponivel = cliente.cil

    if (cliente.conta_id)
      this.getContaById(cliente.conta_id)
    this.disable(true);
    this.getContas(cliente.cliente_id)
    this._getConta(cliente, cliente.novaligacao_id)
    this._cliente.nome = cliente.cliente
    if (cliente.orcamento_id && cliente.estado_flag == 'EM_ORCAMENTACAO') {
      this.simpleFormCliente.controls.estado_orcamento_id.enable();
      this.is_orcameto = false
    } else {
      this.is_orcameto = true
      this.simpleFormCliente.controls.estado_orcamento_id.disable();
    }
    this.estado_flag = cliente.estado_flag
    this.changeValueOfMotivoOption(null)
    this.getEstado({ estado: cliente.estado_flag })
    this._estado_flag = cliente.estado_flag;

    this.simpleFormCliente.patchValue({
      tipo_ligacao_id: cliente.tipo_ligacao_id,
      estado_id: cliente.estado_id,
      motivo: null,
      id: cliente.id,
      cliente: cliente.cliente,
      cliente_telefone: cliente.cliente_telefone,
      contrato_id: cliente.contrato_id,
      tipo_id: cliente.tipo_id,
      descricao: cliente.descricao,
      title: cliente.title,
      origem: cliente.origem_id,
      categoria: cliente.comunicacao_categoria_id,
      data: this.DateTimeZone(),
      time: this.DateTimeZone(),
      conta: cliente.conta_id,
      nivel_1: cliente.comunicacao_nivel_id,
      nivel_2: cliente.nivel_id,
      encaminhamento: cliente.encaminhamento_id,
      user: this.userName,
      hostory_id: cliente.h_id
    })
    this.getContratosByConta()
    this.setCliente({ nome: cliente.cliente, id: cliente.cliente_id, telefone: cliente.cliente_telefone })
    this.getMotivo()
    this.getCliente()
    this.getTipo()
    this.getHistorico(cliente.id)
    this.view_client = false;
  }

  _closeModal(closeModal) {
    var action = document.getElementById(closeModal)
    action.click()
  }
  private disable(condition) {

    if (condition == true) {
      this.simpleFormCliente.controls.cliente.disable();
      //this.simpleFormCliente.controls.cliente_telefone.disable();
      this.simpleFormCliente.controls.contrato_id.disable();

      this.simpleFormCliente.controls.data.disable();
      this.simpleFormCliente.controls.conta.disable();
      this.simpleFormCliente.controls.tipo_ligacao_id.disable();

      this.simpleFormCliente.patchValue(
        {
          descricao: null,
        })

    } else {
      this.simpleFormCliente.controls.data.disable();
      this.simpleFormCliente.controls.cliente.enable();
      //this.simpleFormCliente.controls.cliente_telefone.enable();
      this.simpleFormCliente.controls.contrato_id.enable();
      this.simpleFormCliente.controls.title.enable();
      this.simpleFormCliente.controls.conta.enable();
      this.simpleFormCliente.controls.tipo_ligacao_id.enable();
    }

  }


  private imprimirNovaLigacao(id) {

    this.configService.imprimirNovaLigacao(id, "2ª Via", "imprimir")
  }


  get f() {
    return this.simpleFormCliente.controls;
  }
  get fs() {
    return this.formHistorico.controls;
  }
  onReset() {
    if (this.stateValidate == false) {
      this.submitted = false;
      this.simpleFormCliente.reset(
        {
          historico_id: null,
          cliente: null,
          cliente_id: null,
          tipo_id: [null],
          descricao: '',
          estado_id: null,
          origem: null,
          categoria: null,
          encaminhamento: null,
          title: null,
          cliente_telefone: null,
          data: this.DateTimeZone(),
          nivel_1: [null,],
          // nivel_1: [null, [Validators.required]],
          nivel_2: [null],
          conta: [null],
          contrato_id: [null],
          hostory_id: null,
          user: this.userName,
          file: [null]
        });
      this.simpleFormCliente.patchValue(
        {
        }
      );
    }
    this.fileList = []
    this.contas = []
    this.contratos = []
    return
  }

  getPageFilterDataPage(page: number) {
    if (this.filters.pagination.perPage == null) {
      return;
    }
    this.filters.pagination.page = Number.isInteger(page) ? page : 1;
    this.getRegisto();
  }
  getPageFilterDataPageCmunica(page: number) {
    if (this.filtersComunca.pagination.perPage == null) {
      return;
    }
    this.filtersComunca.pagination.page = Number.isInteger(page) ? page : 1;
    this.getHistorico(this.simpleFormCliente.value.id);
  }


  modalDescricao(data) {
    if (data.length < 96) return
    const swal = Swal.mixin({

    })
    swal.fire({
      title: 'Descrição',
      html: '<p>' + data + '</p>'
    })

  }

  files: File[] = [];

  file() {
    this.files.push(this.filenout.files)
    for (var i = 0; i < this.filenout.files.length; i++) {

      this.fileList.push({
        name: this.filenout.files[i].name,
        size: this.filenout.files[i].size,
        file: this.filenout.files[i]
      })
    }

  }

  removeFile(value) {
    const index = this.fileList.indexOf(value)
    this.fileList.splice(index, 1);
  }


  async downloads({ ext, url, nome, id, flag }: Ifile) {
    const documentStyles = document.documentElement.style;

    this.progress = id
    this.progress_1 = flag
    url = environment.app_url + url;

    axios({
      url: url,
      method: "post",
      responseType: "blob",
      async onDownloadProgress(progressEvent) {
        if (flag == 'no') {
          this.progress = await Math.round((progressEvent.loaded / progressEvent.total) * 100);
          documentStyles.setProperty('--progress', `${this.progress}%`);
        } else {
          this.progress = await Math.round((progressEvent.loaded / progressEvent.total) * 100);
          documentStyles.setProperty('--progress', `${this.progress}%`);
        }
      }
    }).then(response => {
      var binaryData = [];
      var index = nome.indexOf(".");
      var afterComa = nome.substr(index, nome.length);

      if (ext == "vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        nome = nome.replace(afterComa, '.xlsx')
      }
      else if (ext == "vnd.oasis.opendocument.text") {
        nome = nome.replace(afterComa, '.odt')
      }

      const res = response
      binaryData.push(res.data);
      if (flag == 'no') {
        this.progress = 0;
        documentStyles.setProperty('--progress', `${this.progress}%`);
        return saveAs(new Blob(binaryData, { type: res.data.type }), nome)
      }
      this.progress = 0;
      documentStyles.setProperty('--progress', `${this.progress}%`);
      const fileURL = window.URL.createObjectURL(new Blob(binaryData, { type: res.data.type }))
      window.open(fileURL, '_blank');
    });
  }

  view_local_consumo_cil = false;
  view_local_consumo = false;

  private getLocalInstalacaosByCILAndMoradia() {
    if (this.local_consumo.cil == "") {
      //this.setNullResidencia();
    }

    this.view_local_consumo_cil = true;
    this.http
      .__call("local-instalacao/getLocalInstalacaosByCILAndMoradia", {
        start: 1,
        end: 15,
        search: this.local_consumo.cil,
      })
      .subscribe((response) => {
        this.local_consumos = Object(response).data.data;

        this.configService.loaddinStarter("stop");
      });
  }
  private setLocalConsumo(item: any) {
    this.local_consumo.id = item.id;
    this.local_consumo.moradia_numero = item.moradia_numero;
    this.local_consumo.predio_nome =
      (item.cil != null ? "[" + item.cil + "] " : "[0000-000-000] ") +
      (item.is_predio
        ? "Prédio " +
        item.predio_nome +
        ", " +
        this.configService.decimalFormat(item.predio_andar) +
        "º Andar - Porta "
        : "Residência ") +
      this.configService.decimalFormat(item.moradia_numero);
    this.local_consumo.is_predio = item.is_predio;
    this.local_consumo.cil = item.cil;

    this.local_consumo.provincia_id = item.provincia_id;

    this.local_consumo.municipio_id = item.municipio_id;
    this.local_consumo.municipio = item.municipio;
    this.local_consumo.distrito_id = item.distrito_id;
    this.local_consumo.distrito = item.distrito;
    this.local_consumo.bairro_id = item.bairro_id;
    this.local_consumo.bairro = item.bairro;
    this.local_consumo.quarteirao_id = item.quarteirao_id;
    this.local_consumo.quarteirao = item.quarteirao;
    this.local_consumo.rua_id = item.rua_id;
    this.local_consumo.rua = item.rua;

    this.view_local_consumo = false;
    this.view_local_consumo_cil = false;

    this.view_local_consumo_propierties = true;

    this.contrato.instalacao_sanitaria_qtd = item.instalacao_sanitaria_qtd;
    this.contrato.reservatorio_flag = item.reservatorio_flag;
    this.contrato.reservatorio_capacidade = item.reservatorio_capacidade;
    this.contrato.piscina_flag = item.piscina_flag;
    this.contrato.piscina_capacidade = item.piscina_capacidade;
    this.contrato.jardim_flag = item.jardim_flag;
    this.contrato.campo_jardim_id = item.campo_jardim_id;
    this.contrato.poco_alternativo_flag = item.poco_alternativo_flag;
    this.contrato.fossa_flag = item.fossa_flag;
    this.contrato.fossa_capacidade = item.fossa_capacidade;
    this.contrato.acesso_camiao_flag = item.acesso_camiao_flag;
    this.contrato.anexo_flag = item.anexo_flag;
    this.contrato.anexo_quantidade = item.anexo_quantidade;
    this.contrato.caixa_contador_flag = item.caixa_contador_flag;
    this.contrato.abastecimento_cil_id = item.abastecimento_cil_id;

    setTimeout(() => {
      this.selectBoxRuasByBairro();

      this.selectBoxDistritosByMunicipio(item.municipio_id);
      this.selectBoxQuarteiraosByBairro(item.bairro_id);
      this.selectBoxRuasByQuarteirao(item.quarteirao_id);

      this.local_consumo.quarteirao_id = item.quarteirao_id;
      this.local_consumo.rua_id = item.rua_id;

      this.local_consumo.cil = this.local_consumo.cil_flag
        ? this.local_consumo.predio_nome
        : item.cil;

    }, 1500);
  }

  private selectBoxQuarteiraosByBairro(id) {
    if (this.local_consumo.cil_flag == false && !this.contrato_edit) {
      this.local_consumo.quarteirao_id = null;
      this.local_consumo.rua_id = null;
    }

    if (!this.contrato_edit) {
      for (let i = 0; i < this.bairros.length; ++i) {
        if (this.bairros[i].id == id)
          this.local_consumo.has_quarteirao = this.bairros[i].has_quarteirao;
      }
    }

    if (this.local_consumo.has_quarteirao || this.contrato_edit) {
      this.http
        .call_get("quarteirao/getQuarteiraosByBairro/" + id, null)
        .subscribe((response) => {
          this.quarteiraos = Object(response).data;

          if (this.quarteiraos.length) this.local_consumo.has_quarteirao = true;
        });
    }

    if (!this.local_consumo.has_quarteirao || this.contrato_edit) {
      this.selectBoxRuasByBairro();
    }
  }

  private selectBoxRuasByQuarteirao(id) {
    this.http
      .call_get("rua/getRuasByQuarteirao/" + id, null)
      .subscribe((response) => {
        this.ruas = Object(response).data;
      });
  }

  private selectBoxRuasByBairro() {
    this.http
      .call_get("rua/selectBoxByBairro/" + this.local_consumo.bairro_id, null)
      .subscribe((response) => {
        this.ruas = Object(response);
      });
  }

  private getProvincias() {
    if (this.local_consumo.cil_flag == false && !this.contrato_edit) {
      this.local_consumo.municipio_id = null;
      this.local_consumo.distrito_id = null;
      this.local_consumo.bairro_id = null;
      this.local_consumo.quarteirao_id = null;
      this.local_consumo.rua_id = null;
    }

    this.http.call_get("provincia/selectBox", null).subscribe((response) => {
      this.provincias = Object(response).data;
    });
  }

  private selectBoxMunicipiosByProvincia(id) {
    if (this.local_consumo.cil_flag == false && !this.contrato_edit) {
      this.local_consumo.distrito_id = null;
      this.local_consumo.bairro_id = null;
      this.local_consumo.quarteirao_id = null;
      this.local_consumo.rua_id = null;
    }

    this.http
      .call_get("municipio/getMunicipiosByProvincia/" + id, null)
      .subscribe((response) => {
        this.municipios = Object(response).data;
      });
  }

  private selectBoxBairrosByMunicipio(id) {
    if (this.local_consumo.cil_flag == false && !this.contrato_edit) {
      this.local_consumo.quarteirao_id = null;
      this.local_consumo.rua_id = null;
    }

    this.http
      .call_get("bairro/selectBoxByMunicipio/" + id, null)
      .subscribe((response) => {
        this.bairros = Object(response)
      });
  }

  private selectBoxDistritosByMunicipio(id) {
    this.http
      .call_get("distrito/getDistritosByMunicipio/" + id, null)
      .subscribe((response) => {
        this.distritos = Object(response).data;
        if (this.distritos.length) this.local_consumo.has_distrito = true;
      });

    if (!this.local_consumo.has_distrito) {
      this.selectBoxBairrosByMunicipio(id);
    } else {
      this.selectBoxBairrosByDistrito(this.local_consumo.distrito_id);
    }
  }

  private selectBoxBairrosByDistrito(id) {
    this.local_consumo.cil = null;
    this.local_consumo.quarteirao_id = null;
    this.local_consumo.rua_id = null;

    this.http
      .call_get("bairro/selectBoxByDistrito/" + id, null)
      .subscribe((response) => {
        this.bairros = Object(response);
      });
  }

  private finalizarContrato() {
    if (this.showCheckServicos_flag) {
      this.getSeries();

      if (!this.facturacao.serie.id) {
        //this.confirmFacturacaoModal.show();
        this.saveContrato();
      } else this.facturarServicos();
    } else {
      this.saveContrato();
    }

    this.getDividaAnteriorByClienteId(this.contrato.cliente_id);
  }

  public gerarFacturaOrcamento() {
    this._route.navigate(['facturacao/'], { queryParams: { id: this.orcamento_id } });
    this.configService.is_factura_by_nova_ligacao = true;
    this.configService.nova_ligacao_id = this.nova_ligacao_id
  }


}

