import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from 'src/environments/environment'

@Injectable({
    providedIn: 'root'
})

export class TipoIdentificacaoService {

  private token = localStorage.getItem('sessionToken')

  private headers = new HttpHeaders()
  .set('content-type', 'application/json')
  .set('Access-Control-Allow-Origin', '*')
  .set('Authorization', `Bearer ${this.token}`)

  constructor(
      private _http_client: HttpClient
  ){}


  getTiposIdentificacao() {
    return   this._http_client.get<any>(`${environment.app_url}api/${environment.apiVersion}/tipos_de_identificacao`, { 'headers': this.headers })
  }

  CreateTipoIdentificacao(tipo: any) {
    return   this._http_client.post<any>(`${environment.app_url}api/${environment.apiVersion}/tipos_de_identificacao`, tipo, { 'headers': this.headers })
  }

  EditTipoIdentificacao(tipo: any) {
    return   this._http_client.patch<any>(`${environment.app_url}api/${environment.apiVersion}/tipos_de_identificacao/${tipo.id}`, tipo, { 'headers': this.headers })
  }
}
