import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

import { ExcelService } from 'src/app/services/excel.service';
import * as moment from 'moment';

@Component({
  selector: 'app-recursos-rede',
  templateUrl: './recursos-rede.component.html',
  styleUrls: ['./recursos-rede.component.css']
})
export class RecursosRedeComponent implements OnInit {

  private loading: boolean = false;
  private central = {
    id: null,
    descricao: null,
    provincia_id: null
  }

  private idArmario = {
    id: null,
    descricao: null,
    central_id: null
  }

  private idCaixa = {
    id: null,
    descricao: null,
    central_id: null
  }

  private idCabo = {
    id: null,
    descricao: null,
    central_id: null
  }

  private parCabo = {
    id: null,
    descricao: null,
    central_id: null
  }

  private parArmarioPrimario = {
    id: null,
    descricao: null,
    central_id: null
  }

  private parArmarioSecundario = {
    id: null,
    descricao: null,
    central_id: null
  }

  private parCaixa = {
    id: null,
    descricao: null,
    central_id: null
  }

  private parADSL = {
    id: null,
    descricao: null,
    central_id: null
  }

  private pagination = {
    total: null,
    perPage: 5,
    page: 1,
    lastPage: null,

    start: 1,
    end: 10,
    search: null,
    order: "created_at"
  };


  private items: any = [];
  private idArmarios: any = [];
  private idCaixas: any = [];
  private idCabos: any = [];
  private parCabos: any = [];
  private armarioPrimarios: any = [];
  private armarioSecundarios: any = [];
  private parCaixas: any = [];
  private parADSLs: any = [];
  private provincias: any = [];

  constructor(private http: HttpService, private configService: ConfigService,private excelService: ExcelService) {

  }

  ngOnInit() {
    this.getPageFilterData(1);
    this.listCentral();

  }

  exportAsXLSX(): void {
    var CurrentDate = new Date();
    var nameFile = "Lista_Centrais-" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    this.excelService.exportAsExcelFile(document.getElementsByClassName("exportAsXLSXCliente")[0], nameFile);
  }
  exportAsPDF(): void {
    //this.reportLoja.relatorioLoja(this.cobrancas, this.simpleForm.value, 'save');
  }

  imprimirPDF(): void {
    //this.reportLoja.relatorioLoja(this.cobrancas, this.simpleForm.value);
  }


  private listCentral() {
    this.loading = true

    this.configService.loaddinStarter('start');

    this.http.__call('central/listaDataTable', this.http.filters).subscribe(

      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.loading = false
        this.configService.loaddinStarter('stop');

      }
    );
  }

  private getProvincias() {
    this.http.call_get('provincia/selectBox', null).subscribe(
      response => {
        this.provincias = Object(response).data
      }
    );
  }


  private listarIdArmarioCentral(central: any) {
    
    this.central.id = central.id;
    this.central.descricao = central.descricao;
    this.idArmario.central_id = central.id;

    this.configService.loaddinStarter('start');
    this.http.call_get('select-armario/armarioByCentral/' + this.central.id, null).subscribe(
      response => {
        this.idArmarios = Object(response).data;
        this.configService.loaddinStarter('stop');
      }
    );
  }

  private listarIdCaixa(central: any) {
    
    this.central.id = central.id;
    this.central.descricao = central.descricao;
    this.idCaixa.central_id = central.id;

    this.configService.loaddinStarter('start');
    this.http.call_get('select/idCaixaByCentral/' + this.central.id, null).subscribe(
      response => {
        this.idCaixas = Object(response).data;
        this.configService.loaddinStarter('stop');
      }
    );
  }

  
  private listarIdCabo(central: any) {
    
    this.central.id = central.id;
    this.central.descricao = central.descricao;
    this.idCabo.central_id = central.id;

    this.configService.loaddinStarter('start');
    this.http.call_get('select/idCaboByCentral/' + this.central.id, null).subscribe(
      response => {
        this.idCabos = Object(response).data;
        this.configService.loaddinStarter('stop');
      }
    );
  }

  private listarParCabo(central: any) {
    
    this.central.id = central.id;
    this.central.descricao = central.descricao;
    this.parCabo.central_id = central.id;

    this.configService.loaddinStarter('start');
    this.http.call_get('select/parCaboByCentral/' + this.central.id, null).subscribe(
      response => {
        this.parCabos = Object(response).data;
        this.configService.loaddinStarter('stop');
      }
    );
  }

  private listarArmarioPrimario(central: any) {
    
    this.central.id = central.id;
    this.central.descricao = central.descricao;
    this.parArmarioPrimario.central_id = central.id;

    this.configService.loaddinStarter('start');
    this.http.call_get('select/armarioPrimarioByCentral/' + this.central.id, null).subscribe(
      response => {
        this.armarioPrimarios = Object(response).data;
        this.configService.loaddinStarter('stop');
      }
    );
  }

  private listarArmarioSecundario(central: any) {
    
    this.central.id = central.id;
    this.central.descricao = central.descricao;
    this.parArmarioSecundario.central_id = central.id;

    this.configService.loaddinStarter('start');
    this.http.call_get('select/armarioSecundarioByCentral/' + this.central.id, null).subscribe(
      response => {
        this.armarioSecundarios = Object(response).data;
        this.configService.loaddinStarter('stop');
      }
    );
  }

  private listarParCaixa(central: any) {
    
    this.central.id = central.id;
    this.central.descricao = central.descricao;
    this.parCaixa.central_id = central.id;

    this.configService.loaddinStarter('start');
    this.http.call_get('select/parCaixaByCentral/' + this.central.id, null).subscribe(
      response => {
        this.parCaixas = Object(response).data;
        this.configService.loaddinStarter('stop');
      }
    );
  }

  
  private listarParADSL(central: any) {
    
    this.central.id = central.id;
    this.central.descricao = central.descricao;
    this.parADSL.central_id = central.id;

    this.configService.loaddinStarter('start');
    this.http.call_get('select/parADSLByCentral/' + this.central.id, null).subscribe(
      response => {
        this.parADSLs = Object(response).data;
        this.configService.loaddinStarter('stop');
      }
    );
  }


  private registerCentral(e) {
    e.preventDefault();
    this.configService.loaddinStarter('start');
    if (this.central.descricao == null || this.central.descricao == null) {

      this.configService.showAlert('É obrigatório fornecer a Descrição', 'alert-danger', true);

    }else if(this.central.provincia_id == null || this.central.provincia_id == null){
      this.configService.showAlert('Seleccione a Província', 'alert-danger', true);
    } else {
      this.http.__call('central/register', this.central).subscribe(
        data => {
          if (Object(data).code == 500) {
            this.configService.showAlert(Object(data).message, 'alert-danger', true);
          } else {
            this.configService.showAlert(Object(data).message, 'alert-success', true);
            this.listCentral();
            this.configService.clearFormInputs(e);
          }

        }
      );
    }
    this.configService.loaddinStarter('stop');
  }



  private refreshDataCentral(id, descricao, provincia_id) {
    this.central.id = id;
    this.central.descricao = descricao;
    this.central.provincia_id = provincia_id;
  }

  private editarCentral(e) {
    e.preventDefault();
   //console.log(this.cliente)
   this.configService.loaddinStarter('start');
    if (this.central.descricao == "" || this.central.descricao == null) {
      this.configService.showAlert('É obrigatório fornecer a Descrição', "alert-danger", true);
    }else if(this.central.provincia_id == "" || this.central.provincia_id == null){
      this.configService.showAlert('Seleccione a Província', "alert-danger", true);
    } else {
      this.http.__call('central/update/' + this.central.id, this.central).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            //this.configService.clearFormInputs(e);
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.listCentral();

          }
        }
      );
    }
    this.configService.loaddinStarter('stop');
    
  }

  /*
  private mostrarResultados() {
    this.pagination.perPage = this.mostrarResultado.nativeElement.value;
    this.listCentral();

  }

  */

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page =Number.isInteger(page) ? page : 1 ;
    this.listCentral();
  }

  private registerIdArmario() {

    if (this.idArmario.descricao == null || this.idArmario.descricao == "") {
      this.configService.showAlert('Digite do Id do Armário', 'alert-danger', true);
    } else {
     
      this.configService.loaddinStarter('start');
      this.http.__call('idArmario/central/register', this.idArmario).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            this.idArmario.descricao = null;
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.configService.loaddinStarter('stop');
            this.listarIdArmarioCentral({ descricao: this.central.descricao, id: this.central.id });

          }
        }
      );
    }
    
  }

  private registerIdCaixa() {

    if (this.idCaixa.descricao == null || this.idCaixa.descricao == "") {
      this.configService.showAlert('Digite do Id da Caixa', 'alert-danger', true);
    } else {
     
      this.configService.loaddinStarter('start');
      this.http.__call('idCaixa/central/register', this.idCaixa).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            this.idCaixa.descricao = null;
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.configService.loaddinStarter('stop');
            this.listarIdCaixa({ descricao: this.central.descricao, id: this.central.id });

          }
        }
      );
    }
    
  }

  private registerIdCabo() {

    if (this.idCabo.descricao == null || this.idCabo.descricao == "") {
      this.configService.showAlert('Digite do Id do Cabo', 'alert-danger', true);
    } else {
     
      this.configService.loaddinStarter('start');
      this.http.__call('idCabo/central/register', this.idCabo).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            this.idCabo.descricao = null;
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.configService.loaddinStarter('stop');
            this.listarIdCabo({ descricao: this.central.descricao, id: this.central.id });

          }
        }
      );
    }
    
  }

  private registerParCabo() {

    if (this.parCabo.descricao == null || this.parCabo.descricao == "") {
      this.configService.showAlert('Digite par do Cabo', 'alert-danger', true);
    } else {
     
      this.configService.loaddinStarter('start');
      this.http.__call('parCabo/central/register', this.parCabo).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            this.parCabo.descricao = null;
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.configService.loaddinStarter('stop');
            this.listarParCabo({ descricao: this.central.descricao, id: this.central.id });

          }
        }
      );
    }
    
  }

  private registerArmarioPrimario() {

    if (this.parArmarioPrimario.descricao == null || this.parArmarioPrimario.descricao == "") {
      this.configService.showAlert('Digite par Armário Primário', 'alert-danger', true);
    } else {
     
      this.configService.loaddinStarter('start');
      this.http.__call('armarioPrimario/central/register', this.parArmarioPrimario).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            this.parArmarioPrimario.descricao = null;
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.configService.loaddinStarter('stop');
            this.listarArmarioPrimario({ descricao: this.central.descricao, id: this.central.id });

          }
        }
      );
    }
    
  }

  private registerArmarioSecundario() {

    if (this.parArmarioSecundario.descricao == null || this.parArmarioSecundario.descricao == "") {
      this.configService.showAlert('Digite par Armário Secundário', 'alert-danger', true);
    } else {
     
      this.configService.loaddinStarter('start');
      this.http.__call('armarioSecundario/central/register', this.parArmarioSecundario).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            this.parArmarioSecundario.descricao = null;
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.configService.loaddinStarter('stop');
            this.listarArmarioSecundario({ descricao: this.central.descricao, id: this.central.id });

          }
        }
      );
    }
    
  }

  private registerParCaixa() {

    if (this.parCaixa.descricao == null || this.parCaixa.descricao == "") {
      this.configService.showAlert('Digite par da Caixa', 'alert-danger', true);
    } else {
     
      this.configService.loaddinStarter('start');
      this.http.__call('parCaixa/central/register', this.parCaixa).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            this.idCaixa.descricao = null;
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.configService.loaddinStarter('stop');
            this.listarParCaixa({ descricao: this.central.descricao, id: this.central.id });

          }
        }
      );
    }
    
  }

  private registerParADSL() {

    if (this.parADSL.descricao == null || this.parADSL.descricao == "") {
      this.configService.showAlert('Digite a descrição', 'alert-danger', true);
    } else {
     
      this.configService.loaddinStarter('start');
      this.http.__call('parADSL/central/register', this.parADSL).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            this.parADSL.descricao = null;
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.configService.loaddinStarter('stop');
            this.listarParADSL({ descricao: this.central.descricao, id: this.central.id });

          }
        }
      );
    }
    
  }

  private clearCentral(){
    this.central.descricao = null;
    
  }

  private clearProvincia(){
    this.idArmario.descricao = null;
    
  }



}
