import { Component,ViewEncapsulation, OnInit, Input,Output, EventEmitter,OnChanges, SimpleChange } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms'; 
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-create-or-edit-produtos',
  templateUrl: './create-or-edit-produtos.component.html',
  styleUrls: ['./create-or-edit-produtos.component.css']
})
export class CreateOrEditProdutosComponent implements OnInit {



  @Input() modal: string = "modalprodutoCreateOrEdit";
  @Input() title: string = "Registar material";
  @Input() produtos:any;

  submitted = false;
  formErrors: any;
  private loading: boolean = false;
  private ver: boolean = true;
  @Input() simpleFormprodutos: FormGroup; 

  @Output() private loadListprodutos = new EventEmitter<any>();
  
  private categorias: any = [];
  private unidades: any = [];

  constructor(private http: HttpService, private configService: ConfigService, private formBuilder: FormBuilder) { 
    this.createForm();
  }

  ngOnInit() {
    this.categoriaOption()
    this.unidadeOption()
  }

  createForm() {
    this.simpleFormprodutos = this.formBuilder.group({
      index: [{ value: null, disabled: true }],
      descricao: [null],
      codigo: [null],
      //valor: [null, Validators.required],
      categoria_id: [null],
      unidade_id: [null, Validators.required],
      status: [null, Validators.required]
    })
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.simpleFormprodutos.controls;
  }

  onReset() {
    this.submitted = false;
    this.simpleFormprodutos.reset();
  }


  onSubmit() {

    this.submitted = true;

    // parar aquei se o formulário for inválido
    if (this.simpleFormprodutos.invalid) {
      return;
    }
    this.loading = true;
    const index = this.simpleFormprodutos.getRawValue().index;
    // TODO: usado para fazer a requisição com a api de criação de objsct or update
    const uri = (index === null ? 'stk-produtos/register' : 'stk-produtos/update/' + index);
    this.createOrEdit(uri, this.simpleFormprodutos, (index === null ? true : false));

  }

  createOrEdit(uri: any, formulario: FormGroup, isCreate: boolean) {

    // TODO: usado para fazer a requisição com a api de criação de object
    this.http.__call(uri, formulario.value).pipe(first()).subscribe(
      response => {
        this.submitted = false;
        this.loading = false;
        if (isCreate && Object(response).code ==200) {
          formulario.reset(); 
        }  
        
        if (Object(response).code ==200) {
          this.loadList_produtos(Object(response).data);
        }
      },
      error => {
        this.submitted = false;
        this.loading = false;
      });
  }


  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
     
    if (this.produtos !== undefined ) {
      
      this.title = "Editar material";
      //this.onReset()
      
      this.simpleFormprodutos.patchValue({
        index: this.produtos.id,
        descricao: this.produtos.descricao,
        codigo: this.produtos.codigo,
        categoria_id: this.produtos.categoria_id,
        unidade_id: this.produtos.unidade_id,
        status: this.produtos.status
      });
      
    } else {
      this.onReset()
      this.title = "Registar material";
      
    }
  }

  private categoriaOption() {

    this.http.call_get('stk-categoria/select-option', null).subscribe(
      response => {
        this.categorias = Object(response).data;
      }
    );
  }

  private unidadeOption() {

    this.http.call_get('unidades/select-option', null).subscribe(
      response => {
        this.unidades = Object(response).data;
      }
    );
  }

  public loadList_produtos(produtos) { 
    this.loadListprodutos.emit(produtos);
  }


}
