import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

@Component({
  selector: 'app-report-diario-auto',
  templateUrl: './report-diario-auto.component.html',
  styleUrls: ['./report-diario-auto.component.css']
})
export class ReportDiarioAutoComponent implements OnInit {

  private roles: any = [];

  constructor(private http: HttpService, private configService: ConfigService) { }

  ngOnInit() {

    this.getTipoPerfil();
  }

  private getTipoPerfil() {

   

    this.http.__call('roles/reportDiarioAuto', null).subscribe(

      response => {
        this.roles = Object(response).data;
 
        for (var i = 0; i < this.roles.length; i++) {
          //console.log(this.roles[i].id)
          this.configService.gerarReportDiario(this.roles[i].id)
        }

      }
    );
  }

}
