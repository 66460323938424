import { CreatLocalInstalacaoByNovaLigacaoComponent } from './components/nova-ligacao/creat-local-instalacao-by-nova-ligacao/creat-local-instalacao-by-nova-ligacao.component';
import { TriggerSequenciaComponent } from './components/ordemservico/trigger-sequencia/trigger-sequencia.component';
import { RelatorioCaucaoComponent } from './components/relatorios/financeiro/relatorio-caucao/relatorio-caucao.component';
import { DevoulcaoComponent } from './components/devoulcao/devoulcao.component';
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { RxReactiveFormsModule } from "@rxweb/reactive-form-validators"
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";

import { JwtInterceptor } from "./interceptors/jwt.interceptor";
import { ErroInterceptor } from "./interceptors/erro-interceptor";

import { ReactiveFormsModule, FormsModule } from "@angular/forms";
//  Importar o módulo da biblioteca
import { NgxSpinnerModule } from "ngx-spinner";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";
import { ArchwizardModule } from "angular-archwizard";
import { Select2Module } from "ng2-select2";
import { NgxMaskModule, IConfig } from 'ngx-mask'
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

import { AppRoutingModule, routeComponents } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LayoutComponent } from "./components/layout/layout.component";
import { AsidenavbarComponent } from "./components/layout/asidenavbar/asidenavbar.component";
import { TopnavbarComponent } from "./components/layout/topnavbar/topnavbar.component";
import { FooternavbarComponent } from "./components/layout/footernavbar/footernavbar.component";
import { SetingsnavbarComponent } from "./components/layout/setingsnavbar/setingsnavbar.component";
import { FornecedorComponent } from "./components/fornecedor/fornecedor.component";
import { UtilizadorComponent } from "./components/utilizador/utilizador.component";
import { ListarProdutosComponent } from "./components/listar-produtos/listar-produtos.component";
import { ImpostoComponent } from "./components/imposto/imposto.component";
import { from } from "rxjs";
import { DocumentoComponent } from "./components/documento/documento.component";
import { SerieComponent } from "./components/serie/serie.component";
import { ListarFacturacaoComponent } from "./components/listar-facturacao/listar-facturacao.component";

import { LoginComponent } from "./components/autenticacao/login/login.component";
import { ReciboComponent } from "./components/recibo/recibo.component";
import { LoadingComponent } from "./components/loading/loading.component";
import { AlertComponent } from "./components/alert/alert.component";
import { SaftComponent } from "./components/saft/saft.component";

// providers imports
import { AuthService } from "./providers/auth/auth.service";
import { HttpService } from "./providers/http/http.service";
import { ApiService } from "./providers/http/api.service";
import { ConfigService } from "./providers/config/config.service";
import { PDFComponent } from "./components/pdf/pdf.component";
import { EmpresaComponent } from "./components/empresa/empresa.component";
import { StockMovimentoComponent } from "./components/stock-movimento/stock-movimento.component";
import { ContaCorrenteComponent } from "./components/conta-corrente/conta-corrente.component";
import { PermissionsComponent } from "./components/permissions/permissions.component";
import { RoleComponent } from "./components/role/role.component";
import { ModulosComponent } from "./components/modulos/modulos.component";

//import { HomeComponent } from './components/home/home.component';
import { RegisterComponent } from "./components/register/register.component";

import { SummaryComponent } from "./components/board/card/summary/summary.component";
import { DetailComponent } from "./components/board/card/detail/detail.component";
import { BoardComponent } from "./components/board/board/board.component";
import { HeaderComponent } from "./components/common/header/header.component";
import { ListComponent } from "./components/board/list/list.component";

import { ContentEditDirective } from "./directives/common/content-edit.directive";
import { LocalService } from "./providers/board/local/local.service";
import { TarefaComponent } from "./components/tarefa/tarefa.component";
import { BiComponent } from "./bi/bi.component";

import { ContextMenuComponent } from "./components/common/contextmenu/context-menu.component";
import { AprovisionamentoComponent } from "./components/aprovisionamento/aprovisionamento.component";
import { ProdutoFornecedorComponent } from "./components/produto-fornecedor/produto-fornecedor.component";
import { MoedaComponent } from "./components/moeda/moeda.component";
import { BancoComponent } from "./components/banco/banco.component";
import { ProjectoComponent } from "./components/projecto/projecto.component";

import { P404Component } from "./components/error/404.component";
import { P500Component } from "./components/error/500.component";
import { P403Component } from "./components/error/403.component";
import { TarifarioComponent } from "./components/tarifario/tarifario.component";
import { ContratoComponent } from "./components/contrato/contrato.component";
import { FacturacaoChargeComponent } from "./components/facturacao-charge/facturacao-charge.component";
import { HistoricoFacturacaoChargeComponent } from "./components/facturacao/historico-facturacao/historico-facturacao.component";

import { PlanoPrecoComponent } from "./components/plano-preco/plano-preco.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxImageCompressService } from 'ngx-image-compress';

import { NgxPaginationModule } from "ngx-pagination";
import { TestPaginationComponent } from "./components/test-pagination/test-pagination.component";
import { MovimentoCaixaComponent } from "./components/caixa/movimento-caixa/movimento-caixa.component";
import { LojaComponent } from "./components/loja/loja.component";
import { AberturaComponent } from "./components/caixa/abertura/abertura.component";
import { FechoComponent } from "./components/caixa/fecho/fecho.component";
import { FacturacaoContaComponent } from "./components/facturacao-conta/facturacao-conta.component";
import { RedefinirPasswordComponent } from "./components/utilizador/redefinir-password/redefinir-password.component";
import { PedidoReportService } from "./components/report-at/pedido/pedido-report.service";
import { UtilizadorAlterarPasswordComponent } from "./components/utilizador/utilizador-alterar-password/utilizador-alterar-password.component";
import { DepositoCaixaComponent } from "./components/caixa/deposito-caixa/deposito-caixa.component";
import { DiarioVendasService } from "./components/report-at/vendas/diario-vendas.service";
import { PrioridadeComponent } from "./components/reclamacoes/prioridade/prioridade.component";
import { TipoReclamacoesComponent } from "./components/reclamacoes/tipo-reclamacoes/tipo-reclamacoes.component";
import { ReclamacoesComponent } from "./components/reclamacoes/reclamacoes/reclamacoes.component";
import { PedidosComponent } from "./components/pedidos/pedidos/pedidos.component";
import { ConsultarReciboComponent } from "./components/recibo/consultar-recibo/consultar-recibo.component";
import { AdiantamentoComponent } from "./components/adiantamento/adiantamento.component";
import { ListaDepositosComponent } from "./components/caixa/lista-depositos/lista-depositos.component";

import { NgxLoadingModule, ngxLoadingAnimationTypes } from "ngx-loading";
import { FacturacaoAutomaticaComponent } from "./components/facturacao/facturacao-automatica/facturacao-automatica.component";
import { CicloFacturacaoComponent } from "./components/facturacao/ciclo-facturacao/ciclo-facturacao.component";

import { TecnologiasComponent } from "./components/tecnologias/tecnologias.component";
import { EstadoReclamacoesComponent } from "./components/reclamacoes/estado-reclamacoes/estado-reclamacoes.component";
import { EstadoPedidosComponent } from "./components/pedidos/estado-pedidos/estado-pedidos.component";
import { TiposPedidoComponent } from "./components/pedidos/tipos-pedido/tipos-pedido.component";

import { UnificarclienteComponent } from "./components/config-crm/clientes/unificarcliente/unificarcliente.component";
import { ClientesunificadosComponent } from "./components/config-crm/clientes/clientesUnificados/clientesunificados/clientesunificados.component";
import { BillRunHeaderComponent } from "./components/relatorios/bill-run-header/bill-run-header.component";
import { GetPdfEmailComponent } from "./components/get-pdf-email/get-pdf-email.component";
import { EnvioRelatorioDiarioComponent } from "./components/relatorios/envio-relatorio-diario/envio-relatorio-diario.component";
import { ReportDiarioAutoComponent } from "./components/relatorios/report-diario-auto/report-diario-auto.component";
import { CriarParceriaComponent } from "./components/interconexao/criar-parceria/criar-parceria.component";
import { ListarParceriaComponent } from "./components/interconexao/listar-parceria/listar-parceria.component";
import { VisaoGeralComponent } from "./components/conta-corrente/visao-geral/visao-geral.component";
// Import ng-circle-progress
import { NgCircleProgressModule } from "ng-circle-progress";
import { RelatorioFinanceiroComponent } from "./components/relatorios/financeiro/relatorio-financeiro/relatorio-financeiro.component";
import { EntidadeCativadoraComponent } from "./components/entidade-cativadora/entidade-cativadora.component";
import { VisualizarRelatorioComponent } from "./components/visualizar-relatorio/visualizar-relatorio.component";
import { RedefinirPasswordFirstAcessComponent } from "./components/utilizador/redefinir-password-first-acess/redefinir-password-first-acess.component";
import { RecursosRedeComponent } from "./components/recursos-rede/recursos-rede.component";
import { EmitirNotaCreditoComponent } from "./components/facturacao/emitir-nota-credito/emitir-nota-credito.component";
import { LeituraContradorComponent } from "./components/leitura-contrador/leitura-contrador.component";

import { CobreFormComponent } from "./components/formularios/pedidos/cobre-form/cobre-form.component";
import { CircuitoEmpresarialFormComponent } from "./components/formularios/pedidos/circuito-empresarial-form/circuito-empresarial-form.component";
import { RejectFormComponent } from "./components/formularios/pedidos/reject_fom/reject-form/reject-form.component";
import { PedidoCircuitoEmpresarialComponent } from "./components/formularios/pedidos/circuito-empresarial-form/pedido-circuito-empresarial/pedido-circuito-empresarial.component";
import { GeralFormComponent } from "./components/formularios/pedidos_crm/geral-form/geral-form.component";
import { FormCircuitoEmpresarialCRMComponent } from "./components/formularios/pedidos_crm/form-circuito-empresarial-crm/form-circuito-empresarial-crm.component";
import { ProgressBarModule } from "angular-progress-bar";

import { ReportCotrancaGlobalComponent } from './components/relatorios/financeiro/report-cotranca-global/report-cotranca-global.component';

import { ExcelService } from 'src/app/services/excel.service';
import { ConfigModuloService } from 'src/app/services/config-modulo.service';
import { RelCobracaGlobalService } from 'src/app/components/report-at/relatorios/financeira/rel-cobraca-global.service';
import { ReportLojaService } from 'src/app/components/report-at/relatorios/financeira/report-loja.service';
import { ReportIVAService } from 'src/app/components/report-at/relatorios/financeira/report-iva.service';
import { ReportServicoContradosService } from 'src/app/components/report-at/relatorios/financeira/report-servico-contrados.service';
import { ReportLojaComponent } from './components/relatorios/financeiro/report-loja/report-loja.component';
import { ReportIVAComponent } from './components/relatorios/financeiro/report-iva/report-iva.component';
import { ReportServicosContratadosComponent } from './components/relatorios/financeiro/report-servicos-contradados/report-servicos-contradados.component';
import { DireccaoComponent } from './components/direccao/direccao.component';
import { PedidosRelatorioComponent } from './components/relatorios/pedidos-relatorio/pedidos-relatorio.component';
import { ReclamacoesRelatorioComponent } from './components/relatorios/reclamacoes-relatorio/reclamacoes-relatorio.component';
import { CreateOrEditClientComponent } from './components/config-crm/clientes/create-or-edit-client/create-or-edit-client.component';
import { PontoVendaComponent } from './components/ponto-venda/ponto-venda.component';
import { CreateOrEditImpostoComponent } from './components/imposto/create-or-edit-imposto/create-or-edit-imposto.component';
import { ReportClienteService } from './components/report-at/relatorios/report-cliente.service';
import { ReportAdiantamentosService } from './components/report-at/relatorios/report-adiantamnetos.service';

import { CreateOrEditProdutoComponent } from './components/listar-produtos/create-or-edit-produto/create-or-edit-produto.component';
import { CreateOrEditTarifarioComponent } from './components/tarifario/create-or-edit-tarifario/create-or-edit-tarifario.component';
import { CreateOrEditUtilizadorComponent } from './components/utilizador/create-or-edit-utilizador/create-or-edit-utilizador.component';
import { EditFlatRateServicoComponent } from './components/config-crm/clientes/edit-flat-rate-servico/edit-flat-rate-servico.component';
import { VisualizarFlatRateComponent } from './components/config-crm/clientes/conta/servico/flat-rate/visualizar-flat-rate/visualizar-flat-rate.component';
import { UpdateFateRateComponent } from './components/config-crm/clientes/conta/servico/flat-rate/update-fate-rate/update-fate-rate.component';
import { ModalexportarcontacorrenteexelComponent } from './components/conta-corrente/modalexportarcontacorrenteexel/modalexportarcontacorrenteexel.component';

import { CreateOrEditSerieComponent } from './components/serie/create-or-edit-serie/create-or-edit-serie.component';
import { ClienteRelatorioComponent } from './components/relatorios/cliente-relatorio/cliente-relatorio.component';



import { DispositivosComponent } from './components/dispositivos/dispositivos.component';
import { LtePrePagoFormComponent } from './components/dispositivos/formularios/lte-pre-pago-form/lte-pre-pago-form.component';

import { PreFacturacaoComponent } from "./components/facturacao/pre-facturacao/pre-facturacao.component";
import { WimaxDispositivoFormComponent } from './components/dispositivos/formularios/wimax-dispositivo-form/wimax-dispositivo-form.component';
import { CmdaDispositivoFormComponent } from './components/dispositivos/formularios/cmda-dispositivo-form/cmda-dispositivo-form.component';
import { NumeracaoInventarioRedeComponent } from './components/numeracao-inventario-rede/numeracao-inventario-rede.component';

import { PerfilComponent } from './components/utilizador/perfil/perfil.component';
import { ReportPagamentoGlobalComponent } from './components/relatorios/financeiro/report-pagamento-global/report-pagamento-global.component';
import { ReportFacturacaoGestorComponent } from './components/relatorios/financeiro/report-facturacao-gestor/report-facturacao-gestor.component';
import { ContadoresComponent } from './components/contadores/contadores.component';
import { ConfEstadoServicoComponent } from './components/conf-estado-servico/conf-estado-servico.component';
import { ConfEstadoContadorComponent } from './components/conf-estado-contador/conf-estado-contador.component';
import { ConfTipoRamalComponent } from './components/conf-tipo-ramal/conf-tipo-ramal.component';
import { RamalComponent } from './components/ramal/ramal.component';
import { ConfRuaComponent } from './components/conf-rua/conf-rua.component';
import { ConfBairroComponent } from './components/conf-bairro/conf-bairro.component';
import { ConfMunicipioComponent } from './components/conf-municipio/conf-municipio.component';
import { ConfProvinciaComponent } from './components/conf-provincia/conf-provincia.component';
import { CreateOrEditMunicipioComponent } from './components/conf-municipio/create-or-edit-municipio/create-or-edit-municipio.component';
import { ConfDistritoComponent } from './components/conf-distrito/conf-distrito.component';
import { CreateOrEditBairroComponent } from './components/conf-bairro/create-or-edit-bairro/create-or-edit-bairro.component';
import { CreateOrEditRuaComponent } from './components/conf-rua/create-or-edit-rua/create-or-edit-rua.component';
import { CreateOrEditDistritoComponent } from './components/conf-distrito/create-or-edit-distrito/create-or-edit-distrito.component';
import { CreateOrEditProvinciaComponent } from './components/conf-provincia/create-or-edit-provincia/create-or-edit-provincia.component';
import { ConfTarifarioComponent } from './components/conf-tarifario/conf-tarifario.component';
import { LocalInstalacaoComponent } from './components/locais/local-instalacao/local-instalacao.component';
import { LocalConsumoComponent } from './components/locais/local-consumo/local-consumo.component';
import { CreateOrEditLocalInstalacaoComponent } from './components/locais/local-instalacao/create-or-edit-local-instalacao/create-or-edit-local-instalacao.component';
import { CreateOrEditContadoresComponent } from './components/contadores/create-or-edit-contadores/create-or-edit-contadores.component';
import { ConfTipoContratoComponent } from './components/conf-tipo-contrato/conf-tipo-contrato.component';
import { ClasseTarifarioComponent } from './components/classe-tarifario/classe-tarifario.component';
import { ProdutoclasseTarifarioComponent } from './components/produto-classe-tarifario/produto-classe-tarifario.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown-angular7';
import { BsModalService, BsModalRef, ModalDirective, ModalModule } from 'ngx-bootstrap/modal';
import { Ng2OrderModule } from 'ng2-order-pipe';

import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';

import { FloatLinkComponent } from './components/layout/float-link/float-link.component';
import { ListarContratoComponent } from './components/contrato/listar-contrato/listar-contrato.component';
import { ConfModeloComponent } from './components/conf-modelo/conf-modelo.component';
import { ConfMarcaComponent } from './components/conf-marca/conf-marca.component';
import { ConfMedicaoComponent } from './components/conf-medicao/conf-medicao.component';
import { ConfClassePrecisaoComponent } from './components/conf-classe-precisao/conf-classe-precisao.component';
import { ConfFabricanteComponent } from './components/conf-fabricante/conf-fabricante.component';
import { ConfTipoContadorComponent } from './components/conf-tipo-contador/conf-tipo-contador.component';
import { MapaRotaComponent } from './components/config-rotas/mapa-rota/mapa-rota.component';
import { ConfQuarteiraoComponent } from './components/conf-quarteirao/conf-quarteirao.component';
import { ConfiguracaoComponent } from './components/configuracao/configuracao.component';
import { TipoObjectoComponent } from './components/tipo-objecto/tipo-objecto.component';
import { LigacaoRamalComponent } from './components/ligacao-ramal/ligacao-ramal.component';
import { ObjectoLigacaoRamalComponent } from './components/objecto-ligacao-ramal/objecto-ligacao-ramal.component';
import { NaoLeituraComponent } from './components/nao-leitura/nao-leitura.component';
import { TipoNaoLeituraComponent } from './components/tipo-nao-leitura/tipo-nao-leitura.component';
import { TipoOcorrenciaComponent } from './components/tipo-ocorrencia/tipo-ocorrencia.component';
import { OcorrenciaComponent } from './components/ocorrencia/ocorrencia.component';
import { CreateOrEditQuarteiraoComponent } from './components/conf-quarteirao/create-or-edit-quarteirao/create-or-edit-quarteirao.component';
import { ConfTipoRegistoComponent } from './components/conf-tipo-registo/conf-tipo-registo.component';
import { ConfTipoOrdemServicoComponent } from './components/conf-tipo-ordem-servico/conf-tipo-ordem-servico.component';
import { ConfTipoFacturacaoComponent } from './components/conf-tipo-facturacao/conf-tipo-facturacao.component';
import { ConfEstadoContaComponent } from './components/conf-estado-conta/conf-estado-conta.component';
import { NewLigacaoRamalComponent } from './components/new-ligacao-ramal/new-ligacao-ramal.component';
import { ConfTipoLigacaoComponent } from './components/conf-tipo-ligacao/conf-tipo-ligacao.component';
import { ConfEstadoCicloFacturacaoComponent } from './components/conf-estado-ciclo-facturacao/conf-estado-ciclo-facturacao.component';
import { ContextoConfiguracaoComponent } from './components/contexto-configuracao/contexto-configuracao.component';
import { ConfEstadoTarifarioComponent } from './components/conf-estado-tarifario/conf-estado-tarifario.component';
import { ConfCaudalComponent } from './components/conf-caudal/conf-caudal.component';
import { ConfCalibreComponent } from './components/conf-calibre/conf-calibre.component';
import { ConfTipologiaClienteComponent } from './components/conf-tipologia-cliente/conf-tipologia-cliente.component';
import { ConfTiposTipologiaComponent } from './components/conf-tipos-tipologia/conf-tipos-tipologia.component';
import { ConfNivelSensibilidadeComponent } from './components/conf-nivel-sensibilidade/conf-nivel-sensibilidade.component';
import { ConfTipoProdutoComponent } from './components/conf-tipo-produto/conf-tipo-produto.component';
import { CaucaoComponent } from './components/caucao/caucao.component';

import { GeneroComponent } from './components/config-crm/generos/genero.component';
import { TipoIdentificacaoComponent } from './components/config-crm/tipo-de-identificacao/tipo-de-identificacao.component';
import { GestorContaComponent } from './components/config-crm/gestores-contas/gestor-conta.component';
import { TipoClienteComponent } from './components/config-crm/tipo-de-cliente/tipo-de-cliente.component';
import { CreateOrEditTipoClienteComponent } from './components/config-crm/tipo-de-cliente/create-or-edit-tipo-cliente/create-or-edit-tipo-cliente.component';

import { ValidationFormsService } from './providers/validation/validation-forms.service';
import { ConfTipoLocalInstalacaoComponent } from './components/conf-tipo-local-instalacao/conf-tipo-local-instalacao.component';
import { ConfTipoLocalConsumoComponent } from './components/conf-tipo-local-consumo/conf-tipo-local-consumo.component';
import { ConfTipoClienteComponent } from './components/conf-tipo-cliente/conf-tipo-cliente.component';
import { CreateOrEditConfigTarifarioComponent } from './components/conf-tarifario/create-or-edit-tarifario/create-or-edit-tarifario.component';
import { ConfCampoJardimComponent } from './components/conf-campo-jardim/conf-campo-jardim.component';
import { ConfAbastecimentoCilComponent } from './components/conf-abastecimento-cil/conf-abastecimento-cil.component';

import { ConfTipoJuroComponent } from './components/conf-tipo-juro/conf-tipo-juro.component';
import { ConfObjectoContratoComponent } from './components/conf-objecto-contrato/conf-objecto-contrato.component';

import {
  MatProgressSpinnerModule, MatRadioModule, MatCheckboxModule, DateAdapter, MAT_DATE_FORMATS, MatDatepickerModule, MatNativeDateModule, MatFormFieldModule, MatInputModule, MatIconModule, MatButtonModule, MatSelectModule, MatOptionModule, MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatTreeModule,
  MatSortModule,
  MatTableModule,
  MatPaginatorModule,
  MatProgressBarModule
} from "@angular/material";
import { ConfMotivoDenunciaComponent } from './components/conf-motivo-denuncia/conf-motivo-denuncia.component';
import { ConfEstadoContratoComponent } from './components/conf-estado-contrato/conf-estado-contrato.component';
import { ConfMesesMediaConsumoComponent } from './components/conf-meses-media-consumo/conf-meses-media-consumo.component';
import { ClassificacaoProdutoComponent } from './components/config.components/classificacao-produto/classificacao-produto.component';
import { CreateOrEditTipoClassificacaoComponent } from './components/config.components/classificacao-produto/create-or-edit-classificacao/create-or-edit-tipo-classificacao.component';
import { ConfTipoMedicaoComponent } from './components/conf-tipo-medicao/conf-tipo-medicao.component';
import { ConfTipoMensagemComponent } from './components/conf-tipo-mensagem/conf-tipo-mensagem.component';
import { FilterRoteiroPipe } from './providers/filters/filter.roteiros.pipe';
import { StockProdutosComponent } from './components/stock/stock-produtos/stock-produtos.component';
import { CreateOrEditArmazemComponent } from './components/stock/create-or-edit-armazem/create-or-edit-armazem.component';
import { CreateOrEditProdutosComponent } from './components/stock/create-or-edit-produtos/create-or-edit-produtos.component';
import { StockCategoriaProdutosComponent } from './components/stock/stock-categoria-produtos/stock-categoria-produtos.component';
import { StockTipoMovimentosComponent } from './components/stock/stock-tipo-movimentos/stock-tipo-movimentos.component';
import { CreateOrEditCategoriaStkComponent } from './components/stock/create-or-edit-categoria-stk/create-or-edit-categoria-stk.component';
import { CreateOrEditTipoMovimentoComponent } from './components/stock/create-or-edit-tipo-movimento/create-or-edit-tipo-movimento.component';


import { FormatDateAdapter, FORMAT_DATE_FORMATS } from "./directives/date-adapter";
import { CreateRoteiroComponent } from './components/config-rotas/rota-header/create-roteiro/create-roteiro';
import { RotaHeaderComponent } from './components/config-rotas/rota-header/rota-header.component';
import { PeriodicidadeRoteiroComponent } from './components/config-rotas/periodicidade-roteiro/periodicidade-roteiro.component';
import { RotasRoutingModule } from './components/config-rotas/rotas-routing.module';
import { AgendamentoRoteiroComponent } from './components/config-rotas/agendamento-roteiro/agendamento-roteiro.component';

import { FullCalendarModule } from '@fullcalendar/angular';
import { LeituraComponent } from "./components/config-rotas/leituras/leitura.component";
import { EntradaStockComponent } from './components/stock/entrada-stock/entrada-stock.component';
import { MovimentacaoStockComponent } from './components/stock/movimentacao-stock/movimentacao-stock.component';
import { MenuReportComponent } from './components/layout/menu-report/menu-report.component';
import { SidebarReportingComponent } from './components/layout/menu-report/sidebar-reporting/sidebar-reporting.component';
import { SaidaStockComponent } from './components/stock/saida-stock/saida-stock.component';
import { StockExistenteComponent } from './components/stock/stock-existente/stock-existente.component';
import { ConfContaBancariaComponent } from './components/conf-conta-bancaria/conf-conta-bancaria.component';
import { CreateOrEditContaBancariaComponent } from './components/conf-conta-bancaria/create-or-edit-conta-bancaria/create-or-edit-conta-bancaria.component';
import { FacturacaoDetalhadaPosPagoComponent } from './components/relatorios/financeiro/facturacao-detalhada-pos-pago/facturacao-detalhada-pos-pago.component';
import { ConfDadosFacturacaoComponent } from './components/conf-dados-facturacao/conf-dados-facturacao.component';
import { ReportResumoContaCorrenteComponent } from './components/relatorios/financeiro/report-resumo-conta-corrente/report-resumo-conta-corrente.component';
import { BancosComponent } from "./components/institucional/bancos/bancos.component";
import { ExtraccaoClientesComponent } from "./components/relatorios/cliente-relatorio/extraccao-clientes/extraccao-clientes.component";
import { ReportMovimentosCaixaComponent } from "./components/relatorios/financeiro/report-movimentos-caixa/report-movimentos-caixa.component";
import { TransferenciaComponent } from "./components/stock/transferencia/transferencia.component";
import { PagamentoParcelarFacturaComponent } from "./components/recibo/pagamento-parcelar-factura/pagamento-parcelar-factura.component";
import { ReportVendaLojaProdutoComponent } from "./components/relatorios/financeiro/report-venda-loja-produto/report-venda-loja-produto.component";
import { MovimentoAdiantamentoComponent } from './components/config-crm/clientes/adiantamento/movimento-adiantamento/movimento-adiantamento.component';
import { FacturacaoDetalhadaProdutoComponent } from "./components/relatorios/financeiro/facturacao-detalhada-produto/facturacao-detalhada-produto.component";
import { ImpressaoMassivaComponent } from './components/impressao-massiva/impressao-massiva.component';
import { ConfFormaPagamentoComponent } from './components/conf-forma-pagamento/conf-forma-pagamento.component';
import { ConfSeloComponent } from './components/conf-selo/conf-selo.component';
import { ConfTipoSeloComponent } from './components/conf-tipo-selo/conf-tipo-selo.component';
import { ReportFormaPagamentoComponent } from './components/relatorios/financeiro/report-forma-pagamento/report-forma-pagamento.component';
import { DetalheContratoComponent } from './components/contrato/detalhe-contrato/detalhe-contrato.component';
import { ListarImpressaoMassivaComponent } from './components/impressao-massiva/listar-impressao-massiva/listar-impressao-massiva.component';
import { RegistarLeituraComponent } from "./components/config-rotas/leituras/registar-leitura/registar-leitura.component";
import { ConfCorSeloComponent } from './components/conf-cor-selo/conf-cor-selo.component';
import { CreateOrEditColaboradorComponent } from './components/recurso-humano/create-or-edit-colaborador/create-or-edit-colaborador.component';
import { CreateOrEditDepartamentoComponent } from './components/recurso-humano/create-or-edit-departamento/create-or-edit-departamento.component';
import { ColaboradoresComponent } from './components/recurso-humano/colaboradores/colaboradores.component';
import { CreateOrEditRegistoInternoComponent } from './components/registos/create-or-edit-registo-interno/create-or-edit-registo-interno.component';
import { RegistoInternoComponent } from './components/registos/registo-interno/registo-interno.component';
import { CategoriaComponent } from './components/comunicacao/categoria/categoria.component';
import { NivelComponent } from './components/comunicacao/nivel/nivel.component';
import { TipoComponent } from './components/comunicacao/tipo/tipo.component';
import { EstadoComponent } from './components/comunicacao/estado/estado.component';
import { OrigemComponent } from './components/comunicacao/origem/origem.component';
import { RegistroComponent } from './components/comunicacao/registro/registro.component';

//comunicacao

import { ListagemRoteirosComponent } from "./components/config-rotas/listagem-roteiros/listagem-roteiros.component";
import { EditarRoteiroComponent } from "./components/config-rotas/rota-header/editar-roteiro/editar-roteiro.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { ResumoContaComponent } from './components/relatorios/financeiro/resumo-conta/resumo-conta.component';
import { OrdemEstadoComponent } from "./components/ordemservico/ordem-estado/ordem-estado.component";
import { OrdemEquipamentoComponent } from "./components/ordemservico/ordem-equipamento/ordem-equipamento.component";
import { OrdemPrioridadeComponent } from "./components/ordemservico/ordem-prioridade/ordem-prioridade.component";
import { ListarLeiturasValidasComponent } from "./components/config-rotas/listar-leituras-validas/listar-leituras-validas.component";
import { CreateOrEditEquipamentoComponent } from "./components/ordemservico/create-or-edit-equipamento/create-or-edit-equipamento.component";
import { CreateOrEditEstadoComponent } from "./components/ordemservico/create-or-edit-estado/create-or-edit-estado.component";
import { CreateOrEditPrioridadeComponent } from "./components/ordemservico/create-or-edit-prioridade/create-or-edit-prioridade.component";
import { FazerAgendamentoComponent } from "./components/config-rotas/fazer-agendamento/fazer-agendamento.component";
import { ListarAgendamentoComponent } from "./components/config-rotas/listar-agendamento/listar-agendamento.component";
import { CriarOrdemServicoComponent } from './components/ordemservico/criar-ordem-servico/criar-ordem-servico.component';
import { ListarOrdemServicoComponent } from './components/ordemservico/listar-ordem-servico/listar-ordem-servico.component';
import { OsClassificacaoComponent } from './components/ordemservico/os-classificacao/os-classificacao.component';
import { OsTipoComponent } from './components/ordemservico/os-tipo/os-tipo.component';
import { CreateOrEditClassificacaoComponent } from './components/ordemservico/create-or-edit-classificacao/create-or-edit-classificacao.component';
import { CreateOrEditOsTipoComponent } from './components/ordemservico/create-or-edit-os-tipo/create-or-edit-os-tipo.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import {
  MgxCircularProgressBarModule,
  MgxCircularProgressFullBarModule,
  MgxCircularProgressPieModule
} from 'mgx-circular-progress-bar';
import { AngularDraggableModule } from 'angular2-draggable';


import { EquipamentoEstadosComponent } from './components/ordemservico/equipamento-estados/equipamento-estados.component';
import { CreateOrEditEquipamentoEstadoComponent } from './components/ordemservico/create-or-edit-equipamento-estado/create-or-edit-equipamento-estado.component';
import { OsSintomasComponent } from './components/ordemservico/os-sintomas/os-sintomas.component';
import { OsCausasComponent } from './components/ordemservico/os-causas/os-causas.component';
import { CreateOrEditOsCausasComponent } from './components/ordemservico/create-or-edit-os-causas/create-or-edit-os-causas.component';
import { CreateOrEditOsSintomasComponent } from './components/ordemservico/create-or-edit-os-sintomas/create-or-edit-os-sintomas.component';
import { ListOsAgendadosComponent } from './components/ordemservico/list-os-agendados/list-os-agendados.component';
import { ListCriticidadeComponent } from './components/equipamentos/list-criticidade/list-criticidade.component';
import { ListTipoAquisicaoComponent } from './components/equipamentos/list-tipo-aquisicao/list-tipo-aquisicao.component';
import { ListEstadoConservacaoComponent } from './components/equipamentos/list-estado-conservacao/list-estado-conservacao.component';
import { CreateOrEditEstadoConservacaoComponent } from './components/equipamentos/create-or-edit-estado-conservacao/create-or-edit-estado-conservacao.component';
import { CreateOrEditTipoAquisicaoComponent } from './components/equipamentos/create-or-edit-tipo-aquisicao/create-or-edit-tipo-aquisicao.component';
import { CreateOrEditGrauCriticidadeComponent } from './components/equipamentos/create-or-edit-grau-criticidade/create-or-edit-grau-criticidade.component';
import { OsCategoriaComponent } from './components/ordemservico/os-categoria/os-categoria.component';
import { CreateOrEditOsCategoriaComponent } from './components/ordemservico/create-or-edit-os-categoria/create-or-edit-os-categoria.component';
import { HistoricoOrdemServicoComponent } from './components/ordemservico/historico-ordem-servico/historico-ordem-servico.component';
import { ListaOsTrabalhoComponent } from './components/ordemservico/lista-os-trabalho/lista-os-trabalho.component';
import { ExecutarOrdemServicoComponent } from './components/ordemservico/executar-ordem-servico/executar-ordem-servico.component';
import { ListAgendamentoByOrdemComponent } from './components/ordemservico/list-agendamento-by-ordem/list-agendamento-by-ordem.component';
import { DetalheAgendamentoComponent } from './components/ordemservico/detalhe-agendamento/detalhe-agendamento.component';
import { EditOrdemAgendamentoComponent } from './components/ordemservico/edit-ordem-agendamento/edit-ordem-agendamento.component';
import { ViewEquipamentoLocationMapaComponent } from './components/ordemservico/view-equipamento-location-mapa/view-equipamento-location-mapa.component';
import { ViwListaTrabalhoComponent } from './components/ordemservico/viw-lista-trabalho/viw-lista-trabalho.component';
import { UnidadesComponent } from './components/stock/unidades/unidades.component';
import { CreateOrEditUnidadeComponent } from './components/stock/create-or-edit-unidade/create-or-edit-unidade.component';
import { ConfigHorasComponent } from './components/config-hora/config-horas/config-horas.component';
import { CreateOrEditHoraComponent } from './components/config-hora/create-or-edit-hora/create-or-edit-hora.component';
import { ProcessarLeiturasComponent } from "./components/config-rotas/processar-leituras/processar-leituras.component";
import { RhEmpresasComponent } from './components/recurso-humano/rh-empresas/rh-empresas.component';
import { CreateOrEditRhEmpresasComponent } from './components/recurso-humano/create-or-edit-rh-empresas/create-or-edit-rh-empresas.component';
import { FacturacaoTableComponent } from "./components/facturacao/facturacao-table/facturacao-table.component";
import { CobrancaProdutoComponent } from './components/relatorios/financeiro/cobranca-produto/cobranca-produto.component';
import { OtTriggerComponent } from './components/ordemservico/ot-trigger/ot-trigger.component';
import { CreateOrEditOtTriggerComponent } from './components/ordemservico/create-or-edit-ot-trigger/create-or-edit-ot-trigger.component';
import { ModalCreateOrdemTrabalhoComponent } from './components/ordemservico/modal-create-ordem-trabalho/modal-create-ordem-trabalho.component';
import { MotivoAlteracaoLeituraComponent } from "./components/config-rotas/leituras/motivo-alteracao-leitura/motivo-alteracao-leitura.component";
import { TipificacaoAnomaliasComponent } from "./components/tipificacao-anomalias/tipificacao-anomalias.component";
import { OpenlayersComponent } from './components/ordemservico/openlayers/openlayers.component';
import { ModalCreateOtEquipamentoComponent } from './components/ordemservico/modal-create-ot-equipamento/modal-create-ot-equipamento.component';
import { TipoAnomaliaComponent } from './components/config-rotas/leituras/tipo-anomalia/tipo-anomalia.component';
import { HistoricosFacturacaoComponent } from './components/facturacao/historicos-facturacao/historicos-facturacao.component';
import { EditarOrdemTrabalhoComponent } from './components/ordemservico/editar-ordem-trabalho/editar-ordem-trabalho.component';
import { HistoricoAnomaliasComponent } from "./components/config-rotas/historico-anomalias/historico-anomalias.component";
import { ResumoCobrancaArtigoComponent } from './components/relatorios/resumo-cobranca-artigo/resumo-cobranca-artigo.component';
import { ResumoTotalComponent } from './components/relatorios/resumo-cobranca-artigo/components/resumo-total/resumo-total.component';
import { ResumoPorLeituraComponent } from './components/relatorios/resumo-cobranca-artigo/components/resumo-por-leitura/resumo-por-leitura.component';
import { ResumoPorAvencaComponent } from './components/relatorios/resumo-cobranca-artigo/components/resumo-por-avenca/resumo-por-avenca.component';
import { ResumoPorArtigoComponent } from './components/relatorios/resumo-cobranca-artigo/components/resumo-por-artigo/resumo-por-artigo.component';
import { ResumoPorServicoComponent } from './components/relatorios/resumo-cobranca-artigo/components/resumo-por-servico/resumo-por-servico.component';
import { ResumoMovimentoCaixaComponent } from './components/relatorios/financeiro/report-movimentos-caixa/resumo-movimento-caixa/resumo-movimento-caixa.component';
import { RelatorioMovimentoCaixaComponent } from './components/relatorios/financeiro/report-movimentos-caixa/relatorio-movimento-caixa/relatorio-movimento-caixa.component';
import { FacturaCicloIndividualComponent } from "./components/facturacao/factura-ciclo-individual/factura-ciclo-individual.component";
import { ConfiTipoFacturacaoComponent } from './components/configuracao/confi-tipo-facturacao/confi-tipo-facturacao.component';
import { ResumoAguaMCubicoComponent } from './components/relatorios/resumo-cobranca-artigo/components/resumo-agua-m-cubico/resumo-agua-m-cubico.component';
import { ResumoAguaSaniamentoComponent } from './components/relatorios/resumo-cobranca-artigo/components/resumo-agua-saniamento/resumo-agua-saniamento.component';
import { ResumoTaraFixaComponent } from './components/relatorios/resumo-cobranca-artigo/components/resumo-tara-fixa/resumo-tara-fixa.component';
import { ResumoFormaPagamentoComponent } from './components/relatorios/resumo-cobranca-artigo/components/resumo-forma-pagamento/resumo-forma-pagamento.component';
import { ViewClienteDetalhesComponent } from './components/ordemservico/view-cliente-detalhes/view-cliente-detalhes.component';
import { ResumoFacturacaoComponent } from './components/relatorios/financeiro/resumo-facturacao/resumo-facturacao.component';
import { FacturacaoDistritoComponent } from './components/relatorios/financeiro/resumo-facturacao/components/facturacao-distrito/facturacao-distrito.component';
import { FacturacaoProdutoComponent } from './components/relatorios/financeiro/resumo-facturacao/components/facturacao-produto/facturacao-produto.component';
import { FacturacaoSaneamentoComponent } from './components/relatorios/financeiro/resumo-facturacao/components/facturacao-saneamento/facturacao-saneamento.component';
import { FacturacaoAguaSaneamentoComponent } from './components/relatorios/financeiro/resumo-facturacao/components/facturacao-agua-saneamento/facturacao-agua-saneamento.component';
import { FacturacaoAguaMedidaComponent } from './components/relatorios/financeiro/resumo-facturacao/components/facturacao-agua-medida/facturacao-agua-medida.component';
import { FacturacaoAguaEstimadaComponent } from './components/relatorios/financeiro/resumo-facturacao/components/facturacao-agua-estimada/facturacao-agua-estimada.component';
import { FacturacaoTarifaFixaComponent } from './components/relatorios/financeiro/resumo-facturacao/components/facturacao-tarifa-fixa/facturacao-tarifa-fixa.component';
import { FacturacaoServicoLigacaoComponent } from './components/relatorios/financeiro/resumo-facturacao/components/facturacao-servico-ligacao/facturacao-servico-ligacao.component';
import { SAVER, getSaver } from './services/saver.provider';
import { TarifariioContratoComponent } from './components/tarifario/tarifariio-contrato/tarifariio-contrato.component';
import { EmitirNotaCreditoCicloComponent } from './components/facturacao/emitir-nota-credito-ciclo/emitir-nota-credito-ciclo.component';
import { EstruturaMoradasComponent } from './components/estrutura-moradas/estrutura-moradas.component';
import { RelatorioResumoContratosComponent } from './components/relatorios/relatorio-resumo-contratos/relatorio-resumo-contratos.component';
import { ConfigurarAnomaliasComponent } from "./components/config-rotas/configurar-anomalias/configurar-anomalias.component";
import { CentroCustoComponent } from "./components/centro-custo/centro-custo.component";
import { ConfEstadoRamalComponent } from './components/conf-estado-ramal/conf-estado-ramal.component';
import { ConfDiametroRamalComponent } from './components/conf-diametro-ramal/conf-diametro-ramal.component';
import { ConfMaterialRamalComponent } from './components/conf-material-ramal/conf-material-ramal.component';
import { ListaIncumprimentoComponent } from './components/lista-incumprimento/lista-incumprimento.component';
import { MpContadorComponent } from './components/manutencao_preventiva/mp-contador/mp-contador.component';
import { CreateOrEditMpContadorComponent } from './components/manutencao_preventiva/create-or-edit-mp-contador/create-or-edit-mp-contador.component';
import { PlanoManutencaoComponent } from './components/manutencao_preventiva/plano-manutencao/plano-manutencao.component';
import { ItensPlanoManutencaoComponent } from './components/manutencao_preventiva/itens-plano-manutencao/itens-plano-manutencao.component';
import { RegisterContadorEquipamentoComponent } from './components/manutencao_preventiva/register-contador-equipamento/register-contador-equipamento.component';
import { CreateOrEditPlanoManutencaoComponent } from './components/manutencao_preventiva/create-or-edit-plano-manutencao/create-or-edit-plano-manutencao.component';
import { MpEstadoPlanoManutencaoComponent } from './components/manutencao_preventiva/mp-estado-plano-manutencao/mp-estado-plano-manutencao.component';
import { CreateOrEditEstadoPlanoManutencaoComponent } from './components/manutencao_preventiva/create-or-edit-estado-plano-manutencao/create-or-edit-estado-plano-manutencao.component';
import { ClienteMenssagemComponent } from "./components/config-crm/clientes-mensagens/cliente-menssagem.component";
import { AlterarTitularidadeComponent } from './components/contrato/alterar-titularidade/alterar-titularidade.component';

import { ListaGeralIntervencoesComponent } from './components/manutencao_preventiva/lista-geral-intervencoes/lista-geral-intervencoes.component';
import { CobrancaDetalhadaValorRecebidoComponent } from './components/relatorios/financeiro/report-resumo-conta-corrente/cobranca-detalhada-valor-recebido/cobranca-detalhada-valor-recebido.component';
import { CobrancaDetalhadaValorSaldadoComponent } from './components/relatorios/financeiro/report-resumo-conta-corrente/cobranca-detalhada-valor-saldado/cobranca-detalhada-valor-saldado.component';
import { ClientesSemRotasComponent } from "./components/config-rotas/clientes-sem-rotas/clientes-sem-rotas.component";
import { MpCalendarioTarefasComponent } from './components/manutencao_preventiva/mp-calendario-tarefas/mp-calendario-tarefas.component';

import { ConfNotificacaoComponent } from './components/conf-notificacao/conf-notificacao.component';
import {  NotificacaoService } from "./components/conf-notificacao/conf-notificacao.service";

import { RelatorioDividasComponent } from './components/relatorios/relatorio-dividas/relatorio-dividas.component';
import { RelatorioAdiantamentosComponent } from './components/relatorios/relatorio-adiantamentos/relatorio-adiantamentos.component';
import { ReportManutencaoComponent } from './components/layout/menu-report/report-manutencao/report-manutencao.component';
import { ViewArmazemProdutoDesponiveisComponent } from './components/ordemservico/view-armazem-produto-desponiveis/view-armazem-produto-desponiveis.component';
import { LigarRamalsComponent } from './components/ligar-ramals/ligar-ramals.component';
import { ListarRamalsComponent } from './components/listar-ramals/listar-ramals.component';
import { SubsistemaComponent } from './components/subsistema/subsistema.component';
import { PressaoNominalComponent } from './components/pressao-nominal/pressao-nominal.component';
import { CreateOsMassivaComponent } from './components/ordemservico/criar-ordem-servico/create-os-massiva/create-os-massiva.component';
import { TipoRamalComponent } from './components/tipo-ramal/tipo-ramal.component';
import { EditarOtPreventivaComponent } from './components/ordemservico/editar-ot-preventiva/editar-ot-preventiva.component';
import { ExecutarOtPreventivaComponent } from './components/ordemservico/executar-ot-preventiva/executar-ot-preventiva.component';
import { DetalheAgendamentoPreventivaComponent } from './components/ordemservico/detalhe-agendamento-preventiva/detalhe-agendamento-preventiva.component';
import { AgendarOtPreventivaComponent } from './components/ordemservico/agendar-ot-preventiva/agendar-ot-preventiva.component';
import { LocalInstacaoDisponiveisComponent } from './components/locais/local-instacao-disponiveis/local-instacao-disponiveis.component';
import { AgendarOsMassivaComponent } from './components/ordemservico/agendar-os-massiva/agendar-os-massiva.component';
import { VerFacturaComponent } from './components/ordemservico/ver-factura/ver-factura.component';

import { EstadoNovaLigacaoComponent } from './components/nova-ligacao/estado/estado-nova-ligacao.component'
import { MotivoLigacaoComponent } from "./components/nova-ligacao/motivo-ligacao/motivo-ligacao.component";
import { TipoLigacaoComponent } from "./components/nova-ligacao/tipo-ligacao/tipo-ligacao.component";
import { RegistroNovaLigacaoComponent } from "./components/nova-ligacao/registro-nova-ligacao/registro-nova-ligacao.component";
import { FerramentasComponent } from './components/manutencao_preventiva/ferramentas/ferramentas.component';
import { EspecialistaComponent } from './components/manutencao_preventiva/especialista/especialista.component';
import { PeriodicidadeEmDiaComponent } from './components/manutencao_preventiva/periodicidade-em-dia/periodicidade-em-dia.component';
import { DuracaoEmHoraComponent } from './components/manutencao_preventiva/duracao-em-hora/duracao-em-hora.component';
import { ConsumiveisComponent } from './components/manutencao_preventiva/consumiveis/consumiveis.component';
import { CreateOrEditMpDuracaoHorasComponent } from './components/manutencao_preventiva/create-or-edit-mp-duracao-horas/create-or-edit-mp-duracao-horas.component';
import { CreateOrEditMpEspecialistaComponent } from './components/manutencao_preventiva/create-or-edit-mp-especialista/create-or-edit-mp-especialista.component';
import { CreateOrEditMpConsumiveisComponent } from './components/manutencao_preventiva/create-or-edit-mp-consumiveis/create-or-edit-mp-consumiveis.component';
import { CreateOrEditMpPeriodicidadeDiasComponent } from './components/manutencao_preventiva/create-or-edit-mp-periodicidade-dias/create-or-edit-mp-periodicidade-dias.component';
import { CreateOrEditMpFerramentaComponent } from './components/manutencao_preventiva/create-or-edit-mp-ferramenta/create-or-edit-mp-ferramenta.component';
import { ExecutarOsMassivaComponent } from './components/ordemservico/executar-os-massiva/executar-os-massiva.component';
import { ViewClientesByOrdemComponent } from './components/ordemservico/view-clientes-by-ordem/view-clientes-by-ordem.component';
import { ViewTarefasByAgedamentoComponent } from './components/ordemservico/view-tarefas-by-agedamento/view-tarefas-by-agedamento.component';
import { RelatorioLeiturasRealizadasComponent } from "./components/relatorios/relatorio-leituras-realizadas/relatorio-leituras-realizadas";
import { ConfNotificacaoNovasLigacoesComponent } from './components/conf-notificacao-novas-ligacoes/conf-notificacao-novas-ligacoes.component';
import { NumeroFacturasTipoFactutracao } from "./components/relatorios/financeiro/numero-facturas-por-tipo-facturacao/numero-facturas-por-tipo-facturacao";
import { NumeroContratosFacturadosFacturas } from "./components/relatorios/financeiro/numero-contratos-facturados-facturas/numero-contratos-facturados-facturas";
import { NumeroContratosCobradosFactura } from "./components/relatorios/financeiro/numero-contratos-cobrados-recibos/numero-contratos-cobrados-recibos";
import { MotivoComponent } from "./components/comunicacao/motivo/motivo.component";
import { LogsReferenciaEmisComponent } from './components/logs-referencia-emis/logs-referencia-emis.component';
import { LogsDeNotificacoesComponent } from './components/logs-de-notificacoes/logs-de-notificacoes.component';
import { TipoAvisoCortesComponent } from './components/tipo-aviso-cortes/tipo-aviso-cortes.component';
import { ConfigAvisoCorteComponent } from './components/config-aviso-corte/config-aviso-corte.component';
import { RelatorioIvaComponent } from "./components/relatorios/relatorio-iva/relatorio-iva.component";
import { ResumoTotalIvaComponent } from "./components/relatorios/relatorio-iva/components/resumo-total/resumo-total-iva.component";
import { ResumoAguaMCubicoIvaComponent } from "./components/relatorios/relatorio-iva/components/resumo-agua-m-cubico/resumo-agua-m-cubico-iva.component";
import { ResumoPorArtigoIvaComponent } from "./components/relatorios/relatorio-iva/components/resumo-por-artigo/resumo-por-artigo-iva.component";
import { ReportRecebimentoComponent } from "./components/layout/menu-report/report-recebimento/report-recebimento.component";
import { OtListagemConsumivelComponent } from './components/ordemservico/ot-listagem-consumivel/ot-listagem-consumivel.component';
import { ConfMotivosNaoLeiturasComponent } from "./components/config-rotas/conf-motivos-nao-leituras/conf-motivos-nao-leituras.component";
import { OrcamentoDetalhadoComponent } from './components/relatorios/financeiro/orcamento-detalhado/orcamento-detalhado.component';
import { FacturacaoCaucaoComponent } from './components/facturacao/facturacao-caucao/facturacao-caucao.component';
import { MapComponent } from './components/ordemservico/criar-ordem-servico/map/map.component';
import { RelatorioContadoresComponent } from "./components/relatorios/relatorio-contadores/relatorio-contadores.component";
import { CriarPlanoPrestacaoComponent } from './components/criar-plano-prestacao/criar-plano-prestacao.component';
import { ListagemEstornoComponent } from './components/recibo/listagem-estorno/listagem-estorno.component';
import { ListarPlanoPrestacaoComponent } from './components/listar-plano-prestacao/listar-plano-prestacao.component';
import { ListagemDevolucaoComponent } from './components/devoulcao/listagem-devolucao/listagem-devolucao.component';
import { ListaLinhaPrestacaoByPlanoPrestacaoComponent } from './components/lista-linha-prestacao-by-plano-prestacao/lista-linha-prestacao-by-plano-prestacao.component';
import { PagamentoPrestacaoComponent } from './components/pagamento-prestacao/pagamento-prestacao.component';
import { LogOtFiscalizacaoComponent } from './components/log-ot-fiscalizacao/log-ot-fiscalizacao.component';
import { CreateOrEditLocalInstalacaoComponentOs } from './components/ordemservico/executar-ordem-servico/create-or-edit-local-instalacao/create-or-edit-local-instalacao-os.component';
import { DevolucaoEstimativaComponent } from './components/devoulcao/devolucao-estimativa/devolucao-estimativa.component';
import { ListagemEstimativaComponent } from './components/devoulcao/listagem-estimativa/listagem-estimativa.component';
import { LogGestaoDividaComponent } from './components/log-gestao-divida/log-gestao-divida.component';
import { CreateOrEditLogGestaoDividaComponent } from './components/log-gestao-divida/create-or-edit-log-gestao-divida/create-or-edit-log-gestao-divida.component';
import { LogsContenciososComponent } from './components/logs-contenciosos/logs-contenciosos.component';
import { EstadoContenciosoComponent } from './components/estado-contencioso/estado-contencioso.component';
import { PagamentoPlanoPrestacaoComponent } from './components/pagamento-plano-prestacao/pagamento-plano-prestacao.component';
import { TipoJuroComponent } from './components/tipo-juro/tipo-juro.component';
import { ListarFacturasByPlanoPrestacaoComponent } from './components/listar-facturas-by-plano-prestacao/listar-facturas-by-plano-prestacao.component';
import { DespesasContenciosoComponent } from './components/despesas-contencioso/despesas-contencioso.component';
import { LogComunicacaoComponent } from './components/log-comunicacao/log-comunicacao.component';
import { EditarLinhaPrestacaoComponent } from './components/editar-linha-prestacao/editar-linha-prestacao.component';
import { ListarFacturacaoIncobravelComponent } from './components/listar-facturacao-incobravel/listar-facturacao-incobravel.component';
import { RelatorioOrdemServicoComponent } from './components/relatorios/relatorio-ordem-servico/relatorio-ordem-servico.component';
import { AnulacaoRecebimentosComponent } from './components/caixa/anulacao-recebimentos/anulacao-recebimentos.component';
import { ListagemRecibosAnularComponent } from './components/caixa/listagem-recibos-anular/listagem-recibos-anular.component';
import { AberturaDeLojaComponent } from './components/caixa/abertura_de_loja/abertura-de-loja.component';
import { GestaoZmcComponent } from './components/gestao-zmc/gestao-zmc.component';

/* import { ReportVendaLojaProdutoComponent } from "./components/relatorios/financeiro/report-venda-loja-produto/report-venda-loja-produto.component"; */
@NgModule({
  declarations: [
    MapComponent,
    AberturaDeLojaComponent,
    ListarFacturacaoIncobravelComponent,
    DespesasContenciosoComponent,
    LogComunicacaoComponent,
    EstadoContenciosoComponent,
    RelatorioContadoresComponent,
    CreateOrEditLocalInstalacaoComponentOs,
    MotivoComponent,
    ReportRecebimentoComponent,
    MovimentoAdiantamentoComponent,
    EstadoNovaLigacaoComponent,
    MotivoLigacaoComponent,
    RegistroNovaLigacaoComponent,
    TipoLigacaoComponent ,
    MotivoAlteracaoLeituraComponent,
    FilterRoteiroPipe,
    CreateRoteiroComponent,
    RotaHeaderComponent,
    AgendamentoRoteiroComponent,
    HistoricoAnomaliasComponent,
    FazerAgendamentoComponent,
    ListarAgendamentoComponent,
    CreateOrEditTipoClassificacaoComponent,
    ClassificacaoProdutoComponent,
    CreateOrEditTipoClienteComponent,
    TipoClienteComponent,
    CreateOrEditConfigTarifarioComponent,
    GeneroComponent,
    TipoIdentificacaoComponent,
    GestorContaComponent,
    CobreFormComponent,
    CreateOrEditSerieComponent,
    CircuitoEmpresarialFormComponent,
    RejectFormComponent,
    PedidoCircuitoEmpresarialComponent,
    GeralFormComponent,
    FormCircuitoEmpresarialCRMComponent,
    ReportCotrancaGlobalComponent,
    AppComponent,
    routeComponents,
    LayoutComponent,
    AsidenavbarComponent,
    ClientesSemRotasComponent,
    TopnavbarComponent,
    FooternavbarComponent,
    SetingsnavbarComponent,
    FornecedorComponent,
    UtilizadorComponent,
    ListarProdutosComponent,
    ImpostoComponent,
    DocumentoComponent,
    SerieComponent,
    ListarFacturacaoComponent,
    FacturaCicloIndividualComponent,
    AlertComponent,
    ReciboComponent,
    LoadingComponent,
    SaftComponent,
    EmpresaComponent,
    StockMovimentoComponent,
    ContaCorrenteComponent,
    PermissionsComponent,
    RoleComponent,
    ModulosComponent,

    //HomeComponent,
    LoginComponent,
    RegisterComponent,
    SummaryComponent,
    DetailComponent,
    BoardComponent,
    HeaderComponent,
    ListComponent,
    ContentEditDirective,
    ContextMenuComponent,
    AprovisionamentoComponent,
    ProdutoFornecedorComponent,
    MoedaComponent,
    BancoComponent,
    ProjectoComponent,

    P404Component,
    P500Component,
    P403Component,
    TarifarioComponent,
    ContratoComponent,
    FacturacaoChargeComponent,
    HistoricoFacturacaoChargeComponent,
    PlanoPrecoComponent,
    TestPaginationComponent,
    MovimentoCaixaComponent,
    LojaComponent,
    AberturaComponent,
    FechoComponent,
    FacturacaoContaComponent,
    RedefinirPasswordComponent,
    UtilizadorAlterarPasswordComponent,
    CicloFacturacaoComponent,
    PontoVendaComponent,
    ConfigurarAnomaliasComponent,
    ConfMotivosNaoLeiturasComponent,
    DepositoCaixaComponent,
    PrioridadeComponent,
    TipoReclamacoesComponent,
    ReclamacoesComponent,
    PedidosComponent,
    ConsultarReciboComponent,
    AdiantamentoComponent,
    ListaDepositosComponent,
    FacturacaoAutomaticaComponent,
    TecnologiasComponent,
    EstadoReclamacoesComponent,
    EstadoPedidosComponent,
    TiposPedidoComponent,
    ClienteMenssagemComponent,
    DepositoCaixaComponent,
    PrioridadeComponent,
    TipoReclamacoesComponent,
    ReclamacoesComponent,
    PedidosComponent,
    ConsultarReciboComponent,
    AdiantamentoComponent,
    ListaDepositosComponent,
    FacturacaoAutomaticaComponent,
    TecnologiasComponent,
    EstadoReclamacoesComponent,
    EstadoPedidosComponent,
    UnificarclienteComponent,
    ClientesunificadosComponent,
    BillRunHeaderComponent,
    GetPdfEmailComponent,
    EnvioRelatorioDiarioComponent,
    ReportDiarioAutoComponent,
    CriarParceriaComponent,
    ListarParceriaComponent,
    VisaoGeralComponent,
    RelatorioFinanceiroComponent,
    EntidadeCativadoraComponent,
    VisualizarRelatorioComponent,
    RedefinirPasswordFirstAcessComponent,
    RecursosRedeComponent,
    EmitirNotaCreditoComponent,
    LeituraContradorComponent,
    ReportLojaComponent,
    ReportIVAComponent,
    ReportServicosContratadosComponent,
    DireccaoComponent,
    PedidosRelatorioComponent,
    ReclamacoesRelatorioComponent,
    CreateOrEditClientComponent,
    CreateOrEditImpostoComponent,
    CreateOrEditProdutoComponent,
    CreateOrEditTarifarioComponent,
    CreateOrEditUtilizadorComponent,
    EditFlatRateServicoComponent,
    VisualizarFlatRateComponent,
    UpdateFateRateComponent,
    ModalexportarcontacorrenteexelComponent,
    ClienteRelatorioComponent,

    DispositivosComponent,
    LtePrePagoFormComponent,
    PreFacturacaoComponent,
    WimaxDispositivoFormComponent,
    CmdaDispositivoFormComponent,
    NumeracaoInventarioRedeComponent,
    CreateOrEditEstadoComponent,
    CreateOrEditEquipamentoComponent,
    CreateOrEditPrioridadeComponent,
    PerfilComponent,
    ReportPagamentoGlobalComponent,
    ReportFacturacaoGestorComponent,
    ContadoresComponent,
    ConfEstadoServicoComponent,
    ConfEstadoContadorComponent,
    ConfTipoRamalComponent,
    RamalComponent,
    ConfRuaComponent,
    ConfBairroComponent,
    ConfMunicipioComponent,
    ConfProvinciaComponent,
    CreateOrEditMunicipioComponent,
    ConfDistritoComponent,
    CreateOrEditBairroComponent,
    CreateOrEditRuaComponent,
    CreateOrEditDistritoComponent,
    CreateOrEditProvinciaComponent,
    ConfTarifarioComponent,
    LocalInstalacaoComponent,
    LocalConsumoComponent,
    CreateOrEditLocalInstalacaoComponent,
    CreateOrEditContadoresComponent,
    ConfTipoContratoComponent,
    ClasseTarifarioComponent,
    ProdutoclasseTarifarioComponent,
    FloatLinkComponent,
    ListarContratoComponent,
    LeituraComponent,
    ListarLeiturasValidasComponent,
    RegistarLeituraComponent,
    ConfModeloComponent,
    ConfMarcaComponent,
    ConfMedicaoComponent,
    ConfClassePrecisaoComponent,
    ConfFabricanteComponent,
    ConfTipoContadorComponent,
    MapaRotaComponent,
    ListagemRoteirosComponent,
    ConfQuarteiraoComponent,
    ConfiguracaoComponent,
    TipoObjectoComponent,
    LigacaoRamalComponent,
    ObjectoLigacaoRamalComponent,
    NaoLeituraComponent,
    TipoNaoLeituraComponent,
    TipoOcorrenciaComponent,
    OcorrenciaComponent,
    CreateOrEditQuarteiraoComponent,
    OcorrenciaComponent,
    ConfTipoRegistoComponent,
    ConfTipoOrdemServicoComponent,
    ConfTipoFacturacaoComponent,
    ConfEstadoContaComponent,
    NewLigacaoRamalComponent,
    ConfTipoLigacaoComponent,
    ConfEstadoCicloFacturacaoComponent,
    ContextoConfiguracaoComponent,
    ConfEstadoTarifarioComponent,
    ConfCaudalComponent,
    ConfCalibreComponent,
    ConfTipologiaClienteComponent,
    ConfTiposTipologiaComponent,
    ConfNivelSensibilidadeComponent,
    ConfTipoProdutoComponent,
    CaucaoComponent,
    GestorContaComponent,
    ConfTipoLocalInstalacaoComponent,
    ConfTipoLocalConsumoComponent,
    EditarRoteiroComponent,
    ConfTipoClienteComponent,
    ConfCampoJardimComponent,
    ConfAbastecimentoCilComponent,
    ConfTipoJuroComponent,
    ConfObjectoContratoComponent,
    ConfMotivoDenunciaComponent,
    ConfEstadoContratoComponent,
    ConfMesesMediaConsumoComponent,
    ConfTipoMedicaoComponent,
    ConfTipoMensagemComponent,
    StockProdutosComponent,
    CreateOrEditArmazemComponent,
    CreateOrEditProdutosComponent,
    StockCategoriaProdutosComponent,
    StockTipoMovimentosComponent,
    CreateOrEditCategoriaStkComponent,
    CreateOrEditTipoMovimentoComponent,
    PeriodicidadeRoteiroComponent,
    EntradaStockComponent,
    MovimentacaoStockComponent,
    MenuReportComponent,
    SidebarReportingComponent,
    SaidaStockComponent,
    StockExistenteComponent,
    PagamentoParcelarFacturaComponent,
    ExtraccaoClientesComponent,
    TransferenciaComponent,
    ReportMovimentosCaixaComponent,
    ConfContaBancariaComponent,
    CreateOrEditContaBancariaComponent,
    FacturacaoDetalhadaPosPagoComponent,
    ConfDadosFacturacaoComponent,
    ReportResumoContaCorrenteComponent,
    BancosComponent,
    ReportVendaLojaProdutoComponent,
    FacturacaoDetalhadaProdutoComponent,
    ImpressaoMassivaComponent,
    ConfFormaPagamentoComponent,
    ConfSeloComponent,
    ConfTipoSeloComponent,
    ReportFormaPagamentoComponent,
    DetalheContratoComponent,
    ListarImpressaoMassivaComponent,
    ConfCorSeloComponent,
    CreateOrEditColaboradorComponent,
    CreateOrEditDepartamentoComponent,
    ColaboradoresComponent,
    CreateOrEditRegistoInternoComponent,
    RegistoInternoComponent,
    CategoriaComponent,
    NivelComponent,
    TipoComponent,
    EstadoComponent,
    OrigemComponent,
    RegistroComponent,
    ResumoContaComponent,
    OrdemEquipamentoComponent,
    OrdemEstadoComponent,
    OrdemPrioridadeComponent,
    CreateOrEditEquipamentoComponent,
    CreateOrEditEstadoComponent,
    CreateOrEditPrioridadeComponent,
    CriarOrdemServicoComponent,
    ListarOrdemServicoComponent,
    OsClassificacaoComponent,
    OsTipoComponent,
    CreateOrEditClassificacaoComponent,
    CreateOrEditOsTipoComponent,
    EquipamentoEstadosComponent,
    CreateOrEditEquipamentoEstadoComponent,
    OsSintomasComponent,
    OsCausasComponent,
    CreateOrEditOsCausasComponent,
    CreateOrEditOsSintomasComponent,
    ListOsAgendadosComponent,
    ListCriticidadeComponent,
    ListTipoAquisicaoComponent,
    ListEstadoConservacaoComponent,
    CreateOrEditEstadoConservacaoComponent,
    CreateOrEditTipoAquisicaoComponent,
    CreateOrEditGrauCriticidadeComponent,
    OsCategoriaComponent,
    CreateOrEditOsCategoriaComponent,
    HistoricoOrdemServicoComponent,
    ListaOsTrabalhoComponent,
    ExecutarOrdemServicoComponent,
    ListAgendamentoByOrdemComponent,
    DetalheAgendamentoComponent,
    EditOrdemAgendamentoComponent,
    ViewEquipamentoLocationMapaComponent,
    ViwListaTrabalhoComponent,
    UnidadesComponent,
    CreateOrEditUnidadeComponent,
    ConfigHorasComponent,
    CreateOrEditHoraComponent,
    ProcessarLeiturasComponent,
    RhEmpresasComponent,
    CreateOrEditRhEmpresasComponent,
    FacturacaoTableComponent,
    CobrancaProdutoComponent,
    OtTriggerComponent,
    CreateOrEditOtTriggerComponent,
    ModalCreateOrdemTrabalhoComponent,
    TipificacaoAnomaliasComponent,
    OpenlayersComponent,
    ModalCreateOtEquipamentoComponent,
    TipoAnomaliaComponent,
    HistoricosFacturacaoComponent,
    EditarOrdemTrabalhoComponent,
    ResumoCobrancaArtigoComponent,
    ResumoTotalComponent,
    ResumoPorLeituraComponent,
    ResumoPorAvencaComponent,
    ResumoPorArtigoComponent,
    ResumoPorServicoComponent,
    ResumoMovimentoCaixaComponent,
    RelatorioMovimentoCaixaComponent,
    ConfiTipoFacturacaoComponent,
    ResumoAguaMCubicoComponent,
    ResumoAguaSaniamentoComponent,
    ResumoTaraFixaComponent,
    ResumoFormaPagamentoComponent,
    ViewClienteDetalhesComponent,
    ResumoFacturacaoComponent,
    FacturacaoDistritoComponent,
    FacturacaoProdutoComponent,
    FacturacaoSaneamentoComponent,
    FacturacaoAguaSaneamentoComponent,
    FacturacaoAguaMedidaComponent,
    FacturacaoAguaEstimadaComponent,
    FacturacaoTarifaFixaComponent,
    FacturacaoServicoLigacaoComponent,
    TarifariioContratoComponent,
    EmitirNotaCreditoCicloComponent,
    EstruturaMoradasComponent,
    RelatorioResumoContratosComponent,
    CentroCustoComponent,
    ConfEstadoRamalComponent,
    ConfDiametroRamalComponent,
    ConfMaterialRamalComponent,
    MpContadorComponent,
    CreateOrEditMpContadorComponent,
    PlanoManutencaoComponent,
    ItensPlanoManutencaoComponent,
    RegisterContadorEquipamentoComponent,
    CreateOrEditPlanoManutencaoComponent,
    MpEstadoPlanoManutencaoComponent,
    CreateOrEditEstadoPlanoManutencaoComponent,
    AlterarTitularidadeComponent,
    ListaGeralIntervencoesComponent,
    NumeroFacturasTipoFactutracao,
    CobrancaDetalhadaValorRecebidoComponent,
    CobrancaDetalhadaValorSaldadoComponent,
    MpCalendarioTarefasComponent,
    ConfNotificacaoComponent,
    RelatorioDividasComponent,
    RelatorioAdiantamentosComponent,
    RelatorioLeiturasRealizadasComponent,
    NumeroContratosFacturadosFacturas,
    NumeroContratosCobradosFactura,

    ReportManutencaoComponent,
    ViewArmazemProdutoDesponiveisComponent,
    LigarRamalsComponent,
    ListarRamalsComponent,
    SubsistemaComponent,
    PressaoNominalComponent,
    CreateOsMassivaComponent,
    TipoRamalComponent,
    EditarOtPreventivaComponent,
    ExecutarOtPreventivaComponent,
    DetalheAgendamentoPreventivaComponent,
    AgendarOtPreventivaComponent,
    LocalInstacaoDisponiveisComponent,
    AgendarOsMassivaComponent,
    RegistroNovaLigacaoComponent,
    VerFacturaComponent,
    FerramentasComponent,
    EspecialistaComponent,
    PeriodicidadeEmDiaComponent,
    DuracaoEmHoraComponent,
    ConsumiveisComponent,
    CreateOrEditMpDuracaoHorasComponent,
    CreateOrEditMpEspecialistaComponent,
    CreateOrEditMpConsumiveisComponent,
    CreateOrEditMpPeriodicidadeDiasComponent,
    CreateOrEditMpFerramentaComponent,
    ExecutarOsMassivaComponent,
    ViewClientesByOrdemComponent,
    ViewTarefasByAgedamentoComponent,
    ConfNotificacaoNovasLigacoesComponent,
    LogsReferenciaEmisComponent,
    LogsDeNotificacoesComponent,
    TipoAvisoCortesComponent,
    ConfigAvisoCorteComponent,
    RelatorioIvaComponent,
    ResumoTotalIvaComponent,
    ResumoAguaMCubicoIvaComponent,
    ResumoPorArtigoIvaComponent,
    OtListagemConsumivelComponent,
    OrcamentoDetalhadoComponent,
    FacturacaoCaucaoComponent,
    CriarPlanoPrestacaoComponent,
    DevoulcaoComponent,
    RelatorioCaucaoComponent,
    TriggerSequenciaComponent,
    ListarPlanoPrestacaoComponent,
    ListagemEstornoComponent,
    ListagemDevolucaoComponent,
    ListaLinhaPrestacaoByPlanoPrestacaoComponent,
    PagamentoPrestacaoComponent,
    LogOtFiscalizacaoComponent,
    DevolucaoEstimativaComponent,
    ListagemEstimativaComponent,
    LogGestaoDividaComponent,
    CreateOrEditLogGestaoDividaComponent,
    LogsContenciososComponent,
    PagamentoPlanoPrestacaoComponent,
    TipoJuroComponent,
    ListarFacturasByPlanoPrestacaoComponent,
    EditarLinhaPrestacaoComponent,
    RelatorioOrdemServicoComponent,
    AnulacaoRecebimentosComponent,
    ListagemRecibosAnularComponent,
    GestaoZmcComponent,
    CreatLocalInstalacaoByNovaLigacaoComponent
  ],
  imports: [
    MatProgressBarModule,
    MgxCircularProgressBarModule,
    MgxCircularProgressFullBarModule,
    MgxCircularProgressPieModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatCheckboxModule,
    RotasRoutingModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatOptionModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    MatButtonModule,
    MatSortModule,
    MatPaginatorModule,
    MatTableModule,
    NgIdleKeepaliveModule.forRoot(),
    ModalModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    CommonModule,
    BrowserAnimationsModule,
    Ng2OrderModule,
    DragDropModule,
    AngularDraggableModule,
    ToastrModule.forRoot({
      timeOut: 15000,
      progressBar: true,
      progressAnimation: "increasing",
      preventDuplicates: true,
      newestOnTop: true,
      closeButton: true,
      maxOpened: 1,
    }),
    NgxMaskModule.forRoot(options),
    ArchwizardModule,
    Select2Module,

    NgSelectModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    NgxLoadingModule.forRoot({
      backdropBorderRadius: "3px",
      backdropBackgroundColour: "rgba(255, 255, 255, 0.78)",
      primaryColour: "#20a8d8",
      secondaryColour: "#20a8d8",
      tertiaryColour: "#20a8d8",
    }),
    // Specify ng-circle-progress as an import
    NgCircleProgressModule.forRoot({
      backgroundGradient: true,
      backgroundColor: "#ffffff",
      backgroundGradientStopColor: "#c0c0c0",
      backgroundPadding: -10,
      radius: 60,
      maxPercent: 100,
      outerStrokeWidth: 10,
      outerStrokeColor: "#61A9DC",
      innerStrokeWidth: 0,
      subtitleColor: "#444444",
      showInnerStroke: false,
      startFromZero: false,
    }),
    ProgressBarModule,
    NgMultiSelectDropDownModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyDUfCKari6bAJFFAvToSFQq8KKoCSXPT9Y",
    }),
    AgmJsMarkerClustererModule,
    ProgressBarModule,
    NgMultiSelectDropDownModule.forRoot(),
    FullCalendarModule,
    RxReactiveFormsModule,
  ],
  exports: [
    LayoutComponent,
    AsidenavbarComponent,
    TopnavbarComponent,
    FooternavbarComponent,
    SetingsnavbarComponent,
  ],
  providers: [
    {provide: SAVER, useFactory: getSaver},
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErroInterceptor, multi: true },
    {
      provide: DateAdapter,
      useClass: FormatDateAdapter,
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: FORMAT_DATE_FORMATS,
    },
    HttpService,
    NgxImageCompressService,
    ApiService,
    AuthService,
    LocalService,
    PedidoReportService,
    DiarioVendasService,
    ExcelService,
    GoogleMapsAPIWrapper,
    ConfigModuloService,
    RelCobracaGlobalService,
    ReportLojaService,
    ReportIVAService,
    ReportClienteService,
    ReportAdiantamentosService,
    ReportServicoContradosService,
    ValidationFormsService,
    NotificacaoService
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
