import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

@Component({
  selector: 'app-conf-tipo-contrato',
  templateUrl: './conf-tipo-contrato.component.html',
  styleUrls: ['./conf-tipo-contrato.component.css']
})
export class ConfTipoContratoComponent implements OnInit, OnDestroy {

  private title: string = "Novo";
  private tipo_contrato = {
    id: null,
    descricao: null,
    slug: null
  }


  private items: any = [];

  constructor(private http: HttpService, private configService: ConfigService) { }

  ngOnInit() {
    this.http.filters.search = null
    this.getPageFilterData(1);
  }

  ngOnDestroy(){
    this.http.filters.orderBy = null
  }


  private getTipoContratos() {

    this.configService.loaddinStarter('start');

    this.http.__call('tipo-contrato/listagem', this.http.filters).subscribe(

      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.configService.loaddinStarter('stop');

      }
    );
  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
    this.getTipoContratos();
  }


  private save() {
    this.configService.loaddinStarter('start');

    if (this.tipo_contrato.descricao == "" || this.tipo_contrato.slug == null) {
      this.configService.showAlert("Os campos Nome e Slug são obrigatórios", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    } else {

      const uri = (!this.tipo_contrato.id) ? 'tipo-contrato/create' : 'tipo-contrato/update/' + this.tipo_contrato.id;

      this.http.__call(uri, this.tipo_contrato).subscribe(
        res => {
          if (Object(res).code == 201) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
            this.configService.loaddinStarter('stop');
          } else {
            this.configService.showAlert(Object(res).message, 'alert-success', true);

            this.configService.loaddinStarter('stop');
          }

          this.getTipoContratos()

        }
      )
    };
  }


  private refresh(id, descricao, slug) {
    this.title = "Editar";

    this.tipo_contrato.id = id;
    this.tipo_contrato.descricao = descricao;
    this.tipo_contrato.slug = slug;
  }


  private ini() {
    this.tipo_contrato = {
      id: null,
      descricao: null,
      slug: null
    }
  }





}
