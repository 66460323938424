import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

@Component({
  selector: 'app-tipo-juro',
  templateUrl: './tipo-juro.component.html',
  styleUrls: ['./tipo-juro.component.css']
})
export class TipoJuroComponent implements OnInit,OnDestroy  {

  private tipoJuros = {
    id: null,
    dias: null,
    valor_dias: null,
    percentagem: null,
    slug:null,
  }

  private items: any = [];

  constructor(private http: HttpService, private configService: ConfigService) { }

  ngOnInit() {
    this.http.filters.search = null
    this.getPageFilterData(1);
  }
  ngOnDestroy(){
    this.http.filters.orderBy = null
  }



  private listatipoJuros() {

    this.configService.loaddinStarter('start');

    this.http.__call('tipo-juro/listagem', this.http.filters).subscribe(

      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.configService.loaddinStarter('stop');

      }
    );
  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page =Number.isInteger(page) ? page : 1 ;
    this.listatipoJuros();
  }

  private refresh(item) {
    this.tipoJuros.id = item.id;
    this.tipoJuros.slug = item.slug;
    this.tipoJuros.dias = item.dias;
    this.tipoJuros.valor_dias = item.valor_dias;
    this.tipoJuros.percentagem = item.percentagem;
    console.log(item)
  }


  private register(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (this.tipoJuros.slug == null || this.tipoJuros.valor_dias == null) {
      this.configService.showAlert("O campo Designação e valor em dias são obrigatórios", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    } else if (this.tipoJuros.slug == null && this.tipoJuros.valor_dias == null) {
      this.configService.showAlert("O campo Designação e valor em dias são obrigatórios", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    }
    else {
    this.http.__call('tipo-juro/create', this.tipoJuros).subscribe(
      res => {
        if (Object(res).code == 201) {
          this.configService.showAlert(Object(res).message, 'alert-danger', true);
          this.configService.loaddinStarter('stop');
        } else {
          this.configService.showAlert(Object(res).message, 'alert-success', true);
          this.clearFormInputs(e);
          this.listatipoJuros()
          this.configService.loaddinStarter('stop');
        }
      }
    )
  };
}

private clearFormInputs(e) {
  e.target.elements[0].value = null;
  e.target.elements[1].value = null;
  e.target.elements[2].value = null;
  e.target.elements[3].value = null;
  e.target.elements[4].value = null;
}


private editar(e) {
  this.configService.loaddinStarter('start');
  e.preventDefault();
  if (this.tipoJuros.slug == null || this.tipoJuros.valor_dias == null) {
    this.configService.showAlert("O campo Designação e valor por dias são obrigatórios", 'alert-danger', true);
    this.configService.loaddinStarter('stop');
  } else if (this.tipoJuros.slug == null && this.tipoJuros.valor_dias == null) {
    this.configService.showAlert("O campo Designação e valor diario são obrigatórios", 'alert-danger', true);
    this.configService.loaddinStarter('stop');
  }
  else {
    this.http.__call('tipo-juro/update/' + this.tipoJuros.id, this.tipoJuros).subscribe(
      res => {
        if (Object(res).code == 201) {
          this.configService.showAlert(Object(res).message, 'alert-danger', true);
        } else {
          this.configService.showAlert(Object(res).message, 'alert-success', true);
          this.listatipoJuros();

        }
      }
    );
  }
  this.configService.loaddinStarter('stop');
}

  private ini() {
    this.tipoJuros = {
      id: null,
      dias: null,
      valor_dias: null,
      percentagem: null,
      slug:null,
    }
  }


}
