import { Component, OnInit, SimpleChange, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ConfigService } from 'src/app/providers/config/config.service';
import { HttpService } from 'src/app/providers/http/http.service';
var moment = require("moment");

@Component({
  selector: 'app-create-or-edit-contadores',
  templateUrl: './create-or-edit-contadores.component.html',
  styleUrls: ['./create-or-edit-contadores.component.css']
})
export class CreateOrEditContadoresComponent implements OnInit {

  @Input() modalContadorCreateOrEdit: string = "modalContadorCreateOrEdit";
  @Input() modalUpdateEstado: string = "modalUpdateEstado";

  @Input() title: string = "Registar Contador";
  @Input() contador: any;
  @Input() editar: boolean = false;

  @Input() contadorValid: boolean = false;

  @Input() marcas: any = [];
  @Input() modelos: any = [];
  @Input() fabricantes: any = [];
  @Input() armazem: any = [];
  @Input() classes: any = [];
  @Input() tipos: any = [];
  @Input() medicao: any = [];
  @Input() tipo_contadores: any = [];
  @Input() caudal: any = [];
  @Input() calibre: any = [];
  @Input() estado_contadores: any = [];



  submitted = false;
  private loading: boolean = false;

  @Input() simpleFormContador: FormGroup;
  @Input() selectForms: any = [];

  @Output() private loadListContador = new EventEmitter<any>();
  private anos_fabrico: any = []
  private anos_instalacao: any = []
  private Selo: boolean = false;

  constructor(private http: HttpService, private configService: ConfigService, private formBuilder: FormBuilder) {
    this.createForm();
  }

  ngOnInit() {
    this.gerarAnoFabrico();
    this.gerarAnoInstalacao();
    this.disabledItem()
  }

  createForm() {
    this.simpleFormContador = this.formBuilder.group({
      index: [{ value: null, disabled: true }],
      marca_id: [null],
      modelo_id: [null],
      medicao_id: [null],
      numero_serie: [null, [Validators.required]],
      id_classe_precisao: [null],
      estado_contador_id: [null, [Validators.required]],
      tipo_contador_id: [null],
      calibre_id: [null],
      caudal_id: [null],
      contador: [null],
      contador_ID: [null],
      digitos: [null],
      ano_fabrico: [null],
      ano_instalacao: [null],
      fabricante_id: [null],
      selo: [null],
      armazem_id: [null],
      ultima_leitura: [null, [Validators.required]],
      ultima_verificacao: [null],
      observacao: [null],
    });
  }



  get f() {
    return this.simpleFormContador.controls;
  }

  disabledItem() {
    this.simpleFormContador.controls.ultima_leitura.enable()
  }


  anebleItem() {
    this.simpleFormContador.controls.ultima_leitura.enable()
  }

  onReset() {
    this.submitted = false;
    this.simpleFormContador.reset();
  }


  onSubmit() {

    this.submitted = true;
    this.simpleFormContador.value.numero_serie = this.simpleFormContador.value.numero_serie || null
    this.simpleFormContador.value.ultima_leitura = this.simpleFormContador.value.ultima_leitura


    if (
      (this.simpleFormContador.value.numero_serie == null) ||
      (this.simpleFormContador.value.ultima_leitura == null)
      || (this.simpleFormContador.value.estado_contador_id == null ||
        this.simpleFormContador.value.estado_contador_id == 'null')) {
      return;
    }
    this.loading = true;

    const index = this.simpleFormContador.getRawValue().index;

    // TODO: usado para fazer a requisição com a api de criação or update
    console.log(index)
    const uri = (index === null ? 'contador/create' : 'contador/update/' + index);
    this.createOrEdit(uri, this.simpleFormContador, (index === null ? true : false));

  }

  createOrEdit(uri: any, formulario: FormGroup, isCreate: boolean) {

    // TODO: usado para fazer a requisição com a api de criação de object
    this.http.__call(uri, formulario.value).pipe(first()).subscribe(
      response => {
        this.submitted = false;
        this.loading = false;

        if (Object(response).code == 201) {
          this.loadListContadores(Object(response).data);

          this.openCreateModal(true);
        }

        else if (isCreate) {
          formulario.reset();

          this.loadListContadores(Object(response).data);
        }

        if (Object(response).code == 200) {
          this.loadListContadores(Object(response).data);

          this.openCreateModal(true);
        }

        this.configService.showAlert(Object(response).message, 'alert-info', true);
      },
      error => {
        this.submitted = false;
        this.loading = false;
      });
  }

  private createModal: boolean = false;
  private openCreateModal(open: boolean): void {
    this.createModal = open;
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {

    if (this.contador.id > 0) {
      /*      if (this.contador !== undefined ) { */
      var dataActual = moment(new Date()).format("YYYY-MM-DD");
      this.title = "Editar Contador";
      this.simpleFormContador.patchValue({
        index: this.contador.id,
        contador: this.contador.contador,
        contador_ID: this.contador.contador_ID,
        marca_id: this.contador.marca_id,
        modelo_id: this.contador.modelo_id,
        medicao_id: this.contador.medicao_id,
        numero_serie: this.contador.numero_serie,
        id_classe_precisao: this.contador.id_classe_precisao,
        calibre_id: this.contador.calibre_id,
        caudal_id: this.contador.caudal_id,
        estado_contador_id: this.contador.estado_contador_id,
        digitos: this.contador.digitos,
        ano_fabrico: this.contador.ano_fabrico,
        ano_instalacao: this.contador.ano_instalacao,
        validade: this.contador.validade,
        fabricante_id: this.contador.fabricante_id,
        selo: this.Selo,
        armazem_id: this.contador.armazem_id,
        tipo_contador_id: this.contador.tipo_contador_id,
        ultima_leitura: this.contador.ultima_leitura,
        ultima_verificacao: dataActual,
        observacao: this.contador.observacao
      });
    } else {
      this.onReset();
      this.title = "Registar Contador";
    }
  }

  private modelo_id: number

  private ModelosbyMarca() {

    this.http.call_get('selectBox/modelobymarca/' + this.modelo_id, null).subscribe(
      data => {
        /*         this.modelos = Object(data).data; */
        this.modelos = Object(data);
      }
    );
  }


  public loadListContadores(contador) {
    this.loadListContador.emit(contador);
  }

  private setSelo(): void {
    this.Selo = !this.Selo;
    var dataActual = this.Selo ? moment(new Date()).format("YYYY-MM-DD") : null;

    this.simpleFormContador.patchValue({
      selo: this.Selo,
      ultima_verificacao: dataActual
    })
  }

  private gerarAnoInstalacao() {
    var fecha = new Date();
    var anyo = fecha.getFullYear();

    let j = 0;
    for (let i = anyo; i >= 2000; i--) {
      this.anos_instalacao[j] = i;
      j++;
    }
  }

  private gerarAnoFabrico() {
    var fecha = new Date();
    var anyo = fecha.getFullYear();

    let j = 0;
    for (let i = anyo; i >= 1990; i--) {
      this.anos_fabrico[j] = i;
      j++;
    }
  }
}
