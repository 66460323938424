import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { Response } from 'selenium-webdriver/http';

import { ReportClienteService } from 'src/app/components/report-at/relatorios/report-cliente.service';
import { ExcelService } from 'src/app/services/excel.service';
import * as moment from 'moment';
import { ExcelAutoService } from 'src/app/services/excel/excel-auto.service';

@Component({
  selector: 'app-cliente-relatorio',
  templateUrl: './cliente-relatorio.component.html',
  styleUrls: ['./cliente-relatorio.component.css']
})

export class ClienteRelatorioComponent implements OnInit {


  loading: boolean = false;
  disableButton: boolean = true;

  private filter = {
    tipo_identidade: null,
    tipo_cliente: null,
    gestor_conta: null,
    genero: null,
    direccao: null,
    email: null,
    municipio:null,
    bairro:null,
    rua:null,
    cliente: null,
    cliente_id: null,
    data1:null,
    data2:null
  }

  submitted = false;
  public filters = {
    search: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    pagination: {
      perPage: 5,// Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima,
      lastPage: null,
      total: null // defini o total de registo da BD
    },
    filter: null // subelemente do filter
    ,is_allexel:false
  }

  private localUrl: any;
  private largura: any;
  private altura: any;
  items: any = [];
  direccoes: any = [];
  gestores: any = [];
  generos: any = [];
  tipoclientes: any = [];
  municipios: any = [];
  bairros: any = [];
  ruas: any = [];
  tipoidentidades: any = [];
  clientes: any = [];
  public checkoutDate:boolean=false

  constructor(private http: HttpService,
    private configService: ConfigService,
     private excelService: ExcelService,
     private reportClientes: ReportClienteService,
     private excelsService: ExcelAutoService
     ) {

  }

  ngOnInit() {
    this.getGeneros();
    this.getTipoCliente();
    this.getTipoIdentidade();
    this.empresaUser()
    this.getMunicipio()
    /* this.getPageFilterData(1); */
  }


  exportAsPDF(): void {
    var file = document.getElementsByClassName("exportAsXLSXCliente")[0]
    this.reportClientes.relatorioClientes('save', file,this.localUrl);

  }

  imprimirPDF(): void {
    var file = document.getElementsByClassName("exportAsXLSXCliente")[0];
    /*     this.reportClientes.relatorioClientes(this.items,file,'print'); */
    this.reportClientes.relatorioClientes('print', file,this.localUrl);
    // this.reportCliente.relatorioClientes(this.items);
  }

  private empresaUser() {
    this.configService.loaddinStarter('start');

    this.http.call_get('empresa/empresa-user', null).subscribe(
      response => {
        this.localUrl = Object(response).data.logotipo
        this.altura = Object(response).data.width
        this.largura = Object(response).data.height
        this.configService.loaddinStarter('stop');
      }
    );
  }

  setTrue(){
    this.filters.pagination.page=this.filters.pagination.page
    this.filters.is_allexel=true
    this.listClientes()
  }
   hendleDateValidate=()=>{
           
    let format = "YYYY-MM-DD"
    let data1 = moment( this.filter.data1 ).format(format);
    let data2 = moment( this.filter.data2 ).format(format);
   
    let condition= ( data1 > data2 ) 
                       
    return condition
 }

 resetDate(){
  this.checkoutDate=this.hendleDateValidate()
 }


  private listClientes() {    
    

    this.configService.loaddinStarter('start');
    this.loading = true;
    this.filters.filter = this.filter;


    this.http.__call('relatorio/clientes', this.filters).subscribe(
      response => {
        this.filters.pagination.lastPage = Object(response).data.lastPage;
         this.filters.pagination.page = Object(response).data.page;
         this.filters.pagination.total = Object(response).data.total;
         this.filters.pagination.perPage = Object(response).data.perPage;
        this.items = Object(response).data;
        this.loading = false;
        if (this.items != 0) {
          this.disableButton = false;
        }

        this.loading = false;
      }
      );
      this.filters.is_allexel=false
      this.configService.loaddinStarter('stop');
  }

  exportAsXLSX():void {
    var CurrentDate = new Date();
    
    var keys = [
      { key: 'cliente_id', width: 30 },
      { key: 'nome', width:30, style: { font: { name: 'Calibri' } } },
      { key: 'genero', width:30, style: { font: { name: 'Calibri' } } },
      { key: 'operador', width: 30 },
      { key: 'loja', width: 30 },
      { key: 'tipoClienteDesc', width: 30 },
      { key: 'morada', width: 30 },
      { key: 'municipio', width: 30 },
      { key: 'bairro', width: 30 },
      { value:'NIF' },
      { key: 'numero_identificacao', width: 50 },
      { key: 'tipo_cliente', width: 30 },
      { key: 'email', width: 30 },
      { key: 'telefone', width: 30 },
    ];

    var Cols = [
        'Nº Cliente',
        'Nome Cliente',
        'Gênero',
        'Operador',
        'Loja',
        'Tipo Cliente',
        'Morada Cliente',
        'Município',
        'Bairro/Zona',
        'Tipo Identidade',
        'Nº Identidade',
        'Tipo Cliente',
        'Email',
        'Telefone',
      ]

    var title='RELATÓRIOS CLIENTES'
    var nameFile = "RELATORIOS_CLIENTES -" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
     this.excelsService.excels(this.items,nameFile,this.localUrl,keys,Cols,title,5,14,40,3)
  }

  /*  getPageFilterData(page: number) {
     if (this.http.filters.pagination.perPage == null) {
       return;
     }
     this.http.filters.pagination.page = page;
     this.listClientes();
   } */
  private getMunicipio(){
    this.http.__call('municipio/listagems', null).subscribe(
      response => {
        this.municipios = Object(response).data;
      }
    );
  }
  private getBairro(id){
    this.http.call_get(`bairro/getBairrosByMunicipio/${id}`, null).subscribe(
      response => {
        this.bairros = Object(response).data;
      }
    );
  }
  private getRua(id){
    this.http.call_get(`rua/getRuasByBairro/${id}`, null).subscribe(
      response => {
        this.ruas = Object(response).data;
      }
    );
  }
  view_client = false;
  private getCliente(search: any) {
    search = search.target.value;
    this.view_client = true;
    if(search == ''){
      this.view_client = false;
      this.filter.cliente_id = null;

    }
    this.http.__call('cliente/search-cliente', { start: 1, end: 10, search: search }).subscribe(
      response => {
        this.clientes = Object(response).data.data; 
      }
    );
  }

  private setCliente(client: any) {
    this.view_client = false;
        this.filter.cliente_id = client.id,
        this.filter.cliente = client.nome
  }

  private getGeneros() {
    this.http.call_get('genero/selectBox', null).subscribe(
      response => {
        this.generos = Object(response);
      }
    );
  }

   

  private getTipoCliente() {
    this.http.call_get('tipo-cliente/listagem', null).subscribe(
      response => {
        this.tipoclientes = Object(response).data.data;
      }
    );
  }

  private getTipoIdentidade() {
    
    this.http.call_get('tipo-identidade/listagem', null).subscribe(
      response => {
        this.tipoidentidades = Object(response).data;

      }
    );
  }

}


