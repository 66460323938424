import { Component, OnInit, Input, Output } from '@angular/core';

@Component({
  selector: 'editFlatFateServico',
  templateUrl: './edit-flat-rate-servico.component.html',
  styleUrls: ['./edit-flat-rate-servico.component.css']
})
export class EditFlatRateServicoComponent implements OnInit {

  @Input() modal:string="editFlatFateServico"
  constructor() { }

  ngOnInit() {
  }

}
