import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { Pagination } from 'src/app/models/pagination';
import { TipoClienteService } from '../config-crm/tipo-de-cliente/tipo-de-cliente.service';


@Component({
    selector: 'app-conf-tarifario',
    templateUrl: './conf-tarifario.component.html',
    styleUrls: ['./conf-tarifario.component.css']
})

export class ConfTarifarioComponent implements OnInit, OnDestroy {

    public pagination = new Pagination();
    tarifarios: any = [];

    tarifario = {
        id: null,
        descricao: null,
        estado:1,
        tarifario_variavel: null,
        tarifa_fixa_mensal: null,
        regra_aplicacao: null,
        tipo_cliente_id: null,
        tipo_contrato_id: null
    }

    tarifarioValor = {
        tarifarioId: null,
        valor: null,
        decricao: null
    }

    private current_tarifarioValor: any = [];
    private items: any = [];
    private loading: boolean = false;

    constructor(
        private http: HttpService,
        private configService: ConfigService,
    ) { }

    ngOnInit() {
        this.http.filters.search = null
        this.getPageFilterData(1)
    }
    ngOnDestroy() {
        this.http.filters.orderBy = null
    }


    private ListarTarifario() {
        this.configService.loaddinStarter('start');

        this.http.__call('tarifarios/listagem', this.http.filters).subscribe(
            response => {
                this.http.filters.pagination.lastPage = Object(response).data.lastPage;
                this.http.filters.pagination.page = Object(response).data.page;
                this.http.filters.pagination.total = Object(response).data.total;
                this.http.filters.pagination.perPage = Object(response).data.perPage;

                this.items = Object(response).data.data;
                this.configService.loaddinStarter('stop');
            }
        );
    }

    getPageFilterData(page: number) {
        if (this.http.filters.pagination.perPage == null) { return; }
        this.http.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
        this.ListarTarifario();
    }


    private clearFormInputs(e) {
        e.target.elements[0].value = null;
        e.target.elements[1].value = null;
        e.target.elements[2].value = null;
    }

    private refresh(id, descricao, tarifario_variavel, tarifa_fixa_mensal, regra_aplicacao) {
        this.tarifario.id = id;
        this.tarifario.descricao = descricao;
        this.tarifario.tarifario_variavel = tarifario_variavel;
        this.tarifario.tarifa_fixa_mensal = tarifa_fixa_mensal;
        this.tarifario.regra_aplicacao = regra_aplicacao;
    }

    _initModal(tarifario) {
        this.tarifario = tarifario

        this.tarifarioValor.tarifarioId = this.tarifario.id;
        this.tarifarioValor.decricao = this.tarifario.descricao;
    }

    resetTarifarioValor() {
        this.tarifarioValor.valor = null;
    }

    getTarifarioValor() {
        this.resetTarifarioValor();
        this.loading = true;
        this.http._get("getTarifarioValor/" + this.tarifarioValor.tarifarioId).subscribe(res => {
            console.log(res)
            this.current_tarifarioValor = res
            this.loading = false;
        })
    }

    createOrUpdateTarifarioValor() {
        this.loading = true;

        this.http.__call("createTarifarioValor", this.tarifarioValor)
            .subscribe(res => {
                this.loading = false;
                this.getTarifarioValor();
            })
    }

    private _initForm() {
        this.tarifario = {
            id: null,
            descricao: null,
            estado:1,
            tarifario_variavel: null,
            tarifa_fixa_mensal: null,
            regra_aplicacao: null,
            tipo_cliente_id: null,
            tipo_contrato_id: null
        }
    }

}
