import { TipoJuroComponent } from './components/tipo-juro/tipo-juro.component';
import { ListarPlanoPrestacaoComponent } from './components/listar-plano-prestacao/listar-plano-prestacao.component';
import { CriarPlanoPrestacaoComponent } from './components/criar-plano-prestacao/criar-plano-prestacao.component';
import { OrcamentoDetalhadoComponent } from './components/relatorios/financeiro/orcamento-detalhado/orcamento-detalhado.component';
import { ReportRecebimentoComponent } from './components/layout/menu-report/report-recebimento/report-recebimento.component';
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";

import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { ServicoComponent } from "./components/servico/servico.component";
import { VendaComponent } from "./components/venda/venda.component";
import { TerceiroComponent } from "./components/config-crm/clientes/terceiro.component";
import { FacturacaoComponent } from "./components/facturacao/facturacao.component";
import { PontoVendaComponent } from "./components/ponto-venda/ponto-venda.component";
import { AvencaComponent } from "./components/avenca/avenca.component";
import { ProdutoComponent } from "./components/produto/produto.component";
import { ContabilidadeComponent } from "./components/contabilidade/contabilidade.component";
import { CompraComponent } from "./components/compra/compra.component";
import { RecursoHumanoComponent } from "./components/recurso-humano/recurso-humano.component";
import { PagamentoComponent } from "./components/pagamento/pagamento.component";
import { RegisterComponent } from "./components/register/register.component";
//import { LoginComponent } from './components/login/login.component';
import { CriarArtigoComponent } from "./components/artigo/criar-artigo/criar-artigo.component";
import { ListarArtigoComponent } from "./components/artigo/listar-artigo/listar-artigo.component";
import { FornecedorComponent } from "./components/fornecedor/fornecedor.component";
import { UtilizadorComponent } from "./components/utilizador/utilizador.component";
import { ListarProdutosComponent } from "./components/listar-produtos/listar-produtos.component";
import { ImpostoComponent } from "./components/imposto/imposto.component";
import { DocumentoComponent } from "./components/documento/documento.component";
import { SerieComponent } from "./components/serie/serie.component";
import { ListarFacturacaoComponent } from "./components/listar-facturacao/listar-facturacao.component";
import { ListaIncumprimentoComponent } from './components/lista-incumprimento/lista-incumprimento.component';
//import { AuthGuard } from './providers/auth/auth.guard';
import { ReciboComponent } from "./components/recibo/recibo.component";
import { NotaCreditoComponent } from "./components/nota-credito/nota-credito.component";
import { SaftComponent } from "./components/saft/saft.component";
import { EmpresaComponent } from "./components/empresa/empresa.component";
import { StockMovimentoComponent } from "./components/stock-movimento/stock-movimento.component";
import { ContaCorrenteComponent } from "./components/conta-corrente/conta-corrente.component";
import { RelatorioResumoContratosComponent } from './components/relatorios/relatorio-resumo-contratos/relatorio-resumo-contratos.component';

import { PermissionsComponent } from "./components/permissions/permissions.component";
import { RoleComponent } from "./components/role/role.component";
import { ModulosComponent } from "./components/modulos/modulos.component";
import { ArmazemComponent } from "./components/armazem/armazem.component";
import { InventarioComponent } from "./components/inventario/inventario.component";
import { StockComponent } from "./components/stock/stock.component";

//import { HomeComponent } from './components/home/home.component';
import { AuthGuard } from "./guards/auth.guard";
import { LoginGuard } from "./guards/Login/login.guard";
import { OrcamentoComponent } from "./components/orcamento/orcamento.component";
import { ListaOrcamentoComponent } from "./components/lista-orcamento/lista-orcamento.component";

import { BiComponent } from "./bi/bi.component";

import { TarefaComponent } from "./components/tarefa/tarefa.component";
import { ProdutoFornecedorComponent } from "./components/produto-fornecedor/produto-fornecedor.component";

import { MoedaComponent } from "./components/moeda/moeda.component";
import { BancoComponent } from "./components/banco/banco.component";
import { ProjectoComponent } from "./components/projecto/projecto.component";

import { P404Component } from "./components/error/404.component";
import { P500Component } from "./components/error/500.component";
import { P403Component } from "./components/error/403.component";

import { LoginComponent } from "./components/autenticacao/login/login.component";
import { TarifarioComponent } from "./components/tarifario/tarifario.component";
import { ContratoComponent } from "./components/contrato/contrato.component";
import { PlanoPrecoComponent } from "./components/plano-preco/plano-preco.component";

import { MovimentoCaixaComponent } from "./components/caixa/movimento-caixa/movimento-caixa.component";

import { TestPaginationComponent } from "./components/test-pagination/test-pagination.component";
import { LojaComponent } from "./components/loja/loja.component";
import { TipoReclamacoesComponent } from "./components/reclamacoes/tipo-reclamacoes/tipo-reclamacoes.component";
import { PrioridadeComponent } from "./components/reclamacoes/prioridade/prioridade.component";
import { ReclamacoesComponent } from "./components/reclamacoes/reclamacoes/reclamacoes.component";
import { PedidosComponent } from "./components/pedidos/pedidos/pedidos.component";
import { ListaDepositosComponent } from "./components/caixa/lista-depositos/lista-depositos.component";
import { FacturacaoAutomaticaComponent } from "./components/facturacao/facturacao-automatica/facturacao-automatica.component";
import { CicloFacturacaoComponent } from "./components/facturacao/ciclo-facturacao/ciclo-facturacao.component";
import { TecnologiasComponent } from "./components/tecnologias/tecnologias.component";
import { EstadoReclamacoesComponent } from "./components/reclamacoes/estado-reclamacoes/estado-reclamacoes.component";
import { EstadoPedidosComponent } from "./components/pedidos/estado-pedidos/estado-pedidos.component";
import { UnificarclienteComponent } from "./components/config-crm/clientes/unificarcliente/unificarcliente.component";
import { ClientesunificadosComponent } from "./components/config-crm/clientes/clientesUnificados/clientesunificados/clientesunificados.component";
import { TiposPedidoComponent } from "./components/pedidos/tipos-pedido/tipos-pedido.component";
import { BillRunHeaderComponent } from "./components/relatorios/bill-run-header/bill-run-header.component";
import { GetPdfEmailComponent } from "./components/get-pdf-email/get-pdf-email.component";
import { EnvioRelatorioDiarioComponent } from "./components/relatorios/envio-relatorio-diario/envio-relatorio-diario.component";
import { ReportDiarioAutoComponent } from "./components/relatorios/report-diario-auto/report-diario-auto.component";

import { CriarParceriaComponent } from "./components/interconexao/criar-parceria/criar-parceria.component";
import { ListarParceriaComponent } from "./components/interconexao/listar-parceria/listar-parceria.component";


import { FacturacaoChargeComponent } from './components/facturacao-charge/facturacao-charge.component';
import { RelatorioFinanceiroComponent } from './components/relatorios/financeiro/relatorio-financeiro/relatorio-financeiro.component';
import { EntidadeCativadoraComponent } from './components/entidade-cativadora/entidade-cativadora.component';
import { RedefinirPasswordFirstAcessComponent } from './components/utilizador/redefinir-password-first-acess/redefinir-password-first-acess.component';
import { RecursosRedeComponent } from './components/recursos-rede/recursos-rede.component';
import { EmitirNotaCreditoComponent } from './components/facturacao/emitir-nota-credito/emitir-nota-credito.component';
import { EmitirNotaCreditoCicloComponent } from './components/facturacao/emitir-nota-credito-ciclo/emitir-nota-credito-ciclo.component';
import { LeituraContradorComponent } from './components/leitura-contrador/leitura-contrador.component';
import { ReportCotrancaGlobalComponent } from './components/relatorios/financeiro/report-cotranca-global/report-cotranca-global.component'
import { ReportLojaComponent } from './components/relatorios/financeiro/report-loja/report-loja.component'
import { ReportIVAComponent } from './components/relatorios/financeiro/report-iva/report-iva.component';
import { ReportServicosContratadosComponent } from './components/relatorios/financeiro/report-servicos-contradados/report-servicos-contradados.component';
import { ConsultarReciboComponent } from './components/recibo/consultar-recibo/consultar-recibo.component';
import { DireccaoComponent } from './components/direccao/direccao.component';
import { PedidosRelatorioComponent } from './components/relatorios/pedidos-relatorio/pedidos-relatorio.component';
import { ReclamacoesRelatorioComponent } from './components/relatorios/reclamacoes-relatorio/reclamacoes-relatorio.component';
import { ClienteRelatorioComponent } from './components/relatorios/cliente-relatorio/cliente-relatorio.component';
import { PerfilComponent } from './components/utilizador/perfil/perfil.component';
import { ReportPagamentoGlobalComponent } from './components/relatorios/financeiro/report-pagamento-global/report-pagamento-global.component';
import { ReportFacturacaoGestorComponent } from './components/relatorios/financeiro/report-facturacao-gestor/report-facturacao-gestor.component';

import { DispositivosComponent } from './components/dispositivos/dispositivos.component';
import { PreFacturacaoComponent } from "./components/facturacao/pre-facturacao/pre-facturacao.component";
import { NumeracaoInventarioRedeComponent } from './components/numeracao-inventario-rede/numeracao-inventario-rede.component';
import { from } from 'rxjs';
import { ContadoresComponent } from './components/contadores/contadores.component';
import { ConfiguracaoComponent } from './components/configuracao/configuracao.component';
import { ContextoConfiguracaoComponent } from './components/contexto-configuracao/contexto-configuracao.component';
import { ConfEstadoServicoComponent } from './components/conf-estado-servico/conf-estado-servico.component';
import { ConfEstadoContadorComponent } from './components/conf-estado-contador/conf-estado-contador.component';
import { ConfTipoRamalComponent } from './components/conf-tipo-ramal/conf-tipo-ramal.component';
import { ConfRuaComponent } from './components/conf-rua/conf-rua.component';
import { ConfQuarteiraoComponent } from './components/conf-quarteirao/conf-quarteirao.component';
import { ConfBairroComponent } from './components/conf-bairro/conf-bairro.component';
import { ConfMunicipioComponent } from './components/conf-municipio/conf-municipio.component';
import { ConfProvinciaComponent } from './components/conf-provincia/conf-provincia.component';
import { ConfDistritoComponent } from './components/conf-distrito/conf-distrito.component';

import { RamalComponent } from './components/ramal/ramal.component';
import { LocalInstalacaoComponent } from './components/locais/local-instalacao/local-instalacao.component';
import { LocalConsumoComponent } from './components/locais/local-consumo/local-consumo.component';
import { ConfTarifarioComponent } from './components/conf-tarifario/conf-tarifario.component';
import { ConfTipoContratoComponent } from './components/conf-tipo-contrato/conf-tipo-contrato.component';
import { ListarContratoComponent } from './components/contrato/listar-contrato/listar-contrato.component';
import { ClasseTarifarioComponent } from './components/classe-tarifario/classe-tarifario.component';
import { ProdutoclasseTarifarioComponent } from './components/produto-classe-tarifario/produto-classe-tarifario.component';
import { ConfMarcaComponent } from './components/conf-marca/conf-marca.component';
import { ConfModeloComponent } from './components/conf-modelo/conf-modelo.component';
import { ConfMedicaoComponent } from './components/conf-medicao/conf-medicao.component';
import { ConfClassePrecisaoComponent } from './components/conf-classe-precisao/conf-classe-precisao.component';
import { ConfFabricanteComponent } from './components/conf-fabricante/conf-fabricante.component';
import { ConfTipoContadorComponent } from './components/conf-tipo-contador/conf-tipo-contador.component';
import { TipoObjectoComponent } from './components/tipo-objecto/tipo-objecto.component';
import { LigacaoRamalComponent } from './components/ligacao-ramal/ligacao-ramal.component';
import { ObjectoLigacaoRamalComponent } from './components/objecto-ligacao-ramal/objecto-ligacao-ramal.component';
import { ConfEstadoRamalComponent } from './components/conf-estado-ramal/conf-estado-ramal.component';
import { ConfDiametroRamalComponent } from './components/conf-diametro-ramal/conf-diametro-ramal.component';
import { ConfMaterialRamalComponent } from './components/conf-material-ramal/conf-material-ramal.component';
import { ConfTipoRegistoComponent } from './components/conf-tipo-registo/conf-tipo-registo.component';

import { NaoLeituraComponent } from './components/nao-leitura/nao-leitura.component';
import { TipoNaoLeituraComponent } from './components/tipo-nao-leitura/tipo-nao-leitura.component';
import { TipoOcorrenciaComponent } from './components/tipo-ocorrencia/tipo-ocorrencia.component';
import { CaucaoComponent } from './components/caucao/caucao.component';
import { OcorrenciaComponent } from './components/ocorrencia/ocorrencia.component';
import { ConfTipoOrdemServicoComponent } from './components/conf-tipo-ordem-servico/conf-tipo-ordem-servico.component';
import { ConfTipoFacturacaoComponent } from './components/conf-tipo-facturacao/conf-tipo-facturacao.component';
import { ConfEstadoContaComponent } from './components/conf-estado-conta/conf-estado-conta.component';
import { NewLigacaoRamalComponent } from './components/new-ligacao-ramal/new-ligacao-ramal.component';
import { ConfTipoLigacaoComponent } from './components/conf-tipo-ligacao/conf-tipo-ligacao.component';
import { ConfTipoSeloComponent } from './components/conf-tipo-selo/conf-tipo-selo.component';
import { ConfCorSeloComponent } from './components/conf-cor-selo/conf-cor-selo.component';
import { ConfSeloComponent } from './components/conf-selo/conf-selo.component';
import { ConfEstadoCicloFacturacaoComponent } from './components/conf-estado-ciclo-facturacao/conf-estado-ciclo-facturacao.component';
import { ConfEstadoTarifarioComponent } from './components/conf-estado-tarifario/conf-estado-tarifario.component';
import { ConfCaudalComponent } from './components/conf-caudal/conf-caudal.component';
import { ConfCalibreComponent } from './components/conf-calibre/conf-calibre.component';
import { ConfTipologiaClienteComponent } from './components/conf-tipologia-cliente/conf-tipologia-cliente.component';
import { ConfTiposTipologiaComponent } from './components/conf-tipos-tipologia/conf-tipos-tipologia.component';
import { ConfTipoProdutoComponent } from './components/conf-tipo-produto/conf-tipo-produto.component';
import { ConfNivelSensibilidadeComponent } from './components/conf-nivel-sensibilidade/conf-nivel-sensibilidade.component';
import { GeneroComponent } from './components/config-crm/generos/genero.component';
import { TipoIdentificacaoComponent } from './components/config-crm/tipo-de-identificacao/tipo-de-identificacao.component';
import { GestorContaComponent } from './components/config-crm/gestores-contas/gestor-conta.component';
import { TipoClienteComponent } from './components/config-crm/tipo-de-cliente/tipo-de-cliente.component';
import { ConfTipoJuroComponent } from './components/conf-tipo-juro/conf-tipo-juro.component';
import { ConfObjectoContratoComponent } from './components/conf-objecto-contrato/conf-objecto-contrato.component';
import { ConfMotivoDenunciaComponent } from './components/conf-motivo-denuncia/conf-motivo-denuncia.component';
import { ConfEstadoContratoComponent } from './components/conf-estado-contrato/conf-estado-contrato.component';
import { ConfMesesMediaConsumoComponent } from './components/conf-meses-media-consumo/conf-meses-media-consumo.component';
import { ClassificacaoProdutoComponent } from './components/config.components/classificacao-produto/classificacao-produto.component';
import { ConfTipoMensagemComponent } from './components/conf-tipo-mensagem/conf-tipo-mensagem.component';
import { ConfTipoMedicaoComponent } from './components/conf-tipo-medicao/conf-tipo-medicao.component';
import { StockProdutosComponent } from './components/stock/stock-produtos/stock-produtos.component';
import { StockCategoriaProdutosComponent } from './components/stock/stock-categoria-produtos/stock-categoria-produtos.component';
import { StockTipoMovimentosComponent } from './components/stock/stock-tipo-movimentos/stock-tipo-movimentos.component';
import { RotaHeaderComponent } from './components/config-rotas/rota-header/rota-header.component';
import { CreateRoteiroComponent } from './components/config-rotas/rota-header/create-roteiro/create-roteiro';
import { EntradaStockComponent } from "./components/stock/entrada-stock/entrada-stock.component";
import { MovimentacaoStockComponent } from "./components/stock/movimentacao-stock/movimentacao-stock.component";
import { MenuReportComponent } from "./components/layout/menu-report/menu-report.component";
import { SaidaStockComponent } from "./components/stock/saida-stock/saida-stock.component";
import { StockExistenteComponent } from "./components/stock/stock-existente/stock-existente.component";

import { FacturacaoDetalhadaPosPagoComponent } from './components/relatorios/financeiro/facturacao-detalhada-pos-pago/facturacao-detalhada-pos-pago.component';
import { ConfContaBancariaComponent } from "./components/conf-conta-bancaria/conf-conta-bancaria.component";
import { ConfDadosFacturacaoComponent } from "./components/conf-dados-facturacao/conf-dados-facturacao.component";
import { ReportResumoContaCorrenteComponent } from './components/relatorios/financeiro/report-resumo-conta-corrente/report-resumo-conta-corrente.component';
import { ResumoContaComponent } from './components/relatorios/financeiro/resumo-conta/resumo-conta.component';
import { BancosComponent } from "./components/institucional/bancos/bancos.component";
import { ReportMovimentosCaixaComponent } from "./components/relatorios/financeiro/report-movimentos-caixa/report-movimentos-caixa.component";
import { TransferenciaComponent } from "./components/stock/transferencia/transferencia.component";
import { ExtraccaoClientesComponent } from "./components/relatorios/cliente-relatorio/extraccao-clientes/extraccao-clientes.component";
import { ReportVendaLojaProdutoComponent } from "./components/relatorios/financeiro/report-venda-loja-produto/report-venda-loja-produto.component";
import { FacturacaoDetalhadaProdutoComponent } from "./components/relatorios/financeiro/facturacao-detalhada-produto/facturacao-detalhada-produto.component";
import { ConfFormaPagamentoComponent } from "./components/conf-forma-pagamento/conf-forma-pagamento.component";
/* import { ReportVendaLojaProdutoComponent } from "./components/relatorios/financeiro/report-venda-loja-produto/report-venda-loja-produto.component";
 */
import { ImpressaoMassivaComponent } from './components/impressao-massiva/impressao-massiva.component';
import { ListarImpressaoMassivaComponent } from './components/impressao-massiva/listar-impressao-massiva/listar-impressao-massiva.component';
import { ReportFormaPagamentoComponent } from "./components/relatorios/financeiro/report-forma-pagamento/report-forma-pagamento.component";
import { ColaboradoresComponent } from "./components/recurso-humano/colaboradores/colaboradores.component";
import { RegistoInternoComponent } from "./components/registos/registo-interno/registo-interno.component";
import { ListagemRoteirosComponent } from "./components/config-rotas/listagem-roteiros/listagem-roteiros.component";


import { CategoriaComponent } from './components/comunicacao/categoria/categoria.component';
import { NivelComponent } from './components/comunicacao/nivel/nivel.component';
import { TipoComponent } from './components/comunicacao/tipo/tipo.component';
import { EstadoComponent } from './components/comunicacao/estado/estado.component';
import { OrigemComponent } from './components/comunicacao/origem/origem.component';
import { RegistroComponent } from './components/comunicacao/registro/registro.component';
import { EditarRoteiroComponent } from "./components/config-rotas/rota-header/editar-roteiro/editar-roteiro.component";
import { OrdemEstadoComponent } from "./components/ordemservico/ordem-estado/ordem-estado.component";
import { OrdemPrioridadeComponent } from "./components/ordemservico/ordem-prioridade/ordem-prioridade.component";
import { OrdemEquipamentoComponent } from "./components/ordemservico/ordem-equipamento/ordem-equipamento.component";
import { FazerAgendamentoComponent } from "./components/config-rotas/fazer-agendamento/fazer-agendamento.component";
import { CriarOrdemServicoComponent } from "./components/ordemservico/criar-ordem-servico/criar-ordem-servico.component";
import { ListarOrdemServicoComponent } from "./components/ordemservico/listar-ordem-servico/listar-ordem-servico.component";
import { ListarAgendamentoComponent } from "./components/config-rotas/listar-agendamento/listar-agendamento.component";
import { OsClassificacaoComponent } from "./components/ordemservico/os-classificacao/os-classificacao.component";
import { OsTipoComponent } from "./components/ordemservico/os-tipo/os-tipo.component";
import { EquipamentoEstadosComponent } from "./components/ordemservico/equipamento-estados/equipamento-estados.component";
import { OsSintomasComponent } from "./components/ordemservico/os-sintomas/os-sintomas.component";
import { OsCausasComponent } from "./components/ordemservico/os-causas/os-causas.component";
import { ListOsAgendadosComponent } from "./components/ordemservico/list-os-agendados/list-os-agendados.component";
import { ListEstadoConservacaoComponent } from "./components/equipamentos/list-estado-conservacao/list-estado-conservacao.component";
import { ListCriticidadeComponent } from "./components/equipamentos/list-criticidade/list-criticidade.component";
import { ListTipoAquisicaoComponent } from "./components/equipamentos/list-tipo-aquisicao/list-tipo-aquisicao.component";
import { CreateOrEditEquipamentoComponent } from "./components/ordemservico/create-or-edit-equipamento/create-or-edit-equipamento.component";
import { OsCategoriaComponent } from "./components/ordemservico/os-categoria/os-categoria.component";
import { ListaOsTrabalhoComponent } from "./components/ordemservico/lista-os-trabalho/lista-os-trabalho.component";
import { ExecutarOrdemServicoComponent } from "./components/ordemservico/executar-ordem-servico/executar-ordem-servico.component";
import { DetalheAgendamentoComponent } from "./components/ordemservico/detalhe-agendamento/detalhe-agendamento.component";
import { EditOrdemAgendamentoComponent } from "./components/ordemservico/edit-ordem-agendamento/edit-ordem-agendamento.component";
import { UnidadesComponent } from "./components/stock/unidades/unidades.component";
import { ConfigHorasComponent } from "./components/config-hora/config-horas/config-horas.component";
import { HistoricoFacturacaoChargeComponent } from "./components/facturacao/historico-facturacao/historico-facturacao.component";
import { ProcessarLeiturasComponent } from "./components/config-rotas/processar-leituras/processar-leituras.component";
import { RhEmpresasComponent } from "./components/recurso-humano/rh-empresas/rh-empresas.component";
import { CobrancaProdutoComponent } from './components/relatorios/financeiro/cobranca-produto/cobranca-produto.component';
import { OtTriggerComponent } from "./components/ordemservico/ot-trigger/ot-trigger.component";
import { MotivoAlteracaoLeituraComponent } from "./components/config-rotas/leituras/motivo-alteracao-leitura/motivo-alteracao-leitura.component";
import { TipificacaoAnomaliasComponent } from "./components/tipificacao-anomalias/tipificacao-anomalias.component";
import { OpenlayersComponent } from "./components/ordemservico/openlayers/openlayers.component";
import { TipoAnomaliaComponent } from "./components/config-rotas/leituras/tipo-anomalia/tipo-anomalia.component";
import { HistoricosFacturacaoComponent } from "./components/facturacao/historicos-facturacao/historicos-facturacao.component";
import { EditarOrdemTrabalhoComponent } from "./components/ordemservico/editar-ordem-trabalho/editar-ordem-trabalho.component";
import { ResumoCobrancaArtigoComponent } from "./components/relatorios/resumo-cobranca-artigo/resumo-cobranca-artigo.component";
import { FacturaCicloIndividualComponent } from "./components/facturacao/factura-ciclo-individual/factura-ciclo-individual.component";
import { ConfiTipoFacturacaoComponent } from "./components/configuracao/confi-tipo-facturacao/confi-tipo-facturacao.component";
import { ResumoFacturacaoComponent } from './components/relatorios/financeiro/resumo-facturacao/resumo-facturacao.component';
import { TarifariioContratoComponent } from "./components/tarifario/tarifariio-contrato/tarifariio-contrato.component";
import { EstruturaMoradasComponent } from "./components/estrutura-moradas/estrutura-moradas.component";
import { ConfigurarAnomaliasComponent } from "./components/config-rotas/configurar-anomalias/configurar-anomalias.component";
import { CentroCustoComponent } from "./components/centro-custo/centro-custo.component";
import { MpContadorComponent } from "./components/manutencao_preventiva/mp-contador/mp-contador.component";
import { PlanoManutencaoComponent } from "./components/manutencao_preventiva/plano-manutencao/plano-manutencao.component";
import { MpEstadoPlanoManutencaoComponent } from "./components/manutencao_preventiva/mp-estado-plano-manutencao/mp-estado-plano-manutencao.component";
import { ClienteMenssagemComponent } from "./components/config-crm/clientes-mensagens/cliente-menssagem.component";
import { AlterarTitularidadeComponent } from "./components/contrato/alterar-titularidade/alterar-titularidade.component";

import { ListaGeralIntervencoesComponent } from "./components/manutencao_preventiva/lista-geral-intervencoes/lista-geral-intervencoes.component";
import { ClientesSemRotasComponent } from "./components/config-rotas/clientes-sem-rotas/clientes-sem-rotas.component";
import { MpCalendarioTarefasComponent } from "./components/manutencao_preventiva/mp-calendario-tarefas/mp-calendario-tarefas.component";

import { ConfNotificacaoComponent } from "./components/conf-notificacao/conf-notificacao.component";
import { RelatorioAdiantamentosComponent } from "./components/relatorios/relatorio-adiantamentos/relatorio-adiantamentos.component";
import { RelatorioLeiturasRealizadasComponent } from "./components/relatorios/relatorio-leituras-realizadas/relatorio-leituras-realizadas";
import { RelatorioDividasComponent } from "./components/relatorios/relatorio-dividas/relatorio-dividas.component";
import { ReportManutencaoComponent } from "./components/layout/menu-report/report-manutencao/report-manutencao.component";
import { LigarRamalsComponent } from "./components/ligar-ramals/ligar-ramals.component";
import { ListarRamalsComponent } from "./components/listar-ramals/listar-ramals.component";
import { PressaoNominalComponent } from "./components/pressao-nominal/pressao-nominal.component";
import { SubsistemaComponent } from "./components/subsistema/subsistema.component";
import { TipoRamalComponent } from "./components/tipo-ramal/tipo-ramal.component";
import { EditarOtPreventivaComponent } from "./components/ordemservico/editar-ot-preventiva/editar-ot-preventiva.component";
import { ExecutarOtPreventivaComponent } from "./components/ordemservico/executar-ot-preventiva/executar-ot-preventiva.component";
import { DetalheAgendamentoPreventivaComponent } from "./components/ordemservico/detalhe-agendamento-preventiva/detalhe-agendamento-preventiva.component";
import { AgendarOtPreventivaComponent } from "./components/ordemservico/agendar-ot-preventiva/agendar-ot-preventiva.component";
import { EstadoNovaLigacaoComponent } from './components/nova-ligacao/estado/estado-nova-ligacao.component'
import { MotivoLigacaoComponent } from "./components/nova-ligacao/motivo-ligacao/motivo-ligacao.component";
import { TipoLigacaoComponent } from "./components/nova-ligacao/tipo-ligacao/tipo-ligacao.component";
import { RegistroNovaLigacaoComponent } from "./components/nova-ligacao/registro-nova-ligacao/registro-nova-ligacao.component";
import { ConsumiveisComponent } from "./components/manutencao_preventiva/consumiveis/consumiveis.component";
import { FerramentasComponent } from "./components/manutencao_preventiva/ferramentas/ferramentas.component";
import { PeriodicidadeEmDiaComponent } from "./components/manutencao_preventiva/periodicidade-em-dia/periodicidade-em-dia.component";
import { DuracaoEmHoraComponent } from "./components/manutencao_preventiva/duracao-em-hora/duracao-em-hora.component";
import { EspecialistaComponent } from "./components/manutencao_preventiva/especialista/especialista.component";
import { AgendarOsMassivaComponent } from "./components/ordemservico/agendar-os-massiva/agendar-os-massiva.component";
import { ExecutarOsMassivaComponent } from "./components/ordemservico/executar-os-massiva/executar-os-massiva.component";
import { ConfNotificacaoNovasLigacoesComponent } from "./components/conf-notificacao-novas-ligacoes/conf-notificacao-novas-ligacoes.component";
import { NumeroFacturasTipoFactutracao } from "./components/relatorios/financeiro/numero-facturas-por-tipo-facturacao/numero-facturas-por-tipo-facturacao";
import { NumeroContratosFacturadosFacturas } from "./components/relatorios/financeiro/numero-contratos-facturados-facturas/numero-contratos-facturados-facturas";
import { NumeroContratosCobradosFactura } from "./components/relatorios/financeiro/numero-contratos-cobrados-recibos/numero-contratos-cobrados-recibos";
import { MotivoComponent } from "./components/comunicacao/motivo/motivo.component";
import { LogsReferenciaEmisComponent } from "./components/logs-referencia-emis/logs-referencia-emis.component";
import { LogsDeNotificacoesComponent } from "./components/logs-de-notificacoes/logs-de-notificacoes.component";
import { TipoAvisoCortesComponent } from "./components/tipo-aviso-cortes/tipo-aviso-cortes.component";
import { ConfigAvisoCorteComponent } from "./components/config-aviso-corte/config-aviso-corte.component";
import { RelatorioIvaComponent } from "./components/relatorios/relatorio-iva/relatorio-iva.component";
import { OtListagemConsumivelComponent } from './components/ordemservico/ot-listagem-consumivel/ot-listagem-consumivel.component';
import { ConfMotivosNaoLeiturasComponent } from './components/config-rotas/conf-motivos-nao-leituras/conf-motivos-nao-leituras.component';
import { FacturacaoCaucaoComponent } from './components/facturacao/facturacao-caucao/facturacao-caucao.component';
import { RelatorioContadoresComponent } from './components/relatorios/relatorio-contadores/relatorio-contadores.component';
import { DevoulcaoComponent } from './components/devoulcao/devoulcao.component';
import { ListagemDevolucaoComponent } from './components/devoulcao/listagem-devolucao/listagem-devolucao.component';
import { RelatorioCaucaoComponent } from './components/relatorios/financeiro/relatorio-caucao/relatorio-caucao.component';
import { TriggerSequenciaComponent } from './components/ordemservico/trigger-sequencia/trigger-sequencia.component';
import { ListagemEstornoComponent } from './components/recibo/listagem-estorno/listagem-estorno.component';
import { LogOtFiscalizacaoComponent } from './components/log-ot-fiscalizacao/log-ot-fiscalizacao.component';
import { DevolucaoEstimativaComponent } from './components/devoulcao/devolucao-estimativa/devolucao-estimativa.component';
import { ListagemEstimativaComponent } from './components/devoulcao/listagem-estimativa/listagem-estimativa.component';
import { LogGestaoDividaComponent } from './components/log-gestao-divida/log-gestao-divida.component';
import { LogsContenciososComponent } from './components/logs-contenciosos/logs-contenciosos.component';
import { EstadoContenciosoComponent } from './components/estado-contencioso/estado-contencioso.component';
import { DespesasContenciosoComponent } from './components/despesas-contencioso/despesas-contencioso.component';
import { LogComunicacaoComponent } from './components/log-comunicacao/log-comunicacao.component';
import { ListarFacturacaoIncobravelComponent } from './components/listar-facturacao-incobravel/listar-facturacao-incobravel.component';
import { RelatorioOrdemServicoComponent } from './components/relatorios/relatorio-ordem-servico/relatorio-ordem-servico.component';
import { AnulacaoRecebimentosComponent } from './components/caixa/anulacao-recebimentos/anulacao-recebimentos.component';
import { GestaoZmcComponent } from './components/gestao-zmc/gestao-zmc.component';

const routes: Routes = [
    {
        path: "",
        redirectTo: "dashboard",
        pathMatch: "full"
    },
    {
        path: "404",
        component: P404Component,
        data: {
            title: "Page 404"
        }
    },
    {
        path: "500",
        component: P500Component,
        data: {
            title: "Page 500"
        }
    },
    {
        path: "403",
        component: P403Component,
        data: {
            title: "Erro 403"
        }
    },
    {
        path: "login",
        component: LoginComponent,
        data: {
            title: "Login Page"
        }
    },
    {
        path: "password-first-access",
        component: RedefinirPasswordFirstAcessComponent
    },
    {
        path: "reportDiarioAuto",
        component: ReportDiarioAutoComponent
    },
    { path: "test", component: TestPaginationComponent },

    //novas ligações
    {
        path: "nova-ligacao",
        children: [
            {
                path: "registro-ligacao",
                component: RegistroNovaLigacaoComponent,
                canActivate: [AuthGuard],
            },
            {
                path: "estado",
                component: EstadoNovaLigacaoComponent,
                canActivate: [AuthGuard],
            },
            {
                path: "motivo-alteracao",
                component: MotivoLigacaoComponent,
                canActivate: [AuthGuard],
            },
            {
                path: "tipo-ligacao",
                component: TipoLigacaoComponent,
                canActivate: [AuthGuard],
            }
        ]
    },
    //
    //comunicacao
    {
        path: 'caucao',
        children: [
            { path: 'devolucao', component: DevoulcaoComponent }
        ]
    },
    {
        path: "comunicacao",
        children: [
            {
                path: "categoria",
                component: CategoriaComponent,
                canActivate: [AuthGuard],
            },
            {
                path: "niveis",
                component: NivelComponent,
                canActivate: [AuthGuard],
            },
            {
                path: "tipos",
                component: TipoComponent,
                canActivate: [AuthGuard],
            },
            {
                path: "estado",
                component: EstadoComponent,
                canActivate: [AuthGuard],
            },
            {
                path: "motivo",
                component: MotivoComponent,
                canActivate: [AuthGuard],
            },
            {
                path: "origem",
                component: OrigemComponent,
                canActivate: [AuthGuard],
            },
            {
                path: "registro",
                component: RegistroComponent,
                canActivate: [AuthGuard],
            },
        ]
    },
    //

    //Ordem serviço
    {
        path: "ordemservico",
        children: [
            {
                path: "estado",
                component: OrdemEstadoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "ot_estado" }
            },
            {
                path: "estados-equipamentos",
                component: EquipamentoEstadosComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "ot_estado_equipamento" }
            },
            {
                path: "sintomas",
                component: OsSintomasComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "ot_sintomas" }
            },
            {
                path: "causas",
                component: OsCausasComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "ot_causas" }
            },
            {
                path: "equipamento",
                component: OrdemEquipamentoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "ot_equipamento" }
            },
            {
                path: "massiva/execucao/:id/:agendamento_id",
                component: ExecutarOsMassivaComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "nova_ordem_trabalho" }
            },
            {
                path: "classificacao",
                component: OsClassificacaoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "ot_classificacao" }
            },
            {
                path: "categorias",
                component: OsCategoriaComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "ot_categoria" }
            },
            {
                path: "tipo",
                component: OsTipoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "ot_tipo" }
            },
            {
                path: "triggers",
                component: OtTriggerComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "ot_triggers" }
            },
            {
              path: "triggers-consequencia",
              component: TriggerSequenciaComponent,
              canActivate: [AuthGuard],
              data: { expectedPermission: "ot_triggers" }
            },
            {
                path: "prioridade",
                component: OrdemPrioridadeComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "ot_prioridade" }
            },
            {
                path: "nova",
                component: CriarOrdemServicoComponent,
                canActivate: [AuthGuard],
                data: {}
            },
            /* expectedPermission: "nova_ordem_trabalho" */
            {
                path: "agendar/:id",
                component: CriarOrdemServicoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "nova_ordem_trabalho" }
            },
            {
                path: "agendarMassiva/:id",
                component: AgendarOsMassivaComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "nova_ordem_trabalho" }
            },
            {
                path: "facturacao/:id",
                component: FacturacaoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "registar_facturacao" }
            },
            {
                path: "editar/:id",
                component: EditarOrdemTrabalhoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "editar_ordem_trabalho" }
            },
            {
                path: "agendamento/detalhes/:agendamento_id",
                component: DetalheAgendamentoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "nova_ordem_trabalho" }
            },
            {
                path: "agendamentoPreventiva/detalhes/:id/:agendamento_id",
                component: DetalheAgendamentoPreventivaComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "nova_ordem_trabalho" }
            },
            {
                path: "execucao/:id/:agendamento_id",
                component: ExecutarOrdemServicoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "nova_ordem_trabalho" }
            },
            {
              path: "execucao/:id/:agendamento_id/:os_tipo_id",
              component: ExecutarOrdemServicoComponent,
              canActivate: [AuthGuard],
              data: { expectedPermission: "nova_ordem_trabalho" }
            },
            {
                path: "editar/agendamento/:id/:agendamento_id",
                component: EditOrdemAgendamentoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "nova_ordem_trabalho" }
            },

            {
                path: "editar/agendamentoPreventiva/:id/:agendamento_id",
                component: EditarOtPreventivaComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "nova_ordem_trabalho" }
            },
            {
                path: "listagem",
                component: ListarOrdemServicoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "listagem_ordem_trabalho" }
            },
            {
                path: "listagem-ot-prevista",
                component: OtListagemConsumivelComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "listagem_ordem_trabalho" }
            },
            {
                path: "agendados",
                component: ListOsAgendadosComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "listagem_ot_agendadas" }
            },
            {
                path: "lista-trabalho",
                component: ListaOsTrabalhoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "listagem_ot_trabalho" }
            },
            {
                path: "openlayers",
                component: OpenlayersComponent,
                canActivate: [AuthGuard],
                data: {}
            }

        ]
    },
    //Caução
    {
        path: "caucao",
        children: [
            {
                path: 'devolucao',
                component: DevoulcaoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "menu_devolucao_caucao" }
            }
        ]
    },

    //Ordem Trabalho
    {
        path: "ordemTrabalho",
        children: [
            {
                path: "estado",
                component: OrdemEstadoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "ot_estado" }
            },
            {
                path: "estados-equipamentos",
                component: EquipamentoEstadosComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "ot_estado_equipamento" }
            },
            {
                path: "sintomas",
                component: OsSintomasComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "ot_sintomas" }
            },
            {
                path: "causas",
                component: OsCausasComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "ot_causas" }
            },
            {
                path: "preventiva/execucao/:id/:agendamento_id",
                component: ExecutarOtPreventivaComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "nova_ordem_trabalho" }
            },
            {
                path: "equipamento",
                component: OrdemEquipamentoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "ot_equipamento" }
            },
            {
                path: "classificacao",
                component: OsClassificacaoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "ot_classificacao" }
            },
            {
                path: "categorias",
                component: OsCategoriaComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "ot_categoria" }
            },
            {
                path: "tipo",
                component: OsTipoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "ot_tipo" }
            },
            {
                path: "triggers",
                component: OtTriggerComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "ot_triggers" }
            },
            {
                path: "prioridade",
                component: OrdemPrioridadeComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "ot_prioridade" }
            },
            {
                path: "nova",
                component: CriarOrdemServicoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "nova_ordem_trabalho" }
            },

            {
                path: "agendar/:id",
                component: CriarOrdemServicoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "nova_ordem_trabalho" }
            },
            {
                path: "agendarPreventiva/:id",
                component: AgendarOtPreventivaComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "nova_ordem_trabalho" }
            },
            {
                path: "editar/:id",
                component: EditarOrdemTrabalhoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "editar_ordem_trabalho" }
            },
            {
                path: "agendamento/detalhes/:agendamento_id",
                component: DetalheAgendamentoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "nova_ordem_trabalho" }
            },
            {
                path: "execucao/:id/:agendamento_id",
                component: ExecutarOrdemServicoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "nova_ordem_trabalho" }
            },
            {
                path: "editar/agendamento/:id/:agendamento_id",
                component: EditOrdemAgendamentoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "nova_ordem_trabalho" }
            },

            {
                path: "listagem",
                component: ListarOrdemServicoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "listagem_ordem_trabalho" }
            },
            {
                path: "agendados",
                component: ListOsAgendadosComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "listagem_ot_agendadas" }
            },
            {
                path: "lista-trabalho",
                component: ListaOsTrabalhoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "listagem_ot_trabalho" }
            },
            {
                path: "openlayers",
                component: OpenlayersComponent,
                canActivate: [AuthGuard],
                data: {}
            }

        ]
    },
    //

    //Ordem serviço
    {
        path: "ConfigHoras",
        children: [
            {
                path: "duracao",
                component: ConfigHorasComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "config_duracao" }
            }

        ]
    },
    //

    //Equipamento
    {
        path: "equipamento",
        children: [
            {
                path: "listagem",
                component: OrdemEquipamentoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "equipamento_listagem" }
            },
            {
                path: "novo",
                component: CreateOrEditEquipamentoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "equipamento_novo" }
            },

            {
                path: "estados",
                component: EquipamentoEstadosComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "equipamento_estados" }
            },

            {
                path: "editar/:id",
                component: CreateOrEditEquipamentoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "equipamento_novo" }
            },

            {
                path: "estado-conservacao",
                component: ListEstadoConservacaoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "equipamento_estado_conservacao" }
            },

            {
                path: "grau-criticidade",
                component: ListCriticidadeComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "equipamento_grau_criticidade" }
            },

            {
                path: "tipo-aquisicao",
                component: ListTipoAquisicaoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "equipamento_tipo_aquisicao" }
            }

        ]
    },
    //

    //MANUTENÇÃO PREVENTIVA
    {
        path: "manutencao-preventiva",
        children: [
            {
                path: "contador",
                component: MpContadorComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "mp-config-contador" }
            },
            {
                path: "estados",
                component: MpEstadoPlanoManutencaoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "mp-config-estados" }
            },
            {
                path: "Plano-manutencao",
                component: PlanoManutencaoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "plano_manutencao" }
            },

            {
                path: "Intervencoes",
                component: ListaGeralIntervencoesComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "mp_listar_intervencoes" }
            },

            {
                path: "Calendario-tarefas",
                component: MpCalendarioTarefasComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "mp_visulizar_calendario" }
            },

            {
                path: "Consumiveis",
                component: ConsumiveisComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "mp_visulizar_calendario" }
            },

            {
                path: "Ferramentas",
                component: FerramentasComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "mp_visulizar_calendario" }
            },
            {
                path: "Periodiciade-dias",
                component: PeriodicidadeEmDiaComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "mp_visulizar_calendario" }
            },
            {
                path: "Duracao-horas",
                component: DuracaoEmHoraComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "mp_visulizar_calendario" }
            },
            {
                path: "Especialistas",
                component: EspecialistaComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "mp_visulizar_calendario" }
            }

        ]
    },
    //

    {
        path: "",
        children: [
            {
                path: "listarFacturacao",
                component: ListarFacturacaoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "listar_facturacao" }
            },
            {
              path: "listarFacturacaoIncobravel",
              component: ListarFacturacaoIncobravelComponent,
              canActivate: [AuthGuard],
              data: { expectedPermission: "listar_facturacao" }
            },

            {
                path: "listaDeIncumprimento",
                component: ListaIncumprimentoComponent,
                canActivate: [AuthGuard]
                //data: { expectedPermission: "lista_incumprimentos" }
            },
            {
              path: "criarPlanoPrestacao",
              component: CriarPlanoPrestacaoComponent,
              canActivate: [AuthGuard],
              data: { expectedPermission: "menu_gestao_de_plano_de_prestacao" }
          },
          {
            path: "criarPlanoPrestacao/:contrato_id/:conta_id/:cliente_id",
            component: CriarPlanoPrestacaoComponent,
            canActivate: [AuthGuard],
            data: { expectedPermission: "menu_gestao_de_plano_de_prestacao" }
        },
          {
            path: "listar-plano-prestacao",
            component: ListarPlanoPrestacaoComponent,
            canActivate: [AuthGuard],
            data: { expectedPermission: "menu_gestao_de_plano_de_prestacao" }
        },

            {
                path: "listar-entidade-cativadoras",
                component: EntidadeCativadoraComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "entidade_cativadora" }
            },
            {
                path: "relatorio",
                children: [
                    {
                        path: "financeira",
                        canActivate: [AuthGuard],
                        data: { expectedPermission: "relatorios" },
                        children: [
                            {
                                path: "dashboard",
                                component: RelatorioFinanceiroComponent,
                                canActivate: [AuthGuard],
                                data: { expectedPermission: "reporter_leituras" }
                            },
                            {
                                path: "resumo-cobranca-por-artigo",
                                component: ResumoCobrancaArtigoComponent,
                                canActivate: [AuthGuard],
                                data: { expectedPermission: "reporter_cobranca" }
                            },
                            {
                              path: "relatorio-caucao",
                              component: RelatorioCaucaoComponent,
                              canActivate: [AuthGuard],
                              data: { expectedPermission: "relatorio_de_caucao" }
                            },
                            {
                                path: "facturacao-detalhada-pre-pago/:name",
                                component: ReportCotrancaGlobalComponent,
                                canActivate: [AuthGuard],
                                data: { expectedPermission: "relatorios" }
                            },
                            {
                                path: "facturacao-detalhada-pos-pago/:name",
                                component: ReportCotrancaGlobalComponent,
                                canActivate: [AuthGuard],
                                data: { expectedPermission: "relatorios" }
                            },
                            {
                                path: "facturacao-detalhada",
                                component: FacturacaoDetalhadaPosPagoComponent,
                                canActivate: [AuthGuard],
                                data: { expectedPermission: "reporter_facturacao" }
                            },
                            {
                                path: "orcamento-detalhado",
                                component: OrcamentoDetalhadoComponent,
                                canActivate: [AuthGuard],
                                data: { expectedPermission: "reporter_facturacao" }
                            },
                            {
                                path: "facturacao-detalhada-produto",
                                component: FacturacaoDetalhadaProdutoComponent,
                                canActivate: [AuthGuard],
                                data: { expectedPermission: "reporter_facturacao" }
                            },
                            {
                                path: "facturacao-Por-Gestor-pre-pago/:name",
                                component: ReportFacturacaoGestorComponent,
                                canActivate: [AuthGuard],
                                data: { expectedPermission: "relatorios" }
                            },
                            {
                                path: "facturacao-Por-Gestor-pos-pago/:name",
                                component: ReportFacturacaoGestorComponent,
                                canActivate: [AuthGuard],
                                data: { expectedPermission: "relatorios" }
                            },


                            {
                                path: 'facturacao-paga-pre-pago/:name',
                                component: ReportPagamentoGlobalComponent,
                                canActivate: [AuthGuard], data: { expectedPermission: 'relatorios' }
                            }, {
                                path: 'facturacao-paga-pos-pago/:name',
                                component: ReportPagamentoGlobalComponent,
                                canActivate: [AuthGuard], data: { expectedPermission: 'relatorios' }
                            },
                            {
                                path: 'lojas',

                                component: ReportLojaComponent,
                                canActivate: [AuthGuard],
                                data: { expectedPermission: "reporter_agencia" }
                            },
                            {
                                path: "movimentos-de-caixa",
                                component: ReportMovimentosCaixaComponent,
                                canActivate: [AuthGuard],
                                data: { expectedPermission: "reporter_agencia" }
                            },
                            {
                                path: "numero-facturas-tipo-facturacao",
                                component: NumeroFacturasTipoFactutracao,
                                canActivate: [AuthGuard],
                                data: { expectedPermission: "reporter_facturacao" }
                            },
                            {
                                path: "numero-contratos-facturados",
                                component: NumeroContratosFacturadosFacturas,
                                canActivate: [AuthGuard],
                                data: { expectedPermission: "reporter_facturacao" }
                            },

                            {
                                path: "numero-contratos-cobrados",
                                component: NumeroContratosCobradosFactura,
                                canActivate: [AuthGuard],
                                data: { expectedPermission: "reporter_cobranca" }
                            },

                            {
                                path: "venda-Por-Lojas-Por-Produto",
                                component: ReportVendaLojaProdutoComponent,
                                canActivate: [AuthGuard],
                                data: { expectedPermission: "reporter_agencia" }
                            },
                            {
                                path: "iva",
                                component: ReportIVAComponent,
                                canActivate: [AuthGuard],
                                data: { expectedPermission: "relatorios" }
                            },
                            {
                                path: "resumo-iva",
                                component: RelatorioIvaComponent,
                                canActivate: [AuthGuard],
                                data: { expectedPermission: "reporter_cobranca" }
                            },
                            {
                                path: "resumo-conta-corrente",
                                component: ReportResumoContaCorrenteComponent,
                                canActivate: [AuthGuard],
                                data: { expectedPermission: "reporter_cobranca" }
                            },
                            {
                                path: "cobranca-produto",
                                component: CobrancaProdutoComponent,
                                canActivate: [AuthGuard],
                                data: { expectedPermission: "reporter_cobranca" }
                            },
                            {
                                path: "resumo-conta",
                                component: ResumoContaComponent,
                                canActivate: [AuthGuard],
                                data: { expectedPermission: "reporter_cobranca" }
                            },
                            {
                                path: "forma-pagamento",
                                component: ReportFormaPagamentoComponent,
                                canActivate: [AuthGuard],
                                data: { expectedPermission: "reporter_cobranca" }
                            },
                            {
                                path: "servicos-contradados",
                                component: ReportServicosContratadosComponent,
                                canActivate: [AuthGuard],
                                data: { expectedPermission: "relatorios" }
                            },
                            {
                                path: "resumo-facturacao",
                                component: ResumoFacturacaoComponent,
                                canActivate: [AuthGuard],
                                data: { expectedPermission: "reporter_facturacao" }
                            },

                        ]
                    },
                    {
                        path: "clientes",
                        component: ClienteRelatorioComponent,
                        canActivate: [AuthGuard],
                        data: { expectedPermission: "reporter_cliente" }
                    },
                    {
                        path: "resumo-contratos",
                        component: RelatorioResumoContratosComponent,
                        canActivate: [AuthGuard],
                        data: { expectedPermission: "reporter_cliente" }
                    },
                    {
                        path: "dividas",
                        component: RelatorioDividasComponent,
                        canActivate: [AuthGuard],
                        data: { expectedPermission: "reporter_divida" }
                    },
                    {
                        path: "adiantamentos",
                        component: RelatorioAdiantamentosComponent,
                        canActivate: [AuthGuard],
                        data: { expectedPermission: "reporter_adiantamento" }
                    },
                    {
                        path: "contadores",
                        component: RelatorioContadoresComponent,
                        canActivate: [AuthGuard],
                        data: { expectedPermission: "reporter_contadores" }
                    },
                    //"reporter_contadores"
                    {
                        path: "leituras-realizadas",
                        component: RelatorioLeiturasRealizadasComponent,
                        canActivate: [AuthGuard],
                        data: { expectedPermission: "reporter_leituras" }
                    },
                    {
                        path: "manutencao",
                        component: ReportManutencaoComponent,
                        canActivate: [AuthGuard],
                        data: { expectedPermission: "reporter_ordem_trabalho" }
                    },
                    {
                        path: "recebimentos",
                        component: ReportRecebimentoComponent,
                        canActivate: [AuthGuard],
                        data: { expectedPermission: "reporter_recibimento" }
                    },
                    {
                      path: "ordem_servico",
                      component: RelatorioOrdemServicoComponent,
                      canActivate: [AuthGuard],
                      data: { expectedPermission: "" }
                  }
                ]
            },
            {
                path: "vendas",
                children: [
                    {
                        path: "facturacao",
                        canActivate: [AuthGuard],
                        data: { expectedPermission: "facturacao_automatica" },
                        children: [
                            {
                                path: "historico-facturacao",
                                canActivate: [AuthGuard],
                                component: HistoricosFacturacaoComponent,
                                data: { expectedPermission: "facturacao_automatica" },
                            },

                            {
                                path: "facturacao-automatica",
                                canActivate: [AuthGuard],
                                data: { expectedPermission: "facturacao_automatica" },
                                children: [
                                    {
                                        path: "facturacao-final",
                                        component: FacturacaoAutomaticaComponent,
                                        canActivate: [AuthGuard],
                                        data: { expectedPermission: "facturacao_automatica" }
                                    },
                                    {
                                        path: "facturacao-pre",
                                        component: PreFacturacaoComponent,
                                        canActivate: [AuthGuard],
                                        data: { expectedPermission: "facturacao_automatica" }
                                    },
                                    {
                                        path: "listar-pre-facturacao",
                                        component: FacturacaoChargeComponent,
                                        canActivate: [AuthGuard],
                                        data: { expectedPermission: "facturacao_automatica" }
                                    }
                                ]
                            },
                            {
                                path: "pdv-ponto-venda",
                                component: PontoVendaComponent,
                                canActivate: [AuthGuard]
                            },

                        ]
                    }
                ]
            },
            {
                path: "crm",
                canActivate: [AuthGuard],
                data: { title: "crm", expectedPermission: "crm" },
                children: [
                    {
                        path: "cliente",
                        canActivate: [AuthGuard],
                        data: { title: "clientes", expectedPermission: "crm" },
                        children: [
                            {
                                path: "conta-corrente/:id/:name",
                                component: ContaCorrenteComponent,
                                canActivate: [AuthGuard]
                            },
                            {
                                path: "listar-clientes",
                                component: TerceiroComponent,
                                canActivate: [AuthGuard],
                                data: {
                                    title: "Lista de clientes",
                                    expectedPermission: "listar_clientes"
                                }
                            }
                        ]
                    }
                ]
            }, {
                path: 'config',
                children: [
                    {
                        path: 'controle-acesso',
                        children: [
                            {
                                path: 'utilizador',
                                children: [
                                    {
                                        path: 'listar', component: UtilizadorComponent,
                                        canActivate: [AuthGuard],
                                        data: { expectedPermission: 'listar_utilizadores' }
                                    },
                                    {
                                        path: 'perfil/:id/:name', component: PerfilComponent,
                                        canActivate: [AuthGuard],
                                        data: { expectedPermission: 'listar_utilizadores' }
                                    }
                                ]
                            },
                        ]
                    }
                ]
            },


            {
                path: "lista-de-generos",
                component: GeneroComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "lista-dos-tipos-de-identificacoes",
                component: TipoIdentificacaoComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "gestores-de-contas",
                component: GestorContaComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "tipos-de-clientes",
                component: TipoClienteComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "tipos-de-clientes-mensagem",
                component: ClienteMenssagemComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "tipos-de-classificacoes",
                component: ClassificacaoProdutoComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "confTipoConsumo",
                component: ConfiTipoFacturacaoComponent,
                canActivate: [AuthGuard]
            },



            { path: 'leitura-contador', component: LeituraContradorComponent, canActivate: [AuthGuard] },
            { path: 'facturacao/emitir-nota-de-credito/:id', component: EmitirNotaCreditoComponent, canActivate: [AuthGuard], data: { expectedPermission: 'nota_credito_factura' } },
            { path: 'facturacao/emitir-nota-de-credito-ciclo/:id', component: EmitirNotaCreditoCicloComponent, canActivate: [AuthGuard], data: { expectedPermission: 'nota_credito_factura' } },
            { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
            { path: 'servicos', component: ServicoComponent, canActivate: [AuthGuard] },
            { path: 'vendas', component: VendaComponent, canActivate: [AuthGuard] },
            { path: 'clientes', component: TerceiroComponent, canActivate: [AuthGuard], data: { expectedPermission: 'listar_clientes' } },
            { path: 'unificarClientes', component: UnificarclienteComponent, canActivate: [AuthGuard], data: { expectedPermission: 'unificar_clientes' } },
            { path: 'clientesUnificados', component: ClientesunificadosComponent, canActivate: [AuthGuard], data: { expectedPermission: 'concluir_unificacao' } },
            { path: 'facturacao', component: FacturacaoComponent, canActivate: [AuthGuard], data: { expectedPermission: 'registar_facturacao' } },
            { path: 'facturacao-caucao/:contrato_id/:cliente_id', component: FacturacaoCaucaoComponent, canActivate: [AuthGuard], data: { expectedPermission: 'registar_facturacao' } },
            { path: 'vendas/recibo/gera-recibo', component: ReciboComponent, canActivate: [AuthGuard], data: { expectedPermission: 'gerar_recibos' } },

            { path: 'produtos', component: ProdutoComponent, canActivate: [AuthGuard], data: { expectedPermission: 'listar_produtos' } },
            { path: 'contabilidade', component: ContabilidadeComponent, canActivate: [AuthGuard] },
            { path: 'recursosHumanos', component: RecursoHumanoComponent, canActivate: [AuthGuard] },
            { path: 'compras', component: CompraComponent, canActivate: [AuthGuard], data: { expectedPermission: 'listar_compras' } },
            { path: 'pagamentos', component: PagamentoComponent, canActivate: [AuthGuard] },
            { path: 'artigo/criar', component: CriarArtigoComponent, canActivate: [AuthGuard] },
            { path: 'artigo/listar', component: ListarArtigoComponent, canActivate: [AuthGuard] },
            { path: 'utilizadores', component: UtilizadorComponent, canActivate: [AuthGuard], data: { expectedPermission: 'listar_utilizadores' } },
            { path: 'fornecedores', component: FornecedorComponent, canActivate: [AuthGuard] },
            { path: 'listarProdutos', component: ListarProdutosComponent, canActivate: [AuthGuard], data: { expectedPermission: 'listar_produtos' } },
            { path: 'impostos', component: ImpostoComponent, canActivate: [AuthGuard], data: { expectedPermission: 'listar_impostos' } },
            { path: 'documentos', component: DocumentoComponent, canActivate: [AuthGuard], data: { expectedPermission: 'listar_documentos' } },
            { path: 'empresa', component: EmpresaComponent, canActivate: [AuthGuard], data: { expectedPermission: 'configurar_empresa' } },
            { path: 'tipoReclamacoes', component: TipoReclamacoesComponent, canActivate: [AuthGuard], data: { expectedPermission: 'listar_tipo_reclamacao' } },
            { path: 'recursosRede', component: RecursosRedeComponent, canActivate: [AuthGuard], data: { expectedPermission: 'recursos_rede' } },
            { path: 'prioridades', component: PrioridadeComponent, canActivate: [AuthGuard], data: { expectedPermission: 'listar_prioridades' } },
            { path: 'listarReclamacoes', component: ReclamacoesComponent, canActivate: [AuthGuard], data: { expectedPermission: 'listar_reclamacoes' } },
            { path: 'Depositos', component: ListaDepositosComponent, canActivate: [AuthGuard], data: { expectedPermission: 'listar_depositos' } },
            { path: 'listarPedidos', component: PedidosComponent, canActivate: [AuthGuard], data: { expectedPermission: 'listar_pedidos' } },
            { path: 'Dispositivos', component: DispositivosComponent, canActivate: [AuthGuard], data: {} },
            { path: 'InvRedeNumeracao', component: NumeracaoInventarioRedeComponent, canActivate: [AuthGuard], data: {} },
            { path: 'Tecnologias', component: TecnologiasComponent, canActivate: [AuthGuard], data: { expectedPermission: 'listar_tecnologias' } },
            { path: 'Contadores', component: ContadoresComponent, canActivate: [AuthGuard], data: { expectedPermission: 'listar_contadores' } },
            { path: 'Rotas', component: RotaHeaderComponent, canActivate: [AuthGuard], data: { expectedPermission: 'listar_rotas' } },
            { path: 'clientes-sem-rotas', component: ClientesSemRotasComponent, canActivate: [AuthGuard], data: { expectedPermission: 'listar_rotas' } },
            { path: 'Roteiros', component: ListagemRoteirosComponent, canActivate: [AuthGuard], data: { expectedPermission: 'listar_rotas' } },
            { path: 'listagem-agendamentos', component: ListarAgendamentoComponent, canActivate: [AuthGuard], data: { expectedPermission: 'listar_rotas' } },
            { path: 'estadosReclamacao', component: EstadoReclamacoesComponent, canActivate: [AuthGuard], data: { expectedPermission: 'estado_reclamacoes' } },
            { path: 'estadosPedido', component: EstadoPedidosComponent, canActivate: [AuthGuard], data: { expectedPermission: 'estado_pedidos' } },
            { path: 'tiposPedido', component: TiposPedidoComponent, canActivate: [AuthGuard], data: { expectedPermission: 'tipos_pedido' } },
            { path: 'tarifarios', component: TarifarioComponent, canActivate: [AuthGuard], data: {} },

            { path: 'contrato', component: ContratoComponent, canActivate: [AuthGuard], data: { expectedPermission: 'registar_contrato' } },
            { path: 'centro-custo', component: CentroCustoComponent, canActivate: [AuthGuard], data: { expectedPermission: 'registar_contrato' } },
            {
                path: "contratos/New/:conta_id",
                component: ContratoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: 'registar_contrato' }
            },
            {
              path: "contratos/New/:conta_id/:nova_ligacao_id",
              component: ContratoComponent,
              canActivate: [AuthGuard],
              data: { expectedPermission: 'registar_contrato' }
           },
            {
                path: "contratos/Update/:id/:conta_id/:cliente_id/:tarifario_id/:classe_tarifario_id",
                component: ContratoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: 'registar_contrato' }
            },

            { path: 'planoPrecos', component: PlanoPrecoComponent, canActivate: [AuthGuard], data: {} },
            { path: 'enviofacturasEmail', component: BillRunHeaderComponent, canActivate: [AuthGuard], data: { expectedPermission: 'envio_factura_email' } },
            { path: 'envioReportDiario', component: EnvioRelatorioDiarioComponent, canActivate: [AuthGuard], data: { expectedPermission: 'envio_report_diario' } },
            { path: 'vendas/facturacao/charge', component: FacturacaoChargeComponent, canActivate: [AuthGuard], data: { expectedPermission: 'charge_facturacao' } },

            { path: 'saft', component: SaftComponent, canActivate: [AuthGuard], data: { expectedPermission: 'gerar_saft' } },
            { path: 'stockMovimento', component: StockMovimentoComponent, canActivate: [AuthGuard], data: { expectedPermission: 'stock_movimento' } },
            { path: 'contaCorrente', component: ContaCorrenteComponent, canActivate: [AuthGuard], data: { expectedPermission: 'conta_corrente' } },
            { path: 'permissions', component: PermissionsComponent, canActivate: [AuthGuard], data: { expectedPermission: 'listar_permissions' } },
            { path: 'roles', component: RoleComponent, canActivate: [AuthGuard], data: { expectedPermission: 'listar_roles' } },
            { path: 'modulos', component: ModulosComponent, canActivate: [AuthGuard], data: { expectedPermission: 'listar_modulos' } },
            { path: 'stock', component: StockComponent, canActivate: [AuthGuard] },
            { path: 'inventariacao', component: InventarioComponent, canActivate: [AuthGuard], data: { expectedPermission: 'listar_inventarios' } },
            { path: 'armazem', component: ArmazemComponent, canActivate: [AuthGuard], data: { expectedPermission: 'listar_armazens' } },
            { path: 'orcamento', component: OrcamentoComponent, canActivate: [AuthGuard], data: { expectedPermission: 'listar_orcamentos' } },
            { path: 'listarOrcamento', component: ListaOrcamentoComponent },
            { path: 'business-intelligence', component: BiComponent, canActivate: [AuthGuard], data: { expectedPermission: 'business_intelligence' } },
            { path: 'tarefa', component: TarefaComponent, canActivate: [AuthGuard], data: { expectedPermission: 'tarefas' } },
            { path: 'produtos-fornecedores', component: ProdutoFornecedorComponent, canActivate: [AuthGuard] },
            { path: 'moedas', component: MoedaComponent, canActivate: [AuthGuard], data: { expectedPermission: 'listar_moedas' } },
            { path: 'bancos', component: BancoComponent, canActivate: [AuthGuard], data: { expectedPermission: 'listar_bancos' } },
            { path: 'projectos', component: ProjectoComponent, canActivate: [AuthGuard], data: { expectedPermission: 'listar_projectos' } },
            { path: 'lojas', component: LojaComponent, canActivate: [AuthGuard], data: {} },
            { path: 'getPdfEmail/:id', component: GetPdfEmailComponent, canActivate: [AuthGuard], data: {} },
            { path: 'caixa/movimento', component: MovimentoCaixaComponent, canActivate: [AuthGuard], data: { expectedPermission: 'movimento_caixa' } },
            { path: 'caixa/anular/recebimentos', component: AnulacaoRecebimentosComponent, canActivate: [AuthGuard], data: { expectedPermission: 'movimento_caixa' } },
            { path: 'facturacao-automatica/facturar', component: FacturacaoAutomaticaComponent, canActivate: [AuthGuard], data: { expectedPermission: 'facturacao_automatica' } },
            { path: 'facturacao-automatica/loop', component: CicloFacturacaoComponent, canActivate: [AuthGuard], data: { expectedPermission: 'facturacao_automatica' } },
            { path: 'listarRecibos', component: ConsultarReciboComponent, canActivate: [AuthGuard], data: { expectedPermission: 'listar_recibos' } },
            { path: 'listarEstorno', component: ListagemEstornoComponent, canActivate: [AuthGuard], data: { expectedPermission: 'registar_estorno_de_pagamento' } },
            { path: 'caucao/listarDevolucao', component: ListagemDevolucaoComponent, canActivate: [AuthGuard], data: { expectedPermission: 'menu_devolucao_caucao' } },
            { path: 'devolucao/listarEstimativa', component: ListagemEstimativaComponent, canActivate: [AuthGuard], data: { expectedPermission: 'menu_devolucao_estimativa' } },
            { path: 'devolucao/estimativa', component: DevolucaoEstimativaComponent, canActivate: [AuthGuard], data: { expectedPermission: 'menu_devolucao_estimativa' } },
            { path: 'relatorio/pedidos', component: PedidosRelatorioComponent, canActivate: [AuthGuard], data: { expectedPermission: 'relatorios' } },
            { path: 'relatorio/reclamacoes', component: ReclamacoesRelatorioComponent, canActivate: [AuthGuard], data: { expectedPermission: 'relatorios' } },

            {
                path: "reporting_e_bi",
                component: MenuReportComponent,
                canActivate: [AuthGuard]
            },

            {
                path: "config_estrutura_morada",
                component: EstruturaMoradasComponent,
                canActivate: [AuthGuard]
            },

            {
                path: "Configuracao",
                component: ConfiguracaoComponent,
                canActivate: [AuthGuard],
                data: {}
            },

            {
                path: "ContextoConfiguracao",
                component: ContextoConfiguracaoComponent,
                canActivate: [AuthGuard],
                data: {}
            },

            {
                path: "ConfContaBancaria",
                component: ConfContaBancariaComponent,
                /*                 canActivate: [AuthGuard],
                                data: { expectedPermission: "configurar_tipo_servico" } */
            },

            {
                path: "ConfDadosFacturacao",
                component: ConfDadosFacturacaoComponent,
                /*                 canActivate: [AuthGuard],
                                data: { expectedPermission: "configurar_tipo_servico" } */
            },

            {
                path: "ConfTipoServico",
                component: ConfEstadoServicoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "configurar_tipo_servico" }
            },

            {
                path: "ConfTipoRamal",
                component: ConfTipoRamalComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "configurar_tipo_ramal" }
            },

            {
                path: "ConfEstadoTarifario",
                component: ConfEstadoTarifarioComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "gestao_tarifario" }
            },

            {
                path: "ConfCaudal",
                component: ConfCaudalComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "configurar_contador" }
            },

            {
                path: "ConfCalibre",
                component: ConfCalibreComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "configurar_contador" }
            },

            {
                path: "ConfTipoContrato",
                component: ConfTipoContratoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "configurar_tipo_contrato" }
            },

            {
                path: "ListarContrato",
                component: ListarContratoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "listar_contratos" }
            },


            {
                path: 'ConfDireccao',
                component: DireccaoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "configurar_direccao" }
            },

            {
                path: "ConfLojas",
                component: LojaComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "configurar_loja" }
            },

            {
                path: "ConfBancos",
                component: BancosComponent,
                /*                 canActivate: [AuthGuard], */
            },

            {
                path: 'ConfSeries',
                component: SerieComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: 'listar_series' }
            },
            {
                path: "config-notificacao",
                component: ConfNotificacaoComponent,
                canActivate: [AuthGuard],
            },
            {
                path: "config-notificacao-novaLigacoes",
                component: ConfNotificacaoNovasLigacoesComponent,
                canActivate: [AuthGuard],
            },
            {
                path: "ConfEstadoContador",
                component: ConfEstadoContadorComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "configurar_contador" }
            },
            {
                path: 'ConfMarca',
                component: ConfMarcaComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: 'configurar_contador' }
            },
            {
                path: 'ConfModelo',
                component: ConfModeloComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: 'configurar_contador' }
            },
            {
                path: 'ConfMedicao',
                component: ConfMedicaoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: 'configurar_contador' }
            },
            {
                path: 'ConfClassePrecisao',
                component: ConfClassePrecisaoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: 'configurar_contador' }
            },
            {
                path: 'ConfFabricante',
                component: ConfFabricanteComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: 'configurar_contador' }
            },
            {
                path: 'ConfTipoContador',
                component: ConfTipoContadorComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: 'configurar_contador' }
            },
            {
                path: 'ConfTipoRegisto',
                component: ConfTipoRegistoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: 'configurar_contador' }
            },

            {
                path: 'confTipoOrdemServico',
                component: ConfTipoOrdemServicoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: 'configurar_tipo_ordem_trabalho' }
            },

            {
                path: 'confTipofacturacao',
                component: ConfTipoFacturacaoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: 'configurar_tipo_facturacao' }
            },

            {
                path: 'confTipoligacao',
                component: ConfTipoLigacaoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: 'configurar_tipo_ligacao' }
            },
            {
                path: 'confTipoSelo',
                component: ConfTipoSeloComponent,
                canActivate: [AuthGuard],
                data: {}
            },
            {
                path: 'confCorSelo',
                component: ConfCorSeloComponent,
                canActivate: [AuthGuard],
                data: {}
            },
            {
                path: 'confSelo',
                component: ConfSeloComponent,
                canActivate: [AuthGuard],
                data: {}
            },

            {
                path: 'confTipojuro',
                component: ConfTipoJuroComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: 'configurar_tipo_juro' }
            },

            {
                path: 'confObjectoContrato',
                component: ConfObjectoContratoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: 'configurar_objecto_contrato' }
            },

            {
                path: 'confMotivoDenuncia',
                component: ConfMotivoDenunciaComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: 'configurar_motivo_denuncia' }
            },
            {
                path: 'confformapagamento',
                component: ConfFormaPagamentoComponent,
                canActivate: [AuthGuard],
                /*                 data: { expectedPermission: 'configurar_forma_pagamento' } */
            },

            {
              path: 'tipoJuros',
              component: TipoJuroComponent,
              canActivate: [AuthGuard],
              /*                 data: { expectedPermission: 'configurar_forma_pagamento' } */
          },

            {
                path: 'confEstadoContrato',
                component: ConfEstadoContratoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: 'configurar_estado_contrato' }
            },

            {
                path: 'confTipoMensagem',
                component: ConfTipoMensagemComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: 'configurar_tipo_mensagem' }
            },
            {
                path: 'confTipoMedicao',
                component: ConfTipoMedicaoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: 'configurar_tipo_medicao' }
            },

            {
                path: 'confMesesConsumo',
                component: ConfMesesMediaConsumoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: 'configurar_meses_media_consumo' }
            },


            {
                path: 'confEstadoCicloFacturacao',
                component: ConfEstadoCicloFacturacaoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: 'configurar_estado_ciclo_faturacao' }
            },

            {
                path: 'confTipologiaCliente',
                component: ConfTipologiaClienteComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: 'configurar_tipologia_cliente' }
            },

            /*        {
                    path: 'confTiposTipologia',
                    component: ConfTiposTipologiaComponent,
                    canActivate: [AuthGuard],
                     data: { expectedPermission: 'configurar_tipo_facturacao' }
                    },*/

            {
                path: 'confTipoProduto',
                component: ConfTipoProdutoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: 'configurar_tipo_produto' }
            },

            {
                path: 'confNivelSensibilidade',
                component: ConfNivelSensibilidadeComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: 'configurar_nivel_sensibilidade' }
            },

            {
                path: 'confEstadoconta',
                component: ConfEstadoContaComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: 'configurar_estado_conta' }
            },

            {
                path: "LocalInstalacao",
                component: LocalInstalacaoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "gestao_local_instalacao" }
            },

            {
                path: "LocalConsumo",
                component: LocalConsumoComponent,
                canActivate: [AuthGuard],
            },


            {
                path: "ConfRua",
                component: ConfRuaComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "configurar_objecto_tecnico" }
            },

            {
                path: "ConfQuarteirao",
                component: ConfQuarteiraoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "configurar_objecto_tecnico" }
            },

            {
                path: "ConfBairro",
                component: ConfBairroComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "configurar_objecto_tecnico" }
            },

            {
                path: "ConfDistrito",
                component: ConfDistritoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "configurar_objecto_tecnico" }
            },

            {
                path: "ConfMunicipio",
                component: ConfMunicipioComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "configurar_objecto_tecnico" }
            },

            {
                path: "ConfProvincia",
                component: ConfProvinciaComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "configurar_objecto_tecnico" }
            },

            {
                path: "Ramal",
                component: RamalComponent,
                data: { expectedPermission: "gestao_ramal" }
            },

            {
                path: "Tarifario",
                component: ConfTarifarioComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "gestao_tarifario" }
            },

            {
                path: "Tarifario-contrato",
                component: TarifariioContratoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "gestao_tarifario" }
            },

            {
                path: "ProdutoClasseTarifario",
                component: ProdutoclasseTarifarioComponent,
                data: { expectedPermission: "gestao_tarifario" }
            },
            {
                path: "ClasseTarifario",
                component: ClasseTarifarioComponent,
                data: { expectedPermission: "gestao_tarifario" }
            },
            {
                path: "listarProdutos",
                component: ListarProdutosComponent,
                data: { expectedPermission: "listar_produtos" }
            },
            {
                path: "RegistoInterno",
                component: RegistoInternoComponent,
                data: { expectedPermission: "listar_registo_interno" }
            },
            {
                path: "RecursosHumanosDepartamento",
                component: RecursoHumanoComponent,
                data: { expectedPermission: "recursos_humanos_departamentos" }
            },
            {
                path: "RecursosHumanosColaboradores",
                component: ColaboradoresComponent,
                data: { expectedPermission: "recursos_humanos_colaboradores" }
            },
            {
                path: "EmpresasColaboradores",
                component: RhEmpresasComponent,
                data: { expectedPermission: "empresas_colaboradores" }
            },
            {
                path: "leitura-contador",
                component: LeituraContradorComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "facturacao/emitir-nota-de-credito/:id",
                component: EmitirNotaCreditoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "nota_credito_factura" }
            },
            {
                path: "dashboard",
                component: DashboardComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "servicos",
                component: ServicoComponent,
                canActivate: [AuthGuard]
            },
            { path: "vendas", component: VendaComponent, canActivate: [AuthGuard] },
            {
                path: "clientes",
                component: TerceiroComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "listar_clientes" }
            },
            {
                path: "unificarClientes",
                component: UnificarclienteComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "unificar_clientes" }
            },
            {
                path: "clientesUnificados",
                component: ClientesunificadosComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "concluir_unificacao" }
            },
            {
                path: "facturacao",
                component: FacturacaoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "registar_facturacao" }
            },
            {
                path: "vendas/recibo/gera-recibo",
                component: ReciboComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "gerar_recibos" }
            },
            {
                path: "relatorio/extraccao/clientes",
                component: ExtraccaoClientesComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "reporter_cliente" }
            },


            {
                path: "produtos",
                component: ProdutoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "listar_produtos" }
            },
            {
                path: "contabilidade",
                component: ContabilidadeComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "logsReferenciaEmis",
                component: LogsReferenciaEmisComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "tipoAvisoCortes",
                component: TipoAvisoCortesComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "config/avisoCortes",
                component: ConfigAvisoCorteComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "logsDeNotificacoes",
                component: LogsDeNotificacoesComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "logsOSFiscalizacao",
                component: LogOtFiscalizacaoComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "logsGestaoDivida",
                component: LogGestaoDividaComponent,
                canActivate: [AuthGuard]
            },
            {
              path: "logsContenciosos",
              component: LogsContenciososComponent,
              canActivate: [AuthGuard]
            },
            {
              path: "logsComunicacao",
              component: LogComunicacaoComponent,
              canActivate: [AuthGuard]
            },
            {
              path: "despesasContenciosos",
              component: DespesasContenciosoComponent,
              canActivate: [AuthGuard]
            },
            {
              path: "estado-contencioso",
              component: EstadoContenciosoComponent,
              canActivate: [AuthGuard]
            },

            {
                path: "recursosHumanos",
                component: RecursoHumanoComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "compras",
                component: CompraComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "listar_compras" }
            },
            {
                path: "pagamentos",
                component: PagamentoComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "artigo/criar",
                component: CriarArtigoComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "artigo/listar",
                component: ListarArtigoComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "utilizadores",
                component: UtilizadorComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "listar_utilizadores" }
            },
            {
                path: "fornecedores",
                component: FornecedorComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "listarProdutos",
                component: ListarProdutosComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "listar_produtos" }
            },
            {
                path: "impostos",
                component: ImpostoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "listar_impostos" }
            },
            {
                path: "documentos",
                component: DocumentoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "listar_documentos" }
            },
            {
                path: "empresa",
                component: EmpresaComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "configurar_empresa" }
            },
            {
                path: "tipoReclamacoes",
                component: TipoReclamacoesComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "listar_tipo_reclamacao" }
            },
            {
                path: "recursosRede",
                component: RecursosRedeComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "recursos_rede" }
            },
            {
                path: "prioridades",
                component: PrioridadeComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "listar_prioridades" }
            },
            {
                path: "listarReclamacoes",
                component: ReclamacoesComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "listar_reclamacoes" }
            },
            {
                path: "Depositos",
                component: ListaDepositosComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "listar_depositos" }
            },
            {
                path: "listarPedidos",
                component: PedidosComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "listar_pedidos" }
            },
            {
                path: "Tecnologias",
                component: TecnologiasComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "listar_tecnologias" }
            },
            {
                path: "estadosReclamacao",
                component: EstadoReclamacoesComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "estado_reclamacoes" }
            },
            {
                path: "estadosPedido",
                component: EstadoPedidosComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "estado_pedidos" }
            },
            {
                path: "tiposPedido",
                component: TiposPedidoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "tipos_pedido" }
            },
            {
                path: "series",
                component: SerieComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "listar_series" }
            },
            {
                path: "tarifarios",
                component: TarifarioComponent,
                canActivate: [AuthGuard],
                data: {}
            },
            {
                path: "contratos",
                component: ContratoComponent,
                canActivate: [AuthGuard],
                data: {}
            },
            {
                path: "planoPrecos",
                component: PlanoPrecoComponent,
                canActivate: [AuthGuard],
                data: {}
            },
            {
                path: "enviofacturasEmail",
                component: BillRunHeaderComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "envio_factura_email" }
            },
            {
                path: "envioReportDiario",
                component: EnvioRelatorioDiarioComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "envio_report_diario" }
            },
            {
                path: "vendas/facturacao/charge",
                component: FacturacaoChargeComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "charge_facturacao" }
            },

            {
                path: "saft",
                component: SaftComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "gerar_saft" }
            },
            {
                path: "stockMovimento",
                component: StockMovimentoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "stock_movimento" }
            },
            {
                path: "contaCorrente",
                component: ContaCorrenteComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "conta_corrente" }
            },
            {
                path: "permissions",
                component: PermissionsComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "listar_permissions" }
            },
            {
                path: "roles",
                component: RoleComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "listar_roles" }
            },
            {
                path: "modulos",
                component: ModulosComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "listar_modulos" }
            },
            { path: "stock", component: StockComponent, canActivate: [AuthGuard] },
            {
                path: "inventariacao",
                component: InventarioComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "listar_inventarios" }
            },
            {
                path: "stockArmazem",
                component: ArmazemComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "listar_stock_armazem" }
            },
            {
                path: "stockProdutos",
                component: StockProdutosComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "listar_stock_produtos" }
            },
            {
                path: "stockMaterialUnidades",
                component: UnidadesComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "listar_stock_unidades" }
            },
            {
                path: "stockCategoriaProdutos",
                component: StockCategoriaProdutosComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "listar_stock_categoria_produto" }
            },
            {
                path: "stockTipoMovimentos",
                component: StockTipoMovimentosComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "listar_stock_tipo_movimento" }
            },
            {
                path: "entradaStock",
                component: EntradaStockComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "entrada_stock" }
            },
            {
                path: "saidaStock",
                component: SaidaStockComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "saida_stock" }
            },
            {
                path: "movimentacao_stock",
                component: MovimentacaoStockComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "movimentacao_stock" }
            },
            {
                path: "stock_existente",
                component: StockExistenteComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "stock_existente" }
            },
            {
                path: "transferenciaStock",
                component: TransferenciaComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "transferencia_stock" }
            },
            {
                path: "orcamento",
                component: OrcamentoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "listar_orcamentos" }
            },
            { path: "listarOrcamento", component: ListaOrcamentoComponent },
            {
                path: "business-intelligence",
                component: BiComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "business_intelligence" }
            },
            {
                path: "tarefa",
                component: TarefaComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "tarefas" }
            },
            {
                path: "produtos-fornecedores",
                component: ProdutoFornecedorComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "moedas",
                component: MoedaComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "listar_moedas" }
            },
            {
                path: "bancos",
                component: BancoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "listar_bancos" }
            },
            {
                path: "projectos",
                component: ProjectoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "listar_projectos" }
            },
            {
                path: "getPdfEmail/:id",
                component: GetPdfEmailComponent,
                canActivate: [AuthGuard],
                data: {}
            },
            {
                path: "caixa/movimento",
                component: MovimentoCaixaComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "movimento_caixa" }
            },
            {
                path: "facturacao-automatica/loop",
                component: CicloFacturacaoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "facturacao_automatica" }
            },
            {
                path: "listarRecibos",
                component: ConsultarReciboComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "listar_recibos" }
            },
            {
                path: "listarDireccao",
                component: DireccaoComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "listar_direccao" }
            },
            {
                path: "relatorio/pedidos",
                component: PedidosRelatorioComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "relatorios" }
            },
            {
                path: "relatorio/reclamacoes",
                component: ReclamacoesRelatorioComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "relatorios" }
            },

            {
                path: "crm/interconexao/criar-parceria",
                component: CriarParceriaComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "gerar_recibos" }
            },
            {
                path: "TipoObjecto",
                component: TipoObjectoComponent,
                data: { expectedPermission: "gestao_ramal" }

            },
            {
                path: "gestao-zmc",
                component: GestaoZmcComponent,
                data: { expectedPermission: "gestao_ramal" }

            },
            {
                path: "tipo-ramal",
                component: TipoRamalComponent,
                data: { expectedPermission: "gestao_ramal" }

            },
            {
                path: "pressao-nominal",
                component: PressaoNominalComponent,
                data: { expectedPermission: "gestao_ramal" }

            },
            {
                path: "subsistema",
                component: SubsistemaComponent,
                data: { expectedPermission: "gestao_ramal" }

            },
            {
                path: "LigacaoRamal",
                component: LigacaoRamalComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "gestao_ramal" }

            },
            {
                path: "Listar-ramal",
                component: ListarRamalsComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "gestao_ramal" }

            },
            {
                path: "ObjectoLigacaoRamal",
                component: ObjectoLigacaoRamalComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "gestao_ramal" }

            },
            {
                path: "ConfEstadoRamal",
                component: ConfEstadoRamalComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "gestao_ramal" }

            },
            {
                path: "ConfDiametroRamal",
                component: ConfDiametroRamalComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "gestao_ramal" }

            },
            {
                path: "ConfMaterialRamal",
                component: ConfMaterialRamalComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "gestao_ramal" }

            },
            /*{
                path: "novaLigacao",
                component: NewLigacaoRamalComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "gestao_ramal" }

            },*/
            {
                path: "registar-ramal",
                component: LigarRamalsComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "gestao_ramal" }

            },
            {
                path: "crm/interconexao/listar-parceria",
                component: ListarParceriaComponent,
                canActivate: [AuthGuard],
                data: { expectedPermission: "gerar_recibos" }
            },

            {
                path: "NaoLeitura",
                component: NaoLeituraComponent,
                canActivate: [AuthGuard],
                data: {}
            },
            {
                path: "ConfTipoNaoLeitura",
                component: TipoNaoLeituraComponent,
                canActivate: [AuthGuard],
                data: {}
            },
            {
                path: "ConfMotivoAlteracaoLeitura",
                component: MotivoAlteracaoLeituraComponent,
                canActivate: [AuthGuard],
                data: {}
            },
            {
                path: "Ocorrencia",
                component: OcorrenciaComponent,
                canActivate: [AuthGuard],
                data: {}
            },
            {
                path: "ConfTipoOcorrencia",
                component: TipoOcorrenciaComponent,
                canActivate: [AuthGuard],
                data: {}
            },
            {
                path: "ConfTipoAnomalia",
                component: TipificacaoAnomaliasComponent,
                canActivate: [AuthGuard],
                data: {}
            },
            {
                path: "TipoAnomalia",
                component: TipoAnomaliaComponent,
                canActivate: [AuthGuard],
                data: {}
            },
            {
                path: "Regras-anomalias",
                component: ConfigurarAnomaliasComponent,
                canActivate: [AuthGuard],
                data: {}
            },
            {
                path: "Motivos-nao-leituras",
                component: ConfMotivosNaoLeiturasComponent,
                canActivate: [AuthGuard],
                data: {}
            },
            {
                path: "Caucao",
                component: CaucaoComponent,
                canActivate: [AuthGuard],
                data: {}
            }, {
                path: "criar-roteiros/:slug",
                component: CreateRoteiroComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "processar-leituras/:slug",
                component: ProcessarLeiturasComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "editar-roteiros/:id",
                component: EditarRoteiroComponent,
                canActivate: [AuthGuard]
            }
            , {
                path: "fazer-agendamento/:id",
                component: FazerAgendamentoComponent,
                canActivate: [AuthGuard]
            }
            , {
                path: "facturas/impressao-massiva",
                component: ImpressaoMassivaComponent,
                canActivate: [AuthGuard],
                data: {}
                //data: { expectedPermission: "impressao_massiva" }
            }, {
                path: "facturas/listar-impressao-massiva",
                component: ListarImpressaoMassivaComponent,
                canActivate: [AuthGuard],
                data: {}
                //data: { expectedPermission: "impressao_massiva" }
            }
            , {
                path: "facturas/ciclo-individual",
                component: FacturaCicloIndividualComponent,
                canActivate: [AuthGuard],
                data: {}
                //data: { expectedPermission: "impressao_massiva" }
            }






        ]
    },
    /*
    { path: "", redirectTo: '/login', pathMatch: 'full', canActivate: [LoginGuard] },
    { path: "login", component: LoginComponent /*, canActivate: [LoginGuard] *},
    //{ path: "two-tfa-auth", component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
    { path: "register", component: RegisterComponent, canActivate: [LoginGuard] },*/
    { path: "**", component: P404Component }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
        CommonModule,
        ReactiveFormsModule
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
export const routeComponents = [
    LoginComponent,
    DashboardComponent,
    ServicoComponent,
    VendaComponent,
    TerceiroComponent,
    FacturacaoComponent,
    PontoVendaComponent,
    AvencaComponent,
    ProdutoComponent,
    PagamentoComponent,
    ContabilidadeComponent,
    CompraComponent,
    RecursoHumanoComponent,
    RegisterComponent,
    CriarArtigoComponent,
    ListarArtigoComponent,
    FornecedorComponent,
    UtilizadorComponent,
    ListarProdutosComponent,
    ImpostoComponent,
    DocumentoComponent,
    SerieComponent,
    ListarFacturacaoComponent,
    ReciboComponent,
    NotaCreditoComponent,
    StockMovimentoComponent,
    ContaCorrenteComponent,
    PermissionsComponent,
    RoleComponent,
    ModulosComponent,
    InventarioComponent,
    StockComponent,
    ArmazemComponent,
    OrcamentoComponent,
    ListaOrcamentoComponent,
    BiComponent,
    TarefaComponent,
    ProdutoFornecedorComponent,
    TipoReclamacoesComponent,
    PrioridadeComponent,
    ListaIncumprimentoComponent
];
