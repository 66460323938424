import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../providers/auth/auth.service';
import { HttpService } from 'src/app/providers/http/http.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private auth: AuthService, private http: HttpService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const currentUser = this.auth.currentUserValue; 
    if (currentUser && currentUser.token.token) {
      if (!currentUser.user.alterPassword) {
        this.router.navigate(['/password-first-access']);
        return false;
      }

      const expectedPermission = route.data.expectedPermission;
      if (expectedPermission) {
        if (currentUser.permissions.includes(expectedPermission)) {
          return true;
        } else {
          this.router.navigate(['/403']);
          return false;
        }
      } else {
        return true;
      }
      // authorised so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login']);
    return false;
  }

}
