import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-conf-abastecimento-cil',
  templateUrl: './conf-abastecimento-cil.component.html',
  styleUrls: ['./conf-abastecimento-cil.component.css']
})
export class ConfAbastecimentoCilComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
