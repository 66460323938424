import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { FormControl } from '@angular/forms';
import { PedidoReportService } from 'src/app/components/report-at/pedido/pedido-report.service';



@Component({
  selector: 'app-unificarcliente',
  templateUrl: './unificarcliente.component.html',
  styleUrls: ['./unificarcliente.component.css']
})
export class UnificarclienteComponent implements OnInit {

  @ViewChild("search") search;
  @ViewChild("orderBy") orderBy;
  @ViewChild("searchData") searchData;
  @ViewChild("mostrarResultado") mostrarResultado;
  @ViewChild("orderAscOrDesc") orderAscOrDesc;
  checkbox1 = new FormControl('');
  radioButton = new FormControl('');

  private pagination = {
    total: null,
    perPage: 3,
    page: 1,
    lastPage: null,

    start: 1,
    end: 4,
    search: null,
    order: "created_at"
  };



  private items: any = [];
  private provincias: any = [];
  private prioridades: any = [];
  private tipos: any = [];
  private reclamacoes: any = [];

  private tipoClientes: any = [];
  private tipoIdentidades: any = [];
  private contas: any = [];
  private clienteContas: any = [];
  private contactos: any = [];
  private tarifarios: any = [];
  private servicos: any = [];
  private chaveServicos: any = [];
  private chaves: any = [];
  private lojas: any = [];
  private carregamentos: any = [];
  private pedidos: any = [];
  wimaxMessage: string = null;
  classMessage: string = null;
  cdmaMessage: string = null;
  cdmaClassMessage: string = null
  private isFinalizar: boolean = true;
  public cliente_manter: any;
  public cliente_unificar: Array<any> = [];
  public cliente_nome_unificar: Array<any>=[];
  public cliente_nome_manter=null;
  public CheckValue: any;
  private radioID=null;
  private DadosClienteUnficar={

  }

  constructor(
    private http: HttpService,
    private configService: ConfigService,
    private PedidoReportService: PedidoReportService
  ) {

  }

  ngOnInit() {this.getPageFilterData(1);}



  private listClientes() {

    this.configService.loaddinStarter('start');

    this.http.__call('unificar/getContas', this.http.filters).subscribe(

      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;
        this.items = Object(response).data.data;
        this.gerarPages();
        this.configService.loaddinStarter('stop');

      }
    );
  }







  /*
  private listarChaveServicos() {

    this.http.call_get('servico/chaveServicos', null).subscribe(
      response => {
        this.chaveServicos = Object(response).data;
      }
    );
  }

  */
  private pages = [];
  private nextProvPageNumber = 1;

  private gerarPages() {
    for (var i = 1; i <= this.pagination.lastPage; i++) {
      this.pages.push(i);
    }
  }
  private nextPage() {
    this.nextProvPageNumber++;
    if (this.nextProvPageNumber < 0) {
      this.nextProvPageNumber = this.pagination.page;
    }
    this.listClientes();

  }
  private prevPage() {
    this.nextProvPageNumber--
    if (this.nextProvPageNumber > 0) {
      this.listClientes();
      this.nextProvPageNumber = this.pagination.page;
    }
  }

  private refreshPaginate(page) {
    this.pagination.page = page;
    this.listClientes();
  }
  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
    this.listClientes();
  }


  /**
   * @name "get Retornar Impostos"
   * @descriptio "Esta Função permite Unific"
   */


  loadingServicoPosPago = false;
 dadosCliente
  UnificarClientes() {
    this.http.filters.search=""
    if (this.cliente_unificar.length ==0 || this.cliente_manter==null) {
      console.log("escolhe os dados que quer unificaar")
    }else{
    // fazer  unificar certo

    for(let i=0; i<this.cliente_unificar.length; i++){
      this.http.__call("unificar/clientes",{cliente_manter:this.cliente_manter, cliente_unificar:this.cliente_unificar[i], cliente_nome_manter:this.cliente_nome_manter, cliente_nome_unificar:this.cliente_nome_unificar[i]}).subscribe(response=>{
        this.listClientes();

      })

    }


    }

  }

  getClienteID(event, items: any) {
    if (this.cliente_unificar.length == 0) {
      this.cliente_unificar.push(items.id);
      this.cliente_nome_unificar.push(items.nome);
    } else {
      var validar = 0
      for (let i = 0; i < this.cliente_unificar.length; ++i) {
        if (this.cliente_unificar[i] === items.id) {
          this.cliente_unificar.splice(i, 1);
          this.cliente_nome_unificar.splice(i, 1);
          validar = 1;
        }
      }

      if (validar === 0) {
        this.cliente_unificar.push(items.id);
        this.cliente_nome_unificar.push(items.nome)
      }
    }
    console.log(this.cliente_unificar)
  }




  getCheckBox(event, items: any) {
    this.radioID = items.id
    this.cliente_nome_manter= items.nome;


    //console.log(event.target);
    for (let i = 0; i < this.cliente_unificar.length; i++) {
      if (items.id === this.cliente_unificar[i]) {

        this.cliente_unificar.splice(i, 1);
        this.cliente_nome_unificar.splice(i,1);


      } else {

      }



    }
    console.log(items.id)
    console.log(this.cliente_unificar)
  }

}

