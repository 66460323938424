import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/providers/config/config.service';
import { HttpService } from 'src/app/providers/http/http.service';

@Component({
  selector: 'app-ligacao-ramal',
  templateUrl: './ligacao-ramal.component.html',
  styleUrls: ['./ligacao-ramal.component.css']
})
export class LigacaoRamalComponent implements OnInit, OnDestroy {


  private residencia = {
    id: null,
    numero: null,
    building: null,
    building_id: null,
    nome: null,
    is_predio: null,
    rua_id: null,
    rua: null,
    bairro_id: null,
    bairro: null,
    has_distrito: false,
    municipio_id: null,
    distrito_id: null,
    provincia_id: null,
    is_active: null,
    user_id: null,
  };

  private ligacao_ramal = {
    id: null,
    ponto_a_id: null,
    ponto_b_id: null,
    ponto_a: null,
    ponto_b: null,
    comprimento: null,
    local_instalacao_id: null,
    profundidade: null,
    moradia_numero: null,
    predio_nome: null,
    tipo_objecto_tecnico: null,
    latitude: null,
    longitude: null
  }
  private predio_flag: boolean = false;
  private items: any = [];
  private ponto_a: any = [];
  private ponto_b: any = [];

  private provincias: any = [];
  private municipios: any[];
  private distritos: any[];
  private bairros: any[];
  private residencias: any[];

  private ruas: any[];
  private tipo_ramals: any[];
  private diametro_ramals: any[];
  private material_ramals: any[];
  private tipo_objectos: any = [];

  constructor(private http: HttpService, private configService: ConfigService) { }

  ngOnInit() {
    this.http.filters.search = null
    this.getPageFilterData(1);
    this.loadSelectOptions();

  }
  ngOnDestroy() {
    this.http.filters.orderBy = null
  }

  private loadSelectOptions() {
    this.getTiposRamal();
    this.selectBoxTipoRamals();
    this.selectBoxDiametroRamals();
    this.selectBoxMaterialRamals();
  }

  private getTiposRamal() {
    this.http
      .call_get("tipo-objecto-tecnico/selectBox", null)
      .subscribe((response) => {
        this.tipo_objectos = Object(response).data;
      });
  }

  private selectBoxTipoRamals() {
    this.http.call_get("tipo-ramal/selectBox", null).subscribe((response) => {
      this.tipo_ramals = Object(response);
    });
  }

  private selectBoxDiametroRamals() {
    this.http
      .call_get("diametro-ramal/selectBox", null)
      .subscribe((response) => {
        this.diametro_ramals = Object(response);
      });
  }

  private selectBoxMaterialRamals() {
    this.http
      .call_get("material-ramal/selectBox", null)
      .subscribe((response) => {
        this.material_ramals = Object(response);
      });
  }

  private ListarLigacaoRamal() {

    this.configService.loaddinStarter('start');

    this.http.__call('ligacao-ramal/listagem', { filters: this.http.filters }).subscribe(
      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.configService.loaddinStarter('stop');

      }
    );
  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1;
    this.ListarLigacaoRamal();
  }


  private register() {
    this.configService.loaddinStarter('start');
    if (
      (this.ligacao_ramal.ponto_a == "" || this.ligacao_ramal.ponto_a == null) ||
      (this.residencia.id == "" || this.residencia.id == null) ||
      (this.ligacao_ramal.ponto_b == "" || this.ligacao_ramal.ponto_b == null)) {
      this.configService.showAlert("O campo ponto_a é obrigatório", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    }
    else {

      this.http.__call('ligacao-ramal/create', {
        profundidade: this.ligacao_ramal.profundidade,
        comprimento: this.ligacao_ramal.comprimento,
        ponto_a_id: this.ligacao_ramal.ponto_a_id,
        ponto_b_id: this.ligacao_ramal.ponto_b_id,
        local_instalacao_id: this.residencia.id
      }).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
            this.configService.loaddinStarter('stop');
          }
          else {
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            /*  this.clearFormInputs(e); */
            this.ListarLigacaoRamal()
            this.configService.loaddinStarter('stop');
          }
        }
      )
    };
  }


  /*   private clearFormInputs(e) {
      e.target.elements[0].value = null;
      e.target.elements[1].value = null;
      e.target.elements[2].value = null;
    } */

  private refresh(id, ponto_a, ponto_b, comprimento, profundidade, local_instalacao_id,
    moradia_numero, predio_nome, tipo_objecto_tecnico, ponto_a_id, ponto_b_id) {
    this.ligacao_ramal.id = id;
    this.ligacao_ramal.ponto_a = ponto_a;
    this.ligacao_ramal.ponto_b = ponto_b;
    this.ligacao_ramal.comprimento = comprimento;
    this.ligacao_ramal.profundidade = profundidade;
    this.ligacao_ramal.local_instalacao_id = local_instalacao_id;
    this.ligacao_ramal.moradia_numero = moradia_numero,
      this.ligacao_ramal.predio_nome = predio_nome,
      this.ligacao_ramal.tipo_objecto_tecnico = tipo_objecto_tecnico
    this.ligacao_ramal.ponto_a_id = ponto_a_id,
      this.ligacao_ramal.ponto_b_id = ponto_b_id,
      this.getProvincias();
  }

  private editar() {
    this.configService.loaddinStarter('start');
    if (
      (this.ligacao_ramal.ponto_a == "" || this.ligacao_ramal.ponto_a == null) ||
      (this.residencia.id == "" || this.residencia.id == null) ||
      (this.ligacao_ramal.ponto_b == "" || this.ligacao_ramal.ponto_b == null)) {
      this.configService.showAlert("O campo ponto_a é obrigatórios", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    }
    else {
      this.http.__call('ligacao-ramal/update/' + this.ligacao_ramal.id, {
        profundidade: this.ligacao_ramal.profundidade,
        comprimento: this.ligacao_ramal.comprimento,
      }).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          }
          else {
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.ListarLigacaoRamal();

          }
        }
      );
    }
    this.configService.loaddinStarter('stop');
  }

  private getProvincias() {
    this.http.call_get('provincia/selectBox', null).subscribe(
      response => {
        this.provincias = Object(response).data
      }
    );
  }


  private selectBoxMunicipiosByProvincia() {

    //console.log(this.rua);

    this.http.call_get('municipio/getMunicipiosByProvincia/' + this.residencia.provincia_id, null).subscribe(
      response => {
        //console.log(Object(response).data);

        this.municipios = Object(response).data;
      }
    );
  }

  private selectBoxDistritosByMunicipio() {

    for (let i = 0; i < this.municipios.length; ++i) {
      if (this.municipios[i].id == this.residencia.municipio_id)
        this.residencia.has_distrito = this.municipios[i].has_distrito;
    }

    if (this.residencia.has_distrito) {
      this.http.call_get('distrito/getDistritosByMunicipio/' + this.residencia.municipio_id, null).subscribe(
        response => {

          this.distritos = Object(response).data;
        }
      );
    }

    if (!this.residencia.has_distrito) {
      this.selectBoxBairrosByMunicipio();
    }

  }

  private selectBoxRuasByBairro() {

    //console.log(this.residencia);

    this.http.call_get('rua/selectBoxByBairro/' + this.residencia.bairro_id, null).subscribe(
      response => {


        this.ruas = Object(response);
      }
    );
  }

  private selectBoxBairrosByMunicipio() {
    //console.log(this.residencia);

    this.http.call_get('bairro/selectBoxByMunicipio/' + this.residencia.municipio_id, null).subscribe(
      response => {

        this.bairros = Object(response);
      }
    );
  }

  private getSelectBoxResidenciasByRua() {

    this.residencia.rua_id;
    this.getResidencia();
  }

  view_residencia = false;


  private getResidencia() {

    if (this.residencia.nome == "") {
      this.setNullResidencia();
    }

    this.view_residencia = true;
    this.http.__call('local-instalacao/getSelectLocalInstalacaosByRua', { rua_id: this.residencia.rua_id, start: 1, end: 10, search: this.residencia.numero }).subscribe(
      response => {
        this.residencias = Object(response).data.data;

        this.configService.loaddinStarter('stop');
      }
    );
  }

  private setResidencia(codigo, numero, nome, andar, is_predio) {
    this.residencia.id = codigo;
    this.residencia.numero = numero;
    this.residencia.nome = ((is_predio) ? 'Prédio ' + nome + ', ' + (andar ? andar + ' º Andar - ' : '') + 'Porta ' : 'Residência ') + numero;
    this.residencia.is_predio = is_predio;
    this.view_residencia = false;

  }

  private setNullResidencia() {
    this.residencia.id = null;
    this.residencia.numero = null;
    this.residencia.nome = null;
    this.residencia.is_predio = null,
      this.view_residencia = false;

    this.predio_flag = false;
  }

  view_ponto_a = false;
  private getPontoA() {

    if (this.ligacao_ramal.ponto_a == "" || this.ligacao_ramal.ponto_a == null) {
      this.setNullPontoA();
    }

    this.view_ponto_a = true;
    this.http.__call('objecto-ligacao-ramal/selectBoxSearchPontoA', { ponto_b_id: this.ligacao_ramal.ponto_b_id, start: 1, end: 10, search: this.ligacao_ramal.ponto_a }).subscribe(
      response => {
        this.ponto_a = Object(response).data.data;

        this.configService.loaddinStarter('stop');
      }
    );
  }

  private setPontoA(id, descricao) {
    this.ligacao_ramal.ponto_a_id = id;
    this.ligacao_ramal.ponto_a = descricao;


    this.view_ponto_a = false;

  }

  private setNullPontoA() {
    this.ligacao_ramal.ponto_a_id = null;
    this.ligacao_ramal.ponto_a = null;
    this.view_ponto_a = false;

  }

  view_ponto_b = false;
  private getPontoB() {

    if (this.ligacao_ramal.ponto_b == "" || this.ligacao_ramal.ponto_b == null) {
      this.setNullPontoB();
    }

    this.view_ponto_b = true;
    this.http.__call('objecto-ligacao-ramal/selectBoxSearchPontoB', { ponto_a_id: this.ligacao_ramal.ponto_a_id, start: 1, end: 10, search: this.ligacao_ramal.ponto_b }).subscribe(
      response => {
        this.ponto_b = Object(response).data.data;

        this.configService.loaddinStarter('stop');
      }
    );
  }

  private setPontoB(id, descricao) {
    this.ligacao_ramal.ponto_b_id = id;
    this.ligacao_ramal.ponto_b = descricao;


    this.view_ponto_b = false;

  }

  private setNullPontoB() {
    this.ligacao_ramal.ponto_b_id = null;
    this.ligacao_ramal.ponto_b = null;
    this.view_ponto_b = false;

  }
  private ini() {
    this.ligacao_ramal = {
      id: null,
      ponto_a_id: null,
      ponto_b_id: null,
      ponto_a: null,
      ponto_b: null,
      comprimento: null,
      local_instalacao_id: null,
      profundidade: null,
      moradia_numero: null,
      predio_nome: null,
      tipo_objecto_tecnico: null,
      latitude: null,
      longitude: null
    }
    this.getProvincias();
  }
}
