import { Injectable } from '@angular/core';
import { Container, removePercent, setGlobalHeight } from '../../../services/styled-pdf'
import { toDate, format } from 'date-fns-tz'
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class MovimentoRecebimentoPdfService {

  constructor() { }

  printMovimentoRecebimento(dados: any) {
    console.log('dados listagem =>', dados)
    // You'll need to make your image into a Data URL
    // Use http://dataurl.net/#dataurlmaker
    let CurrentDate = new Date();
    let imgData = dados.empresa.logotipo;
    let dateTime = "" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + ""
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')

    let totalPage = [1]
    let container = new Container();

    function img(imgData, container) {
      let style = {
        marginTop: "2%",
        width: "25%",
        height: "20%",
        marginLeft: "3%",
      };
      container.Image(imgData, style);
    }
    img(imgData, container);

    function title(container) {

      container.p('FOLHA DE CAIXA DO DIA 23/04/2022',
        {
          fontWeigth: 'bold',
          fontSize: 10,
          marginTop: '9%',
          marginLeft: '65%'
        }
      )
    }
    title(container)

    function header(data, container) {
      let style = {
        companyTitle: {
          marginTop: '16%',
          marginLeft: '3%',
          fontWeigth: 'bold',
          fontSize: 8,
        },
        streat: {
          marginTop: '18%',
          marginLeft: '3%',
          fontWeigth: 'bold',
          fontSize: 8,
        },
        nif: {
          marginTop: '20%',
          marginLeft: '3%',
          fontWeigth: 'bold',
          fontSize: 8,
          value: {
            marginTop: '20%',
            marginLeft: '7%',
            fontWeigth: 'bold',
            fontSize: 8
          }
        },
        phone: {
          marginTop: '22%',
          marginLeft: '3%',
          fontWeigth: 'bold',
          fontSize: 8,
          value: {
            marginTop: '22%',
            marginLeft: '11%',
            fontWeigth: 'bold',
            fontSize: 8
          }
        },
        fax: {
          marginTop: '22%',
          marginLeft: '24%',
          fontWeigth: 'bold',
          fontSize: 8,
          value: {
            marginTop: '22%',
            marginLeft: '29%',
            fontWeigth: 'bold',
            fontSize: 8
          }
        },
      }
      container.p('' + dados.empresa.companyName, style.streat)
      container.p('' + dados.empresa.addressDetail, style.companyTitle)
      container.p('NIF:', style.nif)
      container.p('' + dados.empresa.taxRegistrationNumber, style.nif.value)
      container.p('Telefone:', style.phone)
      container.p('' + dados.empresa.telefone, style.phone.value)
      container.p('Fax:', style.fax)
      container.p('' + dados.empresa.taxRegistrationNumber, style.fax.value)
    }
    header('', container)

    function bordInfo(data, container) {

      container.div({
        marginLeft: '45.5%',
        width: '35%',
        heigth: '13%',
        padding: '15%',
        border: [0, 0, 0]
      })
      container.p('Referente à Agência: ', {
        marginTop: '15%',
        marginLeft: '67%',
        fontSize: 8,
        fontWeigth: 'bold'
      })

      container.p('Nº: ' + dados.loja.id, {
        marginTop: '17%',
        marginLeft: '67%',
        fontSize: 8,
        fontWeigth: 'bold'
      })

      container.p('' + dados.resumoCaixa[0].loja, {
        marginTop: '18.5%',
        marginLeft: '67%',
        fontSize: 8,
        fontWeigth: 'bold'
      })

      container.p('Utilizador: ' + (dados.users.length == 1 ? dados.users[0].nome : 'Todos'), {
        marginTop: '20%',
        marginLeft: '67%',
        fontSize: 8,
        fontWeigth: 'bold',
        width: 60
      })

    }
    bordInfo('', container)

    function footer(page = 1) {

      container.p(`Página ${page} de ${totalPage[0]}`, {
        fontWeigth: 'bold',
        fontSize: 7,
        marginTop: '93%',
        marginLeft: '90%',
      })

      container.div({
        marginLeft: '2%',
        width: '35%',
        heigth: '80%',
        padding: '15%',
        border: [0, 0, 0]
      })
      container.p('Totais por meios de pagamento:', {
        marginTop: '82%',
        marginLeft: '4%',
        fontSize: 8,
        fontWeigth: 'bold'
      })

      container.hr({
        marginRight: '38%',
        marginLeft: '3%',
        top: '83%',
        bottom: '83%'
      })

      let header = removePercent('82.5%') + 2
      let content = removePercent('28%') + 2

      for (let i = 0; i < dados.resumoCaixaPagamento.data.length; i++) {
        let item = dados.resumoCaixaPagamento.data
        container.p(item[i].forma_paga + ' | docs: ' + item[i].total_documento + ' | val: ' + item[i].valor_recebido, {
          marginTop: header + '%',
          marginLeft: '4%',
          fontSize: 8,
          fontWeigth: 'normal'
        })
        header += 2
      }


      container.p('Todos os pagamentos | docs: ' + dados.resumoCaixaPagamento.total[0].total_documento + ' | val: ' + dados.resumoCaixaPagamento.total[0].valor_recebido, {
        marginTop: '88.5%',
        marginLeft: '4%',
        fontSize: 8,
        fontWeigth: 'normal'
      })

      container.div({
        marginLeft: '2%',
        width: '35%',
        heigth: '92%',
        padding: '5%',
        border: [0, 0, 0],
        bgColor: [201, 201, 201],
      })

      container.p('Total de caixa: ', {
        marginTop: '94%',
        marginLeft: '4%',
        fontSize: 8,
        fontWeigth: 'bold'
      })
      container.p('' + dados.resumoCaixaPagamento.countRecibo, {
        marginTop: '94%',
        marginLeft: '30%',
        fontSize: 8,
        fontWeigth: 'bold'
      })

      container.hr({
        marginRight: '100%',
        marginLeft: '65%',
        top: '86%',
        bottom: '86%'
      })

      container.p('(confirmo aceitação folha de caixa)', {
        marginTop: '87.5%',
        marginLeft: '74%',
        fontSize: 8,
        fontWeigth: 'normal'
      })

      container.p('Impresso no dia ' + dateTime, {
        marginTop: '95%',
        marginLeft: '74%',
        fontSize: 8,
        fontWeigth: 'normal'
      })
    }
    footer()

    function fistTable(data, container) {

      container.div({
        marginLeft: '2%',
        width: '8%',
        heigth: '26%',
        padding: '4%',
        border: [0, 0, 0],
        bgColor: [201, 201, 201],
      })
      container.p('Descrição', {
        marginTop: '28%',
        marginLeft: '4%',
        fontSize: 8,
        fontWeigth: 'bold'
      })

      container.div({
        marginLeft: '7.7%',
        width: '15%',
        heigth: '26%',
        padding: '4%',
        border: [0, 0, 0],
        bgColor: [201, 201, 201],
      })
      container.p('Documento', {
        marginTop: '28%',
        marginLeft: '14%',
        fontSize: 8,
        fontWeigth: 'bold'
      })

      container.div({
        marginLeft: '18.3%',
        width: '14.2%%',
        heigth: '26%',
        padding: '4%',
        border: [0, 0, 0],
        bgColor: [201, 201, 201],
      })
      container.p('Valor', {
        marginTop: '28%',
        marginLeft: '28%',
        fontSize: 8,
        fontWeigth: 'bold'
      })

      container.div({
        marginLeft: '28.3%',
        width: '15%',
        heigth: '26%',
        padding: '4%',
        border: [0, 0, 0],
        bgColor: [201, 201, 201],
      })
      container.p('Método Pagamento', {
        marginTop: '28%',
        marginLeft: '42%',
        fontSize: 8,
        fontWeigth: 'bold'
      })

      container.div({
        marginLeft: '38.8%',
        width: '14.2%',
        heigth: '26%',
        padding: '4%',
        border: [0, 0, 0],
        bgColor: [201, 201, 201],
      })
      container.p('Nº Cliente', {
        marginTop: '28%',
        marginLeft: '57%',
        fontSize: 8,
        fontWeigth: 'bold'
      })

      container.div({
        marginLeft: '48.8%',
        width: '30%',
        heigth: '26%',
        padding: '4%',
        border: [0, 0, 0],
        bgColor: [201, 201, 201],
      })
      container.p('Nome Cliente', {
        marginTop: '28%',
        marginLeft: '72%',
        fontSize: 8,
        fontWeigth: 'bold'
      })

      let header = removePercent('26%') + 2.9
      let content = removePercent('28%') + 2.9
      console.log('items =>', data)
      for (let i = 0; i < data.length; i++) {

        container.div({
          marginLeft: '2%',
          width: '8%',
          heigth: (header) + '%',
          padding: '6%',
          border: [245, 245, 245],
        })
        container.p((data[i] || {}).documento, {
          marginTop: (content) + '%',
          marginLeft: '4%',
          fontSize: 6,
          fontWeigth: 'normal'
        })

        container.div({
          marginLeft: '7.7%',
          width: '15%',
          heigth: (header) + '%',
          padding: '6%',
          border: [245, 245, 245],
        })
        container.p('' + (data[i] || {}).recibo_sigla, {
          marginTop: (content) + '%',
          marginLeft: '12%',
          fontSize: 6,
          fontWeigth: 'normal'
        })

        container.div({
          marginLeft: '18.3%',
          width: '14.2%',
          heigth: (header) + '%',
          padding: '6%',
          border: [245, 245, 245],
        })
        container.p('' + (data[i] || {}).total, {
          marginTop: (content) + '%',
          marginLeft: '28%',
          fontSize: 6,
          fontWeigth: 'normal'
        })

        container.div({
          marginLeft: '28.3%',
          width: '15%',
          heigth: (header) + '%',
          padding: '6%',
          border: [245, 245, 245],
        })
        container.p('' + (data[i] || {}).forma_paga, {
          marginTop: (content) + '%',
          marginLeft: '42%',
          fontSize: 6,
          fontWeigth: 'normal'
        })

        container.div({
          marginLeft: '38.8%',
          width: '14.2%',
          heigth: (header) + '%',
          padding: '6%',
          border: [245, 245, 245],
        })
        container.p('' + (data[i] || {}).cliente_id, {
          marginTop: (content) + '%',
          marginLeft: '59%',
          fontSize: 6,
          fontWeigth: 'normal'
        })

        container.div({
          marginLeft: '48.8%',
          width: '30%',
          heigth: (header) + '%',
          padding: '6%',
          border: [245, 245, 245],
        })

        container.p((data[i] || {}).nome_cliente, {
          marginTop: (content) + '%',
          marginLeft: '72%',
          fontSize: 6,
          fontWeigth: 'normal',
          width: 50
        })


        content += 4
        header += 4
        if (header >= 70) {
          container.newPageMiddle()
          header = 4
          content = 5.5
          totalPage[0]++
          footer(totalPage[0])
        }
      }
      container.setGlobalHeight(header)

    }
    let data = [
      {
        data: '20220614', documento: 'RV - Factura', numberCode: '9518607917',
        valor_aberto: 'matondo pedro antonio antonio', valor_original: '855,87', periodo_fact: 'matondo pedro antonio',
        entidade: '602', reference: '151921905'
      },
      {
        data: '20220614', documento: 'RV - Factura', numberCode: '9518607917',
        valor_aberto: '855,87', valor_original: '855,87', periodo_fact: '05/2022',
        entidade: '602', reference: '151921905'
      },
      {
        data: '20220614', documento: 'RV - Factura', numberCode: '9518607917',
        valor_aberto: '855,87', valor_original: '855,87', periodo_fact: '05/2022',
        entidade: '602', reference: '151921905'
      },
      {
        data: '20220614', documento: 'RV - Factura', numberCode: '9518607917',
        valor_aberto: '855,87', valor_original: '855,87', periodo_fact: 'matondo pedro antonio antonio pedro antonio antonio',
        entidade: '602', reference: '151921905'
      },
      {
        data: '20220614', documento: 'RV - Factura', numberCode: '9518607917',
        valor_aberto: '855,87', valor_original: '855,87', periodo_fact: 'matondo pedro antonio antonio pedro antonio antonio',
        entidade: '602', reference: '151921905'
      },
      {
        data: '20220614', documento: 'RV - Factura', numberCode: '9518607917',
        valor_aberto: '855,87', valor_original: '855,87', periodo_fact: '05/2022',
        entidade: '602', reference: '151921905'
      },
      {
        data: '20220614', documento: 'RV - Factura', numberCode: '9518607917',
        valor_aberto: '855,87', valor_original: '855,87', periodo_fact: '05/2022',
        entidade: '602', reference: '151921905'
      },
      {
        data: '20220614', documento: 'RV - Factura', numberCode: '9518607917',
        valor_aberto: '855,87', valor_original: '855,87', periodo_fact: '05/2022',
        entidade: '602', reference: '151921905'
      },
      {
        data: '20220614', documento: 'RV - Factura', numberCode: '9518607917',
        valor_aberto: '855,87', valor_original: '855,87', periodo_fact: '05/2022',
        entidade: '602', reference: '151921905'
      },
      {
        data: '20220614', documento: 'RV - Factura', numberCode: '9518607917',
        valor_aberto: '855,87', valor_original: '855,87', periodo_fact: '05/2022',
        entidade: '602', reference: '151921905'
      },
      {
        data: '20220614', documento: 'RV - Factura', numberCode: '9518607917',
        valor_aberto: '855,87', valor_original: '855,87', periodo_fact: '05/2022',
        entidade: '602', reference: '151921905'
      },
      {
        data: '20220614', documento: 'RV - Factura', numberCode: '9518607917',
        valor_aberto: '855,87', valor_original: '855,87', periodo_fact: '05/2022',
        entidade: '602', reference: '151921905'
      },
      {
        data: '20220614', documento: 'RV - Factura', numberCode: '9518607917',
        valor_aberto: '855,87', valor_original: '855,87', periodo_fact: '05/2022',
        entidade: '602', reference: '151921905'
      },
      {
        data: '20220614', documento: 'RV - Factura', numberCode: '9518607917',
        valor_aberto: '855,87', valor_original: '855,87', periodo_fact: '05/2022',
        entidade: '602', reference: '151921905'
      },
      {
        data: '20220614', documento: 'RV - Factura', numberCode: '9518607917',
        valor_aberto: '855,87', valor_original: '855,87', periodo_fact: '05/2022',
        entidade: '602', reference: '151921905'
      },
      {
        data: '20220614', documento: 'RV - Factura', numberCode: '9518607917',
        valor_aberto: '855,87', valor_original: '855,87', periodo_fact: '05/2022',
        entidade: '602', reference: '151921905'
      },
      {
        data: '20220614', documento: 'RV - Factura', numberCode: '9518607917',
        valor_aberto: '855,87', valor_original: '855,87', periodo_fact: '05/2022',
        entidade: '602', reference: '151921905'
      },
      {
        data: '20220614', documento: 'RV - Factura', numberCode: '9518607917',
        valor_aberto: '855,87', valor_original: '855,87', periodo_fact: '05/2022',
        entidade: '602', reference: '151921905'
      },
      {
        data: '20220614', documento: 'RV - Factura', numberCode: '9518607917',
        valor_aberto: '855,87', valor_original: '855,87', periodo_fact: '05/2022',
        entidade: '602', reference: '151921905'
      },
      {
        data: '20220614', documento: 'RV - Factura', numberCode: '9518607917',
        valor_aberto: '855,87', valor_original: '855,87', periodo_fact: '05/2022',
        entidade: '602', reference: '151921905'
      },
      {
        data: '20220614', documento: 'RV - Factura', numberCode: '9518607917',
        valor_aberto: '855,87', valor_original: '855,87', periodo_fact: '05/2022',
        entidade: '602', reference: '151921905'
      },
      {
        data: '20220614', documento: 'RV - Factura', numberCode: '9518607917',
        valor_aberto: '855,87', valor_original: '855,87', periodo_fact: '05/2022',
        entidade: '602', reference: '151921905'
      },
      {
        data: '20220614', documento: 'RV - Factura', numberCode: '9518607917',
        valor_aberto: '855,87', valor_original: '855,87', periodo_fact: '05/2022',
        entidade: '602', reference: '151921905'
      }
    ]
    fistTable(dados.resumoCaixa, container)

    container.save()

  }

}
