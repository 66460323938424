import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';

@Injectable({
  providedIn: 'root'
})
export class LocalInstalacaoService {

  constructor(private http: HttpService) { }

  public cil_epas_ln: any;

  public localInstalacaoListagem(filters: any) {
    return this.http.__call('local-instalacao/listagem', filters)
  }

  public localInstalacaoListagemDisponivel(filters: any) {
    return this.http.__call('local-instalacao/listagem-disponivel', filters)
  }
}
