import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

@Component({
  selector: 'app-bancos',
  templateUrl: './bancos.component.html',
  styleUrls: ['./bancos.component.css']
})
export class BancosComponent implements OnInit {

  private banco = {
    id: null,
    nome: null,
    abreviatura: null,
    numero_conta: null,
    iban: null
  }

  private fabricante = {
    id: null,
    descricao: null,
    slug: null
  }

  private bancos: any = [];

  constructor(private http: HttpService, private configService: ConfigService) { }

  ngOnInit() {
    this.http.filters.search = null
    this.getPageFilterData(1);
  }


  private listarbancos() {

    this.configService.loaddinStarter('start');

    this.http.call_get('banco/listar', this.http.filters).subscribe(

      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.bancos = Object(response).data;
        this.configService.loaddinStarter('stop');

      }
    );
  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
    this.listarbancos();
  }


   private register(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();

/*     if (this.fabricante.descricao == "" || this.fabricante.slug == null) {
      this.configService.showAlert("Os campos Descrição e Slug são obrigatórios", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    } else  */

    {
    this.http.__call('banco/create', this.banco).subscribe(
      res => {
        if (Object(res).code == 201) {
          this.configService.showAlert(Object(res).message, 'alert-danger', true);
          this.configService.loaddinStarter('stop');
        } else {
          this.configService.showAlert(Object(res).message, 'alert-success', true);
          this.clearFormInputs(e);
          this.listarbancos()
          this.configService.loaddinStarter('stop');
        }
      }
    )
  };
}

  private clearFormInputs(e) {
    e.target.elements[0].value = null;
    e.target.elements[1].value = null;
    e.target.elements[2].value = null;
  }

  private refresh(id, nome, abreviatura, numero_conta, iban) {
    this.banco.id = id;
    this.banco.nome = nome;
    this.banco.abreviatura = abreviatura;
    this.banco.numero_conta = numero_conta;
    this.banco.iban = iban;
  }

  private editar(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
/*     if (this.fabricante.descricao == "" || this.fabricante.slug == null) {
      this.configService.showAlert("Os campos Descrição e Slug são obrigatórios", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    } else  */
    {
      this.http.__call('banco/update/' + this.banco.id, this.banco).subscribe(
        res => {
          if (Object(res).code == 201) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            //this.configService.clearFormInputs(e);
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.listarbancos();

          }
        }
      );
    }
    this.configService.loaddinStarter('stop');
  }


  private ini() {
    this.banco = {
      id: null,
      nome: null,
      abreviatura: null,
      numero_conta: null,
      iban: null
    }
  }





}
