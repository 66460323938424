import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, NgControl } from '@angular/forms';
import * as moment from 'moment';
import { ConfigService } from 'src/app/providers/config/config.service';
import { HttpService } from 'src/app/providers/http/http.service';
import { ExcelService } from 'src/app/services/excel.service';
import { ExcelAutoService } from 'src/app/services/excel/excel-auto.service';
import { ReportClienteService } from '../../report-at/relatorios/report-cliente.service';


@Component({
  selector: 'app-tipo',
  templateUrl: './tipo.component.html',
  styleUrls: ['./tipo.component.css']
})

export class TipoComponent implements OnInit, OnDestroy {


  loading: boolean = false;
  disableButton: boolean = true;


  submitted = false;
  public filters = {
    search: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    pagination: {
      perPage: 5,// Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima,
      lastPage: null,
      total: null // defini o total de registo da BD
    },
    filter: null // subelemente do filter
    , is_allexel: false
  }

  private localUrl: any;
  private largura: any;
  private altura: any;
  items: any = [];
  estado: any = [];
  nivels: any = [];
  origems: any = [];
  categorias: any = [];
  classificacoes: any = [];
  osDefault: any = [];
  nivel_ids: any = [];
  encamiamento: any = [];
  simpleFormCliente: FormGroup
  valiateOsDefault: string = "S"
  inputDisable: boolean
  stateValidate: boolean
  carregando = {
    tipo: ''
  }
  constructor(private http: HttpService,
    private configService: ConfigService,
    private excelService: ExcelService,
    private reportClientes: ReportClienteService,
    private excelsService: ExcelAutoService,
    private _formBuilder: FormBuilder,
  ) {
    this.createForm()
  }

  ngOnInit() {
    this.empresaUser()
    this.getCategoria()
    this.getPageFilterData(1);
    this.getEstado()
    this.getPageFilterDataPage(1)
    this.getNivel()
    this.getOrigem()
    this.getcategoria()
    this.getEncamiamento()
    this.validateOs()
    this.privategetClassificacao()
    /* this.createForm()  */
  }
  changeValue(value) {
    this.stateValidate = value
  }

  ngOnDestroy() {
    this.http.filters.orderBy = null
  }

  createForm() {
    this.simpleFormCliente = this._formBuilder.group({
      id: null,
      descricao: null,
      sla: [null, [Validators.required]],
      os: [null, [Validators.required]],
      encaminhamento: [null, [Validators.required]],
      os_default: [null],
      nivel_id: [null, [Validators.required]],
      estado_id: null,
      origem: [null, [Validators.required]],
      categoria: [null, [Validators.required]],
      nivel_id_2: [null],
      classificacao: null
    })
  }

  private disable(condition) {
    if (condition == 'N') {
      this.simpleFormCliente.controls.os_default.disable();
      this.simpleFormCliente.controls.classificacao.disable();
    } else {
      this.simpleFormCliente.controls.classificacao.enable();
      this.simpleFormCliente.controls.os_default.enable();
    }
  }
  get f() {
    return this.simpleFormCliente.controls;
  }

  privategetClassificacao() {
    this.http.call_get('ordemservico/classificacao/selectBox', null).subscribe(
      response => {
        this.classificacoes = Object(response).data;
      }
    );
  }

  privategetTipoByClassificacao(id) {
    this.carregando.tipo = 'Carregando...';
    console.log(this.simpleFormCliente.value)
    this.http.call_get('ordemservico/ostipo-by-classificacao/selectBox/' + id, null).subscribe(
      response => {
        this.osDefault = Object(response).data;
        this.carregando.tipo = 'Seleccione o tipo';
      }
    );
  }



  exportAsPDF(): void {
    var file = document.getElementsByClassName("exportAsXLSXCliente")[0]
    this.reportClientes.relatorioClientes('save', file, this.localUrl);

  }

  imprimirPDF(): void {
    var file = document.getElementsByClassName("exportAsXLSXCliente")[0];
    /*     this.reportClientes.relatorioClientes(this.items,file,'print'); */
    this.reportClientes.relatorioClientes('print', file, this.localUrl);
    // this.reportCliente.relatorioClientes(this.items);
  }

  private empresaUser() {
    this.configService.loaddinStarter('start');

    this.http.call_get('empresa/empresa-user', null).subscribe(
      response => {
        this.localUrl = Object(response).data.logotipo
        this.altura = Object(response).data.width
        this.largura = Object(response).data.height
        this.configService.loaddinStarter('stop');
      }
    );
  }

  private validateOs() {
    this.valiateOsDefault = this.simpleFormCliente.value.os
  }

  private getEstado() {
    this.http.__call('comunicacoes/estado_listagem', null).subscribe(
      response => {
        this.estado = Object(response).data;
      })
  }

  private getEncamiamento() {
    this.http.call_get('departamento/selectBox', null).subscribe(
      response => {
        this.encamiamento = Object(response).data;
      })
  }

  private getOrigem() {
    this.http.__call('comunicacoes/origem_listagem_all', null).subscribe(
      response => {
        this.origems = Object(response).data;
      })
  }
  private getcategoria() {
    this.nivels = []
    this.http.__call('comunicacoes/categorias_listagem_all', null).subscribe(
      response => {
        this.categorias = Object(response).data;
      })
  }
  private getNivel() {
    this.nivel_ids = []
    this.nivels = []
    const id = this.simpleFormCliente.get('categoria').value
    this.http.__call(`comunicacoes/categoriasByNivel/${id}/category`, null).subscribe(
      response => {
        this.nivels = Object(response).data;
      })
  }
  refreshNivelId() {
    this.nivel_ids = []
  }
  private getNivelId() {
    this.refreshNivelId()
    const id = this.simpleFormCliente.get('nivel_id').value
    this.http.__call('comunicacoes/niveis_id/' + id, null).subscribe(
      response => {
        this.nivel_ids = Object(response).data
        console.log(this.nivel_ids)

      })
  }
  setTrue() {
    this.filters.pagination.page = Number.isInteger(this.filters.pagination.page) ? this.filters.pagination.page : 1 ;
    this.filters.is_allexel = true
    this.getCategoria()
  }
  private getCategoria() {
    this.loading = true
    this.http.__call('comunicacoes/tipo_listagem', this.filters).subscribe(
      response => {
        this.filters.pagination.lastPage = Object(response).data.lastPage;
        this.filters.pagination.page = Object(response).data.page;
        this.filters.pagination.total = Object(response).data.total;
        this.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        console.log(this.items)
        this.loading = false;
        if (this.items != 0) {
          this.disableButton = false;
        }

        if (this.filters.is_allexel == false) {
          this.items = Object(response).data.data;
        } else {
          this.items = Object(response).data.data;
          this.exportAsXLSX(this.items)
        }
        this.filters.is_allexel = false
        this.loading = false;
      }
    );
  }

  exportAsXLSX(data: any): void {
    var CurrentDate = new Date();
    for (let i = 0; i < data.length; i++) {
      if (data[i].is_active == 1) {
        data[i].is_active = "Aberto"
      } else {
        data[i].is_active = "Fechado"
      }
    }
    var keys = [
      { key: 'cliente_id', width: 30 },
      { key: 'contrato_id', width: 15 },
      { key: 'numero_contador', width: 30, style: { font: { name: 'Calibri' } } },
      { key: 'cil', width: 30, style: { font: { name: 'Calibri' } } },
      { key: 'calibre', width: 30 },
      { key: 'numero_serie', width: 30 },
      { key: 'estado_contrato', width: 30 },
      { key: 'data_inicio', width: 30 },
      { key: 'data_fim', width: 30 },
      { key: 'data_rescisao', width: 30 },
      { key: 'motivo_recisao', width: 50 },
      { key: 'descricao', width: 30 },
      { key: 'tipoClienteDesc', width: 30 },
      { key: 'nivel_sensi', width: 30 },
      { key: 'nome', width: 30 },
      { key: 'factura_sigla', width: 30 },
      { key: 'morada', width: 30 },
      { key: 'ruas', width: 30 },
      { key: 'municipio', width: 30 },
      { key: 'bairro', width: 30 },
      { key: 'numero_identificacao', width: 30 },
      { key: 'tipo_identidade', width: 30 },
      { key: 'tipo_cliente', width: 30 },
      { key: 'email', width: 30 },
      { key: 'telefone', width: 30 },
      { key: 'media_consumo', width: 30 },
    ];

    var Cols = [
      'Nº Cliente', 'Nº Contrato', 'Nº Contador', 'Cil',
      'Calibre', 'Contador', 'Estado Contrato', 'Data Contrato', 'Data Prevista Fim', 'Data Rescisão',
      'Motivo Rescisão', 'Tarifa', 'Tipo Cliente', 'Nivel sensibilidade', 'Nome Cliente',
      'Documento', 'Morada Cliente', 'Morada', 'Município', 'Bairro', 'Identificação', 'Tipo Identidade',
      'Tipo Cliente', 'Email', 'Telefone', 'Consumo Médio Mensal'
    ]

    var title = 'RELATÓRIOS CLIENTES'
    var nameFile = "RELATORIOS_CLIENTES -" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    this.excelsService.excels(data, nameFile, this.localUrl, keys, Cols, title, 5, 26, 40, 3)
  }



  onSubmit() {
    this.submitted = true
    if (this.simpleFormCliente.value.id == null) {
      this.simpleFormCliente.patchValue({
        estado_id: 1
      })
    }
    if (this.simpleFormCliente.invalid) { return }
    let cliente_value = this.simpleFormCliente.value
    let url_action = cliente_value.id ? `comunicacoes/tipos/${cliente_value.id}` : 'comunicacoes/tipos'
    this._createOrUpdateCliente(url_action, cliente_value)
  }
  _createOrUpdateCliente(url, cliente) {
    this.http.__call(url, cliente)
      .subscribe(
        response => {

          this.submitted = false
          this.loading = false

          this.getCategoria();
          this.configService.showAlert(Object(response).message, 'success', true)
          this.getPageFilterData(1)

          if (this.simpleFormCliente.value.id == null) this.simpleFormCliente.reset()
          //this.after_reset()
        },
        error => {
          this.configService.showAlert("Alguma coisa correu mal", 'warning', true);
          this.submitted = false;
        }

      )
    this.loading = false
  }


  iniCliente(cliente) {
    this.simpleFormCliente.patchValue({
      id: cliente.id,
      sla: cliente.sla,
      os: cliente.os,
      encaminhamento: cliente.encaminhamento_default,
      os_default: cliente.os_default,
      nivel_id: cliente.comunicacao_nivel_id,
      origem: cliente.origem,
      categoria: cliente.comunicacao_categoria_id,
      nivel_id_2: cliente.nivel_id,
      estado_id: cliente.estado
    })
    this.getNivelId()
  }

  _closeModal(closeModal) {
    var action = document.getElementById(closeModal)
    action.click()
  }

  onReset() {
    this.submitted = false;
    this.simpleFormCliente.reset();
  }


  getPageFilterData(page: number) {
    if (this.filters.pagination.perPage == null) {
      return;
    }
    this.filters.pagination.page = Number.isInteger( this.filters.pagination.page) ?  this.filters.pagination.page : 1 
    this.getCategoria();
  }
  getPageFilterDataPage(page: number) {
    if (this.filters.pagination.perPage == null) {
      return;
    }
    this.filters.pagination.page = Number.isInteger(page) ? page : 1 
    this.getCategoria();
  }

  /*
      private refreshData({descricao,sla,os,encaminhamento_default,origems,nivel_desc,os_default,id,estado_id,categoria,nivel_ids}) {
      this.simpleFormCliente.value.descricao=descricao
      this.simpleFormCliente.value.estado_id=estado_id
      this.simpleFormCliente.value.sla=sla
      this.simpleFormCliente.value.os=os
      this.simpleFormCliente.value.encaminhamento=encaminhamento_default
      this.simpleFormCliente.value.os_default=os_default
      this.simpleFormCliente.value.nivel_id=id
      this.simpleFormCliente.value.estado_id=false
      this.simpleFormCliente.value.origem=origems
      this.simpleFormCliente.value.categoria=categoria
      this.simpleFormCliente.value.nivel_ids=nivel_ids
      this.getNivelId()
      console.log(estado_id)
    }  */

}



