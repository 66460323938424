import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-itens-plano-manutencao',
  templateUrl: './itens-plano-manutencao.component.html',
  styleUrls: ['./itens-plano-manutencao.component.css']
})
export class ItensPlanoManutencaoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
