import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-visao-geral',
  templateUrl: './visao-geral.component.html',
  styleUrls: ['./visao-geral.component.css']
})
export class VisaoGeralComponent implements OnInit {

  private tab_title: string = 'Conta Corrente'
  
  constructor() { }

  ngOnInit() {
  }

  private tabs(title) {
    this.tab_title = title
  }

}
