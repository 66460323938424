import { Component, OnInit, Input, createPlatformFactory, OnDestroy } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { ExcelService } from 'src/app/services/excel.service';
import * as moment from 'moment';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-conf-provincia',
  templateUrl: './conf-provincia.component.html',
  styleUrls: ['./conf-provincia.component.css']
})
export class ConfProvinciaComponent implements OnInit, OnDestroy {

  @Input() simpleFormProvincia: FormGroup;

  private provincia = {
    id: null,
    nome: null,
    is_active: null,
    abreviatura: null,
    user_id: null,
    //provinciaModal: false
  };

  private title: string = null;

  private items: any = [];
  private distritos: any = [];
/*  private estados: any = []; */

  constructor(private http: HttpService, private configService: ConfigService,private excelService: ExcelService) {
  }



  exportAsXLSX(): void {
    var CurrentDate = new Date();
    var nameFile = "lista_provincias -" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    this.excelService.exportAsExcelFile(document.getElementsByClassName("exportAsXLSXCliente")[0], nameFile);
  }
  exportAsPDF(): void {
    //this.reportLoja.relatorioLoja(this.cobrancas, this.simpleForm.value, 'save');
  }

  imprimirPDF(): void {
    //this.reportLoja.relatorioLoja(this.cobrancas, this.simpleForm.value);
  }

  ngOnInit() {
    this.http.filters.search = null
    this.getPageFilterData(1);
  }

  ngOnDestroy(){
    this.http.filters.orderBy = null
  }

  private getDadosModal() {

  }

  private getProvincias() {

    this.configService.loaddinStarter('start');

    this.http.__call('provincia/listagem', this.http.filters).subscribe(

      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        console.log( this.items )
        this.configService.loaddinStarter('stop');

      }
    );
  }

  private setDataProvincia(item) {

    if (item !== undefined) {
      this.title = "Editar Munícipio";

      this.provincia.id = item.id;
      this.provincia.nome = item.nome;
      this.provincia.abreviatura = item.abreviatura;
      this.provincia.is_active = item.is_active;
      this.provincia.user_id = item.user_id;
    }
  }


  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page =Number.isInteger(page) ? page : 1 ;
    this.getProvincias();
  }

  private getDistritosByProvincia(id){

    this.configService.loaddinStarter('start');

    this.http.call_get('distrito/getDistritosByProvincia/' + id, this.http.filters).subscribe(

      response => {

        this.distritos = Object(response).data.data;
        this.configService.loaddinStarter('stop');

      }
    );
  }

}
