import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange, NgModule } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpService } from 'src/app/providers/http/http.service';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { first } from 'rxjs/operators';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-create-or-edit-municipio',
  templateUrl: './create-or-edit-municipio.component.html',
  styleUrls: ['./create-or-edit-municipio.component.css']
})
export class CreateOrEditMunicipioComponent implements OnInit {

  public currentUser: any;
  @Input() title: string = "Registar Município";

  @Input() municipio = {
    id: null,
    nome: null,
    has_distrito: false,
    provincia_id: null,
    user_id: null,
    municipioModal: false
  };

  private provincia = {
    id: null,
    nome: null
  };


  @Input() municipios: any = [];
  @Input() provincias: any = [];


  @Input() showCreateMunicipio: boolean = true;
  @Input() municipioModal: boolean = false;


  @Input() simpleFormMunicipio: FormGroup;

  submitted = false;
  private loading: boolean = false;

  //@Output() private loadListMunicipio = new EventEmitter<any>();

  constructor(private auth: AuthService, private http: HttpService, private configService: ConfigService, private formBuilder: FormBuilder) {
    this.createForm();
    this.currentUser = this.auth.currentUserValue;


    if (this.provincias.length <= 0) {
      this.selectBoxProvincias();

    }
  }


  ngOnInit() {

    if (this.municipio.municipioModal) this.openEndMunicipioModal(false);
    this.municipioModal = this.municipio.municipioModal;
    this.municipio.user_id = this.currentUser.user.id;
/*
    console.log(this.municipio); */
  }

  createForm() {
    this.simpleFormMunicipio = this.formBuilder.group({
      index: [{ value: null, disabled: false }],
      nome: [null, Validators.required],
      provincia_id: [null, Validators.required],
      user_id: [null, Validators.required],
      has_distrito: [{ value: null, disabled: true }],
      municipioModal: [{ value: null, disabled: true }]
    });
  }

  get f() {
    return this.simpleFormMunicipio.controls;
  }
  onReset() {
    this.submitted = false;
    this.simpleFormMunicipio.reset();
    this.municipio.id = null
  }


  onSubmit() {

    this.submitted = true;

    // parar aqui se o formulário for inválido
    if (this.simpleFormMunicipio.invalid) {
      return;
    }
    //console.log(this.simpleFormMunicipio);

    this.loading = true;
    const index = this.simpleFormMunicipio.getRawValue().index;
    this.resetUser(this.currentUser.user.id)
    // TODO: usado para fazer a requisição com a api de criação de objsct or update
    const uri = (index === null ? 'municipio/create' : 'municipio/update/' + index);
    this.createOrEdit(uri, this.simpleFormMunicipio, (index === null ? true : false));

  }

  createOrEdit(uri: any, formulario: FormGroup, isCreate: boolean) {

    // TODO: usado para fazer a requisição com a api de criação de object
    this.http.__call(uri, formulario.value).pipe(first()).subscribe(
      response => {
        this.submitted = false;
        this.loading = false;

        if (Object(response).code == 200) {
          this.openEndMunicipioModal(true);
          this.reloadMunicipiosByProvincia();
        }

        if (isCreate) {
          this.simpleFormMunicipio.reset();
        }


        //this.municipios = Object(response).data;
      },
      error => {
        this.submitted = false;
        this.loading = false;
      });
  }


  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {

    /**/
    if (this.provincias.length <= 0) {
      this.selectBoxProvincias();
    }

    //console.log(this.municipio);

    if (this.municipio.id > 0) {
      this.title = "Editar Município";
      this.simpleFormMunicipio.patchValue({
        index: this.municipio.id,
        nome: this.municipio.nome,
        provincia_id: this.municipio.provincia_id,
        user_id: this.municipio.user_id,
        has_distrito: this.municipio.has_distrito
      });

    }
    else if (!(this.municipio.id > 0) && this.municipio.provincia_id != null) {
      this.simpleFormMunicipio.patchValue({
        provincia_id: this.municipio.provincia_id
      });
    }
    else {
      this.onReset()
      this.title = "Registar Município";
      this.simpleFormMunicipio.patchValue({
        user_id: this.currentUser.user.id,
        has_distrito: false
      });

    }

    //console.log(this.simpleFormMunicipio);
  }


  private reloadMunicipiosByProvincia() {

    this.http.call_get('municipio/getMunicipiosByProvincia/' + this.simpleFormMunicipio.value.provincia_id, null).subscribe(
      response => {

        this.municipios = Object(response).data;
      }
    );
  }


  private selectBoxProvincias() {
    this.http.call_get('provincia/selectBox', null).subscribe(
      response => {
        //console.log(Object(response).data);

        this.provincias = Object(response).data;
      }
    );
  }

  private openEndMunicipioModal(flag: boolean): void {
    this.municipioModal = flag;

    if (!flag) this.selectBoxProvincias();


  }

  public loadListEstabelecimentos(municipios) {
    this.municipios = municipios;
  }
  resetUser(id){
    this.simpleFormMunicipio.patchValue({
      user_id: id,
    })
  }
}
