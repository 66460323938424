import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import * as moment from 'moment';
import { ConfigService } from 'src/app/providers/config/config.service';
import { HttpService } from 'src/app/providers/http/http.service';
import { ExcelService } from 'src/app/services/excel.service';
import { ExcelAutoService } from 'src/app/services/excel/excel-auto.service';
import { RotaService } from '../../rotas.service';
// import { ReportClienteService } from '../../report-at/relatorios/report-cliente.service';

@Component({
  selector: 'app-motivo-alteracao-leitura',
  templateUrl: './motivo-alteracao-leitura.component.html',
  styleUrls: ['./motivo-alteracao-leitura.component.css']
})
export class MotivoAlteracaoLeituraComponent implements OnInit, OnDestroy {

  loading: boolean = false;
  disableButton: boolean = true;


  submitted = false;
  public filters = {
    search: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    pagination: {
      perPage: 5,// Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima,
      lastPage: null,
      total: null // defini o total de registo da BD
    },
    filter: null // subelemente do filter
    ,is_allexel:false
  }

  items: any = [];
  estado: any = [];
  simpleFormCliente: FormGroup

  constructor(private http: HttpService,
    private configService: ConfigService,
     private excelService: ExcelService,
     private _rotaService: RotaService,
     private excelsService: ExcelAutoService,
     private _formBuilder: FormBuilder,
     ) {
      this.createForm()
  }

  ngOnInit() {
    this.getPageFilterData(1);
    this.getMotivoanomalia()
    this.getPageFilterDataPage(1)
  }

  ngOnDestroy(){
    this.http.filters.orderBy = null
  }

  get f() {
    return this.simpleFormCliente.controls;
  }
  createForm() {
    this.simpleFormCliente = this._formBuilder.group({
      id: null,
      nome: [null, [Validators.required]],
      slug: [null],
      estado: null,
    })

  }
  private createFlagKeyup(value){
    this.simpleFormCliente.patchValue({flag:value})
  }
  private disable(){
    this.simpleFormCliente.controls.flag.disable();
  }



  private getMotivoanomalia() {
    console.log(this.filters)
    this.loading = true
    this._rotaService.motivoAlteracaoLeitura(this.filters).subscribe(
      response => {
         this.filters.pagination.lastPage = Object(response).data.lastPage;
         this.filters.pagination.page = Object(response).data.page;
         this.filters.pagination.total = Object(response).data.total;
         this.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        console.log(this.items)
        this.loading = false;
        if (this.items != 0) {
          this.disableButton = false;
        }
        this.filters.is_allexel=false
        this.loading = false;
      }
    );
  }




  onSubmit() {
    this.submitted = true
    if (this.simpleFormCliente.invalid) { return }
    let cliente_value = this.simpleFormCliente.getRawValue()
    this._createOrUpdateCliente(cliente_value,cliente_value.id)
  }
  _createOrUpdateCliente(cliente,id) {
    console.log(this.simpleFormCliente)
    this._rotaService.motivoAlteracaoLeituraCraeteOrUpdate(cliente, id)
      .subscribe(
        response => {

          this.submitted = false
          this.loading = false

          this.getMotivoanomalia();
          this.configService.showAlert(Object(response).message, 'success', true)
          this.getPageFilterData(1)
          if(this.simpleFormCliente.value.id==null)this.simpleFormCliente.reset()
          //this.after_reset()
        },
        error => {
          this.configService.showAlert("Alguma coisa correu mal", 'warning', true);
          this.submitted = false;
        }

      )
    this.loading = false
  }

  iniCliente(cliente) {
    this.simpleFormCliente.patchValue(cliente)
    this.disable()
  }

  _closeModal(closeModal) {
    var action = document.getElementById(closeModal)
    action.click()
  }

  onReset() {
    this.submitted = false;
    this.simpleFormCliente.reset();
  }


  getPageFilterData(page: number) {
     if (this.filters.pagination.perPage == null) {
       return;
     }
     this.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
     this.getMotivoanomalia();
   }
  getPageFilterDataPage(page: number) {
     if (this.filters.pagination.perPage == null) {
       return;
     }
     this.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
     this.getMotivoanomalia();
   }

   private refreshData({descricao}) {
    this.simpleFormCliente.value.descricao= descricao
  }

}



