import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { RotaService } from '../rotas.service';

import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import { FullCalendarComponent } from '@fullcalendar/angular';
import timeGridPlugin from '@fullcalendar/timegrid';
import { HttpService } from 'src/app/providers/http/http.service';

@Component({
  selector: 'app-agendamento-roteiro',
  templateUrl: './agendamento-roteiro.component.html',
  styleUrls: ['./agendamento-roteiro.component.css']
})
export class AgendamentoRoteiroComponent implements OnInit {

  private pagination = {
    total: null,
    perPage: 11,
    page: 1,
    lastPage: null,

    start: 1,
    end: 10,
    search: null,
    order: "created_at"
  }

  private filtroServicos = {
    search: null,
    orderBy: null,
    pagination: {
      perPage: 11,
      page: 1,
      lastPage: null,
      total: null
    },
    filter: null
  }

  loading: boolean
  agendamentos: any = []
  calendarEvents: any = []
  evento: any = {
    data_realizar: null,
    foi_realizada: null,
    rota_run_id: null,
    created_at: null,
    updated_at: null,
    roteiro: {
      rota_header_id: null,
      local_consumo_id: null,
      dia_mes: null,
      cil: null,
      is_predio: null,
      predio_nome: null,
      predio_andar: null,
      moradia_numero: null,
      nome_rota: null,
      nome_leitor: null,
    }
  }

  options: any

  @ViewChild('fullcalendar') fullcalendar: FullCalendarComponent;
  @ViewChild('external') external: ElementRef;

  constructor(
    private _rotaService: RotaService,
    private http: HttpService,

  ) {
    this._loadingDependences()
  }

  ngOnInit() {
    this.http.filters.search = null
    this.getPageFilterData(1)
  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
    this._loadingDependences();
  }

  getPageFilterDataServicos(page: number) {
    if (this.filtroServicos.pagination.perPage == null) {
      return;
    }
    this.filtroServicos.pagination.page =Number.isInteger(page) ? page : 1 ;
  }

  get yearMonth(): string {
    const dateObj = new Date()
    return dateObj.getUTCFullYear() + '-' + (dateObj.getUTCMonth() + 1)
  }

  _set_agendamento( evento ){
    this.evento = evento
  }

  _loadingDependences(){
    this.loading = true
    this._rotaService.getAgendamentos()
    .subscribe((response) => {

      this.http.filters.pagination.lastPage   = response.data.lastPage;
      this.http.filters.pagination.page       = response.data.page;
      this.http.filters.pagination.total      = response.data.total;
      this.http.filters.pagination.perPage    = response.data.perPage;

      this.agendamentos = response.data
      let calendarEvents = response.data.map((item) => {
        return {title: item.agendamento.descricao, date: item.data_realizar}
      })

      this._map_events(calendarEvents)
    })

    this.loading = false
  }

  _map_events(events){

    this.options = {
      editable: true,
      header: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay'
      },
      events: events,
      buttonText: {
        today: 'Hoje',
        month: 'Mês',
        week: 'Semana',
        day: 'Dia'
      },
      views: {
        agenda: {
          eventLimit: 2
        }
      },
      plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin]
    }
  }

}
