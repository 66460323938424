import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'appFilterRoteiro' })

export class FilterRoteiroPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) { return [] }

    if (!searchText) { return items }

    searchText = searchText.toLocaleLowerCase()

    return items.filter(it => {
      return it.cil.toLocaleLowerCase().includes(searchText) || it.moradia_numero.toLocaleLowerCase().includes(searchText)
    })
  }
}
