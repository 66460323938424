import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { ExcelService } from 'src/app/services/excel.service';
import Swal from 'sweetalert2';
import { ExcelAutoService } from 'src/app/services/excel/excel-auto.service';
import * as moment from 'moment';
import { toDate, format } from 'date-fns-tz'
import { saveAs } from 'file-saver';
import { HttpEvent, HttpEventType } from '@angular/common/http';
const axios = require('axios')
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/providers/http/api.service';
import { ModalListOrdemServicoService } from '../listar-ordem-servico/modal-ordem-servico.service';
//import { ModalListOrdemServicoService } from './modal-ordem-servico.service';
interface Ifile {
  ext: string,
  nome: any,
  url: string,
  id: number,
  flag: string
}

export class Filter {
 
  municipio_id:string="T";
  distrito_id:string="T"
  bairro_id: string="T";
  constructor() {
  }
}
@Component({
  selector: 'app-ot-listagem-consumivel',
  templateUrl: './ot-listagem-consumivel.component.html',
  styleUrls: ['./ot-listagem-consumivel.component.css']
})
export class OtListagemConsumivelComponent implements OnInit {
  public tipos: any;
  public estados: any;
  public prioridades: any;
  private distritos=[]
  private municipios=[]
  private bairros=[]
   datatime=null
  private loading: boolean = false;
  public filters = {
    search: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    estado_id: null, // Ordenação
    prioridade_id: null, // Ordenação
    classificacao_id: null, // Ordenação
    agendamento: null, // Ordenação
    preventiva: null, // Ordenação
    massiva: null, // Ordenação
    pagination: {
      perPage: 25,// Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima, 
      lastPage: null,
      total: null // defini o total de registo da BD
    },
    filter: null, // subelemente do filter
    permission: null, // subelemente do filter
    startDate: this.DateTimeZone(),
    endDate: null,
    is_allexel: false
  }

  @Input() minusculaTitle: string = "Ordem de Trabalho Prevista";
  @Input() maiusculaTitle: string = "ORDEM DE TRABALHO PREVISTA";

  @Input() ordem_url: string = "ordemservico";

  public permission = {
    value: null,
  }


  DateTimeZone() {
    const event = new Date();
    const parisDate = toDate(event.toISOString())
    const pattern = "yyyy-MM-dd"
    const timeOutput = format(parisDate, pattern, { timeZone: 'UTC/GMT+1' })
    return timeOutput.slice(0, 16);
  }


  private coordenada = {
    latitude: null,
    longitude: null,
    equipamentoDescricao: null
  }

  private dash = {
    abertoCount: 0,
    executadoCount: 0,
    agendadoCount: 0,
    noAgendadoCount: 0
  }

  progress: any = 0;
  progress_1: string = null;

  constructor(private auth: AuthService,
    private http: HttpService,
    private configService: ConfigService,
    private excelService: ExcelService,
    private api: ApiService,
    private checkOrdemServico:ModalListOrdemServicoService,
    private excelsService: ExcelAutoService,
  ) { }

  private items: any = [];
  private fileItems: any = []
  private resultados: any = [];
  private clientes: any = [];
  private facturas: any = [];

  private local_consumo: any = [];
  private classificacoes: any = [];
  private res: any = [];
  private localUrl: any;
  public filter = new Filter();

  ngOnInit() {
    this.getMunicipios();
    this.dashboard()
    this.getPageFilterData(1);
    this.getEstados();
    this.getClassificacao();
    this.getPrioridades();
    this.empresaUser();
  
    this.filters.permission = localStorage.getItem('permission');

    this.minusculaTitle = this.filters.permission == 'listagem_ot_comercial' ? 'Ordem de Serviço Prevista' : 'Ordem de Trabalho Prevista';
    this.maiusculaTitle = this.filters.permission == 'listagem_ot_comercial' ? 'ORDEM DE SERVIÇO PREVISTA' : 'ORDEM DE TRABALHO PREVISTA';

    if (this.filters.permission == 'listagem_ot_comercial') {
      this.ordem_url = "ordemservico";
    } else {
      this.ordem_url = "ordemTrabalho";
    }

  }
  timeOutput: any = null

  private getMunicipios() {
    this.distritos = []; 
    this.api.get('municipio/selectBox')
    .subscribe(response => {this.municipios = Object(response).data;});
  }


  private getDistritos() {
    this.bairros = [];
    this.filter.distrito_id = "";
    this.filter.bairro_id = "";
    this.api.get(`distrito/selectBoxDistritosByMunicipio/${this.filter.municipio_id}`)
    .subscribe(response => {
         this.distritos = Object(response).data;
        }        
    );
  }

  private getBairrosByDistritos() {
    this.filter.bairro_id = "";
    if(this.filter.distrito_id==""){this.filter.distrito_id = null};
    this.api.get(`bairro/getBairrosByDistrito/${this.filter.distrito_id}`)
            .subscribe(response => {
                this.bairros = Object(response).data;
            }
       );
  }

  getTimeZone(date) {
    if (date == null) return
    const parisDate = toDate(date)
    const pattern = "dd/MM/yyyy"
    this.timeOutput = format(parisDate, pattern, { timeZone: 'UTC/GMT+1' })
    return this.timeOutput
  }
  private empresaUser() {
    this.configService.loaddinStarter('start');

    this.http.call_get('empresa/empresa-user', null).subscribe(
      response => {
        this.localUrl = Object(response).data.logotipo
        this.configService.loaddinStarter('stop');
      }
    );
  }

  private getFileList(id) {
    this.loading = true
    this.http.call_get(`ordemservico/ordem-servico-anexo/${id}`, null).subscribe(
      response => {
        this.fileItems = Object(response);
        this.loading = false
      }
    );
  }
  setTrue() {
    this.filters.pagination.page = this.filters.pagination.page
    this.filters.is_allexel = true
    // console.log(this.filters.is_allexel)
    this.listar()
  }

  private listar() {

    //this.configService.loaddinStarter('start');
    this.filters.filter=this.filter
    this.filters.permission = localStorage.getItem('permission');

    this.loading = true
    this.http.__call('ordemservico/listagem-prevista', {...this.filters, isOrdem: this.checkOrdemServico.checkIfIsOrdemServico}).subscribe(
      response => {
        this.filters.pagination.lastPage = Object(response).data.lastPage;
        this.filters.pagination.page = Object(response).data.page;
        this.filters.pagination.total = Object(response).data.total;
        this.filters.pagination.perPage = Object(response).data.perPage;
        // console.log(this.filters.is_allexel)
        this.items = Object(response).data.data;
        if (this.filters.is_allexel) this.exportAsXLSX()
        this.loading = false
        this.filters.is_allexel = false
      }
    );


  }

  exportAsXLSX(): void {
    var CurrentDate = new Date();
    for (let i = 0; i < this.items.length; i++) {
      this.items[i].data_prevista = this.items[i].data_prevista ? this.getTimeZone(this.items[i].data_prevista):'------'
    }
    var keys = [
      { key: 'descricao', width: 70 },
      { key: 'equipamento', width: 80 },
      { key: 'tarefa', width: 70, style: { font: { name: 'Calibri' } } },
      { key: 'consumivel', width: 70 },
      { key: 'quantidade', width: 30 },
      { key: 'unidade', width: 70, style: { font: { name: 'Calibri' } } },
      { key: 'data_prevista', width: 30, style: { font: { name: 'Calibri' } } },
     
    ];

    var Cols = [
      'Descricao',
      'Codigo equipamento',
      'Tarefa',
      'Ferramenta/Consumivel',
      'Quantidade',
      'Unidade',
      'Data prevista',
    ]

    var title = 'LISTA ORDEM DE TRABALHO PREVISTA'
    var nameFile = "LISTA_ORDEM_DE_TRABALHO_PREVISTA" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    this.excelsService.excels(this.items, nameFile, this.localUrl, keys, Cols, title, 5, 7, 40, 3,[5])
  }



  private getEstados() {
    this.http.call_get('ordemservico/estado/selectBox', null).subscribe(
      response => {
        this.estados = Object(response).data;
      }
    );
  }

  private getPrioridades() {
    this.http.call_get('ordemservico/prioridade/selectBox', null).subscribe(
      response => {
        this.prioridades = Object(response).data;
      }
    );
  }

  private confirmar(item) {

    Swal.fire({
      html: `
      <h3><b>Pretende cancelar a OT?</b></h3>
      OT`+ item.osCodigo + `
   `,
      //title: '<strong></strong>',
      text: "Você não poderá reverter isso!",
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      confirmButtonColor: '#d33'

    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.value) {
        this.cancelarOrdemTrabalho(item.id)

      } else if (!result.value) {
        Swal.fire('', '', 'info')
      }
    })

  }

  private cancelarOrdemTrabalho(id) {

    this.http.__call('ordemservico/cancelarOrdemTrabalho/' + id, null).subscribe(
      response => {
        if (Object(response).code == 200) {
          Swal.fire('Sucesso!', '', 'success')
          this.getPageFilterData(1)
        } else {

          Swal.fire(Object(response).message, '', 'info')
          this.getPageFilterData(1)
        }
      }
    );
  }

  private confirmarFecharOT(item) {

    Swal.fire({
      html: `
      <h3><b>Pretende fechar a OT?</b></h3>
      OT`+ item.osCodigo + `
   `,
      //title: '<strong></strong>',
      text: "Você não poderá reverter isso!",
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      confirmButtonColor: '#d33'

    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.value) {
        this.fecharOrdemTrabalho(item.id)

      } else if (!result.value) {
        Swal.fire('', '', 'info')
      }
    })

  }

  private fecharOrdemTrabalho(id) {

    this.http.__call('ordemservico/fecharOrdemTrabalho/' + id, null).subscribe(
      response => {
        if (Object(response).code == 200) {
          Swal.fire('Sucesso!', '', 'success')
          this.getPageFilterData(1)
        } else {

          Swal.fire(Object(response).message, '', 'info')
          this.getPageFilterData(1)
        }
      }
    );
  }

  private dashboard() {

    this.filters.permission = localStorage.getItem('permission');

    this.http.__call('ordemservico/ordem-servico/dashBoard', this.filters).subscribe(
      response => {
        this.dash.abertoCount = Object(response).data.aberto;
        this.dash.executadoCount = Object(response).data.executado;
        this.dash.agendadoCount = Object(response).data.agendado;
        this.dash.noAgendadoCount = Object(response).data.noAgendado;

      }
    );
  }


  getPageFilterData(page: number) {

    if (this.filters.pagination.perPage == null) {
      return;
    }

    if (this.filters.startDate != null && this.filters.endDate != null) {
      if (this.filters.startDate > this.filters.endDate) {
        this.configService.showAlert("Data Início não pode ser superior a Data Fim", 'alert-danger', true);
        return;
      }
    }
/* 
    if (this.filters.startDate != null && this.filters.endDate == null) {
      this.configService.showAlert("Digite a Data Fim", 'alert-danger', true);
      return;
    } */

    if (this.filters.startDate == null && this.filters.endDate != null) {
      this.configService.showAlert("Digite a Data Início", 'alert-danger', true);
      return;
    }

    if (this.filters.pagination.perPage == null) {
      return;
    }

    this.filters.pagination.page = Number.isInteger(page) ? page : 1;
    this.listar();
  }

  private clearFilters() {
    this.filters = {
      search: null,
      orderBy: null,
      estado_id: null, // Ordenação
      prioridade_id: null, // Ordenação
      classificacao_id: null, // Ordenação
      agendamento: null, // OT com agendamento "true" ou não "false"
      preventiva: null, // Ordenação
      massiva: null, // Ordenação
      pagination: {
        perPage: 25,
        page: 1,
        lastPage: null,
        total: null
      },
      filter: null,
      permission: null,
      startDate: null,
      endDate: null,
      is_allexel: false
    }

    this.getPageFilterData(1);
  }


  private setData(item) {
    this.tipos = item
    //console.log(this.tipos)
  }

  private getClassificacao() {

    console.log('Permissões',this.filters.permission)
    const uri = (
                  this.filters.permission === 'listagem_ot_comercial' ? 
                                                     'ordemservico/classificacao-comercial/selectBox' : 
                                                              this.filters.permission === 'listagem_ot_tecnico' ?
                                                                                          'ordemservico/classificacao/selectBox' : 
                                                                                                   'ordemservico/classificacao/selectBox'
                );

    this.http.call_get(uri, null).subscribe(
      response => {
        this.classificacoes = Object(response).data;
      }
    );
  }


  private getHistoricoByOrdem(item) {
    this.http.call_get('ordemservico/os-historico/listagem/' + item.id, null).subscribe(
      response => {
        this.resultados = Object(response).data;
      }
    );
  }

  private getClientesByOrdem(item) {
    this.http.call_get('ordemservico/clientes-by-ordem/' + item.id, null).subscribe(
      response => {

        this.clientes = Object(response).data;
      }
    );
  }


  private getFacturas(item) {
    this.http.call_get('ordemservico/os-factura/listagem/' + item.factura, null).subscribe(
      response => {
        this.facturas = Object(response).data;
      }
    );
  }

  private getAgendamentoByOrdem(item) {
    this.configService.loaddinStarter('start');
    this.http.call_get('ordemservico/agendamentos-by-ordem/listagem/' + item.id, null).subscribe(
      response => {
        this.res = Object(response).data;
        this.configService.loaddinStarter('stop');
      }
    );
  }


  private getClienteDetalhes(item) {

    this.local_consumo.cil = null;
    this.local_consumo.numero_serie = null;
    this.local_consumo.predio_nome = null;

    if (item.contrato_id != null) {
      this.http.call_get('local-consumo/getLocalConsumoByContratoId/' + item.contrato_id, null).subscribe(
        response => {

          this.local_consumo = Object(response);
          this.local_consumo.cliente_nome = item.clienteNome;
          this.local_consumo.conta = item.numeroConta;
          this.local_consumo.distrito = this.local_consumo.distrito;
          this.local_consumo.bairro = this.local_consumo.bairro;
          this.local_consumo.contrato = item.contrato_id;
          this.local_consumo.cil = this.local_consumo.cil == null ? '' : this.local_consumo.cil;
          this.local_consumo.numero_serie = this.local_consumo.numero_serie == null ? '' : this.local_consumo.numero_serie;
          this.local_consumo.predio_nome = ((this.local_consumo.cil != null) ? '[' + this.local_consumo.cil + '] ' : '[0000-000-000] ') + ((this.local_consumo.is_predio) ? 'Prédio ' + this.local_consumo.predio_nome + ', ' + (this.local_consumo.predio_andar ? this.local_consumo.predio_andar + ' º Andar - ' : '') + 'Porta ' : 'Residência ') + this.local_consumo.moradia_numero;

        }
      );

    }

  }

  private getLatitudeLongitude(id) {

    this.coordenada.latitude = null;
    this.coordenada.longitude = null;

    this.configService.loaddinStarter('start');
    this.http.call_get('ordemservico/equipamento/getCoordenadas/' + id, null).subscribe(
      response => {
        this.coordenada.latitude = Object(response).data.latitude;
        this.coordenada.longitude = Object(response).data.longitude;
        this.coordenada.equipamentoDescricao = Object(response).data.descricao;
        this.configService.loaddinStarter('stop');
      }
    );


  }


  async downloads({ ext, url, nome, id, flag }: Ifile) {
    const documentStyles = document.documentElement.style;

    this.progress = id
    this.progress_1 = flag
    url = environment.app_url + "s" + url;

    axios({
      url: url,
      method: "post",
      responseType: "blob",
      async onDownloadProgress(progressEvent) {
        if (flag == 'no') {
          this.progress = await Math.round((progressEvent.loaded / progressEvent.total) * 100);
          documentStyles.setProperty('--progress', `${this.progress}%`);
        } else {
          this.progress = await Math.round((progressEvent.loaded / progressEvent.total) * 100);
          documentStyles.setProperty('--progress', `${this.progress}%`);
        }
      },
      data: {
        path: 'OT'
      },
    }).then(response => {
      var binaryData = [];
      var index = nome.indexOf(".");
      var afterComa = nome.substr(index, nome.length);

      if (ext == "vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        nome = nome.replace(afterComa, '.xlsx')
        //console.log(nome)
      }
      else if (ext == "vnd.oasis.opendocument.text") {
        nome = nome.replace(afterComa, '.odt')
      }

      const res = response
      // console.log(response)
      binaryData.push(res.data);
      if (flag == 'no') {
        this.progress = 0;
        documentStyles.setProperty('--progress', `${this.progress}%`);
        return saveAs(new Blob(binaryData, { type: res.data.type }), nome)
      }
      this.progress = 0;
      documentStyles.setProperty('--progress', `${this.progress}%`);
      const fileURL = window.URL.createObjectURL(new Blob(binaryData, { type: res.data.type }))
      window.open(fileURL, '_blank');
    });
  }

}
