import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { url } from '@rxweb/reactive-form-validators';
import * as moment from 'moment';
import { ConfigService } from 'src/app/providers/config/config.service';
import { HttpService } from 'src/app/providers/http/http.service';
import { ExcelService } from 'src/app/services/excel.service';
import { ExcelAutoService } from 'src/app/services/excel/excel-auto.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-centro-custo',
  templateUrl: './centro-custo.component.html',
  styleUrls: ['./centro-custo.component.css']
})
export class CentroCustoComponent implements OnInit {

  loading: boolean = false;
  disableButton: boolean = true;


  submitted = false;
  public filters = {
    search: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    pagination: {
      perPage: 5,// Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima,
      lastPage: null,
      total: null // defini o total de registo da BD
    },
    filter: null // subelemente do filter
    , is_allexel: false
  }

  items: any = [];
  estado: any = [];
  FormCentroCusto: FormGroup

  constructor(private http: HttpService,
    private configService: ConfigService,
    private excelService: ExcelService,
    private excelsService: ExcelAutoService,
    private _formBuilder: FormBuilder,
  ) {
    this.createForm()
    this.getPageFilterData(1);
  }

  ngOnInit() { }

  ngOnDestroy() {
    this.http.filters.orderBy = null
  }

  get f() {
    return this.FormCentroCusto.controls;
  }
  createForm() {
    this.FormCentroCusto = this._formBuilder.group({
      id: null,
      descricao: [null, [Validators.required]],
      referencia: [null],
    })

  }
  private createFlagKeyup(value) {
    this.FormCentroCusto.patchValue({ flag: value })
  }
  private disable() {
    this.FormCentroCusto.controls.flag.disable();
  }



  private listagem() {
    this.loading = true
    return this.http.__call('centro-custo/listagem', { filters: this.filters }).subscribe(
      response => {
        this.filters.pagination.lastPage = Object(response).lastPage;
        this.filters.pagination.page = Object(response).page;
        this.filters.pagination.total = Object(response).total;
        this.filters.pagination.perPage = Object(response).perPage;

        this.items = Object(response).data;
        this.loading = false;
        if (this.items != 0) {
          this.disableButton = false;
        }
        this.filters.is_allexel = false
        this.loading = false;
      }
    );
  }

  onSubmit() {

    this.submitted = true

    if (this.FormCentroCusto.invalid) { return }
    const currentId = this.FormCentroCusto.getRawValue().id
    const url = currentId == null ? 'centro-custo/create' : 'centro-custo/update/' + currentId;

    this.loading = true;
    this._createOrUpdate(url, this.FormCentroCusto.value)
  }
  _createOrUpdate(url: any, fb: any) {
    this.http.__call(url, fb)
      .subscribe(
        response => {

          this.submitted = false
          this.loading = false
          this.listagem();
          this.configService.showAlert(Object(response).message, 'success', true)
          this.getPageFilterData(1)
          if (this.FormCentroCusto.value.id == null) this.FormCentroCusto.reset()
          this.onReset();
        },
        error => {
          this.loading = false;
          this.submitted = false;
        }

      )
  }

  iniCliente(value) {
    this.FormCentroCusto.patchValue(value)
    console.log(this.FormCentroCusto.value)
  }

  _closeModal(closeModal) {
    var action = document.getElementById(closeModal)
    action.click()
  }

  onReset() {
    this.submitted = false;
    this.FormCentroCusto.reset();
  }


  getPageFilterData(page: number) {
    if (this.filters.pagination.perPage == null) {
      return;
    }
    this.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
    this.listagem();
  }

  private refreshData({ descricao }) {
    this.FormCentroCusto.value.descricao = descricao
  }

}
