import { Component, OnInit, Input, SimpleChange } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-view-cliente-detalhes',
  templateUrl: './view-cliente-detalhes.component.html',
  styleUrls: ['./view-cliente-detalhes.component.css']
})
export class ViewClienteDetalhesComponent implements OnInit {



  @Input() modal: string = "modalClienteDetalhes";
  @Input() cliente: any;

  constructor(private http: HttpService, private configService: ConfigService, private formBuilder: FormBuilder) {

  }

  ngOnInit() {

  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {

    this.cliente;

  }


}
