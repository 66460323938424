import { Injectable, EventEmitter } from '@angular/core';
import { ApiService } from 'src/app/providers/http/api.service';
import { HttpService } from 'src/app/providers/http/http.service';
import * as moment from 'moment';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';

@Injectable({​
    providedIn: 'root'
  }​)
  export class ContratoLundasulService {​

  public pdfEvent = new EventEmitter<Object>();

  private report: any;

  constructor(private http: HttpService) { }

  public imprimirPDFContratoLundaSul(id, report = 'imprimir') {
    this.http._get('contrato/imprimir/' + id).subscribe(
      response => {
        const dados = Object(response).data;
        console.log(dados)
        this.imprimirContrato(dados, report)
      }, error => {
        console.log(error)
      }
    );
  }


  public imprimirContrato(item, report = 'imprimir') {


    const contrato = item.contrato;
    const tipo_identidades = item.tipo_identidades;
    const empresa = item.empresa;


    var img_logotipo = empresa.logotipo;
    var doc = new jsPDF()
    doc.addImage(img_logotipo, 'JPEG', 10, 17, 24, 20)

    doc.setFontSize(8);
    doc.setFont("calibri");
    doc.setTextColor(0);
    doc.text('' + empresa.companyName.toUpperCase(), 37, 20);
    doc.setTextColor(0);
    doc.text('' + (empresa.addressDetail || ''), 37, 25);
    doc.text('NIF: ' + (empresa.taxRegistrationNumber || ''), 37, 30);
    doc.text('Email: ' + (empresa.email || ''), 37, 35);
    doc.text('Telefone: ', 95, 30);
    doc.text('' + (empresa.telefone || ''), 107, 30);
    doc.setTextColor(0, 0, 255);
    doc.text('' + (empresa.site || ''), 107, 35);

    doc.setFontSize(8);
    doc.setDrawColor(0);
    doc.setFillColor(255);
    doc.rect(145, 14, 48, 23, 'B');
    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.text('CONTRATO N.º', 148, 20);
/*     doc.text('CIL', 148, 30); */
    doc.setFontSize(8);
    doc.setFontType("normal");
    doc.text('' + contrato.id, 170, 20);
/*     doc.text('' + (contrato.cil == null ? '' : contrato.cil), 155, 30); */
    doc.setFontSize(11);
    doc.setFontType("bold");
    doc.text('CONTRATO DE FORNECIMENTO DE SERVIÇOS DE ÁGUAS' , 10, 48);
/*     doc.text('CONTRATO DE ' + contrato.objecto_contrato.toUpperCase(), 10, 46); */

    doc.setFontSize(8);
    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.setFont("arial");
    doc.text('LOCAL DE CONSUMO: ' + contrato.id, 10, 60);
    doc.text('CIL: ' + (contrato.cil || ''), 10, 63.5);
    doc.text('Morada Local de Consumo:', 10, 67);
    doc.setFontType("normal");
    doc.text('Bairro/Zona ' + (contrato.bairro || '') + ', Quarteirão ' + (contrato.quarteirao || ''), 10, 70);
    doc.text('' + ((contrato.is_predio == null ? '' : contrato.is_predio ? + 'Prédio ' + (contrato.predio_nome || '') + ', ' + (contrato.predio_andar || '') + 'º Andar - Porta ' : 'Residência ') + (contrato.moradia_numero || '')), 10, 73);
    doc.text('' + (contrato.distrito || ''), 10, 77);


    doc.setFontType("bold");
    doc.text('CLIENTE N.º: ' + contrato.cliente_id, 85, 60);
    doc.text('NIF: ', 85, 67);
    doc.text('Telefone: ', 85, 70.5);
    doc.text('EMAIL: ', 85, 74);
    doc.setFontType("normal");
    doc.text('' + contrato.cliente_nome.toUpperCase(), 85, 63.5);
    doc.text((contrato.cliente_telefone || ''), 98, 70.5);
    doc.text((contrato.cliente_email || ''), 98, 74);

    let nif = tipo_identidades.find(obj => obj.nome == 'NIF')
    doc.text(' ' + (nif ? nif.numero_identidade : ""), 91, 67);

    doc.setFontSize(8)
    doc.setTextColor(0);
    doc.setFont("arial");
    doc.setFontType("normal");

     let identidade_text = nif ? `, portador do ${nif.nome} n.º ${nif.numero_identidade}, ` : ", "
     doc.text('Titulares deste Contrato: - Empresa Pública de Águas e Saneamento da Lunda Sul, E.P na qualidade de fornecedor;', 10, 88, { maxWidth: 170, align: 'justify' });
     doc.text('- '+ contrato.cliente_nome + ' na qualidade de cliente.', 39, 91, { maxWidth: 170, align: 'justify' });

    doc.setFontType("bold");
    doc.text('Tarifa: ', 10, 103);
    doc.text((contrato.numero_serie != null) ? 'Contador: ' : '', 10, 109);

    if (contrato.morada_correspondencia_id == null) doc.text('Endereço para Correspondência:', 10, 115);


    doc.text('Tipo de Utilização: ', 72, 103);

    doc.text('Leitura: ', 72, 109);
    doc.text('Tipo de Contrato:', 144, 103);
    doc.text('Data Início:', 144, 109);
    doc.text('Data Fim:', 144, 115);
    doc.text('Consumo Máximo Previsto:', 144, 121);
    doc.text('Período de Facturação:', 144, 127);

    doc.setFontType("Normal");
    doc.text('' + contrato.classe_tarifario, 19, 103);
    doc.text((contrato.numero_serie != null) ? contrato.numero_serie : '', 24, 109);
    doc.text('' + contrato.tipo_contrato, 98, 103);

    if (contrato.morada_correspondencia_id == null) {
      doc.text('Província de ' + contrato.cliente_provincia + ', Município de ' + contrato.cliente_municipio, 10, 119);
      doc.text(contrato.cliente_morada, 10, 123);
    }

    doc.text('' + (contrato.tipo_medicao_slug == 'LEITURA') ? contrato.ultima_leitura || '' + ' m³' : '', 83, 109);

    if (contrato.data_inicio != null && contrato.data_fim != null)
    {
      doc.text('Provisório', 173, 103);
    } else {
      doc.text('Definitivo', 173, 103);
    }

    doc.text('' +(moment(contrato.data_inicio).format("DD/MM/YYYY")), 173, 109);
    doc.text('' +(contrato.data_fim == null ? 'Sem Data' : moment(contrato.data_fim).format("DD/MM/YYYY")), 173, 115);
    doc.text('Mensal', 175, 127);
    doc.text(((contrato.consumo_maximo) == null ? 'Sem Limite' : String(contrato.consumo_maximo + ' m3')) , 180, 121);
    doc.setFontSize(8)
    doc.setTextColor(0);
    doc.setFont("arial");
/*     doc.setFontType("bold");
    doc.text('O Cliente declara que deseja receber as suas Facturas no seguinte email: ', 10, 135);
    doc.setFontType("normal");
    doc.setTextColor(0, 0, 255);
    doc.text(' ' + (contrato.cliente_email == null ? '' : contrato.cliente_email), 100, 135); */
    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.text('O Cliente declara que deseja pagar por Débito Directo através das seguintes referências bancárias:', 10, 140);
    doc.setFontType("normal");
    doc.text('BANCO SOL - CONTA Nº 67230684, IBAN: 0044 0000 6723068410148', 10, 145);
    doc.text('BANCO BPC - CONTA Nº 0221-E65363-011, IBAN: 0010 0221 0146536301141', 10, 150);
    doc.text('BANCO BAI - CONTA Nº 138979263-10-001, IBAN: 0040 0000 3897926310170', 10, 155);
    doc.setFontType("bold");
    doc.text('O Cliente declara ter conhecimento das condições gerais do presente contrato que constam no seu verso.', 10, 160);
    doc.text('Saurimo, ', 10, 174);
    var dataActual = moment(new Date()).format("DD/MM/YYYY");
    doc.text('' + dataActual, 25, 174);
/*     doc.text('O Cliente ', 10, 184);
    doc.text('_____________________________________________________________________________________________________________', 24, 184);  */
    /* doc.text('A EPAS LUNDA-SUL', 20, 195); */

    doc.setFontType("bold");
    doc.text('A EPAS LUNDA-SUL', 20, 210);
    doc.text('_______________________________________', 10, 218);
    doc.setFontType("normal");
    doc.text('(Zaione Xavier Muloueno)', 21, 223);
    doc.text('Administrador Área Administrativa e Financeira', 10, 227);

    doc.setFontType("bold");
    doc.text('O Cliente', 147, 210);
    doc.text('_______________________________________', 125, 218);
    doc.setFontType("normal");
    doc.text('(' + contrato.cliente_nome + ')', 140, 223, { maxWidth: 40, align: 'left' });

    var numberPage = 1;
    var totalNumberPage = 1;
    var dataActual = moment(new Date()).format("DD/MM/YYYY");

    doc.line(10, doc.internal.pageSize.height - 9, 194, doc.internal.pageSize.height - 9); // vertical line
    doc.text(dataActual + ' ' + moment().format("H:m"), 11, 285);
    doc.text('Página: ' + numberPage + '/1' , 180, 285);
    doc.setFontSize(6);

    doc.text("NIF: " + empresa.taxRegistrationNumber + " - " + empresa.companyName + " / " + empresa.addressDetail + " / " + (empresa.telefone == null ? '' : empresa.telefone) + " / " + (empresa.email == null ? '' : empresa.email), 105, doc.internal.pageSize.height - 6, null, null, 'center');
    doc.setFontSize(7);
    doc.text('Processado por programa validado nº 4/AGT119', 105, doc.internal.pageSize.height - 3, null, null, 'center');


    doc.addPage();

    doc.setFontSize(9);
    doc.setFont("arial");
    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.text('CLÁUSULAS GERAIS DO CONTRATO', 80, 13);

    var dataActual = moment(new Date()).format("DD/MM/YYYY");
    doc.text(dataActual, 7, 13);

    doc.setFontSize(10)
    doc.setTextColor(0);
    doc.setFont("arial");
    doc.setFontType("bold");
    doc.setFontSize(8)
    doc.text('Cláusula 1ª - Contrato ', 10, 33);
    doc.setFontType("normal");
    doc.text('1) O presente Contrato tem por objectivo o fornecimento de água pela EPASLUNDA - SUL, E.P. ao cliente, sendo automaticamente alargado o seu âmbito ao serviço de saneamento logo que este esteja disponível.', 10, 37, { maxWidth: 85, align: 'left' });
    doc.text('2) Os ramais prediais ligados devem ser providos de hidrómetros, instalados em local escolhido pela EPASLUNDA - SUL, E.P. e, sempre que possível, livremente acessível para leitura regular, com protecção adequada à sua conservação e normal funcionamento, não podendo ser mudado de local, em quaisquer circunstâncias, pelo Cliente. ', 10, 47, { maxWidth: 85, align: 'left' });
    doc.text('3) Até à instalação de um hidrómetro, a aferição do consumo será feita por estimativa.', 10, 63, { maxWidth: 85, align: 'left' });
    doc.text('4) O contador é propriedade da EPASLUNDA - SUL, E.P., competindo-lhe a sua instalação, manutenção, reparação, substituição e remoção a expensas do cliente, devendo estes comunicar à EPASLUNDA - SUL, E.P. as anomalias que nele possam ocorrer até 5 dias após a constatação do facto.', 10, 69, { maxWidth: 85, align: 'left' });
    doc.text('5) O Cliente é responsável pela guarda, segurança e inviolabilidade dos hidrómetros, respondendo pelos danos que lhe sejam causados e em caso de furto ou dano total ou parcial suportará as despesas inerentes à sua reposição.', 10, 85, { maxWidth: 85, align: 'left' });

    doc.setFontType("bold");
    doc.text('Cláusula 2ª - Deveres do Cliente', 10, 98);
    doc.setFontType("normal");
    doc.text('Os Clientes estão obrigados a: ', 10, 101);
    doc.text('1) Cumprir as Leis e Regulamentos relacionados com os serviços de abastecimento de água e saneamento, as disposições do presente Contrato, bem como as instruções e recomendações emanadas da EPASLUNDA - SUL, E.P.; ', 10, 104, { maxWidth: 85, align: 'left' });
    doc.setFontType("bold");
    doc.text('2) Pagar pontualmente as importâncias devidas, nos prazos estipulados e nos termos do presente Contrato; ', 10, 117, { maxWidth: 85, align: 'left' });
    doc.setFontType("normal");
    doc.text('3) Manter em bom estado de conservação e funcionamento e não fazer uso indevido ou danificar as instalações fixas, meios e dispositivos afectos aos sistemas; ', 10, 124, { maxWidth: 85, align: 'left' });
    doc.setFontType("bold");
    doc.text('4) Não proceder à execução de ligações aos sistemas públicos sem autorização da EPASLUNDA - SUL, E.P.;', 10, 134, { maxWidth: 85, align: 'left' });
    doc.setFontType("normal");
    doc.text('5) Não alterar os ramais de ligação aos sistemas públicos, estabelecidos entre rede geral e a rede predial;', 10, 141, { maxWidth: 85, align: 'left' });
    doc.setFontType("bold");
    doc.text('6) Não fazer uso indevido dos sistemas, nomeadamente, abstendo-se de provocar desperdícios de água, entupimentos nas canalizações ou actos que possam provocar contaminação da água;', 10, 148, { maxWidth: 85, align: 'left' });
    doc.setFontType("normal");
    doc.text('7) Informar a EPASLUNDA - SUL, E.P., sempre que se aperceba de mau funcionamento da instalação ou do hidrómetro, quando exista, comunicando-lhe as anomalias detectadas;', 10, 158, { maxWidth: 85, align: 'left' });
    doc.text('8) Cooperar com a EPASLUNDA - SUL, E.P. no bom funcionamento dos sistemas e permitir o acesso de colaboradores, devidamente credenciados, para recolha de leituras, substituição de contador, fiscalização, colheita de amostras de controlo e outros fins relacionados com o âmbito do presente Contrato;', 10, 168, { maxWidth: 85, align: 'left' });
    doc.text('9) Entregar na EPASLUNDA - SUL, E.P., caso ainda não o tenha feito, os documentos em falta (croquis de localização, título de propriedade ou Contrato de arrendamento, certidão matricial).', 10, 184, { maxWidth: 85, align: 'left' });

    doc.setFontType("bold");
    doc.text('Cláusula 3ª - Direitos dos clientes', 10, 194);
    doc.setFontType("normal");
    doc.text('Gozam de todos os direitos consagrados na Lei e Regulamentos relacionados com os serviços de abastecimento de água e saneamento e em especial:', 10, 197, { maxWidth: 85, align: 'left' });
    doc.text('1) Ao bom funcionamento dos sistemas;', 10, 207, { maxWidth: 85, align: 'left' });
    doc.text('2) À preservação da segurança, saúde pública e conforto próprio;', 10, 210, { maxWidth: 85, align: 'left' });
    doc.text('3) À informação sobre todos os aspectos ligados aos serviços fornecidos;', 10, 213, { maxWidth: 85, align: 'left' });
    doc.text('4) À solicitação de vistorias; ', 10, 216, { maxWidth: 85, align: 'left' });
    doc.text('5) À reclamação dos actos e omissões da EPASLUNDA-SUL, E.P. que prejudiquem os seus direitos ou interesses legitimamente protegidos.; ', 10, 219, { maxWidth: 85, align: 'left' });


    doc.setFontType("bold");
    doc.text('Cláusula 4ª - Rescisão de Contrato', 10, 226);
    doc.setFontType("normal");
    doc.text('1) A rescisão de Contrato deve ser obrigatoriamente comunicada pelo Cliente à EPASLUNDA - SUL, E.P., pelo menos, com 30 dias de antecedência sobre a data da efectiva rescisão. ', 10, 229, { maxWidth: 85, align: 'left' });
    doc.text('2) O Cliente mantém-se obrigado ao pagamento das facturas até efectiva rescisão do Contrato, que se concretiza através do acesso ao contador e da suspensão do abastecimento.', 10, 239, { maxWidth: 85, align: 'left' });
    doc.text('3) Caso não seja cumprido o estipulado no número 9 da cláusula anterior, a EPASLUNDA - SUL, E.P. poderá proceder à rescisão unilateral deste Contrato e consequentemente interromper o abastecimento de água.', 10, 249, { maxWidth: 85, align: 'left' });

    doc.setFontType("bold");
    doc.text('Cláusula 5ª - Continuidade e suspensão do fornecimento', 10, 259);
    doc.setFontType("normal");
    doc.text('1) O fornecimento de água poderá ser suspenso nas seguintes situações:', 10, 262, { maxWidth: 85, align: 'left' });
    doc.text('a) Exigências de serviço público;', 10, 265);
    doc.text('b) Ocorrência de avarias ou obras nos sistemas sempre que os trabalhos justifiquem essa interrupção ou restrição; ', 10, 268, { maxWidth: 85, align: 'left' });
    doc.text('c) Casos fortuitos ou de força maior;', 10, 274);
    doc.text('d) Trabalhos de manutenção, reabilitação ou expansão das redes públicas;', 10, 277 );
    doc.text('e) Modificação programada das condições de exploração dos sistemas ou alteração justificada das pressões de serviço;', 10, 280, { maxWidth: 85, align: 'left' } );


    // PARTE DIREITA DA FOLHA
    doc.setFontType("normal");
    doc.text('f) Actos imputáveis ao Cliente.', 120, 36 );
    doc.text('2) Para efeitos da última alínea do ponto anterior, são considerados actos imputáveis ao Cliente:', 120, 39, { maxWidth: 85, align: 'left' });
    doc.text('a) Falta de condições de salubridade das redes interiores do local de consumo; ', 120, 46, { maxWidth: 85, align: 'left' });
    doc.text('b) Falta de pagamento das importâncias devidas à EPASLUNDA-SUL, E.P.;', 120, 53, { maxWidth: 85, align: 'left' });
    doc.text('c) Recusa de entrada a um colaborador da EPASLUNDA - SUL, E.P., devidamente credenciado, para a inspecção das canalizações interiores ou efectuar leitura, verificação, substituição ou levantamento do contador;', 120, 59, { maxWidth: 85, align: 'left' });
    doc.text('d) Viciação do contador ou utilização do meio fraudulento para consumir água;', 120, 69, { maxWidth: 85, align: 'left' });
    doc.text('e) Efectuar venda de água a terceiros sem o consentimento da EPASLUNDA - SUL, E.P.;', 120, 76, { maxWidth: 85, align: 'left' });
    doc.text('3) A suspensão do fornecimento por causas imputáveis ao Cliente não o isenta do pagamento da tarifa fixa mensal;', 120, 82, { maxWidth: 85, align: 'left' });
    doc.text('4) A suspensão do fornecimento de serviços pela EPASLUNDA - SUL, E.P., por falta de pagamentos das importâncias devidas, é precedida de advertência notificada por escrito ao Cliente com antecedência mínima de 5 dias da data em que a suspensão venha a ter lugar.', 120, 88, { maxWidth: 85, align: 'left' });


    doc.setFontType("bold");
    doc.text('Cláusula 6ª - Leituras', 120, 101);
    doc.setFontType("normal");
    doc.text('1) A leitura dos hidrómetros, quando instalados, será efectuada, pelo menos, duas vezes por ano;', 120, 104, { maxWidth: 85, align: 'left' });
    doc.text('2) A leitura ou verificação será efectuada dentro do horário normal de trabalho da EPASLUNDA - SUL, E.P.;', 120, 111, { maxWidth: 85, align: 'left' });
    doc.text('3) Quando, por motivo imputável ao Cliente, não tenha sido efectuada a leitura do contador, será aplicado um consumo calculado por estimativa.', 120, 118, { maxWidth: 80, align: 'left' });

    doc.setFontType("bold");
    doc.text('Cláusula 7ª - Determinação do consumo por estimativa', 120, 128);
    doc.setFontType("normal");
    doc.text('1) O consumo por estimativa aplicar-se-á quando não for possível a leitura real do contador pela EPASLUNDA - SUL, E.P.', 120, 131, { maxWidth: 85, align: 'left' });
    doc.text('2) Quando, por avaria do hidrómetro ou por razões manifestamente técnicas, não for possível a medição dos consumos de água, o seu cálculo deve basear-se na média dos últimos três consumos registados por ciclo de venda ou, no caso de ainda não existirem, na média dos que existam;', 120, 138, { maxWidth: 85, align: 'left' });
    doc.text('3) Sempre que não exista histórico de consumo, a estimativa a aplicar será calculada com base na média de consumo dos Clientes da mesma tipologia.', 120, 151, { maxWidth: 85, align: 'left' });

    doc.setFontType("bold");
    doc.text('Cláusula 8ª - Preços', 120, 161);
    doc.setFontType("normal");
    doc.text('1) As tarifas de abastecimento de água e saneamento são fixadas pela Entidade Reguladora do Subsector de Águas e Saneamento, após homologação do titular do Departamento Ministerial Responsável pelas Finanças Públicas;', 120, 164, { maxWidth: 85, align: 'left' });
    doc.text('2) A Entidade Reguladora do Subsector de Águas e Saneamento e a EPASLUNDA - SUL, E.P. devem promover ampla difusão do novo tarifário, com a antecipação de 30 (trinta) dias em relação à data da sua entrada em vigor;', 120, 177, { maxWidth: 85, align: 'left' });
    doc.text('3) Os editais com o novo tarifário devem ser publicados no Diário da República, nas páginas de internet da Entidade Reguladora do Subsector de Águas e Saneamento e da EPASLUNDA - SUL, E.P. e divulgados por outros meios de fácil acesso da população.', 120, 190, { maxWidth: 85, align: 'left' });


    doc.setFontType("bold");
    doc.text('Cláusula 9ª - Facturas ', 120, 203);
    doc.setFontType("normal");
    doc.text('As facturas são emitidas, mensalmente, de acordo com o volume apurado ou estimado de consumo e são pagas nos termos e condições definidos pela EPASLUNDA - SUL, E.P. e respectivos regulamentos de serviços.', 120, 206, { maxWidth: 85, align: 'left' });

    doc.setFontType("bold");
    doc.text('Cláusula 10ª - Pagamentos', 120, 216);
    doc.setFontType("normal");
    doc.text('1) O pagamento da factura é efectuado junto dos serviços de atendimento da EPASLUNDA - SUL, E.P., nos horários de expediente divulgados, ou por outras vias que venham a ser acordadas com o cliente, pelo valor da respectiva factura, dentro do prazo limite de pagamento nela estipulado;', 120, 219, { maxWidth: 85, align: 'left' });
    doc.text('2) A liquidação da factura poderá ser feita nas seguintes modalidades:', 120, 232, { maxWidth: 85, align: 'left' });
    doc.text('a) Pagamento no terminal multicaixa disponível nos serviços de atendimento da EPASLUNDA - SUL, E.P.;', 120, 235, { maxWidth: 85, align: 'left' });
    doc.text('b) Pagamento por transferência bancária ou por depósito numa das contas indicadas na factura, com apresentação do respectivo comprovativo nos serviços de atendimento da EPASLUNDA - SUL, E.P.', 120, 241, { maxWidth: 85, align: 'left' });
    doc.text('3) A EPASLUNDA - SUL, E.P. envidará esforços para disponibilizar outras formas ou vias de pagamento, designadamente:', 120, 251, { maxWidth: 85, align: 'left' });
    doc.text('a) Pagamento em dinheiro nos serviços de atendimento da EPASLUNDA - SUL, E.P.; ', 120, 258, { maxWidth: 85, align: 'left' });
    doc.text('b) Pagamento num terminal multicaixa, utilizando para o efeito a referência multicaixa indicada na respectiva factura.', 120, 265, { maxWidth: 85, align: 'left' });


    doc.setFontType("bold");
    doc.text('Cláusula 11ª - Multas e outras tarifas', 120, 272);
    doc.setFontType("normal");
    doc.text('1) As facturas pagas após o seu vencimento ficam sujeitas a multa, nos termos e condições definidos nos regulamentos de serviços;', 120, 275, { maxWidth: 84, align: 'left' });


    doc.line(10, doc.internal.pageSize.height - 9, 194, doc.internal.pageSize.height - 9); // vertical line
    doc.text('Página: ' + numberPage + '/2' , 180, 285);
    doc.setFontSize(6);

    doc.text("NIF: " + empresa.taxRegistrationNumber + " - " + empresa.companyName + " / " + empresa.addressDetail + " / " + (empresa.telefone == null ? '' : empresa.telefone) + " / " + (empresa.email == null ? '' : empresa.email), 105, doc.internal.pageSize.height - 6, null, null, 'center');
    doc.setFontSize(7);
    doc.text('Processado por programa validado nº 4/AGT119', 105, doc.internal.pageSize.height - 3, null, null, 'center');


    doc.addPage();

    doc.setFontSize(9);
    doc.setFont("arial");
    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.text('CLÁUSULAS GERAIS DO CONTRATO', 80, 13);

    doc.setFontSize(10)
    doc.setTextColor(0);
    doc.setFont("arial");
    doc.setFontType("bold");
    doc.setFontSize(8)
    doc.text('Cláusula 11ª - Multas e outras tarifas', 10, 33);
    doc.setFontType("normal");
    doc.text('2) O cliente será também sujeito ao pagamento de multa sempre que:', 10, 37, { maxWidth: 85, align: 'left' });
    doc.text('a) Incorra em consumo excessivo ou desperdício de água;', 10, 40, { maxWidth: 85, align: 'left' });
    doc.text('b) Sejam provocados danos no contador, do ramal de ligação ou da rede pública de água;', 10, 43, { maxWidth: 85, align: 'left' });
    doc.text('c) Se ligue sem autorização ou clandestinamente à rede da EPASLUNDA - SUL, E.P.;', 10, 50, { maxWidth: 85, align: 'left' });
    doc.text('d) Revenda água da rede pública sem permissão.', 10, 57, { maxWidth: 85, align: 'left' });
    doc.text('2) O cliente estará sujeito ao pagamento da tarifa de religação após suspensão do serviço por atraso de pagamento ou por razões imputáveis ao cliente. ', 10, 60, { maxWidth: 85, align: 'left' });
    doc.text('3) Para além das multas referidas nos números anteriores, o Cliente pode ainda ser sujeito a um processo judicial indemnizatório por perdas e danos, instaurado pela EPASLUNDA - SUL, E.P. ', 10, 70, { maxWidth: 85, align: 'left' });

    doc.setFontType("bold");
    doc.text('Cláusula 12ª - Reclamações', 10, 80);
    doc.setFontType("normal");
    doc.text('1) Aos Clientes assiste o direito de reclamar, por qualquer meio, perante a EPASLUNDA - SUL, E.P., contra qualquer acto ou omissão desta, por actos dos seus agentes ou pelos serviços prestados, que tenham lesado os seus direitos ou interesses legítimos legalmente protegidos. ', 10, 83, { maxWidth: 85, align: 'left' });
    doc.text('2) Os serviços de atendimento ao público dispõem de formulário próprio onde os Clientes podem apresentar as suas reclamações. ', 10, 96, { maxWidth: 85, align: 'left' });
    doc.text('3) A reclamação é apreciada pela EPASLUNDA - SUL, E.P. no prazo de 22 dias úteis, que informará o reclamante da decisão tomada e respectiva fundamentação. ', 10, 103, { maxWidth: 85, align: 'left' });


    doc.setFontType("bold");
    doc.text('Cláusula 13ª - Vigência', 10, 113);
    doc.setFontType("normal");
    doc.text('O presente Contrato considera-se em vigor a partir da data da sua assinatura ou da ligação da água, conforme o que acontecer por último, ou ainda após a apresentação do documento comprovativo da compra ou arrendamento do local de consumo, este apenas em caso de transferências da titularidade do Contrato.', 10, 116, { maxWidth: 85, align: 'left' });


    doc.setFontType("bold");
    doc.text('Cláusula 14ª - Foro', 10, 132);
    doc.setFontType("normal");
    doc.text('1) Em caso de eventuais litígios, as Partes tentarão prioritariamente a sua resolução amigável.', 10, 135, { maxWidth: 85, align: 'left' });
    doc.text('2) Na impossibilidade de ser obtida resolução amigável, será competente o Tribunal Judicial de Saurimo.', 10, 142, { maxWidth: 85, align: 'left' });

    doc.setFontSize(14)
    doc.setFontType("bold");
/*     doc.text('EPAS Lunda-Sul, E.P.', 23, 162);
    doc.text('____________________________', 12, 167); */

    doc.text('EPASLUNDA - SUL, E.P.', 100, 162, {align: "center"});
    doc.text('____________________________', 100, 167, {align: "center"});


    doc.setFontSize(10)
    doc.line(10, doc.internal.pageSize.height - 9, 194, doc.internal.pageSize.height - 9); // vertical line
    doc.text('Página: ' + numberPage + '/3' , 180, 285);
    doc.setFontSize(6);

    doc.text("NIF: " + empresa.taxRegistrationNumber + " - " + empresa.companyName + " / " + empresa.addressDetail + " / " + (empresa.telefone == null ? '' : empresa.telefone) + " / " + (empresa.email == null ? '' : empresa.email), 105, doc.internal.pageSize.height - 6, null, null, 'center');
    doc.setFontSize(7);
    doc.text('Processado por programa validado nº 4/AGT119', 105, doc.internal.pageSize.height - 3, null, null, 'center');

    if (report == 'imprimir') {
      doc.autoPrint();
      window.open(doc.output("bloburl")); //opens the data uri in new window
    } else {
      doc.save(contrato.id + '.pdf'); /* download the file immediately on loading */
    }
  }
}
