
import { Component, OnInit, ViewChild } from "@angular/core";
import { ApiService } from "src/app/providers/http/api.service";
import { ConfigService } from "src/app/providers/config/config.service";
import { MatTableDataSource } from '@angular/material'
import { Observable, Subject } from "rxjs";
import { MatPaginator } from '@angular/material/paginator';
import * as moment from 'moment';
import { ExcelAutoService } from 'src/app/services/excel/excel-auto.service';
import {
  debounceTime,
  finalize
} from "rxjs/operators";
import { HttpParams } from "@angular/common/http";

export class Filter {
  search: string = "";
  contrato_id: string = "";
  orderBy: string = "";
  typeOrderBy: string = "";
  typeFilter: string = "";
  is_facturado: string = "0";
  municipio: string = "";
  rota : string = ""
  constructor() { }
}

export class Pagination {
  lastPage: number;
  page: number = 1;
  perPage: number = 10;
  total: number;
  deserialize(input): this {
    return Object.assign(this, input);
  }
}

export class Charge {
  charge_id: number;
  contrato_id: number;
  produto: string;
  cliente: string;
  valor_new: number;
  valor_old: number;
  quantidade: number;
  quantidade_new: number;
  observacao: string;
}

export class Group {
  level = 0;
  parent: Group;
  expanded = false;
  totalCounts = 0;
  total = 0;
  qunatidade = 0;
  conta = 0;
  contrato = 0;
  nome = ""
  get visible(): boolean {
    return !this.parent || (this.parent.visible && this.parent.expanded);
  }
}



@Component({
  selector: 'app-facturacao-table',
  templateUrl: './facturacao-table.component.html',
  styleUrls: ['./facturacao-table.component.css'],
})

export class FacturacaoTableComponent implements OnInit {

  public pagination = new Pagination();
  public filter = new Filter();
  public charge = new Charge();

  submitted = false;
  loading = false;
  private charges: any;
  private localUrl: any;
  private dadosDoExcel = [];

  observableObj: Observable<any>;
  subjectObj = new Subject<number>();

  public dataSource = new MatTableDataSource<any | Group>([]);
  _alldata: any[];
  columns: any[];
  displayedColumns: string[];
  groupByColumns: string[] = [];
  municipios: string[] = [];
  rotas: string[] = [];



  constructor(
    private excelService: ExcelAutoService,
    private http: ApiService,
    private configService: ConfigService,
    private api: ApiService

  ) {
    this.columns = [
      {
        field: '#'
      },
      {
        field: 'Cliente'
      },
      {
        field: 'Município'
      },
      {
        field: 'Nº Conta'
      },
      {
        field: 'Contrato'
      },
      {
        field: 'Produto'
      },
      {
        field: 'Quantidade'
      },
      {
        field: 'Unidade'
      },
      {
        field: 'Valor'
      },
      {
        field: 'Total'
      },
      {
        field: 'Iva'
      },
      {
        field: 'COMIVA'
      },
      {
        field: 'Tipo'
      },
      {
        field: 'Estado'
      },
      {
        field: 'Opções'
      }
    ];
    this.displayedColumns = this.columns.map(column => column.field);
    this.groupByColumns = ['contrato_id'];
  }
  public loadings = {
    municipios: null
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit() {
    this.empresaUser();
    this.subjectObj.pipe(debounceTime(1000)).subscribe({
      next: () => this.listarCharge(),

    });
    this.subjectObj.next(1);
  }

  private empresaUser() {
    this.configService.loaddinStarter('start');
    this.http.get('empresa/empresa-user', null).subscribe(
      response => {
      this.localUrl = Object(response).data.logotipo
      this.configService.loaddinStarter('stop');
      }
    );
  }

  private listarCharge() {
    var params = new HttpParams();
    params = this.http.params
      .set("page", (Number.isInteger(this.pagination.page) || 1).toString())
      .set("search", this.filter.search.toString())
      .set("orderBy", this.filter.orderBy.toString())
      .set("typeOrderBy", this.filter.typeOrderBy.toString())
      .set("typeFilter", this.filter.typeFilter.toString())
      .set("is_facturado", this.filter.is_facturado.toString())
      .set("municipio", this.filter.municipio.toString())
      .set("rota", this.filter.rota.toString());
    this.http
      .get(`charge/listar-pre-facturacao`, params)
      .pipe(
        debounceTime(1000),
        finalize(() => {
          this.loading = false;
        })
      ).subscribe((response) => {

        let data = Object(response).data

        this.pagination.perPage = this.pagination.perPage

        this.charges = data.charges;

        this._alldata = this.charges;

        console.log(data);

        this.municipios = data.municipios
        this.rotas = data.rotas

        this.dataSource.data = this.addGroups(
          this._alldata,
          this.groupByColumns
        );


        this.dataSource.filterPredicate = this.customFilterPredicate.bind(this);
        this.dataSource.filter = performance.now().toString();


      });

      this.filter = {
        search : "",
        contrato_id:  "",
        orderBy:  "",
        typeOrderBy:  "",
        typeFilter:  "",
        is_facturado:  "0",
        municipio:  "",
        rota :  ""
      }

  }

  exportAsXLSX(): void {

    var CurrentDate = new Date();
    this.configService.loaddinStarter('start');
    this.http.get('charge/exportar-pre-facturacao', null) .pipe(
      debounceTime(1000),
      finalize(() => {
        this.loading = false;
      })
    ).subscribe(
      response => {
        this.dadosDoExcel = Object(response).data

          var keys = [
            { key: 'cliente', width: 40, style: { font: { name: 'Calibri' } } },
            { key: 'municipio', width: 25 },
            { key: 'conta_id', width: 25 },
            { key: 'contrato_id', width: 25 },
            { key: 'produto', width: 40 },
            { key: 'quantidade', width: 40  },
            { key: 'unidade', width: 40  },
            { key: 'valor', width: 25,style: { numFmt: '#,##0.00', } },
            { key: 'totalSemIva', width: 25 ,style: { numFmt: '#,##0.00', }},
            { key: 'facturacao', width: 25 },
            { key: 'is_facturado', width: 40 },
          ];
          var Cols = ["Cliente", "Município", "Nº de Conta","Contrato", "Produto", "Quantidade","Unidade","Valor Unitário","Total Sem Iva","Tipo","Estado"]
          var title = 'Listagem de Pré-Facturacão'
          var nameFile = "Listar Pre-Facturacao -" + moment(CurrentDate).format('DD-MM-YYYY HH:mm')
          this.excelService.excels(this.dadosDoExcel, nameFile,this.localUrl, keys, Cols, title, 5, 11, 40, 3)
          this.configService.loaddinStarter('stop');
      }
    );

  }

  getPageFilterData(page) {
    this.pagination.perPage == this.pagination.perPage
  }

  groupBy(event, column) {
    event.stopPropagation();
    this.checkGroupByColumn(column.field, true);
    this.dataSource.data = this.addGroups(this._alldata, this.groupByColumns);
    this.dataSource.filter = performance.now().toString();
  }

  checkGroupByColumn(field, add) {
    let found = null;
    for (const column of this.groupByColumns) {
      if (column === field) {
        found = this.groupByColumns.indexOf(column, 0);
      }
    }
    if (found != null && found >= 0) {
      if (!add) {
        this.groupByColumns.splice(found, 1);
      }
    } else {
      if (add) {
        this.groupByColumns.push(field);
      }
    }
  }

  clearSearch() {
    this.filter.search = "";
  }

  unGroupBy(event, column) {
    event.stopPropagation();
    this.checkGroupByColumn(column.field, false);
    this.dataSource.data = this.addGroups(this._alldata, this.groupByColumns);
    this.dataSource.filter = performance.now().toString();
  }

  // below is for grid row grouping
  customFilterPredicate(data: any | Group, filter: string): boolean {
    return data instanceof Group ? data.visible : this.getDataRowVisible(data);
  }
  getDataRowVisible(data: any): boolean {
    const groupRows = this.dataSource.data.filter(row => {
      if (!(row instanceof Group)) {
        return false;
      }
      let match = true;
      this.groupByColumns.forEach(column => {
        if (!row[column] || !data[column] || row[column] !== data[column]) {
          match = false;
        }
      });
      return match;
    });

    if (groupRows.length === 0) {
      return true;
    }
    const parent = groupRows[0] as Group;
    return parent.visible && parent.expanded;
  }

  groupHeaderClick(row) {
    row.expanded = !row.expanded;
    this.dataSource.filter = performance.now().toString();
  }

  addGroups(data: any[], groupByColumns: string[]): any[] {
    const rootGroup = new Group();
    rootGroup.expanded = true;
    return this.getSublevel(data, 0, groupByColumns, rootGroup);
  }

  getSublevel(
    data: any[],
    level: number,
    groupByColumns: string[],
    parent: Group
  ): any[] {
    if (level >= groupByColumns.length) {
      return data;
    }
    const groups = this.uniqueBy(
      data.map(row => {
        const result = new Group();
        result.level = level + 1;
        result.parent = parent;
        for (let i = 0; i <= level; i++) {
          result[groupByColumns[i]] = row[groupByColumns[i]];
        }
        return result;
      }),
      JSON.stringify
    );

    const currentColumn = groupByColumns[level];
    let subGroups = [];
    groups.forEach(group => {
      const rowsInGroup = data.filter(
        row => group[currentColumn] === row[currentColumn]
      );

      rowsInGroup.forEach((item, index) => {
        item.ordenacao = index + 1;
      });

      const total = rowsInGroup.reduce(function (acumulador, valorAtual) {
        let qtd = 0
        if (valorAtual.produto.incidencia.abreviacao == 'M3' && (valorAtual.facturacao != "SANEAMENTO")) {
          qtd = valorAtual.quantidade
        }
        else {
          qtd = 0
        }
        return {
          total: acumulador.total + (valorAtual.quantidade * valorAtual.valor),
          quantidade: acumulador.quantidade + qtd,
          conta: valorAtual.conta_id,
          contrato: valorAtual.contrato.id,
          nome: valorAtual.contrato.conta.cliente.nome,
        }
      }, {
        total: 0,
        quantidade: 0,
        conta: 0,
        contrato: 0,
        nome: "",
      });


      group.totalCounts = rowsInGroup.length;
      group.total = total.total
      group.quantidade = total.quantidade
      group.conta = total.conta
      group.contrato = total.contrato
      group.nome = total.nome

      const subGroup = this.getSublevel(
        rowsInGroup,
        level + 1,
        groupByColumns,
        group
      );
      subGroup.unshift(group);
      subGroups = subGroups.concat(subGroup);
    });
    return subGroups;
  }

  uniqueBy(a, key) {
    const seen = {};
    return a.filter(item => {
      const k = key(item);
      return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    });
  }

  isGroup(index, item): boolean {
    return item.level;
  }


  private updateValorCharge() {
    if (this.charge.valor_new == null) {
      this.configService.showAlert(
        "É obrigatório fornecer Valor Novo",
        "alert-danger",
        true
      );
      return;
    } else if (this.charge.valor_new < 0) {
      this.configService.showAlert(
        "É obrigatório fornecer Valor Novo superior que zero",
        "alert-danger",
        true
      );
      return;
    } else if (this.charge.valor_new == this.charge.valor_old) {
      this.configService.showAlert(
        "É obrigatório fornecer Valor Novo diferente do valor Actual",
        "alert-danger",
        true
      );
      return;
    } else if (this.charge.observacao == null) {
      this.configService.showAlert(
        "É obrigatório motivo de actualização do valor.",
        "alert-danger",
        true
      );
      return;
    }
    this.loading = true;
    this.http
      .put(`charge/update/${this.charge.charge_id}`, this.charge)
      .subscribe(
        (response) => {
          this.charge.valor_old = this.charge.valor_new;
          this.charge.quantidade = this.charge.quantidade_new;
          this.charge.valor_new = null;
          this.charge.quantidade_new = null;
          this.listarCharge();
          this.loading = false;
        },
        (error) => {
          this.loading = false;
        }
      );
  }

  setCharge(charge) {
    this.charge.charge_id = charge.id;
    this.charge.valor_old = charge.valor;
    this.charge.quantidade = charge.quantidade;
    this.charge.produto = charge.produto.nome;
    this.charge.cliente = charge.contrato.conta.cliente.nome;
    this.charge.contrato_id = charge.contrato.id;
  }

  private anularCharge() {
    if (this.charge.observacao == null) {
      this.configService.showAlert(
        "É obrigatório Escrever o motivo da anulação",
        "alert-danger",
        true
      );
      return;
    }
    this.loading = true;
    this.http
      .put(`charge/anular/${this.charge.charge_id}`, this.charge)
      .subscribe(
        (response) => {
          this.listarCharge();
          this.loading = false;
        },
        (error) => {
          this.loading = false;
        }
      );
  }

  private revert_anulacao() {
    this.loading = true;
    this.http
      .put(`charge/revert_anulacao/${this.charge.charge_id}`, this.charge)
      .subscribe(
        (response) => {
          this.listarCharge();
          this.loading = false;
        },
        (error) => {
          this.loading = false;
        }
      );
  }
  reset() {
    this.charge = new Charge();
  }

}
