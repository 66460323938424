import { Component, OnInit, Input,Output, EventEmitter,OnChanges, SimpleChange } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

@Component({
  selector: 'createOrEdittarifario',
  templateUrl: './create-or-edit-tarifario.component.html',
  styleUrls: ['./create-or-edit-tarifario.component.css']
})
export class CreateOrEditTarifarioComponent implements OnInit {

  @Input() modal: string = "modaltarifarioCreateOrEdit";
  @Input() title: string = "Registar tarifario";
  @Input() tarifario:any;

  submitted = false;
  private loading: boolean = false;
  @Input() simpleFormtarifario: FormGroup; 

  @Output() private loadListtarifario = new EventEmitter<any>();
  
  private planoprecos: any = [];
  private tecnologias: any = [];

  constructor(private http: HttpService, private configService: ConfigService, private formBuilder: FormBuilder) {
    this.createForm();
  }

  ngOnInit() {
    this.SelectPlanoPreco();
    this.selectBoxTecnologias();
  }

  createForm() {
    this.simpleFormtarifario = this.formBuilder.group({
      index: [{ value: null, disabled: true }],
      plano_preco_id: [null, Validators.required],
      condicoes: [null, Validators.required],
      descricao: [null, Validators.required],
      credMensalInDefault: [null, Validators.required],
      estado: [null, Validators.required],
      dataEstado: [null, Validators.required],
      tecnologia_id: [null, Validators.required],
      
      
    });
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.simpleFormtarifario.controls;
  }

  onReset() {
    this.submitted = false;
    this.simpleFormtarifario.reset();
  }


  onSubmit() {

    this.submitted = true;

    // parar aquei se o formulário for inválido
    if (this.simpleFormtarifario.invalid) {
      return;
    }
    this.loading = true;
    const index = this.simpleFormtarifario.getRawValue().index;
    // TODO: usado para fazer a requisição com a api de criação de objsct or update
    const uri = (index === null ? 'tarifario/create' : 'tarifario/update/' + index);
    this.createOrEdit(uri, this.simpleFormtarifario, (index === null ? true : false));

  }

  createOrEdit(uri: any, formulario: FormGroup, isCreate: boolean) {

    // TODO: usado para fazer a requisição com a api de criação de object
    this.http.__call(uri, formulario.value).pipe(first()).subscribe(
      response => {
        this.submitted = false;
        this.loading = false;
        if (isCreate) {
          formulario.reset();
          this.loadListtarifarios(Object(response).data);
        }  
        
        if (Object(response).code ==200) {
          this.loadListtarifarios(Object(response).data);
        }
      },
      error => {
        this.submitted = false;
        this.loading = false;
      });
  }




  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {

    if (this.tarifario !== undefined ) {
      this.title = "Editar tarifario";
      this.simpleFormtarifario.patchValue({
        index: this.tarifario.id,
        plano_preco_id: this.tarifario.plano_preco_id,
        condicoes: this.tarifario.condicoes,
        descricao: this.tarifario.descricao,
        credMensalInDefault: this.tarifario.credMensalInDefault,
        estado: this.tarifario.estado,
        dataEstado: this.tarifario.dataEstado,
        tecnologia_id: this.tarifario.tecnologia_id
      });
    } else {
      this.onReset()
      this.title = "Registar tarifario";
    }
  }

  private SelectPlanoPreco() {
    this.http.__call('planoPreco/show', null).subscribe(
      data => {
        this.planoprecos = data;
      }
    );
  }

  private selectBoxTecnologias() { 
    this.http.__call("tecnologia/selectBox", { tipoFacturacao: ['POS-PAGO','PRE-PAGO'] }).subscribe(
      res => {
        this.tecnologias = Object(res).data 
      }
    );
  }
  

  public loadListtarifarios(tarifario) { 
    this.loadListtarifario.emit(tarifario);
  }


}