import { Component, OnInit, Input } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { ClassificacaoService } from '../classificacao.service';

@Component({
    selector: 'modal-tipo-classificacao',
    templateUrl: './create-or-edit-tipo-classificacao.component.html'
})

export class CreateOrEditTipoClassificacaoComponent implements OnInit {

    @Input() classificacao: any;
    
    constructor(
        private http: HttpService,
        private configService: ConfigService,
        private _classificacaoService: ClassificacaoService
    ){}

    ngOnInit(){

    }

    _submitForm(){
        this.configService.loaddinStarter('start');

        if (Boolean(this.classificacao.descricao)) {
            if (this.classificacao.id) {
                this._update()
            } else {
                this._add()
            }
        }else{
            this.configService.showAlert("A descrição é obrigatório", 'alert-danger', true);
        }
        this.configService.loaddinStarter('stop');
    }

    _add(){
        this._classificacaoService.CreateClassificacao( this.classificacao )
        .subscribe( response => {
            this.configService.showAlert(response.message, 'alert-success', true);
            this._cleanForm();
        }),
        (error) => {
            if (!error.ok) {
                this.configService.showAlert(error.message, 'alert-danger', true);
            }
        }
    }

    _update(){
        this._classificacaoService.UpdateTipoCliente( this.classificacao )
        .subscribe( response => {
            this.configService.showAlert(response.message, 'alert-success', true);
        }),
        (error) => {
            if (!error.ok) {
                this.configService.showAlert(error.message, 'alert-danger', true);
            }
        }
    }

    _cleanForm(){
        this.classificacao = {
            id: null,
            tipoClienteDesc: null
        }
    }


}


