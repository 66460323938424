import { Component, OnInit, ViewChild } from "@angular/core";
import { HttpService } from "../../providers/http/http.service";
import { ConfigService } from "src/app/providers/config/config.service";
import * as moment from "moment";
@Component({
  selector: "app-new-ligacao-ramal",
  templateUrl: "./new-ligacao-ramal.component.html",
  styleUrls: ["./new-ligacao-ramal.component.css"],
})
export class NewLigacaoRamalComponent implements OnInit {
  @ViewChild("search") search;
  @ViewChild("qtd") qtd;

  @ViewChild("valor") valor;
  @ViewChild("serie") serie;
  @ViewChild("observacao") observacao;

  private desconto: number = 0;

  private dataActual = new Date();

  private pontoB: any = [];

  private objectoRamal = {
    id: null,
    descricao: null,
  };

  private activeButtom = {
    cliente: false,
    produto: false,
  };

  private ligacao_ramal = {
    id: null,
    ponto_a_id: null,
    ponto_b_id: null,
    pontoA: null,
    pontoB: null,
    comprimento: null,
    profundidade: null,
    codigo_remal: null,
    morada_ramals: null,
    tipo_ramal_id: null,
    diametro_ramal_id: null,
    material_ramal_id: null,
    estado_ramal_id: null,

    fita_sinalizacao: null,
    valvula_ramal: null,
    latitude: null,
    longitude: null,
  };

  private pontoFinal = {
    id: null,
    value: null,
    rota: null,
    slug: null,
  };

  private residencia = {
    id: null,
    numero: null,
    building: null,
    building_id: null,
    nome: null,
    is_predio: null,
    rua_id: null,
    rua: null,
    quarteirao_id: null,
    quarteirao: null,
    bairro_id: null,
    bairro: null,
    has_distrito: false,
    has_quarteirao: false,
    municipio_id: null,
    distrito_id: null,
    provincia_id: null,
    is_active: null,
  };

  private itemList = {
    item: {
      linhas: [],
    },
  };

  view_ponto_b = false;

  private validarFacturaRecibo: boolean = false;

  private predio_flag: boolean = false;

  private contas_clientes: any = [];
  private servicos_conta_cliente: any = [];

  private tipo_objectos: any = [];

  private numero_telefone: string;
  private searchCliente: string;

  private isFinalizar: boolean = false;

  private tipoObjecto = {
    id: null,
    descricao: null,
    tipo_objecto_b_id: null,
  };

  private interest = {
    id: null,
  };

  private selected_objecto = {
    id: null,
    descricao: null,
    latitude: null,
    longitude: null,
  };

  private items: any = [];
  private pagination = {
    start: 1,
    end: 10,
    search: null,
    servico: null,
  };

  private provincias: any = [];
  private municipios: any[];
  private distritos: any[];
  private bairros: any[];
  private quarteiraos: any[];
  private residencias: any[];

  private ruas: any[];
  private tipo_ramals: any[];
  private diametro_ramals: any[];
  private material_ramals: any[];

  private produto_selecionado = null;
  private preco: number;

  constructor(
    private http: HttpService,
    private configService: ConfigService
  ) {}
  ngOnInit() {
    this.getTiposRamal();
  }

  private getTiposRamal() {
    this.http
      .call_get("tipo-objecto-tecnico/selectBox", null)
      .subscribe((response) => {
        this.tipo_objectos = Object(response).data;
      });
  }

  private selectBoxTipoRamals() {
    this.http.call_get("tipo-ramal/selectBox", null).subscribe((response) => {
      //console.log(Object(response));
      this.tipo_ramals = Object(response);
    });
  }

  private selectBoxDiametroRamals() {
    this.http
      .call_get("diametro-ramal/selectBox", null)
      .subscribe((response) => {
        //console.log(Object(response));
        this.diametro_ramals = Object(response);
      });
  }

  private selectBoxMaterialRamals() {
    this.http
      .call_get("material-ramal/selectBox", null)
      .subscribe((response) => {
        //console.log(Object(response));
        this.material_ramals = Object(response);
      });
  }

  private getProvincias() {
    this.http.call_get("provincia/selectBox", null).subscribe((response) => {
      this.provincias = Object(response).data;
    });
  }

  private selectBoxMunicipiosByProvincia() {
    //console.log(this.rua);

    this.http
      .call_get(
        "municipio/getMunicipiosByProvincia/" + this.residencia.provincia_id,
        null
      )
      .subscribe((response) => {
        //console.log(Object(response).data);

        this.municipios = Object(response).data;
      });
  }

  private selectBoxDistritosByMunicipio() {
    for (let i = 0; i < this.municipios.length; ++i) {
      if (this.municipios[i].id == this.residencia.municipio_id)
        this.residencia.has_distrito = this.municipios[i].has_distrito;
    }

    if (this.residencia.has_distrito) {
      this.http
        .call_get(
          "distrito/getDistritosByMunicipio/" + this.residencia.municipio_id,
          null
        )
        .subscribe((response) => {
          this.distritos = Object(response).data;
        });
    }

    if (!this.residencia.has_distrito) {
      this.selectBoxBairrosByMunicipio();
    }
  }

  
  private selectBoxRuasByQuarteirao() {
    this.http
      .call_get(
        "rua/getRuasByQuarteirao/" + this.residencia.quarteirao_id,
        null
      )
      .subscribe((response) => {
        this.ruas = Object(response).data;
      });
  }
  
  private selectBoxQuarteiraosByBairro() {
    this.residencia.quarteirao_id = null;
    this.residencia.rua_id = null;

    for (let i = 0; i < this.bairros.length; ++i) {
      if (this.bairros[i].id == this.residencia.bairro_id)
        this.residencia.has_quarteirao = this.bairros[i].has_quarteirao;
    }

    if (this.residencia.has_quarteirao) {
      this.http
        .call_get(
          "quarteirao/getQuarteiraosByBairro/" +
            this.residencia.bairro_id,
          null
        )
        .subscribe((response) => {
          this.quarteiraos = Object(response).data;
        });
    }

    if (!this.residencia.has_quarteirao) {
      this.selectBoxRuasByBairro();
    }
  }


  private selectBoxRuasByBairro() {
    //console.log(this.residencia);

    this.http
      .call_get("rua/selectBoxByBairro/" + this.residencia.bairro_id, null)
      .subscribe((response) => {
        this.ruas = Object(response);
      });
  }

  private selectBoxBairrosByMunicipio() {
    //console.log(this.residencia);

    this.http
      .call_get(
        "bairro/selectBoxByMunicipio/" + this.residencia.municipio_id,
        null
      )
      .subscribe((response) => {
        this.bairros = Object(response);
      });
  }

  private getSelectBoxResidenciasByRua() {
    this.residencia.rua_id;
    this.getResidencia();
  }

  view_residencia = false;

  private getResidencia() {
    if (this.residencia.nome == "") {
      this.setNullResidencia();
    }

    this.view_residencia = true;
    this.http
      .__call("local-instalacao/ligacaoRamLocalInstalacaosByRua", {
        rua_id: this.residencia.rua_id,
        start: 1,
        end: 10,
        search: this.residencia.numero,
      })
      .subscribe((response) => {
        this.residencias = Object(response).data.data;

        this.configService.loaddinStarter("stop");
      });
  }

  private setResidencia(codigo, numero, nome, andar, is_predio) {
    this.residencia.id = codigo;
    this.residencia.numero = numero;
    this.residencia.nome =
      (is_predio
        ? "Prédio " +
          nome +
          ", " +
          (andar ? andar + " º Andar - " : "") +
          "Porta "
        : "Residência ") + numero;
    this.residencia.is_predio = is_predio;
    this.view_residencia = false;
  }

  private setNullResidencia() {
    this.residencia.id = null;
    this.residencia.numero = null;
    this.residencia.nome = null;
    (this.residencia.is_predio = null), (this.view_residencia = false);

    this.predio_flag = false;
  }

  private selectedTipoObjecto(item) {
    setTimeout(() => {
      console.log(item);
    }, 1500);

    this.selected_objecto.id = null;
    this.selected_objecto.descricao = null;
    this.selected_objecto.latitude = null;
    this.selected_objecto.longitude = null;

    this.itemList = {
      item: {
        linhas: [],
      },
    };
    this.getObjectoRamal();
  }

  private selectedObjectoRamal(item) {
    this.selected_objecto.id = item.id;
    this.selected_objecto.descricao = item.descricao;
    this.selected_objecto.latitude = item.latitude;
    this.selected_objecto.longitude = item.longitude;
  }

  private getTipoObjectoLigacaoFinal() {
    this.ligacao_ramal.pontoB = null;
    this.ligacao_ramal.ponto_b_id = null;

    this.pontoB = [];

    if (this.ligacao_ramal.pontoB == "" || this.ligacao_ramal.pontoB == null) {
      this.setNullPontoB();
    }

    this.view_ponto_b = true;
    this.http
      .__call("search/fim/ligacao", {
        start: 1,
        end: 10,
        tipo_objecto_id: this.tipoObjecto.tipo_objecto_b_id,
        search: this.ligacao_ramal.pontoB,
      })
      .subscribe((res) => {
        console.log(Object(res).data);

        this.pontoB = Object(res).data.data;
      });
  }

  private setPontoB(id, descricao) {
    this.ligacao_ramal.ponto_b_id = id;
    this.ligacao_ramal.pontoB = descricao;

    this.view_ponto_b = false;
  }

  private setNullPontoB() {
    this.ligacao_ramal.ponto_b_id = null;
    this.ligacao_ramal.pontoB = null;
    this.view_ponto_b = false;
  }

  private getObjectoRamal() {
    this.items = [];
    this.configService.loaddinStarter("start");

    this.http
      .__call("search/inicio/ligacao", {
        start: 1,
        end: 10,
        tipo_objecto_id: this.tipoObjecto.id,
        search: this.objectoRamal.descricao,
      })
      .subscribe((res) => {
        this.items = Object(res).data.data;

        this.configService.loaddinStarter("stop");
      });
  }

  private getObjectoRamalPontoFinal() {
    this.items = [];
    this.configService.loaddinStarter("start");

    this.http
      .__call("search/inicio/ligacao", {
        start: 1,
        end: 10,
        tipo_objecto_id: this.tipoObjecto.id,
        search: this.objectoRamal.descricao,
      })
      .subscribe((res) => {
        this.items = Object(res).data.data;

        this.configService.loaddinStarter("stop");
      });
  }

  private reloadFacturacao() {
    location.reload();
  }

  private getRotaModal() {
    this.interest.id = this.pontoFinal.rota;

    if (this.interest.id == "pontoBObjectoRamalModal") {
      this.pontoFinal.slug = "OBJECTO-DO-RAMAL";
    } else {
      this.pontoFinal.slug = "LOCAL-INSTALACAO";
    }
  }

  private confirmarPontoB() {
    this.activeButtom.cliente = false;

    if (this.pontoFinal.rota == null) {
      this.configService.showAlert(
        "Seleccione onde fará a ligação",
        "alert-danger",
        true
      );
    } else {
      this.activeButtom.cliente = true;
    }

    this.selectBoxTipoRamals();
    this.selectBoxDiametroRamals();
    this.selectBoxMaterialRamals();
  }

  private cancelarSelectedObject() {
    this.selected_objecto.id = null;
    this.selected_objecto.descricao = null;
    this.selected_objecto.latitude = null;
    this.selected_objecto.longitude = null;

    this.interest.id = null;
    this.pontoFinal.rota = null;
    this.activeButtom.cliente = false;
  }

  private addLinha() {
    console.log(this.ligacao_ramal);

    var validar = 0;

    if (
      this.pontoFinal.slug == "OBJECTO-DO-RAMAL" &&
      this.tipoObjecto.tipo_objecto_b_id == null
    ) {
      this.configService.showAlert(
        "Seleccione o tipo de objeto",
        "alert-danger",
        true
      );
    } else if (
      this.pontoFinal.slug == "OBJECTO-DO-RAMAL" &&
      this.ligacao_ramal.ponto_b_id == null
    ) {
      this.configService.showAlert(
        "Seleccione a descrição",
        "alert-danger",
        true
      );
    } else if (
      this.pontoFinal.slug == "LOCAL-INSTALACAO" &&
      this.residencia.id == null
    ) {
      this.configService.showAlert(
        "Seleccione o local de instalação",
        "alert-danger",
        true
      );
    } else {
      console.log("cheguei 0")

      var linha = {
        descricao:
          this.residencia.id != null
            ? this.residencia.nome
            : this.ligacao_ramal.pontoB,
        codigo:
          this.residencia.id != null
            ? this.residencia.id
            : this.ligacao_ramal.ponto_b_id,
        slug: this.pontoFinal.slug,
        codigo_remal: this.ligacao_ramal.codigo_remal,
        morada_ramals: this.ligacao_ramal.morada_ramals,
        comprimento: this.ligacao_ramal.comprimento,
        profundidade: this.ligacao_ramal.profundidade,

        tipo_ramal_id: this.ligacao_ramal.tipo_ramal_id,
        diametro_ramal_id: this.ligacao_ramal.diametro_ramal_id,
        material_ramal_id: this.ligacao_ramal.material_ramal_id,

        fita_sinalizacao: this.ligacao_ramal.fita_sinalizacao,
        valvula_ramal: this.ligacao_ramal.valvula_ramal,
        latitude: this.ligacao_ramal.latitude,
        longitude: this.ligacao_ramal.longitude,
      };

      if (this.itemList.item.linhas.length >= 1) {
        console.log("cheguei >=1")

        for (let index = 0; index < this.itemList.item.linhas.length; index++) {
          const l = this.itemList.item.linhas[index];

          if (l.codigo == linha.codigo && l.slug == linha.slug) {
            this.configService.showAlert(
              "Esse ponto de ligação já se encontra na lista",
              "alert-danger",
              true
            );
            this.itemList.item.linhas.splice(index, 0, linha);
            this.itemList.item.linhas.splice(index, 1);
            validar = 1;
          }
        }

        if (validar == 0) {
          this.isFinalizar = true;

          console.log("cheguei 2")

          this.itemList.item.linhas.push(linha);

          setTimeout(() => {
            this.getCleanData()
          }, 1000);
        }
      } else {
        console.log("cheguei 1")

        if (linha.codigo == this.selected_objecto.id) {
          this.configService.showAlert(
            "Não é permitido ligar um ponto a ele mesmo!",
            "alert-danger",
            true
          );
        } else {
          this.isFinalizar = true;

          console.log("cheguei 2")


          this.itemList.item.linhas.push(linha);

          setTimeout(() => {
            this.getCleanData()

            
            console.log("itemList.item.linhas")
            console.log(this.itemList.item.linhas)
          }, 1000);


        }
      }
    }
  }

  
  private getCleanData(){
    this.residencia.numero = null;
    this.residencia.nome = null;
    this.residencia.id = null;
    this.ligacao_ramal.pontoB = null;
    this.ligacao_ramal.ponto_b_id = null;
    this.ligacao_ramal.comprimento = null;
    this.ligacao_ramal.profundidade = null;
    this.tipoObjecto.tipo_objecto_b_id = null;

    this.ligacao_ramal.tipo_ramal_id = null;
    this.ligacao_ramal.diametro_ramal_id = null;
    this.ligacao_ramal.material_ramal_id = null;
    this.ligacao_ramal.estado_ramal_id = null;

    this.ligacao_ramal.fita_sinalizacao = null;
    this.ligacao_ramal.valvula_ramal = null;
    this.ligacao_ramal.latitude = null;
    this.ligacao_ramal.longitude = null;}

  deleteRow(linha: any) {
    for (let i = 0; i < this.itemList.item.linhas.length; ++i) {
      if (this.itemList.item.linhas[i].codigo === linha.codigo) {
        this.itemList.item.linhas.splice(i, 1);
      }
    }
  }

  private cleanFormsData() {
    this.pontoFinal.rota = null;
    this.residencia.numero = null;
    this.residencia.nome = null;
    this.residencia.id = null;
    this.ligacao_ramal.pontoB = null;
    this.ligacao_ramal.ponto_b_id = null;
    this.ligacao_ramal.comprimento = null;
    this.tipoObjecto.tipo_objecto_b_id = null;
    this.ligacao_ramal.pontoB = null;
    this.ligacao_ramal.profundidade = null;
    this.activeButtom.cliente = false;

    this.ligacao_ramal.tipo_ramal_id = null;
    this.ligacao_ramal.diametro_ramal_id = null;
    this.ligacao_ramal.material_ramal_id = null;
    this.ligacao_ramal.estado_ramal_id = null;

    this.ligacao_ramal.fita_sinalizacao = null;
    this.ligacao_ramal.valvula_ramal = null;
    this.ligacao_ramal.latitude = null;
    this.ligacao_ramal.longitude = null;
  }

  private guardarDados() {
    this.isFinalizar = false;
    this.configService.loaddinStarter("start");

    this.http
      .__call("ligacao-ramal/create", {
        ponto_a_id: this.selected_objecto.id,
        ligacao: this.itemList.item,
      })
      .subscribe((res) => {
        if (Object(res).code == 200) {
          this.configService.showAlert(
            Object(res).message,
            "alert-success",
            true
          );
          this.resetDados();
          this.isFinalizar = false;
        } else {
          this.configService.showAlert(
            Object(res).message,
            "alert-danger",
            true
          );
          this.isFinalizar = true;
        }

        this.configService.loaddinStarter("stop");
      });
  }

  private resetDados() {
    this.residencia = {
      id: null,
      numero: null,
      building: null,
      building_id: null,
      nome: null,
      is_predio: null,
      rua_id: null,
      rua: null,
      quarteirao_id: null,
      quarteirao: null,
      bairro_id: null,
      bairro: null,
      has_distrito: false,
      has_quarteirao: false,
      municipio_id: null,
      distrito_id: null,
      provincia_id: null,
      is_active: null,
    };

    this.tipoObjecto.id = null;

    this.selected_objecto.id = null;
    this.selected_objecto.descricao = null;
    this.selected_objecto.latitude = null;
    this.selected_objecto.longitude = null;

    this.items = [];
    this.itemList = {
      item: {
        linhas: [],
      },
    };
  }
}
