import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/providers/config/config.service';
import { HttpService } from 'src/app/providers/http/http.service';

@Component({
  selector: 'app-objecto-ligacao-ramal',
  templateUrl: './objecto-ligacao-ramal.component.html',
  styleUrls: ['./objecto-ligacao-ramal.component.css']
})
export class ObjectoLigacaoRamalComponent implements OnInit, OnDestroy {
  private objecto_ligacao = {
    id: null,

    estado_objecto: null,
    tipo_escoamento: null,
    conduta: null,
    funcao_de_rede: null,
    sistema: null,
    subsistema_id: null,
    tipo_de_rede: null,
    material_ramal_id: null,
    diametro_ramal_id: null,
    pressao_nominal_id: null,
    fita_sinalizacao: null,
    comprimento: null,
    profundidade: null,

    descricao: null,
    latitude: null,
    longitude: null,
    tipo_objecto_id: null,
    tipo_objecto_tecnico: null,
  }

  private items: any = [];
  private tipo_objecto: any = [];
  private diametro_ramals: any[];
  private material_ramals: any[];
  private pressao_nominal: any[];
  private susbsitema: any[];

  constructor(private http: HttpService, private configService: ConfigService) { }

  ngOnInit() {
    this.http.filters.search = null
    this.getPageFilterData(1);
    this.getTiposRamal();
    this.selectBoxDiametroRamals();
    this.selectBoxMaterialRamals();
    this.selectBoxSubsistema();
    this.selectBoxPressaoNominal();
  }
  ngOnDestroy() {
    this.http.filters.orderBy = null
  }

  private getTiposRamal() {
    this.http.call_get('tipo-objecto-tecnico/selectBox', null).subscribe(
      response => {
        this.tipo_objecto = Object(response).data;
      }
    );
  }


  private ListarObjectoLigacao() {

    this.configService.loaddinStarter('start');

    this.http.__call('objecto-ligacao-ramal/listagem', this.http.filters).subscribe(

      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.configService.loaddinStarter('stop');

      }
    );
  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1;
    this.ListarObjectoLigacao();
  }


  private register(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (this.objecto_ligacao.descricao == "") {
      this.configService.showAlert("O campo Material é obrigatório", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    }
    else {
      this.http.__call('objecto-ligacao-ramal/create', this.objecto_ligacao).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
            this.configService.loaddinStarter('stop');
          }
          else {
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.clearFormInputs(e);
            this.ListarObjectoLigacao()
            this.configService.loaddinStarter('stop');
          }
        }
      )
    };
  }


  private clearFormInputs(e) {
    e.target.elements[0].value = null;
    e.target.elements[1].value = null;
    e.target.elements[2].value = null;
  }

  private refresh(item: any) {
    this.objecto_ligacao.id = item.id;
    this.objecto_ligacao.descricao = item.descricao;
    this.objecto_ligacao.diametro_ramal_id = item.diametro_ramal_id;
    this.objecto_ligacao.material_ramal_id = item.material_ramal_id;
    this.objecto_ligacao.estado_objecto = item.estado_objecto;
    this.objecto_ligacao.sistema = item.sistema;
    this.objecto_ligacao.subsistema_id = item.subsistema_id;
    this.objecto_ligacao.pressao_nominal_id = item.pressao_nominal_id;
    this.objecto_ligacao.conduta = item.conduta;
    this.objecto_ligacao.tipo_escoamento = item.tipo_escoamento;
    this.objecto_ligacao.tipo_de_rede = item.tipo_de_rede;
    this.objecto_ligacao.funcao_de_rede = item.funcao_de_rede;
    this.objecto_ligacao.fita_sinalizacao = item.fita_sinalizacao;
    this.objecto_ligacao.tipo_objecto_tecnico = item.tipo_objecto_tecnico;
    this.objecto_ligacao.profundidade = item.profundidade;
    this.objecto_ligacao.comprimento = item.comprimento;
    this.objecto_ligacao.latitude = item.latitude;
    this.objecto_ligacao.longitude = item.longitude;
    this.objecto_ligacao.tipo_objecto_id = item.tipo_objecto_id;
  }

  private editar(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (this.objecto_ligacao.descricao == "" || this.objecto_ligacao.descricao == null) {
      this.configService.showAlert("O campo Material é obrigatórios", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    }
    else {
      this.http.__call('objecto-ligacao-ramal/update/' + this.objecto_ligacao.id, this.objecto_ligacao).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          }
          else {
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.ListarObjectoLigacao();

          }
        }
      );
    }
    this.configService.loaddinStarter('stop');
  }

  private selectBoxDiametroRamals() {
    this.http
      .call_get("diametro-ramal/selectBox", null)
      .subscribe((response) => {
        this.diametro_ramals = Object(response);
      });
  }


  private selectBoxSubsistema() {
    this.http
      .call_get("subsistema/selectBox", null)
      .subscribe((response) => {
        this.susbsitema = Object(response);
      });
  }


  private selectBoxPressaoNominal() {
    this.http
      .call_get("pressao-nominal/selectBox", null)
      .subscribe((response) => {
        this.pressao_nominal = Object(response);
      });
  }

  private selectBoxMaterialRamals() {
    this.http
      .call_get("material-ramal/selectBox", null)
      .subscribe((response) => {
        //console.log(Object(response));
        this.material_ramals = Object(response);
      });
  }

  private ini() {
    this.objecto_ligacao = {
      id: null,
      estado_objecto: null,
      tipo_escoamento: null,
      conduta: null,
      funcao_de_rede: null,
      sistema: null,
      subsistema_id: null,
      tipo_de_rede: null,
      material_ramal_id: null,
      diametro_ramal_id: null,
      pressao_nominal_id: null,
      fita_sinalizacao: null,
      comprimento: null,
      profundidade: null,
      descricao: null,
      latitude: null,
      longitude: null,
      tipo_objecto_id: null,
      tipo_objecto_tecnico: null
    }
  }
}

