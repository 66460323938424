import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service'

import { ConfigService } from 'src/app/providers/config/config.service';

import * as moment from 'moment';
import { RotaService } from '../../config-rotas/rotas.service';
import { FacturaService } from '../factura.service';
import { ApiService } from 'src/app/providers/http/api.service';

import { finalize } from 'rxjs/operators';
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'


@Component({
    selector: 'app-factura-ciclo-individual',
    templateUrl: './factura-ciclo-individual.component.html',
    styleUrls: ['./factura-ciclo-individual.component.css']
})

export class FacturaCicloIndividualComponent implements OnInit {
    public filters = {
        search: null, // ordem de pesquisa de elemento
        orderBy: null, // Ordenação
        pagination: {
            perPage: 5,// Entrada - define o limite de resultados a serem gerados
            page: 1, //  define a pagina inicial ou proxima,
            lastPage: null,
            total: null // defini o total de registo da BD
        },
        filter: null
    }

    text_contador = null
    tipo_facturacao = null
    current_contrato: any = {}
    loading: boolean
    show_btn_continuar: boolean
    clientes: any = []
    readings: any = [];
    cliente: any = {}
    leituras: any = []
    linhas_charges: any = []
    contrato: any = {}
    local_consumo: any = {};
    validateButton: boolean = true;

    private series: any = [];
    private loadings = {
        series: false,
        process: false
    }

    leitura: any = {
        contador_id: null,
        contrato_id: null,
        leitura: null,
        data_leitura: moment(new Date()).format('YYYY-MM-DD'),
        nao_leitura: false,
        classificacao: null,
        tipo_facturacao: null
    }

    finalizar_facturacao: any = {
        contrato_id: null,
        serie_id: null,
        is_inprimir:1
    }

    motivos_nao_leitura_list: any = []

    constructor(
        private configService: ConfigService,
        private _rotaService: RotaService,
        private _facturaService: FacturaService,
        private api: ApiService,
        private http: HttpService,

    ) {
    }

    ngOnInit() {
        this.getSeries('FT');

        this.show_btn_continuar = false
        this.http.filters.search = null
        this.getPageFilterData(1);
    }

    getPageFilterData(page: number) {
        if (this.filters.pagination.perPage == null) {
            return;
        }
        this.filters.pagination.page = Number.isInteger(page) ? page : 1;
        this.listClientes()
    }

    async listClientes() {
        this.loading = true
        this._rotaService.getClientes(this.filters)
            .subscribe(
                response => {
                    this.clientes = [];
                    this.filters.pagination.lastPage = response.lastPage
                    this.filters.pagination.page = response.page
                    this.filters.pagination.total = response.total
                    this.filters.pagination.perPage = response.perPage
                    this.clientes = response.data
                    this.loading = false
                }
            )
    }

    private getSeries(type_doc) {
        this.loadings.series = true;
        this.api.post('serie/selectBoxSeries-activas', { documentos: [type_doc], status: [false, true] })
            .pipe(finalize(() => { this.loadings.series = false; }))
            .subscribe(response => {
                this.series = Object(response)
            });
    }

    _processarLeituraIndividual() {
        this.configService.loaddinStarter('start')
        this.validateButton = false;
        //====================== VALIDATION ==========================
        if (this.leitura.leitura < 0) {
            this.configService.showAlert('O valor da leitura não deve ser negativo', 'alert-danger', true)
            this.configService.loaddinStarter('stop')
            this.validateButton = true;
            return
        }

        if (this.leitura.data_leitura == null) {
            this.configService.showAlert('A data da leitura é obrigatória', 'alert-danger', true)
            this.configService.loaddinStarter('stop')
            this.validateButton = true;
            return
        }

        this.finalizar_facturacao.contrato_id = this.leitura.contrato_id

        this._facturaService.LeituraCicloIndividual(this.leitura)
            .subscribe(
                res => {

                    console.log( Object(res).code )

                    if (Object(res).code != 200) {
                        this.validateButton = true;
                    }

                    if (Object(res).code == 100) {
                        this.alert_consumo_excessivo()

                        this.validateButton = true;
                    }

                    this.get_charges_individual(this.leitura.contrato_id)
                    this.configService.loaddinStarter('stop')
                }
            )
    }

    alert_consumo_excessivo(){

        Swal.fire({
            title: 'Você tem certeza?',
            text: "Facturar consumo excessivo!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim, facturar',
            cancelButtonText: 'Não, Cancelar!',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.configService.loaddinStarter('start')
                this.configService.showAlert('Processamento em andamento', 'alert-success', true)
                this.facturar_consumo_excessivo()
            }
        })
    }

    facturar_consumo_excessivo(){
        this._facturaService.fcaturarConsumoExcessivoCicloIndividual(this.leitura)
        .subscribe(
            res => {
                this.get_charges_individual(this.leitura.contrato_id)
                this.configService.loaddinStarter('stop')
            }
        )
    }

    get_charges_individual(contrato_id) {
        this._facturaService.GetChargesCicloIndividual(contrato_id)
        .subscribe(
            response => {
                this.linhas_charges = response[0].charges
            }
        )
    }

    _facturarIndividual() {
        this.configService.loaddinStarter('start')

        if (this.finalizar_facturacao.serie_id == null) {
            this.configService.showAlert('A série para factura é obrigatória', 'alert-danger', true)
            this.configService.loaddinStarter('stop')
            return
        }

        this._facturaService.FacturarCicloIndividual(this.finalizar_facturacao)
            .subscribe(
                res => {
                    this._rotaService._closeModal("closeModalCreateLeituraEFacturar")
                    this.configService.loaddinStarter('stop')
                    this._initLeitura()
                }
            )
    }

    _getLeiturasByCliente(cliente) {
        this.loading = true
        this.configService.loaddinStarter('start')

        this.cliente = cliente
        this._rotaService.getLeiturasByCliente(cliente.id)
            .subscribe(
                response => {
                    this.leituras = response.data
                    this.loading = false
                    this.configService.loaddinStarter('stop')
                }
            )
    }

    _initLeitura() {
        this.leitura = {
            contador_id: null,
            leitura: null,
            data_leitura: moment(new Date()).format('YYYY-MM-DD'),
            nao_leitura: false,
            tipo_facturacao: null
        }

        this.finalizar_facturacao = {
            contrato_id: null,
            serie_id: null
        }
    }

    _changeKind(kind: string){
        this.tipo_facturacao = kind
        this.leitura.tipo_facturacao = kind
    }

    _initContaAndContrato() {
        this.cliente = {}
        this.contrato = {}
        this.local_consumo = {}
        this.text_contador = null
        this.loading = false
        this.show_btn_continuar = false
    }


    selectedCliente(cliente) {
        this.validateButton = true;
        this.leitura.contrato_id = cliente.contrato_id
        this.cliente = cliente
        this.finalizar_facturacao.contrato_id = cliente.contrato_id
        this.get_charges_individual( cliente.contrato_id )

        this.current_contrato = this.get_current_contrato(cliente)
    }

    get_current_contrato(cliente: any){
        let contrato = cliente.contrato.filter( contrato => contrato.id == cliente.contrato_id )
        return contrato[0]
    }




    validateDate(event: any) {
        if (moment(event.target.value).format('YYYY-MM-DD') > moment(new Date()).format('YYYY-MM-DD')) {
            this.leitura.data_leitura = null;

            this.configService.showAlert(
                'A data não deve ser maior que actual.',
                'alert-error', true);
            return;
        }
    }


}
