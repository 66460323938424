import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';

@Component({
  selector: 'app-menu-report',
  templateUrl: './menu-report.component.html',
  styleUrls: ['./menu-report.component.css']
})
export class MenuReportComponent implements OnInit {

  itensMenu: any[]
  title: ''

  constructor( private http: HttpService) { }

  ngOnInit() {
  }

  MenuReporting(event){
    this.title = event.title
    this.itensMenu = event.itensMenu
}

}
