import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

@Component({
  selector: 'app-conf-estado-contador',
  templateUrl: './conf-estado-contador.component.html',
  styleUrls: ['./conf-estado-contador.component.css']
})
export class ConfEstadoContadorComponent implements OnInit, OnDestroy {

  private estadocontador = {
    id: null,
    descricao: null,
    slug: null
  }

  private relacaoestado = {
    estado_pai: null,
    estado_filho: null
  }

  private items: any = [];
  private estadosContadores: any = [];
  private estadosAssociados: any = [];

  constructor(private http: HttpService, private configService: ConfigService) { }

  ngOnInit() {
    this.http.filters.search = null
    this.getPageFilterData(1);
    this.getEstadosContador()
  }
  ngOnDestroy(){
    this.http.filters.orderBy = null
  }

  private listaestadocontador() {

    this.configService.loaddinStarter('start');

    this.http.__call('estado-contador/listagem', this.http.filters).subscribe(

      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.configService.loaddinStarter('stop');

      }
    );
  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
    this.listaestadocontador();
  }


  private register(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (this.estadocontador.descricao == "" || this.estadocontador.slug == null) {
      this.configService.showAlert("Os campos Estado e Slug são obrigatórios", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    } else {
    this.http.__call('estado-contador/create', this.estadocontador).subscribe(
      res => {
        console.log(res);
        if (Object(res).code == 201) {
          this.configService.showAlert(Object(res).message, 'alert-danger', true);
          this.configService.loaddinStarter('stop');
        } else {
          this.configService.showAlert(Object(res).message, 'alert-success', true);
          this.clearFormInputs(e);
          this.listaestadocontador()
          this.configService.loaddinStarter('stop');
        }
      }
    )
  };
}

  private clearFormInputs(e) {
    e.target.elements[0].value = null;
    e.target.elements[1].value = null;
    e.target.elements[2].value = null;
  }

  private refresh(id, descricao, slug) {
    this.estadocontador.id = id;
    this.estadocontador.descricao = descricao;
    this.estadocontador.slug = slug;
  }

  private editar(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (this.estadocontador.descricao == "" || this.estadocontador.slug == null) {
      this.configService.showAlert("Os campos Estado e Slug são obrigatórios", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    } else {
      this.http.__call('estado-contador/update/' + this.estadocontador.id, this.estadocontador).subscribe(
        res => {
                  console.log(res);
          if (Object(res).code == 201) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            //this.configService.clearFormInputs(e);
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.listaestadocontador();

          }
        }
      );
    }
    this.configService.loaddinStarter('stop');
  }

  private associarestados(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();

      this.http.__call('relacao-estado-contador/relacao/' + this.estadocontador.id, this.relacaoestado).subscribe(
        res => {
          if (Object(res).code == 201) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            //this.configService.clearFormInputs(e);
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.clearFormInputs(e);
            this.getEstadoFilhosByEstadoPai();
          }
        }
      );
    this.configService.loaddinStarter('stop');
  }

  private loadingEstado = {
    estado: 'Seleccione o estado',
  }

  private getEstadosContador() {
    this.loadingEstado.estado = 'Carregando...';

      this.http.call_get('estado-contador/selectBox' , null).subscribe(
      response => {
        this.estadosContadores = Object(response);
        this.loadingEstado.estado = 'Seleccione o estado';
      }
    );
  }

  private getEstadoFilhosByEstadoPai() {
    this.http.call_get('estado-contador/update/getestados/' + this.estadocontador.id, null).subscribe(
      response => {
        this.estadosAssociados = Object(response).data;
        this.loadingEstado.estado = 'Seleccione o Estado';
      }
    );
  }


  private ini() {
    this.estadocontador = {
      id: null,
      descricao: null,
      slug: null
    }
  }
}
