import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';


@Component({
  selector: 'app-projecto',
  templateUrl: './projecto.component.html',
  styleUrls: ['./projecto.component.css']
})
export class ProjectoComponent implements OnInit {

  @ViewChild("search") search;
  @ViewChild("orderBy") orderBy;
  @ViewChild("searchData") searchData;
  @ViewChild("mostrarResultado") mostrarResultado;



  private pagination = {
    total: null,
    perPage: 5,
    page: 1,
    lastPage: null,

    start: 1,
    end: 10,
    search: null,
    order: "projectos.created_at",
    searchData: ''
  };
  private projectos: any = [];

  private series_projecto: any = [];

  projecto_serie = {
    serie_id: null,
    projecto_id: null
  }

  constructor(private http: HttpService, private configService: ConfigService) { }

  ngOnInit() {
    this.listarProjectos(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.orderBy.nativeElement.value);

  }

  /**
  * @name "Listar series"
  * @descriptio "Esta Função permite Listar todas series"
  * @author "caniggia.moreira@itgest.pt"
  * @param start
  * @param end
  */
  private listarseries() {
    this.configService.loaddinStarter('start');
    this.series=[]
    this.http.__call('serie/listar',null).subscribe(
      data => {
        data.forEach(element => {

          if (element.sigla == 'FT' || element.sigla == 'FR' || element.sigla == 'RC') {
            //this.series_projecto.forEach(el => {
              //if (el.id !== element.id){
                this.series.push(element);
              //}
            //});
          }
          this.configService.loaddinStarter('stop');
       });

      }
    );
  }

  /**
   * @name "Listar projectos"
   * @descriptio "Esta Função permite Listar todos projectos"
   * @author "caniggia.moreira@itgest.pt"
   * @param start
   * @param end
   */
  private listarProjectos(start, end, search, orderBy) {

    this.configService.loaddinStarter('start');
    this.pages = [];
    this.pagination.start = start;
    this.pagination.end = end;
    this.pagination.search = null;
    this.pagination.order = orderBy;
    this.http.__call('projecto/listar', this.pagination).subscribe(
      response => {

        this.pagination.lastPage = Object(response).data.lastPage;
        this.pagination.page = Object(response).data.page;
        this.pagination.total = Object(response).data.total;
        this.pagination.perPage = Object(response).data.perPage;
        this.projectos = Object(response).data.data;

        this.gerarPages();
        this.configService.loaddinStarter('stop');

      }
    );
  }


  private searchDados(e) {
    this.pagination.page = 1;
    this.listarProjectos(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.orderBy.nativeElement.value);

  }

  private mostrarResultados() {
    this.pagination.perPage = this.mostrarResultado.nativeElement.value;
    this.listarProjectos(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.orderBy.nativeElement.value);

  }

  private pages = [];
  private nextProvPageNumber = 1;

  private gerarPages() {
    for (var i = 1; i <= this.pagination.lastPage; i++) {
      this.pages.push(i);
    }
  }
  private nextPage() {
    this.nextProvPageNumber++;
    if (this.nextProvPageNumber < 0) {
      this.nextProvPageNumber = this.pagination.page;
    }
    this.listarProjectos(this.nextProvPageNumber, this.pagination.perPage, this.search.nativeElement.value, this.orderBy.nativeElement.value);

  }
  private prevPage() {
    this.nextProvPageNumber--
    if (this.nextProvPageNumber > 0) {
      this.listarProjectos(this.nextProvPageNumber, this.pagination.perPage, this.search.nativeElement.value, this.orderBy.nativeElement.value);
      this.nextProvPageNumber = this.pagination.page;
    }
  }

  private refreshPaginate(page) {
    this.listarProjectos(page, this.pagination.perPage, this.search.nativeElement.value, this.orderBy.nativeElement.value);
  }
  private refreshData() {
    this.listarProjectos(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.orderBy.nativeElement.value);
  }


  //-------------------------------------------------------------------------------------------------------------------------------------------
  /**
   * Gestão de Registos de novo projecto
   */

  private clientes: any = [];
  private series: any = [];

  private projecto = {
    id: null,
    descricao: null,
    morada_projecto: null,
    municipio: null,
    provincia: null,
    telefone: null,
    data_inicio: null,
    data_fim: null,
    custo_total: null
  }

  private iniOject() {
    this.projecto = {
      id: null,
      descricao: null,
      morada_projecto: null,
      municipio: null,
      provincia: null,
      telefone: null,
      data_inicio: null,
      data_fim: null,
      custo_total: null
    }
  }
  /**
     * @name "Registar Projecto"
     * @description "Esta Função permite Registar Projecto"
     * @author "caniggia.moreira@itgest.pt"
     * @param start
     * @param end
     */
  private createOrUpdate(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    var url = "";
    if (this.projecto.id == null) {
      url = 'projecto/register';
    } else {
      url = 'projecto/update/' + this.projecto.id;
    }

    this.http.__call(url, this.projecto).subscribe(
      res => {
        if (Object(res).code == 500) {
          this.configService.showAlert(Object(res).message, 'alert-danger', true);
        } else {
          this.configService.showAlert(Object(res).message, 'alert-success', true);
          if (this.projecto.id == null) {
            this.iniOject();
          }
          this.refreshData();
          this.configService.loaddinStarter('stop');
        }
      }

    );

  }

  /**
   * @name "Registar Projecto"
   * @description "Esta Função permite Registar Projecto"
   * @author "caniggia.moreira@itgest.pt"
   * @param start
   * @param end
   */
  private createOrSerieProjecto(e) {
    this.configService.loaddinStarter('start');
    var url = 'projecto/serie/adicionar';
    this.projecto_serie.projecto_id = this.projecto.id;

    this.http.__call(url, this.projecto_serie).subscribe(
      res => {
        if (Object(res).code == 500) {
          this.configService.loaddinStarter('stop');
          this.configService.showAlert(Object(res).message, 'alert-danger', true);
        } else {
          this.configService.showAlert(Object(res).message, 'alert-success', true);
          if (Object(res).code == 200) {
            this.listarseries();
            this.listarSeriesProjecto();
            this.iniOject();
          }
          this.refreshData();
          this.configService.loaddinStarter('stop');
        }
      }

    );

  }

  /**
  * @name "Listar series"
  * @descriptio "Esta Função permite Listar todas series"
  * @author "caniggia.moreira@itgest.pt"
  * @param start
  * @param end
  */
  private listarSeriesProjecto() {
    this.configService.loaddinStarter('start');
    this.http.__call('projecto/serie/listar', { projecto_id: this.projecto.id }).subscribe(
      response => {
        this.series_projecto = Object(response).data;
        this.configService.loaddinStarter('stop');
      }
    );
  }

  /**
     * @name "delete Projecto"
     * @description "Esta Função permite delete Projecto"
     * @author "caniggia.moreira@itgest.pt"
     * @param start
     * @param end
     */
  private delete(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    var url = 'projecto/eliminar/' + this.projecto.id;

    this.http.__call(url, this.projecto).subscribe(
      res => {
        if (Object(res).code == 500) {
          this.configService.showAlert(Object(res).message, 'alert-danger', true);
        } else {
          this.configService.showAlert(Object(res).message, 'alert-success', true);
          this.refreshData();
          this.configService.loaddinStarter('stop');
        }
      }

    );

  }







  private refreshProjecto(id) {

    this.projectos.forEach(element => {
      if (element.id === id) {
        this.projecto.id = element.id;
        this.projecto.descricao = element.descricao;
        this.projecto.data_fim = element.data_fim.replace('.000Z', '').substr(0, 10);
        this.projecto.data_inicio = element.data_inicio.replace('.000Z', '').substr(0, 10);
        this.projecto.custo_total = element.custo_total;
        this.projecto.telefone = element.telefone;
        this.projecto.morada_projecto = element.morada_projecto;
        this.projecto.municipio = element.municipio;
        this.projecto.provincia = element.provincia;
      }
    });
  }

}
