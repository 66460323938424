import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

@Component({
  selector: 'app-conf-tipo-medicao',
  templateUrl: './conf-tipo-medicao.component.html',
  styleUrls: ['./conf-tipo-medicao.component.css']
})
export class ConfTipoMedicaoComponent implements OnInit, OnDestroy {

  private tipomedicao = {
    id: null,
    nome: null,
    slug: null
  }


  private items: any = [];

  constructor(private http: HttpService, private configService: ConfigService) { }

  ngOnInit() {
    this.http.filters.search = null
    this.getPageFilterData(1);
  }
  ngOnDestroy() {
    this.http.filters.orderBy = null
  }


  private listatipomedicao() {

    this.configService.loaddinStarter('start');

    this.http.__call('tipo-medicao/listagem', this.http.filters).subscribe(

      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.configService.loaddinStarter('stop');

      }
    );
  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
    this.listatipomedicao();
  }


  private register(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (this.tipomedicao.nome == "" || this.tipomedicao.slug == null) {
      this.configService.showAlert("Os campos Descrição e Slug são obrigatórios", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    } else {
      this.http.__call('tipo-medicao/create', this.tipomedicao).subscribe(
        res => {
          if (Object(res).code == 201) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
            this.configService.loaddinStarter('stop');
          } else {
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.clearFormInputs(e);
            this.listatipomedicao()
            this.configService.loaddinStarter('stop');
          }
        }
      )
    };

  }


  private clearFormInputs(e) {
    e.target.elements[0].value = null;
    e.target.elements[1].value = null;
    e.target.elements[2].value = null;
  }

  private refresh(id, nome, slug) {
    this.tipomedicao.id = id;
    this.tipomedicao.nome = nome;
    this.tipomedicao.slug = slug;
  }

  private editar(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (this.tipomedicao.nome == "") {
      this.configService.showAlert("Os campos Descrição é obrigatório", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    } else {
      this.http.__call('tipo-medicao/update/' + this.tipomedicao.id, this.tipomedicao).subscribe(
        res => {
          if (Object(res).code == 201) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            //this.configService.clearFormInputs(e);
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.listatipomedicao();

          }
        }
      );
    }
    this.configService.loaddinStarter('stop');
  }


  private ini() {
    this.tipomedicao = {
      id: null,
      nome: null,
      slug: null
    }
  }





}
