import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

@Component({
  selector: 'app-cmda-dispositivo-form',
  templateUrl: './cmda-dispositivo-form.component.html',
  styleUrls: ['./cmda-dispositivo-form.component.css']
})
export class CmdaDispositivoFormComponent implements OnInit {




  private cdma = {
    id: null,
    Num_Serie: null,
    A_Key: null,
    MINCode: null,
    AgenciaFilialID: null
  }


  private itemsCDMAs: any = [];

  constructor(private http: HttpService, private configService: ConfigService) { }

  ngOnInit() {
    this.getPageFilterData(1);
  }

  private listaDispositivosCDMA() {

    this.configService.loaddinStarter('start');

    this.http.__call('cdma/equipamento/listagem', this.http.filters).subscribe(

      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.itemsCDMAs = Object(response).data.data;

       // console.log(this.items)
        this.configService.loaddinStarter('stop');

      }
    );
  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
    this.listaDispositivosCDMA();
  }


  private register(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (this.cdma.Num_Serie == null) {
      this.configService.showAlert("Digite o Número de Série", 'alert-danger', true);
    } else {
      this.http.__call('cdma/equipamento/create', this.cdma).subscribe(
        res => {
          if (Object(res).status == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
            this.configService.loaddinStarter('stop');
          } else {
            this.configService.showAlert(Object(res).message, 'alert-success', true);
           
            this.cdma.Num_Serie = null;
            this.cdma.A_Key = null;
            this.cdma.MINCode =  null;
            this.cdma.AgenciaFilialID = null;
            this.listaDispositivosCDMA();
            this.configService.loaddinStarter('stop');
          }
        }
      )
    }
    ;
  }

  private refresh(id, Num_Serie, A_Key, MINCode, AgenciaFilialID) {

    this.cdma.id = id;
    this.cdma.Num_Serie = Num_Serie;
    this.cdma.A_Key = A_Key;
    this.cdma.MINCode =  MINCode;
    this.cdma.AgenciaFilialID = AgenciaFilialID;

  }

  private closeForm(){
    this.cdma.id = null;
    this.cdma.Num_Serie = null;
    this.cdma.A_Key = null;
    this.cdma.MINCode =  null;
    this.cdma.AgenciaFilialID = null;
  }

  private editar(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (this.cdma.Num_Serie == null) {
      this.configService.showAlert("O campo designação é obrigatorio", 'alert-danger', true);
    } else {
      this.http.__call('cdma/equipamento/update/' + this.cdma.id, this.cdma).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            //this.configService.clearFormInputs(e);
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.listaDispositivosCDMA();

          }
        }
      );
    }
    this.configService.loaddinStarter('stop');
  }

  private clearFormInputs(e) {
    e.target.elements[0].value = null;
    e.target.elements[1].value = null;
    e.target.elements[2].value = null;
    e.target.elements[3].value = null;
  }

  private carregando = {
    filial: 'Seleccione a filial',
  }

  private filiais: any = [];

  private listarFilial() {
    this.carregando.filial = 'Carregando...';
    this.http.call_get('filial/selectBox', null).subscribe(
      response => {
        this.filiais = Object(response).data;
        this.carregando.filial = 'Seleccione a Filial';
      }
    );
  }
  

  private ini() {
    this.cdma = {
      id: null,
      Num_Serie: null,
      A_Key: null,
      MINCode: null,
      AgenciaFilialID: null
    }
  }








}
