
import { Component, Input, Output, EventEmitter, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { ConfigModuloService } from 'src/app/services/config-modulo.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { Pagination } from 'src/app/models/pagination';
import { ActivatedRoute, Router } from '@angular/router'
import { RotaService } from '../rotas.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CdkDragDrop, copyArrayItem, moveItemInArray } from "@angular/cdk/drag-drop";
import { HostListener } from '@angular/core';
import * as moment from 'moment';
declare var google;
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'
@Component({
  selector: 'app-fazer-agendamento',
  templateUrl: './fazer-agendamento.component.html',
  styleUrls: ['./fazer-agendamento.component.css']
})
export class FazerAgendamentoComponent implements OnInit {

  public pagination = new Pagination();


  dias = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
    11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31
  ]

  locais: any = []
  novos_locais: any[] = [];
  locais_processar: any = []
  periodicidades: any = []

  new_periodo: any = {
    periodicidade_roteiro_id: null,
    ciclo: null,
    dia_mes: 1
  }

  isToExpand = false;
  public isSearch = false;
  public _searchedlocais: any[];

  public newPerPageLocais = 1;
  check_all: boolean
  isCheckAllNewsPlaces: boolean
  header_id: number
  rota_header: any = {}
  municipio: any = {}
  searchText = ''
  public itemsToMove = [];
  private rotas;
  rota: any;

  @ViewChild('mapContainer') gmap: ElementRef;

  marker: any;
  map: google.maps.Map;
  lat = null;
  lng = null;
  markers: any = [];
  loading: boolean
  public search: any;

  private estado = [{ id: 0, nome: "Inactivo" }, { id: 1, nome: "Activo" }];

  AgendamentoForm: FormGroup;
  FormFilter: FormGroup;
  submitted = false;

  constructor(
    private configService: ConfigService,
    private config: ConfigModuloService,
    private _rotaService: RotaService,
    private http: HttpService,
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
  ) {
    this.route.params.subscribe(params => {
      this.header_id = params['id']
    })

    //this.getPageFilterData(1)
    this.createForm();
    this.loadingMethods();
  }

  ngOnInit() {
    this.check_all = false
    this.http.filters.search = null
    this.mapInitializer();
  }


  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      this.http.filters.pagination.page = 1;
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
    this.loadingMethods();
  }


  createForm() {
    this.AgendamentoForm = this.fb.group({
      index: [{ value: null, disabled: true }],
      data_inicio: [null],
      data_fim: [null],
      estado_id: [null],
      leitor_id: [null],
      user_nome: [null],
      rotaRuns: [null],
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.AgendamentoForm.controls;
  }

  onReset() {
    this.submitted = false;
    this.AgendamentoForm.reset();
  }

  onSubmit() {
    this.submitted = true;
    // parar aquei se o formulário for inválido

    if (this.novos_locais === []) {
      return;
    }

    this.AgendamentoForm.patchValue({ rotaRuns: this.locais });

    this.loading = true;
    const index = this.AgendamentoForm.getRawValue().index;
    // TODO: usado para fazer a requisição com a api de criação de objsct or update
    const url = 'rotaRuns-agendamento-create/' + this.header_id;
    this.createOrEdit(url, this.AgendamentoForm, (index === null ? true : false));
  }


  createOrEdit(url: any, formulario: FormGroup, isCreate: boolean) {

    // TODO: usado para fazer a requisição com a api de criação de object
    this.http.__call(url, formulario.value).subscribe(
      response => {
        this.submitted = false;
        this.loading = false;

        if (Object(response).code == 200) {
          Swal.fire({
            position: 'center',
            type: 'success',
            title: 'Agendamento processado com sucesso.',
            showConfirmButton: false,
            timer: 2000
          })
          this._resetAfterResponse();
        } else {
          Swal.fire({
            position: 'center',
            type: 'error',
            title: 'Erro ao processar o Agendamento.',
            showConfirmButton: false,
            timer: 2000
          })
        }
      },
      error => {
        this.submitted = false;
        this.loading = false;
      });
  }

  _resetAfterResponse() {
    this.locais = [];
    this.AgendamentoForm.reset();
    this.router.navigate(['/processar-leituras/' + this.header_id]);
  }

  loadingMethods() {
    this.configService.loaddinStarter('start');

    this._rotaService.getPeriodicidades()
      .subscribe(response => {
        this.periodicidades = response.data
      })

    this.http.call_get(`rota-header/${this.header_id}`, null)
      .subscribe(
        response => {
          this.rota = Object(response).data
          this.rota_header = this.rota

          this._getLocaisConsumo(this.header_id)
          this._getDefaulRouteHeadertLeitor(this.rota_header.leitor_id).subscribe((res) => {
            const lt = Object(res).data;
            this.AgendamentoForm.patchValue({
              leitor_id: lt.id,
              user_nome: lt.nome,
              data_inicio: moment(this.rota_header.data_inicio).format('YYYY-MM-DD'),
              data_fim: moment(this.rota_header.data_fim).format('YYYY-MM-DD'),
            });
          });
        }
      )
  }

  _getDefaulRouteHeadertLeitor(leitor_id: any) {
    return this.http.call_get('get-userOrleitor-agendamento/' + leitor_id, null);
  }

  _resetLoais() {
    this.locais = [];
  }


  _getLocaisConsumo(id) {
    this.http.call_get(`roteiros/${id}/agendamento/`, null)
      .subscribe(
        response => {
          let data = Object(response).data
          this._init_locais(response);
        }
      )
  }


  _init_locais(locais) {
    this.configService.loaddinStarter('start')

    let locais_merge = []

    for (let local of locais.data) {
      var prop = { checkbox: false }
      locais_merge.push({ ...local, ...prop })
    }

    locais_merge.forEach(local => {
      this.locais.push(local);
    });
    this.pagination.total = locais.data.length

    this.http.filters.pagination.lastPage = locais.lastPage;
    this.http.filters.pagination.page = locais.page;
    this.http.filters.pagination.total = locais.total;
    this.http.filters.pagination.perPage = locais.perPage;

    this.configService.loaddinStarter('stop')
  }

  //===========================================================================

  _addMultiples() {
    for (let c = 0; c < 30; c++) {
      for (let local of this.locais) {
        if (local.checkbox) {
          this._addNewRota(local)
        }
      }
    }
  }

  _Remove_Multiples() {
    for (let c = 0; c < 30; c++) {
      for (let local of this.novos_locais) {
        if (local.checkbox) {
          this._removeRota(local);
        }
      }
    }
  }

  _checkAll(event) {
    this.check_all = event.target.checked
    for (let local of this.locais) {
      local.checkbox = event.target.checked
    }
  }

  _checkAll_novos_Locais(event) {
    this.isCheckAllNewsPlaces = event.target.checked
    for (let local of this.novos_locais) {
      local.checkbox = event.target.checked;
    }
  }

  _removeRota(local) {
    local.checkbox = false;
    this.locais.push(local)
    for (let l = 0; l < this.novos_locais.length; l++) {
      if (local.local_consumo_id === this.novos_locais[l].local_consumo_id) {
        this.novos_locais.splice(l, 1)
      }
    }
  }

  _addNewRota(local) {
    local.checkbox = false;
    this.novos_locais.push(this._initNewColletion(local))
    for (let l = 0; l < this.locais.length; l++) {
      if (local.local_consumo_id === this.locais[l].local_consumo_id) {
        this.locais.splice(l, 1)
      }
    }
  }

  markNewPlacesOrNot(e: any, local: any) {
    for (let l = 0; l < this.locais.length; l++) {
      if (this.locais[l].local_consumo_id === local.local_consumo_id) {
        if (e.target.checked) {
          this.locais[l].checkbox = e.target.checked;
        } else {
          this.locais[l].checkbox = e.target.checked;
        }
      }
    }
  }

  _checkOrUncheck_novos_Locais(e: any, local: any) {
    for (let l = 0; l < this.novos_locais.length; l++) {
      if (this.novos_locais[l].local_consumo_id === local.local_consumo_id) {
        if (e.target.checked) {
          this.novos_locais[l].checkbox = e.target.checked;
        } else {
          this.novos_locais[l].checkbox = e.target.checked;
        }
      }
    }
  }


  reOrderLocais(locais) {
    this.novos_locais = []

    for (let i = 0; i < locais.length; i++) {
      let local = locais[i]
      local.ordem = i + 1
      this.novos_locais.push(this._initNewColletion(local))
    }
  }

  _initNewColletion(local) {

    let new_local = {
      rota_run_id: local.rota_run_id,
      cil: local.cil,
      rua: local.rua,
      contaDescricao: local.contaDescricao,
      is_predio: local.is_predio,
      latitude: local.latitude,
      local_consumo_id: local.local_consumo_id,
      local_instalacao_id: local.local_instalacao_id,
      longitude: local.longitude,
      ordem: local.ordem,
      moradia_numero: local.moradia_numero,
      numero_conta: local.numero_conta,
      predio_andar: local.predio_andar,
      predio_nome: local.predio_nome,
    }
    return new_local
  }

  _saveAndContinue(rota_header_id) {
    this._createProcess(rota_header_id)
    this._closeModal("closeModal")
  }

  _save(rota_header_id) {
    this._createProcess(rota_header_id)
    this._closeModal("closeModal")
    this.router.navigate(['/Rotas'])
  }

  _createProcess(rota_header_id) {
    this.configService.loaddinStarter('start');

    console.log(this.novos_locais)

    this.http.__call('agendamento/create', {
      locais: this.novos_locais,
      dia_mes: this.new_periodo.dia_mes,
      rota_header_id: rota_header_id,
      periodicidade_roteiro_id: this.new_periodo.periodicidade_roteiro_id
    }).subscribe(
      res => {
        if (Object(res).code == 200) {
          this.configService.showAlert("Agendamento registado com sucesso", 'alert-success', true)

          this.configService.loaddinStarter('stop')
          this.novos_locais = []
          this._updateListRotasRun(rota_header_id)

        } else {
          this.configService.showAlert("Erro ao registar Agendamento", 'alert-danger', true);
        }
      }
    )

  }

  _updateListRotasRun(id: number) {
    this.http.call_get(`rota-run/getRotasRunByRotaHeader/${id}`, null).subscribe(
      response => {
        let rotaRuns = Object(response).data
        this._rotaService.listRotasHeadersChange.emit(rotaRuns)
      }
    )
  }

  _closeModal(closeModal) {
    var action = document.getElementById(closeModal);
    action.click();
  }

  //=========================================================================== MAP


  /*async loadAllMarkers() {

    const loadedStateCallback = () => {

      for (let index = 0; index < this.rotas.length; index++) {

        let item = this.rotas[index];

        const markerInfo = {
          position: new google.maps.LatLng(item.latitude, item.longitude),
          map: this.map,
          title: item.moradia_numero,
          description: ""
        };

        const marker = new google.maps.Marker({
          ...markerInfo
        });

        let dist = (item.has_distrito) ? '<br>Distrito ' + item.distrito : '';
        let alocacao = (item.is_predio) ? 'Prédio ' + item.predio_nome + ', ' + this.configService.decimalFormat(item.predio_andar) + 'º Andar - Porta ' + this.configService.decimalFormat(item.moradia_numero) : 'Residência ' + this.configService.decimalFormat(item.moradia_numero);

        var content = '<div style="width: 380px">' +
          '<div style="background-color: #0099FF!important; color: white; padding: 5px; border-radius: 5px; font-size: 22px; " align="center">' + item.cliente + '</div>' +
          '<div style="font-size: 16px"><br>' +
          '<div class="row">' +
          '<div class="col-md-6">Rota ' + item.rota_header +
          '<br>Estado ' + item.estado_rota +
          '<br>Província ' + item.provincia +
          '<br>Município ' + item.municipio +
          dist +
          '<br>Bairro/Zona ' + item.bairro +
          '<br>Rua ' + item.rua +
          '</div>' +
          '<div class="col-md-6">' +
          alocacao +
          '<br>Telefone ' + item.cliente_telefone +
          '<br>Conta ' + item.conta_id +
          '<br>Contador ' + item.numero_serie +
          '</div>' +
          '</div>' +
          '<div class="iw-bottom-gradient"></div>' +
          '</div>';


        //creating a new info window with markers info
        const infoWindow = new google.maps.InfoWindow({
          content: content
        });

        //Add click event to open info window on marker
        marker.addListener("click", () => {
          infoWindow.open(marker.getMap(), marker);
        });

        //Adding marker to google map
        marker.setMap(this.map);

      }

      console.log(this.markers);
      console.log("this.markers: " + this.markers.length);

      /* if (this.markers.length > 0) {


        console.log("markers: " + this.markers.length);
        this.markers.forEach(markerInfo => {
          //Creating a new marker object
          const marker = new google.maps.Marker({
            ...markerInfo
          });

          //creating a new info window with markers info
          const infoWindow = new google.maps.InfoWindow({
            content: marker.getTitle()
          });

          //Add click event to open info window on marker
          marker.addListener("click", () => {
            infoWindow.open(marker.getMap(), marker);
          });

          //Adding marker to google map
          marker.setMap(this.map);
        });

      } 

    }

    await this.getRotas(loadedStateCallback);


    console.log("markers");
    console.log(this.markers);


  }*/


  mapInitializer() {

    this.lat = -7.739212;
    this.lng = 21.251341;


    const coordinates = new google.maps.LatLng(this.lat, this.lng);

    const mapOptions: google.maps.MapOptions = {
      center: coordinates,
      zoom: 8,
    };

    this.marker = new google.maps.Marker({
      position: coordinates,
      map: this.map,
    });


    this.map = new google.maps.Map(this.gmap.nativeElement,
      mapOptions);

    this.marker.addListener("click", () => {
      const infoWindow = new google.maps.InfoWindow({
        content: this.marker.getTitle()
      });
      infoWindow.open(this.marker.getMap(), this.marker);
    });

    this.marker.setMap(this.map);

    //Adding other markers
    //this.loadAllMarkers();

  }


  async getRotas(callback: Function) {
    this.http.call_get('local-instalacao/getLocaisMapa', null).subscribe(
      response => {
        this.rotas = Object(response);
        callback();
      }
    )
  }



  //**version aldimiro coding changes
  drop(event: CdkDragDrop<any[]>) {
    //this.moveUpSimple(event.currentIndex);
    this.moveDownSimple(event.previousIndex);
  }

  moveUpSimple(index: number) {
    if (index >= 1)
      this.swap(this.novos_locais, index, index - 1)
  }

  moveDownSimple(index: number) {
    if (index < this.novos_locais.length - 1)
      this.swap(this.novos_locais, index, index + 1)
  }

  private swap(array: any[], x: any, y: any) {
    var b = array[x];
    array[x] = array[y];
    array[y] = b;
  }

  @HostListener('scroll', ['$event'])
  onScroll(evento: any) {
    /*if (evento.target.offsetHeight + evento.target.scrollTop >= evento.target.scrollHeight - 1) {
      ++this.newPerPageLocais;

      if (this.header_id) {
        this._getLocaisConsumo(this.header_id);
      } 
    }*/
  }

  searchLocais(e) {
    this.isSearch = false;

    if (this.search != "") {
      this.isSearch = true;

      this._searchedlocais = this.locais.filter((res) =>
        String(res.cil).toLocaleLowerCase().includes(this.search.toLocaleLowerCase())
        || String(res.moradia_numero).toLocaleLowerCase().includes(this.search.toLocaleLowerCase())
      );
    }
  }

  key: string = 'id';
  reverse: boolean = false;
  sort(key: any) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  expandirMapa(e) {
    if (e.target.checked) {
      this.isToExpand = e.target.checked
    }
    else {
      this.isToExpand = e.target.checked
    }
  }

  users: any = [];
  view_user = true;

  private getUtilizador() {

    if (this.AgendamentoForm.getRawValue().user_nome == "") {
      this.setNullUser();
    }

    this.view_user = true;
    this.http.__call('search-utilizador', { start: 1, end: 10, search: this.AgendamentoForm.getRawValue().user_nome })
      .subscribe(
        response => {
          this.users = Object(response).data.data;
          this.configService.loaddinStarter('stop');
        }
      );
  }

  private setUtilizador(id: any, nome: any) {
    this.AgendamentoForm.patchValue({ leitor_id: id, user_nome: nome });
    this.view_user = false;
  }

  private setNullUser() {
    this.AgendamentoForm.getRawValue().leitor_id = null;
    this.AgendamentoForm.getRawValue().user_nome = null;
    this.view_user = false;
  }

  validateDate(event: any) {
    if (moment(event.target.value).format('YYYY-MM-DD') < moment(this.AgendamentoForm.getRawValue().data_inicio).format('YYYY-MM-DD')) {
      this.AgendamentoForm.patchValue({ data_fim: null });;

      this.configService.showAlert(
        'A data fim não deve ser menor que a data início.',
        'alert-error', true);
      return;
    }
  }

  public end() {
    console.log(this.locais.map((m, i, l) => { m.SortOrder = i; return m; }))
  }

  eventUpdateCounter: any = 0;
  public eventOptions = {
    onUpdate: () => this.eventUpdateCounter++,
    draggable: '.draggable',
    group: 'group'
  }

}

