import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, SimpleChange } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ConfigService } from 'src/app/providers/config/config.service';
import { HttpService } from 'src/app/providers/http/http.service';
import { LogGestaoDividaComponent } from '../log-gestao-divida.component';

@Component({
  selector: 'createOrEditLogGestaoDivida',
  templateUrl: './create-or-edit-log-gestao-divida.component.html',
  styleUrls: ['./create-or-edit-log-gestao-divida.component.css']
})
export class CreateOrEditLogGestaoDividaComponent implements OnInit {

  @Input() modal: any = "createOrEditLogGestaoDividaModal";
  @Input() title: string = "Editar Log Gestão Divida";
  @Input() currrent_log: any;

  submitted = false;

  @Input() LogGestaoDividaForm: FormGroup;


  private loading: boolean = false;

  public tipo_aviso_corte: any = []
  private max_date = moment(new Date()).format('YYYY-MM');
  private min_date = moment(new Date()).format('YYYY-MM');

  constructor(
    private fb: FormBuilder,
    private http: HttpService,
    private configService: ConfigService,
    private logGestDividaComp: LogGestaoDividaComponent
  ) {

    this.LogGestaoDividaForm = this.fb.group({
      id: [{ value: null, disabled: true }],
      descricao: [null, Validators.required],
      data_prevista: [null, Validators.required],
      data_limite: [null, Validators.required],
      estado_atual: [null, Validators.required],

      is_active: [null, Validators.required],
      contrato_id: [null, Validators.required],
      tipo_aviso_id: [null, Validators.required],
    });
    this.getTipoAvisoCorte()
  }

  ngOnInit(): void { }

  // convenience getter for easy access to form fields
  get f() {
    return this.LogGestaoDividaForm.controls;
  }

  onReset() {
    this.submitted = false;
    this.LogGestaoDividaForm.reset();
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if (this.currrent_log !== undefined) {
      this.title = "Editar Log Gestão Divida";
      this.LogGestaoDividaForm.patchValue({
        id: this.currrent_log.id,
        contrato_id: this.currrent_log.contrato_id,
        data_prevista: moment(this.currrent_log.data_prevista).format('YYYY-MM-DD'),
        descricao: this.currrent_log.descricao,
        estado_atual: this.currrent_log.estado_atual,
        is_active: this.currrent_log.is_active,
        nome: this.currrent_log.nome,
        tipo_aviso_id: this.currrent_log.tipo_aviso_id
      });
    } else {
      this.title = "Editar Log Gestão Divida";
    }
  }

  validarData($event: any) {
    console.log("data limite", $event.target.value)
    if (moment($event.target.value).format('YYYY-MM-DD') <= moment(new Date()).format('YYYY-MM-DD')) {
      this.configService.showAlert('A data limite não deve ser menor ou igual a actual', 'alert-danger', true);
      this.LogGestaoDividaForm.get('data_limite').reset();
    }
  }


  getTipoAvisoCorte() {
    this.http.__call('api/v1/notifications/tipoAvisoCorte/index', null)
      .subscribe(res => {
        this.tipo_aviso_corte = Object(res).data
      })
  }
  createOrEdit() {

    this.submitted = true
    if (this.LogGestaoDividaForm.invalid) {
      return
    }

    this.loading = true;
    const url = `api/v1/dividas/` + this.LogGestaoDividaForm.getRawValue().id

    this.http
      .__call(url, this.LogGestaoDividaForm.value)
      .subscribe(res => {
        this.loading = false;
        //this.cleanForm()
        this.logGestDividaComp.listagem()
      })
  }

  cleanForm() {
    this.LogGestaoDividaForm.get('id').reset()
    this.LogGestaoDividaForm.get('contrato_id').reset()
    this.LogGestaoDividaForm.get('data_prevista').reset()
    this.LogGestaoDividaForm.get('descricao').reset()
    this.LogGestaoDividaForm.get('estado_atual').reset()
    this.LogGestaoDividaForm.get('is_active').reset()
    this.LogGestaoDividaForm.get('tipo_aviso_id').reset()
    this.LogGestaoDividaForm.get('data_limite').reset()
  }

}
