import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service'; 

@Component({
  selector: 'app-stock-movimento',
  templateUrl: './stock-movimento.component.html',
  styleUrls: ['./stock-movimento.component.css']
})
export class StockMovimentoComponent implements OnInit {
  @ViewChild("search") search; 
  @ViewChild("orderBy") orderBy;
  @ViewChild("searchData") searchData; 
  @ViewChild("mostrarResultado") mostrarResultado; 
  private movimentos: any = [];
  private pagination = {
    total: null,
    perPage: 5,
    page: 1,
    lastPage: null,

    start: 1,
    end: 10,
    search: null,
    order: "stoke_movimentos.created_at",
    searchData: ''
  };

  constructor(private http: HttpService, private configService: ConfigService) { }

  ngOnInit() { 
    this.listarStockMovimentos(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.searchData.nativeElement.value, this.orderBy.nativeElement.value);
  }

  /**
 * @name "Listar facturação"
 * @descriptio "Esta Função permite Listar todas facturações"
 * @author "caniggia.moreira@itgest.pt"
 * @param start 
 * @param end 
 */
  private listarStockMovimentos(start, end, search, searchData, orderBy) {

    this.configService.loaddinStarter('start');
    this.pages = [];
    this.pagination.start = start;
    this.pagination.end = end;
    this.pagination.search = search;
    this.pagination.order = orderBy;
    this.pagination.searchData = searchData;
    this.http.__call('stockMovimento/listar', this.pagination).subscribe(
      response => {
        this.pagination.lastPage = Object(response).data.lastPage;
        this.pagination.page = Object(response).data.page;
        this.pagination.total = Object(response).data.total;
        this.pagination.perPage = Object(response).data.perPage;

        this.movimentos = Object(response).data.data;
        console.log(this.movimentos);
        this.gerarPages();
        this.configService.loaddinStarter('stop');

      }
    );
  }

  private searchStockMovimento(e) {
    this.pagination.page = 1;
    this.listarStockMovimentos(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.searchData.nativeElement.value, this.orderBy.nativeElement.value);

  }
 
  private mostrarResultados() {
    this.pagination.perPage = this.mostrarResultado.nativeElement.value;
    this.listarStockMovimentos(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.searchData.nativeElement.value, this.orderBy.nativeElement.value);

  }

  private pages = [];
  private nextProvPageNumber = 1;

  private gerarPages() {
    for (var i = 1; i <= this.pagination.lastPage; i++) {
      this.pages.push(i);
    }
  }
  private nextPage() {
    this.nextProvPageNumber++;
    if (this.nextProvPageNumber < 0) {
      this.nextProvPageNumber = this.pagination.page;
    }
    this.listarStockMovimentos(this.nextProvPageNumber, this.pagination.perPage, this.search.nativeElement.value, this.searchData.nativeElement.value, this.orderBy.nativeElement.value);

  }
  private prevPage() {
    this.nextProvPageNumber--
    if (this.nextProvPageNumber > 0) {
      this.listarStockMovimentos(this.nextProvPageNumber, this.pagination.perPage, this.search.nativeElement.value, this.searchData.nativeElement.value, this.orderBy.nativeElement.value);
      this.nextProvPageNumber = this.pagination.page;
    }
  }

  private refreshPaginate(page) {
    this.listarStockMovimentos(page, this.pagination.perPage, this.search.nativeElement.value, this.searchData.nativeElement.value, this.orderBy.nativeElement.value);
  }









}
