import { Component, OnInit, Input } from '@angular/core';
import { concatAll } from 'rxjs/operators';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { ExcelService } from 'src/app/services/excel.service'; 
import * as moment from 'moment';


@Component({
  selector: 'app-modalexportarcontacorrenteexel',
  templateUrl: './modalexportarcontacorrenteexel.component.html',
  styleUrls: ['./modalexportarcontacorrenteexel.component.css']
})
export class ModalexportarcontacorrenteexelComponent implements OnInit {
  @Input() modalExportalContaCorrent: string = "modalExportalContaCorrent";
  @Input () clienteid :any
  private contaCorrente ;
  private contaCorrenteDados:any=[];
  private conta:any;
  private SomaTotal=0.0;
  private arr:any=[]

  constructor( private http: HttpService, private configservice:ConfigService, private execel:ExcelService) { 

  }

  ngOnInit() {
    //console.log(this.clienteid);
  }

  ngOnChanges(){
  
    if(this.clienteid.length==0){
      //console.log("nqoq posso dormir");
    }else{
     // console.log("test")
      this.ExportalContaCorrente();
    
    


    }

  }

  ExportalContaCorrente(){
    this.http._get('contaCorrente/report/'+this.clienteid.facturas[0].cliente_id).subscribe(response=>{
      this.contaCorrente=Object(response).data.facturas;
      this.contaCorrenteDados=[];
      this.arr=[];
      for(let i=0; i<this.contaCorrente.length; i++){
                
        for(let index=0; index<this.contaCorrente[i].contacorrente.length; index++){
          this.SomaTotal+= this.contaCorrente[i].contacorrente[index].valor;
         this.contaCorrenteDados.push(this.contaCorrente[i].contacorrente[index])
         this.arr.push(this.contaCorrente[i].clientefilhos)
         this.contaCorrenteDados[index].contas= this.arr[index];

        
        }
       
        }

    
 //console.log(this.contaCorrenteDados)


   
    })

  }
  exportAsXLSX(): void {
    var CurrentDate = new Date();
    var nameFile = "Conta_Corrente-" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m') 
    this.execel.exportAsExcelFile(document.getElementsByClassName("exportAsXLSXContaCorrent")[0], nameFile);
  }
}

