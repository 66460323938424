import { Component, OnInit, Input, createPlatformFactory } from '@angular/core';
import { GestorContaService } from './gestor-conta.service';

@Component({
    selector: 'app-gestor-conta',
    templateUrl: './gestor-conta.component.html',
})

export class GestorContaComponent implements OnInit {
    gestor: any = {
        nome: null,
        telefone: null,
        email: null,
        morada: null,
    }

    is_new: boolean
    gestores: any = []

    constructor(
        private _gestorContaService: GestorContaService
    ){
        this._loadingDependences()
    }

    ngOnInit() {

    }

    _initModal(gestor: any, status: boolean){
        this.gestor = gestor
        this.is_new = status
    }

    _save(){
        if (this.is_new) {
            this._new()
        } else {
            this._update()
        }
    }

    _new(){
        this._gestorContaService.CreateGestorConta( this.gestor )
        .subscribe( response => {
            this._loadingDependences()
        }),
        (error) => {
            if (!error.ok) {}
        }
    }

    _update(){
        this._gestorContaService.UpdateGestorConta( this.gestor )
        .subscribe( response => {
            this._loadingDependences()
        }),
        (error) => {
            if (!error.ok) {}
        }
    }

    _loadingDependences(){

        this._gestorContaService.getGestoresContas()
        .subscribe((response) => {
            this.gestores = response.data
        })
    }
}
