import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

import { ExcelService } from 'src/app/services/excel.service';
import * as moment from 'moment';

@Component({
  selector: 'app-imposto',
  templateUrl: './imposto.component.html',
  styleUrls: ['./imposto.component.css']
})
export class ImpostoComponent implements OnInit {

  public imposto: any;
  private loading: boolean = false;

  private items: any = [];

  constructor(private http: HttpService, private configService: ConfigService, private excelService: ExcelService) { }

  ngOnInit() {
    this.getPageFilterData(1);
  }

  exportAsXLSX(): void {
    var CurrentDate = new Date();
    var nameFile = "Lista_impostoss-" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    this.excelService.exportAsExcelFile(document.getElementsByClassName("exportAsXLSXCliente")[0], nameFile);
  }
  exportAsPDF(): void {
    //this.reportLoja.relatorioLoja(this.cobrancas, this.simpleForm.value, 'save');
  }

  imprimirPDF(): void {
    //this.reportLoja.relatorioLoja(this.cobrancas, this.simpleForm.value);
  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
    this.listarImpostos();
  }


  /**
   * @name "Listar Impostos"
   * @descriptio "Esta Função permite Listar todos impostos"
   * @author "caniggia.moreira@itgest.pt"
   * @param start 
   * @param end 
   */
  private listarImpostos() {

    this.loading = true
    this.http.__call('imposto/listar', this.http.filters).subscribe(
      response => {

        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.loading = false;

      }
    );
  }

  private setDataImposto(imposto) {
    this.imposto = imposto
  }


}

