import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

@Component({
  selector: 'app-ramal',
  templateUrl: './ramal.component.html',
  styleUrls: ['./ramal.component.css']
})
export class RamalComponent implements OnInit {

  private ramal = {
    id: null,
    Material: null,
    Diametro_nominal: null,
    Comprimento: null,
    Profundidade: null,
    Bairro: null,
    Rua: null,
    tipo_ramal_id: null,
    Tipo_ramal: null,
  }

  private items: any = [];  
  private tipo_ramals: any = [];

  constructor(private http: HttpService, private configService: ConfigService) { }

  ngOnInit() {
    this.getPageFilterData(1);
    this.getTiposRamal();
  }

  private getTiposRamal() {
    this.http.call_get('tipo-ramal/selectBox', null).subscribe(
      response => {
        this.tipo_ramals = response;
      }
    );
  }


  private ListarRamal() {

    this.configService.loaddinStarter('start');

    this.http.__call('gestao-ramal/listagem', this.http.filters).subscribe(

      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.configService.loaddinStarter('stop');

      }
    );
  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
    this.ListarRamal();
  }


  private register(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (this.ramal.Material == "") {
      this.configService.showAlert("O campo Material é obrigatório", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    } 
    else {
    this.http.__call('gestao-ramal/create', this.ramal).subscribe(
      res => {
        if (Object(res).code == 500) {
          this.configService.showAlert(Object(res).message, 'alert-danger', true);
          this.configService.loaddinStarter('stop');
        } 
        else {
          this.configService.showAlert(Object(res).message, 'alert-success', true);
          this.clearFormInputs(e);
          this.ListarRamal()
          this.configService.loaddinStarter('stop');
        }
      }
    )
   }; 
}


  private clearFormInputs(e) {
    e.target.elements[0].value = null;
    e.target.elements[1].value = null;
    e.target.elements[2].value = null;
  }

  private refresh(id, Material, Diametro_nominal, Comprimento, Profundidade, Bairro, Rua, Tipo_ramal) {
    this.ramal.id = id;
    this.ramal.Material = Material;
    this.ramal.Diametro_nominal = Diametro_nominal;
    this.ramal.Comprimento = Comprimento;
    this.ramal.Profundidade = Profundidade;
    this.ramal.Bairro = Bairro;
    this.ramal.Rua = Rua;
    this.ramal.Tipo_ramal = Tipo_ramal;
  }

  private editar(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (this.ramal.Material == "") {
      this.configService.showAlert("O campo Material é obrigatórios", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    } 
    else {
      this.http.__call('gestao-ramal/update/' + this.ramal.id, this.ramal).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } 
          else {
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.ListarRamal();

          }
        }
      );
   } 
    this.configService.loaddinStarter('stop');
  }


  private ini() {
    this.ramal = {
      id: null,
      Material: null,
      Diametro_nominal: null,
      Comprimento: null,
      Profundidade: null,
      Bairro: null,
      Rua: null,
      tipo_ramal_id: null,
      Tipo_ramal: null,
    }
  }
}
