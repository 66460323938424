import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxImageCompressService } from 'ngx-image-compress';
import { first } from 'rxjs/operators';
import { MouseEvent } from '@agm/core';

@Component({
  selector: 'app-historico-ordem-servico',
  templateUrl: './historico-ordem-servico.component.html',
  styleUrls: ['./historico-ordem-servico.component.css']
})
export class HistoricoOrdemServicoComponent implements OnInit {

  @Input() modal: string = "modalHistoricoOs";
  @Input() title: string = "Registar Equipamento";
  @Input() resultados: any;
  @Input() item: any;

  submitted = false;
  formErrors: any;
  private loading: boolean = false;
  private ver: boolean = true;

  private success_new: boolean = false;

  private items: any = [];

  @Input() simpleFormCreateOrEdit: FormGroup;

  @Output() private loadList = new EventEmitter<any>();

  public filters = {
    search: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    pagination: {
      perPage: 5,// Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima, 
      lastPage: null,
      total: null // defini o total de registo da BD
    },
    filter: null // subelemente do filter
  }

  constructor(private http: HttpService, private configService: ConfigService, private formBuilder: FormBuilder) { 
    this.createForm();
  }

  ngOnInit() {
  }

  createForm() {
    this.simpleFormCreateOrEdit = this.formBuilder.group({
      index: [{ value: null, disabled: true }],
      descricao: [null, Validators.required],
      ordem_servico_id: [{ value: null, disabled: true }]
    })
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.simpleFormCreateOrEdit.controls;
  }

  onReset() {
    this.submitted = false;
    this.simpleFormCreateOrEdit.reset();
  }

  private setData(item){
      this.items = item;
      this.changeOrEdit(this.items)
  }


  changeOrEdit(value){
    if (value !== undefined ) {
      
      this.title = "Editar";
      //this.onReset()
      
      this.simpleFormCreateOrEdit.patchValue({
        index: value.id,
        descricao: value.descricao
      });
      
    } else {
      this.onReset()

      this.title = "Registar";
      
    }
  }


  onSubmit() {

    this.submitted = true;

    // parar aquei se o formulário for inválido
    if (this.simpleFormCreateOrEdit.invalid) {
      return;
    }
    this.loading = true;

    
    const index = this.simpleFormCreateOrEdit.getRawValue().index;
    // TODO: usado para fazer a requisição com a api de criação de objsct or update
    const uri = (index === null ? 'ordemservico/os-historico/register' : 'ordemservico/os-historico/update/' + index);
    this.createOrEdit(uri, this.simpleFormCreateOrEdit, (index === null ? true : false));

  }

  createOrEdit(uri: any, formulario: FormGroup, isCreate: boolean) {

    formulario.value.ordem_servico_id = this.item.id;

    // TODO: usado para fazer a requisição com a api de criação de object
    this.http.__call(uri, formulario.value).pipe(first()).subscribe(
      response => {
        this.submitted = false;
        this.loading = false;
        if (isCreate && Object(response).code ==200) {
          formulario.reset(); 
        }  
        
        if (Object(response).code ==200) {
          this.reloadData();
        }
      },
      error => {
        this.submitted = false;
        this.loading = false;
      });
  }


  /* ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
     
    if (this.items !== undefined ) {
      
      this.title = "Editar";
      //this.onReset()
      
      this.simpleFormCreateOrEdit.patchValue({
        index: this.items.id,
        descricao: this.items.descricao
      });
      
    } else {
      this.onReset()

      this.title = "Registar";
      
    }
  }
 */
  private reloadData() {

    this.http.call_get('ordemservico/os-historico/listagem/' + this.item.id, null).subscribe(
      response => {
        this.resultados = Object(response).data;
        
      }
    );
    
  }

}
