import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import * as jsPDF from 'jspdf';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-bill-run-header',
  templateUrl: './bill-run-header.component.html',
  styleUrls: ['./bill-run-header.component.css']
})
export class BillRunHeaderComponent implements OnInit {


  private credencials = {
    last_name: null
  }

  private dataType = {
    data: null,
    report_name: null,
    factura_id: null
  }

  private search: string;


  private recibo = {
    recibo_id: null,
    recibo_sigla: null,
    status_reason: null,
    status_date: null,
    status: null
  }


  private items: any = [];
  private facturas: any = [];

  constructor(private http: HttpService, private configService: ConfigService) { }

  ngOnInit() {
    this.getPageFilterData(1);
  }

  private clientes: any = [];
  view_client = false;
  private getCliente() {
    this.view_client = true;
    this.http.__call('cliente/search-cliente', { start: 1, end: 10, search: this.search }).subscribe(
      response => {
        this.clientes = Object(response).data.data;

        this.configService.loaddinStarter('stop');
      }
    );
  }

  private cliente: any = null;

  private setCliente(client: any) {
    this.cliente = client;
    this.search = client.nome
    this.view_client = false;

    this.http.__call('factura/consultarFacturaPosPagoVoz/' + client.id, null).subscribe(
      response => {

        this.configService.loaddinStarter('stop');
        if (Object(response).code == 500) {
          this.facturas = []
          this.configService.showAlert(Object(response).message, 'alert-danger', true);
        } else {
          this.facturas = Object(response).data;
        }
      }
    );
  }

  private btnImprimirFactura(id) {
    //this.configService.imprimirFactura(id, "2ª Via");
  }

  private btnEnviarFactura(id) {

    this.configService.loaddinStarter('start');
    this.http.call_get('enviar/factura/email/one/' + id, null).subscribe(
      res => {

        this.configService.loaddinStarter('stop');
      }
    );
  }

  close() {
    this.search = ''
    this.facturas = []
  }

  private listarBillRunHeader() {

    this.configService.loaddinStarter('start');

    this.http.__call('billRunHeader/listagem', this.http.filters).subscribe(

      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.configService.loaddinStarter('stop');


      }
    );
  }


  private enviarFactura(id: number) {
    this.configService.loaddinStarter('start');
    this.http.call_get('enviar/factura/email/' + id, null).subscribe(
      res => {
        this.configService.loaddinStarter('stop');
      }
    );
  }


  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
    this.listarBillRunHeader();
  }


}
