import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js';

import { AuthService } from 'src/app/providers/auth/auth.service';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

@Component({
  selector: 'app-pedidos-relatorio',
  templateUrl: './pedidos-relatorio.component.html',
  styleUrls: ['./pedidos-relatorio.component.css']
})
export class PedidosRelatorioComponent implements OnInit {



  private dash = {
    clienteCount: 0,
    facturaCount: 0,
    produtoCount: 0,
    documentoCount: 0,


    facturaSumTotalSemImpostoHoje: 0,
    facturaSumTotalSemImpostoMes: 0,
    facturaSumTotalSemImpostoOntem: 0,
    facturaSumTotalSemImpostoMesAnterior: 0,
    facturaSumTotalSemImpostoGeral: 0,

    facturaSumTotalComImpostoHoje: 0,
    facturaSumTotalComImpostoMes: 0,
    facturaSumTotalComImpostoOntem: 0,
    facturaSumTotalComImpostoMesAnterior: 0,
    facturaSumTotalComImpostoGeral: 0
  }

  /*
  private totalPedidos = {
    finalizados: 0,
    rejeitados: 0,
    outros: 0
  }
*/

  public pieChartLabels = ['Sales Q1', 'Sales Q2', 'Sales Q3', 'Sales Q4'];

  public pieChartType = 'pie';

  private meio: any = ['Dih']

  myChart: any = [];
  private totalPedidosFinalizados: any = [];
  private totalPedidosRejeitado: any = [];
  private totalOutro: any = [];
  private items: any = [];

  constructor(private auth: AuthService, private http: HttpService, private configService: ConfigService) {
  }

  ngOnInit() {
    this.getPageFilterData(1);
    this.pedidosTotalDash();


  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
    this.listarPedidos();
  }

  private listarPedidos() {

    this.configService.loaddinStarter('start');

    this.http.__call('pedido/listagem', this.http.filters).subscribe(

      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;

       // console.log(this.items)
        this.configService.loaddinStarter('stop');

      }
    );
  }

  private getCliente(nomeCliente: any) {
   // this.http.filters.search = nomeCliente;

  }



  private pedidosTotalDash() {
    this.configService.loaddinStarter('start');
    this.http.call_get('pedido/totalDashBoard', null).subscribe(
      response => {
        let dado = Object(response).data;

        //console.log(dado)
        this.totalPedidosFinalizados = dado.totalFinalizado.totalFinalizado;
        this.totalPedidosRejeitado = dado.totalRejeitado.totalRejeitado;
        this.totalOutro = dado.totalOutro.totalOutro;


        let pieChartData = [
          this.totalPedidosFinalizados,
          this.totalPedidosRejeitado,
          this.totalOutro];

        this.callChart(pieChartData);



      }

    );
  }


  private callChart(pieChartData) {
    this.myChart = new Chart('myChart', {
      type: 'bar',
      data: {
        labels: ['Finalizado', 'Rejeitado', 'Outro'],
        datasets: [{
          label: '# Pedidos Finalizados | Rejeitados | Outros ',
          data: pieChartData,
          backgroundColor: [
            'rgb(40, 167, 69)',
            'rgb(255, 0, 0)',
            'rgba(0, 128, 255)'
          ],
          borderColor: [
            'rgb(40, 167, 69)',
            'rgb(255, 0, 0)',
            'rgba(0, 128, 255)'
          ],
          borderWidth: 1
        }]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    });
  }





}
