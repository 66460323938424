import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfigService } from 'src/app/providers/config/config.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent implements OnInit {

  @ViewChild("loadding") loadding;
  constructor(private loaddingEvent: ConfigService) { }

  ngOnInit() {
    
    this.loaddingEvent.loaddingEvent.subscribe(
      res => {
        this.loaddingStatus(res.type);
      }
    );
  }

  public loaddingStatus(type) {
    if (type == 'stop') {
      this.loadding.nativeElement.classList.add("d-none");
    } else {
      this.loadding.nativeElement.classList.remove("d-none");
    }

  }

}
