import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { ConfigService } from 'src/app/providers/config/config.service';


@Component({
  selector: 'app-topnavbar',
  templateUrl: './topnavbar.component.html',
  styleUrls: ['./topnavbar.component.css']
})
export class TopnavbarComponent implements OnInit {
  
  isLoggedIn: boolean = false;  
  public currentUser: any; 

  constructor(private auth: AuthService, private router: Router, private spinner: NgxSpinnerService, private configService: ConfigService) {
    this.currentUser = this.auth.currentUserValue;
    /*this.auth.authSub.subscribe((data) => {
      this.isLoggedIn = data
    })*/
  }

  ngOnInit() {
    //this.isLoggedIn = this.auth.getAuthStatus()
    /** spinner inicia no init */
    this.spinner.show();
    setTimeout(() => {
      /**o spinner termina após 5 segundos */
      this.spinner.hide();
    }, 1000);
  }

  
  logout() {
    //localStorage.removeItem('userToken');

    this.auth.logoutUser()
    //this.router.navigate(['/login']) 
  }

}
