import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/providers/config/config.service';
import { HttpService } from 'src/app/providers/http/http.service';

@Component({
  selector: 'app-listar-ramals',
  templateUrl: './listar-ramals.component.html',
  styleUrls: ['./listar-ramals.component.css']
})
export class ListarRamalsComponent implements OnInit {


  private local_consumo = {
    id: null,
    moradia_numero: null,
    is_predio: null,
    predio_nome: null,
    predio_andar: null,
    cil: null,
    cil_flag: true,
    rua_id: null,
    rua: null,
    bairro_id: null,
    bairro: null,
    has_quarteirao: false,
    quarteirao_id: null,
    quarteirao: null,
    has_distrito: false,
    distrito_id: null,
    distrito: null,
    municipio_id: null,
    municipio: null,
    provincia_id: null,
    is_active: null,
    user_id: null,
  };

  private ligacao_ramal = {
    id: null,
    ponto_a_id: null,
    ponto_b_id: null,
    estado_ligacao_ramal: null,
    gestao_zmc_id: null,
    pontoA: null,
    pontoB: null,

    bairro_id: null,
    distrito_id: null,
    rua_id: null,
    municipio_id: null,

    comprimento: null,
    profundidade: null,
    codigo_remal: null,
    morada_ramals: null,
    tipo_ramal_id: null,
    diametro_ramal_id: null,
    material_ramal_id: null,
    estado_ramal: null,
    fita_sinalizacao: null,
    valvula_ramal: null,
    latitude: null,
    longitude: null,
  }

  private local_consumos: any[];

  view_local_consumo_cil = false;
  private predio_flag: boolean = false;
  private items: any = [];
  private ponto_a: any = [];
  private ponto_b: any = [];

  private ruas: any[];

  private tipo_objectos: any = [];
  private tipo_ramals: any[];
  private diametro_ramals: any[];
  private material_ramals: any[];

  private items_inicial: any = [];
  private items_final: any = [];

  private isDisabled: boolean = false;
  private loading: boolean = false;

  private interest = {
    id: null,
  };


  private objectoRamal = {
    id: null,
    descricao: null,
  };


  private ponto = {
    id: null,
    value: null,
    rota: null,
    slug: null,
  };

  view_objecto_ramal = false;

  private tipoObjecto = {
    id: null,
    descricao: null,
    tipo_objecto_b_id: null,
  };

  quarteiroes: any = []
  distritos: any = []
  bairros: any = []
  municipios: any = [];
  zmcs: any = []

  constructor(private http: HttpService, private configService: ConfigService) { }

  ngOnInit() {
    this.http.filters.search = null
    this.getPageFilterData(1);
    this.loadSelectOptions();

  }
  ngOnDestroy() {
    this.http.filters.orderBy = null
  }


  private loadSelectOptions() {
    this.getObjectoTecnico();
    this.selectBoxTipoRamals();
    this.selectBoxDiametroRamals();
    this.selectBoxMaterialRamals();
    this._getMunicipios();
    this.selectBoxZmc();
  }

  private selectBoxZmc() {
    this.http
      .call_get("api/v1/gestao-zmc/selectBox", null)
      .subscribe((response) => {
        this.zmcs = Object(response);
      });
  }
  _getMunicipios() {
    this.http.__call('municipio/listagem', this.http.filters)
      .subscribe(response => {
        this.municipios = Object(response).data.data
        console.log(response);
      }),
      (error) => {
        if (!error.ok) {
          console.log(error)
        }
      }
  }

  _changeDistrito(municipio_id) {
    if (municipio_id) {
      this._getComuna(municipio_id);
    }
  }
  _changeBairros(distrito_id) {
    if (distrito_id) {
      this._getBairros(distrito_id);
    }
  }

  _changeRua(bairro_id) {
    if (bairro_id) {
      this._getRuasDoBairro(bairro_id);
    }
  }
  _getBairros(id) {
    this.http.__call('get-bairros/' + id, this.http.filters)
      .subscribe(response => {
        this.bairros = Object(response).data
      }),
      (error) => {
        if (!error.ok) {
          console.log(error)
        }
      }
  }

  _getComuna(id) {
    this.http.__call('get-distritos/' + id, this.http.filters)
      .subscribe(response => {
        this.distritos = Object(response).data
      }),
      (error) => {
        if (!error.ok) {
        }
      }
  }
  _getRuasDoBairro(id) {
    this.http.call_get(`rua/selectBoxByBairro/${id}`, null)
      .subscribe(
        response => {
          this.ruas = Object(response)
        }
      )
  }

  private getObjectoTecnico() {
    this.http
      .call_get("tipo-objecto-tecnico/selectBox", null)
      .subscribe((response) => {
        this.tipo_objectos = Object(response).data;
      });
  }

  private selectBoxTipoRamals() {
    this.http.call_get("tipo-ramal/selectBox", null).subscribe((response) => {
      this.tipo_ramals = Object(response);
    });
  }

  private selectBoxDiametroRamals() {
    this.http
      .call_get("diametro-ramal/selectBox", null)
      .subscribe((response) => {
        this.diametro_ramals = Object(response);
      });
  }

  private selectBoxMaterialRamals() {
    this.http
      .call_get("material-ramal/selectBox", null)
      .subscribe((response) => {
        this.material_ramals = Object(response);
      });
  }

  private sincronizar() {

    this.isDisabled = true;
    this.loading = true;
    this.configService.loaddinStarter('start');

    this.http.call_get("sincronizarRamalGia", null).subscribe((res: any) => {
      if (Object(res).code == 200) {

        this.ListarRamal();

        this.isDisabled = false;
      }

    });
  }


  private ListarRamal() {

    this.configService.loaddinStarter('start');

    this.http.__call('gestao-ramal/listagem', { filters: this.http.filters }).subscribe(

      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.configService.loaddinStarter('stop');
        this.loading = false

      }
    );
  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1;
    this.ListarRamal();
  }

  private save() {
    this.configService.loaddinStarter('start');

    this.http.__call('gestao-ramal/update/' + this.ligacao_ramal.id, this.ligacao_ramal).subscribe(
      res => {
        if (Object(res).code == 500) {
          this.configService.showAlert(Object(res).message, 'alert-danger', true);
          this.configService.loaddinStarter('stop');
        }
        else {
          this.configService.showAlert(Object(res).message, 'alert-success', true);
          this.ListarRamal()
          this.ini();
          this.configService.loaddinStarter('stop');
        }
      }
    )
  }

  private refresh(item: any) {

    this.ligacao_ramal.id = item.id;
    this.ligacao_ramal.pontoA = item.pontoA;
    this.ligacao_ramal.pontoB = item.pontoB;
    this.ligacao_ramal.comprimento = item.comprimento;
    this.ligacao_ramal.profundidade = item.profundidade;
    this.ligacao_ramal.ponto_a_id = item.ponto_a_id;
    this.ligacao_ramal.ponto_b_id = item.ponto_b_id;

    this.ligacao_ramal.municipio_id = item.municipio_id;
    this.ligacao_ramal.distrito_id = item.distrito_id;
    this.ligacao_ramal.bairro_id = item.bairro_id;
    this.ligacao_ramal.rua_id = item.rua_id;
    this.ligacao_ramal.gestao_zmc_id = item.gestao_zmc_id;

    this.ligacao_ramal.codigo_remal = item.codigo_remal;
    this.ligacao_ramal.morada_ramals = item.morada_ramals;
    this.ligacao_ramal.tipo_ramal_id = item.tipo_ramal_id;
    this.ligacao_ramal.diametro_ramal_id = item.diametro_ramal_id;
    this.ligacao_ramal.material_ramal_id = item.material_ramal_id;
    this.ligacao_ramal.estado_ramal = item.estado_ramal;
    this.ligacao_ramal.latitude = item.latitude;
    this.ligacao_ramal.longitude = item.longitude;
  }


  private refreshSyncRamalGia() {

    this.configService.loaddinStarter('start');

    this.http.__call('refreshSyncRamalGia',
      {
        codigo_ramal: this.ligacao_ramal.codigo_remal

      }
    ).subscribe(
      res => {
        if (Object(res).code == 200) {
          this.configService.showAlert(Object(res).message, 'alert-danger', true);

          this.ligacao_ramal.comprimento = Object(res).data.COMPRIMENTO;
          this.ligacao_ramal.profundidade = Object(res).data.PROFUNDIDADE;

          this.ligacao_ramal.morada_ramals = Object(res).data.MORADA;

          this.ligacao_ramal.latitude = Object(res).data.LATITUDE;
          this.ligacao_ramal.longitude = Object(res).data.LONGITUDE;

          this.configService.loaddinStarter('stop');
        }
        else {
          this.configService.showAlert(Object(res).message, 'alert-success', true);

          this.configService.loaddinStarter('stop');
        }
      }
    )
  }

  private pontoOpt() {
    this.interest.id = this.ponto.rota;

    if (this.interest.id == "pontoBObjectoRamalModal") {
      this.ponto.slug = "OBJECTO-DO-RAMAL";
    } else {
      this.ponto.slug = "LOCAL-INSTALACAO";
    }
  }


  private getObjectoRamal() {
    this.items_inicial = [];
    this.view_objecto_ramal = true

    this.http
      .__call("search/inicio/ligacao", {
        start: 1,
        end: 10,
        tipo_objecto_id: this.tipoObjecto.id,
        search: this.objectoRamal.descricao,
      })
      .subscribe((res) => {
        this.items_inicial = Object(res).data.data;
      });
  }


  private getLocalInstalacaosByCILAndMoradia() {
    if (this.local_consumo.cil == "") {
      //this.setNullResidencia();
    }

    this.view_local_consumo_cil = true;
    this.http
      .__call("local-instalacao/getLocalInstalacaosByRamal", {
        start: 1,
        end: 15,
        search: this.local_consumo.cil,
      })
      .subscribe((response) => {
        this.local_consumos = Object(response).data.data;

        this.configService.loaddinStarter("stop");
      });
  }


  setObjectoRamal(item: any) {
    this.objectoRamal.id = item.id;
    this.objectoRamal.descricao = item.descricao

    this.ligacao_ramal.ponto_a_id = item.id
    this.ligacao_ramal.pontoA = this.ponto.slug

    this.view_objecto_ramal = false
  }

  private setLocalConsumo(item: any) {
    this.local_consumo.id = item.id;
    this.local_consumo.moradia_numero = item.moradia_numero;

    this.ligacao_ramal.ponto_a_id = item.id
    this.ligacao_ramal.pontoA = this.ponto.slug

    this.local_consumo.predio_nome =
      (item.cil != null ? "[" + item.cil + "] " : "[0000-000-000] ") +
      (item.is_predio
        ? "Prédio " +
        item.predio_nome +
        ", " +
        this.configService.decimalFormat(item.predio_andar) +
        "º Andar - Porta "
        : "Residência ") +
      this.configService.decimalFormat(item.moradia_numero);
    this.local_consumo.is_predio = item.is_predio;
    this.local_consumo.cil = item.cil;

    this.local_consumo.provincia_id = item.provincia_id;

    this.local_consumo.municipio_id = item.municipio_id;
    this.local_consumo.municipio = item.municipio;
    this.local_consumo.distrito_id = item.distrito_id;
    this.local_consumo.distrito = item.distrito;
    this.local_consumo.bairro_id = item.bairro_id;
    this.local_consumo.bairro = item.bairro;
    this.local_consumo.quarteirao_id = item.quarteirao_id;
    this.local_consumo.quarteirao = item.quarteirao;
    this.local_consumo.rua_id = item.rua_id;
    this.local_consumo.rua = item.rua;

    this.view_local_consumo_cil = false
  }

  saveLigacao() {
    this.http.__call("nova/ligacao-ramal/" + this.ligacao_ramal.id, this.ligacao_ramal)
      .subscribe(res => {
        this.ini();
        this.resetObjectos();
        this.resetLocalIntalacao();
      })
  }

  resetObjectos() {
    this.objectoRamal.id = null
    this.objectoRamal.descricao = null
  }

  resetLocalIntalacao() {
    this.local_consumo.cil;
  }

  private ini() {
    this.ligacao_ramal = {
      id: null,
      ponto_a_id: null,
      ponto_b_id: null,
      estado_ligacao_ramal: null,
      pontoA: null,
      pontoB: null,

      comprimento: null,
      profundidade: null,
      codigo_remal: null,
      morada_ramals: null,
      tipo_ramal_id: null,
      diametro_ramal_id: null,
      material_ramal_id: null,
      gestao_zmc_id: null,
      estado_ramal: null,
      fita_sinalizacao: null,
      valvula_ramal: null,
      latitude: null,
      longitude: null,
      municipio_id: null,
      distrito_id: null,
      bairro_id: null,
      rua_id: null
    }
  }

}
