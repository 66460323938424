import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

import { Router,ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl  } from '@angular/forms';

import { ExcelService } from 'src/app/services/excel.service';
import { ReportVendaProdutoLojaService } from 'src/app/components/report-at/relatorios/financeira/report-venda-produto-loja.service';

import * as moment from 'moment';
import { ExcelAutoService } from 'src/app/services/excel/excel-auto.service';

@Component({
  selector: 'app-report-venda-loja-produto',
  templateUrl: './report-venda-loja-produto.component.html',
  styleUrls: ['./report-venda-loja-produto.component.css']
})
export class ReportVendaLojaProdutoComponent implements OnInit {

  public titulo="Vendas Por Lojas Por Produtos";
  loading: boolean = false;
  private loadingLojas: string = "Loja"

  public filters = {
    search: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    pagination: {
      perPage: 5,// Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima,
      lastPage: null,
      total: null // defini o total de registo da BD
    },
    filter: null // subelemente do filter
    ,is_allexel:false
  }

  private localUrl: any;
  private largura: any;
  private altura: any;
  private cobrancas: any = [];
  private direccaos: any = []
  private lojas: any = []
  private produtos: any = []
  private anos: any = []
  private provincias: any = [];
  private filials: any = [];

  private disabledButton = true

  submitted = false;
  simpleForm: FormGroup;
  tipoFacturacao:string;

  constructor(private formBuilder: FormBuilder,
     private http: HttpService,
     private route: ActivatedRoute,
     private configService: ConfigService,
     private relCobracaGlobal: ReportVendaProdutoLojaService,
     private excelService: ExcelAutoService) {
    this.createForm();
  }

  ngOnInit() {
    this.empresaUser()
    this.getFilials();
    this.gerarAno();
    this.getLojas();
    this.getProdutos();
    this.direccaosSelectBox();
  }



  createForm() {
    this.simpleForm = this.formBuilder.group({
     // ano: [null, Validators.required],
      data1: [null, Validators.required],
      data2: [null, [this.matchValidator.bind(this)]],
      direccao: [null,],
      //tipoFacturacao: [null],
      loja: [null, Validators.required],
      produto: [null, Validators.required],
      loja_nome: [null],
      produto_nome: [null],
      municipe: [null, Validators.required]
    });
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.simpleForm.controls;
  }
  onSubmit() {

    this.submitted = true;
    // parar aquei se o simpleFormulário for inválido
    if (this.simpleForm.invalid) {
      return;
    }
    const uri = 'relatorio/financeira/vendalojaproduto';
    this.createOrEdit(uri, this.simpleForm);
  }
private empresaUser() {
    this.configService.loaddinStarter('start');

    this.http.call_get('empresa/empresa-user', null).subscribe(
      response => {
        this.localUrl = Object(response).data.logotipo
        this.altura = Object(response).data.width
        this.largura = Object(response).data.height
        this.configService.loaddinStarter('stop');
      }
    );
  }

  setTrue(){
    this.filters.pagination.page=this.filters.pagination.page
    this.filters.is_allexel=true
    this.onSubmit()
  }

  matchValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const fromValue = control.value;
    if(fromValue) {

     // console.log(this.simpleForm.controls)
      const startDate = (<FormGroup>this.simpleForm.get('data1')).value;
      const endDate = (<FormGroup>this.simpleForm.get('data2')).value;
      if (startDate <= endDate) {
        //console.log('Control: ', control);
       return null;
      }
      //console.log('Control: ', control);
      return { 'invalidDate' : true };
    }

  }

  /**
  * @name "relatorioFacturacaoRealizadaCobrancaGlobal"
  * @descriptio "Esta Função permite gerar relatorio Facturacao Realizada Cobrança Global"
  * @author "caniggiamoreira@gmail.com"
  * @param start
  * @param end
  */
  createOrEdit(uri: any, simpleFormulario: FormGroup) {
    this.cobrancas = []
    this.loading = true;
    this.disabledButton = true;
    this.total_venda.total=0;
    // TODO: usado para fazer a requisição com a api de criação de organismo
    this.http.__call(uri, simpleFormulario.value).subscribe(
      response => {
        this.cobrancas = Object(response).data;
        this.loading = false;
        if (this.cobrancas.length != 0) {
          this.disabledButton = false;
          this.somaTotais();
        }
        if(this.filters.is_allexel==false){
          this.cobrancas = Object(response).data;
        }else{
          this.cobrancas = Object(response).data;
          this.exportAsXLSX(this.cobrancas)
        }
        this.filters.is_allexel=false
        this.loading = false;
      }
    );
  }

  exportAsXLSX(data:any):void {
    var CurrentDate = new Date();

 var keys= [
    { key: 'dia', width:30, style: { font: { name: 'Calibri' } } },
    { key: 'facturacao', width: 50 },
    { key: 'user', width: 30 },
    { key: 'municipio', width: 50 },
    { key: 'loja', width:45, style: { font: { name: 'Calibri' } } },
    { key: 'produto', width: 50 },
    { key: 'quantidade', width: 15 },
    { key: 'total', width:30, style: { numFmt: '#,##0.00', } },
  ];

    var Cols = ["Dia","Facturação","Operador","Município","Loja","Produtos","Quantidade","Total"]
    var title='VENDA POR AGÊNCIA POR PRODUTO'
    var nameFile = "venda_agencia_produto" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
     this.excelService.excels(data,nameFile,this.localUrl,keys,Cols,title,5,8,30,3,[8])
  }

  exportAsPDF(): void {
   var file =  document.getElementsByClassName("tableCobranca")[0]
    this.relCobracaGlobal.relatorioVendasLojaProduto(file,'save',this.simpleForm.value,this.localUrl);
  }
  imprimirPDF(): void {
    var file =  document.getElementsByClassName("tableCobranca")[0]
    this.relCobracaGlobal.relatorioVendasLojaProduto(file,'print',this.simpleForm.value,this.localUrl);
  }


  private direccaosSelectBox() {
    this.http.call_get('direccao/selectBox', null).subscribe(
      response => {
        this.direccaos = Object(response).data;
      }
    );
  }

  private gerarAno() {
    var fecha = new Date();
    var anyo = fecha.getFullYear();

    let j = 0;
    for (let i = anyo; i >= 2000; i--) {
      this.anos[j] = i;
      j++;
    }
  }

  private getLojas() {
    this.loadingLojas = 'Carregando...';
    const id = this.simpleForm.getRawValue().municipe;
    if (id != "" || id != null || id != "T") {
      this.http.call_get('lojas/selectBox/' + id, null).subscribe(
        response => {
          this.lojas = Object(response)
          this.loadingLojas = 'Agência';
        }
      );
    }
  }

 /*  private getProvincias() {
    this.http.call_get('filial/selectBox', null).subscribe(
      response => {
        this.provincias = Object(response).data
      }
    );
  } */
  private getFilials() {
    this.http.call_get('municipio/selectBox/', null).subscribe(
      response => {
      this.filials = Object(response).data
    }
  );
}
  private getProdutos() {
    this.http.call_get('artigo/selectProdutos', null).subscribe(
      response => {
        this.produtos = Object(response).data
      }
    );
  }

  changeProduto() {
    this.simpleForm.patchValue({
      loja_nome: null
    });
    this.produtos.forEach(element => {
      if (element.id == this.simpleForm.getRawValue().produto) {
        this.simpleForm.patchValue({
          produto_nome: element.nome
        });
      }
    });
  }
  changeLoja() {
    this.simpleForm.patchValue({
      loja_nome: null
    });
    this.lojas.forEach(element => {
      if (element.id == this.simpleForm.getRawValue().loja) {
        this.simpleForm.patchValue({
          loja_nome: element.nome
        });
      }
    });
  }

  private total_venda = {
    total: 0
  }
  private somaTotais() {
    var totalv: number = 0;

    this.cobrancas.forEach(element => {
      totalv += element.total;
    });
    this.total_venda.total= totalv;
  }

}
