import { Component, OnInit, Input } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-documento',
  templateUrl: './documento.component.html',
  styleUrls: ['./documento.component.css']
})

export class DocumentoComponent implements OnInit {

  @Input() documentsModal: string = "documentsModal";
  @Input() documentos = [];

  documents = [];

  submitted = false;
  docsRegistarForm: FormGroup;
  private title_modal: string ="Registar Documento";

  validar = true;

  constructor(private http: HttpService, private configService: ConfigService, private formBuilder: FormBuilder) {
    this.createForm();
  }

  ngOnInit() {
    this.configService.listarDocumentos();
  }




  createForm() {
    this.title_modal = 'Registar';
    this.docsRegistarForm = this.formBuilder.group({
      index: [{ value: null, disabled: true }],
      nome: ['', Validators.required],
      sigla: ['', Validators.required],
      credito: ['', ],
      debito: ['', ]

    });
  }

  private editar(d: any) {
    console.log(d);
    this.title_modal = 'Editar';
    this.docsRegistarForm.patchValue({
      index: d.id,
      nome: d.nome, 
      sigla: d.sigla,
      debito: d.debito,
      credito: d.credito
    }); 

  }
  // convenience getter for easy access to form fields
  get f() {
    return this.docsRegistarForm.controls;
  }

  onReset() {
    this.submitted = false;
    this.docsRegistarForm.reset();
  }

  onSubmit() {
    this.submitted = true;
    // parar aquei se o docsRegistarFormulário for inválido
    if (this.docsRegistarForm.invalid) {
      return;
    } 

    const index = this.docsRegistarForm.getRawValue().index;
    if (index === null) {
      // TODO: usado para fazer a requisição com a api de criação de organismo
      const uri = 'documento/create';
      
      this.createOrEdit(uri, this.docsRegistarForm, true);
    } else {
      // TODO: update
      const uri = 'documento/update/' + index;
      this.createOrEdit(uri, this.docsRegistarForm, false);
    }

  }

  createOrEdit(uri: any, docsRegistarFormulario: FormGroup, isCreate: boolean) {
    this.configService.loaddinStarter('start');
    // TODO: usado para fazer a requisição com a api de criação de organismo
    this.http.__call(uri, docsRegistarFormulario.value).subscribe(
      res => {

        if (Object(res).code == 200) {
          this.submitted = false;
          if (isCreate) {
            docsRegistarFormulario.reset();
          }
          this.configService.listarDocumentos();
          this.validar = false;
          this.configService.showAlert(Object(res).message, 'alert-success', true);
        } else {
          this.configService.showAlert(Object(res).message, 'alert-danger', true);
        }
      }
    );
    this.configService.loaddinStarter('stop');
  }

  

}

