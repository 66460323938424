import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

@Component({
  selector: 'app-conf-estado-servico',
  templateUrl: './conf-estado-servico.component.html',
  styleUrls: ['./conf-estado-servico.component.css']
})
export class ConfEstadoServicoComponent implements OnInit {

  private estadoservico = {
    id: null,
    nome: null,
    slug: null
  }


  private items: any = [];

  constructor(private http: HttpService, private configService: ConfigService) { }

  ngOnInit() {
    this.http.filters.search = null
    this.getPageFilterData(1);
  }


  private listaEstadoServico() {

    this.configService.loaddinStarter('start');

    this.http.__call('estado-servico/listagem', this.http.filters).subscribe(

      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.configService.loaddinStarter('stop');

      }
    );
  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page =Number.isInteger(page) ? page : 1 ;
    this.listaEstadoServico();
  }


  private register(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (this.estadoservico.nome == "" || this.estadoservico.slug == null) {
      this.configService.showAlert("Os campos Estado e Slug são obrigatórios", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    } else {
    this.http.__call('estado-servico/create', this.estadoservico).subscribe(
      res => {
        if (Object(res).code == 500) {
          this.configService.showAlert(Object(res).message, 'alert-danger', true);
          this.configService.loaddinStarter('stop');
        } else {
          this.configService.showAlert(Object(res).message, 'alert-success', true);
          this.clearFormInputs(e);
          this.listaEstadoServico()
          this.configService.loaddinStarter('stop');
        }
      }
    )
  };
}


  private clearFormInputs(e) {
    e.target.elements[0].value = null;
    e.target.elements[1].value = null;
    e.target.elements[2].value = null;
  }

  private refresh(id, nome, slug) {
    this.estadoservico.id = id;
    this.estadoservico.nome = nome;
    this.estadoservico.slug = slug;
  }

  private editar(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (this.estadoservico.nome == "" || this.estadoservico.slug == null) {
      this.configService.showAlert("Os campos Estado e Slug são obrigatórios", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    } else {
      this.http.__call('estado-servico/update/' + this.estadoservico.id, this.estadoservico).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            //this.configService.clearFormInputs(e);
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.listaEstadoServico();

          }
        }
      );
    }
    this.configService.loaddinStarter('stop');
  }


  private ini() {
    this.estadoservico = {
      id: null,
      nome: null,
      slug: null
    }
  }





}
