import { Injectable, Input, EventEmitter } from '@angular/core';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
var Buffer = require('buffer/').Buffer;


@Injectable({
  providedIn: 'root'
})
export class OrdemServicoReportService {

  @Input() app_environment: null;

  public pdfEvent = new EventEmitter<Object>();

  public imprimirAgendamento(data, original: any = 'Original', report: string = 'imprimir') {
    console.log('data preventiva=>', data)
    // You'll need to make your image into a Data URL
    // Use http://dataurl.net/#dataurlmaker
    var imgData = data.empresa.logotipo
    var doc = new jsPDF('p');
    var posX = 1;
    var posY = 5;
    var logo_width = 35;
    var logo_height = 40;
    var pos_body = logo_height + 5;
    var pos_equipamento = 0;
    var pos_cliente = 0;

    var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
    var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

    doc.addImage(imgData, 'JPEG', 8, 14, 28, 24);
    doc.setFontSize(8);
    doc.setFontType('Arial');

    if (data.empresa.addressDetail.length <= 62) {
      doc.text(posX + logo_width + 5, 30, 'NIF: ');
      doc.text(posX + logo_width + 13, 30, '' + (data.empresa.taxRegistrationNumber == null ? '' : data.empresa.taxRegistrationNumber));
      doc.text(posX + logo_width + 5, 35, 'Email: ');
      doc.text(posX + logo_width + 16, 35, '' + (data.empresa.email == null ? '' : data.empresa.email));
      doc.text(posX + logo_width + 50, 30, 'Telefone : ');
      doc.text(posX + logo_width + 65, 30, '' + (data.empresa.telefone == null ? '' : data.empresa.telefone));
      doc.text(posX + logo_width + 50, 35, 'Website: ');
      doc.text(posX + logo_width + 65, 35, '' + (data.empresa.site == null ? '' : data.empresa.site));
    } else {
      doc.text(posX + logo_width + 5, 33, 'NIF: ');
      doc.text(posX + logo_width + 13, 33, '' + (data.empresa.taxRegistrationNumber == null ? '' : data.empresa.taxRegistrationNumber));
      doc.text(posX + logo_width + 5, 38, 'Email: ');
      doc.text(posX + logo_width + 16, 38, '' + (data.empresa.email == null ? '' : data.empresa.email));
      doc.text(posX + logo_width + 60, 33, 'Telefone : ');
      doc.text(posX + logo_width + 80, 33, '' + (data.empresa.telefone == null ? '' : data.empresa.telefone));
      doc.text(posX + logo_width + 60, 38, 'Website: ');
      doc.text(posX + logo_width + 80, 38, '' + (data.empresa.site == null ? '' : data.empresa.site));
    }
    doc.text(posX + logo_width + 5, 20, '' + (data.empresa.companyName == null ? '' : data.empresa.companyName));
    //lines = doc.splitTextToSize(endereco, 62)
    doc.text(posX + logo_width + 5, 25, '' + (data.empresa.addressDetail == null ? '' : data.empresa.addressDetail));
    var pos_rectangulo = posX + logo_width + 110;
    doc.rect(pos_rectangulo, 18, pageWidth / 3 - 17, 20)
    doc.setFontSize(8);
    doc.setFontType('bold');
    doc.text(pos_rectangulo + 5, 23, 'Nº ' + ((data.ordemTrabalho || {}).codigo == null ? '' : (data.ordemTrabalho || {}).codigo) + ((data.ordemTrabalho || {}).codigo_agendamento == null ? '' : '/' + (data.ordemTrabalho || {}).codigo_agendamento));
    doc.setFontType('Normal');
    doc.text(pos_rectangulo + 5, 28, 'Data:' + (data.ordemTrabalho || {}).data_criacao + ' ' + (data.ordemTrabalho || {}).hora_criacao);
    doc.text(pos_rectangulo + 5, 34, 'Prioridade:');
    doc.setFontType('bold');
    doc.text(pos_rectangulo + 18, 34, '' + ((data.ordemTrabalho || {}).prioridade_descricao == null ? '' : (data.ordemTrabalho || {}).prioridade_descricao));

    // CORPO DO RELATÓRIO ........
    doc.setFontSize(8);
    doc.setFillColor(208, 211, 212);
    doc.setFontType('bold');
    doc.rect(10, pos_body, pageWidth - 20, 5);
    logo_height = pos_body - 5;
    doc.text(posX + 10, logo_height + 8.5, 'ORDEM DE TRABALHO -');

    var corpo_ordem = logo_height + 14;

    if ((data.ordemTrabalho || {}).agendamento_flag == 'FECHADA') {
      doc.setFontType('normal');
      doc.setFontSize(7);
      doc.text(posX + 110, corpo_ordem + 13, '' + ((data.ordemTrabalho || {}).data_fim_execucao == null ? '' : (data.ordemTrabalho || {}).data_fim_execucao));
      doc.text(posX + 130, corpo_ordem + 13, '' + ((data.ordemTrabalho || {}).hora_fim_execucao == null ? '' : (data.ordemTrabalho || {}).hora_fim_execucao));
      doc.text(posX + 150, corpo_ordem + 13, '' + ((data.ordemTrabalho || {}).dif_dias_definida == null ? '' : (data.ordemTrabalho || {}).dif_dias_definida));
      doc.text(posX + 155, corpo_ordem + 13, '' + ((data.ordemTrabalho || {}).dif_horas_definida == null ? '' : (data.ordemTrabalho || {}).dif_horas_definida));
      doc.text(posX + 160, corpo_ordem + 13, '' + ((data.ordemTrabalho || {}).dif_minutos_definida == null ? '' : (data.ordemTrabalho || {}).dif_minutos_definida));
      doc.setTextColor(0, 0, 255);
      doc.setFontSize(8);
      doc.text(posX + 53, corpo_ordem - 5.5, 'EXECUTADA', null, 'center');
      doc.setTextColor(0, 0, 0);
      doc.setFontSize(7);
      doc.text(posX + 10, (corpo_ordem + 13), '' + ((data.ordemTrabalho || {}).data_criacao == null ? '' : (data.ordemTrabalho || {}).data_criacao));
      doc.text(posX + 25, (corpo_ordem + 13), '' + ((data.ordemTrabalho || {}).hora_criacao == null ? '' : (data.ordemTrabalho || {}).hora_criacao));
      doc.text(posX + 40, (corpo_ordem + 13), '' + ((data.ordemTrabalho || {}).dif_dias_prevista == null ? '' : (data.ordemTrabalho || {}).dif_dias_prevista));
      doc.text(posX + 45, (corpo_ordem + 13), '' + ((data.ordemTrabalho || {}).dif_horas_prevista == null ? '' : (data.ordemTrabalho || {}).dif_horas_prevista));
      doc.text(posX + 50, (corpo_ordem + 13), '' + ((data.ordemTrabalho || {}).dif_minutos_prevista == null ? '' : (data.ordemTrabalho || {}).dif_minutos_prevista));
    } else {

      /*Dados do agendamento*/
      doc.setTextColor(45, 124, 35);
      doc.setFontSize(8);
      doc.text(posX + 10, (corpo_ordem + 13), '' + ((data.ordemTrabalho || {}).data_criacao == null ? '' : (data.ordemTrabalho || {}).data_criacao));
      doc.text(posX + 25, (corpo_ordem + 13), '' + ((data.ordemTrabalho || {}).hora_criacao == null ? '' : (data.ordemTrabalho || {}).hora_criacao));
      doc.text(posX + 40, (corpo_ordem + 13), '' + ((data.ordemTrabalho || {}).dif_dias_prevista == null ? '' : (data.ordemTrabalho || {}).dif_dias_prevista));
      doc.text(posX + 45, (corpo_ordem + 13), '' + ((data.ordemTrabalho || {}).dif_horas_prevista == null ? '' : (data.ordemTrabalho || {}).dif_horas_prevista));
      doc.text(posX + 50, (corpo_ordem + 13), '' + ((data.ordemTrabalho || {}).dif_minutos_prevista == null ? '' : (data.ordemTrabalho || {}).dif_minutos_prevista));
      doc.text(posX + 62, corpo_ordem - 5.5, '' + ((data.ordemTrabalho || {}).agendamento_estado == null ? '' : (data.ordemTrabalho || {}).agendamento_estado.toUpperCase()), null, 'center');
      doc.setTextColor(0, 0, 0);
      doc.setFontSize(7);
    }


    /*Dados da OS.*/
    doc.setFontSize(7);
    doc.setFontType('bold');
    doc.text(posX + 10, corpo_ordem, 'TÍTULO OT');
    doc.text(posX + 70, corpo_ordem, 'RESPONSÁVEL');
    doc.text(posX + 10, corpo_ordem + 7, 'AGENDAMENTO PREVISTO');
    doc.text(posX + 110, corpo_ordem, 'CLASSIFICAÇÃO');
    doc.text(posX + 150, corpo_ordem, 'TIPO OT');
    doc.text(posX + 110, corpo_ordem + 7, 'EXECUÇÃO');
    doc.setFontType('normal');
    doc.text(posX + 10, corpo_ordem + 10, 'DATA');//data_agendamento
    doc.text(posX + 25, corpo_ordem + 10, 'HORA');//hora_agendamento
    doc.text(posX + 40, corpo_ordem + 10, 'DURAÇÃO (D H M)');//duracao_agendamento
    doc.text(posX + 110, corpo_ordem + 10, 'DATA');//data_execução
    doc.text(posX + 130, corpo_ordem + 10, 'HORA');//hora_execução
    doc.text(posX + 150, corpo_ordem + 10, 'DURAÇÃO (D H M)');//duração_execução
    doc.setFontType('bold');
    doc.text(posX + 10, (corpo_ordem + 18), 'DESCRIÇÃO DA ORDEM DE TRABALHO');
    doc.setFontType('normal');

    var titulo_ot = (data.ordemTrabalho || {}).titulo == null ? '' : (data.ordemTrabalho || {}).titulo;
    var split_titulo_ot = doc.splitTextToSize(titulo_ot, 65);
    doc.setFontSize(6);
    doc.text(posX + 10, corpo_ordem + 2, split_titulo_ot);


    //doc.text(posX+10,corpo_ordem+3,'' + ((data.ordemTrabalho || {} ).titulo == null ? '' : (data.ordemTrabalho || {} ).titulo));
    doc.text(posX + 70, corpo_ordem + 2, '' + ((data.ordemTrabalho || {}).responsavel_nome == null ? '' : (data.ordemTrabalho || {}).responsavel_nome));
    /*PERIODO DE AGENDAMENTO*/
    doc.text(posX + 110, corpo_ordem + 2, '' + ((data.ordemTrabalho || {}).classificacao_descricao == null ? '' : (data.ordemTrabalho || {}).classificacao_descricao));

    var tipo_ot = (data.ordemTrabalho || {}).tipo_descricao == null ? '' : (data.ordemTrabalho || {}).tipo_descricao;
    var split_tipo_ot = doc.splitTextToSize(tipo_ot, 50);
    doc.text(posX + 150, corpo_ordem + 2, split_tipo_ot);


    //doc.text(posX+150,corpo_ordem+2, '' + ((data.ordemTrabalho || {} ).tipo_descricao == null ? '' : (data.ordemTrabalho || {} ).tipo_descricao));
    var splitText = doc.splitTextToSize((data.ordemTrabalho || {}).descricao == null ? '' : (data.ordemTrabalho || {}).descricao, 190)
    doc.text(splitText == null ? '' : splitText, posX + 10, corpo_ordem + 21);

    if ((data.ordemTrabalho || {}).descricao ? (data.ordemTrabalho || {}).descricao.length >= 448 : 0 >= 448) {
      doc.setFontType('bold');
      doc.text(posX + 10, corpo_ordem + 32, 'DESCRIÇÃO DO AGENDAMENTO');
      doc.setFontType('normal');
      var splitText = doc.splitTextToSize('' + (!data.agendamentoComentario ? '' : data.agendamentoComentario.descricao), 190)
      doc.text(splitText == '' ? '' : splitText, posX + 10, corpo_ordem + 35);
    } else {
      doc.setFontType('bold');
      doc.text(posX + 10, corpo_ordem + 28, 'DESCRIÇÃO DO AGENDAMENTO');
      doc.setFontType('normal');
      var splitText = doc.splitTextToSize('' + (!data.agendamentoComentario ? '' : data.agendamentoComentario.descricao), 190)
      doc.text(splitText == '' ? '' : splitText, posX + 10, corpo_ordem + 31);
    }

    /*Verificação do tamanho da descrição.*/

    if (data.agendamentoComentario && data.agendamentoComentario.descricao ? data.agendamentoComentario.descricao.length >= 448 : 0 >= 448) {
      if (data.ordemEquipamento) {
        pos_equipamento = corpo_ordem + 47;
      } else {
        pos_cliente = corpo_ordem + 47;
      }

    } else {
      if (data.ordemEquipamento) {
        pos_equipamento = corpo_ordem + 39;
      } else {
        pos_cliente = corpo_ordem + 39;
      }
    }
    if (data.ordemEquipamento && data.ordemCliente) {
      doc.setFontSize(8);
      doc.setFontType('bold');
      doc.setFillColor(208, 211, 212);
      doc.rect(10, pos_equipamento, pageWidth - 20, 5);
      doc.text(posX + 10, pos_equipamento + 3.5, 'EQUIPAMENTO');
      doc.setFontSize(7);
      doc.setFontType('bold');
      doc.text(posX + 10, pos_equipamento + 9, 'Nº SÉRIE');
      doc.text(posX + 30, pos_equipamento + 9, 'CÓDIGO DE REGISTO');
      doc.text(posX + 60, pos_equipamento + 9, 'ABREVIATURA');
      doc.text(posX + 10, pos_equipamento + 16, 'DESIGNAÇÃO');
      doc.text(posX + 140, pos_equipamento + 9, 'ESTADO');
      doc.text(posX + 170, pos_equipamento + 9, 'TIPO DE AQUISIÇÃO');
      doc.text(posX + 10, pos_equipamento + 23, 'GRAU DE CRITICIDADE');
      doc.text(posX + 90, pos_equipamento + 9, 'ESTADO DE CONSERVAÇÃO');
      doc.text(posX + 90, pos_equipamento + 23, 'MODELO');
      doc.text(posX + 135, pos_equipamento + 23, 'LATITUDE');
      doc.text(posX + 170, pos_equipamento + 23, 'LONGITUDE');
      /*Dados do Equipamento*/
      doc.setFontSize(7);
      doc.setFontType('normal');
      doc.text(posX + 10, pos_equipamento + 12, '' + (data.ordemEquipamento.numero_serie == null ? '' : data.ordemEquipamento.numero_serie));
      doc.text(posX + 30, pos_equipamento + 12, '' + (data.ordemEquipamento.codigo_registo == null ? '' : data.ordemEquipamento.codigo_registo));
      doc.text(posX + 60, pos_equipamento + 12, '' + (data.ordemEquipamento.abreviatura == null ? '' : data.ordemEquipamento.abreviatura));
      var designacao_equip = doc.splitTextToSize(data.ordemEquipamento.designacao_equipamento, 190)
      doc.text(designacao_equip == null ? '' : designacao_equip, posX + 10, pos_equipamento + 19);
      doc.text(posX + 140, pos_equipamento + 12, '' + (data.ordemEquipamento.estado == null ? '' : data.ordemEquipamento.estado));
      doc.text(posX + 170, pos_equipamento + 12, '' + (data.ordemEquipamento.tipo_aquisicao == null ? '' : data.ordemEquipamento.tipo_aquisicao));// tipo_aquisição
      doc.text(posX + 10, pos_equipamento + 26, '' + (data.ordemEquipamento.grau_criticidade == null ? '' : data.ordemEquipamento.grau_criticidade));//grau_criticidade
      doc.text(posX + 90, pos_equipamento + 12, '' + (data.ordemEquipamento.estado_conservacao == null ? '' : data.ordemEquipamento.estado_conservacao));//estado_conservação
      doc.text(posX + 90, pos_equipamento + 26, '' + (data.ordemEquipamento.modelo == null ? '' : data.ordemEquipamento.modelo));//modelo
      doc.text(posX + 135, pos_equipamento + 26, '' + (data.ordemEquipamento.latitude == null ? '' : data.ordemEquipamento.latitude));//latitude
      doc.text(posX + 170, pos_equipamento + 26, '' + (data.ordemEquipamento.longitude == null ? '' : data.ordemEquipamento.longitude));//longitude
      /*var splitText = doc.splitTextToSize(obsevacao_equipamento, 190)
      doc.text(splitText, posX+10,pos_equipamento+64);
      if(obsevacao_equipamento.length >= 435) {
          pos_cliente = pos_equipamento+80;
      } else {
          pos_cliente = pos_equipamento+70;
      }*/

      let predio_nome = ((data.ordemCliente.cil != null) ? '[' + data.ordemCliente.cil + '] ' : '[0000-000-000] ') + ((data.ordemCliente.is_predio) ? 'Prédio ' + data.ordemCliente.predio_nome + ', ' + (data.ordemCliente.predio_andar ? data.ordemCliente.predio_andar + ' º Andar - ' : '') + 'Porta ' : 'Residência ') + data.ordemCliente.moradia_numero;

      pos_cliente = pos_equipamento + 30;
      doc.setFontSize(8);
      doc.setFontType('bold');
      doc.setFillColor(208, 211, 212);
      doc.rect(10, pos_cliente, pageWidth - 20, 5);
      doc.text(posX + 10, pos_cliente + 3.5, 'CLIENTE');
      doc.setFontSize(7);
      doc.setFontType('bold');
      doc.text(posX + 10, (pos_cliente + 9), 'Nº CLIENTE');
      doc.text(posX + 30, (pos_cliente + 9), 'CONTA');
      doc.text(posX + 50, (pos_cliente + 9), 'CONTRATO');
      doc.text(posX + 70, ((pos_cliente + 9)), 'NOME');
      doc.text(posX + 135, ((pos_cliente + 9)), 'MUNICIPIO');
      doc.text(posX + 10, (pos_cliente + 16), 'DIST. URBANO/LOCALIDADE');
      doc.text(posX + 70, (pos_cliente + 16), 'BAIRRO/ZONA');
      doc.text(posX + 135, ((pos_cliente + 16)), 'MORADA');
      doc.text(posX + 50, ((pos_cliente + 23)), 'CIL');
      doc.text(posX + 10, (pos_cliente + 23), 'CONTADOR');
      doc.text(posX + 70, (pos_cliente + 23), 'LATITUDE');
      doc.text(posX + 135, (pos_cliente + 23), 'LONGITUDE');
      doc.setFontType('normal');
      doc.text(posX + 10, (pos_cliente + 12), '' + (data.ordemCliente.cliente_numero == null ? '' : data.ordemCliente.cliente_numero));
      doc.text(posX + 30, (pos_cliente + 12), '' + (data.ordemCliente.numero_conta == null ? '' : data.ordemCliente.numero_conta));
      doc.text(posX + 50, (pos_cliente + 12), '' + (data.ordemCliente.contrato_numero == null ? '' : data.ordemCliente.contrato_numero));
      doc.text(posX + 70, (pos_cliente + 12), '' + (data.ordemCliente.cliente_nome == null ? '' : data.ordemCliente.cliente_nome));
      doc.text(posX + 135, (pos_cliente + 12), '' + (data.ordemCliente.municipio == null ? '' : data.ordemCliente.municipio));
      doc.text(posX + 10, (pos_cliente + 19), '' + (data.ordemCliente.distrito == null ? '' : data.ordemCliente.distrito));
      doc.text(posX + 70, (pos_cliente + 19), '' + (data.ordemCliente.bairro == null ? '' : data.ordemCliente.bairro));
      doc.text(posX + 135, (pos_cliente + 19), '' + (predio_nome == null ? '' : predio_nome));
      doc.text(posX + 10, (pos_cliente + 26), '' + (data.ordemCliente.contador == null ? '' : data.ordemCliente.contador));//contador
      doc.text(posX + 50, (pos_cliente + 26), '' + (data.ordemCliente.cil == null ? '' : data.ordemCliente.cil));//CIL
      doc.text(posX + 70, (pos_cliente + 26), '' + (data.ordemCliente.latitude == null ? '' : data.ordemCliente.latitude));//Latitude
      doc.text(posX + 135, (pos_cliente + 26), '' + (data.ordemCliente.longitude == null ? '' : data.ordemCliente.longitude));//Longitude
      //doc.addPage();
      var pos_cliente_aux = pos_cliente + 26;
      pos_cliente = pos_cliente_aux;
      /*Dados dos Materiais a utilizar.*/
      doc.setFontSize(8);
      doc.setFontType('bold');
      doc.rect(10, pos_cliente + 5, pageWidth - 20, 5);
      doc.text(posX + 10, pos_cliente + 8.5, 'MATERIAIS/FERRAMENTAS');
      doc.setFontSize(7);
      doc.text(posX + 10, (pos_cliente + 14), 'CÓDIGO');
      doc.text(posX + 50, pos_cliente + 14, 'DESIGNAÇÃO');
      doc.text(posX + 135, pos_cliente + 14, 'QTD (EST)');
      doc.text(posX + 170, pos_cliente + 14, 'QTD (UTL)');
      doc.line(10, pos_cliente + 15, pageWidth - 10, pos_cliente + 15);
      var pos_materiais = pos_cliente + 18;


      for (var i = 0; i < data.ordemMaterial.length; i++) {
        doc.setFontSize(6);
        doc.setFontType('normal');
        doc.text(posX + 10, pos_materiais, '' + (data.ordemMaterial[i].codigo_material == null ? '' : data.ordemMaterial[i].codigo_material));
        doc.text(posX + 50, pos_materiais, '' + data.ordemMaterial[i].nome_material == null ? '' : data.ordemMaterial[i].nome_material);
        doc.text(posX + 138, pos_materiais, '' + (data.ordemMaterial[i].quantidade_prevista == null ? '' : data.ordemMaterial[i].quantidade_prevista));
        doc.text(posX + 185, pos_materiais, '' + (data.ordemMaterial[i].quantidade_utilizada == null ? '' : data.ordemMaterial[i].quantidade_utilizada));
        pos_materiais += 3;
      }
      doc.setFontType('bold');
      doc.text('OBSERVAÇÃO', posX + 10, pos_materiais + 1);
      doc.setFontType('normal');
      doc.line(11, pos_materiais + 2, pageWidth - 10, pos_materiais + 2);
      doc.setFontSize(7);
      var pos_materiais = pos_materiais + 33;
      /*Dados dos Recursos a utilizar.*/
      doc.setFontSize(8);
      doc.setFontType('bold');
      doc.setFillColor(208, 211, 212);
      var pos_rec = pos_materiais - 20;
      doc.rect(10, pos_rec, pageWidth - 20, 5);
      doc.text(posX + 10, (pos_rec) + 3.5, 'RECURSOS HUMANOS');


      doc.setFontType('bold');
      doc.setFontSize(7);
      doc.text(posX + 10, pos_rec + 9, 'FUNÇÃO');
      doc.text(posX + 50, pos_rec + 9, 'COLABORADOR');
      doc.text(posX + 115, pos_rec + 9, 'D');
      doc.text(posX + 120, pos_rec + 9, 'H');
      doc.text(posX + 125, pos_rec + 9, 'M (EST)');
      doc.text(posX + 170, pos_rec + 9, 'D');
      doc.text(posX + 175, pos_rec + 9, 'H');
      doc.text(posX + 180, pos_rec + 9, 'M (UTL)');
      doc.line(10, pos_rec + 10, pageWidth - 10, pos_rec + 10);
      for (var i = 0; i < data.ordemRecursosHumanos.length; i++) {
        doc.setFontType('normal');
        doc.setFontSize(6);
        var pos_item = pos_rec + 13;
        doc.text(posX + 10, pos_item, '' + (data.ordemRecursosHumanos[i].nome_funcao == null ? '' : data.ordemRecursosHumanos[i].nome_funcao));
        doc.text(posX + 50, pos_item, '' + (data.ordemRecursosHumanos[i].colaborador_nome == null ? '' : data.ordemRecursosHumanos[i].colaborador_nome));
        doc.text(posX + 115, pos_item, '' + (data.ordemRecursosHumanos[i].dif_dias_prevista == null ? '' : data.ordemRecursosHumanos[i].dif_dias_prevista));
        doc.text(posX + 120, pos_item, '' + (data.ordemRecursosHumanos[i].dif_horas_prevista == null ? '' : data.ordemRecursosHumanos[i].dif_horas_prevista));
        doc.text(posX + 125, pos_item, '' + (data.ordemRecursosHumanos[i].dif_minutos_prevista == null ? '' : data.ordemRecursosHumanos[i].dif_minutos_prevista));
        doc.text(posX + 170, pos_item, '' + (data.ordemRecursosHumanos[i].dif_dias_definida == null ? '' : data.ordemRecursosHumanos[i].dif_dias_definida));
        doc.text(posX + 175, pos_item, '' + (data.ordemRecursosHumanos[i].dif_horas_definida == null ? '' : data.ordemRecursosHumanos[i].dif_horas_definida));
        doc.text(posX + 180, pos_item, '' + (data.ordemRecursosHumanos[i].dif_minutos_definida == null ? '' : data.ordemRecursosHumanos[i].dif_minutos_definida));
        pos_rec += 3;
      }
      doc.setFontType('bold');
      doc.text('OBSERVAÇÃO', posX + 10, pos_rec + 13);
      doc.setFontType('normal');
      // Obsevações...
      doc.line(11, pos_rec + 14, pageWidth - 10, pos_rec + 14);
      var pos_obs = pos_rec + 25;
      doc.setFontSize(8);
      doc.setFontType('bold');
      doc.setFillColor(208, 211, 212);
      doc.rect(10, pos_obs, pageWidth - 20, 5);
      doc.text(posX + 10, (pos_obs) + 3.5, 'DESCRIÇÃO DA INTERVENÇÃO');
      doc.setFontType('normal');
      doc.setFontSize(7);
      var obs = doc.splitTextToSize(!data.agendamentoComentarioIntervencao ? '' : data.agendamentoComentarioIntervencao.descricao, 187)
      doc.text((obs == '' ? '' : obs), posX + 11, pos_obs + 8);
      /*if(observacao.length >=435) {
          doc.rect(10, pos_obs+5, pageWidth-20, 15);
     } else {
          doc.rect(10, pos_obs+5, pageWidth-20, 13);
     }*/

      assinaturas(pageHeight - 25);

    } else if (data.ordemEquipamento && !data.ordemCliente) {
      doc.setFontSize(8);
      doc.setFontType('bold');
      doc.setFillColor(208, 211, 212);
      doc.rect(10, pos_equipamento, pageWidth - 20, 5);
      doc.text(posX + 10, pos_equipamento + 3.5, 'EQUIPAMENTO/FERRAMENTAS');
      doc.setFontSize(7);
      doc.setFontType('bold');
      doc.text(posX + 10, pos_equipamento + 9, 'Nº SÉRIE');
      doc.text(posX + 30, pos_equipamento + 9, 'CÓDIGO DE REGISTO');
      doc.text(posX + 60, pos_equipamento + 9, 'ABREVIATURA');
      doc.text(posX + 10, pos_equipamento + 16, 'DESIGNAÇÃO');
      doc.text(posX + 140, pos_equipamento + 9, 'ESTADO');
      doc.text(posX + 170, pos_equipamento + 9, 'TIPO DE AQUISIÇÃO');
      doc.text(posX + 10, pos_equipamento + 23, 'GRAU DE CRITICIDADE');
      doc.text(posX + 90, pos_equipamento + 9, 'ESTADO DE CONSERVAÇÃO');
      doc.text(posX + 90, pos_equipamento + 23, 'MODELO');
      doc.text(posX + 135, pos_equipamento + 23, 'LATITUDE');
      doc.text(posX + 170, pos_equipamento + 23, 'LONGITUDE');
      /*Dados do Equipamento*/
      doc.setFontSize(7);
      doc.setFontType('normal');
      doc.text(posX + 10, pos_equipamento + 12, '' + (data.ordemEquipamento.numero_serie == null ? '' : data.ordemEquipamento.numero_serie));
      doc.text(posX + 30, pos_equipamento + 12, '' + (data.ordemEquipamento.codigo_registo == null ? '' : data.ordemEquipamento.codigo_registo));
      doc.text(posX + 60, pos_equipamento + 12, '' + (data.ordemEquipamento.abreviatura == null ? '' : data.ordemEquipamento.abreviatura));
      var designacao_equip = doc.splitTextToSize(data.ordemEquipamento.designacao_equipamento, 190)
      doc.text(designacao_equip == null ? '' : designacao_equip, posX + 10, pos_equipamento + 19);
      doc.text(posX + 140, pos_equipamento + 12, '' + (data.ordemEquipamento.estado == null ? '' : data.ordemEquipamento.estado));
      doc.text(posX + 170, pos_equipamento + 12, '' + (data.ordemEquipamento.tipo_aquisicao == null ? '' : data.ordemEquipamento.tipo_aquisicao));// tipo_aquisição
      doc.text(posX + 10, pos_equipamento + 26, '' + (data.ordemEquipamento.grau_criticidade == null ? '' : data.ordemEquipamento.grau_criticidade));//grau_criticidade
      doc.text(posX + 90, pos_equipamento + 12, '' + (data.ordemEquipamento.estado_conservacao == null ? '' : data.ordemEquipamento.estado_conservacao));//estado_conservação
      doc.text(posX + 90, pos_equipamento + 26, '' + (data.ordemEquipamento.modelo == null ? '' : data.ordemEquipamento.modelo));//modelo
      doc.text(posX + 135, pos_equipamento + 26, '' + (data.ordemEquipamento.latitude == null ? '' : data.ordemEquipamento.latitude));//latitude
      doc.text(posX + 170, pos_equipamento + 26, '' + (data.ordemEquipamento.longitude == null ? '' : data.ordemEquipamento.longitude));//longitude
      /*var splitText = doc.splitTextToSize(obsevacao_equipamento, 190)
      doc.text(splitText, posX+10,pos_equipamento+64);
      if(obsevacao_equipamento.length >= 435) {
          pos_cliente = pos_equipamento+80;
      } else {
          pos_cliente = pos_equipamento+70;
      }*/
      pos_cliente = pos_equipamento + 24;
      doc.setFontSize(8);
      doc.setFontType('bold');
      doc.setTextColor(0)
      doc.setFillColor(208, 211, 212);
      doc.rect(10, pos_cliente + 6, pageWidth - 20, 5);
      doc.text(posX + 10, pos_cliente + 10, 'MATERIAIS/FERRAMENTAS A UTILIZAR');
      doc.setFontType('bold');
      doc.setFontSize(8);
      doc.text(posX + 10, (pos_cliente + 16), 'CÓDIGO');
      doc.text(posX + 50, pos_cliente + 16, 'DESIGNAÇÃO');
      doc.text(posX + 135, pos_cliente + 16, 'QTD (EST)');
      doc.text(posX + 170, pos_cliente + 16, 'QTD (UTL)');
      doc.line(10, pos_cliente + 17, pageWidth - 10, pos_cliente + 17);
      var pos_materiais = pos_cliente + 20;


      for (var i = 0; i < data.ordemMaterial.length; i++) {
        doc.setFontType('normal');
        doc.setFontSize(6);
        doc.text(posX + 10, pos_materiais, '' + (data.ordemMaterial[i].codigo_material == null ? '' : data.ordemMaterial[i].codigo_material));
        doc.text(posX + 50, pos_materiais, '' + data.ordemMaterial[i].nome_material == null ? '' : data.ordemMaterial[i].nome_material);
        doc.text(posX + 143, pos_materiais, '' + (data.ordemMaterial[i].quantidade_prevista == null ? '' : data.ordemMaterial[i].quantidade_prevista));
        doc.text(posX + 180, pos_materiais, '' + (data.ordemMaterial[i].quantidade_utilizada == null ? '' : data.ordemMaterial[i].quantidade_utilizada));
        pos_materiais += 3;
      }
      doc.setFontType('bold');
      doc.text('DESCRIÇÃO', posX + 10, pos_materiais + 1);
      doc.setFontType('normal');
      doc.line(11, pos_materiais + 2, pageWidth - 10, pos_materiais + 2);
      var pos_materiais = pos_materiais + 34;
      /*Dados dos Recursos a utilizar.*/
      doc.setFontSize(8);
      doc.setFontType('bold');
      doc.setFillColor(208, 211, 212);
      var pos_rec = pos_materiais - 20;
      doc.rect(10, pos_rec, pageWidth - 20, 5);
      doc.text(posX + 10, (pos_rec) + 3.5, 'RECURSOS HUMANOS');


      doc.setFontType('bold');
      doc.setFontSize(8);
      doc.text(posX + 10, pos_rec + 9, 'FUNÇÃO');
      doc.text(posX + 50, pos_rec + 9, 'COLABORADOR');
      doc.text(posX + 115, pos_rec + 9, 'D');
      doc.text(posX + 120, pos_rec + 9, 'H');
      doc.text(posX + 125, pos_rec + 9, 'M (EST)');
      doc.text(posX + 170, pos_rec + 9, 'D');
      doc.text(posX + 175, pos_rec + 9, 'H');
      doc.text(posX + 180, pos_rec + 9, 'M (UTL)');
      doc.line(10, pos_rec + 10, pageWidth - 10, pos_rec + 10);
      for (var i = 0; i < data.ordemRecursosHumanos.length; i++) {
        doc.setFontType('normal');
        doc.setFontSize(6);
        var pos_item = pos_rec + 13;
        doc.text(posX + 10, pos_item, '' + (data.ordemRecursosHumanos[i].nome_funcao == null ? '' : data.ordemRecursosHumanos[i].nome_funcao));
        doc.text(posX + 50, pos_item, '' + (data.ordemRecursosHumanos[i].colaborador_nome == null ? '' : data.ordemRecursosHumanos[i].colaborador_nome));
        doc.text(posX + 115, pos_item, '' + (data.ordemRecursosHumanos[i].dif_dias_prevista == null ? '' : data.ordemRecursosHumanos[i].dif_dias_prevista));
        doc.text(posX + 120, pos_item, '' + (data.ordemRecursosHumanos[i].dif_horas_prevista == null ? '' : data.ordemRecursosHumanos[i].dif_horas_prevista));
        doc.text(posX + 125, pos_item, '' + (data.ordemRecursosHumanos[i].dif_minutos_prevista == null ? '' : data.ordemRecursosHumanos[i].dif_minutos_prevista));
        doc.text(posX + 170, pos_item, '' + (data.ordemRecursosHumanos[i].dif_dias_definida == null ? '' : data.ordemRecursosHumanos[i].dif_dias_definida));
        doc.text(posX + 175, pos_item, '' + (data.ordemRecursosHumanos[i].dif_horas_definida == null ? '' : data.ordemRecursosHumanos[i].dif_horas_definida));
        doc.text(posX + 180, pos_item, '' + (data.ordemRecursosHumanos[i].dif_minutos_definida == null ? '' : data.ordemRecursosHumanos[i].dif_minutos_definida));
        pos_rec += 3;
      }
      doc.setFontType('bold');
      doc.text('DESIGNAÇÃO', posX + 10, pos_rec + 14);
      doc.setFontType('normal');
      doc.line(11, pos_rec + 15, pageWidth - 10, pos_rec + 15);
      var pos_obs = pos_rec + 28;
      doc.setFontSize(8);
      doc.setFontType('bold');
      doc.setTextColor(0)
      doc.setFillColor(208, 211, 212);
      doc.rect(10, pos_obs, pageWidth - 20, 5);
      doc.text(posX + 10, (pos_obs) + 3.5, 'DESCRIÇÃO DA INTERVENÇÃO');
      doc.setFontType('normal');
      doc.setFontSize(7);
      var obs = doc.splitTextToSize(!data.agendamentoComentarioIntervencao ? '' : data.agendamentoComentarioIntervencao.descricao, 187)
      doc.text((obs == '' ? '' : obs), posX + 11, pos_obs + 8);

      assinaturas(pageHeight - 25);


    } else if (!data.ordemEquipamento && data.ordemCliente) {

      let predio_nome = ((data.ordemCliente.cil != null) ? '[' + data.ordemCliente.cil + '] ' : '[0000-000-000] ') + ((data.ordemCliente.is_predio) ? 'Prédio ' + data.ordemCliente.predio_nome + ', ' + (data.ordemCliente.predio_andar ? data.ordemCliente.predio_andar + ' º Andar - ' : '') + 'Porta ' : 'Residência ') + data.ordemCliente.moradia_numero;

      doc.setFontSize(8);
      doc.setFontType('bold');
      doc.setFillColor(208, 211, 212);
      doc.rect(10, pos_cliente, pageWidth - 20, 5);
      doc.text(posX + 10, pos_cliente + 3.5, 'CLIENTE');
      doc.setFontSize(7);
      doc.setFontType('bold');
      doc.text(posX + 10, (pos_cliente + 9), 'Nº CLIENTE');
      doc.text(posX + 30, (pos_cliente + 9), 'CONTA');
      doc.text(posX + 50, (pos_cliente + 9), 'CONTRATO');
      doc.text(posX + 70, ((pos_cliente + 9)), 'NOME');
      doc.text(posX + 135, ((pos_cliente + 9)), 'MUNICIPIO');
      doc.text(posX + 10, (pos_cliente + 16), 'DIST. URBANO/LOCALIDADE');
      doc.text(posX + 70, (pos_cliente + 16), 'BAIRRO/ZONA');
      doc.text(posX + 135, ((pos_cliente + 16)), 'MORADA');
      doc.text(posX + 50, ((pos_cliente + 23)), 'CIL');
      doc.text(posX + 10, (pos_cliente + 23), 'CONTADOR');
      doc.text(posX + 70, (pos_cliente + 23), 'LATITUDE');
      doc.text(posX + 135, (pos_cliente + 23), 'LONGITUDE');
      doc.setFontType('normal');
      doc.text(posX + 10, (pos_cliente + 12), '' + (data.ordemCliente.cliente_numero == null ? '' : data.ordemCliente.cliente_numero));
      doc.text(posX + 30, (pos_cliente + 12), '' + (data.ordemCliente.numero_conta == null ? '' : data.ordemCliente.numero_conta));
      doc.text(posX + 50, (pos_cliente + 12), '' + (data.ordemCliente.contrato_numero == null ? '' : data.ordemCliente.contrato_numero));
      doc.text(posX + 70, (pos_cliente + 12), '' + (data.ordemCliente.cliente_nome == null ? '' : data.ordemCliente.cliente_nome));
      doc.text(posX + 135, (pos_cliente + 12), '' + (data.ordemCliente.municipio == null ? '' : data.ordemCliente.municipio));
      doc.text(posX + 10, (pos_cliente + 19), '' + (data.ordemCliente.distrito == null ? '' : data.ordemCliente.distrito));
      doc.text(posX + 70, (pos_cliente + 19), '' + (data.ordemCliente.bairro == null ? '' : data.ordemCliente.bairro));
      doc.text(posX + 135, (pos_cliente + 19), '' + (predio_nome == null ? '' : predio_nome));
      doc.text(posX + 10, (pos_cliente + 26), '' + (data.ordemCliente.contador == null ? '' : data.ordemCliente.contador));//contador
      doc.text(posX + 50, (pos_cliente + 26), '' + (data.ordemCliente.cil == null ? '' : data.ordemCliente.cil));//CIL
      doc.text(posX + 70, (pos_cliente + 26), '' + (data.ordemCliente.latitude == null ? '' : data.ordemCliente.latitude));//Latitude
      doc.text(posX + 135, (pos_cliente + 26), '' + (data.ordemCliente.longitude == null ? '' : data.ordemCliente.longitude));//Longitude
      var pos_cliente_aux = pos_cliente + 24;
      pos_cliente = pos_cliente_aux;
      /*Dados dos Materiais a utilizar.*/
      doc.setFontSize(8);
      doc.setFontType('bold');
      doc.setTextColor(0)
      doc.setFillColor(208, 211, 212);
      doc.rect(10, pos_cliente + 5, pageWidth - 20, 5);
      doc.text(posX + 10, pos_cliente + 8.5, 'MATERIAIS/FERRAMENTAS A UTILIZAR');
      doc.setFontType('bold');
      doc.setFontSize(8);
      doc.text(posX + 10, (pos_cliente + 15), 'CÓDIGO');
      doc.text(posX + 50, pos_cliente + 15, 'DESIGNAÇÃO');
      doc.text(posX + 135, pos_cliente + 15, 'QTD (EST)');
      doc.text(posX + 170, pos_cliente + 15, 'QTD (UTL)');
      doc.line(10, pos_cliente + 16, pageWidth - 10, pos_cliente + 16);
      var pos_materiais = pos_cliente + 20;


      for (var i = 0; i < data.ordemMaterial.length; i++) {
        doc.setFontType('normal');
        doc.setFontSize(6);
        doc.text(posX + 10, pos_materiais, '' + (data.ordemMaterial[i].codigo_material == null ? '' : data.ordemMaterial[i].codigo_material));
        doc.text(posX + 50, pos_materiais, '' + data.ordemMaterial[i].nome_material == null ? '' : data.ordemMaterial[i].nome_material);
        doc.text(posX + 145, pos_materiais, '' + (data.ordemMaterial[i].quantidade_prevista == null ? '' : data.ordemMaterial[i].quantidade_prevista));
        doc.text(posX + 180, pos_materiais, '' + (data.ordemMaterial[i].quantidade_utilizada == null ? '' : data.ordemMaterial[i].quantidade_utilizada));
        pos_materiais += 3;
      }
      doc.setFontType('bold');
      doc.text('DESCRIÇÃO', posX + 10, pos_materiais + 1);
      doc.setFontType('normal');
      doc.line(11, pos_materiais + 2, pageWidth - 10, pos_materiais + 2);

      var pos_materiais = pos_materiais + 33;
      /*doc.addPage();
      pos_cliente = 10;*/
      /*Dados dos Recursos a utilizar.*/
      doc.setFontSize(8);
      doc.setFontType('bold');
      doc.setTextColor(0)
      doc.setFillColor(208, 211, 212);
      var pos_rec = pos_materiais - 18;
      doc.rect(10, pos_rec, pageWidth - 20, 5);
      doc.text(posX + 10, (pos_rec) + 3.5, 'RECURSOS HUMANOS');

      doc.setFontType('bold');
      doc.setFontSize(8);
      doc.text(posX + 10, pos_rec + 9, 'FUNÇÃO');
      doc.text(posX + 50, pos_rec + 9, 'COLABORADOR');
      doc.text(posX + 115, pos_rec + 9, 'D');
      doc.text(posX + 120, pos_rec + 9, 'H');
      doc.text(posX + 125, pos_rec + 9, 'M (EST)');
      doc.text(posX + 170, pos_rec + 9, 'D');
      doc.text(posX + 175, pos_rec + 9, 'H');
      doc.text(posX + 180, pos_rec + 9, 'M (UTL)');
      doc.line(10, pos_rec + 11, pageWidth - 10, pos_rec + 11);
      for (var i = 0; i < data.ordemRecursosHumanos.length; i++) {
        doc.setFontSize(6);
        doc.setFontType('normal');
        doc.text(posX + 10, pos_rec + 15, '' + (data.ordemRecursosHumanos[i].nome_funcao == null ? '' : data.ordemRecursosHumanos[i].nome_funcao));
        doc.text(posX + 50, pos_rec + 15, '' + (data.ordemRecursosHumanos[i].colaborador_nome == null ? '' : data.ordemRecursosHumanos[i].colaborador_nome));
        doc.text(posX + 115, pos_rec + 15, '' + (data.ordemRecursosHumanos[i].dif_dias_prevista == null ? '' : data.ordemRecursosHumanos[i].dif_dias_prevista));
        doc.text(posX + 120, pos_rec + 15, '' + (data.ordemRecursosHumanos[i].dif_horas_prevista == null ? '' : data.ordemRecursosHumanos[i].dif_horas_prevista));
        doc.text(posX + 125, pos_rec + 15, '' + (data.ordemRecursosHumanos[i].dif_minutos_prevista == null ? '' : data.ordemRecursosHumanos[i].dif_minutos_prevista));
        doc.text(posX + 170, pos_rec + 15, '' + (data.ordemRecursosHumanos[i].dif_dias_definida == null ? '' : data.ordemRecursosHumanos[i].dif_dias_definida));
        doc.text(posX + 175, pos_rec + 15, '' + (data.ordemRecursosHumanos[i].dif_horas_definida == null ? '' : data.ordemRecursosHumanos[i].dif_horas_definida));
        doc.text(posX + 180, pos_rec + 15, '' + (data.ordemRecursosHumanos[i].dif_minutos_definida == null ? '' : data.ordemRecursosHumanos[i].dif_minutos_definida));
        pos_rec += 3;
      }
      doc.setFontType('bold');
      doc.text('DESCRIÇÃO', posX + 10, pos_rec + 16);
      doc.setFontType('normal');
      doc.line(11, pos_rec + 17, pageWidth - 10, pos_rec + 17);
      var pos_obs = pos_rec + 30;
      doc.setFontType('bold');
      doc.setFontSize(8);
      doc.setFillColor(208, 211, 212);
      doc.rect(10, pos_obs, pageWidth - 20, 5);
      doc.text(posX + 10, (pos_obs) + 3.5, 'DESCRIÇÃO DA INTERVENÇÃO');
      doc.setFontType('normal');
      doc.setFontSize(7);

      var obs = doc.splitTextToSize(!data.agendamentoComentarioIntervencao ? '' : data.agendamentoComentarioIntervencao.descricao, 187)
      doc.text((obs == '' ? '' : obs), posX + 11, pos_obs + 8);

      assinaturas(pageHeight - 25);

    }else if (data.ordemByQuarteirao) {
      let predio_nome = ((data.ordemByQuarteirao.cil != null) ? '[' + data.ordemByQuarteirao.cil + '] ' : '[0000-000-000] ') + ((data.ordemByQuarteirao.is_predio) ? 'Prédio ' + data.ordemByQuarteirao.predio_nome + ', ' + (data.ordemByQuarteirao.predio_andar ? data.ordemByQuarteirao.predio_andar + ' º Andar - ' : '') + 'Porta ' : 'Residência ') + data.ordemByQuarteirao.moradia_numero;

      //pos_cliente = pos_cliente+30;
      doc.setFontSize(8);
      doc.setFontType('bold');
      doc.setFillColor(208, 211, 212);
      doc.rect(10, pos_cliente, pageWidth-20, 5);
      doc.text(posX+10,pos_cliente+3.5,'LOCAL DE INSTALAÇÃO');
      doc.setFontSize(7);
      doc.setFontType('bold');
      doc.text(posX+10,(pos_cliente+9),'DIST. URBANO/LOCALIDADE');
      doc.text(posX+50,(pos_cliente+9),'CONTRATO');
      doc.text(posX+70,((pos_cliente+9)),'BAIRRO/ZONA');
      doc.text(posX+135,((pos_cliente+9)),'MUNICIPIO');
      doc.text(posX+10,(pos_cliente+16),'CONTADOR');
      doc.text(posX+70,(pos_cliente+16),'RUA');
      doc.text(posX+50,(pos_cliente+16),'QUARTEIRÃO');
      doc.text(posX+70,((pos_cliente+23)),'CIL');
      doc.text(posX+135,((pos_cliente+16)),'MORADA');
      doc.text(posX+135,(pos_cliente+30),'LATITUDE');
      doc.text(posX+135,(pos_cliente+23),'LONGITUDE');
      doc.setFontType('normal');
      doc.text(posX+10,(pos_cliente+12), '' + (data.ordemByQuarteirao.distrito == null ? '' : data.ordemByQuarteirao.distrito));
      doc.text(posX+50,(pos_cliente+12),'' + (data.ordemByQuarteirao.contrato_id == null ? '' : data.ordemByQuarteirao.contrato_id));
      doc.text(posX+70,(pos_cliente+12),'' + (data.ordemByQuarteirao.bairro == null ? '' : data.ordemByQuarteirao.bairro));
      doc.text(posX+135,(pos_cliente+12),'' + (data.ordemByQuarteirao.municipio == null ? '' : data.ordemByQuarteirao.municipio));
      doc.text(posX+10,(pos_cliente+19),'' + (data.ordemByQuarteirao.contador == null ? '' : data.ordemByQuarteirao.contador));
      doc.text(posX+70,(pos_cliente+19),'' + (data.ordemByQuarteirao.rua == null ? '' : data.ordemByQuarteirao.rua));
      doc.text(posX+50,(pos_cliente+19),'' + (data.ordemByQuarteirao.quarteirao == null ? '' : data.ordemByQuarteirao.quarteirao));
      doc.text(posX+70,(pos_cliente+26),'' + (data.ordemByQuarteirao.cil == null ? '' : data.ordemByQuarteirao.cil));
      doc.text(posX+135,(pos_cliente+19),'' + (data.ordemByQuarteirao.cil == null ? '' : predio_nome));
      doc.text(posX+135,(pos_cliente+33),'' + (data.ordemByQuarteirao.latitude == null ? '' : data.ordemByQuarteirao.latitude));//Latitude
      doc.text(posX+135,(pos_cliente+26),'' + (data.ordemByQuarteirao.longitude == null ? '' : data.ordemByQuarteirao.longitude));//Longitude
      //doc.addPage();
      var pos_cliente_aux = pos_cliente + 33;
      pos_cliente = pos_cliente_aux;
      /*Dados dos Materiais a utilizar.*/

      doc.setFontSize(8);
      doc.setFontType('bold');
      doc.setTextColor(0)
      doc.setFillColor(208, 211, 212);
      doc.rect(10, pos_cliente + 5, pageWidth - 20, 5);
      doc.text(posX + 10, pos_cliente + 8.5, 'MATERIAIS/FERRAMENTAS A UTILIZAR');
      doc.setFontType('bold');
      doc.setFontSize(8);
      doc.text(posX + 10, (pos_cliente + 15), 'CÓDIGO');
      doc.text(posX + 50, pos_cliente + 15, 'DESIGNAÇÃO');
      doc.text(posX + 135, pos_cliente + 15, 'QTD (EST)');
      doc.text(posX + 170, pos_cliente + 15, 'QTD (UTL)');
      doc.line(10, pos_cliente + 16, pageWidth - 10, pos_cliente + 16);
      var pos_materiais = pos_cliente + 20;


      for (var i = 0; i < data.ordemMaterial.length; i++) {
        doc.setFontType('normal');
        doc.setFontSize(6);
        doc.text(posX + 10, pos_materiais, '' + (data.ordemMaterial[i].codigo_material == null ? '' : data.ordemMaterial[i].codigo_material));
        doc.text(posX + 50, pos_materiais, '' + data.ordemMaterial[i].nome_material == null ? '' : data.ordemMaterial[i].nome_material);
        doc.text(posX + 145, pos_materiais, '' + (data.ordemMaterial[i].quantidade_prevista == null ? '' : data.ordemMaterial[i].quantidade_prevista));
        doc.text(posX + 180, pos_materiais, '' + (data.ordemMaterial[i].quantidade_utilizada == null ? '' : data.ordemMaterial[i].quantidade_utilizada));
        pos_materiais += 3;
      }
      doc.setFontType('bold');
      doc.text('DESCRIÇÃO', posX + 10, pos_materiais + 1);
      doc.setFontType('normal');
      doc.line(11, pos_materiais + 2, pageWidth - 10, pos_materiais + 2);

      var pos_materiais = pos_materiais + 33;
      /*doc.addPage();
      pos_cliente = 10;*/
      /*Dados dos Recursos a utilizar.*/
      doc.setFontSize(8);
      doc.setFontType('bold');
      doc.setTextColor(0)
      doc.setFillColor(208, 211, 212);
      var pos_rec = pos_materiais - 18;
      doc.rect(10, pos_rec, pageWidth - 20, 5);
      doc.text(posX + 10, (pos_rec) + 3.5, 'RECURSOS HUMANOS');

      doc.setFontType('bold');
      doc.setFontSize(8);
      doc.text(posX + 10, pos_rec + 9, 'FUNÇÃO');
      doc.text(posX + 50, pos_rec + 9, 'COLABORADOR');
      doc.text(posX + 115, pos_rec + 9, 'D');
      doc.text(posX + 120, pos_rec + 9, 'H');
      doc.text(posX + 125, pos_rec + 9, 'M (EST)');
      doc.text(posX + 170, pos_rec + 9, 'D');
      doc.text(posX + 175, pos_rec + 9, 'H');
      doc.text(posX + 180, pos_rec + 9, 'M (UTL)');
      doc.line(10, pos_rec + 11, pageWidth - 10, pos_rec + 11);
      for (var i = 0; i < data.ordemRecursosHumanos.length; i++) {
        doc.setFontSize(6);
        doc.setFontType('normal');
        doc.text(posX + 10, pos_rec + 15, '' + (data.ordemRecursosHumanos[i].nome_funcao == null ? '' : data.ordemRecursosHumanos[i].nome_funcao));
        doc.text(posX + 50, pos_rec + 15, '' + (data.ordemRecursosHumanos[i].colaborador_nome == null ? '' : data.ordemRecursosHumanos[i].colaborador_nome));
        doc.text(posX + 115, pos_rec + 15, '' + (data.ordemRecursosHumanos[i].dif_dias_prevista == null ? '' : data.ordemRecursosHumanos[i].dif_dias_prevista));
        doc.text(posX + 120, pos_rec + 15, '' + (data.ordemRecursosHumanos[i].dif_horas_prevista == null ? '' : data.ordemRecursosHumanos[i].dif_horas_prevista));
        doc.text(posX + 125, pos_rec + 15, '' + (data.ordemRecursosHumanos[i].dif_minutos_prevista == null ? '' : data.ordemRecursosHumanos[i].dif_minutos_prevista));
        doc.text(posX + 170, pos_rec + 15, '' + (data.ordemRecursosHumanos[i].dif_dias_definida == null ? '' : data.ordemRecursosHumanos[i].dif_dias_definida));
        doc.text(posX + 175, pos_rec + 15, '' + (data.ordemRecursosHumanos[i].dif_horas_definida == null ? '' : data.ordemRecursosHumanos[i].dif_horas_definida));
        doc.text(posX + 180, pos_rec + 15, '' + (data.ordemRecursosHumanos[i].dif_minutos_definida == null ? '' : data.ordemRecursosHumanos[i].dif_minutos_definida));
        pos_rec += 3;
      }
      doc.setFontType('bold');
      doc.text('DESCRIÇÃO', posX + 10, pos_rec + 16);
      doc.setFontType('normal');
      doc.line(11, pos_rec + 17, pageWidth - 10, pos_rec + 17);
      var pos_obs = pos_rec + 30;
      doc.setFontType('bold');
      doc.setFontSize(8);
      doc.setFillColor(208, 211, 212);
      doc.rect(10, pos_obs, pageWidth - 20, 5);
      doc.text(posX + 10, (pos_obs) + 3.5, 'DESCRIÇÃO DA INTERVENÇÃO');
      doc.setFontType('normal');
      doc.setFontSize(7);

      var obs = doc.splitTextToSize(!data.agendamentoComentarioIntervencao ? '' : data.agendamentoComentarioIntervencao.descricao, 187)
      doc.text((obs == '' ? '' : obs), posX + 11, pos_obs + 8);

      assinaturas(pageHeight - 25);
    }



    function assinaturas(posy) {
      doc.setFontSize(9);
      doc.line(20, posy + 2, 80, posy + 2);
      doc.text('Entregou', posX + 45, posy + 5.5);

      doc.text('Executou', posX + 150, posy + 5.5);
      doc.line(130, posy + 2, 190, posy + 2);

    }
    //var pos_materiais = logo_height + 180;
    /*RODAPÉ*/
    doc.setFontType('normal');
    doc.setFontStyle('italic');
    doc.setFontSize(6);
    var pageCount = doc.internal.getNumberOfPages();
    for (var i = 0; i <= pageCount; i++) {
      doc.line(10, pageHeight - 10, pageWidth - 10, pageHeight - 10);
      doc.text('-Processado por programa validado 4/AGT119', 105, doc.internal.pageSize.height - 7, null, null, 'center');
      doc.setPage(i);
      doc.text(10, pageHeight - 15, 'Versão: ' + original);
      doc.text('Página: ' + doc.internal.getCurrentPageInfo().pageNumber + '/' + pageCount, pageWidth - 10, pageHeight - 15, null, null, 'right');
      doc.text(pageWidth - 10, pageHeight - 12, '', 'right');
      doc.text("NIF: " + (data.empresa.taxRegistrationNumber == null ? '' : data.empresa.taxRegistrationNumber) + " - " + (data.empresa.companyName == null ? '' : data.empresa.companyName) + " / " + (data.empresa.addressDetail == null ? '' : data.empresa.addressDetail) + " / " + (data.empresa.telefone == null ? '' : data.empresa.telefone) + " / " + (data.empresa.email == null ? '' : data.empresa.email), 105, doc.internal.pageSize.height - 11, null, null, 'center');
    }

    if (report == 'imprimir') {
      doc.autoPrint();
      window.open(doc.output("bloburl")); //opens the data uri in new window
    }
    else if (report == 'buffer') {
      return new Buffer.from(doc.output('arraybuffer'))
    }
    else {
      doc.save('nome.pdf'); /* download the file immediately on loading */
    }





  }



  public imprimirAgendamentoMassiva(data, original: any = 'Original', report: string = 'imprimir') {
    // You'll need to make your image into a Data URL
    // Use http://dataurl.net/#dataurlmaker
    var imgData = data.empresa.logotipo
    var doc = new jsPDF('p');
    var posX = 1;
    var posY = 5;
    var logo_width = 35;
    var logo_height = 40;
    var pos_body = logo_height + 5;
    var pos_equipamento = 0;
    var pos_cliente = 0;

    var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
    var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

    doc.addImage(imgData, 'JPEG', 8, 14, 28, 24);
    doc.setFontSize(8);
    doc.setFontType('Arial');

    if (data.empresa.addressDetail.length <= 62) {
      doc.text(posX + logo_width + 5, 30, 'NIF: ');
      doc.text(posX + logo_width + 13, 30, '' + (data.empresa.taxRegistrationNumber == null ? '' : data.empresa.taxRegistrationNumber));
      doc.text(posX + logo_width + 5, 35, 'Email: ');
      doc.text(posX + logo_width + 16, 35, '' + (data.empresa.email == null ? '' : data.empresa.email));
      doc.text(posX + logo_width + 50, 30, 'Telefone : ');
      doc.text(posX + logo_width + 65, 30, '' + (data.empresa.telefone == null ? '' : data.empresa.telefone));
      doc.text(posX + logo_width + 50, 35, 'Website: ');
      doc.text(posX + logo_width + 65, 35, '' + (data.empresa.site == null ? '' : data.empresa.site));
    } else {
      doc.text(posX + logo_width + 5, 33, 'NIF: ');
      doc.text(posX + logo_width + 13, 33, '' + (data.empresa.taxRegistrationNumber == null ? '' : data.empresa.taxRegistrationNumber));
      doc.text(posX + logo_width + 5, 38, 'Email: ');
      doc.text(posX + logo_width + 16, 38, '' + (data.empresa.email == null ? '' : data.empresa.email));
      doc.text(posX + logo_width + 60, 33, 'Telefone : ');
      doc.text(posX + logo_width + 80, 33, '' + (data.empresa.telefone == null ? '' : data.empresa.telefone));
      doc.text(posX + logo_width + 60, 38, 'Website: ');
      doc.text(posX + logo_width + 80, 38, '' + (data.empresa.site == null ? '' : data.empresa.site));
    }
    doc.text(posX + logo_width + 5, 20, '' + (data.empresa.companyName == null ? '' : data.empresa.companyName));
    //lines = doc.splitTextToSize(endereco, 62)
    doc.text(posX + logo_width + 5, 25, '' + (data.empresa.addressDetail == null ? '' : data.empresa.addressDetail));
    var pos_rectangulo = posX + logo_width + 110;
    doc.rect(pos_rectangulo, 18, pageWidth / 3 - 17, 20)
    doc.setFontSize(8);
    doc.setFontType('bold');
    doc.text(pos_rectangulo + 5, 23, 'Nº ' + ((data.ordemTrabalho || {}).codigo == null ? '' : (data.ordemTrabalho || {}).codigo) + ((data.ordemTrabalho || {}).codigo_agendamento == null ? '' : '/' + (data.ordemTrabalho || {}).codigo_agendamento));
    doc.setFontType('Normal');
    doc.text(pos_rectangulo + 5, 28, 'Data:' + (data.ordemTrabalho || {}).data_inicio_previsao + ' ' + (data.ordemTrabalho || {}).hora_inicio_previsao);
    doc.text(pos_rectangulo + 5, 34, 'Prioridade:');
    doc.setFontType('bold');
    doc.text(pos_rectangulo + 18, 34, '' + ((data.ordemTrabalho || {}).prioridade_descricao == null ? '' : (data.ordemTrabalho || {}).prioridade_descricao));

    // CORPO DO RELATÓRIO ........
    doc.setFontSize(8);
    doc.setFillColor(208, 211, 212);
    doc.setFontType('bold');
    doc.rect(10, pos_body, pageWidth - 20, 5);
    logo_height = pos_body - 5;
    doc.text(posX + 10, logo_height + 8.5, 'ORDEM DE SERVIÇO -');

    var corpo_ordem = logo_height + 14;
    console.log(data)
    doc.text(posX + 70, corpo_ordem - 5.5, '' + ((data.ordemTrabalho || {}).classificacao_descricao == null ? '' : (data.ordemTrabalho || {}).classificacao_descricao.toUpperCase()), null, 'center');

    if ((data.ordemTrabalho || {}).agendamento_flag == 'FECHADA') {
      doc.setFontType('normal');
      doc.setFontSize(7);
      doc.text(posX + 110, corpo_ordem + 13, '' + ((data.ordemTrabalho || {}).data_fim_execucao == null ? '' : (data.ordemTrabalho || {}).data_fim_execucao));
      doc.text(posX + 130, corpo_ordem + 13, '' + ((data.ordemTrabalho || {}).hora_fim_execucao == null ? '' : (data.ordemTrabalho || {}).hora_fim_execucao));
      doc.text(posX + 150, corpo_ordem + 13, '' + ((data.ordemTrabalho || {}).dif_dias_definida == null ? '' : (data.ordemTrabalho || {}).dif_dias_definida));
      doc.text(posX + 155, corpo_ordem + 13, '' + ((data.ordemTrabalho || {}).dif_horas_definida == null ? '' : (data.ordemTrabalho || {}).dif_horas_definida));
      doc.text(posX + 160, corpo_ordem + 13, '' + ((data.ordemTrabalho || {}).dif_minutos_definida == null ? '' : (data.ordemTrabalho || {}).dif_minutos_definida));
      doc.setTextColor(0, 0, 255);
      doc.setFontSize(8);
      doc.text(posX + 53, corpo_ordem - 5.5, 'EXECUÇÃO', null, 'center');
      doc.setTextColor(0, 0, 0);
      doc.setFontSize(7);
      doc.text(posX + 10, (corpo_ordem + 13), '' + ((data.ordemTrabalho || {}).data_inicio_previsao == null ? '' : (data.ordemTrabalho || {}).data_inicio_previsao));
      doc.text(posX + 25, (corpo_ordem + 13), '' + ((data.ordemTrabalho || {}).hora_inicio_previsao == null ? '' : (data.ordemTrabalho || {}).hora_inicio_previsao));
      doc.text(posX + 40, (corpo_ordem + 13), '' + ((data.ordemTrabalho || {}).dif_dias_prevista == null ? '' : (data.ordemTrabalho || {}).dif_dias_prevista));
      doc.text(posX + 45, (corpo_ordem + 13), '' + ((data.ordemTrabalho || {}).dif_horas_prevista == null ? '' : (data.ordemTrabalho || {}).dif_horas_prevista));
      doc.text(posX + 165, (corpo_ordem + 13), '' + ((data.ordemTrabalho || {}).dif_minutos_prevista == null ? '' : (data.ordemTrabalho || {}).dif_minutos_prevista));
    } else {

      /*Dados do agendamento*/
      doc.setTextColor(45, 124, 35);
      doc.setFontSize(8);
      doc.text(posX + 10, (corpo_ordem + 13), '' + ((data.ordemTrabalho || {}).data_inicio_previsao == null ? '' : (data.ordemTrabalho || {}).data_inicio_previsao));
      doc.text(posX + 25, (corpo_ordem + 13), '' + ((data.ordemTrabalho || {}).hora_inicio_previsao == null ? '' : (data.ordemTrabalho || {}).hora_inicio_previsao));
      doc.text(posX + 40, (corpo_ordem + 13), '' + ((data.ordemTrabalho || {}).dif_dias_prevista == null ? '' : (data.ordemTrabalho || {}).dif_dias_prevista));
      doc.text(posX + 45, (corpo_ordem + 13), '' + ((data.ordemTrabalho || {}).dif_horas_prevista == null ? '' : (data.ordemTrabalho || {}).dif_horas_prevista));
      doc.text(posX + 50, (corpo_ordem + 13), '' + ((data.ordemTrabalho || {}).dif_minutos_prevista == null ? '' : (data.ordemTrabalho || {}).dif_minutos_prevista));


      doc.text(posX + 165, corpo_ordem - 5.5, '' + ((data.ordemTrabalho || {}).agendamento_estado == null ? '' : (data.ordemTrabalho || {}).agendamento_estado.toUpperCase()), null, 'center');

      doc.setTextColor(0, 0, 0);
      doc.setFontSize(7);
    }


    /*Dados da OS.*/
    doc.setFontSize(7);
    doc.setFontType('bold');
    doc.text(posX + 10, corpo_ordem, 'TÍTULO OS');
    doc.text(posX + 70, corpo_ordem, 'RESPONSÁVEL');
    doc.text(posX + 10, corpo_ordem + 7, 'AGENDAMENTO PREVISTO');
    doc.text(posX + 110, corpo_ordem, 'CLASSIFICAÇÃO');
    doc.text(posX + 150, corpo_ordem, 'TIPO OS');
    doc.text(posX + 110, corpo_ordem + 7, 'EXECUÇÃO');
    doc.setFontType('normal');
    doc.text(posX + 10, corpo_ordem + 10, 'DATA');//data_agendamento
    doc.text(posX + 25, corpo_ordem + 10, 'HORA');//hora_agendamento
    doc.text(posX + 40, corpo_ordem + 10, 'DURAÇÃO (D H M)');//duracao_agendamento
    doc.text(posX + 110, corpo_ordem + 10, 'DATA');//data_execução
    doc.text(posX + 130, corpo_ordem + 10, 'HORA');//hora_execução
    doc.text(posX + 150, corpo_ordem + 10, 'DURAÇÃO (D H M)');//duração_execução
    doc.setFontType('bold');
    //doc.text(posX+10,(corpo_ordem+18),'DESCRIÇÃO DA ORDEM DE TRABALHO');
    doc.setFontType('normal');

    var titulo_ot = (data.ordemTrabalho || {}).titulo == null ? '' : (data.ordemTrabalho || {}).titulo;
    var split_titulo_ot = doc.splitTextToSize(titulo_ot, 65);
    doc.setFontSize(6);
    doc.text(posX + 10, corpo_ordem + 2, split_titulo_ot);


    //doc.text(posX+10,corpo_ordem+3,'' + ((data.ordemTrabalho || {} ).titulo == null ? '' : (data.ordemTrabalho || {} ).titulo));
    doc.text(posX + 70, corpo_ordem + 2, '' + ((data.ordemTrabalho || {}).responsavel_nome == null ? '' : (data.ordemTrabalho || {}).responsavel_nome));
    /*PERIODO DE AGENDAMENTO*/
    doc.text(posX + 110, corpo_ordem + 2, '' + ((data.ordemTrabalho || {}).classificacao_descricao == null ? '' : (data.ordemTrabalho || {}).classificacao_descricao));

    var tipo_ot = (data.ordemTrabalho || {}).tipo_descricao == null ? '' : (data.ordemTrabalho || {}).tipo_descricao;
    var split_tipo_ot = doc.splitTextToSize(tipo_ot, 50);
    doc.text(posX + 150, corpo_ordem + 2, split_tipo_ot);


    if (data.agendamentoComentario && data.agendamentoComentario.descricao ? data.agendamentoComentario.descricao.length >= 448 : 1 >= 448) {
      if (data.ordemEquipamento) {
        pos_equipamento = corpo_ordem + 27;
      } else {
        pos_cliente = corpo_ordem + 27;
      }

    } else {
      if (data.ordemEquipamento) {
        pos_equipamento = corpo_ordem + 19;
      } else {
        pos_cliente = corpo_ordem + 19;
      }
    }
    if (data.ordemCliente) {


      doc.setFontSize(8);
      doc.setFontType('bold');
      doc.setFillColor(208, 211, 212);
      doc.rect(10, pos_cliente, pageWidth - 20, 5);
      doc.text(posX + 10, pos_cliente + 3.5, 'TAREFAS');
      doc.setFontSize(7);
       console.log('position',posX, pos_cliente)
      doc.text(posX + 10, (pos_cliente + 9), 'Nº CLIENTE');
      doc.text(posX + 30, (pos_cliente + 9), 'CONTRATO');
      doc.text(posX + 50, ((pos_cliente + 9)), 'NOME');
      doc.text(posX + 68, (pos_cliente + 9), 'BAIRRO/ZONA');
      doc.text(posX + 90, (pos_cliente + 9), 'QUARTEIRÃO');
      doc.text(posX + 114, (pos_cliente + 9), 'RUA');
      doc.text(posX + 140, ((pos_cliente + 9)), 'MORADA');
      doc.text(posX + 178, (pos_cliente + 9), 'CONTADOR');
      console.log(data.ordemCliente)
      for (let i = 0; i < data.ordemCliente.length; i++) {
        //posX+=20
        doc.setFontSize(6);
        let predio_nome = ((data.ordemCliente[i].cil != null) ?
          '[' + data.ordemCliente[i].cil + '] ' :
          '[0000-000-000] ') + ((data.ordemCliente[i].is_predio)
            ? 'Prédio ' + data.ordemCliente[i].predio_nome + ', ' + (data.ordemCliente[i].predio_andar ?
              data.ordemCliente[i].predio_andar + ' º Andar - ' : '') + 'Porta ' : 'Residência ') + data.ordemCliente[i].moradia_numero;
        doc.setFontType('bold');


        doc.setFontType('normal');
        doc.text(posX + 10, (pos_cliente + 12), '' + (data.ordemCliente[i].cliente_numero == null ? '' : data.ordemCliente[i].cliente_numero));
        doc.text(posX + 30, (pos_cliente + 12), '' + (data.ordemCliente[i].contrato_numero == null ? '' : data.ordemCliente[i].contrato_numero));

        var splitName = doc.splitTextToSize('' + (!data.ordemCliente[i].cliente_nome ? '' : data.ordemCliente[i].cliente_nome), 14)
        doc.text(splitName == '' ? '' : splitName, posX + 50, (pos_cliente + 12));
        //doc.text(posX+50,(pos_cliente+12),'' + (data.ordemCliente[i].cliente_nome == null ? '' : data.ordemCliente[i].cliente_nome));
        doc.text(posX + 68, (pos_cliente + 12), '' + (data.ordemCliente[i].bairro == null ? '' : data.ordemCliente[i].bairro));
        doc.text(posX + 90, (pos_cliente + 12), '' + (data.ordemCliente[i].quarteirao == null ? '' : data.ordemCliente[i].quarteirao));

        doc.text(posX + 114, (pos_cliente + 12), '' + (!data.ordemCliente[i].rua ? '' : data.ordemCliente[i].rua), { maxWidth: 14, align: "justify"});

        doc.text(posX + 140, (pos_cliente + 12), '' + (predio_nome == null ? '' : predio_nome));
        doc.text(posX + 178, (pos_cliente + 12), '' + (data.ordemCliente[i].contador == null ? '' : data.ordemCliente[i].contador));//contador
        var pos_cliente_aux = pos_cliente + 16;
        pos_cliente = pos_cliente_aux;
        if(pos_cliente >= Number(pageHeight - 50)){
           pos_cliente = 20
           doc.addPage()
        }

      }
      pos_cliente = pos_cliente_aux + 5;
      pos_materiais = pos_cliente

      var pos_materiais = pos_materiais + 33;

      if (pos_materiais + 10 >= pageHeight - 25) {
        doc.addPage();
        pos_materiais = 15;
      }


      doc.setFontSize(8);
      doc.setFontType('bold');
      doc.setTextColor(0)
      doc.setFillColor(208, 211, 212);
      var pos_rec = pos_materiais - 18;
      doc.rect(10, pos_rec, pageWidth - 20, 5);
      doc.text(posX + 10, (pos_rec) + 3.5, 'CONSUMIVEIS');

      doc.setFontType('bold');
      doc.setFontSize(8);

      doc.text(posX + 10, pos_rec + 9, 'CÓDIGO');
      doc.text(posX + 50, pos_rec + 9, 'DESIGNAÇÃO');
      doc.text(posX + 115, pos_rec + 9, 'QUANTIDADE EST');
      doc.text(posX + 170, pos_rec + 9, 'QUANTIDADE UTIL');
      doc.line(10, pos_rec + 11, pageWidth - 10, pos_rec + 11);
      for (var i = 0; i < data.ordemMaterial.length; i++) {
        doc.setFontType('normal');
        doc.setFontSize(7);
        var pos_item = pos_rec + 13;
        doc.text(posX + 10, pos_item, '' + (data.ordemMaterial[i].codigo_material == null ? '' : data.ordemMaterial[i].codigo_material));
        doc.text(posX + 50, pos_item, '' + (data.ordemMaterial[i].nome_material == null ? '' : data.ordemMaterial[i].nome_material));
        doc.text(posX + 115, pos_item, '' + (data.ordemMaterial[i].quantidade_prevista == null ? '' : data.ordemMaterial[i].quantidade_prevista));
        doc.text(posX + 170, pos_item, '' + (data.ordemMaterial[i].quantidade_utilizada == null ? '' : data.ordemMaterial[i].quantidade_utilizada));
        pos_rec += 3;

      }
      var pos_obs = pos_rec + 30;
      doc.setFontType('bold');
      doc.setFontSize(8);
      doc.setFillColor(208, 211, 212);
      doc.rect(10, pos_obs, pageWidth - 20, 5);
      doc.text(posX + 10, (pos_obs) + 3.5, 'RECURSOS HUMANOS');
      doc.setFontType('bold');
      doc.setFontSize(8);

      doc.text(posX + 10, pos_obs + 9, 'FUNÇÃO');
      doc.text(posX + 50, pos_obs + 9, 'COLABORADOR');
      doc.text(posX + 115, pos_obs + 9, 'D');
      doc.text(posX + 120, pos_obs + 9, 'H');
      doc.text(posX + 125, pos_obs + 9, 'M (EST)');
      doc.text(posX + 170, pos_obs + 9, 'D');
      doc.text(posX + 175, pos_obs + 9, 'H');
      doc.text(posX + 180, pos_obs + 9, 'M (UTL)');
      doc.line(10, pos_obs + 10, pageWidth - 10, pos_obs + 10);

      for (var i = 0; i < data.ordemRecursosHumanos.length; i++) {
        doc.setFontSize(7);
        doc.setFontType('normal');
        var splitFuncao = doc.splitTextToSize('' + (!data.ordemRecursosHumanos[i].nome_funcao ? '' : data.ordemRecursosHumanos[i].nome_funcao), 30)
        doc.text(splitFuncao == '' ? '' : splitFuncao, posX + 10, pos_obs + 15);
        var splitColaborador = doc.splitTextToSize('' + (!data.ordemRecursosHumanos[i].colaborador_nome ? '' : data.ordemRecursosHumanos[i].colaborador_nome), 30)
        doc.text(splitColaborador == '' ? '' : splitColaborador, posX + 50, pos_obs + 15);
        doc.text(posX + 115, pos_obs + 15, '' + (data.ordemRecursosHumanos[i].dif_dias_prevista == null ? '' : data.ordemRecursosHumanos[i].dif_dias_prevista));
        doc.text(posX + 120, pos_obs + 15, '' + (data.ordemRecursosHumanos[i].dif_horas_prevista == null ? '' : data.ordemRecursosHumanos[i].dif_horas_prevista));
        doc.text(posX + 125, pos_obs + 15, '' + (data.ordemRecursosHumanos[i].dif_minutos_prevista == null ? '' : data.ordemRecursosHumanos[i].dif_minutos_prevista));
        doc.text(posX + 170, pos_obs + 15, '' + (data.ordemRecursosHumanos[i].dif_dias == null ? '' : data.ordemRecursosHumanos[i].dif_dias));
        doc.text(posX + 175, pos_obs + 15, '' + (data.ordemRecursosHumanos[i].dif_horas == null ? '' : data.ordemRecursosHumanos[i].dif_horas));
        doc.text(posX + 180, pos_obs + 15, '' + (data.ordemRecursosHumanos[i].dif_minutos == null ? '' : data.ordemRecursosHumanos[i].dif_minutos));
        pos_obs += 3;
      }




      var pos_desc_interv = pos_obs + 25;
      doc.setFontSize(8);
      doc.setFontType('bold');
      doc.setFillColor(208, 211, 212);
      doc.rect(10, pos_desc_interv, pageWidth - 20, 5);
      doc.text(posX + 10, (pos_desc_interv) + 3.5, 'OBSERVAÇÕES GERAIS');
      doc.setFontType('normal');
      doc.setFontSize(7);
      var obs = doc.splitTextToSize(!data.agendamentoComentarioIntervencao ? '' : data.agendamentoComentarioIntervencao.descricao, 187)
      doc.text((obs == '' ? '' : obs), posX + 11, pos_desc_interv + 8);

      assinaturas(pageHeight - 25);

    }


    function assinaturas(posy) {
      doc.setFontSize(9);
      doc.line(20, posy + 2, 80, posy + 2);
      doc.text('Entregou', posX + 45, posy + 5.5);

      doc.text('Executou', posX + 150, posy + 5.5);
      doc.line(130, posy + 2, 190, posy + 2);

    }
    //var pos_materiais = logo_height + 180;
    /*RODAPÉ*/
    doc.setFontType('normal');
    doc.setFontStyle('italic');
    doc.setFontSize(6);
    var pageCount = doc.internal.getNumberOfPages();
    for (var i = 0; i <= pageCount; i++) {
      doc.line(10, pageHeight - 10, pageWidth - 10, pageHeight - 10);
      doc.text('-Processado por programa validado 4/AGT119', 105, doc.internal.pageSize.height - 7, null, null, 'center');
      doc.setPage(i);
      doc.text(10, pageHeight - 15, 'Versão: ' + original);
      doc.text('Página: ' + doc.internal.getCurrentPageInfo().pageNumber + '/' + pageCount, pageWidth - 10, pageHeight - 15, null, null, 'right');
      doc.text(pageWidth - 10, pageHeight - 12, '', 'right');
      doc.text("NIF: " + (data.empresa.taxRegistrationNumber == null ? '' : data.empresa.taxRegistrationNumber) + " - " + (data.empresa.companyName == null ? '' : data.empresa.companyName) + " / " + (data.empresa.addressDetail == null ? '' : data.empresa.addressDetail) + " / " + (data.empresa.telefone == null ? '' : data.empresa.telefone) + " / " + (data.empresa.email == null ? '' : data.empresa.email), 105, doc.internal.pageSize.height - 11, null, null, 'center');
    }



    if (report == 'imprimir') {
      doc.autoPrint();
      window.open(doc.output("bloburl")); //opens the data uri in new window
    } else if (report == 'buffer') {
      return new Buffer.from(doc.output('arraybuffer'))
    } else {
      doc.save('nome.pdf'); /* download the file immediately on loading */

    }





  }

  public imprimirAgendamentoPreventivo(data, original: any = 'Original', report: string = 'imprimir') {
    console.log(data)
    // You'll need to make your image into a Data URL
    // Use http://dataurl.net/#dataurlmaker
    var imgData = data.empresa.logotipo
    var doc = new jsPDF('p');
    var posX = 1;
    var posY = 5;
    var logo_width = 35;
    var logo_height = 40;
    var pos_body = logo_height + 5;
    var pos_equipamento = 0;
    var pos_cliente = 0;

    var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
    var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

    doc.addImage(imgData, 'JPEG', 8, 14, 28, 24);
    doc.setFontSize(8);
    doc.setFontType('Arial');

    if (data.empresa.addressDetail.length <= 62) {
      doc.text(posX + logo_width + 5, 30, 'NIF: ');
      doc.text(posX + logo_width + 13, 30, '' + (data.empresa.taxRegistrationNumber == null ? '' : data.empresa.taxRegistrationNumber));
      doc.text(posX + logo_width + 5, 35, 'Email: ');
      doc.text(posX + logo_width + 16, 35, '' + (data.empresa.email == null ? '' : data.empresa.email));
      doc.text(posX + logo_width + 50, 30, 'Telefone : ');
      doc.text(posX + logo_width + 65, 30, '' + (data.empresa.telefone == null ? '' : data.empresa.telefone));
      doc.text(posX + logo_width + 50, 35, 'Website: ');
      doc.text(posX + logo_width + 65, 35, '' + (data.empresa.site == null ? '' : data.empresa.site));
    } else {
      doc.text(posX + logo_width + 5, 33, 'NIF: ');
      doc.text(posX + logo_width + 13, 33, '' + (data.empresa.taxRegistrationNumber == null ? '' : data.empresa.taxRegistrationNumber));
      doc.text(posX + logo_width + 5, 38, 'Email: ');
      doc.text(posX + logo_width + 16, 38, '' + (data.empresa.email == null ? '' : data.empresa.email));
      doc.text(posX + logo_width + 60, 33, 'Telefone : ');
      doc.text(posX + logo_width + 80, 33, '' + (data.empresa.telefone == null ? '' : data.empresa.telefone));
      doc.text(posX + logo_width + 60, 38, 'Website: ');
      doc.text(posX + logo_width + 80, 38, '' + (data.empresa.site == null ? '' : data.empresa.site));
    }
    doc.text(posX + logo_width + 5, 20, '' + (data.empresa.companyName == null ? '' : data.empresa.companyName));
    //lines = doc.splitTextToSize(endereco, 62)
    doc.text(posX + logo_width + 5, 25, '' + (data.empresa.addressDetail == null ? '' : data.empresa.addressDetail));
    var pos_rectangulo = posX + logo_width + 110;
    doc.rect(pos_rectangulo, 18, pageWidth / 3 - 17, 20)
    doc.setFontSize(8);
    doc.setFontType('bold');
    doc.text(pos_rectangulo + 5, 23, 'Nº ' + ((data.ordemTrabalho || {}).codigo == null ? '' : (data.ordemTrabalho || {}).codigo) + ((data.ordemTrabalho || {}).codigo_agendamento == null ? '' : '/' + (data.ordemTrabalho || {}).codigo_agendamento));
    doc.setFontType('Normal');
    doc.text(pos_rectangulo + 5, 28, 'Data:' + (data.ordemTrabalho || {}).data_inicio_previsao + ' ' + (data.ordemTrabalho || {}).hora_inicio_previsao);
    doc.text(pos_rectangulo + 5, 34, 'Prioridade:');
    doc.setFontType('bold');
    doc.text(pos_rectangulo + 18, 34, '' + ((data.ordemTrabalho || {}).prioridade_descricao == null ? '' : (data.ordemTrabalho || {}).prioridade_descricao));

    // CORPO DO RELATÓRIO ........
    doc.setFontSize(8);
    doc.setFillColor(208, 211, 212);
    doc.setFontType('bold');
    doc.rect(10, pos_body, pageWidth - 20, 5);
    logo_height = pos_body - 5;
    doc.text(posX + 10, logo_height + 8.5, 'ORDEM DE TRABALHO -');

    var corpo_ordem = logo_height + 14;
    console.log(data)
    doc.text(posX + 70, corpo_ordem - 5.5, '' + ((data.ordemTrabalho || {}).classificacao_descricao == null ? '' : (data.ordemTrabalho || {}).classificacao_descricao.toUpperCase()), null, 'center');

    if ((data.ordemTrabalho || {}).agendamento_flag == 'FECHADA') {
      doc.setFontType('normal');
      doc.setFontSize(7);
      doc.text(posX + 110, corpo_ordem + 13, '' + ((data.ordemTrabalho || {}).data_fim_execucao == null ? '' : (data.ordemTrabalho || {}).data_fim_execucao));
      doc.text(posX + 130, corpo_ordem + 13, '' + ((data.ordemTrabalho || {}).hora_fim_execucao == null ? '' : (data.ordemTrabalho || {}).hora_fim_execucao));
      doc.text(posX + 150, corpo_ordem + 13, '' + ((data.ordemTrabalho || {}).dif_dias_definida == null ? '' : (data.ordemTrabalho || {}).dif_dias_definida));
      doc.text(posX + 155, corpo_ordem + 13, '' + ((data.ordemTrabalho || {}).dif_horas_definida == null ? '' : (data.ordemTrabalho || {}).dif_horas_definida));
      doc.text(posX + 160, corpo_ordem + 13, '' + ((data.ordemTrabalho || {}).dif_minutos_definida == null ? '' : (data.ordemTrabalho || {}).dif_minutos_definida));
      doc.setTextColor(0, 0, 255);
      doc.setFontSize(8);
      doc.text(posX + 53, corpo_ordem - 5.5, 'EXECUÇÃO', null, 'center');
      doc.setTextColor(0, 0, 0);
      doc.setFontSize(7);
      doc.text(posX + 10, (corpo_ordem + 13), '' + ((data.ordemTrabalho || {}).data_inicio_previsao == null ? '' : (data.ordemTrabalho || {}).data_inicio_previsao));
      doc.text(posX + 25, (corpo_ordem + 13), '' + ((data.ordemTrabalho || {}).hora_inicio_previsao == null ? '' : (data.ordemTrabalho || {}).hora_inicio_previsao));
      doc.text(posX + 40, (corpo_ordem + 13), '' + ((data.ordemTrabalho || {}).dif_dias_prevista == null ? '' : (data.ordemTrabalho || {}).dif_dias_prevista));
      doc.text(posX + 45, (corpo_ordem + 13), '' + ((data.ordemTrabalho || {}).dif_horas_prevista == null ? '' : (data.ordemTrabalho || {}).dif_horas_prevista));
      doc.text(posX + 165, (corpo_ordem + 13), '' + ((data.ordemTrabalho || {}).dif_minutos_prevista == null ? '' : (data.ordemTrabalho || {}).dif_minutos_prevista));
    } else {

      /*Dados do agendamento*/
      doc.setTextColor(45, 124, 35);
      doc.setFontSize(8);
      doc.text(posX + 10, (corpo_ordem + 13), '' + ((data.ordemTrabalho || {}).data_inicio_previsao == null ? '' : (data.ordemTrabalho || {}).data_inicio_previsao));
      doc.text(posX + 25, (corpo_ordem + 13), '' + ((data.ordemTrabalho || {}).hora_inicio_previsao == null ? '' : (data.ordemTrabalho || {}).hora_inicio_previsao));
      doc.text(posX + 40, (corpo_ordem + 13), '' + ((data.ordemTrabalho || {}).dif_dias_prevista == null ? '' : (data.ordemTrabalho || {}).dif_dias_prevista));
      doc.text(posX + 45, (corpo_ordem + 13), '' + ((data.ordemTrabalho || {}).dif_horas_prevista == null ? '' : (data.ordemTrabalho || {}).dif_horas_prevista));
      doc.text(posX + 50, (corpo_ordem + 13), '' + ((data.ordemTrabalho || {}).dif_minutos_prevista == null ? '' : (data.ordemTrabalho || {}).dif_minutos_prevista));


      doc.text(posX + 165, corpo_ordem - 5.5, '' + ((data.ordemTrabalho || {}).agendamento_estado == null ? '' : (data.ordemTrabalho || {}).agendamento_estado.toUpperCase()), null, 'center');

      doc.setTextColor(0, 0, 0);
      doc.setFontSize(7);
    }


    /*Dados da OS.*/
    doc.setFontSize(7);
    doc.setFontType('bold');
    doc.text(posX + 10, corpo_ordem, 'TÍTULO OT');
    doc.text(posX + 70, corpo_ordem, 'RESPONSÁVEL');
    doc.text(posX + 10, corpo_ordem + 7, 'AGENDAMENTO PREVISTO');
    doc.text(posX + 110, corpo_ordem, 'CLASSIFICAÇÃO');
    doc.text(posX + 150, corpo_ordem, 'TIPO OT');
    doc.text(posX + 110, corpo_ordem + 7, 'EXECUÇÃO');
    doc.setFontType('normal');
    doc.text(posX + 10, corpo_ordem + 10, 'DATA');//data_agendamento
    doc.text(posX + 25, corpo_ordem + 10, 'HORA');//hora_agendamento
    doc.text(posX + 40, corpo_ordem + 10, 'DURAÇÃO (D H M)');//duracao_agendamento
    doc.text(posX + 110, corpo_ordem + 10, 'DATA');//data_execução
    doc.text(posX + 130, corpo_ordem + 10, 'HORA');//hora_execução
    doc.text(posX + 150, corpo_ordem + 10, 'DURAÇÃO (D H M)');//duração_execução
    doc.setFontType('bold');
    //doc.text(posX+10,(corpo_ordem+18),'DESCRIÇÃO DA ORDEM DE TRABALHO');
    doc.setFontType('normal');

    var titulo_ot = (data.ordemTrabalho || {}).titulo == null ? '' : (data.ordemTrabalho || {}).titulo;
    var split_titulo_ot = doc.splitTextToSize(titulo_ot, 65);
    doc.setFontSize(6);
    doc.text(posX + 10, corpo_ordem + 2, split_titulo_ot);


    //doc.text(posX+10,corpo_ordem+3,'' + ((data.ordemTrabalho || {} ).titulo == null ? '' : (data.ordemTrabalho || {} ).titulo));
    doc.text(posX + 70, corpo_ordem + 2, '' + ((data.ordemTrabalho || {}).responsavel_nome == null ? '' : (data.ordemTrabalho || {}).responsavel_nome));
    /*PERIODO DE AGENDAMENTO*/
    doc.text(posX + 110, corpo_ordem + 2, '' + ((data.ordemTrabalho || {}).classificacao_descricao == null ? '' : (data.ordemTrabalho || {}).classificacao_descricao));

    var tipo_ot = (data.ordemTrabalho || {}).tipo_descricao == null ? '' : (data.ordemTrabalho || {}).tipo_descricao;
    var split_tipo_ot = doc.splitTextToSize(tipo_ot, 50);
    doc.text(posX + 150, corpo_ordem + 2, split_tipo_ot);


    //doc.text(posX+150,corpo_ordem+2, '' + ((data.ordemTrabalho || {} ).tipo_descricao == null ? '' : (data.ordemTrabalho || {} ).tipo_descricao));
    var splitText = (data.ordemTrabalho || {}).descricao ? doc.splitTextToSize((data.ordemTrabalho || {}).descricao, 190) : null
    doc.text(splitText == null ? '' : splitText, posX + 10, corpo_ordem + 21);

    if ((data.ordemTrabalho || {}).descricao && (data.ordemTrabalho || {}).descricao.length >= 448) {
      doc.setFontType('bold');
      // doc.text(posX+10,corpo_ordem+32,'DESCRIÇÃO DO AGENDAMENTO');
      doc.setFontType('normal');
      var splitText = doc.splitTextToSize('' + (!data.agendamentoComentario ? '' : data.agendamentoComentario.descricao), 190)
      doc.text(splitText == '' ? '' : splitText, posX + 10, corpo_ordem + 35);
    } else {
      doc.setFontType('bold');
      //doc.text(posX+10,corpo_ordem+28,'DESCRIÇÃO DO AGENDAMENTO');
      doc.setFontType('normal');
      var splitText = doc.splitTextToSize('' + (!data.agendamentoComentario ? '' : data.agendamentoComentario.descricao), 190)
      doc.text(splitText == '' ? '' : splitText, posX + 10, corpo_ordem + 31);
    }

    /*Verificação do tamanho da descrição.*/

    if (data.agendamentoComentario && data.agendamentoComentario.descricao ? data.agendamentoComentario.descricao.length >= 448 : 1 >= 448) {
      if (data.ordemEquipamento) {
        pos_equipamento = corpo_ordem + 27;
      } else {
        pos_cliente = corpo_ordem + 27;
      }

    } else {
      if (data.ordemEquipamento) {
        pos_equipamento = corpo_ordem + 19;
      } else {
        pos_cliente = corpo_ordem + 19;
      }
    }
    if (data.ordemEquipamento && data.ordemCliente) {
      doc.setFontSize(8);
      doc.setFontType('bold');
      doc.setFillColor(208, 211, 212);
      doc.rect(10, pos_equipamento, pageWidth - 20, 5);
      doc.text(posX + 10, pos_equipamento + 3.5, 'EQUIPAMENTO');
      doc.setFontSize(7);
      doc.setFontType('bold');
      doc.text(posX + 10, pos_equipamento + 9, 'Nº SÉRIE');
      doc.text(posX + 30, pos_equipamento + 9, 'CÓDIGO DE REGISTO');
      doc.text(posX + 60, pos_equipamento + 9, 'ABREVIATURA');
      doc.text(posX + 10, pos_equipamento + 16, 'DESIGNAÇÃO');
      doc.text(posX + 140, pos_equipamento + 9, 'ESTADO');
      doc.text(posX + 170, pos_equipamento + 9, 'TIPO DE AQUISIÇÃO');
      doc.text(posX + 10, pos_equipamento + 23, 'GRAU DE CRITICIDADE');
      doc.text(posX + 90, pos_equipamento + 9, 'ESTADO DE CONSERVAÇÃO');
      doc.text(posX + 90, pos_equipamento + 23, 'MODELO');
      doc.text(posX + 135, pos_equipamento + 23, 'LATITUDE');
      doc.text(posX + 170, pos_equipamento + 23, 'LONGITUDE');
      /*Dados do Equipamento*/
      doc.setFontSize(7);
      doc.setFontType('normal');
      doc.text(posX + 10, pos_equipamento + 12, '' + (data.ordemEquipamento.numero_serie == null ? '' : data.ordemEquipamento.numero_serie));
      doc.text(posX + 30, pos_equipamento + 12, '' + (data.ordemEquipamento.codigo_registo == null ? '' : data.ordemEquipamento.codigo_registo));
      doc.text(posX + 60, pos_equipamento + 12, '' + (data.ordemEquipamento.abreviatura == null ? '' : data.ordemEquipamento.abreviatura));
      var designacao_equip = doc.splitTextToSize(data.ordemEquipamento.designacao_equipamento, 190)
      doc.text(designacao_equip == null ? '' : designacao_equip, posX + 10, pos_equipamento + 19);
      doc.text(posX + 140, pos_equipamento + 12, '' + (data.ordemEquipamento.estado == null ? '' : data.ordemEquipamento.estado));
      doc.text(posX + 170, pos_equipamento + 12, '' + (data.ordemEquipamento.tipo_aquisicao == null ? '' : data.ordemEquipamento.tipo_aquisicao));// tipo_aquisição
      doc.text(posX + 10, pos_equipamento + 26, '' + (data.ordemEquipamento.grau_criticidade == null ? '' : data.ordemEquipamento.grau_criticidade));//grau_criticidade
      doc.text(posX + 90, pos_equipamento + 12, '' + (data.ordemEquipamento.estado_conservacao == null ? '' : data.ordemEquipamento.estado_conservacao));//estado_conservação
      doc.text(posX + 90, pos_equipamento + 26, '' + (data.ordemEquipamento.modelo == null ? '' : data.ordemEquipamento.modelo));//modelo
      doc.text(posX + 135, pos_equipamento + 26, '' + (data.ordemEquipamento.latitude == null ? '' : data.ordemEquipamento.latitude));//latitude
      doc.text(posX + 170, pos_equipamento + 26, '' + (data.ordemEquipamento.longitude == null ? '' : data.ordemEquipamento.longitude));//longitude
      /*var splitText = doc.splitTextToSize(obsevacao_equipamento, 190)
      doc.text(splitText, posX+10,pos_equipamento+64);
      if(obsevacao_equipamento.length >= 435) {
          pos_cliente = pos_equipamento+80;
      } else {
          pos_cliente = pos_equipamento+70;
      }*/

      let predio_nome = ((data.ordemCliente.cil != null) ? '[' + data.ordemCliente.cil + '] ' : '[0000-000-000] ') + ((data.ordemCliente.is_predio) ? 'Prédio ' + data.ordemCliente.predio_nome + ', ' + (data.ordemCliente.predio_andar ? data.ordemCliente.predio_andar + ' º Andar - ' : '') + 'Porta ' : 'Residência ') + data.ordemCliente.moradia_numero;

      pos_cliente = pos_equipamento + 30;
      doc.setFontSize(8);
      doc.setFontType('bold');
      doc.setFillColor(208, 211, 212);
      doc.rect(10, pos_cliente, pageWidth - 20, 5);
      doc.text(posX + 10, pos_cliente + 3.5, 'CLIENTE');
      doc.setFontSize(7);
      doc.setFontType('bold');
      doc.text(posX + 10, (pos_cliente + 9), 'Nº CLIENTE');
      doc.text(posX + 30, (pos_cliente + 9), 'CONTA');
      doc.text(posX + 50, (pos_cliente + 9), 'CONTRATO');
      doc.text(posX + 70, ((pos_cliente + 9)), 'NOME');
      doc.text(posX + 135, ((pos_cliente + 9)), 'MUNICIPIO');
      doc.text(posX + 10, (pos_cliente + 16), 'DIST. URBANO/LOCALIDADE');
      doc.text(posX + 70, (pos_cliente + 16), 'BAIRRO/ZONA');
      doc.text(posX + 135, ((pos_cliente + 16)), 'MORADA');
      doc.text(posX + 50, ((pos_cliente + 23)), 'CIL');
      doc.text(posX + 10, (pos_cliente + 23), 'CONTADOR');
      doc.text(posX + 70, (pos_cliente + 23), 'LATITUDE');
      doc.text(posX + 135, (pos_cliente + 23), 'LONGITUDE');
      doc.setFontType('normal');
      doc.text(posX + 10, (pos_cliente + 12), '' + (data.ordemCliente.cliente_numero == null ? '' : data.ordemCliente.cliente_numero));
      doc.text(posX + 30, (pos_cliente + 12), '' + (data.ordemCliente.numero_conta == null ? '' : data.ordemCliente.numero_conta));
      doc.text(posX + 50, (pos_cliente + 12), '' + (data.ordemCliente.contrato_numero == null ? '' : data.ordemCliente.contrato_numero));
      doc.text(posX + 70, (pos_cliente + 12), '' + (data.ordemCliente.cliente_nome == null ? '' : data.ordemCliente.cliente_nome));
      doc.text(posX + 135, (pos_cliente + 12), '' + (data.ordemCliente.municipio == null ? '' : data.ordemCliente.municipio));
      doc.text(posX + 10, (pos_cliente + 19), '' + (data.ordemCliente.distrito == null ? '' : data.ordemCliente.distrito));
      doc.text(posX + 70, (pos_cliente + 19), '' + (data.ordemCliente.bairro == null ? '' : data.ordemCliente.bairro));
      doc.text(posX + 135, (pos_cliente + 19), '' + (predio_nome == null ? '' : predio_nome));
      doc.text(posX + 10, (pos_cliente + 26), '' + (data.ordemCliente.contador == null ? '' : data.ordemCliente.contador));//contador
      doc.text(posX + 50, (pos_cliente + 26), '' + (data.ordemCliente.cil == null ? '' : data.ordemCliente.cil));//CIL
      doc.text(posX + 70, (pos_cliente + 26), '' + (data.ordemCliente.latitude == null ? '' : data.ordemCliente.latitude));//Latitude
      doc.text(posX + 135, (pos_cliente + 26), '' + (data.ordemCliente.longitude == null ? '' : data.ordemCliente.longitude));//Longitude
      //doc.addPage();
      var pos_cliente_aux = pos_cliente + 26;
      pos_cliente = pos_cliente_aux;
      /*Dados dos Materiais a utilizar.*/
      doc.setFontSize(8);
      doc.setFontType('bold');
      doc.rect(10, pos_cliente + 5, pageWidth - 20, 5);
      doc.text(posX + 10, pos_cliente + 8.5, 'TAREFASSS');
      doc.setFontSize(7);
      //doc.text(posX+10,(pos_cliente+14),'CÓDIGO');
      doc.text(posX + 10, pos_cliente + 14, 'DESIGNAÇÃO');
      doc.text(posX + 80, pos_cliente + 14, 'ESTADO');
      doc.text(posX + 105, pos_cliente + 15, 'CHECKLIST');
      doc.text(posX + 155, pos_cliente + 15, 'OBS');
      //doc.text(posX+170,pos_cliente+14,'QTD (UTL)');
      doc.line(10, pos_cliente + 15, pageWidth - 10, pos_cliente + 15);
      var pos_materiais = pos_cliente + 18;


      for (var i = 0; i < data.osTarefas.length; i++) {
        doc.setFontSize(7);
        doc.setFontType('normal');
        if (data.osTarefas[i].descricao.length >= 57) {
          doc.setFontSize(6);
          doc.text(posX + 10, pos_materiais, '' + data.osTarefas[i].descricao == null ? '' : data.osTarefas[i].descricao);
        } else {
          doc.text(posX + 10, pos_materiais, '' + data.osTarefas[i].descricao == null ? '' : data.osTarefas[i].descricao);
        }
        var split_tipo_ot = doc.splitTextToSize(tipo_ot, 50);
        doc.text(posX + 10, pos_materiais, split_tipo_ot);

        doc.text(posX + 10, pos_materiais, '' + data.osTarefas[i].descricao == null ? '' : data.osTarefas[i].descricao);
        doc.text(posX + 80, pos_materiais, '' + (data.osTarefas[i].estadoFlag == null ? '' : data.osTarefas[i].estadoFlag));
        doc.rect(posX + 110, pos_materiais, 6, 4);
        //doc.text(posX+155,pos_materiais,'');
        //doc.text(posX+108,pos_materiais,'' + (data.osTarefas[i].quantidade_utilizada == null ? '' : data.osTarefas[i].quantidade_utilizada));
        pos_materiais += 3;
      }
      if (pos_materiais + 10 >= pageHeight - 25) {
        doc.addPage();
        pos_materiais = 10;
      }
      doc.setFontType('bold');
      doc.text('OBSERVAÇÃO', posX + 10, pos_materiais + 1);
      doc.setFontType('normal');
      doc.line(11, pos_materiais + 2, pageWidth - 10, pos_materiais + 2);
      doc.setFontSize(7);
      var pos_materiais = pos_materiais + 33;
      /*Dados dos Recursos a utilizar.*/
      doc.setFontSize(8);
      doc.setFontType('bold');
      doc.setFillColor(208, 211, 212);
      var pos_rec = pos_materiais - 20;
      doc.rect(10, pos_rec, pageWidth - 20, 5);
      doc.text(posX + 10, (pos_rec) + 3.5, 'CONSUMIVEIS');


      doc.setFontType('bold');
      doc.setFontSize(7);
      doc.text(posX + 10, pos_rec + 9, 'CÓDIGO');
      doc.text(posX + 50, pos_rec + 9, 'DESIGNAÇÃO');
      doc.text(posX + 115, pos_rec + 9, 'QUANTIDADE EST');
      //doc.text(posX+120,pos_rec+9,'H');
      //doc.text(posX+125,pos_rec+9,'M (EST)');
      doc.text(posX + 170, pos_rec + 9, 'QUANTIDADE UTIL');
      //doc.text(posX+175,pos_rec+9,'H');
      //doc.text(posX+180,pos_rec+9,'M (UTL)');
      doc.line(10, pos_rec + 10, pageWidth - 10, pos_rec + 10);
      for (var i = 0; i < data.ordemMaterial.length; i++) {
        doc.setFontType('normal');
        doc.setFontSize(7);
        var pos_item = pos_rec + 13;
        doc.text(posX + 10, pos_item, '' + (data.ordemMaterial[i].codigo_material == null ? '' : data.ordemMaterial[i].codigo_material));
        doc.text(posX + 50, pos_item, '' + (data.ordemMaterial[i].nome_material == null ? '' : data.ordemMaterial[i].nome_material));
        doc.text(posX + 115, pos_item, '' + (data.ordemMaterial[i].quantidade_prevista == null ? '' : data.ordemMaterial[i].quantidade_prevista));
        //doc.text(posX+120,pos_item,'' + (data.ordemMaterial[i].dif_horas_prevista == null ? '' : data.ordemMaterial[i].dif_horas_prevista));
        //doc.text(posX+125,pos_item,'' + (data.ordemMaterial[i].quantidade_prevista == null ? '' : data.ordemMaterial[i].quantidade_prevista));
        doc.text(posX + 170, pos_item, '' + (data.ordemMaterial[i].quantidade_utilizada == null ? '' : data.ordemMaterial[i].quantidade_utilizada));
        //doc.text(posX+175,pos_item,'' + (data.ordemMaterial[i].dif_horas_definida == null ? '' : data.ordemMaterial[i].dif_horas_definida));
        //doc.text(posX+180,pos_item,'' + (data.ordemMaterial[i].dif_minutos_definida == null ? '' : data.ordemMaterial[i].dif_minutos_definida));
        pos_rec += 3;
      }
      doc.setFontType('bold');
      doc.text('OBSERVAÇÃO', posX + 10, pos_rec + 13);
      doc.setFontType('normal');
      // Obsevações...
      doc.line(11, pos_rec + 14, pageWidth - 10, pos_rec + 14);
      var pos_rec = pos_rec + 25;
      doc.setFontSize(8);
      doc.setFontType('bold');
      doc.setFillColor(208, 211, 212);
      doc.rect(10, pos_rec, pageWidth - 20, 5);
      doc.text(posX + 10, (pos_rec) + 3.5, 'RECURSOS HUMANOS');
      doc.setFontType('bold');
      doc.setFontSize(8);

      doc.text(posX + 10, pos_rec + 9, 'FUNÇÃO');
      doc.text(posX + 50, pos_rec + 9, 'COLABORADOR');
      doc.text(posX + 115, pos_rec + 9, 'D');
      doc.text(posX + 120, pos_rec + 9, 'H');
      doc.text(posX + 125, pos_rec + 9, 'M (EST)');
      doc.text(posX + 170, pos_rec + 9, 'D');
      doc.text(posX + 175, pos_rec + 9, 'H');
      doc.text(posX + 180, pos_rec + 9, 'M (UTLL)');
      doc.line(10, pos_rec + 10, pageWidth - 10, pos_rec + 10);

      for (var i = 0; i < data.ordemRecursosHumanos.length; i++) {
        doc.setFontSize(7);
        doc.setFontType('normal');
        doc.text(posX + 10, pos_rec + 15, '' + (data.ordemRecursosHumanos[i].nome_funcao == null ? '' : data.ordemRecursosHumanos[i].nome_funcao));
        doc.text(posX + 50, pos_rec + 15, '' + (data.ordemRecursosHumanos[i].colaborador_nome == null ? '' : data.ordemRecursosHumanos[i].colaborador_nome));
        doc.text(posX + 115, pos_rec + 15, '' + (data.ordemRecursosHumanos[i].dif_dias_prevista == null ? '' : data.ordemRecursosHumanos[i].dif_dias_prevista));
        doc.text(posX + 120, pos_rec + 15, '' + (data.ordemRecursosHumanos[i].dif_horas_prevista == null ? '' : data.ordemRecursosHumanos[i].dif_horas_prevista));
        doc.text(posX + 125, pos_rec + 15, '' + (data.ordemRecursosHumanos[i].dif_minutos_prevista == null ? '' : data.ordemRecursosHumanos[i].dif_minutos_prevista));
        doc.text(posX + 170, pos_rec + 15, '' + (data.ordemRecursosHumanos[i].dif_dias == null ? '' : data.ordemRecursosHumanos[i].dif_dias));
        doc.text(posX + 175, pos_rec + 15, '' + (data.ordemRecursosHumanos[i].dif_horas == null ? '' : data.ordemRecursosHumanos[i].dif_horas));
        doc.text(posX + 180, pos_rec + 15, '' + (data.ordemRecursosHumanos[i].dif_minutos == null ? '' : data.ordemRecursosHumanos[i].dif_minutos));
        pos_rec += 3;
      }



      var pos_desc_interv = pos_rec + 25;
      doc.setFontSize(8);
      doc.setFontType('bold');
      doc.setFillColor(208, 211, 212);
      // doc.rect(10, pos_desc_interv, pageWidth-20, 5);
      // doc.text(posX+10,(pos_desc_interv)+3.5,'DESCRIÇÃO DA INTERVENÇÃO');
      doc.setFontType('normal');
      doc.setFontSize(7);
      var obs = doc.splitTextToSize(!data.agendamentoComentarioIntervencao ? '' : data.agendamentoComentarioIntervencao.descricao, 187)
      doc.text((obs == '' ? '' : obs), posX + 11, pos_desc_interv + 8);
      /*if(observacao.length >=435) {
          doc.rect(10, pos_obs+5, pageWidth-20, 15);
     } else {
          doc.rect(10, pos_obs+5, pageWidth-20, 13);
     }*/

      assinaturas(pageHeight - 40);

    } else if (data.ordemEquipamento && !data.ordemCliente) {
      doc.setFontSize(8);
      doc.setFontType('bold');
      doc.setFillColor(208, 211, 212);
      doc.rect(10, pos_equipamento, pageWidth - 20, 5);
      doc.text(posX + 10, pos_equipamento + 3.5, 'EQUIPAMENTO');
      doc.setFontSize(7);
      doc.setFontType('bold');
      doc.text(posX + 10, pos_equipamento + 9, 'Nº SÉRIE');
      doc.text(posX + 30, pos_equipamento + 9, 'CÓDIGO DE REGISTO');
      doc.text(posX + 60, pos_equipamento + 9, 'ABREVIATURA');
      doc.text(posX + 10, pos_equipamento + 16, 'DESIGNAÇÃO');
      doc.text(posX + 140, pos_equipamento + 9, 'ESTADO');
      doc.text(posX + 170, pos_equipamento + 9, 'TIPO DE AQUISIÇÃO');
      doc.text(posX + 10, pos_equipamento + 23, 'GRAU DE CRITICIDADE');
      doc.text(posX + 90, pos_equipamento + 9, 'ESTADO DE CONSERVAÇÃO');
      doc.text(posX + 90, pos_equipamento + 23, 'MODELO');
      doc.text(posX + 135, pos_equipamento + 23, 'LATITUDE');
      doc.text(posX + 170, pos_equipamento + 23, 'LONGITUDE');
      /*Dados do Equipamento*/
      doc.setFontSize(7);
      doc.setFontType('normal');
      doc.text(posX + 10, pos_equipamento + 12, '' + (data.ordemEquipamento.numero_serie == null ? '' : data.ordemEquipamento.numero_serie));
      doc.text(posX + 30, pos_equipamento + 12, '' + (data.ordemEquipamento.codigo_registo == null ? '' : data.ordemEquipamento.codigo_registo));
      doc.text(posX + 60, pos_equipamento + 12, '' + (data.ordemEquipamento.abreviatura == null ? '' : data.ordemEquipamento.abreviatura));
      var designacao_equip = doc.splitTextToSize(data.ordemEquipamento.designacao_equipamento, 190)
      doc.text(designacao_equip == null ? '' : designacao_equip, posX + 10, pos_equipamento + 19);
      doc.text(posX + 140, pos_equipamento + 12, '' + (data.ordemEquipamento.estado == null ? '' : data.ordemEquipamento.estado));
      doc.text(posX + 170, pos_equipamento + 12, '' + (data.ordemEquipamento.tipo_aquisicao == null ? '' : data.ordemEquipamento.tipo_aquisicao));// tipo_aquisição
      doc.text(posX + 10, pos_equipamento + 26, '' + (data.ordemEquipamento.grau_criticidade == null ? '' : data.ordemEquipamento.grau_criticidade));//grau_criticidade
      doc.text(posX + 90, pos_equipamento + 12, '' + (data.ordemEquipamento.estado_conservacao == null ? '' : data.ordemEquipamento.estado_conservacao));//estado_conservação
      doc.text(posX + 90, pos_equipamento + 26, '' + (data.ordemEquipamento.modelo == null ? '' : data.ordemEquipamento.modelo));//modelo
      doc.text(posX + 135, pos_equipamento + 26, '' + (data.ordemEquipamento.latitude == null ? '' : data.ordemEquipamento.latitude));//latitude
      doc.text(posX + 170, pos_equipamento + 26, '' + (data.ordemEquipamento.longitude == null ? '' : data.ordemEquipamento.longitude));//longitude
      /*var splitText = doc.splitTextToSize(obsevacao_equipamento, 190)
      doc.text(splitText, posX+10,pos_equipamento+64);
      if(obsevacao_equipamento.length >= 435) {
          pos_cliente = pos_equipamento+80;
      } else {
          pos_cliente = pos_equipamento+70;
      }*/
      pos_cliente = pos_equipamento + 24;
      doc.setFontSize(8);
      doc.setFontType('bold');
      doc.setTextColor(0)
      doc.setFillColor(208, 211, 212);
      doc.rect(10, pos_cliente + 6, pageWidth - 20, 5);
      doc.text(posX + 10, pos_cliente + 10, 'TAREFAS');
      doc.setFontType('bold');
      doc.setFontSize(8);
      //doc.text(posX+10,(pos_cliente+16),'CÓDIGO');
      doc.text(posX + 10, pos_cliente + 16, 'DESIGNAÇÃO');
      doc.text(posX + 80, pos_cliente + 16, 'ESTADO');
      doc.text(posX + 105, pos_cliente + 15, 'CHECKLIST');
      doc.text(posX + 155, pos_cliente + 15, 'OBS');
      //doc.text(posX+170,pos_cliente+16,'QTD (UTL)');
      doc.line(10, pos_cliente + 17, pageWidth - 10, pos_cliente + 17);
      var pos_materiais = pos_cliente + 20;


      for (var i = 0; i < data.osTarefas.length; i++) {
        doc.setFontType('normal');
        doc.setFontSize(7);
        var total_linhas = ((data.osTarefas[i].descricao.length) / 57)
        var indice_inicio = 0
        var indice_fim = 57
        var texto = null
        //doc.text(posX+10,pos_materiais,'' + (data.osTarefas[i].codigo_material == null ? '' : data.osTarefas[i].codigo_material));
        if (total_linhas <= 1) {
          doc.text(posX + 10, pos_materiais, '' + data.osTarefas[i].descricao == null ? '' : data.osTarefas[i].descricao);
          doc.text(posX + 80, pos_materiais, '' + (data.osTarefas[i].estadoFlag == null ? '' : data.osTarefas[i].estadoFlag));
          doc.rect(posX + 110, pos_materiais, 5, 3);

        } else {
          for (var t = 0; t < total_linhas; t++) {
            doc.text(posX + 10, pos_materiais, '' + data.osTarefas[i].descricao == null ? '' : data.osTarefas[i].descricao.substring(indice_inicio, indice_fim));
            pos_materiais += 7;
            indice_inicio = indice_fim;
            indice_fim = indice_fim + 57
          }
          doc.text(posX + 80, pos_materiais - (total_linhas * 7), '' + (data.osTarefas[i].estadoFlag == null ? '' : data.osTarefas[i].estadoFlag));
          doc.rect(posX + 110, pos_materiais - (total_linhas * 7), 5, 3);

        }
        pos_materiais += 7;
        /*
        doc.setFontType('normal');
          doc.setFontSize(7);
          //doc.text(posX+10,pos_materiais,'' + (data.osTarefas[i].codigo_material == null ? '' : data.osTarefas[i].codigo_material));
          if(data.osTarefas[i].descricao.length >= 57){
            doc.setFontSize(6);
             doc.text(posX+10,pos_materiais,'' + data.osTarefas[i].descricao == null ? '' : data.osTarefas[i].descricao);
          }else {
              doc.text(posX+10,pos_materiais,'' + data.osTarefas[i].descricao == null ? '' : data.osTarefas[i].descricao);
          }

          //doc.text(posX+10,pos_materiais,'' + data.osTarefas[i].descricao == null ? '' : data.osTarefas[i].descricao);
          doc.text(posX+80,pos_materiais,'' + (data.osTarefas[i].estadoFlag == null ? '' : data.osTarefas[i].estadoFlag));
          //doc.text(posX+155,pos_materiais,'');
          doc.rect(posX+110,pos_materiais, 5, 3);
          //doc.text(posX+180,pos_materiais,'' + (data.osTarefas[i].quantidade_utilizada == null ? '' : data.osTarefas[i].quantidade_utilizada));
          pos_materiais +=7;*/
      }

      var pos_materiais = pos_materiais + 34;
      if (pos_materiais + 10 >= pageHeight - 25) {
        doc.addPage();
        pos_materiais = 10;
      }
      /*Dados dos Recursos a utilizar.*/
      doc.setFontSize(8);
      doc.setFontType('bold');
      doc.setFillColor(208, 211, 212);
      var pos_rec = pos_materiais - 20;
      doc.rect(10, pos_rec, pageWidth - 20, 5);
      doc.text(posX + 10, (pos_rec) + 3.5, 'CONSUMIVEIS');


      doc.setFontType('bold');
      doc.setFontSize(8);

      doc.text(posX + 10, pos_rec + 9, 'CÓDIGO');
      doc.text(posX + 50, pos_rec + 9, 'DESIGNAÇÃO');
      doc.text(posX + 115, pos_rec + 9, 'QUANTIDADE EST');
      //doc.text(posX+120,pos_rec+9,'H');
      //doc.text(posX+125,pos_rec+9,'M (EST)');
      doc.text(posX + 170, pos_rec + 9, 'QUANTIDADE UTIL');


      doc.line(10, pos_rec + 10, pageWidth - 10, pos_rec + 10);


      for (var i = 0; i < data.ordemMaterial.length; i++) {
        doc.setFontType('normal');
        doc.setFontSize(7);
        var pos_item = pos_rec + 13;
        doc.text(posX + 10, pos_item, '' + (data.ordemMaterial[i].codigo_material == null ? '' : data.ordemMaterial[i].codigo_material));
        doc.text(posX + 50, pos_item, '' + (data.ordemMaterial[i].nome_material == null ? '' : data.ordemMaterial[i].nome_material));
        doc.text(posX + 115, pos_item, '' + (data.ordemMaterial[i].quantidade_prevista == null ? '' : data.ordemMaterial[i].quantidade_prevista));
        //doc.text(posX+120,pos_item,'' + (data.ordemMaterial[i].dif_horas_prevista == null ? '' : data.ordemMaterial[i].dif_horas_prevista));
        //doc.text(posX+125,pos_item,'' + (data.ordemMaterial[i].quantidade_prevista == null ? '' : data.ordemMaterial[i].quantidade_prevista));
        doc.text(posX + 170, pos_item, '' + (data.ordemMaterial[i].quantidade_utilizada == null ? '' : data.ordemMaterial[i].quantidade_utilizada));
        //doc.text(posX+175,pos_item,'' + (data.ordemMaterial[i].dif_horas_definida == null ? '' : data.ordemMaterial[i].dif_horas_definida));
        //doc.text(posX+180,pos_item,'' + (data.ordemMaterial[i].dif_minutos_definida == null ? '' : data.ordemMaterial[i].dif_minutos_definida));
        pos_rec += 3;
      }

      var pos_obs = pos_rec + 28;
      doc.setFontSize(8);
      doc.setFontType('bold');
      doc.setTextColor(0)
      doc.setFillColor(208, 211, 212);
      doc.rect(10, pos_obs, pageWidth - 20, 5);
      doc.text(posX + 10, (pos_obs) + 3.5, 'RECURSOS HUMANOS');
      doc.setFontType('bold');
      doc.setFontSize(8);

      doc.text(posX + 10, pos_obs + 9, 'FUNÇÃO');
      doc.text(posX + 50, pos_obs + 9, 'COLABORADOR');
      doc.text(posX + 115, pos_obs + 9, 'D');
      doc.text(posX + 120, pos_obs + 9, 'H');
      doc.text(posX + 125, pos_obs + 9, 'M (EST)');
      doc.text(posX + 170, pos_obs + 9, 'D');
      doc.text(posX + 175, pos_obs + 9, 'H');
      doc.text(posX + 180, pos_obs + 9, 'M (UTL)');
      doc.line(10, pos_obs + 10, pageWidth - 10, pos_obs + 10);

      for (var i = 0; i < data.ordemRecursosHumanos.length; i++) {
        doc.setFontSize(7);
        doc.setFontType('normal');
        doc.text(posX + 10, pos_obs + 15, '' + (data.ordemRecursosHumanos[i].nome_funcao == null ? '' : data.ordemRecursosHumanos[i].nome_funcao));
        doc.text(posX + 50, pos_obs + 15, '' + (data.ordemRecursosHumanos[i].colaborador_nome == null ? '' : data.ordemRecursosHumanos[i].colaborador_nome));
        doc.text(posX + 115, pos_obs + 15, '' + (data.ordemRecursosHumanos[i].dif_dias_prevista == null ? '' : data.ordemRecursosHumanos[i].dif_dias_prevista));
        doc.text(posX + 120, pos_obs + 15, '' + (data.ordemRecursosHumanos[i].dif_horas_prevista == null ? '' : data.ordemRecursosHumanos[i].dif_horas_prevista));
        doc.text(posX + 125, pos_obs + 15, '' + (data.ordemRecursosHumanos[i].dif_minutos_prevista == null ? '' : data.ordemRecursosHumanos[i].dif_minutos_prevista));
        doc.text(posX + 170, pos_obs + 15, '' + (data.ordemRecursosHumanos[i].dif_dias == null ? '' : data.ordemRecursosHumanos[i].dif_dias));
        doc.text(posX + 175, pos_obs + 15, '' + (data.ordemRecursosHumanos[i].dif_horas == null ? '' : data.ordemRecursosHumanos[i].dif_horas));
        doc.text(posX + 180, pos_obs + 15, '' + (data.ordemRecursosHumanos[i].dif_minutos == null ? '' : data.ordemRecursosHumanos[i].dif_minutos));
        pos_obs += 3;
      }



      var pos_desc_interv = pos_obs + 25;
      doc.setFontSize(8);
      doc.setFontType('bold');
      doc.setFillColor(208, 211, 212);
      doc.rect(10, pos_desc_interv, pageWidth - 20, 5);
      doc.text(posX + 10, (pos_desc_interv) + 3.5, 'OBSERVAÇÕES GERAIS');
      doc.setFontType('normal');
      doc.setFontSize(7);
      var obs = doc.splitTextToSize(!data.agendamentoComentarioIntervencao ? '' : data.agendamentoComentarioIntervencao.descricao, 187)
      doc.text((obs == '' ? '' : obs), posX + 11, pos_desc_interv + 8);

      assinaturas(pageHeight -  40);


    } else if (!data.ordemEquipamento && data.ordemCliente) {

      let predio_nome = ((data.ordemCliente.cil != null) ? '[' + data.ordemCliente.cil + '] ' : '[0000-000-000] ') + ((data.ordemCliente.is_predio) ? 'Prédio ' + data.ordemCliente.predio_nome + ', ' + (data.ordemCliente.predio_andar ? data.ordemCliente.predio_andar + ' º Andar - ' : '') + 'Porta ' : 'Residência ') + data.ordemCliente.moradia_numero;

      doc.setFontSize(8);
      doc.setFontType('bold');
      doc.setFillColor(208, 211, 212);
      doc.rect(10, pos_cliente, pageWidth - 20, 5);
      doc.text(posX + 10, pos_cliente + 3.5, 'CLIENTE');
      doc.setFontSize(7);
      doc.setFontType('bold');
      doc.text(posX + 10, (pos_cliente + 9), 'Nº CLIENTE');
      doc.text(posX + 30, (pos_cliente + 9), 'CONTA');
      doc.text(posX + 50, (pos_cliente + 9), 'CONTRATO');
      doc.text(posX + 70, ((pos_cliente + 9)), 'NOME');
      doc.text(posX + 135, ((pos_cliente + 9)), 'MUNICIPIO');
      doc.text(posX + 10, (pos_cliente + 16), 'DIST. URBANO/LOCALIDADE');
      doc.text(posX + 70, (pos_cliente + 16), 'BAIRRO/ZONA');
      doc.text(posX + 135, ((pos_cliente + 16)), 'MORADA');
      doc.text(posX + 50, ((pos_cliente + 23)), 'CIL');
      doc.text(posX + 10, (pos_cliente + 23), 'CONTADOR');
      doc.text(posX + 70, (pos_cliente + 23), 'LATITUDE');
      doc.text(posX + 135, (pos_cliente + 23), 'LONGITUDE');
      doc.setFontType('normal');
      doc.text(posX + 10, (pos_cliente + 12), '' + (data.ordemCliente.cliente_numero == null ? '' : data.ordemCliente.cliente_numero));
      doc.text(posX + 30, (pos_cliente + 12), '' + (data.ordemCliente.numero_conta == null ? '' : data.ordemCliente.numero_conta));
      doc.text(posX + 50, (pos_cliente + 12), '' + (data.ordemCliente.contrato_numero == null ? '' : data.ordemCliente.contrato_numero));
      doc.text(posX + 70, (pos_cliente + 12), '' + (data.ordemCliente.cliente_nome == null ? '' : data.ordemCliente.cliente_nome));
      doc.text(posX + 135, (pos_cliente + 12), '' + (data.ordemCliente.municipio == null ? '' : data.ordemCliente.municipio));
      doc.text(posX + 10, (pos_cliente + 19), '' + (data.ordemCliente.distrito == null ? '' : data.ordemCliente.distrito));
      doc.text(posX + 70, (pos_cliente + 19), '' + (data.ordemCliente.bairro == null ? '' : data.ordemCliente.bairro));
      doc.text(posX + 135, (pos_cliente + 19), '' + (predio_nome == null ? '' : predio_nome));
      doc.text(posX + 10, (pos_cliente + 26), '' + (data.ordemCliente.contador == null ? '' : data.ordemCliente.contador));//contador
      doc.text(posX + 50, (pos_cliente + 26), '' + (data.ordemCliente.cil == null ? '' : data.ordemCliente.cil));//CIL
      doc.text(posX + 70, (pos_cliente + 26), '' + (data.ordemCliente.latitude == null ? '' : data.ordemCliente.latitude));//Latitude
      doc.text(posX + 135, (pos_cliente + 26), '' + (data.ordemCliente.longitude == null ? '' : data.ordemCliente.longitude));//Longitude
      var pos_cliente_aux = pos_cliente + 24;
      pos_cliente = pos_cliente_aux;
      /*Dados dos Materiais a utilizar.*/
      doc.setFontSize(8);
      doc.setFontType('bold');
      doc.setTextColor(0)
      doc.setFillColor(208, 211, 212);
      doc.rect(10, pos_cliente + 5, pageWidth - 20, 5);
      doc.text(posX + 10, pos_cliente + 8.5, 'TAREFAS');
      doc.setFontType('bold');
      doc.setFontSize(8);
      //doc.text(posX+10,(pos_cliente+15),'CÓDIGO');
      doc.text(posX + 10, pos_cliente + 15, 'DESIGNAÇÃO');
      doc.text(posX + 80, pos_cliente + 15, 'ESTADO');
      doc.text(posX + 105, pos_cliente + 15, 'CHECKLIST');
      doc.text(posX + 155, pos_cliente + 15, 'OBS');
      doc.line(10, pos_cliente + 16, pageWidth - 10, pos_cliente + 16);
      var pos_materiais = pos_cliente + 20;


      for (var i = 0; i < data.osTarefas.length; i++) {
        doc.setFontType('normal');
        doc.setFontSize(7);
        var total_linhas = ((data.osTarefas[i].descricao.length) / 57)
        var indice_inicio = 0
        var indice_fim = 57
        var texto = null
        //doc.text(posX+10,pos_materiais,'' + (data.osTarefas[i].codigo_material == null ? '' : data.osTarefas[i].codigo_material));
        if (total_linhas <= 1) {
          doc.text(posX + 10, pos_materiais, '' + data.osTarefas[i].descricao == null ? '' : data.osTarefas[i].descricao);
          doc.text(posX + 80, pos_materiais, '' + (data.osTarefas[i].estadoFlag == null ? '' : data.osTarefas[i].estadoFlag));
          doc.rect(posX + 110, pos_materiais, 5, 3);
          pos_materiais += 3;
        } else {
          for (var t = 0; t < total_linhas; t++) {
            doc.text(posX + 10, pos_materiais, '' + data.osTarefas[i].descricao == null ? '' : data.osTarefas[i].descricao.substring(indice_inicio, indice_fim));
            pos_materiais += 3;
            indice_inicio = indice_fim;
            indice_fim = indice_fim + 57
          } doc.text(posX + 80, pos_materiais - (total_linhas * 3), '' + (data.osTarefas[i].estadoFlag == null ? '' : data.osTarefas[i].estadoFlag));
          doc.rect(posX + 110, pos_materiais - (total_linhas * 3), 5, 3);
          pos_materiais += 3;
        }

      }



      var pos_materiais = pos_materiais + 33;

      if (pos_materiais + 10 >= pageHeight - 25) {
        doc.addPage();
        pos_materiais = 10;
      }

      /*doc.addPage();
      pos_cliente = 10;*/
      /*Dados dos Recursos a utilizar.*/
      doc.setFontSize(8);
      doc.setFontType('bold');
      doc.setTextColor(0)
      doc.setFillColor(208, 211, 212);
      var pos_rec = pos_materiais - 18;
      doc.rect(10, pos_rec, pageWidth - 20, 5);
      doc.text(posX + 10, (pos_rec) + 3.5, 'CONSUMIVEIS');

      doc.setFontType('bold');
      doc.setFontSize(8);

      doc.text(posX + 10, pos_rec + 9, 'CÓDIGO');
      doc.text(posX + 50, pos_rec + 9, 'DESIGNAÇÃO');
      doc.text(posX + 115, pos_rec + 9, 'QUANTIDADE EST');
      //doc.text(posX+120,pos_rec+9,'H');
      //doc.text(posX+125,pos_rec+9,'M (EST)');
      doc.text(posX + 170, pos_rec + 9, 'QUANTIDADE UTIL');
      doc.line(10, pos_rec + 11, pageWidth - 10, pos_rec + 11);
      for (var i = 0; i < data.ordemMaterial.length; i++) {
        doc.setFontType('normal');
        doc.setFontSize(7);
        var pos_item = pos_rec + 13;
        doc.text(posX + 10, pos_item, '' + (data.ordemMaterial[i].codigo_material == null ? '' : data.ordemMaterial[i].codigo_material));
        doc.text(posX + 50, pos_item, '' + (data.ordemMaterial[i].nome_material == null ? '' : data.ordemMaterial[i].nome_material));
        doc.text(posX + 115, pos_item, '' + (data.ordemMaterial[i].quantidade_prevista == null ? '' : data.ordemMaterial[i].quantidade_prevista));
        //doc.text(posX+120,pos_item,'' + (data.ordemMaterial[i].dif_horas_prevista == null ? '' : data.ordemMaterial[i].dif_horas_prevista));
        //doc.text(posX+125,pos_item,'' + (data.ordemMaterial[i].quantidade_prevista == null ? '' : data.ordemMaterial[i].quantidade_prevista));
        doc.text(posX + 170, pos_item, '' + (data.ordemMaterial[i].quantidade_utilizada == null ? '' : data.ordemMaterial[i].quantidade_utilizada));
        //doc.text(posX+175,pos_item,'' + (data.ordemMaterial[i].dif_horas_definida == null ? '' : data.ordemMaterial[i].dif_horas_definida));
        //doc.text(posX+180,pos_item,'' + (data.ordemMaterial[i].dif_minutos_definida == null ? '' : data.ordemMaterial[i].dif_minutos_definida));
        pos_rec += 3;
      }

      var pos_obs = pos_rec + 30;
      doc.setFontType('bold');
      doc.setFontSize(8);
      doc.setFillColor(208, 211, 212);
      doc.rect(10, pos_obs, pageWidth - 20, 5);
      doc.text(posX + 10, (pos_obs) + 3.5, 'RECURSOS HUMANOS');
      doc.setFontType('bold');
      doc.setFontSize(8);

      doc.text(posX + 10, pos_obs + 9, 'FUNÇÃO');
      doc.text(posX + 50, pos_obs + 9, 'COLABORADOR');
      doc.text(posX + 115, pos_obs + 9, 'D');
      doc.text(posX + 120, pos_obs + 9, 'H');
      doc.text(posX + 125, pos_obs + 9, 'M (EST)');
      doc.text(posX + 170, pos_obs + 9, 'D');
      doc.text(posX + 175, pos_obs + 9, 'H');
      doc.text(posX + 180, pos_obs + 9, 'M (UTL)');
      doc.line(10, pos_obs + 10, pageWidth - 10, pos_obs + 10);

      for (var i = 0; i < data.ordemRecursosHumanos.length; i++) {
        doc.setFontSize(7);
        doc.setFontType('normal');
        doc.text(posX + 10, pos_obs + 15, '' + (data.ordemRecursosHumanos[i].nome_funcao == null ? '' : data.ordemRecursosHumanos[i].nome_funcao));
        doc.text(posX + 50, pos_obs + 15, '' + (data.ordemRecursosHumanos[i].colaborador_nome == null ? '' : data.ordemRecursosHumanos[i].colaborador_nome));
        doc.text(posX + 115, pos_obs + 15, '' + (data.ordemRecursosHumanos[i].dif_dias_prevista == null ? '' : data.ordemRecursosHumanos[i].dif_dias_prevista));
        doc.text(posX + 120, pos_obs + 15, '' + (data.ordemRecursosHumanos[i].dif_horas_prevista == null ? '' : data.ordemRecursosHumanos[i].dif_horas_prevista));
        doc.text(posX + 125, pos_obs + 15, '' + (data.ordemRecursosHumanos[i].dif_minutos_prevista == null ? '' : data.ordemRecursosHumanos[i].dif_minutos_prevista));
        doc.text(posX + 170, pos_obs + 15, '' + (data.ordemRecursosHumanos[i].dif_dias == null ? '' : data.ordemRecursosHumanos[i].dif_dias));
        doc.text(posX + 175, pos_obs + 15, '' + (data.ordemRecursosHumanos[i].dif_horas == null ? '' : data.ordemRecursosHumanos[i].dif_horas));
        doc.text(posX + 180, pos_obs + 15, '' + (data.ordemRecursosHumanos[i].dif_minutos == null ? '' : data.ordemRecursosHumanos[i].dif_minutos));
        pos_obs += 3;
      }




      var pos_desc_interv = pos_obs + 25;
      doc.setFontSize(8);
      doc.setFontType('bold');
      doc.setFillColor(208, 211, 212);
      doc.rect(10, pos_desc_interv, pageWidth - 20, 5);
      doc.text(posX + 10, (pos_desc_interv) + 3.5, 'OBSERVAÇÕES GERAIS');
      doc.setFontType('normal');
      doc.setFontSize(7);
      var obs = doc.splitTextToSize(!data.agendamentoComentarioIntervencao ? '' : data.agendamentoComentarioIntervencao.descricao, 187)
      doc.text((obs == '' ? '' : obs), posX + 11, pos_desc_interv + 8);

      assinaturas(pageHeight -  40);

    }

    function assinaturas(posy) {
      console.log(posy, posX)
      doc.setFontSize(9);
      doc.line(10, posy + 2, 60, posy + 2);
      doc.text('Entregou', posX + 25, posy + 5.5);

      doc.text('Local Intervenção', posX + 77, posy + 5.5);
      doc.line(67, posy+ 2, 124, posy + 2);

      doc.text('Executou', posX + 146, posy + 5.5);
      doc.line(130, posy + 2, 190, posy + 2);




    }
    //var pos_materiais = logo_height + 180;
    /*RODAPÉ*/
    doc.setFontType('normal');
    doc.setFontStyle('italic');
    doc.setFontSize(6);
    var pageCount = doc.internal.getNumberOfPages();
    for (var i = 0; i <= pageCount; i++) {
      doc.line(10, pageHeight - 10, pageWidth - 10, pageHeight - 10);
      doc.text('-Processado por programa validado 4/AGT119', 105, doc.internal.pageSize.height - 7, null, null, 'center');
      doc.setPage(i);
      doc.text(10, pageHeight - 15, 'Versão: ' + original);
      doc.text('Página: ' + doc.internal.getCurrentPageInfo().pageNumber + '/' + pageCount, pageWidth - 10, pageHeight - 15, null, null, 'right');
      doc.text(pageWidth - 10, pageHeight - 12, '', 'right');
      doc.text("NIF: " + (data.empresa.taxRegistrationNumber == null ? '' : data.empresa.taxRegistrationNumber) + " - " + (data.empresa.companyName == null ? '' : data.empresa.companyName) + " / " + (data.empresa.addressDetail == null ? '' : data.empresa.addressDetail) + " / " + (data.empresa.telefone == null ? '' : data.empresa.telefone) + " / " + (data.empresa.email == null ? '' : data.empresa.email), 105, doc.internal.pageSize.height - 11, null, null, 'center');
    }



    if (report == 'imprimir') {
      doc.autoPrint();
      window.open(doc.output("bloburl")); //opens the data uri in new window
    } else if (report == 'buffer') {
      return new Buffer.from(doc.output('arraybuffer'))
    } else {
      doc.save('nome.pdf'); /* download the file immediately on loading */

    }





  }



  public imprimirOrdemServico(data, original: any = 'Original', report: string = 'imprimir') {
    console.log('inspect', data)
    // You'll need to make your image into a Data URL
    // Use http://dataurl.net/#dataurlmaker
    var imgData = data.empresa.logotipo
    var doc = new jsPDF('p');
    var posX = 1;
    var posY = 5;
    var logo_width = 35;
    var logo_height = 40;
    var pos_body = logo_height + 5;
    var pos_equipamento = 0;
    var pos_cliente = 0;
    var pos_agendamento = 0;

    var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
    var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

    doc.addImage(imgData, 'JPEG', 8, 14, 28, 24);
    doc.setFontSize(8);
    doc.setFontType('Arial');

    if (data.empresa.addressDetail.length <= 62) {
      doc.text(posX + logo_width + 5, 30, 'NIF: ');
      doc.text(posX + logo_width + 13, 30, '' + (data.empresa.taxRegistrationNumber == null ? '' : data.empresa.taxRegistrationNumber));
      doc.text(posX + logo_width + 5, 35, 'Email: ');
      doc.text(posX + logo_width + 16, 35, '' + (data.empresa.email == null ? '' : data.empresa.email));
      doc.text(posX + logo_width + 50, 30, 'Telefone : ');
      doc.text(posX + logo_width + 65, 30, '' + (data.empresa.telefone == null ? '' : data.empresa.telefone));
      doc.text(posX + logo_width + 50, 35, 'Website: ');
      doc.text(posX + logo_width + 65, 35, '' + (data.empresa.site == null ? '' : data.empresa.site));
    } else {
      doc.text(posX + logo_width + 5, 33, 'NIF: ');
      doc.text(posX + logo_width + 13, 33, '' + (data.empresa.taxRegistrationNumber == null ? '' : data.empresa.taxRegistrationNumber));
      doc.text(posX + logo_width + 5, 38, 'Email: ');
      doc.text(posX + logo_width + 16, 38, '' + (data.empresa.email == null ? '' : data.empresa.email));
      doc.text(posX + logo_width + 60, 33, 'Telefone : ');
      doc.text(posX + logo_width + 80, 33, '' + (data.empresa.telefone == null ? '' : data.empresa.telefone));
      doc.text(posX + logo_width + 60, 38, 'Website: ');
      doc.text(posX + logo_width + 80, 38, '' + (data.empresa.site == null ? '' : data.empresa.site));
    }
    doc.text(posX + logo_width + 5, 20, '' + (data.empresa.companyName == null ? '' : data.empresa.companyName));
    //lines = doc.splitTextToSize(endereco, 62)
    doc.text(posX + logo_width + 5, 25, '' + (data.empresa.addressDetail == null ? '' : data.empresa.addressDetail));
    var pos_rectangulo = posX + logo_width + 110;
    doc.rect(pos_rectangulo, 18, pageWidth / 3 - 17, 20)
    doc.setFontSize(8);
    doc.setFontType('bold');
    doc.text(pos_rectangulo + 5, 23, 'Nº ' + ((data.ordemTrabalho || {}).codigo == null ? '' : (data.ordemTrabalho || {}).codigo) + ((data.ordemTrabalho || {}).codigo_agendamento == null ? '' : '/' + (data.ordemTrabalho || {}).codigo_agendamento));
    doc.setFontType('Normal');
    doc.text(pos_rectangulo + 5, 28, 'Data:' + (data.ordemTrabalho || {}).data_inicio_previsao + ' ' + (data.ordemTrabalho || {}).hora_inicio_previsao);
    doc.text(pos_rectangulo + 5, 34, 'Prioridade:');
    doc.setFontType('bold');
    doc.text(pos_rectangulo + 18, 34, '' + ((data.ordemTrabalho || {}).prioridade_descricao == null ? '' : (data.ordemTrabalho || {}).prioridade_descricao));


    // CORPO DO RELATÓRIO ........
    doc.setFontSize(8);
    doc.setFillColor(208, 211, 212);
    doc.setFontType('bold');
    doc.rect(10, pos_body, pageWidth - 20, 5);
    logo_height = pos_body - 5;
    doc.text(posX + 10, logo_height + 8.5, 'ORDEM DE TRABALHO -');

    var corpo_ordem = logo_height + 14;

    if ((data.ordemTrabalho || {}).agendamento_flag == 'FECHADA') {
      doc.setFontType('normal');
      doc.setFontSize(7);
      doc.text(posX + 110, corpo_ordem + 13, '' + ((data.ordemTrabalho || {}).data_fim_execucao == null ? '' : (data.ordemTrabalho || {}).data_fim_execucao));
      doc.text(posX + 130, corpo_ordem + 13, '' + ((data.ordemTrabalho || {}).hora_fim_execucao == null ? '' : (data.ordemTrabalho || {}).hora_fim_execucao));
      doc.text(posX + 150, corpo_ordem + 13, '' + ((data.ordemTrabalho || {}).dif_dias_definida == null ? '' : (data.ordemTrabalho || {}).dif_dias_definida));
      doc.text(posX + 155, corpo_ordem + 13, '' + ((data.ordemTrabalho || {}).dif_horas_definida == null ? '' : (data.ordemTrabalho || {}).dif_horas_definida));
      doc.text(posX + 160, corpo_ordem + 13, '' + ((data.ordemTrabalho || {}).dif_minutos_definida == null ? '' : (data.ordemTrabalho || {}).dif_minutos_definida));
      doc.setTextColor(0, 0, 255);
      doc.setFontSize(8);
      doc.text(posX + 53, corpo_ordem - 5.5, 'EXECUÇÃO', null, 'center');
      doc.setTextColor(0, 0, 0);
      doc.setFontSize(7);
      doc.text(posX + 10, (corpo_ordem + 13), '' + ((data.ordemTrabalho || {}).data_inicio_previsao == null ? '' : (data.ordemTrabalho || {}).data_inicio_previsao));
      doc.text(posX + 25, (corpo_ordem + 13), '' + ((data.ordemTrabalho || {}).hora_inicio_previsao == null ? '' : (data.ordemTrabalho || {}).hora_inicio_previsao));
      doc.text(posX + 40, (corpo_ordem + 13), '' + ((data.ordemTrabalho || {}).dif_dias_prevista == null ? '' : (data.ordemTrabalho || {}).dif_dias_prevista));
      doc.text(posX + 45, (corpo_ordem + 13), '' + ((data.ordemTrabalho || {}).dif_horas_prevista == null ? '' : (data.ordemTrabalho || {}).dif_horas_prevista));
      doc.text(posX + 50, (corpo_ordem + 13), '' + ((data.ordemTrabalho || {}).dif_minutos_prevista == null ? '' : (data.ordemTrabalho || {}).dif_minutos_prevista));
    } else {

      /*Dados do agendamento*/
      doc.setTextColor(45, 124, 35);
      doc.setFontSize(8);
      doc.text(posX + 10, (corpo_ordem + 13), '' + ((data.ordemTrabalho || {}).data_inicio_previsao == null ? '' : (data.ordemTrabalho || {}).data_inicio_previsao));
      doc.text(posX + 25, (corpo_ordem + 13), '' + ((data.ordemTrabalho || {}).hora_inicio_previsao == null ? '' : (data.ordemTrabalho || {}).hora_inicio_previsao));
      doc.text(posX + 40, (corpo_ordem + 13), '' + ((data.ordemTrabalho || {}).dif_dias_prevista == null ? '' : (data.ordemTrabalho || {}).dif_dias_prevista));
      doc.text(posX + 45, (corpo_ordem + 13), '' + ((data.ordemTrabalho || {}).dif_horas_prevista == null ? '' : (data.ordemTrabalho || {}).dif_horas_prevista));
      doc.text(posX + 50, (corpo_ordem + 13), '' + ((data.ordemTrabalho || {}).dif_minutos_prevista == null ? '' : (data.ordemTrabalho || {}).dif_minutos_prevista));
      doc.text(posX + 62, corpo_ordem - 5.5, '' + ((data.ordemTrabalho || {}).agendamento_estado == null ? '' : (data.ordemTrabalho || {}).agendamento_estado.toUpperCase()), null, 'center');
      doc.setTextColor(0, 0, 0);
      doc.setFontSize(7);
    }



    /*Dados da OS.*/
    doc.setFontSize(7);
    doc.setFontType('bold');
    doc.text(posX + 10, corpo_ordem, 'TÍTULO OT');
    //doc.text(posX+70,corpo_ordem,'RESPONSÁVEL');
    doc.text(posX + 90, corpo_ordem, 'CLASSIFICAÇÃO');
    doc.text(posX + 150, corpo_ordem, 'TIPO OT');
    doc.text(posX + 90, corpo_ordem + 7, 'EXECUÇÃO');
    doc.setFontType('normal');
    doc.text(posX + 90, corpo_ordem + 10, 'DATA');//data_execução
    doc.text(posX + 110, corpo_ordem + 10, 'HORA');//hora_execução
    doc.text(posX + 130, corpo_ordem + 10, 'DURAÇÃO (D H M)');//duração_execução
    doc.setFontType('bold');
    doc.text(posX + 10, (corpo_ordem + 18), 'DESCRIÇÃO DA ORDEM DE TRABALHO');
    doc.setFontType('normal');

    var titulo_ot = (data.ordemTrabalho || {}).titulo == null ? '' : (data.ordemTrabalho || {}).titulo;
    var split_titulo_ot = doc.splitTextToSize(titulo_ot, 65);
    doc.setFontSize(6);
    doc.text(posX + 10, corpo_ordem + 2, split_titulo_ot);


    //doc.text(posX+10,corpo_ordem+3,'' + ((data.ordemTrabalho || {} ).titulo == null ? '' : (data.ordemTrabalho || {} ).titulo));
    /*PERIODO DE AGENDAMENTO*/
    doc.text(posX + 90, corpo_ordem + 2, '' + ((data.ordemTrabalho || {}).classificacao_descricao == null ? '' : (data.ordemTrabalho || {}).classificacao_descricao));

    var tipo_ot = (data.ordemTrabalho || {}).tipo_descricao == null ? '' : (data.ordemTrabalho || {}).tipo_descricao;
    var split_tipo_ot = doc.splitTextToSize(tipo_ot, 50);
    doc.text(posX + 130, corpo_ordem + 2, split_tipo_ot);


    //doc.text(posX+150,corpo_ordem+2, '' + ((data.ordemTrabalho || {} ).tipo_descricao == null ? '' : (data.ordemTrabalho || {} ).tipo_descricao));
    var splitText = (data.ordemTrabalho || {}).descricao ? doc.splitTextToSize((data.ordemTrabalho || {}).descricao, 190) : null
    doc.text(splitText == null ? '' : splitText, posX + 10, corpo_ordem + 21);



    /*Verificação do tamanho da descrição.*/

    if ((data.ordemTrabalho || {}).titulo && (data.ordemTrabalho || {}).titulo.length >= 448) {
      if (data.ordemEquipamento) {
        pos_equipamento = corpo_ordem + 37;
      } else {
        pos_cliente = corpo_ordem + 37;
      }

    } else {
      if (data.ordemEquipamento) {
        pos_equipamento = corpo_ordem + 29;
      } else {
        pos_cliente = corpo_ordem + 29;
      }
    }


    if (data.ordemEquipamento) {
      pos_agendamento = pos_equipamento + 24;
      doc.setFontSize(8);
      doc.setFontType('bold');
      doc.setFillColor(208, 211, 212);
      doc.rect(10, pos_equipamento, pageWidth - 20, 5);
      doc.text(posX + 10, pos_equipamento + 3.5, 'EQUIPAMENTO');
      doc.setFontSize(7);
      doc.setFontType('bold');
      doc.text(posX + 10, pos_equipamento + 9, 'Nº SÉRIE');
      doc.text(posX + 30, pos_equipamento + 9, 'CÓDIGO DE REGISTO');
      doc.text(posX + 60, pos_equipamento + 9, 'ABREVIATURA');
      doc.text(posX + 10, pos_equipamento + 16, 'DESIGNAÇÃO');
      doc.text(posX + 140, pos_equipamento + 9, 'ESTADO');
      doc.text(posX + 170, pos_equipamento + 9, 'TIPO DE AQUISIÇÃO');
      doc.text(posX + 10, pos_equipamento + 23, 'GRAU DE CRITICIDADE');
      doc.text(posX + 90, pos_equipamento + 9, 'ESTADO DE CONSERVAÇÃO');
      doc.text(posX + 90, pos_equipamento + 23, 'MODELO');
      doc.text(posX + 135, pos_equipamento + 23, 'LATITUDE');
      doc.text(posX + 170, pos_equipamento + 23, 'LONGITUDE');
      /*Dados do Equipamento*/
      doc.setFontSize(7);
      doc.setFontType('normal');
      doc.text(posX + 10, pos_equipamento + 12, '' + (data.ordemEquipamento.numero_serie == null ? '' : data.ordemEquipamento.numero_serie));
      doc.text(posX + 30, pos_equipamento + 12, '' + (data.ordemEquipamento.codigo_registo == null ? '' : data.ordemEquipamento.codigo_registo));
      doc.text(posX + 60, pos_equipamento + 12, '' + (data.ordemEquipamento.abreviatura == null ? '' : data.ordemEquipamento.abreviatura));
      var designacao_equip = doc.splitTextToSize(data.ordemEquipamento.designacao_equipamento, 190)
      doc.text(designacao_equip == null ? '' : designacao_equip, posX + 10, pos_equipamento + 19);
      doc.text(posX + 140, pos_equipamento + 12, '' + (data.ordemEquipamento.estado == null ? '' : data.ordemEquipamento.estado));
      doc.text(posX + 170, pos_equipamento + 12, '' + (data.ordemEquipamento.tipo_aquisicao == null ? '' : data.ordemEquipamento.tipo_aquisicao));// tipo_aquisição
      doc.text(posX + 10, pos_equipamento + 26, '' + (data.ordemEquipamento.grau_criticidade == null ? '' : data.ordemEquipamento.grau_criticidade));//grau_criticidade
      doc.text(posX + 90, pos_equipamento + 12, '' + (data.ordemEquipamento.estado_conservacao == null ? '' : data.ordemEquipamento.estado_conservacao));//estado_conservação
      doc.text(posX + 90, pos_equipamento + 26, '' + (data.ordemEquipamento.modelo == null ? '' : data.ordemEquipamento.modelo));//modelo
      doc.text(posX + 135, pos_equipamento + 26, '' + (data.ordemEquipamento.latitude == null ? '' : data.ordemEquipamento.latitude));//latitude
      doc.text(posX + 170, pos_equipamento + 26, '' + (data.ordemEquipamento.longitude == null ? '' : data.ordemEquipamento.longitude));//longitude
      /*var splitText = doc.splitTextToSize(obsevacao_equipamento, 190)
      doc.text(splitText, posX+10,pos_equipamento+64);
      if(obsevacao_equipamento.length >= 435) {
          pos_cliente = pos_equipamento+80;
      } else {
          pos_cliente = pos_equipamento+70;
      }*/




    } else if (data.ordemCliente) {
      let predio_nome = ((data.ordemCliente.cil != null) ? '[' + data.ordemCliente.cil + '] ' : '[0000-000-000] ') + ((data.ordemCliente.is_predio) ? 'Prédio ' + data.ordemCliente.predio_nome + ', ' + (data.ordemCliente.predio_andar ? data.ordemCliente.predio_andar + ' º Andar - ' : '') + 'Porta ' : 'Residência ') + data.ordemCliente.moradia_numero;
      pos_agendamento = pos_cliente + 24;
      //pos_cliente = pos_cliente+30;
      doc.setFontSize(8);
      doc.setFontType('bold');
      doc.setFillColor(208, 211, 212);
      doc.rect(10, pos_cliente, pageWidth-20, 5);
      doc.text(posX+10,pos_cliente+3.5,'CLIENTE');
      doc.setFontSize(7);
      doc.setFontType('bold');
      doc.text(posX+10,(pos_cliente+9),'Nº CLIENTE');
      doc.text(posX+30,(pos_cliente+9),'CONTA');
      doc.text(posX+50,(pos_cliente+9),'CONTRATO');
      doc.text(posX+70,((pos_cliente+9)),'NOME');
      doc.text(posX+135,((pos_cliente+9)),'MUNICIPIO');
      doc.text(posX+10,(pos_cliente+16),'DIST. URBANO/LOCALIDADE');
      doc.text(posX+70,(pos_cliente+16),'BAIRRO/ZONA');
      doc.text(posX+50,(pos_cliente+23),'QUARTEIRÃO');
      doc.text(posX+70,((pos_cliente+23)),'RUA');
      doc.text(posX+135,((pos_cliente+16)),'MORADA');
      doc.text(posX+50,((pos_cliente+30)),'CIL');
      doc.text(posX+10,(pos_cliente+23),'CONTADOR');
      doc.text(posX+135,(pos_cliente+30),'LATITUDE');
      doc.text(posX+135,(pos_cliente+23),'LONGITUDE');
      doc.setFontType('normal');
      doc.text(posX+10,(pos_cliente+12), '' + (data.ordemCliente.cliente_numero == null ? '' : data.ordemCliente.cliente_numero));
      doc.text(posX+30,(pos_cliente+12), '' + (data.ordemCliente.numero_conta == null ? '' : data.ordemCliente.numero_conta));
      doc.text(posX+50,(pos_cliente+12),'' + (data.ordemCliente.contrato_numero == null ? '' : data.ordemCliente.contrato_numero));
      doc.text(posX+70,(pos_cliente+12),'' + (data.ordemCliente.cliente_nome == null ? '' : data.ordemCliente.cliente_nome));
      doc.text(posX+135,(pos_cliente+12),'' + (data.ordemCliente.municipio == null ? '' : data.ordemCliente.municipio));
      doc.text(posX+10,(pos_cliente+19),'' + (data.ordemCliente.distrito == null ? '' : data.ordemCliente.distrito));
      doc.text(posX+70,(pos_cliente+19),'' + (data.ordemCliente.bairro == null ? '' : data.ordemCliente.bairro));
      doc.text(posX+50,(pos_cliente+26),'' + (data.ordemCliente.quarteirao == null ? '' : data.ordemCliente.quarteirao));
      doc.text(posX+70,(pos_cliente+26),'' + (data.ordemCliente.rua == null ? '' : data.ordemCliente.rua));
      doc.text(posX+135,(pos_cliente+19),'' + (predio_nome == null ? '' : predio_nome));
      doc.text(posX+10,(pos_cliente+26),'' + (data.ordemCliente.contador == null ? '' : data.ordemCliente.contador));//contador
      doc.text(posX+50,(pos_cliente+33),'' + (data.ordemCliente.cil == null ? '' : data.ordemCliente.cil));//CIL
      doc.text(posX+135,(pos_cliente+33),'' + (data.ordemCliente.latitude == null ? '' : data.ordemCliente.latitude));//Latitude
      doc.text(posX+135,(pos_cliente+26),'' + (data.ordemCliente.longitude == null ? '' : data.ordemCliente.longitude));//Longitude
      //doc.addPage();
      var pos_cliente_aux = pos_cliente+33;
      pos_cliente = pos_cliente_aux ;
      pos_agendamento += 20

    }else if (data.ordemByQuarteirao) {
      let predio_nome = ((data.ordemByQuarteirao.cil != null) ? '[' + data.ordemByQuarteirao.cil + '] ' : '[0000-000-000] ') + ((data.ordemByQuarteirao.is_predio) ? 'Prédio ' + data.ordemByQuarteirao.predio_nome + ', ' + (data.ordemByQuarteirao.predio_andar ? data.ordemByQuarteirao.predio_andar + ' º Andar - ' : '') + 'Porta ' : 'Residência ') + data.ordemByQuarteirao.moradia_numero;
      pos_agendamento = pos_cliente + 24;
      //pos_cliente = pos_cliente+30;
      doc.setFontSize(8);
      doc.setFontType('bold');
      doc.setFillColor(208, 211, 212);
      doc.rect(10, pos_cliente, pageWidth-20, 5);
      doc.text(posX+10,pos_cliente+3.5,'LOCAL DE INSTALAÇÃO');
      doc.setFontSize(7);
      doc.setFontType('bold');
      doc.text(posX+10,(pos_cliente+9),'DIST. URBANO/LOCALIDADE');
      doc.text(posX+50,(pos_cliente+9),'CONTRATO');
      doc.text(posX+70,((pos_cliente+9)),'BAIRRO/ZONA');
      doc.text(posX+135,((pos_cliente+9)),'MUNICIPIO');
      doc.text(posX+10,(pos_cliente+16),'CONTADOR');
      doc.text(posX+70,(pos_cliente+16),'RUA');
      doc.text(posX+50,(pos_cliente+16),'QUARTEIRÃO');
      doc.text(posX+70,((pos_cliente+23)),'CIL');
      doc.text(posX+135,((pos_cliente+16)),'MORADA');
      doc.text(posX+135,(pos_cliente+30),'LATITUDE');
      doc.text(posX+135,(pos_cliente+23),'LONGITUDE');
      doc.setFontType('normal');
      doc.text(posX+10,(pos_cliente+12), '' + (data.ordemByQuarteirao.distrito == null ? '' : data.ordemByQuarteirao.distrito));
      doc.text(posX+50,(pos_cliente+12),'' + (data.ordemByQuarteirao.contrato_id == null ? '' : data.ordemByQuarteirao.contrato_id));
      doc.text(posX+70,(pos_cliente+12),'' + (data.ordemByQuarteirao.bairro == null ? '' : data.ordemByQuarteirao.bairro));
      doc.text(posX+135,(pos_cliente+12),'' + (data.ordemByQuarteirao.municipio == null ? '' : data.ordemByQuarteirao.municipio));
      doc.text(posX+10,(pos_cliente+19),'' + (data.ordemByQuarteirao.contador == null ? '' : data.ordemByQuarteirao.contador));
      doc.text(posX+70,(pos_cliente+19),'' + (data.ordemByQuarteirao.rua == null ? '' : data.ordemByQuarteirao.rua));
      doc.text(posX+50,(pos_cliente+19),'' + (data.ordemByQuarteirao.quarteirao == null ? '' : data.ordemByQuarteirao.quarteirao));
      doc.text(posX+70,(pos_cliente+26),'' + (data.ordemByQuarteirao.cil == null ? '' : data.ordemByQuarteirao.cil));
      doc.text(posX+135,(pos_cliente+19),'' + (data.ordemByQuarteirao.cil == null ? '' : predio_nome));
      doc.text(posX+135,(pos_cliente+33),'' + (data.ordemByQuarteirao.latitude == null ? '' : data.ordemByQuarteirao.latitude));//Latitude
      doc.text(posX+135,(pos_cliente+26),'' + (data.ordemByQuarteirao.longitude == null ? '' : data.ordemByQuarteirao.longitude));//Longitude
      //doc.addPage();
      var pos_cliente_aux = pos_cliente+33;
      pos_cliente = pos_cliente_aux ;
      pos_agendamento += 20

    }
    else {
      pos_agendamento = pos_cliente + 12;
    }
    console.log('data.ordemCliente ===',data.ordemCliente)
    if (data.agendamentos) {

      doc.setFontSize(8);
      doc.setFontType('bold');
      doc.setTextColor(0)
      doc.setFillColor(208, 211, 212);
      doc.rect(10, pos_agendamento + 6, pageWidth - 20, 5);
      doc.text(posX + 10, pos_agendamento + 10, 'AGENDAMENTOS');
      doc.setFontType('bold');
      doc.setFontSize(8);
      //doc.text(posX+10,(pos_agendamento+16),'CÓDIGO');
      doc.text(posX + 10, pos_agendamento + 16, 'CODIGO');
      doc.text(posX + 70, pos_agendamento + 16, 'ESTADO');
      doc.text(posX + 105, pos_agendamento + 15, 'RESPONSAVEL');
      //doc.text(posX+170,pos_agendamento+16,'QTD (UTL)');
      doc.line(10, pos_agendamento + 17, pageWidth - 10, pos_agendamento + 17);
      var pos_materiais = pos_agendamento + 20;


      for (var i = 0; i < data.agendamentos.length; i++) {
        doc.setFontType('normal');
        doc.setFontSize(7);
        //doc.text(posX+10,pos_materiais,'' + (data.osTarefas[i].codigo_material == null ? '' : data.osTarefas[i].codigo_material));
        doc.text(posX + 10, pos_materiais, '' + data.agendamentos[i].codigo == null ? '' : data.agendamentos[i].codigo);
        doc.text(posX + 70, pos_materiais, '' + (data.agendamentos[i].estadoFlag == null ? '' : data.agendamentos[i].estadoFlag));
        doc.text(posX + 105, pos_materiais, '' + (data.agendamentos[i].responsavel == null ? '' : data.agendamentos[i].responsavel));
        //doc.text(posX+155,pos_materiais,'');

        pos_materiais += 7;
      }
    }




    assinaturas(pageHeight - 40, (data.agendamentos[0] || {}).is_preventiva);



    function assinaturas(posy, is_preventiva) {
      console.log(posX, posY)
      doc.setFontSize(9);
      doc.line(10, posy + 2, 60, posy + 2);
      doc.text('Entregou', posX + 25, posy + 5.5);

      doc.text('Executou', posX + 146, posy + 5.5);
      doc.line(130, posy + 2, 190, posy + 2);

      if(is_preventiva){
         doc.text('Local Intervenção', posX + 77, posy + 5.5);
         doc.line(67, posy+ 2, 124, posy + 2);
       }
    }
    //var pos_materiais = logo_height + 180;
    /*RODAPÉ*/
    doc.setFontType('normal');
    doc.setFontStyle('italic');
    doc.setFontSize(6);
    var pageCount = doc.internal.getNumberOfPages();
    for (var i = 0; i <= pageCount; i++) {
      doc.line(10, pageHeight - 10, pageWidth - 10, pageHeight - 10);
      doc.text('-Processado por programa validado 4/AGT119', 105, doc.internal.pageSize.height - 7, null, null, 'center');
      doc.setPage(i);
      doc.text(10, pageHeight - 15, 'Versão: ' + original);
      doc.text('Página: ' + doc.internal.getCurrentPageInfo().pageNumber + '/' + pageCount, pageWidth - 10, pageHeight - 15, null, null, 'right');
      doc.text(pageWidth - 10, pageHeight - 12, '', 'right');
      doc.text("NIF: " + (data.empresa.taxRegistrationNumber == null ? '' : data.empresa.taxRegistrationNumber) + " - " + (data.empresa.companyName == null ? '' : data.empresa.companyName) + " / " + (data.empresa.addressDetail == null ? '' : data.empresa.addressDetail) + " / " + (data.empresa.telefone == null ? '' : data.empresa.telefone) + " / " + (data.empresa.email == null ? '' : data.empresa.email), 105, doc.internal.pageSize.height - 11, null, null, 'center');
    }

    if (report == 'imprimir') {
      doc.autoPrint();
      window.open(doc.output("bloburl")); //opens the data uri in new window
    } else if (report == 'buffer') {
      return new Buffer.from(doc.output('arraybuffer'))
    } else {
      doc.save('nome.pdf'); /* download the file immediately on loading */

    }

  }


  public imprimirAvisoCorte(data, original: any = 'Original', report: string = 'imprimir') {

    console.log('data => ', data)
    // You'll need to make your image into a Data URL
    // Use http://dataurl.net/#dataurlmaker
    var imgData = data.empresa.logotipo
    var doc = new jsPDF('p');
    var posX = 1;
    var posY = 5;
    var logo_width = 35;
    var logo_height = 40;
    var pos_body = logo_height + 5;
    var pos_equipamento = 0;
    var pos_cliente = 0;
    var pos_agendamento = 0;
    var date = new Date();
    var today = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()
    console.log(today)
    var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
    var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
    const meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

    doc.addImage(imgData, 'JPEG', 8, 14, 28, 24);
    doc.setFontSize(8);
    doc.setFontType('Arial');

    if (data.empresa.addressDetail.length <= 62) {
      doc.text(posX + logo_width + 5, 30, 'NIF: ');
      doc.text(posX + logo_width + 13, 30, '' + (data.empresa.taxRegistrationNumber == null ? '' : data.empresa.taxRegistrationNumber));
      doc.text(posX + logo_width + 5, 35, 'Email: ');
      doc.text(posX + logo_width + 16, 35, '' + (data.empresa.email == null ? '' : data.empresa.email));
      doc.text(posX + logo_width + 50, 30, 'Telefone : ');
      doc.text(posX + logo_width + 65, 30, '' + (data.empresa.telefone == null ? '' : data.empresa.telefone));
      doc.text(posX + logo_width + 50, 35, 'Website: ');
      doc.text(posX + logo_width + 65, 35, '' + (data.empresa.site == null ? '' : data.empresa.site));
    } else {
      doc.text(posX + logo_width + 5, 33, 'NIF: ');
      doc.text(posX + logo_width + 13, 33, '' + (data.empresa.taxRegistrationNumber == null ? '' : data.empresa.taxRegistrationNumber));
      doc.text(posX + logo_width + 5, 38, 'Email: ');
      doc.text(posX + logo_width + 16, 38, '' + (data.empresa.email == null ? '' : data.empresa.email));
      doc.text(posX + logo_width + 60, 33, 'Telefone : ');
      doc.text(posX + logo_width + 80, 33, '' + (data.empresa.telefone == null ? '' : data.empresa.telefone));
      doc.text(posX + logo_width + 60, 38, 'Website: ');
      doc.text(posX + logo_width + 80, 38, '' + (data.empresa.site == null ? '' : data.empresa.site));
    }
    doc.text(posX + logo_width + 5, 20, '' + (data.empresa.companyName == null ? '' : data.empresa.companyName));
    //lines = doc.splitTextToSize(endereco, 62)
    doc.text(posX + logo_width + 5, 25, '' + (data.empresa.addressDetail == null ? '' : data.empresa.addressDetail));
    var pos_rectangulo = posX + logo_width + 110;
    doc.rect(pos_rectangulo, 18, pageWidth / 3 - 17, 20)
    doc.setFontSize(8);
    doc.setFontType('bold');


    doc.text(pos_rectangulo + 5, 23, 'Contrato Nº ' + ((data || {}).contrato_id == null ? '' : (data || {}).contrato_id));
    doc.setFontType('Normal');
    doc.text(pos_rectangulo + 5, 28, 'CIL:' + (data || {}).cil);
    doc.text(pos_rectangulo + 5, 34, 'Data:' + today);
    doc.setFontType('bold');



    // CORPO DO RELATÓRIO ........
    doc.setFontSize(8);
    doc.setFillColor(208, 211, 212);
    doc.setFontType('bold');
    //doc.rect(10, pos_body, pageWidth-20, 5);
    logo_height = pos_body - 5;
    //doc.setTextColor(65, 105, 225);
    doc.setTextColor(0, 0, 0);
    //doc.setDrawColor(65, 105, 225);
    doc.setDrawColor(255, 0, 0);
    doc.setFontSize(14);
    doc.text(posX + 60, logo_height + 20, 'AVISO PRÉVIO DE CORTE DE ÁGUA');
    //doc.line(55, 63, 155, 63);

    doc.setFontSize(12);
    doc.setFontType('normal');

    var corpo_ordem = logo_height + 18;

    var corpoDoc = 'Sr(a)' + data.cliente_nome.toUpperCase() + ' portador do BI/NIF nº ' + (data.numero_identificacao == null ? ' ' : data.numero_identificacao) + ' , informamos que possui uma divida de ' + data.divida + ' de facturas vencidas.'
    doc.text(posX + 10, corpo_ordem + 20, corpoDoc, { maxWidth: 196, align: "justify", lineHeightFactor: 1.5 });

    //doc.text(corpoDoc, margemXPadrao, margemY, { maxWidth: 176, align: "justify" })

    //doc.text(posX+10,corpo_ordem+30,' de )

    var corpoDoc2 = 'Evite interrupção comercial com a nossa empresa , efectue pagamento imediato até ' + data.dias_aviso_corte + ' dias após receber esta carta.'

    doc.text(posX + 10, corpo_ordem + 35, corpoDoc2, { maxWidth: 196, align: "justify", lineHeightFactor: 1.5 })

    doc.text(posX + 10, corpo_ordem + 45, 'Em caso de dúvidas  ,entre em contacto com a nossa área de atendimento e suporte ao cliente.')

    doc.text(posX + 10, corpo_ordem + 55, 'Atenciosamente,')

    doc.text(posX + 10, corpo_ordem + 65, data.empresa.municipio == null ? '' : data.empresa.municipio + ' , aos ' + date.getDate() + ' de ' + meses[date.getMonth()] + ' de ' + date.getFullYear())

    assinaturas(pageHeight - 25);


    function assinaturas(posy) {
      doc.setFontSize(9);
      doc.setDrawColor(0, 0, 0);
      doc.line(80, posy + 2, 140, posy + 2);

      doc.text('Dept Comercial', posX + 95, posy + 5.5);
      /*
      doc.text('Executou',posX+150, posy+5.5);
      doc.line(130, posy+2, 190, posy+2);
      */
    }


    /*








    //var pos_materiais = logo_height + 180;/*
    /*RODAPÉ*/
    doc.setFontType('normal');
    doc.setFontStyle('italic');
    doc.setFontSize(6);
    var pageCount = doc.internal.getNumberOfPages();
    for (var i = 0; i <= pageCount; i++) {
      doc.line(10, pageHeight - 10, pageWidth - 10, pageHeight - 10);
      doc.text('-Processado por programa validado 4/AGT119', 105, doc.internal.pageSize.height - 7, null, null, 'center');
      doc.setPage(i);
      doc.text(10, pageHeight - 15, 'Versão: ' + original);
      doc.text('Página: ' + doc.internal.getCurrentPageInfo().pageNumber + '/' + pageCount, pageWidth - 10, pageHeight - 15, null, null, 'right');
      doc.text(pageWidth - 10, pageHeight - 12, '', 'right');
      doc.text("NIF: " + (data.empresa.taxRegistrationNumber == null ? '' : data.empresa.taxRegistrationNumber) + " - " + (data.empresa.companyName == null ? '' : data.empresa.companyName) + " / " + (data.empresa.addressDetail == null ? '' : data.empresa.addressDetail) + " / " + (data.empresa.telefone == null ? '' : data.empresa.telefone) + " / " + (data.empresa.email == null ? '' : data.empresa.email), 105, doc.internal.pageSize.height - 11, null, null, 'center');
    }

    if (report == 'imprimir') {
      doc.autoPrint();
      window.open(doc.output("bloburl")); //opens the data uri in new window
    } else if (report == 'buffer') {
      return new Buffer.from(doc.output('arraybuffer'))
    } else {
      doc.save('nome.pdf'); /* download the file immediately on loading */

    }

  }




  public imprimirListaTrabalho(data, item, original: any = '2ª Via', report: string = 'imprimir') {
    var imgData = data.empresa.logotipo
    var doc = new jsPDF('p');
    doc.setFont('Calibri');
    var posX = 1;
    var posY = 5;
    var logo_width = 30;
    var logo_height = 40;

    var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
    var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

    doc.addImage(imgData, 'JPEG', 8, 14, 28, 24);
    doc.setFontSize(10);
    doc.setFontType('')
    doc.text(posX + logo_width + 5, 20, '' + (data.empresa.companyName == null ? '' : data.empresa.companyName));
    doc.text(posX + logo_width + 5, 26, '' + (data.empresa.addressDetail == null ? '' : data.empresa.addressDetail))
    doc.text(posX + logo_width + 5, 32, 'NIF: ');
    doc.text(posX + logo_width + 13, 32, '' + (data.empresa.taxRegistrationNumber == null ? '' : data.empresa.taxRegistrationNumber));
    doc.text(posX + logo_width + 5, 37, 'Email: ');
    doc.text(posX + logo_width + 15, 37, '' + (data.empresa.email == null ? '' : data.empresa.email));
    doc.text(posX + logo_width + 50, 32, 'Telefone : ');
    doc.text(posX + logo_width + 65, 32, '' + (data.empresa.telefone == null ? '' : data.empresa.telefone));
    doc.text(posX + logo_width + 50, 37, 'Website: ');
    doc.text(posX + logo_width + 65, 37, '' + (data.empresa.site == null ? '' : data.empresa.site));

    doc.setFontSize(10);
    doc.rect(140, 18, 60, 7);
    doc.rect(140, 25, 60, 20);
    doc.text('PARÂMETROS', 168, 23, null, null, 'center');
    doc.text('Data :', 142, 30, null, null);
    doc.text('Responsável :', 142, 34, null, null);
    //doc.text('Total O.T.:', 142, 38, null, null);
    //doc.text('O.S. :', 142, 90, null, null);
    doc.text('' + item.inicio_previsao, pageWidth - 15, 30, null, null, 'right');
    //doc.text(''+item.colaboradorNome, pageWidth - 12, 34, null, null, 'right');

    var text_resp = doc.splitTextToSize('' + item.colaboradorNome == null ? '' : item.colaboradorNome, 32);

    if (item.colaboradorNome.length >= 20) {
      doc.text(pageWidth - 15, 34, text_resp, 'right');
      doc.text('Total O.T.:', 142, 42, null, null);
      doc.text('' + item.total, pageWidth - 15, 42, null, null, 'right');
    } else {
      doc.text(pageWidth - 15, 34, text_resp, 'right');
      doc.text('Total O.T.:', 142, 38, null, null);
      doc.text('' + item.total, pageWidth - 15, 38, null, null, 'right');
    }
    //doc.text(''+item.total, pageWidth - 12, 38, null, null, 'right');
    //doc.text('11213', pageWidth-20, 90, null, null,'right');

    /*TABELA*/
    doc.setFontSize(10);
    doc.setFontType('bold');
    doc.setTextColor(0)
    doc.setFillColor(208, 211, 212);
    doc.rect(10, 60, pageWidth - 20, 7, 'F');
    doc.text(15, 65, 'LISTA OT´S', null, null);

    doc.setFontSize(9);
    doc.setFontType('bold');
    doc.text(20, 72, 'DATA', null, null, 'center');
    doc.text(50, 72, 'CLASSIFICAÇÃO OS', null, null, 'center');
    doc.text(80, 72, 'TIPO OT', null, null, 'center');
    doc.text(110, 72, 'CÓDIGO OT', null, null, 'center');
    doc.text(145, 72, 'TÍTULO OT', null, null, 'center');
    doc.text(180, 72, 'PRIORIDADE', null, null, 'center');
    doc.setDrawColor(94, 94, 94);
    doc.line(10, 74, pageWidth - 10, 74);
    //doc.text(145,105,'CLIENTE',null,null,'center');
    //doc.text(180,105,'EQUIPAMENTO',null,null,'center');

    var pos_dados = 68;
    function createTable(item_recursos) {
      doc.setTextColor(1);
      doc.setFontSize(8);
      doc.setFontType('normal');
      var line_tipo = doc.splitTextToSize('' + (item_recursos.tipoDescricao == null ? '' : item_recursos.tipoDescricao), 32)
      var line_titulo = doc.splitTextToSize('' + (item_recursos.osTitulo == null ? '' : item_recursos.osTitulo), 32)
      doc.text(20, pos_dados + 10, '' + (item_recursos.os_data_previsao == null ? '' : item_recursos.os_data_previsao), null, null, 'center');
      doc.text(50, pos_dados + 10, '' + (item_recursos.classificacaoDescricao == null ? '' : item_recursos.classificacaoDescricao), null, null, 'center');
      doc.text(80, pos_dados + 10, line_tipo, null, null, 'center');
      doc.text(110, pos_dados + 10, '' + (item_recursos.osCodigo == null ? '' : item_recursos.osCodigo), null, null, 'center');
      doc.text(145, pos_dados + 10, line_titulo, null, null, 'center');
      doc.text(180, pos_dados + 10, '' + (item_recursos.prioridadeDescricao == null ? '' : item_recursos.prioridadeDescricao), null, null, 'center');
      if (item_recursos.tipoDescricao.length >= 30) {
        pos_dados += 8;
      } else {
        pos_dados += 7;
      }
    }
    for (var i = 0; i < (data.ordemTrabalho || {}).length; i++) {
      createTable((data.ordemTrabalho || {})[i]);
      if (pos_dados + 10 >= pageHeight - 25) {
        doc.addPage();
        pos_dados = 10;
      }
    }
    var pos_consumiveis = pos_dados + 10;
    doc.setFontSize(10);
    doc.setFontType('bold');
    doc.setTextColor(0)
    doc.setFillColor(208, 211, 212);
    doc.rect(10, pos_consumiveis, pageWidth - 20, 7, 'F');
    doc.text(15, pos_consumiveis + 5, 'LISTA DE CONSUMIVEIS', null, null);
    doc.setFontSize(9);
    doc.text(25, pos_consumiveis + 12, 'CATEGORIA', null, null, 'center');
    doc.text(90, pos_consumiveis + 12, 'MATERIAL', null, null, 'center');
    doc.text(160, pos_consumiveis + 12, 'UN', null, null, 'center');
    doc.text(180, pos_consumiveis + 12, 'QTD', null, null, 'center');
    doc.setDrawColor(94, 94, 94);
    doc.line(10, pos_consumiveis + 14, pageWidth - 10, pos_consumiveis + 14);
    function listConsumiveis(item_consumiveis) {
      doc.setTextColor(1);
      doc.setFontSize(9);
      doc.setFontType('normal');
      doc.text(25, pos_consumiveis + 19, '' + (item_consumiveis.categoria == null ? '' : item_consumiveis.categoria), null, null, 'center');
      doc.text(90, pos_consumiveis + 19, '' + (item_consumiveis.nome_material == null ? '' : item_consumiveis.nome_material), null, null, 'center');
      doc.text(160, pos_consumiveis + 19, '' + (item_consumiveis.unidade == null ? '' : item_consumiveis.unidade), null, null, 'center');
      doc.text(180, pos_consumiveis + 19, '' + item_consumiveis.total, null, null, 'center');
      pos_consumiveis += 5;
    }

    for (var c = 0; c < data.ordemMaterial.length; c++) {
      listConsumiveis(data.ordemMaterial[c]);
      if (pos_consumiveis + 10 >= pageHeight - 25) {
        doc.addPage();
        pos_consumiveis = 10;
      }
    }
    var pos_rh = pos_consumiveis + 20;
    doc.setFontSize(10);
    doc.setFontType('bold');
    doc.setTextColor(0)
    doc.setFillColor(208, 211, 212);
    doc.rect(10, pos_rh, pageWidth - 20, 7, 'F');
    doc.text(15, pos_rh + 5, 'LISTA RH', null, null);
    doc.setFontSize(9);
    doc.text(25, pos_rh + 12, 'DEPARTAMENTO', null, null, 'center');
    doc.text(90, pos_rh + 12, 'FUNÇÃO', null, null, 'center');
    doc.text(160, pos_rh + 12, 'COLABORADOR', null, null, 'center');
    doc.setDrawColor(94, 94, 94);
    doc.line(10, pos_rh + 14, pageWidth - 10, pos_rh + 14);
    function listRH(item_rh) {
      doc.setTextColor(1);
      doc.setFontSize(9);
      doc.setFontType('normal');
      doc.text(25, pos_rh + 19, '' + (item_rh.nome_departamento == null ? '' : item_rh.nome_departamento), null, null, 'center');
      doc.text(90, pos_rh + 19, '' + (item_rh.nome_funcao == null ? '' : item_rh.nome_funcao), null, null, 'center');
      doc.text(160, pos_rh + 19, '' + (item_rh.colaborador_nome == null ? '' : item_rh.colaborador_nome), null, null, 'center');
      pos_rh += 5;
    }

    for (var r = 0; r < data.ordemRecursosHumanos.length; r++) {
      listRH(data.ordemRecursosHumanos[r]);
      if (pos_rh + 10 >= pageHeight - 35) {
        doc.addPage();
        pos_rh = 10;
      }
    }
    var pageCount = doc.internal.getNumberOfPages();
    for (var i = 0; i <= pageCount; i++) {
      doc.setFontStyle('italic');
      doc.setTextColor(0);
      doc.setFontSize(7);
      doc.text('Processado por programa validado 4/AGT119', 105, doc.internal.pageSize.height - 10, null, null, 'center');
      doc.setFontStyle('italic');
      doc.setTextColor(0);
      doc.setFontSize(7);
      doc.setPage(i);
      doc.text(10, pageHeight - 19, 'Versão: ' + original);
      doc.setDrawColor(0, 0, 0);
      doc.line(10, pageHeight - 17, pageWidth - 10, pageHeight - 17);
      doc.text('Página: ' + doc.internal.getCurrentPageInfo().pageNumber + '/' + pageCount, pageWidth - 10, pageHeight - 19, null, null, 'right');
      doc.text(pageWidth - 10, pageHeight - 19, '', 'right');
      doc.setFontSize(7);
      doc.text("NIF: " + (data.empresa.taxRegistrationNumber == null ? '' : data.empresa.taxRegistrationNumber) + " - " + (data.empresa.companyName == null ? '' : data.empresa.companyName) + " / " + (data.empresa.addressDetail == null ? '' : data.empresa.addressDetail) + " / " + (data.empresa.telefone == null ? '' : data.empresa.telefone) + " / " + (data.empresa.email == null ? '' : data.empresa.email), 105, doc.internal.pageSize.height - 14, null, null, 'center');

    }



    if (report == 'imprimir') {
      doc.autoPrint();
      window.open(doc.output("bloburl")); //opens the data uri in new window
    } else {
      doc.save('nome.pdf'); /* download the file immediately on loading */
    }


  }

  public numberFormat(number) {
    return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(number).replace('€', '').trim();
  }

}
