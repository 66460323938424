
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { GeneroService } from '../../generos/genero.service';
import { GestorContaService } from '../../gestores-contas/gestor-conta.service';
import { DireccaoService } from 'src/app/components/direccao/direccao.service';
import { TipoIdentificacaoService } from '../../tipo-de-identificacao/tipo-de-identificacao.service';

@Component({
  selector: 'createOrEditClient',
  templateUrl: './create-or-edit-client.component.html',
  styleUrls: ['./create-or-edit-client.component.css']
})

export class CreateOrEditClientComponent implements OnInit, OnChanges {

    @Input() modalClienteCreateOrEdit: string = "modalClienteCreateOrEdit";
    @Input() title: string = "Registar Cliente";
    @Input() cliente: any;

    submitted = false;
    loadingModal: boolean
    @Input() simpleFormCliente: FormGroup;
    @Input() selectForms: any;
    @Output() private loadListClient = new EventEmitter<any>();

    generos: any = []
    direccoes: any = []
    tipos_identidades: any = []
    gestores: any = []
    default_tipo_identidade: any = {}

    is_required: boolean

    constructor(
      private http: HttpService,
      private configService: ConfigService,
      private formBuilder: FormBuilder,
      private _generoService: GeneroService,
      private _gestorContaService: GestorContaService,
      private _direccaoService: DireccaoService,
      private _tipoIdentidadeService: TipoIdentificacaoService
    ) {
      this.createForm();
      this._loadingDependences()
    }

    ngOnInit() {
      this.is_required = true
      this._tipoIdentidadeService.getTiposIdentificacao()
      .subscribe((response) => {
        this.default_tipo_identidade = response.data.filter(obj => obj.is_default == true)[0]
        this.tipos_identidades = response.data
      })
  }

  createForm() {
    this.title = "Registar Cliente";

    this.simpleFormCliente = this.formBuilder.group({
      index: [{ value: null, disabled: true }],
      nome: ['', Validators.required],
      telefone: '',
      email: '',
      genero_id: [null],
      tipo_identidade_id: [10, Validators.required],
      numero_identificacao: [null, Validators.required],

      tipo_cliente_id: [null, Validators.required],
      gestor_conta_id: [{ value: null }],
      direccao_id: null,

      provincia_id: [null, Validators.required],
      municipio_id: [null, Validators.required],
      morada: [null, Validators.required],
    })
  }

  onReset() {
    this.createForm()
  }

  // convenience getter for easy access to form fields
  get f() { return this.simpleFormCliente.controls; }

  _toSnakeCase(value: string) {
    if (value) {
      return value.toUpperCase()//.replace(/[\W_]+/g, "");
    } else {
      return ""
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.simpleFormCliente.invalid) { return }
    //this.loading = true;
    const index = this.simpleFormCliente.getRawValue().index;
    const uri = (index === null ? 'cliente/register' : 'cliente/update/' + index);
    this.createOrEdit(uri, this.simpleFormCliente, (index === null ? true : false));
  }

  createOrEdit(uri: any, formulario: FormGroup, isCreate: boolean) {
    this.http.__call(uri, formulario.value).pipe(first()).subscribe(
      response => {
        this.submitted = false;
        //this.loading = false;
        this.configService.showAlert(Object(response).message, 'success', true);

        if (Object(response).code == 200) {
          formulario.reset();
          this.loadListClients(Object(response).data);
          this._closeModal('closeModalCliente')
        }

      },
      error => {
        this.configService.showAlert("Alguma coisa correu mal", 'warning', true);

        this.submitted = false;
        //this.loading = false;
      }
    );
  }

  _closeModal(closeModal) {
    var action = document.getElementById(closeModal)
    action.click()
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if (this.cliente !== undefined) {



      this.title = "Editar Cliente";
      this.simpleFormCliente.patchValue({
        index: this.cliente.id,
        nome: this.cliente.nome,
        genero_id: this.cliente.genero_id,
        telefone: this.cliente.telefone,
        email: this.cliente.email,
        tipo_cliente_id: this.cliente.tipo_cliente_id,
        tipo_identidade_id: this.cliente.tipo_identidade_id,
        numero_identificacao: this.cliente.numero_identificacao,
        provincia_id: this.cliente.provincia_id,
        municipio_id: this.cliente.municipio_id,
        morada: this.cliente.morada,
        gestor_conta_id: this.cliente.gestor_conta_id,
        direccao_id: this.cliente.direccao_id
      });
    } else {
      this.title = "Registar Cliente";
    }
  }

  private selectBoxMunicipiosByProvincia() {
    this.http.call_get('municipio/getMunicipiosByProvincia/' + this.simpleFormCliente['value']['provincia_id'], null).subscribe(
      response => {
        this.selectForms.municipios = Object(response).data;
      }
    );
  }

  public loadListClients(client) {
    this.loadListClient.emit(client);
  }

  _loadingDependences() {

    this._direccaoService.getDireccoes()
      .subscribe((response) => {
        this.direccoes = response.data
      })

    this._generoService.getGenres()
      .subscribe((response) => {
        this.generos = response.data
      })

    this._gestorContaService.getGestoresContas()
      .subscribe((response) => {
        this.gestores = response.data
      })
  }
}
