import { Component, OnInit, Input,Output, EventEmitter,OnChanges, SimpleChange } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { ModalOrdemServicoService } from './modal-ordem-servico.service';

import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'

@Component({
  selector: 'app-modal-create-ordem-trabalho',
  templateUrl: './modal-create-ordem-trabalho.component.html',
  styleUrls: ['./modal-create-ordem-trabalho.component.css']
})
export class ModalCreateOrdemTrabalhoComponent implements OnInit {


  @Input() modal: string = "modalCreateOrdemTrabalho";
  @Input() title: string = "Criar Ordem de Serviço";
  @Input() cliente:any;
  @Input() contrato:any;
  @Input() contratoAnomaliaData:any;

  submitted = false;
  formErrors: any;
  private loading: boolean = false;
  private disabledBotton: boolean = false;
  private ver: boolean = true;

  private prioridades: any = [];
  private classificacoes: any = [];
  private causas: any = [];
  private sintomas: any = [];
  private tipos: any = [];
  private contas: any = [];
  private contratos: any = [];
  dropdownSettingsTipo:any={}
  private TipoArray:any[]=[]
  private causasArray: any = [];
  private sintomasArray: any = [];
  private local_consumo: any = [];

  private cliente_contrato_id = null;
  private is_sintoma: boolean = false;
  private is_causa: boolean = false;
  private is_titulo: boolean = false;
  private editable: boolean = false;

  dropdownSettings = {};
  dropdownSettingsSintomas = {};

  dropdownSettingsDepartamentos = {};
  private departamentoArray: any = [];
  private dropdownDepartamentos: any = [];

  @Input() simpleFormCreateOrEdit: FormGroup;

  @Output() private loadList = new EventEmitter<any>();


  constructor(
        private http: HttpService,
          private flag:ModalOrdemServicoService,
        private configService: ConfigService, private formBuilder: FormBuilder
    ) {
    this.createForm();
  }

  ngOnInit() {
    this.getPrioridade();
    this.getClassificacao();
    this.getDropDownDepartamentos();
  }

  createForm() {
    this.simpleFormCreateOrEdit = this.formBuilder.group({
      index: [{ value: null, disabled: true }],
      id: null,
      cliente_nome: [null],
      titulo: [null],
      data_prevista: [null, Validators.required],
      prioridade_id: [null, Validators.required],
      classificacao_id: [null, Validators.required],
      tipo_id: [null, Validators.required],
      conta_id: [null],
      cliente_id: [null],
      cliente_contrato_id: [null],
      descricao: [null, Validators.required],
      categoria_descricao: [null],
      predio_nome: [null],
      numero_serie: [null],
      cil: [null],
      origem: [null],
      email: [null],
      nivel_3: [null],
      nivel_2: [null],
      nivel_1: [null],
      causa_id: [null],
      sintoma_id: [null],
      sla: [null]
    })
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.simpleFormCreateOrEdit.controls;
  }

  onReset() {
    this.submitted = false;
    this.simpleFormCreateOrEdit.reset();
  }

  private getPrioridade() {
    this.http.call_get('ordemservico/prioridade/selectBox', null).subscribe(
      response => {
        this.prioridades = Object(response).data;
      }
    );
  }

  private getDropDownDepartamentos() {
    this.http.call_get('departamento/selectBox', null).subscribe(
      response => {
        this.dropdownDepartamentos = Object(response).data;

        this.dropdownSettingsDepartamentos = {
          singleSelection: false,
          idField: 'id',
          textField: 'descricao',
          selectAllText: 'Seleccione Todos',
          unSelectAllText: 'DeSeleccionar Todos',
          itemsShowLimit: 10,
          allowSearchFilter: true
        };

      }
    );
  }

  onItemSelectDepartamento(item: any) {
    this.departamentoArray.push(item);
  }
  onSelectAllDepartamentos(items: any) {
    this.departamentoArray = items;
  }

  private getClassificacao() {
    this.http.call_get('ordemservico/classificacao-comercial/selectBox', null).subscribe(
      response => {
        this.classificacoes = Object(response).data;
      }
    );
  }

  private getContas(cliente_id) {

    this.contas = [];

      this.simpleFormCreateOrEdit.patchValue({
        cil: null,
        numero_serie: null,
        predio_nome: null
      });

    this.http.call_get('cliente/conta/selectBox/' + cliente_id, null).subscribe(
      response => {
        this.contas = Object(response).data
      })
  }
  private getTipoByClassificacao() {
    console.log("flag",this.flag.flag)
    this.carregando.tipo = 'Carregando...';
   this.http.__call('ordemservico/ostipo-trigger-by-flag/selectBox/' + this.simpleFormCreateOrEdit.value.classificacao_id, { flag: this.flag.flag }).subscribe(
   /*  this.http.call_get('ordemservico/ostipo-by-classificacao/selectBox/' + this.ordemServico.classificacao_id, null).subscribe( */
      response => {
        this.tipos = Object(response).data;
        this.carregando.tipo = 'Seleccione o tipo';
        console.log('tipos',this.tipos)
        this.dropdownSettingsTipo= {
          singleSelection: false,
          idField: 'id',
          textField: 'descricao',
          selectAllText: 'Seleccione Todos',
          unSelectAllText: 'DeSeleccionar Todos',
          itemsShowLimit: 10,
          allowSearchFilter: true
        };
      }
    );

    this.getCausasByClassificacao();
    this.getSintomasByClassificacao();
  }

  onItemSelectTipo(item: any) {
    this.TipoArray.push(item);
  }
  onSelectAllTipos(items: any) {
    this.TipoArray = items;
  }

  public getContratosByConta() {

    this.contratos = [];

    if (this.simpleFormCreateOrEdit.value.conta_id != null) {

      this.configService.loaddinStarter('start');
      this.http.__call('conta/contratoByConta/' + this.simpleFormCreateOrEdit.value.conta_id, null).subscribe(
        response => {
          this.contratos = Object(response);
          this.configService.loaddinStarter('stop');
        }
      );

    }
  }

  private getLocalConsumoByContratoId() {

    this.simpleFormCreateOrEdit.patchValue({
      cil: null,
      numero_serie: null,
      predio_nome: null
    });

    if (this.simpleFormCreateOrEdit.value.cliente_contrato_id != null) {

      this.cliente_contrato_id = this.simpleFormCreateOrEdit.value.cliente_contrato_id;

      this.http.call_get('local-consumo/getLocalConsumoByContratoId/' + this.simpleFormCreateOrEdit.value.cliente_contrato_id , null).subscribe(
        response => {

          this.local_consumo = Object(response);
          this.simpleFormCreateOrEdit.patchValue({
            cil: this.local_consumo.cil == null ? '' : this.local_consumo.cil,
            numero_serie: this.local_consumo.numero_serie == null ? '' : this.local_consumo.numero_serie,
            predio_nome: ((this.local_consumo.cil != null) ? '[' + this.local_consumo.cil + '] ' : '[0000-000-000] ') + ((this.local_consumo.is_predio) ? 'Prédio ' + this.local_consumo.predio_nome + ', ' + (this.local_consumo.predio_andar ? this.local_consumo.predio_andar + ' º Andar - ' : '') + 'Porta ' : 'Residência ') + this.local_consumo.moradia_numero
          });

        }
      );
    }else{
      this.simpleFormCreateOrEdit.patchValue({
        cil: null,
        numero_serie: null,
        predio_nome: null
      });

      this.cliente_contrato_id = null
    }



  }

  private getAssociacao() {

    this.simpleFormCreateOrEdit.patchValue({
      titulo: this.simpleFormCreateOrEdit.value.titulo
    });

    this.is_titulo = false;

    this.simpleFormCreateOrEdit.patchValue({
      categoria_descricao: null
    });

    if (this.simpleFormCreateOrEdit.value.tipo_id != null) {
      this.configService.loaddinStarter('start');
      this.http.call_get('ordemservico/ostipo/associacao/' + this.simpleFormCreateOrEdit.value.tipo_id, null).subscribe(
        response => {

          this.simpleFormCreateOrEdit.patchValue({
            categoria_descricao: Object(response).data.categoria_descricao,
            sla: Object(response).data.sla
          });

          this.is_sintoma = Object(response).data.sintoma;
          this.is_causa = Object(response).data.causa;
          this.is_titulo = Object(response).data.is_titulo;

          if (this.is_titulo) {

            this.simpleFormCreateOrEdit.patchValue({
              titulo: Object(response).data.descricao
            });


          }
          this.configService.loaddinStarter('stop');
        }

      );
    }

      this.is_titulo = false

  }

  private carregando = {
    tipo: 'Seleccione o tipo'
  }

/*   private getTipoByClassificacao() {
    this.carregando.tipo = 'Carregando...';
    this.http.call_get('ordemservico/ostipo-by-classificacao/selectBox/' + this.simpleFormCreateOrEdit.value.classificacao_id, null).subscribe(
      response => {
        this.tipos = Object(response).data;
        this.carregando.tipo = 'Seleccione o tipo';
      }
    );

    this.getCausasByClassificacao();
    this.getSintomasByClassificacao();
  } */

  private getCausasByClassificacao() {

    this.http.call_get('ordemservico/oscausas-by-classificacao/selectBox/'+ this.simpleFormCreateOrEdit.value.classificacao_id, null).subscribe(
      response => {
        this.causas = Object(response).data;

        this.dropdownSettings = {
          singleSelection: false,
          idField: 'id',
          textField: 'descricao',
          selectAllText: 'Seleccione Todos',
          unSelectAllText: 'DeSeleccionar Todos',
          itemsShowLimit: 10,
          allowSearchFilter: true
        };

      }
    );
  }

  onResetSome(){

    this.disabledBotton = false;
    this.causasArray = [];
    this.sintomasArray = [];
    this.departamentoArray = [];

    this.simpleFormCreateOrEdit.patchValue({
      titulo: null,
      sla: null,
      data_prevista: null,
      prioridade_id: null,
      classificacao_id: null,
      categoria_descricao: null,
      tipo_id: null,
      descricao: null
    });
  }

  onItemSelect(item: any) {
    //console.log(item);
    this.causasArray.push(item);
  }
  onSelectAll(items: any) {
    this.causasArray = items;
  }

  private getSintomasByClassificacao() {
    this.http.call_get('ordemservico/ossintomas-by-classificacao/selectBox/' + this.simpleFormCreateOrEdit.value.classificacao_id, null).subscribe(
      response => {
        this.sintomas = Object(response).data;

        this.dropdownSettingsSintomas = {
          singleSelection: false,
          idField: 'id',
          textField: 'descricao',
          selectAllText: 'Seleccione Todos',
          unSelectAllText: 'DeSeleccionar Todos',
          itemsShowLimit: 10,
          allowSearchFilter: true
        };

      }
    );
  }

  onItemSelectSintoma(item: any) {
    this.sintomasArray.push(item);
  }
  onSelectAllSintomas(items: any) {
    this.sintomasArray = items;
  }

  onSubmit() {

    this.submitted = true;

    // parar aquei se o formulário for inválido
    if (this.simpleFormCreateOrEdit.invalid) {
      return;
    }
    this.loading = true;
    const index = this.simpleFormCreateOrEdit.getRawValue().index;
    // TODO: usado para fazer a requisição com a api de criação de objsct or update
    const uri = 'ordemservico/ordem-servico/create';
    this.createOrEdit(uri, this.simpleFormCreateOrEdit, (index === null ? true : false));

  }

  createOrEdit(uri: any, formulario: FormGroup, isCreate: boolean) {

    // TODO: usado para fazer a requisição com a api de criação de object
    let uploadData = new FormData();

    this.disabledBotton = true;

    uploadData.append("ordemServico", JSON.stringify(formulario.value));
    uploadData.append("causas", JSON.stringify(this.causasArray));
    uploadData.append("sintomas", JSON.stringify(this.sintomasArray));
    uploadData.append("departamentos", JSON.stringify(this.departamentoArray));

    this.http.$__call(uri, uploadData ).subscribe(
      response => {
        
        this.submitted = false;
        this.loading = false;
        if (isCreate && Object(response).code ==200) {
          formulario.reset();
          this.onReset()
        }

        if ((Object(response).body || {}).code ==200) {
          this.simpleFormCreateOrEdit.patchValue({
            id: (Object(response).body || {}).data.ordem.id
          }) 
          this.alertPrintOrdemServico((Object(response).body || {}).data.ordem.id)
          this.loadList_item(Object(response).data);
          this.onReset()
        }
      },
      error => {
        this.submitted = false;
        this.loading = false;
      });
  }


  alertPrintOrdemServico(id){
   
   if(!id) return
    Swal.fire({
        title: 'Imprimir Ordem de Serviço!',
        text: "Ordem de Serviço!",
        type: 'success',
        showCancelButton: true,
        confirmButtonText: 'Imprimir',
        cancelButtonText: 'Cancelar!',
        reverseButtons: true
    }).then((result) => {
        if (result.value) {
          this.imprimirOrdemServico(id)
        }
    })

}
private imprimirOrdemServico(id) {
  this.configService.imprimirOrdemServico(id, "2ª Via", "imprimir")
}

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
   console.log('cliente modal',this.cliente, this.contrato)
   //if (this.cliente !== undefined && this.contrato == undefined) {
   if ((this.cliente !== undefined && this.contrato == undefined) && (this.cliente.length > 0)) {

      this.title = "Criar Ordem de Serviço";
      //this.onReset()

      this.getContas(this.cliente.id)

      this.simpleFormCreateOrEdit.patchValue({
        index: this.cliente.id,
        cliente_id: this.cliente.id,
        cliente_nome: this.cliente.nome,
        origem: "COMERCIAL"
      });

    }else if(this.contrato != undefined){

      this.getContas(this.contrato.cliente_id);

      this.simpleFormCreateOrEdit.value.conta_id = this.contrato.conta_id

      this.getContratosByConta();

      this.cliente.nome = this.contrato.cliente_nome;
      this.editable = true;

      this.simpleFormCreateOrEdit.patchValue({
        cliente_id: this.contrato.cliente_id,
        cliente_nome: this.contrato.cliente_nome == undefined ? this.contrato.cliente : this.contrato.cliente_nome,
        conta_id: this.contrato.conta_id == undefined ? this.contrato.conta : this.contrato.conta_id,
        cliente_contrato_id: this.contrato.id == undefined ? this.contrato.contrato_id: this.contrato.id,
        origem: "COMERCIAL"
      });



    }else if(this.contratoAnomaliaData != undefined){

      this.getContas(this.contratoAnomaliaData.cliente_id);

      this.simpleFormCreateOrEdit.value.conta_id = this.contratoAnomaliaData.conta_id

      this.getContratosByConta();

      this.cliente.nome = this.contratoAnomaliaData.cliente;
      this.editable = true;

      this.simpleFormCreateOrEdit.patchValue({
        cliente_id: this.contratoAnomaliaData.cliente_id,
        cliente_nome: this.contratoAnomaliaData.cliente,
        conta_id: this.contratoAnomaliaData.conta_id,
        cliente_contrato_id: this.contratoAnomaliaData.contrato_id,
        origem: "COMERCIAL"
      });



    }else if (this.cliente !== undefined && this.contrato == undefined) {

      this.title = "Criar Ordem de Serviço";

      this.getContas(this.cliente.id)

      this.simpleFormCreateOrEdit.patchValue({
        index: this.cliente.id,
        cliente_id: this.cliente.id,
        cliente_nome: this.cliente.nome,
        origem: "COMERCIAL"
      });

    } else {

      this.title = "Criar Ordem de Trabalho";

    }
  }

  public loadList_item(cliente) {
    this.loadList.emit(cliente);
  }

}
