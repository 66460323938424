import { PlanoPrestacaoService } from './../reports/recibo/planoPrestacao.service';
import { finalize } from 'rxjs/operators';
import * as moment from 'moment';
import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { ReciboService } from 'src/app/components/reports/recibo/recibo.service'
// ES6 Modules or TypeScript
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'
@Component({
	selector: 'app-criar-plano-prestacao',
	templateUrl: './criar-plano-prestacao.component.html',
	styleUrls: ['./criar-plano-prestacao.component.css']
})
export class CriarPlanoPrestacaoComponent implements OnInit {

	private contas: any = [];
	private contratos: any = [];
	private loadingConta: string = "Conta"
	private loadingContrato: string = "Contrato"
	private search: string;
	private total_linhas = 0;
	public cliente_id = null
	submitted = false;
	disable = false;
	public view_btn_pagador = true
	private contrat = {
		contrato_id: null
	}
	private _conta = {
		id: null
	}
	loading = false;
	//desabe_is_adiantamento: boolean

	lista_dos_adiantamentos: any = []
	adiantamento = []
	listMovimento = []
	private listCaucaos: any
	private totalAdiantamento = 0;

	private recibo: any;
	adianntamento_como_default: any
	private configuracoesPP: any
	planoPrestacaoForm: FormGroup;

	private validateButton: boolean = true;
	private carregando = {
		serie: 'Seleccione a serie',
		forma_pagamento: 'Seleccione a forma de pagamento',
		banco: 'Seleccione conta da bancaria'
	}

	constructor(
		private http: HttpService,
		private configService: ConfigService,
		private formBuilder: FormBuilder,
		private PDFrecibo: ReciboService,
		private route: ActivatedRoute,
		private PDFreciboPlanoPrestacao: PlanoPrestacaoService,
		private router: Router
	) {
		this.http.__call(`configuracao/getConfiguracaobySlug/adianntamento_como_default`, null)
			.subscribe(response => {
				if (Object(response).data.valor == "true") {
					this.adianntamento_como_default = '1'
				} else {
					this.adianntamento_como_default = '0'
				}
			})

		this.listarFormaPagamentos();
		this.configService.listarBancos();
		this.getConfiguracoesPlanoPrestacao();
		this.createForm();
	}

	ngOnInit() {
		const param = this.route.queryParams.subscribe(params => {

			const id = params['c'] || 0;
			const name = params['n'] || 0;
			const contrato_id = params['contrato_id'] || 0;
			const conta_id = params['conta_id'] || 0;
			if (id > 0 && name) {
				this.getSeriesRecibos();
				this.setCliente({ id: id, nome: name });
				this.planoPrestacaoForm.get('nome').disable();
			}
		});
		this.setDefault()

		const conta_id = this.route.snapshot.params.conta_id;
		const contrato_id = Number(this.route.snapshot.params.contrato_id);
		const cliente_id = Number(this.route.snapshot.params.cliente_id);

		let clientes = null
		if (contrato_id || conta_id) {
			this.http.call_get('cliente/getClienteById/' + cliente_id, null)
				.subscribe(response => {
					clientes = Object(response).data
					this.getSeriesRecibos();
					this.setCliente({ id: clientes.id, nome: clientes.nome });
					if (clientes) {
						this.getContratosByConta(conta_id)
						this.faturaByContrato(contrato_id);
					}
				});

		}
	}

	ngOnDestroy() { }

	listarBancosById(id) {
		this.configService.listarBancosById(id)
	}

	createForm() {
		this.planoPrestacaoForm = this.formBuilder.group({
			nome: [''],
			total_pago: [{ disabled: true }],
			data_inicio: [{ value: moment(Date()).format("YYYY-MM-DD").toString(), disabled: false }],
			data_fim: [{ value: null, disabled: false }],
			valor_recebido: [0],
			total_valor_recebido: [0, Validators.required],
			facturas: [{ value: null }],
			contrato_id: null,
			linha_prestacao: [{ value: null }],
			n_prestacao: [0],
		});

		this.adiantamento = []
		this.listMovimento = []

		this.facturas = [];
		this.facturasSeleciondas = []
		this.linha_prestacao = []
		this.view_facturas = false
		this.configService.loaddinStarter('stop');
		this.loading = false;
	}

	// convenience getter for easy access to form fields
	get f() {
		return this.planoPrestacaoForm.controls;
	}

	onReset() {

		this.submitted = false;

		this.planoPrestacaoForm.get('valor_recebido').reset();
		this.planoPrestacaoForm.get('data_inicio').reset();
		this.planoPrestacaoForm.get('data_fim').reset();

		this.linha_prestacao = []
		this.lista_dos_adiantamentos = []
		this.adiantamento = []
		this.facturas = [];
		this.listMovimento = [];
		this.facturasSeleciondas = []
		this.view_facturas = false
		this.configService.loaddinStarter('stop');
		this.loading = false;

	}


	onSubmit() {
		this.submitted = true;

		if (this.planoPrestacaoForm.invalid) {
			return;
		}

		if (this.facturasSeleciondas.length == 0) {
			this.configService.showAlert('Nenhuma factura seleccionada', "alert-danger", true);
			return;
		} else if (this.linha_prestacao.length == 0) {
			this.configService.showAlert('Nenhuma Prestação Criada', "alert-danger", true);
			return;
		}

		this.loading = true

		this.planoPrestacaoForm.patchValue({
			facturas: this.facturasSeleciondas,
			linha_prestacao: this.linha_prestacao,
			contrato_id: this.contrato.numero_contrato
		});

		const uri = 'plano-prestacao/criar';
		this.createOrEdit(uri, this.planoPrestacaoForm, true);
	}

	createOrEdit(uri: any, planoPrestacaoFormulario: FormGroup, isCreate: boolean) {
		this.validateButton = false;
		var nome: string;
		this.http.__call(uri, planoPrestacaoFormulario.value).pipe(finalize(() => { this.loading = false; this.validateButton = true; this.submitted = false; })).subscribe(
			res => {
				this.printer_recibo = false;
				this.planoPrestacaoForm.patchValue({ contrato_id: Object(res).data.contrato_id });
				this.btnImprimirPlanoPrestacao(Object(res).data.planoPrestacao)
				this.onReset();
			}, error => {
				this.validateButton = true;
				this.loading = false
			}
		)

	}

	private btnImprimirPlanoPrestacao(planoPrestacao: any) {
		this.PDFreciboPlanoPrestacao.imprimirReciboPlanoPrestacao(planoPrestacao.id, '2ª Via');
	}

	private activarAdiantamento = false
	private activarCaucao = false

	printer_recibo: boolean = true;
	private series: any = []
	private default: any = []

	private getSeriesRecibos() {
		this.http.__call(`configuracao/getConfiguracaobySlug/adianntamento_como_default`, null)
			.subscribe(response => {
				if (Object(response).data.valor == "true") {
					this.planoPrestacaoForm.patchValue({
						is_adiantamento: '1',
					})
					this.adianntamento_como_default = '1'
				} else {
					this.planoPrestacaoForm.patchValue({
						is_adiantamento: '0',
					})
					this.adianntamento_como_default = '0'
				}
			})

		//=============================================================

		this.http.call_get('serie/loja/selectSerieLojaReciboBox', null)
			.subscribe(response => {
				let data = Object(response).data

				this.series = data;
				this.default = this.series[0].id
				this.listarBancosById(data[0].loja_id)
				this.planoPrestacaoForm.patchValue({
					serie_id: this.default

				})
				this.carregando.serie = 'Seleccione a serie'
			});
	}

	private setDefault() {
		this.planoPrestacaoForm.patchValue({
			serie_id: this.default,
			is_adiantamento: this.adianntamento_como_default
		})
	}

	private formasPagamentos: any = [];

	private listarFormaPagamentos() {
		this.carregando.forma_pagamento = 'Carregando...'
		this.http.call_get('formaPagamento/formas', null).subscribe(
			response => {

				if (this.totalAdiantamento == 0) {
					this.formasPagamentos = [];
					for (let i = 0; i < Object(response).data.length; i++) {
						if (Object(response).data[i].designacao !== 'Adiantamento') {
							this.formasPagamentos.push(Object(response).data[i]);
						}
					}
				} else {
					this.formasPagamentos = [];
					this.formasPagamentos = Object(response).data;
				}
				this.configService.loaddinStarter('stop');
				this.carregando.forma_pagamento = 'Seleccione a forma de pagamento'
			}
		);
	}



	private clientes: any = [];

	view_client = false;

	private getCliente() {
		this.facturas = [];
		this.facturasSeleciondas = []
		this.view_facturas = false;
		this.view_client = true;
		this.linha_prestacao = []

		this.http.__call('cliente/search-cliente', { start: 1, end: 10, search: this.planoPrestacaoForm.getRawValue().nome }).subscribe(
			response => {
				this.clientes = Object(response).data.data;
				this.configService.loaddinStarter('stop');
				this.getSeriesRecibos();
			}
		);
	}



	private setCliente(client: any) {
		this.createForm();
		this.cliente_id = client.id
		this.planoPrestacaoForm.patchValue({
			cliente_id: client.id,
			nome: client.nome,
		});
		this.view_client = false;
		this.getContasByCliente(client.id)
		this.Valoradiantamentos(client.id);
		this.view_btn_pagador = false
	}




	view_facturas = false;
	private facturas: any = []
	private faturaByContrato(contrato_id: number) {
		this.loading = true
		this.configService.loaddinStarter('start');
		this.http.__call('factura/byContrato', { contrato_id: contrato_id }).subscribe(
			res => {
				if (Object(res).code == 500) {
					this.configService.showAlert(Object(res).message, "alert-danger", true);
					this.configService.loaddinStarter('stop');
				} else {
					this.view_facturas = true
					this.facturas = Object(res).data;
					this.configService.loaddinStarter('stop');
				}
				this.loading = false;
			}, error => {
				this.loading = false;
			}
		);
	}




	private Valoradiantamentos(cliente_id: number) {

		this.http.__call("adiantamento/factura/adiantamentoContaCliente", { cliente_id: cliente_id }).subscribe(response => {
			if (Object(response).data.length == 0) {
				this.totalAdiantamento = 0;
			} else {
				this.totalAdiantamento = Object(response).data[0].valor;
				this.listarFormaPagamentos();
			}
		})
	}



	private totalApagar() {
		let total_valor_recebido = Number(this.planoPrestacaoForm.getRawValue().total_valor_recebido)

		var ve = (total_valor_recebido + this.total_adiantamento())
		this.printer_recibo = true;
		var total = 0;
		var total_saldado = 0

		this.planoPrestacaoForm.patchValue({
			total_pago: null,
			total_saldado: null
		});

		this.facturasSeleciondas.forEach(element => {
			total += element.valor_aberto + element.juros;
			var factura = element;
			factura.valor_aberto = (factura.valor_aberto == null && factura.pago == 0 ? factura.total : factura.valor_aberto);// Atribui o valor total caso o valor em aberto for null
			var va = factura.valor_aberto; // valor actual em aberto da factura
			var saldado = 0  // recebe o valor saldado na factura
			if (ve > 0) {
				ve = ve - va;
				saldado = (ve < 0 ? ve - va * -1 : va) // calcula o valor saldado
				total_saldado += saldado
			}
		});

		this.planoPrestacaoForm.patchValue({
			total_pago: total,
			total_saldado: total_saldado.toFixed(2)
		});

		this.calcularTroco()
	}

	private calcularTroco() {
		let total_valor_selecionado = Number(this.planoPrestacaoForm.getRawValue().total_pago)
		let total_valor_recebido = Number(this.planoPrestacaoForm.getRawValue().total_valor_recebido)
		let total_adiantamentos = this.total_adiantamento()
		let total_troco = ((total_adiantamentos + total_valor_recebido) - total_valor_selecionado)

		this.planoPrestacaoForm.patchValue({ troco: 0 });

		if (total_troco > 0) {
			this.planoPrestacaoForm.patchValue({
				troco: total_troco.toFixed(2)
			})
		}
	}

	private clerInputsAfterAddLinha(e) {
		this.planoPrestacaoForm.patchValue({
			total_valor_recebido: (this.planoPrestacaoForm.getRawValue().valor_recebido).toFixed(2),
		});
		e.target.elements[2].value = null;
		e.target.elements[3].value = null;
	}


	private btnImprimirRecibo() {
		this.PDFrecibo.imprimirRecibo(this.recibo, 'Original');
	}

	private changeSelectValue(contrato_id: any) {
		this.contrat.contrato_id = contrato_id;
		if (contrato_id != null) {
			this.faturaByContrato(contrato_id);
		}
	}

	private getContasByCliente(id) {
		this.loadingConta = 'Carregando...';
		//id = id.target.value;
		this.configService.loaddinStarter('start');
		this.http.call_get('cliente/conta/listar/' + id, null).subscribe(
			response => {
				this.contas = Object(response).data;
				this.configService.loaddinStarter('stop');
			}
		);
	}

	public getContratosByConta(id) {
		this.loadingContrato = 'Carregando...';
		this.http.__call('conta/GETcontratoIDByConta/' + id, null).subscribe(
			response => {
				this.contratos = Object(response);
				this.loadingContrato = 'Contrato';
				this.configService.loaddinStarter('stop');
			}
		);
	}

	public getConfiguracoesPlanoPrestacao() {
		this.http.call_get('plano-prestacao-configuracao', null).subscribe(
			response => {
				this.configuracoesPP = Object(response).data;
				this.configService.loaddinStarter('stop');
			}
		);
	}

	private facturasSeleciondas = []
	private contrato: any;

	private SeleccionarFactura(factura: any) {
		this.contrato = factura;

		if (this.facturasSeleciondas.length == 0) {
			factura.valor_aberto = (factura.valor_aberto == null && factura.pago == 0 ? factura.total : factura.valor_aberto);
			this.facturasSeleciondas.push(factura);
		} else {
			var validar = 0
			for (let i = 0; i < this.facturasSeleciondas.length; ++i) {
				if (this.facturasSeleciondas[i].id === factura.id) {
					this.facturasSeleciondas.splice(i, 1);
					validar = 1;
				}
			}
			if (validar === 0) {
				factura.valor_aberto = (factura.valor_aberto == null && factura.pago == 0 ? factura.total : factura.valor_aberto);
				this.facturasSeleciondas.push(factura);
			}
		}

		//Calcula o Total da Factura
		this.totalApagar();
	}

	//--------------------------------------------------------------
	private linha_prestacao: any = []

	deleteRowFormaPagamento(linha: any) {
		if (linha.designacao == "Adiantamento") {
			this.lista_dos_adiantamentos = []
			this.adiantamento = []
		}

		this.total_linhas = this.total_linhas - linha.valor

		for (let i = 0; i < this.linha_prestacao.length; ++i) {
			if (this.linha_prestacao[i].forma_pagamento_id === linha.forma_pagamento_id) {
				this.linha_prestacao.splice(i, 1);
				this.calcularValorTotalRecebido();
				this.totalApagar()
				this.calcularValorFaltarPagar();
			}
		}

	}

	private validateModal() {
		this.submitted = true;

		if (this.planoPrestacaoForm.invalid) {
			return;
		}

		if (this.facturasSeleciondas.length == 0) {
			this.configService.showAlert('Nenhuma factura seleccionada', "alert-danger", true);
			return;
		} else if (this.linha_prestacao.length == 0) {
			this.configService.showAlert('Nenhuma forma de pagamento seleccionada', "alert-danger", true);
			return;
		}

		// this.modal()
	}

	private modal() {
		/* if(value.troco<0 && value.is_adiantamento!=1)return */
		if (this.planoPrestacaoForm.getRawValue().troco > 0 && this.planoPrestacaoForm.getRawValue().is_adiantamento == '0') {
			const swalWithBootstrapButtons = Swal.mixin({
				customClass: {
					confirmButton: 'btn btn-success',
					cancelButton: 'btn btn-danger',
				},
				buttonsStyling: false,
			})

			swalWithBootstrapButtons.fire({

				title: '<strong>Esta cobrança irá gerar troco, pretende proceder?</strong>',
				type: 'error',
				showCloseButton: true,
				showCancelButton: true,
				confirmButtonText: 'Continuar',
				cancelButtonText: 'Cancelar'
			}).then((result) => {
				if (result.value) {
					this.onSubmit()
				} else if (
					/* Read more about handling dismissals below */
					result.dismiss === Swal.DismissReason.cancel
				) {
				}

			})
		} else {
			this.onSubmit()
		}
	}

	private adicionarDiasData(dias) {
		var hoje = new Date();
		var dataVenc = new Date(hoje.getTime() + (dias * 24 * 60 * 60 * 1000));
		var mes
		if ((dataVenc.getMonth() + 1) < 10) {
			mes = '0' + (dataVenc.getMonth() + 1)
		} else {
			mes = (dataVenc.getMonth() + 1)
		}
		return dataVenc.getFullYear() + "-" + mes + "-" + dataVenc.getDate();
	}

	private addPrestacoes() {
		this.linha_prestacao = []
		var data_inicio = moment(Date()).format("YYYY-MM-DD");;
		var validar = 0;
		var numero_prestacao = Number(this.planoPrestacaoForm.getRawValue().n_prestacao)
		var total_a_parcelar = (this.planoPrestacaoForm.getRawValue().total_pago - this.planoPrestacaoForm.getRawValue().valor_recebido) / numero_prestacao

		if (this.planoPrestacaoForm.getRawValue().total_pago == 0) {
			this.configService.showAlert('Selecione as Facturas que Pertendes Parcelar', "alert-danger", true);
			return;
		} else if (total_a_parcelar > this.planoPrestacaoForm.getRawValue().total_pago) {
			this.configService.showAlert('O Total das Parcelas  não podem ser Superior  ao Total da Divida Selecionada', "alert-danger", true);
			return;
		} else if ((this.planoPrestacaoForm.getRawValue().valor_recebido > this.planoPrestacaoForm.getRawValue().total_pago) || this.planoPrestacaoForm.getRawValue().valor_recebido < this.configuracoesPP.valor) {
			this.configService.showAlert('O Valor de Entrada não Pode ser Maior que o Valor da Divida nem menor que o Valor Minimo de Entrada ( '+this.configuracoesPP.valor+ ' )', "alert-danger", true);
			return;
		} else {

			for (let i = 1; i <= numero_prestacao; i++) {

				var novaData_fim = this.adicionarDiasData(30 * i);
				var linha = {
					valor: Math.round(total_a_parcelar),
					data_inicio: data_inicio,
					data_fim: novaData_fim,
				}
				this.linha_prestacao.push(linha);
				data_inicio = novaData_fim

			}
		}

		this.totalApagar()
	}

	calcularValorFaltarPagar() {
		var valor_falta_pagar = this.planoPrestacaoForm.getRawValue().total_pago - this.planoPrestacaoForm.getRawValue().total_valor_recebido;
		this.planoPrestacaoForm.patchValue({
			valor_falta_pagar: valor_falta_pagar < 0 ? (0).toFixed(2) : (valor_falta_pagar).toFixed(2)
		})
	}

	private calcularValorTotalRecebido() {
		var total_valor_recebido = 0
		this.linha_prestacao.forEach(element => {
			if (element.designacao !== 'Adiantamento') {
				total_valor_recebido += ((element.valor_recebido));
			}
		});
		this.planoPrestacaoForm.patchValue({
			total_valor_recebido: (total_valor_recebido).toFixed(2)
		});
	}


	private codeReferencia: any;
	private validationReferenciaBancaria(ref, bank) {
		this.http.__call('referencia/validation', { referencia: ref, banco_id: bank }).subscribe(
			response => {
				this.codeReferencia = Object(response);
			}
		);
	}

	private verificaForma(item) {
		if (item == "") {
			return true
		}
		var chaves = [];
		this.formasPagamentos.forEach(element => {
			if (element.usar_banco === 0) {
				chaves.push("" + element.id);
			}
		})
		if (chaves.length > 0) {
			if (chaves.indexOf(item) > -1) {
				return true
			} else {
				return false
			}
		} else {
			return false
		}
	}

	private getMovimentoAdiantamento() {
		this.configService.loaddinStarter('start');
		this.http.__call('adiantamento/factura/adiantamentoFactura', { cliente_id: this.planoPrestacaoForm.getRawValue().cliente_id }).subscribe(
			response => {
				this.listMovimento = Object(response).data;
				this.configService.loaddinStarter('stop');
			}
		);
	}

	_add_and_remove_adiantamento(adiantamento: any) {

		const adiantamento1 = this.lista_dos_adiantamentos.find((add) => add.id === adiantamento.id)
		if (!adiantamento1) {
			this.lista_dos_adiantamentos.push(adiantamento)
		}
		else {
			const index = this.lista_dos_adiantamentos.indexOf(adiantamento);
			if (index !== -1) {
				this.lista_dos_adiantamentos.splice(index, 1);
			}
		}
	}

	setAdianta(adiantamento: any, checked: boolean) {
		let valor = adiantamento.valor
		let valor_recebido = Boolean(this.planoPrestacaoForm.getRawValue().valor_recebido) ? this.planoPrestacaoForm.getRawValue().valor_recebido : 0
		let result = 0

		if (checked) {
			result = Math.abs(valor + valor_recebido)
			this.lista_dos_adiantamentos.push(adiantamento)
		} else {
			result = Math.abs(valor - valor_recebido)
			const index = this.lista_dos_adiantamentos.indexOf(adiantamento);
			if (index !== -1) {
				this.lista_dos_adiantamentos.splice(index, 1);
			}
		}

		this.planoPrestacaoForm.patchValue({ valor_recebido: result });
		this.totalApagar()
	}

	total_adiantamento() {
		let total_a_pagar = this.linha_prestacao.filter(item => item.designacao == 'Adiantamento').reduce((accum, item) => accum + item.valor_recebido, 0)
		return Number(total_a_pagar)
	}

}
