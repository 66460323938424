import { Injectable } from '@angular/core';
import * as moment from 'moment';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import { ConfigService } from 'src/app/providers/config/config.service';

@Injectable({
  providedIn: 'root'
})
export class ReportResumoContaService {

  constructor(private configService: ConfigService) { }

  public relatorioResumoConta(p = 'print', file, imgData, value) {
    var today = moment().format("DD-MM-YYYY H:mm:ss");
    var doc = new jsPDF('l', '', 'a3')
    doc.setProperties({
      title: 'Listagem-de-divida' + today,
      subject: 'Report',
      author: 'Unig',
      keywords: '',
      creator: 'unig bewater'
    });

    doc.addImage(imgData, 'JPEG', 15, 10, 33, 28)
    const totalPagesExp = "{total_pages_count_string}";
    doc.setFontSize(9)
    doc.setFontType('bold')
    doc.text('Cliente:', 345, 20)
    doc.text('Data:', 347, (value.cliente != null && value.cliente.length > 33 ? 29 : 25))
    doc.setFontType('normal')
    doc.text('' + (value.cliente == null ? 'Todos' : value.cliente), 358, 20, { maxWidth: 33 });
    doc.text('' + (value.data == null ? '' : value.data), 358, (value.cliente != null && value.cliente.length > 33 ? 29 : 25));
    doc.setFontType('bold')
    doc.setFontSize(15)
    doc.setFontSize(15)
    doc.text(225, 27, 'Listagem de Dívida', 'center')

    doc.autoTable({
      html: file,
      didParseCell: function (data) {
        var rows = data.table.body;
        if (data.row.index === 0) {
          data.cell.styles.fontStyle = 'bold';
          data.cell.styles.textColor = "white";
          data.cell.styles.halign = 'center';
          data.cell.styles.fillColor = [0, 153, 255];
        }
      },
      addPageContent: data => {
        let footerStr = "Página " + doc.internal.getNumberOfPages();
        if (typeof doc.putTotalPages === 'function') {
          footerStr = footerStr + " de " + totalPagesExp;
        }
        doc.setFontSize(10);
        doc.text(today, data.settings.margin.left, doc.internal.pageSize.height - 2)
        doc.text(footerStr, 260, doc.internal.pageSize.height - 2, 'left');
      },
      styles: { textColor: [0, 0, 0],fontSize: 9, margin: { left: 0,right:10} },
      columnStyles: {
        0: { cellWidth: 20, halign: 'center' },
        1: { cellWidth: 20, halign: 'center' },
        2: { cellWidth: 45, halign: 'left' },
        3: { cellWidth: 25, halign: 'center' },
        4: { cellWidth: 30, halign: 'center' },
        5: { cellWidth: 20, halign: 'center' },
        6: { cellWidth: 20, halign: 'center' },
        7: { cellWidth: 25, halign: 'center' },
        8: { cellWidth: 35, halign: 'center' },
        9: { cellWidth: 35, halign: 'center' },
        10: { cellWidth: 35, halign: 'center' },
        11: { cellWidth: 20, halign: 'center' },
        12: { cellWidth: 35, halign: 'center' },
        13: { cellWidth: 30, halign: 'right' },
      },
      startY: 40,
      theme: 'grid',
    })

    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }
    if (p === 'save') {
      doc.save('Listagem-de-divida' + '.pdf');
    } else {
      doc.autoPrint();
      doc.output("dataurlnewwindow");
    }
  }
}
