import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { toDate, format } from 'date-fns-tz'


import { ConfigModuloService } from 'src/app/services/config-modulo.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { RescisaoService } from "src/app/components/reports/rescisao/rescisao.service";
import { ContratoService } from "src/app/components/reports/contrato/contrato.service";

import { AuthService } from 'src/app/providers/auth/auth.service';
import { HttpParams } from "@angular/common/http";
import { debounceTime, finalize } from 'rxjs/operators';

import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'




import { ApiService } from "src/app/providers/http/api.service";

import { ExcelService } from 'src/app/services/excel.service';
import { FileSelectDirective, FileUploader } from 'ng2-file-upload';
import { FileService } from 'src/app/services/file.service';
import { saveAs } from 'file-saver';

import * as moment from 'moment';

import { Router } from '@angular/router';
import { ContratoLundasulService } from '../../reports/contrato-lundasul/contrato-lundasul.service';
import { ContratoLundanorteService } from '../../reports/contrato-lundanorte/contrato-lundanorte.service';
import { RescisaoLundanorteService } from '../../reports/rescisao-lundanorte/rescisao-lundanorte.service';

import { environment } from 'src/environments/environment';
import { FilterService } from '../../relatorios/financeiro/report-forma-pagamento/filter.service';
import { te } from 'date-fns/locale';
import { FacturaService } from '../../facturacao/factura.service';
import { RotaService } from '../../config-rotas/rotas.service';
import { ModalOrdemServicoService } from '../../ordemservico/modal-create-ordem-trabalho/modal-ordem-servico.service';

const uri = 'http://localhost:3000/file/upload';

@Component({
  selector: 'app-listar-contrato',
  templateUrl: './listar-contrato.component.html',
  styleUrls: ['./listar-contrato.component.css']
})
export class ListarContratoComponent implements OnInit {

  @ViewChild('closebutton') closebutton;
  @ViewChild('closebuttonalterar') closebuttonalterar;
  @ViewChild('closebuttontitularidade') closebuttontitularidade;



  loadingListarFacturacao = false;

  private _empty = '-------';
  private totalDivida: any = 0

  public currentUser: any;
  ultimaLeitura: any
  private contas: any = [];
  private view_client = true;
  private clientes: any = [];
  TipoArray: any[] = []
  dropdownSettingsTipo: any = {}
  private empresa_sigla: any = [];

  datatime: string



  private cliente_nome = ""

  private contratos: any = [];
  checkedAll: boolean = true;
  private contadores: any = [];

  private uploader: FileUploader = new FileUploader({ url: environment.app_url + '/file/upload' });

  private attachmentList: any = [];
  private attachToUploaded: boolean = false;
  resetUpload1: boolean;


  private view_serie_contador: boolean = false;
  private is_alterar_titularidade: boolean = false;
  private contador_join: boolean = true;
  private isAlterarMedicao: boolean = false;
  private contador_title: string = "Associar";
  private causasArray: any = [];
  private sintomasArray: any = [];
  private filter = {
    municipio_id: null
  }

  private current_rota = {
    rota_header_id: null,
    descricao: null,
    local_consumo_id: null
  };

  private view_rotas: boolean = false;

  private contador = {
    contador_antigo: null,
    id: null,
    numero_serie: null,
    marca: null,
    ultima_leitura: null,
    modelo: null,
    data_leitura: null,
    tipo_contador: null,
    classe_precisao: null,
    medicao: null,
    calibre: null,
    digitos: null,
    fabricante: null,
    centro_distribuicao: null,
    precisao: null,
    leitura: null,
    leitura_origem: null,
    contrato_id: null,
    contador_id: null,
    is_leitura_inicial: false,
    new_leitura_by_contrato: false,
    observacao: null
  }

  private cliente = {
    id: null,
    nome: null,
    telefone: null,
    morada: null,
    numero_identidade: null,
    tipo_identidade_id: null,
    tipo_cliente_id: null,
    email: null,
    provincia_id: null,
    provincia: null,
    municipio_id: null,
    municipio: null,
    genero_id: null,
    genero: null,

    conta_id: null,
    numero_conta: null,
    direccao_id: null,
    direccao: null,

    tipo_identidade: null,
    numero_digitos: null,
    numero_identificacao: null,
    tipo_cliente: null,

    gestor_cliente_id: null,
    gestor_cliente: null,
    gestor_cliente_telefone: null
  }

  private ordemServico = {
    data_prevista: null,
    prioridade_id: null,
    classificacao_id: null,
    categoria_descricao: null,
    tipo_id: null,
    email: null,
    descricao: null,
    cliente_contrato_id: null
  }

  private classificacoes: any = [];
  private tipos: any = [];

  dropdownSettingsDepartamentos = {};
  private departamentoArray: any = [];
  private dropdownDepartamentos: any = [];

  private titularidade: any;
  private contrato = {

    id: null,
    tipo_contracto_id: null,
    tipo_contrato: null,
    tipo_medicao_id: null,
    tipo_medicao: null,
    tipo_medicao_slug: null,
    tipo_facturacao_id: null,
    tipo_facturacao: null,
    tipologia_cliente_id: null,
    tipologia_cliente: null,
    tipologia_cliente_juro_mora: null,
    tipologia_cliente_sujeito_corte: null,
    tipologia_cliente_caucao: null,
    nivel_sensibilidade_id: null,
    nivel_sensibilidade: null,
    objecto_contrato_id: null,
    objecto_contrato: null,
    tarifario: null,
    classe_tarifario: null,
    classe_tarifario_consumo_minimo: null,
    classe_tarifario_consumo_maximo: null,
    numero_habitantes: null,
    data_inicio: null,
    data_fim: null,
    rua_id: null,
    morada_correspondencia_flag: false,
    estado_contrato_id: null,
    estado_contrato: null,
    estado_contrato_slug: null,

    data_rescisao: null,
    motivo_recisao_id: null,
    estado_rescisao_id: null,
    motivo_recisao: null,
    motivo_recisao_flag: null,
    conta_id: null,

    contador_id: null,
    numero_serie: null,

    instalacao_sanitaria_qtd: null,
    reservatorio_flag: null,
    reservatorio_capacidade: null,
    piscina_flag: null,
    piscina_capacidade: null,
    jardim_flag: null,
    campo_jardim_id: null,
    campo_jardim: null,
    poco_alternativo_flag: null,
    fossa_flag: null,
    fossa_capacidade: null,
    acesso_camiao_flag: null,
    anexo_flag: null,
    anexo_quantidade: null,
    caixa_contador_flag: null,
    abastecimento_cil_id: null,
    abastecimento_cil: null,

    cliente_id: null,
    cliente: null,
    info_source: null,

    local_consumo_id: null,
    local_instalacao_id: null,
  };

  private dividas: any = []

  private contrato_historico: any = [];
  private series: any[] = []

  private detalhe_historico = {
    id: null,
    operacao: null,
    alocacao: null
  };

  private detalheProps: any;
  public rotas: any = []

  private estado_contratos: any = [];

  private estadosContrato: any = [];
  private municipios: any = [];

  private local_consumo: any = [];
  private local_consumos: any = [];

  private motivo_rescisaos: any = [];
  private estado_rescisaos: any = [];
  private prioridades: any = [];

  private estadoActual: string = null;
  private validateContador: string = null;

  clienteNome: string = null;
  cliente_id: string = null;

  private local_instalacao: any;
  private classe_tarifario: any;
  private empresa: any;
  private isContrato: boolean = false
  private factura = {
    factura_id: null,
    factura_sigla: null,
    status_reason: null,
  }
  current_contrato: any = {}
  private dashboard = {
    countTotal: null,
    countAtivo: null,
    countRescindidos: null,
    countSuspensos: null
  }
  validateButton: boolean = true;

  linhas_charges: any = []
  tipo_facturacao = null
  private object_contrato_rescindido = {
    data_rescisao: new Date(),
    motivo_recisao_id: 5,
    estado_rescisao_id: 3
  }

  constructor(
    private auth: AuthService,
    private http: HttpService,
    private configService: ConfigService,
    private _route: Router,
    private _facturaService: FacturaService,
    private router: Router,
    private excelService: ExcelService,
    private _rotaService: RotaService,
    private config: ConfigModuloService,
    private reportRescisao: RescisaoService,
    private reportRescisaolundanorte: RescisaoLundanorteService,
    private contratoService: ContratoService,
    private api_service: ApiService,
    private flag: ModalOrdemServicoService,
    private api: ApiService,
    private contratoLundasulService: ContratoLundasulService,
    private contratoLundanorteService: ContratoLundanorteService,
    private _fileService: FileService,
    private filterService: FilterService,
  ) {

    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.attachmentList.push(JSON.parse(response));
    }

    this.currentUser = this.auth.currentUserValue;
  }

  leitura: any = {
    contador_id: null,
    contrato_id: null,
    leitura: null,
    data_leitura: moment(new Date()).format('YYYY-MM-DD'),
    nao_leitura: false,
    classificacao: null,
    tipo_facturacao: null
  }

  finalizar_facturacao: any = {
    contrato_id: null,
    serie_id: null
  }

  private url_upload_file: string = null;
  private token = this.auth.currentUserValue ? `${this.auth.currentUserValue.token.type} ${this.auth.currentUserValue.token.token}` : null


  ngOnInit() {
    this.getSeries('FT');
    this.flag.flag = "NOVO-CONTRATO"
    this.getPageFilterData(1);
    this.dashboardFacturacao();
    this.getMunicipios()
    this.getEmpresa();
    this.getEmpresaSigla();
    this.getMotivosRescisao()
    this.getEstadosRescisao();
    this.getEstadoContratos();
    this.searchRotas();
  }

  exportAsXLSX(): void {
/*     var CurrentDate = new Date();
    var nameFile = "Lista_Contratos -" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    this.excelService.exportAsExcelFile(document.getElementsByClassName("exportAsXLSX")[0], nameFile, '9');
 */  }
  exportAsPDF(): void {
    //this.reportLoja.relatorioLoja(this.cobrancas, this.simpleForm.value, 'save');
  }

  imprimirPDF(): void {
    //this.reportLoja.relatorioLoja(this.cobrancas, this.simpleForm.value);
  }

  private getMunicipios() {
    this.http.call_get('municipio/selectBox', null)
      .subscribe(response => { this.municipios = Object(response).data; })
  }

  //dashboard contrato
  private dashboardFacturacao() {
    this.http.call_get('contrato/dashboard', null).subscribe(
      response => {
        this.dashboard.countTotal = this.configService.numberFormat(Object(response).data.countTotal)
        this.dashboard.countAtivo = this.configService.numberFormat(Object(response).data.countAtivo)
        this.dashboard.countRescindidos = this.configService.numberFormat(Object(response).data.countRescindidos)
        this.dashboard.countSuspensos = this.configService.numberFormat(Object(response).data.countSuspensos)
      }
    )
  }

  private getDropDownDepartamentos() {
    this.http.call_get('departamento/selectBox', null).subscribe(
      response => {
        this.dropdownDepartamentos = Object(response).data;

        this.dropdownSettingsDepartamentos = {
          singleSelection: false,
          idField: 'id',
          textField: 'descricao',
          selectAllText: 'Seleccione Todos',
          unSelectAllText: 'DeSeleccionar Todos',
          itemsShowLimit: 10,
          allowSearchFilter: true
        };

      }
    );
  }

  onItemSelectDepartamento(item: any) {
    this.departamentoArray.push(item);
  }
  onSelectAllDepartamentos(items: any) {
    this.departamentoArray = items;
  }

  _changeKind(kind: string) {
    this.tipo_facturacao = kind
    this.leitura.tipo_facturacao = kind
  }

  selectedCliente(cliente) {
    this.getContador(cliente.numero_serie);
    this.validateButton = true;
    this.leitura.contrato_id = cliente.id
    this.leitura.contador_id = cliente.contador_id
    this.cliente = cliente
    this.finalizar_facturacao.contrato_id = cliente.id
    console.log(this.leitura, 'cliente.contrato_id', cliente, this.leitura.contrato_id)
    this.get_charges_individual(cliente.contrato_id)

    this.current_contrato = this.get_current_contrato(cliente)
  }

  get_current_contrato(cliente: any) {
    let contrato = cliente.contrato.filter(contrato => contrato.id == cliente.contrato_id)
    console.log('cliente.contrato', cliente.contrato)
    return contrato[0]
  }
  getUltimaLeitura(id) {
    this.ordemServico.cliente_contrato_id = id
    console.log(id, 'contrato')
    this._facturaService.ultimaLeitura(id).subscribe(
      res => {
        this.ultimaLeitura = res
        console.log(this.ultimaLeitura, res)
      }
    )

  }

  _processarLeituraIndividual() {
    this.configService.loaddinStarter('start')
    this.validateButton = false;
    //====================== VALIDATION ==========================

    if (this.leitura.leitura < 0) {
      this.configService.showAlert('O valor da leitura não deve ser negativo', 'alert-danger', true)
      this.configService.loaddinStarter('stop')
      this.validateButton = true;
      return
    }
    console.log('ultima leitura==>', this.ultimaLeitura)
    if (this.ultimaLeitura && this.ultimaLeitura.leitura > this.leitura.leitura) {
      this.configService.showAlert('Ultima Leitura não deve ser maior que leitura Inicial', 'alert-danger', true)
      this.configService.loaddinStarter('stop')
      this.validateButton = true;
      return
    }
    if (this.leitura.data_leitura == null) {
      this.configService.showAlert('A data da leitura é obrigatória', 'alert-danger', true)
      this.configService.loaddinStarter('stop')
      this.validateButton = true;
      return
    }

    this.finalizar_facturacao.contrato_id = this.leitura.contrato_id

    this._facturaService.LeituraCicloIndividual(this.leitura)
      .subscribe(
        res => {

          console.log(Object(res).code)

          if (Object(res).code != 200) {
            this.validateButton = true;
          }

          if (Object(res).code == 100) {
            this.alert_consumo_excessivo()

            this.validateButton = true;
          }

          console.log('this.leitura.contrato_id', this.leitura.contrato_id)
          this.get_charges_individual(this.leitura.contrato_id)
          this.configService.loaddinStarter('stop')
        }
      )
  }
  alert_consumo_excessivo() {

    Swal.fire({
      title: 'Você tem certeza?',
      text: "Facturar consumo excessivo!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, facturar',
      cancelButtonText: 'Não, Cancelar!',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.configService.loaddinStarter('start')
        this.configService.showAlert('Processamento em andamento', 'alert-success', true)
        this.facturar_consumo_excessivo()
      }
    })
  }


  alert_FacturacoCicle() {


    this.finalizar_facturacao.contrato_id = this.leitura.contrato_id
    console.log("get in factura ciclo")
    Swal.fire({
      title: 'Aviso!',
      text: "Deseja fazer a última ?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, facturar',
      cancelButtonText: 'Não, não facturar!',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {

        ($("#CreateLeituraEFacturar") as any).modal("show")

      } else {
        if (this.totalDivida.temDivida === false && this.finalizar_facturacao.serie_id == null) {
          this.saveRescisaoContrato(false, false);
          this._facturarIndividual();
          return
        }
        this.alert_FacturacoFinal()
      }
    })
  }

  alert_FacturacoFinal() {
    /*   if(this.totalDivida.temDivida === false){
          this.saveRescisaoContrato(false, false);
          this._facturarIndividual();
          return
      } */


    this.finalizar_facturacao.contrato_id = this.leitura.contrato_id
    if (this.isContrato) {
      /* Swal.fire({
        title: 'Aviso!',
        text: "Existe facturas em aberto, rescindir contrato?",
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, rescindir',
        cancelButtonText: 'Não, não rescindir!',
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
       */
      //  this.saveRescisaoContrato(false, false);
      this._facturarIndividual();
      ($("#CreateLeituraEFacturar") as any).modal("hide")
      this.alertToRescindirContrato()
      /*
      }
  }) */
    } else {
      Swal.fire({
        title: 'Aviso!',
        text: "Existe facturas em aberto, rescindir contrato?",
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, rescindir',
        cancelButtonText: 'Não, não rescindir!',
        reverseButtons: true
      }).then((result) => {
        if (result.value) {

          this.saveRescisaoContrato(false, false);
          this._facturarIndividual();
          ($("#CreateLeituraEFacturar") as any).modal("hide")

        }
      })
    }
  }


  hendleTitularidade(value) {
    this.isContrato = value
  }

  facturar_consumo_excessivo() {
    this._facturaService.fcaturarConsumoExcessivoCicloIndividual(this.leitura)
      .subscribe(
        res => {
          //console.log(this.leitura.contrato_id)
          this.get_charges_individual(this.leitura.contrato_id)
          this.configService.loaddinStarter('stop')
        }
      )
  }

  get_charges_individual(contrato_id) {
    this._facturaService.GetChargesCicloIndividual(contrato_id)
      .subscribe(
        response => {
          this.linhas_charges = response[0].charges
        }
      )
  }
  _initLeitura() {
    this.leitura = {
      contador_id: null,
      leitura: null,
      data_leitura: moment(new Date()).format('YYYY-MM-DD'),
      nao_leitura: false,
      tipo_facturacao: null
    }

    this.finalizar_facturacao = {
      contrato_id: null,
      serie_id: null
    }
  }

  private getSeries(type_doc) {

    this.api.post('serie/selectBoxSeries-activas', { documentos: [type_doc], status: [false, true] })
      .subscribe(response => {
        this.series = Object(response)
      });
  }
  _facturarIndividual() {
    this.configService.loaddinStarter('start')

    if (this.finalizar_facturacao.serie_id == null) {
      //.configService.showAlert('A série para factura é obrigatória', 'alert-danger', true)
      this.configService.loaddinStarter('stop')
      return
    }

    this._facturaService.FacturarCicloIndividual(this.finalizar_facturacao)
      .subscribe(
        res => {
          this._rotaService._closeModal("closeModalCreateLeituraEFacturar")
          this.configService.loaddinStarter('stop')
          this._initLeitura()
        }
      )
  }
  private imprimirContrato(id) {
    this.contratoLundanorteService.imprimirPDFContrato(id)
  }

  private imprimirReciboCaucao(item) {
    var img_logotipo = this.empresa.logotipo;
    var empresa = this.empresa;
    this.contratoService.imprimirReciboCaucao(item, 'imprimir', img_logotipo, empresa);
  }

  private imprimirPDFRescisao(id) {
    this.reportRescisaolundanorte.imprimirRescisaolundanorte(id);
  }
  /*   private imprimirPDFRescisao(id) {
      this.reportRescisao.imprimirRescisao(id);
    } */


  async getEmpresa() {
    await this.http.call_get('empresa/empresa-user', null).subscribe(
      response => {
        this.empresa = Object(response).data
      }
    )
  }

  async getClienteById(id) {
    await this.http.call_get('cliente/getClienteById/' + id, null).subscribe(
      response => {
        this.cliente = Object(response);
      }
    )
  }

  private getDetalhesContrato(id) {

    this.getLocalConsumoByContratoId(id);
    this.getContratoById(id);
  }

  private getLocalConsumoByContratoId(contrato_id) {

    this.configService.loaddinStarter('start');

    this.http.call_get('local-consumo/getLocalConsumoByContratoId/' + contrato_id, null).subscribe(
      response => {

        this.local_consumo = Object(response);

        this.local_consumo.cil_flag = (Object(response) != null) ? true : false;
        this.local_consumo.predio_nome = ((this.local_consumo.cil != null) ? '[' + this.local_consumo.cil + '] ' : '[0000-000-000] ') + ((this.local_consumo.is_predio) ? 'Prédio ' + this.local_consumo.predio_nome + ', ' + (this.local_consumo.predio_andar ? this.local_consumo.predio_andar + ' º Andar - ' : '') + 'Porta ' : 'Residência ') + this.local_consumo.moradia_numero;
        this.configService.loaddinStarter('stop');
      }
    );
  }

  async getClasseTarifarioById(id) {
    await this.http.call_get('classe-tarifario/getClasseTarifarioById/' + id, null).subscribe(
      response => {
        this.classe_tarifario = Object(response);
        //console.log(this.local_instalacao);
      }
    )
  }

  private listarContratos() {
    this.contratos = []
    this.http.filters.filter = this.filter
    this.loadingListarFacturacao = true
    this.http.__call('contrato/listagem', this.http.filters).subscribe(
      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;
        this.contratos = Object(response).data.data;
        this.loadingListarFacturacao = false
      }
    );

  }

  private get contratosChecked(): any[] {
    return this.contratos.filter(val => val.checked === true);
  }

  setCheckedAll(checked: boolean) {
    this.checkedAll = checked;
    this.contratos.forEach(f => f.checked = checked);
  }

  updateChecked(f: any) {
    this.contratos.forEach(element => {
      element.checked = element.id === f.id ? !f.checked : element.checked;
      Object.assign(element);
    });
    const lengthFalse = this.contratos.filter(val => val.checked === true);
    this.checkedAll = lengthFalse.length === this.contratos.length ? true : false;
  }

  private async criarOrdemServico() {

    if (this.contratos === 0) {
      this.configService.showAlert("Nenhuma linha seleccionada", "alert-error", true);
      return;
    }
    if (this.contratosChecked.length == 1) {
      this.configService.showAlert("Seleccione no minimo duas ou mais linhas", "alert-error", true);
      return;
    }

    this.configService.showAlert("A processar...", "alert-info", true);

    const incumprimentos = this.contratosChecked;
    //console.log(incumprimentos)
  }

  private getEstadoContratos() {
    this.http.call_get('estado-contrato/selectBox', null).subscribe(
      response => {
        this.estado_contratos = Object(response);
      }
    );
  }



  private carregando = {
    estado: 'Seleccione o estado',
    tipo: 'Seleccione o tipo'
  }

  private selectBoxEstadosContrato(id: any) {
    this.carregando.estado = 'Carregando...';

    this.http.call_get('estadocontrato/selectBox/' + id, null).subscribe(
      response => {
        this.estadosContrato = Object(response).data;
        this.carregando.estado = 'Seleccione o estado';

      }
    );

  }

  private updateContrato() {

    /*     console.log(this.contrato); */

    this.http.__call('contrato/updatePrecosEstado/' + this.contrato.id, {
      data_inicio: this.contrato.data_inicio,
      data_fim: this.contrato.data_fim,
      estado_contrato_id: this.contrato.estado_contrato_id
    }).subscribe(
      res => {
        if (Object(res).code == 200) {
          this.listarContratos();

          this.configService.showAlert(Object(res).message, "alert-success", true);
          this.getPageFilterData(1);
        } else {
          this.configService.showAlert(Object(res).message, "alert-danger", true);
        }
        this.configService.loaddinStarter('stop');
      }
    );
  }



  //--------------------------------------------------------------------------

  getPageFilterData(page: number) {
    //this.http.filters.pagination.page=1
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1;
    this.listarContratos();
  }
  //--------------------------------------------------------------------------


  private getFactura(factura: any) {
    this.factura = factura;
  }




  /**
   * @name "Anualar Factura"
   * @descriptio "Esta Função permite fazer a anulação de uma deteminada factura"
   * @author "caniggia.moreira@itgest.pt"
   * @param start
   * @param end
   */
  private anularFactura(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (e.target.elements[0].value == "" || e.target.elements[2].value == "") {
      this.configService.showAlert('É obrigatório fornecer o nº da factura e o motivo', 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    } else {
      this.factura.status_reason = e.target.elements[2].value;
      this.http.__call('factura/anular/' + this.factura.factura_id, this.factura).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.listarContratos();
            this.dashboardFacturacao();
            this.configService.clearFormInputs(e);
          }
          this.configService.loaddinStarter('stop');
        }
      );
    }
  }

  goToPageCreateNotaCredito(factura: any) {
    this._route.navigate(['/facturacao/emitir-nota-de-credito', factura.factura_id]);
  }

  private imprimirFactura(id) {
    //this.configService.imprimirFactura(id, "2ª Via");
  }

  calcularPercentual(f: any) {
    var calc = f.total - f.valor_aberto;
    return (f.pago === 0 && f.status === 'N' && f.valor_aberto == null ? 0 : (calc * 100) / f.total) //(f.total === f.valor_aberto ? 0 : f.pago == 1 ? 100 : (calc * 100) / f.total);
  }


  private setDataContratoCliente(contrato) {

    this.contrato = contrato;
    this.contrato.info_source = contrato.info_source;
    this.contrato.estado_contrato_id = null;

    /*
    if (contrato.estado_contrato_slug == "CANCELADO") this.estadoActual = "Cancelado";
    if (contrato.estado_contrato_slug == "TEMPORARIO") this.estadoActual = "Temporário";
    if (contrato.estado_contrato_slug == "ACTIVO") this.estadoActual = "Activo";
    if (contrato.estado_contrato_slug == "CONTRATO") this.estadoActual = "Contrato";
    if (contrato.estado_contrato_slug == "CANCELADO") this.estadoActual = "Cancelado";
    */

    this.estadoActual = contrato.estado_contrato;

  }


  private setDataContrato(item) {

    this.contrato = item;
    this.url_upload_file = environment.app_url + 'contrato/uploadFile/' + item.id;

    /*  console.log(this.contrato);
     console.log(this.url_upload_file); */

  }

  private setContratoData(item) {

    this.contrato = item;

  }

  private getUploadURL() {

    // console.log(environment.app_url + 'contrato/uploadFile/' + this.contrato.id);

    return environment.app_url + 'contrato/uploadFile/' + this.contrato.id;
  }

  private getHistoricoById(id) {

    //console.log(id);

    this.http.call_get('contrato-historico/getHistoricoById/' + id, null).subscribe(
      response => {
        //console.log(Object(response));

        this.contrato_historico = Object(response);
      }
    );
  }


  private getDetalheHistorico(item) {

    this.detalhe_historico = {
      id: item.id,
      operacao: item.operacao,
      alocacao: this.getAlocacao(JSON.parse(item.actualizacao))
    }

    this.detalheProps = this.getPropriedadeDetalhe(JSON.parse(item.historico), JSON.parse(item.actualizacao));

    //console.log(item);


  }

  private getPropriedadeDetalhe(historico, actualizacao) {

    var detalhe: any = [];


    detalhe.push(this.getDadosPropriedade('Alocação', ((historico != null) ? this.getAlocacao(historico) : this._empty), this.getAlocacao(actualizacao)));

    detalhe.push(this.getDadosPropriedade('Estado', ((historico != null) ? this.validatePropValue(historico.estado_contrato) : this._empty), this.validatePropValue(actualizacao.estado_contrato)));
    detalhe.push(this.getDadosPropriedade('CIL', ((historico != null) ? this.validatePropValue(historico.cil) : this._empty), this.validatePropValue(actualizacao.cil)));
    detalhe.push(this.getDadosPropriedade('Cliente', ((historico != null) ? this.validatePropValue(historico.cliente_nome) : this._empty), this.validatePropValue(actualizacao.cliente_nome)));
    detalhe.push(this.getDadosPropriedade('Conta', ((historico != null) ? this.validatePropValue(historico.conta_id) : this._empty), this.validatePropValue(actualizacao.conta_id)));
    detalhe.push(this.getDadosPropriedade('Tipo de Identidade', ((historico != null) ? this.validatePropValue(historico.tipo_identidade) : this._empty), this.validatePropValue(actualizacao.tipo_identidade)));
    detalhe.push(this.getDadosPropriedade('Número de Identificação', ((historico != null) ? this.validatePropValue(historico.numero_identificacao) : this._empty), this.validatePropValue(actualizacao.numero_identificacao)));
    detalhe.push(this.getDadosPropriedade('Telefone', ((historico != null) ? this.validatePropValue(historico.cliente_telefone) : this._empty), this.validatePropValue(actualizacao.cliente_telefone)));
    detalhe.push(this.getDadosPropriedade('Email', ((historico != null) ? this.validatePropValue(historico.cliente_email) : this._empty), this.validatePropValue(actualizacao.cliente_email)));
    detalhe.push(this.getDadosPropriedade('Morada', ((historico != null) ? this.validatePropValue(historico.cliente_morada) : this._empty), this.validatePropValue(actualizacao.cliente_morada)));

    //detalhe.push(this.getDadosPropriedade('cil', ((historico != null) ? this.getEstadoLabel(historico.is_active) : this._empty), this.getEstadoLabel(actualizacao.is_active)));
    detalhe.push(this.getDadosPropriedade('Tipo de Medição', ((historico != null) ? this.validatePropValue(historico.tipo_medicao) : this._empty), this.validatePropValue(actualizacao.tipo_medicao)));
    detalhe.push(this.getDadosPropriedade('Tipo de Contrato', ((historico != null) ? this.validatePropValue(historico.tipo_contrato) : this._empty), this.validatePropValue(actualizacao.tipo_contrato)));
    detalhe.push(this.getDadosPropriedade('Tipo de Facturação', ((historico != null) ? this.validatePropValue(historico.tipo_facturacao) : this._empty), this.validatePropValue(actualizacao.tipo_facturacao)));
    detalhe.push(this.getDadosPropriedade('Tipologia de Clientes', ((historico != null) ? this.validatePropValue(historico.tipologia_cliente) : this._empty), this.validatePropValue(actualizacao.tipologia_cliente)));
    detalhe.push(this.getDadosPropriedade('Objecto de Contrato', ((historico != null) ? this.validatePropValue(historico.objecto_contrato) : this._empty), this.validatePropValue(actualizacao.objecto_contrato)));
    detalhe.push(this.getDadosPropriedade('Nível de Sensibilidade', ((historico != null) ? this.validatePropValue(historico.nivel_sensibilidade) : this._empty), this.validatePropValue(actualizacao.nivel_sensibilidade)));
    detalhe.push(this.getDadosPropriedade('Número de habitantes', ((historico != null) ? this.validatePropValue(historico.numero_habitantes) : this._empty), this.validatePropValue(actualizacao.numero_habitantes)));
    detalhe.push(this.getDadosPropriedade('Número de Utilizadores', ((historico != null) ? this.validatePropValue(historico.numero_utilizadores) : this._empty), this.validatePropValue(actualizacao.numero_utilizadores)));
    detalhe.push(this.getDadosPropriedade('Morada de Correspondência', ((historico != null) ? this.getFlagLabel(historico.morada_correspondencia_flag) : this._empty), this.getFlagLabel(actualizacao.morada_correspondencia_flag)));
    detalhe.push(this.getDadosPropriedade('Morada do Contrato', ((historico != null) ? this.validatePropValue(historico.morada_contrato) : this._empty), this.validatePropValue(actualizacao.morada_contrato)));
    detalhe.push(this.getDadosPropriedade('Tarifário', ((historico != null) ? this.validatePropValue(historico.tarifario_descricao) : this._empty), this.validatePropValue(actualizacao.tarifario_descricao)));
    detalhe.push(this.getDadosPropriedade('Classe de Tarifário', ((historico != null) ? this.validatePropValue(historico.classe_tarifario) : this._empty), this.validatePropValue(actualizacao.classe_tarifario)));
    detalhe.push(this.getDadosPropriedade('Data de Início', ((historico != null) ? this.getDateValue(historico.data_inicio) : this._empty), this.getDateValue(actualizacao.data_inicio)));
    detalhe.push(this.getDadosPropriedade('Data de Fim', ((historico != null) ? this.getDateValue(historico.data_fim) : this._empty), this.getDateValue(actualizacao.data_fim)));

    detalhe.push(this.getDadosPropriedade('Contador', ((historico != null) ? this.validatePropValue(historico.numero_serie) : this._empty), this.validatePropValue(actualizacao.numero_serie)));
    detalhe.push(this.getDadosPropriedade('Última Leitura do Contador', ((historico != null) ? this.validatePropValue(historico.ultima_leitura) : this._empty), this.validatePropValue(actualizacao.ultima_leitura)));

    detalhe.push(this.getDadosPropriedade('Utilizador', ((historico != null) ? this.validatePropValue(historico.user) : this._empty), this.validatePropValue(actualizacao.user)));
    //console.log(detalhe)

    return detalhe;

  }

  private validatePropValue(item) {
    return ((item != null) ? item : this._empty);
  }

  private getEstadoLabel(item) {
    return ((item) ? 'Activo' : 'Desactivo');
  }

  private getFlagLabel(item) {
    return ((item) ? 'Sim' : 'Não');
  }

  private getDateValue(item) {
    return (item == null) ? this._empty : moment(item).format('YYYY-MM-DD');
  }




  private getAlocacao(item) {

    return ((item.cil != null) ? '[' + item.cil + '] ' : '[0000-000-000] ') + ((item.is_predio) ? 'Prédio ' + item.predio_nome + ', ' + this.configService.decimalFormat(item.predio_andar) + 'º Andar - Porta ' : 'Residência ') + this.configService.decimalFormat(item.moradia_numero);

  }

  private getDadosPropriedade(label, item_historico, item_actualizacao) {

    let _label: string = "<div class='col-sm-4'><strong>" + label + "</strong></div>";

    if (item_historico != this._empty && item_historico != item_actualizacao) {
      return _label + "<div class='col-sm-4 text-right'><span class='badge badge-danger'>" + item_historico + "</span></div>"
        + "<div class='col-sm-4'><span class='badge badge-success'>" + item_actualizacao + "</span></div>";
    }

    return _label + "<div class='col-sm-4 text-right'>" + item_historico + "</div>"
      + "<div class='col-sm-4'>" + item_actualizacao + "</div>";
  }


  public DateTimeZone() {
    const event = new Date();
    const parisDate = toDate(event.toISOString())
    const pattern = "yyyy-MM-dd'T'HH:mm"
    const timeOutput = format(parisDate, pattern, { timeZone: 'UTC/GMT+1' })
    return this.datatime = timeOutput.slice(0, 16);
  }



  public getMotivosRescisao() {
    this.configService.loaddinStarter('start');
    this.http.call_get('motivo-rescisao/selectBox/', null).subscribe(
      response => {
        this.motivo_rescisaos = Object(response);
        this.configService.loaddinStarter('stop');
      }
    );
  }

  public getEstadosRescisao() {
    this.configService.loaddinStarter('start');
    this.http.call_get('motivo-rescisao/selectBoxEstado/', null).subscribe(
      response => {
        this.estado_rescisaos = Object(response);
        this.configService.loaddinStarter('stop');
      }
    );
  }

  public getEstadosAlteracao() {
    this.configService.loaddinStarter('start');
    this.http.call_get('motivo-alteracao/selectBoxEstado', null).subscribe(
      response => {
        this.estado_rescisaos = Object(response);

        this.configService.loaddinStarter('stop');
      }
    );
  }

  private getClassificacao(titularidade) {

    this.is_alterar_titularidade = titularidade;

    const uri = (titularidade === true ? 'ordemservico/classificacao-comercial/selectBox' : 'ordemservico/classificacao-comercial/selectBox');

    this.http.call_get(uri, null).subscribe(
      response => {
        this.classificacoes = Object(response).data;
      }
    );
  }

  private getTipoByClassificacao() {

    const uri = (this.is_alterar_titularidade === true ? 'ordemservico/ostipo-trigger-by-flag/selectBox/' + this.ordemServico.classificacao_id : 'ordemservico/ostipo-trigger-by-flag/selectBox/' + this.ordemServico.classificacao_id);
    // const uri = (this.is_alterar_titularidade === true ? 'ordemservico/ostipo-alteracao-titularidade/selectBox/' + this.ordemServico.classificacao_id : 'ordemservico/ostipo-remocao-contador/selectBox/' + this.ordemServico.classificacao_id);

    this.carregando.tipo = 'Carregando...';
    this.http.__call(uri, { flag: "ALTERACAO_DE_TITULARIDADE " }).subscribe(
      response => {
        this.tipos = Object(response).data;
        this.carregando.tipo = 'Seleccione o tipo';

        this.dropdownSettingsTipo = {
          singleSelection: false,
          idField: 'id',
          textField: 'descricao',
          selectAllText: 'Seleccione Todos',
          unSelectAllText: 'DeSeleccionar Todos',
          itemsShowLimit: 10,
          allowSearchFilter: true
        };
      }
    );
  }

  onItemSelectTipo(item: any) {
    this.TipoArray.push(item);
  }
  onSelectAllTipos(items: any) {
    this.TipoArray = items;
  }

  private getAssociacao() {

    this.ordemServico.categoria_descricao = null;

    if (this.ordemServico.tipo_id != null) {
      this.configService.loaddinStarter('start');
      this.http.call_get('ordemservico/ostipo/associacao/' + this.ordemServico.tipo_id, null).subscribe(
        response => {

          this.ordemServico.categoria_descricao = Object(response).data.categoria_descricao,

            this.configService.loaddinStarter('stop');
        }

      );
    }

  }

  private getPrioridade() {
    this.http.call_get('ordemservico/prioridade/selectBox', null).subscribe(
      response => {
        this.prioridades = Object(response).data;
      }
    );
  }

  public getLocaisConsumo(id: number) {

    /* console.log(id); */

    this.configService.loaddinStarter('start');
    this.http.__call('local-consumo/getLocalConsumoByContratoId/' + id, null).subscribe(
      response => {

        this.local_consumos = Object(response);

        /* console.log(this.local_consumos); */

        this.configService.loaddinStarter('stop');
      }
    );

  }

  private cleanFormOT() {
    this.ordemServico.data_prevista = null;
    this.ordemServico.prioridade_id = null;
    this.ordemServico.classificacao_id = null;
    this.ordemServico.tipo_id = null;
    this.ordemServico.categoria_descricao = null;
    this.ordemServico.descricao = null
    this.ordemServico.email = null
    this.ordemServico.cliente_contrato_id = null
  }

  createOrEdit(uri: any, formulario: any, isCreate: boolean) {

    // TODO: usado para fazer a requisição com a api de criação de object
    let uploadData = new FormData();

    uploadData.append("ordemServico", JSON.stringify(formulario));
    uploadData.append("causas", JSON.stringify(this.causasArray));
    uploadData.append("sintomas", JSON.stringify(this.sintomasArray));
    uploadData.append("departamentos", JSON.stringify(this.departamentoArray));

    this.http.$__call(uri, uploadData).subscribe(
      response => {

        if (isCreate && Object(response).code == 200) {
          formulario.reset();
          this.configService.showAlert(Object(response).message, 'alert-success', true);
          this.onReset()
        }

        if (Object(response).code == 200) {
          this.configService.showAlert(Object(response).message, 'alert-success', true);
          this.onReset()
        }
      },
    );
  }

  loadingRescisao = false;
  private saveRescisaoContrato(create_ordem_trabalho, alterar_estado, ordemServico = false) {



    if (this.empresa_sigla.includes('EPASN', 'EPASLS') && alterar_estado == false) {
      this.contrato.data_rescisao = this.object_contrato_rescindido.data_rescisao;
      this.contrato.motivo_recisao_id = this.object_contrato_rescindido.motivo_recisao_id
      this.contrato.estado_rescisao_id = this.object_contrato_rescindido.estado_rescisao_id
    }

    let mensagem
    if (alterar_estado) {
      mensagem = "alteração"
    }
    else {
      mensagem = "rescisão"
    }
    if (this.contrato.motivo_recisao_id == null || this.contrato.motivo_recisao_id == '') {
      this.configService.showAlert(`Motivo de ${mensagem} é obrigatorio`, 'alert-danger', true);
      return;
    } else if (this.contrato.estado_rescisao_id == null || this.contrato.estado_rescisao_id == '') {
      this.configService.showAlert(`Estado de ${mensagem}  é obrigatorio`, 'alert-danger', true);
      return;
    } else if (create_ordem_trabalho && this.ordemServico.data_prevista == null) {
      this.configService.showAlert('Digite a Data Prevista da Ordem de Trabalho', 'alert-danger', true);
      return;
    } else if (create_ordem_trabalho && this.ordemServico.prioridade_id == null) {
      this.configService.showAlert('Seleccione a Prioridade da Ordem de Trabalho', 'alert-danger', true);
      return;
    } else if (create_ordem_trabalho && this.ordemServico.classificacao_id == null) {
      this.configService.showAlert('Seleccione a Classificação', 'alert-danger', true);
      return;
    } else if (create_ordem_trabalho && this.ordemServico.tipo_id == null) {
      this.configService.showAlert('Seleccione o Tipo', 'alert-danger', true);
      return;
    } else if (create_ordem_trabalho && this.ordemServico.descricao == null) {
      this.configService.showAlert('Escreve a Descrição da Ordem de Trabalho', 'alert-danger', true);
      return;
    }
    this.loadingRescisao = true;

    const uri = 'ordemservico/ordem-servico/create';
    if (ordemServico) this.createOrEdit(uri, this.ordemServico, true);


    this.http.__call('contrato/denunciar-rescindir/' + this.contrato.id, {
      contrato_id: this.contrato.id,
      morada_correspondencia_flag: this.contrato.morada_correspondencia_flag,
      tipo_medicao_slug: this.contrato.tipo_medicao_slug,
      contador_id: this.contrato.contador_id,
      motivo_recisao_id: this.contrato.motivo_recisao_id,
      estado_rescisao_id: this.contrato.estado_rescisao_id,
      data_rescisao: this.datatime,

      local_instalacao_id: this.contrato.local_instalacao_id,

      /*rua_id: this.contrato.rua_id,
      numero_moradia: this.local_instalacao.numero,
      is_predio: this.local_instalacao.is_predio,
      predio_nome: this.local_instalacao.predio_nome,
      predio_andar: this.local_instalacao.predio_andar,*/

      leitura: this.contador.leitura,
      data_leitura: this.contador.data_leitura,
      leitura_origem: this.contador.leitura_origem,
      create_ordem_trabalho: create_ordem_trabalho,
      ordem_trabalho: this.ordemServico,
      departamentos: this.departamentoArray,
      isAlteracaoEstado: alterar_estado

    }).subscribe(
      res => {
        console.log("now getting in method", Object(res))
        if (Object(res).code == 500) {
          this.configService.showAlert(Object(res).message, 'alert-danger', true);
        } else if (Object(res).code == 201) {
          this.configService.showAlert(Object(res).message, 'alert-warning', true);
          this.configService.loaddinStarter('stop');

        } else {
          console.log("now getting in method")
          this.configService.showAlert(Object(res).message, 'alert-success', true);
          this.closebutton.nativeElement.click();
          this.configService.loaddinStarter('stop');
          this.listarContratos();
          this.onReset();
        }
        this.loadingRescisao = false;
      }, error => {
        this.loadingRescisao = false;
      }
    );
  }

  onReset() {
    this.contrato.data_rescisao = null;
    this.contrato.motivo_recisao_id = null;
    this.contrato.estado_rescisao_id = null;
    this.ordemServico.data_prevista = null;
    this.ordemServico.classificacao_id = null;
    this.ordemServico.prioridade_id = null;
    this.ordemServico.categoria_descricao = null;
    this.ordemServico.descricao = null;
    this.ordemServico.tipo_id = null;

  }

  getContador(contador) {
    this.validateContador = contador;

    console.log('contador', contador)
  }

  private selectBoxMunicipiosByProvincia(id) {

    this.local_instalacao.distrito_id = null;
    this.local_instalacao.bairro_id = null;
    this.local_instalacao.quarteirao_id = null;
    this.local_instalacao.rua_id = null;

    this.http.call_get('municipio/getMunicipiosByProvincia/' + id, null).subscribe(
      response => {
        this.municipios = Object(response).data;
      }
    );
  }


  private getContratoById(id, contrato = null) {
    this.contador.contador_antigo = contrato.contador_id
    this.configService.loaddinStarter('start');

    this.http.call_get('contrato/getContratoById/' + id, null).subscribe(
      res => {
        this.contrato = Object(res).contrato;
        this.dividas = Object(res).dividas;
        this.dividasV()
        this.configService.loaddinStarter('stop');

      }
    );

  }

  public dividasV() {

    this.totalDivida = this.dividas.reduce(function (acumulador, atual) {
      let temDivida
      if (atual.valor_aberto) {
        temDivida = true
      }
      return {
        totalDivida: acumulador.totalDivida + atual.valor_aberto,
        temDivida
      }
    }, {
      totalDivida: 0,
      temDivida: false
    })

  }

  public getEditContrato(item) {

    this.contrato = item;

    this._route.navigate(['/contratos/Update/' + item.id + '/' + item.conta_id + '/' + item.cliente_id + '/' + item.tarifario_id + '/' + item.classe_tarifario_id]);

  }


  public getConfiguracaos() {

    let result = null;

    const slugs = [
      this.config.provincia_default,
      this.config.estado_rescisao_default,
    ];


    for (let index = 0; index < slugs.length; index++) {

      this.http.__call('configuracao/getConfiguracaobySlug/' + slugs[index], null).subscribe(
        response => {
          if (Object(response).code != 200) {
            result = null;
          }
          else {

            result = Object(response).data;

            if (slugs[index] == this.config.provincia_default) {
              this.local_instalacao.provincia_id = result.valor;
              this.selectBoxMunicipiosByProvincia(result.valor);
            }

            if (slugs[index] == this.config.estado_rescisao_default) {
              this.contrato.estado_rescisao_id = result.valor;
              //console.log(this.contrato.estado_rescisao_id)
            }
          }
        });
    }

  }

  download(index) {
    var filename = this.attachmentList[index].uploadname;

    this._fileService.downloadFile(filename)
      .subscribe(
        data => saveAs(data, filename),
        error => console.error(error)
      );
  }


  public uploadFiles() {

    //this.configService.loaddinStarter('start');


    let formData = new FormData();
    let item: any;


    var reader = new FileReader();
    reader.readAsDataURL(this.attachmentList[0]);
    reader.onload = (event: any) => {
      item = reader.result;
      formData.append('files', item);
    }


    //console.log(formData);

    this.http.__call('contrato/uploadFiles', formData).subscribe(
      res => {
        this.contrato = Object(res);
        this.configService.loaddinStarter('stop');

        //console.log(this.contrato)
      }
    );

  }

  docUpload(event) {
    //console.log('ApiResponse -> docUpload -> Event: ', event.target.files);

    this.configService.validateContratoPatch(this.contrato.id);

    this.attachmentList = event.target.files;


    //console.log(this.attachmentList)
  }


  public getContadorById(id) {

    this.view_serie_contador = false;

    this.contador_title = "Retirar";
    this.contador_join = false;

    this.http.call_get('contador/getContadorById/' + id, null).subscribe(
      response => {

        this.setContador(Object(response));


        this.configService.loaddinStarter('stop');
      }
    );

    //console.log(this.contadores);

  }

  public getContadoresDisponiveisByNumeroSerie(flag = true) {


    this.view_serie_contador = false;
    let aux_serie = this.contador.numero_serie;

    this.contador_title = "Associar";
    this.contador_join = true;

    this.http.__call('contador/getContadoresDisponiveisByNumeroSerie', { start: 1, end: 15, search: this.contador.numero_serie }).subscribe(
      response => {

        this.contadores = Object(response).data.data;

        //console.log(Object(response));
        this.configService.loaddinStarter('stop');
      }
    );

    this.cleanContador();
    this.contador.numero_serie = aux_serie;

    //console.log(this.contadores);

  }

  public setContador(item: any) {

    console.log(item);

    this.contador = {
      ...this.contador,
      id: item.id,
      numero_serie: item.numero_serie,
      marca: item.marca,
      ultima_leitura: item.ultima_leitura,
      modelo: item.modelo,

      tipo_contador: item.tipo_contador,
      classe_precisao: item.classe_precisao,
      medicao: item.medicao,
      calibre: item.calibre,
      digitos: item.digitos,
      fabricante: item.fabricante,
      centro_distribuicao: item.centro_distribuicao,

      precisao: null,
      leitura: 0,
      data_leitura: moment(new Date()).format('YYYY-MM-DD'),
      leitura_origem: null,
      contrato_id: this.contrato.id,
      is_leitura_inicial: true,
      contador_id: item.id,
      new_leitura_by_contrato: true,
      observacao: null
    }

    this.view_serie_contador = false;
  }

  public cleanContador() {

    //console.log(item);

    this.contador = {
      ...this.contador,
      id: null,
      numero_serie: null,
      marca: null,
      ultima_leitura: null,
      modelo: null,

      tipo_contador: null,
      classe_precisao: null,
      medicao: null,
      calibre: null,
      digitos: null,
      fabricante: null,
      centro_distribuicao: null,

      precisao: null,
      leitura: 0,
      data_leitura: null,
      leitura_origem: null,
      contrato_id: null,
      contador_id: null,
      is_leitura_inicial: false,
      new_leitura_by_contrato: false,
      observacao: null
    }

    this.view_serie_contador = false;
  }


  public saveContador() {

    Swal.fire({
      title: 'Aviso!',
      text: "Deseja alterar a medição do contrato para leitura ?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, Alterar',
      cancelButtonText: 'Não, não Alterar!',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.isAlterarMedicao = true;
      }
      this.configService.loaddinStarter('start');
      this.http.__call('local-consumo/updateLocalConsumoByContador/' + this.contrato.id, {
        contador_id: this.contador.id,
        contador_antigo: this.contador.contador_antigo,
        contador_join: this.contador_join,
        data_leitura: this.contador.data_leitura,
        isAlterarMedicao: this.isAlterarMedicao,
        user_id: this.currentUser.user.id
      }).subscribe(
        response => {

          if (Object(response).code == 200) {
            this.listarContratos();

            if ((this.contador.leitura != null) && (this.contador.new_leitura_by_contrato == true)) {
              this.create_leitura_inicial();
            }
            this.cleanContador();
            this.view_serie_contador = false;
          }

          this.getContadoresDisponiveisByNumeroSerie(false);
          this.configService.loaddinStarter('stop');
        }
      );
    })

  }

  create_leitura_inicial() {
    this._rotaService.CreateLeitura(this.contador)
      .subscribe(
        response => { }
      )
  }


  private saveAlteracao(create_ordem_trabalho) {
    let conta_nova = this.contas[0].numero_conta
    this.loadingRescisao = true;

    this.http.__call('contrato/alteracao/' + this.contrato.id, {
      contrato_id: this.contrato.id,
      conta_actual: this.contrato.conta_id,
      motivo_recisao_id: this.contrato.motivo_recisao_id,
      create_ordem_trabalho: create_ordem_trabalho,
      ordem_trabalho: this.ordemServico,
      departamentos: this.departamentoArray,
      conta_nova

    }).subscribe(
      res => {
        console.log("create_ordem_trabalho", create_ordem_trabalho, res)
        if (Object(res).code == 500) {
          this.configService.showAlert(Object(res).message, 'alert-danger', true);
        } else if (Object(res).code == 201) {
          this.configService.showAlert(Object(res).message, 'alert-warning', true);
          this.configService.loaddinStarter('stop');

        } else {
          this.configService.showAlert(Object(res).message, 'alert-success', true);
          this.closebuttontitularidade.nativeElement.click();
          this.configService.loaddinStarter('stop');
          this.listarContratos();
          this.cleanFormOT();
          this.alertCreateFacturacao();
        }
        this.loadingRescisao = false;
      }, error => {
        this.loadingRescisao = false;
      }
    );


  }


  private saveAlteracaoEMigraContaCorrente(create_ordem_trabalho) {
    let conta_nova = this.contas[0].numero_conta
    //console.log(this.ordemServico)

    this.http.__call('contrato/alteracao/' + this.contrato.id, {
      contrato_id: this.contrato.id,
      conta_actual: this.contrato.conta_id,
      motivo_recisao_id: this.contrato.motivo_recisao_id,
      create_ordem_trabalho: create_ordem_trabalho,
      ordem_trabalho: this.ordemServico,
      conta_nova,
      migra_conta_corrente: true

    }).subscribe(
      res => {

        if (Object(res).code == 500) {
          this.configService.showAlert(Object(res).message, 'alert-danger', true);
        } else if (Object(res).code == 201) {
          this.configService.showAlert(Object(res).message, 'alert-warning', true);
          this.configService.loaddinStarter('stop');

        } else {
          this.configService.showAlert(Object(res).message, 'alert-success', true);
          this.closebuttontitularidade.nativeElement.click();
          this.configService.loaddinStarter('stop');
          this.listarContratos();
          this.cleanFormOT();
          //  if (this.empresa_sigla.includes('EPASN', 'EPASLS')) {
          this.alertCreateFacturacao();
          //}
        }
        this.loadingRescisao = false;
      }, error => {
        this.loadingRescisao = false;
      }
    );


  }

  canActivateRouterLink(permission: string): boolean {
    return this.auth.canActivateRouterLink(permission);
  }


  private getCliente() {
    this.contas = []
    this.view_client = true;
    this.http.__call('cliente/search-cliente', { start: 1, end: 10, search: this.cliente_nome }).subscribe(
      response => {
        this.clientes = Object(response).data.data;
      })
  }

  private getContas(cliente) {

    this.contas = [];

    this.local_consumo.cil = null,
      this.local_consumo.numero_serie = null,
      this.local_consumo.predio_nome = null

    this.http.call_get('cliente/conta/selectBox/' + cliente.id, null).subscribe(
      response => {
        this.contas = Object(response).data
      })
  }

  private setCliente(client: any) {
    this.getContas(client)
    this.cliente_nome = client.nome

    this.configService.contrato.cliente_id = client.id
    this.configService.contrato.cliente_nome = client.nome
    this.view_client = false;
  }

  private setConta(conta_id: any) {
    this.configService.contrato.conta_id = conta_id;
    console.log("conta_id", conta_id)
  }

  private setContrato(contrato: any) {
    this.configService.contrato.contrato_id = contrato.id
    this.configService.contrato.cil_disponivel = contrato.cil;
    console.log("cil", contrato.cil)
  }

  private clear() {
    this.cliente_nome = ""
    this.clientes = []
  }

  private getEmpresaSigla() {
    this.http.call_get("get-empresa-sigla")
      .subscribe(res => {
        this.empresa_sigla = Object(res).data
      })
  }
  private setDataTitularidade(contrato: any) {
    const data = { contratoId: contrato.id }
    this.titularidade = data
  }


  alertCreateFacturacao() {
    Swal.fire({
      title: `Pretende criar uma nova Factura?`,
      text: "Criar Factura!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, Pretendo!',
      cancelButtonText: 'Não, Pretendo!',
    }).then((result) => {
      if (result.value) {

        this.getContratoById(this.contrato.id);
        this.getMotivosRescisao()
        this.getEstadosRescisao();
        this.getLocalConsumoByContratoId(this.contrato.id);

        ($("#CreateLeituraEFacturar") as any).modal("show")
        //this.goCreate_contrato();
        //($('#rescindirContratoModal') as any).modal('show'); // to show the modal
      } else {
        this.alertToRescindirContrato()
        //($('#rescindirContratoModal') as any).modal('hide'); // to hide the modal
        /* Swal.fire({
          position: 'center',
          type: 'error',
          title: 'Contrato sem cil associado',
          showConfirmButton: false,
          timer: 2000
        }) */
      }
    })
  }

  alertToRescindirContrato() {
    Swal.fire({
      title: this.totalDivida.totalDivida > 0 ? 'Cliente com dívida, pretende ' +
        `rescindir esse contrato e criar um novo?` :
        'Pretende ' + `rescindir esse contrato e criar um novo?`,
      text: "Você não poderá reverter isso!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, Pretendo!',
      cancelButtonText: 'Não, Pretendo!',
    }).then((result) => {
      if (result.value) {
        if (this.configService.contrato.cil_disponivel) {

          this.getContratoById(this.contrato.id);
          this.getMotivosRescisao()
          this.getEstadosRescisao();
          this.getLocalConsumoByContratoId(this.contrato.id);
          this.saveRescisaoContrato(false, false);
          this.goCreate_contrato();
          //($('#rescindirContratoModal') as any).modal('show'); // to show the modal
        } else {
          //($('#rescindirContratoModal') as any).modal('hide'); // to hide the modal
          Swal.fire({
            position: 'center',
            type: 'error',
            title: 'Contrato sem cil associado',
            showConfirmButton: false,
            timer: 2000
          })
        }
      }

    })
  }

  disassociateCilOfContract(local_consumo) {
    Swal.fire({
      title: `Tens certeza que deseja desassociar desse contrato o cil ${local_consumo.cil}`,
      text: "Você não poderá reverter isso!",
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, Desejo!',
      cancelButtonText: 'Não, Desejo!',
    }).then((result) => {
      if (result.value) {
        this.disassociateCil(local_consumo.local_consumo_id);
      } else {
        this.swalAlert('Operação Cancelada!', 'info');
      }
    })
  }

  swalAlert(title: any, type: any) {
    Swal.fire({
      position: 'center',
      type: type,
      title: title,
      showConfirmButton: false,
      timer: 2000
    })
  }

  disassociateCil(local_consumo_id: any) {
    this.http.call_get("disassociar-cil-contrato/" + local_consumo_id)
      .subscribe(res => {
        this.listarContratos();
        if (Object(res).code == 200) {
          this.swalAlert('Cil desassociado com sucesso!', 'success')
        }
      })
  }

  convertNumberToPositive(event) {
    this.contador.leitura = Math.abs(this.contador.leitura);
  }

  public getRotas(search) {
    this.http.__call('get/rotas', { search: search })
      .subscribe(res => {
        this.rotas = Object(res).data;
      })
  }

  searchRotas() {
    this.view_rotas = true;
    this.getRotas(this.current_rota.descricao)
  }

  set_cliente_sem_rotas(item: any) {
    this.current_rota.local_consumo_id = item.local_consumo_id
    this.view_rotas = false
    this.current_rota.rota_header_id = null
    this.current_rota.descricao = null
  }

  set_view_rotas(item: any) {
    this.current_rota.rota_header_id = item.id
    this.current_rota.descricao = item.descricao
    this.view_rotas = false;
  }

  associar_roteiro_a_rota() {
    this.http.__call("associar-roteiro-a-rota", this.current_rota)
      .subscribe(res => {
        this.listarContratos();
      })
  }

  goCreate_contrato() {
    this.router.navigate(['/contrato'])
  }

  clearSearch() {
    this.http.filters.search = ''
    //this.getPageFilterData(1)
  }
}
