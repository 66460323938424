import { Injectable, EventEmitter } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
//import { environment } from '@env/environment'

import { Router } from '@angular/router'
import { environment } from 'src/environments/environment'


@Injectable({
    providedIn: 'root'
})

export class GeneroService {

    private token = localStorage.getItem('sessionToken')

    private headers = new HttpHeaders()
    .set('content-type', 'application/json')
    .set('Access-Control-Allow-Origin', '*')
    .set('Authorization', `Bearer ${this.token}`)

    constructor(
      private router: Router,
      private _http_client: HttpClient
    ){}

    getGenres() {
      return   this._http_client.get<any>(`${environment.app_url}api/${environment.apiVersion}/generos`, { 'headers': this.headers })
    }

    CreateGenre(genre: any) {
      return   this._http_client.post<any>(`${environment.app_url}api/${environment.apiVersion}/generos`, genre, { 'headers': this.headers })
    }
}
