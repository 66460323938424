import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { ExcelService } from 'src/app/services/excel.service';

@Component({
  selector: 'app-registo-interno',
  templateUrl: './registo-interno.component.html',
  styleUrls: ['./registo-interno.component.css']
})
export class RegistoInternoComponent implements OnInit {


  public armazem: any;
  private loading: boolean = false;
  public filters = {
    search: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    pagination: {
      perPage: 5,// Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima, 
      lastPage: null,
      total: null // defini o total de registo da BD
    },
    filter: null // subelemente do filter
  }


  constructor(private auth: AuthService, private http: HttpService, private configService: ConfigService,private excelService: ExcelService) { }
 
  private items: any = [];

  ngOnInit() {
    this.getPageFilterData(1);

  }


  private listarArmazem() {

    //this.configService.loaddinStarter('start');

    this.loading = true
    this.http.__call('armazem/listar', this.filters).subscribe(
      response => {
        this.filters.pagination.lastPage = Object(response).data.lastPage;
        this.filters.pagination.page = Object(response).data.page;
        this.filters.pagination.total = Object(response).data.total;
        this.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.loading = false
      }
    );
  }
 
  getPageFilterData(page: number) {
    if (this.filters.pagination.perPage == null) {
      return;
    }
    this.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
    this.listarArmazem();
  }

  
  private setDataArmazem(armazem) {
    this.armazem = armazem
  }
 



}
