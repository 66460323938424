


import { Injectable, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import { ConfigService } from 'src/app/providers/config/config.service';

@Injectable({
  providedIn: 'root'
})
export class ReportClienteService {

  public pdfEvent = new EventEmitter<Object>();

  constructor(private configService: ConfigService) { }

  public relatorioExtraccaoClientes(p = 'print', file: any,imgData) {

    // var imgData = this.configService.logotipoBase64;
    var doc = new jsPDF('l', '', 'a3')
    doc.setProperties({
      title: 'Relatorio_Extraccao_Clientes',
      subject: 'Report',
      author: 'Unig',
      keywords: '',
      creator: 'VistaWater'
    });

    var today = moment().format("DD-MM-YYYY H:mm:ss");
    // doc.addImage(imgData, 'JPEG', 140, 10, 28, 18)
    doc.addImage(imgData, 'JPEG',5, 10, 28, 18)
    const totalPagesExp = "{total_pages_count_string}";

    doc.setFontType('bold')
    doc.setFontSize(15)
    doc.text(150, 25, 'Relatório de Extracção de Clientes', 'center')

    doc.autoTable({
      html: file,
      didParseCell: function (data) {
        var rows = data.table.body;
        if (data.row.index === 0) {
        data.cell.styles.fontStyle = 'bold';
        data.cell.styles.textColor = "white";
        data.cell.styles.fillColor = [32, 95, 190];
        }
      },
      didDrawPage: data => {
        let footerStr = "Página " + doc.internal.getNumberOfPages();
        if (typeof doc.putTotalPages === 'function') {
          footerStr = footerStr + " de " + totalPagesExp;
        }
        doc.setFontSize(10);
        doc.text(today,data.settings.margin.left, doc.internal.pageSize.height - 10)
        doc.text(footerStr,  393,  doc.internal.pageSize.height - 10, 'left');
      },
      styles: { textColor: [0, 0, 0], font: 'helvetica', fontStyle: 'normal' },

      columnStyles: {
        0: { cellWidth: 9, halign: 'center', fontSize: 10 },
        1: { cellWidth: 15, halign: 'center', fontSize: 10 },
        2: { cellWidth: 15, halign: 'center', fontSize: 10 },
        3: { cellWidth: 15, halign: 'center', fontSize: 10 },
        4: { cellWidth: 15, halign: 'center', fontSize: 10 },
        5: { cellWidth: 15, halign: 'center', fontSize: 10 },
        6: { cellWidth: 15, halign: 'center', fontSize: 10 },
        7: { cellWidth: 15, halign: 'center', fontSize: 10 },
        8: { cellWidth: 15, halign: 'center', fontSize: 10 },
        9: { cellWidth: 15, halign: 'center', fontSize: 10 },
        10: { cellWidth: 15, halign: 'center', fontSize: 10 },
        11: { cellWidth: 15, halign: 'center', fontSize: 10 },
        12: { cellWidth: 15, halign: 'center', fontSize: 10 },
        13: { cellWidth: 15, halign: 'center', fontSize: 10 },
        14: { cellWidth: 15, halign: 'center', fontSize: 10 },
        15: { cellWidth: 15, halign: 'center', fontSize: 10},
        16: { cellWidth: 15, halign: 'center', fontSize: 10 },
        17: { cellWidth: 15, halign: 'center', fontSize: 10  },
        18: { cellWidth: 15, halign: 'center', fontSize: 10 },
        19: { cellWidth: 15, halign: 'center', fontSize: 10 },
        20: { cellWidth: 15, halign: 'center', fontSize: 10 },
        21: { cellWidth: 15, halign: 'center', fontSize: 10 },
        22: { cellWidth: 15, halign: 'center', fontSize: 10 },
        23: { cellWidth: 15, halign: 'center', fontSize: 10 },
        24: { cellWidth: 15, halign: 'center', fontSize: 10 },
        25: { cellWidth: 15, halign: 'center', fontSize: 10 },
        26: { cellWidth: 15, halign: 'center', fontSize: 10 },
        27: { cellWidth: 15, halign: 'center', fontSize: 10 },
        28: { cellWidth: 15, halign: 'center', fontSize: 10 },
      },
      rowsStyles: {},
      startY: 60,
      theme: 'grid',

    })

    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }

    if (p === 'save') {
      doc.save();
    } else {
      doc.autoPrint();
      doc.output("dataurlnewwindow");
    }
  }

  public relatorioClientes(p = 'print', file: any,imgData) {


    var today = moment().format("DD-MM-YYYY H:mm:ss");
    var doc = new jsPDF('l', '', 'a4')
    doc.setProperties({
      title: 'Listagem_Clientes'+today,
      subject: 'Report',
      author: 'Unig',
      keywords: '',
      creator: 'bewater'
    });
    doc.addImage(imgData, 'JPEG',10, 10, 28, 18)
    const totalPagesExp = "{total_pages_count_string}";

    doc.setFontType('bold')
    doc.setFontSize(15)
    // doc.text(145, 60, 'Relatório', 'center')
    doc.setFontSize(15)
    doc.text(150, 25, 'Listagem de Clientes', 'center')


    doc.autoTable({
      html: file,
      didParseCell: function (data) {
        var rows = data.table.body;
        if (data.row.index === 0) {
          data.cell.styles.fontStyle = 'bold';
          data.cell.styles.textColor = "white";
          data.cell.styles.fillColor = [32, 95, 190];
        }
      },
      didDrawPage: data => {
        let footerStr = "Página " + doc.internal.getNumberOfPages();
        if (typeof doc.putTotalPages === 'function') {
          footerStr = footerStr + " de " + totalPagesExp;
        }
        doc.setFontType('bold')
        doc.setFontSize(10);
        doc.text(today,data.settings.margin.left, doc.internal.pageSize.height - 2)
        doc.setFontSize(10);
        doc.text(footerStr, 393, doc.internal.pageSize.height - 2, 'left');
      },
      styles: { textColor: [0, 0, 0] },
    tableWidth: 10,
      columnStyles: {
        0: {cellWidth: 15,halign: 'center'},
        1: {cellWidth: 20,halign: 'center'},
        2: {cellWidth: 20,halign: 'center'},
        3: {cellWidth: 20,halign: 'center'},
        4: {cellWidth: 20,halign: 'center'},
        5: {cellWidth: 20,halign: 'center'},
        6: {cellWidth: 20,halign: 'center'},
        7: {cellWidth: 20,halign: 'center'},
        8: {cellWidth: 20,halign: 'center'},
        9: {cellWidth: 20,halign: 'center'},
        10: {cellWidth: 20,halign: 'center'},
        11: {cellWidth: 20,halign: 'center'},
        12: {cellWidth:20,halign: 'center'},
        13: {cellWidth:20,halign: 'center'},
      },
      rowsStyles: {},
      startY: 58,
      theme: 'grid',

    })

    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }

    if (p === 'save') {
      doc.save();
    } else {
      doc.autoPrint();
      doc.output("dataurlnewwindow");
    }


  }

  public imprimirAvisoDeCorte(data, original: any = 'Original', report: string = 'imprimir') {



  }
}
