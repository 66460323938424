import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  constructor() { }

  filter(simpleForm:any,_cobrancaCopy1:any){
    _cobrancaCopy1=_cobrancaCopy1.filter(res=>{
      let forma_paga=res.linhaPagamentos[0]?res.linhaPagamentos[0].forma_pagamento?res.linhaPagamentos[0].forma_pagamento.id:null:null
        if (simpleForm.value.forma_pagamento != null && simpleForm.value.forma_pagamento != 'null' && simpleForm.value.forma_pagamento != 'T') {
          if(forma_paga==simpleForm.value.forma_pagamento) return res
        }else return res
       })

    _cobrancaCopy1=_cobrancaCopy1.filter(res=>{
      let loja=res?res.caixas?res.caixas.loja?res.caixas.loja.id:null:null:null
        if (simpleForm.value.loja != null && simpleForm.value.loja != 'null' && simpleForm.value.loja != 'T') {
          if(loja==simpleForm.value.loja) return res
        }else return res
    })
    
    _cobrancaCopy1=_cobrancaCopy1.filter(res=>{
      let user=res.user.id
        if (simpleForm.value.operador != null && simpleForm.value.operador != 'null' && simpleForm.value.operador != 'T') {
          console.log("operator")
          if(user==simpleForm.value.operador) return res
        }else return res
    })

    _cobrancaCopy1=_cobrancaCopy1.filter(res=>{
      let municipio=res.linhasRecibos[0]?res.linhasRecibos[0].factura?res.linhasRecibos[0].factura.contrato?res.linhasRecibos[0].factura.contrato.localconsumoVerifyLocate[0]?res.linhasRecibos[0].factura.contrato.localconsumoVerifyLocate[0].municipio_id:null:null:null:null
        if (simpleForm.value.municipio_id != null && simpleForm.value.municipio_id != 'null' && simpleForm.value.municipio_id != 'T') {
          if(municipio==simpleForm.value.municipio_id) return res
        }else return res
    })

    _cobrancaCopy1=_cobrancaCopy1.filter(res=>{
      let distrito=res.linhasRecibos[0]?res.linhasRecibos[0].factura?res.linhasRecibos[0].factura.contrato?res.linhasRecibos[0].factura.contrato.localconsumoVerifyLocate[0]?res.linhasRecibos[0].factura.contrato.localconsumoVerifyLocate[0].distrito_id:null:null:null:null
  
        if (simpleForm.value.distrito_id != null && simpleForm.value.distrito_id != 'null' && simpleForm.value.distrito_id != 'T') {
          if(distrito==simpleForm.value.distrito_id) return res
        }else return res
    })

    _cobrancaCopy1=_cobrancaCopy1.filter(res=>{
      let bairro=res.linhasRecibos[0]?res.linhasRecibos[0].factura?res.linhasRecibos[0].factura.contrato?res.linhasRecibos[0].factura.contrato.localconsumoVerifyLocate[0]?res.linhasRecibos[0].factura.contrato.localconsumoVerifyLocate[0].bairro_id:null:null:null:null
       if (simpleForm.value.bairro_id != null && simpleForm.value.bairro_id != 'null' && simpleForm.value.bairro_id != 'T') {
          if(bairro==simpleForm.value.bairro_id) return res
        }else return res
    })

    return _cobrancaCopy1
  }



  filterContrato(filter:any,_cobrancaCopy1:any){
      let search=filter.search,
          municipio_id=filter.filter.municipio_id,
         order=filter.orderBy,

         object ={
            morada:function(x,y){
              x=x?x.conta?x.conta.cliente?x.conta.cliente.morada:'':'':''
              y=y?y.conta?y.conta.cliente?y.conta.cliente.morada:'':'':''

              let a=x.toUpperCase(),
                  b=y.toUpperCase()
               return a==b?0:a>b?1:-1
            },
            telefone:function(x,y){
              x=x?x.conta?x.conta.cliente?x.conta.cliente.telefone:'':'':''
              y=y?y.conta?y.conta.cliente?y.conta.cliente.telefone:'':'':''
              let a=x.toUpperCase(),
                  b=y.toUpperCase()
               return a==b?0:a>b?1:-1
            },
            nome :function(x,y){
              x=x?x.conta?x.conta.cliente?x.conta.cliente.nome:'':'':''
              y=y?y.conta?y.conta.cliente?y.conta.cliente.nome:'':'':''
              let a=x.toUpperCase(),
                  b=y.toUpperCase()
               return a==b?0:a>b?1:-1
            },
         };

     _cobrancaCopy1=_cobrancaCopy1.filter(res=>{
            let id=res?res.id.toString():null
            let cliente_nome=res?res.conta?res.conta.cliente?res.conta.cliente.nome.toLowerCase():null:null:null
            let conta=res?res.conta?res.conta.contaDescricao.toLowerCase():null:null
            let tipo_contrato=res?res.TipoContrato?res.TipoContrato.descricao.toLowerCase():null:null
            let cil=res?res.local_consumos[0]?res.local_consumos[0].cil.toLowerCase():'':''
            let estado_contratoes=res?res.EstadoContrato?res.EstadoContrato.nome.toLowerCase():null:null
            let tarifario=res?res.Tarifario?res.Tarifario.descricao.toLowerCase():null:null

        if (search) {
          
          if(id.includes(search.toLowerCase()))return res
          console.log(cil,search.toLowerCase())
          if(cliente_nome.includes(search.toLowerCase())) return res
          if(conta.includes(search.toLowerCase())) return res
          if(tipo_contrato.includes(search.toLowerCase())) return res
          if(cil.includes(search.toLowerCase())) return res
         if(estado_contratoes.includes(search.toLowerCase())) return res
         if(tarifario.includes(search.toLowerCase())) return res 

        }else return res
       
        
       })

    _cobrancaCopy1=_cobrancaCopy1.filter(res=>{
      let minicipio=res?res.local_consumos[0]?res.local_consumos[0].municipio_id:null:null
        if (municipio_id!=null && municipio_id!='null' && municipio_id != 'T') {
          console.log(minicipio)
          if(minicipio==municipio_id) return res
        }else return res
    })   

    if(order && order != 'created_at'){
        _cobrancaCopy1.sort(function(x,y){
           if(object[order])return object[order](x,y)
        })
    }
    

   
    return _cobrancaCopy1
  }

}
