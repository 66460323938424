import { Component, OnInit, Input, createPlatformFactory } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { ExcelService } from 'src/app/services/excel.service';
import * as moment from 'moment';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-nao-leitura',
  templateUrl: './nao-leitura.component.html',
  styleUrls: ['./nao-leitura.component.css']
})
export class NaoLeituraComponent implements OnInit {

  @Input() simpleFormNaoLeitura: FormGroup;

  private nao_leitura = {
    id: null,
    motivo: null,
    rota_header_id: null,
    rota_run_id: null,
    tipo_nao_leitura_id: null,
    latitude: null,
    longitude: null,
    is_delected: null,
    user_id: null
  };

  public currentUser: any;

  private title: string = "Registar Não Leitura";

  private input_default: boolean = false;
  private input_required: boolean = false;

  private has_rotas_header: boolean = false;

  private items: any = [];

  private rotas_header: any = [];
  private rota_runs: any = [];
  private tipos_nao_leituras: any = [];
  /*  private estados: any = []; */

  constructor(private http: HttpService, private configService: ConfigService, private excelService: ExcelService, private auth: AuthService) {
    this.currentUser = this.auth.currentUserValue;
  }



  exportAsXLSX(): void {
    var CurrentDate = new Date();
    var nameFile = "lista_configuracoes -" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    this.excelService.exportAsExcelFile(document.getElementsByClassName("exportAsXLSXCliente")[0], nameFile);
  }
  exportAsPDF(): void {
    //this.reportLoja.relatorioLoja(this.cobrancas, this.simpleForm.value, 'save');
  }

  imprimirPDF(): void {
    //this.reportLoja.relatorioLoja(this.cobrancas, this.simpleForm.value);
  }

  ngOnInit() {
    this.getPageFilterData(1);

  }

  private getDadosModal() {

  }

  private getNaoLeituras() {

    this.configService.loaddinStarter('start');

    this.http.__call('leituras/nao_leituras', this.http.filters).subscribe(
      response => {

        console.log( Object(response).data )

        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.configService.loaddinStarter('stop');

      }
    );
  }


  view_nao_leitura = false;

  private saveNaoLeitura() {
    console.log(this.nao_leitura);

    if (this.nao_leitura.id == null) {

      this.http.__call('nao-leitura/create', {
        motivo: this.nao_leitura.motivo,
        rota_run_id: this.nao_leitura.rota_run_id,
        tipo_nao_leitura_id: this.nao_leitura.tipo_nao_leitura_id,
        latitude: this.nao_leitura.latitude,
        longitude: this.nao_leitura.longitude,
        user_id: this.currentUser.user.id
      }).subscribe(
        res => {
          if (Object(res).code == 200) {
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            /*    this.clearFormInputs();
               this.listarRotaRunByRotaHeader(); */

            this.getNaoLeituras();

            this.configService.loaddinStarter('stop');
          } else {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
            this.configService.loaddinStarter('stop');
          }
        }
      )
    }
    else {

      this.http.__call('nao-leitura/update/' + this.nao_leitura.id, {
        motivo: this.nao_leitura.motivo,

        rota_run_id: this.nao_leitura.rota_run_id,
        tipo_nao_leitura_id: this.nao_leitura.tipo_nao_leitura_id,
        latitude: this.nao_leitura.latitude,
        longitude: this.nao_leitura.longitude,
        user_id: this.currentUser.user.id
      }).subscribe(
        res => {
          if (Object(res).code == 200) {
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            /*    this.clearFormInputs();
               this.listarRotaRunByRotaHeader(); */

            this.getNaoLeituras();

            this.configService.loaddinStarter('stop');
          } else {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
            this.configService.loaddinStarter('stop');
          }
        }
      )
    }

  }

  private onReset() {

    this.nao_leitura.motivo = null;
    this.nao_leitura.rota_header_id = null;
    this.nao_leitura.rota_run_id = null,
    this.nao_leitura.tipo_nao_leitura_id = null,
    this.nao_leitura.latitude = null,
    this.nao_leitura.longitude = null,
    this.nao_leitura.is_delected = false

  }


  private actualizarEstadoNaoLeitura(item) {

    this.nao_leitura.id = item.id;
    this.nao_leitura.motivo = item.motivo;
    this.nao_leitura.rota_run_id = item.rota_run_id;
    this.nao_leitura.tipo_nao_leitura_id = item.tipo_nao_leitura_id;
    this.nao_leitura.latitude = item.latitude;
    this.nao_leitura.longitude = item.longitude;
    this.nao_leitura.is_delected = !item.is_delected;
    this.nao_leitura.user_id = item.user_id;

    //console.log(item);
    this.http.__call('nao-leitura/update/' + this.nao_leitura.id, this.nao_leitura).subscribe(
      response => {

        if (Object(response).code == 200) {
          var update = (this.nao_leitura.is_delected == true) ? "Activado" : "Desactivado";

          this.configService.showAlert("Não Leitura " + this.nao_leitura.motivo + " foi " + update, "alert-success", true);
        }

      }
    );

    for (let i = 0; i < this.items.length; ++i) {
      if (this.items[i].id == this.nao_leitura.id) {
        this.items[i].is_delected = this.nao_leitura.is_delected;
      }
    }
  }


  private setDataNaoLeitura(item) {

    if (item !== undefined) {

      this.selectBoxTiposNaoLeituras();

      this.title = "Editar Não Leitura";

      this.nao_leitura.id = item.id;
      this.nao_leitura.motivo = item.motivo;
      this.nao_leitura.rota_header_id = item.rota_header_id;
      this.nao_leitura.rota_run_id = item.rota_run_id;
      this.nao_leitura.tipo_nao_leitura_id = item.tipo_nao_leitura_id;
      this.nao_leitura.latitude = item.latitude;
      this.nao_leitura.longitude = item.longitude;
      this.nao_leitura.is_delected = item.is_delected;
      this.nao_leitura.user_id = item.user_id;

      this.getRotasHeaderWithRuns();
      this.selectBoxRotasRunPendentesByRotaReader();

    }
  }


  private getRotasHeaderWithRuns() {

    //console.log(this.currentUser);

    this.http.call_get('rota-header/getRotasHeaderWithRuns', null).subscribe(
      response => {

        //console.log(Object(response));

        this.rotas_header = Object(response).data;

        if (Object(response).data.length > 0) {
          this.has_rotas_header = true;
        }
        else {
          this.has_rotas_header = false;
        }

      }
    );
  }

  private selectBoxRotasRunPendentesByRotaReader() {

    this.http.call_get('rota-run/selectBoxRotasRunPendentesByRotaReader/' + this.nao_leitura.rota_header_id, null).subscribe(
      response => {

        //console.log(this.nao_leitura);
        //console.log(Object(response));

        this.rota_runs = Object(response);
      }
    );
  }

  private selectBoxTiposNaoLeituras() {

    this.http.call_get('tipo-nao-leitura/selectBox', null).subscribe(
      response => {

        this.tipos_nao_leituras = Object(response);
      }
    );
  }


  private getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
    this.getNaoLeituras();
  }

  private setDefault() {
    this.input_default = !this.input_default;
  }

  private setRequired() {
    this.input_required = !this.input_required;
  }

}
