import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

@Component({
  selector: 'app-wimax-dispositivo-form',
  templateUrl: './wimax-dispositivo-form.component.html',
  styleUrls: ['./wimax-dispositivo-form.component.css']
})
export class WimaxDispositivoFormComponent implements OnInit {



  private wimax = {
    id: null,
    EqNbrSerie: null,
    Mainkey: null,
    MAC: null,
    PasswordDados: null,
    PasswordVoz: null,
    ModeloTelefone: null,
    AgenciaFilialID: null
  }


  private itemsWamaxes: any = [];

  constructor(private http: HttpService, private configService: ConfigService) { }

  ngOnInit() {
    this.getPageFilterData(1);
  }

  private listaDispositivosWimax() {

    this.configService.loaddinStarter('start');

    this.http.__call('wimax/listagem', this.http.filters).subscribe(

      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.itemsWamaxes = Object(response).data.data;

       // console.log(this.items)
        this.configService.loaddinStarter('stop');

      }
    );
  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
    this.listaDispositivosWimax();
  }


  private register(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (this.wimax.EqNbrSerie == null) {
      this.configService.showAlert("Digite o Número de Série", 'alert-danger', true);
    } else {
      this.http.__call('wimax/create', this.wimax).subscribe(
        res => {
          if (Object(res).status == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
            this.configService.loaddinStarter('stop');
          } else {
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.wimax.EqNbrSerie = null;
            this.wimax.Mainkey = null;
            this.wimax.MAC = null;
            this.wimax.PasswordDados = null;
            this.wimax.PasswordVoz = null;
            this.wimax.ModeloTelefone = null;
            this.wimax.AgenciaFilialID = null;
            this.listaDispositivosWimax();
            this.configService.loaddinStarter('stop');
          }
        }
      )
    }
    ;
  }

  private refresh(id, EqNbrSerie, Mainkey, MAC, PasswordDados, PasswordVoz, ModeloTelefone, AgenciaFilialID) {

    this.wimax.id = id;
    this.wimax.EqNbrSerie = EqNbrSerie;
    this.wimax.Mainkey = Mainkey;
    this.wimax.MAC = MAC;
    this.wimax.PasswordDados = PasswordDados;
    this.wimax.PasswordVoz = PasswordVoz;
    this.wimax.ModeloTelefone = ModeloTelefone;
    this.wimax.AgenciaFilialID = AgenciaFilialID;

  }

  private closeForm(){
    this.wimax.id = null;
    this.wimax.EqNbrSerie = null;
    this.wimax.Mainkey = null;
    this.wimax.MAC = null;
    this.wimax.PasswordDados = null;
    this.wimax.PasswordVoz = null;
    this.wimax.ModeloTelefone = null;
    this.wimax.AgenciaFilialID = null;
  }

  private editar(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (this.wimax.EqNbrSerie == null) {
      this.configService.showAlert("O campo designação é obrigatorio", 'alert-danger', true);
    } else {
      this.http.__call('wimax/update/' + this.wimax.id, this.wimax).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            //this.configService.clearFormInputs(e);
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.listaDispositivosWimax();

          }
        }
      );
    }
    this.configService.loaddinStarter('stop');
  }

  private clearFormInputs(e) {
    e.target.elements[0].value = null;
    e.target.elements[1].value = null;
    e.target.elements[2].value = null;
    e.target.elements[3].value = null;
  }

  private carregando = {
    filial: 'Seleccione a filial',
  }

  private filiais: any = [];

  private listarFilial() {
    this.carregando.filial = 'Carregando...';
    this.http.call_get('filial/selectBox', null).subscribe(
      response => {
        this.filiais = Object(response).data;
        this.carregando.filial = 'Seleccione a Filial';
      }
    );
  }
  

  private gerarPasswordVoz() {
    var anysize = 6;//the size of string
    let randomNumbers = '';
    var charset = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
    for (var i = 0; i < anysize; i++) {
      randomNumbers += charset[Math.floor(Math.random() * charset.length)];
    }
    this.wimax.PasswordVoz = randomNumbers;

  }

  private gerarPasswordDados() {
    var anysize = 6;//the size of string
    let randomNumbers = '';
    var charset = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
    for (var i = 0; i < anysize; i++) {
      randomNumbers += charset[Math.floor(Math.random() * charset.length)];
    }
    this.wimax.PasswordDados = randomNumbers;

  }



  private ini() {
    this.wimax = {
      id: null,
      EqNbrSerie: null,
      Mainkey: null,
      MAC: null,
      PasswordDados: null,
      PasswordVoz: null,
      ModeloTelefone: null,
      AgenciaFilialID: null,
    }
  }







}
