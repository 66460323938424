import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { BsModalService, BsModalRef, ModalDirective, ModalModule } from 'ngx-bootstrap/modal';
import { ConfigService } from 'src/app/providers/config/config.service';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-logs-contenciosos',
  templateUrl: './logs-contenciosos.component.html',
  styleUrls: ['./logs-contenciosos.component.css']
})
export class LogsContenciososComponent implements OnInit {

  @ViewChild('closebutton') closebutton;
  @ViewChild('modalAppendServico') modalAppendServico: ModalDirective;

  loadingListarFacturacao = false;
  loadingListarFacturas = false;

  @ViewChild('modalShowEvent') public modalShowEvent: ModalDirective;

  private filter = {
    municipio_id: null,
    distrito_id: null,
    bairro_id: null,
    quarteirao_id: null,
    estado_contrato_id: null,
    tipologia_cliente_id: null
  }
  facturas:any=[]
  public filters = {
    search: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    pagination: {
      perPage: 10,// Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima,
      lastPage: null,
      total: null // defini o total de registo da BD
    },
    filter: null // subelemente do filter
  }
  private contenciosos: any = [];
  checkedAll: boolean = true;
  loading: boolean = false;
  dispesaContencioso: any = [];
  private facturasIncumprimento: any = [];

  private dadosClienteSelecionado = {
    nome: null,
    totaldivida: null
  }
  private estado=[]
  private contencioso_id:any=null
  private cliente: any = {}


  private dashboard = {
    totalContratos: null,
    totalFacturas: null,
    totaldividas: null,
  };
  distritos: any[]
  municipios: any[]
  bairros: any[]
  quarteiroes: any[]
  simpleFormCliente: FormGroup

  constructor(
    private auth: AuthService,
    private http: HttpService,
    private configService: ConfigService,
    private _formBuilder: FormBuilder
  ) {

    this.createForm()
   }

  ngOnInit() {
    this.getPageFilterData(1);
    this.dashboardFacturacao();
    this.listagemContenciosos();
    this.listagem_despesa_contencioso_selectBox()
    this.http.filters.pagination.perPage = 5
    this.http.filters.pagination.page = 5
    this.getMunicipios()
    this.getEstadoOfContrato()
    this.getTipologiaCliente()
  }

  facturasToBeIncobravel(id,contencioso_id){

    this.loading=true
     this.http.__call("api/v1/facturas/cliente/"+id, null).subscribe(
       res => {
           this.facturas = Object(res)
           this.hendleInvalidarCobrancaFactura(this.facturas.map(item=>item.id),contencioso_id)
          // console.log(this.facturas,res)
       });
     this.loading = false;
   }
  hendAllFacturas(){
    this.hendleInvalidarCobrancaFactura(this.facturas.map(item=>item.id),this.contencioso_id)
  }
  hendleInvalidarCobrancaFactura(facturasIncobraveis,contencioso_id){

    this.loading=true
     this.http.__call("api/v1/facturas/processo-incobraveis", { factura_ids: facturasIncobraveis, contencioso_id }).subscribe(
       res => {
         if (Object(res).code == 200) {
           this.configService.showAlert(Object(res).message, 'alert-success', true)
         } else {
           this.loading = false;
           this.configService.showAlert(Object(res).message, 'alert-danger', true);
         }
       });
     this.loading = false;
   }

   facturaByCliente(id){

    this.loading=true
     this.http.__call("api/v1/facturas/cliente/"+id, null).subscribe(
       res => {
           this.facturas = Object(res)
           console.log(this.facturas,res)
       });
     this.loading = false;
   }

  createForm() {
    this.simpleFormCliente = this._formBuilder.group({
      id: null,
      numero_processo:null,
      estado_contencioso_id: [null, [Validators.required]],
      data_processo:[null, [Validators.required]],
      identificacao_advogado:[null, [Validators.required]],
      identificacao_tribunal:[null, [Validators.required]],
      despesa_contencioso_id:[null]
    })

  }
  get f() {
    return this.simpleFormCliente.controls;
  }

   initForm(item){
    this.simpleFormCliente.patchValue({
      id:item.id,
      flag:item.flag,
      estado_contencioso_id:item.estado_contencioso_id,
      data_processo:item.data_processo,
      numero_processo:item.numero_processo,
      identificacao_advogado:item.identificacao_advogado,
      identificacao_tribunal:item.identificacao_tribunal,
      despesa_contencioso_id:item.despesa_contencioso_id
    })
   }

   _onSubmit(flag){

    this.loading=true
    this.http.__call("edit_contencioso/"+this.simpleFormCliente.value.id, {...this.simpleFormCliente.value,flag}).subscribe(
      res => {
        if (Object(res).code == 200) {
          this.listagemContenciosos();
          this.configService.showAlert(Object(res).message, 'alert-success', true);
        } else {
           this.loading = false;
           this.configService.showAlert(Object(res).message, 'alert-danger', true);
        }
      });
      this.loading = false;
  }

   onSubmit(){

    this.loading=true
    this.http.__call("edit_contencioso/"+this.simpleFormCliente.value.id, this.simpleFormCliente.value).subscribe(
      res => {
        if (Object(res).code == 200) {
          this.listagemContenciosos();
          this.configService.showAlert(Object(res).message, 'alert-success', true);
        } else {
           this.loading = false;
           this.configService.showAlert(Object(res).message, 'alert-danger', true);
        }
      });
      this.loading = false;
  }

  private getMunicipios() {

    this.municipios = []
    this.filter.distrito_id = null
    this.filter.bairro_id = null
    this.http.call_get('municipio/selectBox', null)
      .subscribe(
        response => {
          this.municipios = Object(response).data;
        }
      );
  }

  private getEstado(flag: string) {
    this.estado=[]
    this.http.__call('estado_contencioso_selectBox', { flag }).subscribe(
      response => {

        if(Array.isArray(Object(response).data)){
          this.estado = Object(response).data;
        }else{
            this.estado.push(Object(response).data)
        }

      })
  }


  private selectBoxDistritosByMunicipio(id) {
    this.distritos = [];
    this.filter.bairro_id = null
    if (!id || id == 'null') return
    this.http.call_get('distrito/getDistritosByMunicipio/' + id, null).subscribe(
      response => {
        this.distritos = Object(response).data;
      }
    );
  }

  private selectBoxBairrosByDistrito(id) {
    this.bairros = []

    if (!id || id == 'null') return
    this.http.call_get('bairro/selectBoxByDistrito/' + id, null).subscribe(
      response => {
        this.bairros = Object(response);
      }
    );
  }

  _getQuarteirosDoBairro(id) {
    this.http.call_get(`quarteirao/getQuarteiraosByBairro/${id}`, null)
      .subscribe(
        response => {
          this.quarteiroes = Object(response).data
        }
      )
  }


  private dashboardFacturacao() {
    this.http.call_get('contrato/dashboardIncumprimentos', null).subscribe(
      response => {
        this.somaTotaisDoDashboard(Object(response).data)
      }
    )
  }

  private listagem_despesa_contencioso_selectBox() {
    this.http.call_get('listagem_despesa_contencioso_selectBox', null).subscribe(
      response => {
        this.dispesaContencioso=Object(response)
      }
      )

  }



  async retornarFacturaIncumprimetoPorContrato(contrato_id: any, nome: any) {
    this.loadingListarFacturas = true
    this.dadosClienteSelecionado.nome = nome + ' - ' + contrato_id;
    await this.http.call_get('facturasIncumprimento/contratos/' + contrato_id, null).subscribe(
      response => {
        this.facturasIncumprimento = Object(response).data
        this.somaTotais(this.facturasIncumprimento)
        this.loadingListarFacturas = false
      }
    )
  }

  private get incumprimentosChecked(): any[] {
    return this.contenciosos.filter(val => val.checked === true);
  }

  setCheckedAll(checked: boolean) {
    this.checkedAll = checked;
    this.contenciosos.forEach(f => f.checked = checked);
  }

  updateChecked(f: any) {
    this.contenciosos.forEach(element => {
      element.checked = element.contrato_id === f.contrato_id ? !f.checked : element.checked;
      Object.assign(element);
    });
    const lengthFalse = this.contenciosos.filter(val => val.checked === true);
    this.checkedAll = lengthFalse.length === this.contenciosos.length ? true : false;
  }

  private async criarOrdemServico() {

    if (this.contenciosos === 0) {
      this.configService.showAlert("Nenhuma linha seleccionada", "alert-error", true);
      return;
    }
    if (this.incumprimentosChecked.length == 1) {
      this.configService.showAlert("Seleccione no minimo duas ou mais linhas", "alert-error", true);
      return;
    }

    this.configService.showAlert("A processar...", "alert-info", true);
    this.modalShowEvent.show();
    const incumprimentos = this.incumprimentosChecked;
    //console.log(incumprimentos)
  }

  private closeModal() {
    this.modalAppendServico.hide();
  }

  private contrato: any = [];

  private setDataContrato(item) {

    this.contrato = item;


  }

  private notificarCliente() {

    const data = {
      cliente_id: this.cliente.cliente_id,
      contrato_id: this.cliente.contrato_id
    }

    this.http.__call('api/v1/notifications/customer', data).subscribe(response => {
      //console.log(response)
    })
  }

  private notificarClienteModal(item) {
    this.contencioso_id = item.id
    this.facturaByCliente(item.cliente_id);
    this.initForm(item)
    this.cliente = item
    this.getEstado(item.flag)

  }

  getPageFilterData(page: number) {
    if (this.filters.pagination.perPage == null) {
      return;
    }
    this.filters.pagination.page = Number(page);
    this.listagemContenciosos();
  }
  private listagemContenciosos() {
    this.filters.filter = this.filter
    this.loadingListarFacturacao = true
    this.http.__call('listagem_contencioso', this.filters).subscribe(
      response => {
        this.filters.pagination.lastPage = Object(response).data.lastPage;
        this.filters.pagination.page = Object(response).data.page;
        this.filters.pagination.total = Object(response).data.total;
        this.filters.pagination.perPage = Object(response).data.perPage;
        this.contenciosos = Object(response).data.data;
        this.loadingListarFacturacao = false
      }
    );
  }

  private estados_contrato: any = []
  private getEstadoOfContrato() {

    this.http.call_get("estado-contrato/selectBox")
      .subscribe(res => {
        this.estados_contrato = Object(res)
      })
  }

  private tipologias_cliente: any = []
  private getTipologiaCliente() {
    this.http.call_get('tipologia-cliente/selectBox')
      .subscribe(res => {
        this.tipologias_cliente = Object(res)
      })
  }

  private somaTotais(dados) {
    var total_divida: number = 0;
    dados.forEach(element => {
      total_divida += element.valor_aberto;
    });
    this.dadosClienteSelecionado.totaldivida = total_divida;
  }

  private somaTotaisDoDashboard(dados) {
    var total_contratos: number = 0;
    var total_facturas: number = 0;
    var total_Dividas: number = 0;
    dados.forEach(element => {
      total_facturas += element.numero_facturas;
      total_Dividas += element.total_divida;
    });
    total_contratos = dados.length;
    this.dashboard.totalContratos = total_contratos;
    this.dashboard.totalFacturas = total_facturas;
    this.dashboard.totaldividas = total_Dividas;

  }

  private imprimirCartaNotificacao(dados) {
    dados['divida'] = this.configService.numberFormat(dados.total_divida)
    this.configService.imprimirAvisoDeCorte(dados, "1ª Via", "imprimir")
  }
}

