import { Injectable, Input, EventEmitter } from '@angular/core';
import { ApiService } from 'src/app/providers/http/api.service';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import * as extenso from 'extenso';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';

@Injectable({
  providedIn: 'root'
})
export class DevolucaoService {



  @Input() app_environment: null;

  constructor(private http: ApiService, private auth: AuthService) {
    this.app_environment = this.auth.getAppEnvironment();
    if (this.app_environment == null) {
      var url = require('url');
      var app_url = url.parse(environment.app_url, true);
      this.app_environment = app_url.host;
    }
  }
  public imprimirDevolucao(id, via = 'Original', report = 'imprimir') {
    this.http.get('devolucao/printerDevolucao/' + id).subscribe(
      response => {

        const dados = Object(response).data;
        this.imprimirPDFDevolucao(
          dados.devolucao,
          dados.linha_devolucaos,
          dados.cliente,
          dados.tipos_identidades,
          dados.user,
          dados.linha_pagamentos,
          dados.contrato,
          dados.contas_bancarias,
          dados.lojas,
          report,
          via
        )
      }, error => {

      }
    );
  }

  public imprimirEstorno(id, via = 'Original', report = 'imprimir') {
    this.http.get('recibo/printerEstorno/' + id).subscribe(
      response => {

        const dados = Object(response).data;
        this.imprimirPDEstorno(
          dados.devolucao,
          dados.linha_devolucaos,
          dados.cliente,
          dados.tipos_identidades,
          dados.user,
          dados.linha_pagamentos,
          dados.contrato,
          dados.contas_bancarias,
          dados.lojas,
          report,
          via
        )
      }, error => {

      }
    );
  }

  public imprimirEstimativa(id, via = 'Original', report = 'imprimir') {
    this.http.get('devolucao/printerEstimativa/' + id).subscribe(
      response => {

        const dados = Object(response).data;
        this.imprimirPDFEstimativa(
          dados.devolucao,
          dados.linha_devolucaos,
          dados.cliente,
          dados.tipos_identidades,
          dados.user,
          dados.linha_pagamentos,
          dados.contrato,
          dados.contas_bancarias,
          dados.lojas,
          report,
          via
        )
      }, error => {

      }
    );
  }


  public imprimirPDFDevolucao(
    devolucao: any,
    linha_devolucaos: any[],
    cliente: any,
    tipos_identidades: any,
    user: any,
    linha_pagamentos: any,
    contrato: any,
    contas_bancarias: any,
    lojas: any,
    report: string = 'imprimir',
    original: any = 'Original'
  ) {
    var numberPage = 1;
    var img_logotipo = user.logotipo;
    var doc = new jsPDF();
    doc.addImage(img_logotipo, 'JPEG', 8, 14, 28, 24)

    doc.setFontSize(8);
    doc.setFont("calibri");
    doc.setTextColor(0);
    doc.text('' + (user.companyName || ''), 37, 20);
    doc.setTextColor(0);
    doc.text('' + (user.addressDetail || ''), 37, 25);
    doc.text('NIF: ' + (user.taxRegistrationNumber || ''), 37, 30);
    doc.text('Email: ' + (user.company_email || ''), 37, 35);
    doc.text('Telefone: ', 95, 30);
    doc.text('' + (user.company_telefone || ''), 107, 30);
    doc.text('WebSite: ', 95, 35);
    doc.setTextColor(0, 0, 255);
    doc.text('' + (user.site || ''), 107, 35);

    doc.setFontSize(8);
    doc.setDrawColor(0);
    doc.setFillColor(255);
    doc.rect(145, 14, 55, 23, 'B');

    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.text('DEVOLUÇÃO N.º', 148, 20);
    doc.text('Data Emissão:', 148, 25);
    doc.text('Via: ' + original, 148, 35);

    doc.setFontSize(8);
    doc.setFontType("normal");
    doc.text('' + (devolucao.devolucao_sigla || ''), 172, 20);
    doc.text('' + moment(devolucao.created_at).format("DD/MM/YYYY"), 168, 25);

    doc.setFontSize(8);
    doc.setFont("calibri");
    doc.setFontType("normal");
    doc.text(cliente.nome.trimStart(), 120, 52);
    let text_local = "";// local_consumo.is_predio ? `, Prédio ${local_consumo.predio_nome ? local_consumo.predio_andar : ''}  ${local_consumo.moradia_numero}` : ` ${local_consumo.moradia_numero ? `, Residência nº ${local_consumo.moradia_numero}` : ''}`

    let full_morada = `${cliente.morada} ${text_local}`
    doc.text((cliente.morada == null ? '' : full_morada), 120, 57, { maxWidth: 80 });

    let desc_municipio = cliente.municipio ? cliente.municipio.nome : ''
    doc.text('' + desc_municipio, 120, 66.5);
    /*
    doc.text(cliente.nome.trimStart(), 148, 52);
    doc.text(cliente.morada, 148, 57);
    */

    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.rect(12, 75, 40.5, 3.5, 'B');
    doc.setFontType("bold");
    doc.text('Contrato N.º: ' + devolucao.contrato_id, 13, 77.5);
    doc.text(cliente.nome.trimStart(), 13, 83);
    doc.text('NIF: ', 13, 87);
    doc.setFontType("normal");
    let nif = tipos_identidades.find(obj => obj.nome == 'NIF')
    doc.text('' + (nif ? nif.numero_identidade : ""), 21, 87);

    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.rect(12, 100, 182, 5, 'B');
    doc.setFontSize(8);
    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.text('DEVOLUÇÃO  N.º', 13, 103.5);
    doc.text(' ' + devolucao.devolucao_sigla + ' de  ' + moment(devolucao.created_at).format("DD/MM/YYYY"), 35, 103.5);
    doc.setFontType("normal");

    doc.setDrawColor(0, 0, 0);
    doc.setLineWidth(0.1);
    doc.line(12, 104.5, 194, 104.5); // horizontal line

    doc.setFontType("bold");
    doc.text('Nº Documento', 13, 108);
    doc.text('Valor Imposto.', 60, 108);
    doc.text('Valor Em Aberto', 90, 108);
    //    doc.text('Total a Pagar', 140, 108);
    doc.text('Total Devolução', 140, 108);
    doc.text('Estado Factura', 175, 108);
    doc.setTextColor(0);
    doc.setFontType("normal");

    doc.setDrawColor(0, 0, 0);
    doc.setLineWidth(0.1);
    doc.line(12, 109, 194, 109); // horizontal line
    doc.setTextColor(0);

    var posY = 115;
    for (var i = 0; i < linha_devolucaos.length; i++) {
      const element = linha_devolucaos[i];

      var document_sigla = element.is_entrada == true ? element.factura_sigla : element.recibo_sigla
      doc.text('' + document_sigla, 13, posY);
      doc.text('' + this.numberFormat(element.is_entrada ? element.totalComImposto : 0), 78, posY, 'right');
      doc.text('' + this.numberFormat(element.is_entrada ? element.valor_aberto : element.valor_aberto), 110, posY, 'right');
      doc.text('' + this.numberFormat(element.is_entrada ? element.total : element.total), 155, posY, 'right');
      doc.text('' + (element.total == null ? (element.pago == 0 ? 'Não Saldado' : 'Saldado') : (element.valor_aberto == 0 ? 'Saldado' : 'Não Saldado')), 193, posY, 'right');
      posY += 5;
    }

    doc.line(12, posY + 4.5, 194, posY + 4.5); // horizontal line

    const hasReachedPageLimit = (posY) >= 220

    if (hasReachedPageLimit) {
      posY = 10;
      footer()
      doc.addPage();
    }
    doc.setDrawColor(0, 0, 0);
    doc.setLineWidth(0.1);
    doc.line(12, posY + 4.5, 194, posY + 4.5); // horizontal line

    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.rect(12, posY + 5, 182, 5, 'B');
    doc.text('VALOR DEVOLUÇÃO', 13, posY + 8);
    doc.text('' + this.numberFormat(devolucao.total), 155, posY + 8, 'right');
    // TOTAL RECEBIDO EM EXTENSO
    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.rect(12, posY + 12, 182, 5, 'B');
    doc.text(13, posY + 15, doc.splitTextToSize('VALOR POR EXTENSO', 50));
    var valor = extenso('' + this.numberFormat(devolucao.total), { locale: 'pt', mode: 'currency', currency: { type: 'EUR' }, decimal: 'formal' });
    doc.text('' + valor.replace(/(euros?)/gi, 'kwanzas') + '', 155, posY + 15, 'right')
    //RESUMO DOS IMPOSTOS

    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.rect(12, posY + 22, 182, 5, 'B');
    doc.text('MEIOS DE PAGAMENTOS', 13, posY + 25);
    doc.text('DESCRIÇÃO', 13, posY + 30);
    doc.text('BANCO', 65, posY + 30);
    doc.text('REFERÊNCIA', 95, posY + 30);
    doc.text('VALOR DEVOLVIDO', 155, posY + 30, 'right');
    doc.setDrawColor(0, 0, 0);
    doc.setLineWidth(0.1);
    doc.line(12, posY + 31, 194, posY + 31); // horizontal line
    var posYLinhaPagamento = posY + 1;


    for (let linha of linha_pagamentos) {
      doc.text('' + linha.designacao, 13, posYLinhaPagamento + 35);
      doc.text('' + (linha.abreviatura == null ? '' : linha.abreviatura), 65, posYLinhaPagamento + 35);
      doc.text('' + (linha.referencia == null ? '' : linha.referencia), 95, posYLinhaPagamento + 35);
      doc.text('' + (this.numberFormat(linha.valor_recebido)), 155, (posYLinhaPagamento + 35), 'right');
      
      posYLinhaPagamento += 4
    }

    doc.setFontType("normal");

    /*if (recibo.isTrocoAdiantamento) {
      let text_adiantamento = `Adiantamento registado no valor de: ${this.numberFormat(recibo.valorCriadoAdiantamento)} AOA`
      doc.text((text_adiantamento), 15, posYLinhaPagamento + 38, null, null, 'left');
    }*/

    /*if (!recibo.isTrocoAdiantamento && recibo.troco > 0) {
      let text_adiantamento = `Valor do troco: ${this.numberFormat(recibo.troco)} AOA`
      doc.text((text_adiantamento), 15, posYLinhaPagamento + 38, null, null, 'left');
    }*/

    //doc.text((recibo.saldado_recibo == null ? '' : 'Adiantamento registado no valor de: '+ this.numberFormat(recibo.troco) ), 15, posYLinhaPagamento + 38, null, null, 'left');
    doc.setFontType("bold");




    posYLinhaPagamento += 18
    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.rect(12, posYLinhaPagamento + 25, 182, 5, 'B');
    doc.text('TOTAL DEVOLVIDO', 13, posYLinhaPagamento + 28);
    if (devolucao.isTrocoAdiantamento) {
      doc.text('' + this.numberFormat(devolucao.valor_recebido), 155, posYLinhaPagamento + 28, 'right');
    }
    else {
      doc.text('' + this.numberFormat(devolucao.valor_recebido - devolucao.troco), 155, posYLinhaPagamento + 28, 'right');
    }

    // TOTAL RECEBIDO EM EXTENSO
    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.rect(12, posYLinhaPagamento + 31.5, 182, 5, 'B');
    doc.text(13, posYLinhaPagamento + 35, doc.splitTextToSize('VALOR POR EXTENSO', 50));
    var valor = extenso('' + this.numberFormat(devolucao.total - devolucao.troco), { locale: 'pt', mode: 'currency', currency: { type: 'EUR' }, decimal: 'formal' });
    doc.text('' + valor.replace(/(euros?)/gi, 'kwanzas') + '', 155, posYLinhaPagamento + 35, 'right')

    let text_obs = `OBSERVAÇÕES: ${devolucao.observacao ? devolucao.observacao : ''}`
    doc.text(text_obs, 13, posYLinhaPagamento + 45, { maxWidth: 182 });

    // END RESUMO DOS IMPOSTOS
    posY = 130;

    //ANULAÇÃO
    /*if (recibo.status == 'A') {
      doc.setDrawColor(0);
      doc.setFillColor(220, 220, 220);
      doc.rect(10, posY + 99, 52, 5, 'B');
      doc.setTextColor(0);
      doc.setFontType("bold");
      doc.text('Estado: ', 12, posY + 102.5);
      doc.setTextColor('red');
      doc.text('Anulação', 24, posY + 102.5);
      doc.setTextColor(0);
    }*/

    //REGIME DE IMPOSTO
    doc.setDrawColor(0);
    doc.setFillColor(220, 220, 220);
    doc.rect(160, posY + 99, 35, 5, 'B');
    doc.setTextColor(0);
    doc.setFontType("bold");

    let tipo_regime = (user.sigla_empresa == 'EPASN' || user.sigla_empresa == 'EPASLS') ? 'Regime Simplificado' : 'IVA - Regime de Caixa'

    if (user.sigla_empresa == 'EPASN') {
      if (new Date(devolucao.created_at) > new Date('2021-05-31')) {
        tipo_regime = 'IVA - Regime de Caixa'
      } else {
        tipo_regime = 'Regime Simplificado'
      }
    }

    doc.text(tipo_regime, 192, posY + 102.5, 'right');
    doc.setTextColor(0);

    doc.setFontType("bold")
    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.rect(10, posY + 106, 185.5, 5, 'B');
    doc.setTextColor(0);
    doc.setFontType("bold");

    doc.setDrawColor(0);
    doc.rect(10, posY + 105, 185, 20, 'B');

    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);

    doc.setTextColor(0);
    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.text('Contas Bancárias (IBAN):', 12, posY + 109.5);
    doc.setFontSize(8);
    doc.setDrawColor(0);
    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.setFontSize(8);
    doc.setDrawColor(0);
    doc.setFillColor(255);
    doc.rect(10, posY + 111, 185, 20, 'B');
    doc.setTextColor(0);
    doc.setFontType("normal");
    const ContasFiltradas = [];

    contas_bancarias.forEach((item) => {
      var duplicated = ContasFiltradas.findIndex(redItem => {
        return item.iban == redItem.iban;
      }) > -1;

      if (!duplicated) {
        ContasFiltradas.push(item);
      }
    });
    var posX = 12;
    let posYY = 115;
    for (var i = 0; i < ContasFiltradas.length; i++) {
      if (i !== 0 && i % 3 === 0) {
        posX = 12;
        posYY += 4;
      }
      doc.text('' + ContasFiltradas[i].banco, posX, posY + posYY);
      doc.text('' + ContasFiltradas[i].iban, posX + 7, posY + posYY);
      posX += 60;
    }

    doc.text('Sempre que efectuar uma transferência ou depósito, deve indicar o número das recibos a liquidar.', 12, posY + 125.7);

    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.text('Lojas EPAS:', 10, posY + 135);
    doc.setFontType("normal");
    for (let loja of lojas) {
      doc.text('' + loja.nome + ', ' + loja.endereco + ', ' + (loja.horario ? loja.horario : ''), 11, posY + 138);
      posY += 3;
    }

    footer()


    function footer() {
      var dataActual = moment(new Date()).format("DD/MM/YYYY");
      doc.text(dataActual + ' ' + moment().format("H:m") + '/ Versão: ' + original, 11, 285);
      /*     doc.text(moment(recibo.created_at).format("DD/MM/YYYY") + ' ' + moment(recibo.created_at).format("H:m") + '/ Versão: ' + original, 11, 285); */
      // doc.text('Página: ' + numberPage, 184, 285);

      doc.setFontSize(8);
      doc.setDrawColor(0);
      doc.setFillColor(0);


      doc.line(10, doc.internal.pageSize.height - 9, 194, doc.internal.pageSize.height - 9); // vertical line
      var hash = devolucao == null ? '' : devolucao.hash ? devolucao.hash.substring(0, 1) : ''
      hash += devolucao == null ? '' : devolucao.hash ? devolucao.hash.substring(10, 11) : ''
      hash += devolucao == null ? '' : devolucao.hash ? devolucao.hash.substring(20, 21) : ''
      hash += devolucao == null ? '' : devolucao.hash ? devolucao.hash.substring(30, 31) : ''
      doc.setFontSize(6);

      doc.text("NIF: " + user.taxRegistrationNumber + " - " + user.companyName + " / " + user.addressDetail + " / " + (user.company_telefone || '') + " / " + (user.company_email || ''), 105, doc.internal.pageSize.height - 6, null, null, 'center');

      doc.setFontSize(8);
      doc.text(hash + '-Processado por programa validado nº 4/AGT119', 105, doc.internal.pageSize.height - 3, null, null, 'center');
    }



    if (report == 'imprimir') {
      doc.autoPrint();
      window.open(doc.output("bloburl")); //opens the data uri in new window
    } else {
      doc.save(devolucao.devolucao_sigla + '.pdf'); /* download the file immediately on loading */
    }

  }

  public imprimirPDFEstimativa(
    devolucao: any,
    linha_devolucaos: any[],
    cliente: any,
    tipos_identidades: any,
    user: any,
    linha_pagamentos: any,
    contrato: any,
    contas_bancarias: any,
    lojas: any,
    report: string = 'imprimir',
    original: any = 'Original'
  ) {
    var numberPage = 1;
    var img_logotipo = user.logotipo;
    var doc = new jsPDF();
    doc.addImage(img_logotipo, 'JPEG', 8, 14, 28, 24)

    doc.setFontSize(8);
    doc.setFont("calibri");
    doc.setTextColor(0);
    doc.text('' + (user.companyName || ''), 37, 20);
    doc.setTextColor(0);
    doc.text('' + (user.addressDetail || ''), 37, 25);
    doc.text('NIF: ' + (user.taxRegistrationNumber || ''), 37, 30);
    doc.text('Email: ' + (user.company_email || ''), 37, 35);
    doc.text('Telefone: ', 95, 30);
    doc.text('' + (user.company_telefone || ''), 107, 30);
    doc.text('WebSite: ', 95, 35);
    doc.setTextColor(0, 0, 255);
    doc.text('' + (user.site || ''), 107, 35);

    doc.setFontSize(8);
    doc.setDrawColor(0);
    doc.setFillColor(255);
    doc.rect(145, 14, 55, 23, 'B');

    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.text('DEVOLUÇÃO N.º', 148, 20);
    doc.text('Data Emissão:', 148, 25);
    doc.text('Via: ' + original, 148, 35);

    doc.setFontSize(8);
    doc.setFontType("normal");
    doc.text('' + (devolucao.devolucao_sigla || ''), 172, 20);
    doc.text('' + moment(devolucao.created_at).format("DD/MM/YYYY"), 168, 25);

    doc.setFontSize(8);
    doc.setFont("calibri");
    doc.setFontType("normal");
    doc.text(cliente.nome.trimStart(), 120, 52);
    let text_local = "";// local_consumo.is_predio ? `, Prédio ${local_consumo.predio_nome ? local_consumo.predio_andar : ''}  ${local_consumo.moradia_numero}` : ` ${local_consumo.moradia_numero ? `, Residência nº ${local_consumo.moradia_numero}` : ''}`

    let full_morada = `${cliente.morada} ${text_local}`
    doc.text((cliente.morada == null ? '' : full_morada), 120, 57, { maxWidth: 80 });

    let desc_municipio = cliente.municipio ? cliente.municipio.nome : ''
    doc.text('' + desc_municipio, 120, 66.5);
    /*
    doc.text(cliente.nome.trimStart(), 148, 52);
    doc.text(cliente.morada, 148, 57);
    */

    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.rect(12, 75, 40.5, 3.5, 'B');
    doc.setFontType("bold");
    doc.text('Contrato N.º: ' + devolucao.contrato_id, 13, 77.5);
    doc.text(cliente.nome.trimStart(), 13, 83);
    doc.text('NIF: ', 13, 87);
    doc.setFontType("normal");
    let nif = tipos_identidades.find(obj => obj.nome == 'NIF')
    doc.text('' + (nif ? nif.numero_identidade : ""), 21, 87);

    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.rect(12, 100, 182, 5, 'B');
    doc.setFontSize(8);
    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.text('DEVOLUÇÃO  N.º', 13, 103.5);
    doc.text(' ' + devolucao.devolucao_sigla + ' de  ' + moment(devolucao.created_at).format("DD/MM/YYYY"), 35, 103.5);
    doc.setFontType("normal");

    doc.setDrawColor(0, 0, 0);
    doc.setLineWidth(0.1);
    doc.line(12, 104.5, 194, 104.5); // horizontal line

    doc.setFontType("bold");
    doc.text('Nº Documento', 13, 108);
    doc.text('Valor Imposto.', 60, 108);
    doc.text('Valor Em Aberto', 90, 108);
    //    doc.text('Total a Pagar', 140, 108);
    doc.text('Total Devolução', 140, 108);
    doc.text('Estado Factura', 175, 108);
    doc.setTextColor(0);
    doc.setFontType("normal");

    doc.setDrawColor(0, 0, 0);
    doc.setLineWidth(0.1);
    doc.line(12, 109, 194, 109); // horizontal line
    doc.setTextColor(0);

    var posY = 115;
    for (var i = 0; i < linha_devolucaos.length; i++) {
      const element = linha_devolucaos[i];

      var document_sigla = element.is_entrada == true ? element.factura_sigla : element.recibo_sigla
      doc.text('' + document_sigla, 13, posY);
      doc.text('' + this.numberFormat(element.is_entrada ? element.totalComImposto : 0), 78, posY, 'right');
      doc.text('' + this.numberFormat(element.is_entrada ? element.valor_aberto : element.valor_aberto), 110, posY, 'right');
      doc.text('' + this.numberFormat(element.is_entrada ? element.valor_caucao : element.valor_caucao), 155, posY, 'right');
      doc.text('' + (element.total == null ? (element.pago == 0 ? 'Não Saldado' : 'Saldado') : (element.valor_aberto == 0 ? 'Saldado' : 'Não Saldado')), 193, posY, 'right');
      posY += 5;
    }

    doc.line(12, posY + 4.5, 194, posY + 4.5); // horizontal line

    const hasReachedPageLimit = (posY) >= 220

    if (hasReachedPageLimit) {
      posY = 10;
      footer()
      doc.addPage();
    }
    doc.setDrawColor(0, 0, 0);
    doc.setLineWidth(0.1);
    doc.line(12, posY + 4.5, 194, posY + 4.5); // horizontal line

    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.rect(12, posY + 5, 182, 5, 'B');
    doc.text('VALOR DEVOLUÇÃO', 13, posY + 8);
    doc.text('' + this.numberFormat(devolucao.total), 155, posY + 8, 'right');
    // TOTAL RECEBIDO EM EXTENSO
    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.rect(12, posY + 12, 182, 5, 'B');
    doc.text(13, posY + 15, doc.splitTextToSize('VALOR POR EXTENSO', 50));
    var valor = extenso('' + this.numberFormat(devolucao.total), { locale: 'pt', mode: 'currency', currency: { type: 'EUR' }, decimal: 'formal' });
    doc.text('' + valor.replace(/(euros?)/gi, 'kwanzas') + '', 155, posY + 15, 'right')
    //RESUMO DOS IMPOSTOS

    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.rect(12, posY + 22, 182, 5, 'B');
    doc.text('MEIOS DE PAGAMENTOS', 13, posY + 25);
    doc.text('DESCRIÇÃO', 13, posY + 30);
    doc.text('BANCO', 65, posY + 30);
    doc.text('REFERÊNCIA', 95, posY + 30);
    doc.text('VALOR DEVOLVIDO', 155, posY + 30, 'right');
    doc.setDrawColor(0, 0, 0);
    doc.setLineWidth(0.1);
    doc.line(12, posY + 31, 194, posY + 31); // horizontal line
    var posYLinhaPagamento = posY + 1;


    for (let linha of linha_pagamentos) {
      doc.text('' + linha.designacao, 13, posYLinhaPagamento + 35);
      doc.text('' + (linha.abreviatura == null ? '' : linha.abreviatura), 65, posYLinhaPagamento + 35);
      doc.text('' + (linha.referencia == null ? '' : linha.referencia), 95, posYLinhaPagamento + 35);
      doc.text('' + (this.numberFormat(linha.valor_recebido)), 155, (posYLinhaPagamento + 35), 'right');
      
      posYLinhaPagamento += 4
    }

    doc.setFontType("normal");

    /*if (recibo.isTrocoAdiantamento) {
      let text_adiantamento = `Adiantamento registado no valor de: ${this.numberFormat(recibo.valorCriadoAdiantamento)} AOA`
      doc.text((text_adiantamento), 15, posYLinhaPagamento + 38, null, null, 'left');
    }*/

    /*if (!recibo.isTrocoAdiantamento && recibo.troco > 0) {
      let text_adiantamento = `Valor do troco: ${this.numberFormat(recibo.troco)} AOA`
      doc.text((text_adiantamento), 15, posYLinhaPagamento + 38, null, null, 'left');
    }*/

    //doc.text((recibo.saldado_recibo == null ? '' : 'Adiantamento registado no valor de: '+ this.numberFormat(recibo.troco) ), 15, posYLinhaPagamento + 38, null, null, 'left');
    doc.setFontType("bold");




    posYLinhaPagamento += 18
    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.rect(12, posYLinhaPagamento + 25, 182, 5, 'B');
    doc.text('TOTAL DEVOLVIDO', 13, posYLinhaPagamento + 28);
    if (devolucao.isTrocoAdiantamento) {
      doc.text('' + this.numberFormat(devolucao.valor_recebido), 155, posYLinhaPagamento + 28, 'right');
    }
    else {
      doc.text('' + this.numberFormat(devolucao.valor_recebido - devolucao.troco), 155, posYLinhaPagamento + 28, 'right');
    }

    // TOTAL RECEBIDO EM EXTENSO
    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.rect(12, posYLinhaPagamento + 31.5, 182, 5, 'B');
    doc.text(13, posYLinhaPagamento + 35, doc.splitTextToSize('VALOR POR EXTENSO', 50));
    var valor = extenso('' + this.numberFormat(devolucao.total - devolucao.troco), { locale: 'pt', mode: 'currency', currency: { type: 'EUR' }, decimal: 'formal' });
    doc.text('' + valor.replace(/(euros?)/gi, 'kwanzas') + '', 155, posYLinhaPagamento + 35, 'right')

    let text_obs = `OBSERVAÇÕES: ${devolucao.observacao ? devolucao.observacao : ''}`
    doc.text(text_obs, 13, posYLinhaPagamento + 45, { maxWidth: 182 });

    // END RESUMO DOS IMPOSTOS
    posY = 130;

    //ANULAÇÃO
    /*if (recibo.status == 'A') {
      doc.setDrawColor(0);
      doc.setFillColor(220, 220, 220);
      doc.rect(10, posY + 99, 52, 5, 'B');
      doc.setTextColor(0);
      doc.setFontType("bold");
      doc.text('Estado: ', 12, posY + 102.5);
      doc.setTextColor('red');
      doc.text('Anulação', 24, posY + 102.5);
      doc.setTextColor(0);
    }*/

    //REGIME DE IMPOSTO
    doc.setDrawColor(0);
    doc.setFillColor(220, 220, 220);
    doc.rect(160, posY + 99, 35, 5, 'B');
    doc.setTextColor(0);
    doc.setFontType("bold");

    let tipo_regime = (user.sigla_empresa == 'EPASN' || user.sigla_empresa == 'EPASLS') ? 'Regime Simplificado' : 'IVA - Regime de Caixa'

    if (user.sigla_empresa == 'EPASN') {
      if (new Date(devolucao.created_at) > new Date('2021-05-31')) {
        tipo_regime = 'IVA - Regime de Caixa'
      } else {
        tipo_regime = 'Regime Simplificado'
      }
    }

    doc.text(tipo_regime, 192, posY + 102.5, 'right');
    doc.setTextColor(0);

    doc.setFontType("bold")
    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.rect(10, posY + 106, 185.5, 5, 'B');
    doc.setTextColor(0);
    doc.setFontType("bold");

    doc.setDrawColor(0);
    doc.rect(10, posY + 105, 185, 20, 'B');

    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);

    doc.setTextColor(0);
    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.text('Contas Bancárias (IBAN):', 12, posY + 109.5);
    doc.setFontSize(8);
    doc.setDrawColor(0);
    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.setFontSize(8);
    doc.setDrawColor(0);
    doc.setFillColor(255);
    doc.rect(10, posY + 111, 185, 20, 'B');
    doc.setTextColor(0);
    doc.setFontType("normal");
    const ContasFiltradas = [];

    contas_bancarias.forEach((item) => {
      var duplicated = ContasFiltradas.findIndex(redItem => {
        return item.iban == redItem.iban;
      }) > -1;

      if (!duplicated) {
        ContasFiltradas.push(item);
      }
    });
    var posX = 12;
    let posYY = 115;
    for (var i = 0; i < ContasFiltradas.length; i++) {
      if (i !== 0 && i % 3 === 0) {
        posX = 12;
        posYY += 4;
      }
      doc.text('' + ContasFiltradas[i].banco, posX, posY + posYY);
      doc.text('' + ContasFiltradas[i].iban, posX + 7, posY + posYY);
      posX += 60;
    }

    doc.text('Sempre que efectuar uma transferência ou depósito, deve indicar o número das recibos a liquidar.', 12, posY + 125.7);

    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.text('Lojas EPAS:', 10, posY + 135);
    doc.setFontType("normal");
    for (let loja of lojas) {
      doc.text('' + loja.nome + ', ' + loja.endereco + ', ' + (loja.horario ? loja.horario : ''), 11, posY + 138);
      posY += 3;
    }

    footer()


    function footer() {
      var dataActual = moment(new Date()).format("DD/MM/YYYY");
      doc.text(dataActual + ' ' + moment().format("H:m") + '/ Versão: ' + original, 11, 285);
      /*     doc.text(moment(recibo.created_at).format("DD/MM/YYYY") + ' ' + moment(recibo.created_at).format("H:m") + '/ Versão: ' + original, 11, 285); */
      // doc.text('Página: ' + numberPage, 184, 285);

      doc.setFontSize(8);
      doc.setDrawColor(0);
      doc.setFillColor(0);


      doc.line(10, doc.internal.pageSize.height - 9, 194, doc.internal.pageSize.height - 9); // vertical line
      var hash = devolucao == null ? '' : devolucao.hash ? devolucao.hash.substring(0, 1) : ''
      hash += devolucao == null ? '' : devolucao.hash ? devolucao.hash.substring(10, 11) : ''
      hash += devolucao == null ? '' : devolucao.hash ? devolucao.hash.substring(20, 21) : ''
      hash += devolucao == null ? '' : devolucao.hash ? devolucao.hash.substring(30, 31) : ''
      doc.setFontSize(6);

      doc.text("NIF: " + user.taxRegistrationNumber + " - " + user.companyName + " / " + user.addressDetail + " / " + (user.company_telefone || '') + " / " + (user.company_email || ''), 105, doc.internal.pageSize.height - 6, null, null, 'center');

      doc.setFontSize(8);
      doc.text(hash + '-Processado por programa validado nº 4/AGT119', 105, doc.internal.pageSize.height - 3, null, null, 'center');
    }



    if (report == 'imprimir') {
      doc.autoPrint();
      window.open(doc.output("bloburl")); //opens the data uri in new window
    } else {
      doc.save(devolucao.devolucao_sigla + '.pdf'); /* download the file immediately on loading */
    }

  }

  public imprimirPDEstorno(
    devolucao: any,
    linha_devolucaos: any[],
    cliente: any,
    tipos_identidades: any,
    user: any,
    linha_pagamentos: any,
    contrato: any,
    contas_bancarias: any,
    lojas: any,
    report: string = 'imprimir',
    original: any = 'Original'
  ) {
    var numberPage = 1;
    var img_logotipo = user.logotipo;
    var doc = new jsPDF();
    doc.addImage(img_logotipo, 'JPEG', 8, 14, 28, 24)

    doc.setFontSize(8);
    doc.setFont("calibri");
    doc.setTextColor(0);
    doc.text('' + (user.companyName || ''), 37, 20);
    doc.setTextColor(0);
    doc.text('' + (user.addressDetail || ''), 37, 25);
    doc.text('NIF: ' + (user.taxRegistrationNumber || ''), 37, 30);
    doc.text('Email: ' + (user.company_email || ''), 37, 35);
    doc.text('Telefone: ', 95, 30);
    doc.text('' + (user.company_telefone || ''), 107, 30);
    doc.text('WebSite: ', 95, 35);
    doc.setTextColor(0, 0, 255);
    doc.text('' + (user.site || ''), 107, 35);

    doc.setFontSize(8);
    doc.setDrawColor(0);
    doc.setFillColor(255);
    doc.rect(145, 14, 55, 23, 'B');

    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.text('ESTORNO N.º', 148, 20);
    doc.text('Data Emissão:', 148, 25);
    doc.text('Via: ' + original, 148, 35);

    doc.setFontSize(8);
    doc.setFontType("normal");
    doc.text('' + (devolucao.devolucao_sigla || ''), 172, 20);
    doc.text('' + moment(devolucao.created_at).format("DD/MM/YYYY"), 168, 25);

    doc.setFontSize(8);
    doc.setFont("calibri");
    doc.setFontType("normal");
    doc.text(cliente.nome.trimStart(), 120, 52);
    let text_local = "";// local_consumo.is_predio ? `, Prédio ${local_consumo.predio_nome ? local_consumo.predio_andar : ''}  ${local_consumo.moradia_numero}` : ` ${local_consumo.moradia_numero ? `, Residência nº ${local_consumo.moradia_numero}` : ''}`

    let full_morada = `${cliente.morada} ${text_local}`
    doc.text((cliente.morada == null ? '' : full_morada), 120, 57, { maxWidth: 80 });

    let desc_municipio = cliente.municipio ? cliente.municipio.nome : ''
    doc.text('' + desc_municipio, 120, 66.5);
    /*
    doc.text(cliente.nome.trimStart(), 148, 52);
    doc.text(cliente.morada, 148, 57);
    */

    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.rect(12, 75, 40.5, 3.5, 'B');
    doc.setFontType("bold");
    doc.text('Contrato N.º: ' + devolucao.contrato_id || '', 13, 77.5);
    doc.text(cliente.nome.trimStart(), 13, 83);
    doc.text('NIF: ', 13, 87);
    doc.setFontType("normal");
    let nif = tipos_identidades.find(obj => obj.nome == 'NIF')
    doc.text('' + (nif ? nif.numero_identidade : ""), 21, 87);

    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.rect(12, 100, 182, 5, 'B');
    doc.setFontSize(8);
    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.text('ESTORNO  N.º', 13, 103.5);
    doc.text(' ' + devolucao.devolucao_sigla + ' de  ' + moment(devolucao.created_at).format("DD/MM/YYYY"), 35, 103.5);
    doc.setFontType("normal");

    doc.setDrawColor(0, 0, 0);
    doc.setLineWidth(0.1);
    doc.line(12, 104.5, 194, 104.5); // horizontal line

    doc.setFontType("bold");
    doc.text('Nº Documento', 13, 108);
    doc.text('Valor Imposto.', 60, 108);
    doc.text('Valor Em Aberto', 90, 108);
    //    doc.text('Total a Pagar', 140, 108);
    doc.text('Total Estorno', 140, 108);
    doc.text('Estado Factura', 175, 108);
    doc.setTextColor(0);
    doc.setFontType("normal");

    doc.setDrawColor(0, 0, 0);
    doc.setLineWidth(0.1);
    doc.line(12, 109, 194, 109); // horizontal line
    doc.setTextColor(0);

    var posY = 115;
    for (var i = 0; i < linha_devolucaos.length; i++) {
      const element = linha_devolucaos[i];

      var document_sigla = element.is_entrada == true ? element.factura_sigla : element.recibo_sigla
      doc.text('' + document_sigla, 13, posY);
      doc.text('' + this.numberFormat(element.is_entrada ? element.totalComImposto : 0), 78, posY, 'right');
      doc.text('' + this.numberFormat(element.is_entrada ? element.total : element.total), 110, posY, 'right');
      doc.text('' + this.numberFormat(element.is_entrada ? element.total : element.total), 155, posY, 'right');
      doc.text('' + (element.total == null ? (element.pago == 0 ? 'Não Saldado' : 'Saldado') : (element.valor_aberto == 0 ? 'Saldado' : 'Não Saldado')), 193, posY, 'right');
      posY += 5;
    }

    doc.line(12, posY + 4.5, 194, posY + 4.5); // horizontal line

    const hasReachedPageLimit = (posY) >= 220

    if (hasReachedPageLimit) {
      posY = 10;
      footer()
      doc.addPage();
    }
    doc.setDrawColor(0, 0, 0);
    doc.setLineWidth(0.1);
    doc.line(12, posY + 4.5, 194, posY + 4.5); // horizontal line

    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.rect(12, posY + 5, 182, 5, 'B');
    doc.text('VALOR ESTORNO', 13, posY + 8);
    doc.text('' + this.numberFormat(devolucao.total), 155, posY + 8, 'right');
    // TOTAL RECEBIDO EM EXTENSO
    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.rect(12, posY + 12, 182, 5, 'B');
    doc.text(13, posY + 15, doc.splitTextToSize('VALOR POR EXTENSO', 50));
    var valor = extenso('' + this.numberFormat(devolucao.total), { locale: 'pt', mode: 'currency', currency: { type: 'EUR' }, decimal: 'formal' });
    doc.text('' + valor.replace(/(euros?)/gi, 'kwanzas') + '', 155, posY + 15, 'right')
    //RESUMO DOS IMPOSTOS

    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.rect(12, posY + 22, 182, 5, 'B');
    doc.text('MEIOS DE PAGAMENTOS', 13, posY + 25);
    doc.text('DESCRIÇÃO', 13, posY + 30);
    doc.text('BANCO', 65, posY + 30);
    doc.text('REFERÊNCIA', 95, posY + 30);
    doc.text('VALOR ESTORNO', 155, posY + 30, 'right');
    doc.setDrawColor(0, 0, 0);
    doc.setLineWidth(0.1);
    doc.line(12, posY + 31, 194, posY + 31); // horizontal line
    var posYLinhaPagamento = posY + 1;


    for (let linha of linha_pagamentos) {
      doc.text('' + linha.designacao, 13, posYLinhaPagamento + 35);
      doc.text('' + (linha.abreviatura == null ? '' : linha.abreviatura), 65, posYLinhaPagamento + 35);
      doc.text('' + (linha.referencia == null ? '' : linha.referencia), 95, posYLinhaPagamento + 35);
      doc.text('' + (this.numberFormat(linha.valor_recebido)), 155, (posYLinhaPagamento + 35), 'right');

      //doc.text('' + (linha.designacao == "Numerário" ? this.numberFormat(linha.valor_recebido - recibo.troco) : this.numberFormat(linha.valor_recebido)) , 155, (posYLinhaPagamento + 35), 'right');

      posYLinhaPagamento += 4
    }

    doc.setFontType("normal");

    /*if (recibo.isTrocoAdiantamento) {
      let text_adiantamento = `Adiantamento registado no valor de: ${this.numberFormat(recibo.valorCriadoAdiantamento)} AOA`
      doc.text((text_adiantamento), 15, posYLinhaPagamento + 38, null, null, 'left');
    }*/

    /*if (!recibo.isTrocoAdiantamento && recibo.troco > 0) {
      let text_adiantamento = `Valor do troco: ${this.numberFormat(recibo.troco)} AOA`
      doc.text((text_adiantamento), 15, posYLinhaPagamento + 38, null, null, 'left');
    }*/

    //doc.text((recibo.saldado_recibo == null ? '' : 'Adiantamento registado no valor de: '+ this.numberFormat(recibo.troco) ), 15, posYLinhaPagamento + 38, null, null, 'left');
    doc.setFontType("bold");




    posYLinhaPagamento += 18
    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.rect(12, posYLinhaPagamento + 25, 182, 5, 'B');
    doc.text('TOTAL ESTORNO', 13, posYLinhaPagamento + 28);
    if (devolucao.isTrocoAdiantamento) {
      doc.text('' + this.numberFormat(devolucao.valor_recebido), 155, posYLinhaPagamento + 28, 'right');
    }
    else {
      doc.text('' + this.numberFormat(devolucao.valor_recebido - devolucao.troco), 155, posYLinhaPagamento + 28, 'right');
    }

    // TOTAL RECEBIDO EM EXTENSO
    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.rect(12, posYLinhaPagamento + 31.5, 182, 5, 'B');
    doc.text(13, posYLinhaPagamento + 35, doc.splitTextToSize('VALOR POR EXTENSO', 50));
    var valor = extenso('' + this.numberFormat(devolucao.total - devolucao.troco), { locale: 'pt', mode: 'currency', currency: { type: 'EUR' }, decimal: 'formal' });
    doc.text('' + valor.replace(/(euros?)/gi, 'kwanzas') + '', 155, posYLinhaPagamento + 35, 'right')

    let text_obs = `OBSERVAÇÕES: ${devolucao.observacao ? devolucao.observacao : ''}`
    doc.text(text_obs, 13, posYLinhaPagamento + 45, { maxWidth: 182 });

    // END RESUMO DOS IMPOSTOS
    posY = 130;

    //ANULAÇÃO
    /*if (recibo.status == 'A') {
      doc.setDrawColor(0);
      doc.setFillColor(220, 220, 220);
      doc.rect(10, posY + 99, 52, 5, 'B');
      doc.setTextColor(0);
      doc.setFontType("bold");
      doc.text('Estado: ', 12, posY + 102.5);
      doc.setTextColor('red');
      doc.text('Anulação', 24, posY + 102.5);
      doc.setTextColor(0);
    }*/

    //REGIME DE IMPOSTO
    doc.setDrawColor(0);
    doc.setFillColor(220, 220, 220);
    doc.rect(160, posY + 99, 35, 5, 'B');
    doc.setTextColor(0);
    doc.setFontType("bold");

    let tipo_regime = (user.sigla_empresa == 'EPASN' || user.sigla_empresa == 'EPASLS') ? 'Regime Simplificado' : 'IVA - Regime de Caixa'

    if (user.sigla_empresa == 'EPASN') {
      if (new Date(devolucao.created_at) > new Date('2021-05-31')) {
        tipo_regime = 'IVA - Regime de Caixa'
      } else {
        tipo_regime = 'Regime Simplificado'
      }
    }

    doc.text(tipo_regime, 192, posY + 102.5, 'right');
    doc.setTextColor(0);

    doc.setFontType("bold")
    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.rect(10, posY + 106, 185.5, 5, 'B');
    doc.setTextColor(0);
    doc.setFontType("bold");

    doc.setDrawColor(0);
    doc.rect(10, posY + 105, 185, 20, 'B');

    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);

    doc.setTextColor(0);
    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.text('Contas Bancárias (IBAN):', 12, posY + 109.5);
    doc.setFontSize(8);
    doc.setDrawColor(0);
    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.setFontSize(8);
    doc.setDrawColor(0);
    doc.setFillColor(255);
    doc.rect(10, posY + 111, 185, 20, 'B');
    doc.setTextColor(0);
    doc.setFontType("normal");
    const ContasFiltradas = [];

    contas_bancarias.forEach((item) => {
      var duplicated = ContasFiltradas.findIndex(redItem => {
        return item.iban == redItem.iban;
      }) > -1;

      if (!duplicated) {
        ContasFiltradas.push(item);
      }
    });
    var posX = 12;
    let posYY = 115;
    for (var i = 0; i < ContasFiltradas.length; i++) {
      if (i !== 0 && i % 3 === 0) {
        posX = 12;
        posYY += 4;
      }
      doc.text('' + ContasFiltradas[i].banco, posX, posY + posYY);
      doc.text('' + ContasFiltradas[i].iban, posX + 7, posY + posYY);
      posX += 60;
    }

    doc.text('Sempre que efectuar uma transferência ou depósito, deve indicar o número das recibos a liquidar.', 12, posY + 125.7);

    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.text('Lojas EPAS:', 10, posY + 135);
    doc.setFontType("normal");
    for (let loja of lojas) {
      doc.text('' + loja.nome + ', ' + loja.endereco + ', ' + (loja.horario ? loja.horario : ''), 11, posY + 138);
      posY += 3;
    }

    footer()


    function footer() {
      var dataActual = moment(new Date()).format("DD/MM/YYYY");
      doc.text(dataActual + ' ' + moment().format("H:m") + '/ Versão: ' + original, 11, 285);
      /*     doc.text(moment(recibo.created_at).format("DD/MM/YYYY") + ' ' + moment(recibo.created_at).format("H:m") + '/ Versão: ' + original, 11, 285); */
      // doc.text('Página: ' + numberPage, 184, 285);

      doc.setFontSize(8);
      doc.setDrawColor(0);
      doc.setFillColor(0);


      doc.line(10, doc.internal.pageSize.height - 9, 194, doc.internal.pageSize.height - 9); // vertical line
      var hash = devolucao == null ? '' : devolucao.hash ? devolucao.hash.substring(0, 1) : ''
      hash += devolucao == null ? '' : devolucao.hash ? devolucao.hash.substring(10, 11) : ''
      hash += devolucao == null ? '' : devolucao.hash ? devolucao.hash.substring(20, 21) : ''
      hash += devolucao == null ? '' : devolucao.hash ? devolucao.hash.substring(30, 31) : ''
      doc.setFontSize(6);

      doc.text("NIF: " + user.taxRegistrationNumber + " - " + user.companyName + " / " + user.addressDetail + " / " + (user.company_telefone || '') + " / " + (user.company_email || ''), 105, doc.internal.pageSize.height - 6, null, null, 'center');

      doc.setFontSize(8);
      doc.text(hash + '-Processado por programa validado nº 4/AGT119', 105, doc.internal.pageSize.height - 3, null, null, 'center');
    }



    if (report == 'imprimir') {
      doc.autoPrint();
      window.open(doc.output("bloburl")); //opens the data uri in new window
    } else {
      doc.save(devolucao.devolucao_sigla + '.pdf'); /* download the file immediately on loading */
    }

  }

  public numberFormat(number) {
    return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2 }).format(number || 0).replace('€', '').trim();
  }

}
