import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { ExcelService } from 'src/app/services/excel.service';
import Swal from 'sweetalert2';
import { ExcelAutoService } from 'src/app/services/excel/excel-auto.service';
import * as moment from 'moment';
import { toDate, format } from 'date-fns-tz'
import { saveAs } from 'file-saver';
import { HttpEvent, HttpEventType } from '@angular/common/http';
const axios = require('axios')
import { environment } from 'src/environments/environment';
import { finalize } from 'rxjs/operators';
import { OrdemServicoReportService } from '../reports/ordem-servico-report.service';
import { forkJoin } from 'rxjs';
import is from 'date-fns/esm/locale/is/index.js';

interface Ifile {
  ext: string,
  nome: any,
  url: string,
  id: number,
  flag: string
}
@Component({
  selector: 'app-list-os-agendados',
  templateUrl: './list-os-agendados.component.html',
  styleUrls: ['./list-os-agendados.component.css']
})

export class ListOsAgendadosComponent implements OnInit {


  public tipos: any;
  public estados: any;
  public prioridades: any;
  checkedAll: boolean = false;
  private ordens = []


  private loading: boolean = false;

  @Input() ordem_sigla: string = "OT";

  public filters = {
    search: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    estado_id: null, // Ordenação
    prioridade_id: null, // Ordenação
    pagination: {
      perPage: 25,// Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima,
      lastPage: null,
      total: null // defini o total de registo da BD
    },
    filter: null, // subelemente do filter
    permission: null, // subelemente do filter
    startDate: null,
    endDate: null
  }

  private coordenada = {
    latitude: null,
    longitude: null,
    equipamentoDescricao: null
  }

  private dash = {
    abertoCount: 0,
    executadoCount: 0,
    canceladoCount: 0
  }
  progress: any = 0;
  progress_1: string = null;
  private localUrl: any;

  constructor(private auth: AuthService,
    private http: HttpService,
    private configService: ConfigService,
    public OrdemServicoReportService: OrdemServicoReportService,

    private excelService: ExcelService,
    private excelsService: ExcelAutoService) { }

  private items: any = [];
  private tarefas: any = [];
  private fileItems:any=[];
  private local_consumo: any = [];
  private resultados: any = [];

  ngOnInit() {
    this.dashboard()
    this.getPageFilterData(1);
    this.getEstados();
    this.getPrioridades();
    this.empresaUser();

    this.filters.permission = localStorage.getItem('permission');

    if (this.filters.permission === 'listagem_ot_comercial') {
      this.ordem_sigla = "OS";
    } else {
      this.ordem_sigla = "OT";
    }

  }
  timeOutput: any = null
  getTimeZone(date) {
    if (date == null) return
    const parisDate = toDate(date)
    const pattern = "dd/MM/yyyy"
    this.timeOutput = format(parisDate, pattern, { timeZone: 'UTC/GMT+1' })
    return this.timeOutput
  }
  private empresaUser() {
    this.configService.loaddinStarter('start');

    this.http.call_get('empresa/empresa-user', null).subscribe(
      response => {
        this.localUrl = Object(response).data.logotipo
        this.configService.loaddinStarter('stop');
      }
    );
  }


  setCheckedAll(checked: boolean) {

    this.checkedAll = checked;
       let request=[]
       this.items.forEach(f => f.checked = checked);

        for (const item of this.items) {
          let url =`ordemservico/get-report-ot-info/${item.os_agendamento_id}`
          request.push(this.http.call_get(url,null))
        }

        forkJoin(request).subscribe(
           res=>{
             const dados = Object(res);
             for (const item of dados) {
                this.ordens.push(item.data)
             }
           }
        )

  }


  updateChecked(f: any) {

    this.items.forEach(element => {
      element.checked = element.id === f.id ? !f.checked : element.checked;
      Object.assign(element);
    });
    const lengthFalse = this.items.filter(val => val.checked === true);
    this.checkedAll = lengthFalse.length === this.items.length ? true : false;
  }

  private confirmar(item) {

    Swal.fire({
      html: `
      <h3><b>Pretende cancelar o agedamento?</b></h3>
      OT`+ item.osCodigo + `/AG` + item.codigo_agendamento + `
   `,
      //title: '<strong></strong>',
      text: "Você não poderá reverter isso!",
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      confirmButtonColor: '#d33'

    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.value) {
        this.cancelarAgendamento(item.os_agendamento_id)
        Swal.fire('Sucesso!', '', 'success')
      } else if (!result.value) {
        Swal.fire('', '', 'info')
      }
    })

  }

  private cancelarAgendamento(id) {

    this.http.__call('ordemservico/cancelarAgendamento/' + id, null).subscribe(
      response => {
        if (Object(response).code == 200) {
          this.getPageFilterData(1)
        } else {
          this.configService.showAlert(Object(response).message, "alert-danger", true);
          this.getPageFilterData(1)
        }
      }
    );
  }

  private getTarefasByAgendamento(item) {
    this.http.call_get('ordemservico/tarefas-by-agendamento/' + item.os_agendamento_id, null).subscribe(
      response => {

        this.tarefas = Object(response).data;
      }
    );
  }

  private getClienteDetalhes(item) {

    this.local_consumo.cil = null;
    this.local_consumo.numero_serie = null;
    this.local_consumo.predio_nome = null;

    this.http.call_get('local-consumo/getLocalConsumoByContratoId/' + item.contrato_id, null).subscribe(
      response => {

        this.local_consumo = Object(response);
        this.local_consumo.cliente_nome = item.clienteNome;
        this.local_consumo.conta = item.numeroConta;
        this.local_consumo.distrito = this.local_consumo.distrito;
        this.local_consumo.bairro = this.local_consumo.bairro;
        this.local_consumo.contrato = item.contrato_id;
        this.local_consumo.cil = this.local_consumo.cil == null ? '' : this.local_consumo.cil;
        this.local_consumo.numero_serie = this.local_consumo.numero_serie == null ? '' : this.local_consumo.numero_serie;
        this.local_consumo.predio_nome = ((this.local_consumo.cil != null) ? '[' + this.local_consumo.cil + '] ' : '[0000-000-000] ') + ((this.local_consumo.is_predio) ? 'Prédio ' + this.local_consumo.predio_nome + ', ' + (this.local_consumo.predio_andar ? this.local_consumo.predio_andar + ' º Andar - ' : '') + 'Porta ' : 'Residência ') + this.local_consumo.moradia_numero;

      }
    );


  }

  private getFileList(id) {
    this.loading = true
    this.http.call_get(`ordemservico/agendamento-anexo/${id}`, null).subscribe(
      response => {
        this.fileItems = Object(response);
        this.loading = false
      }
    );
  }


  private listar() {

    //this.configService.loaddinStarter('start');

    this.filters.permission = localStorage.getItem('permission');

    this.loading = true
    this.http.__call('ordemservico/agendados/listagem', this.filters).subscribe(
      response => {
        this.filters.pagination.lastPage = Object(response).data.lastPage;
        this.filters.pagination.page = Object(response).data.page;
        this.filters.pagination.total = Object(response).data.total;
        this.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;

        this.items.forEach((element) => {
          Object.assign(element, { checked: false });
        });

        this.loading = false
      }
    );
  }

  getOrdemServio(ordem_servico_id:any){
    console.log('item',this.items)
    for(let item of this.items){
        console.log('os_tipos',item)
       if(item.agendamento === ordem_servico_id){
            console.log('item ordem',item)
            return item.os_tipo_id
       }
    }
    return null
  }

  private imprimirAgendamentoPreventivo(id){
    console.log(id)
    this.configService.imprimirAgendamentoPreventivo(id, "2ª Via", "imprimir")
  }

  exportAsXLSX(): void {
    var CurrentDate = new Date();
    for (let i = 0; i < this.items.length; i++) {
      this.items[i].agendamento != null ?
        this.items[i].agendamento = "SIM" :
        this.items[i].agendamento = "NÃO"
      this.items[i].equipamentoCodigo == null ? '' :
        this.items[i].equipamentoCodigo
        + ' - ' + this.items[i].equipamentoDescricao
    }
    var keys = [
      { key: 'osCodigo', width: 50 },
      { key: 'codigo_agendamento', width: 50 },
      { key: 'osTitulo', width: 30, style: { font: { name: 'Calibri' } } },
      { key: 'prioridadeDescricao', width: 30 },
      { key: 'tipoDescricao', width: 30 },
      { key: 'estadoDescricao', width: 30, style: { font: { name: 'Calibri' } } },
      { key: 'clienteNome', width: 30, style: { font: { name: 'Calibri' } } },
      { key: 'equipamentoCodigo', width: 30, style: { font: { name: 'Calibri' } } },
      { key: 'inicio_previsao', width: 50 },
      { key: 'fim_previsao', width: 50 },
      { key: 'fim_execucao', width: 50 },


    ];

    var Cols = [
      'Código OT',
      'Código AG',
      'Título OT',
      'Prioridade OT',
      'Tipo',
      'Estado AG',
      'Cliente',
      'Equipamento',
      'Início Previsto',
      'Fim Previsto',
      'Data Execução',
    ]

    var title = 'LISTA DE AGENDAMENTOS'
    var nameFile = "LISTA_DE_AGENDAMENTOS_" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    this.excelsService.excels(this.items, nameFile, this.localUrl, keys, Cols, title, 5, 11, 40, 3)
  }

  private dashboard() {

    this.filters.permission = localStorage.getItem('permission');

    this.http.__call('ordemservico/agendamento/dashBoard', this.filters).subscribe(
      response => {
        this.dash.abertoCount = Object(response).data.aberto;
        this.dash.executadoCount = Object(response).data.executado;
        this.dash.canceladoCount = Object(response).data.cancelado;

      }
    );
  }

  private getEstados() {
    this.http.call_get('ordemservico/estado/selectBox', null).subscribe(
      response => {
        this.estados = Object(response).data;
      }
    );
  }

  private getPrioridades() {
    this.http.call_get('ordemservico/prioridade/selectBox', null).subscribe(
      response => {
        this.prioridades = Object(response).data;
      }
    );
  }


  getPageFilterData(page: number) {

    if (this.filters.startDate != null && this.filters.endDate != null) {
      if (this.filters.startDate > this.filters.endDate) {
        this.configService.showAlert("Data Início não pode ser superior a Data Fim", 'alert-danger', true);
        return;
      }
    }

    if (this.filters.startDate != null && this.filters.endDate == null) {
      this.configService.showAlert("Digite a Data Fim", 'alert-danger', true);
      return;
    }

    if (this.filters.startDate == null && this.filters.endDate != null) {
      this.configService.showAlert("Digite a Data Início", 'alert-danger', true);
      return;
    }

    if (this.filters.pagination.perPage == null) {
      return;
    }
    this.filters.pagination.page = Number.isInteger(page) ? page : 1;
    this.listar();
  }

  private clearFilters() {
    this.filters = {
      search: null,
      orderBy: null,
      estado_id: null, // Ordenação
      prioridade_id: null, // Ordenação
      pagination: {
        perPage: 25,
        page: 1,
        lastPage: null,
        total: null
      },
      filter: null,
      permission: null,
      startDate: null,
      endDate: null

    }

    this.getPageFilterData(1);
  }


  private setData(item) {
    this.tipos = item
  }


  private getHistoricoByOrdem(item) {

    this.http.call_get('ordemservico/os-historico/listagem/' + item.id, null).subscribe(
      response => {
        this.resultados = Object(response).data;

      }
    );

  }

  private imprimirAgendamento(id) {
    this.configService.imprimirAgendamento(id, "2ª Via", "imprimir")
  }


  private async imprimirAgendamentoDat(id) {

    this.http.call_get('ordemservico/get-report-ot-info/' + id, null).subscribe(
      response => {
        const dados = Object(response).data;
        this.ordens.push(dados)

      }
    );

  }

  async getFactura(id) {

    const ordem = this.ordens.find(val => val.ordemTrabalho.codigo === id);
    return {
      ordemTrabalho: ordem.ordemTrabalho,
      ordemMaterial: ordem.ordemMaterial,
      ordemRecursosHumanos: ordem.ordemRecursosHumanos,
      empresa: ordem.empresa,
      ordemCliente: ordem.ordemCliente ? ordem.ordemCliente : false,
      ordemEquipamento: ordem.ordemEquipamento ? ordem.ordemEquipamento : false,
      agendamentoComentario: ordem.agendamentoComentario ? ordem.agendamentoComentario : false,
      agendamentoComentarioIntervencao: ordem.agendamentoComentarioIntervencao ? ordem.agendamentoComentarioIntervencao : false
    }
  }

  private get facturasChecked(): any[] {
    return this.items.filter(val => val.checked === true);
  }


  private async printMergePdfBuffers() {

    if (this.facturasChecked.length === 0) {
      this.configService.showAlert("Nenhuma factura seleccionada", "alert-error", true);
      return;
    }
    if (this.facturasChecked.length == 1) {
      this.configService.showAlert("Seleccione no minimo duas ou mais facturas", "alert-error", true);
      return;
    }
    this.loading = true;
    this.configService.showAlert("Estamos a gerar facturas estão a ser geradas", "alert-info", true);
    const facturas = this.facturasChecked;


    var buffers = [];
    for (let index = 0; index < facturas.length; index++) {
      const factura = facturas[index];
      const dados = await this.getFactura(factura.osCodigo);
      const buffer = this.OrdemServicoReportService.imprimirAgendamento(dados, "2ª Via", 'buffer')
      buffers.push(buffer);
    }

    console.log(buffers)
    const pdfUrl = await this.configService.mergePdfBuffers(buffers);
    this.loading = false;
    window.open(pdfUrl, '_blank');
  }

  async selectTipoFactura(type: string, dados: any, report = 'buffer') {
    return this.OrdemServicoReportService.imprimirAgendamento(dados, "2ª Via", report)
  }

  private getLatitudeLongitude(id) {

    this.coordenada.latitude = null;
    this.coordenada.longitude = null;


    this.configService.loaddinStarter('start');
    this.http.call_get('ordemservico/equipamento/getCoordenadas/' + id, null).subscribe(
      response => {
        this.coordenada.latitude = Object(response).data.latitude;
        this.coordenada.longitude = Object(response).data.longitude;
        this.coordenada.equipamentoDescricao = Object(response).data.descricao;
        this.configService.loaddinStarter('stop');
      }
    );


  }


  async downloads({ ext, url, nome, id, flag }: Ifile) {
    const documentStyles = document.documentElement.style;

    this.progress = id
    this.progress_1 = flag
    url = environment.app_url + "s" + url;

    axios({
      url: url,
      method: "post",
      responseType: "blob",
      async onDownloadProgress(progressEvent) {
        if (flag == 'no') {
          this.progress = await Math.round((progressEvent.loaded / progressEvent.total) * 100);
          documentStyles.setProperty('--progress', `${this.progress}%`);
        } else {
          this.progress = await Math.round((progressEvent.loaded / progressEvent.total) * 100);
          documentStyles.setProperty('--progress', `${this.progress}%`);
        }
      },
      data: {
        path: 'OT_Agendamento'
      },
    }).then(response => {
      var binaryData = [];
      var index = nome.indexOf(".");
      var afterComa = nome.substr(index, nome.length);

      if (ext == "vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        nome = nome.replace(afterComa, '.xlsx')
      }
      else if (ext == "vnd.oasis.opendocument.text") {
        nome = nome.replace(afterComa, '.odt')
      }

      const res = response
      binaryData.push(res.data);
      if (flag == 'no') {
        this.progress = 0;
        documentStyles.setProperty('--progress', `${this.progress}%`);
        return saveAs(new Blob(binaryData, { type: res.data.type }), nome)
      }
      this.progress = 0;
      documentStyles.setProperty('--progress', `${this.progress}%`);
      const fileURL = window.URL.createObjectURL(new Blob(binaryData, { type: res.data.type }))
      window.open(fileURL, '_blank');
    });
  }

}
