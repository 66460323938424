import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

@Component({
  selector: 'app-lte-pre-pago-form',
  templateUrl: './lte-pre-pago-form.component.html',
  styleUrls: ['./lte-pre-pago-form.component.css']
})
export class LtePrePagoFormComponent implements OnInit {


  private LTECEPS = {
    id: null,
    fabricante: null,
    modelo: null,
    numero_serie: null,
    tipo: null
  }


  private items: any = [];

  constructor(private http: HttpService, private configService: ConfigService) { }

  ngOnInit() {
    this.getPageFilterData(1);
  }

  private listaDispositivos() {

    this.configService.loaddinStarter('start');

    this.http.__call('LteCpe/listagem', this.http.filters).subscribe(

      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.configService.loaddinStarter('stop');

      }
    );
  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
    this.listaDispositivos();
  }


  private register(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (this.LTECEPS.numero_serie == null) {
      this.configService.showAlert("Digite o Número de Série", 'alert-danger', true);
    } else {
      this.http.__call('LteCpe/create', this.LTECEPS).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
            this.configService.loaddinStarter('stop');
          } else {
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.LTECEPS.numero_serie = null;
            this.LTECEPS.fabricante = null;
            this.LTECEPS.modelo = null;
            this.LTECEPS.tipo = null;
            this.listaDispositivos();
            this.configService.loaddinStarter('stop');
          }
        }
      )
    }
    ;
  }

  private refresh(id, numero_serie, fabricante, modelo, tipo) {
/*
    this.LTECEPS.id = id;
    this.LTECEPS.numero_serie = numero_serie;
    this.LTECEPS.fabricante = fabricante;
    this.LTECEPS.modelo = modelo;
    this.LTECEPS.tipo = tipo;
*/
  }

  private editar(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (this.LTECEPS.numero_serie == null) {
      this.configService.showAlert("O campo designação é obrigatorio", 'alert-danger', true);
    } else {
      this.http.__call('tecnologia/update/' + this.LTECEPS.id, this.LTECEPS).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            //this.configService.clearFormInputs(e);
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.listaDispositivos();

          }
        }
      );
    }
    this.configService.loaddinStarter('stop');
  }

  private clearFormInputs(e) {
    e.target.elements[0].value = null;
    e.target.elements[1].value = null;
    e.target.elements[2].value = null;
    e.target.elements[3].value = null;
  }



  private ini() {
    this.LTECEPS = {
      id: null,
      fabricante: null,
      modelo: null,
      numero_serie: null,
      tipo: null
    }
  }






}
