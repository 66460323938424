import { Component, OnInit, Input } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { TipoClienteService } from '../tipo-de-cliente.service';

@Component({
    selector: 'create-or-edit-tipo-cliente',
    templateUrl: './create-or-edit-tipo-cliente.component.html'
})

export class CreateOrEditTipoClienteComponent implements OnInit {

    @Input() tipo_cliente: any;
    
    constructor(
        private http: HttpService,
        private configService: ConfigService,
        private _tipoClienteService: TipoClienteService
    ){}

    ngOnInit(){

    }

    _submitForm(){
        this.configService.loaddinStarter('start');

        if (Boolean(this.tipo_cliente.tipoClienteDesc)) {
            if (this.tipo_cliente.id) {
                this._update()
            } else {
                this._add()
            }
        }else{
            this.configService.showAlert("A descrição é obrigatório", 'alert-danger', true);
        }
        this.configService.loaddinStarter('stop');
    }

    _add(){
        this._tipoClienteService.CreateTipoCliente( this.tipo_cliente )
        .subscribe( response => {
            this.configService.showAlert(response.message, 'alert-success', true);
            this._cleanForm();
            //this._loadingDependences()
        }),
        (error) => {
            if (!error.ok) {
                this.configService.showAlert(error.message, 'alert-danger', true);
            }
        }
    }

    _update(){
        this._tipoClienteService.UpdateTipoCliente( this.tipo_cliente )
        .subscribe( response => {
            this.configService.showAlert(response.message, 'alert-success', true);
            //this._loadingDependences()
        }),
        (error) => {
            if (!error.ok) {
                this.configService.showAlert(error.message, 'alert-danger', true);
            }
        }
    }

    _cleanForm(){
        this.tipo_cliente = {
            id: null,
            tipoClienteDesc: null
        }
    }


}


