import { Component, Input, OnInit, SimpleChange, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { Response } from 'selenium-webdriver/http';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
/*
import { RelMovimentoCaixaService } from 'src/app/components/report-at/relatorios/financeira/rel-movimento-caixa.service'; */
import { ExcelService } from 'src/app/services/excel.service';
import * as moment from 'moment';
import { ExcelAutoService } from 'src/app/services/excel/excel-auto.service';
import { ReportLojaService } from 'src/app/components/report-at/relatorios/financeira/report-loja.service';
import { RelMovimentoCaixaService } from 'src/app/components/report-at/relatorios/financeira/rel-movimento-caixa.service';
import { FilterCaixaService } from '../../relatorios/financeiro/report-movimentos-caixa/filter-caixa.service';

@Component({
  selector: 'listagemRecibosAnular',
  templateUrl: './listagem-recibos-anular.component.html',
  styleUrls: ['./listagem-recibos-anular.component.css']
})
export class ListagemRecibosAnularComponent implements OnInit {

  @Input() modal: any = "listagemRecibosAnularModal";
  @Input() title: string = "Listagem de Recebimentos";

  submitted = false;

  @Input() simpleForm: FormGroup;
  @Input() caixaActual: any;

  loading: boolean = false;

  items: any = [];
  dias: any = [];
  operadores: any = [];
  lojas: any = [];
  caixas: any = [];
  movimentos: any = [];

  private disabledButton = true

  public filters = {
    search: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    pagination: {
      perPage: 5,// Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima,
      lastPage: null,
      total: null // defini o total de registo da BD
    },
    filter: null // subelemente do filter
    , is_allexel: false
  }

  private localUrl: any;
  private largura: any;
  private altura: any;
  tipoFacturacao: string;
  private loadingLojas: string = "Agência"

  private recibo = {
    recibo_id: null,
    recibo_sigla: null,
    status_reason: null,
    status_date: null,
    status: null,
    serie: null,
    serie_id: null,
    municipio_id: null,
    operador_id: null
  }

  constructor(
    private formBuilder: FormBuilder,
    private http: HttpService,
    private excelService: ExcelAutoService, private configService: ConfigService,
    private reportMovimentoCaixa: RelMovimentoCaixaService,
    private filterService: FilterCaixaService
  ) {
    this.createForm();
  }

  ngOnInit() {
    /* this.getLojas(); */
    this.getLojas()
    this.empresaUser()
    this.getCaixas()

  }


  createForm() {

    this.simpleForm = this.formBuilder.group({
      data1: [null, Validators.required],
      data2: [null, [this.matchValidator.bind(this)]],
      operador: null,
      caixa: [null],
      loja: [null],
      status: [null],
      loja_nome: [null],
      caixa_estado: [null]
    });
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.simpleForm.controls;
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if (this.caixaActual !== undefined) {
      this.simpleForm.patchValue({ caixa: this.caixaActual.id })
      this.title = "";
      this.onSubmit();
    } else {
      this.title = "";
    }
  }

  onSubmit() {

    this.submitted = true;
    // parar aquei se o simpleFormulário for inválido
    if (this.simpleForm.invalid) {
      //return;
    }

    const uri = 'relatorio/financeira/movimentocaixa-resumo-chefeLoja';
    this.simpleForm.patchValue({
      data1: this.filterService.filter.data1,
      data2: this.filterService.filter.data2,
      operador: this.filterService.filter.operador,
      loja: this.filterService.filter.loja,
      status: this.filterService.filter.status,
      loja_nome: this.filterService.filter.loja_nome,
      caixa_estado: this.filterService.filter.caixa_estado
    })
    this.createOrEdit(uri, this.simpleForm);
  }
  private empresaUser() {
    this.configService.loaddinStarter('start');

    this.http.call_get('empresa/empresa-user', null).subscribe(
      response => {
        this.localUrl = Object(response).data.logotipo
        this.altura = Object(response).data.width
        this.largura = Object(response).data.height
        this.configService.loaddinStarter('stop');
      }
    );
  }

  setTrue() {
    this.filters.pagination.page = this.filters.pagination.page
    this.filters.is_allexel = true
    this.onSubmit()
  }
  createOrEdit(uri: any, simpleFormulario: FormGroup) {
    this.movimentos = []
    this.loading = true;
    this.disabledButton = true;
    this.totais.total = 0;
    this.totais.valor_saldado = 0;
    this.totais.movimento = 0
    this.totais.saldado_recibo = 0

    this.http.__call(uri, simpleFormulario.value).subscribe(
      response => {
        this.movimentos = Object(response).data;
        this.loading = false;
        if (this.movimentos.length != 0) {
          this.disabledButton = false;
          this.somaTotais();
        }
        //this.simpleForm.reset()
        this.caixaActual = null
        this.loading = false;
      }
    );
  }

  exportAsXLSX(): void {
    let data = JSON.parse(JSON.stringify(this.movimentos))

    var CurrentDate = new Date();
    for (let i = 0; i < data.length; i++) {

      data[i].movimento = data[i].movimento ? data[i].movimento : '-------'
      data[i].saldado_recibo = data[i].saldado_recibo ? data[i].saldado_recibo : '-------'

      if (data[i].is_active == 1) {
        data[i].is_active = "Aberto"
      } else {
        data[i].is_active = "Fechado"
      }
    }
    var keys = [
      { key: 'user', width: 40, style: { font: { name: 'Calibri' } } },
      { key: 'loja', width: 50 },
      { key: 'caixa_id', width: 15 },
      { key: 'recibo_sigla', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'forma_paga', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'created_at', width: 20 },
      { key: 'is_active', width: 20 },
      { key: 'statu', width: 30, style: { numFmt: '#,##0.00', } },
      { key: 'total', width: 40, style: { numFmt: '#,##0.00', } },
      { key: 'valor_saldado', width: 50, style: { numFmt: '#,##0.00', } },
      /*   { key: 'movimento', width:40, style: { numFmt: '#,##0.00', } },
        { key: 'saldado_recibo', width: 50 ,style: { numFmt: '#,##0.00', }}, */
    ];

    var Cols = [
      "Operador",
      "Agência",
      "Nº Caixa",
      "Recibos",
      "Forma Pagamento",
      "Data",
      "Estado Caixa",
      "Estado",
      "TOTAL",
      "TOTAL RECEBIDO",
      /*  "ADM GERADO",
       "ADM RETIFICADO", */
    ]

    var title = 'RESUMO DE MOVIMENTOS DE CAIXA'
    var nameFile = "RESUMO_DE_MOVIMENTOS_DE_CAIXA - [" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " ]"
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    this.excelService.excels(data, nameFile, this.localUrl, keys, Cols, title, 5, 10, 40, 3, [9, 10])
  }
  exportAsPDF(): void {
    var file = document.getElementsByClassName("exportAsXLSXCliente")[0]
    this.reportMovimentoCaixa.relatoriomovimentocaixa(file, 'save', this.localUrl);
  }

  imprimirPDF(): void {
    var file = document.getElementsByClassName("exportAsXLSXCliente")[0]
    this.reportMovimentoCaixa.relatoriomovimentocaixa(file, 'print', this.localUrl);
  }
  matchValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const fromValue = control.value;
    if (fromValue) {

      // console.log(this.simpleForm.controls)
      const startDate = (<FormGroup>this.simpleForm.get('data1')).value;
      const endDate = (<FormGroup>this.simpleForm.get('data2')).value;
      if (startDate <= endDate) {
        //console.log('Control: ', control);
        return null;
      }
      //console.log('Control: ', control);
      return { 'invalidDate': true };
    }

  }

  changeProduto() {
    this.simpleForm.patchValue({
      loja_nome: null
    });
    this.operadores.forEach(element => {
      if (element.id == this.simpleForm.getRawValue().produto) {
        this.simpleForm.patchValue({
          produto_nome: element.nome
        });
      }
    });
  }

  changeLoja() {
    this.simpleForm.patchValue({
      loja_nome: null
    });
    this.lojas.forEach(element => {
      if (element.id == this.simpleForm.getRawValue().loja) {
        this.simpleForm.patchValue({
          loja_nome: element.nome
        });
      }
    });
  }

  /* private listClientes() {
     this.loading= true
     this.http.filters.filter = this.filter;
     this.http.__call('relatorio/clientes', this.http.filters).subscribe(
       response => {
         this.items = Object(response).data;
         this.loading = false;
       }
     );
   }
 
   getPageFilterData() {
     this.listClientes();
   }*/
  private getLojas() {
    this.http.call_get('loja/selectBox', null).subscribe(
      response => {
        this.lojas = Object(response).data
        const id = this.simpleForm.getRawValue().loja;
        this.lojas.map(res => {
          if (res.id == id) {
            this.getoperadores(res.id)
          } else {
            return
          }
        })
      }
    );
  }

  private getoperadores(id) {
    this.loadingLojas = 'Carregando...';
    this.http.call_get('user/selectBox/' + id, null).subscribe(
      response => {
        this.operadores = Object(response).data
      }
    )

  }
  private getCaixas() {
    this.loadingLojas = 'Carregando...';
    this.http.call_get('caixa/selectBox', null).subscribe(
      response => {
        this.caixas = Object(response).data
      }
    )

  }

  private responseCode: 0
  private setRecibo(recibo: any) {
    this.responseCode = 0
    this.recibo.recibo_id = recibo.id
    this.recibo.recibo_sigla = recibo.recibo_sigla
    this.recibo.serie = recibo.serie
    this.recibo.operador_id = recibo.operador_id
    this.recibo.status_reason = null
    this.recibo.serie_id = null
  }

  private anularRecibo(e) {
    e.preventDefault();

    if (this.recibo.status_reason.length > 49) {
      this.configService.showAlert('O motivo da anulação só deve ter 50 caracteres no maximo.', 'alert-danger', true);
      return
    }

    if (this.recibo.status_reason == null) {
      this.configService.showAlert('É obrigatório fornecer o nº da recibo e o motivo', 'alert-danger', true);
    } else {
      this.configService.loaddinStarter('start');
      this.http.__call('recibo/anular-chefe-loja', this.recibo).subscribe(
        res => {
          if (Object(res).code == 500 || Object(res).code == 201) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.recibo = {
              recibo_id: null,
              recibo_sigla: null,
              status_reason: null,
              status_date: null,
              status: null,
              serie: null,
              serie_id: null,
              operador_id: null,
              municipio_id: null
            }

            this.onSubmit()
            this.responseCode = Object(res).code
          }
          this.configService.loaddinStarter('stop');
        }
      );
    }
  }

  private totais = {
    total: 0,
    valor_saldado: 0,
    movimento: 0,
    saldado_recibo: 0
  }
  private somaTotais() {
    var total: number = 0;
    var valor_saldado: number = 0;
    var movimento: number = 0;
    var saldado_recibo: number = 0;
    this.movimentos.forEach(element => {
      total += element.total;
      valor_saldado += element.valor_saldado;
      movimento += element.movimento;
      saldado_recibo += element.saldado_recibo;
    });
    this.totais.total = total;
    this.totais.valor_saldado = valor_saldado;
    this.totais.movimento = movimento
    this.totais.saldado_recibo = saldado_recibo
  } OnInit() {
  }

}
