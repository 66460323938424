import { finalize } from 'rxjs/operators';
import { ExcelAutoService } from './../../services/excel/excel-auto.service';
import { ApiService } from './../../providers/http/api.service';
import { ExcelService } from './../../services/excel.service';
import { Router } from '@angular/router';
import { FacturaCicloService } from './../reports/factura-ciclo/factura-ciclo.service';
import { Component, OnInit, Input, ViewChild, SimpleChange } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-lista-linha-prestacao-by-plano-prestacao',
  templateUrl: './lista-linha-prestacao-by-plano-prestacao.component.html',
  styleUrls: ['./lista-linha-prestacao-by-plano-prestacao.component.css']
})
export class ListaLinhaPrestacaoByPlanoPrestacaoComponent implements OnInit {

  @Input() modal: string = "modalListaLinhaPrestacao";
  @Input() res: any;
  @Input() item: any;
  @Input() plano_header: any;
  @Input() os_tipo_id: any

  loading = false
  loading_factura = null
  linhaEditar = null
  items: any = {}
  public facturas_selecionada: any = [];
  linhaSelecionada: any
  private factura: any = null;
  private valor_aberto: number = 0
  private linhas_nao_pagas: any = [];


  public filters = {
    search: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    pagination: {
      perPage: 5,// Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima,
      lastPage: null,
      total: null // defini o total de registo da BD
    },
    filter: null // subelemente do filter
  }

  constructor(
    private http: HttpService,
    private reportFactura: FacturaCicloService,
    private configService: ConfigService,
    private _route: Router,
    private excelService: ExcelService,
    private api: ApiService,
    private _excelService: ExcelAutoService,
  ) { }

  ngOnInit() { }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if (this.res !== undefined) {
      this.valor_aberto = 0

      this.linhas_nao_pagas = this.res.filter((linha: any) => linha.estado != 'PAGO')

      for (const linha of this.linhas_nao_pagas) {
        this.valor_aberto += linha.valor
      }
    }
  }

  public getSelecionarLinhaPagamento(item) {
    this.linhaEditar = item
    console.log("Este é ", this.linhaEditar)
  }

  public getPagamentoLinhaPrestacao(item) {
    this.linhaSelecionada = item
    this.api
      .get("plano-prestacao/factura-prestacao/" + item.plano_prestacao_id)
      .pipe(
        finalize(() => {
          this.loading_factura = false;
        })
      )
      .subscribe((response) => {
        if (Object(response).code == 200) {
          this.facturas_selecionada = Object(response).data;
          console.log(this.facturas_selecionada)
        } else {
          this.configService.showAlert
            (
              Object(response).message,
              "alert-danger",
              true
            );
        }
      });
    console.log(this.linhaSelecionada)
  }


  public showFactura(id: number) {
    //this.ncLengthAnulado = 0;
    //this.factura = null;
    // this.loading_factura = true;

  }


}
