import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import * as moment from 'moment';
import { ConfigService } from 'src/app/providers/config/config.service';
import { HttpService } from 'src/app/providers/http/http.service';
import { ExcelService } from 'src/app/services/excel.service';
import { ExcelAutoService } from 'src/app/services/excel/excel-auto.service';
import { ReportClienteService } from '../../report-at/relatorios/report-cliente.service';

@Component({
  selector: 'app-estado',
  templateUrl: './estado.component.html',
  styleUrls: ['./estado.component.css']
})
export class EstadoComponent implements OnInit, OnDestroy {


  loading: boolean = false;
  disableButton: boolean = true;


  submitted = false;
  public filters = {
    search: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    pagination: {
      perPage: 5,// Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima,
      lastPage: null,
      total: null // defini o total de registo da BD
    },
    filter: null // subelemente do filter
    ,is_allexel:false
  }

  private localUrl: any;
  private largura: any;
  private altura: any;
  items: any = [];
  estado: any = [];
  simpleFormCliente: FormGroup

  constructor(private http: HttpService,
    private configService: ConfigService,
     private excelService: ExcelService,
     private reportClientes: ReportClienteService,
     private excelsService: ExcelAutoService,
     private _formBuilder: FormBuilder,
     ) {
      this.createForm()
  }

  ngOnInit() {
    this.empresaUser()
    this.getPageFilterData(1);
    this.getEstado()
    this.getPageFilterDataPage(1)
  }
  ngOnDestroy(){
    this.http.filters.orderBy = null
  }

  get f() {
    return this.simpleFormCliente.controls;
  }
  createForm() {
    this.simpleFormCliente = this._formBuilder.group({
      id: null,
      descricao: [null, [Validators.required]],
      flag: [null, [Validators.required]],
      estado: null,
    })

  }
  private createFlagKeyup(value){
    this.simpleFormCliente.patchValue({flag:value})
  }
  private disable(){
    this.simpleFormCliente.controls.flag.disable();
  }
  exportAsPDF(): void {
    var file = document.getElementsByClassName("exportAsXLSXCliente")[0]
    this.reportClientes.relatorioClientes('save', file,this.localUrl);

  }

  imprimirPDF(): void {
    var file = document.getElementsByClassName("exportAsXLSXCliente")[0];
    /*     this.reportClientes.relatorioClientes(this.items,file,'print'); */
    this.reportClientes.relatorioClientes('print', file,this.localUrl);
    // this.reportCliente.relatorioClientes(this.items);
  }

  private empresaUser() {
    this.configService.loaddinStarter('start');

    this.http.call_get('empresa/empresa-user', null).subscribe(
      response => {
        this.localUrl = Object(response).data.logotipo
        this.altura = Object(response).data.width
        this.largura = Object(response).data.height
        this.configService.loaddinStarter('stop');
      }
    );
  }
/*   private getEstado(){
    this.http.__call('comunicacoes/estado_listagem',null).subscribe(
      response => {
        this.estado = Object(response).data;
      })
  } */
  setTrue(){
    this.filters.pagination.page=this.filters.pagination.page
    this.filters.is_allexel=true
    this.getEstado()
  }
  private getEstado() {
    console.log(this.filters)
    this.loading = true
    this.http.__call('comunicacoes/estado_listagem_all', this.filters).subscribe(
      response => {
         this.filters.pagination.lastPage = Object(response).data.lastPage;
         this.filters.pagination.page = Object(response).data.page;
         this.filters.pagination.total = Object(response).data.total;
         this.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        console.log(this.items)
        this.loading = false;
        if (this.items != 0) {
          this.disableButton = false;
        }

        if(this.filters.is_allexel==false){
          this.items = Object(response).data.data;
        }else{
          this.items = Object(response).data.data;
          this.exportAsXLSX(this.items)
        }
        this.filters.is_allexel=false
        this.loading = false;
      }
    );
  }

  exportAsXLSX(data:any):void {
    var CurrentDate = new Date();
     for(let i=0;i<data.length;i++){
       if(data[i].is_active==1){
           data[i].is_active="Aberto"
       }else{
           data[i].is_active="Fechado"
       }
     }
    var keys = [
      { key: 'cliente_id', width: 30 },
      { key: 'contrato_id', width: 15 },
      { key: 'numero_contador', width:30, style: { font: { name: 'Calibri' } } },
      { key: 'cil', width:30, style: { font: { name: 'Calibri' } } },
      { key: 'calibre', width: 30 },
      { key: 'numero_serie', width: 30 },
      { key: 'estado_contrato', width: 30 },
      { key: 'data_inicio', width: 30 },
      { key: 'data_fim', width: 30 },
      { key: 'data_rescisao', width: 30 },
      { key: 'motivo_recisao', width: 50 },
      { key: 'descricao', width: 30 },
      { key: 'tipoClienteDesc', width: 30 },
      { key: 'nivel_sensi', width: 30 },
      { key: 'nome', width: 30 },
      { key: 'factura_sigla', width: 30 },
      { key: 'morada', width: 30 },
      { key: 'ruas', width: 30 },
      { key: 'municipio', width: 30 },
      { key: 'bairro', width: 30 },
      { key: 'numero_identificacao', width: 30 },
      { key: 'tipo_identidade', width: 30 },
      { key: 'tipo_cliente', width: 30 },
      { key: 'email', width: 30 },
      { key: 'telefone', width: 30 },
      { key: 'media_consumo', width: 30 },
    ];

    var Cols = [
        'Nº Cliente','Nº Contrato','Nº Contador','Cil',
        'Calibre','Contador','Estado Contrato','Data Contrato','Data Prevista Fim','Data Rescisão',
        'Motivo Rescisão','Tarifa','Tipo Cliente','Nivel sensibilidade','Nome Cliente',
        'Documento','Morada Cliente','Morada','Município','Bairro','Identificação','Tipo Identidade',
        'Tipo Cliente','Email', 'Telefone','Consumo Médio Mensal'
      ]

    var title='RELATÓRIOS CLIENTES'
    var nameFile = "RELATORIOS_CLIENTES -" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
     this.excelsService.excels(data,nameFile,this.localUrl,keys,Cols,title,5,26,40,3)
  }



  onSubmit() {
    this.submitted = true
    if (this.simpleFormCliente.invalid) { return }
    let cliente_value = this.simpleFormCliente.getRawValue()
    let url_action = cliente_value.id ? `comunicacoes/estado/${cliente_value.id}` : 'comunicacoes/estado'
    this._createOrUpdateCliente(url_action, cliente_value)
  }
  _createOrUpdateCliente(url, cliente) {
    console.log(this.simpleFormCliente)
    this.http.__call(url, cliente)
      .subscribe(
        response => {

          this.submitted = false
          this.loading = false

          this.getEstado();
          this.configService.showAlert(Object(response).message, 'success', true)
          this.getPageFilterData(1)
          if(this.simpleFormCliente.value.id==null)this.simpleFormCliente.reset()
          //this.after_reset()
        },
        error => {
          this.configService.showAlert("Alguma coisa correu mal", 'warning', true);
          this.submitted = false;
        }

      )
    this.loading = false
  }

  iniCliente(cliente) {
    this.simpleFormCliente.patchValue(cliente)
    this.disable()
  }

  _closeModal(closeModal) {
    var action = document.getElementById(closeModal)
    action.click()
  }

  onReset() {
    this.submitted = false;
    this.simpleFormCliente.reset();
  }


  getPageFilterData(page: number) {
     if (this.filters.pagination.perPage == null) {
       return;
     }
     this.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
     
     this.getEstado();
   }
  getPageFilterDataPage(page: number) {
     if (this.filters.pagination.perPage == null) {
       return;
     }
     this.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
     this.getEstado();
   }

   private refreshData({descricao}) {
    this.simpleFormCliente.value.descricao= descricao
  }

}



