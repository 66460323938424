import { Component, OnInit, Input } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { PedidosComponent } from 'src/app/components/pedidos/pedidos/pedidos.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cobre-form',
  templateUrl: './cobre-form.component.html',
  styleUrls: ['./cobre-form.component.css']
})
export class CobreFormComponent implements OnInit {

  @Input() cliente: any;
  @Input() pedidoObject: any;
  @Input() contrato: any;

  private pedido = {
    id: null,
    contador: null,
    contador_id: null,
    contrato_id: null,
    conta_id: null,
    observacao: null,
    local_instalacao: null,
    estado_pedido_sigla: null

  }

  private showContadorDetail: boolean = false;

  private contador = {
    id: null,
    numero_serie: null,
    marca: null,
    ultima_leitura: null,
    modelo: null
  }

  private conta = {
    id: null
  }

  private interest = {
    id: null
  }

  private isFinalizar: boolean = true;
  private contadorMessage: string = null;
  private contadorClassMessage: string = null;

  constructor(private http: HttpService, private configService: ConfigService, private router: Router, private PedidosComponent: PedidosComponent) {

  }

  ngOnInit() {
    this.getPageFilterData(1);
  }

  private clearForm() {
    this.isFinalizar = true;
    this.pedido.id = null;
    this.pedido.contador_id = null;
    this.pedido.local_instalacao = null;
    this.pedido.estado_pedido_sigla = null;
    this.conta.id = null;
    this.contador.numero_serie = null;
    this.contador.id = null;
    this.pedido.observacao = null;
    this.contadorMessage = null;
    this.contadorClassMessage = null;
  }

  private showSubmit() {
    this.isFinalizar = true;
  }


  private validateSerieContador() {

    if (this.contador.numero_serie == null) {
      this.contadorMessage = "Digite o Número de Série!";
      this.contadorClassMessage = "text-danger";
    } else {
      this.configService.loaddinStarter('start');
      this.http.__call('contador/validate/serie', this.contador).subscribe(
        data => {
          if (data.code === 200) {
            this.contador.id = data.data.id;
            this.contador.numero_serie = data.data.numero_serie;
            this.contador.marca = data.data.marca;
            this.contador.modelo = data.data.modelo;
            this.contador.ultima_leitura = data.data.ultima_leitura;
            this.contadorMessage = "Sucesso";
            this.contadorClassMessage = "text-success";

            //this.classMessage = "glyphicon glyphicon-ok";
            this.configService.loaddinStarter('stop');
            this.showContadorDetail = true;

          }
        }, error => {
          //console.log(error.status);
          if (error.status === 302) {
            this.contadorMessage = "Contador em uso!";
            this.contadorClassMessage = "text-warning";
            this.contador.id = 302;
          } else if (error.status === 404) {
            this.contadorMessage = "Esse número de série não existe";
            this.contadorClassMessage = "text-warning";
            this.contador.id = 404;
          } else {
            this.contadorMessage = "Não definido";
            this.contadorClassMessage = "text-warning";
          }
          this.configService.loaddinStarter('stop');
        }
      );
    }
  }


  private finalizarPedido() {
    //console.log(this.ServicoObject)
    this.pedido.id = this.pedidoObject.pedido_id;
    this.pedido.contrato_id = this.pedidoObject.contrato_id;
    this.pedido.conta_id = this.pedidoObject.conta_id;
    this.pedido.local_instalacao = this.pedidoObject.residencia_id;
    this.pedido.estado_pedido_sigla = this.pedidoObject.estado_pedido_sigla;


    if ((this.pedido.estado_pedido_sigla == 'FECHADA' && this.contador.numero_serie == null) && this.contrato.tipo_medicao_slug !='ESTIMATIVA') {
      this.configService.showAlert('Digite o número de Série do contador', 'alert-danger', true);
    }else if ((this.contador.id == null || this.contador.id == "") && this.contrato.tipo_medicao_slug !='ESTIMATIVA') {
      this.configService.showAlert('Clique no ícone para validar', "alert-danger", true);
    } else if (this.contador.id == 302) {
      this.configService.showAlert('Esse Contador já está em uso', "alert-danger", true);
    } else if (this.contador.id == 404) {
      this.configService.showAlert('Esse número de série não existe', "alert-danger", true);
    } else {

      this.isFinalizar = false;

      this.showContadorDetail = false;

      this.configService.loaddinStarter('start');
      this.http.__call('pedido/finalizar', {
        contrato_id: this.pedido.contrato_id,
        conta_id: this.pedido.conta_id,
        residencia_id: this.pedido.local_instalacao,
        contador_id: this.contador.id,
        estado_pedido_sigla: this.pedido.estado_pedido_sigla,
        observacao: this.pedido.observacao,
        pedido_id: this.pedido.id
      }).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
            this.isFinalizar = true;
          } else {
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.configService.loaddinStarter('stop');
            this.pedido.id = null;
            this.contador.numero_serie = null;
            this.contador.id = null;
            this.pedido.observacao = null;
            this.pedido.local_instalacao = null;
            this.pedido.estado_pedido_sigla = null;
            this.pedido.contrato_id = null;
            this.pedido.conta_id = null;
            this.contadorMessage = null;
            this.contadorClassMessage = null;
            this.PedidosComponent.getPageFilterData(1);

          }
        }
      );
    }


  }

  private hideBotton() {
    this.PedidosComponent.hideBottonAvancar();
  }


  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
  }

}
