import { Component, OnInit, Input, createPlatformFactory } from '@angular/core';
import { ConfigService } from 'src/app/providers/config/config.service';
import { HttpService } from 'src/app/providers/http/http.service';
import { GeneroService } from './genero.service';

@Component({
    selector: 'app-genero',
    templateUrl: './genero.component.html'
})

export class GeneroComponent implements OnInit {

    genero: any = {
        id: null,
        abreviacao: null,
        descricao: null
    }

    generos: any = []

    constructor(
        private _generoService: GeneroService,
        private configService: ConfigService,
        private http: HttpService
    ){
        this._loadingDependences()
    }

    ngOnInit() {

    }

    _save(){
      this.genero.descricao=this.genero.descricao.toUpperCase()
      this.genero.abreviacao=this.genero.abreviacao.toUpperCase()
      let count=0
     /*  this.generos.map(res=>{
        if( res.descricao==this.genero.descricao || res.abreviacao==this.genero.abreviacao ){
          console.log(res.descricao)
          count++
          return
        }
      }) */
      /*  if(count>=0) return */
      this.configService.showAlert("Já existe este Gênero", 'alert-danger', true);
        this._generoService.CreateGenre( this.genero )
        .subscribe( response => {
            this._loadingDependences()
        }),
        (error) => {
            if (!error.ok) {
            }
        }


    }

    _loadingDependences(){
        this._generoService.getGenres()
        .subscribe((response) => {
            this.generos = response.data.data
        })
    }

    private clearFormInputs(e) {
        e.target.elements[0].value = null;
        e.target.elements[1].value = null;
        e.target.elements[2].value = null;
      }
    
      private refresh(id, abreviacao, descricao) {
        this.genero.id = id;
        this.genero.abreviacao = abreviacao;
        this.genero.descricao = descricao;
      }
    
      private editar(e) {
        this.configService.loaddinStarter('start');
        e.preventDefault();
     
          this.http.__call('genero/update/' + this.genero.id, this.genero).subscribe(
            res => {
              if (Object(res).code == 201) {
                this.configService.showAlert(Object(res).message, 'alert-danger', true);
              } else {
                //this.configService.clearFormInputs(e);
                this.configService.showAlert(Object(res).message, 'alert-success', true);
                this._loadingDependences()
    
              }
            }
          );
        this.configService.loaddinStarter('stop');
      }

      private ini() {
        this.genero = {
            id: null,
            abreviacao: null,
            descricao: null
        }
      }
}
