import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { BsModalService, BsModalRef, ModalDirective, ModalModule } from 'ngx-bootstrap/modal';
import { ConfigService } from 'src/app/providers/config/config.service';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { json } from '@rxweb/reactive-form-validators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-log-gestao-divida',
  templateUrl: './log-gestao-divida.component.html',
  styleUrls: ['./log-gestao-divida.component.css']
})
export class LogGestaoDividaComponent implements OnInit {

  @ViewChild('closebutton') closebutton;
  @ViewChild('modalAppendServico') modalAppendServico: ModalDirective;

  loading = false;
  loadingListarFacturas = false;

  @ViewChild('modalShowEvent') public modalShowEvent: ModalDirective;

  private filter = {
    municipio_id: null,
    distrito_id: null,
    bairro_id: null,
    quarteirao_id: null
  }
  public filters = {
    search: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    pagination: {
      perPage: 10,// Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima,
      lastPage: null,
      total: null // defini o total de registo da BD
    },
    filter: null // subelemente do filter
  }
  private logs: any = [];
  checkedAll: boolean = true;
  private facturasIncumprimento: any = [];

  private dadosClienteSelecionado = {
    nome: null,
    totaldivida: null
  }

  private cliente: any = {}


  private dashboard = {
    totalContratos: null,
    totalFacturas: null,
    totaldividas: null,
  };
  distritos: any[]
  municipios: any[]
  bairros: any[]
  quarteiroes: any[]

  private currrent_log: any;

  constructor(
    private auth: AuthService,
    private http: HttpService,
    private configService: ConfigService,
    private _route: Router
  ) { }

  ngOnInit() {
    this.getPageFilterData(1);
    this.listagem();
    this.http.filters.pagination.perPage = 5
    this.http.filters.pagination.page = 5
  }

  private getMunicipios() {

    this.municipios = []
    this.filter.distrito_id = null
    this.filter.bairro_id = null
    this.http.call_get('municipio/selectBox', null)
      .subscribe(
        response => {
          this.municipios = Object(response).data;
        }
      );
  }


  private selectBoxDistritosByMunicipio(id) {
    this.distritos = [];
    this.filter.bairro_id = null
    if (!id || id == 'null') return
    this.http.call_get('distrito/getDistritosByMunicipio/' + id, null).subscribe(
      response => {
        this.distritos = Object(response).data;
      }
    );
  }

  private selectBoxBairrosByDistrito(id) {
    this.bairros = []
    console.log(id)

    if (!id || id == 'null') return
    this.http.call_get('bairro/selectBoxByDistrito/' + id, null).subscribe(
      response => {
        this.bairros = Object(response);
      }
    );
  }

  _getQuarteirosDoBairro(id) {
    this.http.call_get(`quarteirao/getQuarteiraosByBairro/${id}`, null)
      .subscribe(
        response => {
          this.quarteiroes = Object(response).data
        }
      )
  }

  private dashboardFacturacao() {
    this.http.call_get('contrato/dashboardIncumprimentos', null).subscribe(
      response => {
        this.somaTotaisDoDashboard(Object(response).data)
      }
    )
  }

  async retornarFacturaIncumprimetoPorContrato(contrato_id: any, nome: any) {
    this.loadingListarFacturas = true
    this.dadosClienteSelecionado.nome = nome + ' - ' + contrato_id;
    await this.http.call_get('facturasIncumprimento/contratos/' + contrato_id, null).subscribe(
      response => {
        this.facturasIncumprimento = Object(response).data
        this.somaTotais(this.facturasIncumprimento)
        this.loadingListarFacturas = false
      }
    )
  }

  setCheckedAll(checked: boolean) {
    this.checkedAll = checked;
    this.logs.forEach(f => f.checked = checked);
  }

  updateChecked(f: any) {
    this.logs.forEach(element => {
      element.checked = element.contrato_id === f.contrato_id ? !f.checked : element.checked;
      Object.assign(element);
    });
    const lengthFalse = this.logs.filter(val => val.checked === true);
    this.checkedAll = lengthFalse.length === this.logs.length ? true : false;
  }

  private closeModal() {
    this.modalAppendServico.hide();
  }

  private contrato: any = [];

  private setDataContrato(item) {
    this.contrato = item;
  }

  getPageFilterData(page: number) {
    if (this.filters.pagination.perPage == null) {
      return;
    }
    this.filters.pagination.page = Number(page);
    this.listagem();
  }
  public listagem() {
    this.loading = true
    this.http.__call(`api/v1/dividas/listagem?search=${this.filters.search}&page=${this.filters.pagination.page}&perPage=${this.filters.pagination.perPage}`, null).subscribe(
      response => {
        this.filters.pagination.lastPage = Object(response).data.lastPage;
        this.filters.pagination.page = Object(response).data.page;
        this.filters.pagination.total = Object(response).data.total;
        this.filters.pagination.perPage = Object(response).data.perPage;
        this.logs = Object(response).data.data;
        this.loading = false
      }
    );
  }

  private historico_notifications: any[];
  private getHistoricoNotifications(item) {
    this.loading = true
    this.http.call_get("api/v1/notifications/historico/clientes/" + item.id)
      .subscribe(res => {
        this.historico_notifications = Object(res);
        this.loading = false
      })
  }

  private somaTotais(dados) {
    var total_divida: number = 0;
    dados.forEach(element => {
      total_divida += element.valor_aberto;
    });
    this.dadosClienteSelecionado.totaldivida = total_divida;
  }

  private humanize(str) {
    return str.trim().split(/\s+/).map(function (str) {
      return str.replace(/_/g, ' ').replace(/\s+/, ' ').trim();
    }).join(' ').toUpperCase().replace(/^./, function (m) {
      return m.toUpperCase();
    });
  }

  private somaTotaisDoDashboard(dados) {
    var total_contratos: number = 0;
    var total_facturas: number = 0;
    var total_Dividas: number = 0;
    dados.forEach(element => {
      total_facturas += element.numero_facturas;
      total_Dividas += element.total_divida;
    });
    total_contratos = dados.length;
    this.dashboard.totalContratos = total_contratos;
    this.dashboard.totalFacturas = total_facturas;
    this.dashboard.totaldividas = total_Dividas;

  }

  setLog(log) {
    this.currrent_log = log
  }

  gerarOS() {

    const data = {
      id: this.currrent_log.id,
      contrato_id: this.currrent_log.contrato_id,
      factura_id: this.currrent_log.factura_id
    }

    this.loading = true
    this.http.__call('api/v1/dividas/gerarOsCorte/' + this.currrent_log.id, data)
      .subscribe(response => {
        this.listagem()
        this.loading = false
      })
  }

  routerGerarRecibo(cliente_id: number, name: string) {
    this._route.navigate(['/vendas/recibo/gera-recibo'], { queryParams: { c: cliente_id, n: name } });
  }

}
