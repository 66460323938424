import { Injectable } from '@angular/core';
import * as moment from 'moment';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import { ConfigService } from 'src/app/providers/config/config.service';

@Injectable({
  providedIn: 'root'
})
export class RelMovimentoCaixaService {
  constructor(private configService: ConfigService) { }

  public relatoriomovimentocaixa(file,  p = 'print',imgData) {

    var doc = new jsPDF('l', '', 'a4')
        doc.setProperties({
        title: 'Relatorio_Movimentos_Caixa',
        subject: 'Report',
        author: 'Unig',
        keywords: '',
        creator: 'Angola Telecom'
        });

    doc.addImage(imgData, 'JPEG',8, 10, 28, 18)
    const totalPagesExp = "{total_pages_count_string}";

        doc.setFontType('bold')
        doc.setFontSize(15)
       // doc.text(145, 60, 'Relatório', 'center')
        doc.setFontSize(15)
        doc.text(150, 25, 'Relatório Movimentos de Caixa', 'center')

  doc.autoTable({ html: file ,
    didParseCell: function (data) {
    var rows = data.table.body;
    if (data.row.index === 0) {
        data.cell.styles.fontStyle = 'bold';
        data.cell.styles.textColor = "white";
        data.cell.styles.fillColor = [32, 95, 190];
    } if (data.row.index === rows.length - 1) {
      data.cell.styles.fontStyle = 'bold';
  }
},
    didDrawPage : data => {
      let footerStr = "Página " + doc.internal.getNumberOfPages();
      if (typeof doc.putTotalPages === 'function') {
        footerStr = footerStr + " de " + totalPagesExp;
      }
      doc.setFontSize(10);
      doc.text(footerStr,260, 200, 'left');
    },
    styles: { textColor: [0, 0, 0] },margin: {bottom : 20},
    columnStyles: {
    0: {cellWidth: 35,halign: 'center'},
    1: {cellWidth: 60,halign: 'center'},
    2: {cellWidth: 24,halign: 'center'},
    3: {cellWidth: 35,halign: 'center'},
    4: {cellWidth: 34,halign: 'center'},
    5: {cellWidth: 30,halign: 'center'},
    6: {cellWidth: 25,halign: 'center'},
    7: {cellWidth: 25,halign: 'center'}},
    rowsStyles:{},
  startY: 60,
  theme: 'grid',

  })

  if (typeof doc.putTotalPages === 'function') {
    doc.putTotalPages(totalPagesExp);
  }

  if (p === 'save') {
    doc.save('Relatorio_Movimentos_Caixa'+'.pdf');
  } else {
    doc.autoPrint();
    doc.output("dataurlnewwindow");
  }


  }
}
