import { Component, OnInit, Input,SimpleChange,EventEmitter ,OnChanges, Output} from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReciboService } from 'src/app/components/reports/recibo/recibo.service'
import * as moment from 'moment';
// ES6 Modules or TypeScript
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'
@Component({
  selector: 'app-pagamento-plano-prestacao',
  templateUrl: './pagamento-plano-prestacao.component.html',
  styleUrls: ['./pagamento-plano-prestacao.component.css']
})
export class PagamentoPlanoPrestacaoComponent implements OnInit {


  //@Output() private loadList = new EventEmitter<any>();
  @Input() facturas: any = null;
  @Input() loadingDataFactura = false;
  loading = false;
  @Input () planoPrestacao: any = null;
  validateButton:boolean=true;
  submitted = false;
  total_linha = 0
  disable = false;
  lista_dos_adiantamentos: any = []

  private recibo: any;

  adiantamento = []
  listMovimento = []
  private totalAdiantamento =0;

  gerarReciboParcialForm: FormGroup;

  private carregando = {
    serie: 'Seleccione a serie',
    forma_pagamento: 'Seleccione a forma de pagamento',
    banco: 'Seleccione conta da bancaria'
  }

  constructor(private http: HttpService, private configService: ConfigService, private formBuilder: FormBuilder, private PDFrecibo: ReciboService) {

    this.listarFormaPagamentos();
    this.configService.listarBancos();
    this.createForm();

  }


  ngOnInit() {
    this.setDefault()
    this.getSeriesRecibos()

  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {

      this.getSeriesRecibos();
      this.listarFormaPagamentos();
      console.log(this.planoPrestacao)
      this.gerarReciboParcialForm.patchValue({
        linha_prestacao_id: null,
        plano_prestacao_id: this.planoPrestacao.id,
        total_saldado: this.planoPrestacao.valor,
        total_pago:this.planoPrestacao.valor,
        cliente_id : this.planoPrestacao.cliente_id,
        facturas : this.facturas
      });

  }

  createForm() {
    this.gerarReciboParcialForm = this.formBuilder.group({
      nome: [''],
      cliente_id: [null, Validators.required],
      serie_id: [null, Validators.required],
      forma_pagamento_id: [{ value: null}],
      linha_prestacao_id: [{ value: null}],
      plano_prestacao_id: [{ value: null}],
      valor_recebido: [''],
      total_valor_recebido: ['', Validators.required],
      troco: [{ disabled: true }],
      total_pago: [{ disabled: true }],
      total_saldado: [{ disabled: true }],
      facturas: [{ value: null }],
      is_adiantamento: ['0'],
      is_valor_entrada_pago: ['1'],
      adiantamentos: [{ value: null }],
      linha_pagamentos: [{ value: null }],
      banco_id: [{ value: null, disabled: true }],
      referencia: [{ value: null, disabled: true }],
      data_pagamento: [{ value: null, disabled: true }],

    });

    this.adiantamento = []
    this.listMovimento = []


    this.linha_pagamentos = []
    this.view_facturas = false
    this.configService.loaddinStarter('stop');
    this.loading = false;
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.gerarReciboParcialForm.controls;
  }

  onReset() {

    this.submitted = false;
    //this.gerarReciboParcialForm.reset();
    this.gerarReciboParcialForm.get('serie_id').reset();
    this.gerarReciboParcialForm.get('forma_pagamento_id').reset();
    this.gerarReciboParcialForm.get('valor_recebido').reset();
    this.gerarReciboParcialForm.get('data_pagamento').reset();
    this.gerarReciboParcialForm.get('referencia').reset();
    this.gerarReciboParcialForm.get('banco_id').reset();

    this.gerarReciboParcialForm.get('troco').reset();
    this.gerarReciboParcialForm.get('total_valor_recebido').reset();
    this.gerarReciboParcialForm.get('total_saldado').reset();

    this.linha_pagamentos = []
    this.lista_dos_adiantamentos = []
    this.listMovimento=[];
    this.view_facturas = false
    this.configService.loaddinStarter('stop');
    this.loading = false;

  }


  onSubmit() {

    this.submitted = true;
    const _validarAd = this.linha_pagamentos.find(l => l.designacao === 'Adiantamento');
    console.log(_validarAd)
		this.lista_dos_adiantamentos = _validarAd == null ? [] : this.lista_dos_adiantamentos;
    console.log(this.gerarReciboParcialForm.value);
    this.gerarReciboParcialForm.patchValue({
      linha_pagamentos: this.linha_pagamentos,
      adiantamentos: this.lista_dos_adiantamentos,
      /* serie_id: this.default */
    });
    // parar aquei se o gerarReciboParcialFormulário for inválido
    if (this.gerarReciboParcialForm.invalid) {
      this.configService.showAlert('OPSSS!! Algo esta mal Priencha devidamente o Formulario', "alert-danger", true);
      return;
    }

    if (this.gerarReciboParcialForm.value.facturas.length == 0) {
      this.configService.showAlert('Nenhuma factura seleccionada', "alert-danger", true);
      return;
    } else if (this.gerarReciboParcialForm.value.linha_pagamentos.length == 0) {
      this.configService.showAlert('Nenhuma forma de pagamento seleccionada', "alert-danger", true);
      return;
    }

    this.loading = true

    console.log(this.gerarReciboParcialForm.value )
    //this.configService.loaddinStarter('start');
    const uri = 'recibo/gerarRecibo';
    this.createOrEdit(uri, this.gerarReciboParcialForm, true);

  }


  createOrEdit(uri: any, gerarReciboParcialFormulario: FormGroup, isCreate: boolean) {
    this.validateButton=false;
    var nome: string;
    // TODO: usado para fazer a requisição com a api de criação de organismo
    this.http.__call(uri, gerarReciboParcialFormulario.value).subscribe(
      res => {


          this.submitted = false;
            this.printer_recibo = false;
            nome = gerarReciboParcialFormulario.value.nome
            this.recibo = Object(res).data.recibo.id;
            this.gerarReciboParcialForm.patchValue({
              cliente_id: Object(res).data.recibo.cliente_id,
              nome: nome
            });
            this.closeModal();
            this.findAllLoadList();
            this.sucessoRecibo(Object(res).data);
            this.onReset();
        this.validateButton=true;
        this.loading = false
        this.configService.showAlert(Object(res).message, 'alert-success', true);
      },error=>{
        this.validateButton=true;
        //this.errorReferenciasRecibo(Object(res).data)
        this.loading = false
      }
    );
    this.configService.loaddinStarter('stop');
  }


  private activarAdiantamento = false
  /**
   *
   */
  handleChangeFormaPagamento() {
    this.gerarReciboParcialForm.patchValue({
      valor_recebido: '',
      troco: 0,
      is_adiantamento: '0'
    });
    this.adiantamento = []
    this.listMovimento = []
    this.activarAdiantamento = false
    this.gerarReciboParcialForm.get("valor_recebido").enable();
    if (this.gerarReciboParcialForm.getRawValue().forma_pagamento_id != null  && !this.verificaForma(this.gerarReciboParcialForm.getRawValue().forma_pagamento_id)) {

      this.gerarReciboParcialForm.get('data_pagamento').enable();
      this.gerarReciboParcialForm.get('referencia').enable();
      this.gerarReciboParcialForm.get('banco_id').enable();
    } else {

      this.gerarReciboParcialForm.get('data_pagamento').reset();
      this.gerarReciboParcialForm.get('data_pagamento').disable();
      this.gerarReciboParcialForm.get('referencia').reset();
      this.gerarReciboParcialForm.get('referencia').disable();
      this.gerarReciboParcialForm.get('banco_id').reset();
      this.gerarReciboParcialForm.get('banco_id').disable();
    }

    this.formasPagamentos.forEach(element => {
      if (element.designacao === 'Adiantamento' && this.gerarReciboParcialForm.getRawValue().forma_pagamento_id == element.id) {

        this.gerarReciboParcialForm.get("valor_recebido").disable();
        this.activarAdiantamento = true;
        this.getMovimentoAdiantamento();
      }

    });

  }


  printer_recibo: boolean = true;
  private series: any = []
  private default: any = []

  private getSeriesRecibos() {
    this.carregando.serie = 'Carregando...'
    this.http.call_get('serie/loja/selectSerieLojaReciboBox', null).subscribe(
      response => {
        let data = Object(response).data
        this.series = data;
        this.default = this.series[0].id
        this.carregando.serie = 'Seleccione a serie'
      });
  }



   private setDefault() {
    this.gerarReciboParcialForm.patchValue({
      serie_id: this.default
    })
  }

  private formasPagamentos: any = [];

  private listarFormaPagamentos() {
    this.carregando.forma_pagamento = 'Carregando...'
    this.http.call_get('formaPagamento/formas', null).subscribe(
      response => {
        this.formasPagamentos = Object(response).data;
        this.configService.loaddinStarter('stop');
        this.carregando.forma_pagamento = 'Seleccione a forma de pagamento'
      }
    );
  }

  private ValorTotalAdiantamentos(cliente_id:number) {

    this.http.__call("adiantamento/factura/adiantamentoContaCliente", { cliente_id: cliente_id }).subscribe(response => {
      if (Object(response).data.length == 0) {
        this.totalAdiantamento = 0;
      } else {
        this.totalAdiantamento = Object(response).data[0].valor;
      }
    })
  }

  view_client = false;




  view_facturas = false;

  private totalApagar() {
    /*
    var ve = this.gerarReciboParcialForm.getRawValue().total_valor_recebido;
    this.printer_recibo = true;
    var total = 0;
    var total_saldado = 0
    this.gerarReciboParcialForm.patchValue({
      total_pago: null,
      total_saldado: null
    });

    this.gerarReciboParcialForm.value.facturas.forEach(element => {
      total += element.valor_aberto;
      var factura = element;
      factura.valor_aberto = (factura.valor_aberto == null && factura.pago==0 ? factura.total : factura.valor_aberto);// Atribui o valor total caso o valor em aberto for null
      var va = factura.valor_aberto; // valor actual em aberto da factura
      var saldado = 0  // recebe o valor saldado na factura
      if (ve > 0) {

        ve = ve - va;
        saldado = (ve < 0 ? ve - va * -1 : va) // calcula o valor saldado
        total_saldado += saldado
      }
    });
    this.gerarReciboParcialForm.patchValue({
      total_pago: total,
      total_saldado: total_saldado
    });
    */
  }




  private btnImprimirRecibo() {
    this.PDFrecibo.imprimirRecibo(this.recibo, 'Original');
  }



  private SeleccionarFactura() {
    this.ValorTotalAdiantamentos(this.planoPrestacao.cliente_id);
    //Calcula o Total da Factura
    //this.totalApagar();
    //this.calcularTroco()
    /*
    this.gerarReciboParcialForm.patchValue({
      cliente_id: this.linha.cliente_id
    });
    const factura = this.linha.cliente_id;
    if (this.gerarReciboParcialForm.value.facturas.length == 0) {
      factura.valor_aberto = (factura.valor_aberto == null && factura.pago==0? factura.total : factura.valor_aberto);
      this.gerarReciboParcialForm.value.facturas.push(factura);
    } else {
      var validar = 0
      for (let i = 0; i < this.gerarReciboParcialForm.value.facturas.length; ++i) {
        if (this.gerarReciboParcialForm.value.facturas[i].id === factura.id) {
          this.gerarReciboParcialForm.value.facturas.splice(i, 1);
          validar = 1;
        }
      }
      if (validar === 0) {
        factura.valor_aberto = (factura.valor_aberto == null && factura.pago==0? factura.total : factura.valor_aberto);
        this.gerarReciboParcialForm.value.facturas.push(factura);
      }
    }
    this.ValorTotalAdiantamentos(this.linha.cliente_id);
    //Calcula o Total da Factura
    this.totalApagar();
    this.calcularTroco()
    */
  }

  sucessoRecibo(response: any) {
    var facts = ''
    response.facts.forEach(element => {
      facts += '<tr>'
        + '<td>' + element.factura_sigla + '</td>'
        + '<td>' + this.configService.numberFormat(element.saldado) + '</td>'
        + '<td>' + this.configService.numberFormat(element.va_ant) + '</td>'
        + '<td>' + this.configService.numberFormat(element.va_new) + '</td>'
        + '</tr>'
    });
    var html = '<div style="text-align: left;margin: 13px 20px;width: 700px;padding: 9px 23px;background: #f8f9fa;border-radius: 5px;">'
      + '<h4>Estatística</h4><hr/>'
      + '<table class="table table-hover table-bordered table-striped text-center">'
      + '<thead>'
      + '<tr>'
      + '<th> Factura </th>'
      + '<th> V.Saldado </th>'
      + '<th> V.Aberto Anterior </th>'
      + '<th> V.Aberto Novo </th>'
      + '</tr>'
      + '</thead>'
      + '<tbody>'
      + facts +
      + ' </tbody>'
      + '</table>'
      + '<b>Factura Saldada:</b> ' + (response.contTotalFacturaSaldadas) + '<br>'
      + '<b>Factura em aberto: </b>' + (response.contTotalFacturaAbertas) + "<br>"
      + '</div>'

    const swalWithBootstrapButtons = Swal.mixin({

      customClass: {
        container: 'container-class',
        popup: 'popup-class',
        header: 'header-class',
        title: 'title-class',
        closeButton: 'close-button-class',
        icon: 'icon-class',
        content: 'content-class',
        input: 'input-class',
        actions: 'actions-class',
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-default',
        footer: 'footer-class'
      },
      width: '49em',
      buttonsStyling: false,
    })

    swalWithBootstrapButtons.fire({

      html: html,
      title: 'Recibo Gerado',
      text: "Você não poderá reverter isso!",
      type: 'success',
      showCancelButton: true,
      confirmButtonText: 'Sair',
      cancelButtonText: 'Imprimir',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        //window.location.reload()
      } else if (
        // Read more about handling dismissals
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Aguarde',
          'Aguarde, estamos a gerar a impressão do recibo',
          'success'
        )
        this.btnImprimirRecibo();
        //window.location.reload()
      }
    })

  }

  errorReferenciasRecibo(response: any) {
    var facts = ''
    response.facts.forEach(element => {
      facts += '<tr>'
                + '<td>' + element.banco_nome + '</td>'
                + '<td>' + element.referencia_banco + '</td>'
            + '</tr>'
    });
    var html = '<div style="text-align: left;margin: 13px 20px;width: 700px;padding: 9px 23px;background: #f8f9fa;border-radius: 5px;">'
      + '<h4>As Referencias Bancarias listadas abaixo já foram utilizadas</h4><hr/>'
      + '<table class="table table-hover table-bordered table-striped text-center">'
      + '<thead>'
      + '<tr>'
      + '<th> Banco </th>'
      + '<th> Referencia </th>'
      + '</tr>'
      + '</thead>'
      + '<tbody>'
      + facts +
      + ' </tbody>'
      + '</table>'
      + '</div>'

    const swalWithBootstrapButtons = Swal.mixin({

      customClass: {
        container: 'container-class',
        popup: 'popup-class',
        header: 'header-class',
        title: 'title-class',
        closeButton: 'close-button-class',
        icon: 'icon-class',
        content: 'content-class',
        input: 'input-class',
        actions: 'actions-class',
        confirmButton: 'btn btn-success',
        footer: 'footer-class'
      },
      width: '49em',
      buttonsStyling: false,
    })

    swalWithBootstrapButtons.fire({

      html: html,
      title: 'Erro Referencias Bancarias',
      text: "Você não poderá reverter isso!",
      type: 'error',
      showCancelButton: false,
      confirmButtonText: 'Continuar',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        //window.location.reload()
      }
    })

  }


  //--------------------------------------------------------------
  private linha_pagamentos: any = []

  deleteRowFormaPagamento(linha: any) {
    for (let i = 0; i < this.linha_pagamentos.length; ++i) {
      if (this.linha_pagamentos[i].id === linha.id) {
        this.linha_pagamentos.splice(i, 1);
        //this.facturacao.pagamento.total_valor_recebido -= linha.valor_entrada;
        this.calcularValorTotalRecebido();
        this.totalApagar()
        this.calcularTroco()
      }
    }

  }

  private addLinhaPagamentos() {
    console.log(this.gerarReciboParcialForm.getRawValue())
    var validar = 0;
    var forma = null
    var banco_nome = null
    this.total_linha = this.total_linha + this.gerarReciboParcialForm.getRawValue().valor_recebido


    this.configService.bancos.forEach(element => {
      if (this.gerarReciboParcialForm.getRawValue().banco_id == element.id) {
        banco_nome = element.abreviatura + ' - ' + element.numero_conta;
      }
    });

    this.formasPagamentos.forEach(element => {
      if (this.gerarReciboParcialForm.getRawValue().forma_pagamento_id == element.id) {
        forma = element.designacao;
      }
    });

    if (forma == null) {
      this.configService.showAlert('Informa uma forma pagamento valida', "alert-danger", true);
      return;
    } else if (this.gerarReciboParcialForm.getRawValue().valor_recebido == '' || this.gerarReciboParcialForm.getRawValue().valor_recebido < 0) {
      this.configService.showAlert('Informa Valor de Entrada valido', "alert-danger", true);
      return;

    } else {
      if ((this.gerarReciboParcialForm.getRawValue().referencia == null || this.gerarReciboParcialForm.getRawValue().data_pagamento == null || this.gerarReciboParcialForm.getRawValue().banco_id == null) && !this.verificaForma(this.gerarReciboParcialForm.getRawValue().forma_pagamento_id)) {
        if (forma !== "Adiantamento") {
          this.configService.showAlert('Informa Nº conta da bancaria, referencia a bancaria e a data de pagamento', "alert-danger", true);
          return;
        }

      }

      if ((this.gerarReciboParcialForm.getRawValue().referencia != null || this.gerarReciboParcialForm.getRawValue().referencia != "") && !this.verificaForma(this.gerarReciboParcialForm.getRawValue().forma_pagamento_id)) {

        var code = 0

        this.linha_pagamentos.forEach(element => {
          if (this.gerarReciboParcialForm.getRawValue().referencia === element.referencia_banco && this.gerarReciboParcialForm.getRawValue().banco_id === element.banco_id) {
            code++;
          }
        });

        /*
        this.validationReferenciaBancaria(this.gerarReciboParcialForm.getRawValue().referencia, this.gerarReciboParcialForm.getRawValue().banco_id);
        if (this.codeReferencia == 0 || code!=0 && (this.gerarReciboParcialForm.getRawValue().referencia != null || this.gerarReciboParcialForm.getRawValue().banco_id != null)) {
          this.configService.showAlert("A refêrencia bancaria já foi utilizada", "alert-danger", true);
          return;
        }
        */


      }

      var linha = {
        designacao: forma,
        valor_recebido: this.gerarReciboParcialForm.getRawValue().valor_recebido,
        data_pagamento: this.gerarReciboParcialForm.getRawValue().data_pagamento,
        forma_pagamento_id: this.gerarReciboParcialForm.getRawValue().forma_pagamento_id,
        referencia: this.gerarReciboParcialForm.getRawValue().referencia,
        banco_id: this.gerarReciboParcialForm.getRawValue().banco_id,
        banco_nome: banco_nome
      }

      if (this.linha_pagamentos.length >= 1) {
        for (let index = 0; index < this.linha_pagamentos.length; index++) {
          const l = this.linha_pagamentos[index];
          if (l.id == linha.forma_pagamento_id) {
            this.linha_pagamentos.splice(index, 1);
            this.linha_pagamentos.splice(index, 0, linha);
            validar = 1;
          }
        }
        if (validar == 0) {
          this.linha_pagamentos.push(linha);
        }
      } else {
        this.linha_pagamentos.push(linha);
      }

      this.calcularValorTotalRecebido();
      this.calcularTroco();


      this.gerarReciboParcialForm.get('forma_pagamento_id').reset();
      this.gerarReciboParcialForm.get('valor_recebido').reset();
      this.gerarReciboParcialForm.get('data_pagamento').reset();
      this.gerarReciboParcialForm.get('referencia').reset();
      this.gerarReciboParcialForm.get('banco_id').reset();

      this.gerarReciboParcialForm.get('referencia').disable();
      this.gerarReciboParcialForm.get('data_pagamento').disable();
      this.gerarReciboParcialForm.get('banco_id').disable();

    }
  }

  private calcularValorTotalRecebido() {
    console.log( this.linha_pagamentos)
    var total_valor_recebido = 0
    this.linha_pagamentos.forEach(element => {
      total_valor_recebido += ((element.valor_recebido));
    });
    this.gerarReciboParcialForm.patchValue({
      total_valor_recebido: (total_valor_recebido).toFixed(2),
      //valor_recebido : total_valor_recebido
    });
  }



  private calcularTroco() {
		let total_valor_selecionado = Number(this.gerarReciboParcialForm.getRawValue().total_pago)
		let total_valor_recebido = Number(this.gerarReciboParcialForm.getRawValue().total_valor_recebido)
		let total_adiantamentos = this.total_adiantamento()
		let total_troco = ((total_adiantamentos + total_valor_recebido) - total_valor_selecionado)

		this.gerarReciboParcialForm.patchValue({ troco: 0 });

		if (total_troco > 0) {
			this.gerarReciboParcialForm.patchValue({
				troco: total_troco.toFixed(2)
			})
		}
	}


  private codeReferencia: any;
  private validationReferenciaBancaria(ref, bank) {
    this.http.__call('referencia/validation', { referencia: ref, banco_id: bank }).subscribe(
      response => {
        this.codeReferencia = Object(response);
      }
    );
  }

  private verificaForma(item) {
    if(item==""){
      return true
    }
    var chaves = [];
    this.formasPagamentos.forEach(element => {
        if(element.usar_banco === 0){
          chaves.push(""+element.id);
        }
    })
    if(chaves.length > 0){
      if (chaves.indexOf(item) > -1) {
        return true
      } else {
          return false
      }
    }else{
      return false
    }
  }


  closeModal(closeModal="closeModal"){
    var action = document.getElementById(closeModal)
    action.click()
    //this.loadingDataFactura = true;
    this.onReset();
  }

  public findAllLoadList() {
   // this.loadList.emit(null);
  }

  private getMovimentoAdiantamento() {
    this.configService.loaddinStarter('start');
    this.http.__call('adiantamento/factura/adiantamentoFactura', { cliente_id: this.gerarReciboParcialForm.getRawValue().cliente_id }).subscribe(
      response => {
        this.listMovimento = Object(response).data;
        this.configService.loaddinStarter('stop');
      });
  }

  setAdianta(ad: any) {

    if (this.adiantamento.length == 0) {
      this.adiantamento.push(ad);
    } else {
      var validar = 0
      for (let i = 0; i < this.adiantamento.length; ++i) {
        if (this.adiantamento[i].id === ad.id) {
          this.adiantamento.splice(i, 1);
          validar = 1;
        }
      }
      if (validar === 0) {
        this.adiantamento.push(ad);
      }
    }
    var valor = 0
    for (let i = 0; i < this.adiantamento.length; ++i) {
        valor += this.adiantamento[i].valor;
    }
    this.gerarReciboParcialForm.patchValue({valor_recebido: valor});
    this.calcularTroco()
  }

  total_adiantamento() {
		let total_a_pagar = this.linha_pagamentos.filter(item => item.designacao == 'Adiantamento').reduce((accum, item) => accum + item.valor_recebido, 0)
		return Number(total_a_pagar)
	}

}
