import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

import { ExcelService } from 'src/app/services/excel.service';
import * as moment from 'moment';

@Component({
  selector: 'app-lista-depositos',
  templateUrl: './lista-depositos.component.html',
  styleUrls: ['./lista-depositos.component.css']
})
export class ListaDepositosComponent implements OnInit, OnDestroy {
  private loading: boolean = false;

  @ViewChild("search") search;
  @ViewChild("orderBy") orderBy;
  @ViewChild("searchData") searchData;
  @ViewChild("mostrarResultado") mostrarResultado;


  private items: any = [];

  constructor(private http: HttpService, private configService: ConfigService, private excelService: ExcelService) { }

  ngOnInit() {
    this.http.filters.search = null
    this.getPageFilterData(1);
  }
  ngOnDestroy(){
    this.http.filters.orderBy = null
  }

  exportAsXLSX(): void {
    var CurrentDate = new Date();
    var nameFile = "Lista_Depositos-" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    this.excelService.exportAsExcelFile(document.getElementsByClassName("exportAsXLSXCliente")[0], nameFile);
  }
  exportAsPDF(): void {
    //this.reportLoja.relatorioLoja(this.cobrancas, this.simpleForm.value, 'save');
  }

  imprimirPDF(): void {
    //this.reportLoja.relatorioLoja(this.cobrancas, this.simpleForm.value);
  }



  private listarDepositos() {
    this.loading = true
    this.configService.loaddinStarter('start');

    this.http.__call('deposito/listagem', this.http.filters).subscribe(

      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.configService.loaddinStarter('stop');
        this.loading = false;

      }
    );
  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
    this.listarDepositos();
  }





}
