import { Component, OnInit, SimpleChange, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { ExcelService } from 'src/app/services/excel.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-trigger-sequencia',
  templateUrl: './trigger-sequencia.component.html',
  styleUrls: ['./trigger-sequencia.component.css']
})
export class TriggerSequenciaComponent implements OnInit {

  submitted = false;
  simpleFormCreateOrEdit: FormGroup;

  public resultados: any;
  private loading: boolean = false;
  public filters = {
    search: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    pagination: {
      perPage: 5,// Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima,
      lastPage: null,
      total: null // defini o total de registo da BD
    },
    filter: null // subelemente do filter
  }
  title: string;

  constructor(
     private auth: AuthService,
     private http: HttpService,
     private configService: ConfigService,
     private excelService: ExcelService,
     private formBuilder: FormBuilder
    ) {
      this.createForm()
    }

  private items: any = [];

  ngOnInit() {
    this.getPageFilterData(1);
  }
  get f() {
    return this.simpleFormCreateOrEdit.controls;
  }

  onReset() {
    this.submitted = false;
    this.simpleFormCreateOrEdit.reset();
  }

  createForm() {
    this.simpleFormCreateOrEdit = this.formBuilder.group({
      index: [{ value: null, disabled: true }],
      descricao: [null, Validators.required],
      flag: [null, Validators.required]
    })
  }

  private listar() {

    this.loading = true
    this.http.__call('ordemservico/trigger-consequencia/listagem', this.filters).subscribe(
      response => {
        this.filters.pagination.lastPage = Object(response).data.lastPage;
        this.filters.pagination.page = Object(response).data.page;
        this.filters.pagination.total = Object(response).data.total;
        this.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.loading = false
      }
    );
  }



  onSubmit() {

    this.submitted = true;

    // parar aquei se o formulário for inválido
    if (this.simpleFormCreateOrEdit.invalid) {
      return;
    }
    this.loading = true;
    const index = this.simpleFormCreateOrEdit.getRawValue().index;
    // TODO: usado para fazer a requisição com a api de criação de objsct or update
    const uri = (index === null ? 'ordemservico/trigger-consequencia/register' : 'ordemservico/trigger-consequencia/update/' + index);
    this.createOrEdit(uri, this.simpleFormCreateOrEdit, (index === null ? true : false));

  }

  createOrEdit(uri: any, formulario: FormGroup, isCreate: boolean) {

    // TODO: usado para fazer a requisição com a api de criação de object
    this.http.__call(uri, formulario.value).pipe(first()).subscribe(
      response => {
        this.submitted = false;
        this.loading = false;
        if (isCreate && Object(response).code ==200) {
          formulario.reset();
        }

        if (Object(response).code ==200) {
          this.listar()
        }
      },
      error => {
        this.submitted = false;
        this.loading = false;
      });
  }

  initForm(data){
    this.simpleFormCreateOrEdit.patchValue({
      index: data.id,
      descricao: data.descricao,
      flag: data.flag
    });
  }

  getPageFilterData(page: number) {
    if (this.filters.pagination.perPage == null) {
      return;
    }
    this.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
    this.listar();
  }


  private setData(item) {
    this.resultados = item
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {

    if (this.resultados !== undefined ) {

      this.title = "Editar";
      //this.onReset()

      this.simpleFormCreateOrEdit.patchValue({
        index: this.resultados.id,
        descricao: this.resultados.descricao,
        flag: this.resultados.flag
      });

    } else {
      this.onReset()
      this.title = "Registar";

    }
  }

}
