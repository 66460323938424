import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/guards/auth.guard';
import { AgendamentoRoteiroComponent } from './agendamento-roteiro/agendamento-roteiro.component';
import { HistoricoAnomaliasComponent } from './historico-anomalias/historico-anomalias.component';
import { LeituraComponent } from './leituras/leitura.component';
import { RegistarLeituraComponent } from './leituras/registar-leitura/registar-leitura.component';
import { ListarLeiturasValidasComponent } from './listar-leituras-validas/listar-leituras-validas.component';
import { MapaRotaComponent } from './mapa-rota/mapa-rota.component';
import { PeriodicidadeRoteiroComponent } from './periodicidade-roteiro/periodicidade-roteiro.component';
import { CreateRoteiroComponent } from './rota-header/create-roteiro/create-roteiro';

const routes: Routes = [
  { path: 'rotas',
    children: [
      {
        path: 'roteiros',
        canActivate: [AuthGuard],
        component: CreateRoteiroComponent
      },
      {
        path: 'agendamentos',
        canActivate: [AuthGuard],
        component: AgendamentoRoteiroComponent
      },
      {
        path: 'historico-anomalias',
        canActivate: [AuthGuard],
        component: HistoricoAnomaliasComponent
      },
      {
        path: 'periodicidade',
        canActivate: [AuthGuard],
        component: PeriodicidadeRoteiroComponent
      },
      {
        path: 'leituras',
        canActivate: [AuthGuard],
        component: ListarLeiturasValidasComponent
      },
      {
        path: 'validar-leituras',
        canActivate: [AuthGuard],
        component: LeituraComponent
      },

      {
        path: 'registar-leituras',
        canActivate: [AuthGuard],
        component: RegistarLeituraComponent
      },
      {
        path: 'mapa',
        component: MapaRotaComponent,
        canActivate: [AuthGuard],
        data: { expectedPermission: 'listar_rotas' }
      }


    ]
  }

]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class RotasRoutingModule { }
