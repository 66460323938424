import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from '../../providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { copyStyles } from '@angular/animations/browser/src/util';
import * as moment from 'moment';
import { finalize } from "rxjs/operators";


import { AuthService } from 'src/app/providers/auth/auth.service';
@Component({
  selector: 'app-orcamento',
  templateUrl: './orcamento.component.html',
  styleUrls: ['./orcamento.component.css']
})
export class OrcamentoComponent implements OnInit {

  @ViewChild("search") search;
  @ViewChild("qtd") qtd;
  //@ViewChild("desconto") desconto;
  @ViewChild("valor") valor;
  @ViewChild("serie") serie;
  @ViewChild("observacao") observacao;
  //@ViewChild("descricao") descricao;


  private dataActual = new Date();
  private totalorcamento = 0;
  private totalSemImposto = 0;
  private totalComImposto = 0;
  private produts: any = [];
  private clientes: any = [];
  private quantidade = 1;
  private produtoId: number;
  private clienteId: number;
  private serieId: number;
  private formasPagamentos: any = [];
  private validarFacturaRecibo: boolean = false

  private contas_clientes: any = [];
  private contratos_conta_cliente: any = [];


  private numero_telefone: string;
  private searchCliente: string;
  filters = {
    keyFilter: 'nome_cliente'
  }

  private isFinalizar: boolean = true;



  private orcamento = {
    cliente: {
      id: null,
      nome: null,
      contribuinte: null,
      morada: null,
      telefone: null,

      conta_id: null,
      contrato_id: null
    },
    serie: {
      id: null,
      sigla: null,
      nome: null,
      proximo_numero: null
    },
    produto: [],
    pagamento: {
      forma: null,
      valor_recebido: 0.0,
      total_valor_recebido: 0.0,
      troco: 0.0,
      total_pago: null,
      referencia: null,
      data_pagamento: null,
      forma_pagamento_id: null,
      bancos: [],
      adiantamento: [],
      is_adiantamento: 0,
      linha_pagamentos: []
    },
    totalComImposto: 0.0,
    totalSemImposto: 0.0,
    total: 0.0,
    success_orcamento: false,
    facturaGerada: null,
    data_vencimento: null,
    moeda: null
  }

  private disabledPagamento = true;
  private disabledPagamentoAdiantamento = true




  private series: any = [];
  private activeButtom = {
    cliente: false,
    produto: false
  }

  private items_produtos: any = [];
  private pagination = {
    start: 1,
    end: 10,
    search: null,
    contrato: null,
    keyFilter: null
  };

  private produto_selecionado = null;
  private preco: number;

  public currentUser: any;


  constructor(private auth: AuthService, private http: HttpService, private configService: ConfigService) {
    this.currentUser = this.auth.currentUserValue;
  }
  ngOnInit() {
    this.ListarClientes(1, 10, null, null);
    this.listarseries();
    this.configService.listarBancos();
    this.configService.listarMoedas();
    this.cleanQTD();
  }

  private cleanQTD() {
    this.quantidade = 1;
  }

  private ListarClientes(start, end, searchCliente, keyFilter) {
    this.configService.loaddinStarter('start');
    this.pagination.start = start;
    this.pagination.end = end;
    this.pagination.search = searchCliente
    this.pagination.keyFilter = keyFilter

    this.http.__call('cliente/search-cliente', this.pagination).subscribe(
      res => {
        this.clientes = Object(res).data.data;
        this.configService.loaddinStarter('stop');

        // console.log(this.clientes)
      }
    );
  }
  private getCliente(e) {
    this.searchCliente
    this.ListarClientes(1, 10, this.searchCliente, this.filters.keyFilter);
  }



  private getProduto() {
    this.produtos(this.search.nativeElement.value);
  }



  private listarProdutos(response: any) {
    this.orcamento.total = 0;
    let validar = 0;
    if (response.code == 400) {
      this.configService.showAlert(response.message, "alert-danger", true);
    } else {
      if (this.orcamento.produto.length >= 1) {
        for (let index = 0; index < this.orcamento.produto.length; index++) {
          const produt = this.orcamento.produto[index];
          if (produt.produto_id == response.produto_id) {

            this.orcamento.produto.splice(index, 1);
            this.orcamento.produto.splice(index, 0, response);
            validar = 1;
          }

        }
        if (validar == 0) {
          this.orcamento.produto.push(response);
        }
      } else {
        this.orcamento.produto.push(response);
      }
      //Calcula o Total da Factura
      this.calcularTotal();
    }

  }

  private calcularTotal() {
    this.orcamento.total = 0;
    this.orcamento.totalSemImposto = 0;
    this.orcamento.totalComImposto = 0;
    //Calcula o Total da Factura
    for (let index = 0; index < this.orcamento.produto.length; index++) {
      this.orcamento.totalSemImposto += this.orcamento.produto[index].linhaTotalSemImposto;
      this.orcamento.totalComImposto += this.orcamento.produto[index].valorImposto;

      this.orcamento.total += this.orcamento.produto[index].total;
    }
    this.orcamento.pagamento.total_pago = this.orcamento.total;


    if (this.orcamento.total >= 0) {
      this.activeButtom.produto = true;
    } else {
      this.activeButtom.produto = false;
    }
  }

  deleteRow(produto: any) {
    for (let i = 0; i < this.orcamento.produto.length; ++i) {
      if (this.orcamento.produto[i].produto_id === produto.produto_id) {
        this.orcamento.produto.splice(i, 1);
        this.calcularTotal();
      }
    }
  }

  private refreshProdutoId(produto: any) {
    this.produtoId = produto.servico_id;
    this.produto_selecionado = produto.servico_valor
    this.cleanQTD();
  }
  /*
    private refreshProdutoId(produto: any) {
      this.produtoId = produto.tipo_produto_id;
      this.produto_selecionado = produto.valor;
    } */

  private refreshSerieId() {
    this.serieId = this.serie.nativeElement.value;
    if (Object(this.serieId) == "") {
      this.serieId = 0;
    }

  }


  private selectedSerie() {
    this.series.forEach(element => {
      if (this.orcamento.serie.id == element.id) {
        if (element.sigla == 'FT') {
          this.configService.listarBancos();
          this.configService.listarMoedas();
        }
        this.orcamento.serie.id = element.id
        this.orcamento.serie.nome = element.nome
        this.orcamento.serie.sigla = element.sigla
        this.orcamento.serie.proximo_numero = element.proximo_numero
      }
    });
  }

  private selectedCliente(id) {

    this.orcamento.cliente.id = id;

    this.clientes.forEach(element => {
      if (this.orcamento.cliente.id == element.id) {

        this.orcamento.cliente.id = element.id;
        this.orcamento.cliente.nome = element.nome;
        this.orcamento.cliente.contribuinte = element.contribuente;
        this.orcamento.cliente.telefone = element.telefone;
        this.orcamento.cliente.morada = element.morada;

        this.activeButtom.cliente = true;
      }
    });
    this.getContas();
  }


  private produtos(search) {
    this.items_produtos = [];
    this.configService.loaddinStarter('start');
    this.pagination.search = (search == "" || search == null ? 'a' : search);

    /*     this.pagination.contrato = (this.orcamento.cliente.contrato_id == "" || this.orcamento.cliente.contrato_id == null ? null : this.orcamento.cliente.contrato_id); */


    this.http.__call('artigo/search', this.pagination).subscribe(
      res => {

        this.items_produtos = Object(res)
        this.configService.loaddinStarter('stop');
      }
    );
  }

  // verificar se o produto tem preço.
  // se não, permitir adicionar o preço
  private updateBancList(banco: any) {

    this.orcamento.pagamento.bancos.push(banco);
  }

  getAddProduto() {

    this.configService.loaddinStarter('start');
    this.http.__call('artigo/pesquisar',
      {
        produto_id: this.produtoId,
        quantidade: (this.qtd.nativeElement.value == "" || isNaN(this.qtd.nativeElement.value) ? 1 : this.qtd.nativeElement.value),
        desconto: 0,
        cliente_id: this.clienteId,
        observacao: null,
        preco: this.preco
      }
    ).subscribe(
      res => {
        this.listarProdutos(Object(res).data);
        if (this.produto_selecionado == 0) {
          this.produtos(this.search.nativeElement.value);
        }
        this.qtd.nativeElement.value = 1;
        this.configService.loaddinStarter('stop');
      }
    );

  }

  private facturar() {

    if (this.orcamento.cliente.id == null) {
      this.configService.showAlert('É obrigatório fornecer um cliente', "alert-danger", true);
    } else if (this.orcamento.serie.id == null) {
      this.configService.showAlert('É obrigatório fornecer uma serie', "alert-danger", true);
    } else if (this.orcamento.produto.length == 0) {
      this.configService.showAlert('É obrigatório fornecer produtos para a factura', "alert-danger", true);
    } else {
      if (this.orcamento.serie.sigla == 'FR') {
        if (this.orcamento.pagamento.linha_pagamentos.length == 0) {
          this.configService.showAlert('É obrigatório fornecer uma forma de pagamento', "alert-danger", true);
        } else if (this.orcamento.pagamento.total_valor_recebido < this.orcamento.total || this.orcamento.pagamento.total_valor_recebido == 0) {
          this.configService.showAlert('Informa um valor de entrada superir ou igual ao valor total a pagar', "alert-danger", true);
        } else {
          this.guardarFactura();
        }
      } else {
        this.guardarFactura();
      }
    }
  }

  isPagamento() {
    this.disabledPagamentoAdiantamento = false
    this.orcamento.pagamento.valor_recebido = 0.0
    this.orcamento.pagamento.referencia = null
    this.orcamento.pagamento.data_pagamento = null

    if (this.orcamento.serie.sigla == 'FR') {
      var a: string = null
      this.formasPagamentos.forEach(element => {
        if (this.orcamento.pagamento.forma_pagamento_id == element.id) {
          if (element.designacao === 'Adiantamento' || element.designacao === 'adiantamento') {
            a = element.designacao
          }
        }
      });

      if (this.orcamento.pagamento.forma_pagamento_id != 1 && this.orcamento.pagamento.referencia == null) {
        if (a !== 'Adiantamento') {
          this.disabledPagamento = false;

        } else {
          this.disabledPagamento = true;
          this.disabledPagamentoAdiantamento = true;
        }
      } else {
        this.disabledPagamento = true;
      }
    }
  }


  private guardarFactura() {
    this.isFinalizar = false;
    this.configService.loaddinStarter('start');

    this.http.__call('factura/create', {
      produtos: this.orcamento.produto, documento: 'Factura',
      cliente_id: this.orcamento.cliente.id,
      total: this.orcamento.total,
      serie_id: this.orcamento.serie.id,
      totalComImposto: this.orcamento.totalComImposto,
      totalSemImposto: this.orcamento.totalSemImposto,
      observacao: this.observacao.nativeElement.value,
      numero_origem_factura: null,
      data_origem_factura: null,
      data_vencimento: this.orcamento.data_vencimento,
      pagamento: this.orcamento.pagamento,
      contas_cliente: this.orcamento.cliente,
      moeda: this.orcamento.moeda

    }).pipe(
      finalize(() => {
        this.configService.loaddinStarter("stop");
        //this.validateButton = 200;
      })
    ).subscribe(
      (res) => {
        //this.configService.showAlert(Object(res).message, "alert-success", true);
        //this.configService.imprimirFactura(Object(res).data.id, "Original");
        this.iniorcamento();
        this.orcamento.success_orcamento = true;
        this.orcamento.facturaGerada = Object(res).data.id
      }, (error) => {
        this.configService.loaddinStarter('stop');
        // this.configService.showAlert(Object(res).message, "alert-danger", true);
        this.isFinalizar = true;
      }
    );
  }



  private listarseries() {
    this.http.call_get('serie/selectBoxSerieOrcamento', null).subscribe(
      data => {

        console.log(Object(data).data)

        this.series = Object(data).data
        if (this.series.length == 1) {
          this.series.forEach(element => {

            if (element.sigla == 'FT') {
              this.configService.listarBancos();
              this.configService.listarMoedas();
            }

            this.orcamento.serie.id = element.id
            this.orcamento.serie.nome = element.nome
            this.orcamento.serie.sigla = element.sigla
            this.orcamento.serie.proximo_numero = element.proximo_numero
          });
        }
      }
    );
  }



  private listarFormaPagamentos() {

    this.configService.loaddinStarter('start');
    this.http.call_get('formaPagamento/formas', null).subscribe(
      response => {
        this.formasPagamentos = Object(response).data;
        this.configService.loaddinStarter('stop');

      }
    );
  }


  private iniorcamento() {


    this.orcamento = {
      cliente: {
        id: null,
        nome: null,
        contribuinte: null,
        morada: null,
        telefone: null,

        conta_id: null,
        contrato_id: null
      },
      serie: {
        id: null,
        sigla: null,
        nome: null,
        proximo_numero: null
      },
      produto: [],
      pagamento: {
        forma: null,
        valor_recebido: 0.0,
        total_valor_recebido: 0.0,
        troco: 0.0,
        total_pago: null,
        referencia: null,
        data_pagamento: null,
        forma_pagamento_id: null,
        bancos: null,
        adiantamento: null,
        is_adiantamento: 0,
        linha_pagamentos: []

      },
      totalComImposto: 0.0,
      totalSemImposto: 0.0,
      total: 0.0,
      success_orcamento: false,
      facturaGerada: null,
      data_vencimento: null,
      moeda: null
    }
  }

  private reloadOrcamento() {
    location.reload();
  }

  private getContas() {
    this.activeButtom.cliente = false
    this.http.call_get('cliente/conta/selectBox/' + this.orcamento.cliente.id, null).subscribe(
      response => {
        this.contas_clientes = Object(response).data
      })
  }

  private getContratosConta() {
    this.activeButtom.cliente = false;
    this.configService.loaddinStarter('start');
    this.http.call_get('conta/contratos/' + this.orcamento.cliente.conta_id, null).subscribe(
      response => {
        this.contratos_conta_cliente = Object(response).data
        this.configService.loaddinStarter('stop');
      })

  }

  confirmarConta() {
    this.activeButtom.cliente = false
    if (this.orcamento.cliente.conta_id == null || this.orcamento.cliente.contrato_id == null) {
      this.configService.showAlert('A conta e serviço são obrigatorio', "alert-danger", true);
    } else {
      this.activeButtom.cliente = true
    }
  }

  private contrato = null; // conta a ser visualizada no ecra das operaçoes

  private contaServicoSeleciona() {
    this.contratos_conta_cliente.forEach(element => {
      if (element.id == this.orcamento.cliente.contrato_id) {
        this.contrato = element;
      }
    });
  }

  private clienteSemOrComConta = 0

  selectedClienteComConta(n: number) {

    this.clienteSemOrComConta = n
    this.listarseries();
    this.listarFormaPagamentos();
    this.produtos(null);
    this.getMovimentoAdiantamento();
    this.orcamento.produto = [];

  }

  selectedClienteSemConta(n: number) {
    this.clienteSemOrComConta = n
    this.listarseries();
    this.listarFormaPagamentos();
    this.orcamento.produto = []
    this.contrato = null
    this.orcamento.cliente.conta_id = null
    this.orcamento.cliente.contrato_id = null
    this.produtos(null);
    this.getMovimentoAdiantamento();

  }




  cancelarConta() {
    this.activeButtom.cliente = false;
    this.orcamento.cliente.conta_id = null
    this.orcamento.cliente.contrato_id = null
    this.orcamento.cliente.id = null;
    this.contrato = null
  }


  private numeroTelefone() {
    this.configService.loaddinStarter('start');
    this.http.__call('cliente/searchClienteTelefone', { chaveServico: this.numero_telefone }).subscribe(
      res => {
        if (Object(res).code == 500) {
          this.configService.showAlert(Object(res).message, "alert-danger", true);
          this.searchCliente = ''
          this.clientes = []
        } else {
          Object(res).data;
          this.searchCliente = Object(res).data.nome
          this.ListarClientes(1, 10, Object(res).data.id, null);
        }
        this.numero_telefone = '';
        this.configService.loaddinStarter('stop');
      }
    );
  }




  private addLinhaPagamentos() {
    var validar = 0;

    if (this.orcamento.serie.sigla == 'FR') {

      var a: string = null
      this.formasPagamentos.forEach(element => {
        if (this.orcamento.pagamento.forma_pagamento_id == element.id) {
          if (element.designacao === 'Adiantamento' || element.designacao === 'adiantamento') {
            a = element.designacao
            this.orcamento.pagamento.referencia = '000000'
            this.orcamento.pagamento.data_pagamento = moment(new Date()).format("YYYY-MM-DD");
          }
        }
      });

      if (this.orcamento.pagamento.valor_recebido <= 0) {
        this.configService.showAlert('Informa um valor de superior a zero', "alert-danger", true);
      } else if (this.orcamento.pagamento.forma_pagamento_id == null || this.orcamento.pagamento.forma_pagamento_id == "null") {
        this.configService.showAlert('Informa uma forma pagamento valida', "alert-danger", true);
      } else if (this.orcamento.pagamento.forma_pagamento_id != 1 && this.orcamento.pagamento.referencia == null) {
        if (a !== 'Adiantamento') {
          this.configService.showAlert('Referencia a bancaria', "alert-danger", true);
        }
      } else if (this.orcamento.pagamento.forma_pagamento_id != 1 && this.orcamento.pagamento.data_pagamento == null) {
        if (a !== 'Adiantamento') {
          this.configService.showAlert('Informa a data de pagamento valida!', "alert-danger", true);
        }
      } else {

        this.formasPagamentos.forEach(element => {
          if (this.orcamento.pagamento.forma_pagamento_id == element.id) {
            this.orcamento.pagamento.forma = element.designacao;
          }
        });

        var linha = {
          designacao: this.orcamento.pagamento.forma,
          valor_entrada: this.orcamento.pagamento.valor_recebido,
          data_pagament: this.orcamento.pagamento.data_pagamento,
          id: this.orcamento.pagamento.forma_pagamento_id,
          referencia_banco: this.orcamento.pagamento.referencia
        }

        if (this.orcamento.pagamento.linha_pagamentos.length >= 1) {
          for (let index = 0; index < this.orcamento.pagamento.linha_pagamentos.length; index++) {
            const l = this.orcamento.pagamento.linha_pagamentos[index];
            if (l.id == linha.id) {
              this.orcamento.pagamento.linha_pagamentos.splice(index, 1);
              this.orcamento.pagamento.linha_pagamentos.splice(index, 0, linha);
              validar = 1;
            }
          }
          if (validar == 0) {
            this.orcamento.pagamento.linha_pagamentos.push(linha);
          }
        } else {
          this.orcamento.pagamento.linha_pagamentos.push(linha);
        }

        this.calcularTroco();
        this.orcamento.pagamento.forma = null
        this.orcamento.pagamento.valor_recebido = 0.0
        this.orcamento.pagamento.referencia = null
        this.orcamento.pagamento.data_pagamento = null
        this.orcamento.pagamento.forma_pagamento_id = null
        this.adiantamento == null;
      }
    }
  }


  private calcularTroco() {
    this.orcamento.pagamento.troco = 0;
    var valor = 0;
    this.orcamento.pagamento.linha_pagamentos.forEach(element => {
      valor += element.valor_entrada;

    });
    if (valor > 0) {
      this.orcamento.pagamento.troco = valor - this.orcamento.total;
      if (valor <= this.orcamento.total) {
        this.orcamento.pagamento.troco = 0;
      }
    } else {
      this.orcamento.pagamento.troco = 0;
    }
    this.orcamento.pagamento.total_valor_recebido = valor;
  }

  deleteRowFormaPagamento(linha: any) {
    for (let i = 0; i < this.orcamento.pagamento.linha_pagamentos.length; ++i) {
      if (this.orcamento.pagamento.linha_pagamentos[i].id === linha.id) {
        if (this.orcamento.pagamento.linha_pagamentos[i].designacao !== 'Adiantamento') {
          this.orcamento.pagamento.adiantamento = []
        }
        this.orcamento.pagamento.linha_pagamentos.splice(i, 1);
        this.orcamento.pagamento.total_valor_recebido -= linha.valor_entrada;
        this.orcamento.pagamento.troco = (this.orcamento.pagamento.total_valor_recebido < this.orcamento.total || this.orcamento.pagamento.total_valor_recebido == 0 ? 0 : this.orcamento.pagamento.total_valor_recebido - this.orcamento.total);



        this.calcularTotal();
      }
    }

  }


  private listMovimento: any = [];
  private adiantamento: string = null;

  private listMovimentoAdiantamentoCliente() {
    this.adiantamento = null;
    this.formasPagamentos.forEach(element => {
      if (this.orcamento.pagamento.forma_pagamento_id == element.id) {
        if (element.designacao === 'Adiantamento' || element.designacao === 'adiantamento') {
          this.adiantamento = element.designacao
        }
      }
    });
  }

  private getMovimentoAdiantamento() {
    this.orcamento.pagamento.adiantamento = [];
    this.configService.loaddinStarter('start');
    this.http.__call('adiantamento/factura/adiantamentoFactura', { cliente_id: this.orcamento.cliente.id }).subscribe(
      response => {
        this.listMovimento = Object(response).data;
        this.configService.loaddinStarter('stop');
      });
  }


  setAdianta(ad: any) {

    if (this.orcamento.pagamento.adiantamento.length == 0) {
      this.orcamento.pagamento.valor_recebido = ad.valor;
      this.orcamento.pagamento.adiantamento.push(ad);
    } else {
      var validar = 0
      for (let i = 0; i < this.orcamento.pagamento.adiantamento.length; ++i) {
        if (this.orcamento.pagamento.adiantamento[i].id === ad.id) {
          this.orcamento.pagamento.adiantamento.splice(i, 1);
          this.orcamento.pagamento.valor_recebido -= ad.valor;
          validar = 1;
        }
      }

      if (validar === 0) {
        this.orcamento.pagamento.valor_recebido += ad.valor;
        this.orcamento.pagamento.adiantamento.push(ad);
      }

    }


  }

  clearSearch() {
    this.searchCliente = null
  }

}
