import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';

import * as moment from 'moment';
import { ExcelAutoService } from 'src/app/services/excel/excel-auto.service';
import { PdfAutoService } from 'src/app/services/pdf/pdf-auto.service';
import { ResumoFaturacaoService } from '../../resumo-faturacao.service';

@Component({
  selector: 'app-facturacao-agua-medida',
  templateUrl: './facturacao-agua-medida.component.html',
  styleUrls: ['./facturacao-agua-medida.component.css']
})
export class FacturacaoAguaMedidaComponent implements OnInit {

  loading: boolean = false;
  items: any = [];
  distritos: any = [];
  private disabledButton = true;

  submitted = false;
  public filters = {
    search: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    pagination: {
      perPage: 5,// Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima,
      lastPage: null,
      total: null // defini o total de registo da BD
    },
    filter: null // subelemente do filter
    , is_allexel: false
  }

  private localUrl: any;
  private largura: any;
  private altura: any;
  simpleForm: FormGroup;

  constructor(private formBuilder: FormBuilder, private http: HttpService,
    private excelService: ExcelAutoService, private configService: ConfigService,
    private pdfService: PdfAutoService,
    private filterSearchBar: ResumoFaturacaoService
    ) {
    this.createForm();
  }
  createForm() {
    this.simpleForm = this.formBuilder.group({
      data1: [null, Validators.required],
      data2: [null, [this.matchValidator.bind(this)]],
      distrito_id: null
    });
  }

  ngOnInit() {
    this.empresaUser()
    this.getDistritos()
  }


  get f() {
    return this.simpleForm.controls;
  }
  onSubmit() {

    this.submitted = true;
    if (this.simpleForm.invalid) {
      return;
    }
    const uri = 'relatorio/financeira/facturacao-agua-medida';
    this.createOrEdit(uri, this.simpleForm);
  }

  private empresaUser() {
    this.configService.loaddinStarter('start');
    this.http.call_get('empresa/empresa-user', null).subscribe(
      response => {
        this.localUrl = Object(response).data.logotipo
        this.altura = Object(response).data.width
        this.largura = Object(response).data.height
        this.configService.loaddinStarter('stop');
      }
    );
  }

  setTrue() {
    this.filters.pagination.page = Number.isInteger(this.filters.pagination.page) ? this.filters.pagination.page : 1 ;
    this.filters.is_allexel = true
    this.onSubmit()
  }

  createOrEdit(uri: any, simpleFormulario: FormGroup) {
    this.items = []
    this.loading = true;
    this.disabledButton = true;
    this.totais.semImposto = 0;
    this.totais.comImposto = 0;
    this.totais.contratos = 0;
    this.totais.quantidade = 0;

    this.http.__call(uri, simpleFormulario.value).subscribe(
      response => {
        this.items = Object(response).data;
        this.loading = false;
        if (this.items.length != 0) {
          this.disabledButton = false;
          this.somaTotais();
        }
        this.loading = false;
      }
    );
  }

  exportAsXLSX(): void {
    var CurrentDate = new Date();
    
    var keys = [
      { key: 'distrito', width: 40, style: { font: { name: 'Calibri' } } },
      { key: 'artigo', width: 25 },
      { key: 'contratos', width: 25 },
      { key: 'quantidade', width: 25 },
      { key: 'total_sem_imposto', width: 40, style: { numFmt: '#,##0.00', } },
      { key: 'total_com_imposto', width: 40, style: { numFmt: '#,##0.00', } },
    ];
    var Cols = ["Distrito", "Nº de Cliente", "Nº de Contratos","Quantidade", "Total Sem IVA", "Total Com IVA"]
    var title = 'Resumo de Facturação por Água Medida'
    var nameFile = "Resumo_Facturacao_Distrito_agua_medida -" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
      this.excelService.excels(this.items, nameFile, this.localUrl, keys, Cols, title, 5, 6, 40, 3, [5, 6])
  }

  exportAsPDF(): void {
    var collumns = {
      0: { cellWidth: 50, halign: 'center' },
      1: { cellWidth: 70, halign: 'center' },
      2: { cellWidth: 40, halign: 'center' },
      3: { cellWidth: 35, halign: 'center' },
      4: { cellWidth: 35, halign: 'right' },
      5: { cellWidth: 35, halign: 'right' }
    };

    var file = document.getElementsByClassName("table_agua_medidada")[0]
    this.pdfService.auto_pdf(file, 'save', 'Resumo de Facturação por Água Medida', this.localUrl,collumns);
  }
 
  imprimirPDF(): void {
    var collumns = {
      0: { cellWidth: 50, halign: 'center' },
      1: { cellWidth: 70, halign: 'center' },
      2: { cellWidth: 40, halign: 'center' },
      3: { cellWidth: 35, halign: 'center' },
      4: { cellWidth: 35, halign: 'right' },
      5: { cellWidth: 35, halign: 'right' }
    };

    var file = document.getElementsByClassName("table_agua_medidada")[0]
    this.pdfService.auto_pdf(file, 'print', 'Resumo de Facturação por Água Medida', this.localUrl,collumns);
  }

  matchValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const fromValue = control.value;
    if (fromValue) {
      const startDate = (<FormGroup>this.simpleForm.get('data1')).value;
      const endDate = (<FormGroup>this.simpleForm.get('data2')).value;
      if (startDate <= endDate) {
        return null;
      }
      return { 'invalidDate': true };
    }
  }

  private totais = {
    comImposto: 0,
    semImposto: 0,
    contratos: 0,
    quantidade : 0
  }

  private somaTotais() {
    var total_iva: number = 0;
    var total_sem_iva: number = 0;
    var contratos: number = 0;
    var quantidade: number = 0;
    this.items.forEach(element => {
      total_iva += element.total_com_imposto;
      total_sem_iva += element.total_sem_imposto;
      quantidade += element.quantidade;
      contratos += element.contratos;
    });
    this.totais.comImposto = total_iva;
    this.totais.semImposto = total_sem_iva;
    this.totais.quantidade = quantidade;
    this.totais.contratos = contratos;
  }

  private getDistritos() {
    this.http.call_get('distrito/selectBox', null).subscribe(
      response => {
        this.distritos = Object(response).data;
      }
    );
  }
}

