import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as excelprop from "exceljs";
import * as Excel from "exceljs/dist/exceljs.min.js";

@Injectable({
  providedIn: 'root'
})
export class MovimentoCaixasService {

  blobType: string = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  constructor() { }

  public ExcelExportmovimentosCaixa(Data: any, empresa, excelFileName) {

    //var footer = ["UNIG -[ BWATER]"];
    var header = 'Lista de Movimentos de Caixa ';
    var keys = [
      { key: 'operador', width: 30 },
      { key: 'loja', width: 30, style: { font: { name: 'Calibri' } } },
      { key: 'valor_abertura', width: 20,style: { numFmt: '#,##0.00', }  },
      { key: 'data_abertura_format_exc', width: 20 },
      { key: 'valor_fecho', width: 20,style: { numFmt: '#,##0.00', }  },
      { key: 'data_fecho_format_exc', width: 20 },
      { key: 'valor_vendas', width: 20, style: { numFmt: '#,##0.00', } },
      { key: 'valor_deposito', width: 20, style: { numFmt: '#,##0.00', } },
      { key: 'data_deposito', width: 20, style: { numFmt: 'dd/mm/yyyy', } },
      { key: 'banco', width: 20, style: { numFmt: 'dd/mm/yyyy', } },
      { key: 'referencia_banco ', width: 20,style: { numFmt: '#,##0.00', }  },
      { key: 'estado_format_exc', width: 20 },
    ];

    var Cols_FT = ['Operador', 'Loja', 'Valor Abertura', 'Data/hora Abertura', 'Valor Fecho', 'Data/hora Fecho', 'Total de Cobranças', 'Valor Depositado','Data Deposito ','Conta Bancaria','Referencia Bancaria','Estado']

    var workbook = new Excel.Workbook();
    workbook.creator = 'Web';
    workbook.lastModifiedBy = 'Web';
    workbook.created = new Date();
    workbook.modified = new Date();
    var imageId1 = workbook.addImage({
      base64: empresa.logotipo,
      extension: 'png',
    });

    workbook.addWorksheet('Lista de Movimentos de Caixa', { views: [{ state: 'frozen', ySplit: 6, xSplit: 1, activeCell: 'A6', showGridLines: true }] })


    var sheet_FT = workbook.getWorksheet(1);

    sheet_FT.addImage(imageId1, 'A1:A5');
    //sheet_FT.addRow(header);
    sheet_FT.mergeCells('A1', 'L5')
    sheet_FT.getCell('B3').value = header;
    sheet_FT.getCell('B3').font = { family: 4, name: 'Calibri', size: 25, bold: true, underline: true };
    sheet_FT.addRow("");
    sheet_FT.getRow(6).values = Cols_FT;
    sheet_FT.columns = keys;
    sheet_FT.addRows(Data);


    sheet_FT.eachRow({ includeEmpty: true }, function (row, rowNumber) {
      row.eachCell(function (cell, colNumber) {
        cell.font = {
          name: 'Arial',
          family: 2,
          bold: false,
          size: 10,
        };
        cell.alignment = {
          vertical: 'middle', horizontal: 'left'
        };
        if (rowNumber <= 6) {
          row.height = 20;
          cell.font = {
            bold: true,
            size: 15,
          };
          cell.alignment = {
            vertical: 'middle', horizontal: 'center'
          };
        }
        if (rowNumber >= 6) {


          cell.alignment = {
            vertical: 'middle', horizontal: 'center'
          };
          if (rowNumber >= 7) {

            row.getCell(3).alignment = {
              vertical: 'middle', horizontal: 'right', 'color': { 'argb': 'FFFF6600' }
            };

            row.getCell(5).alignment = {
              vertical: 'middle', horizontal: 'right', 'color': { 'argb': 'FFFF6600' }
            };

            row.getCell(7).alignment = {
              vertical: 'middle', horizontal: 'right', 'color': { 'argb': 'FFFF6600' }
            };

            row.getCell(8).alignment = {
              vertical: 'middle', horizontal: 'right', 'color': { 'argb': 'FFFF6600' }
            };
          }
          for (var i = 1; i < 13; i++) {
            if (rowNumber == 6) {
              cell.font = {
                color: { argb: 'FFFFFF' },
                bold: true
              };
              row.height = 25;
              row.getCell(i).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '0099FF' }
              };
            }
            row.getCell(i).border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' }
            };
          }
        }
      });
    });

    workbook.xlsx.writeBuffer().then(Data => {
      var blob = new Blob([Data], { type: this.blobType });
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.href = url;
      a.download = excelFileName;
      a.click();
    });


  }
}
