import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MouseEvent } from '@agm/core';

@Component({
  selector: 'app-view-equipamento-location-mapa',
  templateUrl: './view-equipamento-location-mapa.component.html',
  styleUrls: ['./view-equipamento-location-mapa.component.css']
})
export class ViewEquipamentoLocationMapaComponent implements OnInit {


  @Input() modal: string = "modalMapaEquipamentoLocation";
  @Input() coordenada: any;

  location = {
    latitude: -8.996737,
    longitude: 13.268137,
    zoom: 10,
    isFullScreen: true,
    markers: [
      {
        lat: 19.0760,
        lng: 72.8777,
      }
    ]
  };


  constructor(private http: HttpService, private configService: ConfigService, private formBuilder: FormBuilder) {

  }

  ngOnInit() {

  }

  lat = -8.996737;
  lng = 13.268137;
  zoom = 10;

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {

    var longitude: number = +this.coordenada.longitude;
    var latitude: number = +this.coordenada.latitude;

    this.lat = latitude;
    this.lng = longitude;

 }

  markerDragEnd(m: marker, $event: MouseEvent) {
  }

  markers: marker[] = [
    {
      lat: -8.996737,
      lng: 13.268137,
      draggable: false
    }
  ]

}

interface marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}


