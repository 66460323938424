import { Component, OnInit, Input, Output, SimpleChange } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/providers/config/config.service';
import { HttpService } from 'src/app/providers/http/http.service';

@Component({
  selector: 'app-alterar-titularidade',
  templateUrl: './alterar-titularidade.component.html',
  styleUrls: ['./alterar-titularidade.component.css']
})
export class AlterarTitularidadeComponent implements OnInit {

  @Input() modalTitularidade: string = "modalTitularidade";
  @Input() title: string = "Alterar Titularidade";
  @Input() titularidade: any;

  private cliente_nome = ""
  private motivo_rescisaos: any = [];
  private local_consumo: any = [];
  private contas: any = [];
  private view_client = true;
  private clientes: any = [];
  private contrato: any = [];

  private data_contrato = {
    contratoId: null,
    clienteId: null,
    Cil: null,
    contaId: null
  };

  private currente_cliente: any;
  constructor(private http: HttpService, private configService: ConfigService, private router: Router) { }

  ngOnInit() {
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {

    if (this.titularidade !== undefined) {
    } else {
      this.title = "Alterar Titularidade"
    }
  }

}
