import { from } from 'rxjs';
import { Component, ViewChild, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { NgxImageCompressService } from 'ngx-image-compress';
import * as jsPDF from 'jspdf';

@Component({
  selector: 'app-empresa',
  templateUrl: './empresa.component.html',
  styleUrls: ['./empresa.component.css']
})
export class EmpresaComponent implements OnInit {



  @ViewChild("search") search;
  @ViewChild("orderBy") orderBy;
  @ViewChild("searchData") searchData;
  @ViewChild("mostrarResultado") mostrarResultado;
  @ViewChild("orderAscOrDesc") orderAscOrDesc;

  private empresa = {
    id: null,
    companyName: null,
    telefone: null,
    taxRegistrationNumber: null,
    city: null,
    province: null,
    addressDetail: null,
    email: null,
    site: null,
    active_tfa: 0,
    logotipo: null,
    width: 0,
    height: 0
  }

  private pagination = {
    total: null,
    perPage: 5,
    page: 1,
    lastPage: null,

    start: 1,
    end: 10,
    search: "a",
    order: "companyName"
  };

  private items: any = [];
  private url: "";
  private logotipo: "";
  private largura: any;
  private altura: any;
  private filePdf: any = [];
  private widithImage: any;
  private heightImage: any;


  constructor(private http: HttpService, private configService: ConfigService, private imageCompress: NgxImageCompressService) { }

  ngOnInit() {
    //this.listEmpresas(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.orderBy.nativeElement.value);
    this.empresaUser();
    this.largura;
    this.altura;
    this.widithImage = 28;
    this.heightImage = 18;
  }


  private pesquisarEmpresa(e) {
    this.pagination.page = 1;

  }

  private empresaUser() {

    this.configService.loaddinStarter('start');

    this.http.call_get('empresa/empresa-user', null).subscribe(

      response => {
        this.localUrl = Object(response).data.logotipo
        this.altura = Object(response).data.width
        this.largura = Object(response).data.height
        this.empresa.id = Object(response).data.id
        this.empresa.companyName = Object(response).data.companyName
        this.empresa.taxRegistrationNumber = Object(response).data.taxRegistrationNumber
        this.empresa.telefone = Object(response).data.telefone
        this.empresa.addressDetail = Object(response).data.addressDetail
        this.empresa.city = Object(response).data.city
        this.empresa.province = Object(response).data.province
        this.empresa.active_tfa = Object(response).data.active_tfa
        this.empresa.logotipo = Object(response).data.logotipo
        this.empresa.email = Object(response).data.email
        this.empresa.site = Object(response).data.site
        this.empresa.width = Object(response).data.width
        this.empresa.height = Object(response).data.height
        this.configService.loaddinStarter('stop');


      }
    );
  }

  private register(e) {
    e.preventDefault();
    this.configService.loaddinStarter('start');
    if (e.target.elements[0].value == "" || e.target.elements[1].value == "" || e.target.elements[2].value == "" || e.target.elements[3].value == "") {

      this.configService.showAlert('É obrigatório fornecer o nome, telefone, contribuente e morada do cliente', 'alert-danger', true);

    } else {
      this.empresa.companyName = e.target.elements[0].value;
      this.empresa.telefone = e.target.elements[1].value;
      this.empresa.taxRegistrationNumber = e.target.elements[2].value;
      this.empresa.city = e.target.elements[3].value;
      this.empresa.province = e.target.elements[4].value;
      this.empresa.addressDetail = e.target.elements[5].value;
      this.http.__call('empresa/register', this.empresa).subscribe(
        data => {
          if (Object(data).code == 500) {
            this.configService.showAlert(Object(data).message, 'alert-danger', true);
          } else {
            this.configService.showAlert(Object(data).message, 'alert-success', true);
            //this.listEmpresas(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.orderBy.nativeElement.value);
            this.configService.clearFormInputs(e);
          }

        }
      );
    }
    this.configService.loaddinStarter('stop');
  }

  private alert = {
    message: null,
    show: false,
    class: null
  }

  private alerts(message, classs) {
    this.alert.message = message;
    this.alert.class = classs;
    this.alert.show = true;
    setTimeout(() => {
      this.alert.show = false;
    }, 5000);
  }


  private refreshDataEmpresa(id, companyName, taxRegistrationNumber, telefone, city, province, addressDetail, active_tfa, email, site) {
    this.empresa.id = id;
    this.empresa.companyName = companyName;
    this.empresa.taxRegistrationNumber = taxRegistrationNumber;
    this.empresa.telefone = telefone;
    this.empresa.city = city;
    this.empresa.province = province;
    this.empresa.addressDetail = addressDetail;
    this.empresa.active_tfa = active_tfa
    this.empresa.email = email
    this.empresa.site = site
  }

  private editar(e) {
    e.preventDefault();

    if (this.empresa.companyName == null || this.empresa.telefone == null || this.empresa.taxRegistrationNumber == null || this.empresa.city == null || this.empresa.province == null || this.empresa.addressDetail == null || this.empresa.addressDetail == null) {
      this.configService.showAlert('É obrigatório fornecer o nome, telefone, contribuente e morada', 'alert-danger', true);
    } else {
      this.configService.loaddinStarter('start');
      this.empresa.logotipo = this.logotipo
      this.http.__call('empresa/update/' + this.empresa.id, this.empresa).subscribe(
        response => {
          this.configService.loaddinStarter('stop');
          this.configService.showAlert(Object(response).message, 'alert-success', true);
          //this.listEmpresas(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.orderBy.nativeElement.value);
          this.empresaUser();

        }
      );
    }
  }
  private upload(e) {



      this.configService.loaddinStarter('start');
      this.empresa.logotipo = this.logotipo
      this.http.__call('empresa/upload/' + this.empresa.id, this.empresa).subscribe(
        response => {
          this.configService.loaddinStarter('stop');
          this.configService.showAlert(Object(response).message, 'alert-success', true);
          this.empresaUser();
        }
      );

  }



  private mostrarResultados() {
    this.pagination.perPage = this.mostrarResultado.nativeElement.value;
    //this.listEmpresas(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.orderBy.nativeElement.value);

  }

  private pages = [];
  private nextProvPageNumber = 1;

  private gerarPages() {
    for (var i = 1; i <= this.pagination.lastPage; i++) {
      this.pages.push(i);
    }
  }
  private nextPage() {
    this.nextProvPageNumber++;
    if (this.nextProvPageNumber < 0) {
      this.nextProvPageNumber = this.pagination.page;
    }
    //this.listEmpresas(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.orderBy.nativeElement.value);

  }
  private prevPage() {
    this.nextProvPageNumber--
    if (this.nextProvPageNumber > 0) {
      //this.listEmpresas(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.orderBy.nativeElement.value);
      this.nextProvPageNumber = this.pagination.page;
    }
  }

  private refreshPaginate(page) {
    this.pagination.page = page;
    //this.listEmpresas(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value, this.orderBy.nativeElement.value);
  }



  file: any;
  localUrl: any;
  save: any;
  urlCarregada: any;
  localCompressedURl: any;
  sizeOfOriginalImage: number;
  sizeOFCompressedImage: number;




  selectFile(event: any) {
    var fileName: any;
    var larg: any;
    this.file = event.target.files[0];
    let img = new Image()
    img.src = window.URL.createObjectURL(event.target.files[0])

    img.onload = () => {
      /*if (img.width === 100 && img.height === 100) {
      } else {*/
      this.empresa.width = img.width;
      this.empresa.height = img.height;
      this.save = true;
      //}

    }

    fileName = this.file['name'];
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.urlCarregada = event.target.result;
        this.compressFile(this.urlCarregada, fileName)
        this.pdfPreview2();
      }
      reader.readAsDataURL(event.target.files[0]);
    }

  }

  imgResultBeforeCompress: string;
  imgResultAfterCompress: string;

  compressFile(image, fileName) {
    var orientation = -1;
    this.sizeOfOriginalImage = this.imageCompress.byteCount(image) / (1024 * 1024);
    // console.warn('Size in bytes is now:', this.sizeOfOriginalImage);
    this.imageCompress.compressFile(image, orientation, 30, 30).then(
      result => {
        this.imgResultAfterCompress = result;
        this.localCompressedURl = this.imgResultAfterCompress;
        this.sizeOFCompressedImage = this.imageCompress.byteCount(result) / (1024 * 1024)
        // console.warn('Size in bytes after compression:', this.sizeOFCompressedImage);
        const imageName = fileName;
        document.getElementById('customFileLabel').innerHTML = imageName;
        const imageBlob = this.dataURItoBlob(this.imgResultAfterCompress.split(',')[1]);
        const imageFile = new File([result], imageName, { type: 'image/jpeg' });
        this.logotipo = this.localCompressedURl;
      });

  }
  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }


  setWidth() {
    this.largura = (<HTMLInputElement>document.getElementById("width")).value;
    document.getElementById('logo_empresa').style.width = this.largura + "px";
  }
  setHeight() {
    this.altura = (<HTMLInputElement>document.getElementById("height")).value;
    document.getElementById('logo_empresa').style.height = this.altura + "px";
  }
  setLargura() {
    this.largura = (<HTMLInputElement>document.getElementById("largura")).value;
    document.getElementById('logo_empresa1').style.width = this.largura + "px";
  }
  setAltura() {
    this.altura = (<HTMLInputElement>document.getElementById("altura")).value;
    document.getElementById('logo_empresa1').style.height = this.altura + "px";
  }


  pdfPreview2() {

    var largura = (<HTMLInputElement>document.getElementById("width")).value;
    var altura = (<HTMLInputElement>document.getElementById("height")).value;

    var imgData = {
      src: this.urlCarregada,
      w: Number(largura),
      h: Number(altura)
    }

    var doc = new jsPDF();
    doc.addImage(imgData.src, 'png', 8, 10, imgData.w, imgData.h)
    doc.setFontSize(7)

    var blobPDF = new Blob([doc.output('blob')], { type: 'application/pdf' });
    var blobUrl = URL.createObjectURL(blobPDF);
    $("#pdf_preview2").attr('src', blobUrl);

  }

  pdfPreview() {

    var largura = (<HTMLInputElement>document.getElementById("largura")).value;
    var altura = (<HTMLInputElement>document.getElementById("altura")).value;

    var imgData = {
      src: this.localUrl,
      w: Number(largura),
      h: Number(altura)
    }

    var doc = new jsPDF();

    doc.addImage(imgData.src, 'png', 8, 10, imgData.w, imgData.h)
    doc.setFontSize(7)

    var blobPDF = new Blob([doc.output('blob')], { type: 'application/pdf' });
    var blobUrl = URL.createObjectURL(blobPDF);
    $("#pdf_preview").attr('src', blobUrl);

  }

  closeModal() {
    $('#formUpload input').each(function () {
      $(this).val('');
    });
  }

  setModalControl(tipo) {
    if (tipo == 1) { // carregar
    } else {
      this.pdfPreview();
    }
  }

}
