import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange, NgModule } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpService } from 'src/app/providers/http/http.service';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { first } from 'rxjs/operators';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-create-or-edit-bairro',
  templateUrl: './create-or-edit-bairro.component.html',
  styleUrls: ['./create-or-edit-bairro.component.css']
})
export class CreateOrEditBairroComponent implements OnInit {

  public currentUser: any;
  @Input() title: string = "Registar Bairro/Zona";
  @Input() distrito_view: boolean = false;

  @Input() bairro = {
    id: null,
    nome: null,
    abreviatura: null,
    has_distrito: false,
    municipio_id: null,
    distrito_id: null,
    provincia_id: null,
    is_active: null,
    user_id: null,
  };


  /*   @Input() municipio = {
      id: null,
      nome: null
    }; */

  @Input() bairros: any = [];

  @Input() provincias: any = [];
  @Input() municipios: any = [];
  @Input() distritos: any[];

  private bairrosArray = [];
  private bairroRow = {
    id: null,
    nome: null,
    abreviatura: null,
    has_distrito: null,
    distrito_id: null,
    distrito: null,
    municipio_id: null,
    municipio: null,
    provincia_id: null,
    provincia: null,
    user_id: null,
    user: null

  };
  private bairroRowValid: boolean = false;
  @Input() addRows: boolean = true;

  @Input() showCreateBairro: boolean = true;

  @Input() bairroModal: boolean = false;


  //@Input() simpleFormBairro: FormGroup;

  submitted = false;
  private loading: boolean = false;

  //@Output() private loadListBairro = new EventEmitter<any>();

  constructor(private auth: AuthService, private http: HttpService, private configService: ConfigService, private formBuilder: FormBuilder) {
    //this.createForm();
    this.currentUser = this.auth.currentUserValue;

    if (this.provincias.length <= 0) {
      this.selectBoxProvincias();

    }
    /*
        let provincia_id = null;

        const loadedStateCallback = () => {

          console.log("provincia_id: " + provincia_id);

          this.bairro.provincia_id = provincia_id;
          console.log("this.bairro.provincia_id: " + this.bairro.provincia_id);
        }

        if(this.bairro.provincia_id != null)
          provincia_id = config.getConfig(this.modulo, "provincia_id", loadedStateCallback); */

  }



  ngOnInit() {

    //console.log(this.bairro);
    //console.log(this.municipios);

    //this.bairro.provincia_id = ;

    if (this.addRows == false) {
      this.selectBoxProvincias();
    }

    this.onReset();
    this.bairro.user_id = this.currentUser.user.id;

    //console.log(this.bairro);
  }

  save() {

    if (!this.addRows) {
      console.log(this.bairro);
      this.bairrosArray.unshift({ ...this.bairro });

      console.log(this.bairrosArray);
    }

    for (let i = 0; i < this.bairrosArray.length; ++i) {

      this.bairrosArray[i].user_id = this.currentUser.user.id;
      if (this.bairrosArray[i].id == null) {
        this.http.__call('bairro/create', this.bairrosArray[i]).subscribe(
          response => {

            if (Object(response).code == 200) {
              this.configService.showAlert(Object(response).message, "alert-success", true);
              this.bairrosArray = [];
              this.onReset();

            } else {
              this.configService.showAlert(Object(response).message, "alert-danger", true);
            }

          }
        );

      }
      else {
        console.log('bairro',this.bairro);
        this.http.__call('bairro/update/' + this.bairrosArray[i].id, this.bairrosArray[i]).subscribe(
          response => {

            if (Object(response).code == 200) {
              this.configService.showAlert(Object(response).message, "alert-success", true);
              this.bairrosArray = [];
            } else {
              this.configService.showAlert(Object(response).message, "alert-danger", true);
            }

          }
        );
      }
    }

  }


  createOrEdit(uri: any, formulario: any, isCreate: boolean) {
    console.log(formulario)
    // TODO: usado para fazer a requisição com a api de criação de object
    this.http.__call(uri, formulario).pipe(first()).subscribe(
      response => {
        this.submitted = false;
        this.loading = false;

        if (Object(response).code == 200) {
          this.openEndBairroModal(true);
          this.reloadBairros();
        }

        if (isCreate) {
          formulario.reset();
        }

        //this.bairros = Object(response).data;
      },
      error => {
        this.submitted = false;
        this.loading = false;
      });
  }

  setDataBairro({ item }) {

    if (this.provincias.length <= 0) {
      this.selectBoxProvincias();
    }
    //console.log(this.bairro);
    //console.log(item);

    if (item.id > 0) {
      this.title = "Editar Bairro/Zona";

      this.bairro.id = item.id;
      this.bairro.nome = item.nome;
      this.bairro.abreviatura = item.abreviatura;
        this.bairro.has_distrito = item.has_distrito;
      this.bairro.distrito_id = item.distrito_id;
      this.bairro.municipio_id = item.municipio_id;
      this.bairro.provincia_id = item.provincia_id;
      this.bairro.is_active = item.is_active;
      this.bairro.user_id = item.user_id;

    } else {
      this.title = "Registar Bairro/Zona";
      this.onReset();
    }

    //console.log(this.bairro);
  }

  onReset() {
    this.submitted = false;

    this.bairro.id = null;
    this.bairro.nome = null;
    this.bairro.abreviatura = null;
    this.bairro.has_distrito = false;
    this.bairro.distrito_id = null;
    this.bairro.municipio_id = null;
    this.bairro.is_active = null;
    this.bairro.user_id = null;
  }

  private async appendRowBairro() {
    var validar = 0;

    const loadedStateCallback = () => {
      //console.log(this.bairro);
      this.bairroRow.nome = this.bairro.nome;
      this.bairroRow.abreviatura = this.bairro.abreviatura;
      this.bairroRow.user_id = this.currentUser.user.id;

      //console.log(this.bairroRow);

      this.bairroRowValid = this.bairroRowValidation(this.bairroRow);
      if (this.bairroRowValid)
        this.bairrosArray.unshift({ ...this.bairroRow });
    }

    await this.getDistritoById(this.bairro.distrito_id, loadedStateCallback);

    //console.log(this.bairrosArray);

  }

  private deleteRowBairroAppended(row): void {

    for (let i = 0; i < this.bairrosArray.length; ++i) {

      if ((this.bairrosArray[i].municipio_id == row.municipio_id) && (this.bairrosArray[i].distrito_id == row.distrito_id) && (this.bairrosArray[i].nome == row.nome)) {
        this.bairrosArray.splice(i, 1);
        //console.log(this.bairrosArray[i]);
      }

    }


  }


  private bairroRowValidation(row): boolean {

    if (this.bairrosArray.length == 0)
      return true;

    for (let i = 0; i < this.bairrosArray.length; ++i) {
      //console.log(this.bairrosArray[i]);
      if ((this.bairrosArray[i].municipio_id == row.municipio_id) && this.bairrosArray[i].distrito_id == row.distrito_id && this.bairrosArray[i].nome == row.nome) {
        return false;
      }
    }

    return true;
  }


  private reloadBairros() {

    /*     this.http.call_get('bairro/listagem/', null).subscribe(
          response => {

            this.bairros = Object(response).data;
          }
        ); */
  }

  private selectBoxProvincias() {
    this.http.call_get('provincia/selectBox', null).subscribe(
      response => {
        //console.log(Object(response).data);

        this.provincias = Object(response).data;
      }
    );
  }

  private getDistritoById(id: any, callback: Function) {

    this.http.__call('distrito/getDistritoById/' + ((this.bairro.has_distrito) ? id : 0),
      {
        has_distrito: this.bairro.has_distrito,
        municipio_id: this.bairro.municipio_id
      }).subscribe(
        response => {

          console.log(Object(response));

          if (this.bairro.has_distrito) {
            this.bairroRow.distrito_id = Object(response).id;
            this.bairroRow.distrito = Object(response).nome;
          }

          this.bairroRow.municipio_id = Object(response).municipio_id;
          this.bairroRow.municipio = Object(response).municipio;

          this.bairroRow.provincia_id = Object(response).provincia_id;
          this.bairroRow.provincia = Object(response).provincia;

          this.bairroRow.has_distrito = this.bairro.has_distrito;

          if (!this.bairro.has_distrito) this.bairroRow.distrito_id = null;

          callback();

          //console.log(this.bairroRow);
        }
      );

  }


  private selectBoxMunicipiosByProvincia() {

    //this.bairro.provincia_id = this.config.getConfig(this.modulo, "provincia_id");

    console.log(this.bairro);

    this.http.call_get('municipio/getMunicipiosByProvincia/' + this.bairro.provincia_id, null).subscribe(
      response => {
        //console.log(Object(response).data);

        this.municipios = Object(response).data;
      }
    );
  }

  private selectBoxDistritosByMunicipio(id) {

    this.bairro.has_distrito = this.distrito_view;

    if (this.distrito_view) {
      for (let i = 0; i < this.municipios.length; ++i) {
        if (this.municipios[i].id == this.bairro.municipio_id)
          this.bairro.has_distrito = this.municipios[i].has_distrito;
      }
    }


    this.http.call_get('distrito/getDistritosByMunicipio/' + id, null).subscribe(
      response => {
        //console.log(Object(response).data);

        this.distritos = Object(response).data;

        if (this.distritos.length) this.bairro.has_distrito = true;
      }
    );

    if (!this.bairro.has_distrito && !this.distrito_view) {
      this.selectBoxBairrosByMunicipio(id);
    }
    else {
      this.selectBoxBairrosByDistrito(this.bairro.distrito_id);
    }
  }

  private selectBoxBairrosByDistrito(id) {

    this.http.call_get('bairro/selectBoxByDistrito/' + id, null).subscribe(
      response => {
        this.bairros = Object(response);
      }
    );

  }

  private selectBoxBairrosByMunicipio(id) {

    this.http.call_get('bairro/selectBoxByMunicipio/' + id, null).subscribe(
      response => {

        this.bairros = Object(response);
      }
    );
  }


  private openEndBairroModal(flag: boolean): void {
    this.bairroModal = flag;
  }

  public loadListEstabelecimentos(bairros) {
    this.bairros = bairros;
  }



}
