import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

@Component({
  selector: 'app-conf-selo',
  templateUrl: './conf-selo.component.html',
  styleUrls: ['./conf-selo.component.css']
})
export class ConfSeloComponent implements OnInit, OnDestroy {


  private tipo_selos: any = [];
  private cor_selos: any = [];


  private title: string = "Novo";

  public selo = {
    id: null,
    nome: null,
    cor_selo_id: null,
    numero: null,
    tipo_selo_id: null,
    tipo_selo_slug: null
  }


  private items: any = [];

  constructor(private http: HttpService, private configService: ConfigService) { }

  ngOnInit() {
    this.http.filters.search = null
    this.getPageFilterData(1);


    if (this.tipo_selos.length <= 0)    this.selectBoxTipoSelos();
    if (this.cor_selos.length <= 0)    this.selectBoxCorSelos();

  }

  ngOnDestroy(){
    this.http.filters.orderBy = null
  }


  private getSelos() {

    this.configService.loaddinStarter('start');

    this.http.__call('selo/listagem', this.http.filters).subscribe(

      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.configService.loaddinStarter('stop');

      }
    );
  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
    this.getSelos();
  }


  private save() {
    this.configService.loaddinStarter('start');

    if (this.selo.nome == "" || this.selo.tipo_selo_id == null) {
      this.configService.showAlert("Os campos Nome e Tipo são obrigatórios", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    } else {

      const uri = (!this.selo.id) ? 'selo/create' : 'selo/update/' + this.selo.id;

    this.http.__call(uri, this.selo).subscribe(
      res => {
        if (Object(res).code == 201) {
          this.configService.showAlert(Object(res).message, 'alert-danger', true);
          this.configService.loaddinStarter('stop');
        } else {
          this.configService.showAlert(Object(res).message, 'alert-success', true);

          this.configService.loaddinStarter('stop');
        }

          this.getSelos();
      }
    )
  };
}

private selectBoxTipoSelos() {

  this.http.call_get('tipo-selo/selectBox', null).subscribe(
    response => {
      //console.log(Object(response).data);

      this.tipo_selos = Object(response);
    }
  );
}

private selectBoxCorSelos() {

  this.http.call_get('cor-selo/selectBox', null).subscribe(
    response => {
      //console.log(Object(response).data);

      this.cor_selos = Object(response);
    }
  );
}

  private refresh(item) {

    this.title = "Editar";

    this.selo.id = item.id;
    this.selo.nome = item.nome;

    this.selo.cor_selo_id = item.cor_selo_id,
    this.selo.numero = item.numero,
    this.selo.tipo_selo_id = item.tipo_selo_id;
    this.selo.tipo_selo_slug = null;
  }

  private getTipoSeloSlug() {

    for (let i = 0; i < this.tipo_selos.length; ++i) {
      if (this.tipo_selos[i].id == this.selo.tipo_selo_id)
        this.selo.tipo_selo_slug = this.tipo_selos[i].slug;
    }
  }

  private ini() {
    this.selo = {
      id: null,
      nome: null,
      cor_selo_id: null,
      numero: null,
      tipo_selo_id: null,
      tipo_selo_slug: null

    }
  }





}
