import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ValidationFormsService {

    static getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
        let config = {
            required: 'Obrigatório',
            invalidEmailAddress: 'endereço de e-mail invalido',
            invalidCellPhone: 'Número de telefone invalido',
            minlength: `Tamanho min. ${validatorValue.requiredLength}`
        };

        return config[validatorName];
    }


    static emailValidator(control) {
      if ( control.value === null ) { return null }

      let test_regex = control.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)
      if (test_regex) {
          return null
      } else {
          return { invalidEmailAddress: true };
      }
    }

    static cellPhoneValidator(control) {
      if ( control.value === null ) { return null }

      let test_regex = control.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)
      if (test_regex) {
          return null
      } else {
          return { invalidCellPhone: true };
      }
  }

}
