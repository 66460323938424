import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from 'src/environments/environment'


@Injectable({
    providedIn: 'root'
})

export class ClassificacaoService {

    private token = sessionStorage.getItem('sessionToken')

    private headers = new HttpHeaders()
    .set('content-type', 'application/json')
    .set('Access-Control-Allow-Origin', '*')
    .set('Authorization', `Bearer ${this.token}`)

    constructor(
        private _http_client: HttpClient
    ){}


    getClassificacoes() {
        return this._http_client.get<any>(
            `${environment.app_url}api/${environment.apiVersion}/classificacao_produtos`, 
            { 'headers': this.headers }
        )
    }

    getClassificacao(id: number) {
        return this._http_client.get<any>(
            `${environment.app_url}api/${environment.apiVersion}/classificacao_produtos${id}`, 
            { 'headers': this.headers }
        )
    }

    CreateClassificacao(classificacao: any) {
        return this._http_client.post<any>(
            `${environment.app_url}api/${environment.apiVersion}/classificacao_produtos`, 
            classificacao, 
            { 'headers': this.headers }
        )
    }

    UpdateTipoCliente(edit_objet: any) {
        return   this._http_client.patch<any>(
            `${environment.app_url}api/${environment.apiVersion}/classificacao_produtos/${edit_objet.id}`, 
            edit_objet, 
            { 'headers': this.headers }
        )
    }
}