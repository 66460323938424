import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { ExcelService } from 'src/app/services/excel.service';
import { AuthService } from 'src/app/providers/auth/auth.service';
import * as moment from 'moment';
import { RotaService } from '../rotas.service';
import { requiredTrue } from '@rxweb/reactive-form-validators';
import { ExcelAutoService } from 'src/app/services/excel/excel-auto.service';
import { RoteiroService } from '../../reports/roteiro/roteiro.service';

@Component({
  selector: 'app-listar-leituras-validas',
  templateUrl: './listar-leituras-validas.component.html',
  styleUrls: ['./listar-leituras-validas.component.css']
})
export class ListarLeiturasValidasComponent implements OnInit, OnDestroy {

  @ViewChild("search") search;
  @ViewChild("orderBy") orderBy;
  @ViewChild("searchData") searchData;
  @ViewChild("mostrarResultado") mostrarResultado;

  public currentUser: any;
  private loading: boolean = false;

  private has_rotas_header: boolean = false;
  motivos_nao_leitura_list: any = [];
  motivos_alteracao_leitura_list: any = [];

  private rotas_header: any = [];
  private rotaRuns: any = [];
  private DadosEmpresa: any = [];

  private corridasLeiturasArray = [];
  private leituraToProcess: boolean = true;

  private rota_header = {
    id: null,
    descricao: null,
    data_inicio: null,
    data_fim: null,
    leitor_id: null,
    user_nome: null,
    estado: null,
  }

  private rotarun = {
    id: null,
    nome_cliente: null,
    rota_header_id: null,
    conta_id: null,
    servico_id: null,
    id_servico: null,
    provincia_id: null,
    municipio_id: null,
    bairro_id: null,
    has_distrito: null,
  }

  private items: any = [];

  view_user = false

  //current data leituras
  leitura: any = {
    motivo_edicao: null,
    leitura: null,
    contrato_id: null,
    ultima_leitura: null,
    data_ultima_leitura: null,
    data_leitura: null,
    nao_leitura: null,
    motivo: null
  }

  quarteiroes: any = []
  distritos: any = []
  bairros: any = []
  ruas: any = []
  municipios: any = [];

  constructor(
    private auth: AuthService,
    private http: HttpService,
    private _rotaService: RotaService,
    private configService: ConfigService,
    private reportRoteiro: RoteiroService,
    private excelService: ExcelAutoService,
  ) {

    this._resetFilters();
    this.getPageFilterData(1);
    this._loadAllDataFilter();
    this.currentUser = this.auth.currentUserValue;
    this._rotaService.getMotivosNaoLeitura()
      .subscribe(
        response => {
          this.motivos_nao_leitura_list = response.data
        }
      )

  }

  imprimirPDF(): void {

  }


  ngOnInit() {
    this.http.filters.search = null
    this.http.filters.orderBy = null
    this.orderBy = null
    console.log(this.orderBy)
    console.log(this.http)
    this.getPageFilterData(1)
    this.getRotasHeader();
    this._rotaService.motivoAlteracaoLeituraForSelect()
      .subscribe(
        response => {
          this.motivos_alteracao_leitura_list = response.data
        }
      )
  }
  _loadAllDataFilter() {
    this._getMunicipios();
    this.empresaUser();
  }

  _getMunicipios() {
    this.http.__call('municipio/listagems', null)
      .subscribe(response => {
        this.municipios = Object(response).data
      }),
      (error) => {
        if (!error.ok) {
          console.log(error)
        }
      }
  }

  _changeDistrito(municipio_id) {
    this.distritos = [];
    this.bairros = [];
    this.quarteiroes = []
    this.ruas = []
    this._resetFilters()
    if (municipio_id) {
      this._getComuna(municipio_id);
    }
  }
  _changeBairros(distrito_id) {
    if (distrito_id) {
      this._getBairros(distrito_id);
    }
  }

  _changeQuarteirao(bairro_id) {
    this.quarteiroes = []
    if (bairro_id) {
      this._getQuarteirosDoBairro(bairro_id)
    }
  }

  _changeRuas(quarteirao_id) {
    this.ruas = []
    if (quarteirao_id) {
      this._getRuasDoQuarteirao(quarteirao_id)
    }
  }

  _getRuasDoQuarteirao(id) {
    this.http.call_get(`rua/getRuasByQuarteirao/${id}`, null)
      .subscribe(
        response => {
          this.ruas = Object(response).data
        }
      )
  }

  _getBairros(id) {
    this.http.__call('get-bairros/' + id, this.http.filters)
      .subscribe(response => {
        this.bairros = Object(response).data
      }),
      (error) => {
        if (!error.ok) {
          console.log(error)
        }
      }
  }

  _getComuna(id) {
    this.http.__call('get-distritos/' + id, this.http.filters)
      .subscribe(response => {
        this.distritos = Object(response).data
      }),
      (error) => {
        if (!error.ok) {
        }
      }
  }

  _getQuarteirosDoBairro(id) {
    this.http.call_get(`quarteirao/getQuarteiraosByBairro/${id}`, null)
      .subscribe(
        response => {
          this.quarteiroes = Object(response).data
        }
      )
  }


  private getRotasHeader() {
    this.http.call_get('rota-header/select', null).subscribe(
      response => {
        this.rotas_header = Object(response).data;
      }
    );
  }
  ngOnDestroy() {
    this.http.filters.orderBy = null
  }

  onReset() {
    this.leitura = {
      motivo_edicao: null,
      contrato_id: null,
      leitura: null,
      ultima_leitura: null,
      data_ultima_leitura: null,
      data_leitura: null,
      nao_leitura: null,
      motivo: null
    }
  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      this.http.filters.pagination.page = 1;
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1;
    this.listarLeituras();
    this._resetFilters();
  }

  _resetFilters() {
    this.http.filters.data1 = null
    this.http.filters.data2 = null;
    this.http.filters.municipio_id = null;
    this.http.filters.distrito_id = null;
    this.http.filters.bairro_id = null;
    this.http.filters.quarteirao_id = null;
    this.http.filters.rua_id = null;
  }

  private listarLeituras() {
    this.loading = true
    this.http.__call('leitura-validas/listagem', { filters: this.http.filters }).subscribe(
      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;
        this.items = Object(response).data.data;
        this.loading = false
      }
    );
  }

  private clearFormInputs() {
    this.rota_header.id = null,
      this.rota_header.descricao = null,
      this.rota_header.data_inicio = null,
      this.rota_header.data_fim = null,
      this.rota_header.leitor_id = null,
      this.rota_header.user_nome = null
  }

  private clearFormRotaRun() {

    this.rotarun.nome_cliente = null,
      this.rotarun.conta_id = null,
      this.rotarun.servico_id = null,
      this.rotarun.id_servico = null
  }

  _update() {

    //====================== VALIDATION ==========================
    if (this.leitura.leitura < 0) {
      this.configService.showAlert('O valor da leitura não deve ser negativo', 'alert-danger', true)
      this.configService.loaddinStarter('stop')
      return
    }

    this._rotaService.updateLeitura(this.leitura)
      .subscribe((response) => {

        this.listarLeituras()
        this._rotaService._closeModal("closeModalUpdateLeitura")

        //loading list
        this.getPageFilterData(1)

        this.leitura.leitura = {
          leitura: null,
          ultima_leitura: null,
          data_leitura: null,
          nao_leitura: null,
          motivo: null,
          contrato_id: null
        }

      })
  }

  _updateNaoLeituraToLeitura() {
    this.leitura.nao_leitura = false
    this.leitura.motivo = ''

    this._rotaService.updateLeitura(this.leitura)
      .subscribe((response) => {
        this._rotaService._closeModal("closeModalNaoLeitura")
        this._rotaService._closeModal("closeModalUpdateLeitura")
        this.listarLeituras()

        this.leitura = {
          leitura: null,
          ultima_leitura: null,
          data_leitura: null,
          nao_leitura: null,
          motivo: null
        }
      })
  }

  setDataLeitura(item) {
    this.leitura.id = item.id
    this.leitura.motivo_edicao = item.motivo_edicao
    this.leitura.contador_id = item.contador_id
    this.leitura.contrato_id = item.contrato_id
    this.leitura.nao_leitura = item.nao_leitura
    this.leitura.motivo = item.motivo
    this.leitura.leitura = item.leitura
    this.leitura.perido = item.perido
    this.leitura.ultima_leitura = item.ultima_leitura
    this.leitura.data_leitura = item.data_leitura;
    this.leitura.data_ultima_leitura = item.data_ultima_leitura
  }


  historicos: any;
  private getLeituraHistorico(leitura_id: any) {
    this.loading = true;
    this.http.call_get("gestao/leitura-historicos/" + leitura_id, null).subscribe(res => {
      console.log(Object(res).data)
      this.historicos = Object(res).data;
      this.loading = false;
    });

  }

  setleitura: any;
  _title: any = 'Anular Leitura';
  text_alert: any = 'Tens certeza que desejas anular está leitura ?';

  setLeitura(leitura: any) {
    this._title = leitura.is_annulled ? 'Voltar ao normal' : 'Anular Leitura';
    this.text_alert = leitura.is_annulled ? 'Voltar ao normal' : 'Tens certeza que pretende anular está leitura?';
    this.setleitura = leitura;
  }

  anularleitura() {
    this.loading = true;
    this.http.__call("anulateOrnot-leitura/" + this.setleitura.id, { motivo: this.leitura.motivo_anulacao })
      .subscribe((res) => {
        this.getPageFilterData(1);
        this.loading = false;
      })
  }

  public isLeitura = false;
  _isLeitura(event) {
    this.isLeitura = false;
    if (event.target.value == 0) {
      this.isLeitura = true;
    }
    this.http.filters.motivo_id = null;
  }

  validateDate(event: any) {
    if (moment(event.target.value).format('YYYY-MM-DD') > moment(new Date()).format('YYYY-MM-DD')) {
      this.leitura.data_leitura = null;

      this.configService.showAlert(
        'A data da leitura não deve ser maior que actual.',
        'alert-error', true);
      return;
    }
  }

  private empresaUser() {
    this.configService.loaddinStarter('start');
    this.http.call_get('empresa/empresa-user', null).subscribe(
      response => {
        this.DadosEmpresa = Object(response).data;
      }
    );
  }

  exportAsPDF(): void {
    var file = document.getElementsByClassName("tablePDF")[0]
    this.reportRoteiro.reportListagemDLeituras(file, this.DadosEmpresa, 'save', 'Rotas', this.items.length);
  }

  exportAsXLSXAsHistorico(): void {
    var CurrentDate = new Date();

    var keys = [
      { key: 'leitura', width: 35, style: { font: { name: 'Calibri' } } },
      { key: 'ultima_leitura', width: 25 },
      { key: 'consumo', width: 55 },
      { key: 'numero_serie', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'data_leitura', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'estado', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'created_at', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'leitor', width: 20, style: { font: { name: 'Calibri' } } },
    ];

    var Cols = ["Leitura", "Última Leitura", "Consumo", "Contador", "Data Leitura", "Estado", "Data Registo", "Registado Por"]
    var title = 'Histórico de Leitura';
    var nameFile = "Histórico de Leitura -" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    this.excelService.excels(this.historicos, nameFile, this.DadosEmpresa.logotipo, keys, Cols, title, 5, 8, 30, 3)
  }

  exportAsXLSX(): void {
    var CurrentDate = new Date();

    var keys = [
      { key: 'rota_header', width: 35, style: { font: { name: 'Calibri' } } },
      { key: 'contrato_id', width: 25 },
      { key: 'cliente', width: 30 },
      { key: 'cil', width: 55 },
      { key: 'motivo', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'morada_format', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'numero_serie', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'ultima_leitura', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'leitura', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'consumo', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'data_leitura', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'data_ultima_leitura', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'is_anulada', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'is_facturada', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'tipo_anomalia', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'is_estado', width: 20, style: { font: { name: 'Calibri' } } },
    ];

    var Cols = ["Rota", "Contrato", "Cliente", "Cil", "Motivo", "Morada", "Contador", "Última Leitura", "Leitura", "Consumo", "Data Leitura", "Data Última Leitura", "Anulada?", "Facturada?", "Tipo Anomalia", "Estado"]
    var title = 'Listagem das Leituras';
    var nameFile = "Listagem das Leituras -" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    this.excelService.excels(this.items, nameFile, this.DadosEmpresa.logotipo, keys, Cols, title, 5, 16, 30, 3)
  }

}
