import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

@Component({
  selector: 'app-prioridade',
  templateUrl: './prioridade.component.html',
  styleUrls: ['./prioridade.component.css']
})
export class PrioridadeComponent implements OnInit {
  @ViewChild("search") search;
  @ViewChild("orderBy") orderBy;
  @ViewChild("searchData") searchData;
  @ViewChild("mostrarResultado") mostrarResultado;

  private prioridade = {
    id: null,
    designacao: null
  }


  private items: any = [];

  constructor(private http: HttpService, private configService: ConfigService) { }

  ngOnInit() {
    this.getPageFilterData(1);
  }


  private listarPrioridades() {

    this.configService.loaddinStarter('start');

    this.http.__call('prioridade/listagem', this.http.filters).subscribe(

      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.configService.loaddinStarter('stop');

      }
    );
  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
    this.listarPrioridades();
  }


  private register(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (this.prioridade.designacao == "") {
      this.configService.showAlert("O campo designação é obrigatorio", 'alert-danger', true);
    } else {
    this.http.__call('prioridade/create', this.prioridade).subscribe(
      res => {
        if (Object(res).code == 500) {
          this.configService.showAlert(Object(res).message, 'alert-danger', true);
          this.configService.loaddinStarter('stop');
        } else {
          this.configService.showAlert(Object(res).message, 'alert-success', true);
          this.clearFormInputs(e);
          this.listarPrioridades()
          this.configService.loaddinStarter('stop');
        }
      }
    )
  }
      ;
  }


  private clearFormInputs(e) {
    e.target.elements[0].value = "";
    e.target.elements[1].value = "";
  }

  private refresh(id, designacao) {

    this.prioridade.id = id;
    this.prioridade.designacao = designacao;

  }

  private editar(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (this.prioridade.designacao == "") {
      this.configService.showAlert("O campo designação é obrigatorio", 'alert-danger', true);
    } else {
      this.http.__call('prioridade/update/' + this.prioridade.id, this.prioridade).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
           // this.configService.clearFormInputs(e);
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.listarPrioridades();

          }
        }
      );
    }
    this.configService.loaddinStarter('stop');
  }


  private ini() {
    this.prioridade = {
      id: null,
      designacao: null
    }
  }



}
