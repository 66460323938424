import { Injectable } from '@angular/core';
import * as moment from 'moment';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import { ConfigService } from 'src/app/providers/config/config.service';
@Injectable({
  providedIn: 'root'
})
export class ReportServicoContradosService {

  constructor(private configService: ConfigService) { }

  /* public relatorioServicoContratados(relatorio: any, filter: any, p = 'print') {


    //var imgData = this.configService.logotipoBase64;

    var doc = new jsPDF('l', '', 'a4')
    doc.addImage(imgData, 'JPEG', 130, 17, 24, 20)


    doc.setFontType('bold')
    doc.setFontSize(15)
    doc.text(145, 60, 'Relatório', 'center')
    doc.setFontSize(15)
    doc.text(145, 70, 'Serviços Contratados ', 'center')

    doc.setDrawColor(150);
    doc.setFillColor(255);
    doc.setFontSize(7);
    doc.setTextColor(0);

    var posY = 0;
    doc.rect(5, 80 + posY, 30, 6, 'B'); // filled red square
    doc.rect(35, 80 + posY, 50, 6, 'B'); // filled red square
    doc.rect(85, 80 + posY, 30, 6, 'B'); // filled red square
    doc.rect(115, 80 + posY, 30, 6, 'B'); // filled red square
    doc.rect(145, 80 + posY, 25, 6, 'B'); // filled red square
    doc.rect(170, 80 + posY, 75, 6, 'B'); // filled red square
    doc.rect(245, 80 + posY, 45, 6, 'B'); // filled red square

    doc.setFontType("bold");
    doc.text('Número Conta', 20, 84 + posY, 'center');
    doc.text('Chave Serviço', 60, 84 + posY, 'center');
    doc.text('Número Serviço', 99, 84 + posY, 'center');
    doc.text('Estado Serviço', 128, 84 + posY, 'center');
    doc.text('Data Estado', 158, 84 + posY, 'center');
    doc.text('Produto', 210, 84 + posY, 'center');
    doc.text('Mensalidade', 275, 84 + posY, 'center');
    posY = 5;
    var total = 0
    var total_valor_pago = 0
    var total_iva = 0
    var page = 0
    for (var i = 0; i < relatorio.length; i++) {
      doc.setDrawColor(150);
      doc.setFillColor(255);
      doc.rect(5, 80 + posY, 30, 6, 'B'); // filled red square
      doc.rect(35, 80 + posY, 50, 6, 'B'); // filled red square
      doc.rect(85, 80 + posY, 30, 6, 'B'); // filled red square
      doc.rect(115, 80 + posY, 30, 6, 'B'); // filled red square
      doc.rect(145, 80 + posY, 25, 6, 'B'); // filled red square
      doc.rect(170, 80 + posY, 75, 6, 'B'); // filled red square
      doc.rect(245, 80 + posY, 45, 6, 'B'); // filled red square

      doc.setFontType("normal");
      doc.text('' + relatorio[i].conta, 20, 84 + posY, 'center');
      doc.text('' + relatorio[i].nome_servico, 60, 84 + posY, 'center');
      doc.text('' + relatorio[i].numero_servico, 99, 84 + posY, 'center');
      doc.text('' +  relatorio[i].nome_estado_servicos, 128, 84 + posY, 'center');
      doc.text('' + (relatorio[i].data_servico==null ? '':relatorio[i].data_servico), 158, 84 + posY, 'right');
      doc.text('' + relatorio[i].produto, 210, 84 + posY, 'center');
      doc.text('' + this.configService.numberFormat(relatorio[i].mensalidade), 288, 84 + posY, 'right');
      total += relatorio[i].mensalidade;
      posY += 6;

      if (page == 18 && i == 18) {
        posY += 20;
        page = 0;
      }
      if (page == 38 && i != 18) {
        posY += 20;
        page = 0;
      }

      if (doc.internal.pageSize.height < (posY + 35)) {
        posY = posY - 250;
        doc.addPage();
      }
      page++;
    }
    doc.setFontType("bold");
    doc.setDrawColor(150);
    doc.setFillColor(255);
    doc.rect(5, 80 + posY, 240, 6, 'B'); // filled red square
    doc.rect(245, 80 + posY, 45, 6, 'B'); // filled red square
    doc.text('Total', 235, 84 + posY, 'right');
    doc.text('' + this.configService.numberFormat(total), 288, 84 + posY, 'right');




    if (p === 'save') {
      doc.save();
    } else {
      doc.autoPrint();
      doc.output("dataurlnewwindow");
    }


  } */
}
