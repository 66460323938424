import { Component, OnInit, Input, Output, EventEmitter, SimpleChange } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-create-or-edit-plano-manutencao',
  templateUrl: './create-or-edit-plano-manutencao.component.html',
  styleUrls: ['./create-or-edit-plano-manutencao.component.css']
})
export class CreateOrEditPlanoManutencaoComponent implements OnInit {

  @Input() modal: string = "modalCreateOrEdit";
  @Input() title: string = "Registar";
  @Input() planos_manutencao: any;
  @Input() plano: any;
  @Input() itens_planos: any;
  @Input() tarefas_planos: any;
  @Input() contadores_equipamento: any;

  submitted = false;
  formErrors: any;
  private loading: boolean = false;
  private ver: boolean = true;
  @Input() formCreateOrEditPlanoManutencao: FormGroup;
  @Input() formCreateOrEditItemPlano: FormGroup;
  @Input() formCreateOrEditTipoTarefa: FormGroup;
  @Input() formCreateContador: FormGroup;
  @Input() formEditarPeriodicidade: FormGroup;

  @Output() private loadList = new EventEmitter<any>();

  private empresas: any = [];
  private especialista: any = [];
  private periodicidadeDias: any = [];
  private duracoaHoras: any = [];
  private consumiveis: any = [];

  private lojas: any = [];
  private tarefa = {
    id: null,
    descricao: null
  };

  private equipamentos: any = [];
  private artigos: any = [];
  private roles: any = [];
  private armazens: any = [];
  private classificacoes: any = [];
  private funcoes: any = [];
  private tipos: any = [];
  private departamentos: any = [];

  private produtoCategorias: any = [];
  private is_sintoma: boolean = false;
  private is_causa: boolean = false;
  private is_titulo: boolean = false;

  private funcao = {
    id: null,
    descricao: null
  }

  public filters = {
    search: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    pagination: {
      perPage: 5,// Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima,
      lastPage: null,
      total: null // defini o total de registo da BD
    },
    filter: null // subelemente do filter
  }

  constructor(private http: HttpService, private configService: ConfigService, private formBuilder: FormBuilder) {
    this.createForm();
    this.createFormFuncao();
    this.createFormContador();
    this.createFormEditarPeriodicidade();
    this.createFormTipoTarefa();
  }

  ngOnInit() {
    this.getArmazens();
    this.getClassificacao();
    this.getPeriodicidadeDias();
    this.getDuracaoHoras();
    this.getDepartamento();
  }

  createForm() {
    this.formCreateOrEditPlanoManutencao = this.formBuilder.group({
      index: [{ value: null, disabled: true }],
      equipamento: [null, Validators.required],
      equipamento_id: [null],
      descricao: [null, Validators.required],
      estado_id: 1,

    })
  }

  createFormFuncao() {
    this.formCreateOrEditItemPlano = this.formBuilder.group({
      index: [{ value: null, disabled: true }],
      artigo: [null, Validators.required],
      artigo_id: [null],
      armazem_id: [null, Validators.required],
      categoria_id: [null, Validators.required],
      quantidade: [null, Validators.required],
    })
  }

  createFormTipoTarefa() {
    this.formCreateOrEditTipoTarefa = this.formBuilder.group({
      index: [{ value: null, disabled: true }],
      classificacao_id: [null, Validators.required],
      tipo_id: [null, Validators.required],
      inicio_contador: [null, Validators.required],
      //categoria_descricao: [null],
      //sla: [null],

      // ===== campos tipificados =====
      orientacoes: [null],
      periodicidade: [null, Validators.required],
      duracao_horas_id: [null],
      departamento_id: [null],
      funcao_id: [null], // or especialista_id
      qdade_especialista: [null],
    })
  }

  createFormContador() {
    this.formCreateContador = this.formBuilder.group({
      index: [{ value: null, disabled: true }],
      plano_manutencao_id: [null],
      valor_contador: [null, Validators.required],
    })
  }

  createFormEditarPeriodicidade() {
    this.formEditarPeriodicidade = this.formBuilder.group({
      index: [{ value: null, disabled: true }],
      periodicidade: [null, Validators.required],
    })
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.formCreateOrEditPlanoManutencao.controls;
  }

  get g() {
    return this.formCreateOrEditItemPlano.controls;
  }

  get h() {
    return this.formCreateContador.controls;
  }

  get j() {
    return this.formCreateOrEditTipoTarefa.controls;
  }

  get k() {
    return this.formEditarPeriodicidade.controls;
  }

  onReset() {
    this.submitted = false;
    this.formCreateOrEditPlanoManutencao.reset();
    this.formCreateOrEditItemPlano.reset();
    this.formCreateContador.reset();
    this.formCreateOrEditTipoTarefa.reset();
  }


  public getArmazens() {
    this.http.__call("armazem/selectBox", null).subscribe((response) => {
      this.armazens = Object(response).data;
    });
  }

  private getDepartamento() {
    this.http.call_get('departamento/selectBoxByTarefas', null).subscribe(
      response => {
        this.departamentos = Object(response);
      }
    );
  }

  private getFuncaoByDepartamento() {
    this.http.call_get('funcao/funcaoesByDepartamento/' + this.formCreateOrEditTipoTarefa.value.departamento_id, null).subscribe(
      response => {
        this.funcoes = Object(response).data;
      }
    );
  }

  private getClassificacao() {
    this.http.call_get('ordemservico/classificacao-getTecnica/selectBox-preventiva', null).subscribe(
      response => {
        this.classificacoes = Object(response).data;
      }
    );
  }

  private carregando = {
    nivel_2: 'Seleccione o equipamento',
    funcao: 'Seleccione a função',
    material: 'Seleccione o material',
    categoria: 'Seleccione a categoria',
    tipo: 'Seleccione o tipo',
  }

  private getTipoByClassificacao() {
    this.carregando.tipo = 'Carregando...';
    this.http.call_get('ordemservico/ostipo-by-classificacao/selectBox/' + this.formCreateOrEditTipoTarefa.value.classificacao_id, null).subscribe(
      response => {
        this.tipos = Object(response).data;
        this.carregando.tipo = 'Seleccione o tipo';
      }
    );
  }

  private getAssociacao() {

    this.formCreateOrEditTipoTarefa.patchValue({
      titulo: this.formCreateOrEditTipoTarefa.value.titulo
    });

    this.is_titulo = false;

    this.formCreateOrEditTipoTarefa.patchValue({
      categoria_descricao: null
    });

    if (this.formCreateOrEditTipoTarefa.value.tipo_id != null) {
      this.configService.loaddinStarter('start');
      this.http.call_get('ordemservico/ostipo/associacao/' + this.formCreateOrEditTipoTarefa.value.tipo_id, null).subscribe(
        response => {

          this.formCreateOrEditTipoTarefa.patchValue({
            categoria_descricao: Object(response).data.categoria_descricao,
            sla: Object(response).data.sla
          });

          this.is_sintoma = Object(response).data.sintoma;
          this.is_causa = Object(response).data.causa;
          this.is_titulo = Object(response).data.is_titulo;

          if (this.is_titulo) {

            this.formCreateOrEditTipoTarefa.patchValue({
              titulo: Object(response).data.descricao
            });

          }
          this.configService.loaddinStarter('stop');
        }

      );
    }

    this.is_titulo = false

  }

  private getCategoriaByArmazem() {

    //this.consumiveis.categoria_id = null,
    this.produtoCategorias = []
    this.artigos = [];

    this.carregando.categoria = 'Carregando...';
    this.http
      .__call("categoria/by/armazem", {
        armazem_id: this.formCreateOrEditItemPlano.value.armazem_id
      }).subscribe((res: any) => {
        this.produtoCategorias = Object(res).data;
        this.carregando.categoria = 'Seleccione a categoria';
      });
  }

  private getCategoriaByArmazemByTarefa() {

    //this.consumiveis.categoria_id = null,
    this.produtoCategorias = []
    this.artigos = [];

    this.carregando.categoria = 'Carregando...';
    this.http
      .__call("categoria/by/armazem", {
        armazem_id: this.formCreateOrEditTipoTarefa.value.armazem_id
      }).subscribe((res: any) => {
        this.produtoCategorias = Object(res).data;
        this.carregando.categoria = 'Seleccione a categoria';
      });
  }

  private setEquipamento(item) {

    this.formCreateOrEditPlanoManutencao.patchValue({
      equipamento_id: item.id,
      equipamento: item.descricao
    });

    this.view_equipamento = false;

  }

  //SEARCH EQUIPAMENTO
  view_equipamento = false;

  private searchEquipamento() {

    if (this.formCreateOrEditPlanoManutencao.value.equipamento == "" || this.formCreateOrEditPlanoManutencao.value.equipamento == null) {
      this.formCreateOrEditPlanoManutencao.value.equipamento_id = null;
      this.formCreateOrEditPlanoManutencao.value.equipamento = null;
      this.view_equipamento = false;
    }

    this.equipamentos = [];

    this.view_equipamento = true;
    this.http.__call('ordemservico/equipamento/search-equipamento', { start: 1, end: 500, search: this.formCreateOrEditPlanoManutencao.value.equipamento }).subscribe(
      response => {

        this.equipamentos = Object(response).data.data;

      }
    );
  }

  private setArtigo(item) {

    this.formCreateOrEditItemPlano.patchValue({
      artigo_id: item.id,
      artigo: item.descricao
    });

    this.view_artigo = false;
  }


  private setArtigoByTarefa(item) {
    this.formCreateOrEditTipoTarefa.patchValue({})
    this.view_artigo = false;
  }

  //SEARCH EQUIPAMENTO
  view_artigo = false;

  /*  private searchArtigo() {

     if (this.formCreateOrEditItemPlano.value.artigo == "" || this.formCreateOrEditItemPlano.value.artigo == null) {
       this.formCreateOrEditItemPlano.value.artigo_id = null;
       this.formCreateOrEditItemPlano.value.artigo = null;
       this.view_artigo = false;
     }

     this.artigos = [];

     this.view_artigo = true;
     this.http.__call('manutencao-preventiva/item-plano/search-artigo', { start: 1, end: 500, search: this.formCreateOrEditItemPlano.value.artigo }).subscribe(
       response => {

         this.artigos = Object(response).data.data;

       }
     );
   }
  */

  private searchArtigo() {

    this.artigos = [];

    this.formCreateOrEditItemPlano.value.artigo_id = null;

    if (this.formCreateOrEditItemPlano.value.categoria_id == "" || this.formCreateOrEditItemPlano.value.categoria_id == null) {
      this.formCreateOrEditItemPlano.value.artigo = null;
      this.view_artigo = false;
    }

    this.view_artigo = true;
    this.http.__call('search-material/by/categoria-armazem', {
      start: 1,
      end: 500,
      armazem_id: this.formCreateOrEditItemPlano.value.armazem_id,
      categoria_id: this.formCreateOrEditItemPlano.value.categoria_id,
      search: this.formCreateOrEditItemPlano.value.artigo
    }).subscribe(
      response => {

        this.artigos = Object(response).data.data;

      }
    );
  }



  private searchArtigoByTarefa() {

    this.artigos = [];

    this.formCreateOrEditTipoTarefa.value.artigo_id = null;

    if (this.formCreateOrEditTipoTarefa.value.categoria_id == "" || this.formCreateOrEditTipoTarefa.value.categoria_id == null) {
      this.formCreateOrEditTipoTarefa.value.artigo = null;
      this.view_artigo = false;
    }

    this.view_artigo = true;
    this.http.__call('search-material/by/categoria-armazem', {
      start: 1,
      end: 500,
      armazem_id: this.formCreateOrEditTipoTarefa.value.armazem_id,
      categoria_id: this.formCreateOrEditTipoTarefa.value.categoria_id,
      search: this.formCreateOrEditTipoTarefa.value.artigo
    }).subscribe(
      response => {
        this.artigos = Object(response).data.data;
      }
    );
  }

  onSubmit() {

    this.submitted = true;

    // parar aquei se o formulário for inválido
    if (this.formCreateOrEditPlanoManutencao.invalid) {
      return;
    }
    this.loading = true;
    const index = this.formCreateOrEditPlanoManutencao.getRawValue().index;
    // TODO: usado para fazer a requisição com a api de criação de objsct or update
    const uri = (index === null ? 'manutencao-preventiva/plano-manutencao/register' : 'manutencao-preventiva/plano-manutencao/update/' + index);
    this.createOrEdit(uri, this.formCreateOrEditPlanoManutencao, (index === null ? true : false));

  }

  createOrEdit(uri: any, formulario: FormGroup, isCreate: boolean) {

    // TODO: usado para fazer a requisição com a api de criação de object
    this.http.__call(uri, formulario.value).pipe(first()).subscribe(
      response => {
        this.submitted = false;
        this.loading = false;
        if (isCreate && Object(response).code == 200) {
          formulario.reset();
        }

        if (Object(response).code == 200) {
          this.loadList_Departamento(Object(response).data);
        }
      },
      error => {
        this.submitted = false;
        this.loading = false;
      });
  }


  //RULES ABOUT FUNÇÃO

  onSubmitFormItem() {

    this.submitted = true;

    // parar aquei se o formulário for inválido
    if (this.formCreateOrEditItemPlano.invalid) {
      return;
    }
    this.loading = true;
    const index = this.formCreateOrEditItemPlano.getRawValue().index;
    // TODO: usado para fazer a requisição com a api de criação de objsct or update
    const uri = (index === null ? 'manutencao-preventiva/ferramenta-tarefa/register/' + this.tarefa.id : 'manutencao-preventiva/ferramenta-tarefa/update/' + index);
    this.createOrEditFuncao(uri, this.formCreateOrEditItemPlano, (index === null ? true : false));

  }

  createOrEditFuncao(uri: any, formulario: FormGroup, isCreate: boolean) {

    // TODO: usado para fazer a requisição com a api de criação de object
    this.http.__call(uri, formulario.value).pipe(first()).subscribe(
      response => {
        this.submitted = false;
        this.loading = false;
        if (isCreate && Object(response).code == 200) {
          this.getItensByTarefas(this.tarefa.id);
          formulario.reset();
        }

        if (Object(response).code == 200) {
          this.formCreateOrEditItemPlano.value.categoria_id = null;
          this.getItensByTarefas(this.tarefa.id);
        }
      });
  }

  onSubmitFormTarefa() {

    this.submitted = true;

    // parar aquei se o formulário for inválido
    if (this.formCreateOrEditTipoTarefa.invalid) {
      return;
    }
    this.loading = true;
    const index = this.formCreateOrEditTipoTarefa.getRawValue().index;
    // TODO: usado para fazer a requisição com a api de criação de objsct or update
    const uri = (index === null ? 'manutencao-preventiva/tipo-tarefas/register/' + this.plano.id : 'manutencao-preventiva/tipo-tarefaso/update/' + index);
    this.createOrEditTarefa(uri, this.formCreateOrEditTipoTarefa, (index === null ? true : false));

  }

  createOrEditTarefa(uri: any, formulario: FormGroup, isCreate: boolean) {

    // TODO: usado para fazer a requisição com a api de criação de object
    this.http.__call(uri, formulario.value).pipe(first()).subscribe(
      response => {
        this.submitted = false;
        this.loading = false;
        if (isCreate && Object(response).code == 200) {
          formulario.reset();
        }

        if (Object(response).code == 200) {
          this.formCreateOrEditTipoTarefa.value.categoria_id = null;
          this.getTarefasByPlano();
        }
      });
  }


  onSubmitFormContador() {

    this.submitted = true;

    // parar aquei se o formulário for inválido
    if (this.formCreateContador.invalid) {
      return;
    }
    this.loading = true;
    const index = this.formCreateContador.getRawValue().index;

    this.formCreateContador.patchValue({
      plano_manutencao_id: this.plano.id
    });

    // TODO: usado para fazer a requisição com a api de criação de objsct or update
    const uri = (index === null ? 'manutencao-preventiva/contador-equipamento/register/' + this.plano.equipamento_id : 'manutencao-preventiva/contador-equipamento/update/' + index);
    this.createContador(uri, this.formCreateContador, (index === null ? true : false));

  }

  createContador(uri: any, formulario: FormGroup, isCreate: boolean) {

    // TODO: usado para fazer a requisição com a api de criação de object
    this.http.__call(uri, formulario.value).pipe(first()).subscribe(
      response => {
        this.submitted = false;
        this.loading = false;
        if (isCreate && Object(response).code == 200) {
          formulario.reset();
        }

        if (Object(response).code == 200) {
          this.getContadorByPlano();
          this.listar()
        }
      },
      error => {
        this.submitted = false;
        this.loading = false;
      });
  }

  onSubmitEditarPeriodicidade() {

    this.submitted = true;

    // parar aquei se o formulário for inválido
    if (this.formEditarPeriodicidade.invalid) {
      return;
    }
    this.loading = true;
    const index = this.formEditarPeriodicidade.getRawValue().index;

    // TODO: usado para fazer a requisição com a api de criação de objsct or update
    const uri = 'manutencao-preventiva/periodicidade/update/' + this.tarefa.id;
    this.editarPeriodicidade(uri, this.formEditarPeriodicidade, (index === null ? true : false));

  }

  editarPeriodicidade(uri: any, formulario: FormGroup, isCreate: boolean) {

    // TODO: usado para fazer a requisição com a api de criação de object
    this.http.__call(uri, formulario.value).pipe(first()).subscribe(
      response => {
        this.submitted = false;
        this.loading = false;
        if (isCreate && Object(response).code == 200) {
          //formulario.reset();
        }

        if (Object(response).code == 200) {
          this.getTarefasByPlano()
        }
      },
      error => {
        this.submitted = false;
        this.loading = false;
      });
  }

  private listar() {

    this.loading = true
    this.http.__call('manutencao-preventiva/plano-manutencao/listagem', this.filters).subscribe(
      response => {
        this.filters.pagination.lastPage = Object(response).data.lastPage;
        this.filters.pagination.page = Object(response).data.page;
        this.filters.pagination.total = Object(response).data.total;
        this.filters.pagination.perPage = Object(response).data.perPage;

        this.loadList_Departamento(Object(response).data.data);

        this.loading = false
      }
    );
  }

  private confirmar(item) {

    Swal.fire({
      html: `
      <h3><b>Pretende eliminar este item?</b></h3>
      CÓDIGO: `+ item.produtoCodigo + `
   `,
      //title: '<strong></strong>',
      text: "Você não poderá reverter isso!",
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      confirmButtonColor: '#d33'

    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.value) {
        this.eliminarItem(item.id)

      } else if (!result.value) {
        Swal.fire('', '', 'info')
      }
    })

  }

  private deleteTarefa(item) {

    Swal.fire({
      html: `
      <h3><b>Pretende eliminar esta tarefa?</b></h3>
      DESCRIÇÃO: `+ item.tipoDescricao + `
   `,
      //title: '<strong></strong>',
      text: "Você não poderá reverter isso!",
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      confirmButtonColor: '#d33'

    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.value) {
        this.eliminarTarefa(item.id)

      } else if (!result.value) {
        Swal.fire('', '', 'info')
      }
    })

  }

  private eliminarItem(id) {

    this.http.__call('manutencao-preventiva/ferramenta-tarefa/eliminar/' + id, null).subscribe(
      response => {
        if (Object(response).code == 200) {
          Swal.fire('Sucesso!', '', 'success')
          this.getItensByTarefas(this.tarefa.id);
        } else {

          Swal.fire(Object(response).message, '', 'info')
          this.getItensByTarefas(this.tarefa.id);
        }
      }
    );
  }

  private eliminarTarefa(id) {

    this.http.__call('manutencao-preventiva/tipo-tarefa/eliminar/' + id, null).subscribe(
      response => {
        if (Object(response).code == 200) {
          Swal.fire('Sucesso!', '', 'success')
          this.getTarefasByPlano();
        } else {

          Swal.fire(Object(response).message, '', 'info')
          this.getTarefasByPlano();
        }
      }
    );
  }

  private getItensByTarefas(item) {

    this.tarefa.id = item.id == undefined ? item : item.id;
    this.tarefa.descricao = item.tipoDescricao;

    this.configService.loaddinStarter('start');
    this.http.call_get('manutencao-preventiva/ferramentaByTarefa/' + this.tarefa.id, null).subscribe(
      response => {
        this.itens_planos.itens_planos = Object(response).data;
        this.configService.loaddinStarter('stop');
      }
    );
  }

  private getInfo(item) {

    this.tarefa.id = item.id == undefined ? item : item.id;
    this.tarefa.descricao = item.tipoDescricao;
    this.formEditarPeriodicidade.patchValue({
      periodicidade: item.periodicidade
    });

  }
  private getTarefasByPlano() {
    this.configService.loaddinStarter('start');
    this.http.call_get('manutencao-preventiva/tipo-tarefas/tarefaByPlanoManutencao/' + this.plano.id, null).subscribe(
      response => {
        this.tarefas_planos.tarefas_planos = Object(response).data;
        this.configService.loaddinStarter('stop');
      }
    );
  }

  private getContadorByPlano() {
    this.configService.loaddinStarter('start');
    this.http.call_get('manutencao-preventiva/contador-equipamento/contadorByEquipamento/' + this.plano.id, null).subscribe(
      response => {
        this.contadores_equipamento.contadores_equipamento = Object(response).data;
        this.configService.loaddinStarter('stop');
      }
    );
  }

  private getPeriodicidadeDias() {
    this.configService.loaddinStarter('start');
    this.http.call_get('manutencao-preventiva/periodicidade-dias/selectBox/', null).subscribe(
      response => {
        this.periodicidadeDias = Object(response);
        this.configService.loaddinStarter('stop');
      }
    );
  }

  private getDuracaoHoras() {
    this.configService.loaddinStarter('start');
    this.http.call_get('manutencao-preventiva/duracao-horas/selectBox/', null).subscribe(
      response => {
        this.duracoaHoras = Object(response);
        this.configService.loaddinStarter('stop');
      }
    );
  }

  private setDataFuncao(item) {
    this.funcao.id = item.id;
    this.funcao.descricao = item.descricao;

    this.change(this.funcao.descricao)
  }

  private setDataFuncaoUndefined(item) {
    this.funcao.descricao = item;
    this.change(this.funcao.descricao)
  }
  //END

  change(descricao) {
    if (descricao !== undefined) {
      this.title = "Editar";

      this.formCreateOrEditItemPlano.patchValue({
        index: this.funcao.id,
        descricao: this.funcao.descricao
      });
    } else {
      this.onReset()
      this.title = "Registar";
    }

  }


  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {

    if (this.planos_manutencao !== undefined) {

      this.title = "Editar";
      //this.onReset()

      this.formCreateOrEditPlanoManutencao.patchValue({
        index: this.planos_manutencao.id,
        descricao: this.planos_manutencao.descricao
      });

    } else {
      this.onReset()
      this.title = "Registar";

    }
  }

  public loadList_Departamento(planos_manutencao) {
    this.loadList.emit(planos_manutencao);
  }


}
