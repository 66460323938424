import { Component, OnInit, ViewChild } from "@angular/core";
import { ConfigService } from "src/app/providers/config/config.service";
import * as moment from "moment";
import { AuthService } from "src/app/providers/auth/auth.service";
import Swal from "sweetalert2";
import { finalize } from "rxjs/operators";
import { ApiService } from "src/app/providers/http/api.service";
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from "src/app/providers/http/http.service";

@Component({
  selector: 'app-facturacao-caucao',
  templateUrl: './facturacao-caucao.component.html',
  styleUrls: ['./facturacao-caucao.component.css']
})
export class FacturacaoCaucaoComponent implements OnInit {

  @ViewChild("search") search;
  @ViewChild("qtd") qtd;

  @ViewChild("valor") valor;
  @ViewChild("serie") serie;
  @ViewChild("observacao") observacao;
  @ViewChild("leitura") leitura;
  @ViewChild('closebutton') closebutton;


  private desconto: number = 0;
  private is_facturar_caucao: boolean = false;

  private dataActual = new Date();
  private totalFacturacao = 0;
  private totalSemImposto = 0;
  private totalComImposto = 0;
  private produts: any = [];
  private clientes: any = [];
  private quantidade = 1;
  private produtoId: number;
  private clienteId: number;
  private serieId: number;
  private formasPagamentos: any = [];
  private validarFacturaRecibo: boolean = false;
  facturar_sem_contrato: boolean = false;
  private clienteOrc: any
  private contas_clientes: any = [];
  private contratos_conta_cliente: any = [];

  private numero_telefone: string;
  private searchCliente: string;

  private isFinalizar: boolean = true;

  private default: any = [];

  public currentUser: any;
  tarifario_descricao = "";

  private facturacao = {
    cliente: {
      id: null,
      nome: null,
      contribuinte: null,
      morada: null,
      telefone: null,
      conta_id: null,
      contrato_id: null,
    },
    serie: {
      id: null,
      sigla: null,
      nome: null,
      proximo_numero: null,
    },
    produto: [],
    pagamento: {
      forma: null,
      valor_recebido: 0.0,
      total_valor_recebido: 0.0,
      troco: 0.0,
      total_pago: null,
      referencia: null,
      data_pagamento: null,
      forma_pagamento_id: null,
      bancos: [],
      adiantamentos: [],
      is_adiantamento: 1,
      linha_pagamentos: [],
    },
    totalComImposto: 0.0,
    totalSemImposto: 0.0,
    total: 0.0,
    success_facturacao: false,
    facturaGerada: null,
    data_vencimento: moment(Date()).format("YYYY-MM-DD").toString(),
    moeda: null,
  };

  private disabledPagamento = true;
  private disabledPagamentoAdiantamento = true;

  private series: any = [];
  routerParamId: any;
  cliente_id: any;
  contrato_id: any;
  private cliente_nome: any;

  private activeButtom = {
    cliente: false,
    produto: false,
  };
  private municipio_id: "T"
  private items_produtos: any = [];
  private pagination = {
    start: 1,
    end: 10,
    search: null,
    contrato: null,
    municipio_id: "T"
  };
  public sub = null
  public id_orcamento: any = 0
  private produto_selecionado = null;
  private preco: number;
  private validateButton: any = null;
  private municipios: []
  constructor(
    private auth: AuthService,
    private http: HttpService,
    private configService: ConfigService,
    private api: ApiService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.currentUser = this.auth.currentUserValue;
    /*
    this.route.queryParams.subscribe(params => {
      this.id_orcamento = +params.orcamento_id;
      console.log( this.id_orcamento)
    });*/
  }

  ngOnInit() {
    this.getMunicipios()
    this.ListarClientes(1, 10, null, this.municipio_id);
    this.listarseries();
    this.configService.listarBancos();
    this.configService.listarMoedas();
    this.listarFormaPagamentos();
    this.getServicos(" ");
    this.getRouterParamId();
    //this.getFaturarPorOrcamento();

  }

  public getRouterParamId() {

    this.routerParamId = this.route.snapshot.params.id;

    this.cliente_id = this.route.snapshot.params.cliente_id;
    this.contrato_id = this.route.snapshot.params.contrato_id;
    this.getDetailsCliente();
    if (this.routerParamId != undefined) {
      this._facturarComContrato();
      this.selectedClienteComConta(0);
      this.getOrdemById(this.routerParamId)
    }

  }
  /*
  public getFaturarPorOrcamento(){
      if(this.id_orcamento){
        this.http.call_get('facturas/' + this.id_orcamento, null).subscribe(
          response => {
            this.clienteOrc = Object(response).data
            console.log( this.clienteOrc)
          this.facturacao.cliente.id =  Object(response).data.id;
          this.facturacao.cliente.nome =  Object(response).data.cliente.nome;
          this.facturacao.cliente.contribuinte =
          Object(response).data.numero_identificacao ;
          this.facturacao.cliente.telefone = Object(response).data.telefone;
          this.facturacao.cliente.morada = Object(response).data.morada;
          this.activeButtom.cliente = true;

          this.facturar_sem_contrato = true;
          this.contrato = null;
          this.facturacao.cliente.contrato_id = null;
          })
          this.getContas();

      }else {
        console.log("Faturar Sem Orçamento")
      }
  }*/




  private getOrdemById(id) {
    this.http.call_get('ordemservico/get-info-ordem-by-id/' + id, null).subscribe(
      response => {

        if (Object(response).data == undefined) {
          this.router.navigate(['/ordemservico/listagem']);
        }

        this.facturacao.cliente.contrato_id = Object(response).data.contrato_id;
        this.facturacao.cliente.conta_id = Object(response).data.conta_id;
        this.facturacao.cliente.id = Object(response).data.cliente_id;
        this.facturacao.cliente.nome = Object(response).data.cliente_nome;
        this.facturacao.cliente.contribuinte = Object(response).data.cliente_nif;
        this.facturacao.cliente.telefone = Object(response).data.cliente_telefone;
        this.facturacao.cliente.morada = Object(response).data.cliente_morada;
        this.activeButtom.cliente = true;
        this.getContas();
        this.getContaContrato();
        this.selectedCliente(this.facturacao.cliente.id)
        this.contaServicoSeleciona(this.facturacao.cliente.contrato_id);

      }
    );
  }

  private ListarClientes(start, end, searchCliente, municipio_id) {
    this.configService.loaddinStarter("start");
    this.pagination.start = start;
    this.pagination.end = end;
    this.pagination.search = searchCliente;
    this.pagination.municipio_id = municipio_id;

    this.http
      .__call("cliente/search-cliente", this.pagination)
      .subscribe((res) => {
        this.clientes = Object(res).data.data;
        this.configService.loaddinStarter("stop");
      });
  }
  private getCliente(e) {
    this.searchCliente;
    this.ListarClientes(1, 10, this.searchCliente, this.municipio_id);
  }

  getProduto(search) {
    this.getServicos(search);
  }

  private listarProdutos(response: any) {
    /* if (response.total == 0 || response.total == null) {
      this.configService.showAlert("Não foi informado nenhum preço no produto", "alert-danger", true);
      return;
    } */
    this.facturacao.total = 0;
    let validar = 0;
    if (response.code == 400) {
      this.configService.showAlert(response.message, "alert-danger", true);
    } else {
      if (this.facturacao.produto.length >= 1) {
        if (response.valor_original) {
          for (let index = 0; index < this.facturacao.produto.length; index++) {
            const produt = this.facturacao.produto[index];
            if (produt.produto_id == response.produto_id) {
              this.facturacao.produto.splice(index, 1);
              this.facturacao.produto.splice(index, 0, response);
              validar = 1;
            }
          }
        }
        if (validar == 0) {
          this.facturacao.produto.push(response);
        }
      } else {
        this.facturacao.produto.push(response);
      }
      this.calcularTotal();
    }
  }

  private getMunicipios() {
    this.api.get('municipio/selectBox').subscribe(
      response => {
        this.municipios = Object(response).data
      }
    )
  }
  private calcularTotal() {
    this.facturacao.total = 0;
    this.facturacao.totalSemImposto = 0;
    this.facturacao.totalComImposto = 0;
    //Calcula o Total da Factura
    for (let index = 0; index < this.facturacao.produto.length; index++) {
      this.facturacao.totalSemImposto +=
        this.facturacao.produto[index].linhaTotalSemImposto;
      this.facturacao.totalComImposto +=
        this.facturacao.produto[index].valorImposto;

      this.facturacao.total += this.facturacao.produto[index].total;
    }
    this.facturacao.pagamento.total_pago = this.facturacao.total;

    if (this.facturacao.total >= 0) {
      this.activeButtom.produto = true;
    } else {
      this.activeButtom.produto = false;
    }
  }

  deleteRow(produto: any) {
    for (let i = 0; i < this.facturacao.produto.length; ++i) {
      if (this.facturacao.produto[i].produto_id === produto.produto_id) {
        this.facturacao.produto.splice(i, 1);
        this.calcularTotal();
      }
    }
  }

  private refreshProdutoId(produto: any) {
    this.produtoId = produto.servico_id;
    this.produto_selecionado = produto.servico_valor;
  }

  private refreshSerieId() {
    this.serieId = this.serie.nativeElement.value;
    if (Object(this.serieId) == "") {
      this.serieId = 0;
    }
  }

  private selectedSerie() {
    this.series.forEach((element) => {
      if (this.facturacao.serie.id == element.id) {
        if (element.sigla == "FT") {
          this.configService.listarBancos();
          this.configService.listarMoedas();
        }
        this.facturacao.serie.id = element.id;
        this.facturacao.serie.nome = element.nome;
        this.facturacao.serie.sigla = element.sigla;
        this.facturacao.serie.proximo_numero = element.proximo_numero;
      }
    });
  }

  getDetailsCliente() {
    this.http.call_get('get-detail-cliente/' + this.cliente_id)
      .subscribe(element => {
        this.facturacao.cliente.id = Object(element).data.id;
        this.facturacao.cliente.nome = Object(element).data.nome;
        this.facturacao.cliente.contribuinte = Object(element).data.numero_identificacao || Object(element).data.nif;
        this.facturacao.cliente.telefone = Object(element).data.telefone;
        this.facturacao.cliente.morada = Object(element).data.morada;

        this.activeButtom.cliente = true;
        this.getContas();
      })
  }

  private selectedCliente(id) {
    this.facturacao.cliente.id = id;
    this.clientes.forEach((element) => {
      if (this.facturacao.cliente.id == element.id) {
        this.facturacao.cliente.id = element.id;
        this.facturacao.cliente.nome = element.nome;
        this.facturacao.cliente.contribuinte =
          element.numero_identificacao || element.nif;
        this.facturacao.cliente.telefone = element.telefone;
        this.facturacao.cliente.morada = element.morada;
        this.activeButtom.cliente = true;
      }
    });
    this.getContas();
  }

  private selectedClientePorOrcamento() {
    console.log("Cheguei aqui");
  }

  private getServicos(search) {
    this.is_facturar_caucao = true
    this.items_produtos = [];
    this.configService.loaddinStarter("start");
    this.pagination.search = search == "" || search == null ? "a" : search;
    this.pagination.contrato =
      this.facturacao.cliente.contrato_id == "" ||
        this.facturacao.cliente.contrato_id == null
        ? null
        : this.facturacao.cliente.contrato_id;

    this.http
      .__call("artigo/search", {
        pagination: this.pagination,
        search: search,
        is_facturar_caucao: this.is_facturar_caucao
      })
      .subscribe((res) => {
        this.items_produtos = Object(res);
        this.configService.loaddinStarter("stop");
      });
  }

  private updateBancList(banco: any) {
    this.facturacao.pagamento.bancos.push(banco);
  }

  getAddProduto() {
    if (this.quantidade <= 0 || isNaN(this.quantidade)) {
      this.configService.showAlert(
        "Não foi informado uma quantidade valida",
        "alert-danger",
        true
      );
      return;
    }
    if (this.http.canActivateRouterLink("atribuir_desconto")) {
      if (!(this.desconto <= 100 && this.desconto >= 0)) {
        this.configService.showAlert(
          "Desconto valido, informe um valor no intervalo entre 0 e 100%",
          "alert-danger",
          true
        );
        return;
      }
    }
    this.configService.loaddinStarter("start");
    this.http
      .__call("artigo/pesquisar", {
        produto_id: this.produtoId,
        quantidade:
          this.quantidade < 0 || isNaN(this.quantidade) ? 1 : this.quantidade,
        desconto: this.desconto,
        cliente_id: this.clienteId,
        observacao: null,
        preco: this.preco,
      })
      .subscribe((res) => {
        let service = Object(res).data

        this.listarProdutos(service);
        if (this.produto_selecionado == 0) {
          //this.getServicos(this.search.nativeElement.value);
        }
        this.quantidade = 1;
        this.desconto = 0;
        this.configService.loaddinStarter("stop");
      });
  }

  private facturar() {
    if (this.facturacao.cliente.id == null) {
      this.configService.showAlert(
        "É obrigatório fornecer um cliente",
        "alert-danger",
        true
      );
    } else if (this.facturacao.serie.id == null) {
      this.configService.showAlert(
        "É obrigatório fornecer uma serie",
        "alert-danger",
        true
      );
    } else if (this.facturacao.produto.length == 0) {
      this.configService.showAlert(
        "É obrigatório fornecer produtos para a factura",
        "alert-danger",
        true
      );
    } else {
      if (this.facturacao.serie.sigla == "FR") {

        let total_linhas_pagamentos = this.facturacao.pagamento.linha_pagamentos.reduce((accum, item) => accum + item.valor_recebido, 0)

        console.log(this.facturacao.total)

        if (total_linhas_pagamentos < this.facturacao.total) {
          this.configService.showAlert(
            "O valor do pagamento têm que ser maior ou igual ao valor total da factura",
            "alert-danger",
            true
          )

          return
        }

        if (this.facturacao.pagamento.linha_pagamentos.length == 0) {
          this.configService.showAlert(
            "É obrigatório fornecer uma forma de pagamento",
            "alert-danger",
            true
          );
        } else if (
          this.facturacao.pagamento.total_valor_recebido <
          this.facturacao.total ||
          this.facturacao.pagamento.total_valor_recebido == 0
        ) {
          this.configService.showAlert(
            "Informa um valor de entrada superir ou igual ao valor total a pagar",
            "alert-danger",
            true
          );
        } else {
          this.guardarFactura();
        }
      } else {
        this.guardarFactura();
      }
    }
  }

  isPagamento() {
    this.disabledPagamentoAdiantamento = false;
    this.facturacao.pagamento.valor_recebido = this.facturacao.total;
    this.facturacao.pagamento.referencia = null;
    this.facturacao.pagamento.data_pagamento = null;

    if (this.facturacao.serie.sigla == "FR") {
      var a: string = null;
      this.formasPagamentos.forEach((element) => {
        if (this.facturacao.pagamento.forma_pagamento_id == element.id) {
          if (
            element.designacao === "Adiantamento" ||
            element.designacao === "adiantamento"
          ) {
            a = element.designacao;
          }
        }
      });

      if (
        this.facturacao.pagamento.forma_pagamento_id != 1 &&
        this.facturacao.pagamento.referencia == null
      ) {
        if (a !== "Adiantamento") {
          this.disabledPagamento = false;
        } else {
          this.disabledPagamento = true;
          this.disabledPagamentoAdiantamento = true;
          this.facturacao.pagamento.is_adiantamento = 1;
        }
      } else {
        this.disabledPagamento = true;
      }
    }
  }

  private modal(id) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        html: `
              <h1><b>Deseja Continuar sem contrato</b></h1>
              <button awNextStep>continuar</button>
           `,
        title: "<strong></strong>",
        text: "Você não poderá reverter isso!",
        type: "error",
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: "Continuar",
      })
      .then((result) => {
        if (result.value) {
          swalWithBootstrapButtons.fire(
            "Deleted!",
            "Your file has been deleted.",
            "success"
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "Your imaginary file is safe :)",
            "error"
          );
        }
      });
  }

  private guardarFactura() {
    this.isFinalizar = false;
    this.configService.loaddinStarter("start");
    this.closebutton.nativeElement.disabled = true

    if (this.facturar_sem_contrato) {
      this.facturacao.cliente.contrato_id = null;
      this.contrato = null;
    }

    let dataActual = moment(Date()).format("YYYY-MM-DD");

    if (this.facturacao.data_vencimento < dataActual) {
      this.configService.showAlert(
        "Data de Vencimento não pode ser inferior que a Data Actual",
        "alert-danger",
        true
      );
      this.configService.loaddinStarter("stop");
      this.isFinalizar = true;
    } else {
      const _validarAd = this.facturacao.pagamento.linha_pagamentos.find(
        (l) => l.designacao === "Adiantamento"
      );
      this.facturacao.pagamento.adiantamentos =
        _validarAd == null ? [] : this.facturacao.pagamento.adiantamentos;

      let data = {
        is_caucao: true,
        produtos: this.facturacao.produto,
        documento: "Factura",
        cliente_id: this.facturacao.cliente.id,
        total: this.facturacao.total,
        serie_id: this.facturacao.serie.id,
        totalComImposto: this.facturacao.totalComImposto,
        totalSemImposto: this.facturacao.totalSemImposto,
        observacao: this.observacao.nativeElement.value,
        leitura: this.leitura.nativeElement.value,
        numero_origem_factura: null,
        data_origem_factura: null,
        data_vencimento: this.facturacao.data_vencimento,
        pagamento: this.facturacao.pagamento,
        moeda: this.facturacao.moeda,
        valor_aberto: this.facturacao.total,
        conta_id: this.facturacao.cliente.conta_id,
        ordem_servico_id: this.routerParamId,
        contrato_id: this.contrato_id,
      };

      this.http
        .__call("factura/create", data)
        .pipe(
          finalize(() => {
            this.configService.loaddinStarter("stop");
            this.validateButton = 200;
          })
        )
        .subscribe(
          (res) => {
            this.iniFacturacao();
            this.facturacao.success_facturacao = true;
            this.facturacao.facturaGerada = Object(res).data.id;
            this.isFinalizar = true;
          },
          (error) => {
            this.configService.loaddinStarter("stop");
            this.isFinalizar = true;
          }
        );
    }
  }

  private listarseries() {
    this.is_facturar_caucao = true;
    this.http
      .call_get(`serie/loja/selectSerieLojaFacturaBox?is_facturar_caucao=${this.is_facturar_caucao}`, null)
      .subscribe((data) => {
        let serie = Object(data).data.find((obj) => obj.sigla == "FT" || obj.sigla == "FR");

        this.facturacao.serie.id = serie.id;
        this.facturacao.serie = serie;

        this.series = Object(data).data;
        if (this.series.length == 1) {
          this.series.forEach((element) => {
            if (element.sigla == "FT") {
              this.configService.listarBancos();
              this.configService.listarMoedas();
            }

            this.facturacao.serie.id = element.id;
            this.facturacao.serie.nome = element.nome;
            this.facturacao.serie.sigla = element.sigla;
            this.facturacao.serie.proximo_numero = element.proximo_numero;
          });
        }
      });
  }

  private listarFormaPagamentos() {
    this.configService.loaddinStarter("start");
    this.http.call_get("formaPagamento/formas", null).subscribe((response) => {
      this.formasPagamentos = Object(response).data;
      this.configService.loaddinStarter("stop");
    });
  }

  private iniFacturacao() {
    this.facturacao = {
      cliente: {
        id: null,
        nome: null,
        contribuinte: null,
        morada: null,
        telefone: null,
        conta_id: null,
        contrato_id: null,
      },
      serie: {
        id: null,
        sigla: null,
        nome: null,
        proximo_numero: null,
      },
      produto: [],
      pagamento: {
        forma: null,
        valor_recebido: 0.0,
        total_valor_recebido: 0.0,
        troco: 0.0,
        total_pago: null,
        referencia: null,
        data_pagamento: null,
        forma_pagamento_id: null,
        bancos: null,
        adiantamentos: null,
        is_adiantamento: 0,
        linha_pagamentos: [],
      },
      totalComImposto: 0.0,
      totalSemImposto: 0.0,
      total: 0.0,
      success_facturacao: false,
      facturaGerada: null,
      data_vencimento: moment(Date()).format("YYYY-MM-DD").toString(),
      moeda: null,
    };
  }

  private reloadFacturacao() {
    location.reload();
  }

  private getContas() {
    this.activeButtom.cliente = false;
    this.http
      .call_get("cliente/conta/selectBox/" + this.facturacao.cliente.id, null)
      .subscribe((response) => {
        this.contas_clientes = Object(response).data;

        if (this.contas_clientes.length == 1) {
          this.default = this.contas_clientes[0].numero_conta;
          this.facturacao.cliente.conta_id = this.default;
        }

        this.getContaContrato();
      });
  }

  private getContaContrato() {
    this.activeButtom.cliente = false;
    this.configService.loaddinStarter("start");
    this.http
      .call_get("conta/contratos/" + this.facturacao.cliente.conta_id, null)
      .subscribe((response) => {
        this.contratos_conta_cliente = Object(response).data;

        if (this.contratos_conta_cliente.length == 1) {
          this.default = this.contratos_conta_cliente[0].id;
          this.facturacao.cliente.contrato_id = this.default;
        }

        this.configService.loaddinStarter("stop");
      });
  }

  confirmarConta() {
    this.activeButtom.cliente = false;
    if (
      this.facturacao.cliente.conta_id == null ||
      this.facturacao.cliente.contrato_id == null
    ) {
      this.configService.showAlert(
        "A conta e serviço são obrigatorio",
        "alert-danger",
        true
      );
    } else {
      this.activeButtom.cliente = true;
    }
  }

  private contrato = null; // conta a ser visualizada no ecra das operaçoes

  private contaServicoSeleciona(contrato_id) {
    this.contrato = this.contratos_conta_cliente.find(
      (element) => element.id == contrato_id
    );
  }

  private clienteSemOrComConta = 0;

  selectedClienteComConta(n: number) {
    this.clienteSemOrComConta = n;
    this.listarseries();
    this.listarFormaPagamentos();
    //this.getServicos(null);
    this.getMovimentoAdiantamento();
    this.facturacao.produto = [];
  }

  selectedClienteSemConta(n: number) {
    this.clienteSemOrComConta = n;
    this.listarseries();
    this.listarFormaPagamentos();
    this.facturacao.produto = [];
    this.contrato = null;
    //this.contrato = null
    this.facturacao.cliente.conta_id = null;
    this.facturacao.cliente.contrato_id = null;
    this.getMovimentoAdiantamento();
  }

  //================================================================

  _facturarComContrato() {
    this.facturar_sem_contrato = false;
  }

  set_contrato_null() {
    this.facturar_sem_contrato = true;
    this.contrato = null;
    this.facturacao.cliente.contrato_id = null;
  }

  cancelarConta() {
    this.activeButtom.cliente = false;
    this.facturacao.cliente.conta_id = null;
    this.facturacao.cliente.contrato_id = null;
    this.facturacao.cliente.id = null;
    this.contrato = null;
  }

  /*
  private numeroTelefone() {
    this.configService.loaddinStarter("start");
    this.http
      .__call("cliente/searchClienteTelefone", {
        chaveServico: this.numero_telefone,
      })
      .subscribe((res) => {
        if (Object(res).code == 500) {
          this.configService.showAlert(
            Object(res).message,
            "alert-danger",
            true
          );
          this.searchCliente = "";
          this.clientes = [];
        } else {
          Object(res).data;
          this.searchCliente = Object(res).data.nome;
          this.ListarClientes(1, 10, Object(res).data.id);
        }
        this.numero_telefone = "";
        this.configService.loaddinStarter("stop");
      });
  }
  */

  private addLinhaPagamentos() {
    var validar = 0;

    if (this.facturacao.serie.sigla == "FR") {
      var a: string = null;
      this.formasPagamentos.forEach((element) => {
        if (this.facturacao.pagamento.forma_pagamento_id == element.id) {
          if (
            element.designacao === "Adiantamento" ||
            element.designacao === "adiantamento"
          ) {
            a = element.designacao;
            this.facturacao.pagamento.referencia = "000000";
            this.facturacao.pagamento.data_pagamento = moment(
              new Date()
            ).format("YYYY-MM-DD");
          }
        }
      });

      if (this.facturacao.pagamento.valor_recebido <= 0) {
        this.configService.showAlert(
          "Informa um valor de superior a zero",
          "alert-danger",
          true
        );
      } else if (
        this.facturacao.pagamento.forma_pagamento_id == null ||
        this.facturacao.pagamento.forma_pagamento_id == "null"
      ) {
        this.configService.showAlert(
          "Informa uma forma pagamento valida",
          "alert-danger",
          true
        );
      } else if (
        this.facturacao.pagamento.forma_pagamento_id != 1 &&
        this.facturacao.pagamento.referencia == null
      ) {
        if (a !== "Adiantamento") {
          this.configService.showAlert(
            "Referencia a bancaria",
            "alert-danger",
            true
          );
        }
      } else if (
        this.facturacao.pagamento.forma_pagamento_id != 1 &&
        this.facturacao.pagamento.data_pagamento == null
      ) {
        if (a !== "Adiantamento") {
          this.configService.showAlert(
            "Informa a data de pagamento valida!",
            "alert-danger",
            true
          );
        }
      } else {
        this.formasPagamentos.forEach((element) => {
          if (this.facturacao.pagamento.forma_pagamento_id == element.id) {
            this.facturacao.pagamento.forma = element.designacao;
          }
        });

        var linha = {
          designacao: this.facturacao.pagamento.forma,
          valor_recebido: this.facturacao.pagamento.valor_recebido,
          data_pagamento: this.facturacao.pagamento.data_pagamento,
          forma_pagamento_id: this.facturacao.pagamento.forma_pagamento_id,
          referencia: this.facturacao.pagamento.referencia,
        };

        if (this.facturacao.pagamento.linha_pagamentos.length >= 1) {
          for (
            let index = 0;
            index < this.facturacao.pagamento.linha_pagamentos.length;
            index++
          ) {
            const l = this.facturacao.pagamento.linha_pagamentos[index];
            if (l.forma_pagamento_id == linha.forma_pagamento_id) {
              this.facturacao.pagamento.linha_pagamentos.splice(index, 1);
              this.facturacao.pagamento.linha_pagamentos.splice(
                index,
                0,
                linha
              );
              validar = 1;
            }
          }
          if (validar == 0) {
            this.facturacao.pagamento.linha_pagamentos.push(linha);
          }
        } else {
          this.facturacao.pagamento.linha_pagamentos.push(linha);
        }

        this.calcularTroco();
        this.facturacao.pagamento.forma = null;
        this.facturacao.pagamento.valor_recebido = 0.0;
        this.facturacao.pagamento.referencia = null;
        this.facturacao.pagamento.data_pagamento = null;
        this.facturacao.pagamento.forma_pagamento_id = null;
        this.adiantamento == null;
      }
    }
  }

  private calcularTroco() {
    this.facturacao.pagamento.troco = 0;
    var valor = 0;
    this.facturacao.pagamento.linha_pagamentos.forEach((element) => {
      valor += element.valor_recebido;
    });
    if (valor > 0) {
      this.facturacao.pagamento.troco = valor - this.facturacao.total;
      if (valor <= this.facturacao.total) {
        this.facturacao.pagamento.troco = 0;
      }
    } else {
      this.facturacao.pagamento.troco = 0;
    }
    this.facturacao.pagamento.total_valor_recebido = valor;
  }

  deleteRowFormaPagamento(linha: any) {
    for (
      let i = 0;
      i < this.facturacao.pagamento.linha_pagamentos.length;
      ++i
    ) {
      if (
        this.facturacao.pagamento.linha_pagamentos[i].forma_pagamento_id ===
        linha.forma_pagamento_id
      ) {
        if (
          this.facturacao.pagamento.linha_pagamentos[i].designacao !==
          "Adiantamento"
        ) {
          this.facturacao.pagamento.adiantamentos = [];
        }
        this.facturacao.pagamento.linha_pagamentos.splice(i, 1);
        this.facturacao.pagamento.total_valor_recebido -= linha.valor_recebido;
        this.facturacao.pagamento.troco =
          this.facturacao.pagamento.total_valor_recebido <
            this.facturacao.total ||
            this.facturacao.pagamento.total_valor_recebido == 0
            ? 0
            : this.facturacao.pagamento.total_valor_recebido -
            this.facturacao.total;
        this.calcularTotal();
      }
    }
  }

  private listMovimento: any = [];
  private adiantamento: string = null;

  private listMovimentoAdiantamentoCliente() {
    this.adiantamento = null;
    this.formasPagamentos.forEach((element) => {
      if (this.facturacao.pagamento.forma_pagamento_id == element.id) {
        if (
          element.designacao === "Adiantamento" ||
          element.designacao === "adiantamento"
        ) {
          this.adiantamento = element.designacao;
        }
      }
    });
  }

  private getMovimentoAdiantamento() {
    this.facturacao.pagamento.adiantamentos = [];
    this.configService.loaddinStarter("start");
    this.http
      .__call("adiantamento/factura/adiantamentoFactura", {
        cliente_id: this.facturacao.cliente.id,
      })
      .subscribe((response) => {
        this.listMovimento = Object(response).data;
        this.configService.loaddinStarter("stop");
      });
  }

  setAdianta(ad: any) {
    if (this.facturacao.pagamento.adiantamentos.length == 0) {
      this.facturacao.pagamento.adiantamentos.push(ad);
    } else {
      var validar = 0;
      for (let i = 0; i < this.facturacao.pagamento.adiantamentos.length; ++i) {
        if (this.facturacao.pagamento.adiantamentos[i].id === ad.id) {
          this.facturacao.pagamento.adiantamentos.splice(i, 1);
          validar = 1;
        }
      }

      if (validar === 0) {
        this.facturacao.pagamento.adiantamentos.push(ad);
      }
    }
    var valor = 0;
    for (let i = 0; i < this.facturacao.pagamento.adiantamentos.length; ++i) {
      valor += this.facturacao.pagamento.adiantamentos[i].valor;
    }
    this.facturacao.pagamento.valor_recebido = valor;
  }
}
