import { Component, OnInit, Compiler } from '@angular/core';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { ConfigModuloService } from 'src/app/services/config-modulo.service';
import { environment } from 'src/environments/environment';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})

export class AppComponent implements OnInit {
  private app_environment: null;
  private app_version = `3.2.6 (14/10/2022)`
  private currentYear = new Date().getFullYear()

  public screenWidth: any
  title = 'frontend'
  public currentUser: any = null
  current_company: any = {}

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;

  constructor(
    private auth: AuthService,
    private compiler: Compiler,
    public toasterService: ToastrService,
    private idle: Idle,
    private keepalive: Keepalive,
    private config: ConfigModuloService
  ) {

    this.compiler.clearCache();
    idle.setIdle(5);
    idle.setTimeout(5);
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.'
      console.log(this.idleState);
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      //this.childModal.hide();
      this.idleState = 'Timed out!';
      this.timedOut = true;
      console.log(this.idleState);
      //this.router.navigate(['/']);
    });

    idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!'
      console.log(this.idleState);
      //this.childModal.show();
    });

    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!'
      console.log(this.idleState);
    });

    // sets the ping interval to 15 seconds
    keepalive.interval(15);

    keepalive.onPing.subscribe(() => this.lastPing = new Date());

   // if (this.auth.isLogged()) {
      idle.watch()
    this.timedOut = false;
    console.log("watching", this.timedOut);
    /*/} else {
      idle.stop();
    }*/

    this.current_company = Boolean(this.auth.currentUserValue) ? this.auth.currentUserValue.user : null
    this.currentUser = this.auth.currentUserValue;
  }

  ngOnInit() {
    this.app_environment = this.auth.getAppEnvironment();
    if (this.app_environment == null) {
      var url = require('url');
      var app_url = url.parse(environment.app_url, true);
      this.app_environment = app_url.host;
      this.config.saveConfig(app_url.host, this.config.modulo.CONFIGURACOES, app_url.host);
    }

    this.config.initConfiguracaos();
  }


  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

}

