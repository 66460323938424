import { Component, OnInit, ViewChild } from "@angular/core";
import { HttpService } from "../../providers/http/http.service";
import { ConfigService } from "src/app/providers/config/config.service";
import { AuthService } from "src/app/providers/auth/auth.service";
import { ConfigModuloService } from "src/app/services/config-modulo.service";
import { ContratoService } from "src/app/components/reports/contrato/contrato.service";
import * as moment from "moment";
import {
  BsModalService,
  BsModalRef,
  ModalDirective,
  ModalModule,
} from "ngx-bootstrap/modal";
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from "@angular/router";
import { ContratoLundasulService } from "../reports/contrato-lundasul/contrato-lundasul.service";
import { ContratoLundanorteService } from "../reports/contrato-lundanorte/contrato-lundanorte.service";
import { finalize } from "rxjs/operators";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'
import { RotaService } from "../config-rotas/rotas.service";

@Component({
  selector: "app-contrato",
  templateUrl: "./contrato.component.html",
  styleUrls: ["./contrato.component.css"],
})
export class ContratoComponent implements OnInit {
  @ViewChild("search") search;
  @ViewChild("qtd") qtd;
  @ViewChild("valor") valor;
  @ViewChild("serie") serie;
  @ViewChild("observacao") observacao;
  @ViewChild("leitura") leitura;

  @ViewChild("modalAppendServico") modalAppendServico: ModalDirective;
  @ViewChild("confirmFacturacaoModal") confirmFacturacaoModal: ModalDirective;
  @ViewChild("mediaConsumoModal") mediaConsumoModal: ModalDirective;

  @ViewChild("closebutton") closebutton;

  public currentUser: any;
  private dataContratoSaved: any;
  private tarifa_fixa_modal_view: boolean = false;
  

  private cil_disabled_view: boolean = false;
  private contador_title: string = "Associar";
  private existCaucao: boolean = false
  private clientes: any = [];
  private pagadoresAlternativos: any = [];
  private pagador_alternativo: any
  private cliente: any;
  private tipo_contratos: any = [];
  private tipo_medicaos: any = [];
  private niveis_sensibilidade: any = [];
  private tarifarios: any = [];
  private tipologia_clientes: any = [];
  private centro_custo: any = [];
  private objecto_contratos: any = [];
  private motivo_recisaos: any = [];

  private tipo_mensagems: any = [];
  private campo_jardims: any = [];
  private abastecimento_cils: any = [];
  private title_facturacao: any;

  private mensagem_title: string = "Mensagem";
  private servico_modal_title: string = "Associar Serviço";

  private edit_servico_flag: boolean = false;
  private clienteId: number;
  private serieId: number;
  private formasPagamentos: any = [];
  private validarFacturaRecibo: boolean = false;
  private addValorClasse: boolean = true;
  private empresa: any;

  private url_params: any;

  private local_instalacao = {
    id: null,
    moradia_numero: null,
    is_predio: null,
    predio_id: null,
    predio_nome: null,
    latitude: null,
    longitude: null,
    rua_id: null,
    is_active: null,
    user_id: null,
  };

  private contrato_id: any;
  private provincias: any = [];
  private municipios: any[];
  private distritos: any[];
  private quarteiraos: any[];
  private bairros: any[];
  public submitted = false;
  public view_client = true
  private series: any[];
  private servicos: any = [];

  private local_consumos: any[];
  private ruas: any[];
  private contas_clientes: any = [];
  private servicos_conta_cliente: any = [];
  private numero_telefone: string;
  private searchCliente: string;
  private searchPagador: string;
  private isFinalizar: boolean = false;
  private contrato_validated: boolean = false;
  private contrato_edit: boolean = false;

  private dados_validated: boolean = false;

  private contrato = {
    id: null,
    cliente_id: null,
    cliente_nome: null,
    cliente_morada: null,
    cliente_provincia: null,
    cliente_telefone: null,
    enviar_factura: 1,
    cliente_email: null,
    tipo_identidade: null,
    numero_identificacao: null,
    centro_custo_id: null,

    genero: null,
    conta_id: null,
    tipo_contracto_id: null,
    tipo_contrato: null,
    tipo_medicao_id: null,
    tipo_medicao: null,
    tipo_medicao_slug: null,
    media_consumo: 0,
    tipo_facturacao_id: null,
    tipo_facturacao: null,
    tipologia_cliente_id: null,
    tipologia_cliente: null,
    tipologia_cliente_juro_mora: null,
    tipologia_cliente_sujeito_corte: null,
    tipologia_cliente_caucao: null,
    nivel_sensibilidade_id: null,
    nivel_sensibilidade: null,
    objecto_contrato_id: null,
    objecto_contrato: null,
    tarifario: null,
    tarifario_id: null,
    classe_tarifario: null,
    classe_tarifario_consumo_minimo: null,
    classe_tarifario_consumo_maximo: null,
    numero_habitantes: null,
    numero_utilizadores: null,
    data_inicio: null,
    data_fim: null,
    morada_correspondencia_flag: false,
    morada_contrato: null,
    estado_contrato_id: null,

    cil: null,

    provincia_id: null,
    municipio_id: null,
    bairro_id: null,
    rua_id: null,

    classe_tarifario_id: null,

    motivo_recisao_id: null,
    motivo_recisao: null,
    motivo_recisao_flag: null,
    novo_contrato_cil: null,
    rescindir_contrato_cil: null,

    tipo_mensagem_id: null,
    tipo_mensagem: null,
    tipo_mensagem_slug: null,
    mensagem: null,

    instalacao_sanitaria_qtd: null,
    reservatorio_flag: null,
    reservatorio_capacidade: null,
    piscina_flag: null,
    piscina_capacidade: null,
    jardim_flag: null,
    campo_jardim_id: null,
    campo_jardim: null,
    poco_alternativo_flag: null,
    fossa_flag: null,
    fossa_capacidade: null,
    acesso_camiao_flag: null,
    anexo_flag: null,
    anexo_quantidade: null,
    caixa_contador_flag: null,
    abastecimento_cil_id: null,
    abastecimento_cil: null,
    caucao: null,
    caucao_recebida: null,
    pagador_alternativo: null,
    
    cliente: null,
    info_source: null,
    local_consumo_id: null,
    local_instalacao_id: null,
    
  };

  private dadosContratoValid: boolean = false;
  private dadosLocalConsumoValid: boolean = false;

  private classificacoes: any = [];
  private tipos: any = [];

  private view_local_consumo_propierties: boolean = false;

  private conta = {
    id: null,
    numero_conta: null,
    nova_ligacao_id: null
  };

  private tarifario = {
    id: null,
    classe_tarifario_id: null,
  };

  private local_consumo = {
    id: null,
    moradia_numero: null,
    is_predio: null,
    predio_nome: null,
    predio_andar: null,
    cil: null,
    cil_flag: true,
    rua_id: null,
    rua: null,
    bairro_id: null,
    bairro: null,
    has_quarteirao: false,
    quarteirao_id: null,
    quarteirao: null,
    has_distrito: false,
    distrito_id: null,
    distrito: null,
    municipio_id: null,
    municipio: null,
    provincia_id: null,
    is_active: null,
    user_id: null,
  };

  private facturacao = {
    cliente: {
      id: null,
      nome: null,
      telefone: null,
      tipo_identidade_id: null,
      tipo_identidade: null,
      tipo_facturacao_id: null,
      tipo_facturacao: null,
      numero_identificacao: null,
      genero: null,
      email: null,
      tipo_cliente_id: null,
      tipo_cliente: null,
      tipo_cliente_slug: null,
      municipio: null,
      morada: null,
      direccao: null,
      direccao_id: null,
      gestor_cliente_id: null,
      gestor_cliente: null,
      gestor_cliente_telefone: null,

      conta_id: null,
      servico_id: null,
    },
    servicos: [],
    pagamento: {
      forma: null,
      valor_recebido: 0.0,
      total_valor_recebido: 0.0,
      troco: 0.0,
      total_pago: null,
      referencia: null,
      data_pagamento: null,
      forma_pagamento_id: null,
      adiantamentos: [],
      bancos: [],
      adiantamento: [],
      is_adiantamento: 0,
      linha_pagamentos: [],
    },
    serie: {
      id: null,
      nome: null,
      proximo_numero: null,
      documento_id: null,
      documento: null,
      sigla: null,
      movimento: null,
      tipo_movimento: null,
    },
    total_com_imposto: 0.0,
    total_sem_imposto: 0.0,
    valor_total_imposto: 0.0,
    success_facturacao: false,

    total_facturar: 0.0,
    enviar_factura: 1,
    divida_cliente: 0,
    divida_local_consumo: 0,
    divida_total: 0,

    processado: false,
    facturaGerada: null,
    data_vencimento: null,
    moeda: null,
  };

  private detalhe_contrato = {
    flag: false,
    collapse: "row collapsed",
    class: "panel-collapse collapse",
  };

  private validateButton: any = null;

  private servico = {
    servico_id: null,
    servico: null,
    servico_valor: null,
    servico_valor_load: null,
    quantidade: 1,
    imposto_id: null,
    imposto: null,
    imposto_codigo: null,
    imposto_valor: null,
    is_editable: false,
    facturado: false,
    tipo_produto_id: null,
    tipo_produto: null,
    incidencia_id: null,
    incidencia: null,
  };

  private classe_tarifario = {
    id: null,
    tarifario_id: null,
    produto_id: null,
    descricao: null,
    valor: null,
    ordem: null,
    tarifa_variavel: null,
    tarifa_fixa_mensal: null,
    tarifa_intervalo: null,
    consumo_minimo: null,
    consumo_maximo: null,
  };


  private contador = {
    contador_antigo: null,
    id: null,
    numero_serie: null,
    marca: null,
    ultima_leitura: null,
    modelo: null,
    data_leitura: null,
    tipo_contador: null,
    classe_precisao: null,
    medicao: null,
    calibre: null,
    digitos: null,
    fabricante: null,
    centro_distribuicao: null,
    precisao: null,
    leitura: null,
    leitura_origem: null,
    contrato_id: null,
    contador_id: null,
    is_leitura_inicial: false,
    new_leitura_by_contrato: false,
    observacao: null
  }

  private ordemServico = {
    data_prevista: null,
    prioridade_id: null,
    descricao: null,
    classificacao_id: null,
    tipo_id: null,
    email: null,
    categoria_descricao: null,
  };

  dropdownSettingsDepartamentos = {};
  private departamentoArray: any = [];
  private dropdownDepartamentos: any = [];

  private prioridades: any = [];

  private predio_flag: boolean = false;
  private sem_produto_show: boolean = false;

  private showCheckServicos_flag: boolean = false;
  private showCheckOT_flag: boolean = false;
  private checkAllServicos_flag: boolean = false;
  private checkConfirmServico_flag: boolean = false;

  private disabledPagamento = true;
  private disabledPagamentoAdiantamento = true;
  private view_serie_contador:boolean =false
  private classe_tarifarios: any = [];
  private activeButtom = {
    cliente: false,
    servico: false,
  };

  private items_servicos: any = [];

  private factura_cliente_dividas: any = [];
  private factura_local_consumo_dividas: any = [];

  private pagination = {
    start: 1,
    end: 10,
    search: null,
    servico: null,
    contrato: null,
    conta_id: null,
  };

  private produto_selecionado = null;
  private preco: number;
  //private servico_id: number;

  private object_cliente = { id: null, nome: null }


  public rotas: any = []
  private view_rotas: boolean = false;

  private current_rota = {
    rota_header_id: null,
    descricao: null,
    local_consumo_id: null
  };
  sigla_empresa:any = null
  private contador_join: boolean = false
  isAlterarMedicao: boolean = false
  contadores: boolean = false
  constructor(
    private auth: AuthService,
    private configService: ConfigService,
    private http: HttpService,
    private router: Router,
    private config: ConfigModuloService,
    private contratoService: ContratoService,
    private contratoLundasulService: ContratoLundasulService,
    private contratoLundanorteService: ContratoLundanorteService,
    private activated_route: ActivatedRoute,
    private _rotaService: RotaService,
  ) {
    this.currentUser = this.auth.currentUserValue;
    this.getContratoByCilRescindido();
  }

  ngOnInit() {
    this.ListarClientes(1, 50, null);
    this.contrato_edit = false;
    this.local_consumo.cil_flag = true;
    this.configService.empresaUser();
    this.getEmpresa();
    this._getTipologiaClientes()
    this.getProvincias();
    //this.selectBoxRuasByBairro();
    this.getSeries()
    this.getTarifarios();

    this.getConfiguracaos();
    this.getTiposContratos();
    this.getTiposMedicaos();
    this.getObjectosContratos();
    this.getNiveisSensibilidade();
    this.getTipologiaClientes();
    this.getCentroCusto();

    this.getParams();
    this.getContratoByCilRescindido();
    this.getClassificacao();
    this.getDropDownDepartamentos();
    this.searchRotas();
    this.empresaUser()
  }


  public getContadorById(id) {

    this.view_serie_contador = false;

    this.contador_title = "Retirar";
    this.contador_join = false;

    this.http.call_get('contador/getContadorById/' + id, null).subscribe(
      response => {

        this.setContador(Object(response));


        this.configService.loaddinStarter('stop');
      }
    );

    //console.log(this.contadores);

  }
  public setContador(item: any) {

    console.log(item);

    this.contador = {
      ...this.contador,
      id: item.id,
      numero_serie: item.numero_serie,
      marca: item.marca,
      ultima_leitura: item.ultima_leitura,
      modelo: item.modelo,

      tipo_contador: item.tipo_contador,
      classe_precisao: item.classe_precisao,
      medicao: item.medicao,
      calibre: item.calibre,
      digitos: item.digitos,
      fabricante: item.fabricante,
      centro_distribuicao: item.centro_distribuicao,

      precisao: null,
      leitura: 0,
      data_leitura: moment(new Date()).format('YYYY-MM-DD'),
      leitura_origem: null,
      contrato_id: this.contrato.id,
      is_leitura_inicial: true,
      contador_id: item.id,
      new_leitura_by_contrato: true,
      observacao: null
    }

    this.view_serie_contador = false;
  }

  public cleanContador() {

    //console.log(item);

    this.contador = {
      ...this.contador,
      id: null,
      numero_serie: null,
      marca: null,
      ultima_leitura: null,
      modelo: null,

      tipo_contador: null,
      classe_precisao: null,
      medicao: null,
      calibre: null,
      digitos: null,
      fabricante: null,
      centro_distribuicao: null,

      precisao: null,
      leitura: 0,
      data_leitura: null,
      leitura_origem: null,
      contrato_id: null,
      contador_id: null,
      is_leitura_inicial: false,
      new_leitura_by_contrato: false,
      observacao: null
    }

    this.view_serie_contador = false;
  }


  public saveContador() {

    Swal.fire({
      title: 'Aviso!',
      text: "Deseja alterar a medição do contrato para leitura ?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, Alterar',
      cancelButtonText: 'Não, não Alterar!',
      reverseButtons: true
    }).then((result) => {
     
      if (result.value) {
        this.isAlterarMedicao = true;
      }
      this.configService.loaddinStarter('start');
      this.http.__call('local-consumo/updateLocalConsumoByContador/' + this.contrato_id, {
        contador_id: this.contador.id,
        contador_antigo: this.contador.contador_antigo,
        contador_join: this.contador_join,
        data_leitura: this.contador.data_leitura,
        ultima_leitura: this.contador.leitura,
        isAlterarMedicao: this.isAlterarMedicao,
        user_id: this.currentUser.user.id
      }).subscribe(
        response => {

          if (Object(response).code == 200) {

            if ((this.contador.leitura != null) && (this.contador.new_leitura_by_contrato == true)) {
              this.create_leitura_inicial();
            }
            this.cleanContador();
            this.view_serie_contador = false;
          }

          this.getContadoresDisponiveisByNumeroSerie(false);
          this.configService.loaddinStarter('stop');
        }
      );
    })

  }

  public getContadoresDisponiveisByNumeroSerie(flag = true) {


    this.view_serie_contador = false;
    let aux_serie = this.contador.numero_serie;

    this.contador_title = "Associar";
    this.contador_join = true;

    this.http.__call('contador/getContadoresDisponiveisByNumeroSerie', { start: 1, end: 15, search: this.contador.numero_serie }).subscribe(
      response => {

        this.contadores = Object(response).data.data;

        //console.log(Object(response));
        this.configService.loaddinStarter('stop');
      }
    );

    this.cleanContador();
    this.contador.numero_serie = aux_serie;

    //console.log(this.contadores);

  }

  create_leitura_inicial() {
    this._rotaService.CreateLeitura(this.contador)
      .subscribe(
        response => { }
      )
  }
  private empresaUser() {

    this.http.call_get('empresa/empresa-user', null).subscribe(
        response => {
            this.sigla_empresa = Object(response).data.sigla_empresa
            console.log('sigla_empresa',this.sigla_empresa)
            this.empresa = Object(response);
        }
    );
}
  private getDropDownDepartamentos() {
    this.http.call_get('departamento/selectBox', null).subscribe(
      response => {
        this.dropdownDepartamentos = Object(response).data;

        this.dropdownSettingsDepartamentos = {
          singleSelection: false,
          idField: 'id',
          textField: 'descricao',
          selectAllText: 'Seleccione Todos',
          unSelectAllText: 'DeSeleccionar Todos',
          itemsShowLimit: 10,
          allowSearchFilter: true
        };

      }
    );
  }

  onItemSelectDepartamento(item: any) {
    this.departamentoArray.push(item);
  }
  onSelectAllDepartamentos(items: any) {
    this.departamentoArray = items;
  }

  private getClassificacao() {
    this.http.call_get('ordemservico/classificacao-comercial/selectBox', null).subscribe(
      response => {
        this.classificacoes = Object(response).data;
      }
    );
  }

  private getTipoByClassificacao() {
    this.carregando.tipo = 'Carregando...';
    this.http.call_get('ordemservico/ostipo-novo-contrato/selectBox/' + this.ordemServico.classificacao_id, null).subscribe(
      /*  this.http.call_get('ordemservico/ostipo-by-classificacao/selectBox/' + this.ordemServico.classificacao_id, null).subscribe( */
      response => {
        this.tipos = Object(response).data;
        this.carregando.tipo = 'Seleccione o tipo';
      }
    );
  }

  private getAssociacao() {

    this.ordemServico.categoria_descricao = null;

    if (this.ordemServico.tipo_id != null) {
      this.configService.loaddinStarter('start');
      this.http.call_get('ordemservico/ostipo/associacao/' + this.ordemServico.tipo_id, null).subscribe(
        response => {

          this.ordemServico.categoria_descricao = Object(response).data.categoria_descricao,

            this.configService.loaddinStarter('stop');
        }

      );
    }

  }

  getContratoByCilRescindido() {
    this.reset_details_rescendidos();
    if (this.configService.contrato.cliente_id) {
      this.facturacao.cliente.nome = this.configService.contrato.cliente_nome
      this.facturacao.cliente.id = this.configService.contrato.cliente_id
      this.contrato.cliente_id = this.configService.contrato.cliente_id
      this.contrato.conta_id = this.configService.contrato.conta_id
      this.conta.id = this.configService.contrato.conta_id
      this.local_consumo.cil = this.configService.contrato.cil_disponivel
      this.configService.contrato.isVisible = true
      this.getLocalConsumoByContratoId(this.configService.contrato.contrato_id)
      this.getContaById(this.configService.contrato.conta_id)
    }
  }

  reset_details_rescendidos() {
    this.facturacao.cliente.nome = null
    this.facturacao.cliente.id = null
    this.conta.id = null
    this.local_consumo.cil = null
  }

  ngOnDestroy() {
    this.configService.contrato.isVisible = false
    this.configService.contrato.cliente_nome = null
    this.configService.contrato.cliente_id = null
    this.configService.contrato.conta_id = null
    this.configService.contrato.cil_disponivel = null
  }

  private getParams() {

    this.activated_route.params.subscribe((params) => {
     
      this.contrato_id = params.id;

      this.contrato.id = params.id;
      this.contrato.conta_id = params.conta_id;
      this.contrato.cliente_id = params.cliente_id;
      this.contrato.media_consumo = this.contrato.media_consumo;
      this.contrato.id = params.id;
      this.contrato.cil = params.cil
      this.local_consumo.id = params.local_consumo_id
      this.contrato.local_consumo_id = params.local_consumo_id
      this.tarifario.id = params.tarifario_id;
      this.tarifario.classe_tarifario_id = params.classe_tarifario_id;

      this.conta.id = params.conta_id;
      this.conta.nova_ligacao_id = params.nova_ligacao_id;

      if (params.id) {
        this.getContratoById(params.id);
        this.getLocalConsumoByContratoId(params.id);
        this.classeTarifarioBytarifario();
      }

      if (params.conta_id) this.getContaById(params.conta_id);
    });

    if (this.contrato.conta_id != null) {
      if (this.contrato.id) {
        this.contrato_edit = true;
        this.activeButtom.cliente = false;

        if ((this.contrato.tipo_facturacao_id = null))
          this.contrato.tipo_facturacao_id = 2;

        this.local_consumo.cil_flag = false;
        this.local_consumo.provincia_id = this.contrato.provincia_id;
        this.local_consumo.cil = this.contrato.cil;

        this.selectBoxDistritosByMunicipio(this.contrato.municipio_id);

        this.selectBoxQuarteiraosByBairro(this.contrato.bairro_id);
        this.selectBoxMunicipiosByProvincia(this.contrato.provincia_id);
        this.getProvincias();
      }
    }

    this.local_consumo.has_distrito = true;
    this.local_consumo.has_quarteirao = true;
  }

  changeClasseTarifario(classe_tarifario_id) {
    this.contrato.classe_tarifario_id = classe_tarifario_id;
    this.setClasseTarifario();
  }

  private ListarClientes(start, end, searchCliente) {
    this.configService.loaddinStarter("start");
    this.pagination.start = start;
    this.pagination.end = end;
    this.pagination.search = searchCliente;

    this.http
      .__call("cliente/search-cliente-on-contrato", this.pagination)
      .subscribe((res) => {
        this.clientes = Object(res).data.data;
        this.configService.loaddinStarter("stop");
      });
  }


  private getClienteByConta(id) {
    this.configService.loaddinStarter("start");

    this.http
      .call_get("cliente/getClienteByConta/" + id, null)
      .subscribe((res) => {
        this.cliente = Object(res);
        this.configService.loaddinStarter("stop");
      });
  }

  private getContaById(id) {
    this.http
      .call_get("conta/getContaById/" + id, null)
      .subscribe((response) => {
        let item = Object(response);
        this.conta.numero_conta = item.numero_conta;

        this.facturacao.cliente.conta_id = item.id;
        this.contrato.tipo_facturacao_id = item.tipo_facturacao_id;
        this.contrato.tipo_facturacao = item.tipo_facturacao;

        this.facturacao.cliente.nome = item.cliente_nome;
        this.facturacao.cliente.id = item.cliente_id;
        this.facturacao.cliente.telefone = item.cliente_telefone;
        this.facturacao.cliente.genero = item.genero;
        this.facturacao.cliente.email = item.cliente_email;
        this.facturacao.cliente.tipo_cliente_id = item.tipo_cliente_id;
        this.facturacao.cliente.tipo_cliente = item.tipo_cliente;

        this.getDividaAnteriorByClienteId(item.cliente_id);
      });
  }

  private getContratoById(id) {
    this.configService.loaddinStarter("start");

    this.http
      .call_get("contrato/getContratoById/" + id, null)
      .subscribe((res) => {
        this.contrato = Object(res).contrato;

        console.log(this.contrato)
        this.searchPagador = this.contrato.pagador_alternativo
        this.configService.loaddinStarter("stop");
      });
  }

  private getDividaAnteriorByClienteId(id) {
    this.configService.loaddinStarter("start");

    this.http
      .call_get("factura/getDividaAnteriorByClienteId/" + id, null)
      .subscribe((res) => {
        this.factura_cliente_dividas = Object(res);
        this.configService.loaddinStarter("stop");
        this.validacaoDivida();
      });
  }

  private getDividaAnteriorByLocalInstalacaoId(id) {
    this.configService.loaddinStarter("start");

    this.http
      .__call("factura/getDividaAnteriorByLocalInstalacaoId/" + id, {
        cliente_id: this.contrato.cliente_id,
      })
      .subscribe((res) => {
        this.factura_local_consumo_dividas = Object(res);
        this.configService.loaddinStarter("stop");
        this.validacaoDivida();
      });
  }

  private validacaoDivida() {
    let divida_cliente = 0;
    let divida_local_consumo = 0;

    for (let index = 0; index < this.factura_cliente_dividas.length; index++) {
      const item = this.factura_cliente_dividas[index];

      divida_cliente = divida_cliente + item.valor_aberto;
    }

    for (
      let index = 0;
      index < this.factura_local_consumo_dividas.length;
      index++
    ) {
      const item = this.factura_local_consumo_dividas[index];

      divida_local_consumo = divida_local_consumo + item.valor_aberto;
    }

    this.facturacao.divida_total = divida_cliente + divida_local_consumo;

    this.facturacao.divida_cliente = divida_cliente;
    this.facturacao.divida_local_consumo = divida_local_consumo;
  }

  private getCliente(e) {
    console.log(e)
    this.searchCliente;
    this.ListarClientes(1, 50, this.searchCliente);
  }

  private getClientePagadorAlternativo() {
    console.log(this.searchPagador)
    this.http.__call('cliente/search-cliente', { start: 1, end: 10, search: this.searchPagador }).subscribe(
      response => {
        this.pagadoresAlternativos = Object(response).data.data;
        this.configService.loaddinStarter('stop');
        //this.getSeriesRecibos();
      }
    );


  }

  private setPagadorData(item) {
    this.pagador_alternativo = item
    console.log(item)
    this.searchPagador = item.nome
    this.view_client = false;
  }

  private getProduto() {
    this.produtos(this.search.nativeElement.value);
  }

  private setServicos(response: any) {
    this.facturacao.valor_total_imposto = 0;
    let validar = 0;

    if (response.code == 400) {
      this.configService.showAlert(response.message, "alert-danger", true);
    } else {
      if (this.facturacao.servicos.length >= 1) {
        if (response.valor_original) {
          for (
            let index = 0;
            index < this.facturacao.servicos.length;
            index++
          ) {
            const servico = this.facturacao.servicos[index];
            if (servico.servico_id == response.servico_id) {
              this.facturacao.servicos.splice(index, 1);
              this.facturacao.servicos.splice(index, 0, response);
              validar = 1;
            }
          }
        }
        if (validar == 0) {
          this.facturacao.servicos.push(response);
        }
      } else {
        this.facturacao.servicos.push(response);
      }
      //Calcula o Total da Factura
      this.getValorTotalServicos();
    }
  }

  private showCheckOT() {
    this.showCheckOT_flag = !this.showCheckOT_flag;

    if (this.showCheckOT_flag) {
      this.getPrioridade();
      this.getClassificacao();
    }
  }

  private getPrioridade() {
    this.http
      .call_get("ordemservico/prioridade/selectBox", null)
      .subscribe((response) => {
        this.prioridades = Object(response).data;
      });
  }

  private showCheckServicos() {
    this.showCheckServicos_flag = !this.showCheckServicos_flag;

    let servicos_avalaible_flag = false;

    if (this.showCheckServicos_flag) {
      for (let index = 0; index < this.facturacao.servicos.length; index++) {
        let item = this.facturacao.servicos[index];

        if (!item.facturar) this.facturacao.servicos[index].facturar = false;

        if (!item.facturado && item.servico_valor > 0)
          servicos_avalaible_flag = true;
      }
    }

    this.showCheckServicos_flag = servicos_avalaible_flag
      ? this.showCheckServicos_flag
      : false;
  }

  private checkAllServicos() {
    this.checkAllServicos_flag = !this.checkAllServicos_flag;
    for (let index = 0; index < this.facturacao.servicos.length; index++) {
      if (!this.facturacao.servicos[index].facturado)
        this.facturacao.servicos[index].facturar = this.checkAllServicos_flag;
    }
  }

  private notExistServico(id) {
    for (let index = 0; index < this.facturacao.servicos.length; index++) {
      if (this.facturacao.servicos[index].servico_id == id) return false;
    }

    return true;
  }

  private detalheContratoCollapse() {
    this.detalhe_contrato.flag = !this.detalhe_contrato.flag;
    this.detalhe_contrato.class = this.detalhe_contrato.flag
      ? "panel-collapse collapse show"
      : "panel-collapse collapse";
    this.detalhe_contrato.collapse = this.detalhe_contrato.flag
      ? "row"
      : "row collapsed";
  }

  private getServicosToAdd() {
    this.configService.loaddinStarter("start");

    this.http
      .call_get("produto-classe-tarifario/selectBox", null)
      .subscribe((res) => {
        this.servicos = Object(res);

        this.configService.loaddinStarter("stop");
      });

    this.servico_modal_title = "Associar Serviço";
    this.edit_servico_flag = false;
    //this.modalAppendServico.show()
  }


  private setClasseTarifario() {
    this.addValorClasse = true;

    for (let index = 0; index < this.classe_tarifarios.length; index++) {
      if (
        this.classe_tarifarios[index].id == this.tarifario.classe_tarifario_id
      ) {
        this.classe_tarifario.id = this.classe_tarifarios[index].id;
        this.classe_tarifario.tarifario_id =
          this.classe_tarifarios[index].tarifario_id;
        this.classe_tarifario.produto_id =
          this.classe_tarifarios[index].produto_id;
        this.classe_tarifario.descricao =
          this.classe_tarifarios[index].descricao;
        this.classe_tarifario.valor = this.classe_tarifarios[index].valor;
        this.classe_tarifario.ordem = this.classe_tarifarios[index].ordem;
        this.classe_tarifario.tarifa_variavel =
          this.classe_tarifarios[index].tarifa_variavel;
        this.classe_tarifario.tarifa_fixa_mensal =
          this.classe_tarifarios[index].tarifa_fixa_mensal;
        this.classe_tarifario.tarifa_intervalo =
          this.classe_tarifarios[index].tarifa_intervalo;
        this.classe_tarifario.consumo_minimo =
          this.classe_tarifarios[index].consumo_minimo;
        this.classe_tarifario.consumo_maximo = this.classe_tarifarios[index].consumo_maximo;
        this.contrato.media_consumo = this.classe_tarifarios[index].media_consumo
        this.contrato.media_consumo = this.classe_tarifarios[index].media_consumo;
      }
    }

    this.servico_modal_title = "Editar Serviço " + this.servico.servico;

    this.edit_servico_flag = true;
  }

  private getServicosToEdit(id) {
    this.addValorClasse = true;

    for (let index = 0; index < this.facturacao.servicos.length; index++) {
      if (this.facturacao.servicos[index].servico_id == id) {
        this.servico.servico_id = this.facturacao.servicos[index].servico_id;
        this.servico.servico = this.facturacao.servicos[index].servico;
        this.servico.servico_valor =
          this.facturacao.servicos[index].servico_valor;
        this.servico.imposto_id = this.facturacao.servicos[index].imposto_id;
        this.servico.imposto = this.facturacao.servicos[index].imposto;
        this.servico.imposto_codigo =
          this.facturacao.servicos[index].imposto_codigo;
        this.servico.imposto_valor =
          this.facturacao.servicos[index].imposto_valor;
        this.servico.is_editable = this.facturacao.servicos[index].is_editable;
        this.servico.facturado = this.facturacao.servicos[index].facturado;
        this.servico.tipo_produto_id =
          this.facturacao.servicos[index].tipo_produto_id;
        this.servico.tipo_produto =
          this.facturacao.servicos[index].tipo_produto;
        this.servico.incidencia_id =
          this.facturacao.servicos[index].incidencia_id;
        this.servico.incidencia = this.facturacao.servicos[index].incidencia;
      }
    }

    this.servico.servico_valor_load =
      this.servico.servico_valor == null ? 0 : this.servico.servico_valor;
    this.servico_modal_title = "Editar Serviço " + this.servico.servico;
    this.edit_servico_flag = true;
    //this.modalAppendServico.show()
  }

  private getValorTotalServicos() {
    this.facturacao.valor_total_imposto = 0;
    this.facturacao.total_sem_imposto = 0;
    this.facturacao.total_com_imposto = 0;

    //Calcula o Total da Factura
    for (let index = 0; index < this.facturacao.servicos.length; index++) {
      let item = this.facturacao.servicos[index];

      let valor = item.servico_valor ? item.servico_valor * item.quantidade : 0;
      let valor_imposto = (item.imposto_valor ? item.imposto_valor : 0) * valor;

      this.facturacao.total_sem_imposto += valor;
      this.facturacao.valor_total_imposto += valor_imposto;
      this.facturacao.total_com_imposto += valor + valor_imposto;
    }

    this.facturacao.pagamento.total_pago = this.facturacao.valor_total_imposto;
  }

  deleteRow(servico: any) {
    for (let i = 0; i < this.facturacao.servicos.length; ++i) {
      if (this.facturacao.servicos[i].servico_id === servico.servico_id) {
        this.facturacao.servicos.splice(i, 1);

        this.getValorTotalServicos();
      }
    }

    this.getServicoValorFacturar();
  }

  selectSerie() {
    for (let i = 0; i < this.series.length; ++i) {
      if (this.series[i].id == this.facturacao.serie.id) {
        this.facturacao.serie = {
          id: this.series[i].id,
          nome: this.series[i].nome,
          proximo_numero: this.series[i].proximo_numero,
          documento_id: this.series[i].documento_id,
          documento: this.series[i].documento,
          sigla: this.series[i].sigla,
          movimento: this.series[i].momento,
          tipo_movimento: this.series[i].tipo_movimento,
        };
      }
    }
  }

  refreshServico(item) {
    this.http
      .__call("produto-classe-tarifario/getServicoById/" + item.servico_id, {
        conta_id: this.facturacao.cliente.conta_id,
        contrato_id: this.contrato.id,
      })
      .subscribe((response) => {
        this.servico = Object(response);

        this.configService.loaddinStarter("stop");
      });
  }

  appendServico() {
    this.configService.loaddinStarter("start");

    let quantidade = this.servico.quantidade;
    this.servico.quantidade = 1;

    if (quantidade <= 0 || isNaN(quantidade)) {
      this.configService.showAlert(
        "Não foi informado uma quantidade valida",
        "alert-danger",
        true
      );
      return;
    }

    this.http
      .__call(
        "produto-classe-tarifario/getServicoById/" + this.servico.servico_id,
        {
          conta_id: this.facturacao.cliente.conta_id,
          contrato_id: this.contrato.id,
        }
      )
      .subscribe((response) => {
        let servico = Object(response);

        if (servico != null && this.notExistServico(servico.servico_id)) {
          servico.quantidade = quantidade;

          this.facturacao.servicos.push(servico);

          let valor = servico.servico_valor
            ? servico.servico_valor * quantidade
            : 0;
          this.facturacao.total_sem_imposto += valor;
          let valor_imposto =
            (servico.imposto_valor ? servico.imposto_valor : 0) * valor;
          this.facturacao.valor_total_imposto += valor_imposto;
          this.facturacao.total_com_imposto += valor + valor_imposto;
        }

        this.configService.loaddinStarter("stop");
      });

    this.getValorTotalServicos();
    this.getServicoValorFacturar();
  }

  private refreshSerieId() {
    this.serieId = this.serie.nativeElement.value;
    if (Object(this.serieId) == "") {
      this.serieId = 0;
    }
  }

  private selectedTarifario() {
    this.tarifario.id = this.tarifario.id;
  }

  private selectedCliente(cliente) {
    //this.currente_cliente = cliente // this.clientes.find(obj => obj.id = id)
    this.facturacao.cliente.id = cliente.id;

    this.getDividaAnteriorByClienteId(cliente.id);

    this.getConfiguracaos();
    this.getTiposContratos();
    this.getTiposMedicaos();
    this.getObjectosContratos();
    this.getNiveisSensibilidade();
    this.getTipologiaClientes();

    this.facturacao.cliente = cliente;
    this.activeButtom.cliente = true;
    this.getContas();
  }

  private produtos(search) {
    this.items_servicos = [];
    this.configService.loaddinStarter("start");
    this.pagination.search = search == "" || search == null ? "a" : search;
    this.pagination.servico =
      this.facturacao.cliente.servico_id == "" ||
        this.facturacao.cliente.servico_id == null
        ? null
        : this.facturacao.cliente.servico_id;

    this.pagination.conta_id = this.facturacao.cliente.conta_id;

    this.http.__call("artigo/search", this.pagination).subscribe((res) => {
      this.items_servicos = Object(res).data;
      this.configService.loaddinStarter("stop");
    });
  }


  private validateTipoContrato(id) {
    console.log("Clicou aqui")
  }

  private getAddProdutoClasseTarifario() {
    this.configService.loaddinStarter("start");
    this.http
      .__call("classe-tarifario/getServicoByClasseTarifario", {
        conta_id: this.facturacao.cliente.conta_id,
        contrato_id: this.contrato.id,
        classe_tarifario_id: this.tarifario.classe_tarifario_id,
        preco: this.preco,
      })
      .subscribe((res) => {
        this.setServicos(Object(res).data);
        this.getValorTotalServicos();
        this.preco = null;
        this.closeModal();

        this.configService.loaddinStarter("stop");
      });
  }

  private resetTarifario() {
    this.tarifario.id = null;
    this.tarifario.classe_tarifario_id = null;
    this.facturacao.servicos = [];
  }

  private getServicosByClasseTarifario() {
    this.facturacao.servicos = [];

    if (
      this.contrato.tipo_medicao_slug == "ESTIMATIVA" &&
      this.addValorClasse === true &&
      this.preco == null
    ) {
      this.configService.showAlert("Digite o valor", "alert-danger", true);
      return;
    } else {
      this.configService.loaddinStarter("start");

      this.http
        .__call(
          "produto-classe-tarifario/getServicosByClasseTarifario/" +
          this.tarifario.classe_tarifario_id,
          {
            conta_id: this.facturacao.cliente.conta_id,
            contrato_id: this.contrato.id,
          }
        )
        .subscribe((response) => {
          this.facturacao.servicos = Object(response).data;

          if (this.facturacao.servicos.length == 0) {
            this.sem_produto_show = true;
          } else {
            this.sem_produto_show = false;
          }

          this.getValorTotalServicos();

          if (
            this.contrato.tipo_medicao_slug == "ESTIMATIVA" &&
            this.addValorClasse === true
          ) {
            this.getAddProdutoClasseTarifario();
          }

          this.closeModal();
          this.configService.loaddinStarter("stop");
        });
    }
  }

  private getServicos(search) {
    this.title_facturacao = `Facturação de serviços do contrato ${this.dataContratoSaved.contrato.id}`
    this.servicos = [];
    this.facturacao.servicos = [];
    this.configService.loaddinStarter("start");
    this.pagination.search = search == "" || search == null ? "a" : search;
    this.pagination.contrato = this.dataContratoSaved.contrato.id == "";
    this.pagination.conta_id = this.contrato.conta_id == "";

    this.http
      .__call("artigo/search", {
        pagination: this.pagination,
        search: search,
      })
      .subscribe((res) => {
        this.servicos = Object(res);
        this.configService.loaddinStarter("stop");
      });
  }

  private openModal() {
    this.getServicosByClasseTarifario();

    if (this.contrato.tipo_medicao_slug == "ESTIMATIVA") {
      this.configService.loaddinStarter("start");

      this.http
        .__call("classe-tarifario/getServicoByClasseTarifario", {
          conta_id: this.facturacao.cliente.conta_id,
          contrato_id: this.contrato.id,
          classe_tarifario_id: this.tarifario.classe_tarifario_id,
          preco: this.preco,
          flag: this.tarifa_fixa_modal_view,
        })
        .subscribe((res) => {
          if (Object(res).code === 404) {
            this.addValorClasse = false;
            //this.modaltwo.show()
            this.configService.showAlert(
              "Classe tarifário sem serviço associado",
              "alert-warning",
              true
            );
            this.configService.loaddinStarter("stop");
          } else {
            if (
              Object(res).data != null &&
              this.notExistServico(Object(res).data.servico_id)
            ) {
              this.addValorClasse = true;
              this.facturacao.servicos.push(Object(res).data);
              this.getServicosToEdit(Object(res).data.servico_id);

              if (this.tarifa_fixa_modal_view) {

              }
            }
          }

          this.configService.loaddinStarter("stop");
        });
    }

    if (
      !this.contrato_edit &&
      this.contrato.tipo_medicao_slug == "LEITURA" &&
      this.contrato.media_consumo <= 0
    )
      this.mediaConsumoModal.show();

    this.getValorTotalServicos();
    this.getDividaAnteriorByClienteId(this.contrato.cliente_id);
  }

  private closeModal() {
    this.modalAppendServico.hide();
  }

  private setMediaConsumo() {
    this.contrato.media_consumo = 0;
    //this.mediaConsumoModal.show();
  }

  private closeMediaConsumoModal() {
    this.mediaConsumoModal.hide();
  }

  private validateMensagem() {
    for (let index = 0; index < this.tipo_mensagems.length; index++) {
      if (this.contrato.tipo_mensagem_id == this.tipo_mensagems[index].id) {
        this.contrato.tipo_mensagem = this.tipo_mensagems[index].nome;
        this.contrato.tipo_mensagem_slug = this.tipo_mensagems[index].slug;
      }
    }

    if (this.contrato.tipo_mensagem_slug == "GERAL") {
      this.mensagem_title = "Mensagem Geral";

      this.http
        .__call(
          "mensagem/getMensagemByTipoMensagem/" +
          this.contrato.tipo_mensagem_id,
          { tipo_cliente_id: null }
        )
        .subscribe((res) => {
          this.contrato.mensagem = Object(res).texto;
        });
    } else if (this.contrato.tipo_mensagem_slug == "TIPO_CLIENTE") {
      this.mensagem_title =
        "Mensagem de Tipo de Cliente(" +
        this.facturacao.cliente.tipo_cliente +
        ")";

      this.http
        .__call(
          "mensagem/getMensagemByTipoMensagem/" +
          this.contrato.tipo_mensagem_id,
          { tipo_cliente_id: this.facturacao.cliente.tipo_cliente_id }
        )
        .subscribe((res) => {
          this.contrato.mensagem = Object(res).texto;
        });
    } else {
      this.mensagem_title = "Mensagem Individual";
    }
  }

  private revertValidateDadosContrato() {
    this.dados_validated = false;
  }

  private validateCIL() {
    if (!this.local_consumo.cil_flag) this.view_local_consumo_cil = false;
  }

  private validateDadosContrato() {
    if (this.contrato.data_inicio != null && this.contrato.data_fim != null) {
      var tokenize_date_1 = this.contrato.data_inicio.split("-");
      var tokenize_date_2 = this.contrato.data_fim.split("-");

      var data_1 = new Date(
        tokenize_date_1[2],
        tokenize_date_1[1] - 1,
        tokenize_date_1[0]
      );
      var data_2 = new Date(
        tokenize_date_2[2],
        tokenize_date_2[1] - 1,
        tokenize_date_2[0]
      );
    }

    if (this.contrato.data_inicio != null) {
      var tokenize_date_1 = this.contrato.data_inicio.split("-");
      var data_1 = new Date(
        tokenize_date_1[2],
        tokenize_date_1[1] - 1,
        tokenize_date_1[0]
      );
    }

    var result: boolean = true;

    if (this.contrato.caucao_recebida < this.contrato.caucao) {
      this.configService.showAlert(
        "A caução recebida não deve ser inferior ao valor da caução!",
        "alert-danger",
        true
      );
      result = false;
    } else if (this.contrato.tipo_medicao_id == null) {
      this.configService.showAlert(
        "O tipo de medição não foi seleccionado!",
        "alert-danger",
        true
      );
      result = false;
    } else if (this.contrato.tipo_contracto_id == null) {
      this.configService.showAlert(
        "O tipo de contrato não foi seleccionado!",
        "alert-danger",
        true
      );
      result = false;
    } else if (
      this.contrato.tipo_facturacao_id == null &&
      !this.contrato_edit
    ) {
      this.configService.showAlert(
        "O tipo de facturação não foi seleccionado!",
        "alert-danger",
        true
      );
      result = false;
    } else if (
      this.contrato.tipologia_cliente_id == null &&
      !this.contrato_edit
    ) {
      this.configService.showAlert(
        "A tipologia de cliente não foi seleccionado!",
        "alert-danger",
        true
      );
      result = false;
    } else if (
      this.contrato.objecto_contrato_id == null &&
      !this.contrato_edit
    ) {
      this.configService.showAlert(
        "O objecto de contrato não foi seleccionado!",
        "alert-danger",
        true
      );
      result = false;
    } else if (this.contrato.data_inicio == null && !this.contrato_edit) {
      this.configService.showAlert(
        "A data Início não foi inserida!",
        "alert-danger",
        true
      );

      result = false;
    } else if (data_2 != null && data_1 > data_2) {
      this.configService.showAlert(
        "A data Início não pode ser maior que a data fim",
        "alert-danger",
        true
      );
      result = false
    } else if (this.tarifario.id == null) {
      this.configService.showAlert(
        "A categoria do tarifário não foi seleccionado!",
        "alert-danger",
        true
      );
      result = false;
    } else if (this.tarifario.classe_tarifario_id == null) {
      this.configService.showAlert(
        "A classe tarifário não foi seleccionado!",
        "alert-danger",
        true
      );
      result = false;
    }
    else if (
      this.showCheckOT_flag == true &&
      this.ordemServico.data_prevista == null
    ) {
      this.configService.showAlert(
        "Digite a Data Prevista da Ordem de Trabalho!",
        "alert-danger",
        true
      );
      result = false;
    } else if (
      this.showCheckOT_flag == true &&
      this.ordemServico.prioridade_id == null
    ) {
      this.configService.showAlert(
        "Seleccione a Prioridade da Ordem de Trabalho!",
        "alert-danger",
        true
      );
      result = false;
    } else if (
      this.showCheckOT_flag == true &&
      this.ordemServico.classificacao_id == null
    ) {
      this.configService.showAlert(
        "Seleccione a Classificação da Ordem de Trabalho!",
        "alert-danger",
        true
      );
      result = false;
    } else if (
      this.showCheckOT_flag == true &&
      this.ordemServico.tipo_id == null
    ) {
      this.configService.showAlert(
        "Seleccione o Tipo de Ordem de Trabalho!",
        "alert-danger",
        true
      );
      result = false;
    } else if (
      this.showCheckOT_flag == true &&
      this.ordemServico.descricao == null
    ) {
      this.configService.showAlert(
        "Escreve a Descrição para Ordem de Trabalho!",
        "alert-danger",
        true
      );
      result = false;
    }

    else {
      this.configService.showAlert(
        "Os dados estão válidos",
        "alert-success",
        true
      );
    }

    this.dados_validated = result;

    return result;
  }

  private validateDadosContratoCil() {


    let result: boolean = true;

    if (
      this.local_consumo.cil == null &&
      this.empresa.sigla_empresa == "EPASLN"
    ) {
      this.configService.showAlert(
        "Cil Obrigatório ",
        "alert-danger",
        true
      );

      result = false;
    }

    else {
      this.configService.showAlert(
        "Os dados estão válidos",
        "alert-success",
        true
      );
    }

    this.dados_validated = result;

    return result;
  }

  private validateDadosLocalConsumo() {
    var result: boolean = true;
    return result;
  }

  private validateLocalConsumo() {
    this.http
      .__call("local-consumo/getLocalConsumoWithContrato", {
        id: this.local_consumo.id,
        conta_id: this.conta.id,
      })
      .subscribe((response) => {
        let res = Object(response);
        if (res != null) this.contrato.motivo_recisao_flag = true;
        this.configService.loaddinStarter("stop");
      });
  }

  private cilAvailable(flag) {
    if (flag) {
      this.contrato.rescindir_contrato_cil = false;
      this.contrato.novo_contrato_cil = true;
    } else {
      this.contrato.rescindir_contrato_cil = true;
      this.contrato.novo_contrato_cil = false;
    }
  }

  private checkConfirmServico() {
    this.checkConfirmServico_flag = true; // provisório
  }

  private getServicoValorFacturar() {
    this.facturacao.total_facturar = 0;

    for (let index = 0; index < this.facturacao.servicos.length; index++) {
      let item = this.facturacao.servicos[index];

      if (item.facturar) {
        let servico_valor = item.servico_valor
          ? item.servico_valor * item.quantidade
          : 0;
        let valor_imposto =
          (item.imposto_valor ? item.imposto_valor : 0) * servico_valor;

        this.facturacao.total_facturar += servico_valor + valor_imposto;
      }
    }
  }

  private facturarServicos() {
    this.selectSerie();

    let valor_total_imposto = 0;
    let total_sem_imposto = 0;
    let total_com_imposto = 0;

    for (let index = 0; index < this.facturacao.servicos.length; index++) {
      let item = this.facturacao.servicos[index];

      //if (item.facturar) {

      let servico_valor = item.servico_valor ? item.servico_valor : 0;
      let valor_imposto =
        (item.imposto_valor ? item.imposto_valor : 0) * servico_valor;

      total_sem_imposto += servico_valor * item.quantidade;
      valor_total_imposto += valor_imposto * item.quantidade;
      total_com_imposto += (servico_valor + valor_imposto) * item.quantidade;
      //}
    }
    console.log(this.contrato_id)
    const data = {
      contrato_id: this.contrato_id,
      //contrato_id: this.dataContratoSaved.contrato.id,
      enviar_factura: this.facturacao.enviar_factura,
      cliente_id: this.facturacao.cliente.id,
      gestor_cliente_id: this.facturacao.cliente.gestor_cliente_id,
      conta_id: this.facturacao.cliente.conta_id,
      servicos: this.facturacao.servicos,
      total_sem_imposto: total_sem_imposto,
      total_com_imposto: total_com_imposto,
      valor_total_imposto: valor_total_imposto,
      valor_aberto: total_com_imposto,
      serie: this.facturacao.serie,
    };

    this.http
      .__call("factura/facturarServicosContrato", data)
      .subscribe((res) => {
        if (Object(res).code == 200) {
          this.showCheckServicos_flag = false;

          this.facturacao.servicos = Object(res).data.servicos;
          console.log(Object(res).data.factura.id)
          //this.confirmFacturacaoModal.hide();
          this.imprimirFacturaServicos(Object(res).data.factura.id)
        }

        if (Object(res).code == 507) {
          this.showCheckServicos_flag = true;
          this.configService.showAlert(
            Object(res).message,
            "alert-success",
            true
          );
          console.log("Test 2")
        }
      });

    this.getDividaAnteriorByClienteId(this.facturacao.cliente.id);
  }



  private getSeries() {
    this.series = [];
    this.http
      .call_get(`serie/loja/selectSerieLojaFacturaBox`, null)
      .subscribe((data) => {
        let serie = Object(data).data.find((obj) => obj.sigla == "FT");

        this.facturacao.serie.id = serie.id;
        this.facturacao.serie = serie;

        this.series = Object(data).data;
      });
  }

  private closeConfirmFacturacaoModal() {
    //this.checkAllServicos_flag = false;
    this.confirmFacturacaoModal.hide();
  }

  private imprimirFacturaServicos(id) {
    this.configService.imprimirFactura(id, "Original", "imprimir")
   }

  private validateProcess() {
    if (this.validateDadosContrato() && this.validateDadosLocalConsumo()) {
      this.contrato_validated = true;
    }
  }

  private imprimirContrato(id) {
    if (this.empresa.sigla_empresa == "EPASN") {
      this.contratoService.imprimirPDFContrato(id);
    }

    if (this.empresa.sigla_empresa == "EPASLS") {
      this.contratoLundasulService.imprimirPDFContratoLundaSul(id);
    }

    if (this.empresa.sigla_empresa == "EPASLN") {
      this.contratoLundanorteService.imprimirPDFContrato(id);
    }
  }

  async getEmpresa() {
    await this.http
      .call_get("empresa/empresa-user", null)
      .subscribe((response) => {
        this.empresa = Object(response);
        this.empresa.sigla_empresa = Object(response).data.sigla_empresa;
      });
  }

  private validarfinalizacaoContrato(flag) {
    this.showCheckServicos_flag = flag;

    this.saveContrato();
  }

  private finalizarContrato() {
    if (this.showCheckServicos_flag) {
      this.getSeries();

      if (!this.facturacao.serie.id) {
        //this.confirmFacturacaoModal.show();
        this.saveContrato();
      } else {
        this.facturarServicos()
      };
    } else {
      this.saveContrato();
    }

    this.getDividaAnteriorByClienteId(this.contrato.cliente_id);
  }

  private saveContrato() {

    //console.log(this.pagador_alternativo)

    if (this.validateDadosContrato() && this.validateDadosLocalConsumo()) {
      this.configService.loaddinStarter("start");

      const data = {
        conta_id: this.conta.id,
        cliente_id: this.facturacao.cliente.id,
        tipo_contracto_id: this.contrato.tipo_contracto_id,
        tipo_medicao_id: this.contrato.tipo_medicao_id,
        media_consumo: this.contrato.media_consumo,
        tipo_facturacao_id: this.contrato.tipo_facturacao_id,
        tipologia_cliente_id: this.contrato.tipologia_cliente_id,
        nivel_sensibilidade_id: this.contrato.nivel_sensibilidade_id,
        objecto_contrato_id: this.contrato.objecto_contrato_id,
        motivo_recisao_id: this.contrato.motivo_recisao_id,
        data_inicio: this.contrato.data_inicio,
        data_fim: this.contrato.data_fim,
        morada_correspondencia_flag: this.contrato.morada_correspondencia_flag,
        numero_habitantes: this.contrato.numero_habitantes,
        numero_utilizadores: this.contrato.numero_utilizadores,

        morada_contrato: this.contrato.morada_contrato,
        classe_tarifario_id: this.contrato.classe_tarifario_id,

        tipo_mensagem_id: this.contrato.tipo_mensagem_id,
        mensagem: this.contrato.mensagem,

        tarifario_id: this.tarifario.id,

        local_consumo_id: this.local_consumo.id,
        instalacao_sanitaria_qtd: this.contrato.instalacao_sanitaria_qtd,
        reservatorio_flag: this.contrato.reservatorio_flag,
        reservatorio_capacidade: this.contrato.reservatorio_capacidade,
        piscina_flag: this.contrato.piscina_flag,
        piscina_capacidade: this.contrato.piscina_capacidade,
        jardim_flag: this.contrato.jardim_flag,
        campo_jardim_id: this.contrato.campo_jardim_id,
        poco_alternativo_flag: this.contrato.poco_alternativo_flag,
        fossa_flag: this.contrato.fossa_flag,
        fossa_capacidade: this.contrato.fossa_capacidade,
        acesso_camiao_flag: this.contrato.acesso_camiao_flag,
        anexo_flag: this.contrato.anexo_flag,
        anexo_quantidade: this.contrato.anexo_quantidade,
        caixa_contador_flag: this.contrato.caixa_contador_flag,
        abastecimento_cil_id: this.contrato.abastecimento_cil_id,
        is_ordem_trabalho: this.showCheckOT_flag,
        ordem_trabalho: this.ordemServico,
        estado_contrato_id: this.contrato.estado_contrato_id,
        departamentos: this.departamentoArray,
        caucao: this.contrato.caucao,
        caucao_recebida: this.contrato.caucao_recebida,
        pagador_alternativo: this.pagador_alternativo ? this.pagador_alternativo.id : null,
      };

      console.log(data.local_consumo_id)
      console.log(data)

      if (this.contrato.id == null) {
        this.closebutton.nativeElement.click();
        let url = this.conta.nova_ligacao_id ? 'contrato/create_by_nova_ligacao/' + this.conta.nova_ligacao_id : 'contrato/create'
        this.http.__call(url, data).subscribe((res) => {
          if (Object(res).code == 500) {
            this.configService.showAlert(
              Object(res).message,
              "alert-danger",
              true
            );
          } else if (Object(res).code == 201) {
            this.configService.showAlert(
              Object(res).message,
              "alert-warning",
              true
            );
            this.configService.loaddinStarter("stop");

            this.contrato_validated = false;

            this.dataContratoSaved = Object(res).data;
          } else {
            this.configService.showAlert(
              Object(res).message,
              "alert-success",
              true
            );
            this.contrato_id = Object(res).data.contrato.id
            this.facturacao.processado = true;
            this.configService.loaddinStarter("stop");

            this.dataContratoSaved = Object(res).data;
          }
        });
      }

      if (this.contrato.id > 0) {
        console.log(data)

        this.http
          .__call("contrato/update/" + this.contrato.id, data)
          .subscribe((res) => {
            if (Object(res).code == 500) {
              this.configService.showAlert(
                Object(res).message,
                "alert-danger",
                true
              );
            } else if (Object(res).code == 201) {
              this.configService.showAlert(
                Object(res).message,
                "alert-warning",
                true
              );
              this.configService.loaddinStarter("stop");

              this.contrato_validated = false;

              this.dataContratoSaved = Object(res).data;
            } else {
              this.configService.showAlert(
                Object(res).message,
                "alert-success",
                true
              );
              this.facturacao.processado = true;
              this.configService.loaddinStarter("stop");

              this.dataContratoSaved = Object(res).data;
            }
          });
      }

      //this.facturarServicos();
      this.getDividaAnteriorByClienteId(this.facturacao.cliente.id);
    }
  }

  private carregando = {
    tarifario: "Seleccione o tarifário",
    classeTarifario: "Seleccione a classe tarifário",
    tipoUtilizacao: "Seleccione o Tipo Utilizacao",
    tipo: 'Seleccione o tipo'
  };

  private classeTarifarioBytarifario() {
    this.carregando.classeTarifario = "Carregando...";
    this.http
      .call_get(
        "selectBox/classe-tarifarioBytarifario/" + this.tarifario.id, null)
      .subscribe((response) => {
        this.classe_tarifarios = Object(response).data;

        if (this.tarifario.classe_tarifario_id) this.setClasseTarifario();
        this.carregando.classeTarifario = "Seleccione a classe tarifário";
        this.facturacao.valor_total_imposto = 0;
        this.facturacao.servicos = [];
      });
  }

  private tipologiaContratoByTipoUtilizacao() {
    this.carregando.tipoUtilizacao = "Carregando...";
    this.http
      .call_get(
        "selectBox/tipologia-contratoByTipoUtilizacao/" + this.contrato.tipo_contracto_id,
        null
      )
      .subscribe((response) => {
        this.classe_tarifarios = Object(response).data;

        if (this.tarifario.classe_tarifario_id) this.setClasseTarifario();

        this.carregando.classeTarifario = "Seleccione a classe tarifário";
        this.facturacao.valor_total_imposto = 0;
        this.facturacao.servicos = [];
      });
  }

  private getTiposContratos() {
    this.http
      .call_get("tipo-contrato/selectBox", null)
      .subscribe((response) => {
        this.tipo_contratos = Object(response);

      });

  }

  private getTiposMedicaos() {
    this.http.call_get("tipo-medicao/selectBox", null).subscribe((response) => {
      this.tipo_medicaos = Object(response);
    });
  }

  private getNiveisSensibilidade() {
    this.http
      .call_get("nivel-sensibilidade/selectBox", null)
      .subscribe((response) => {
        this.niveis_sensibilidade = Object(response);
      });
  }

  private getAbastecimentoCILs() {
    this.http
      .call_get("abastecimento-cil/selectBox", null)
      .subscribe((response) => {
        this.abastecimento_cils = Object(response);
      });
  }

  private getTipoMensagems() {
    this.http
      .call_get("tipo-mensagem/selectBox", null)
      .subscribe((response) => {
        this.tipo_mensagems = Object(response);
      });
  }

  private getCampoJardims() {
    this.http.call_get("campo-jardim/selectBox", null).subscribe((response) => {
      this.campo_jardims = Object(response);
    });
  }

  private getTarifarios() {
    this.http.call_get("tarifario/selectBox", null).subscribe((response) => {
      this.tarifarios = Object(response).data;
    });
  }

  private setTipologiasContrato(id: any) {
    this.http
      .call_get(
        "tarifario/selectBox/byTipoContrato/" + id,
        null
      )
      .subscribe((response) => {
        this.tarifarios = Object(response);
      });


    this.existCaucao = false
    var child: any
    for (const parent of this.tipologia_clientes) {
      child = parent.childs.filter((obj: any) => (obj.id == id) && (obj.has_caucao == 1))
      if (child.length) {
        this.existCaucao = true;
        this.contrato.caucao = child.length ? child[0].caucao : null;
        ($("#confirmarCaucaoModal") as any).modal("show")
        return
      }
    }
  }

  private is_menor = false;
  private validarCaucao() {
    this.is_menor = false;
    if (this.contrato.caucao_recebida < this.contrato.caucao) {
      this.is_menor = true;
      this.contrato.caucao_recebida = null;
    }
    if (!this.is_menor) {
      ($("#confirmarCaucaoModal") as any).modal("hide")
    }
  }

  private getTipologiaClientes() {
    this.carregando.tipoUtilizacao = "Carregando...";
    if (this.contrato.tipo_contracto_id != null) {
      this.http
        .call_get(
          "selectBox/tipologia-contratoByTipoUtilizacao/" + this.contrato.tipo_contracto_id, null)
        .subscribe((response) => {
          this.tipologia_clientes = Object(response);
        });
    } else {
      this.http
        .call_get("tipologia-cliente/selectGroupBox", null)
        .subscribe((response) => {
          this.tipologia_clientes = Object(response);

        });


    }

  }

  private _getTipologiaClientes() {
    this.carregando.tipoUtilizacao = "Carregando...";
    if (this.contrato.tipo_contracto_id != null) {
      this.http
        .call_get(
          "selectBox/tipologia-contratoByTipoUtilizacao", null)
        .subscribe((response) => {
          this.tipologia_clientes = Object(response);
        });
    } else {
      this.http
        .call_get("tipologia-cliente/selectGroupBox", null)
        .subscribe((response) => {
          this.tipologia_clientes = Object(response);

        });

    }

  }

  private getCentroCusto() {
    this.http.call_get("centro-custo/getAll", null).subscribe((response) => {
      this.centro_custo = Object(response);
    });
  }

  private getObjectosContratos() {
    this.http
      .call_get("objecto-contrato/selectBox", null)
      .subscribe((response) => {
        this.objecto_contratos = Object(response);
      });
  }

  private getMotivosRecisaos() {
    this.http
      .call_get("motivo-recisao/selectBox", null)
      .subscribe((response) => {
        this.motivo_recisaos = Object(response);
      });
  }

  private getProvincias() {
    if (this.local_consumo.cil_flag == false && !this.contrato_edit) {
      this.local_consumo.municipio_id = null;
      this.local_consumo.distrito_id = null;
      this.local_consumo.bairro_id = null;
      this.local_consumo.quarteirao_id = null;
      this.local_consumo.rua_id = null;
    }

    this.http.call_get("provincia/selectBox", null).subscribe((response) => {
      this.provincias = Object(response).data;
    });
  }

  private selectBoxMunicipiosByProvincia(id) {
    if (this.local_consumo.cil_flag == false && !this.contrato_edit) {
      this.local_consumo.distrito_id = null;
      this.local_consumo.bairro_id = null;
      this.local_consumo.quarteirao_id = null;
      this.local_consumo.rua_id = null;
    }

    this.http
      .call_get("municipio/getMunicipiosByProvincia/" + id, null)
      .subscribe((response) => {
        this.municipios = Object(response).data;
      });
  }

  private selectBoxDistritosByMunicipio(id) {
    this.http
      .call_get("distrito/getDistritosByMunicipio/" + id, null)
      .subscribe((response) => {
        this.distritos = Object(response).data;
        if (this.distritos.length) this.local_consumo.has_distrito = true;
      });

    if (!this.local_consumo.has_distrito) {
      this.selectBoxBairrosByMunicipio(id);
    } else {
      this.selectBoxBairrosByDistrito(this.local_consumo.distrito_id);
    }
  }

  private selectBoxBairrosByDistrito(id) {
    this.local_consumo.cil = null;
    this.local_consumo.quarteirao_id = null;
    this.local_consumo.rua_id = null;

    this.http
      .call_get("bairro/selectBoxByDistrito/" + id, null)
      .subscribe((response) => {
        this.bairros = Object(response);
      });
  }

  private selectBoxQuarteiraosByBairro(id) {
    if (this.local_consumo.cil_flag == false && !this.contrato_edit) {
      this.local_consumo.quarteirao_id = null;
      this.local_consumo.rua_id = null;
    }

    if (!this.contrato_edit) {
      for (let i = 0; i < this.bairros.length; ++i) {
        if (this.bairros[i].id == id)
          this.local_consumo.has_quarteirao = this.bairros[i].has_quarteirao;
      }
    }

    if (this.local_consumo.has_quarteirao || this.contrato_edit) {
      this.http
        .call_get("quarteirao/getQuarteiraosByBairro/" + id, null)
        .subscribe((response) => {
          this.quarteiraos = Object(response).data;

          if (this.quarteiraos.length) this.local_consumo.has_quarteirao = true;
        });
    }

    if (!this.local_consumo.has_quarteirao || this.contrato_edit) {
      this.selectBoxRuasByBairro();
    }
  }

  private selectBoxRuasByQuarteirao(id) {
    this.http
      .call_get("rua/getRuasByQuarteirao/" + id, null)
      .subscribe((response) => {
        this.ruas = Object(response).data;
      });
  }

  private selectBoxRuasByBairro() {
    this.http
      .call_get("rua/selectBoxByBairro/" + this.local_consumo.bairro_id, null)
      .subscribe((response) => {
        this.ruas = Object(response);
      });
  }

  private selectBoxBairrosByMunicipio(id) {
    if (this.local_consumo.cil_flag == false && !this.contrato_edit) {
      this.local_consumo.quarteirao_id = null;
      this.local_consumo.rua_id = null;
    }

    this.http
      .call_get("bairro/selectBoxByMunicipio/" + id, null)
      .subscribe((response) => {
        this.bairros = Object(response)
      });
  }

  view_local_consumo = false;
  view_local_consumo_cil = false;

  private getResidencia() {
    this.local_consumo.id = null;
    this.local_consumo.moradia_numero = null;
    this.local_consumo.predio_nome = null;
    this.local_consumo.is_predio = null;
    this.local_consumo.cil = null;

    this.view_local_consumo = true;

    this.http
      .__call("local-instalacao/getSelectLocalInstalacaosByRua", {
        rua_id: this.local_consumo.rua_id,
        start: 1,
        end: 15,
        search: this.local_consumo.moradia_numero,
      })
      .subscribe((response) => {
        this.local_consumos = Object(response).data.data;

        this.configService.loaddinStarter("stop");
      });
  }

  private getLocalInstalacaosByCILAndMoradia() {
    if (this.local_consumo.cil == "") {
      //this.setNullResidencia();
    }

    this.view_local_consumo_cil = true;
    this.http
      .__call("local-instalacao/getLocalInstalacaosByCILAndMoradia", {
        start: 1,
        end: 15,
        search: this.local_consumo.cil,
      })
      .subscribe((response) => {
        this.local_consumos = Object(response).data.data;

        this.configService.loaddinStarter("stop");
      });
  }

  private loc_id: any
  private getLocalConsumoByContratoId(contrato_id) {
    this.configService.loaddinStarter("start");

    this.http
      .call_get(
        "local-consumo/getLocalConsumoByContratoId/" + contrato_id,
        null
      )
      .subscribe((response) => {
        //const item = Object(response)[0]
        const item = Object(response)
        this.setLocalConsumo(item);

        setTimeout(() => {
          this.local_consumo.id = item.local_instalacao_id;

        }, 1000);

        this.getAbastecimentoCILs();
        this.getCampoJardims();
        this.configService.loaddinStarter("stop");
      });
  }

  private setLocalConsumo(item: any) {
    this.loc_id = item.id
    this.local_consumo.id = item.id;
    this.local_consumo.moradia_numero = item.moradia_numero;
    this.local_consumo.predio_nome =
      (item.cil != null ? "[" + item.cil + "] " : "[0000-000-000] ") +
      (item.is_predio
        ? "Prédio " +
        item.predio_nome +
        ", " +
        this.configService.decimalFormat(item.predio_andar) +
        "º Andar - Porta "
        : "Residência ") +
      this.configService.decimalFormat(item.moradia_numero);
    this.local_consumo.is_predio = item.is_predio;
    this.local_consumo.cil = item.cil;

    this.local_consumo.provincia_id = item.provincia_id;

    this.local_consumo.municipio_id = item.municipio_id;
    this.local_consumo.municipio = item.municipio;
    this.local_consumo.distrito_id = item.distrito_id;
    this.local_consumo.distrito = item.distrito;
    this.local_consumo.bairro_id = item.bairro_id;
    this.local_consumo.bairro = item.bairro;
    this.local_consumo.quarteirao_id = item.quarteirao_id;
    this.local_consumo.quarteirao = item.quarteirao;
    this.local_consumo.rua_id = item.rua_id;
    this.local_consumo.rua = item.rua;

    this.view_local_consumo = false;
    this.view_local_consumo_cil = false;

    this.view_local_consumo_propierties = true;

    this.contrato.instalacao_sanitaria_qtd = item.instalacao_sanitaria_qtd;
    this.contrato.reservatorio_flag = item.reservatorio_flag;
    this.contrato.reservatorio_capacidade = item.reservatorio_capacidade;
    this.contrato.piscina_flag = item.piscina_flag;
    this.contrato.piscina_capacidade = item.piscina_capacidade;
    this.contrato.jardim_flag = item.jardim_flag;
    this.contrato.campo_jardim_id = item.campo_jardim_id;
    this.contrato.poco_alternativo_flag = item.poco_alternativo_flag;
    this.contrato.fossa_flag = item.fossa_flag;
    this.contrato.fossa_capacidade = item.fossa_capacidade;
    this.contrato.acesso_camiao_flag = item.acesso_camiao_flag;
    this.contrato.anexo_flag = item.anexo_flag;
    this.contrato.anexo_quantidade = item.anexo_quantidade;
    this.contrato.caixa_contador_flag = item.caixa_contador_flag;
    this.contrato.abastecimento_cil_id = item.abastecimento_cil_id;

    setTimeout(() => {
      this.selectBoxRuasByBairro();

      this.selectBoxDistritosByMunicipio(item.municipio_id);
      this.selectBoxQuarteiraosByBairro(item.bairro_id);
      this.selectBoxRuasByQuarteirao(item.quarteirao_id);

      this.local_consumo.quarteirao_id = item.quarteirao_id;
      this.local_consumo.rua_id = item.rua_id;

      this.local_consumo.cil = this.local_consumo.cil_flag
        ? this.local_consumo.predio_nome
        : item.cil;

    }, 1500);
  }

  private setNullResidencia() {
    this.local_consumo.id = null;
    this.local_consumo.moradia_numero = null;
    this.local_consumo.predio_nome = null;
    this.local_consumo.is_predio = null;
    this.local_consumo.cil = null;

    this.local_consumo.provincia_id = null;
    this.local_consumo.municipio_id = null;
    this.local_consumo.distrito_id = null;
    this.local_consumo.bairro_id = null;
    this.local_consumo.quarteirao_id = null;
    this.local_consumo.rua_id = null;

    this.view_local_consumo = false;
    this.view_local_consumo_cil = false;

    this.view_local_consumo_propierties = false;

    this.predio_flag = false;
  }

  private reloadFacturacao() {
    this.contrato_edit = false;
    location.reload();
  }

  private getContas() {
    this.activeButtom.cliente = false;
    this.http
      .call_get("cliente/conta/selectBox/" + this.facturacao.cliente.id, null)
      .subscribe((response) => {
        this.contas_clientes = Object(response).data;
      });
  }

  private getServicosConta() {
    this.activeButtom.cliente = false;
    this.configService.loaddinStarter("start");
    this.http
      .call_get(
        "tarifario/servico/selectBoxServicosConta/" +
        this.facturacao.cliente.conta_id,
        null
      )
      .subscribe((response) => {
        this.servicos_conta_cliente = Object(response).data;
        this.configService.loaddinStarter("stop");
      });
  }

  confirmarConta() {
    this.activeButtom.cliente = false;
    if (this.conta.id == null) {
      this.configService.showAlert("Seleccione a conta", "alert-danger", true);
    } else {
      this.activeButtom.cliente = true;
    }

    this.contas_clientes.forEach((e) => {
      if (e.id == this.conta.id) this.conta.numero_conta = e.numero_conta;

      this.facturacao.cliente.conta_id = e.id;
      this.contrato.tipo_facturacao_id = e.tipo_facturacao_id;
      this.contrato.tipo_facturacao = e.tipo_facturacao;
    });
  }

  private contaServicoSeleciona() {
    this.servicos_conta_cliente.forEach((element) => {
      if (element.id == this.facturacao.cliente.servico_id) {
        this.servico = element;
      }
    });
  }

  private clienteSemOrComConta = 0;

  selectedClienteComConta(n: number) {
    this.clienteSemOrComConta = n;
    this.getTarifarios();

    this.getTiposContratos();

    this.getProvincias();
    this.facturacao.servicos = [];
  }

  selectedClienteSemConta(n: number) {
    this.clienteSemOrComConta = n;

    this.getTarifarios();
    this.facturacao.servicos = [];
    this.servico = null;
    this.facturacao.cliente.conta_id = null;
    this.facturacao.cliente.servico_id = null;
  }

  private joinLocalInstacaoToContrato(item: any) {
    this.http
      .__call("local-instalacao/getLocalInstalacaoByRuaAndMoradia/", {
        rua_id: item.rua_id,
        moradia_numero: item.moradia_numero,
      })
      .subscribe((response) => {
        let result = Object(response);

        this.local_consumo.id = result.id;
        this.local_consumo.moradia_numero = result.moradia_numero;
        this.local_consumo.predio_nome =
          (result.is_predio
            ? "Prédio " +
            result.predio_nome +
            ", " +
            (result.predio_andar ? result.predio_andar + " º Andar - " : "") +
            "Porta "
            : "Residência ") + result.moradia_numero;
        this.local_consumo.is_predio = result.is_predio;

        if (Object(response).code == 200) {
          this.configService.showAlert(
            Object(response).message,
            "alert-success",
            true
          );
          this.local_instalacao = null;
        } else {
          this.configService.showAlert(
            Object(response).message,
            "alert-danger",
            true
          );
        }
      });
  }

  onReset() {
    this.local_instalacao.id = null;
    this.local_instalacao.moradia_numero = null;
    this.local_instalacao.is_predio = null;
    this.local_instalacao.predio_id = null;
    this.local_instalacao.predio_nome = null;
    this.local_instalacao.rua_id = null;
    this.local_instalacao.is_active = null;
    this.local_instalacao.user_id = null;
  }

  cancelarConta() {
    this.activeButtom.cliente = false;
    this.facturacao.cliente.conta_id = null;
    this.facturacao.cliente.servico_id = null;
    this.facturacao.cliente.id = null;
    this.servico = null;
  }

  cancelarEdit() {
    this.activeButtom.cliente = false;
    this.contrato_edit = false;

    this.facturacao.cliente.conta_id = null;
    this.facturacao.cliente.servico_id = null;
    this.facturacao.cliente.id = null;
    this.servico = null;
  }

  private getContratoDetails() {
    if (this.validateDadosContrato()) {
      this.setClasseTarifario();

      this.getDetailTipoMedicao();
      this.getDetailTipoContrato();
      this.getDetailNivelSensibilidade();
      this.getDetailTipologiaCliente();
      this.getDetailObjectoContrato();
      this.getDetailTarifario();
      this.getDetailClasseTarifario();

      this.getAbastecimentoCILs();
      this.getTipoMensagems();
      this.getCampoJardims();
    }
  }

  private getDetailTipoMedicao() {
    for (let index = 0; index < this.tipo_medicaos.length; index++) {
      if (this.contrato.tipo_medicao_id == this.tipo_medicaos[index].id) {
        this.contrato.tipo_medicao = this.tipo_medicaos[index].nome;
        this.contrato.tipo_medicao_slug = this.tipo_medicaos[index].slug;
      }
    }
  }

  private getDetailTipoContrato() {
    for (let index = 0; index < this.tipo_contratos.length; index++) {
      if (this.contrato.tipo_contracto_id == this.tipo_contratos[index].id) {
        this.contrato.tipo_contrato = this.tipo_contratos[index].descricao;
      }
    }
  }

  private getDetailNivelSensibilidade() {
    for (let index = 0; index < this.niveis_sensibilidade.length; index++) {
      if (
        this.contrato.nivel_sensibilidade_id ==
        this.niveis_sensibilidade[index].id
      ) {
        this.contrato.nivel_sensibilidade =
          this.niveis_sensibilidade[index].nome;
      }
    }
  }

  private getDetailTarifario() {
    for (let index = 0; index < this.tarifarios.length; index++) {
      if (this.tarifario.id == this.tarifarios[index].id) {
        this.contrato.tarifario = this.tarifarios[index].descricao;
      }
    }
  }

  private getDetailClasseTarifario() {
    for (let index = 0; index < this.classe_tarifarios.length; index++) {
      if (
        this.tarifario.classe_tarifario_id == this.classe_tarifarios[index].id
      ) {
        this.contrato.classe_tarifario =
          this.classe_tarifarios[index].descricao;
        this.contrato.classe_tarifario_consumo_minimo =
          this.classe_tarifarios[index].consumo_minimo;
        this.contrato.classe_tarifario_consumo_maximo =
          this.classe_tarifarios[index].consumo_maximo;
      }
    }
  }

  private getDetailTipologiaCliente() {
    for (let index = 0; index < this.tipologia_clientes.length; index++) {
      if (this.contrato.tipologia_cliente_id == this.tipologia_clientes[index].id) {
        this.contrato.tipologia_cliente = this.tipologia_clientes[index].descricao;
        //this.contrato.tipo_medicao_slug = this.tipo_medicaos[index].slug;

      }

    }
    /*
    for (let index = 0; index < this.tipologia_clientes.length; index++) {
      for (let k = 0; k < this.tipologia_clientes[index].childs.length; k++) {
        if (
          this.contrato.tipologia_cliente_id ==
          this.tipologia_clientes[index].childs[k].id
        ) {
          this.contrato.tipologia_cliente =
            this.tipologia_clientes[index].childs[k].descricao;
          this.contrato.tipologia_cliente_juro_mora =
            this.tipologia_clientes[index].childs[k].juro_mora;
          this.contrato.tipologia_cliente_sujeito_corte =
            this.tipologia_clientes[index].childs[k].sujeito_corte;
          this.contrato.tipologia_cliente_caucao =
            this.tipologia_clientes[index].childs[k].caucao;
        }
      }
    }*/
  }

  private getDetailObjectoContrato() {
    for (let index = 0; index < this.objecto_contratos.length; index++) {
      if (
        this.contrato.objecto_contrato_id == this.objecto_contratos[index].id
      ) {
        this.contrato.objecto_contrato = this.objecto_contratos[index].nome;
      }
    }
  }

  private getDetailAbastecimentoCIL() {
    for (let index = 0; index < this.abastecimento_cils.length; index++) {
      if (
        this.contrato.abastecimento_cil_id == this.abastecimento_cils[index].id
      ) {
        this.contrato.abastecimento_cil = this.abastecimento_cils[index].nome;
      }
    }
  }

  private getDetailCampoJardim() {
    for (let index = 0; index < this.campo_jardims.length; index++) {
      if (this.contrato.campo_jardim_id == this.campo_jardims[index].id) {
        this.contrato.campo_jardim = this.campo_jardims[index].nome;
      }
    }
  }

  private getDetailTipoMensagem() {
    for (let index = 0; index < this.tipo_mensagems.length; index++) {
      if (this.contrato.tipo_medicao_id == this.tipo_mensagems[index].id) {
        this.contrato.tipo_mensagem = this.tipo_mensagems[index].nome;
      }
    }
  }

  private getDetailMotivoRecisao() {
    for (let index = 0; index < this.motivo_recisaos.length; index++) {
      if (this.contrato.motivo_recisao_id == this.motivo_recisaos[index].id) {
        this.contrato.motivo_recisao = this.motivo_recisaos[index].nome;
      }
    }
  }

  public getConfiguracaos() {
    let result = null;

    const slugs = [
      this.config.provincia_default,
      this.config.tipo_facturacao_default,
      this.config.media_consumo_default,
      this.config.nivel_sensibilidade_default,
      this.config.tarifa_fixa_modal_view,
      this.config.cil_disabled_view,
    ];

    for (let index = 0; index < slugs.length; index++) {
      this.http
        .__call("configuracao/getConfiguracaobySlug/" + slugs[index], null)
        .subscribe((response) => {

          if (Object(response).code != 200) {
            result = null;
          } else {
            result = Object(response).data;

            if (slugs[index] == this.config.provincia_default) {
              this.local_consumo.provincia_id = result.valor;
              this.selectBoxMunicipiosByProvincia(result.valor);
            }

            if (slugs[index] == this.config.tipo_facturacao_default) {
              this.contrato.tipo_facturacao_id = result.valor;
            }

            if (slugs[index] == this.config.nivel_sensibilidade_default) {
              this.contrato.nivel_sensibilidade_id = result.valor;
            }

            if (slugs[index] == this.config.tarifa_fixa_modal_view)
              this.tarifa_fixa_modal_view = Boolean(result.valor);

            if (slugs[index] == this.config.cil_disabled_view)
              this.cil_disabled_view = Boolean(result.valor);
          }
        });
    }
  }

  public getRotas(search) {
    this.http.__call('get/rotas', { search: search })
      .subscribe(res => {
        this.rotas = Object(res).data;
      })
  }

  convertNumberToPositive(event) {
    this.contrato.numero_utilizadores = Math.abs(this.contrato.numero_utilizadores);
  }

  searchRotas() {
    this.view_rotas = true;
    this.getRotas(this.current_rota.descricao)
  }

  set_cliente_sem_rotas() {
    this.current_rota.local_consumo_id = this.loc_id
    this.view_rotas = false
    this.current_rota.rota_header_id = null
    this.current_rota.descricao = null
  }

  set_view_rotas(item: any) {
    this.current_rota.rota_header_id = item.id
    this.current_rota.descricao = item.descricao
    this.view_rotas = false;
  }

  associar_roteiro_a_rota() {
    this.configService.loaddinStarter("start");
    this.http.__call("associar-roteiro-a-rota", this.current_rota)
      .subscribe(res => {
        this.configService.loaddinStarter("stop");
      })
  }

  FacturarCaucao() {
    this.router.navigate(['/facturacao-caucao/' + this.contrato_id + "/" + this.facturacao.cliente.id])
  }

}
