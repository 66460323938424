import { Component, OnInit, ViewChild } from "@angular/core";
import { HttpService } from "src/app/providers/http/http.service";
import { ApiService } from "src/app/providers/http/api.service";
import { ConfigService } from "src/app/providers/config/config.service";

import { FacturaCicloService } from "src/app/components/reports/factura-ciclo/factura-ciclo.service";

import { HttpParams } from "@angular/common/http";
import { ExcelService } from "src/app/services/excel.service";
import * as moment from "moment";
import { Subject } from 'rxjs';
import { debounceTime, finalize } from 'rxjs/operators';
import { FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { Observable } from "rxjs/Rx";
import { ExcelAutoService } from "src/app/services/excel/excel-auto.service";
import { toDate, format } from 'date-fns-tz'
export class Filter {
  search: string = "";
  orderBy: string = "";
  typeOrderBy: string = "DESC";
  startDate: Date;
  endDate: Date;
  typeFilter: string = "";
  municipio_id: string = 'null';
  constructor() {
    var CurrentDate = new Date();
    var lastDayMonth = moment(
      new Date(CurrentDate.getFullYear(), CurrentDate.getMonth() + 1, 0)
    ).format("DD");
    this.startDate = new Date(
      CurrentDate.getFullYear(), 0, 1, 1
      /*       CurrentDate.getFullYear() + "-" + (CurrentDate.getMonth() + 1) + "-01" */
    );
    this.endDate = new Date(
      CurrentDate.getFullYear() +
      "-" +
      (CurrentDate.getMonth() + 1) +
      "-" +
      lastDayMonth
    );
  }
}
export class Pagination {
  lastPage: number;
  page: number = 1;
  perPage: number = 5;
  total: number;
  deserialize(input): this {
    return Object.assign(this, input);
  }
}

@Component({
  selector: "app-listar-facturacao",
  templateUrl: "./listar-facturacao.component.html",
  styleUrls: ["./listar-facturacao.component.css"],
})
export class ListarFacturacaoComponent implements OnInit {
  public pagination = new Pagination();
  public filter = new Filter();
  private factura: any = null;
  private empresa = {
    sigla_empresa: null
  };
  loading = false;

  private facturas: any = [];
  private operador: any = [];
  private municipios: any = [];
  subjectListFacts: Subject<number> = new Subject();
  mes = moment(new Date()).format("MM");

  status_reason: string = null;

  loading_factura: boolean = false;
  private dashboard = {
    countRecibos: null,
    countFacturas: null,
    countFacturasVencidas: null,
    countFacturasContaCorrente: null,
  };
  private localUrl:any
  observableObj: Observable<any>;
  subjectObj = new Subject<number>();

  private timeOutput:any

  constructor(
    private http: HttpService,
    private reportFactura: FacturaCicloService,
    private configService: ConfigService,
    private _route: Router,
    private excelService: ExcelService,
    private api: ApiService,
    private _excelService: ExcelAutoService,
  ) { }

  getTimeZone(date) {
    const parisDate = toDate(date)
    const pattern = 'dd-MM-yyyy'
    this.timeOutput = format(parisDate, pattern, { timeZone: 'UTC/GMT+1' })
    return this.timeOutput
  }

  public loadings = { municipios: null }
  isActive: boolean
  ngOnInit() {
    this.isActive = false
    this.filter.orderBy = "created_at";
     this.empresaUser()
    this.dashboardFacturacao();
    this.subjectObj.pipe(debounceTime(1000)).subscribe({
      next: () => this.listarFacturacao(),
    });
    this.subjectObj.next(1);

    this.getEmpresa()
    this.getMunicipios()
  }

  private getMunicipios() {
    this.loadings.municipios = true;
    this.api.get('municipio/selectBox')
      .pipe(finalize(() => { this.loadings.municipios = false; }))
      .subscribe(response => { this.municipios = Object(response).data; }, error => { this.loadings.municipios = false; });
  }
  exportAsXLSX(): void {
    var CurrentDate = new Date();
    var nameFile =
      "Lista_facturacao-" +
      moment(CurrentDate).format("DD") +
      "-" +
      moment(CurrentDate).format("MM") +
      "-" +
      moment(CurrentDate).format("YYYY") +
      " " +
      moment(CurrentDate).format("H") +
      ":" +
      moment(CurrentDate).format("m");
    this.excelService.exportAsExcelFile(
      document.getElementsByClassName("exportAsXLSX")[0],
      nameFile
    );
  }
  exportAsPDF(): void {
    //this.reportLoja.relatorioLoja(this.cobrancas, this.simpleForm.value, 'save');
  }

  imprimirPDF(): void {
    //this.reportLoja.relatorioLoja(this.cobrancas, this.simpleForm.value);
  }

  /**
   * @name "Breve estatistica de facturação"
   * @descriptio "Esta Função permite Estatistica todas facturações"
   */
  private dashboardFacturacao() {
    this.http.call_get("factura/dashboard", null).subscribe((response) => {
      this.dashboard.countRecibos = this.configService.numberFormat(
        Object(response).data.countRecibos
      );
      this.dashboard.countFacturas = this.configService.numberFormat(
        Object(response).data.countFacturas
      );
      this.dashboard.countFacturasVencidas = this.configService.numberFormat(
        Object(response).data.countFacturasVencidas
      );
      this.dashboard.countFacturasContaCorrente = this.configService.numberFormat(
        Object(response).data.countFacturasContaCorrente
      );
    });
  }

  /**
   * @name "Listar facturação"
   * @descriptio "Esta Função permite Listar todas facturações"
   * @author "caniggia.moreira@itgest.pt"
   * @param start
   * @param end
   */
  private listarFacturacao() {
    this.facturas = []
    this.loading = true;
    var params = new HttpParams();
    params = this.api.params
      .set("page", this.pagination.page.toString())
      .set("perPage", (this.pagination.perPage || 5).toString())
      .set("search", this.filter.search.toString())
      .set(
        "startDate", moment(this.filter.startDate).format("YYYY-MM-DD").toString()
      )
      .set(
        "endDate", moment(this.filter.endDate).format("YYYY-MM-DD").toString()
      )
      .set("orderBy", this.filter.orderBy.toString())
      .set("typeOrderBy", this.filter.typeOrderBy.toString())
      .set("typeFilter", this.filter.typeFilter.toString())
      .set("municipio_id", this.filter.municipio_id);

    this.api
      .get(`facturas`, params)
      .pipe(
        debounceTime(1000),
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(response => {
        this.pagination.lastPage = Object(response).data.lastPage;
        this.pagination.page = Object(response).data.page;
        this.pagination.total = Object(response).data.total;
        this.pagination.perPage = Object(response).data.perPage;
        this.facturas = Object(response).data.data;
        console.log(Object(response))
      }, error => {
        console.log("TEM ALGUM ERRO : ", error)
      })
  }
  private empresaUser() {
    this.configService.loaddinStarter('start');
    this.http.call_get('empresa/empresa-user', null).subscribe(
      response => {
        this.localUrl = Object(response).data.logotipo
      }
    );
  }
  _exportAsXLSX(): void {
    var CurrentDate = new Date();
    let datas=[]
    for (const iterator of  this.facturas) {
      datas.push(
        {
          ...iterator,
          status:iterator.status=='N'?'Normal':'Anulado',
          valor_aberto: iterator.valor_aberto+' '+iterator.moeda_iso,
          percent:this.calcularPercentual(iterator),
          created_at:this.getTimeZone(iterator.created_at)
        }
      )
    }


    var keys = [

      { key: 'contrato_id', width: 40, style: { font: { name: 'Calibri' } } },
      { key: 'nome', width: 40 },
      { key: 'municipio_nome', width: 25 },
      { key: 'cil', width: 40, style: { font: { name: 'Calibri' } } },
      { key: 'factura_sigla', width: 25 },
      { key: 'status', width: 40 },
      { key: 'pago', width: 40 },
      { key: 'percent', width: 40, style: { numFmt: '#,##0.00', } },
      { key: 'total', width: 40, style: { numFmt: '#,##0.00', } },
      { key: 'valor_aberto', width: 40, style: { numFmt: '#,##0.00', } },
      { key: 'created_at', width: 40 },


    ];
    var Cols = [
      "Nº Contrato",
      "Cliente",
      "Município",
      "Cil",
      "Nº Factura",
      "Status",
      "Saldado ?",
      "Saldado %",
      "Total",
      "Valor Em Aberto",
      "Data Facturação"
    ]
    var title = 'LISTA DE FACTURAÇÃO'
    var nameFile = "LISTA_DE_FACTURAÇÃO -" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
      this._excelService.excels(datas, nameFile, this.localUrl, keys, Cols, title, 5, 11, 40, 3, [7,8,10,9])
  }
  //--------------------------------------------------------------------------

  getPageFilterData(page) {
    if (this.pagination.perPage == null) {
      return;
    }
    console.log(page)
    this.pagination.page = Number(page) || 1;
    this.listarFacturacao()
    //this.subjectObj.next(this.pagination.page);
  }
  //--------------------------------------------------------------------------

  private getFactura(factura: any) {
    this.factura = factura;
  }
  private getOperador(value: any) {
    this.http.call_get('user/perfils/' + value.user_id + '/' + value.id, null).subscribe(
      response => {
        this.operador = Object(response).data;
        if (this.operador[0].status == 'N') {
          this.isActive = true
        } else {
          this.isActive = false
        }
      }
    );

  }

  hendleFacturaViaEmail(id){
    this.loading = true;
      this.http.__call('documentos/' + id, null).subscribe(
        response => {
          if (Object(response).code == 400) {
            this.configService.showAlert(
              Object(response).message,
              "alert-danger",
              true
            );
          } else {
            this.configService.showAlert(
              Object(response).message,
              "alert-danger",
              true
            );
          this.loading = false;
        }
     });
  }

  private anularFactura(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();

    if (this.status_reason == "" || this.status_reason == null) {
      this.configService.showAlert("É obrigatório fornecer o nº da factura e o motivo", "alert-danger", true);
    } else {

      if (this.status_reason.length > 50) {
        this.configService.showAlert("O motivo da anulação deve ser o mais breve possível", 'alert-danger', true);
        this.configService.loaddinStarter('stop');
      } else {
        this.loading_factura = true;

        this.api.put("facturas/anular/" + this.factura.id, {
          status_reason: this.status_reason,
        })
          .pipe(
            finalize(() => {
              this.loading_factura = false;
            })
          )
          .subscribe((res) => {
            if (Object(res).code == 400) {
              this.configService.showAlert(
                Object(res).message,
                "alert-danger",
                true
              );
            } else {
              this.configService.showAlert(
                Object(res).message,
                "alert-success",
                true
              );
              this.getPageFilterData(this.pagination.page);
              this.dashboardFacturacao();
              this.status_reason = "";
              this.factura = null;
            }
            this.loading_factura = false;
          });
      }
    }
  }

  goToPageCreateNotaCredito(factura: any) {
    this._route.navigate(["/facturacao/emitir-nota-de-credito", factura.id]);
  }

  private imprimirFactura(fact) {
    //console.log(fact)
    this.configService.imprimirFactura(fact.id, "2ª Via", "imprimir")
  }

  private DownloadFactura(id) {
    this.configService.imprimirFactura(id, "2ª Via", "download");
  }

  calcularPercentual(f: any) {
    var calc = f.total - f.valor_aberto;
    return f.serie.documento.sigla == "NC"
      ? 100
      : f.pago === 0 && f.status === "N" && f.valor_aberto == null
        ? 0
        : (calc * 100) / f.total;

    //(f.total === f.valor_aberto ? 0 : f.pago == 1 ? 100 : (calc * 100) / f.total);
  }

  ncLengthAnulado = 0;
  public showFactura(id: number) {
    this.ncLengthAnulado = 0;
    this.factura = null;
    this.loading_factura = true;
    this.api
      .get("facturas/" + id)
      .pipe(
        finalize(() => {
          this.loading_factura = false;
        })
      )
      .subscribe((response) => {
        if (Object(response).code == 200) {
          this.factura = Object(response).data;
          for (
            let index = 0;
            index < this.factura.notas_credito.length;
            index++
          ) {
            const element = this.factura.notas_credito[index];
            if (element.status == "N") {
              this.ncLengthAnulado++;
            }
          }
        } else {
          this.configService.showAlert
            (
              Object(response).message,
              "alert-danger",
              true
            );
        }
      });
  }

  documentos = [];

  /**
   * @name "Listar Documentos"
   * @descriptio "Esta Função permite Listar todos Documentos"
   * @author "caniggia.moreira@itgest.pt"
   * @param start
   * @param end
   *
  private getDocumentos() {
    this.http.__call("documento/show", null).subscribe((data) => {
      this.documentos = data.filter(function (documento) {
        return (
          documento.sigla === "FT" ||
          documento.sigla === "NC" ||
          documento.sigla === "FR"
        );
      });
    });
  }*/

  routerGerarRecibo(cliente_id: number, name: string) {
    this._route.navigate(['/vendas/recibo/gera-recibo'], { queryParams: { c: cliente_id, n: name } });
  }

  clearSearch() {
    this.filter.search = "";
  }

  async getEmpresa() {
    await this.http.call_get('empresa/empresa-user', null).subscribe(
      response => {
        this.empresa.sigla_empresa = Object(response).data.sigla_empresa
      }
    )
  }
}
