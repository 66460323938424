import { finalize } from 'rxjs/operators';

import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ReciboService } from 'src/app/components/reports/recibo/recibo.service'
// ES6 Modules or TypeScript
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'

@Component({
	selector: 'recibo',
	templateUrl: './recibo.component.html',
	styleUrls: ['./recibo.component.css']
})
export class ReciboComponent implements OnInit {

	private search: string;
	public cliente_id = null
	submitted = false;
	disable = false;
	public view_btn_pagador = true

	loading = false;
	//desabe_is_adiantamento: boolean
	private facturasPagador_alternativo: any = []
	lista_dos_adiantamentos: any = []
	adiantamento = []
	listMovimento = []
	private listCaucaos: any
	private totalAdiantamento = 0;

	private isChecked = true;
	private recibo: any;
	adianntamento_como_default: any

	reciboForm: FormGroup;

	private validateButton: boolean = true;
	private carregando = {
		serie: 'Seleccione a serie',
		forma_pagamento: 'Seleccione a forma de pagamento',
		banco: 'Seleccione conta da bancaria'
	}

	constructor(
		private http: HttpService,
		private configService: ConfigService,
		private formBuilder: FormBuilder,
		private PDFrecibo: ReciboService,
		private route: ActivatedRoute,
		private router: Router
	) {
		this.http.__call(`configuracao/getConfiguracaobySlug/adianntamento_como_default`, null)
			.subscribe(response => {
				if (Object(response).data.valor == "true") {
					this.adianntamento_como_default = '1'

				} else {
					this.adianntamento_como_default = '0'

				}
			})

		this.listarFormaPagamentos();
		this.configService.listarBancos();
		this.createForm();
	}



	ngOnInit() {
		const param = this.route.queryParams.subscribe(params => {
			const id = params['c'] || 0;
			const name = params['n'] || 0;
			if (id > 0 && name) {
				this.getSeriesRecibos();
				this.setCliente({ id: id, nome: name });
				this.reciboForm.get('nome').disable();
			}
		});
		this.setDefault()
	}

	private selecionarTodos(event) {

		this.facturasSeleciondas = [];
		this.reciboForm.get('total_pago').reset();

		if (event.target.checked) {
			this.marcar_tudo(event)
		} else {
			this.desmarcar_tudo(event)
		}
	}

	marcar_tudo(event) {
		for (let factura of this.facturas) {
			console.log('event', event.target.checked)
			factura.checkbox = event.target.checked
			this.SeleccionarFactura(factura)
		}
	}

	desmarcar_tudo(event) {
		for (let factura of this.facturas) {
			factura.checkbox = event.target.checked
		}
	}

	ngOnDestroy() {
		//this.sub.unsubscribe();
	}
	listarBancosById(id) {
		this.configService.listarBancosById(id)
	}
	createForm() {
		this.reciboForm = this.formBuilder.group({
			nome: [''],
			cliente_id: [null, Validators.required],
			serie_id: [this.default],
			numero_contrato: [null],
			forma_pagamento_id: [null],
			banco_id: [{ value: null, disabled: true }],
			referencia: [{ value: null, disabled: true }],
			data_pagamento: [{ value: null, disabled: true }],
			keyFilter: [''],
			observacao: '',
			valor_recebido: [0],
			total_valor_recebido: [0, Validators.required],
			troco: [0, { disabled: true }],
			total_pago: [{ disabled: true }],
			total_saldado: [{ disabled: true }],
			valor_falta_pagar: [0],
			facturas: [{ value: null }],
			is_adiantamento: [this.adianntamento_como_default],
			adiantamentos: [{ value: null }],
			itemCaucao: null,
			contrato_id: null,
			linha_pagamentos: [{ value: null }],
		});

		this.adiantamento = []
		this.listMovimento = []

		this.facturas = [];
		this.facturasSeleciondas = []
		this.linha_pagamentos = []
		this.view_facturas = false
		this.configService.loaddinStarter('stop');
		this.loading = false;
	}

	// convenience getter for easy access to form fields
	get f() {
		return this.reciboForm.controls;
	}

	onReset() {

		this.submitted = false;
		//this.reciboForm.reset();

		this.reciboForm.get('forma_pagamento_id').reset();
		this.reciboForm.get('valor_recebido').reset();
		this.reciboForm.get('data_pagamento').reset();
		this.reciboForm.get('referencia').reset();
		this.reciboForm.get('banco_id').reset();

		this.reciboForm.get('troco').reset();
		this.reciboForm.get('total_valor_recebido').reset();
		this.reciboForm.get('total_saldado').reset();

		this.linha_pagamentos = []
		this.lista_dos_adiantamentos = []
		this.adiantamento = []
		this.facturas = [];
		this.listMovimento = [];
		this.facturasSeleciondas = []
		this.view_facturas = false
		this.configService.loaddinStarter('stop');
		this.loading = false;

	}

	public gerarFacturaByClienteDivida() {
		this.router.navigate(['facturacao/'], { queryParams: { cliente_id: this.cliente_id, contrato_id: this.contrato_id } });
	}

	getFacturasWithDividas() {
		this.http.__call("dividas/facturas_with_dividas",
			{ facturasSeleciondas: this.facturasSeleciondas }
		).subscribe(res => {
			if (Object(res).existFacturas) {
				this.gerarFacturaByClienteDivida()
			}
		})
	}

	onSubmit() {
		this.submitted = true;

		if (this.reciboForm.invalid) {
			return;
		}

		if (this.facturasSeleciondas.length == 0) {
			this.configService.showAlert('Nenhuma factura seleccionada', "alert-danger", true);
			return;
		} else if (this.linha_pagamentos.length == 0) {
			this.configService.showAlert('Nenhuma forma de pagamento seleccionada', "alert-danger", true);
			return;
		}

		//this.loading = true
		const _validarAd = this.linha_pagamentos.find(l => l.designacao === 'Adiantamento');
		this.lista_dos_adiantamentos = _validarAd == null ? [] : this.lista_dos_adiantamentos;
		/*
		console.log(this.facturasSeleciondas[0].data_vencimento)
		var data_inicio = new Date(this.facturasSeleciondas[0].data_vencimento)
		data_inicio.setDate(data_inicio.getDate()+ 15);
		console.log(moment(data_inicio).format("YYYY-MM-DD"))
		*/

		this.reciboForm.patchValue({
			facturas: this.facturasSeleciondas,
			adiantamentos: this.lista_dos_adiantamentos,
			linha_pagamentos: this.linha_pagamentos,
			itemCaucao: this.listCaucaos,
			contrato_id: this.contrato.numero_contrato
		});

		const uri = 'recibo/gerarRecibo';
		console.log(this.reciboForm.value)
		this.createOrEdit(uri, this.reciboForm, true);



	}

	createOrEdit(uri: any, reciboFormulario: FormGroup, isCreate: boolean) {
		this.validateButton = false;
		var nome: string;
		this.http.__call(uri, reciboFormulario.value).pipe(finalize(() => { this.loading = false; this.validateButton = true; this.submitted = false; })).subscribe(
			res => {
				this.printer_recibo = false;
				nome = reciboFormulario.value.nome
				this.recibo = Object(res).data.recibo.id;
				this.reciboForm.patchValue({ cliente_id: Object(res).data.recibo.cliente_id, nome: nome });
				//this.facturasOfRecibos(Object(res).data.recibo.id);
				this.Valoradiantamentos(Object(res).data.recibo.cliente_id)
				this.sucessoRecibo(Object(res).data)
				this.getFacturasWithDividas()
				this.onReset();
				this.configService.showAlert(Object(res).message, 'alert-success', true)
			}, error => {
				//this.errorReferenciasRecibo(Object(res).data)
				this.validateButton = true;
				this.loading = false
			}
		)

	}


	private activarAdiantamento = false
	private activarCaucao = false

	handleChangeFormaPagamento() {
		this.reciboForm.patchValue({
			valor_recebido: '',
			troco: 0,
			is_adiantamento: this.adianntamento_como_default
		});
		this.adiantamento = []
		this.listMovimento = []

		this.listCaucaos = null;
		this.activarAdiantamento = false
		this.activarCaucao = false
		this.reciboForm.get("valor_recebido").enable();
		if (this.reciboForm.getRawValue().forma_pagamento_id != null && !this.verificaForma(this.reciboForm.getRawValue().forma_pagamento_id)) {

			this.reciboForm.get('data_pagamento').enable();
			this.reciboForm.get('referencia').enable();
			this.reciboForm.get('banco_id').enable();
		} else {
			this.reciboForm.get('data_pagamento').reset();
			this.reciboForm.get('data_pagamento').disable();
			this.reciboForm.get('referencia').reset();
			this.reciboForm.get('referencia').disable();
			this.reciboForm.get('banco_id').reset();
			this.reciboForm.get('banco_id').disable();
		}

		this.formasPagamentos.forEach(element => {
			if (element.designacao === 'Adiantamento' && this.reciboForm.getRawValue().forma_pagamento_id == element.id) {
				this.lista_dos_adiantamentos = []

				this.reciboForm.get("valor_recebido").disable();
				this.activarAdiantamento = true;
				this.getMovimentoAdiantamento();
			}

		});

		this.formasPagamentos.forEach(element => {
			if (element.designacao === 'Caução' && this.reciboForm.getRawValue().forma_pagamento_id == element.id) {
				this.reciboForm.get("valor_recebido").disable();
				this.activarCaucao = true;
				this.getMovimentoCaucao();
			}

		});

	}
	private getMovimentoCaucao() {
		this.configService.loaddinStarter('start');
		this.http.call_get('contrato/caucao/' + this.contrato.numero_contrato, null)
			.subscribe(
				response => {
					this.listCaucaos = Object(response);

					if (this.listCaucaos) {
						this.reciboForm.patchValue({
							valor_recebido: this.listCaucaos.valor_caucao.toFixed(2),
							total_valor_recebido: this.listCaucaos.valor_caucao.toFixed(2)
						});
					} else {
						this.configService.showAlert('Nenhum resultado encontrado!', 'alert-error', true)
					}
					this.configService.loaddinStarter('stop');
				}
			);
	}



	printer_recibo: boolean = true;
	private series: any = []
	private default: any = []

	private getSeriesRecibos() {
		this.http.__call(`configuracao/getConfiguracaobySlug/adianntamento_como_default`, null)
			.subscribe(response => {
				if (Object(response).data.valor == "true") {
					this.reciboForm.patchValue({
						is_adiantamento: '1',
					})
					this.adianntamento_como_default = '1'
				} else {
					this.reciboForm.patchValue({
						is_adiantamento: '0',
					})
					this.adianntamento_como_default = '0'
				}
			})

		//=============================================================

		this.http.call_get('serie/loja/selectSerieLojaReciboBox', null)
			.subscribe(response => {
				let data = Object(response).data

				this.series = data;
				this.default = this.series[0].id
				this.listarBancosById(data[0].loja_id)
				this.reciboForm.patchValue({
					serie_id: this.default

				})
				this.carregando.serie = 'Seleccione a serie'
			});
	}

	private setDefault() {
		this.reciboForm.patchValue({
			serie_id: this.default,
			is_adiantamento: this.adianntamento_como_default
		})
	}

	private formasPagamentos: any = [];

	private listarFormaPagamentos() {
		this.carregando.forma_pagamento = 'Carregando...'
		this.http.call_get('formaPagamento/formas', null).subscribe(
			response => {

				if (this.totalAdiantamento == 0) {
					this.formasPagamentos = [];
					for (let i = 0; i < Object(response).data.length; i++) {
						if (Object(response).data[i].designacao !== 'Adiantamento') {
							this.formasPagamentos.push(Object(response).data[i]);
						}
					}
				} else {
					this.formasPagamentos = [];
					this.formasPagamentos = Object(response).data;
				}
				this.configService.loaddinStarter('stop');
				this.carregando.forma_pagamento = 'Seleccione a forma de pagamento'
			}
		);
	}

	clearSearch() {
		this.reciboForm.patchValue({ nome: '' })
		this.view_client = false
	}


	private clientes: any = [];

	view_client = false;

	private getCliente() {
		this.loading = true
		this.facturas = [];
		this.facturasSeleciondas = []
		this.view_facturas = false;
		this.view_client = true;
		this.linha_pagamentos = []

		this.http.__call('cliente/search-clienteByKey', { start: 1, end: 10, search: this.reciboForm.getRawValue().nome, keyFilter: this.reciboForm.getRawValue().keyFilter }).subscribe(
			response => {
				this.clientes = Object(response).data ? Object(response).data.data : [];
				this.configService.loaddinStarter('stop');
				this.loading = false
				this.getSeriesRecibos();
			}
		);
	}

	private setCliente(client: any) {
		console.log(client.id)
		this.createForm();
		this.cliente_id = client.id
		this.reciboForm.patchValue({
			cliente_id: client.id,
			nome: client.nome,
		});
		this.view_client = false;
		this.facturasCliente(client.id);
		this.facturasClienteSelect(client.id)
		this.Valoradiantamentos(client.id);
		this.listarFormaPagamentos();
		this.view_btn_pagador = false
	}



	view_facturas = false;
	private facturas: any = []
	private facturasSelect: any = []

	private facturasClienteSelect(client: number) {
		this.loading = true
		this.configService.loaddinStarter('start');

		this.http.__call('factura/notpay', { cliente_id: client, numero_contrato: null }).subscribe(
			res => {
				if (Object(res).code == 500) {
					this.configService.showAlert(Object(res).message, "alert-danger", true);
					this.configService.loaddinStarter('stop');
				} else {
					this.facturasSelect = Object(res).data;
					this.configService.loaddinStarter('stop');
				}
				this.loading = false;
			}, error => {
				this.loading = false;
			}
		);
	}

	private facturasCliente(client: number) {
		this.loading = true
		this.configService.loaddinStarter('start');

		this.http.__call('factura/notpay', { cliente_id: client, numero_contrato: this.reciboForm.value.numero_contrato }).subscribe(
			res => {
				if (Object(res).code == 500) {
					this.configService.showAlert(Object(res).message, "alert-danger", true);
					this.configService.loaddinStarter('stop');
				} else {
					this.view_facturas = true
					this.facturas = Object(res).data;
					this.selecionarTodosDefualt(true)
					this.configService.loaddinStarter('stop');
				}
				this.loading = false;
			}, error => {
				this.loading = false;
			}
		);
	}

	private selecionarTodosDefualt(checked) {

		this.facturasSeleciondas = [];
		this.reciboForm.get('total_pago').reset();

		if (checked == true) {
			this.marcarTudoDefault(checked)
		}
	}

	marcarTudoDefault(checked) {
		for (let factura of this.facturas) {
			factura.checkbox = checked
			this.SeleccionarFactura(factura)
		}
	}

	private facturasOfRecibos(recibo_id: number) {
		this.loading = true
		this.configService.loaddinStarter('start');

		this.http.__call('factura_of_recibo/notpay', { recibo_id: recibo_id }).subscribe(
			res => {
				if (Object(res).code == 500) {
					this.configService.showAlert(Object(res).message, "alert-danger", true);
					this.configService.loaddinStarter('stop');
				} else {
					this.view_facturas = true
					this.facturas = Object(res).data;
					this.configService.loaddinStarter('stop');
				}
				this.loading = false;
			}, error => {
				this.loading = false;
			}
		);
	}


	private selecionarFacturaPagadorAlternativo(cliente_id: any) {
		this.loading = true
		this.configService.loaddinStarter('start');


		this.http.__call('factura/pagadorAlternativo', { cliente_id: cliente_id }).subscribe(
			res => {
				if (Object(res).code == 500) {
					this.configService.showAlert(Object(res).message, "alert-danger", true);
					this.configService.loaddinStarter('stop');
				} else {
					this.view_facturas = true
					this.facturasPagador_alternativo = Object(res).data;
					this.configService.loaddinStarter('stop');
					this.reciboForm.patchValue({
						cliente_id: this.facturasPagador_alternativo[0].cliente_id
					})
				}
				this.loading = false;
			}, error => {
				this.loading = false;
			}
		);
		this.facturas = []
		this.facturasPagador_alternativo.forEach(element => {
			element.is_copagador = true
			this.facturas.push(element)
		})

		//this.view_btn_pagador = true
	}

	private Valoradiantamentos(cliente_id: number) {

		this.http.__call("adiantamento/factura/adiantamentoContaCliente", { cliente_id: cliente_id }).subscribe(response => {
			if (Object(response).data.length == 0) {
				this.totalAdiantamento = 0;
			} else {
				this.totalAdiantamento = Object(response).data[0].valor;
				this.listarFormaPagamentos();
			}
		})
	}


	private _total_pago: any = 0;

	private totalApagar() {
		let total_valor_recebido = Number(this.reciboForm.getRawValue().total_valor_recebido)

		var ve = (total_valor_recebido + this.total_adiantamento())
		this.printer_recibo = true;
		var total = 0;
		var total_saldado = 0
		this._total_pago = 0

		this.reciboForm.patchValue({
			total_pago: null,
			total_saldado: null
		});

		this.facturasSeleciondas.forEach(element => {
			total += element.valor_aberto
			var factura = element;
			factura.valor_aberto = (factura.valor_aberto == null && factura.pago == 0 ? factura.total : factura.valor_aberto);// Atribui o valor total caso o valor em aberto for null
			var va = factura.valor_aberto; // valor actual em aberto da factura

			var saldado = 0  // recebe o valor saldado na factura
			if (ve > 0) {
				ve = ve - va;
				saldado = (ve < 0 ? ve - va * -1 : va) // calcula o valor saldado
				total_saldado += saldado
			}
		});

		this.reciboForm.patchValue({
			total_pago: total.toFixed(2),
			//valor_recebido: total.toFixed(2),
			total_saldado: total_saldado.toFixed(2)
		});
		this._total_pago = total.toFixed(2)
		this.calcularTroco()
	}

	private calcularTroco() {
		let total_valor_selecionado = Number(this.reciboForm.getRawValue().total_pago)
		let total_valor_recebido = Number(this.reciboForm.getRawValue().total_valor_recebido)
		let total_adiantamentos = this.total_adiantamento()
		let total_troco = ((total_adiantamentos + total_valor_recebido) - total_valor_selecionado)

		this.reciboForm.patchValue({ troco: 0 });

		if (total_troco > 0) {
			this.reciboForm.patchValue({
				troco: total_troco.toFixed(2)
			})
		}
	}

	private clerInputsAfterAddLinha(e) {
		this.reciboForm.patchValue({
			total_valor_recebido: (this.reciboForm.getRawValue().valor_recebido).toFixed(2),
		});
		e.target.elements[2].value = null;
		e.target.elements[3].value = null;
	}


	private btnImprimirRecibo() {
		this.PDFrecibo.imprimirRecibo(this.recibo, 'Original');
	}

	private facturasSeleciondas = []
	private contrato: any;
	private contrato_id: any;


	private SeleccionarFactura(factura: any) {
		this.contrato = factura;
		this.contrato_id = factura.contrato_id;
		console.log(this.facturasSeleciondas.length)
		if (this.facturasSeleciondas.length == 0) {
			factura.valor_aberto = (factura.valor_aberto == null && factura.pago == 0 ? factura.total : factura.valor_aberto);
			this.facturasSeleciondas.push(factura);
		} else {
			var validar = 0
			for (let i = 0; i < this.facturasSeleciondas.length; ++i) {
				if (this.facturasSeleciondas[i].id === factura.id) {
					this.facturasSeleciondas.splice(i, 1);
					validar = 1;
				}
			}
			if (validar === 0) {
				factura.valor_aberto = (factura.valor_aberto == null && factura.pago == 0 ? factura.total : factura.valor_aberto);
				this.facturasSeleciondas.push(factura);
			}
		}

		//Calcula o Total da Factura
		this.totalApagar();
	}

	sucessoRecibo(response: any) {
		var facts = ''
		response.facts.forEach(element => {
			facts += '<tr>'
				+ '<td>' + element.factura_sigla + '</td>'
				+ '<td>' + this.configService.numberFormat(element.saldado) + '</td>'
				+ '<td>' + this.configService.numberFormat(element.va_ant) + '</td>'
				+ '<td>' + this.configService.numberFormat(element.va_new) + '</td>'
				+ '</tr>'
		});
		var html = '<div style="text-align: left;margin: 13px 20px;width: 700px;padding: 9px 23px;background: #f8f9fa;border-radius: 5px;">'
			+ '<h4>Estatística</h4><hr/>'
			+ '<table class="table table-hover table-bordered table-striped text-center">'
			+ '<thead>'
			+ '<tr>'
			+ '<th> Factura </th>'
			+ '<th> V.Saldado </th>'
			+ '<th> V.Aberto Anterior </th>'
			+ '<th> V.Aberto Novo </th>'
			+ '</tr>'
			+ '</thead>'
			+ '<tbody>'
			+ facts +
			+ ' </tbody>'
			+ '</table>'
			+ '<b>Facturas Saldadas:</b> ' + (response.contTotalFacturaSaldadas) + '<br>'
			+ '<b>Facturas em aberto: </b>' + (response.contTotalFacturaAbertas) + "<br>"
			+ '<b>Facturas não Avaliadas: </b>' + (response.contTotalFacturaNaoAvaliadas) + '</div>'

		const swalWithBootstrapButtons = Swal.mixin({

			customClass: {
				container: 'container-class',
				popup: 'popup-class',
				header: 'header-class',
				title: 'title-class',
				closeButton: 'close-button-class',
				icon: 'icon-class',
				content: 'content-class',
				input: 'input-class',
				actions: 'actions-class',
				confirmButton: 'btn btn-default',
				cancelButton: 'btn btn-success',
				footer: 'footer-class'
			},
			width: '49em',
			buttonsStyling: false,
		})

		swalWithBootstrapButtons.fire({

			html: html,
			title: 'Recibo Gerado',
			text: "Você não poderá reverter isso!",
			type: 'success',
			showCancelButton: true,
			confirmButtonText: 'Continuar',
			cancelButtonText: 'Imprimir',
			reverseButtons: true,
		}).then((result) => {
			if (result.value) {
				//window.location.reload()
			} else if (
				// Read more about handling dismissals
				result.dismiss === Swal.DismissReason.cancel
			) {
				swalWithBootstrapButtons.fire(
					'Aguarde',
					'Aguarde, estamos a gerar a impressão do recibo',
					'success'
				)
				this.btnImprimirRecibo();
				//window.location.reload()
			}
		})

	}

	errorReferenciasRecibo(response: any) {
		var facts = ''
		response.facts.forEach(element => {
			facts += '<tr>'
				+ '<td>' + element.banco_nome + '</td>'
				+ '<td>' + element.referencia + '</td>'
				+ '</tr>'
		});
		var html = '<div style="text-align: left;margin: 13px 20px;width: 700px;padding: 9px 23px;background: #f8f9fa;border-radius: 5px;">'
			+ '<h4>As Referencias Bancarias listadas abaixo já foram utilizadas</h4><hr/>'
			+ '<table class="table table-hover table-bordered table-striped text-center">'
			+ '<thead>'
			+ '<tr>'
			+ '<th> Banco </th>'
			+ '<th> Referencia </th>'
			+ '</tr>'
			+ '</thead>'
			+ '<tbody>'
			+ facts +
			+ ' </tbody>'
			+ '</table>'
			+ '</div>'

		const swalWithBootstrapButtons = Swal.mixin({

			customClass: {
				container: 'container-class',
				popup: 'popup-class',
				header: 'header-class',
				title: 'title-class',
				closeButton: 'close-button-class',
				icon: 'icon-class',
				content: 'content-class',
				input: 'input-class',
				actions: 'actions-class',
				confirmButton: 'btn btn-success',
				footer: 'footer-class'
			},
			width: '49em',
			buttonsStyling: false,
		})

		swalWithBootstrapButtons.fire({

			html: html,
			title: 'Erro Referencias Bancarias',
			text: "Você não poderá reverter isso!",
			type: 'error',
			showCancelButton: false,
			confirmButtonText: 'Continuar',
			reverseButtons: true,
		}).then((result) => {
			if (result.value) {
				//window.location.reload()
			}
		})

	}


	//--------------------------------------------------------------
	private linha_pagamentos: any = []

	deleteRowFormaPagamento(linha: any) {
		if (linha.designacao == "Adiantamento") {
			this.lista_dos_adiantamentos = []
			this.adiantamento = []
		}

		for (let i = 0; i < this.linha_pagamentos.length; ++i) {
			if (this.linha_pagamentos[i].forma_pagamento_id === linha.forma_pagamento_id) {
				this.linha_pagamentos.splice(i, 1);
				this.calcularValorTotalRecebido();
				this.totalApagar()
				this.calcularValorFaltarPagar();
			}
		}

	}

	private validateModal() {
		this.submitted = true;

		if (this.reciboForm.invalid) {
			return;
		}

		if (this.facturasSeleciondas.length == 0) {
			this.configService.showAlert('Nenhuma factura seleccionada', "alert-danger", true);
			return;
		} else if (this.linha_pagamentos.length == 0) {
			this.configService.showAlert('Nenhuma forma de pagamento seleccionada', "alert-danger", true);
			return;
		}

		// this.modal()
	}

	private validateValorEntradaModal() {
		Swal.fire({
			title: `Deseja confimar e continuar com o valor de entrada ${this.configService.numberFormat(this.reciboForm.getRawValue().total_valor_recebido)} AOA?`,
			type: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Confirmar',
			cancelButtonText: 'Cancelar!',
			reverseButtons: true
		}).then((result) => {
			if (result.value) {
				this.modal()
			} else {
				Swal.fire('Operação Cancelada!', '', 'info')
			}
		})
	}

	private modal() {
		/* if(value.troco<0 && value.is_adiantamento!=1)return */
		if (this.reciboForm.getRawValue().troco > 0 && this.reciboForm.getRawValue().is_adiantamento == '0') {
			const swalWithBootstrapButtons = Swal.mixin({
				customClass: {
					confirmButton: 'btn btn-success',
					cancelButton: 'btn btn-danger',
				},
				buttonsStyling: false,
			})

			swalWithBootstrapButtons.fire({

				title: '<strong>Esta cobrança irá gerar troco, pretende proceder?</strong>',
				type: 'error',
				showCloseButton: true,
				showCancelButton: true,
				confirmButtonText: 'Continuar',
				cancelButtonText: 'Cancelar'
			}).then((result) => {
				if (result.value) {
					this.onSubmit()
				} else if (
					/* Read more about handling dismissals below */
					result.dismiss === Swal.DismissReason.cancel
				) {
				}

			})
		} else {
			this.onSubmit()
		}
	}

	private addLinhaPagamentos() {
		var validar = 0;
		var forma = null
		var banco_nome = null

		this.configService.bancos.forEach(element => {
			if (this.reciboForm.getRawValue().banco_id == element.id) {
				banco_nome = element.abreviatura + ' - ' + element.numero_conta;
			}
		});

		this.formasPagamentos.forEach(element => {
			if (this.reciboForm.getRawValue().forma_pagamento_id == element.id) {
				forma = element.designacao;
			}
		});

		if (forma == null) {
			this.configService.showAlert('Informa uma forma pagamento valida', "alert-danger", true);
			return;
		} else if (this.reciboForm.getRawValue().valor_recebido == '' || this.reciboForm.getRawValue().valor_recebido < 0) {
			this.configService.showAlert('Informa Valor de Entrada valido', "alert-danger", true);
			return;
		} else {
			if ((this.reciboForm.getRawValue().referencia == null || this.reciboForm.getRawValue().data_pagamento == null || this.reciboForm.getRawValue().banco_id == null) && !this.verificaForma(this.reciboForm.getRawValue().forma_pagamento_id)) {
				if (forma !== "Adiantamento") {
					this.configService.showAlert('Informa Nº conta da bancaria, referencia a bancaria e a data de pagamento', "alert-danger", true);
					return;
				}
			}

			if ((this.reciboForm.getRawValue().referencia != null || this.reciboForm.getRawValue().referencia != "") && !this.verificaForma(this.reciboForm.getRawValue().forma_pagamento_id)) {

				var code = 0

				this.linha_pagamentos.forEach(element => {
					if (this.reciboForm.getRawValue().referencia === element.referencia && this.reciboForm.getRawValue().banco_id === element.banco_id) {
						code++;
					}
				});

				this.validationReferenciaBancaria(this.reciboForm.getRawValue().referencia, this.reciboForm.getRawValue().banco_id);
				if (this.codeReferencia == 0 || code != 0 && (this.reciboForm.getRawValue().referencia != null || this.reciboForm.getRawValue().banco_id != null)) {
					this.configService.showAlert("A refêrencia bancaria já foi utilizada", "alert-danger", true);
					return;
				}
			}

			var linha = {
				designacao: forma,
				valor_recebido: this.reciboForm.getRawValue().valor_recebido,
				data_pagamento: this.reciboForm.getRawValue().data_pagamento,
				forma_pagamento_id: this.reciboForm.getRawValue().forma_pagamento_id,
				referencia: this.reciboForm.getRawValue().referencia,
				banco_id: this.reciboForm.getRawValue().banco_id,
				banco_nome: banco_nome
			}

			if (this.linha_pagamentos.length >= 1) {
				for (let index = 0; index < this.linha_pagamentos.length; index++) {
					const pagamento = this.linha_pagamentos[index];

					if ((pagamento.forma_pagamento_id == linha.forma_pagamento_id) && (pagamento.banco_id == linha.banco_id)) {

						pagamento.designacao = linha.designacao
						pagamento.valor_recebido += linha.valor_recebido
						pagamento.data_pagamento = linha.data_pagamento
						pagamento.forma_pagamento_id = linha.forma_pagamento_id
						pagamento.banco_id = linha.banco_id
						pagamento.referencia = linha.referencia
						pagamento.banco_nome = linha.banco_nome

						validar = 1;
					}
				}
				if (validar == 0) {
					this.linha_pagamentos.push(linha);
				}
			} else {
				this.linha_pagamentos.push(linha);
			}

			this.calcularValorTotalRecebido();
			this.totalApagar()
			this.calcularValorFaltarPagar();
			this.resetDisableReciboForm();
		}

		this.totalApagar()
	}

	resetDisableReciboForm() {
		this.reciboForm.get('forma_pagamento_id').reset();
		this.reciboForm.get('valor_recebido').reset();
		this.reciboForm.get('data_pagamento').reset();
		this.reciboForm.get('referencia').reset();
		this.reciboForm.get('banco_id').reset();
		this.reciboForm.get('referencia').disable();
		this.reciboForm.get('data_pagamento').disable();
		this.reciboForm.get('banco_id').disable();
	}

	calcularValorFaltarPagar() {
		var valor_falta_pagar = this.reciboForm.getRawValue().total_pago - this.reciboForm.getRawValue().total_valor_recebido;
		this.reciboForm.patchValue({
			valor_falta_pagar: valor_falta_pagar < 0 ? (0).toFixed(2) : (valor_falta_pagar).toFixed(2)
		})
	}

	private calcularValorTotalRecebido() {
		var total_valor_recebido = 0
		this.linha_pagamentos.forEach(element => {
			if (element.designacao !== 'Adiantamento') {
				total_valor_recebido += ((element.valor_recebido));
			}
		});
		this.reciboForm.patchValue({
			total_valor_recebido: (total_valor_recebido).toFixed(2)
		});
	}


	private codeReferencia: any;
	private validationReferenciaBancaria(ref, bank) {
		this.http.__call('referencia/validation', { referencia: ref, banco_id: bank }).subscribe(
			response => {
				this.codeReferencia = Object(response);
			}
		);
	}

	private verificaForma(item) {
		if (item == "") {
			return true
		}
		var chaves = [];
		this.formasPagamentos.forEach(element => {
			if (element.usar_banco === 0) {
				chaves.push("" + element.id);
			}
		})
		if (chaves.length > 0) {
			if (chaves.indexOf(item) > -1) {
				return true
			} else {
				return false
			}
		} else {
			return false
		}
	}


	private getMovimentoAdiantamento() {
		this.configService.loaddinStarter('start');
		this.http.__call('adiantamento/factura/adiantamentoFactura', { cliente_id: this.reciboForm.getRawValue().cliente_id }).subscribe(
			response => {
				this.listMovimento = Object(response).data;
				this.configService.loaddinStarter('stop');
			}
		);
	}

	_add_and_remove_adiantamento(adiantamento: any) {

		const adiantamento1 = this.lista_dos_adiantamentos.find((add) => add.id === adiantamento.id)
		if (!adiantamento1) {
			this.lista_dos_adiantamentos.push(adiantamento)
		}
		else {
			const index = this.lista_dos_adiantamentos.indexOf(adiantamento);
			if (index !== -1) {
				this.lista_dos_adiantamentos.splice(index, 1);
			}
		}
	}

	setAdianta(adiantamento: any, checked: boolean) {
		//const ad_exist = this.adiantamento.find((add) => add.id === adiantamento.id)
		let valor = adiantamento.valor
		let valor_recebido = Boolean(this.reciboForm.getRawValue().valor_recebido) ? this.reciboForm.getRawValue().valor_recebido : 0
		let result = 0

		if (checked) {
			result = Math.abs(valor + valor_recebido)
			this.lista_dos_adiantamentos.push(adiantamento)
		} else {
			result = Math.abs(valor - valor_recebido)
			const index = this.lista_dos_adiantamentos.indexOf(adiantamento);
			if (index !== -1) {
				this.lista_dos_adiantamentos.splice(index, 1);
			}
		}

		this.reciboForm.patchValue({ valor_recebido: result });
		this.totalApagar()
	}

	total_adiantamento() {
		let total_a_pagar = this.linha_pagamentos.filter(item => item.designacao == 'Adiantamento').reduce((accum, item) => accum + item.valor_recebido, 0)
		return Number(total_a_pagar)
	}

	private plano_prestacao: any = []
	private getPlanoPrestacaoOfContrato() {
		if (this.contrato_id == null || this.contrato_id == 'null') {
			this.configService.showAlert("Selecione uma factura que tenha contrato!", "alert-danger", true);
		} else {
			this.loading = true
			this.http.__call('plano-prestacao/Ofcontrato', { contrato_id: this.contrato_id }).subscribe(
				response => {
					this.plano_prestacao = Object(response).data;
					($("#planoPrestacaoListModal") as any).modal("show")
					this.loading = false
				}
			);
		}
	}

	private linha_prestacao: any = []
	private plano_header: any;
	private getLinhaPrestacao(item) {
		this.plano_header = item
		this.configService.loaddinStarter('start');
		this.http.call_get('plano-prestacao/linha-prestacao/' + item.id, null).subscribe(
			response => {
				this.linha_prestacao = Object(response).data;
				this.configService.loaddinStarter('stop');
			}
		);
	}

}

