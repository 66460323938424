import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-conf-tipo-local-instalacao',
  templateUrl: './conf-tipo-local-instalacao.component.html',
  styleUrls: ['./conf-tipo-local-instalacao.component.css']
})
export class ConfTipoLocalInstalacaoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
