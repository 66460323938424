import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

import { Router,ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl  } from '@angular/forms';

import { ExcelService } from 'src/app/services/excel.service';
import { ReportResumoContaCorrenteService } from 'src/app/components/report-at/relatorios/financeira/report-resumo-conta-corrente.service';

import * as moment from 'moment';
import { ExcelAutoService } from 'src/app/services/excel/excel-auto.service';
import { finalize } from 'rxjs/operators';
import { ApiService } from 'src/app/providers/http/api.service';

@Component({
  selector: 'app-relatorio-iva.component',
  templateUrl: './relatorio-iva.component.html',
  styleUrls: ['./relatorio-iva.component.css']
})
export class RelatorioIvaComponent implements OnInit {

  constructor() {
    // this.createForm();
  }

  ngOnInit() {
    // this._getLojas();
    // this.empresaUser()
  }

  Submit(event){
    event=event
    console.log('event=',event)
  }


}
