import { HttpEvent, HttpEventType } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConfigService } from "src/app/providers/config/config.service";
import { HttpService } from "src/app/providers/http/http.service";
import Swal from "sweetalert2";

@Injectable({
  providedIn: "root",
})
export class ImportDataService {
  public item: [];
  public progress = 0;
  public checkIfIsUploadOrDownload = true;
  public disabledButton: boolean = true;

  constructor(private http: HttpService) {}

  public compliteModalUploaded(title = '') {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: title,
        type: "warning",
        confirmButtonText: "OK!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          console.log("feito");
        }
      });
  }
  getData(uri: string, title :string, simpleFormulario: any, filters: any, excelData: any) {
    this.item = [];
    this.http
      .__callForEvent(uri, { ...simpleFormulario, ...filters })
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            console.log("Request has been made!");
            break;
          case HttpEventType.ResponseHeader:
            console.log("Response header has been received!");
            break;
          case HttpEventType.UploadProgress:
            this.checkIfIsUploadOrDownload = true;
            this.progress = 10;
            console.log("upload");
            break;
          case HttpEventType.DownloadProgress:
            this.checkIfIsUploadOrDownload = false;
            this.progress = Math.round((event.loaded / event.total) * 100);
            console.log("downloading ..");
            break;
          case HttpEventType.Response:
            console.log('data finished to download',Object(event))
            this.item = Array.isArray(Object(event).body.data) ?
             Object(event).body.data :
             Object(event).body.data.debitos

            if (Object(event).body.data != 0) {
              this.disabledButton = false;
              excelData(this.item);
            }

            setTimeout(() => {
              this.progress = 0;

              this.compliteModalUploaded(title);
            }, 1500);
        }
      });
  }
}
