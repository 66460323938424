import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-create-or-edit-departamento',
  templateUrl: './create-or-edit-departamento.component.html',
  styleUrls: ['./create-or-edit-departamento.component.css']
})
export class CreateOrEditDepartamentoComponent implements OnInit {




  @Input() modal: string = "modalCreateOrEdit";
  @Input() title: string = "Registar";
  @Input() departamentos: any;
  @Input() departamento: any;
  @Input() funcoes: any;

  submitted = false;
  formErrors: any;
  private loading: boolean = false;
  private ver: boolean = true;
  @Input() formCreateOrEditDepartamento: FormGroup;
  @Input() formCreateOrEditFuncao: FormGroup;
  

  @Output() private loadList = new EventEmitter<any>();

  private empresas: any = [];
  private lojas: any = [];
  private roles: any = [];

  private isDisabled: boolean = false;

  private funcao = {
    id: null,
    descricao: null,
    codigo: null
  }

  constructor(private http: HttpService, private configService: ConfigService, private formBuilder: FormBuilder) {
    this.createForm();
    this.createFormFuncao();
  }

  ngOnInit() {

  }

  createForm() {
    this.formCreateOrEditDepartamento = this.formBuilder.group({
      index: [{ value: null, disabled: true }],
      descricao: [null, Validators.required],
      codigo: [null],
      email: [null],
      flag: [null]
    })
  }

  createFormFuncao() {
    this.formCreateOrEditFuncao = this.formBuilder.group({
      index: [{ value: null, disabled: true }],
      descricao: [null, Validators.required],
      codigo: [null],
    })
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.formCreateOrEditDepartamento.controls;
  }

  get g() {
    return this.formCreateOrEditFuncao.controls;
  }

  onReset() {
    this.submitted = false;
    this.formCreateOrEditDepartamento.reset();
    this.formCreateOrEditFuncao.reset();
  }


  onSubmit() {

    this.submitted = true;

    // parar aquei se o formulário for inválido
    if (this.formCreateOrEditDepartamento.invalid) {
      return;
    }
    this.loading = true;
    const index = this.formCreateOrEditDepartamento.getRawValue().index;
    // TODO: usado para fazer a requisição com a api de criação de objsct or update
    const uri = (index === null ? 'departamento/register' : 'departamento/update/' + index);
    this.createOrEdit(uri, this.formCreateOrEditDepartamento, (index === null ? true : false));

  }

  createOrEdit(uri: any, formulario: FormGroup, isCreate: boolean) {

    // TODO: usado para fazer a requisição com a api de criação de object
    this.http.__call(uri, formulario.value).pipe(first()).subscribe(
      response => {
        this.submitted = false;
        this.loading = false;
        if (isCreate && Object(response).code == 200) {
          formulario.reset();
        }

        if (Object(response).code == 200) {
          formulario.reset();
          this.loadList_Departamento(Object(response).data);
        }
      },
      error => {
        this.submitted = false;
        this.loading = false;
      });
  }

  private sincronizar() {

    this.isDisabled = true;
    this.loading = true;

    this.http.call_get("funcao-departamento/sincronizacaoPrimavera", null).subscribe((res: any) => {
      if (Object(res).code == 200) {

        this.getFuncoesByDep();;

        this.isDisabled = false;
      }

    });
  }


  //RULES ABOUT FUNÇÃO

  onSubmitFormFuncao() {

    this.submitted = true;

    // parar aquei se o formulário for inválido
    if (this.formCreateOrEditFuncao.invalid) {
      return;
    }
    this.loading = true;
    const index = this.formCreateOrEditFuncao.getRawValue().index;
    // TODO: usado para fazer a requisição com a api de criação de objsct or update
    const uri = (index === null ? 'funcao/register/' + this.departamento.id : 'funcao/update/' + index);
    this.createOrEditFuncao(uri, this.formCreateOrEditFuncao, (index === null ? true : false));

  }

  createOrEditFuncao(uri: any, formulario: FormGroup, isCreate: boolean) {

    // TODO: usado para fazer a requisição com a api de criação de object
    this.http.__call(uri, formulario.value).pipe(first()).subscribe(
      response => {
        this.submitted = false;
        this.loading = false;
        if (isCreate && Object(response).code == 200) {
          formulario.reset();
        }

        if (Object(response).code == 200) {
          this.getFuncoesByDep();
        }
      },
      error => {
        this.submitted = false;
        this.loading = false;
      });
  }


  private getFuncoesByDep() {

    this.configService.loaddinStarter('start');
    this.http.call_get('funcao/funcaoesByDepartamento/' + this.departamento.id, null).subscribe(
      response => {
        this.funcoes.funcoes = Object(response).data;
        this.configService.loaddinStarter('stop');
        this.loading = false;
      }
    );
  }

  private setDataFuncao(item) {
    this.funcao.id = item.id;
    this.funcao.descricao = item.descricao;
    this.funcao.codigo = item.codigo;

    this.change(this.funcao.descricao)
  }

  private setDataFuncaoUndefined(item) {
    this.funcao.descricao = item;
    this.change(this.funcao.descricao)
  }
  //END

  change(descricao) {
    if (descricao !== undefined) {
      this.title = "Editar";
     
      this.formCreateOrEditFuncao.patchValue({
        index: this.funcao.id,
        descricao: this.funcao.descricao,
        codigo: this.funcao.codigo
      });
    } else {
      this.onReset()
      this.title = "Registar";
    }

  }


  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {

    if (this.departamentos !== undefined) {

      this.title = "Editar";
      //this.onReset()

      this.formCreateOrEditDepartamento.patchValue({
        index: this.departamentos.id,
        descricao: this.departamentos.descricao,
        codigo: this.departamentos.codigo,
        email: this.departamentos.email,
        flag: this.departamentos.flag
      });

    } else {
      this.onReset()
      this.title = "Registar";

    }
  }

  public loadList_Departamento(departamentos) {
    this.loadList.emit(departamentos);
  }




}
