import { Component, OnInit, Input, createPlatformFactory, OnDestroy } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { ExcelService } from 'src/app/services/excel.service';
import * as moment from 'moment';
import { FormGroup } from '@angular/forms';
import { ConfigModuloService } from 'src/app/services/config-modulo.service';

@Component({
  selector: 'app-conf-rua',
  templateUrl: './conf-rua.component.html',
  styleUrls: ['./conf-rua.component.css']
})
export class ConfRuaComponent implements OnInit, OnDestroy {

  @Input() simpleFormRua: FormGroup;
  private quarteirao_view: boolean = false;
  private distrito_view: boolean = false;

  private rua = {
    id: null,
    nome: null,
    is_active: null,
    has_distrito: null,
    bairro_id: null,
    distrito_id: null,
    municipio_id: null,
    provincia_id: 29,
    user_id: null,
    //ruaModal: false
  };

  @Input() bairros: any[];
  @Input() distritos: any[];
  @Input() municipios: any = [];

  private addRows: boolean = true;
  private title: string = null;

  private items: any = [];
  private ruas: any = [];

  constructor(
    private http: HttpService,
    private configService: ConfigService,
    private excelService: ExcelService,
    private config: ConfigModuloService
  ) { }



  exportAsXLSX(): void {
    var CurrentDate = new Date();
    var nameFile = "lista_ruas -" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    this.excelService.exportAsExcelFile(document.getElementsByClassName("exportAsXLSXCliente")[0], nameFile);
  }

  exportAsPDF(): void {
    //this.reportLoja.relatorioLoja(this.cobrancas, this.simpleForm.value, 'save');
  }

  imprimirPDF(): void {
    //this.reportLoja.relatorioLoja(this.cobrancas, this.simpleForm.value);
  }

  ngOnInit() {
    this.http.filters.search = null
    this.getPageFilterData(1);

    this.getConfiguracaos();
  }

  ngOnDestroy(){
    this.http.filters.orderBy = null
  }

  private getDadosModal() {

  }

  private getRuas() {

    this.configService.loaddinStarter('start');

    this.http.__call('rua/listagem', this.http.filters).subscribe(

      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.configService.loaddinStarter('stop');

      }
    );
  }

  private initRua() {

    this.rua.id = null;
    this.rua.nome = null;
    this.rua.bairro_id = null;
    this.rua.has_distrito = null;
    this.rua.distrito_id = null;
    this.rua.municipio_id = null;
    this.rua.provincia_id = null;
    this.rua.is_active = null;
    this.rua.user_id = null;

    this.distritos = null;
    this.municipios = null;
    this.bairros = null;

    this.addRows = true;
  }

  private setDataRua(item) {

    if (item !== undefined) {
      this.title = "Editar Rua";

      this.rua.id = item.id;
      this.rua.nome = item.nome;
      this.rua.bairro_id = item.bairro_id;

      this.rua.has_distrito = (item.distrito_id > 0) ? true : false;
      this.rua.distrito_id = item.distrito_id;
      this.rua.municipio_id = item.municipio_id;
      this.rua.provincia_id = item.provincia_id;
      this.rua.is_active = item.is_active;
      this.rua.user_id = item.user_id;

      if (this.rua.has_distrito) this.selectBoxDistritosByMunicipio(item.municipio_id);

      this.selectBoxMunicipiosByProvincia(item.provincia_id);
      this.selectBoxBairrosByMunicipio(item.municipio_id);

      this.addRows = false;

      console.log(this.rua);
    }


  }

  private updateStateRua(item) {

    this.rua.id = item.id;
    this.rua.nome = item.nome;
    this.rua.bairro_id = item.bairro_id;
    this.rua.has_distrito = item.has_distrito;
    this.rua.distrito_id = item.distrito_id;
    this.rua.municipio_id = item.municipio_id;
    this.rua.provincia_id = item.provincia_id;
    this.rua.is_active = !item.is_active;
    this.rua.user_id = item.user_id;

    //console.log(item);
    this.http.__call('rua/update/' + this.rua.id, this.rua).subscribe(
      response => {

        if (Object(response).code == 200) {
          var update = (this.rua.is_active == true) ? "Activado" : "Desactivado";

          this.configService.showAlert("Rua " + this.rua.nome + " foi " + update, "alert-success", true);
        }

      }
    );

    for (let i = 0; i < this.items.length; ++i) {
      if (this.items[i].id == this.rua.id) {
        this.items[i].is_active = this.rua.is_active;
      }
    }
  }


  private selectBoxDistritosByMunicipio(id) {

    //console.log(this.rua);

    this.http.call_get('distrito/getDistritosByMunicipio/' + id, null).subscribe(
      response => {
        console.log(Object(response).data);

        this.distritos = Object(response).data;
      }
    );
  }

  private selectBoxBairrosByMunicipio(id) {

    //console.log(this.rua);

    this.http.call_get('bairro/getBairrosByMunicipio/' + id, null).subscribe(
      response => {
        //console.log(Object(response).data);

        this.bairros = Object(response).data;
      }
    );
  }

  private selectBoxMunicipiosByProvincia(id) {

    //console.log(this.rua);

    this.http.call_get('municipio/getMunicipiosByProvincia/' + id, null).subscribe(
      response => {
        //console.log(Object(response).data);

        this.municipios = Object(response).data;
      }
    );
  }


  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
    this.getRuas();
  }

  private getResidenciasByRua(id) {

    this.configService.loaddinStarter('start');

    this.http.call_get('rua/getResidenciasByRua/' + id, this.http.filters).subscribe(

      response => {

        this.ruas = Object(response).data.data;
        this.configService.loaddinStarter('stop');

      }
    );
  }


  public getConfiguracaos() {

    let result = null;

    const slugs = [
      this.config.provincia_default,
      this.config.quarteirao_view,
      this.config.distrito_view
    ];
    //console.log(slugs);

    for (let index = 0; index < slugs.length; index++) {

      //console.log(slugs[index]);
      this.http.__call('configuracao/getConfiguracaobySlug/' + slugs[index], null).subscribe(
        response => {

          //console.log(Object(response));

          if (Object(response).code != 200) {
            //this.config.saveConfig(slugs[index], this.config.modulo.CONFIGURACOES, null);
            result = null;
          }
          else {

            result = Object(response).data;

            if (slugs[index] == this.config.provincia_default) {
              this.rua.provincia_id = result.valor;
              this.selectBoxMunicipiosByProvincia(result.valor);
            }

            if (slugs[index] == this.config.quarteirao_view) this.quarteirao_view = Boolean(result.valor);

            if (slugs[index] == this.config.distrito_view) this.distrito_view = Boolean(result.valor);

          }
        });
    }
  }


}
