import { Component, OnInit, Input, Output, EventEmitter, SimpleChange } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-create-or-edit-hora',
  templateUrl: './create-or-edit-hora.component.html',
  styleUrls: ['./create-or-edit-hora.component.css']
})
export class CreateOrEditHoraComponent implements OnInit {

  @Input() modal: string = "modalCreateOrEdit";
  @Input() title: string = "Registar";
  @Input() resultados: any;

  submitted = false;
  formErrors: any;
  private loading: boolean = false;
  private ver: boolean = true;
  @Input() simpleFormCreateOrEdit: FormGroup;

  @Output() private loadList = new EventEmitter<any>();

  private departamentos: any = [];
  private funcoes: any = [];

  constructor(private http: HttpService, private configService: ConfigService, private formBuilder: FormBuilder) {
    this.createForm();
  }

  ngOnInit() {
  }

  createForm() {
    this.simpleFormCreateOrEdit = this.formBuilder.group({
      index: [{ value: null, disabled: true }],
      descricao: [null, Validators.required],
      minuto: [null],
      hora: [null],
      duracao: [null, Validators.required]
    })
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.simpleFormCreateOrEdit.controls;
  }

  onReset() {
    this.submitted = false;
    this.simpleFormCreateOrEdit.reset();
  }

  keyupDuracao(){

    if(this.simpleFormCreateOrEdit.value.minuto < 0 || this.simpleFormCreateOrEdit.value.minuto > 59){
      this.configService.showAlert("Minuto válido de 0-59", 'alert-danger', true);
    }else if(this.simpleFormCreateOrEdit.value.hora < 0 || this.simpleFormCreateOrEdit.value.hora > 8){
      this.configService.showAlert("Hora válido de 0-8", 'alert-danger', true);
    }else if(this.simpleFormCreateOrEdit.value.minuto != null && this.simpleFormCreateOrEdit.value.hora == null){
      this.simpleFormCreateOrEdit.patchValue({
        descricao: this.simpleFormCreateOrEdit.value.minuto+ "m",
        duracao: this.simpleFormCreateOrEdit.value.minuto
      });
    }else if(this.simpleFormCreateOrEdit.value.hora !=null && this.simpleFormCreateOrEdit.value.minuto == null){
      this.simpleFormCreateOrEdit.patchValue({
        descricao: this.simpleFormCreateOrEdit.value.hora+ "hora",
        duracao: this.simpleFormCreateOrEdit.value.hora*60
      });
    }else if(this.simpleFormCreateOrEdit.value.hora != null && this.simpleFormCreateOrEdit.value.minuto != null){
      this.simpleFormCreateOrEdit.patchValue({
        descricao: this.simpleFormCreateOrEdit.value.hora+ (this.simpleFormCreateOrEdit.value.minuto == 0 ? 'hora' : 'h') +(this.simpleFormCreateOrEdit.value.minuto != 0 ? this.simpleFormCreateOrEdit.value.minuto : ''),
        duracao: (this.simpleFormCreateOrEdit.value.hora*60) + this.simpleFormCreateOrEdit.value.minuto
      });
    }else{
      this.simpleFormCreateOrEdit.patchValue({
        descricao: null,
        duracao: null
      });
    }
    

  }


  onSubmit() {

    this.submitted = true;

    // parar aquei se o formulário for inválido
    if (this.simpleFormCreateOrEdit.invalid) {
      return;
    }
    this.loading = true;
    const index = this.simpleFormCreateOrEdit.getRawValue().index;
    // TODO: usado para fazer a requisição com a api de criação de objsct or update
    const uri = (index === null ? 'duracao/register' : 'duracao/update/' + index);
    this.createOrEdit(uri, this.simpleFormCreateOrEdit, (index === null ? true : false));

  }

  createOrEdit(uri: any, formulario: FormGroup, isCreate: boolean) {

    // TODO: usado para fazer a requisição com a api de criação de object
    this.http.__call(uri, formulario.value).pipe(first()).subscribe(
      response => {
        this.submitted = false;
        this.loading = false;
        if (isCreate && Object(response).code == 200) {
          formulario.reset();
        }

        if (Object(response).code == 200) {
          this.loadList_item(Object(response).data);
        }
      },
      error => {
        this.submitted = false;
        this.loading = false;
      });
  }


  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {

    if (this.resultados !== undefined) {

      this.title = "Editar";
      //this.onReset()

      this.simpleFormCreateOrEdit.patchValue({
        index: this.resultados.id,
        descricao: this.resultados.descricao,
        minuto: this.resultados.minuto,
        hora: this.resultados.hora,
        duracao: this.resultados.duracao,
      });

    } else {
      this.onReset()
      this.title = "Registar";

    }
  }

  public loadList_item(resultados) {
    this.loadList.emit(resultados);
  }

}
