import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-resumo-facturacao',
  templateUrl: './resumo-facturacao.component.html',
  styleUrls: ['./resumo-facturacao.component.css']
})
export class ResumoFacturacaoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  Submit(event){
    event=event
    console.log('event=',event)
  }

}
