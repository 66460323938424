import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ResumoCobrancaService {

  constructor() { }

  public filters={
    artigo_id:null,
    data1:null,
    data2:null,
    operador:null,
    payment:null,
    distrito_id:null
  }
}
