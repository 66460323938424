import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { HttpService } from 'src/app/providers/http/http.service';

@Component({
  selector: 'app-sidebar-reporting',
  templateUrl: './sidebar-reporting.component.html',
  styleUrls: ['./sidebar-reporting.component.css']
})
export class SidebarReportingComponent implements OnInit {

  @Input() data: any[];
  private empresa_sigla: any = [];

  @Output() ChangedMenuEmitter = new EventEmitter();

  public currentUser: any;
  constructor(
       private auth: AuthService,
        private http: HttpService,
         private configService: ConfigService
         ) {
    this.currentUser = this.auth.currentUserValue;
  }

  canActivateRouterLink(permission: string): boolean {
    return this.auth.canActivateRouterLink(permission);
  }

  ngOnInit() {
    this.getEmpresaSigla();
  }

  toggleMenuSecound(event: any, data: any[]): void {
    let currentAction = event.currentTarget;
     console.log(data)
    $(".report-nav").removeClass("active");
    $(".direct-chat-text").removeClass("active");

    $(currentAction).addClass("active");
    $(currentAction).children(":first").children(":first").addClass("active");

    this.ChangedMenuEmitter.emit(data);
  }

  private getEmpresaSigla() {
    this.http.call_get("get-empresa-sigla")
      .subscribe(res => {
        this.empresa_sigla = Object(res).data
        console.log(this.empresa_sigla)
      })
  }

}
