import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-register-contador-equipamento',
  templateUrl: './register-contador-equipamento.component.html',
  styleUrls: ['./register-contador-equipamento.component.css']
})
export class RegisterContadorEquipamentoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
