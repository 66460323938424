import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { HttpParams } from "@angular/common/http";
import { Router,ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl  } from '@angular/forms';
import { ApiService } from "src/app/providers/http/api.service";
import { ExcelAutoService } from 'src/app/services/excel/excel-auto.service';
import { ReportResumoContaService } from 'src/app/components/report-at/relatorios/financeira/report-resumo-conta.service';
import { finalize } from 'rxjs/operators';
import * as moment from 'moment';
import { ImportDataService } from 'src/app/services/ExportExcel/exportAllData.service';
export class Filter {

  municipio_id:string="null";
  distrito_id:string="null"
  bairro_id: string="null";
  estadoContrato:string= 'null'
  constructor() {
  }
}

@Component({
  selector: 'app-resumo-conta',
  templateUrl: './resumo-conta.component.html',
  styleUrls: ['./resumo-conta.component.css']
})

export class ResumoContaComponent implements OnInit {
  public filter = new Filter();
  public titulo="Facturação Detalhada ";
  loading: boolean = false;
  private cobrancas: any = [];
  private cobranca: any = [];
  private localUrl: any;
  private largura: any;
  private altura: any;
  municipios:any = [];
  distritos:any = [];
  bairros:any = [];

  private loadings = {
    municipios:false,
    distritos: false,
    bairros: false
  };


  estadoContratos:any = []
  dropdownSettingsEstadoContrato: any = {}
  private disabledButton = true

  submitted = false;
  simpleForm: FormGroup;
  tipoFacturacao:string;


  constructor(private formBuilder: FormBuilder, private api: ApiService, private http: HttpService,
     private route: ActivatedRoute,
     private configService: ConfigService, 
     private excelService: ExcelAutoService,
     private getAllData: ImportDataService,
      private RelResumoContaCorrrente: ReportResumoContaService
      ) {
    this.createForm();
  }

  ngOnInit() {
    this.getMunicipios();
    this.empresaUser();
    this.somaTotais()
    this.estadoContrato()
  }

  getAllDatas() {
  
   
    let title = "Listagem de Dívida"
      this.getAllData.getData('relatorio/financeira/resumoconta',
      title,  
      this.simpleForm.value, 
      {},
      (data: any)=>this.exportAsXLSX(data))
  
   }
   
  estadoContrato(){
    this.http.call_get('estado-contrato/selectBox', null).subscribe(
      response => {
        this.estadoContratos = Object(response);
        this.dropdownSettingsEstadoContrato = {
          singleSelection: false,
          idField: 'id',
          textField: 'nome',
          selectAllText: 'Seleccione Todos',
          unSelectAllText: 'DeSeleccionar Todos',
          itemsShowLimit: 10,
          allowSearchFilter: true
        };
      }
    );
  }

  createForm() {
    this.simpleForm = this.formBuilder.group({
      cliente: null,
      cliente_id: null,
      data: null,
      municipio_id: null,
      distrito_id: null,
      bairro_id: null,
      contrato: null,
      estadoContrato: null
    });
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.simpleForm.controls;
  }
  onSubmit() {

    this.submitted = true;
    // parar aquei se o simpleFormulário for inválido
    if (this.simpleForm.invalid) {
      return;
    }
    const uri = 'relatorio/financeira/resumoconta';
    this.createOrEdit(uri, this.simpleForm);
  }

  private empresaUser() {
    this.configService.loaddinStarter('start');

    this.http.call_get('empresa/empresa-user', null).subscribe(
      response => {
        this.localUrl = Object(response).data.logotipo
        this.altura = Object(response).data.width
        this.largura = Object(response).data.heightf
        this.configService.loaddinStarter('stop');
      }
    );
  }

  createOrEdit(uri: any, simpleFormulario: FormGroup) {
    this.cobrancas = []
    this.cobranca = []
    this.loading = true;
    this.disabledButton = true;
    this.totais.valor_divida=0;
    this.totais.valor_adiantamento=0;
    // TODO: usado para fazer a requisição com a api de criação de organismo
    this.http.__call(uri, simpleFormulario.value).subscribe(
      response => {
        this.cobranca = Object(response).data;
        this.loading = false;
        this.cobranca.map(res=>{
          if(res.valor_divida!=0 && res.valor_divida>0){
            this.cobrancas.push(res)
          }
        })

        if (this.cobrancas.length != 0) {
          this.disabledButton = false;
          this.somaTotais();
        }else {}
      }
    );



  }

  exportAsXLSX(data: any = []):void {
    var CurrentDate = new Date();
    let items = data ? data : this.cobrancas
    var keys= [
    { key: 'cliente_id', width:20, style: { font: { name: 'Calibri' } } },
    { key: 'contrato', width: 20 },
    { key: 'cliente', width: 30 },
    { key: 'telefone', width: 30 },
    { key: 'morada', width:30, style: { font: { name: 'Calibri' } } },
    { key: 'cil', width:30, style: { font: { name: 'Calibri' } } },
    { key: 'municipio', width:30, style: { font: { name: 'Calibri' } } },
    { key: 'distrito', width:30, style: { font: { name: 'Calibri' } } },
    { key: 'bairro', width:30, style: { font: { name: 'Calibri' } } },
    { key: 'rua', width: 50 },
    { key: 'moradia', width:30, style: { font: { name: 'Calibri' } } },
    { key: 'predio_nome', width:30, style: { font: { name: 'Calibri' } } },
    { key: 'tarifario', width: 40 },
    { key: 'valor_divida', width:25, style: { font: { name: 'Calibri' },numFmt: '#,##0.00' } },
  ];

    var Cols = ["#", "Contrato","Cliente","Telefone","Morada","Cil","Município",	"Distrito",	"Bairro/Zona","Rua","Moradia/Apartamento/Nº Caixa","Prédio","C.Tarifário","VALOR EM DÍVIDA"]
    var title='Listagem de Dívida'
    var nameFile = "Listagem de Dívida-" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
     this.excelService.excels(items,nameFile,this.localUrl,keys,Cols,title,5,14,30,3,[14])
  }

  exportAsPDF(): void {
    var file =  document.getElementsByClassName("tableCobrancaPDF")[0]
    this.RelResumoContaCorrrente.relatorioResumoConta('save',file,this.localUrl,this.simpleForm.value);
  }

  imprimirPDF(): void {
    var file =  document.getElementsByClassName("tableCobrancaPDF")[0]
    this.RelResumoContaCorrrente.relatorioResumoConta('print',file,this.localUrl,this.simpleForm.value);
  }


  private clientes: any = [];
  view_client = false;

  private getCliente() {
    this.view_client = true;
    this.http.__call('cliente/search-cliente', { start: 1, end: 10, search: this.simpleForm.getRawValue().cliente }).subscribe(
      response => {
        this.clientes = Object(response).data.data;
      }
    );
  }

  private setCliente(client: any) {
    this.view_client = false;
    this.simpleForm.patchValue({
        cliente_id: client.id,
        cliente: client.nome
    });
  }

  private getMunicipios() {
    this.distritos = [];
    this.loadings.municipios = true;
    this.api.get('municipio/selectBox')
    .pipe(finalize(() => {this.loadings.municipios = false;}))
    .subscribe(response => {this.municipios = Object(response).data;},error=>{this.loadings.municipios = false;});
  }

  private getDistritos() {
    this.bairros = [];
    this.loadings.distritos = true;
    this.filter.distrito_id = "";
    this.filter.bairro_id = "";
    this.api.get(`distrito/selectBoxDistritosByMunicipio/${this.filter.municipio_id}`)
    .pipe(finalize(() => {this.loadings.distritos = false;}))
    .subscribe(response => { this.distritos = Object(response).data;}
            ,error=>{this.loadings.distritos = false;}
    );
  }
  private getBairrosByDistritos() {
    this.filter.bairro_id = "";
    this.loadings.bairros = true;
    if(this.filter.distrito_id==""){this.filter.distrito_id = null};
    this.api.get(`bairro/getBairrosByDistrito/${this.filter.distrito_id}`)
    .pipe(finalize(() => {this.loadings.bairros = false;}))
    .subscribe(response => {this.bairros = Object(response).data;}
              ,error=>{this.loadings.bairros = false;}
    );
  }

 private totais = {
  valor_divida: 0,
  valor_adiantamento: 0,
  }
  private somaTotais() {
    var valor_divida: number = 0;
    var valor_adiantamento: number = 0;
    this.cobrancas.forEach(element => {
      valor_divida+= element.valor_divida;
     // valor_adiantamento+= element.valor_adiantamento;

    });
    this.totais.valor_divida = valor_divida;
   // this.totais.valor_adiantamento = valor_adiantamento;
  }

  onItemSelectEstadoContrato(item: any) {
    this.estadoContratos.push(item);
    this.dropdownSettingsEstadoContrato=this.simpleForm.value.estadoContrato.map(item=>item.id)
  }
  onSelectAllEstadoContrato(items: any) {
    this.estadoContratos = items;
  }

}
