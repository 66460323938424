import { Component, OnInit , ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

import { FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { UtilizadorFormValidationService } from '../utilizador-form-validation.service';

import { first } from 'rxjs/operators';

import { AuthService } from 'src/app/providers/auth/auth.service';
/** passwords must match - custom validator */
export const password_confirmationValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const password = control.get('password');
  const confirm = control.get('password_confirmation');
  return password && confirm && password.value === confirm.value ? null : { 'passwordMismatch': true };
};

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.css'],
  providers: [UtilizadorFormValidationService],
  encapsulation: ViewEncapsulation.None
})
export class PerfilComponent implements OnInit {

  simpleForm: FormGroup;
  submitted = false;
  formErrors: any;

  private item: any = null;
  private loading:boolean=false
  public id: number;
  constructor(private http: HttpService, private configService: ConfigService, private route: ActivatedRoute, private _route: Router,
    private fb: FormBuilder,
    public vf: UtilizadorFormValidationService,
    private auth: AuthService) {
    this.route.paramMap.subscribe(params => {
      this.id = +params.get('id');
    });
    
    this.formErrors = this.vf.errorMessages;
    this.createForm();
  }

  getPefilUser( param ){
    this.loading=true
    this.http.call_get('user/perfil/' + param, null).subscribe(
      response => {
        this.item = Object(response).data;
        this.loading=false
      }
    );

  }

  ngOnInit() {
    this.getPefilUser(this.id)
  }

  private setDatauser(id) {
    this.id = id
  }

  createForm() {
    this.simpleForm = this.fb.group({
      old_password: ['',
        [Validators.required,
          //Validators.minLength(this.vf.formRegras.passwordMin),
          //Validators.pattern(this.vf.formRegras.passwordPattern)
        ]],
      password: ['',
        [Validators.required,
        Validators.minLength(this.vf.formRegras.passwordMin),
        Validators.pattern(this.vf.formRegras.passwordPattern)
        ]],
      password_confirmation: ['', [Validators.required]],
    }, { validator: password_confirmationValidator });
  }





  // convenience getter for easy access to form fields
  get f() {
    return this.simpleForm.controls;
  }

  onReset() {

    this.submitted = false;
    this.simpleForm.reset();

  }

  onSubmit() {


    this.submitted = true;
    // stop here if form is invalid
    if (this.simpleForm.invalid) {
      return;
    }
    this.configService.loaddinStarter('start');
    this.loading = true;

    this.http.__call('password', this.simpleForm.value)
      .pipe(first()) 
      .subscribe(
        res => {
          this.submitted = false;
          this.loading = false;
          this.simpleForm.reset();
          this.auth.logout();
        },
        error => {
          this.submitted = false;
          this.loading = false;
        });
    this.configService.loaddinStarter('stop');

  }

  /**
   * redefinirPassword
   */
  private redefinirPassword() {
    this.http.__call('/redefinir-password/' + this.id, { password: 'Mtti-2019', password_confirmation: 'Mtti-2019' })
      .pipe(first())
      .subscribe(
        res => {
          
        },
        error => {
        });

  }

}
