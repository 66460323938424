import { Component, OnInit, Input } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { TerceiroComponent } from 'src/app/components/config-crm/clientes/terceiro.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-form-circuito-empresarial-crm',
  templateUrl: './form-circuito-empresarial-crm.component.html',
  styleUrls: ['./form-circuito-empresarial-crm.component.css']
})
export class FormCircuitoEmpresarialCRMComponent implements OnInit {



  @Input() cliente: any;
  @Input() selectForms:any;
  @Input() tipoPedido:any;
  @Input() servicoTarifario:any;
  @Input() ServicoObject:any;

  private pedido = {
    id: null,
    cliente_id: null,
    tipoPedido: null,
    observacao: null,
    dataPedido: null,
    telefone: null,
    tarifario: null,
    capacidade: null,
    origem: null,
    destino: null
  }


  private interest = {
    id: null
  }

  private isFinalizar: boolean = true;
  private pedidos: any = [];


  constructor(private http: HttpService, private configService: ConfigService, private router: Router, private terceiro: TerceiroComponent) {

  }

  ngOnInit() {
    this.getPageFilterData(1);
  }


  private clearForm() {
    this.isFinalizar = true;
    this.pedido.tarifario = null;
    this.pedido.observacao = null;
    this.pedido.telefone = null;
    this.pedido.dataPedido = null;

  }

  private registerPedido() {

    //console.log(this.cliente)

    this.pedido.cliente_id = this.cliente.id;
    this.pedido.tipoPedido = this.tipoPedido;
    this.cliente.nome = this.cliente.nome;

    if (this.pedido.tarifario == null) {
      this.configService.showAlert('Seleccione o tarifário', 'alert-danger', true);
    }
    else {

      this.configService.loaddinStarter('start');
      this.http.__call('pedido/register', this.pedido).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.configService.loaddinStarter('stop');
            this.terceiro.listarPedidosClientes({ nome: this.cliente.nome, id: this.pedido.cliente_id });

            this.pedido.dataPedido = null;
            this.pedido.observacao = null;
            this.pedido.tipoPedido = null;
            this.pedido.telefone = null;
            this.pedido.tarifario = null;
            this.pedido.origem = null;
            this.pedido.destino = null;
            this.pedido.capacidade = null;

          }
        }
      );
    }
  }

  private hideBotton(){
    this.terceiro.hideBottonAvancar();
  }

  private listarPedidosClientes(client: any) {

    this.pedido.cliente_id = client.id;
    this.cliente.nome = client.nome;

    this.configService.loaddinStarter('start');
    this.http.call_get('pedido/listar/' + client.id, null).subscribe(
      response => {
        this.pedidos = Object(response).data;
        this.configService.loaddinStarter('stop');
      }
    );
  }

  private showSubmit() {
    this.isFinalizar = true;
  }




  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
  }






}
