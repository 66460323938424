import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilizadorFormValidationService {
  errorMessages: any;
  formRegras: any;

  private formErrors = {
    nome: '',
    sobrenome: '',
    email: '',
    telefone: '',
    telemovel: '',
    password: '', 
    confirmPassword: '',
    old_password:'',
    accept: false,
  };

  constructor() {
    this.formRegras =  {
      nonEmpty: '^[a-zA-Z0-9]+([_ -]?[a-zA-Z0-9])*$',
      usernameMin: 5,
      passwordMin: 6,
      sobrenomeMin: 3,
      dataPattern: '^(?:(?:31(\\/|-|\\.)(?:0?[13578]|1[02]))\\1|' +
        '(?:(?:29|30)(\\/|-|\\.)(?:0?[1,3-9]|1[0-2])\\2))(?:(?:1[6-9]|' +
        '[2-9]\\d)?\\d{2})$|^(?:29(\\/|-|\\.)0?2\\3(?:(?:(?:1[6-9]|' +
        '[2-9]\\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]' +
        '|[3579][26])00))))$|^(?:0?[1-9]|1\\d|2[0-8])(\\/|-|\\.)(?:(?:0?[1-9])' +
        '|(?:1[0-2]))\\4(?:(?:1[6-9]|[2-9]\\d)?\\d{2})$',
      telefonePattern: '^-?(0|[1-9]\\d*)?$',
      telemovelPattern: '^-?(0|[1-9]\\d*)?$',
      emailPattern: '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$',
      passwordPattern: ''
      //passwordPattern: '(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{6,}'
    };
    this.errorMessages = {
      nome: {
        required: 'O nome é obrigatório',
      },
      sobrenome: {
        required: 'O sobrenome é obrigatório',
        minLength: `O sobrenome tem de ter no mínimo ${this.formRegras.sobrenomeMin} caracteres`
      },
      username: {
        required: 'Username é obrigatório',
        minLength: `'Username deve conter ${this.formRegras.usernameMin} caracteres ou mais`
      },
      data:{
        pattern: 'A Data informada é inválida'
      },
      telefone: {
        required: 'Telefone é obrigatório',
        pattern: 'O número de telefone deve conter apenas número',
      },
      telemovel: {
        required: 'Telemovel é obrigatório',
        pattern: 'O número de telemóvel deve conter apenas número',
      },
      email: {
        required: 'O Email é obrigatório',
        email: 'Endereço de Email Inválido',
      },
      password: {
        required: 'Password é obrigatório',
        pattern: 'Password deve conter: número, Letras maiúscula e minúscula',
        minLength: `Password deve ser no mínimo ${this.formRegras.passwordMin} caracteres`
      }, old_password: {
        required: 'Password é obrigatório',
        pattern: 'Password deve conter: número, Letras maiúscula e minúscula',
        minLength: `Password deve ser no mínimo ${this.formRegras.passwordMin} caracteres`
      },
      confirmPassword: {
        required: 'A confirmação da Password é obrigatório',
        passwordMismatch: 'Passwords diferentes'
      },
      accept: {
        requiredTrue: 'You have to accept our Terms and Conditions'
      },
    };
  }
}
