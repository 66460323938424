
import { Component, OnInit, Input,Output, EventEmitter,OnChanges, SimpleChange } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

@Component({
  selector: 'createOrEditImposto',
  templateUrl: './create-or-edit-imposto.component.html',
  styleUrls: ['./create-or-edit-imposto.component.css']
})
export class CreateOrEditImpostoComponent implements OnInit {

  @Input() modal: string = "modalImpostoCreateOrEdit";
  @Input() title: string = "Registar Imposto";
  @Input() imposto:any;

  submitted = false;
  private loading: boolean = false;
  @Input() simpleFormImposto: FormGroup; 

  @Output() private loadListImposto = new EventEmitter<any>();

  constructor(private http: HttpService, private configService: ConfigService, private formBuilder: FormBuilder) {
    this.createForm();
  }

  ngOnInit() {
  }

  createForm() {
    this.simpleFormImposto = this.formBuilder.group({
      index: [{ value: null, disabled: true }],
      codigo: [null, Validators.required],
      valor: [null, Validators.required],
      descricao: [null, Validators.required],
      activo: [null, Validators.required],
      
    });
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.simpleFormImposto.controls;
  }

  onReset() {
    this.submitted = false;
    this.simpleFormImposto.reset();
  }


  onSubmit() {

    this.submitted = true;

    // parar aquei se o formulário for inválido
    if (this.simpleFormImposto.invalid) {
      return;
    }
    this.loading = true;
    const index = this.simpleFormImposto.getRawValue().index;
    // TODO: usado para fazer a requisição com a api de criação de objsct or update
    const uri = (index === null ? 'imposto/create' : 'imposto/update/' + index);
    this.createOrEdit(uri, this.simpleFormImposto, (index === null ? true : false));

  }

  createOrEdit(uri: any, formulario: FormGroup, isCreate: boolean) {

    // TODO: usado para fazer a requisição com a api de criação de object
    this.http.__call(uri, formulario.value).pipe(first()).subscribe(
      response => {
        this.submitted = false;
        this.loading = false;
        if (isCreate) {
          formulario.reset();
          this.loadListImpostos(Object(response).data);
        }  
        
        if (Object(response).code ==200) {
          this.loadListImpostos(Object(response).data);
        }
      },
      error => {
        this.submitted = false;
        this.loading = false;
      });
  }




  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {

    if (this.imposto !== undefined ) {
      this.title = "Editar Imposto";
      this.simpleFormImposto.patchValue({
        index: this.imposto.id,
        codigo: this.imposto.codigo,
        descricao: this.imposto.descricao,
        valor: this.imposto.valor,
        activo: this.imposto.activo,
      });
    } else {
      this.title = "Registar Imposto";
    }
  }

  

  public loadListImpostos(imposto) { 
    this.loadListImposto.emit(imposto);
  }


}

