 

import { Component, OnInit } from "@angular/core"; 
import { ApiService } from "src/app/providers/http/api.service";
import { ConfigService } from "src/app/providers/config/config.service"; 

import { HttpParams } from "@angular/common/http"; 
import { finalize } from 'rxjs/operators';  
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';

export class Filter {
  search: string = "";
  serie_id: string ="";
  distrito_id:string=""    
  orderBy: string = "";
  typeOrderBy: string = "DESC"; 
  typeFilter: string = "";
  constructor() {  
  }
}

export class Pagination {
  lastPage: number;
  page: number = 1;
  perPage: number = 100;
  total: number;
  deserialize(input): this {
    return Object.assign(this, input);
  }
}
@Component({
  selector: 'app-listar-impressao-massiva',
  templateUrl: './listar-impressao-massiva.component.html',
  styleUrls: ['./listar-impressao-massiva.component.css']
})
export class ListarImpressaoMassivaComponent implements OnInit {
  public pagination = new Pagination();
  public filter = new Filter(); 
  loading = false; 
  private facturas: any = [];  
  series:any = []; 
  distritos:any = []
  constructor( 
    private configService: ConfigService,  
    private api: ApiService
  ) { 

  }

  ngOnInit() {     
    this.getDistritos();
    this.getSeries(); 
    this.getPageFilterData();
  }
  
  imprimirPDF(): void {
    //this.reportLoja.relatorioLoja(this.cobrancas, this.simpleForm.value);
  }
 
  /**
   * @name "Listar facturação"
   * @descriptio "Esta Função permite Listar todas facturações"
   * @author "caniggia.moreira@itgest.pt"
   * @param start
   * @param end
   */
  private listarFacturasImpressaoMassiva() {
    this.loading = true;  
    var params = new HttpParams();
    params = this.api.params
    .set("search", this.filter.search)
    .set("serie_id", this.filter.serie_id)
    .set("distrito_id", this.filter.distrito_id)  
    .set("page", (Number.isInteger(this.pagination.page ) || 1).toString())
    .set("perPage", (this.pagination.perPage || 5).toString())
    .set("orderBy", this.filter.orderBy.toString())
    .set("typeOrderBy", this.filter.typeOrderBy.toString())
    .set("typeFilter", this.filter.typeFilter.toString());
      
    this.api
      .get(`facturas/massiva`, params)
      .pipe( 
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(response => { 
        this.facturas = Object(response).data.data; 
        this.pagination.lastPage = Object(response).data.lastPage;
        this.pagination.page = Object(response).data.page;
        this.pagination.total = Object(response).data.total;
        this.pagination.perPage = Object(response).data.perPage;  
      }, error => {
        this.loading = false;
      })
  }
 
  //--------------------------------------------------------------------------

  getPageFilterData() {
    this.listarFacturasImpressaoMassiva();
  }
 

  private getSeries() {
    this.api.post('serie/listar').subscribe(
      response => {
        this.series = Object(response);
      }
    );
  }

  private getDistritos() {
    this.api.get('distrito/selectBox').subscribe(
      response => {
        this.distritos = Object(response).data;
      }
    );
  }
   
  

  imprimirPDFFacturas(data) { 

    const swal = Swal.mixin({ 
      
      customClass: {
        
        container: 'container-class',
        popup: 'popup-class',
        header: 'header-class',
        title: 'title-class',
        closeButton: 'close-button-class',
        icon: 'warning',
        content: 'content-class',
        input: 'input-class',
        actions: 'actions-class',
        confirmButton: 'btn btn-default', 
        footer: 'footer-class', 
      }, 
      width: '69em',
      buttonsStyling: false,
    })

    swal.fire({
      backdrop:true,
      html: '<iframe class="embed-responsive-item" id="pdf_preview2" type="application/pdf" width="100%" height="950px" src="'+data+'" frameborder="1"></iframe>',
      title: 'Imprimir facturas',
      text: "",
      type: 'success',  
      reverseButtons: true, 
      confirmButtonText: '&nbsp; Ignorar',
      showLoaderOnConfirm: false, 
      allowOutsideClick: false 
    }).then((result) => {
      if (result.value) { 
        //window.open(data, '_blank');
        //this.redirect_blank(data);
      }  
    })
  }



  downloadFactura(object) {
    var a = document.createElement('a');
    a.download = `facturas-massima(${object.id}).pdf`;
    a.href = object.pdfBase64; 
    a.click();
  }

  previewOrdownloadPDF(name:string,blob,type='preview'){ 
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    if(type=='preview'){
      link.target = '__blink'; 
      window.open(link.href)
    }else{
      link.download = name+".pdf";
    } 
    link.click();
  } 

   
  getFile(id, type='preview') { 
    this.loading = true;
    return this.api.file('facturas/massiva/preview','pdf',{id:id})
    .pipe(  finalize(() => { this.loading = false; })).subscribe(
        (res) => {
          //this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(res)); 
          const name = 'factura-massiva'+id+".pdf"; 
          this.previewOrdownloadPDF(name,res,type)
        },
        error => {
          this.loading = false;
    });
  }
  
  
}
 