import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChange,
  NgModule,
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { HttpService } from "src/app/providers/http/http.service";
import { AuthService } from "src/app/providers/auth/auth.service";
import { ConfigService } from "src/app/providers/config/config.service";
import { first } from "rxjs/operators";
import { ConfigModuloService } from "src/app/services/config-modulo.service";
import { AppComponent } from "src/app/app.component";
import { AgmCoreModule } from "@agm/core";
import { stream } from "xlsx/types";
import { iterator } from "rxjs/internal-compatibility";
import { forkJoin } from "rxjs";
import { LocalInstalacaoComponent } from "../local-instalacao.component";
import { LocalInstalacaoService } from "../local-instalacao.service";

@Component({
  selector: "app-create-or-edit-local-instalacao",
  templateUrl: "./create-or-edit-local-instalacao.component.html",
  styleUrls: ["./create-or-edit-local-instalacao.component.css"],
})
export class CreateOrEditLocalInstalacaoComponent implements OnInit {
  public currentUser: any;
  @Input() title: string = "Registar Local de Instalação";
  @Input() contrato_has_joined: boolean = false;
  @Input() modal: string = "modalCreateOrEditLocalInstalacao"
  @Input() local_instalacao = {
    id: null,
    moradia_numero: null,
    is_predio: null,
    is_predio1: null,
    predio_id: null,
    predio_andar: null,
    predio_nome: null,
    cil: null,
    selo_id: null,
    selo: null,
    rua_id: null,
    rua: null,
    bairro_id: null,
    bairro: null,
    has_quarteirao: null,
    quarteirao_id: null,
    has_distrito: false,
    municipio_id: null,
    distrito_id: null,
    provincia_id: null,
    latitude: null,
    longitude: null,
    is_active: null,
    user_id: null,
    morada_local_instalacao: null,

    ligacao_ramal_id: null,
    tipo_objecto_id: null,
    objecto_ligacao_id: null,
    comprimento: null,
    profundidade: null,

    tipo_ramal_id: null,
    diametro_ramal_id: null,
    material_ramal_id: null,
    estado_ramal_id: null,

    fita_sinalizacao: null,
    valvula_ramal: null,
    latitude_ramal: null,
    longitude_ramal: null,

    instalacao_sanitaria_qtd: null,
    reservatorio_flag: null,
    reservatorio_capacidade: null,
    piscina_flag: null,
    piscina_capacidade: null,
    jardim_flag: null,
    campo_jardim_id: null,
    campo_jardim: null,
    poco_alternativo_flag: null,
    fossa_flag: null,
    fossa_capacidade: null,
    acesso_camiao_flag: null,
    anexo_flag: null,
    anexo_quantidade: null,
    caixa_contador_flag: null,
    estado_caixa_contador_id: null,
    abastecimento_cil_id: null,
    abastecimento_cil: null,
    calibre_id: null,
  };

  view_ramal = false;
  private load_CIL: boolean;
  private distrito_CIL: boolean;

  @Input() local_instalacaos: any = [];

  private provincias: any = [];
  @Input() municipios: any = [];
  @Input() distritos: any[];
  @Input() bairros: any = [];
  @Input() quarteiraos: any[];
  @Input() ruas: any[];
  clearModal: boolean = true
  @Input() tipo_objectos: any[];

  private objectos_ramais: any = [];

  private ligacao_ramals: any = [];

  private selos: any = [];
  isToShow = false;

  private estado_caixa_contadors: any = [];
  //private objecto_contratos: any = [];
  private campo_jardims: any = [];
  private abastecimento_cils: any = [];

  private tipo_ramals: any[];
  private diametro_ramals: any[];
  private material_ramals: any[];

  private local_instalacaosArray: any = [];

  private local_instalacaoRow = {
    id: null,
    moradia_numero: null,
    is_predio: null,
    predio_id: null,
    predio_andar: null,
    predio_nome: null,
    cil: null,
    selo_id: null,
    selo: null,
    rua_id: null,
    morada_local_instalacao: null,
    rua: null,
    bairro_id: null,
    bairro: null,
    bairro_abreviatura: null,
    has_quarteirao: null,
    quarteirao_id: null,
    quarteirao: null,
    has_distrito: null,
    distrito_id: null,
    distrito: null,
    distrito_abreviatura: null,
    municipio_abreviatura: null,
    municipio_id: null,
    municipio: null,
    provincia_id: null,
    provincia: null,
    latitude: null,
    longitude: null,
    user_id: null,
    user: null,

    ligacao_ramal_id: null,
    tipo_objecto_id: null,
    objecto_ligacao_id: null,
    saneamento_flag: null,
    comprimento: null,
    profundidade: null,

    tipo_ramal_id: null,
    diametro_ramal_id: null,
    material_ramal_id: null,
    estado_ramal_id: null,

    fita_sinalizacao: null,
    valvula_ramal: null,
    latitude_ramal: null,
    longitude_ramal: null,

    instalacao_sanitaria_qtd: null,
    reservatorio_flag: null,
    reservatorio_capacidade: null,
    piscina_flag: null,
    piscina_capacidade: null,
    jardim_flag: null,
    campo_jardim_id: null,
    campo_jardim: null,
    poco_alternativo_flag: null,
    fossa_flag: null,
    fossa_capacidade: null,
    acesso_camiao_flag: null,
    anexo_flag: null,
    anexo_quantidade: null,
    caixa_contador_flag: null,
    estado_caixa_contador_id: null,
    abastecimento_cil_id: null,
    abastecimento_cil: null,
  };

  private predio_flag: boolean = false;
  private geolocation_flag: boolean = false;
  private geolocation_updated: boolean = false;

  private geolocalizacaoEndModal: boolean = false;

  private data_ligacao_flag: boolean = false;
  private selected_local_instalacao: string = null;

  private local_instalacaoRowValid: boolean = false;
  @Input() addRows: boolean = true;

  @Input() showCreateLocalInstalacao: boolean = true;

  @Input() local_instalacaoModal: boolean = false;

  texto: string = "Planeamento";
  lat = -12.3757287;
  lng = 13.5610451;
  zoom = 10;

  submitted = false;
  private loading: boolean = false;

  ramal = {
    id: null,
    codigo_remal: null
  }

  //@Output() private loadListLocalInstalacao = new EventEmitter<any>();

  constructor(
    private auth: AuthService,
    private http: HttpService,
    private config: ConfigModuloService,
    private configService: ConfigService,
    private formBuilder: FormBuilder,
    private loiService: LocalInstalacaoService,
    private localInstComp: LocalInstalacaoComponent
  ) {
    //this.createForm();
    this.currentUser = this.auth.currentUserValue;
  }

  ngOnInit() {
    this.getConfiguracaos();
    this.getRamal()

    if (this.provincias.length <= 0) {
      this.local_instalacao.provincia_id = 29;
      this.selectBoxProvincias();
    }

    if (this.addRows == false) {
      this.selectBoxProvincias();
    }

    this.onReset();
    this.local_instalacao.user_id = this.currentUser.user.id;

    this.getSelos();
    this.selectBoxTipoObjectos();

    this.getPageFilterLigacaoRamal(1);

    this.getEstadoCaixaContadors();
    //this.getObjectosContratos();
    this.getCampoJardims();
    this.getAbastecimentoCILs();
  }

  private getLigacaoRamals() {
    this.data_ligacao_flag = false;

    this.local_instalacao.ligacao_ramal_id = null;
    this.local_instalacao.comprimento = null;
    this.local_instalacao.profundidade = null;

    this.http
      .__call("ligacao-ramal/getLigacaoRamals", {
        tipo_objecto_id: this.local_instalacao.tipo_objecto_id,
        objecto_ligacao_id: this.local_instalacao.objecto_ligacao_id,
        pagination: this.http.filters,
      })
      .subscribe((response) => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.ligacao_ramals = Object(response).data.data;
      });
  }

  private getSelos() {
    this.http.call_get("selo/selectBox", null).subscribe((response) => {
      this.selos = Object(response);
    });
  }

  private getPageFilterLigacaoRamal(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }

    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1;

    this.getLigacaoRamals();
  }

  private setLigacaoRamal(item) {
    this.resetLigacao();

    this.local_instalacao.ligacao_ramal_id = item.id;

    if (item.local_instalacao_id != null) {
      this.selected_local_instalacao =
        "a " +
        (item.is_predio
          ? "Prédio " + item.predio_nome + ", Porta "
          : "Residência ") +
        this.configService.decimalFormat(item.moradia_numero);
    } else {
      this.selected_local_instalacao =
        "a " + item.ponto_b_tipo_objecto + " - " + item.ponto_b;
    }

    this.data_ligacao_flag = true;
  }

  private resetLigacao() {
    this.data_ligacao_flag = false;

    this.selected_local_instalacao = null;

    this.local_instalacao.ligacao_ramal_id = null;
    this.local_instalacao.comprimento = null;
    this.local_instalacao.profundidade = null;

    this.local_instalacao.tipo_ramal_id = null;
    this.local_instalacao.diametro_ramal_id = null;
    this.local_instalacao.material_ramal_id = null;
    this.local_instalacao.estado_ramal_id = null;
    this.local_instalacao.morada_local_instalacao = null;

    this.local_instalacao.fita_sinalizacao = null;
    this.local_instalacao.valvula_ramal = null;
    this.local_instalacao.latitude_ramal = null;
    this.local_instalacao.longitude_ramal = null;
  }

  private validateLigacaoRamal(item) {
    var result: boolean = true;

    if (this.local_instalacao.comprimento == null) {
      this.configService.showAlert(
        "Insira o comprimento da ligação!",
        "alert-danger",
        true
      );
      result = false;
    } else if (this.local_instalacao.diametro_ramal_id == null) {
      this.configService.showAlert(
        "Insira o diâmetro da ligação!",
        "alert-danger",
        true
      );
      result = false;
    } else if (this.local_instalacao.profundidade == null) {
      this.configService.showAlert(
        "Insira a profundidade da ligação!",
        "alert-danger",
        true
      );
      result = false;
    }

    return result;
  }

  getCILSequencial(item, count = 0) {
    if (this.distrito_CIL) {
      this.http
        .__call("local-instalacao/getNextCIL/" + item.bairro_id, {
          excesso: count,
          municipio_abreviatura: item.municipio_abreviatura,
          distrito_abreviatura: item.distrito_abreviatura,
        })
        .subscribe((response) => {
          this.local_instalacaoRow.cil = Object(response).data;
        });
    }
  }

  save() {
    if (!this.addRows) {
      this.local_instalacaosArray.unshift({ ...this.local_instalacao });
    }

    for (let i = 0; i < this.local_instalacaosArray.length; ++i) {
      const item = this.local_instalacaosArray[i];

      item.distrito_cil_sequencial = this.distrito_CIL;

      if (item.id == null) {
        this.http
          .__call("local-instalacao/create", { localInstalacao: item, ramal_id: this.ramal.id })
          .subscribe((response) => {
            if (Object(response).code == 200) {
              this.configService.showAlert(
                Object(response).message,
                "alert-success",
                true
              );
              this.local_instalacaosArray = [];
              this.onReset();
              this.clearModal = false
              this.resetLigacao();
              this.localInstComp.localInstalacaoListagem();
            } else {
              this.configService.showAlert(
                Object(response).message,
                "alert-danger",
                true
              );
              this.clearModal = false

            }
          });
      } else {
        console.log(this.local_instalacao);
        this.http
          .__call("local-instalacao/update/" + item.id + "/" + this.ramal.id, item)
          .subscribe((response) => {
            if (Object(response).code == 200) {
              this.configService.showAlert(
                Object(response).message,
                "alert-success",
                true
              );
              this.clearModal = false
              this.local_instalacaosArray = [];
              this.localInstComp.localInstalacaoListagem();
            } else {
              this.configService.showAlert(
                Object(response).message,
                "alert-danger",
                true
              );
              this.clearModal = false
            }
            console.timeEnd("Time required");
          });
      }
    }
  }

  /*
    createLocalInstalacao(item) {
      this.http.__call('local-instalacao/create', item).subscribe(
        response => {

          if (Object(response).code == 200) {
            this.configService.showAlert(Object(response).message, "alert-success", true);
            this.local_instalacaosArray = [];
            this.onReset();

          } else {
            this.configService.showAlert(Object(response).message, "alert-danger", true);
          }


        }
      );
    }
   */

  createOrEdit(uri: any, formulario: any, isCreate: boolean) {
    // TODO: usado para fazer a requisição com a api de criação de object
    this.http
      .__call(uri, formulario)
      .pipe(first())
      .subscribe(
        (response) => {
          this.submitted = false;
          this.loading = false;

          if (Object(response).code == 200) {
            this.openEndLocalInstalacaoModal(true);
            this.reloadLocalInstalacaos();
          }

          if (isCreate) {
            formulario.reset();
          }

          //this.local_instalacaos = Object(response).data;
        },
        (error) => {
          this.submitted = false;
          this.loading = false;
        }
      );
  }

  setDataLocalInstalacao({ item }) {
    if (this.provincias.length <= 0) {
      this.selectBoxProvincias();
    }

    if (item.id > 0) {
      this.title = "Editar Local de Instalação";

      this.local_instalacao.id = item.id;
      this.local_instalacao.moradia_numero = item.moradia_numero;
      this.local_instalacao.is_predio = item.is_predio;
      this.local_instalacao.predio_id = item.predio_id;
      this.local_instalacao.predio_andar = item.predio_andar;
      this.local_instalacao.predio_nome = item.predio_nome;
      this.local_instalacao.cil = item.cil;
      this.local_instalacao.selo_id = item.selo_id;
      this.local_instalacao.selo = item.selo;
      this.local_instalacao.rua_id = item.rua_id;
      this.local_instalacao.rua = item.rua;
      this.local_instalacao.morada_local_instalacao = item.morada_local_instalacao;
      this.local_instalacao.bairro_id = item.bairro_id;
      this.local_instalacao.bairro = item.bairro;
      this.local_instalacao.has_quarteirao = item.quarteirao;
      this.local_instalacao.quarteirao_id = item.quarteirao_id;
      this.local_instalacao.has_distrito = item.has_distrito;
      this.local_instalacao.distrito_id = item.distrito_id;
      this.local_instalacao.municipio_id = item.municipio_id;
      this.local_instalacao.provincia_id = item.provincia_id;
      this.local_instalacao.latitude = item.latitude;
      this.local_instalacao.longitude = item.longitude;
      this.local_instalacao.is_active = item.is_active;
      this.local_instalacao.user_id = item.user_id;

      this.local_instalacao.ligacao_ramal_id = item.ligacao_ramal_id;
      this.local_instalacao.tipo_objecto_id = item.tipo_objecto_id;
      this.local_instalacao.objecto_ligacao_id = item.objecto_ligacao_id;
      this.local_instalacao.comprimento = item.comprimento;
      this.local_instalacao.profundidade = item.profundidade;

      this.local_instalacao.tipo_ramal_id = item.tipo_ramal;
      this.local_instalacao.diametro_ramal_id = item.diametro_ramal_id;
      this.local_instalacao.material_ramal_id = item.material_ramal_id;
      this.local_instalacao.estado_ramal_id = item.estado_ramal_id;

      this.local_instalacao.fita_sinalizacao = item.fita_sinalizacao;
      this.local_instalacao.valvula_ramal = item.valvula_ramal;
      this.local_instalacao.latitude_ramal = item.latitude_ramal;
      this.local_instalacao.longitude_ramal = item.longitude_ramal;

      this.local_instalacao.instalacao_sanitaria_qtd =
        item.instalacao_sanitaria_qtd;
      this.local_instalacao.reservatorio_flag = item.reservatorio_flag;
      this.local_instalacao.reservatorio_capacidade =
        item.reservatorio_capacidade;
      this.local_instalacao.piscina_flag = item.piscina_flag;
      this.local_instalacao.piscina_capacidade = item.piscina_capacidade;
      this.local_instalacao.jardim_flag = item.jardim_flag;
      this.local_instalacao.campo_jardim_id = item.campo_jardim_id;
      this.local_instalacao.poco_alternativo_flag = item.poco_alternativo_flag;
      this.local_instalacao.fossa_flag = item.fossa_flag;
      this.local_instalacao.fossa_capacidade = item.fossa_capacidade;
      this.local_instalacao.acesso_camiao_flag = item.acesso_camiao_flag;
      this.local_instalacao.anexo_flag = item.anexo_flag;
      this.local_instalacao.anexo_quantidade = item.anexo_quantidade;
      this.local_instalacao.caixa_contador_flag = item.caixa_contador_flag;
      this.local_instalacao.estado_caixa_contador_id =
        item.estado_caixa_contador_id;
      this.local_instalacao.abastecimento_cil_id = item.abastecimento_cil_id;

      this.local_instalacao.has_distrito = true;
      this.local_instalacao.has_quarteirao = true;
    } else {
      this.title = "Registar Local de Instalação";
      this.onReset();
    }
  }

  private initLigacaoRamal() {
    this.selectBoxTipoRamals();
    this.selectBoxDiametroRamals();
    this.selectBoxMaterialRamals();
  }


  private cleanLigacaoRamal() {
    //this.local_instalacao.moradia_numero = null;

    this.local_instalacao.ligacao_ramal_id = null;
    this.local_instalacao.tipo_objecto_id = null;
    this.local_instalacao.objecto_ligacao_id = null;
    this.local_instalacao.comprimento = null;
    this.local_instalacao.profundidade = null;

    this.local_instalacao.tipo_ramal_id = null;
    this.local_instalacao.diametro_ramal_id = null;
    this.local_instalacao.material_ramal_id = null;
    this.local_instalacao.estado_ramal_id = null;

    this.local_instalacao.fita_sinalizacao = null;
    this.local_instalacao.valvula_ramal = null;
    this.local_instalacao.latitude_ramal = null;
    this.local_instalacao.longitude_ramal = null;
  }

  onReset() {
    this.submitted = false;

    this.local_instalacao.id = null;
    this.local_instalacao.moradia_numero = null;
    this.local_instalacao.is_predio = null;
    this.local_instalacao.predio_id = null;
    this.local_instalacao.predio_andar = null;
    this.local_instalacao.predio_nome = null;
    this.local_instalacao.cil = null;
    this.local_instalacao.selo_id = null;
    this.local_instalacao.selo = null;
    this.local_instalacao.rua_id = null;
    this.local_instalacao.rua = null;
    this.local_instalacao.bairro_id = null;
    this.local_instalacao.bairro = null;
    this.local_instalacao.has_quarteirao = false;
    this.local_instalacao.quarteirao_id = null;
    this.local_instalacao.has_distrito = false;
    this.local_instalacao.distrito_id = null;
    this.local_instalacao.municipio_id = null;
    this.local_instalacao.is_active = null;
    this.local_instalacao.user_id = null;

    this.local_instalacao.ligacao_ramal_id = null;
    this.local_instalacao.tipo_objecto_id = null;
    this.local_instalacao.objecto_ligacao_id = null;
    this.local_instalacao.comprimento = null;
    this.local_instalacao.profundidade = null;

    this.local_instalacao.tipo_ramal_id = null;
    this.local_instalacao.diametro_ramal_id = null;
    this.local_instalacao.material_ramal_id = null;
    this.local_instalacao.estado_ramal_id = null;

    this.local_instalacao.fita_sinalizacao = null;
    this.local_instalacao.valvula_ramal = null;
    this.local_instalacao.latitude_ramal = null;
    this.local_instalacao.longitude_ramal = null;
  }

  private async appendRowLocalInstalacao() {
    this.geolocation_updated = false;

    const loadedStateCallback = () => {
      this.local_instalacaoRow.is_predio = this.predio_flag;
      this.local_instalacaoRow.predio_nome = this.predio_flag
        ? this.local_instalacao.predio_nome
        : null;

      this.local_instalacaoRow.predio_andar =
        this.local_instalacao.predio_andar;

      this.local_instalacaoRow.moradia_numero =
        this.local_instalacao.moradia_numero;
      this.local_instalacaoRow.morada_local_instalacao = this.local_instalacao.morada_local_instalacao;
      this.local_instalacaoRow.cil = this.local_instalacao.cil;
      this.local_instalacaoRow.user_id = this.currentUser.user.id;

      this.local_instalacaoRow.ligacao_ramal_id =
        this.local_instalacao.ligacao_ramal_id;
      this.local_instalacaoRow.tipo_objecto_id =
        this.local_instalacao.tipo_objecto_id;
      this.local_instalacaoRow.objecto_ligacao_id =
        this.local_instalacao.objecto_ligacao_id;
      this.local_instalacaoRow.comprimento = this.local_instalacao.comprimento;
      this.local_instalacaoRow.profundidade =
        this.local_instalacao.profundidade;

      this.local_instalacaoRow.tipo_ramal_id = this.local_instalacao.tipo_ramal_id;
      this.local_instalacaoRow.diametro_ramal_id = this.local_instalacao.diametro_ramal_id;
      this.local_instalacaoRow.material_ramal_id = this.local_instalacao.material_ramal_id;
      this.local_instalacaoRow.estado_ramal_id = this.local_instalacao.estado_ramal_id;

      this.local_instalacaoRow.fita_sinalizacao = this.local_instalacao.fita_sinalizacao;
      this.local_instalacaoRow.valvula_ramal = this.local_instalacao.valvula_ramal;
      this.local_instalacaoRow.latitude_ramal = this.local_instalacao.latitude_ramal;
      this.local_instalacaoRow.longitude_ramal = this.local_instalacao.longitude_ramal;


      this.local_instalacaoRow.selo_id = this.local_instalacao.selo_id;

      this.local_instalacaoRow.instalacao_sanitaria_qtd =
        this.local_instalacao.instalacao_sanitaria_qtd;
      this.local_instalacaoRow.instalacao_sanitaria_qtd =
        this.local_instalacao.instalacao_sanitaria_qtd;
      this.local_instalacaoRow.reservatorio_flag =
        this.local_instalacao.reservatorio_flag;
      this.local_instalacaoRow.reservatorio_capacidade =
        this.local_instalacao.reservatorio_capacidade;
      this.local_instalacaoRow.piscina_flag =
        this.local_instalacao.piscina_flag;
      this.local_instalacaoRow.piscina_capacidade =
        this.local_instalacao.piscina_capacidade;
      this.local_instalacaoRow.jardim_flag = this.local_instalacao.jardim_flag;
      this.local_instalacaoRow.campo_jardim_id =
        this.local_instalacao.campo_jardim_id;
      this.local_instalacaoRow.poco_alternativo_flag =
        this.local_instalacao.poco_alternativo_flag;
      this.local_instalacaoRow.fossa_flag = this.local_instalacao.fossa_flag;
      this.local_instalacaoRow.fossa_capacidade =
        this.local_instalacao.fossa_capacidade;
      this.local_instalacaoRow.acesso_camiao_flag =
        this.local_instalacao.acesso_camiao_flag;
      this.local_instalacaoRow.anexo_flag = this.local_instalacao.anexo_flag;
      this.local_instalacaoRow.anexo_quantidade =
        this.local_instalacao.anexo_quantidade;
      this.local_instalacaoRow.caixa_contador_flag =
        this.local_instalacao.caixa_contador_flag;
      this.local_instalacaoRow.estado_caixa_contador_id =
        this.local_instalacao.estado_caixa_contador_id;
      this.local_instalacaoRow.abastecimento_cil_id =
        this.local_instalacao.abastecimento_cil_id;

      console.log(this.local_instalacaoRow);
      this.local_instalacaoRowValid = this.localInstalacaoRowValidation(
        this.local_instalacaoRow
      );

      if (this.local_instalacaoRowValid) {
        setTimeout(() => {
          this.local_instalacaosArray.unshift({ ...this.local_instalacaoRow });
          console.log("Appended row");
        }, 1500);
      }
    };

    await this.getRuaById(loadedStateCallback);

    //this.cleanLigacaoRamal();
  }

  private appendGeolocationValues() {
    /*
        if(this.geolocation_flag) {
          this.local_instalacaoRow.latitude = this.local_instalacao.latitude;
          this.local_instalacaoRow.longitude = this.local_instalacao.longitude;
        } */

    this.local_instalacaoRowValid = this.localInstalacaoRowValidation(
      this.local_instalacaoRow
    );
    if (this.local_instalacaoRowValid)
      this.local_instalacaosArray.unshift({ ...this.local_instalacaoRow });

    if (this.geolocation_flag) {
      for (let i = 0; i < this.local_instalacaosArray.length; ++i) {
        if (
          this.local_instalacaosArray[i].rua_id ==
          this.local_instalacaoRow.rua_id &&
          this.local_instalacaosArray[i].moradia_numero ==
          this.local_instalacaoRow.moradia_numero &&
          this.local_instalacaosArray[i].is_predio ==
          this.local_instalacaoRow.is_predio
        ) {
          this.local_instalacaosArray[i].latitude =
            this.local_instalacaoRow.latitude;
          this.local_instalacaosArray[i].longitude =
            this.local_instalacaoRow.longitude;
        }
      }

      this.local_instalacaoRowValid = true;
    }
  }

  private getLocation() {
    /* if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.showPosition);
    } else {

      this.simpleFormcontagem.patchValue({
        latitude: null,
        longitude: null
      });
    } */
  }

  private deleteRowLocalInstalacaoAppended(row): void {
    for (let i = 0; i < this.local_instalacaosArray.length; ++i) {
      if (
        this.local_instalacaosArray[i].rua_id == row.rua_id &&
        this.local_instalacaosArray[i].moradia_numero == row.moradia_numero &&
        this.local_instalacaosArray[i].is_predio == row.is_predio
      ) {
        this.local_instalacaosArray.splice(i, 1);
      }
    }
  }

  /*  searchElementAddToList=(id)=>{
    this.local_instalacaosArray.find(item=>item)
  } */

  private localInstalacaoRowValidation(row): boolean {
    if (this.local_instalacaosArray.length == 0) {
      this.getCILSequencial(row);
      return true;
    }

    const count = this.local_instalacaosArray.filter((item) => {
      return item.bairro_id == row.bairro_id;
    }).length;

    for (let i = 0; i < this.local_instalacaosArray.length; ++i) {
      if (
        this.local_instalacaosArray[i].rua_id == row.rua_id &&
        this.local_instalacaosArray[i].moradia_numero == row.moradia_numero &&
        this.local_instalacaosArray[i].is_predio == row.is_predio
      ) {
        return false;
      }
    }

    this.getCILSequencial(row, count);

    return true;
  }

  private reloadLocalInstalacaos() {
    /*     this.http.call_get('local-instalacao/listagem/', null).subscribe(
          response => {

            this.local_instalacaos = Object(response).data;
          }
        ); */
  }

  private selectBoxProvincias() {
    this.local_instalacao.municipio_id = null;
    this.local_instalacao.distrito_id = null;
    this.local_instalacao.bairro_id = null;
    this.local_instalacao.quarteirao_id = null;
    this.local_instalacao.rua_id = null;

    this.http.call_get("provincia/selectBox", null).subscribe((response) => {
      this.provincias = Object(response).data;
    });

    if (this.local_instalacao.moradia_numero) this.getAutoLoadCIL();
  }

  private setPredioValue() {
    this.predio_flag = !this.predio_flag;
    if (this.predio_flag) {
      this.local_instalacao.is_predio1 = 1
    }
    else {
      this.local_instalacao.is_predio1 = 0
    }
  }

  private setGeolocationFlag() {
    this.geolocation_flag = !this.geolocation_flag;
  }

  private setGeolocationUpdate(flag: boolean) {
    this.geolocation_updated = flag;
  }

  private async setGeolocationValues() {
    const loadedStateCallback = () => {
      this.local_instalacaoRow.is_predio = this.predio_flag;
      this.local_instalacaoRow.predio_nome = this.predio_flag
        ? this.local_instalacao.predio_nome
        : null;
      this.local_instalacaoRow.predio_andar =
        this.local_instalacao.predio_andar;

      this.local_instalacaoRow.cil = this.local_instalacao.cil;

      this.local_instalacaoRow.moradia_numero =
        this.local_instalacao.moradia_numero;
      this.local_instalacaoRow.user_id = this.currentUser.user.id;

      this.local_instalacaoRow.ligacao_ramal_id =
        this.local_instalacao.ligacao_ramal_id;
      this.local_instalacaoRow.tipo_objecto_id =
        this.local_instalacao.tipo_objecto_id;
      this.local_instalacaoRow.objecto_ligacao_id =
        this.local_instalacao.objecto_ligacao_id;
      this.local_instalacaoRow.comprimento = this.local_instalacao.comprimento;
      this.local_instalacaoRow.profundidade =
        this.local_instalacao.profundidade;

      this.local_instalacaoRow.tipo_ramal_id = this.local_instalacao.tipo_ramal_id;
      this.local_instalacaoRow.diametro_ramal_id = this.local_instalacao.diametro_ramal_id;
      this.local_instalacaoRow.material_ramal_id = this.local_instalacao.material_ramal_id;
      this.local_instalacaoRow.estado_ramal_id = this.local_instalacao.estado_ramal_id;

      this.local_instalacaoRow.fita_sinalizacao = this.local_instalacao.fita_sinalizacao;
      this.local_instalacaoRow.valvula_ramal = this.local_instalacao.valvula_ramal;
      this.local_instalacaoRow.latitude_ramal = this.local_instalacao.latitude_ramal;
      this.local_instalacaoRow.longitude_ramal = this.local_instalacao.longitude_ramal;

      this.local_instalacaoRow.instalacao_sanitaria_qtd =
        this.local_instalacao.instalacao_sanitaria_qtd;
      this.local_instalacaoRow.reservatorio_flag =
        this.local_instalacao.reservatorio_flag;
      this.local_instalacaoRow.reservatorio_capacidade =
        this.local_instalacao.reservatorio_capacidade;
      this.local_instalacaoRow.piscina_flag =
        this.local_instalacao.piscina_flag;
      this.local_instalacaoRow.piscina_capacidade =
        this.local_instalacao.piscina_capacidade;
      this.local_instalacaoRow.jardim_flag = this.local_instalacao.jardim_flag;
      this.local_instalacaoRow.campo_jardim_id =
        this.local_instalacao.campo_jardim_id;
      this.local_instalacaoRow.poco_alternativo_flag =
        this.local_instalacao.poco_alternativo_flag;
      this.local_instalacaoRow.fossa_flag = this.local_instalacao.fossa_flag;
      this.local_instalacaoRow.fossa_capacidade =
        this.local_instalacao.fossa_capacidade;
      this.local_instalacaoRow.acesso_camiao_flag =
        this.local_instalacao.acesso_camiao_flag;
      this.local_instalacaoRow.anexo_flag = this.local_instalacao.anexo_flag;
      this.local_instalacaoRow.anexo_quantidade =
        this.local_instalacao.anexo_quantidade;
      this.local_instalacaoRow.caixa_contador_flag =
        this.local_instalacao.caixa_contador_flag;
      this.local_instalacaoRow.estado_caixa_contador_id =
        this.local_instalacao.estado_caixa_contador_id;
      this.local_instalacaoRow.abastecimento_cil_id =
        this.local_instalacao.abastecimento_cil_id;

      //console.log(this.local_instalacaoRow);
    };

    await this.getRuaById(loadedStateCallback);

    //console.log("modal data");
    //console.log(this.local_instalacaoRow);

    /*
        this.http.__call('local-instalacao/getCurrentGeolocation/' + this.local_instalacaoRow.rua_id, null).subscribe(
          response => {
            console.log(Object(response));

             this.lat = Object(response).results[0].geometry.location.lat;
            this.lng = Object(response).results[0].geometry.location.lng;
            //console.log(this.local_instalacaoRow);
          }
        ); */

    /*     const axios = require('axios');
        const places_apiKey = "AIzaSyDJ8uA-rm_2wI2r-oj_UEZIboOuIM0yzIY";

        const query = await this.local_instalacaoRow.provincia
          + "+" + this.local_instalacaoRow.municipio
          + ((this.local_instalacaoRow.distrito_id > 0) ? "+" + this.local_instalacaoRow.distrito : "");

        const uri = "https://maps.googleapis.com/maps/api/place/textsearch/json?query=" + query + "&key=" + places_apiKey;
        console.log(uri)


        axios.post(uri, {
          json: { todo: '' }
        }, (error, res, body) => {
          if (error) {
            console.error(error);
            //return null;
          }

          return body;
        })
     */
    console.log("finished");
  }



  private selectBoxTipoRamals() {
    this.http.call_get("tipo-ramal/selectBox", null).subscribe((response) => {
      //console.log(Object(response));
      this.tipo_ramals = Object(response);
    });
  }

  private selectBoxDiametroRamals() {
    this.http
      .call_get("diametro-ramal/selectBox", null)
      .subscribe((response) => {
        //console.log(Object(response));
        this.diametro_ramals = Object(response);
      });
  }

  private selectBoxMaterialRamals() {
    this.http
      .call_get("material-ramal/selectBox", null)
      .subscribe((response) => {
        //console.log(Object(response));
        this.material_ramals = Object(response);
      });
  }

  private async getAxios() {
    const query = "Luanda+Belas+Kilamba Kiaxe+golfo 2";

    this.http
      .__call(
        "local-instalacao/getCurrentGeolocation/" +
        this.local_instalacao.rua_id,
        { query: query }
      )
      .subscribe((response) => {
        this.lat = Object(response).results[0].geometry.location.lat;
        this.lng = Object(response).results[0].geometry.location.lng;
      });
  }

  private getRuaById(callback: Function) {
    this.http
      .call_get("rua/getRuaById/" + this.local_instalacao.rua_id, null)
      .subscribe((response) => {

        if (Object(response).code == 200) {
          this.local_instalacaoRow.rua_id = Object(response).data.id;
          this.local_instalacaoRow.rua = Object(response).data.nome;

          this.local_instalacaoRow.bairro_id = Object(response).data.bairro_id;
          this.local_instalacaoRow.bairro = Object(response).data.bairro;
          this.local_instalacaoRow.bairro_abreviatura =
            Object(response).data.bairro_abreviatura;
          this.local_instalacaoRow.quarteirao_id =
            Object(response).data.quarteirao_id;
          this.local_instalacaoRow.quarteirao = Object(response).data.quarteirao;
          this.local_instalacaoRow.has_quarteirao =
            Object(response).data.has_quarteirao;
          this.local_instalacaoRow.distrito_id =
            Object(response).data.distrito_id;
          this.local_instalacaoRow.distrito = Object(response).data.distrito;
          this.local_instalacaoRow.distrito_abreviatura =
            Object(response).data.distrito_abreviatura;
            this.local_instalacaoRow.municipio_abreviatura =
            Object(response).data.municipio_abreviatura;
        
          this.local_instalacaoRow.has_distrito =
            Object(response).data.has_distrito;
          this.local_instalacaoRow.municipio_id =
            Object(response).data.municipio_id;
          this.local_instalacaoRow.municipio = Object(response).data.municipio;
          this.local_instalacaoRow.provincia_id =
            Object(response).data.provincia_id;
          this.local_instalacaoRow.provincia = Object(response).data.provincia;
        }

        if (!this.local_instalacaoRow.has_distrito)
          this.local_instalacaoRow.distrito_id = null;
        if (!this.local_instalacaoRow.has_quarteirao)
          this.local_instalacaoRow.quarteirao_id = null;

        callback();
      });
  }

  private selectBoxMunicipiosByProvincia() {
    this.local_instalacao.distrito_id = null;
    this.local_instalacao.bairro_id = null;
    this.local_instalacao.quarteirao_id = null;
    this.local_instalacao.rua_id = null;

    this.http
      .call_get(
        "municipio/getMunicipiosByProvincia/" +
        this.local_instalacao.provincia_id,
        null
      )
      .subscribe((response) => {
        this.municipios = Object(response).data;
      });

    if (this.local_instalacao.moradia_numero) this.getAutoLoadCIL();
  }

  private selectBoxDistritosByMunicipio() {
    this.local_instalacao.bairro_id = null;
    this.local_instalacao.quarteirao_id = null;
    this.local_instalacao.rua_id = null;

    for (let i = 0; i < this.municipios.length; ++i) {
      if (this.municipios[i].id == this.local_instalacao.municipio_id)
        this.local_instalacao.has_distrito = this.municipios[i].has_distrito;
    }

    if (this.local_instalacao.has_distrito) {
      this.http
        .call_get(
          "distrito/getDistritosByMunicipio/" +
          this.local_instalacao.municipio_id,
          null
        )
        .subscribe((response) => {
          this.distritos = Object(response).data;
        });
    }

    if (!this.local_instalacao.has_distrito) {
      this.selectBoxBairrosByMunicipio();
    }

    if (this.local_instalacao.moradia_numero) this.getAutoLoadCIL();
  }

  private selectBoxBairrosByDistrito(id) {
    this.local_instalacao.quarteirao_id = null;
    this.local_instalacao.rua_id = null;

    this.http
      .call_get("bairro/selectBoxByDistrito/" + id, null)
      .subscribe((response) => {
        this.bairros = Object(response);
      });

    if (this.local_instalacao.moradia_numero) this.getAutoLoadCIL();
  }

  private selectBoxQuarteiraosByBairro() {
    this.local_instalacao.quarteirao_id = null;
    this.local_instalacao.rua_id = null;

    for (let i = 0; i < this.bairros.length; ++i) {
      if (this.bairros[i].id == this.local_instalacao.bairro_id)
        this.local_instalacao.has_quarteirao = this.bairros[i].has_quarteirao;
    }

    if (this.local_instalacao.has_quarteirao) {
      this.http
        .call_get(
          "quarteirao/getQuarteiraosByBairro/" +
          this.local_instalacao.bairro_id,
          null
        )
        .subscribe((response) => {
          this.quarteiraos = Object(response).data;
        });
    }

    if (!this.local_instalacao.has_quarteirao) {
      this.selectBoxRuasByBairro();
    }
  }

  private selectBoxRuasByBairro() {
    this.http
      .call_get(
        "rua/selectBoxByBairro/" + this.local_instalacao.bairro_id,
        null
      )
      .subscribe((response) => {
        this.ruas = Object(response);
      });
  }

  private selectBoxRuasByQuarteirao() {
    this.http
      .call_get(
        "rua/getRuasByQuarteirao/" + this.local_instalacao.quarteirao_id,
        null
      )
      .subscribe((response) => {
        this.ruas = Object(response).data;
      });
  }

  private selectBoxTipoObjectos() {
    this.local_instalacao.ligacao_ramal_id = null;
    this.local_instalacao.objecto_ligacao_id = null;

    this.http
      .call_get("tipo-objecto-tecnico/selectBox", null)
      .subscribe((response) => {
        this.tipo_objectos = Object(response).data;
      });
  }

  private selectBoxObjectoLigacao() {
    this.local_instalacao.ligacao_ramal_id = null;

    this.http
      .call_get(
        "objecto-ligacao-ramal/selectBoxByTipoLigacao/" +
        this.local_instalacao.tipo_objecto_id,
        null
      )
      .subscribe((response) => {
        this.objectos_ramais = Object(response);
      });

    this.getLigacaoRamals();
  }

  private selectBoxByObjectoLigacao() {
    this.http
      .call_get(
        "objecto-ligacao-ramal/selectBoxByObjectoLigacao/" +
        this.local_instalacao.tipo_objecto_id,
        null
      )
      .subscribe((response) => {
        this.objectos_ramais = Object(response);
      });
  }

  private selectBoxBairrosByMunicipio() {
    this.http
      .call_get(
        "bairro/selectBoxByMunicipio/" + this.local_instalacao.municipio_id,
        null
      )
      .subscribe((response) => {
        this.bairros = Object(response);
        this.bairros = this.bairros.length ? this.bairros : []
      });

    if (this.local_instalacao.moradia_numero) this.getAutoLoadCIL();
  }

  private getEstadoCaixaContadors() {
    this.http
      .call_get("estado-caixa-contador/selectBox", null)
      .subscribe((response) => {
        this.estado_caixa_contadors = Object(response);
      });
  }


  private getCampoJardims() {
    this.http.call_get("campo-jardim/selectBox", null).subscribe((response) => {
      this.campo_jardims = Object(response);
    });
  }

  private getAbastecimentoCILs() {
    this.http
      .call_get("abastecimento-cil/selectBox", null)
      .subscribe((response) => {
        this.abastecimento_cils = Object(response);
      });
  }

  private getAutoLoadCIL() {
    //this.local_instalacao.cil = "";

    if (this.load_CIL) {
      if (this.distrito_CIL) {

      } else {
        for (let index = 0; index < this.bairros.length; index++) {
          if (this.bairros[index].id == this.local_instalacao.bairro_id) {
            this.local_instalacao.cil = this.bairros[index].abreviatura;

            for (let index = 0; index < this.quarteiraos.length; index++) {
              if (
                this.quarteiraos[index].id ==
                this.local_instalacao.quarteirao_id
              ) {
                this.local_instalacao.cil =
                  this.local_instalacao.cil +
                  "-" +
                  this.quarteiraos[index].abreviatura +
                  "-" +
                  (this.local_instalacao.moradia_numero
                    ? "-" +
                    (this.predio_flag ? "1" : "0") +
                    this.local_instalacao.moradia_numero
                    : "");

                return;
              }
            }

            return;
          }
        }
      }
    }
  }

  private openEndLocalInstalacaoModal(flag: boolean): void {
    this.local_instalacaoModal = flag;
  }

  public loadListEstabelecimentos(local_instalacaos) {
    this.local_instalacaos = local_instalacaos;
  }

  clickedMarker(label: string, index: number) {
    //console.log(`clicked the marker: ${label || index}`);
  }

  mapClicked($event: AgmCoreModule) {
    //console.log($event["coords"].lat);

    this.local_instalacaoRow.latitude = $event["coords"].lat;
    this.local_instalacaoRow.longitude = $event["coords"].lng;

    this.markers.length = 0;
    this.markers.push({
      lat: $event["coords"].lat,
      lng: $event["coords"].lng,
      draggable: true,
    });
  }

  markerDragEnd(m: marker, $event: AgmCoreModule) {
    //console.log("dragEnd", m, $event);
  }

  markers: marker[] = [
    {
      lat: null,
      lng: null,
      draggable: false,
    },
  ];

  public getConfiguracaos() {
    let result = null;

    const slugs = [this.config.load_cil, this.config.distrito_cil_sequencial];

    for (let index = 0; index < slugs.length; index++) {
      this.http
        .__call("configuracao/getConfiguracaobySlug/" + slugs[index], null)
        .subscribe((response) => {
          if (Object(response).code != 200) {
            result = null;
          } else {
            result = Object(response).data;

            if (slugs[index] == this.config.load_cil)
              this.load_CIL = Boolean(result.valor);
            if (slugs[index] == this.config.distrito_cil_sequencial)
              this.distrito_CIL = Boolean(result.valor);
          }
        });
    }
  }

  mostrarCodigoRamal(e) {
    if (e.target.checked) {
      this.isToShow = e.target.checked
    } else {
      this.isToShow = e.target.checked
      this.ramal.id = null
      this.ramal.codigo_remal = null
    }
  }

  setRamal(item: any) {
    this.ramal.id = item.id
    this.ramal.codigo_remal = item.codigo_remal
    this.view_ramal = false
  }

  private ramals: any[];
  private getRamal() {
    this.view_ramal = true
    this.http.call_get("gestao-ramal/getAll?codigo_remal=" + this.ramal.codigo_remal)
      .subscribe(res => {
        this.ramals = Object(res)
      })
  }
}

interface marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}
