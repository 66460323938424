import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProdutoService {

 public produtos={
  centro_custo_id: null,
  codigo_iso: null,
  created_at: null,
  id: null,
  imposto_id: null,
  imposto_valor: null,
  incidencia_id:null,
  is_active:null,
  moeda: null,
  moeda_id:null,
  nome: null,
  observacao: null,
  tipo: null,
  tipo_produto_id:null,
  valor: null,
  classificacao_produto_id:null
 }

  constructor() { }
}
