import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { ExcelService } from 'src/app/services/excel.service';
import * as moment from 'moment';

@Component({
  selector: 'app-reclamacoes',
  templateUrl: './reclamacoes.component.html',
  styleUrls: ['./reclamacoes.component.css']
})
export class ReclamacoesComponent implements OnInit {

  @ViewChild("search") search;
  @ViewChild("orderBy") orderBy;
  @ViewChild("searchData") searchData;
  @ViewChild("mostrarResultado") mostrarResultado;

  private reclamacao = {
    id: null,
    estado_reclamacao_id: null,
    observacao: null,
    observacao_old: null,
    clienteNome: null,
    estado_actual: null,
    tipoReclamacao: null,
    dataReclamacao: null
  }


  private items: any = [];
  private historicos: any = [];
  private estados: any = [];

  constructor(private http: HttpService, private configService: ConfigService,private excelService: ExcelService) { }

  exportAsXLSX(): void {
    var CurrentDate = new Date();
    var nameFile = "Lista_Reclamações -" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    this.excelService.exportAsExcelFile(document.getElementsByClassName("exportAsXLSXCliente")[0], nameFile);
  }
  exportAsPDF(): void {
    //this.reportLoja.relatorioLoja(this.cobrancas, this.simpleForm.value, 'save');
  }

  imprimirPDF(): void {
    //this.reportLoja.relatorioLoja(this.cobrancas, this.simpleForm.value);
  }

  ngOnInit() {
    this.getPageFilterData(1);
  }

  private getDadosModal() {
    this.getEstados();
  }

  private getEstados() {
    this.http.call_get('reclamacao/estados', null).subscribe(
      response => {
        this.estados = Object(response).data
      }
    );

  }

  private getEstadoReclamacao(id: number) {
    this.configService.loaddinStarter('start');

    this.http.call_get('reclamacao/estado/selectBox/' + id, null).subscribe(
      response => {
        let dado = Object(response).data;
        this.reclamacao.estado_reclamacao_id = dado.estado;
        this.reclamacao.id = dado.id;
        this.reclamacao.estado_actual = dado.estado;
        this.reclamacao.observacao = dado.observacao;
        this.reclamacao.observacao_old = dado.observacao;
        this.configService.loaddinStarter('stop');
      }
    )
  }

  private getHistoricoEstado(id: number) {
    this.configService.loaddinStarter('start');

    this.http.call_get('historico/reclamacao/' + id, null).subscribe(
      response => {
        this.historicos = Object(response).data;
        //console.log(this.historicos)
        this.configService.loaddinStarter('stop');
      }
    )
  }

  private getCliente(nomeCliente: any, tipoReclamacao: any, dataReclamacao: any) {
    this.reclamacao.clienteNome = nomeCliente;
    this.reclamacao.tipoReclamacao = tipoReclamacao;
    this.reclamacao.dataReclamacao = dataReclamacao;
    
  }

  private editarEstadoPedido() {
    //console.log(this.pedido)


    if (this.reclamacao.estado_reclamacao_id == null || this.reclamacao.estado_reclamacao_id == "null") {
      this.configService.showAlert('Seleccione o estado', 'alert-danger', true);
    }
    else {

      this.configService.loaddinStarter('start');
      this.http.__call('reclamacao/update/estado/' + this.reclamacao.id, this.reclamacao).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.configService.loaddinStarter('stop');
            this.getPageFilterData(1);

          }
        }
      );
    }
  }


  private listarReclamacoes() {

    this.configService.loaddinStarter('start');

    this.http.__call('reclamacao/listagem', this.http.filters).subscribe(

      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.configService.loaddinStarter('stop');

      }
    );
  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
    this.listarReclamacoes();
  }




}
