import { Component, OnInit, ViewChild } from "@angular/core";
import { HttpService } from "src/app/providers/http/http.service";
import { ConfigService } from "src/app/providers/config/config.service";
import { ExcelService } from "src/app/services/excel.service";
import * as moment from "moment";
import { Router, ActivatedRoute } from "@angular/router";
import { ExcelAutoService } from "src/app/services/excel/excel-auto.service";
import { ReportClienteService } from "./service.service";

@Component({
  selector: 'app-movimentacao-stock',
  templateUrl: './movimentacao-stock.component.html',
  styleUrls: ['./movimentacao-stock.component.css']
})
export class MovimentacaoStockComponent implements OnInit {

  public movimentos: any = [];
  public movimento: any = [];
  public movimento_1: any = [];
  public agendamento:any=[]
  public estado: boolean=false;
  public serie: any;
  loading: boolean = false;
  artigo_id: any = null;
  data: any = null;

  public filters = {
    search: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    pagination: {
      perPage: 5,// Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima,
      lastPage: null,
      total: null // defini o total de registo da BD
    },
    filter: null // subelemente do filter
    ,is_allexel:false
  }

  private localUrl: any;
  private largura: any;
  private altura: any;
  public armazens = [];
  public artigos = [];
  public armazem_id: any = null;
  public artigoId: any = null;
  loadings = {
    artigo: false,
    armazem: true,
  };

  constructor(
    public http: HttpService,
    public configService: ConfigService,
    private excelService: ExcelService,
    public route: ActivatedRoute,
    private excelsService: ExcelAutoService,
    private ReportClienteService: ReportClienteService,
    public _route: Router
  ) {
    this.route.paramMap.subscribe((params) => {
      this.artigo_id = +params.get("artigo_id");

    });
  }

  ngOnInit() {
    this.getPageFilterData(1);
    this.getArmazens();
    this.getProdutos();
    this.empresaUser()
    this.movimento_1
  }



   exportAsPDF() {
    this.estado=true
    this.filters.is_allexel=true
    this.listarStockMovimentos()/*
    var file = document.getElementsByClassName("exportAsXLSXCliente")[0]
    this.ReportClienteService.relatorioClientes('save', file,this.localUrl); */

  }

  async imprimirPDF() {
    this.estado=true
    this.filters.is_allexel=true
    await this.listarStockMovimentos()
    var file = document.getElementsByClassName("exportAsXLSXCliente")[0]
    this.ReportClienteService.relatorioClientes('print', file,this.localUrl);
  }
  private empresaUser() {
    this.configService.loaddinStarter('start');

    this.http.call_get('empresa/empresa-user', null).subscribe(
      response => {
        this.localUrl = Object(response).data.logotipo
        this.altura = Object(response).data.width
        this.largura = Object(response).data.height
        this.configService.loaddinStarter('stop');
      }
    );
  }
  setTrue(){
    this.filters.pagination.page=this.filters.pagination.page
    this.filters.is_allexel=true
    this.estado=false
    this.listarStockMovimentos()
  }
  public  listarStockMovimentos() {
    this.loading = true;
     this.http
      .__call("stockMovimento/listar/" + this.artigo_id, { filter: this.filters, armazem_id: this.armazem_id, artigo_id: this.artigoId, data: this.data })
      .subscribe( response => {
        this.filters.pagination.lastPage = Object(response).data.lastPage;
        this.filters.pagination.page = Object(response).data.page;
        this.filters.pagination.total = Object(response).data.total;
        this.filters.pagination.perPage = Object(response).data.perPage;
        if(this.filters.is_allexel==false){
          this.movimentos = Object(response).data.data;
        }else{
          this.movimento=[]
          this.movimento = Object(response).data.data;
          if(this.estado){
            var file = document.getElementsByClassName("exportAsXLSXCliente")[0]
            setTimeout(()=>this.ReportClienteService.relatorioClientes('save', file,this.localUrl), 3000);

         }else{
          this.movimento = Object(response).data.data;
           this.exportAsXLSX(this.movimento)
         }
        }

        this.filters.is_allexel=false
        this.loading = false;
      });

  }
  exportAsXLSX(data: any): void {
    var CurrentDate = new Date();
    var keys = [
      { key: 'produto', width: 50 },
      { key: 'codigo_produto', width: 30 },
      { key: 'quantidade', width: 30 },
      { key: 'valor', width: 50 },
      { key: 'documento', width: 30 },
      { key: 'movimento', width: 50 },
      { key: 'armazem', width: 50 },
      { key: 'created_at', width: 40 },
    ];

    var Cols = [
      "Nome do Material",
    	"Código",
      "Entrada"	,
      "Saída"	,
      "Valor",
      "Movimentação"	,
      "Armazem"	,
      "Data",
   ]
    var title = 'LISTA STOCK MOVIMENTOS'
    var nameFile = "LISTA_STOCK_MOVIMENTOS-" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    this.excelsService.excels(data, nameFile, this.localUrl, keys, Cols, title, 5, 8, 40,3)
  }
  private agendamentoByMovimentoStock(item) {
    console.log(item)
    this.configService.loaddinStarter('start');
    this.http.call_get('ordemservico/movimento-stock/' + item.agendamento_id, null).subscribe(
      response => {
        this.agendamento = Object(response).data;
        this.configService.loaddinStarter('stop');
      }
    );
  }

  getPageFilterData(page: number) {
    if (this.filters.pagination.perPage == null) {
      return;
    }
    this.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
    this.listarStockMovimentos();
  }

  public setDataSerie(serie: any) {
    this.serie = serie;
  }

  goToPageLink(artigo: any) {
    //this._route.navigate(["/stock/movimentacao/view/artigo", artigo.artigo_id]);
  }

  public getArmazens() {
    this.loadings.armazem = true;
    this.http.__call("armazem/selectBox", null).subscribe((response) => {
      this.armazens = Object(response).data;
      this.loadings.armazem = false;
    });
  }

  getProdutos() {
    this.loadings.artigo = true;
    this.http.__call("artigos/prod/selectBox", { pagination: { page: 1,perPage:10 } })
      .subscribe((res: any) => {
        this.artigos = Object(res).data;
        this.loadings.artigo = false;
      });
  }



}
