import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { url } from '@rxweb/reactive-form-validators';
import * as moment from 'moment';
import { ConfigService } from 'src/app/providers/config/config.service';
import { HttpService } from 'src/app/providers/http/http.service';
import { ExcelService } from 'src/app/services/excel.service';
import { ExcelAutoService } from 'src/app/services/excel/excel-auto.service';
import { environment } from 'src/environments/environment';
import { RotaService } from '../rotas.service';

@Component({
  selector: 'app-configurar-anomalias',
  templateUrl: './configurar-anomalias.component.html',
  styleUrls: ['./configurar-anomalias.component.css']
})
export class ConfigurarAnomaliasComponent implements OnInit {

  loading: boolean = false;
  disableButton: boolean = true;


  submitted = false;
  public filters = {
    search: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    pagination: {
      perPage: 5,// Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima,
      lastPage: null,
      total: null // defini o total de registo da BD
    },
    filter: null // subelemente do filter
    , is_allexel: false
  }

  items: any = [];
  private tarifarios: any = [];
  estado: any = [];

  formulario: FormGroup

  constructor(private http: HttpService,
    private configService: ConfigService,
    private excelService: ExcelService,
    private _rotaService: RotaService,
    private excelsService: ExcelAutoService,
    private _formBuilder: FormBuilder,
  ) {
    this.createForm()
    this.getPageFilterData(1);
    this.getTarifarios();
  }

  ngOnInit() { }

  ngOnDestroy() {
    this.http.filters.orderBy = null
  }

  get f() {
    return this.formulario.controls;
  }
  createForm() {
    this.formulario = this._formBuilder.group({
      id: null,
      descricao: [null, [Validators.required]],
      is_active: [null, [Validators]],
      slug: [null],
      tarifario_id: null,
      valor: [null],
    })

  }
  private createFlagKeyup(value) {
    this.formulario.patchValue({ flag: value })
  }
  private disable() {
    this.formulario.controls.flag.disable();
  }

  private getTarifarios() {
    this.http.call_get('classe-tarifario/selectBox', null)
      .subscribe(
        response => {
          this.tarifarios = Object(response).data
        }
      );
  }

  private tipo_anomalias() {
    console.log(this.filters)
    this.loading = true
    return this.http.__call('anomalias/listagem', { filters: this.filters }).subscribe(
      response => {
        this.filters.pagination.lastPage = Object(response).lastPage;
        this.filters.pagination.page = Object(response).page;
        this.filters.pagination.total = Object(response).total;
        this.filters.pagination.perPage = Object(response).perPage;

        this.items = Object(response).data;
        this.loading = false;
        if (this.items != 0) {
          this.disableButton = false;
        }
        this.filters.is_allexel = false
        this.loading = false;
      }
    );
  }

  onSubmit() {
    this.submitted = true

    if (this.formulario.invalid) { return }
    const current_id = this.formulario.getRawValue().id
    const url = current_id == null ? 'anomalias/create' : 'anomalias/update/' + current_id;
    this.createOrUpdate(url, this.formulario.value)
    this.loading = true;
  }
  createOrUpdate(url, fb) {
    this.http.__call(url, fb)
      .subscribe(
        response => {
          this.submitted = false
          this.loading = false
          this.configService.showAlert(Object(response).message, 'success', true)
          this.getPageFilterData(1)
          if (this.formulario.value.id == null) this.formulario.reset()
          this.onReset();
        },
        error => {
          this.submitted = false;
        }

      )
    this.loading = false
  }

  _closeModal(closeModal) {
    var action = document.getElementById(closeModal)
    action.click()
  }

  onReset() {
    this.submitted = false;
    this.formulario.reset();
  }

  getPageFilterData(page: number) {
    if (this.filters.pagination.perPage == null) {
      return;
    }
    this.filters.pagination.page = Number.isInteger(page) ? page : 1;
    this.tipo_anomalias();
  }

  private refreshData(item) {
    this.formulario.patchValue(item)
    console.log(this.formulario.value)
  }

}
