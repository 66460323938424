import { Injectable, Input, EventEmitter } from '@angular/core';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Container, removePercent, setGlobalHeight } from '../../../services/styled-pdf'
import { toDate, format } from 'date-fns-tz'

@Injectable({
  providedIn: 'root'
})
export class OcamentoService {

  @Input() app_environment: null;

  public pdfEvent = new EventEmitter<Object>();

  constructor(private auth: AuthService) {
    this.app_environment = this.auth.getAppEnvironment();

    if (this.app_environment == null) {
      var url = require('url');
      var app_url = url.parse(environment.app_url, true);
      this.app_environment = app_url.host;
    }
  }

  public _imprimirOrcamentoServico(
    factura: any,
    produtos: any[],
    cliente: any,
    tipos_identidades: any,
    user: any,
    pagamento: any,
    contrato: any,
    leituras: [],
    contas_bancarias: any,
    lojas: any,
    report: string = 'print',
    original: any = 'Original'
  ) {
let img_logotipo = user ? user.empresa ? user.empresa.logotipo : '' : '';
let page = 1, totalPage = 1

let container = new Container()
function line(top, bottom, marginRight = '100%') {
    container.hr({
        top: (top) + '%',
        marginLeft:'2%',
        marginRight: marginRight,
        bottom: (bottom) + '%'
    })
}

function dateTimeZone(date) {
  if(!date) return '';
  const parisDate = toDate(date)
  const pattern = "yyyy-MM-dd"
  const timeOutput = format(parisDate, pattern, { timeZone: 'UTC/GMT+1' })
  return timeOutput.slice(0, 16);
}
function numberFormat(number) {
  return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2 }).format(number || 0).replace('€', '').trim();
}
function img (imgData, container) {
let style = {
    marginTop : '2%',
    width: '25%',
    height: '20%',
    marginLeft: '3%'
 }
  container.Image(imgData, style)  
} 
img(img_logotipo, container)

function facturaTitle(container, text) {
    let style = {
        fontWeigth: 'bold',
        fontSize: 8,
        marginTop: '4%',
        marginLeft: '70%',
        subTitle: {
                fontWeigth: 'bold',
                fontSize: 7,
                 marginTop: '8%',
                 marginLeft: '70%',
        },
        pagination: {
            fontWeigth: 'normal',
            fontSize: 7,
            marginTop: '8%',
            marginLeft: '85%',
        }
    }
    container.p(text, style)
    container.p('ORIGINAL', style.subTitle)
    container.p(`Página ${page} de ${totalPage}`, style.pagination)
}
facturaTitle(container, 'FACTURA')

function header(data, container) {
    let style = {
        companyTitle: {
            marginTop: '16%',
            marginLeft: '3%',
            fontWeigth: 'bold',
            fontSize: 8,
        },
         streat: {
            marginTop: '18%',
            marginLeft: '3%',
            fontWeigth: 'bold',
            fontSize: 8,
        },
         nif: {
            marginTop: '20%',
            marginLeft: '3%',
            fontWeigth: 'bold',
            fontSize: 8,
            value: {
                marginTop: '20%',
                marginLeft: '7%',
                fontWeigth: 'bold',
                fontSize: 8
           }
        }, 
        phone: {
            marginTop: '22%',
            marginLeft: '3%',
            fontWeigth: 'bold',
            fontSize: 8,
            value: {
                marginTop: '22%',
                marginLeft: '11%',
                fontWeigth: 'bold',
                fontSize: 8
           }
         }, 
         fax: {
            marginTop: '22%',
            marginLeft: '24%',
            fontWeigth: 'bold',
            fontSize: 8,
            value: {
                marginTop: '22%',
                marginLeft: '29%',
                fontWeigth: 'bold',
                fontSize: 8
           }
         }, 
    }
    container.p(((user || {}).empresa || {}).companyName, style.streat)
    container.p(((user || {}).empresa || {}).addressDetail, style.companyTitle)
    container.p('NIF:', style.nif)
    container.p(((user || {}).empresa || {}).taxRegistrationNumber, style.nif.value)
     container.p('Telefone:', style.phone)
    container.p(((user || {}).empresa || {}).telefone, style.phone.value)
    container.p('Fax:', style.fax)
    container.p(((user || {}).empresa || {}).telefone, style.fax.value)
}
 header('', container) 
 function footer() {
    let globalHeight = 95
    let style = {
        marginTop: (globalHeight) + '%',
        marginLeft: '3%',
        fontSize: 8,
        fontWeigth: 'normal',
        div: {
            marginLeft: '47%',
            width: '29%',
            heigth: (globalHeight + 4.5)+'%',
            padding: '4%',
            border: [240, 242, 245],
            bgColor : [225, 225, 227]
        }
    }
    style.marginTop = (globalHeight + 2) + '%'
    style.fontWeigth='normal'
    container.p('CONSERVE ESTE DOCUMENTO', style)
     style.fontWeigth='normal'
       style.marginTop = (globalHeight + 4) + '%'
    container.p('* VALIDO COMO RECIBO APÓS CERTIFICADO PELAS CAIXAS DE EPAL, E.P. "IMPOSTO SELO COM GUIA".', style)
     style.fontWeigth='bold'
    style.fontWeigth='normal'
     style.marginLeft = '3%'
      style.marginTop = (globalHeight + 6) + '%'
    container.p('-Processado por programa validado n.º 103/AGT', style)
     style.fontWeigth='bold'
     
     style.fontWeigth='bold'
    style.fontWeigth='normal'
     style.marginLeft = '75%'
    container.p('EPAL E.P. runs SAP, powered by ITGEST', style)
     style.fontWeigth='bold'
}
footer()
 function cardsDocument(data, container) {
     let style = {
        th1: {
            marginLeft: '2%',
            width: '19%',
            heigth: '24%',
            padding: '4%',
            border: [0,0,0],
            bgColor: [201, 201, 201],
            text: {
                marginTop: '26%',
                fontSize: 8,
                marginLeft: '6%',
                fontWeigth: 'bold',
            }
        },
        th2: {
            marginLeft: '15.5%',
            width: '13%',
            heigth: '24%',
            padding: '4%',
            border: [0,0,0],
             bgColor: [201, 201, 201],
            text: {
                marginTop: '26%',
                marginLeft: '23%',
                fontSize: 8,
                fontWeigth: 'bold'
            }
        },
        td1: {
            marginLeft: '2%',
            width: '32.2%',
            heigth: '26.9%',
            padding: '4%',
            border: [0,0,0],
            text1: {
                marginTop: '28.5%',
                marginLeft: '4%',
                fontSize: 7,
                fontWeigth: 'normal'
            },
             text2: {
                marginTop: '28.5%',
                marginLeft: '26%',
                fontSize: 7,
                fontWeigth: 'normal'
            }
        },
     }

     container.div(style.th1)
     container.p('Nº DOCUMENTO', style.th1.text)
     container.div(style.th2)
     container.p('DATA', style.th2.text)
     container.div(style.td1)
      container.p(factura.factura_sigla, style.td1.text1)
      container.p(dateTimeZone(factura.created_at), style.td1.text2)
 }
cardsDocument('', container)

 function cardsReferencia(data, container) {
     let style = {
        th1: {
            marginLeft: '2%',
            width: '32.5%',
            heigth: '31%',
            padding: '4%',
            border: [0,0,0],
            bgColor: [201, 201, 201],
            text: {
                marginTop: '33%',
                marginLeft: '12%',
                fontSize: 8,
                fontWeigth: 'bold'
            }
        },
        td1: {
            marginLeft: '2%',
            width: '32.5%',
            heigth: '33.8%',
            padding: '4%',
            border: [0,0,0],
            text1: {
                marginTop: '35.5%',
                marginLeft: '5%',
                fontSize: 7,
                fontWeigth: 'normal'
            }
        },
     }

     container.div(style.th1)
     container.p('DOC. REFERÊNCIA', style.th1.text)
     container.div(style.td1)
      container.p('Ordem 1032325247 de 27.06.2022', style.td1.text1)
 }
cardsReferencia('', container)

function cardsValor(data, container) {
     let style = {
        th1: {
            marginLeft: '26%',
            width: '25%',
            heigth: '24%',
            padding: '4%',
            border: [0,0,0],
             bgColor: [201, 201, 201],
            text: {
                marginTop: '26%',
                marginLeft: '41%',
                fontSize: 8,
                fontWeigth: 'bold'
            }
        },
        td1: {
            marginLeft: '26%',
            width: '25%',
            heigth: '26.9%',
            padding: '4%',
            border: [0,0,0],
            text1: {
                marginTop: '28.5%',
                marginLeft: '39%',
                fontSize: 7,
                fontWeigth: 'normal'
            }
        },
     }

     container.div(style.th1)
     container.p('VALOR em AOA', style.th1.text)
     container.div(style.td1)
      container.p(String(factura.total), style.td1.text1)
 }
cardsValor('', container)

function cardsValorPagamento(data, container) {
     let style = {
        th1: {
            marginLeft: '26%',
            width: '25%',
            heigth: '29.8%',
            padding: '4%',
            border: [0,0,0],
             bgColor: [201, 201, 201],
            text: {
                marginTop: '31.5%',
                marginLeft: '39%',
                fontSize: 8,
                fontWeigth: 'bold'
            }
        },
        td1: {
            marginLeft: '26%',
            width: '25%',
            heigth: '32.7%',
            padding: '4%',
            border: [0,0,0],
            text1: {
                marginTop: '34.5%',
                marginLeft: '45%',
                fontSize: 7,
                fontWeigth: 'normal'
            }
        },
     }

     container.div(style.th1)
     container.p('DATA LIMITE DE PAGAMENTO', style.th1.text)
     container.div(style.td1)
      container.p(factura.data_vencimento, style.td1.text1)
 }
cardsValorPagamento('', container)

function bordInfo(data, container) {
     let style = {
        div: {
            marginLeft: '45.5%',
            width: '35%',
            heigth: '24%',
            padding: '18%',
            border: [0,0,0],
            text: {
                marginTop: '26%',
                marginLeft: '67%',
                fontSize: 8,
                fontWeigth: 'bold'
            },
        },
     }
     let nif = tipos_identidades.find(obj => obj.nome == 'NIF')

     container.div(style.div)
     container.p('Exmo(a) Senhor(a)', style.div.text)
     style.div.text.marginTop = '28%'
     style.div.text.fontWeigth = 'normal'
     container.p(cliente.nome.trimStart(), style.div.text)
      style.div.text.marginTop = '29.5%'
   container.p(cliente.morada, style.div.text)
      style.div.text.marginTop = '31%'
      container.p(cliente.municipio.nome, style.div.text)
       style.div.text.marginTop = '32.5%'
     container.p(cliente.municipio.nome, style.div.text)
      style.div.text.marginTop = '34%'
     container.p('ANGOLA', style.div.text)
      style.div.text.marginTop = '35.5%'
     container.p(`N.º CONTRIBUINTE: ${ nif.numero_identidade }`, style.div.text)
 }
bordInfo('', container)
line(38, 38)
function fistTable(data, container) {
     let style = {
        th1: {
            marginLeft: '2%',
            width: '10%',
            heigth: '39%',
            padding: '4%',
            border: [0,0,0],
            bgColor: [201, 201, 201],
            text: {
                marginTop: '41%',
                marginLeft: '5%',
                fontSize: 8,
                fontWeigth: 'bold'
            },
            td: {
                marginLeft: '2%',
                width: '10%',
                heigth: '45.9%',
                padding: '4%',
                border: [0,0,0],
                text: {
                    marginTop: '68%',
                    marginLeft: '5%',
                    fontSize: 8,
                    fontWeigth: 'normal'
                },
            }
        },
        th2: {
            marginLeft: '9.1%',
            width: '28.5%',
            heigth: '39%',
            padding: '4%',
            border: [0,0,0],
            bgColor: [201, 201, 201],
            text: {
                marginTop: '41%',
                marginLeft: '22%',
                fontSize: 8,
                fontWeigth: 'bold'
            },
            td: {
                marginLeft: '9.1%',
                width: '28.2%',
                heigth: '45.9%',
                padding: '4%',
                border: [0,0,0],
                text: {
                    marginTop: '68%',
                    marginLeft: '15%',
                    fontSize: 8,
                    fontWeigth: 'normal'
                },
            }
        },
        th3: {
            marginLeft: '29%',
            width: '15%',
            heigth: '39%',
            padding: '4%',
            border: [0,0,0],
            bgColor: [201, 201, 201],
            text: {
                marginTop: '41%',
                marginLeft: '44%',
                fontSize: 8,
                fontWeigth: 'bold'
            },
            td: {
                marginLeft: '29%',
                width: '14.2%',
                heigth: '45.9%',
                padding: '4%',
                border: [0,0,0],
                text: {
                    marginTop: '68%',
                    marginLeft: '43%',
                    fontSize: 8,
                    fontWeigth: 'normal'
                },
            }
        },
        th4: {
            marginLeft: '39%',
            width: '15%',
            heigth: '39%',
            padding: '4%',
            border: [0,0,0],
            bgColor: [201, 201, 201],
            text: {
                marginTop: '41%',
                marginLeft: '59%',
                fontSize: 8,
                fontWeigth: 'bold'
            },
            td: {
                marginLeft: '39%',
                width: '15%',
                heigth: '45.9%',
                padding: '4%',
                border: [0,0,0],
                text: {
                    marginTop: '68%',
                    marginLeft: '62%',
                    fontSize: 8,
                    fontWeigth: 'normal'
                },
            }
        },
        th5: {
            marginLeft: '49.5%',
            width: '15%',
            heigth: '39%',
            padding: '4%',
            border: [0,0,0],
            bgColor: [201, 201, 201],
            text: {
                marginTop: '41%',
                marginLeft: '76%',
                fontSize: 8,
                fontWeigth: 'bold'
            },
            td: {
                marginLeft: '49.5%',
                width: '14.2%',
                heigth: '45.9%',
                padding: '4%',
                border: [0,0,0],
                text: {
                    marginTop: '68%',
                    marginLeft: '76%',
                    fontSize: 8,
                    fontWeigth: 'normal'
                },
            }
        },
        th6: {
            marginLeft: '59.5%',
            width: '15%',
            heigth: '39%',
            padding: '4%',
            border: [0,0,0],
            bgColor: [201, 201, 201],
            text: {
                marginTop: '41%',
                marginLeft: '88%',
                fontSize: 8,
                fontWeigth: 'bold'
            },
            td: {
                marginLeft: '59.5%',
                width: '15%',
                heigth: '45.9%',
                padding: '4%',
                border: [0,0,0],
                text: {
                    marginTop: '68%',
                    marginLeft: '92%',
                    fontSize: 8,
                    fontWeigth: 'normal'
                },
            }
        },
     }
     container.div(style.th1)
     container.p('CÓDIGO', style.th1.text)
     container.div(style.th2)
     container.p('DESIGNAÇÃO', style.th2.text)
    container.div(style.th3)
     container.p('QUANTIDADE', style.th3.text)
    container.div(style.th4)
     container.p('VALOR UNIT.', style.th4.text)
    
     container.div(style.th5)
     container.p('IVA %', style.th5.text)
    
    container.div(style.th6)
     container.p('TOTAL LÍQ.', style.th6.text)
     let header =  removePercent(style.th1.heigth) + 2.9
     let content = removePercent(style.th1.text.marginTop) + 2.9 
     
   for(let i = 0; i <  produtos.length; i++) {
    const data = produtos[i];
     style.th1.td.heigth = (header)  + '%'
     container.div(style.th1.td)
     style.th1.td.text.marginTop = (content)  + '%'
     container.p(data.imposto.codigo == null ? 'IVA' : produtos[0].imposto.codigo, style.th1.td.text)
     
      style.th2.td.heigth = (header )  + '%'
     container.div(style.th2.td)
       style.th2.td.text.marginTop = (content)  + '%'
     container.p(((data || {}).imposto || {}).descricao, style.th2.td.text)
      
      style.th3.td.heigth = (header )  + '%'
     container.div(style.th3.td)
      style.th3.td.text.marginTop = (content)  + '%'
     container.p(data.quantidade + '', style.th3.td.text)
      
     style.th4.td.heigth = (header )  + '%'
     container.div(style.th4.td)
      style.th4.td.text.marginTop = (content)  + '%'
     container.p(numberFormat(data.valor) + '', style.th4.td.text)
     
      style.th5.td.heigth = (header )  + '%'
     container.div(style.th5.td)
      style.th5.td.text.marginTop = (content)  + '%'
     container.p(numberFormat(data.valor_imposto) + '', style.th5.td.text)
      
      style.th6.td.heigth = (header )  + '%'
      container.div(style.th6.td)
      style.th6.td.text.marginTop = (content)  + '%'
      container.p(numberFormat(data.total) + '', style.th6.td.text)
       content+=2.9
       header +=2.9
       if(header >= 90) container.addPage()
    }
    container.setGlobalHeight(header)
 }
let data = [
     {codigo :'1', designacao: 'Ligação de Ramal de Água', qtd: '1 CDA',
         valor_uni : '298.800,88', iva: '14%', total: '298.800,88'
     },
    ]
fistTable(data, container)

line(2, 2)
container.addPage(80, 0)
function taxaIva() {
    
    let style = {
        marginTop: '4%',
        marginLeft: '3%',
        fontSize: 8,
        fontWeigth: 'normal'
    }
    container.p('Os artigos/serviços presentes no documento estão sujeitos à taxa de IVA em vigor', style)
    style.marginTop = '6%'
    style.fontWeigth='bold'
    container.p('Quadro Resumo do IVA', style)
     line(6.5, 6.5, '40%')
     style.marginTop =  '7.5%'
     style.marginLeft = '4%'
    container.p('Taxa', style)
     style.marginLeft = '15%'
     style.marginTop = '7.5%'
    container.p('Incidência', style)
     style.marginLeft = '30%'
    style.marginTop = '7.5%'
    container.p('Valor IVA', style)
    line(8, 8, '40%')

    container.addUpGlobalHeight(2)

    style.fontWeigth='normal'
    style.marginTop = '7%'
        style.marginLeft = '4%'
    container.p((produtos[0].imposto.codigo == null ? 'IVA' : produtos[0].imposto.codigo), style)
     style.marginLeft = '15%'
     style.marginTop = '7%'
    container.p(numberFormat(factura.totalSemImposto), style)
     style.marginLeft = '30%'
    style.marginTop = '7%'
    container.p(numberFormat((factura || {}).totalComImposto), style)
     style.marginLeft = '3%'
     style.marginTop = '9%'
    container.p('IVA - Regime Geral', style)
    
}
taxaIva()

function servico() {
    let style = {
        marginTop:  '2%',
        marginLeft: '67%',
        fontSize: 8,
        fontWeigth: 'normal',
        div: {
            marginLeft: '47%',
            width: '29%',
            heigth: '6.5%',
            padding: '4%',
            border: [240, 242, 245],
            bgColor : [225, 225, 227]
        }
    }
    style.marginTop = '4%'
    style.fontWeigth='bold'
    container.p('Serviços', style)
     style.fontWeigth='normal'
     style.marginLeft = '89%'
    container.p(numberFormat(factura ? factura.totalSemImposto : ''), style)
     style.fontWeigth='bold'
     
      style.marginLeft = '67%'
     style.marginTop = '6%'
    container.p('IVA', style)
    style.fontWeigth='normal'
      style.marginLeft = '89%'
    container.p(numberFormat(factura ? factura.totalComImposto : '') + '', style)
    
     style.fontWeigth='bold'
      style.marginLeft = '67%'
     style.marginTop = '8.5%'
    container.div(style.div)
    container.p('Total (AOA)', style)
    style.fontWeigth='normal'
      style.marginLeft = '89%'
    container.p(numberFormat(factura ? factura.total : ''), style)
}
line(15, 15)
servico()

container.addPage(74.9, -16)
function bordMassege(data, container) {
    
     let style = {
        div: {
            marginLeft: '2%',
            width: '39%',
            heigth: '20%',
            padding: '23%',
            border: [0,0,0],
            text: {
                marginTop: '2%',
                marginLeft: '4%',
                fontSize: 5,
                fontWeigth: 'bold'
            },
        },
     }
     
     //right
    style.div.text.marginTop = '19%',
    container.p('MENSAGENS:', style.div.text)
     style.div.text.marginTop = '21.5%',
     container.p('Contas Bancárias (IBAN):', style.div.text)
     

     let header =  removePercent(style.div.heigth) + 2.9
     let content = removePercent(style.div.text.marginTop) + 2.9 
     //left
    for(let i = 1; i < contas_bancarias.length; i++) {
      style.div.text.marginLeft = '4%',
      style.div.text.marginTop = (content)  + '%'
      let data = contas_bancarias[i - 1]
      let data1 = contas_bancarias[i]
      container.p(((data || {}).abreviatura || '') + ' ' + ((data || {}).iban || ''), style.div.text)

      style.div.text.marginLeft = '23%',

      container.p(((data1 || {}).abreviatura || '') + ' ' + ((data || {}).iban || ''), style.div.text)
      
       content+=1.9
       header +=1.9
    }
       style.div.text.marginLeft = '4%',
      style.div.text.marginTop =  '33.5%',
        style.div.text.fontSize  =  7,
      container.p('Sempre que ocorrer uma transferência para a conta da EPAL, EP', style.div.text)
       style.div.text.marginTop =  '35%',
      container.p('agradecemos que sejam indicados os Nºs das faturas que liquida.', style.div.text)
      
     container.div(style.div)
 }
 bordMassege('', container)
function secondTable(data, container) {
    
     let style = {
        th1: {
            marginLeft: '31.5%',
            width: '20%',
            heigth: '20%',
            padding: '4%',
            border: [0,0,0],
            bgColor: [201, 201, 201],
            text: {
                marginTop:  '22%',
                marginLeft: '50%',
                fontSize: 8,
                fontWeigth: 'bold'
            },
            td: {
                marginLeft: '31.5%',
                width: '20%',
                heigth: '22.9%',
                padding: '3%',
                border: [0,0,0],
                text: {
                    width: 30,
                    marginTop: '24.5%',
                    marginLeft: '47%',
                    fontSize: 8,
                    fontWeigth: 'normal'
                },
            }
        },
        th2: {
            marginLeft: '45.5%',
            width: '15%',
            heigth: '20%',
            padding: '4%',
            border: [0,0,0],
            bgColor: [201, 201, 201],
            text: {
                marginTop: '22%',
                marginLeft: '67%',
                fontSize: 8,
                fontWeigth: 'bold'
            },
            td: {
                marginLeft: '45.5%',
                width: '15%',
                heigth: '22.9%',
                padding: '3%',
                border: [0,0,0],
                text: {
                    marginTop: '24.5%',
                    marginLeft: '66%',
                    fontSize: 8,
                    fontWeigth: 'normal'
                },
            }
        },
        th3: {
            marginLeft: '56%',
            width: '20%',
            heigth: '20%',
            padding: '4%',
            border: [0,0,0],
            bgColor: [201, 201, 201],
            text: {
                marginTop: '22%',
                marginLeft: '81%',
                fontSize: 8,
                fontWeigth: 'bold'
            },
            td: {
                marginLeft: '56%',
                width: '20%',
                heigth: '22.9%',
                padding: '3%',
                border: [0,0,0],
                text: {
                    width: 0,
                    marginTop: '24.5%',
                    marginLeft: '81%',
                    fontSize: 8,
                    fontWeigth: 'normal'
                },
            }
        },
     }

     container.th(style.th1)
     container.th(style.th2)
     container.th(style.th3)
    
     container.p('Factura Nº', style.th1.text)
     container.p('Valor', style.th2.text)
     container.p('Data Limite de Pagamento', style.th3.text)
     
     container.p(factura.factura_sigla, style.th1.td.text)
     container.p(numberFormat(factura.total) + '', style.th2.td.text)
     container.p(dateTimeZone(factura.data_vencimento), style.th3.td.text)
     
     container.td(style.th2.td)
     container.td(style.th1.td)
     container.td(style.th3.td)
 }
 secondTable('', container)
function sirdTable(data, container) {
    container.addUpGlobalHeight(7)
     let style = {
        th1: {
            marginLeft: '31.5%%',
            width: '20%',
            heigth: '20%',
            padding: '4%',
            border: [0,0,0],
            bgColor: [201, 201, 201],
            text: {
                marginTop: '22%',
                marginLeft: '49%',
                fontSize: 8,
                fontWeigth: 'bold'
            },
            td: {
                marginLeft: '31.5%%',
                width: '20%',
                heigth: '22.9%',
                padding: '3%',
                border: [0,0,0],
                text: {
                    marginTop: '24.5%',
                    marginLeft: '47%',
                    fontSize: 8,
                    fontWeigth: 'normal'
                },
            }
        },
        th2: {
            marginLeft: '45.5%',
            width: '15%',
            heigth: '20%',
            padding: '4%',
            border: [0,0,0],
            bgColor: [201, 201, 201],
            text: {
                marginTop: '22%',
                marginLeft: '67.9%',
                fontSize: 8,
                fontWeigth: 'bold'
            },
            td: {
                marginLeft: '45.5%',
                width: '15%',
                heigth: '22.9%',
                padding: '3%',
                border: [0,0,0],
                text: {
                    marginTop: '24.5%',
                    marginLeft: '66%',
                    fontSize: 8,
                    fontWeigth: 'normal'
                },
            }
        },
        th3: {
            marginLeft: '56%',
            width: '20%',
            heigth: '20%',
            padding: '4%',
            border: [0,0,0],
            bgColor: [201, 201, 201],
            text: {
                marginTop: '22%',
                marginLeft: '84%',
                fontSize: 8,
                fontWeigth: 'bold'
            },
            td: {
                marginLeft: '56%',
                width: '20%',
                heigth: '22.9%',
                padding: '3%',
                border: [0,0,0],
                text: {
                    marginTop: '24.5%',
                    marginLeft: '81%',
                    fontSize: 8,
                    fontWeigth: 'normal'
                },
            }
        },
     }

     container.th(style.th1)
     container.th(style.th2)
    container.th(style.th3)
     container.p('Código Local', style.th1.text)
     container.p('Cód. Entidade', style.th2.text)
     container.p('Cód. Cliente', style.th3.text)
     
     container.p(((factura || {}).lines[0] || {}).id + '', style.th1.td.text)
     container.p((user.empresa || {}).id + '', style.th2.td.text)
     container.p(cliente.id + '', style.th3.td.text)
     
     container.td(style.th1.td)
     container.td(style.th2.td)
     container.td(style.th3.td)
 }
  sirdTable('', container)

  container.save(factura.factura_sigla + '.pdf', report)

  }

  public imprimirOrcamentoServico(
    factura: any,
    produtos: any[],
    cliente: any,
    tipos_identidades: any,
    user: any,
    pagamento: any,
    contrato: any,
    leituras: [],
    contas_bancarias: any,
    lojas: any,
    report: string = 'imprimir',
    original: any = 'Original'
  ) {

    var numberPage = 1;
    var img_logotipo = user ? user.empresa ? user.empresa.logotipo : '' : '';
    var doc = new jsPDF();
    doc.addImage(img_logotipo, 'JPEG', 8, 14, 28, 24)

    doc.setFontSize(8);
    doc.setFont("calibri");
    doc.setTextColor(0);
    doc.text('' + (user ? user.empresa ? user.empresa.companyName : '' : '' || ''), 37, 20);
    doc.setTextColor(0);
    doc.text('' + (user ? user.empresa ? user.empresa.addressDetail : '' : '' || ''), 37, 25);
    doc.text('NIF: ' + (user ? user.empresa ? user.empresa.taxRegistrationNumber : '' : '' || ''), 37, 30);
    doc.text('Email: ' + (user ? user.empresa ? user.empresa.email : '' : '' || ''), 37, 35);
    doc.text('Telefone: ', 95, 30);
    doc.text('' + (user ? user.empresa ? user.empresa.telefone : '' : '' || ''), 107, 30);
    doc.text('WebSite: ', 95, 35);
    doc.setTextColor(0, 0, 255);
    doc.text('' + (user ? user.empresa ? user.empresa.site : '' : '' || ''), 107, 35);

    doc.setFontSize(8);
    doc.setDrawColor(0);
    doc.setFillColor(255);
    doc.rect(145, 14, 48, 23, 'B');

    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.text('Orçamento N.º', 148, 20);
    doc.text('Data Emissão:', 148, 25);
    doc.text('Vencimento:', 148, 30);
    doc.text('Via: ' + original, 148, 35);

    doc.setFontSize(8);
    doc.setFontType("normal");
    doc.text('' + factura ? factura.factura_sigla : '', 168, 20);
    doc.text('' + moment(factura ? factura.created_at : '').format("DD/MM/YYYY"), 168, 25);
    doc.text('' + (factura ? factura.data_vencimento === null ? '' : moment(factura.data_vencimento).format('DD/MM/YYYY') : "" ), 168, 30);
    doc.setTextColor(0);

    doc.setFontSize(8);
    doc.setFont("calibri");
    doc.setFontType("normal");
    doc.text(cliente ? cliente.nome.trimStart() : '', 120, 52);
    doc.text((cliente ? cliente.morada : '' || ''), 120, 57);
    doc.text('' + (cliente ? cliente.municipio ? cliente.municipio.nome : '' : '' || ''), 120, 62);


    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.rect(12, 75, 40.5, 3.5, 'B');
    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.setFont("calibri");
    doc.text('CONTRATO N.º: ' + (contrato !== null ? contrato.id || '' : ''), 13, 77.5);
    doc.text('CIL: ' + (contrato !== null ? contrato.localconsumo != null ? contrato.localconsumo.cil : '' || '' : ''), 13, 83);
    doc.text('Morada Local de Consumo:', 13, 88);
    doc.setFontType("normal");
    doc.text('Bairro : ' + (contrato !== null ? contrato.localconsumo != null ? contrato.localconsumo.bairro : '' || '' : ''), 13, 92);
    doc.text('Rua :  ' + (contrato !== null ? contrato.localconsumo != null ? contrato.localconsumo.rua : '' || '' : ''), 13, 96);
    doc.text('Apartamento/Moradia/Caixa : ' + (contrato !== null ? contrato.localconsumo != null ? contrato.localconsumo.moradia_numero : '' || '' : ''), 13, 100);
    doc.text('Prédio :  ' + (contrato !== null ? contrato.localconsumo != null ? contrato.localconsumo.predio_nome : '' || '' : ''), 13, 103);



    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.rect(85, 75, 34.5, 3.5, 'B');
    doc.setFontType("bold");
    doc.text('CLIENTE N.º: ' + cliente.id, 86, 77.5);
    doc.text('NIF: ', 86, 88);
    doc.text('Tarifa: ', 86, 93);
    doc.setFontType("normal");
    doc.text(cliente ? cliente.nome.trimStart() : '', 86, 83);

    let nif = tipos_identidades.find(obj => obj.nome == 'NIF')
    doc.text('' + (nif ? nif.numero_identidade : ""), 92, 88);
    doc.text('' + (contrato !== null ? contrato.tarifa ? contrato.tarifa.descricao : '' || '' : ''), 96, 93);

    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.rect(12, 110, 182, 5, 'B');
    doc.setFontSize(8);
    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.text('Resumo da ' + (factura ? factura.serie ? factura.serie.documento ? factura.serie.documento.nome : '' : '' : '' || ''), 16, 113.5);
    doc.setFontType("normal");

    doc.setDrawColor(0, 0, 0);
    doc.setLineWidth(0.1);
    doc.line(12, 114.5, 194, 114.5); // horizontal line

    doc.setFontType("bold");
    doc.text('Descrição', 13, 118);
    doc.text('Qtd.', 70, 118);
    doc.text('Un.', 85, 118);
    doc.text('V. Unit', 100, 118);
    doc.text('Total S/Iva', 120, 118);
    doc.text('Cod.Imp.', 140, 118);
    doc.text('V.Imp.', 162, 118);
    doc.text('TOTAL', 183, 118);
    doc.setTextColor(0);
    doc.setFontType("normal");

    doc.setDrawColor(0, 0, 0);
    doc.setLineWidth(0.1);
    doc.line(12, 119, 194, 119); // horizontal line
    doc.setFontSize(6);
    doc.setTextColor(0);

    var posY = 125;
    var totalImposto = 0;
    var totalIncidencia = 0;

    for (var i = 0; i < produtos.length; i++) {
      const data = produtos[i];

      doc.text('' + data.produto.nome, 13, posY);
      doc.text('' + data.quantidade, 74, posY, 'right');
      doc.text('' + (data.produto.incidencia ? data.produto.incidencia.abreviacao : ''), 89, posY, 'right');
      doc.text('' + this.numberFormat(data.valor), 109, posY, 'right');
      doc.text('' + this.numberFormat((data.linhaTotalSemImposto)), 133, posY, 'right');
      doc.text('' + (produtos[0].imposto.codigo == null ? 'IVA (14%)' : produtos[0].imposto.codigo), 151, posY, 'right');
      doc.text('' + this.numberFormat(data.valor_imposto), 170, posY, 'right');
      doc.text('' + this.numberFormat(data.total), 193, posY, 'right');
      posY += 5;

      totalImposto += data.valor_imposto;
      totalIncidencia += data.quantidade * data.valor;

      const hasReachedPageLimit = doc.internal.pageSize.height < (posY + 20)

      if (hasReachedPageLimit) {
        posY = 10;
        doc.addPage();
      }
    }

    doc.setFontSize(8);
    doc.setDrawColor(0, 0, 0);
    doc.setLineWidth(0.1);
    doc.line(12, posY + 4.5, 194, posY + 4.5); // horizontal line

    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.rect(12, posY + 5, 182, 5, 'B');
    doc.text('TOTAL ' + factura.serie.documento.nome, 13, posY + 8);
    doc.text('' + this.numberFormat(factura.total), 193, posY + 8, 'right');

    //RESUMO DOS IMPOSTOS

    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.rect(12, posY + 22, 182, 5, 'B');
    doc.text('RESUMO DOS IMPOSTOS', 13, posY + 25);
    doc.text('CÓDIGO', 13, posY + 30);
    doc.text('INCIDÊNCIA', 52, posY + 30);
    doc.text('DESCRIÇÃO', 85, posY + 30);
    doc.text('VALOR IMPOSTO', 115, posY + 30);
    doc.setDrawColor(0, 0, 0);
    doc.setLineWidth(0.1);
    doc.line(12, posY + 31, 194, posY + 31); // horizontal line
    doc.text('' + (produtos[0].imposto.codigo == null ? 'IVA' : produtos[0].imposto.codigo), 13, posY + 35);
    doc.text('' + this.numberFormat(factura.totalSemImposto), 68, posY + 35, 'right');// adicionar o valor total sem o IVA
    doc.text('' + (produtos[0] ? produtos[0].imposto ? produtos[0].imposto.descricao : '' : '' == null ? 'IVA (14%)' : produtos[0] ? produtos[0].imposto ? produtos[0].imposto.descricao : '' : ''), 80, posY + 35);
    doc.text('' + this.numberFormat(factura ? factura.totalComImposto : ''), 138, posY + 35, 'right');

    // END RESUMO DOS IMPOSTOS

    var somaY = 0;

    //LEITURA
    if (factura.leitura != null) {
      doc.setTextColor(0);
      doc.setFontType("bold");
      doc.setDrawColor(250);
      doc.setFillColor(220, 220, 220);
      doc.rect(12, posY + 41, 182, 5, 'B');
      doc.text('LEITURA: ' + (factura ? factura.leitura : '' || ''), 13, posY + 45);
      somaY = 4;
    }
    //END LEITURA


    //OBSERVAÇÃO
    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.rect(12, posY + 45 + somaY, 182, 5, 'B');
    doc.text('OBSERVAÇÃO: ' + (factura ? factura.observacao === null ? '' : factura.observacao : ''), 13, posY + 49 + somaY);
    //END OBSERVAÇÃO
    doc.setFontType("bold")

    posY = 135;
    //NOTA CREDITO
    doc.setDrawColor(0);
    doc.setFillColor(220, 220, 220);
    doc.rect(10, posY + 89, 54, 5, 'B');
    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.text('Estado: ', 12, posY + 92.5);
    doc.setTextColor('red');
    doc.text('Documento não serve de factura', 23, posY + 92.5);
    doc.setTextColor(0);

    //REGIME DE IMPOSTO
    doc.setDrawColor(0);
    doc.setFillColor(220, 220, 220);
    doc.rect(160, posY + 89, 35, 5, 'B');
    doc.setTextColor(0);
    doc.setFontType("bold");

    let tipo_regime = ((user ? user.empresa ? user.empresa.sigla_empresa : '' : '') == 'EPASN' || (user ? user.empresa ? user.empresa.sigla_empresa : '' : '') == 'EPASLS') ? 'Regime Simplificado' : 'IVA - Regime de Caixa'

    if (user.empresa.sigla_empresa == 'EPASN') {
      if (new Date(factura.created_at) > new Date('2021-05-31')) {
        tipo_regime = 'IVA - Regime de Caixa'
      } else {
        tipo_regime = 'Regime Simplificado'
      }
    }

    doc.text(tipo_regime, 192, posY + 92.5, 'right');
    doc.setTextColor(0);

    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.rect(10, posY + 106, 185.5, 5, 'B');
    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.setDrawColor(0);
    doc.rect(10, posY + 95, 185, 20, 'B');
    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.setTextColor(0);
    doc.setDrawColor(250);
    doc.setFillColor(220, 220, 220);
    doc.text('Contas Bancárias (IBAN):', 12, posY + 99.5);
    doc.setFontSize(8);
    doc.setDrawColor(0);
    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.setFontSize(8);
    doc.setDrawColor(0);
    doc.setFillColor(255);
    doc.rect(10, posY + 101, 185, 20, 'B');
    doc.setTextColor(0);
    doc.setFontType("normal");


    var posX = 12;
    let posYY = 105;
    for (var i = 0; i < contas_bancarias.length; i++) {
      if (i !== 0 && i % 3 === 0) {
        posX = 12;
        posYY += 4;
      }
      doc.text('' + contas_bancarias[i] ? contas_bancarias[i].abreviatura : '', posX, posY + posYY);
      doc.text('AO06 ' + contas_bancarias[i] ? contas_bancarias[i].iban : '', posX + 7, posY + posYY);
      posX += 60;
    }





    doc.text('Sempre que efectuar uma transferência ou depósito, deve indicar o número das facturas a liquidar.', 12, posY + 115.7);
    doc.setFontType("bold");
    doc.text('Lojas EPAS:', 10, posY + 128);


    doc.setFontType("normal");
    for (let loja of lojas) {
      doc.text('' + loja.nome + ', ' + loja.endereco + ', ' + (loja.horario ? loja.horario : ''), 11, posY + 132);
      posY += 3;
    }


    var dataActual = moment(new Date()).format("DD/MM/YYYY");

    doc.text(dataActual + ' ' + moment().format("H:m") + '/ Versão: ' + original, 11, 285);
    /* doc.text(moment(factura.datahora_emissao).format("DD/MM/YYYY")+' '+moment(factura.datahora_emissao).format("H:m")+'/ Versão: '+original, 11, 285); */
    doc.text('Página: ' + numberPage, 184, 285);

    doc.setFontSize(8);
    doc.setDrawColor(0);
    doc.setFillColor(0);

    doc.line(10, doc.internal.pageSize.height - 9, 194, doc.internal.pageSize.height - 9); // vertical line
    var hash = factura.hash.substring(0, 1);
    hash += factura.hash.substring(10, 11);
    hash += factura.hash.substring(20, 21);
    hash += factura.hash.substring(30, 31);
    doc.setFontSize(6);

    let telefone = user.empresa.telefone ? `/ ${user.empresa.telefone}` : ''
    let email = user.empresa.email ? `/ ${user.empresa.email}` : ''
    doc.text("NIF: " + user ? user.empresa ? user.empresa.taxRegistrationNumber : '' : '' + " - " + user ? user.empresa ? user.empresa.companyName : '' : '' + " / " + user ? user.empresa ? user.empresa.addressDetail : '' : '' + (user ? user.empresa ? user.empresa.telefone : '' : '' == null ? '' : user ? user.empresa ? user.empresa.telefone : '' : '') + (user ? user.empresa ? user.empresa.email : '' : '' == null ? '' : user ? user.empresa ? user.empresa.email : '' : ''), 105, doc.internal.pageSize.height - 6, null, null, 'center');

    doc.setFontSize(8);
    doc.text(hash + '-Processado por programa validado nº 4/AGT119', 105, doc.internal.pageSize.height - 3, null, null, 'center');


    if (report == 'imprimir') {
      doc.autoPrint();
      window.open(doc.output("bloburl")); //opens the data uri in new window
    } else {
      console.log('report', report)
      doc.save(factura ? factura.factura_sigla : '' + '.pdf'); /* download the file immediately on loading */
    }

  }

  public numberFormat(number) {
    return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2 }).format(number || 0).replace('€', '').trim();
  }
}
