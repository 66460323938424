import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { ConfigModuloService } from 'src/app/services/config-modulo.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { RotaService } from '../rotas.service';
import * as moment from 'moment';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import { FullCalendarComponent } from '@fullcalendar/angular';
import timeGridPlugin from '@fullcalendar/timegrid';
//import esLocale from '@fullcalendar/core/locales/es';
import ptLocale from '@fullcalendar/core/locales/pt-br';
import { finalize } from 'rxjs/operators';
import { ApiService } from 'src/app/providers/http/api.service';
@Component({
  selector: 'app-listar-agendamento',
  templateUrl: './listar-agendamento.component.html',
  styleUrls: ['./listar-agendamento.component.css']
})
export class ListarAgendamentoComponent implements OnInit, OnDestroy {

  public currentUser: any;

  private title: string = "Registar Rota"
  private rota_header = {
    id: null,
    data_inicio: null,
    data_fim: null,
    estado_id: null,
    leitor_id: null,
    user_nome: null
  }

  private estado = [{ id: 0, nome: "Inactivo" }, { id: 1, nome: "Activo" }];

  private rotarun = {
    id: null,
    nome_cliente: null,
    rota_header_id: null,
    conta_id: null,
    servico_id: null,
    id_servico: null,
    provincia_id: null,
    municipio_id: null,
    bairro_id: null,
    has_distrito: null,
  }

  private lista = {
    id: null
  }

  private residencia = {
    id: null,
    numero: null,
    nome: null,
    predio_nome: null,
    is_predio: null,
    rua_id: null,
    rua: null,
    bairro_id: null,
    bairro: null,
    has_distrito: false,
    municipio_id: null,
    distrito_id: null,
    provincia_id: null,
    is_active: null,
    user_id: null,
  };


  private rotas: any[];

  private corridasLeiturasArray = [];
  private leituraToProcess: boolean = true;

  private local_instalacaosArray = [];

  private local_instalacaoRow = {
    id: null,
    moradia_numero: null,
    is_predio: null,
    //predio_id: null,
    predio_nome: null,
    rota_header_id: null,
    local_consumo_id: null,

    rua_id: null,
    rua: null,
    bairro_id: null,
    bairro: null,
    has_distrito: null,
    distrito_id: null,
    distrito: null,
    municipio_id: null,
    municipio: null,
    provincia_id: null,
    provincia: null,
    latitude: null,
    longitude: null,
    user_id: null,
    user: null

  };

  private predio_flag: boolean = false;
  private local_instalacaoRowValid: boolean = false;
  private addRows: boolean = true;


  private provincias: any = [];
  private items: any = [];
  private distritos: any[];
  private bairros: any[];
  private residencias: any[];
  private municipios: any[];
  private ruas: any[];
  private periodo_inicio: any = [];
  private periodo_fim: any = [];

  public total_agendaments_activo = 0;
  public total_agendamentos_inactivo = 0;;
  public filter = {
    municipio_id: null
  }
  roteiro: any = {}
  motivos_nao_leitura_list: any = []

  options: any

  @ViewChild('fullcalendar') fullcalendar: FullCalendarComponent;
  @ViewChild('external') external: ElementRef;

  constructor(
    private auth: AuthService,
    private http: HttpService,
    private configService: ConfigService,
    private _rotaService: RotaService,
    private config: ConfigModuloService,
    private api: ApiService,
  ) {
    this.currentUser = this.auth.currentUserValue;
    this.getPageFilterData(1);
    this.dashboardAgendamento();
    this.getRotas();
  }
  public loadings = {
    municipios: false
  }
  ngOnInit() {
    this.getMunicipios()
  }

  ngOnDestroy() {
    this.http.filters.orderBy = null
  }

  private getMunicipios() {
    this.distritos = [];
    this.loadings.municipios = true;
    this.api.get('municipio/selectBox')
      .pipe(finalize(() => { this.loadings.municipios = false; }))
      .subscribe(response => { this.municipios = Object(response).data; }, error => { this.loadings.municipios = false; });
  }
  _closeModal() {
    const modal = document.querySelector('.modal-backdrop')
    modal.classList.remove('modal-backdrop', 'fade', 'show')
  }

  private listaAgendamentos() {
    this.http.filters.filter = this.filter
    this.configService.loaddinStarter('start');

    this.http.__call('agendamento-listagem', this.http.filters).subscribe(

      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        let calendarEvents = Object(response).data.data.map((item) => {
          return { title: item.rota_header, start: item.data_inicio, backgroundColor: "#BCE4FD", borderColor: "#26A8FB" };
          //return { title: item.rota_header, start: item.data_inicio, end: item.data_fim, backgroundColor: "#BCE4FD", borderColor: "#26A8FB" };
        });

        //this._map_events(calendarEvents)
        this.configService.loaddinStarter('stop');

      }
    );
  }

  //full calendar
  _map_events(events) {

    this.options = {
      editable: true,
      header: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay'
      },
      events: events,
      buttonText: {
        today: 'Hoje',
        month: 'Mês',
        week: 'Semana',
        day: 'Dia'
      },
      locales: [ptLocale],
      locale: 'pt',
      views: {
        agenda: {
          eventLimit: 2
        }
      },
      plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin]
    }
  }

  private dashboardAgendamento() {
    this.http.call_get('dashboad-agendamento', null).subscribe(res => {

      this.total_agendaments_activo = Object(res).data.total_agendamentos_activo;
      this.total_agendamentos_inactivo = Object(res).data.total_agendamentos_inactivo;
    })
  }


  private updateAgendamento(e) {

    this.configService.loaddinStarter('start');
    e.preventDefault();

    if (this.rota_header.data_inicio == null) {
      this.configService.showAlert("O campo data início é obrigatório", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    }
    else if (this.rota_header.data_fim == null) {
      this.configService.showAlert("O campo data fim é obrigatório", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    }
    else {
      this.http.__call('agendamento-update/' + this.rota_header.id, this.rota_header).subscribe(
        res => {
          if (Object(res).code == 201) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
            this.clearFormInputs();
            this.getPageFilterData(1);
            this.configService.loaddinStarter('stop');
          } else {
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.clearFormInputs();
            this.getPageFilterData(1);
            this.configService.loaddinStarter('stop')
            this._closeModalAction("closeModalRegistarRota")
          }
        })
    }
  }

  private clearFormInputs() {
    this.rota_header.id = null,
      this.rota_header.data_inicio = null,
      this.rota_header.data_fim = null
  }

  private contas: any = [];

  private listarContasClientes(id) {
    this.carregando.conta = 'Carregando...';
    this.http.call_get('cliente/conta/listar/' + id, null).subscribe(
      response => {
        this.contas = Object(response).data;
        this.carregando.conta = 'Seleccione a Conta';
      }
    );
  }

  private servicos: any = [];

  private listarServicos() {

    const id = this.rotarun.conta_id

    this.carregando.servico = 'Carregando...';
    this.http.call_get('tarifario/servico/selectBoxServicosConta/' + id, null).subscribe(
      response => {
        this.servicos = Object(response).data;
        this.carregando.servico = 'Seleccione o serviço';
      }
    );
  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
    this.listaAgendamentos();
    this._resetFilters();
  }

  _resetFilters() {
    this.http.filters.data1 = null
    this.http.filters.data2 = null;
  }


  private carregando = {
    filial: 'Seleccione a filial',
    conta: 'Seleccione a conta',
    servico: 'Seleccione o serviço',
    periodo: 'Seleccione o Fim'
  }

  private filiais: any = [];

  private selectBox() {
    this.listarFilial();
  }

  private listarFilial() {
    this.carregando.filial = 'Carregando...';
    this.http.call_get('filial/selectBox', null).subscribe(
      response => {
        this.filiais = Object(response).data;
        this.carregando.filial = 'Seleccione a Filial';
      }
    );
  }

  _initModalCriarRoteiro(rota_header) {
    this.roteiro = rota_header
  }

  private setDataRotaRun(item: any) {

  }


  getIitem(item) {
    this.title = item.rota_header;
    this.rota_header.id = item.id;
    this.rota_header.data_inicio = moment(item.data_inicio).format("YYYY-MM-DD");
    this.rota_header.data_fim = moment(item.data_fim).format("YYYY-MM-DD");
    this.rota_header.estado_id = item.status;
    this.rota_header.user_nome = item.leitor;
    this.rota_header.leitor_id = item.leitor_id;
  }

  public getRotas() {
    this.http.__call('get/rotas', null)
      .subscribe(res => {
        this.rotas = Object(res).data;
      })

  }

  view_user = true;
  users: any = [];
  leitors: any = [];

  private getUtilizador() {

    if (this.rota_header.user_nome == "") {
      this.setNullUser();
    }
    this.view_user = true;
    this.http.__call('search-utilizador', { start: 1, end: 10, search: this.rota_header.user_nome })
      .subscribe(
        response => {
          this.users = Object(response).data.data;
          this.configService.loaddinStarter('stop');
        }
      );
  }

  private setUtilizador(id: any, nome: any) {
    this.rota_header.leitor_id = id;
    this.rota_header.user_nome = nome;
    this.view_user = false;
  }

  private setNullUser() {
    this.rota_header.leitor_id = null;
    this.rota_header.user_nome = null;
    this.view_user = false;
  }


  _closeModalAction(closeModal) {
    var action = document.getElementById(closeModal)
    action.click();
  }

}
