import { Component, OnInit, Input, Output } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { TipoClienteService } from '../../config-crm/tipo-de-cliente/tipo-de-cliente.service';
import { ContratoService } from '../../contrato/contrato.service';

@Component({
    selector: 'app-create-or-edit-tarifario',
    templateUrl: './create-or-edit-tarifario.component.html',
})

export class CreateOrEditConfigTarifarioComponent implements OnInit {
    @Input() tarifario: any;
    tipos_clientes: any = []
    contratos: any = []
    private centro_custo: any = [];

    constructor(
        private http: HttpService,
        private configService: ConfigService,
        private _tipoClienteService: TipoClienteService,
        private _contratoService: ContratoService
    ) {
        this.loadingMethods()
    }

    ngOnInit() { }

    _submitForm() {
        this.configService.loaddinStarter('start')

        if (Boolean(this.tarifario.descricao)) {
            if (this.tarifario.id) {
                this._update()
            } else {
                this._add()
            }
        } else {
            this.configService.showAlert("Os campos Categoria tarifária e Regra de Aplicação são obrigatórios", 'alert-danger', true);
        }
        this.configService.loaddinStarter('stop')

    }

    _add() {
        this.http.__call('tarifarios/create', this.tarifario)
            .subscribe(res => {
                if (Object(res).code == 500) {
                    this.configService.showAlert(Object(res).message, 'alert-danger', true);
                } else {
                    this.configService.showAlert(Object(res).message, 'alert-success', true);
                    this._cleanForm();
                }
            })
    }

    _update() {
        this.http.__call(`tarifarios/update/${this.tarifario.id}`, this.tarifario)
            .subscribe(res => {
                if (Object(res).code == 500) {
                    this.configService.showAlert(Object(res).message, 'alert-danger', true);
                } else {
                    this.configService.showAlert(Object(res).message, 'alert-success', true);
                }
            })
    }

    _cleanForm() {
        this.tarifario = {
            id: null,
            descricao: null,
            centro_custo_id: null,
            tarifario_variavel: null,
            tarifa_fixa_mensal: null,
            regra_aplicacao: null
        }
    }

    private getCentroCusto() {
        return this.http.call_get("centro-custo/getAll", null)
    }

    loadingMethods() {
        this.getCentroCusto().subscribe((response) => {
            this.centro_custo = Object(response);
        });

        this._tipoClienteService.getTipoCliientes()
            .subscribe((response) => {
                this.tipos_clientes = response.data.data
                console.log(this.tipos_clientes)
            })

        this._contratoService.getContratos()
            .subscribe((response) => {
                this.contratos = response.data
            })
    }
}
