import { Injectable } from '@angular/core';
import 'jspdf-autotable';
import * as moment from 'moment';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import { ConfigService } from 'src/app/providers/config/config.service';

@Injectable({
  providedIn: 'root'
})
export class ReportResumoContaCorrenteService {

  constructor(private configService: ConfigService) { }

  public relatorioContaCorrente(p = 'print', file,imgData,value) {
    var today = moment().format("DD-MM-YYYY H:mm:ss");
    var doc = new jsPDF('l', '', 'a3')
    doc.setProperties({
      title: 'Cobrança Detalhada'+today,
      subject: 'Report',
      author: 'Unig',
      keywords: '',
      creator: 'unig bewater'
   });

    doc.addImage(imgData, 'JPEG',10, 10, 28, 18)
    const totalPagesExp = "{total_pages_count_string}";

    doc.setFontSize(9)
        doc.text('Data início:', 367, 20)
        doc.text('Data fim:', 367, 25)
/*  */
        doc.setFontType('normal')
        doc.text('' + (value.data1), 385, 20);
        doc.text('' + (value.data2), 381, 25);

        doc.setFontType('bold')
        doc.setFontSize(15)
        doc.setFontSize(15)
        doc.text(210, 25, 'Cobrança Detalhada', 'center')

     doc.autoTable({ html: file ,
    didParseCell: function (data) {
    var rows = data.table.body;
    if (data.row.index === 1) {
      data.cell.styles.fontStyle = 'bold';
      data.cell.styles.textColor = "white";
      data.cell.styles.fillColor = [32, 95, 190];
    }
  },
    addPageContent: data => {
      let footerStr = "Página " + doc.internal.getNumberOfPages();
      if (typeof doc.putTotalPages === 'function') {
        footerStr = footerStr + " de " + totalPagesExp;
      }
      doc.setFontSize(10);
      doc.text(today,data.settings.margin.left, doc.internal.pageSize.height - 2)
      doc.text(footerStr, 385, doc.internal.pageSize.height - 2, 'left');
    },
    styles: { textColor: [0, 0, 0],margin: {left:0,right:0} },
    columnStyles: {
      0: {cellWidth: 25,halign: 'center'},
      1: {cellWidth: 25,halign: 'center'},
      2: {cellWidth: 20,halign: 'center'},
      3: {cellWidth: 20,halign: 'center'},
      4: {cellWidth: 23,halign: 'center'},
      5: {cellWidth: 23,halign: 'center'},
      6: {cellWidth: 23,halign: 'center'},
      7: {cellWidth: 20,halign: 'center'},
      8: {cellWidth: 20,halign: 'center'},
      9: {cellWidth: 20,halign: 'center'},
      10: {cellWidth: 23,halign: 'center'},
      11: {cellWidth: 20,halign: 'center'},
      12: {cellWidth:20,halign: 'center'},
      13: {cellWidth:18,halign: 'center'},
      14: {cellWidth:18,halign: 'center'},
      15: {cellWidth:20,halign: 'center'},
      16: {cellWidth:20,halign: 'center'},
      17: {cellWidth:20,halign: 'center'},
      18: {cellWidth:20,halign: 'center'},
     
  },
  startY: 40,
  theme: 'grid',

  })

  if (typeof doc.putTotalPages === 'function') {
    doc.putTotalPages(totalPagesExp);
  }

     if (p === 'save') {
       doc.save('cobranca_detalhada'+'.pdf');
     } else {
       doc.autoPrint();
       doc.output("dataurlnewwindow");
     }


   }

  public relatorioFomaPgamento(p = 'print', file,imgData,value) {
    var today = moment().format("DD-MM-YYYY H:mm:ss");
    var doc = new jsPDF('l', '', 'a3')
    doc.setProperties({
      title: 'Forma-Pagamento'+today,
      subject: 'Report',
      author: 'Unig',
      keywords: '',
      creator: 'unig bewater'
   });

    doc.addImage(imgData, 'JPEG',10, 10, 28, 18)
    const totalPagesExp = "{total_pages_count_string}";

    doc.setFontSize(9)
        doc.text('Data início:', 367, 20)
        doc.text('Data fim:', 367, 25)
/*  */
        doc.setFontType('normal')
        doc.text('' + (value.data1), 385, 20);
        doc.text('' + (value.data2), 385, 25);

        doc.setFontType('bold')
        doc.setFontSize(15)
        doc.setFontSize(15)
        doc.text(138, 27, 'Forma Pagamento', 'center')

     doc.autoTable({ html: file ,
    didParseCell: function (data) {
    var rows = data.table.body;
    if (data.row.index === 0) {
      data.cell.styles.fontStyle = 'bold';
      data.cell.styles.textColor = "white";
      data.cell.styles.fillColor = [32, 95, 190];
    }
  },
    addPageContent: data => {
      let footerStr = "Página " + doc.internal.getNumberOfPages();
      if (typeof doc.putTotalPages === 'function') {
        footerStr = footerStr + " de " + totalPagesExp;
      }
      doc.setFontSize(10);
      doc.text(today,data.settings.margin.left, doc.internal.pageSize.height - 2)
      doc.text(footerStr, 385, doc.internal.pageSize.height - 2, 'left');
    },
    styles: { textColor: [0, 0, 0],margin: {left:0,right:0} },
    columnStyles: {
      0: {cellWidth: 20,halign: 'center'},
      1: {cellWidth: 25,halign: 'center'},
      2: {cellWidth: 25,halign: 'center'},
      3: {cellWidth: 20,halign: 'center'},
      4: {cellWidth: 20,halign: 'center'},
      5: {cellWidth: 20,halign: 'center'},
      6: {cellWidth: 23,halign: 'center'},
      7: {cellWidth: 20,halign: 'center'},
      8: {cellWidth: 20,halign: 'center'},
      9: {cellWidth: 20,halign: 'center'},
      10: {cellWidth: 25,halign: 'center'},
      11: {cellWidth: 25,halign: 'center'},
      12: {cellWidth: 25,halign: 'center'},
      13: {cellWidth: 25,halign: 'center'},
      14: {cellWidth: 25,halign: 'center'},
  },
  startY: 40,
  theme: 'grid',

  })

  if (typeof doc.putTotalPages === 'function') {
    doc.putTotalPages(totalPagesExp);
  }

     if (p === 'save') {
       doc.save('forma_pagamento'+'.pdf');
     } else {
       doc.autoPrint();
       doc.output("dataurlnewwindow");
     }


   }
}
