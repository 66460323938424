import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
 class FilterCaixaService {
   
    public filter={
      data1: null,
      data2: null,
      operador: null,
      caixa: null,
      loja: null,
      status: null,
      loja_nome: null,
      caixa_estado:null
    }
  constructor() { }
}

export { FilterCaixaService }
