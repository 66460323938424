import { Component, OnInit, Input, createPlatformFactory } from "@angular/core";
import { HttpService } from "src/app/providers/http/http.service";
import { AuthService } from "src/app/providers/auth/auth.service";
import { ConfigService } from "src/app/providers/config/config.service";
import { ExcelService } from "src/app/services/excel.service";
import * as moment from "moment";
import { FormGroup } from "@angular/forms";

import { Router } from "@angular/router";

@Component({
  selector: "app-local-consumo",
  templateUrl: "./local-consumo.component.html",
  styleUrls: ["./local-consumo.component.css"],
})
export class LocalConsumoComponent implements OnInit {
  public currentUser: any;
  private loading = false;
  private view_rotas: boolean
  private local_consumo = {
    id: null,
    predio_nome: null,
    is_active: null,
    moradia_numero: null,

    has_distrito: null,
    rua_id: null,
    bairro_id: null,
    distrito_id: null,
    municipio_id: null,
    provincia_id: 29,
    user_id: null,
    contrato_id: null
    //local_consumoModal: false
  };
  public filters = {
    search: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    direction: null,
    municipio_id: null,
    bairro_id: null,
    quarteiraoId: null,
    cliente: null,
    contrato: null,
    alocacao: null,
    distrito_id: null,
    motivo_id: null,
    rota_header_id: null,
    leitor_id: null,
    nao_leitura: null,
    pagination: {
      perPage: 5, // Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima,
      lastPage: null,
      total: null, // defini o total de registo da BD
    },
    filter: null, // subelemente do filter
  };
  private filter = {
    municipio: null,
    distrito: null,
    bairro: null,
    cliente: null,
    contrato: null,
    alocacao: null,
    contador_id: 'TODOS',
    contrato_id: 'TODOS',
    quarteirao_id: null,
    rua_id: null
  };

  private contador = {
    id: null,
    numero_serie: null,
    marca: null,
    ultima_leitura: null,
    modelo: null,

    tipo_contador: null,
    classe_precisao: null,
    medicao: null,
    calibre: null,
    digitos: null,
    fabricante: null,
    centro_distribuicao: null,
    contador_actual_id: null,
    observacao: null,
  };

  private contadores: any[];
  private historicoContador: any[];

  @Input() ruas: any[];
  @Input() bairros: any[];
  @Input() distritos: any[];
  @Input() municipios: any = [];
  public quarteiraos = []
  @Input() log_local_consumos: any = [];

  private detalheProps: any;

  private local_consumo_historico: any;

  private view_serie_contador: boolean = false;
  private contador_join: boolean = true;

  private contador_title: string = "Associar";

  private addRows: boolean = true;
  private title: string = null;

  private items: any = [];
  checkedAll: boolean = true;
  private local_consumos: any = [];
  rotas:any[] = []
  clientes: any[];
  contratos: any[];
  alocacaos: any[];
  _bairros: any[] = [];
  _distritos: any[];
  _municipios: any = [];
  private _empty = "-------";
  private current_rota = {
    rota_header_id: null,
    descricao: null,
    local_consumo_id: null
  };
  constructor(
    private auth: AuthService,
    private http: HttpService,
    private _route: Router,
    private configService: ConfigService,
    private excelService: ExcelService
  ) {
    this.currentUser = this.auth.currentUserValue;
  }

  exportAsXLSX(): void {
    var CurrentDate = new Date();
    var nameFile =
      "lista_local_consumos -" +
      moment(CurrentDate).format("DD") +
      "-" +
      moment(CurrentDate).format("MM") +
      "-" +
      moment(CurrentDate).format("YYYY") +
      " " +
      moment(CurrentDate).format("H") +
      ":" +
      moment(CurrentDate).format("m");
    this.excelService.exportAsExcelFile(
      document.getElementsByClassName("exportAsXLSXCliente")[0],
      nameFile
    );
  }

  exportAsPDF(): void {
    //this.reportLoja.relatorioLoja(this.cobrancas, this.simpleForm.value, 'save');
  }

  imprimirPDF(): void {
    //this.reportLoja.relatorioLoja(this.cobrancas, this.simpleForm.value);
  }

  ngOnInit() {
    this.filters.search = null;
    this.getPageFilterData(1);
    this.getMunicipio();
  }

  private getDadosModal() { }

  searchRotas() {
    this.view_rotas = true;
    if(this.current_rota.descricao){
      this.getRotas(this.current_rota.descricao)
      return
    }
    this.rotas = []
    this.view_rotas = false;
    this.current_rota.rota_header_id = null

  }

  public getRotas(search) {
    this.http.__call('get/rotas', { search: search })
      .subscribe(res => {
        this.rotas = Object(res).data;
      })
  }
  set_view_rotas(item: any) {
    this.current_rota.rota_header_id = item.id
    this.current_rota.descricao = item.descricao
    this.view_rotas = false;
  }
  public getContratos(id) {
    this.configService.loaddinStarter("start");
    this.http.call_get("contas/contratos/" + id, null).subscribe((response) => {
      this.contratos = Object(response).data;
      this.configService.loaddinStarter("stop");
    });
  }

  public getAlocacao(item) {
    this.http
      .call_get("alocacao/contratos/" + item, null)
      .subscribe((response) => {
        this.alocacaos = Object(response).data;
      });
  }

  private getLocalConsumos() {
    this.configService.loaddinStarter("start");
    this.filters.filter = this.filter;
    this.filters.rota_header_id = this.current_rota.rota_header_id
    console.log(this.filters);
    this.http
      .__call("local-consumo/listagem", this.filters)
      .subscribe((response) => {
        this.filters.pagination.lastPage = Object(response).data.lastPage;
        this.filters.pagination.page = Object(response).data.page;
        this.filters.pagination.total = Object(response).data.total;
        this.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.items.forEach((element) => {
          Object.assign(element, { checked: false });
        });
        this.configService.loaddinStarter("stop");
      });
  }

  private get localConsumoChecked(): any[] {
    return this.items.filter(val => val.checked === true);
  }

  setCheckedAll(checked: boolean) {
    this.checkedAll = checked;
    this.items.forEach(f => f.checked = checked);
  }

  updateChecked(f: any) {
    this.items.forEach(element => {
      element.checked = element.id === f.id ? !f.checked : element.checked;
      Object.assign(element);
    });
    const lengthFalse = this.items.filter(val => val.checked === true);
    this.checkedAll = lengthFalse.length === this.items.length ? true : false;
  }

  private async criarOrdemServico() {

    if (this.items === 0) {
      this.configService.showAlert("Nenhuma linha seleccionada", "alert-error", true);
      return;
    }
    if (this.localConsumoChecked.length == 1) {
      this.configService.showAlert("Seleccione no minimo duas ou mais linhas", "alert-error", true);
      return;
    }

    this.configService.showAlert("A processar...", "alert-info", true);

    const incumprimentos = this.localConsumoChecked;
    //console.log(incumprimentos)
  }

  private initLocalConsumo() {
    this.local_consumo.id = null;
    this.local_consumo.predio_nome = null;
    this.local_consumo.rua_id = null;

    this.local_consumo.bairro_id = null;
    this.local_consumo.has_distrito = null;
    this.local_consumo.distrito_id = null;
    this.local_consumo.municipio_id = null;
    this.local_consumo.provincia_id = null;
    this.local_consumo.is_active = null;
    this.local_consumo.user_id = null;

    this.distritos = null;
    this.municipios = null;
    this.bairros = null;
    this.ruas = null;

    this.addRows = true;
  }

  private setDataLocalConsumo(item) {
    if (item !== undefined) {
      this.title = "Editar LocalConsumo";

      this.local_consumo.id = item.id;
      this.local_consumo.predio_nome = item.predio_nome;
      this.local_consumo.rua_id = item.rua_id;
      this.local_consumo.moradia_numero = item.moradia_numero;

      this.local_consumo.bairro_id = item.bairro_id;
      this.local_consumo.has_distrito = item.distrito_id > 0 ? true : false;
      this.local_consumo.distrito_id = item.distrito_id;
      this.local_consumo.municipio_id = item.municipio_id;
      this.local_consumo.provincia_id = item.provincia_id;
      this.local_consumo.is_active = item.is_active;
      this.local_consumo.user_id = item.user_id;
      this.local_consumo.contrato_id = item.contrato_id

      if (this.local_consumo.has_distrito)
        this.selectBoxDistritosByMunicipio(item.municipio_id);

      this.selectBoxMunicipiosByProvincia(item.provincia_id);
      this.selectBoxBairrosByMunicipio(item.municipio_id);
      this.selectBoxRuasByBairro(item.bairro_id);

      this.addRows = false;

      //console.log(this.local_consumo);
    }
  }

  private updateStateLocalConsumo(item) {
    this.local_consumo.id = item.id;
    this.local_consumo.predio_nome = item.predio_nome;

    this.local_consumo.rua_id = item.rua_id;
    this.local_consumo.bairro_id = item.bairro_id;
    this.local_consumo.has_distrito = item.has_distrito;
    this.local_consumo.distrito_id = item.distrito_id;
    this.local_consumo.municipio_id = item.municipio_id;
    this.local_consumo.provincia_id = item.provincia_id;
    this.local_consumo.is_active = !item.is_active;
    this.local_consumo.user_id = item.user_id;

    //console.log(item);
    this.http
      .__call(
        "local-consumo/update/" + this.local_consumo.id,
        this.local_consumo
      )
      .subscribe((response) => {
        if (Object(response).code == 200) {
          var update =
            this.local_consumo.is_active == true ? "Activado" : "Desactivado";

          this.configService.showAlert(
            "Local de Consumo " +
            this.local_consumo.predio_nome +
            " foi " +
            update,
            "alert-success",
            true
          );
        }
      });

    for (let i = 0; i < this.items.length; ++i) {
      if (this.items[i].id == this.local_consumo.id) {
        this.items[i].is_active = this.local_consumo.is_active;
      }
    }
  }

  private getLogsByLocalConsumoId(id) {
    //console.log(id);

    this.http
      .call_get("log-local-consumo/getLogsByLocalConsumoId/" + id, null)
      .subscribe((response) => {
        console.log(Object(response));

        console.log(this.local_consumo);

        this.log_local_consumos = Object(response);
      });
  }

  private getDetalheHistorico(item) {
    //console.log(item);

    //this.local_consumo_historico = item;

    this.http
      .call_get("local-consumo-historico/getDetalheHistorico/", {
        current_id: item.current_id,
        updated_id: item.updated_id,
      })
      .subscribe((response) => {
        console.log(Object(response));

        this.local_consumo_historico = {
          operacao: item.operacao,
          detalhe: Object(response),
          alocacao: this.configService.getAlocacao(
            (item.cil != null ? "[" + item.cil + "] " : "[0000-000-000] ") +
            (item.is_predio
              ? "Prédio " +
              item.predio_nome +
              ", " +
              this.configService.decimalFormat(item.predio_andar) +
              "º Andar - Porta "
              : "Residência ") +
            this.configService.decimalFormat(item.moradia_numero)
          ),
          //detalheProps: this.getPropriedadeDetalhe(JSON.parse(item.historico), JSON.parse(item.actualizacao))

          //detalhe.push(this.configService.getDadosPropriedade(item, 'Alocação', null, null, this.configService.Prop);
        };
      });
  }

  private getMunicipio() {
    if (this.filter.municipio == null) {
      this.getLocalConsumos();
      this.filter.bairro = null;
      this.filter.distrito = null;
    }
    this._distritos = [];
    this.http.__call("municipio/listagems", null).subscribe((response) => {
      this._municipios = Object(response).data;
    });
  }
  private getDistrito(id) {
    this._bairros = [];
    this.http
      .call_get(`distrito/selectBoxByMunicipio/${id}`, null)
      .subscribe((response) => {
        this._distritos = Object(response);
        console.log(this.distritos);
      });
  }
  private getBairro(id) {
    this.http
      .call_get(`bairro/selectBoxByDistrito/${id}`, null)
      .subscribe((response) => {
        this._bairros = Object(response);
        console.log(this._bairros);
      });
  }

  private selectBoxRuasByBairro(id) {
    //console.log(this.local_consumo);

    this.http
      .call_get("rua/getRuasByBairro/" + id, null)
      .subscribe((response) => {
        //console.log(Object(response).data);

        this.ruas = Object(response).data;
      });
  }

  private selectBoxRuasByQuarteirao() {
    this.http.call_get('rua/getRuasByQuarteirao/' + this.filter.quarteirao_id, null).subscribe(
        response => {
            this.ruas = Object(response).data;
        }
    );
}

  private selectBoxQuarteiraosByBairro() {
    this.http.call_get('quarteirao/getQuarteiraosByBairro/' + this.filter.bairro, null).subscribe(
         response => {
             this.quarteiraos = Object(response).data;
         }
     );
  }

  private selectBoxDistritosByMunicipio(id) {
    //console.log(this.local_consumo);

    this.http
      .call_get("distrito/getDistritosByMunicipio/" + id, null)
      .subscribe((response) => {
        console.log(Object(response).data);

        this.distritos = Object(response).data;
      });
  }

  private selectBoxBairrosByMunicipio(id) {
    //console.log(this.local_consumo);

    this.http
      .call_get("bairro/getBairrosByMunicipio/" + id, null)
      .subscribe((response) => {
        //console.log(Object(response).data);

        this.bairros = Object(response).data;
      });
  }

  private selectBoxMunicipiosByProvincia(id) {
    //console.log(this.local_consumo);

    this.http
      .call_get("municipio/getMunicipiosByProvincia/" + id, null)
      .subscribe((response) => {
        //console.log(Object(response).data);

        this.municipios = Object(response).data;
      });
  }

  getPageFilterData(page: number) {
    if (this.filters.pagination.perPage == null) {
      return;
    }
    this.filters.pagination.page = Number.isInteger(page) ? page : 1;
    this.getLocalConsumos();
  }

  private getLocalConsumosByLocalConsumo(id) {
    this.configService.loaddinStarter("start");

    this.http
      .call_get("local-consumo/getResidenciasByResidencia/" + id, this.filters)
      .subscribe((response) => {
        this.local_consumos = Object(response).data.data;
        this.configService.loaddinStarter("stop");
      });
  }

  public getEditContratoFromLocalConsumo(item) {
    const id = item.contrato_id;

    this._route.navigate([
      "/contratos/Update/" +
      id +
      "/" +
      item.conta_id +
      "/" +
      item.cliente_id +
      "/" +
      item.tarifario_id +
      "/" +
      item.classe_tarifario_id,
    ]);
  }

  public getContadorById(id) {
    this.view_serie_contador = false;

    this.contador_title = "Retirar";
    this.contador_join = false;

    this.http
      .call_get("contador/getContadorById/" + id, null)
      .subscribe((response) => {
        this.setContador(Object(response));

        console.log(Object(response));

        this.configService.loaddinStarter("stop");
      });

    //console.log(this.contadores);
  }

  public getContadoresDisponiveisByNumeroSerie(flag = true) {
    this.view_serie_contador = false;

    let aux_serie = this.contador.numero_serie;

    this.contador_title = "Associar";
    this.contador_join = true;

    this.http
      .__call("contador/getContadoresDisponiveisByNumeroSerie", {
        start: 1,
        end: 15,
        search: this.contador.numero_serie
      })
      .subscribe((response) => {
        this.contadores = Object(response).data.data;

        //console.log(Object(response));
        this.configService.loaddinStarter("stop");
      });

    this.cleanContador();
    this.contador.numero_serie = aux_serie;

    //console.log(this.contadores);
  }

  public cleanContador() {
    //console.log(item);

    this.contador = {
      id: null,
      numero_serie: null,
      marca: null,
      ultima_leitura: null,
      modelo: null,

      tipo_contador: null,
      classe_precisao: null,
      medicao: null,
      calibre: null,
      digitos: null,
      fabricante: null,
      centro_distribuicao: null,
      contador_actual_id: null,
      observacao: null,
    };

    this.view_serie_contador = false;
  }

  public saveContador() {
    this.http
      .__call(
        "local-consumo/updateLocalConsumoByContador/" + this.local_consumo.contrato_id,
        {
          local_consumo_id: this.local_consumo.id,
          associar_by_loc: true,
          contador_id: this.contador.id,
          contador_join: this.contador_join,
          user_id: this.currentUser.user.id,
        }
      )
      .subscribe((response) => {
        if (Object(response).code == 200) {
          this.getLocalConsumos();

          this.cleanContador();

          this.view_serie_contador = false;
        }

        this.getContadoresDisponiveisByNumeroSerie(false);
        this.configService.loaddinStarter("stop");
      });
  }


  public setContador(item: any) {
    console.log(item);

    this.contador = {
      id: item.id,
      numero_serie: item.numero_serie,
      marca: item.marca,
      ultima_leitura: item.ultima_leitura,
      modelo: item.modelo,

      tipo_contador: item.tipo_contador,
      classe_precisao: item.classe_precisao,
      medicao: item.medicao,
      calibre: item.calibre,
      digitos: item.digitos,
      fabricante: item.fabricante,
      centro_distribuicao: item.centro_distribuicao,
      contador_actual_id: item.id,
      observacao: null,
    };

    this.view_serie_contador = false;
  }

  alterarContador() {
    this.loading = true
    this.http.__call("alterar-contador/" + this.local_consumo.id, { contador_actual_id: this.contador.contador_actual_id })
      .subscribe((res) => {
        this.loading = false
        this.getLocalConsumos();
        this.cleanContador();
      })
  }

  get_historicoContador() {
    this.loading = true
    this.http.call_get("get-historicoContador/" + this.local_consumo.id)
      .subscribe((res) => {
        this.historicoContador = Object(res).data
        this.loading = false
      })
  }
}
