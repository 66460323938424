import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { UserResponse, UserData } from '../../models/userRresponse';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment as env } from "../../../environments/environment";
import { ToastrService } from "ngx-toastr";

import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
@Injectable({
  providedIn: "root",
})
export class AuthService {
  public currentToken = "_token";
  public refreshToken = "_refreshToken";
  readonly url = env.app_url + "auth";

  private currentUserSubject: BehaviorSubject<UserData>;
  public currentUser: Observable<UserData>;

  constructor(
    private http: HttpClient,
    private router: Router,
    private toastrService: ToastrService
  ) {
    this.currentUserSubject = new BehaviorSubject<UserData>(
      JSON.parse(localStorage.getItem(this.currentToken))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): UserData {
    return this.currentUserSubject.value;
  }

  public login(username, password) {
    return this.http
      .post<UserResponse>(environment.app_url + "user/authenticate", {
        username,
        password,
      })
      .pipe(
        map((res) => {
          if (res.data.user && res.data.token.token) {
            this.setItemLocalStorage(res.data);
            localStorage.setItem("sessionToken", res.data.token.token);
            this.currentUserSubject.next(res.data);
          }
        })
      );
  }

  public getAppEnvironment() {
    let result = null;
    var url = require("url");

    //Parse the address:
    var app_url = url.parse(environment.app_url, true);

    /*The parse method returns an object containing url properties*/
    //console.log(app_url.host);

    this.http
      .post<String>("configuracao/getConfiguracaobySlug/" + app_url.host, null)
      .pipe(
        map((response) => {
          //console.log(Object(response));
          if (Object(response).code == 200) {
            result = Object(response).data.valor;
            //console.log("this.app_environment " + result)
          }
        })
      );

    return result;
  }

  public logoutUser() {
    // remove user from local storage to log user out
    localStorage.clear();
    this.currentUserSubject.next(null);
    this.router.navigate(["/login"]);
  }
  public logout() {
    // remove user from local storage to log user out
    localStorage.clear();
    this.currentUserSubject.next(null);
    this.router.navigate(["/login"]);
  }

  public setItemLocalStorage(d) {
    localStorage.setItem("screen", "0");
    localStorage.setItem(this.currentToken, JSON.stringify(d));
  }

  canActivateRouterLink(permission: string): boolean {
    const currentUser = this.currentUserValue;
    if (currentUser.permissions.includes(permission)) {
      return true;
    } else {
      return false;
    }
  }

  isLogged():boolean {
    return localStorage.getItem("currentUser") ? true : false;
  }
  updatePermissionsCurrentUser() {
    const currentUser = this.currentUserValue;
  }

  getAuthStatus() {
    return localStorage.getItem("currentUser") ? true : false;
  }

  get user(): any {
    return !this.isLogged
      ? null
      : JSON.parse(localStorage.getItem(this.currentToken)).user;
  }

  public regster(user, headers) {
    return this.http.post(environment.app_url + "user/register", user, headers);
  }

  swalRefreshToken() {
    var username: string = "";
    if (this.isLogged) {
      username = this.user.username;
      console.log(username);
    }
    localStorage.clear();
    //var formData: FormData = new FormData();
    const swal = Swal.mixin({
      customClass: {
        container: "container-class",
        popup: "popup-class",
        header: "header-class",
        title: "title-class",
        closeButton: "close-button-class",
        icon: "warning",
        content: "content-class",
        input: "input-class",
        actions: "actions-class",
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-default",
        footer: "footer-class",
      },
      buttonsStyling: false,
    });
    swal
      .fire({
        backdrop: true,
        title: "Sessão em Pausa",
        text: "Para Continuar nesta sessão, por favor digite a sua senha",

        input: "password",
        inputPlaceholder: "Digite a sua senha",
        inputAttributes: {
          autocapitalize: "off",
        },
        confirmButtonText: '<i class="fa fa-unlock"></i>&nbsp; Continuar',
        showLoaderOnConfirm: true,
        showCancelButton: true,
        cancelButtonText: '<i class="fa fa-lock"></i>&nbsp; Ignorar',
        preConfirm: (password) => {
          const refreshToken = localStorage.getItem(this.refreshToken);
          return fetch(environment.app_url + "user/authenticate", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              password: password,
              username: username,
            }),
          })
            .then((response) => {
              if (!response.ok) {
                throw new Error("Falha na Autenticação");
              }
              return response.json();
            })
            .catch((error) => {
              this.toastrService.error(
                "Nome de utilizador ou Password Inválido, ou consulta o administrador para verificar se a sua conta está activa",
                ""
              );
              Swal.showValidationMessage(`${error}`);
            });
        },
        allowOutsideClick: false, //() => !Swal.isLoading()
      })
      .then((result) => {
        if (result.value) {
          console.log(result.value.data);
          this.setItemLocalStorage(result.value.data);
          Swal.fire({
            title: `Bem-vindo de volta, ${result.value.data.user.nome}`,
          });
          return true;
        } else {
          this.logout();
          return false;
        }
      });
  }
}











