import { url } from '@rxweb/reactive-form-validators';
import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { ExcelService } from 'src/app/services/excel.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-ordem-equipamento',
  templateUrl: './ordem-equipamento.component.html',
  styleUrls: ['./ordem-equipamento.component.css']
})
export class OrdemEquipamentoComponent implements OnInit {



  public equipamentos: any;
  private criticidades: any;
  public anexos: any

  private loading: boolean = false;
  public filters = {
    nivel1: null,
    nivel2: null,
    nivel3: null,
    search1: null,
    search2: null,
    search3: null,
    search: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    viewBy: null, // Ordenação
    pagination: {
      perPage: 25,// Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima,
      lastPage: null,
      total: null // defini o total de registo da BD
    },
    filter: null // subelemente do filter
  }

  private colaborador = {
    id: null,
    descricao: null,

  }
  private urlCarregada: any

  constructor(private auth: AuthService, private http: HttpService, private configService: ConfigService,private excelService: ExcelService) { }

  private items: any = [];
  private funcoes: any = [];
  nivel1Lista: any = [];
  nivel2Lista: any = [];
  nivel3Lista: any = [];
  view_client1: boolean =false
  view_client2: boolean =false
  view_client3: boolean =false
  nivel_id: any

  ngOnInit() {
    this.getPageFilterData(1);
    this.getCriticidade()
  /*   this.ListagemNivel1()
    this.ListagemNivel2() */
  }
  ngDoCheck() {

    if(this.filters.search1 === null || this.filters.search1 === ''){
      this.view_client1 = false;
      this.filters.nivel1 = null
    }
    if(this.filters.search2 === null || this.filters.search2 === ''){
      this.view_client2 = false
      this.filters.nivel2 = null
    }
    if(this.filters.search3 === null || this.filters.search3 === ''){
      this.view_client3 = false
      this.filters.nivel3 = null
    }
  }

  private hendleNivel = {
    1 : (data: any[]) => {
          if(data)this.view_client1 = true;
          else this.view_client1 = false;
          this.nivel1Lista = []
          this.nivel1Lista = data
        },
    2 : (data: any[]) => {
      if(data)this.view_client2 = true;
      else this.view_client2 = false;
         this.nivel2Lista = []
         this.nivel2Lista = data
       },
    3 : (data: any[]) => {
        if(data)this.view_client3 = true;
        else this.view_client3 = false;
         this.nivel3Lista = []
         this.nivel3Lista = data
       }
  }

  private getNivel(url: string, nivel) {

     url = (nivel == 2 || nivel == 3) ? url + '/' + this.nivel_id : url
    this.http.__call(url, { start: 1, end: 10, search: this.filters }).subscribe(
      response => {
        this.hendleNivel[nivel](Object(response).data)
      })
  }
  private setNivel1(nivel: any,name: string, url: string) {
    if(this.filters.search1) {
      this.nivel_id = nivel
      this.filters.nivel1 = nivel
      this.filters.nivel2 = nivel
      this.filters.search2 = null
      //this.filters.search2 = name
      this.filters.search1 = name
      this.view_client1 = false;
      this.getNivel(url, 2)
    }else {
      this.nivel_id = null
      this.filters.nivel1 = null
      this.filters.nivel2 = null
      this.filters.search2 = null
      //this.filters.search2 = name
      this.filters.search1 = null
      this.view_client1 = false;
      this.getNivel(url, 2)
    }



  }
  private setNivel2(nivel: any,name: string, url: string) {
    this.nivel_id = nivel
    //this.filters.nivel3 = nivel
        this.filters.nivel2 = nivel
      this.filters.search2 = name
      this.filters.search3 = null
      this.getNivel(url, 3)
      this.view_client2 = false;

  }
  private setNivel3(nivel: any, name: string, url=null) {
      this.nivel_id = nivel
      this.filters.nivel3 = nivel
      this.filters.search3 = name
      this.view_client3 = false;
  }
  private listar() {

    //this.configService.loaddinStarter('start');

    this.loading = true
    this.http.__call('ordemservico/equipamento/listagem', this.filters).subscribe(
      response => {
        this.filters.pagination.lastPage = Object(response).data.lastPage;
        this.filters.pagination.page = Object(response).data.page;
        this.filters.pagination.total = Object(response).data.total;
        this.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.loading = false
      }
    );
  }

  private ListagemNivel1() {
    this.nivel1Lista = []
    this.http.__call('ordemservico/equipamento/ListagemNivel1', this.filters).subscribe(
      response => {
        this.nivel1Lista = Object(response)
      }
    );
  }
  private ListagemNivel2() {
    this.nivel2Lista = []
    this.http.__call('ordemservico/equipamento/ListagemNivel2/'+this.nivel_id, this.filters).subscribe(
      response => {
        this.nivel2Lista = Object(response)
      }
    );
  }
  private ListagemNivel3() {
    this.nivel3Lista = []
    this.http.__call('ordemservico/equipamento/ListagemNivel2/'+this.nivel_id, this.filters).subscribe(
      response => {
        this.nivel3Lista = Object(response)
      }
    );
  }
  private getCriticidade() {
    this.http.call_get('ordemservico/criticidade/selectBox', null).subscribe(
      response => {
        this.criticidades = Object(response).data;
      }
    );
  }


  getPageFilterData(page: number) {
    if (this.filters.pagination.perPage == null) {
      return;
    }
    this.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
    this.listar();
  }


  private setData(item) {
    this.equipamentos = item
  }

  private getAnexo(item) {
    this.setData(item)
    this.anexos = item.imagem
    this.previewFile(item.imagem)
  }

  blobToFile(theBlob: any, fileName: any){
      theBlob.lastModifiedDate = new Date();
      theBlob.name = fileName;
      return theBlob;
  }

  downloadFile() {
    const finalIdx = this.equipamentos.imagem.indexOf(";")
    const extName = this.equipamentos.imagem.substr(5, finalIdx - 5)
    console.log(this.equipamentos, 'extName = ',extName)
   //var jsonBlob = new Blob(this.equipamentos.imagem), { type: 'image/jpag' })
   //new Blob([JSON.stringify(this.equipamentos.imagem)], { type: extName })
   // return saveAs(this.equipamentos.imagem, this.equipamentos.id + extName)
   let fileName = this.equipamentos.id + extName
   let file: any = new File([this.equipamentos.imagem], fileName, {lastModified: 1534584790000});
   var link:any=window.URL.createObjectURL(file);
   // file.download = link
   return saveAs(file)
    window.location =link
  }
  previewFile(image: any) {
    this.urlCarregada = image
  }

}
