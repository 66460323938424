import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/providers/config/config.service';
import { HttpService } from 'src/app/providers/http/http.service';
import { ExcelService } from 'src/app/services/excel.service';
import { ExcelAutoService } from 'src/app/services/excel/excel-auto.service';
import * as moment from 'moment';
import { ReportAdiantamentosService } from 'src/app/components/report-at/relatorios/report-adiantamnetos.service';
import { ImportDataService } from 'src/app/services/ExportExcel/exportAllData.service';


@Component({
  selector: 'app-relatorio-numero-contratos-cobrados-recibos',
  templateUrl: './numero-contratos-cobrados-recibos.html',
})
export class NumeroContratosCobradosFactura implements OnInit {



  constructor(private http: HttpService,
    private configService: ConfigService,
    private excelService: ExcelService,
    private reportAdiantamento: ReportAdiantamentosService,
    private excelsService: ExcelAutoService,
    private getAllData: ImportDataService,
  ) {

  }
  loading: boolean = false;
  disableButton: boolean = true;

  private filter = {
    distrito: 'T',
    cliente_id: null
  }
  public totais={
     recibo:0,
     contrato:0,
     cliente:0
  }
  submitted = false;
  public filters = {
    search: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    pagination: {
      perPage: '5',// Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima,
      lastPage: null,
      total: null // defini o total de registo da BD
    },
    filter: null // subelemente do filter
    , is_allexel: false,
    data1: null,
    data2 : null,
    bairro: null,
    estado: null,
  }

  private localUrl: any;
  private largura: any;
  private altura: any;
  items: any = [];
  direccoes: any = [];
  gestores: any = [];
  generos: any = [];
  tipoclientes: any = [];
  municipios: any = [];

  bairros: any = [];
  distritos: any = []
  ruas: any = [];
  tipoidentidades: any = [];
  clientes: any = [];



  ngOnInit() {
    this.getPageFilterData(1),
      this.empresaUser(),
      this.getMunicipio()

      this.getDistritos()
  }

  getAllDatas() {
  
    this.filters.filter = this.filter
    let title = "Forma Pagamento"
      this.getAllData.getData('relatorio/numero-contratos-cobrados-numero-recibos',
      title,  
      {}, 
       this.filters,
      (data: any)=>this.exportAsXLSX(data))
  
   }

  exportAsPDF(): void {
    var file = document.getElementsByClassName("exportAsXLSXCliente")[0]
    this.reportAdiantamento.relatorioAdiantamentos('save', file, this.localUrl);
  }


  private empresaUser() {
    this.configService.loaddinStarter('start');

    this.http.call_get('empresa/empresa-user', null).subscribe(
      response => {
        this.localUrl = Object(response).data.logotipo
        this.altura = Object(response).data.width
        this.largura = Object(response).data.height
        this.configService.loaddinStarter('stop');
      }
    );
  }

  private getMunicipio() {
    this.http.__call('municipio/listagems', null).subscribe(
      response => {
        this.municipios = Object(response).data;
      }
    );
  }


  private getDistritos() {


    this.http.call_get('distrito/selectBox', this.http.filters).subscribe(

      response => {
    

        this.distritos = Object(response).data;


      }
    );
  }

  private getBairro(id) {

    this.http.call_get('bairro/getBairrosByDistrito/' + id, null).subscribe(
      response => {
        this.bairros = Object(response).data;
      }
    );

  }


  imprimirPDF(): void {
    var file = document.getElementsByClassName("exportAsXLSXCliente")[0];
    this.reportAdiantamento.relatorioAdiantamentos('print', file, this.localUrl);
  }


  setTrue() {
    this.filters.pagination.page = this.filters.pagination.page
    this.filters.is_allexel = true
    this.getTotalDeAdiantamentos()
  }

  getPageFilterData(page: number) {
    if (this.filters.pagination.perPage == null) {
      return;
    }
    console.log('page=>', page)
    this.filters.pagination.page = Number.isInteger(page) ? page : 1;
    this.getTotalDeAdiantamentos();
  }
  private somaTotais() {
    this.totais.contrato=0
    this.totais.cliente=0
    this.totais.recibo=0
   
    for(let value of this.items){
        this.totais.cliente+= value.n_clientes;
        this.totais.recibo+=value.n_recibos; 
        this.totais.contrato+= value.n_contratos;
     
    }
  }
  private getTotalDeAdiantamentos() {
    this.configService.loaddinStarter('start');
    this.loading = true;
    this.filters.search = this.filter.distrito;
    this.filters.data1 = this.filters.data1;
    this.filters.data2 = this.filters.data2;
    this.totais.cliente= 0;
    this.totais.contrato= 0;
    this.totais.recibo=0;
    this.items = []

    this.http.__call('relatorio/numero-contratos-cobrados-numero-recibos', this.filters).subscribe(
      response => {

        this.filters.pagination.lastPage = Object(response).data.lastPage;
        this.filters.pagination.page = Object(response).data.page;
        this.filters.pagination.total = Object(response).data.total;
        this.filters.pagination.perPage = Object(response).data.perPage;
        this.items = Object(response).data
        this.loading = false;
        this.somaTotais()
        if (this.items != 0) {
          this.disableButton = false;
        }

        this.loading = false;
      }
    );
    this.filters.is_allexel = false
    this.configService.loaddinStarter('stop');
  }

  exportAsXLSX(data): void {
    var CurrentDate = new Date();
    
    var keys = [
      { key: 'nome', width: 30 },
      { key: 'n_clientes', width: 30, style: { font: { name: 'Calibri' } } },
      { key: 'n_recibos', width: 30, style: { font: { name: 'Calibri' } } },
      { key: 'n_contratos', width: 30, style: { font: { name: 'Calibri' } } }
    ];

    var Cols = [
      'Distritos',
      'Nº de Clientes',
      'Nº de Recibos',
      'Nº de Contratos'
    ]

    const dados = (data || this.items).map((item) => {
      if (item.estado === 0) {
        return {
          ...item,
          estado: "Inválida"
        }
      }
      else {
        return {
          ...item,
          estado: "Válida"
        }

      }

    })

    var title = 'Relatório de Leituras Realizadas'
    var nameFile = "Relatório de Leituras Realizadas -" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    this.excelsService.excels(dados, nameFile, this.localUrl, keys, Cols, title, 5, 6, 30, 3)
  }

  view_client = false;
  private getCliente(search: any) {
    search = search.target.value;
    this.view_client = true;
    if (search == '') {
      this.view_client = false;
      this.filter.cliente_id = null;

    }
    this.http.__call('cliente/search-cliente', { start: 1, end: 10, search: search }).subscribe(
      response => {
        this.clientes = Object(response).data.data;
      }
    );
  }

  private setCliente(client: any) {
    this.view_client = false;
    this.filter.cliente_id = client.id,
      this.filter.distrito = client.nome
  }





}
