import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-edit-ordem-agendamento',
  templateUrl: './edit-ordem-agendamento.component.html',
  styleUrls: ['./edit-ordem-agendamento.component.css']
})
export class EditOrdemAgendamentoComponent implements OnInit {

  @ViewChild("search") search;

  private primeiros_niveis: any = [];
  private segundo_niveis: any = [];
  private terceiro_niveis: any = [];
  private departamentos: any = [];
  private duracoes: any = [];
  private funcoes: any = [];
  private artigos: any = [];
  private produtoCategorias: any = [];
  private recursos: any = [];
  private tipos: any = [];
  private classificacoes: any = [];
  private prioridades: any = [];
  private causas: any = [];
  private sintomas: any = [];
  private clientes: any = [];
  private local_consumo: any = [];

  private colaboradores: any = [];

  private contas: any = [];
  private contratos: any = [];

  private dayInicio: any;
  private hourInicio: any;
  private minutesInicio: any;
  private timeInicio: any;

  private isFinalizar: boolean = true;
  private isDisabled: boolean = false;
  private is_sintoma: boolean = false;
  private is_causa: boolean = false;
  private validateButton: any = null;
  private is_responsavel: boolean = false;
  private qtdPrevistaDecimal: boolean = true;
  private view_client = true;
  dropdownSettings = {};
  dropdownSettingsSintomas = {};

  routerParamId: any;
  routerParamAgendamentoId: any;

  private agendamentodayInicio: any;
  private agendamentohourInicio: any;
  private agendamentominutesInicio: any;
  private hourInicioAgendamento: any;
  private minutesInicioAgendamento: any;

  private causasArray: any = [];
  private sintomasArray: any = [];

  private associacao_cliente = null;

  private agendamento = {
    id: null,
    data_previsao_inicio: null,
    data_previsao_fim: null,
    dif_dias_prevista: null,
    dif_horas_prevista: null,
    dif_minutos_prevista: null,
    descricao: null,
    historico_agendamento_id: null,
    codigo_agendamento: null

  }

  private recursosHumanos = {
    departamento_id: null,
    qtd_colaborador: null,
    funcao_id: null,
    colaborador_id: null,
    colaborador_nome: null,
    qtdRh: null
  }

  private consumiveis = {
    armazem_id: null,
    artigo_id: null,
    qtdArtigo: null,
    qtd_stock: null,
    categoria_id: null,
    reserva: null,
    unidade: null,
    descricao_material: null,
    qtd_stock_reserva: null
  }

  private ordemServico = {
    descricao: null,
    descricao_old: null,
    cliente_nome: null,
    success_ordem_servico: false,
    sintoma_id: null,
    classificacao_id: null,
    classificacao_descricao: null,
    prioridade_id: null,
    prioridade_descricao: null,
    tipo_descricao: null,
    responsavel_nome: null,
    data_prevista: null,
    agendamentoGerado: null,
    codigo: null,
    titulo: null
  }


  private pagination = {
    start: 1,
    end: 10,
    search: null
  };

  currtentPage: number

  constructor(private auth: AuthService, private http: HttpService, private route: ActivatedRoute, private router: Router, private configService: ConfigService) {

  }

  ngOnInit() {
    this.getRouterParamId();

  }

  private carregando = {
    nivel_2: 'Seleccione o equipamento',
    funcao: 'Seleccione a função',
    material: 'Seleccione o material',
    categoria: 'Seleccione a categoria',
    tipo: 'Seleccione o tipo',
  }

  public getRouterParamId() {

    this.routerParamId = this.route.snapshot.params.id;
    this.routerParamAgendamentoId = this.route.snapshot.params.agendamento_id;

    //console.log(this.routerParamId)

    if (this.routerParamId != undefined) {

      this.getArmazens();
      this.getConsumiveisByAgendamento(this.routerParamAgendamentoId);
      this.getOrdemById(this.routerParamId);
      this.getAgendamentoById(this.routerParamAgendamentoId);

    }

  }

  private getOrdemById(id) {
    this.http.call_get('ordemservico/ordem-by-id/' + id, null).subscribe(
      response => {

        this.ordemServico.codigo = Object(response).data.codigo;
        this.ordemServico.titulo = Object(response).data.titulo;
        this.ordemServico.descricao_old = Object(response).data.descricao;
        this.ordemServico.data_prevista = Object(response).data.data_prevista;
        this.ordemServico.prioridade_descricao = Object(response).data.prioridade_descricao;
        this.ordemServico.responsavel_nome = Object(response).data.responsavel_nome;
        this.ordemServico.tipo_descricao = Object(response).data.tipo_descricao;
        this.ordemServico.classificacao_descricao = Object(response).data.classificacao_descricao;
      }
    );
  }

  private getConsumiveisByAgendamento(id) {
    this.http.call_get('ordemservico/consumiveis-by-agendamento/' + id, null).subscribe(
      response => {

        this.consumiveisSelecionados = Object(response).data;
      }
    );
  }

  private getColaboradoresByAgendamento(id) {
    this.http.call_get('ordemservico/colaboradores-by-agendamento/' + id, null).subscribe(
      response => {

        this.recursosSelecionados = Object(response).data;
      }
    );
  }

  private getAgendamentoById(id) {

    this.configService.loaddinStarter('start');
    this.http.call_get('ordemservico/agendamento-by-Ordem/' + id, null).subscribe(
      response => {

        this.agendamento.data_previsao_inicio = Object(response).data.res.data_previsao_inicio;
        this.agendamento.data_previsao_fim = Object(response).data.res.data_previsao_fim;
        this.agendamento.dif_dias_prevista = Object(response).data.res.dif_dias_prevista;
        this.agendamento.dif_horas_prevista = Object(response).data.res.dif_horas_prevista;
        this.agendamento.dif_minutos_prevista = Object(response).data.res.dif_minutos_prevista;
        this.agendamento.codigo_agendamento = Object(response).data.res.codigo_agendamento;
        this.agendamento.descricao = Object(response).data.historico ? Object(response).data.historico.historico_agendamento_descricao : null;
        this.agendamento.id = Object(response).data.res.id;
        this.agendamento.historico_agendamento_id = Object(response).data.historico ? Object(response).data.historico.os_historico_id : null;

        this.configService.loaddinStarter('stop');
      }
    );
  }


  private getDepartamento() {
    this.http.call_get('departamento/selectBox', null).subscribe(
      response => {
        this.departamentos = Object(response).data;
      }
    );

    this.getDuracao();
  }

  private getDuracao() {

    this.http.call_get('duracao/selectBox', null).subscribe(
      response => {
        this.duracoes = Object(response).data;
      }
    );
  }

  private getFuncaoByDepartamento() {
    this.carregando.funcao = 'Carregando...';
    this.http.call_get('funcao/funcaoesByDepartamento/' + this.recursosHumanos.departamento_id, null).subscribe(
      response => {
        this.funcoes = Object(response).data;
        this.carregando.funcao = 'Seleccione a função';
      }
    );
  }

  private getColaboradoresByFuncao() {

    this.colaboradores = [];

    if (this.recursosHumanos.funcao_id != null) {
      this.configService.loaddinStarter('start');
      this.http.__call('colaborador/colaboradoresByFuncao', {
        start: 1,
        end: 10,
        search: null,
        funcao_id: this.recursosHumanos.funcao_id
      }).subscribe(
        response => {
          this.colaboradores = Object(response).data.data;
          this.configService.loaddinStarter('stop');
        }
      );

    }
  }

  public armazens = [];

  public getArmazens() {

    this.http.__call("armazem/selectBox", null).subscribe((response) => {
      this.armazens = Object(response).data;

    });
  }


  private getCategoriaByArmazem() {

    this.consumiveis.qtd_stock = 0;
    this.consumiveis.categoria_id = null,
      this.produtoCategorias = []
    this.artigos = [];

    this.carregando.categoria = 'Carregando...';
    this.http
      .__call("categoria/by/armazem", {
        armazem_id: this.consumiveis.armazem_id
      }).subscribe((res: any) => {
        this.produtoCategorias = Object(res).data;
        this.carregando.categoria = 'Seleccione a categoria';
      });
  }

  view_material = false;

  private getMaterialByCategoriaArmazem() {

    this.consumiveis.qtd_stock = 0;
    this.consumiveis.artigo_id = null;
    this.artigos = [];

    this.carregando.material = 'Carregando...';
    this.http
      .__call("material/by/categoria-armazem", {
        armazem_id: this.consumiveis.armazem_id,
        categoria_id: this.consumiveis.categoria_id
      }).subscribe((res: any) => {
        this.artigos = Object(res).data;
        this.carregando.material = 'Seleccione o material';
      });
  }

  private setMaterial(item) {
    this.consumiveis.artigo_id = item.id;
    this.consumiveis.descricao_material = item.codigo_produto +' - '+ item.descricao;
    this.view_material = false;

  }

  //SEARCH MATERIAL

  private searchMaterial() {

    this.consumiveis.unidade = null;
    this.consumiveis.qtd_stock = 0;
    this.consumiveis.qtd_stock_reserva = 0;
    this.consumiveis.artigo_id = null;

    this.artigos = [];

    if (this.consumiveis.categoria_id == null) {
      this.consumiveis.descricao_material = null,
        this.view_material = false
    }

    this.view_material = true;
    this.http.__call('search-material/by/categoria-armazem', {
      start: 1,
      end: 10,
      armazem_id: this.consumiveis.armazem_id,
      categoria_id: this.consumiveis.categoria_id,
      search: this.consumiveis.descricao_material
    }).subscribe(
      response => {

        this.artigos = Object(response).data.data;

      }
    );
  }

  public consumiveisSelecionados = [];
  public recursosSelecionados = [];
  public qtd_stock = 0;

  private getQtdProduto() {
    //Quantidade de artigo em Stock
    this.getQtdProdutoReserva();

    this.consumiveis.qtd_stock = 0;

    if (this.consumiveis.artigo_id != null) {

      this.http
        .__call("stock/existencia/armazem/qtd_produto", {
          armazem_id: this.consumiveis.armazem_id,
          artigo_id: this.consumiveis.artigo_id
        })
        .subscribe((response) => {
          this.consumiveis.qtd_stock = Object(response).data[0].quantidade;


        });

    }
    //-------
  }

  private getQtdProdutoReserva() {

    this.consumiveis.qtd_stock_reserva = 0;

    if (this.consumiveis.artigo_id != null) {

      this.http
        .__call("stock-reserva/existencia/armazem/qtd_produto", {
          armazem_id: this.consumiveis.armazem_id,
          artigo_id: this.consumiveis.artigo_id
        })
        .subscribe((response) => {
          this.consumiveis.qtd_stock_reserva = Object(response).data[0].quantidade;

        });

    }
    //-------
  }

  private getUnidadeByMaterial() {

    this.consumiveis.unidade = null;

    if (this.consumiveis.artigo_id != null) {

      this.http.call_get("unidade-by-material/" + this.consumiveis.artigo_id, null)
        .subscribe((response) => {
          this.consumiveis.unidade = Object(response).data.unidade;
        });

    }
    //-------
  }

  private getQtColaborador() {

    this.http
      .__call("colaborador/get-total", {
        funcao_id: this.recursosHumanos.funcao_id
      })
      .subscribe((response) => {

        this.recursosHumanos.qtd_colaborador = Object(response).data.total;


      });
    //-------
  }

  private isNumericPercentagem(value: any): boolean {

    value = this.consumiveis.qtdArtigo;

    value = !isNaN(value - parseFloat(value));
    this.qtdPrevistaDecimal = value;

    if (!this.qtdPrevistaDecimal) {
      this.configService.showAlert("Número decimal inválido! Quantidade(EST)! Ex. 1 ou 1.5", 'alert-danger', true);
    }
    return !isNaN(value - parseFloat(value));

  }

  public consumiveisToDelete = [];
  public colaboradoresToDelete = [];

  public adicionarArtigos() {
    var validar = 0;
    var nome_artigo = "";
    var codigo_artigo = "";
    var armazem_nome = "";
    var categoria_descricao = "";

    if (this.consumiveis.qtdArtigo == null) {
      this.configService.showAlert('Digite a quantidade a sair', "alert-danger", true);
    } else if (!this.qtdPrevistaDecimal) {
      this.configService.showAlert("Número decimal inválido! Separe por .", 'alert-danger', true);
    } else if (this.consumiveis.qtdArtigo == 0) {
      this.configService.showAlert('Quantidade não pode ser menor que 1', "alert-danger", true);
    } else if (this.consumiveis.qtdArtigo > this.consumiveis.qtd_stock) {
      this.configService.showAlert('Quantidade a sair não pode ser superior a quantidade em Stock', "alert-danger", true);
    } else {

      this.artigos.forEach((element) => {
        if (element.id == this.consumiveis.artigo_id) {
          nome_artigo = element.descricao;
          codigo_artigo = element.codigo_produto;
          armazem_nome = element.armazem_nome;
          categoria_descricao = element.categoria_descricao;
        }
      });

      var artigo = {
        armazem_id: this.consumiveis.armazem_id,
        armazem_nome: armazem_nome,
        categoria_descricao: categoria_descricao,
        nome_artigo: nome_artigo,
        codigo_produto: codigo_artigo,
        artigo_id: this.consumiveis.artigo_id,
        quantidade: this.consumiveis.qtdArtigo,
        qtd_stock: this.qtd_stock,
        agendamento_id: null,
        unidade: this.consumiveis.unidade
      };

      if (this.consumiveisSelecionados.length >= 1) {
        for (let index = 0; index < this.consumiveisSelecionados.length; index++) {
          const l = this.consumiveisSelecionados[index];
          if (l.artigo_id == artigo.artigo_id) {
            this.consumiveisSelecionados.splice(index, 1);
            this.consumiveisSelecionados.splice(index, 0, artigo);
            validar = 1;
          }
        }
        if (validar == 0) {
          this.consumiveisSelecionados.push(artigo);
        }
      } else {
        this.consumiveisSelecionados.push(artigo);
      }

      for (let index = 0; index < this.consumiveisToDelete.length; index++) {
        if (artigo.artigo_id === this.consumiveisToDelete[index].artigo_id) {
          this.consumiveisToDelete.splice(index, 1);
        }
      }

      this.consumiveis.artigo_id = null,
      this.consumiveis.qtdArtigo = null,
      this.consumiveis.qtd_stock = null,
      this.consumiveis.qtd_stock_reserva = null,
      this.consumiveis.unidade = null

    }
  }

  /*  keyPress(event, item) {
     //console.log(event.key)
     var x = event.key;
     var y: number = +x;
 
     if (y <= 0) {
       this.configService.showAlert('Hora prevista não pode ser menor que 1', "alert-danger", true);
     } else if (!y) {
       this.configService.showAlert('Digite apenas número', "alert-danger", true);
     } else {
       this.adicionarHoraPrevista(event.key, item)
     }
   } */

  adicionarInicioEst(item) {

    var replace = item.data_prevista_inicio.replace('T', ' ');
    var date = new Date(replace);

    this.dayInicio = date.getDay();
    this.hourInicio = date.getHours();
    this.minutesInicio = date.getMinutes();
    this.timeInicio = date.getTime();

    this.adicionarHoraPrevista(item)
  }

  adicionarFimEst(item) {

    var replace1 = item.data_prevista_inicio.replace('T', ' ');
    var date1 = new Date(replace1);

    this.dayInicio = date1.getDay();
    this.hourInicio = date1.getHours();
    this.minutesInicio = date1.getMinutes();
    this.timeInicio = date1.getTime();

    var replace = item.data_prevista_fim.replace('T', ' ');

    if (item.data_prevista_inicio == null || item.data_prevista_inicio == "") {
      this.configService.showAlert("Digite a Data prevista de início do(a) " + item.nome_colaborador, 'alert-danger', true);
    } else if (item.data_prevista_fim == null || item.data_prevista_fim == "") {
      this.configService.showAlert("Digite a Data prevista de fim do(a) " + item.nome_colaborador, 'alert-danger', true);
    } else if (item.data_prevista_inicio >= item.data_prevista_fim) {
      this.configService.showAlert("A data fim previsto não pode ser igual ou inferior a data de início previsto - Colaborador(a): " + item.nome_colaborador, 'alert-danger', true);
    } else {

      var date = new Date(replace);

      var dayFim = date.getDay();
      var hourFim = date.getHours();
      var minutesFim = date.getMinutes();
      var timeFim = date.getTime();


      var data = (this.hourInicio * 3600) + (this.minutesInicio * 60);
      var data2 = (hourFim * 3600) + (minutesFim * 60);
      var diferenca = data2 - data;

      this.hourInicio = Math.floor(diferenca / 3600);
      this.minutesInicio = Math.floor((diferenca - (this.hourInicio * 3600)) / 60);

      var condincao = this.hourInicio + '' + (this.minutesInicio.toString().length < 2 ? '0' + '' + this.minutesInicio : this.minutesInicio);

      var diffHoras = (parseInt(condincao) > 759 ? parseInt(this.hourInicio) - 8 : this.hourInicio)
      var diffDias = dayFim - this.dayInicio;
      diffDias = (parseInt(condincao) > 759 ? diffDias + 1 : diffDias)


      this.adicionarFimPrevista(item, diffDias, diffHoras, this.minutesInicio)

    }

  }


  public adicionarFimPrevista(item, diffDias, diffHoras, diffMinutos) {
    var validar = 0;

    var diffDiasReplace = diffDias.toString();
    var diffHorasReplace = diffHoras.toString();
    var diffMinutosReplace = diffMinutos.toString();

    var recursos = {
      nome_colaborador: item.nome_colaborador,
      colaborador_id: item.colaborador_id,
      nome_departamento: item.nome_departamento,
      nome_funcao: item.nome_funcao,
      is_responsavel: item.is_responsavel,
      data_prevista_inicio: item.data_prevista_inicio,
      data_prevista_fim: item.data_prevista_fim,
      dif_dias_prevista: parseInt(diffDiasReplace.replace("-", "")),
      dif_horas_prevista: parseInt(diffHorasReplace.replace("-", "")),
      dif_minutos_prevista: parseInt(diffMinutosReplace.replace("-", ""))
    };

    if (this.recursosSelecionados.length >= 1) {
      for (let index = 0; index < this.recursosSelecionados.length; index++) {
        const l = this.recursosSelecionados[index];
        if (l.colaborador_id == recursos.colaborador_id) {
          this.recursosSelecionados.splice(index, 1);
          this.recursosSelecionados.splice(index, 0, recursos);
          validar = 1;
        }
      }
      if (validar == 0) {
        this.recursosSelecionados.push(recursos);
      }
    } else {
      this.recursosSelecionados.push(recursos);
    }


  }


  public adicionarHoraPrevista(item) {
    var validar = 0;

    var recursos = {
      nome_colaborador: item.nome_colaborador,
      colaborador_id: item.colaborador_id,
      nome_departamento: item.nome_departamento,
      nome_funcao: item.nome_funcao,
      is_responsavel: item.is_responsavel,
      data_prevista_inicio: item.data_prevista_inicio
    };

    if (this.recursosSelecionados.length >= 1) {
      for (let index = 0; index < this.recursosSelecionados.length; index++) {
        const l = this.recursosSelecionados[index];
        if (l.colaborador_id == recursos.colaborador_id) {
          this.recursosSelecionados.splice(index, 1);
          this.recursosSelecionados.splice(index, 0, recursos);
          validar = 1;
        }
      }
      if (validar == 0) {
        this.recursosSelecionados.push(recursos);
      }
    } else {
      this.recursosSelecionados.push(recursos);
    }


  }

  public adicionarRecursos(item) {
    var validar = 0;
    var nome_funcao = "";
    var nome_departamento = "";


    this.funcoes.forEach((element) => {
      if (element.id == this.recursosHumanos.funcao_id) {
        nome_funcao = element.descricao;
      }
    });

    this.departamentos.forEach((element) => {
      if (element.id == this.recursosHumanos.departamento_id) {
        nome_departamento = element.descricao;
      }
    });

    this.departamentos.forEach((element) => {
      if (element.id == this.recursosHumanos.departamento_id) {
        nome_departamento = element.descricao;
      }
    });

    var recursos = {
      nome_colaborador: item.colaboradorNome,
      colaborador_id: item.id,
      nome_departamento: nome_departamento,
      nome_funcao: nome_funcao,
      agendamento_id: null,
      data_prevista_inicio: null,
      is_responsavel: this.is_responsavel
    };

    this.is_responsavel = false;

    if (this.recursosSelecionados.length >= 1) {
      for (let index = 0; index < this.recursosSelecionados.length; index++) {
        const l = this.recursosSelecionados[index];
        if (l.colaborador_id == recursos.colaborador_id) {
          this.recursosSelecionados.splice(index, 1);
          this.recursosSelecionados.splice(index, 0, recursos);
          validar = 1;
        }
      }
      if (validar == 0) {
        this.recursosSelecionados.push(recursos);
      }
    } else {
      this.recursosSelecionados.push(recursos);
    }

    for (let index = 0; index < this.colaboradoresToDelete.length; index++) {
      if (recursos.colaborador_id === this.colaboradoresToDelete[index].colaborador_id) {
        this.colaboradoresToDelete.splice(index, 1);
      }
    }


  }

  private selectedResponsavel(id) {

    this.recursosHumanos.colaborador_id = id;
    this.colaboradores.forEach(element => {
      if (this.recursosHumanos.colaborador_id == element.id) {
        this.recursosHumanos.colaborador_id = element.id;
        this.is_responsavel = !this.is_responsavel;
      }
    });

  }


  deleteRow(item: any) {

    var validar = 0;

    var deletedConsumiveis = {
      nome_artigo: item.nome_artigo,
      artigo_id: item.artigo_id,
    };

    if (this.consumiveisToDelete.length >= 1) {
      for (let index = 0; index < this.consumiveisToDelete.length; index++) {
        const l = this.consumiveisToDelete[index];
        if (l.artigo_id == deletedConsumiveis.artigo_id) {
          this.consumiveisToDelete.splice(index, 1);
          this.consumiveisToDelete.splice(index, 0, deletedConsumiveis);
          validar = 1;
        }
      }
      if (validar == 0) {
        this.consumiveisToDelete.push(deletedConsumiveis);
      }
    } else {
      this.consumiveisToDelete.push(deletedConsumiveis);
    }

    for (let i = 0; i < this.consumiveisSelecionados.length; ++i) {
      if (this.consumiveisSelecionados[i].artigo_id === item.artigo_id) {
        this.consumiveisSelecionados.splice(i, 1);
      }
    }
  }

  deleteRecursoRow(item: any) {

    var validar = 0;

    var deletedRow = {
      nome_colaborador: item.nome_colaborador,
      colaborador_id: item.colaborador_id,
    };

    if (this.colaboradoresToDelete.length >= 1) {
      for (let index = 0; index < this.colaboradoresToDelete.length; index++) {
        const l = this.colaboradoresToDelete[index];
        if (l.colaborador_id == deletedRow.colaborador_id) {
          this.colaboradoresToDelete.splice(index, 1);
          this.colaboradoresToDelete.splice(index, 0, deletedRow);
          validar = 1;
        }
      }
      if (validar == 0) {
        this.colaboradoresToDelete.push(deletedRow);
      }
    } else {
      this.colaboradoresToDelete.push(deletedRow);
    }

    for (let i = 0; i < this.recursosSelecionados.length; ++i) {
      if (this.recursosSelecionados[i].colaborador_id === item.colaborador_id) {
        this.recursosSelecionados.splice(i, 1);
      }
    }

    this.is_responsavel = false;
  }

  private reloadOrdemServico() {
    location.reload();
  }


  private validateConsumiveis() {

    if (this.consumiveisSelecionados.length == 0) {
      this.configService.showAlert("Adiciona pelo menos um Material na lista", 'alert-danger', true);
    } else {
      const nextElement: HTMLElement = document.querySelector('#next-step-colaboradores')
      nextElement.click()

    }
  }

  private formatDate(date) {

    var dia = date.getDate();
    var ano = date.getFullYear();
    var hora = date.getHours();
    var minuto = date.getMinutes();
    var mes = date.getMonth() + 1

    return (ano + "-" + (mes.toString().length < 2 ? "0" + mes : mes) + "-" + (dia.toString().length < 2 ? "0" + dia : dia) + "T" + (hora.toString().length < 2 ? "0" + hora : hora) + ":" + (minuto.toString() < 2 ? "0" + minuto : minuto));

  }

  private validateColaboradores() {

    var next = 0;
    var start = 1;
    var validar = 0;

    if (this.recursosSelecionados.length == 0) {
      this.configService.showAlert("Adiciona pelo menos um Colaborador na lista", 'alert-danger', true);
    } else if (this.recursosSelecionados.length >= 1) {
      for (let index = 0; index < this.recursosSelecionados.length; index++) {
        const l = this.recursosSelecionados[index];

        if (l.data_prevista_inicio == null) {
          this.configService.showAlert("Digite a Data prevista de início do(a) " + l.nome_colaborador, 'alert-danger', true);
          next = 1;
        }

        if (l.data_prevista_fim == null) {
          this.configService.showAlert("Digite a Data prevista de fim do(a) " + l.nome_colaborador, 'alert-danger', true);
          next = 1;
        }

        if (l.data_prevista_inicio >= l.data_prevista_fim) {
          this.configService.showAlert("A data fim previsto não pode ser igual ou inferior a data de início previsto - Colaborador(a): " + l.nome_colaborador, 'alert-danger', true);
          next = 1;
        }

        if (l.is_responsavel == true) {
          validar = ++start;
        }

        if (validar > 2) {
          this.configService.showAlert("Seleccione apenas um Responsável", 'alert-danger', true);
          next = 1;
        } else if (validar == 0) {
          this.configService.showAlert("Seleccione um Responsável", 'alert-danger', true);
          next = 1;
        }
      }

      var dates_prevista_inicio = [];
      var dates_prevista_fim = [];

      for (let index = 0; index < this.recursosSelecionados.length; index++) {
        dates_prevista_inicio.push(new Date(this.recursosSelecionados[index].data_prevista_inicio))
        dates_prevista_fim.push(new Date(this.recursosSelecionados[index].data_prevista_fim))
      }

      var maxDate = Math.max.apply(null, dates_prevista_fim)
      var mimDate = Math.min.apply(null, dates_prevista_inicio)
      // convert back to date object
      maxDate = new Date(maxDate)
      mimDate = new Date(mimDate)


      this.agendamento.data_previsao_inicio = this.formatDate(mimDate);
      this.agendamento.data_previsao_fim = this.formatDate(maxDate);

      this.agendamentoAddInicioPrev();
      this.agendamentoAddFimPrev();

      if (next == 0) {
        const nextElement: HTMLElement = document.querySelector('#next-step-agendamento')
        nextElement.click()
      }

    }

  }

  private imprimirAgendamento(id) {
    this.configService.imprimirAgendamento(id, "2ª Via", "imprimir")
  }


  agendamentoAddInicioPrev() {

    var replace = this.agendamento.data_previsao_inicio.replace('T', ' ');
    var date = new Date(replace);

    this.agendamentodayInicio = date.getDay();
    this.agendamentohourInicio = date.getHours();
    this.agendamentominutesInicio = date.getMinutes();
  }

  agendamentoAddFimPrev() {

    var replace1 = this.agendamento.data_previsao_fim.replace('T', ' ');

    if (this.agendamento.data_previsao_inicio == null || this.agendamento.data_previsao_fim == "") {
      this.configService.showAlert("Digite a Data prevista de início", 'alert-danger', true);
    } else if (this.agendamento.data_previsao_fim == null || this.agendamento.data_previsao_fim == "") {
      this.configService.showAlert("Digite a Data prevista de fim", 'alert-danger', true);
    } else if (this.agendamento.data_previsao_inicio >= this.agendamento.data_previsao_fim) {
      this.configService.showAlert("A data fim previsto não pode ser igual ou inferior a data de início previsto", 'alert-danger', true);
    } else {

      var date1 = new Date(replace1);

      var dayFim = date1.getDay();
      var hourFim = date1.getHours();
      var minutesFim = date1.getMinutes();

      var data = (this.agendamentohourInicio * 3600) + (this.agendamentominutesInicio * 60);
      var data2 = (hourFim * 3600) + (minutesFim * 60);
      var diferenca = data2 - data;

      this.hourInicioAgendamento = Math.floor(diferenca / 3600);
      this.minutesInicioAgendamento = Math.floor((diferenca - (this.hourInicioAgendamento * 3600)) / 60);


      var condincao = this.hourInicioAgendamento + '' + (this.minutesInicioAgendamento.toString().length < 2 ? '0' + '' + this.minutesInicioAgendamento : this.minutesInicioAgendamento);

      var diffHoras = (parseInt(condincao) > 759 ? parseInt(this.hourInicioAgendamento) - 8 : this.hourInicioAgendamento)
      var diffDias = dayFim - this.agendamentodayInicio;
      diffDias = (parseInt(condincao) > 759 ? diffDias + 1 : diffDias)

      this.agendamento.dif_dias_prevista = diffDias;
      this.agendamento.dif_horas_prevista = diffHoras;
      this.agendamento.dif_minutos_prevista = this.minutesInicioAgendamento

    }

  }

  private finalizar() {

    if (this.agendamento.data_previsao_inicio == null || this.agendamento.data_previsao_inicio == "") {
      this.configService.showAlert("Digite a Data Início / Prevista", 'alert-danger', true);
    } if (this.agendamento.data_previsao_fim == null || this.agendamento.data_previsao_fim == "") {
      this.configService.showAlert("Digite a Data Término / Prevista", 'alert-danger', true);
    } else if (this.agendamento.data_previsao_inicio >= this.agendamento.data_previsao_fim) {
      this.configService.showAlert("Data de início / Prevista não pode ser superior ou igual a Data de término / Prevista", 'alert-danger', true);
    } else if (this.agendamento.descricao == null || this.agendamento.descricao == "") {
      this.configService.showAlert("Digite a descrição", 'alert-danger', true);
    } else {

      this.isFinalizar = false;
      this.isDisabled = true;
      this.configService.loaddinStarter('start');

      this.http.__call('ordemservico/agendamento/update', {
        ordemServico: this.ordemServico,
        routerParamId: this.routerParamId,
        agendamento: this.agendamento,
        consumiveis: this.consumiveisSelecionados,
        recursosHumanos: this.recursosSelecionados,
        colaboradoresToDelete: this.colaboradoresToDelete,
        consumiveisToDelete: this.consumiveisToDelete
      }).subscribe(
        res => {
          if (Object(res).code == 200) {
            this.isDisabled = true;
            this.configService.showAlert(Object(res).message, "alert-success", true);
            this.validateButton = Object(res).code

            this.ordemServico.success_ordem_servico = true;

            this.ordemServico.agendamentoGerado = this.agendamento.id;
          } else {
            this.configService.showAlert(Object(res).message, "alert-danger", true);
            this.isFinalizar = false;
            this.isDisabled = false;
          }
          this.configService.loaddinStarter('stop');
        },
        error => {
          this.configService.loaddinStarter("stop");
          this.isFinalizar = false;
          this.isDisabled = false;
        }
      )

    }
  }

  private getColaboradores() {
    this.getAllColaboradores();
  }

  private getAllColaboradores() {
    this.colaboradores = [];

    this.configService.loaddinStarter('start');
    //this.pagination.search = (search == "" || search == null ? null : search);
    this.http.__call('colaborador/colaboradoresByFuncao', {
      start: 1,
      end: 10,
      search: (this.recursosHumanos.colaborador_nome == "" || this.recursosHumanos.colaborador_nome == null ? null : this.recursosHumanos.colaborador_nome),
      funcao_id: this.recursosHumanos.funcao_id
    }).subscribe(
      res => {
        this.colaboradores = Object(res).data.data;
        this.configService.loaddinStarter('stop');
      }
    );
  }




}
