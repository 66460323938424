import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { ConfigModuloService } from 'src/app/services/config-modulo.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {

  private app_environment: null;

  private dash = {
    contratosCount: 0,
    leiturasFacturadas: 0,
    clienteCount: 0,
    facturaCount: 0,
    produtoCount: 0,
    documentoCount: 0,

    facturaSumTotalSemImpostoHoje: 0,
    facturaSumTotalSemImpostoMes: 0,
    facturaSumTotalSemImpostoOntem: 0,
    facturaSumTotalSemImpostoMesAnterior: 0,
    facturaSumTotalSemImpostoGeral: 0,

    facturaSumTotalComImpostoHoje: 0,
    facturaSumTotalComImpostoMes: 0,
    facturaSumTotalComImpostoOntem: 0,
    facturaSumTotalComImpostoMesAnterior: 0,
    facturaSumTotalComImpostoGeral: 0,

    TotalRecebimentos:0,
		TotalRecebimentoHoje:0,
		TotalRecebimentoOntem:0,
		TotalRecebimentoMes:0,
		TotalRecebimentoMesAnterior:0
  }


  constructor(
    private auth: AuthService,
    private http: HttpService,
    private configService: ConfigService,
    private config: ConfigModuloService
  ) { }

  ngOnInit() {

    //this.initConfiguracaos();

    this.dashboard();

    /*.body.className = 'hold-transition sidebar-mini';

    if (this.auth.getLoading() == '1') {
      this.auth.setLoading(0);
      location.reload();
    }
*/
  }

  private initConfiguracaos() {

    this.app_environment = this.auth.getAppEnvironment();

    if (this.app_environment == null) {

      var url = require('url');
      var app_url = url.parse(environment.app_url, true);

      this.app_environment = app_url.host;

      this.config.saveConfig(app_url.host, this.config.modulo.CONFIGURACOES, app_url.host);
    }

    console.log("this.app_environment " + this.app_environment);

  }

  ngOnDestroy(): void {
    document.body.className = ' ';
  }

  /**
   * @name "Estatistica dashboard"
   * @descriptio "Estatistica dashboard"
   * @author "caniggia.moreira@itgest.pt"
   * @param start
   * @param end
   */
  private dashboard() {
    this.configService.loaddinStarter('start');
    this.http.call_get('dashboard/listar', null).subscribe(
      response => {
        this.dash.clienteCount = Object(response).data.clienteCount;
        //this.dash.produtoCount = Object(response).data.produtoCount;
        this.dash.facturaCount = Object(response).data.facturaCount == null?0.0 : Object(response).data.facturaCount.toLocaleString(['pt', 'id']);
        this.dash.contratosCount = Object(response).data.contratosCount
        this.dash.leiturasFacturadas = Object(response).data.leiturasFacturadas

        

        this.dash.facturaSumTotalSemImpostoHoje = Object(response).data.facturaSumTotalSemImpostoHoje;
        this.dash.facturaSumTotalSemImpostoOntem = Object(response).data.facturaSumTotalSemImpostoOntem;
        this.dash.facturaSumTotalSemImpostoMes = Object(response).data.facturaSumTotalSemImpostoMes;
        this.dash.facturaSumTotalSemImpostoMesAnterior = Object(response).data.facturaSumTotalSemImpostoMesAnterior;
        this.dash.facturaSumTotalSemImpostoGeral = Object(response).data.facturaSumTotalSemImpostoGeral;


        this.dash.facturaSumTotalComImpostoHoje = Object(response).data.facturaSumTotalComImpostoHoje;
        this.dash.facturaSumTotalComImpostoOntem = Object(response).data.facturaSumTotalComImpostoMes;
        this.dash.facturaSumTotalComImpostoMes = Object(response).data.facturaSumTotalComImpostoMes;
        this.dash.facturaSumTotalComImpostoMesAnterior = Object(response).data.facturaSumTotalComImpostoMesAnterior;
        this.dash.facturaSumTotalComImpostoGeral = Object(response).data.facturaSumTotalComImpostoGeral;

        this.dash.TotalRecebimentos = Object(response).data.TotalRecebimentos.total == null? 0.0 : Object(response).data.TotalRecebimentos.total.toLocaleString(['pt', 'id']);
        this.dash.TotalRecebimentoHoje = Object(response).data.TotalRecebimentoHoje.total == null ? 0.0 : Object(response).data.TotalRecebimentoHoje.total.toLocaleString(['pt', 'id']);
        this.dash.TotalRecebimentoOntem = Object(response).data.TotalRecebimentoOntem.total == null ? 0.0 :Object(response).data.TotalRecebimentoOntem.total.toLocaleString(['pt', 'id']);
        this.dash.TotalRecebimentoMes = Object(response).data.TotalRecebimentoMes.total == null ? 0.0 : Object(response).data.TotalRecebimentoMes.total.toLocaleString(['pt', 'id']);
        this.dash.TotalRecebimentoMesAnterior = Object(response).data.TotalRecebimentoMesAnterior.total == null ? 0.0 : Object(response).data.TotalRecebimentoMesAnterior.total.toLocaleString(['pt', 'id']);

        this.configService.loaddinStarter('stop');
      }
    );
  }

}
