import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { HttpService } from 'src/app/providers/http/http.service';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { ExcelService } from 'src/app/services/excel.service';
import * as moment from 'moment';

@Component({
  selector: 'app-stock-categoria-produtos',
  templateUrl: './stock-categoria-produtos.component.html',
  styleUrls: ['./stock-categoria-produtos.component.css']
})
export class StockCategoriaProdutosComponent implements OnInit, OnDestroy {

  public categorias: any;
  private isDisabled: boolean = false;
  
  private loading: boolean = false;
  public filters = {
    search: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    pagination: {
      perPage: 5,// Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima,
      lastPage: null,
      total: null // defini o total de registo da BD
    },
    filter: null // subelemente do filter
  }


  constructor(private auth: AuthService, private http: HttpService, private configService: ConfigService,private excelService: ExcelService) { }

  private items: any = [];

  ngOnInit() {
    this.getPageFilterData(1);

  }

  ngOnDestroy(){
    this.http.filters.orderBy = null
  }


  private listar() {

    //this.configService.loaddinStarter('start');

    this.loading = true
    this.http.__call('categoria-produto/listar', this.filters).subscribe(
      response => {
        this.filters.pagination.lastPage = Object(response).data.lastPage;
        this.filters.pagination.page = Object(response).data.page;
        this.filters.pagination.total = Object(response).data.total;
        this.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.loading = false
      }
    );
  }

  private puxarPrimavera() {

    this.isDisabled = true;
    this.loading = true;

    this.http.call_get("store-categoria-artigo-primavera", null).subscribe((res: any) => {
      if (Object(res).code == 200) {

        this.listar();

        this.isDisabled = false;
      }

    });
  }

  getPageFilterData(page: number) {
    if (this.filters.pagination.perPage == null) {
      return;
    }
    this.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
    this.listar();
  }


  private setData(categorias) {
    this.categorias = categorias
  }



}
