import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'createOrEditSerie',
  templateUrl: './create-or-edit-serie.component.html',
  styleUrls: ['./create-or-edit-serie.component.css']
})
export class CreateOrEditSerieComponent implements OnInit {

  @Input() modal: string = "modalSerieCreateOrEdit";
  @Input() title: string = "Registar Serie";
  @Input() serie: any;
  @Input() documentos: any = [];

  submitted = false;
  private loading: boolean = false;
  @Input() simpleFormSerie: FormGroup;

  private meses = [
    { id: 1, nome: "Janeiro" },
    { id: 2, nome: "Fevereiro" },
    { id: 3, nome: "Março" },
    { id: 4, nome: "Abril" },
    { id: 5, nome: "Maio" },
    { id: 6, nome: "Junho" },
    { id: 7, nome: "Julho" },
    { id: 8, nome: "Agosto" },
    { id: 9, nome: "Setembro" },
    { id: 10, nome: "Outubro" },
    { id: 11, nome: "Novembro" },
    { id: 12, nome: "Dezembro" },
  ]

  private anos = [
    {
      id: moment(new Date()).format('YYYY'), nome: moment(new Date()).format('YYYY')
    }
  ]



  @Output() private loadListSerie = new EventEmitter<any>();

  private max_date = moment(new Date()).format('YYYY-MM');
  private min_date = moment(new Date()).format('YYYY-MM');


  constructor(private http: HttpService, private configService: ConfigService, private formBuilder: FormBuilder) {
    this.createForm();
  }

  ngOnInit() { }

  createForm() {
    this.simpleFormSerie = this.formBuilder.group({
      index: [{ value: null, disabled: true }],
      documento_id: [null, Validators.required],
      nome: [null, Validators.required],
      mes: [null],
      ano: [null, Validators.required],
      movimento: [null, Validators.required],
      tipo_movimento: [null, Validators.required],
      activo: [null, Validators.required],
      descricao: [null]
    });
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.simpleFormSerie.controls;
  }

  onReset() {
    this.submitted = false;
    this.simpleFormSerie.reset();
  }


  onSubmit() {

    this.submitted = true;

    // parar aquei se o formulário for inválido
    if (this.simpleFormSerie.invalid) {
      return;
    }
    this.loading = true;
    const index = this.simpleFormSerie.getRawValue().index;
    // TODO: usado para fazer a requisição com a api de criação de objsct or update
    const uri = (index === null ? 'serie/create' : 'serie/update/' + index);
    this.createOrEdit(uri, this.simpleFormSerie, (index === null ? true : false));

  }

  createOrEdit(uri: any, formulario: FormGroup, isCreate: boolean) {

    // TODO: usado para fazer a requisição com a api de criação de object
    this.http.__call(uri, formulario.value).pipe(first()).subscribe(
      response => {
        this.submitted = false;
        this.loading = false;
        if (isCreate) {
          formulario.reset();
          this.loadListSeries(Object(response).data);
        }

        if (Object(response).code == 200) {
          this.loadListSeries(Object(response).data);
        }
      },
      error => {
        this.submitted = false;
        this.loading = false;
      });
  }




  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    this.getDocumentos();
    if (this.serie !== undefined) {
      this.title = "Editar serie";
      this.simpleFormSerie.patchValue({
        index: this.serie.id,
        documento_id: this.serie.documento_id,
        nome: this.serie.nome,
        //proximo_numero:this.serie.proximo_numero,
        mes: this.serie.mes,
        ano: this.serie.ano,
        movimento: this.serie.movimento,
        tipo_movimento: this.serie.tipo_movimento,
        activo: this.serie.activo,
        descricao: this.serie.descricao
      });
    } else {
      this.title = "Registar Serie";
    }
  }



  public loadListSeries(serie) {
    this.loadListSerie.emit(serie);
  }


  /**
     * @name "Listar Documentos"
     * @descriptio "Esta Função permite Listar todos Documentos"
     * @author "caniggia.moreira@itgest.pt"
     * @param start 
     * @param end 
     */
  private getDocumentos() {
    this.http.__call('documento/show', null).subscribe(
      data => {
        this.documentos = data;
      }
    );
  }

}

