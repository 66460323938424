import { finalize } from 'rxjs/operators';

import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
// ES6 Modules or TypeScript
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'

@Component({
  selector: 'app-devoulcao',
  templateUrl: './devoulcao.component.html',
  styleUrls: ['./devoulcao.component.css']
})
export class DevoulcaoComponent implements OnInit {


  private search: string;
  public cliente_id = null
  submitted = false;
  disable = false;
  public view_btn_pagador = true

  loading = false;
  private facturasPagador_alternativo: any = []
  lista_dos_adiantamentos: any = []
  adiantamento = []
  listMovimento = []
  private totalAdiantamento = 0;

  private recibo: any;
  adianntamento_como_default: any

  devolucaoForm: FormGroup;

  private validateButton: boolean = false;
  private carregando = {
    serie: 'Seleccione a serie',
    forma_pagamento: 'Seleccione a forma de pagamento',
    banco: 'Seleccione conta da bancaria'
  }


  private contrat = {
    contrato_id: null
  }
  private _conta = {
    id: null
  }

  private contas: any = [];
  private contratos: any = [];
  private loadingConta: string = "Conta"
  private loadingContrato: string = "Contrato"

  view_movimento_caucao = false;
  caucaoActual: 0.0;
  private movimentoCaucao = {
    facturas: [],
    recibos: []
  }

  constructor(
    private http: HttpService,
    private configService: ConfigService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
  ) {
    this.http.__call(`configuracao/getConfiguracaobySlug/adianntamento_como_default`, null)
      .subscribe(response => {
        if (Object(response).data.valor == "true") {
          this.adianntamento_como_default = '1'
        } else {
          this.adianntamento_como_default = '0'
        }
      })
    this.listarFormaPagamentos();
    this.configService.listarBancos();
    this.createForm();
  }

  ngOnInit() {
    const param = this.route.queryParams.subscribe(params => {
      const id = params['c'] || 0;
      const name = params['n'] || 0;
      if (id > 0 && name) {
        this.getSeriesDevolucao();
        this.setCliente({ id: id, nome: name });
        this.devolucaoForm.get('nome').disable();
      }
    });
  }

  ngOnDestroy() {
    //this.sub.unsubscribe();
  }
  listarBancosById(id) {
    this.configService.listarBancosById(id)
  }
  createForm() {
    this.devolucaoForm = this.formBuilder.group({
      nome: [''],
      cliente_id: [null, Validators.required],
      serie_id: [this.default],
      observacao: '',
      linha_devolucao_entrada: [{ value: null }],
      linha_devolucao_saida: [{ value: null }],
      contrato_id: null,

      forma_pagamento_id: [null],
      banco_id: [{ value: null, disabled: true }],
      referencia: [{ value: null, disabled: true }],
      data_pagamento: [{ value: null, disabled: true }],
    });

    this.movimentoCaucao.facturas = [];
    this.movimentoCaucao.recibos = [];
    this.documentoSelecionados = []
    this.view_movimento_caucao = false
    this.configService.loaddinStarter('stop');
    this.loading = false;
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.devolucaoForm.controls;
  }

  onReset() {
    this.devolucaoForm.reset()
    this.submitted = false;
    this.movimentoCaucao.facturas = [];
    this.movimentoCaucao.recibos = [];
    this.documentoSelecionados = []
    this.view_movimento_caucao = false
    this.configService.loaddinStarter('stop');
    this.loading = false;
  }


  private formasPagamentos: any = [];

  private listarFormaPagamentos() {
    this.carregando.forma_pagamento = 'Carregando...'
    this.http.call_get('api/v1/caucao/forma_pagamentos', null).subscribe(
      response => {

        if (this.totalAdiantamento == 0) {
          this.formasPagamentos = [];
          for (let i = 0; i < Object(response).data.length; i++) {
            if (Object(response).data[i].designacao !== 'Adiantamento') {
              this.formasPagamentos.push(Object(response).data[i]);
            }
          }
        } else {
          this.formasPagamentos = [];
          this.formasPagamentos = Object(response).data;
        }
        this.configService.loaddinStarter('stop');
        this.carregando.forma_pagamento = 'Seleccione a forma de pagamento'
      }
    );
  }

  handleChangeFormaPagamento() {
    this.devolucaoForm.patchValue({
      total_saldado: '',
    });
    this.adiantamento = []
    this.listMovimento = []

    if (this.devolucaoForm.getRawValue().forma_pagamento_id != null && !this.verificaForma(this.devolucaoForm.getRawValue().forma_pagamento_id)) {

      this.devolucaoForm.get('data_pagamento').enable();
      this.devolucaoForm.get('referencia').enable();
      this.devolucaoForm.get('banco_id').enable();
    } else {
      this.devolucaoForm.get('data_pagamento').reset();
      this.devolucaoForm.get('data_pagamento').disable();
      this.devolucaoForm.get('referencia').reset();
      this.devolucaoForm.get('referencia').disable();
      this.devolucaoForm.get('banco_id').reset();
      this.devolucaoForm.get('banco_id').disable();
    }

    this.formasPagamentos.forEach(element => {
      if (element.designacao === 'Adiantamento' && this.devolucaoForm.getRawValue().forma_pagamento_id == element.id) {
        this.lista_dos_adiantamentos = []

        this.devolucaoForm.get("total_saldado").disable();
      }

    });

    this.formasPagamentos.forEach(element => {
      if (element.designacao === 'Caução' && this.devolucaoForm.getRawValue().forma_pagamento_id == element.id) {
        this.devolucaoForm.get("total_saldado").disable();
      }

    });

  }

  private verificaForma(item) {
    if (item == "") {
      return true
    }
    var chaves = [];
    this.formasPagamentos.forEach(element => {
      if (element.usar_banco === 0) {
        chaves.push("" + element.id);
      }
    })
    if (chaves.length > 0) {
      if (chaves.indexOf(item) > -1) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }


  printer_recibo: boolean = true;
  private series: any = []
  private default: any = []

  private getSeriesDevolucao() {
    this.http.__call(`configuracao/getConfiguracaobySlug/adianntamento_como_default`, null)
      .subscribe(response => {
        if (Object(response).data.valor == "true") {
          this.devolucaoForm.patchValue({
            is_adiantamento: '1',
          })
          this.adianntamento_como_default = '1'
        } else {
          this.devolucaoForm.patchValue({
            is_adiantamento: '0',
          })
          this.adianntamento_como_default = '0'
        }
      })

    this.http.call_get('api/v1/caucao/series', null)
      .subscribe(response => {

        let data = Object(response).data
        this.series = data;
        this.default = this.series[0].id
        this.listarBancosById(data[0].loja_id)
        this.devolucaoForm.patchValue({
          serie_id: this.default

        })
        this.carregando.serie = 'Seleccione a serie'
      });
  }

  /* ===== PROCESSO DEVOLUÇÃO CAUÇÃO ===== */

  private clientes: any = [];
  view_client = false;

  private getCliente() {
    this.movimentoCaucao.facturas = [];
    this.movimentoCaucao.recibos = [];
    this.documentoSelecionados = []
    this.view_movimento_caucao = false;
    this.view_client = true;

    this.http.__call('cliente/search-cliente', { start: 1, end: 10, search: this.devolucaoForm.getRawValue().nome }).subscribe(
      response => {
        this.clientes = Object(response).data.data;
        this.configService.loaddinStarter('stop');
        this.getSeriesDevolucao();
      }
    );
  }

  private setCliente(client: any) {
    this.createForm();
    this.cliente_id = client.id
    this.devolucaoForm.patchValue({
      cliente_id: client.id,
      nome: client.nome,
    });
    this.view_client = false;
    this.getContasByCliente(client.id)
    this.view_btn_pagador = false
    this.validateButton = false
  }

  private changeSelectValue(contrato_id: any) {
    this.contrat.contrato_id = contrato_id;
    if (contrato_id != 'null') {
      this.movimentoCaucaoOfContrato(contrato_id);
    }
  }

  movimentoCaucaoOfContrato(contrato_id) {
    this.loading = true
    this.http.__call('api/v1/caucao/movimento', { contrato_id: contrato_id })
      .subscribe(res => {
        this.movimentoCaucao.facturas = Object(res).data.facturas
        this.movimentoCaucao.recibos = Object(res).data.recibos
        this.caucaoActual = Object(res).data.caucaoActual > 0 ? Object(res).data.caucaoActual : 0

        if ((!this.movimentoCaucao.facturas.length) && (!this.movimentoCaucao.recibos.length)) {
          this.configService.showAlert('Nenhum resultado encontrado', "alert-danger", true);
        }
        this.loading = false
      })
  }

  private getContasByCliente(id) {
    this.loadingConta = 'Carregando...';
    //id = id.target.value;
    this.configService.loaddinStarter('start');
    this.http.call_get('cliente/conta/listar/' + id, null).subscribe(
      response => {
        this.contas = Object(response).data;
        console.log("this.contas", this.contas)
        this.configService.loaddinStarter('stop');
      }
    );
  }

  public getContratosByConta(id) {
    this.loadingContrato = 'Carregando...';
    id = id.target.value;
    this.http.__call('conta/GETcontratoIDByConta/' + id, null).subscribe(
      response => {
        this.contratos = Object(response);
        this.loadingContrato = 'Contrato';
        this.configService.loaddinStarter('stop');
      }
    );
  }


  private documentoSelecionados = []
  private contrato: any;

  save() {

    this.submitted = true;

    if (this.devolucaoForm.getRawValue().forma_pagamento_id == null) {
      this.configService.showAlert('selecione a forma de pagamento', "alert-danger", true);
      return;
    }
    if (this.devolucaoForm.getRawValue().serie_id == null) {
      this.configService.showAlert('selecione a série', "alert-danger", true);
      return;
    }

    this.loading = true
    this.devolucaoForm.patchValue({
      linha_devolucao_entrada: this.movimentoCaucao.facturas,
      linha_devolucao_saida: this.movimentoCaucao.recibos,
      cliente_id: this.cliente_id,
      contrato_id: this.contrat.contrato_id
    });

    const uri = 'api/v1/caucao/devolucao';
    this.createOrEdit(uri, this.devolucaoForm, true);
  }

  createOrEdit(uri: any, devolucaoForm: FormGroup, isCreate: boolean) {
    this.validateButton = false;
    var nome: string;
    this.http.__call(uri, devolucaoForm.value)
      .pipe(finalize(() => { this.loading = false; this.validateButton = true; this.submitted = false; })).subscribe(
        res => {
          this.printer_recibo = false;
          nome = devolucaoForm.value.nome
          this.validateButton = true;
          this.onReset();
          this.configService.showAlert(Object(res).message, 'alert-success', true)
        }, error => {
          this.validateButton = true;
          this.loading = false
        }
      )
  }

}
