import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-visualizar-relatorio',
  templateUrl: './visualizar-relatorio.component.html',
  styleUrls: ['./visualizar-relatorio.component.css']
})
export class VisualizarRelatorioComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
