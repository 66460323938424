import { Injectable, EventEmitter } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import * as moment from 'moment';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import { userInfo } from 'os';


@Injectable({
  providedIn: 'root'
})
export class DiarioVendasService {

  public pdfEvent = new EventEmitter<Object>();

  constructor(private http: HttpService, private configService: ConfigService) { }


  public DiarioVendas(user, data1, data2, caixa_id) {
    
    this.http.__call('diario/vendas', {
      user: user,
      data1: data1,
      data2: data2,
      caixa_id: caixa_id
    }).subscribe(
      response => {
        const dados = Object(response).data;
        //console.log(dados);
        this.imprimirDiarioVendasPDF(dados.produtos, dados.utilizador, dados.data_venda, dados.data1, dados.data2, dados.caixa_id);

      }
    );


  }

  public imprimirDiarioVendasPDF(produtos: any[], utilizador: any, data_venda: any, data1: any, data2: any, caixaNumero: any) {
    // You'll need to make your image into a Data URL
    // Use http://dataurl.net/#dataurlmaker
    var imgData = utilizador.logotipo;
    //var data = Date.now();
    console.log(utilizador);
    var doc = new jsPDF('landscape');
    doc.addImage(utilizador.logotipo, 5, 5, utilizador.width, utilizador.height);
    doc.setFontSize(14);
    doc.text("Diário de Vendas, Produtos e Serviços", 105, 33);

    doc.setFontSize(12);
    doc.setFontType("bold");
 

    doc.setFontSize(12);
    doc.setFontType("bold");
    doc.text("Agência:", 214.5, 28);

    doc.setFontType("normal");
    doc.text(""+utilizador.lojaNome, 234, 28,{maxWidth: 50});

    doc.setFontSize(12);
    doc.setFontType("bold");
    doc.text("Data:", 222, 39);

    doc.setFontType("normal");
    doc.text(""+moment(data1, 'YYYY-MM-DD').format('DD/MM/YYYY') +" a "+moment(data2, 'YYYY-MM-DD').format('DD/MM/YYYY'), 234, 39);
    

    

    doc.setFontSize(12);
    doc.setFontType("normal");
    //doc.text("SRV - Sistema de Recebimentos de Clientes ", 5, 39);

    doc.setFontSize(12);
    doc.setFontType("bold");
    doc.text("Caixa nº: #", 5, 50);
    doc.text("Operador(a):", 5, 60);
   // doc.text("Data:", 5, 70);

    doc.setFontSize(12);
    doc.setFontType("normal");
    doc.text(""+caixaNumero, 30, 50);
    doc.text(""+utilizador.OperadorNome, 33, 60);
   // doc.text(""+moment(data_venda, 'YYYY-MM-DD').format('DD/MM/YYYY'), 50, 70);

    doc.setFontSize(12);
    doc.setFontType("bold");
    doc.text("________________________________________________________________________________________________________________________", 5, 73);

    doc.setFontType("bold");
    doc.text("Produto", 53, 83);
    doc.text("Quantidade", 190, 83, 'right');
    doc.text("Valor (AOA)", 260, 83, 'right');

    doc.setFontSize(12);
    doc.setFontType("normal");
    var posY = 95
    var total = 0;
    var page = 1;
    for (var i = 0; i < produtos.length; i++) {
      doc.text("" + produtos[i].produto, 10, posY);
      doc.text("" + produtos[i].quantidade, 190, posY, 'right');

      //canto valor
      doc.text("" + this.configService.numberFormat(produtos[i].valor), 258, posY, 'right');
      posY += 8
      total += produtos[i].valor
      if(doc.internal.pageSize.height < (posY + 20)){
       
        doc.addPage();
        posY=30;
        page ++;
         }
    };

    doc.setFontSize(12);
    doc.setFontType("bold");
    doc.text("  ________________________________________________________________________________________________________________________", 5, posY);

    var CurrentDate = new Date();

    doc.setFontSize(12);
    doc.setFontType("bold");
    doc.text("Total", 204, posY + 8, 'right');

    doc.setFontSize(12);
    doc.setFontType("normal");
    doc.text("" + this.configService.numberFormat(total), 258, posY + 8, 'right');


    var today = moment().format("DD-MM-YYYY H:mm:ss");
    doc.setFontSize(12);
    doc.setFontType("normal");
    doc.text(""+today, 5, 205);
    doc.text("Página 1 de "+page, 260, 205);
    
    doc.autoPrint();
    window.open(doc.output("bloburl")); //opens the data uri in new window
  }


}
