import { Injectable } from '@angular/core';
import * as moment from 'moment';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import { ConfigService } from 'src/app/providers/config/config.service';

@Injectable({
  providedIn: 'root'
})
export class ReportIVAService {

  constructor(private configService: ConfigService) { }

  /* public relatorioIVA(relatorio: any, filter: any, p = 'print') {


    //var imgData = this.configService.logotipoBase64;

    var doc = new jsPDF('l', '', 'a4')
    doc.addImage(imgData, 'JPEG', 130, 17, 24, 20)
    var name_relatorio = filter.loja_nome

    doc.setFontType('bold')
    doc.setFontSize(15)
    doc.text(145, 60, 'Relatório', 'center')
    doc.setFontSize(15)
    doc.text(145, 70, 'Imposto de Valor Acrescentado ', 'center')

    doc.setDrawColor(150);
    doc.setFillColor(255);
    doc.setFontSize(7);
    doc.setTextColor(0);

    var posY = 0;
    doc.rect(5, 80 + posY, 40, 6, 'B'); // filled red square
    doc.rect(25, 80 + posY, 40, 6, 'B'); // filled red square
    doc.rect(60, 80 + posY, 20, 6, 'B'); // filled red square
    doc.rect(80, 80 + posY, 30, 6, 'B'); // filled red square
    doc.rect(110, 80 + posY, 60, 6, 'B'); // filled red square
    doc.rect(155, 80 + posY, 45, 6, 'B'); // filled red square
    doc.rect(200, 80 + posY, 45, 6, 'B'); // filled red square
    doc.rect(245, 80 + posY, 45, 6, 'B'); // filled red square

    doc.setFontType("bold");
    doc.text('Direcção', 15, 84 + posY, 'center');
    doc.text('Número Factura', 43, 84 + posY, 'center');
    doc.text('Data Factura', 70, 84 + posY, 'center');
    doc.text('Data Pagamento', 95, 84 + posY, 'center');
    doc.text('Tipo Documento', 135, 84 + posY, 'center');
    doc.text('Valor', 180, 84 + posY, 'center');
    doc.text('IVA', 240, 84 + posY, 'center');
    doc.text('Valor Pago', 275, 84 + posY, 'center');

    posY = 5;
    var total = 0
    var total_valor_pago = 0
    var total_iva = 0
    var page = 0
    for (var i = 0; i < relatorio.length; i++) {
      doc.setDrawColor(150);
      doc.setFillColor(255);
      doc.rect(5, 80 + posY, 40, 6, 'B'); // filled red square
      doc.rect(25, 80 + posY, 40, 6, 'B'); // filled red square
      doc.rect(60, 80 + posY, 20, 6, 'B'); // filled red square
      doc.rect(80, 80 + posY, 30, 6, 'B'); // filled red square
      doc.rect(110, 80 + posY, 60, 6, 'B'); // filled red square
      doc.rect(155, 80 + posY, 45, 6, 'B'); // filled red square
      doc.rect(200, 80 + posY, 45, 6, 'B'); // filled red square
      doc.rect(245, 80 + posY, 45, 6, 'B'); // filled red square

      doc.setFontType("normal");
      doc.text('' + relatorio[i].direccao, 15, 84 + posY, 'center');
      doc.text('' + relatorio[i].factura_sigla, 43, 84 + posY, 'center');
      doc.text('' + relatorio[i].data, 70, 84 + posY, 'center');
      doc.text('' + relatorio[i].data, 95, 84 + posY, 'center');
      doc.text('' +  relatorio[i].sigla=='FT'? '----------': relatorio[i].documento + ' -' + relatorio[i].sigla, 135, 84 + posY, 'center');
      doc.text('' + this.configService.numberFormat(relatorio[i].total), 198, 84 + posY, 'right');
      doc.text('' + this.configService.numberFormat(relatorio[i].totalComImposto), 242, 84 + posY, 'right');
      doc.text('' + (relatorio[i].pago == 1 ? this.configService.numberFormat(relatorio[i].total) : this.configService.numberFormat(relatorio[i].total - relatorio[i].valor_aberto)), 288, 84 + posY, 'right');
      total += relatorio[i].total;
      total_iva += relatorio[i].totalComImposto
      total_valor_pago += (relatorio[i].pago == 1 ? (relatorio[i].total) : (relatorio[i].total - relatorio[i].valor_aberto));
      posY += 6;

      if (page == 18 && i == 18) {
        posY += 20;
        page = 0;
      }
      if (page == 38 && i != 18) {
        posY += 20;
        page = 0;
      }

      if (doc.internal.pageSize.height < (posY + 35)) {
        posY = posY - 250;
        doc.addPage();
      }
      page++;
    }
    doc.setFontType("bold");
    doc.setDrawColor(150);
    doc.setFillColor(255);
    doc.rect(5, 80 + posY, 150, 6, 'B'); // filled red square
    doc.rect(155, 80 + posY, 45, 6, 'B'); // filled red square
    doc.rect(200, 80 + posY, 45, 6, 'B'); // filled red square
    doc.rect(245, 80 + posY, 45, 6, 'B'); // filled red square
    doc.text('Total', 150, 84 + posY, 'right');
    doc.text('' + this.configService.numberFormat(total), 198, 84 + posY, 'right');
    doc.text('' + this.configService.numberFormat(total_valor_pago), 242, 84 + posY, 'right');
    doc.text('' + this.configService.numberFormat(total_iva), 288, 84 + posY, 'right');




    if (p === 'save') {
      doc.save();
    } else {
      doc.autoPrint();
      doc.output("dataurlnewwindow");
    }


  } */
}
