import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { ReciboService } from 'src/app/components/reports/recibo/recibo.service'
import { ExcelService } from 'src/app/services/excel.service';
import * as moment from 'moment'
import { DevolucaoService } from '../../reports/devolucao.service';
@Component({
  selector: 'app-listagem-devolucao',
  templateUrl: './listagem-devolucao.component.html',
  styleUrls: ['./listagem-devolucao.component.css']
})
export class ListagemDevolucaoComponent implements OnInit {

  private search: string;

  loadingListarFacturacao = false


  private estorno = {
    recibo_id: null,
    recibo_sigla: null,
    status_reason: null,
    status_date: null,
    status: null,
    serie: null,
    serie_id: null,
    municipio_id: null
  }

  operador: any = []
  distritos: any = []
  municipios: any = []

  constructor(
    private http: HttpService,
    private configService: ConfigService,
    private PDFrecibo: ReciboService,
    private PDFdevolucao: DevolucaoService,
    private excelService: ExcelService) {



  }


  isActive: boolean
  ngOnInit() {
    this.isActive = false
    this.http.filters.search = null
    this.getPageFilterData(1);
    this.getMunicipios()
    this.getSeriesEstorno()
  }
  ngOnDestroy() {
    this.http.filters.orderBy = null
  }

  exportAsXLSX(): void {
    var CurrentDate = new Date();
    var nameFile = "Lista_Recibos-" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    this.excelService.exportAsExcelFile(document.getElementsByClassName("exportAsXLSXCliente")[0], nameFile);
  }
  exportAsPDF(): void {
    //this.reportLoja.relatorioLoja(this.cobrancas, this.simpleForm.value, 'save');
  }

  imprimirPDF(): void {
    //this.reportLoja.relatorioLoja(this.cobrancas, this.simpleForm.value);
  }

  private getMunicipios() {
    this.distritos = [];
    this.http.call_get('municipio/selectBox')
      .subscribe(
        response => {
          this.municipios = Object(response).data;
        },
      );
  }

  private clientes: any = [];
  view_client = false;
  private getCliente() {
    this.view_client = true;
    this.http.__call('cliente/search-cliente', { start: 1, end: 10, search: this.search }).subscribe(
      response => {
        this.clientes = Object(response).data.data;

        this.configService.loaddinStarter('stop');
      }
    );
  }

  private estornos: any = []
  private cliente: any = null;

  private getOperador(value: any) {
    this.http.call_get('user/perfilsRecibo/' + value.user_id + '/' + value.id, null).subscribe(
      response => {
        this.operador = Object(response).data;
        if (this.operador[0].status == 'N') {
          this.isActive = true
        } else {
          this.isActive = false
        }
      }
    );

  }

  private setCliente(client: any) {
    this.cliente = client;
    this.search = client.nome
    this.view_client = false;
    this.http.__call('recibo/consultarRecibo/' + client.id, null).subscribe(
      response => {

        this.configService.loaddinStarter('stop');
        if (Object(response).code == 500) {
          this.estornos = []
          this.configService.showAlert(Object(response).message, 'alert-danger', true);
        } else {
          this.estornos = Object(response).data;
        }
      }
    );
  }



  private listarDevolucao() {

    this.loadingListarFacturacao = true
    this.http.filters = {
      ...this.http.filters,
      municipio_id: this.estorno.municipio_id

    }
    this.http.__call('recibo/listarDevolucao', this.http.filters).subscribe(
      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;
        this.estornos = Object(response).data.data;
        this.loadingListarFacturacao = false
      }
    );
  }


  private btnImprimirDevolucao(estorno: any) {
    this.PDFdevolucao.imprimirDevolucao(estorno, '2ª Via');
  }

  close() {
    this.search = ''
    this.estornos = []
  }

  private anularRecibo(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();

    if (this.estorno.status_reason.length > 49) {
      this.configService.showAlert('O motivo da anulação só deve ter 50 caracteres no maximo.', 'alert-danger', true);
      this.configService.loaddinStarter('stop')

      return
    }

    if (this.estorno.status_reason == null) {
      this.configService.showAlert('É obrigatório fornecer o nº da recibo e o motivo', 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    } else {
      this.http.__call('recibo/anular', this.estorno).subscribe(
        res => {
          if (Object(res).code == 500 || Object(res).code == 201) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            //this.setCliente(this.cliente);
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.listarDevolucao();
            this.estorno = {
              recibo_id: null,
              recibo_sigla: null,
              status_reason: null,
              status_date: null,
              status: null,
              serie: null,
              serie_id: null,
              municipio_id: null
            }

            this.responseCode = Object(res).code
          }


          this.configService.loaddinStarter('stop');
        }
      );
    }
  }


  printer_recibo: boolean = true;
  private series: any = []
  private default: any = []

  private getSeriesEstorno() {
    this.http.call_get('serie/estorno/cliente', null)
      .subscribe(response => {
        let data = Object(response).data
        this.series = data;
      });
  }


  private responseCode: 0
  private setRecibo(recibo: any) {
    this.responseCode = 0
    this.estorno.recibo_id = recibo.id
    this.estorno.recibo_sigla = recibo.recibo_sigla
    this.estorno.serie = recibo.serie
    this.estorno.status_reason = null
    this.estorno.serie_id = null,
      console.log("this.recibo", this.estorno)
  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1;
    this.listarDevolucao();
  }

}
