import { Injectable } from '@angular/core';
import * as moment from 'moment';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import { ConfigService } from 'src/app/providers/config/config.service';

@Injectable({
  providedIn: 'root'
})
export class RoteiroService {

  constructor() { }
  public reportRoteiro(table: any, empresa, p, rota, total_rota = 0) {
    var doc = new jsPDF();

    doc.setProperties({
      title: 'Lista de Roteiros',
      subject: 'Lista de Roteiros',
      author: 'ITGEST',
      creator: 'UNIG'
    });
    doc.setFont("calibri");
    doc.setDrawColor(0);
    doc.setFillColor(255);
    doc.setFontSize(9);
    doc.rect(147, 13, 58, 15, 'B');

    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.text('Leitor:', 150, 17);
    doc.text('Total de Roteiros:', 150, 25);
    //doc.text('NIF:', 236, (cliente.nome.length > 33? 32: 30));
    doc.setFontStyle('normal')
    //doc.text('' + (rota.leitor.nome), 163, 17, { maxWidth: 33 });
    doc.text('' + (total_rota), 176, 25);
    //doc.text(''+ (cliente.numero_identificacao ==null?'':cliente.numero_identificacao),245,(cliente.nome.length>33? 32: 30), 'left');

    /*
    doc.setFontSize(10);
    doc.setFont("calibri");
    doc.setFontStyle('bold')
    doc.text('Leitor: ', 200, 20);
    doc.setFontStyle('normal')
    doc.text('' + rota.leitor.nome, 212, 20);*/

    doc.setFontSize(8);
    doc.setFont("calibri");
    doc.setTextColor(0);
    doc.text('' + empresa.companyName, 40, 10);
    doc.setTextColor(0);
    doc.text('' + empresa.addressDetail, 40, 15);
    doc.text('NIF: ' + empresa.taxRegistrationNumber, 40, 20);
    doc.text('Email: ' + empresa.email, 40, 25);
    doc.text('Telefone: ', 100, 20);
    doc.text('' + empresa.telefone, 112, 20);
    doc.text('WebSite: ', 100, 25);
    doc.setTextColor(0, 0, 255);
    doc.text('' + (empresa.site == null ? '' : empresa.site), 112, 25);
    doc.addImage(empresa.logotipo, 'JPEG', 9, 8, 28, 18);

    doc.setFontStyle('calibri')
    doc.setFontStyle('bold')
    doc.setTextColor(0)
    //doc.text('Conta Corrente', 280, 34, 'right');

    let currentpage = 0;
    var today = moment().format("DD-MM-YYYY H:mm:ss");
    doc.setFontSize(13);
    var titulo = 'Lista de Roteiros';

    const totalPagesExp = "{total_pages_count_string}";

    doc.text('' + titulo, 69, 33, 'left');
    doc.setFontSize(10);
    doc.autoTable({
      html: table,
      /*  didParseCell: function (data) {
          var rows = data.table.head;
          if (data.row.index === 0) {
            data.cell.styles.fontStyle = 'bold';
            data.cell.styles.textColor = "white";
            data.cell.styles.fillColor = [0, 153, 255];
            data.cell.styles.halign = 'center';
          }
        },
          drawHeaderRow: function(row, data) {
          if (data.pageCount >= 1) {
            return true;
          }
        },*/
      drawHeaderCell: function (cell, data) {

        data.cell.styles.fontStyle = 'bold';
        data.cell.styles.textColor = "white";
        data.cell.styles.fillColor = [0, 153, 255];
        data.cell.styles.halign = 'center';

      },
      didDrawPage: data => {
        let footerStr = "Página " + doc.internal.getNumberOfPages();
        if (currentpage < doc.internal.getNumberOfPages()) {
          doc.setFontType('calibri')
          footerStr = footerStr + " de " + totalPagesExp;
        }
        doc.setFontType('calibri')
        doc.setFontSize(8);
        //doc.text(versao,267, 200,'left')
        doc.text('' + footerStr, 189, doc.internal.pageSize.height - 3, 'left')
        doc.text(today, 5, doc.internal.pageSize.height - 3);
        currentpage = doc.internal.getNumberOfPages();
      },
      styles: { textColor: [0, 0, 0], font: "calibri", fontSize: 6 }, margin: { bottom: 5, left: 5 },
      headStyles: { fontStyle: 'bold', halign: 'center', fillColor: [0, 153, 255], textColor: "white", fontSize: 7 },
      columnStyles: {
        0: { cellWidth: 5, halign: 'center' },
        1: { cellWidth: 25, halign: 'center' },
        2: { cellWidth: 20, halign: 'left' },
        3: { cellWidth: 30, halign: 'center' },
        4: { cellWidth: 15, halign: 'center' },
        5: { cellWidth: 17, halign: 'center' },
        6: { cellWidth: 30, halign: 'center' },
        7: { cellWidth: 20, halign: 'center' },
        8: { cellWidth: 20, halign: 'center' },
      },
      rowsStyles: {},
      startY: 36,
      theme: 'grid',

    })

    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }
    if (p === 'save') {
      doc.save('Lista de Roteiros' + today + '.pdf');
    } else {
      doc.autoPrint();
      doc.output("dataurlnewwindow");
    }

  }

  public reportRotas(table: any, empresa, p, rota, total_rota = 0) {
    var doc = new jsPDF();

    doc.setProperties({
      title: 'Lista de Rotas ',
      subject: 'Lista de Rotas ',
      author: 'ITGEST',
      creator: 'UNIG'
    });
    doc.setFont("calibri");
    doc.setDrawColor(0);
    doc.setFillColor(255);
    doc.setFontSize(9);
    doc.rect(147, 13, 58, 15, 'B');

    doc.setTextColor(0);
    doc.setFontType("bold");
    //doc.text('Leitor:', 150, 17);
    doc.text('Total de Rotas:', 150, 25);
    //doc.text('NIF:', 236, (cliente.nome.length > 33? 32: 30));
    doc.setFontStyle('normal')
    //doc.text('' + (rota.leitor.nome), 163, 17, { maxWidth: 33 });
    doc.text('' + (total_rota), 176, 25);
    //doc.text(''+ (cliente.numero_identificacao ==null?'':cliente.numero_identificacao),245,(cliente.nome.length>33? 32: 30), 'left');

    /*
    doc.setFontSize(10);
    doc.setFont("calibri");
    doc.setFontStyle('bold')
    doc.text('Leitor: ', 200, 20);
    doc.setFontStyle('normal')
    doc.text('' + rota.leitor.nome, 212, 20);*/

    doc.setFontSize(8);
    doc.setFont("calibri");
    doc.setTextColor(0);
    doc.text('' + empresa.companyName, 40, 10);
    doc.setTextColor(0);
    doc.text('' + empresa.addressDetail, 40, 15);
    doc.text('NIF: ' + empresa.taxRegistrationNumber, 40, 20);
    doc.text('Email: ' + empresa.email, 40, 25);
    doc.text('Telefone: ', 100, 20);
    doc.text('' + empresa.telefone, 112, 20);
    doc.text('WebSite: ', 100, 25);
    doc.setTextColor(0, 0, 255);
    doc.text('' + (empresa.site == null ? '' : empresa.site), 112, 25);
    doc.addImage(empresa.logotipo, 'JPEG', 9, 8, 28, 18);



    doc.setFontStyle('calibri')
    doc.setFontStyle('bold')
    doc.setTextColor(0)
    //doc.text('Conta Corrente', 280, 34, 'right');

    let currentpage = 0;
    var today = moment().format("DD-MM-YYYY H:mm:ss");
    doc.setFontSize(13);
    var titulo = 'Lista de Rotas ';

    const totalPagesExp = "{total_pages_count_string}";

    doc.text('' + titulo, 69, 33, 'left');
    doc.setFontSize(10);
    doc.autoTable({
      html: table,
      /*  didParseCell: function (data) {
          var rows = data.table.head;
          if (data.row.index === 0) {
            data.cell.styles.fontStyle = 'bold';
            data.cell.styles.textColor = "white";
            data.cell.styles.fillColor = [0, 153, 255];
            data.cell.styles.halign = 'center';
          }
        },
          drawHeaderRow: function(row, data) {
          if (data.pageCount >= 1) {
            return true;
          }
        },*/
      drawHeaderCell: function (cell, data) {
        data.cell.styles.fontStyle = 'bold';
        data.cell.styles.textColor = "white";
        data.cell.styles.fillColor = [0, 153, 255];
        data.cell.styles.halign = 'center';
      },
      didDrawPage: data => {
        let footerStr = "Página " + doc.internal.getNumberOfPages();
        if (currentpage < doc.internal.getNumberOfPages()) {
          doc.setFontType('calibri')
          footerStr = footerStr + " de " + totalPagesExp;
        }
        doc.setFontType('calibri')
        doc.setFontSize(8);
        //doc.text(versao,267, 200,'left')
        doc.text('' + footerStr, 189, doc.internal.pageSize.height - 3, 'left')
        doc.text(today, 5, doc.internal.pageSize.height - 3);
        currentpage = doc.internal.getNumberOfPages();
      },
      styles: { textColor: [0, 0, 0], font: "calibri", fontSize: 6 }, margin: { bottom: 5, left: 5 },
      headStyles: { fontStyle: 'bold', halign: 'center', fillColor: [0, 153, 255], textColor: "white", fontSize: 7 },
      columnStyles: {
        0: { cellWidth: 9, halign: 'center' },
        1: { cellWidth: 22, halign: 'center' },
        2: { cellWidth: 22, halign: 'center' },
        3: { cellWidth: 15, halign: 'center' },
        4: { cellWidth: 15, halign: 'center' },
        5: { cellWidth: 25, halign: 'center' },
        6: { cellWidth: 15, halign: 'center' },
        7: { cellWidth: 22, halign: 'center' },
        8: { cellWidth: 21, halign: 'center' },
        9: { cellWidth: 25, halign: 'center' },
      },
      rowsStyles: {},
      startY: 36,
      theme: 'grid',

    })

    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }
    if (p == 'save') {
      doc.save('Lista de Rotas ' + rota + ' ' + today + '.pdf');
    } else {
      doc.autoPrint();
      doc.output("dataurlnewwindow");
    }

  }

  public reportClienteSemRotas(table: any, empresa, p, rota, total_rota = 0) {
    var doc = new jsPDF();

    doc.setProperties({
      title: 'Lista de Rotas ',
      subject: 'Lista de Rotas ',
      author: 'ITGEST',
      creator: 'UNIG'
    });
    doc.setFont("calibri");
    doc.setDrawColor(0);
    doc.setFillColor(255);
    doc.setFontSize(9);
    doc.rect(147, 13, 58, 15, 'B');

    doc.setTextColor(0);
    doc.setFontType("bold");
    //doc.text('Leitor:', 150, 17);
    doc.text('Total de Rotas:', 150, 25);
    //doc.text('NIF:', 236, (cliente.nome.length > 33? 32: 30));
    doc.setFontStyle('normal')
    //doc.text('' + (rota.leitor.nome), 163, 17, { maxWidth: 33 });
    doc.text('' + (total_rota), 176, 25);
    //doc.text(''+ (cliente.numero_identificacao ==null?'':cliente.numero_identificacao),245,(cliente.nome.length>33? 32: 30), 'left');

    /*
    doc.setFontSize(10);
    doc.setFont("calibri");
    doc.setFontStyle('bold')
    doc.text('Leitor: ', 200, 20);
    doc.setFontStyle('normal')
    doc.text('' + rota.leitor.nome, 212, 20);*/

    doc.setFontSize(8);
    doc.setFont("calibri");
    doc.setTextColor(0);
    doc.text('' + empresa.companyName, 40, 10);
    doc.setTextColor(0);
    doc.text('' + empresa.addressDetail, 40, 15);
    doc.text('NIF: ' + empresa.taxRegistrationNumber, 40, 20);
    doc.text('Email: ' + empresa.email, 40, 25);
    doc.text('Telefone: ', 100, 20);
    doc.text('' + empresa.telefone, 112, 20);
    doc.text('WebSite: ', 100, 25);
    doc.setTextColor(0, 0, 255);
    doc.text('' + (empresa.site == null ? '' : empresa.site), 112, 25);
    doc.addImage(empresa.logotipo, 'JPEG', 9, 8, 28, 18);



    doc.setFontStyle('calibri')
    doc.setFontStyle('bold')
    doc.setTextColor(0)
    //doc.text('Conta Corrente', 280, 34, 'right');

    let currentpage = 0;
    var today = moment().format("DD-MM-YYYY H:mm:ss");
    doc.setFontSize(13);
    var titulo = 'Lista de CILs Sem Rota ';

    const totalPagesExp = "{total_pages_count_string}";

    doc.text('' + titulo, 69, 33, 'left');
    doc.setFontSize(10);
    doc.autoTable({
      html: table,
      /*  didParseCell: function (data) {
          var rows = data.table.head;
          if (data.row.index === 0) {
            data.cell.styles.fontStyle = 'bold';
            data.cell.styles.textColor = "white";
            data.cell.styles.fillColor = [0, 153, 255];
            data.cell.styles.halign = 'center';
          }
        },
          drawHeaderRow: function(row, data) {
          if (data.pageCount >= 1) {
            return true;
          }
        },*/
      drawHeaderCell: function (cell, data) {
        data.cell.styles.fontStyle = 'bold';
        data.cell.styles.textColor = "white";
        data.cell.styles.fillColor = [0, 153, 255];
        data.cell.styles.halign = 'center';
      },
      didDrawPage: data => {
        let footerStr = "Página " + doc.internal.getNumberOfPages();
        if (currentpage < doc.internal.getNumberOfPages()) {
          doc.setFontType('calibri')
          footerStr = footerStr + " de " + totalPagesExp;
        }
        doc.setFontType('calibri')
        doc.setFontSize(8);
        //doc.text(versao,267, 200,'left')
        doc.text('' + footerStr, 189, doc.internal.pageSize.height - 3, 'left')
        doc.text(today, 5, doc.internal.pageSize.height - 3);
        currentpage = doc.internal.getNumberOfPages();
      },
      styles: { textColor: [0, 0, 0], font: "calibri", fontSize: 6 }, margin: { bottom: 5, left: 15 },
      headStyles: { fontStyle: 'bold', halign: 'center', fillColor: [0, 153, 255], textColor: "white", fontSize: 7 },
      columnStyles: {
        0: { cellWidth: 9, halign: 'center' },
        1: { cellWidth: 35, halign: 'center' },
        2: { cellWidth: 22, halign: 'center' },
        3: { cellWidth: 20, halign: 'center' },
        4: { cellWidth: 15, halign: 'center' },
        5: { cellWidth: 35, halign: 'center' },
      },
      rowsStyles: {},
      startY: 36,
      theme: 'grid',

    })

    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }
    if (p == 'save') {
      doc.save('Lista de cliente sem rotas ' + rota + ' ' + today + '.pdf');
    } else {
      doc.autoPrint();
      doc.output("dataurlnewwindow");
    }

  }

  public reportListagemDLeituras(table: any, empresa, p, rota, total_rota = 0) {
    var doc = new jsPDF();

    doc.setProperties({
      title: 'Listagem das Leituras',
      subject: 'Listagem das Leituras',
      author: 'ITGEST',
      creator: 'UNIG'
    });
    doc.setFont("calibri");
    doc.setDrawColor(0);
    doc.setFillColor(255);
    doc.setFontSize(9);
    doc.rect(147, 13, 58, 15, 'B');

    doc.setTextColor(0);
    doc.setFontType("bold");
    //doc.text('Leitor:', 150, 17);
    doc.text('Total de Rotas:', 150, 25);
    //doc.text('NIF:', 236, (cliente.nome.length > 33? 32: 30));
    doc.setFontStyle('normal')
    //doc.text('' + (rota.leitor.nome), 163, 17, { maxWidth: 33 });
    doc.text('' + (total_rota), 176, 25);
    //doc.text(''+ (cliente.numero_identificacao ==null?'':cliente.numero_identificacao),245,(cliente.nome.length>33? 32: 30), 'left');

    /*
    doc.setFontSize(10);
    doc.setFont("calibri");
    doc.setFontStyle('bold')
    doc.text('Leitor: ', 200, 20);
    doc.setFontStyle('normal')
    doc.text('' + rota.leitor.nome, 212, 20);*/

    doc.setFontSize(8);
    doc.setFont("calibri");
    doc.setTextColor(0);
    doc.text('' + empresa.companyName, 40, 10);
    doc.setTextColor(0);
    doc.text('' + empresa.addressDetail, 40, 15);
    doc.text('NIF: ' + empresa.taxRegistrationNumber, 40, 20);
    doc.text('Email: ' + empresa.email, 40, 25);
    doc.text('Telefone: ', 100, 20);
    doc.text('' + empresa.telefone, 112, 20);
    doc.text('WebSite: ', 100, 25);
    doc.setTextColor(0, 0, 255);
    doc.text('' + (empresa.site == null ? '' : empresa.site), 112, 25);
    doc.addImage(empresa.logotipo, 'JPEG', 9, 8, 28, 18);



    doc.setFontStyle('calibri')
    doc.setFontStyle('bold')
    doc.setTextColor(0)
    //doc.text('Conta Corrente', 280, 34, 'right');

    let currentpage = 0;
    var today = moment().format("DD-MM-YYYY H:mm:ss");
    doc.setFontSize(13);
    var titulo = 'Listagem das Leituras';

    const totalPagesExp = "{total_pages_count_string}";

    doc.text('' + titulo, 69, 33, 'left');
    doc.setFontSize(10);
    doc.autoTable({
      html: table,
      /*  didParseCell: function (data) {
          var rows = data.table.head;
          if (data.row.index === 0) {
            data.cell.styles.fontStyle = 'bold';
            data.cell.styles.textColor = "white";
            data.cell.styles.fillColor = [0, 153, 255];
            data.cell.styles.halign = 'center';
          }
        },
          drawHeaderRow: function(row, data) {
          if (data.pageCount >= 1) {
            return true;
          }
        },*/
      drawHeaderCell: function (cell, data) {
        data.cell.styles.fontStyle = 'bold';
        data.cell.styles.textColor = "white";
        data.cell.styles.fillColor = [0, 153, 255];
        data.cell.styles.halign = 'center';
      },
      didDrawPage: data => {
        let footerStr = "Página " + doc.internal.getNumberOfPages();
        if (currentpage < doc.internal.getNumberOfPages()) {
          doc.setFontType('calibri')
          footerStr = footerStr + " de " + totalPagesExp;
        }
        doc.setFontType('calibri')
        doc.setFontSize(8);
        //doc.text(versao,267, 200,'left')
        doc.text('' + footerStr, 189, doc.internal.pageSize.height - 3, 'left')
        doc.text(today, 5, doc.internal.pageSize.height - 3);
        currentpage = doc.internal.getNumberOfPages();
      },
      styles: { textColor: [0, 0, 0], font: "calibri", fontSize: 6 }, margin: { bottom: 5, left: 5 },
      headStyles: { fontStyle: 'bold', halign: 'center', fillColor: [0, 153, 255], textColor: "white", fontSize: 7 },
      columnStyles: {
        0: { cellWidth: 5, halign: 'center' },
        1: { cellWidth: 10, halign: 'center' },
        2: { cellWidth: 12, halign: 'center' },
        3: { cellWidth: 15, halign: 'center' },
        4: { cellWidth: 10, halign: 'center' },
        5: { cellWidth: 25, halign: 'center' },
        6: { cellWidth: 12, halign: 'center' },
        7: { cellWidth: 10, halign: 'center' },
        8: { cellWidth: 10, halign: 'center' },
        9: { cellWidth: 15, halign: 'center' },
        10: { cellWidth: 12, halign: 'center' },
        11: { cellWidth: 12, halign: 'center' },
        12: { cellWidth: 15, halign: 'center' },
        13: { cellWidth: 15, halign: 'center' },
        14: { cellWidth: 12, halign: 'center' },
        15: { cellWidth: 10, halign: 'center' }
      },
      rowsStyles: {},
      startY: 36,
      theme: 'grid',

    })

    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }
    if (p == 'save') {
      doc.save('Listagem das Leituras ' + rota + ' ' + today + '.pdf');
    } else {
      doc.autoPrint();
      doc.output("dataurlnewwindow");
    }

  }

  public reportHistoricoLeituras(table: any, empresa, p, rota, total_rota = 0, current_cliente: any) {
    var doc = new jsPDF();

    doc.setProperties({
      title: 'Histórico de Leituras',
      subject: 'Histórico de Leituras',
      author: 'ITGEST',
      creator: 'UNIG'
    });
    doc.setFont("calibri");
    doc.setDrawColor(0);
    doc.setFillColor(255);
    doc.setFontSize(9);
    doc.rect(147, 13, 58, 18, 'B');

    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.text('Cliente:', 150, 17);
    doc.text('Contrato:', 150, 25);
    doc.text('Total de Leituras:', 150, 30);
    doc.setFontStyle('normal')
    doc.text('' + (current_cliente.cliente), 163, 17, { maxWidth: 33 });
    doc.text('' + (current_cliente.contrato), 165, 25);
    doc.text('' + (total_rota), 176, 30, { maxWidth: 33 });

    doc.setFontSize(8);
    doc.setFont("calibri");
    doc.setTextColor(0);
    doc.text('' + empresa.companyName, 40, 10);
    doc.setTextColor(0);
    doc.text('' + empresa.addressDetail, 40, 15);
    doc.text('NIF: ' + empresa.taxRegistrationNumber, 40, 20);
    doc.text('Email: ' + empresa.email, 40, 25);
    doc.text('Telefone: ', 100, 20);
    doc.text('' + empresa.telefone, 112, 20);
    doc.text('WebSite: ', 100, 25);
    doc.setTextColor(0, 0, 255);
    doc.text('' + (empresa.site == null ? '' : empresa.site), 112, 25);
    doc.addImage(empresa.logotipo, 'JPEG', 9, 8, 28, 18);

    doc.setFontStyle('calibri')
    doc.setFontStyle('bold')
    doc.setTextColor(0)

    let currentpage = 0;
    var today = moment().format("DD-MM-YYYY H:mm:ss");
    doc.setFontSize(13);
    var titulo = 'Histórico de Leituras';

    const totalPagesExp = "{total_pages_count_string}";

    doc.text('' + titulo, 69, 33, 'left');
    doc.setFontSize(10);
    doc.autoTable({
      html: table,
      /*  didParseCell: function (data) {
          var rows = data.table.head;
          if (data.row.index === 0) {
            data.cell.styles.fontStyle = 'bold';
            data.cell.styles.textColor = "white";
            data.cell.styles.fillColor = [0, 153, 255];
            data.cell.styles.halign = 'center';
          }
        },
          drawHeaderRow: function(row, data) {
          if (data.pageCount >= 1) {
            return true;
          }
        },*/
      drawHeaderCell: function (cell, data) {
        data.cell.styles.fontStyle = 'bold';
        data.cell.styles.textColor = "white";
        data.cell.styles.fillColor = [0, 153, 255];
        data.cell.styles.halign = 'center';
      },
      didDrawPage: data => {
        let footerStr = "Página " + doc.internal.getNumberOfPages();
        if (currentpage < doc.internal.getNumberOfPages()) {
          doc.setFontType('calibri')
          footerStr = footerStr + " de " + totalPagesExp;
        }
        doc.setFontType('calibri')
        doc.setFontSize(8);
        //doc.text(versao,267, 200,'left')
        doc.text('' + footerStr, 189, doc.internal.pageSize.height - 3, 'left')
        doc.text(today, 5, doc.internal.pageSize.height - 3);
        currentpage = doc.internal.getNumberOfPages();
      },
      styles: { textColor: [0, 0, 0], font: "calibri", fontSize: 6 }, margin: { bottom: 5, left: 5 },
      headStyles: { fontStyle: 'bold', halign: 'center', fillColor: [0, 153, 255], textColor: "white", fontSize: 7 },
      columnStyles: {
        0: { cellWidth: 5, halign: 'center' },
        1: { cellWidth: 12, halign: 'center' },
        2: { cellWidth: 25, halign: 'center' },
        3: { cellWidth: 18, halign: 'center' },
        4: { cellWidth: 18, halign: 'center' },
        5: { cellWidth: 15, halign: 'center' },
        6: { cellWidth: 15, halign: 'center' },
        7: { cellWidth: 16, halign: 'center' },
        8: { cellWidth: 16, halign: 'center' },
        9: { cellWidth: 16, halign: 'center' },
        10: { cellWidth: 12, halign: 'center' },
        11: { cellWidth: 16, halign: 'center' },
        12: { cellWidth: 16, halign: 'center' },
      },
      rowsStyles: {},
      startY: 36,
      theme: 'grid',

    })

    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }
    if (p == 'save') {
      doc.save('Histórico de Leituras ' + rota + ' ' + today + '.pdf');
    } else {
      doc.autoPrint();
      doc.output("dataurlnewwindow");
    }

  }

  public reportHistoricoConsumo(table: any, empresa, p, rota, total_rota = 0, current_cliente: any) {
    var doc = new jsPDF();

    doc.setProperties({
      title: 'Histórico de Consumo',
      subject: 'Histórico de Consumo',
      author: 'ITGEST',
      creator: 'UNIG'
    });
    doc.setFont("calibri");
    doc.setDrawColor(0);
    doc.setFillColor(255);
    doc.setFontSize(9);
    doc.rect(147, 13, 58, 18, 'B');

    doc.setTextColor(0);
    doc.setFontType("bold");
    doc.text('Cliente:', 150, 17);
    doc.text('Contrato:', 150, 25);
    doc.text('Total de Consumo:', 150, 30);
    doc.setFontStyle('normal')
    doc.text('' + (current_cliente.cliente), 163, 17, { maxWidth: 33 });
    doc.text('' + (current_cliente.contrato), 165, 25);
    doc.text('' + (total_rota), 176, 30, { maxWidth: 33 });

    doc.setFontSize(8);
    doc.setFont("calibri");
    doc.setTextColor(0);
    doc.text('' + empresa.companyName, 40, 10);
    doc.setTextColor(0);
    doc.text('' + empresa.addressDetail, 40, 15);
    doc.text('NIF: ' + empresa.taxRegistrationNumber, 40, 20);
    doc.text('Email: ' + empresa.email, 40, 25);
    doc.text('Telefone: ', 100, 20);
    doc.text('' + empresa.telefone, 112, 20);
    doc.text('WebSite: ', 100, 25);
    doc.setTextColor(0, 0, 255);
    doc.text('' + (empresa.site == null ? '' : empresa.site), 112, 25);
    doc.addImage(empresa.logotipo, 'JPEG', 9, 8, 28, 18);

    doc.setFontStyle('calibri')
    doc.setFontStyle('bold')
    doc.setTextColor(0)

    let currentpage = 0;
    var today = moment().format("DD-MM-YYYY H:mm:ss");
    doc.setFontSize(13);
    var titulo = 'Histórico de Consumo';

    const totalPagesExp = "{total_pages_count_string}";

    doc.text('' + titulo, 69, 33, 'left');
    doc.setFontSize(10);
    doc.autoTable({
      html: table,
      /*  didParseCell: function (data) {
          var rows = data.table.head;
          if (data.row.index === 0) {
            data.cell.styles.fontStyle = 'bold';
            data.cell.styles.textColor = "white";
            data.cell.styles.fillColor = [0, 153, 255];
            data.cell.styles.halign = 'center';
          }
        },
          drawHeaderRow: function(row, data) {
          if (data.pageCount >= 1) {
            return true;
          }
        },*/
      drawHeaderCell: function (cell, data) {
        data.cell.styles.fontStyle = 'bold';
        data.cell.styles.textColor = "white";
        data.cell.styles.fillColor = [0, 153, 255];
        data.cell.styles.halign = 'center';
      },
      didDrawPage: data => {
        let footerStr = "Página " + doc.internal.getNumberOfPages();
        if (currentpage < doc.internal.getNumberOfPages()) {
          doc.setFontType('calibri')
          footerStr = footerStr + " de " + totalPagesExp;
        }
        doc.setFontType('calibri')
        doc.setFontSize(8);
        //doc.text(versao,267, 200,'left')
        doc.text('' + footerStr, 189, doc.internal.pageSize.height - 3, 'left')
        doc.text(today, 5, doc.internal.pageSize.height - 3);
        currentpage = doc.internal.getNumberOfPages();
      },
      styles: { textColor: [0, 0, 0], font: "calibri", fontSize: 6 }, margin: { bottom: 5, left: 5 },
      headStyles: { fontStyle: 'bold', halign: 'center', fillColor: [0, 153, 255], textColor: "white", fontSize: 7 },
      columnStyles: {
        0: { cellWidth: 30, halign: 'center' },
        1: { cellWidth: 30, halign: 'center' },
        2: { cellWidth: 30, halign: 'center' },
        3: { cellWidth: 30, halign: 'center' },
        4: { cellWidth: 30, halign: 'center' },
        5: { cellWidth: 30, halign: 'center' },
      },
      rowsStyles: {},
      startY: 36,
      theme: 'grid',

    })

    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }
    if (p == 'save') {
      doc.save('Histórico de Consumo ' + rota + ' ' + today + '.pdf');
    } else {
      doc.autoPrint();
      doc.output("dataurlnewwindow");
    }

  }
  public reportLeiturasInvalidas(table: any, empresa, p, rota, total_rota = 0) {
    var doc = new jsPDF();

    doc.setProperties({
      title: 'Lista de leituras inválidas',
      subject: 'Lista de leituras inválidas',
      author: 'ITGEST',
      creator: 'UNIG'
    });
    doc.setFont("calibri");
    doc.setDrawColor(0);
    doc.setFillColor(255);
    doc.setFontSize(9);
    doc.rect(147, 13, 58, 15, 'B');

    doc.setTextColor(0);
    doc.setFontType("bold");
    //doc.text('Leitor:', 150, 17);
    doc.text('Total de Rotas:', 150, 25);
    //doc.text('NIF:', 236, (cliente.nome.length > 33? 32: 30));
    doc.setFontStyle('normal')
    //doc.text('' + (rota.leitor.nome), 163, 17, { maxWidth: 33 });
    doc.text('' + (total_rota), 176, 25);
    //doc.text(''+ (cliente.numero_identificacao ==null?'':cliente.numero_identificacao),245,(cliente.nome.length>33? 32: 30), 'left');

    /*
    doc.setFontSize(10);
    doc.setFont("calibri");
    doc.setFontStyle('bold')
    doc.text('Leitor: ', 200, 20);
    doc.setFontStyle('normal')
    doc.text('' + rota.leitor.nome, 212, 20);*/

    doc.setFontSize(8);
    doc.setFont("calibri");
    doc.setTextColor(0);
    doc.text('' + empresa.companyName, 40, 10);
    doc.setTextColor(0);
    doc.text('' + empresa.addressDetail, 40, 15);
    doc.text('NIF: ' + empresa.taxRegistrationNumber, 40, 20);
    doc.text('Email: ' + empresa.email, 40, 25);
    doc.text('Telefone: ', 100, 20);
    doc.text('' + empresa.telefone, 112, 20);
    doc.text('WebSite: ', 100, 25);
    doc.setTextColor(0, 0, 255);
    doc.text('' + (empresa.site == null ? '' : empresa.site), 112, 25);
    doc.addImage(empresa.logotipo, 'JPEG', 9, 8, 28, 18);



    doc.setFontStyle('calibri')
    doc.setFontStyle('bold')
    doc.setTextColor(0)
    //doc.text('Conta Corrente', 280, 34, 'right');

    let currentpage = 0;
    var today = moment().format("DD-MM-YYYY H:mm:ss");
    doc.setFontSize(13);
    var titulo = 'Lista de leituras inválidas';

    const totalPagesExp = "{total_pages_count_string}";

    doc.text('' + titulo, 69, 33, 'left');
    doc.setFontSize(10);
    doc.autoTable({
      html: table,
      /*  didParseCell: function (data) {
          var rows = data.table.head;
          if (data.row.index === 0) {
            data.cell.styles.fontStyle = 'bold';
            data.cell.styles.textColor = "white";
            data.cell.styles.fillColor = [0, 153, 255];
            data.cell.styles.halign = 'center';
          }
        },
          drawHeaderRow: function(row, data) {
          if (data.pageCount >= 1) {
            return true;
          }
        },*/
      drawHeaderCell: function (cell, data) {
        data.cell.styles.fontStyle = 'bold';
        data.cell.styles.textColor = "white";
        data.cell.styles.fillColor = [0, 153, 255];
        data.cell.styles.halign = 'center';
      },
      didDrawPage: data => {
        let footerStr = "Página " + doc.internal.getNumberOfPages();
        if (currentpage < doc.internal.getNumberOfPages()) {
          doc.setFontType('calibri')
          footerStr = footerStr + " de " + totalPagesExp;
        }
        doc.setFontType('calibri')
        doc.setFontSize(8);
        //doc.text(versao,267, 200,'left')
        doc.text('' + footerStr, 189, doc.internal.pageSize.height - 3, 'left')
        doc.text(today, 5, doc.internal.pageSize.height - 3);
        currentpage = doc.internal.getNumberOfPages();
      },
      styles: { textColor: [0, 0, 0], font: "calibri", fontSize: 6 }, margin: { bottom: 5, left: 4 },
      headStyles: { fontStyle: 'bold', halign: 'center', fillColor: [0, 153, 255], textColor: "white", fontSize: 7 },
      columnStyles: {
        0: { cellWidth: 5, halign: 'center' },
        1: { cellWidth: 15, halign: 'center' },
        2: { cellWidth: 11, halign: 'center' },
        3: { cellWidth: 10, halign: 'center' },
        4: { cellWidth: 10, halign: 'center' },
        5: { cellWidth: 10, halign: 'center' },
        6: { cellWidth: 12, halign: 'center' },
        7: { cellWidth: 22, halign: 'center' },
        8: { cellWidth: 12, halign: 'center' },
        9: { cellWidth: 15, halign: 'center' },
        10: { cellWidth: 12, halign: 'center' },
        11: { cellWidth: 12, halign: 'center' },
        12: { cellWidth: 10, halign: 'center' },
        13: { cellWidth: 10, halign: 'center' },
        14: { cellWidth: 12, halign: 'center' },
        15: { cellWidth: 12, halign: 'center' },
        16: { cellWidth: 12, halign: 'center' }
      },
      rowsStyles: {},
      startY: 36,
      theme: 'grid',

    })

    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }
    if (p == 'save') {
      doc.save('Lista de leituras inválidas' + rota + ' ' + today + '.pdf');
    } else {
      doc.autoPrint();
      doc.output("dataurlnewwindow");
    }

  }

}
