import { Injectable } from '@angular/core';
import * as moment from 'moment';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import { ConfigService } from 'src/app/providers/config/config.service';

@Injectable({
  providedIn: 'root'
})
export class RelatorioIncobravelService {

  constructor(private configService: ConfigService) { }

  public relatorioPdf(file, p = 'print',imgData) {

    var today = moment().format("DD-MM-YYYY H:mm:ss");

    var doc = new jsPDF('l', '', 'A4')
        doc.setProperties({
        title: 'LISTA_DE_FACTURAÇÃO_INCOBRÁVEL'+today,
        subject: 'Report',
        author: 'Unig',
        keywords: '',
        creator: 'unig'
        });
    var versao = 'F.D.P 1.0.0';
    doc.addImage(imgData, 'JPEG',5, 10, 28, 18)
    const totalPagesExp = "{total_pages_count_string}";

        doc.setFontType('bold')
        doc.setFontSize(15)
        doc.setFontSize(15)
        doc.text(150, 25, 'LISTA DE FACTURAÇÃO INCOBRÁVEL', 'center')
        doc.setFontSize(9)
        //doc.text('AGÊNCIA:', 249, 25)

    /*      console.log(filtros)
        doc.setFontType('normal')
        filtros.loja_nome==null?doc.text('' + ('TODAS'), 267, 25):doc.text('' + (filtros.loja_nome), 267, 25);
 */
    doc.setFontType('bold')
    doc.autoTable({ html: file ,
    didParseCell: function (data) {
    var rows = data.table.body;
    if (data.row.index === 0) {
      data.cell.styles.fontStyle = 'bold';
      data.cell.styles.textColor = "white";
      data.cell.styles.fillColor = [32, 95, 190];
    }
    if (data.row.index === 0) {
      data.cell.styles.fontStyle = 'bold';
    } if (data.row.index === rows.length - 1) {
      data.cell.styles.fontStyle = 'bold';
    }
    },
    didDrawPage : data => {
      let footerStr = "Página " + doc.internal.getNumberOfPages();
      if (typeof doc.putTotalPages === 'function') {
        footerStr = footerStr + " de " + totalPagesExp;
      }
        doc.setFontType('bold')
        doc.setFontSize(10);
        doc.text(today,data.settings.margin.left, doc.internal.pageSize.height - 10)
        doc.setFontSize(10);
        doc.text(footerStr, 267, 200, 'left');
    },
    styles: { textColor: [0, 0, 0] },margin: {left:10,right:7,bottom:25},
    tableWidth: 20,

    columnStyles: {
    0: {cellWidth: 5,halign: 'center'},
    1: {cellWidth: 20,halign: 'center'},
    2: {cellWidth: 20,halign: 'center'},
    3: {cellWidth: 25,halign: 'center'},
    4: {cellWidth: 25,halign: 'center'},
    5: {cellWidth: 15,halign: 'center'},
    6: {cellWidth: 25,halign: 'center'},
    7: {cellWidth: 20,halign: 'center'},
    8: {cellWidth: 20,halign: 'center'},
    9: {cellWidth: 20,halign: 'center'},
    10: {cellWidth: 20,halign: 'center'},
    11: {cellWidth: 20,halign: 'center'},
    12: {cellWidth:20,halign: 'center'},
    13: {cellWidth:20,halign: 'center'},
    14: {cellWidth:20,halign: 'center'}},
    rowsStyles:{},
  startY: 60,
  theme: 'grid',

  })

  if (typeof doc.putTotalPages === 'function') {
    doc.putTotalPages(totalPagesExp);
  }

  if (p === 'save') {
    doc.save('LISTA_DE_FACTURAÇÃO_INCOBRÁVEL'+today+'.pdf');
  } else {
    doc.autoPrint();
    doc.output("dataurlnewwindow");
  }


  }

}
