import { Injectable } from '@angular/core';
import * as moment from 'moment';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import { ConfigService } from 'src/app/providers/config/config.service';

@Injectable({
  providedIn: 'root'
})
export class ReportVendaProdutoLojaService{

  constructor(private configService: ConfigService) { }

  public relatorioVendasLojaProduto(file, p = 'print',date,imgData) {

    var today = moment().format("DD-MM-YYYY H:mm:ss");
    // var imgData = this.configService.logotipoBase64;
    var doc = new jsPDF('l', '', 'a4')
        doc.setProperties({
        title: 'Report_Venda_produto_loja'+"-"+today,
        subject: 'Report',
        author: 'Unig',
        keywords: '',
        creator: 'Angola Telecom'
        });

    // doc.addImage(imgData, 'JPEG',8, 10, 28, 18)
    const totalPagesExp = "{total_pages_count_string}";
     
    doc.addImage(imgData, 'JPEG',10, 10, 28, 18)
        doc.setFontType('bold')
        doc.setFontSize(15)
       // doc.text(145, 60, 'Relatório', 'center')
        doc.setFontSize(15)
        doc.text(150, 25, 'Vendas por Lojas por Produtos', 'center')
        doc.setFontSize(13)
        doc.setFontSize(10)
        doc.text('Data início:', 243, 20)
        doc.text('Data fim:', 243, 25)
/*  */
        doc.setFontType('normal')
        doc.text('' + (date.data1), 264, 20);
        doc.text('' + (date.data2), 259, 25);

    doc.autoTable({ html: file ,
    didParseCell: function (data) {
    var rows = data.table.body;
    if (data.row.index === 0) {
          data.cell.styles.fontStyle = 'bold';
          data.cell.styles.textColor = "white";
          data.cell.styles.fillColor = [32, 95, 190];
    } if (data.row.index === rows.length - 1) {
      data.cell.styles.fontStyle = 'bold';
  }
},
    didDrawPage : data => {
      let footerStr = "Página " + doc.internal.getNumberOfPages();
      if (typeof doc.putTotalPages === 'function') {
        footerStr = footerStr + " de " + totalPagesExp;
      }
      doc.setFontSize(10);
      doc.text(today,data.settings.margin.left, doc.internal.pageSize.height - 10)
      doc.text(footerStr, 260, 200, 'left');
    },
    styles: { textColor: [0, 0, 0] },
    columnStyles: {
    0: {cellWidth: 19,halign: 'center'},
    1: {cellWidth: 23,halign: 'center'},
    2: {cellWidth: 23,halign: 'center'},
    3: {cellWidth: 45,halign: 'center'},
    4: {cellWidth: 25,halign: 'center'},
    5: {cellWidth: 30,halign: 'center'},
    6: {cellWidth: 50,halign: 'center'},
    7: {cellWidth: 30,halign: 'center'},
    8: {cellWidth: 30,halign: 'center'},
    9: {cellWidth: 30,halign: 'center'}
  },
    rowsStyles:{},
  startY: 60,
  theme: 'grid',

  })

  if (typeof doc.putTotalPages === 'function') {
    doc.putTotalPages(totalPagesExp);
  }

  if (p === 'save') {
    doc.save('Report_Venda_produto_loja'+'.pdf');
  } else {
    doc.autoPrint();
    doc.output("dataurlnewwindow");
  }


   }

}
