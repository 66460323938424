import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { ExcelService } from 'src/app/services/excel.service';
import * as moment from 'moment';
@Component({
  selector: 'app-tarifariio-contrato',
  templateUrl: './tarifariio-contrato.component.html',
  styleUrls: ['./tarifariio-contrato.component.css']
})
export class TarifariioContratoComponent implements OnInit {

  @ViewChild("search") search;
  @ViewChild("orderBy") orderBy;
  @ViewChild("searchData") searchData;
  @ViewChild("mostrarResultado") mostrarResultado;
  
  public tarifario: any;
  private loading: boolean = false;

  private items: any = [];
  private armazens: any = [];
  private planoprecos: any = [];
  
  constructor(
      private http: HttpService, 
      private configService: ConfigService,
      private excelService: ExcelService
  ) {

  }
  
  exportAsXLSX(): void {
      var CurrentDate = new Date();
      var nameFile = `Lista_Tarifarios-${moment(CurrentDate).format()} `
      /*
      var nameFile = `Lista_Tarifarios - 
          ${moment(CurrentDate).format('DD')} - 
          ${moment(CurrentDate).format('MM')} - 
          ${moment(CurrentDate).format('YYYY')} - 
          ${moment(CurrentDate).format('H')} - 
          ${moment(CurrentDate).format('m')}`
      */
      this.excelService.exportAsExcelFile(
          document.getElementsByClassName("exportAsXLSXCliente")[0], 
          nameFile
      )
  }
  
  exportAsPDF(): void {
  //this.reportLoja.relatorioLoja(this.cobrancas, this.simpleForm.value, 'save');
  }
  
  imprimirPDF(): void {
  //this.reportLoja.relatorioLoja(this.cobrancas, this.simpleForm.value);
  }
  

  
  ngOnInit() {
      this.getPageFilterData(1)
  }
  
  getPageFilterData(page: number) {
      if (this.http.filters.pagination.perPage == null) {
          this.http.filters.pagination.page = 1;
          return;
      }
      this.http.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
      this.listarTarifarios();
  } 
  /**
   * @name "Listar series"
   * @descriptio "Esta Função permite Listar todas series"
   * @author "caniggia.moreira@itgest.pt"
   * @param start 
   * @param end 
   */

  private listarTarifarios() {
      this.loading = true
      this.http.__call('tarifario/listagem', this.http.filters).subscribe(
          response => {
          this.http.filters.pagination.lastPage = Object(response).data.lastPage;
          this.http.filters.pagination.page = Object(response).data.page;
          this.http.filters.pagination.total = Object(response).data.total;
          this.http.filters.pagination.perPage = Object(response).data.perPage;
      
          this.items = Object(response).data.data;
          this.loading = false
          }
      );
  }
  
  private setDataTarifario(tarifario) {
      this.tarifario = tarifario
  }

}
