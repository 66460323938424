import { Component, Input, OnInit } from '@angular/core';
import { ConfigService } from "src/app/providers/config/config.service";


@Component({
  selector: 'app-ver-factura',
  templateUrl: './ver-factura.component.html',
})
export class VerFacturaComponent implements OnInit {

  @Input() facturas: any = [];
  @Input() item: any;



  constructor(
    private configService: ConfigService,
  ) { }

  ngOnInit() { }

  private imprimirFactura(id) {
    this.configService.imprimirFactura(id, "2ª Via", "imprimir")
  }

}
