import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl  } from '@angular/forms';
import { ConfigService } from 'src/app/providers/config/config.service';
import { HttpService } from 'src/app/providers/http/http.service';
import { ExcelAutoService } from 'src/app/services/excel/excel-auto.service';
import * as moment from 'moment';
import { element } from 'protractor';
import { ResumoCobrancaService } from '../../resumo-cobranca.service';

@Component({
  selector: 'app-resumo-forma-pagamento',
  templateUrl: './resumo-forma-pagamento.component.html',
  styleUrls: ['./resumo-forma-pagamento.component.css']
})
export class ResumoFormaPagamentoComponent implements OnInit {

  @Input() resumoTotal:any
  @Output() submits:EventEmitter<any>=new EventEmitter()
  private cobrancas:any[]
  private distritos:any[]
  private consumos:any[]
  private copyArray=[] 
  private payment_forms:any[]
  private _lojas:any[]
  private operadores:any[]
  private localUrl: any;
  simpleForm: FormGroup;

  constructor(private formBuilder: FormBuilder,
               private excelService: ExcelAutoService, 
               private http: HttpService,
               private configService: ConfigService, 
               private filterService:ResumoCobrancaService
              ) {
                this.createForm()
               }

  public filters = {
    search: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    pagination: {
      perPage: 5,// Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima,
      lastPage: null,
      total: null // defini o total de registo da BD
    },
    filter: null // subelemente do filter
    ,is_allexel:false
  }
  submitted = false;
  loading: boolean = false;
  tipoFacturacao:string;
  loadingLojas: string;
  private disabledButton = true

  ngOnInit() {
    this._getLojas();
    this.empresaUser()
    this.getDistritos()
    this.getArtigo()
    this.PaymentForms()
  }
  get f() {
    return this.simpleForm.controls;
  }
  matchValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const fromValue = control.value;
    if(fromValue) {
      const startDate = (<FormGroup>this.simpleForm.get('data1')).value;
      const endDate = (<FormGroup>this.simpleForm.get('data2')).value;
      if (startDate <= endDate) {
       return null;
      }
      return { 'invalidDate' : true };
    }

  }
  private _getLojas() {
    this.http.call_get('loja/selectBox',null).subscribe(
      response => {
        this._lojas = Object(response).data
        const id = this.simpleForm.getRawValue().loja;
        this._lojas.map(res=>{
          if(res.id==id){
            this.getoperadores(res.id)
          }else{
            return
          }
       })
      }
    );
  }

  private getoperadores(id) {
    this.loadingLojas = 'Carregando...';
    this.http.call_get('user/selectBox/'+id, null).subscribe(
      response => {
        this.operadores = Object(response).data
      }
    )
   
  }
  createForm() {
    this.simpleForm = this.formBuilder.group({/*
      gestor: [null, Validators.required], */
      municipio_id: null,
      distrito_id: null,
      artigo_id: null,
      municipio: null,
      cliente: [null],
      cliente_id: [null],
      data1: [null, Validators.required],
      data2: [null, [this.matchValidator.bind(this)]],
      direccao: [null],
      loja:null,
      operador:null,
      payment:null
    });
  }
  private totais = {
    totals: 0,
    semIva: 0,
    iva: 0,
    }
    private somaTotais() {
      var totals: number = 0;
      var semIva: number = 0;
      var iva: number = 0;
      for(let element of this.cobrancas){
        
         totals+= element.valor
         semIva+= element.sem_iva
         iva+= parseFloat(element.iva)
           
        
      }
      this.totais.totals = totals;
      this.totais.semIva = semIva;
      this.totais.iva = iva;
    }
    private empresaUser() {
      this.configService.loaddinStarter('start');
  
      this.http.call_get('empresa/empresa-user', null).subscribe(
        response => {
          this.localUrl = Object(response).data.logotipo
          this.configService.loaddinStarter('stop');
        }
      );
    }
    
    private getDistritos() {
      this.http.call_get(`distrito/selectBox`,null)
      .subscribe(
               response => { 
                   this.distritos = Object(response).data;
                  }
              );
         }

    private PaymentForms() {
      this.http.call_get(`formaPagamento/listar_all`,null)
      .subscribe(
               response => { 
                   this.payment_forms = Object(response);
                  }
              );
         }

    private getArtigo() {
      this.http.call_get(`produtos/listar`,null)
      .subscribe(
               response => { 
                   this.consumos = Object(response).data;
                  }
              );
         }

  setTrue(){
    this.filters.pagination.page=this.filters.pagination.page
    this.filters.is_allexel=true
    this.onSubmit()
  }

  renderData(){
    this.onSubmit()
    // this.submits.emit(this.cobrancas)
  }

  onSubmit() {
    this.submitted = true;
    if (this.simpleForm.invalid) {
      return;
    }
    const uri = 'relatorio/financeira/resumo-cobranca-forma-pagamento';

    this.simpleForm.patchValue({
      data1: this.filterService.filters.data1,
      data2: this.filterService.filters.data2,
      payment: this.filterService.filters.payment,
      distrito_id: this.filterService.filters.distrito_id,
    })

    this.createOrEdit(uri, this.simpleForm);
  }

  createOrEdit(uri: any, simpleFormulario: FormGroup) {
    this.cobrancas = []
    this.copyArray=[]
    this.loading = true;
    this.disabledButton = true;
    this.totais.totals=0
    this.totais.iva = 0;
    this.totais.semIva = 0;
    this.http.__call(uri, simpleFormulario.value).subscribe(
      response => {
        this.cobrancas = Object(response).data;
        console.log(this.cobrancas)
        this.submits.emit(this.cobrancas)
        if (this.cobrancas.length != 0) {
          this.disabledButton = false;
          this.somaTotais();
        } 
   
        this.filters.is_allexel=false
        this.loading = false;
      }
    );
  }

  exportAsXLSX():void {
    let data=[]
  for (const iterator of this.cobrancas) {
     data.push(
       {
         ...iterator,
         distrito:(iterator|| {}).distrito || '-----'
       }
     )
  }

    var CurrentDate = new Date();
    var keys= [
      { key: 'distrito', width:30, style: { font: { name: 'Calibri' } } },
      { key: 'pagamento', width: 40 },
      { key: 'valor', width: 50 ,  style: { font: { name: 'Calibri' },numFmt: '#,##0.00' }}
    ];
  
      var Cols = [
          "DISTRITO", 
        	"FOMA DE PAGAMENTO",	 
          "VALOR TOTAL",
      ]
      var title='RESUMO FORMA PAGAMENTO'
      var nameFile = "RESUMO_FORMA_PAGAMENTO_[" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
        + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')+" ]"
       this.excelService.excels(data,nameFile,this.localUrl,keys,Cols,title,5,3,30,3,[3])
    }

}
