import { NovaLigacaoService } from './../../components/nova-ligacao/registro-nova-ligacao/nova-ligacao.service';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ReportComercialService } from 'src/app/components/relatorios/report-diario/report-comercial.service';
import { ContratoService } from 'src/app/components/reports/contrato/contrato.service';
import { RescisaoService } from 'src/app/components/reports/rescisao/rescisao.service';
import { FacturaCicloService } from "src/app/components/reports/factura-ciclo/factura-ciclo.service";
import { FacturaServicoService } from "src/app/components/reports/factura-servico/factura-servico.service";
import { NotaCreditoServicoService } from "src/app/components/reports/nota-credito-servico/nota-credito-servico.service";
import { OrdemServicoReportService } from "src/app/components/ordemservico/reports/ordem-servico-report.service";
import { OcamentoService } from "src/app/components/reports/orcamento-servico/orcamento.service";
import { ReciboService } from "src/app/components/report-at/recibo/recibo.service";
import { ApiService } from 'src/app/providers/http/api.service';
var Buffer = require('buffer/').Buffer;

import * as moment from 'moment';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
// ES6 Modules or TypeScript
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  public alertEvent = new EventEmitter<Object>();
  public loaddingEvent = new EventEmitter<Object>();
  public pdfEvent = new EventEmitter<Object>();

  //Criar contrato by rescinsão
  public contrato = {
    contrato_id: null,
    cliente_id: null,
    cliente_nome: null,
    conta_id: null,
    cil_disponivel: null,
    isVisible: false
  }

  public _empty = '-------';
  public _filesUploadPath = '/src/assets/files';

  constructor(
    public configService: ConfigService,
    public http: HttpService,
    public httpApiService: ApiService,
    public reportRecibo: ReciboService,
    public RescisaoService: RescisaoService,
    public reportFactura: FacturaCicloService,
    public reportFacturaServico: FacturaServicoService,
    public reportNotaCreditoServico: NotaCreditoServicoService,
    public reportOcamentoServico: OcamentoService,
    public OrdemServicoReportService: OrdemServicoReportService,
    public novaLigacaoService: NovaLigacaoService,
    public ContratoService: ContratoService,
    public ReportComercialService: ReportComercialService) { }

  public showAlert(message: string, cls: string, show: boolean, visible: boolean = true) {
    this.alertEvent.emit({ message: message, class: cls, show: show, visible: visible });
  }

  public loaddinStarter(type: string) {
    this.loaddingEvent.emit({ type: type });
  }


  public clearFormInputs(e) {
    for (var i = 0; i < e.target.elements.length; i++) {
      e.target.elements[i].value = "";
    }
  }

  public is_factura_by_nova_ligacao: boolean = false;
  public nova_ligacao_id: any;

  public static Prop = {
    ALOCACAO: "ALOCACAO",
    VALUE: "VALUE",
    FLAG: "FLAG",
    DATE: "DATE"
  }

  empresa = {
    id: null,
    companyName: null,
    telefone: null,
    taxRegistrationNumber: null,
    city: null,
    province: null,
    addressDetail: null,
    email: null,
    active_tfa: 0,
    logotipo: null
  }

  public convertToSlug(text) {
    const a = 'àáäâãèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;'
    const b = 'aaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------'
    const p = new RegExp(a.split('').join('|'), 'g')
    return text.toString().toLowerCase().trim()
      .replace(p, c => b.charAt(a.indexOf(c))) // Replace special chars
      .replace(/&/g, '-and-') // Replace & with 'and'
      .replace(/[\s\W-]+/g, '-') // Replace spaces, non-word characters and dashes with a single dash (-)
  }

  public dataURIBase64toBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataURI.split(',')[1]);
    } else {
      byteString = unescape(dataURI.split(',')[1]);
    }
    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }

  openOrDownloadDocument(b64Data, title: string, click: boolean = true) {
    const blob = this.dataURIBase64toBlob(b64Data);
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.title = title;
    var responseType = b64Data.split(',')[0].split(':')[1].split(';')[0].split("/")[1];

    if (click) {
      link.target = "__blick";
    } else {
      link.download = title + "." + responseType;
    }
    link.click();
  }




  public gerarImprimirFacturaPDF(factura: any, produtos: any[], cliente: any, user: any, pagamento: any) {
    this.pdfEvent.emit({ factura: factura, produtos: produtos, cliente: cliente, user: user, pagamento: pagamento });

  }

  public caixas = [];
  public getCaixas() {
    this.loaddinStarter('start');
    this.http.call_get('caixa/selectBox', null).subscribe(
      response => {
        this.caixas = Object(response).data
        this.loaddinStarter('stop');
      }
    );
  }



  public documents = []
  /**
  * @name "Listar Documentos"
  * @descriptio "Esta Função permite Listar todos Documentos"
  * @author "caniggia.moreira@itgest.pt"
  * @param start
  * @param end
  */
  public listarDocumentos() {
    this.loaddinStarter('start');
    this.http.__call('documento/listar', null).subscribe(
      data => {
        this.documents = Object(data).data;
        this.loaddinStarter('stop');
      }
    );
  }


  public setPermission(permission) {
    localStorage.setItem('permission', permission)
  }

  public clientAgt: any;
  public searchClienteAGT(contribuite: any) {
    let client: any = [];
    this.http.call_get('cliente/search-cliente-agt/' + contribuite, null).subscribe(
      response => {
        this.clientAgt = Object(response).data;
      }
    );
    return this.clientAgt
  }

  async imprimirContrato(item) {
    await this.ContratoService.imprimirContrato(item)
  }

  public gerarReportDiario(id) {
    this.http.__call('get/RoleSlug/' + id, null).subscribe(
      response => {
        const dados = Object(response).data;
        if (dados.role.slug == "Financeira") {
          this.ReportComercialService.reportDiario(dados.role);
        } else if (dados.role.slug == "chefe_loja") {

        } else {
        }
      }
    );
  }

  openFactura() {


    const urlServer = 'factura/preview/'
    return this.http.__call(urlServer, null)
      .subscribe(
        (response) => {
          const b64Data = Object(response).data
          this.openOrDownloadDocument(b64Data, "Factura");

        });
  }




  public decimalFormat(number) {
    return number ? number : 0;
  }


  public numberFormat(number) {
    return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(number).replace('€', '').trim();
  }
  public getDate(date) {
    const newDate = new Date(date)
    return newDate.toLocaleDateString()
  }

  public validateContratoPatch(id) {
    /*
        const fs = require('fs')

        try {
          if (!fs.existsSync(this._filesUploadPath)) {
            fs.mkdirSync(this._filesUploadPath)
            console.log("New directory successfully created, files.")

            if (!fs.existsSync(this._filesUploadPath + '/' + id)) {
              fs.mkdirSync(this._filesUploadPath + '/' + id)
              console.log("New directory successfully created, contrato: " + id)
            }
          }

        } catch (err) {
          console.error(err)
        }

        fs.mkdir(this._filesUploadPath, function(err) {
          if (err) {
            console.log(err)
          } else {
            console.log("New directory successfully created.")
          }
        })*/

  }


  public onConection() {
    if (navigator.onLine) {

      console.log('online');
    } else {
      alert('Sem conexão com a intenet');
      console.log('offline');
    }
  }

  public imprimirAgendamento(id, via, report: string = 'imprimir') {

    this.http.call_get('ordemservico/get-report-ot-info/' + id, null).subscribe(
      response => {
        const dados = Object(response).data;
        this.OrdemServicoReportService.imprimirAgendamento(dados, via, report)
      }
    );

  }

  public imprimirAgendamentoPreventivo(id, via, report: string = 'imprimir') {

    this.http.call_get('ordemservico/get-report-ot-info/' + id, null).subscribe(
      response => {
        const dados = Object(response).data;
        this.OrdemServicoReportService.imprimirAgendamentoPreventivo(dados, via, report)
      }
    );

  }

  public imprimirOrdemServico(id, via, report: string = 'imprimir') {

    this.http.call_get('ordemservico/get-report-os/' + id, null).subscribe(
      response => {
        const dados = Object(response).data;
        console.log('dados of ordem de servico', dados)
        this.OrdemServicoReportService.imprimirOrdemServico(dados, via, report)
      }
    );

  }

  public imprimirAvisoDeCorte(dados, via, report: string = 'imprimir') {
    let configuracao_dias: any
    // this.http.__call('configuracao/getConfiguracaobySlug/'+'quantidade_dias_depois_aviso_corte').subscribe(

    this.http.__call('configuracao/getConfiguracaobySlug/' + 'quantidade_dias_depois_aviso_corte', null).subscribe(
      response => {
        configuracao_dias = Object(response).data;
        console.log(configuracao_dias.valor)
        dados['dias_aviso_corte'] = configuracao_dias.valor ? configuracao_dias.valor : 0
      }
    );
    this.http.call_get('empresa/empresa-user', null).subscribe(
      response => {
        const empresa = Object(response).data;
        dados['empresa'] = empresa
        this.OrdemServicoReportService.imprimirAvisoCorte(dados, via, report)
      }
    );


  }

  public imprimirNovaLigacao(id, via, report: string = 'imprimir') {

    this.http.call_get('nova-ligacao/get-report-nova-ligacao/' + id, null).subscribe(
      response => {
        const dados = Object(response).data;
        console.log('dados of Novas ligacoes', dados)
        this.novaLigacaoService.imprimirNovaLigacao(dados, via, report)
      }
    );

  }






  public imprimirListaTrabalho(item) {

    this.http.__call('ordemservico/get-report-lista-trabalho', {
      inicio_previsao: item.inicio_previsao,
      colaborador_id: item.colaborador_id
    }).subscribe(
      response => {
        const dados = Object(response).data;
        this.OrdemServicoReportService.imprimirListaTrabalho(dados, item)
      }
    );

  }


  public imprimirFactura(id, via, report: string = 'imprimir') {
    return this.httpApiService.get('facturas/preview/' + id, null).subscribe(
      response => {
        const dados = Object(response).data;
        if (dados.factura.tipo_factura == "CICLO" && !dados.factura.is_service) {
          if (dados.factura.serie.documento.sigla == "FT" || dados.factura.serie.documento.sigla == "FR") {
            this.reportFactura.imprimirFacturaCiclo(
              dados.factura,
              dados.produtos,
              dados.cliente,
              dados.tipos_identidades,
              dados.user,
              dados.consumo,
              dados.pagamentos,
              dados.contrato,
              dados.leituras,
              dados.contabancaria,
              dados.lojas,
              dados.conta_corrente,
              dados.historico,
              dados.mensagem_cliente,
              dados.emis_referencias,
              report,
              via)
          } else if (dados.factura.serie.documento.sigla == "NC") {

            this.reportNotaCreditoServico.imprimirNotaCreditoServico(
              dados.factura,
              dados.produtos,
              dados.cliente,
              dados.tipos_identidades,
              dados.user,
              dados.pagamentos,
              dados.contrato,
              dados.leituras,
              dados.contabancaria,
              dados.lojas,
              report,
              via
            );
          }

        }

        else if (dados.factura.tipo_factura == "SERVICO" && dados.factura.is_service) {
          if (dados.factura.serie.documento.sigla == "FT" || dados.factura.serie.documento.sigla == "FR") {
            console.log(dados)
            return this.reportFacturaServico.imprimirFacturaServico(
              dados.factura,
              dados.produtos,
              dados.cliente,
              dados.tipos_identidades,
              dados.user,
              dados.pagamentos,
              dados.contrato,
              dados.leituras,
              dados.contabancaria,
              dados.lojas,
              dados.mensagem_cliente,
              report,
              via
            )
          } else if (dados.factura.serie.documento.sigla == "NC") {

            this.reportNotaCreditoServico.imprimirNotaCreditoServico(
              dados.factura,
              dados.produtos,
              dados.cliente,
              dados.tipos_identidades,
              dados.user,
              dados.pagamentos,
              dados.contrato,
              dados.leituras,
              dados.contabancaria,
              dados.lojas,
              report,
              via
            );
          }
        }
      }
    );
  }


  public imprimirOrcamento(id, report: string, via: string = 'Original') {
    this.httpApiService.get('facturas/preview/' + id, null).subscribe(
      response => {
        const dados = Object(response).data;

        if (dados.factura.tipo_factura == "CICLO" && dados.factura.is_service == 0) {
          this.reportFactura.imprimirFacturaCiclo(
            dados.factura,
            dados.produtos,
            dados.cliente,
            dados.tipos_identidades,
            dados.user,
            dados.consumo,
            dados.pagamentos,
            dados.contrato,
            dados.leituras,
            dados.contabancaria,
            dados.lojas,
            dados.conta_corrente,
            dados.historico,
            dados.mensagem_cliente,
            dados.emis_referencias,
            report,
            via
          )

        }
        else if (dados.factura.tipo_factura == "SERVICO" && dados.factura.is_service == 1) {
          if (dados.factura.serie.documento.sigla == "OR") {
            this.reportOcamentoServico._imprimirOrcamentoServico(
              dados.factura,
              dados.produtos,
              dados.cliente,
              dados.tipos_identidades,
              dados.user,
              dados.pagamentos,
              dados.contrato,
              dados.leituras,
              dados.contabancaria,
              dados.lojas,
              report,
              via
            );
          } else if (dados.factura.serie.documento.sigla == "NC") {

            this.reportNotaCreditoServico.imprimirNotaCreditoServico(
              dados.factura,
              dados.produtos,
              dados.cliente,
              dados.tipos_identidades,
              dados.user,
              dados.pagamentos,
              dados.contrato,
              dados.leituras,
              dados.contabancaria,
              dados.lojas,
              report,
              via
            );
          }
        }
      }
    );
  }


  public swalTest: boolean = false

  public confirmar() {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false,
    })

    swalWithBootstrapButtons.fire({
      title: 'Você tem certeza?',
      text: "Você não poderá reverter isso!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, Exclua!',
      cancelButtonText: 'Não, Cancele!',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        swalWithBootstrapButtons.fire(
          'Excluído!',
          'Seu arquivo foi excluído.',
          'success'
        )
        this.swalTest = true
      } else if (
        // Read more about handling dismissals
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelado',
          'Seu arquivo foi cancelado:)',
          'error'
        )
        this.swalTest = false
      }
    })
  }

  public salvar() {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false,
    })

    swalWithBootstrapButtons.fire({
      title: 'Você tem certeza?',
      text: "Você não poderá reverter isso!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, Salvo!',
      cancelButtonText: 'Não, Cancele!',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        swalWithBootstrapButtons.fire(
          'Salvado!',
          'Seu arquivo foi salvo.',
          'success'
        )
        this.swalTest = true
      } else if (
        // Read more about handling dismissals
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelado',
          'Seu arquivo foi cancelado:)',
          'error'
        )
        this.swalTest = false
      }
    })
  }




  public bancos: any = [];
  public bancosLojas: any = [];
  /**
  * @name "Listar bancos"
  * @descriptio "Esta Função permite Listar todas bancos"
  * @author "caniggia.moreira@itgest.pt"
  * @param start
  * @param end
  */
  public listarBancos() {

    this.loaddinStarter('start');
    this.http.call_get('banco/listar', null).subscribe(
      response => {
        this.bancos = Object(response).data;
        this.loaddinStarter('stop');
      }
    );
  }

  public listarBancosById(id) {

    this.loaddinStarter('start');
    this.http.call_get('banco/listar/' + id, null).subscribe(
      response => {
        this.bancosLojas = Object(response).data;
        this.loaddinStarter('stop');
      }
    );
  }



  public empresaUser() {


    this.http.call_get('empresa/empresa-user', null).subscribe(

      response => {


        this.empresa.id = Object(response).data.id
        this.empresa.companyName = Object(response).data.companyName
        this.empresa.taxRegistrationNumber = Object(response).data.taxRegistrationNumber
        this.empresa.telefone = Object(response).data.telefone
        this.empresa.addressDetail = Object(response).data.addressDetail
        this.empresa.city = Object(response).data.city
        this.empresa.province = Object(response).data.province
        this.empresa.active_tfa = Object(response).data.active_tfa
        this.empresa.logotipo = Object(response).data.logotipo
        this.empresa.email = Object(response).data.email


      }
    );
  }

  public caixa: any = [];

  public getOpenCaixa() {

    this.loaddinStarter('start');
    this.http.call_get('caixa/open', null).subscribe(
      response => {
        this.caixa = Object(response).data;
        this.loaddinStarter('stop');
      }
    );
  }




  public moedas = []
  /**
  * @name "Listar moedas"imprimirOrcamento
  * @descriptio "Esta Função permite Listar todas moedas"
  * @author "caniggia.moreira@itgest.pt"
  * @param start
  * @param end
  */
  public listarMoedas() {

    this.loaddinStarter('start');
    this.http.call_get('moeda/listar', null).subscribe(
      response => {
        this.moedas = Object(response).data;
        this.loaddinStarter('stop');
      }
    );
  }

  /**
  * @author caniggia.moreira@gmail.com
  * Concatenate n PDFs in Buffers
  * @param {Buffer} buffers
  * @returns {Blob} - a Blob containing the concactenated PDFs
  */
  async mergePdfBuffers(buffers: any[]) {
    const PDFDocument = require('pdf-lib').PDFDocument;
    var pdfsToMerge = buffers
    const mergedPdf = await PDFDocument.create();
    for (const pdfBytes of pdfsToMerge) {
      const pdf = await PDFDocument.load(pdfBytes);
      const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
      copiedPages.forEach((page) => {
        mergedPdf.addPage(page);
      });
    }
    const pdfUrl = URL.createObjectURL(
      new Blob([await mergedPdf.save()], { type: 'application/pdf' }),
    );
    return pdfUrl;
  }

  private getPropriedadeDetalhe(historico, actualizacao) {

    var detalhe: any = [];


  }




  public validatePropValue(item) {
    return ((item != null) ? item : this._empty);
  }

  public getEstadoLabel(item) {
    return ((item) ? 'Activo' : 'Desactivo');
  }

  public getFlagLabel(item) {
    return ((item) ? 'Sim' : 'Não');
  }

  public getDateValue(item) {
    return (item == null) ? this._empty : moment(item).format('YYYY-MM-DD');
  }




  public getAlocacao(item) {

    return ((item.cil != null) ? '[' + item.cil + '] ' : '[0000-000-000] ') + ((item.is_predio) ? 'Prédio ' + item.predio_nome + ', ' + this.decimalFormat(item.predio_andar) + 'º Andar - Porta ' : 'Residência ') + this.decimalFormat(item.moradia_numero);

  }

  public getDadosPropriedade(label, item_historico, item_actualizacao) {

    let _label: string = "<div class='col-sm-4'><strong>" + label + "</strong></div>";

    if (item_historico != this._empty && item_historico != item_actualizacao) {
      return _label + "<div class='col-sm-4 text-right'><span class='badge badge-danger'>" + item_historico + "</span></div>"
        + "<div class='col-sm-4'><span class='badge badge-success'>" + item_actualizacao + "</span></div>";
    }

    return _label + "<div class='col-sm-4 text-right'>" + item_historico + "</div>"
      + "<div class='col-sm-4'>" + item_actualizacao + "</div>";
  }


  public get_any_config(slug: string) {
    this.http.call_get(`get_any_config/${slug}`, null).subscribe(
      response => {
        this.moedas = Object(response).data;
      }
    )
  }

  imprimirAgendamentoMassiva(id, via: any = 'Original', report: string = 'imprimir'){
      this.http.call_get('ordemservico/get-report-os-massiva/' + id, null).subscribe(
      response => {
        const dados = Object(response).data;
        console.log('massivas of ordem de servico', dados)
        this.OrdemServicoReportService.imprimirAgendamentoMassiva(dados, via, report)
      }
    );
  }

}
