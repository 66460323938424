import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

// ES6 Modules or TypeScript
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'

@Component({
  selector: 'app-criar-parceria',
  templateUrl: './criar-parceria.component.html',
  styleUrls: ['./criar-parceria.component.css']
})
export class CriarParceriaComponent implements OnInit {

  private clientes: any = [];

  private search: string;

  submitted = false;
  disable = false;

  loading = false;

  formParceria: FormGroup;

  view_client = false;

  view_form = false;

  private interconexao = {
    chaveServico: null,
    cliente: null,
    moeda_id: null,
    codigo: null,
    tarifario_id: null,
    estado: null
  }

  constructor(private http: HttpService, private configService: ConfigService, private formBuilder: FormBuilder) {
    this.createForm();
    this.configService.listarMoedas();
    this.selectBoxTarifarioTecnologiaInterconexao();
    this.getImposto();
  }

  ngOnInit() {
  }

  private getCliente() {
    this.onReset2();
    

    this.http.__call('cliente/search-cliente', { start: 1, end: 10, search: this.formParceria.getRawValue().nome }).subscribe(
      response => {
        this.clientes = Object(response).data.data;
        this.configService.loaddinStarter('stop');
      }
    );
  }

  private createForm() {
    this.formParceria = this.formBuilder.group({
      nome: [''],
      cliente_id: [null, Validators.required],
      tarifario_id: [null, Validators.required],
      moeda_id: [null, Validators.required],
      imposto_id: [null, Validators.required],
      chaveServico: [null, Validators.required],
      estado: [null, Validators.required],
      codigo: [null, Validators.required], 
      origem: [null, Validators.required],
      destino: [null, Validators.required],
      trunk_in: [null, Validators.required],
      trunk_out: [null, Validators.required]


    });

    this.loading = false;
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.formParceria.controls;
  }

  onReset() {

    this.submitted = false;
    this.formParceria.reset();
    this.view_form = false;
  }

  onReset2(){

    this.view_client = true;
    this.view_form = false;

    this.formParceria.get('cliente_id').reset();
    this.formParceria.get('tarifario_id').reset();
    this.formParceria.get('moeda_id').reset();
    this.formParceria.get('chaveServico').reset();
    this.formParceria.get('estado').reset();
    this.formParceria.get('codigo').reset();
    this.formParceria.get('trunk_in').reset();
    this.formParceria.get('trunk_out').reset();
    this.formParceria.get('imposto_id').reset();
    this.formParceria.get('origem').reset();
    this.formParceria.get('destino').reset();
  }


  onSubmit() {

    this.submitted = true;
    // parar aquei se o formulário for inválido
    if (this.formParceria.invalid) {
      return;
    }

    this.loading = true
    const uri = 'cliente/parceria/create';
    this.createOrEdit(uri, this.formParceria, true);

  }


  createOrEdit(uri: any, formulario: FormGroup, isCreate: boolean) {
    // TODO: usado para fazer a requisição com a api de criação de parceria
    this.http.__call(uri, formulario.value).subscribe(
      res => {
        if (Object(res).code === 200) {
          this.configService.showAlert(Object(res).message, 'alert-success', true);
          this.onReset();
        } else {
          this.configService.showAlert(Object(res).message, 'alert-danger', true);
        }
        this.submitted = false;
        this.loading = false
      });
  }

  private setCliente(client: any) {
    this.createForm();

    this.formParceria.patchValue({
      cliente_id: client.id,
      nome: client.nome,
    });
    this.view_client = false;
    this.view_form = true

  }


  private carregando = {
    tarifario: 'Seleccione o tarifario',
    tecnologia: 'Seleccione a tecnologia',
  }

  private tarifariosInterconexao: any = []

  private selectBoxTarifarioTecnologiaInterconexao() {
    this.tarifariosInterconexao = [];
    this.carregando.tarifario = 'Carregando...';
    this.http.call_get('selectBoxTarifarioTecnologiaInterconexao', null)
      .subscribe(
        response => {
          this.tarifariosInterconexao = Object(response).data;
          this.carregando.tarifario = 'Seleccione o tarifario';
        }
      );

  }


  imponstos = [
    { id: 0, descricao: null }
  ]

  /**
   * @name "get Retornar Impostos"
   * @descriptio "Esta Função permite Listar todos imposto"
   * @author "vitorino.pedro@itgest.ao.co"
   */

  private getImposto() {

    this.http.__call('imposto/getall', null).subscribe(
      data => {
        var count = Object.keys(data).length;
        for (var i = 0; i < count; i++) {
          this.imponstos[i] = data[i];
        }
      }
    );
  }

}


