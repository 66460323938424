export const environment = {
  production: false,
  apiVersion: 'v1',

  //app_url: 'http://localhost:3333/',
  //base_ws_url: 'localhost:3333',

  //===========================  EPAL QAS
  //app_url: 'http://epasbie-qas.unig-erp.com:3314/',
  //base_ws_url: 'epasbie-qas.unig-erp.com:3314',

  //================================================================================
  //================================================================================
  //================================================================================

  //=======================   EPAL PRD
  app_url: 'http://epal.itgest.co.ao:3388/',
  base_ws_url: 'epal.itgest.co.ao:3388',


};
