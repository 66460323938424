import { Component, OnInit, Input, Output, EventEmitter, SimpleChange } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-create-or-edit-mp-periodicidade-dias',
  templateUrl: './create-or-edit-mp-periodicidade-dias.component.html',
  styleUrls: ['./create-or-edit-mp-periodicidade-dias.component.css']
})
export class CreateOrEditMpPeriodicidadeDiasComponent implements OnInit {

  @Input() modal: string = "modalPeriodicidadeDiasCreateOrEdit";
  @Input() title: string = "Registar Periodicidade Dias";
  @Input() resultados: any;

  submitted = false;
  formErrors: any;
  private loading: boolean = false;

  @Input() PeriodicidadeDiasForm: FormGroup;

  @Output() private loadListunidades = new EventEmitter<any>();


  constructor(private http: HttpService, private configService: ConfigService, private formBuilder: FormBuilder) {
    this.createForm();
  }

  ngOnInit() { }

  createForm() {
    this.PeriodicidadeDiasForm = this.formBuilder.group({
      index: [{ value: null, disabled: true }],
      dias: [null, Validators.required],
    })
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.PeriodicidadeDiasForm.controls;
  }

  onReset() {
    this.submitted = false;
    this.PeriodicidadeDiasForm.reset();
  }


  onSubmit() {

    this.submitted = true;

    // parar aquei se o formulário for inválido
    if (this.PeriodicidadeDiasForm.invalid) {
      return;
    }
    this.loading = true;
    const index = this.PeriodicidadeDiasForm.getRawValue().index;
    // TODO: usado para fazer a requisição com a api de criação de objsct or update
    const uri = (index === null ? 'manutencao-preventiva/periodicidade-dias/register' : 'manutencao-preventiva/periodicidade-dias/update/' + index);
    this.createOrEdit(uri, this.PeriodicidadeDiasForm, (index === null ? true : false));

  }

  createOrEdit(uri: any, formulario: FormGroup, isCreate: boolean) {

    // TODO: usado para fazer a requisição com a api de criação de object
    this.http.__call(uri, formulario.value).pipe(first()).subscribe(
      response => {
        this.submitted = false;
        this.loading = false;
        if (isCreate && Object(response).code == 200) {
          formulario.reset();
        }

        if (Object(response).code == 200) {
          this.loadList_unidades(Object(response).data);
        }
      },
      error => {
        this.submitted = false;
        this.loading = false;
      });
  }


  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {

    if (this.resultados !== undefined) {

      this.title = "Editar Periodicidade Dias";
      //this.onReset()

      this.PeriodicidadeDiasForm.patchValue({
        index: this.resultados.id,
        dias: this.resultados.dias,
      });

    } else {
      this.onReset()
      this.title = "Registar Periodicidade Dias";

    }
  }

  public loadList_unidades(resultados) {
    this.loadListunidades.emit(resultados);
  }
}