import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ExcelService } from 'src/app/services/excel.service';
import { ReportServicoContradosService } from 'src/app/components/report-at/relatorios/financeira/report-servico-contrados.service';

import * as moment from 'moment';
@Component({
  selector: 'app-report-servicos-contradados',
  templateUrl: './report-servicos-contradados.component.html',
  styleUrls: ['./report-servicos-contradados.component.css']
})
export class ReportServicosContratadosComponent implements OnInit {


  loading: boolean = false;

  private cobrancas: any = [];

  private produtos: any = [];
  private servicos: any = [];
  private contas: any = [];

  private loadingData = {
    conta: "Conta",
    servico: "Serviço",
    produto: "Produto",
    estado: "Estado"
  }



  private disabledButton = true

  submitted = false;
  simpleForm: FormGroup;

  constructor(private formBuilder: FormBuilder, private http: HttpService, private configService: ConfigService, private excelService: ExcelService, private report: ReportServicoContradosService) {
    this.createForm();
  }

  ngOnInit() {

    this.getProdutos();
    this.getEstadosServicos();
  }

  createForm() {
    this.simpleForm = this.formBuilder.group({
      cliente: [null, Validators.required],
      cliente_id: [null, Validators.required],
      conta: [null, Validators.required],
      servico: [null, Validators.required],
      produto: [null, Validators.required],
      estado: [null, Validators.required],
      produto_nome: [null],
    });
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.simpleForm.controls;
  }
  onSubmit() {

    this.submitted = true;
    // parar aquei se o simpleFormulário for inválido
    if (this.simpleForm.invalid) {
      return;
    }
    const uri = 'relatorio/financeira/servicoContratados';
    this.createOrEdit(uri, this.simpleForm);
  }

  /**
  * @name "relatorioFacturacaoRealizadaCobrancaGlobal"
  * @descriptio "Esta Função permite gerar relatorio Facturacao Realizada Cobrança Global"
  * @author "caniggiamoreira@gmail.com"
  * @param start
  * @param end
  */
  createOrEdit(uri: any, simpleFormulario: FormGroup) {
    this.cobrancas = []
    this.loading = true;
    this.totais.total = 0;
    this.totais.valor_total_aberto = 0 
    this.disabledButton = true;
    // TODO: usado para fazer a requisição com a api de criação de organismo
    this.http.__call(uri, simpleFormulario.value).subscribe(
      response => {
        this.cobrancas = Object(response).data;
        this.loading = false;
        if (this.cobrancas.length != 0) {
          this.disabledButton = false;
          this.somaTotais();
        }
      }
    );
  }

  exportAsXLSX(): void {
    var CurrentDate = new Date();
    var nameFile = "relatorioServicoContratados-" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m') 
    this.excelService.exportAsExcelFile(document.getElementsByClassName("tableExportAsXLSX")[0], nameFile);
  }
  exportAsPDF(): void {
    //this.report.relatorioServicoContratados(this.cobrancas, this.simpleForm.value, 'save');
  }

  imprimirPDF(): void {
    //this.report.relatorioServicoContratados(this.cobrancas, this.simpleForm.value);
  }



  private getProdutos() {
    this.loadingData.produto = "Carregando..."
    this.http.call_get('artigo/selectProdutos', null).subscribe(
      response => {
        this.produtos = Object(response).data
        this.loadingData.produto = "Produto"
      }
    );
  }

  private getContas(cliente: any) {
    this.contas = []
    this.loadingData.conta = "Carregando..."
    this.http.call_get('cliente/conta/selectBox/' + cliente.id, null).subscribe(
      response => {
        this.contas = Object(response).data
        this.loadingData.conta = "Conta"
      })
  }

  private getServicosConta() { 
    this.servicos = []
    this.loadingData.servico = "Carregando..."
    this.http.call_get('tarifario/servico/selectBoxServicosConta/' + this.simpleForm.getRawValue().conta, null).subscribe(
      response => {
        this.servicos = Object(response).data 
        this.loadingData.servico = "Serviço"
      })

  }

  changeProduto() {
    this.produtos.forEach(element => {
      if (element.id == this.simpleForm.getRawValue().produto) {
        this.simpleForm.patchValue({
          produto_nome: element.nome
        });
      }
    });
  }


  private clientes: any = [];
  view_client = false;

  private getCliente() {
    this.view_client = true;
    this.http.__call('cliente/search-cliente', { start: 1, end: 10, search: this.simpleForm.getRawValue().cliente }).subscribe(
      response => {
        this.clientes = Object(response).data.data; 
      }
    );
  }

  private setCliente(client: any) {
    this.view_client = false;
    this.simpleForm.patchValue({
      cliente_id: client.id,
      cliente: client.nome
    });
    this.getContas(client)
  }


  private estadosServicos = []
  private getEstadosServicos() {
    this.loadingData.estado = "Carregando..."
    this.http.call_get('servico/estado/selectBox', null).subscribe(
      response => {
        this.estadosServicos = Object(response).data
        this.loadingData.estado = "Estado"
      }
    );

  }

  private totais = {
    total: 0,
    total_iva: 0,
    valor_total_aberto: 0
  }
  private somaTotais() {
    var valor_aberto: number = 0;
    var total: number = 0;
    var total_iva: number = 0;
    this.cobrancas.forEach(element => {
      total += element.total;
      valor_aberto += (element.pago == 1 ? (element.total) : (element.total - element.valor_aberto));
      total_iva += element.totalComImposto;
    });
    this.totais.total = total;
    this.totais.valor_total_aberto = valor_aberto
    this.totais.total_iva = total_iva;
  }



}
