import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

@Component({
  selector: 'app-numeracao-inventario-rede',
  templateUrl: './numeracao-inventario-rede.component.html',
  styleUrls: ['./numeracao-inventario-rede.component.css']
})
export class NumeracaoInventarioRedeComponent implements OnInit {


  private numeracao = {
    id: null,
    numero: null,
    tecnologia_id: null,
    filial_id: null,
    tecnologia: null,
    numeroEdit: null,
    filial: null
  }

  private phoneMask:string;
  private indicativo:string;


  private items: any = [];

  constructor(private http: HttpService, private configService: ConfigService) { }

  ngOnInit() {
    this.getPageFilterData(1);
  }


  private listaNumeracao() {

    this.configService.loaddinStarter('start');

    this.http.__call('numeracao/listagem', this.http.filters).subscribe(

      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.configService.loaddinStarter('stop');

      }
    );
  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
    this.listaNumeracao();
  }


  private register(e) {

    this.numeracao.numero = this.indicativo + '' + this.numeracao.numero;

    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (this.numeracao.tecnologia_id == null) {
      this.configService.showAlert("Seleccione a tecnologia", 'alert-danger', true);
    } else if (this.numeracao.filial_id == null) {
      this.configService.showAlert("Seleccione a Filial", 'alert-danger', true);
    } else if (this.numeracao.numero == null) {
      this.configService.showAlert("O campo número é obrigatório", 'alert-danger', true);
    } else {
      this.http.__call('numeracao/create', this.numeracao).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
            this.configService.loaddinStarter('stop');
          } else {
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.getPageFilterData(1);
            this.clearFormInputs(e);
           
            this.configService.loaddinStarter('stop');
          }
        }
      )
    }
    ;

    
   
  }


  private clearFormInputs(e) {
    e.target.elements[0].value = null;
    e.target.elements[1].value = null;
    e.target.elements[2].value = null;
  }

  private refresh(id, numero, tecnologia_id, filial_id) {

    this.numeracao.id = id;
    this.numeracao.numero = numero;
    this.numeracao.numeroEdit = numero;
    this.numeracao.tecnologia_id = tecnologia_id;
    this.numeracao.filial_id = filial_id;

  }

  private refreshDisponibilizar(id, numero, tecnologia, filial) {

    this.numeracao.id = id;
    this.numeracao.numero = numero;
    this.numeracao.tecnologia = tecnologia;
    this.numeracao.filial = filial;

  }

  private closeForm() {
    this.isFinalizar = true;
  }

  private editar(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (this.numeracao.tecnologia_id == null) {
      this.configService.showAlert("Seleccione a tecnologia", 'alert-danger', true);
    } else if (this.numeracao.filial_id == null) {
      this.configService.showAlert("Seleccione a Filial", 'alert-danger', true);
    } else if (this.numeracao.numero == null) {
      this.configService.showAlert("O campo número é obrigatório", 'alert-danger', true);
    } else {
      this.http.__call('numeracao/update/' + this.numeracao.id, this.numeracao).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            //this.configService.clearFormInputs(e);
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.listaNumeracao();

          }
        }
      );
    }
    this.configService.loaddinStarter('stop');
  }

  private isFinalizar: boolean = true;

  private disponibilizar(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();

    this.http.__call('numeracao/disponibilizar/' + this.numeracao.id, this.numeracao).subscribe(
      res => {
        if (Object(res).code == 500) {
          this.isFinalizar = true;
          this.configService.showAlert(Object(res).message, 'alert-danger', true);
        } else {
          //this.configService.clearFormInputs(e);
          this.isFinalizar = false;
          this.configService.showAlert(Object(res).message, 'alert-success', true);
          this.listaNumeracao();

        }
      }
    );

    this.configService.loaddinStarter('stop');
  }

  private carregando = {
    filial: 'Seleccione a filial',
    tecnologia: 'Seleccione a tecnologia',
  }

  private filiais: any = [];
  private tecnologias: any = [];

  private selectBox() {
    this.listarFilial();
    this.listarTecnologia();
  }

  private listarFilial() {
    this.carregando.filial = 'Carregando...';
    this.http.call_get('filial/selectBox', null).subscribe(
      response => {
        this.filiais = Object(response).data;
        this.carregando.filial = 'Seleccione a Filial';
      }
    );
  }

  private listarTecnologia() {
    this.carregando.tecnologia = 'Carregando...';
    this.http.call_get('tecnologia/numeracao/selectBox', null).subscribe(
      response => {
        this.tecnologias = Object(response).data;
        this.carregando.tecnologia = 'Seleccione a tecnologia';
      }
    );
  }

  private getPhoneIndicativo(){
    this.indicativo = null;
    this.numeracao.numero = null;
    this.configService.loaddinStarter('start');
    this.http._get('filial/indicativo/phone/' + this.numeracao.filial_id).subscribe(
      response => {

          this.indicativo = Object(response).data.indicativo_telefone;
          this.configService.loaddinStarter('stop');
      }
    );
  }

  private getPhoneMask() {
      
      if(this.indicativo != null){
        this.indicativo;
        this.phoneMask = '000000';
      }
      this.phoneMask = '000000000';
      this.indicativo = "";
      
  }

  private ini() {
    this.numeracao = {
      id: null,
      numero: null,
      tecnologia_id: null,
      filial_id: null,
      tecnologia: null,
      numeroEdit: null,
      filial: null
    }
  }


}
