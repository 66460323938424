import { Component, OnInit, Input } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { PedidosComponent } from 'src/app/components/pedidos/pedidos/pedidos.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reject-form',
  templateUrl: './reject-form.component.html',
  styleUrls: ['./reject-form.component.css']
})
export class RejectFormComponent implements OnInit {
  

  @Input() cliente: any;
  @Input() pedidoObject:any;

 
  private interest = {
    id: null
  }

  private pedido = {
    id: null,
    contador: null,
    contador_id: null,
    local_instalacao: null,
    observacao: null,
    estado_pedido_sigla: null

  }

  private conta = {
    id: null
  }

  private isFinalizar: boolean = true; 
  
  constructor(private http: HttpService, private configService: ConfigService, private router: Router, private PedidosComponent: PedidosComponent) { 

  }

  ngOnInit() {
    this.getPageFilterData(1);
  }


  private clearForm() {
    this.isFinalizar = true;
    
  }

  private showSubmit() {
    this.isFinalizar = true;
  }

  private hideBotton(){
    this.PedidosComponent.hideBottonAvancar();
  }


  
  private finalizarPedido() {
    //console.log(this.ServicoObject)
    this.pedido.id = this.pedidoObject.pedido_id;
    this.pedido.local_instalacao = this.pedidoObject.residencia_id;
    this.conta.id = this.pedidoObject.conta_id;
    this.pedido.estado_pedido_sigla = this.pedidoObject.estado_pedido_sigla;

    if (this.pedido.observacao == null || this.pedido.observacao =="") {
      this.configService.showAlert('Escreve uma observação', "alert-danger", true);
    } else {

      this.isFinalizar = false;

      this.configService.loaddinStarter('start');
      this.http.__call('pedido/anular', {
        observacao: this.pedido.observacao,
        estado_pedido_sigla: this.pedido.estado_pedido_sigla,
        pedido_id: this.pedido.id
      }).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
            this.isFinalizar = true;
          } else {
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.configService.loaddinStarter('stop');
            this.pedido.id = null;
            this.pedido.observacao = null;
            this.PedidosComponent.getPageFilterData(1);

          }
        }
      );
    }


  }
  



  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
  }

  


}
