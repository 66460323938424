import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

import { ExcelService } from 'src/app/services/excel.service';
import * as moment from 'moment';

@Component({
  selector: 'app-estado-pedidos',
  templateUrl: './estado-pedidos.component.html',
  styleUrls: ['./estado-pedidos.component.css']
})
export class EstadoPedidosComponent implements OnInit {

  private loading: boolean = false;

  private estado = {
    id: null,
    designacao: null,
    sigla: null,
/*     tipo_pedido_id: null, */
    rota_form: null
  }


  private items: any = [];
  private tipoPedidos: any = [];

  constructor(private http: HttpService, private configService: ConfigService,private excelService: ExcelService) { }

  ngOnInit() {
    this.http.filters.search = null
    this.getPageFilterData(1);
  }

  exportAsXLSX(): void {
    var CurrentDate = new Date();
    var nameFile = "Lista_estado_Pedidos-" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    this.excelService.exportAsExcelFile(document.getElementsByClassName("exportAsXLSXCliente")[0], nameFile);
  }
  exportAsPDF(): void {
    //this.reportLoja.relatorioLoja(this.cobrancas, this.simpleForm.value, 'save');
  }

  imprimirPDF(): void {
    //this.reportLoja.relatorioLoja(this.cobrancas, this.simpleForm.value);
  }

  private estadosPedido() {
    this.loading = true
    this.configService.loaddinStarter('start');

    this.http.__call('estado/pedidos/listagem', this.http.filters).subscribe(

      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.loading = false
        this.configService.loaddinStarter('stop');

      }
    );
  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page =Number.isInteger(page) ? page : 1 ;
    this.estadosPedido();
  }


  private register(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (this.estado.designacao == "" || this.estado.designacao == null) {
      this.configService.showAlert("O campo designacao é obrigatorio", 'alert-danger', true);
    } else {
    this.http.__call('estado/pedidos/create', this.estado).subscribe(
      res => {
        if (Object(res).code == 500) {
          this.configService.showAlert(Object(res).message, 'alert-danger', true);
          this.configService.loaddinStarter('stop');
        } else {
          this.configService.showAlert(Object(res).message, 'alert-success', true);
          this.clearFormInputs(e);
          this.estadosPedido()
          this.configService.loaddinStarter('stop');
        }
      }
    )
  }
      ;
  }


  private clearFormInputs(e) {
    e.target.elements[0].value = null;
    e.target.elements[1].value = null;
    e.target.elements[2].value = null;
    e.target.elements[3].value = null;
  }

  private refresh(id, designacao, sigla, rota_form, tipo_pedido_id) {

    this.estado.id = id;
    this.estado.designacao = designacao;
    this.estado.sigla = sigla;
    this.estado.rota_form = rota_form;
   /* this.estado.tipo_pedido_id = tipo_pedido_id; */


  }

  private listarTipoPedidos() {
    this.configService.loaddinStarter('start');
    this.http.call_get('tipoPedidos/selectBox', null).subscribe(
      response => {
        this.tipoPedidos = Object(response).data;
      }
    );
    this.configService.loaddinStarter('stop');
  }

  private editar(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (this.estado.designacao == "" || this.estado.designacao == null) {
      this.configService.showAlert("O campo designação é obrigatorio", 'alert-danger', true);
    } else {
      this.http.__call('estado/pedidos/update/' + this.estado.id, this.estado).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            //this.configService.clearFormInputs(e);
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.estadosPedido();

          }
        }
      );
    }
    this.configService.loaddinStarter('stop');
  }


  private ini() {
    this.estado = {
      id: null,
      designacao: null,
      sigla: null,
/*       tipo_pedido_id: null, */
      rota_form: null
    }
  }







}
