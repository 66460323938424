import { Component, OnInit } from "@angular/core";
import { HttpService } from "src/app/providers/http/http.service";
import { ConfigService } from "src/app/providers/config/config.service";

import { Router, ActivatedRoute } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
} from "@angular/forms";

import { ExcelService } from "src/app/services/excel.service";
import { ReportResumoContaCorrenteService } from "src/app/components/report-at/relatorios/financeira/report-resumo-conta-corrente.service";

import * as moment from "moment";
import { ExcelAutoService } from "src/app/services/excel/excel-auto.service";
import { finalize } from "rxjs/operators";
import { ApiService } from "src/app/providers/http/api.service";
import { FilterService } from "./filter.service";
import { OrganizeInNewArrayService } from "./organize-in-new-array.service";
import { toDate, format } from "date-fns-tz";
import { json } from "@rxweb/reactive-form-validators";
import { ImportDataService } from "src/app/services/ExportExcel/exportAllData.service";

export class Filter {
  municipio_id:string=null 
  distrito_id:string=null 
  bairro_id:string=null  
  loja:string=null  
  operador:string=null  
  forma_pagamento:string=null  
  constructor() {}
}
@Component({
  selector: "app-report-forma-pagamento",
  templateUrl: "./report-forma-pagamento.component.html",
  styleUrls: ["./report-forma-pagamento.component.css"],
})
export class ReportFormaPagamentoComponent implements OnInit {
  public titulo = "Facturação Detalhada ";
  loading: boolean = false;

   filterFild:Filter=new Filter
    checkRecibo:any={}
    numberOfItem:number=0
    
    checkReciboExcel:any={}
    numberOfItemExcel:number=0

  private meses = [
    { nome: "Janeiro", numero: "01" },
    { nome: "Fevereiro", numero: "02" },
    { nome: "Março", numero: "03" },
    { nome: "Abril", numero: "04" },
    { nome: "Maio", numero: "05" },
    { nome: "Junho", numero: "06" },
    { nome: "Julho", numero: "07" },
    { nome: "Agosto", numero: "08" },
    { nome: "Setembro", numero: "09" },
    { nome: "Outubro", numero: "10" },
    { nome: "Novembro", numero: "11" },
    { nome: "Dezembro", numero: "12" },
  ];
  private cobrancas: any = [];
  private _cobrancas: any = [];
  private lojas: any = [];
  private filials: any = [];
  private provincias: any = [];
  private servicos: any = [];
  private anos: any = [];
  private direccaos: any = [];
  private gestores: any = [];

  private _cobrancaCopy1 = [];
  private _cobrancaCopy2 = [];
  private disabledButton = true;

  submitted = false;
  simpleForm: FormGroup;
  tipoFacturacao: string;
  loadingLojas: string;
  private filtros = {
    servico: null,
    servico_id: null,
    gestor: null,
    ano: null,
    mes: null,
    tipoFacturacao: null,
    mes_nome: null,
    cliente: null,
    cliente_id: null,
    direccao: null,
    moeda_id: null,
    moeda: null,
    data1: null,
    data: null,
    loja: null,
    operador: null,
  };

  public filters = {
    search: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    pagination: {
      perPage: 5, // Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima,
      lastPage: null,
      total: null, // defini o total de registo da BD
    },
    filter: null, // subelemente do filter
    is_allexel: false,
  };

  municipios: any = [];
  _lojas: any = [];
  operadores: any = [];
  payments: any = [];
  distritos: any = [];
  bairros: any = [];

  private loadings = {
    municipios: false,
    distritos: false,
    bairros: false,
  };
  private localUrl: any;
  private largura: any;
  private altura: any;

  constructor(
    private formBuilder: FormBuilder,
    private http: HttpService,
    private route: ActivatedRoute,
    private configService: ConfigService,
    private excelService: ExcelAutoService,
    private RelResumoContaCorrrente: ReportResumoContaCorrenteService,
    private api: ApiService,
    private filterService: FilterService,
    private organizeInNewArrayService: OrganizeInNewArrayService,
    private getAllData: ImportDataService,
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.getMunicipios();
    this.getProvincias();
    this.getLojas();
    this._getLojas();
    this.typeOfPayment();
    this.gerarAno();
    this.empresaUser();
    this.direccaosSelectBox();
    /*   this.getGestores() */
  }

  public filter = new Filter();

  createForm() {
    this.simpleForm = this.formBuilder.group({
      /*
      gestor: [null, Validators.required], */
      municipio_id: null,
      distrito_id: null,
      bairro_id: null,
      municipio: null,
      cliente: [null],
      cliente_id: [null],
      data1: [null, Validators.required],
      data2: [null, [this.matchValidator.bind(this)]],
      direccao: [null],
      loja: null,
      operador: null,
      forma_pagamento: null,
    });
  }


  getAllDatas() {
  
    this.filters.filter = this.filter
    let title = "Forma Pagamento"
      this.getAllData.getData('relatorio/financeira/formaPagamento',
      title,  
      this.simpleForm.value, 
      {},
      (data: any)=>this.exportAsXLSX(data))
  
   }

  private _getLojas() {
    this.http.call_get("loja/selectBox", null).subscribe((response) => {
      this._lojas = Object(response).data;
      const id = this.simpleForm.getRawValue().loja;
      this._lojas.map((res) => {
        if (res.id == id) { 
          this.getoperadores(res.id);
        } else {
          return;
        }
      });
    });
  }
  private typeOfPayment() {
    this.http
      .call_get("formaPagamento/listar_all", null)
      .subscribe((response) => {
        this.payments = Object(response);
      });
  }
  private getoperadores(id) {
    this.loadingLojas = "Carregando...";
    this.http.call_get("user/selectBox/" + id, null).subscribe((response) => {
      this.operadores = Object(response).data;
    });
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.simpleForm.controls;
  }
  matchValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const fromValue = control.value;
    if (fromValue) {
      // console.log(this.simpleForm.controls)
      const startDate = (<FormGroup>this.simpleForm.get("data1")).value;
      const endDate = (<FormGroup>this.simpleForm.get("data2")).value;
      if (startDate <= endDate) {
        //console.log('Control: ', control);
        return null;
      }
      //console.log('Control: ', control);
      return { invalidDate: true };
    }
  }
  private empresaUser() {
    this.configService.loaddinStarter("start");

    this.http.call_get("empresa/empresa-user", null).subscribe((response) => {
      this.localUrl = Object(response).data.logotipo;
      this.altura = Object(response).data.width;
      this.largura = Object(response).data.height;
      this.configService.loaddinStarter("stop");
    });
  }

  private getMunicipios() {
    this.distritos = [];
    this.loadings.municipios = true;
    this.api
      .get("municipio/selectBox")
      .pipe(
        finalize(() => {
          this.loadings.municipios = false;
        })
      )
      .subscribe(
        (response) => {
          this.municipios = Object(response).data;
        },
        (error) => {
          this.loadings.municipios = false;
        }
      );
  }

  private getDistritos(id) {
    this.bairros = [];
    this.loadings.distritos = true;
    this.filter.distrito_id = "";
    this.filter.bairro_id = "";
    this.api
      .get(`distrito/selectBoxDistritosByMunicipio/${id}`)
      .pipe(
        finalize(() => {
          this.loadings.distritos = false;
        })
      )
      .subscribe(
        (response) => {
          this.distritos = Object(response).data;
        },
        (error) => {
          this.loadings.distritos = false;
        }
      );
  }
  private getBairrosByDistritos(id) {
    this.filter.bairro_id = "";
    this.loadings.bairros = true;
    if (this.filter.distrito_id == "") {
      this.filter.distrito_id = null;
    }
    this.api
      .get(`bairro/getBairrosByDistrito/${id}`)
      .pipe(
        finalize(() => {
          this.loadings.bairros = false;
        })
      )
      .subscribe(
        (response) => {
          this.bairros = Object(response).data;
        },
        (error) => {
          this.loadings.bairros = false;
        }
      );
  }

  setTrue() {
    this.filters.pagination.page = this.filters.pagination.page;
    this.filters.is_allexel = true;
    //this.exportAsXLSX(this._cobrancaCopy1);
  }
  timeOutput: any = null;
  getTimeZone(date) {
    if (date == null) return;
    const parisDate = toDate(date);
    const pattern = "dd/MM/yyyy";
    this.timeOutput = format(parisDate, pattern, { timeZone: "UTC/GMT+1" });
    return this.timeOutput;
  }

  getAllItemOfLinhaPagamento(recibo:string):number{
       
       if(!this.checkRecibo[recibo]){
           this.numberOfItem=0
           this.checkRecibo[recibo]=recibo
           return this.numberOfItem
       }else{
           this.numberOfItem++
       }
      
        return this.numberOfItem

  }

  onSubmit() {
    this.submitted = true;
    // parar aquei se o simpleFormulário for inválido
    if (this.simpleForm.invalid) {
      return;
    }
         this.simpleForm.patchValue({
          municipio_id:this.filterFild.municipio_id,
          distrito_id:this.filterFild.distrito_id,
          bairro_id:this.filterFild.bairro_id,
          loja:this.filterFild.loja,
          operador:this.filterFild.operador,
          forma_pagamento:this.filterFild.forma_pagamento,
      }) 
    const uri = "relatorio/financeira/formaPagamento";
    this.createOrEdit(uri, this.simpleForm);
  }

  createOrEdit(uri: any, simpleFormulario: FormGroup) {
    this.cobrancas = [];
    this._cobrancaCopy1 = [];
    this.loading = true;
    this.disabledButton = true;
    this.totais.totals = 0;
    this.totais.valor_recebido=0
    this.checkRecibo={}
    this.numberOfItem=0
    this.http.__call(uri, simpleFormulario.value).subscribe((response) => {
      this.cobrancas = Object(response).data;
      this.loading = false;
      console.log(this.cobrancas)
 
      if (this.cobrancas.length != 0) {
        this.disabledButton = false;
        this.somaTotais();
      }
      this.simpleForm.patchValue({
        municipio_id:null,
        distrito_id:null,
        bairro_id:null,
        loja:null,
        operador:null,
        forma_pagamento:null,
    }) 
      if (this.filters.is_allexel == false) {
        if (this._cobrancaCopy1.length != 0) this.somaTotais();
      } else {
        this.cobrancas = Object(response).data;
        /* this.exportAsXLSX(this.cobrancas); */
      }
      this.filters.is_allexel = false;
      this.loading = false;
    });
  }

  getAllItemOfLinhaPagamentoExcel(recibo:string):number{
  
    if(!this.checkReciboExcel[recibo]){
        this.numberOfItemExcel=0
        this.checkReciboExcel[recibo]=recibo
        return this.numberOfItemExcel
    }else{
        this.numberOfItemExcel++
    }
   
     return this.numberOfItemExcel

}

  exportAsXLSX(items:any): void {
     console.log(items)
    const newArray = items ? JSON.parse(JSON.stringify(items)) : 
                           JSON.parse(JSON.stringify(this.cobrancas))
    let data = newArray.map((res:any) => {

   
      return {
        id: res.id,
        nome: (res.cliente || {}).nome,
        user: res.user.nome,
        loja: res.caixas.loja.nome,
        morada: (res.cliente || {}).morada,
        recibo_sigla: res.recibo_sigla,
        data: this.getTimeZone(res.created),
        status_date:this.getTimeZone(res.status_date?res.status_date:null),
        forma_paga: res.descricao,

        municipio: res.linhasRecibos[0]
                ?res.linhasRecibos[0].municipio: null,

        distrito: res.linhasRecibos[0] 
                ?res.linhasRecibos[0].distrito: null,
                
        bairro: res.linhasRecibos[0]
                ?res.linhasRecibos[0].bairro: null,
          
        banco:res.banco,
        
        referencia: res.referencia,
        
        res_linha_paga: res.linhaPagamentos[0]
          ? res.linhaPagamentos[0].res_pagamento: null,

        statu: res.statu,
        total: res.total,
        data_linha_paga: this.getTimeZone(
          res.linhaPagamentos[0] ? res.linhaPagamentos[0].created_at : null
        ),
        valor_saldado: res.valor_recebido
      };
    });

    var CurrentDate = new Date();
    var keys = [
      { key: "nome", width: 40 },
      { key: "user", width: 40 },
      { key: "loja", width: 40 },
      { key: "morada", width: 30 },
      { key: "recibo_sigla", width: 50 },
      { key: "data", width: 50 },
      { key: "status_date", width: 50 },
      { key: "forma_paga", width: 30 },
      { key: "banco", width: 30 },
      { key: "referencia", width: 15 },
      {
        key: "data_linha_paga",
        width: 30,
        style: { font: { name: "Calibri" }, numFmt: "#,##0.00" },
      },
      { key: "municipio", width: 50 },
      { key: "distrito", width: 50 },
      { key: "bairro", width: 50 },
      { key: "statu", width: 20 },
      { key: "total", width: 20, style: { font: { name: "Calibri" } } },
      { key: "valor_saldado", width: 20, style: { font: { name: "Calibri" } } },
    ];

    var Cols = [
      "Nome Cliente",
      "Operador",
      "Agência",
      "Morada Cliente",
      "Documento",
      "Data Recibo",
      "Data Anulação Recibo",
      "Forma Pagamento",
      "Banco",
      "Referência",
      "Data Pagamento",
      "Município",
      "Distrito",
      "Bairro",
      "Estado",
      "VALOR TOTAL RECIBO",
      "VALOR RECEBIDO",
    ];
    var title = "Forma Pagamento";
    var nameFile =
      "forma_pagamento-" +
      moment(CurrentDate).format("DD") +
      "-" +
      moment(CurrentDate).format("MM") +
      "-" +
      moment(CurrentDate).format("YYYY") +
      " " +
      moment(CurrentDate).format("H") +
      ":" +
      moment(CurrentDate).format("m");
    this.excelService.excels(
      data,
      nameFile,
      this.localUrl,
      keys,
      Cols,
      title,
      5,
      17,
      30,
      3
    );
  }

  exportAsPDF(): void {
    var file = document.getElementsByClassName("tableCobrancaPDF")[0];
    this.RelResumoContaCorrrente.relatorioFomaPgamento(
      "save",
      file,
      this.localUrl,
      this.simpleForm.value
    );
  }

  imprimirPDF(): void {
    var file = document.getElementsByClassName("tableCobrancaPDF")[0];
    this.RelResumoContaCorrrente.relatorioFomaPgamento(
      "imprimir",
      file,
      this.localUrl,
      this.simpleForm.value
    );
  }

  private getLojas() {
    this.loadingLojas = "Carregando...";
    const id = this.simpleForm.getRawValue().filial;
    if (id != "" || id != null || id != "T") {
      this.http
        .call_get("lojas/selectBox/" + id, null)
        .subscribe((response) => {
          this.lojas = Object(response);
          this.loadingLojas = "Loja";
        });
    }
  }

  changeLoja() {
    this.simpleForm.patchValue({
      loja_nome: null,
    });
    this.lojas.forEach((element) => {
      if (element.id == this.simpleForm.getRawValue().loja) {
        this.simpleForm.patchValue({
          loja_nome: element.nome,
        });
      }
    });
  }

  private gerarAno() {
    var fecha = new Date();
    var anyo = fecha.getFullYear();

    let j = 0;
    for (let i = anyo; i >= 2000; i--) {
      this.anos[j] = i;
      j++;
    }
  }

  private getProvincias() {
    this.http.call_get("provincia/selectBox", null).subscribe((response) => {
      this.provincias = Object(response).data;
    });
  }

  private direccaosSelectBox() {
    this.http.call_get("direccao/selectBox", null).subscribe((response) => {
      this.direccaos = Object(response);
    });
  }

  private clientes: any = [];
  view_client = false;

  private getCliente() {
    this.view_client = true;
    this.http
      .__call("cliente/search-cliente", {
        start: 1,
        end: 10,
        search: this.simpleForm.getRawValue().cliente,
      })
      .subscribe((response) => {
        this.clientes = Object(response).data.data;
      });
  }

  private setCliente(client: any) {
    this.view_client = false;
    this.simpleForm.patchValue({
      cliente_id: client.id,
      cliente: client.nome,
    });
    /*
    this.getFilials(client.municipio_id) */
  }

  private totais = {
    totals: 0,
    valor_recebido:0
  };
  private somaTotais() {
    var totals: number = 0;
    var valor_recebido: number = 0;
    this.cobrancas.forEach((element) => {
      totals += element.total || 0
      valor_recebido +=  element.valor_recebido || 0
      
    });
    this.totais.totals = totals;
    this.totais.valor_recebido = valor_recebido;
  }
}
