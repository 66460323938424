
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { ConfigService } from 'src/app/providers/config/config.service';

import { ExcelService } from 'src/app/services/excel.service';
import * as moment from 'moment';

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.css']
})
export class RoleComponent implements OnInit, OnDestroy {

  constructor(private http: HttpService, private configService: ConfigService,private auth: AuthService,private excelService: ExcelService) { }
  private loading: boolean = false;

  @ViewChild("search") search;
  @ViewChild("orderBy") orderBy;
  @ViewChild("searchData") searchData;
  @ViewChild("mostrarResultado") mostrarResultado;
  @ViewChild("orderAscOrDesc") orderAscOrDesc;
  private role = {
    id: null,
    name: null,
    description: null,
    slug:null
  }
  private items: any = [];
  private modulos: any = [];
  private permissions: any = [];

  private pagination = {
    total: null,
    perPage: 5,
    page: 1,
    lastPage: null,

    start: 1,
    end: 10,
    searchs: null,
    search: null,
    order: "created_at"
  };

  ngOnInit() {

    this.listarroles(this.pagination.page, this.pagination.perPage,
      this.search.nativeElement.value,this.pagination.searchs, this.orderBy.nativeElement.value);

  }
  getPageFilterData(page: number) {
    this.rolePermissions(null)
  }
  ngOnDestroy() {
    this.http.filters.orderBy = null
    this.pagination.searchs = null
  }

  exportAsXLSX(): void {
    var CurrentDate = new Date();
    var nameFile = "Lista_Roles-" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    this.excelService.exportAsExcelFile(document.getElementsByClassName("exportAsXLSXCliente")[0], nameFile);
  }
  exportAsPDF(): void {
    //this.reportLoja.relatorioLoja(this.cobrancas, this.simpleForm.value, 'save');
  }

  imprimirPDF(): void {
    //this.reportLoja.relatorioLoja(this.cobrancas, this.simpleForm.value);
  }

  private listarroles(start, end, search, searchs, orderBy) {
    this.loading = true
    this.items =[]
    console.log('get ini')
    this.configService.loaddinStarter('start');
    this.pages = [];
    this.pagination.start = start;
    this.pagination.end = end;
    this.pagination.search = search;
    this.pagination.searchs = searchs;
    this.pagination.order = orderBy;

    this.http.__call('role/listar', this.pagination).subscribe(

      response => {
        this.pagination.lastPage = Object(response).data.lastPage;
        this.pagination.page = Object(response).data.page;
        this.pagination.total = Object(response).data.total;
        this.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.gerarPages();
        this.configService.loaddinStarter('stop');
        this.loading = false

      }
    );
  }

  private mostrarResultados() {
    this.pagination.perPage = this.mostrarResultado.nativeElement.value;
    this.listarroles(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value,this.pagination.searchs, this.orderBy.nativeElement.value);

  }

  private pages = [];
  private nextProvPageNumber = 1;

  private gerarPages() {
    for (var i = 1; i <= this.pagination.lastPage; i++) {
      this.pages.push(i);
    }
  }
  private nextPage() {
    this.nextProvPageNumber++;
    if (this.nextProvPageNumber < 0) {
      this.nextProvPageNumber = this.pagination.page;
    }
    this.listarroles(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value,this.pagination.searchs, this.orderBy.nativeElement.value);

  }
  private prevPage() {
    this.nextProvPageNumber--
    if (this.nextProvPageNumber > 0) {
      this.listarroles(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value,this.pagination.searchs, this.orderBy.nativeElement.value);
      this.nextProvPageNumber = this.pagination.page;
    }
  }

  private refreshPaginate(page) {
    this.pagination.page = page;
    this.listarroles(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value,this.pagination.searchs, this.orderBy.nativeElement.value);
  }

  private pesquisar(e) {
    this.pagination.page = 1;
    this.listarroles(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value,this.pagination.searchs, this.orderBy.nativeElement.value);
  }



  private register(e) {
    e.preventDefault();
    this.configService.loaddinStarter('start');
    this.role.name = e.target.elements[0].value;
    this.role.description = e.target.elements[1].value;

    if (this.role.name == "" && this.role.description == "") {
      this.configService.showAlert("Todos os campos são de caracter obrigatorio", 'alert-danger', true);
    } else {
      this.http.__call('role/registar', this.role).subscribe(
        res => {
          this.listarroles(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value,this.pagination.searchs, this.orderBy.nativeElement.value);
          this.configService.clearFormInputs(e);
          this.configService.loaddinStarter('stop');
          this.configService.showAlert(Object(res).message, 'alert-success', true);
        }
      );
    }
  }

  /**
   * @name "Editar role"
   * @description "Esta Função permite Registar role"
   * @author "caniggia.moreira@itgest.pt"
   * @param start
   * @param end
   */
  private editar(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    if (this.role.name == "" && this.role.description == "") {
      this.configService.showAlert("Todos os campos são de caracter obrigatorio", 'alert-danger', true);

    } else {
      this.http.__call('role/update/' + this.role.id, this.role).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            this.configService.clearFormInputs(e);
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.listarroles(this.pagination.page, this.pagination.perPage, this.search.nativeElement.value,this.pagination.searchs, this.orderBy.nativeElement.value);

          }
        }
      );
    }
    this.configService.loaddinStarter('stop');
  }

  private refresh(id,nome, slug, descricao ) {
        this.pagination.searchs = null
        this.role.id = id;
        this.role.name = nome;
        this.role.slug= slug;
        this.role.description = descricao;


  }

  private ini() {
    this.pagination.searchs = null
    this.role = {
      id: null,
      description: null,
      slug: null,
      name: null,

    }
  }

  private role_id;

   private rolePermissions(id) {

    this.permissions = []
    this.configService.loaddinStarter('start');
     if(id)this.role_id = id;
    this.http.__call('role/getAllPermissionsOfRole/' + this.role_id, { search: this.pagination.searchs }).subscribe(
      response => {
        this.permissions = Object(response).data;
        this.configService.loaddinStarter('stop');
        //console.log(this.permissions,Object(response).data,Object(response))
      }
    );
  }

  private countar(contar: any[]) {
    return contar.length;
  }

  private validarPermissionRole(id) {
    var i = 0;
    this.permissions.forEach(element => {
      if (element.permission_id == id) {
        i++;
      }
    });
    return i;
  }


  private adicionarPermissionRole(role_id, permission_id) {
    this.configService.loaddinStarter('start');
    this.http.__call('role/adicionarPermissionRole/', { role_id: role_id, permission_id: permission_id }).subscribe(
      res => {
        if (Object(res).code == 500) {
          this.configService.showAlert(Object(res).message, 'alert-danger', true);
        }
        //this.auth.logoutUser();
        ///this.auth.updatePermissionsCurrentUser();
        this.configService.loaddinStarter('stop');
      }

    );
  }

}
