import { Component, OnDestroy, OnInit,ViewChild } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

import { ExcelService } from 'src/app/services/excel.service';
import * as moment from 'moment';
import { CreateOrEditProdutoComponent } from './create-or-edit-produto/create-or-edit-produto.component';
import { ProdutoService } from './produto.service';
@Component({
  selector: 'app-listar-produtos',
  templateUrl: './listar-produtos.component.html',
  styleUrls: ['./listar-produtos.component.css']
})

export class ListarProdutosComponent implements OnInit, OnDestroy {

  @ViewChild("editar") getListOfItem: CreateOrEditProdutoComponent

  constructor(private http: HttpService,
     private configService: ConfigService,
     private excelService: ExcelService,
     private produtoService:ProdutoService
     ) { }

  public produto: any;
  private loading: boolean = false;
  private produto_update = {
    id:null,
    nome:null,
    is_active: null
  };

  private items:any = [];
  private TipoProduto: any = [];
  ngOnInit() {
    this.http.filters.search = null
    this.getPageFilterData(1);
    this.listarTipoproduto();
  }
  ngOnDestroy() {
    this.http.filters.orderBy = null
  }
  exportAsXLSX(): void {
    var CurrentDate = new Date();
    var nameFile = "Lista_Produtos-" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    this.excelService.exportAsExcelFile(document.getElementsByClassName("exportAsXLSXCliente")[0], nameFile);
  }
  exportAsPDF(): void {
    //this.reportLoja.relatorioLoja(this.cobrancas, this.simpleForm.value, 'save');
  }

  imprimirPDF(): void {
    //this.reportLoja.relatorioLoja(this.cobrancas, this.simpleForm.value);
  }

/**
 *
 * @param start
 * @param end
 * @param search
 */
  private listarProdutos() {
    this.loading = true
    this.http.__call('artigo/listar', this.http.filters).subscribe(
      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;

        this.items = Object(response).data.data;
        this.loading = false
      }
    );
  }

  getPageFilterData(page: number) {
    console.log(this.http.filters.estado_id)
    if (this.http.filters.pagination.perPage == null) {
      this.http.filters.pagination.page = 1;
      return;
    }
    this.http.filters.pagination.page =Number.isInteger(page) ? page : 1 ;
    this.listarProdutos();
  }

  private listarTipoproduto() {
    this.http.call_get('tipo-produto/selectBox', null).subscribe(
      response => {
        this.TipoProduto = Object(response);
      }
    );
  }


  private setDataProduto(produto) {

    this.produtoService.produtos.centro_custo_id= ((produto || {}).centro_custo_id || null),
    this.produtoService.produtos.codigo_iso= (produto || {}).codigo_iso,
    this.produtoService.produtos.created_at= (produto || {}).created_at,
    this.produtoService.produtos.id= (produto || {}).id,
    this.produtoService.produtos.imposto_id= (produto || {}).imposto_id,
    this.produtoService.produtos.imposto_valor= (produto || {}).imposto_valor,
    this.produtoService.produtos.incidencia_id= ((produto || {}).incidencia_id || null),
    this.produtoService.produtos.is_active= (produto || {}).is_active,
    this.produtoService.produtos.moeda= (produto || {}).moeda,
    this.produtoService.produtos.moeda_id= (produto || {}).moeda_id,
    this.produtoService.produtos.nome= (produto || {}).nome,
    this.produtoService.produtos.observacao= (produto || {}).observacao,
    this.produtoService.produtos.tipo= (produto || {}).tipo,
    this.produtoService.produtos.tipo_produto_id= (produto || {}).tipo_produto_id,
    this.produtoService.produtos.valor= (produto || {}).valor,
    this.produtoService.produtos.classificacao_produto_id=((produto || {}).classificacao_produto_id || null)
    this.produto = produto
   // this.produtoService=produto


  }

  private actualizarEstadoProduto(item) {
    console.log(item)
    this.produto_update.is_active = item.is_active ? 0 : 1
    this.produto_update.id = item.id
    this.produto_update.nome = item.nome
    console.log(this.produto_update.is_active)

    this.http.__call('produto/actualizarestado/' + this.produto_update.id, this.produto_update).subscribe(
      response => {

        if (Object(response).code == 200) {
          var update = (this.produto_update.is_active == true) ? "Activado" : "Desactivado";
          this.configService.showAlert("Produto " + this.produto_update.nome + " foi " + update, "alert-success", true);
        }

      }
    );

    this.listarProdutos()

  }



}
