

import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { Response } from 'selenium-webdriver/http';
import { FormControl } from '@angular/forms';
import { runInThisContext } from 'vm';
import { type } from 'os';

@Component({
  selector: 'app-clientesunificados',
  templateUrl: './clientesunificados.component.html',
  styleUrls: ['./clientesunificados.component.css']
})
export class ClientesunificadosComponent implements OnInit {

  @ViewChild("search") search;
  @ViewChild("orderBy") orderBy;
  @ViewChild("searchData") searchData;
  @ViewChild("mostrarResultado") mostrarResultado;
  @ViewChild("orderAscOrDesc") orderAscOrDesc;
  checkbox1 = new FormControl('');
  radioButton = new FormControl('');

  private UnificarDados={
    id_cliente_manter:null,
    id_cliente_unificado:null,
    nome_cliente_manter:null,
    nome_cliente_unificar:null
  }
  private pagination = {
    total: null,
    perPage: 3,
    page: 1,
    lastPage: null,

    start: 1,
    end: 4,
    search: null,
    order: "created_at"
  };

  private pagination_two = {
    total: null,
    perPage: 3,
    page: 1,
    lastPage: null,

    start: 1,
    end: 4,
    search: null,
    order: "created_at"
  };



  private items: any = [];
  private id_cliente_unificao:any;
  private id_manter:any;
  private items_two:any= [];
  private items_contas: any=[];
  private provincias: any = [];
  private prioridades: any = [];
  private tipos: any = [];
  private reclamacoes: any = [];

  private tipoClientes: any = [];
  private tipoIdentidades: any = [];
  private contas: any = [];
  private clienteContas: any = [];
  private contactos: any = [];
  private tarifarios: any = [];
  private servicos: any = [];
  private chaveServicos: any = [];
  private chaves: any = [];
  private lojas: any = [];
  private carregamentos: any = [];
  private pedidos: any = [];
  wimaxMessage: string = null;
  classMessage: string = null;
  cdmaMessage: string = null;
  cdmaClassMessage: string = null
  private isFinalizar: boolean = true;
  public cliente_manter: any;
  public cliente_unificar: Array<any> = [];
  public cliente_nome_unificar: Array<any>=[];
  public cliente_nome_manter=null;
  public CheckValue: any;
  private radioID=null;
  private DadosClienteUnficar={

  }

  constructor(private http: HttpService, private configService: ConfigService) {

  }

  ngOnInit() {this.getPageFilterData(1);}

  getClienteUnifcados(cliente_id){
    this.configService.loaddinStarter('start');

    this.http.__call('unificar/getConta_Cliente_unificar', {id_cliente_manter: cliente_id}).subscribe(

      response => {

       console.log(this.items_two);
       this.items_two= Object(response);
        this.configService.loaddinStarter('stop');

      }
    );

  }

  private listContas(cliente_id_manter, cliente_unificar, cliente_nome_manter, cliente_nome_unificar){
  this.UnificarDados.id_cliente_manter= cliente_id_manter;
  this.UnificarDados.id_cliente_unificado= cliente_unificar;
  this.UnificarDados.nome_cliente_manter= cliente_nome_manter;
  this.UnificarDados.nome_cliente_unificar= cliente_nome_unificar;
    this.configService.loaddinStarter('start');
    this.http.__call('unificar/getConta_Cliente_manter',{id_cliente_manter: cliente_id_manter}).subscribe(
      response => {
        this.items_contas = Object(response).data;
         console.log(this.items_contas);
        this.configService.loaddinStarter('stop');
      }
    );
  }

  private deleteUnificado(){

    this.configService.loaddinStarter('start');
    this.http.__call('unificar/delete_unificado', this.UnificarDados).subscribe(response=>{
    this.configService.loaddinStarter('stop');


  })
  this.listClientes();

  }

  private FinalizarUnificao(){
    this.listClientes();
      this.http.__call('unificar/update_unificado', {id_cliente_manter:this.UnificarDados.id_cliente_manter,
        id_cliente_unificado: this.UnificarDados.id_cliente_unificado,conta_id:this.items_two.id, cliente_nome_manter:this.UnificarDados.nome_cliente_manter, cliente_nome_unificar:this.UnificarDados.nome_cliente_unificar}).
      subscribe(response=>{

      });
  }

  private unificarMassa(){

    this.http._get("unificar/masssa").subscribe(response=>{

      this.listClientes();

    });
  }

  private gerarPagesClienteUnificados() {
    for (var i = 1; i <= this.pagination_two.lastPage; i++) {
      this.pages.push(i);
    }
  }

  private listClientes() {

    this.configService.loaddinStarter('start');

    this.http.__call('unificar/clienteUnificados', this.http.filters).subscribe(

      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;
        this.items = Object(response).data.data;
        this.gerarPages();
        this.configService.loaddinStarter('stop');
      }
    );
  }










  /*
  private listarChaveServicos() {

    this.http.call_get('servico/chaveServicos', null).subscribe(
      response => {
        this.chaveServicos = Object(response).data;
      }
    );
  }

  */
  private pages = [];
  private nextProvPageNumber = 1;

  private gerarPages() {
    for (var i = 1; i <= this.pagination.lastPage; i++) {
      this.pages.push(i);
    }
  }
  private nextPage() {
    this.nextProvPageNumber++;
    if (this.nextProvPageNumber < 0) {
      this.nextProvPageNumber = this.pagination.page;
    }
    this.listClientes();

  }
  private prevPage() {
    this.nextProvPageNumber--
    if (this.nextProvPageNumber > 0) {
      this.listClientes();
      this.nextProvPageNumber = this.pagination.page;
    }
  }

  private refreshPaginate(page) {
    this.pagination.page = page;
    this.listClientes();
  }
  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
    this.listClientes();
  }


  /**
   * @name "get Retornar Impostos"
   * @descriptio "Esta Função permite Unific"
   */


  loadingServicoPosPago = false;










}

