import {Component, NgModule, OnInit} from '@angular/core';
import {List, ListInterface} from '../../../models/list/list.model';
import { MovementIntf } from 'src/app/models/card/movement'; 
import {BoardModel} from '../../../models/board/board.model';
import { LocalService } from '../../../providers/board/local/local.service';
 
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
// ES6 Modules or TypeScript
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'

@Component({
  selector: 'app-board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.css'],
})
export class BoardComponent implements OnInit {


  lists: ListInterface[];

  constructor(private localService: LocalService, private http: HttpService, private configService: ConfigService) { }

  ngOnInit() {
    this.saveBoard()  
    this.listTarefas()
    // ideally retrive and initialize from some storage.

  }

  addList() {
    const newList: ListInterface = new List();
    newList.position = this.lists.length + 1;
    newList.name = `Tarefa #${newList.position}`;
    newList.open = true;
    if (this.lists === undefined) {
      this.lists = [];
    }
    this.lists.push(newList);
  }

  moveCardAcrossList(movementInformation: MovementIntf) {
    const cardMoved = this.lists[movementInformation.fromListIdx].cards.splice(movementInformation.fromCardIdx, 1);
    this.lists[movementInformation.toListIdx].cards.splice(movementInformation.toCardIdx , 0 , ...cardMoved);
  }
  openBoard(){

  }
  saveBoard() {
    /*this.configService.salvar();
    if (this.configService.swalTest == true){
      const boardModel = new BoardModel();
      boardModel.lists = this.lists;
      //this.register(JSON.stringify(boardModel));
      this.localService.saveBoard(boardModel);
      alert('o');
    }*/

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false,
    })

    swalWithBootstrapButtons.fire({
      title: 'Você tem certeza?',
      text: "Você não poderá reverter isso!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, Salvo!',
      cancelButtonText: 'Não, Cancele!',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        const boardModel = new BoardModel();
        boardModel.lists = this.lists; 
        (JSON.stringify(boardModel)); 
        swalWithBootstrapButtons.fire(
          'Salvo!',
          'Seu arquivo foi salvo.',
          'success'
        ) 
      } else if (
        // Read more about handling dismissals
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelado',
          'Seu arquivo foi cancelado:)',
          'error'
        ) 
      }
    })
    
    
    
  }

  deleteList(listIndex: number){
      this.lists.splice(listIndex,1);
  }


  /**
  * @name "Registar tarefas"
  * @description "Esta Função permite Registar tarefas"
  * @author "caniggia.moreira@itgest.pt"
  * @param start 
  * @param end 
  */
  private register(tarefas: string) {
    this.configService.loaddinStarter('start'); 
    this.http.__call('tarefa/registar', { tarefa: tarefas}).subscribe(
      res => {
        if (Object(res).code == 500) {
          this.configService.showAlert(Object(res).message, 'alert-danger', true);
        } else {
          this.configService.showAlert(Object(res).message, 'alert-success', true); 
           this.configService.loaddinStarter('stop');
        }
      }
    )
      ;
  }

  listTarefas(){
    this.tarefas();
    const board = this.localService.getBoard();
    this.lists = board.lists || [];
  }
  /**
 * @name "Listar series"
 * @descriptio "Esta Função permite Listar todas series"
 * @author "caniggia.moreira@itgest.pt"
 * @param start 
 * @param end 
 */
  private tarefas() {
    this.http.call_get('tarefa/listar',null).subscribe(
      response => {   
        localStorage.setItem(`board`, JSON.parse(JSON.stringify(Object(response).data[0].tarefa))); 
      }
    );
  }
}
