import { Component, OnInit, Input } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';

@Component({
  selector: 'app-moeda',
  templateUrl: './moeda.component.html',
  styleUrls: ['./moeda.component.css']
})
export class MoedaComponent implements OnInit {

  

  private moeda = {
    id: null,
    nome: null,
    codigo_iso: null,
    unidade_fracionaria: null,
    descricao: null,
    activo: null,
    operacao: null
  }

  private cambio = {
    id: null,
    valor_cambio: null,
    data_cambio: null,
    is_active: null,
    moeda_id: null,
    user_id: null
  }

  //private moedas: any = [];
  @Input() moedas: any = [];

  private cambios: any = [];


  constructor(private http: HttpService, private configService: ConfigService) { }

  ngOnInit() {
 
  }

  

  /**
  * @name "Registar moeda"
  * @description "Esta Função permite Registar moedas"
  * @author "caniggia.moreira@itgest.pt"
  * @param start 
  * @param end 
  */
  private register() {

    if (this.moeda.nome == null || this.moeda.codigo_iso == null || this.moeda.activo == null) {
      this.configService.showAlert('Os campos nome, código ISO, Unidade Fracionária e activo, são de caracter obrigatorio', 'alert-danger', true);
    } else {
      var url = 'moeda/register';
      if (this.moeda.id != null) {
        url = 'moeda/update/' + this.moeda.id;
      }
      this.configService.loaddinStarter('start');
      this.http.__call(url, this.moeda).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.configService.listarMoedas()
            this.ini();
            this.configService.loaddinStarter('stop');
          }
        }
      );
    }
  }

  private ini() {
    this.moeda = {
      id: null,
      nome: null,
      codigo_iso: null,
      descricao: null,
      unidade_fracionaria: null,
      activo: null,
      operacao: null
    }

    this.cambio = {
      id: null,
      valor_cambio: null,
      data_cambio: null,
      is_active: null,
      moeda_id: null,
      user_id: null
    }
  }


  private moedaSelectd(moeda: any) {
    this.cambio.moeda_id = moeda.id;
    console.log(this.cambio);
    this.listarCambios(this.cambio.moeda_id);
  }

  // ------------  Gestão de Cambios --------------------------------
  /**
  * @name "Registar Cambio"
  * @description "Esta Função permite Registar cambio"
  * @author "caniggia.moreira@itgest.pt"
  * @param start 
  * @param end 
  */
  private registerCambio() {

    if (this.cambio.valor_cambio == null || this.cambio.data_cambio == null || this.cambio.is_active == null) {
      this.configService.showAlert('Todos os campos são de caracter obrigatorios', 'alert-danger', true);
    } else {
      var url = 'moeda/cambio/register';
      if (this.cambio.id != null) {
        url = 'moeda/cambio/update';
      }
      this.configService.loaddinStarter('start');
      this.http.__call(url, this.cambio).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            var  moeda_id = this.cambio.moeda_id;
            this.listarCambios(this.cambio.moeda_id);
            this.ini();
            this.cambio.moeda_id = moeda_id
            this.configService.loaddinStarter('stop');
          }
        }
      );
    }
  }

  /**
  * @name "Listar Cambios"
  * @descriptio "Esta Função permite Listar todos os cambios"
  * @author "caniggia.moreira@itgest.pt"
  * @param start 
  * @param end 
  */
  private listarCambios(id: number) {

    this.configService.loaddinStarter('start');
    this.http.call_get('moeda/cambio/listar/' + id, null).subscribe(
      response => {
        this.cambios = Object(response).data[0].cambios;
        this.configService.loaddinStarter('stop');
      }
    );
  }

  editarMoeda(m: any) {

    this.moeda.id = m.id;
    this.moeda.nome = m.nome;
    this.moeda.codigo_iso = m.codigo_iso;
    this.moeda.activo = m.activo;
    this.moeda.unidade_fracionaria = m.unidade_fracionaria;
    this.moeda.descricao = m.descricao;

  }

}
