import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { FormBuilder, FormGroup } from '@angular/forms';


@Component({
  selector: 'app-view-armazem-produto-desponiveis',
  templateUrl: './view-armazem-produto-desponiveis.component.html',
  styleUrls: ['./view-armazem-produto-desponiveis.component.css']
})
export class ViewArmazemProdutoDesponiveisComponent implements OnInit {


  @Input() modal: string = "modalArmazemDisponiveis";
  @Input() title: string = "Registar Equipamento";
  @Input() resultados: any;
  @Input() ferramenta: any;

  submitted = false;
  formErrors: any;
  private loading: boolean = false;
  private ver: boolean = true;

  private success_new: boolean = false;

  private items: any = [];

  @Input() simpleFormCreateOrEdit: FormGroup;

  @Output() private loadList = new EventEmitter<any>();

  public filters = {
    search: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    pagination: {
      perPage: 5,// Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima, 
      lastPage: null,
      total: null // defini o total de registo da BD
    },
    filter: null // subelemente do filter
  }

  constructor(private http: HttpService, private configService: ConfigService, private formBuilder: FormBuilder) { 
   
  }

  ngOnInit() {
  }

}
