import { Injectable } from '@angular/core';
import { environment } from "../../../environments/environment";
import { HttpHeaders, HttpClient, HttpParams , HttpResponse} from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { map} from 'rxjs/operators';
import { AuthService } from "../auth/auth.service";

import { of, timer, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged,tap, switchMap, finalize} from 'rxjs/operators';


@Injectable()
export class ApiService {
  constructor(
    private http: HttpClient,
    private auth: AuthService
  ) {  }

  public params = new HttpParams();
  public token = this.auth.currentUserValue ? `${this.auth.currentUserValue.token.type} ${this.auth.currentUserValue.token.token}` : null

  private setHeaders(): HttpHeaders {
    const token = this.auth.currentUserValue ? `${this.auth.currentUserValue.token.type} ${this.auth.currentUserValue.token.token}` : null
    const headersConfig = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return new HttpHeaders(headersConfig);
  }

  public formatErrors(error: any) {
     return Observable.throw(JSON.stringify(error));
  }

  get(path: string, httpParams: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get<any>(`${environment.app_url}${path}`, { headers: this.setHeaders(), params: httpParams })
   .catch(this.formatErrors)
  }

  post(path: string, body: Object = {}): Observable<any> {
    return this.http.post<any>(`${environment.app_url}${path}`,  body,  { headers: this.setHeaders() })
    .catch(this.formatErrors)
  }

  put(path: string, body: Object = {}, httpParams: HttpParams = new HttpParams()): Observable<any> {
    return this.http.put<any>(`${environment.app_url}${path}`, body,{ headers: this.setHeaders() })
    .catch(this.formatErrors)
  }


  delete(path:string, httpParams: HttpParams = new HttpParams()): Observable<any> {
    return this.http.delete<any>(`${environment.app_url}${path}`, { headers: this.setHeaders(), params: httpParams })
    .catch(this.formatErrors)
  }
 
  public file(path: string, type_application:string, body: Object = {}): Observable<any> {  
    const headersConfig = {responseType: 'blob' as 'json', Authorization: this.token};
    return this.http.post<Blob>(`${environment.app_url}${path}`, body, headersConfig).pipe(
      map((res) => {
          return new Blob([res], { type: 'application/'+type_application });
        })
    ).catch(this.formatErrors);
  }

}
