import { Injectable, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import { ConfigService } from 'src/app/providers/config/config.service';

@Injectable({
  providedIn: 'root'
})
export class ReportClienteService {

  public pdfEvent = new EventEmitter<Object>();

  constructor(private configService: ConfigService) { }


  public relatorioClientes(p = 'print', file: any,imgData) {


    var today = moment().format("DD-MM-YYYY H:mm:ss");
    var doc = new jsPDF('l', '', 'a3')
    doc.setProperties({
      title: 'LISTA_STOCK_MOVIMENTOS'+today,
      subject: 'Report',
      author: 'Unig',
      keywords: '',
      creator: 'bewater'
    });
    doc.addImage(imgData, 'JPEG',10, 10, 28, 18)
    const totalPagesExp = "{total_pages_count_string}";

    doc.setFontType('bold')
    doc.setFontSize(15)
    // doc.text(145, 60, 'Relatório', 'center')
    doc.setFontSize(15)
    doc.text(150, 25, 'LISTA STOCK MOVIMENTOS', 'center')


    doc.autoTable({
      html: file,
      didParseCell: function (data) {
        var rows = data.table.body;
        if (data.row.index === 0) {
          data.cell.styles.fontStyle = 'bold';
          data.cell.styles.textColor = "white";
          data.cell.styles.fillColor = [32, 95, 190];
        }
      },
      didDrawPage: data => {
        let footerStr = "Página " + doc.internal.getNumberOfPages();
        if (typeof doc.putTotalPages === 'function') {
          footerStr = footerStr + " de " + totalPagesExp;
        }
        doc.setFontType('bold')
        doc.setFontSize(10);
        doc.text(today,data.settings.margin.left, doc.internal.pageSize.height - 2)
        doc.setFontSize(10);
        doc.text(footerStr, 393, doc.internal.pageSize.height - 2, 'left');
      },
      styles: { textColor: [0, 0, 0] },
    tableWidth: 10,
      columnStyles: {
        0: {cellWidth: 20,halign: 'center'},
        1: {cellWidth: 60,halign: 'center'},
        2: {cellWidth: 50,halign: 'center'},
        3: {cellWidth: 50,halign: 'center'},
        4: {cellWidth: 50,halign: 'center'},
        5: {cellWidth: 50,halign: 'center'},
        6: {cellWidth: 40,halign: 'center'},
        7: {cellWidth: 40,halign: 'center'},
        8: {cellWidth: 40,halign: 'center'},
        9: {cellWidth: 40,halign: 'center'},
        10: {cellWidth: 40,halign: 'center'},
      },
      rowsStyles: {},
      startY: 58,
      theme: 'grid',

    })

    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }

    if (p === 'save') {
      doc.save();
    } else {
      doc.autoPrint();
      doc.output("dataurlnewwindow");
    }


  }
}
