import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { ExcelService } from 'src/app/services/excel.service';
import { AuthService } from 'src/app/providers/auth/auth.service';
import * as moment from 'moment';
import { RotaService } from '../rotas.service';
import { requiredTrue } from '@rxweb/reactive-form-validators';
import { ExcelAutoService } from 'src/app/services/excel/excel-auto.service';
import { RoteiroService } from '../../reports/roteiro/roteiro.service';

@Component({
  selector: 'app-historico-anomalias',
  templateUrl: './historico-anomalias.component.html',
  styleUrls: ['./historico-anomalias.component.css']
})
export class HistoricoAnomaliasComponent implements OnInit, OnDestroy {

  @ViewChild("search") search;
  @ViewChild("orderBy") orderBy;
  @ViewChild("searchData") searchData;
  @ViewChild("mostrarResultado") mostrarResultado;

  public currentUser: any;
  private loading: boolean = false;

  private has_rotas_header: boolean = false;
  motivos_nao_leitura_list: any = [];
  motivos_alteracao_leitura_list: any = [];

  private rotas_header: any = [];
  private rotaRuns: any = [];

  private corridasLeiturasArray = [];
  private leituraToProcess: boolean = true;
  contrato: any = {}
  leituras: any = []

  private DadosEmpresa: any = [];

  private rota_header = {
    id: null,
    descricao: null,
    data_inicio: null,
    data_fim: null,
    leitor_id: null,
    user_nome: null,
    estado: null,
  }

  private rotarun = {
    id: null,
    nome_cliente: null,
    rota_header_id: null,
    conta_id: null,
    servico_id: null,
    id_servico: null,
    provincia_id: null,
    municipio_id: null,
    bairro_id: null,
    has_distrito: null,
  }

  private items: any = [];

  view_user = false

  //current data leituras
  leitura: any = {
    motivo_edicao: null,
    leitura: null,
    contrato_id: null,
    ultima_leitura: null,
    data_ultima_leitura: null,
    data_leitura: null,
    nao_leitura: null,
    motivo: null
  }

  private current_cliente = {
    cliente: null,
    contrato: null
  }

  constructor(
    private auth: AuthService,
    private http: HttpService,
    private _rotaService: RotaService,
    private configService: ConfigService,
    private reportRoteiro: RoteiroService,
    private excelService: ExcelAutoService,
  ) {

    this.getPageFilterData(1);
    this.currentUser = this.auth.currentUserValue;
    this._rotaService.getMotivosNaoLeitura()
      .subscribe(
        response => {
          this.motivos_nao_leitura_list = response.data
        }
      )
  }

  imprimirPDF(): void { }

  ngOnInit() {
    this.empresaUser();
    this.http.filters.search = null
    this.http.filters.orderBy = null
    this.getPageFilterData(1)
    this._rotaService.motivoAlteracaoLeituraForSelect()
      .subscribe(
        response => {
          this.motivos_alteracao_leitura_list = response.data
        }
      )
  }

  ngOnDestroy() {
    this.http.filters.orderBy = null
  }

  onReset() {
    this.leitura = {
      motivo_edicao: null,
      contrato_id: null,
      leitura: null,
      ultima_leitura: null,
      data_ultima_leitura: null,
      data_leitura: null,
      nao_leitura: null,
      motivo: null
    }
  }

  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      this.http.filters.pagination.page = 1;
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1;
    this.listar_historico_anomalias();
    this._resetFilters();
  }

  _resetFilters() {
    this.http.filters.data1 = null
    this.http.filters.data2 = null;
  }

  private listar_historico_anomalias() {
    this.loading = true
    this.http.__call('leitura-historico/listagem', { filters: this.http.filters }).subscribe(
      response => {
        this.http.filters.pagination.lastPage = Object(response).data.lastPage;
        this.http.filters.pagination.page = Object(response).data.page;
        this.http.filters.pagination.total = Object(response).data.total;
        this.http.filters.pagination.perPage = Object(response).data.perPage;
        this.items = Object(response).data.data;
        this.loading = false
      }
    );
  }

  private clearFormInputs() {
    this.rota_header.id = null,
      this.rota_header.descricao = null,
      this.rota_header.data_inicio = null,
      this.rota_header.data_fim = null,
      this.rota_header.leitor_id = null,
      this.rota_header.user_nome = null
  }

  private clearFormRotaRun() {

    this.rotarun.nome_cliente = null,
      this.rotarun.conta_id = null,
      this.rotarun.servico_id = null,
      this.rotarun.id_servico = null
  }

  _update() {
    console.log(this.leitura)
    this._rotaService.updateLeitura(this.leitura)
      .subscribe((response) => {

        this.listar_historico_anomalias()
        this._rotaService._closeModal("closeModalUpdateLeitura")

        this.getPageFilterData(1)
        this.leitura.leitura = {
          leitura: null,
          ultima_leitura: null,
          data_leitura: null,
          nao_leitura: null,
          motivo: null,
          contrato_id: null
        }

      })
  }

  _updateNaoLeituraToLeitura() {
    this.leitura.nao_leitura = false
    this.leitura.motivo = ''
    this._rotaService.updateLeitura(this.leitura)
      .subscribe((response) => {
        this._rotaService._closeModal("closeModalNaoLeitura")
        this._rotaService._closeModal("closeModalUpdateLeitura")
        this.listar_historico_anomalias()

        this.leitura = {
          leitura: null,
          ultima_leitura: null,
          data_leitura: null,
          nao_leitura: null,
          motivo: null
        }
      })
  }

  private setDataLeitura(leitura: any) {
    this.leitura.id = leitura.id
    this.leitura.motivo_edicao = leitura.motivo_edicao
    this.leitura.contador_id = leitura.contador_id
    this.leitura.contrato_id = leitura.contrato_id
    this.leitura.nao_leitura = leitura.nao_leitura
    this.leitura.motivo = leitura.motivo
    this.leitura.leitura = leitura.leitura
    this.leitura.perido = leitura.perido
    this.leitura.ultima_leitura = leitura.ultima_leitura
    this.leitura.data_leitura = moment(leitura.data_leitura).format("YYYY-MM-DD");
    this.leitura.data_ultima_leitura = moment(leitura.data_ultima_leitura).format("YYYY-MM-DD");
    this.leitura.consumo = leitura.consumo
    this.leitura.estado = leitura.estado
  }

  historicos: any[];
  private getLeituraHistorico(leitura_id: any) {
    this.loading = true;
    this.http.call_get("gestao/leitura-historicos/" + leitura_id, null)
      .subscribe(res => {
        this.historicos = Object(res).data;
        this.loading = false;
      });

  }

  setleitura: any;
  _title: any = 'Anular Leitura';
  text_alert: any = 'Tens certeza que desejas anular está leitura ?';

  setLeitura(leitura: any) {
    this._title = leitura.is_annulled ? 'Voltar ao normal' : 'Anular Leitura';
    this.text_alert = leitura.is_annulled ? 'Voltar ao normal' : 'Tens certeza que pretende anular está leitura?';
    this.setleitura = leitura;
  }

  anularleitura() {
    this.loading = true;
    this.http.__call("anulateOrnot-leitura/" + this.setleitura.id, { motivo: this.leitura.motivo_anulacao })
      .subscribe((res) => {
        this.getPageFilterData(1);
        this.loading = false;
      })
  }

  public isLeitura = false;
  _isLeitura(event) {
    this.isLeitura = false;
    if (event.target.value == 0) {
      this.isLeitura = true;
    }
    this.http.filters.motivo_id = null;
  }


  private empresaUser() {
    this.configService.loaddinStarter('start');
    this.http.call_get('empresa/empresa-user', null).subscribe(
      response => {
        this.DadosEmpresa = Object(response).data;
      }
    );
  }

  exportAsPDF(): void {
    var file = document.getElementsByClassName("tablePDF")[0]
    this.reportRoteiro.reportHistoricoLeituras(file, this.DadosEmpresa, 'save', 'Rotas', this.leituras.length, this.current_cliente);
  }

  exportAsXLSXAsHistorico(): void {
    var CurrentDate = new Date();

    var keys = [
      { key: 'cil', width: 35, style: { font: { name: 'Calibri' } } },
      { key: 'morada_format', width: 25 },
      { key: 'ultima_leitura', width: 55 },
      { key: 'leitura', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'consumo', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'is_facturada', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'tipo_facturacao', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'numero_serie', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'data_leitura', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'is_estado', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'data_registo', width: 20, style: { font: { name: 'Calibri' } } },
      { key: 'leitor', width: 20, style: { font: { name: 'Calibri' } } },
    ];

    var Cols = ["Cil", "Morada", "Última Leitura", "Leitura", "Consumo", "Facturado?", "Tipo De Facturação", "Contador", "Data de Leitura	", "Estado", "Data Registo	", "Registado Por"]
    var title = 'Histórico de Leitura';
    var nameFile = "Histórico de Leitura -" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    this.excelService.excels(this.leituras, nameFile, this.DadosEmpresa.logotipo, keys, Cols, title, 5, 12, 30, 3)
  }

  validateDate(event: any) {
    if (moment(event.target.value).format('YYYY-MM-DD') > moment(new Date()).format('YYYY-MM-DD')) {
      this.leitura.data_leitura = null;
      this.configService.showAlert(
        'A data da leitura não deve ser maior que actual.',
        'alert-error', true);
      return;
    }
  }

  _getLeiturasByContrato(item: any) {
    this.current_cliente.cliente = item.cliente
    this.current_cliente.contrato = item.contrato_id
    this.contrato = item.contrato_id;
    
    this.loading = true
    this.configService.loaddinStarter('start')

    this._rotaService.getLeiturasByContrato(item.contrato_id)
      .subscribe(
        response => {
          this.leituras = response.data
          this.loading = false
          this.configService.loaddinStarter('stop')
        }
      )
  }

}
