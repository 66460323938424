import { Component, ElementRef, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { ExcelAutoService } from 'src/app/services/excel/excel-auto.service';
import * as moment from 'moment';
import { CreateOrEditContadoresComponent } from './create-or-edit-contadores/create-or-edit-contadores.component';
import { fromEvent, Observable } from 'rxjs';
@Component({
  selector: 'app-contadores',
  templateUrl: './contadores.component.html',
  styleUrls: ['./contadores.component.css']
})
export class ContadoresComponent implements OnInit,AfterViewInit {

   @ViewChild(CreateOrEditContadoresComponent ) editeElement:CreateOrEditContadoresComponent
   @ViewChild("disableElement") disableElement
   @ViewChild("aneble") aneble


  private contador = {
    id: null,
    contador: null,
    contador_ID: null,
    marca_id: null,
    modelo_id: null,
    medicao_id: null,
    estado_contador_id: null,
    numero_serie: null,
    id_classe_precisao: null,
    tipo_contador_id: null,
    calibre_id: null,
    caudal_id: null,
    digitos: null,
    ano_fabrico: null,
    ano_instalacao: null,
    observacao: null,
    fabricante_id: null,
    selo: null,
    armazem_id: null,
    ultima_leitura: null,
    ultima_verificacao: null,
    estadoContadorSlug: null
  }

  private contadores_leituras = {
    id: null,
    tipo_registo_id: null,
    data: null,
    ultima_leitura: null
  }

  private contadores_estado = {
    id: null,
    estado_contador_id: null,
    observacao: null
  }

  private items: any = [];
  private estado: any = [];
  private filiais: any = [];
  private Modelo: any = [];
  private Marca: any = [];
  private Medicao: any = [];
  private ClassePrecisao: any = [];
  private Fabricante: any = [];
  private TipoContador: any = [];
  private TipoRegisto: any = [];
  private Armazen: any = [];
  private historicos: any = [];
  private EstadoContadores: any = [];
  private Caudal: any = [];
  private Calibre: any = [];
  private estadosContadores = [];
   public editar=false
  private localUrl: any;
  private contadorValid: boolean = false;
  public filter = {
     estado:null
  }
  public filters = {
    search: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    direction: null,
    municipio_id: null,
    bairro_id: null,
    quarteiraoId: null,
    distrito_id: null,
    motivo_id: null,
    rota_header_id: null,
    leitor_id: null,
    data1: null,
    data2: null,
    nao_leitura: null,
    pagination: {
      perPage: 25,// Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima,
      lastPage: null,
      total: null // defini o total de registo da BD
    },
    filter: null // subelemente do filter
    ,is_allexel:false
  }


  constructor(private http: HttpService, private configService: ConfigService,private excelService:ExcelAutoService) { }
  
  
  ngOnInit() {
    this.filters.search = null
    this.getPageFilterData(1);
    this.listarMarca();
    this.listarArmazen();
    this.listarTipoContador();
    this.listarFabricante();
    this.listarClassePrecisao();
    this.listarMedicao();
    this.listarEstadoContador();
    this.listarCaudal();
    this.listarCalibre();
    this.listaEstado()
    this.empresaUser()
  }

 
  ngAfterViewInit(): void {
    let action:Observable<any>= this.disableElement.click()
    console.log(this.disableElement)

    action.subscribe(res=>{
     //this.simpleFormContador.controls.ultima_leitura.enable() 
     this.editeElement.simpleFormContador.controls.ultima_leitura.enable() 
       console.log(this.editeElement)
       return
    })
 }


  setTrue() {
    this.filters.pagination.page = Number.isInteger(this.filters.pagination.page) ? this.filters.pagination.page : 1 ;
    this.filters.is_allexel=true
    this.listaContadores()
  }
  private empresaUser() {
    this.http.call_get('empresa/empresa-user', null).subscribe(
      response => {
        this.localUrl = Object(response).data.logotipo
      }
    );
  }

  private listaContadores() {

    this.configService.loaddinStarter('start');
    this.filters.filter=this.filter
    this.http.__call('contadores/listagem', this.filters).subscribe(
      response => {
        if (this.filters.is_allexel == false) { 
          this.filters.pagination.lastPage = Object(response).data.lastPage;
          this.filters.pagination.page = Object(response).data.page;
          this.filters.pagination.total = Object(response).data.total;
          this.filters.pagination.perPage = Object(response).data.perPage;
          this.items = Object(response).data.data;
        } else {
          this.items = Object(response).data.data;
          this.exportAsXLSX(this.items)
        }
        this.configService.loaddinStarter('stop');
        this.filters.is_allexel=false
      }
    );
  }

  edite(){
    this.editar=true
  }

  exportAsXLSX(data: any): void {
    var CurrentDate = new Date();
    var keys = [
      { key: 'marca', width: 30 },
      { key: 'modelo', width: 30 },
      { key: 'numero_serie', width: 50 },
      { key: 'contador_ID', width: 30 },
      { key: 'estadoContadorDesc', width: 50 },
      { key: 'tipo_contador', width: 40 },
      { key: 'classe_precisao', width: 30 },
      { key: 'medicao', width: 30 },
      { key: 'calibre', width: 30 },
      { key: 'digitos', width: 30 },
      { key: 'fabricante', width: 30 },
      { key: 'centro_distribuicao', width: 30 },
      
    ];
    
    var Cols = [
     "Marca",	
     "Modelo",
     "Nº Contador",	
     "Contador ID",	
     "Estado",
     "Tipo de Contador",
     "Classe Precisão"	,
     "Medição",	
     "Calibre",
     "Digítos",
     "Fabricante",	
     "Armazém",
   ]
    var title = 'LISTA DE CONTADORES'
    var nameFile = "LISTA_DE_CONTADORES_" + moment(CurrentDate).format('DD') + "-" + moment(CurrentDate).format('MM') + "-" + moment(CurrentDate).format('YYYY') + " "
      + moment(CurrentDate).format('H') + ":" + moment(CurrentDate).format('m')
    this.excelService.excels(data, nameFile, this.localUrl, keys, Cols, title, 5, 12, 40,3)
  }

  private listaEstado() {
    this.http.call_get('contadores/estado', null).subscribe(
      response => {
        this.estado = Object(response).data;
      }
    );
  }

  getPageFilterData(page: number) {
    if (this.filters.pagination.perPage == null) {
      return;
    }
     this.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
    this.listaContadores();
  }

  private carregando = {
    filial: 'Seleccione a filial',
    tecnologia: 'Seleccione a tecnologia',
  }

  private clearFormInputs(e) {
    e.target.elements[0].value = null;
    e.target.elements[1].value = null;
    e.target.elements[2].value = null;
  }

  onReset() {
    this.contadores_estado.observacao = null;
    this.contadores_estado.estado_contador_id = null;
    this.contadores_leituras.data = null;
    this.contadores_leituras.tipo_registo_id = null;
    this.contadores_leituras.ultima_leitura = null;
  }

  private registerleitura(e) {
    this.configService.loaddinStarter('start');
    e.preventDefault();
    {
      if (this.contadores_leituras.tipo_registo_id == null) {
        this.configService.showAlert("É obrigatório seleccionar o Tipo de Registo", 'alert-danger', true);
        this.configService.loaddinStarter('stop');
      } else {
        if (this.contadores_leituras.data == null) {
          this.configService.showAlert("É obrigatório seleccionar Data", 'alert-danger', true);
          this.configService.loaddinStarter('stop');
        } else {
          this.http.__call('contador-leitura/create/' + this.contador.id, this.contadores_leituras).subscribe(
            res => {
              if (Object(res).code == 500) {
                this.configService.showAlert(Object(res).message, 'alert-danger', true);
                this.configService.loaddinStarter('stop');
              } else {
                this.configService.showAlert(Object(res).message, 'alert-success', true);
                this.listaContadores();
                this.clearFormInputs(e);
                this.configService.loaddinStarter('stop');
              }
            }
          )
        }
      }
    };
  }

  private LeiturabyContador() {
    this.http.call_get('contador-leitura/leitura/' + this.contador.id, null).subscribe(
      data => {
        this.contadores_leituras.ultima_leitura = Object(data).data.ultima_leitura;
      }
    );
  }

  private getEstadoFilhosByEstadoPai() {
    console.log(this.contador.id)
    this.loadingEstado.estado = 'Carregando...';
    this.http.call_get('contador/update/getestados/' + this.contador.id, null).subscribe(
      response => {
        this.estadosContadores = Object(response).data;
        console.log(Object(response).data)
        this.loadingEstado.estado = 'Seleccione o Estado';
      }
    );
  }

  private listarFilial() {
    this.carregando.filial = 'Carregando...';
    this.http.call_get('filial/selectBox', null).subscribe(
      response => {
        this.filiais = Object(response).data;
        this.carregando.filial = 'Seleccione a Filial';
      }
    );
  }

  private listarModelo() {
    this.http.call_get('modelo/selectBox', null).subscribe(
      response => {
        this.Modelo = Object(response);
      }
    );
  }

  private listarMarca() {
    this.http.call_get('marca/selectBox', null).subscribe(
      response => {
        this.Marca = Object(response);
      }
    );
  }

  private listarMedicao() {
    this.http.call_get('medicao/selectBox', null).subscribe(
      response => {
        this.Medicao = Object(response);
      }
    );
  }

  private listarClassePrecisao() {
    this.http.call_get('classe-precisao/selectBox', null).subscribe(
      response => {
        this.ClassePrecisao = Object(response);
      }
    );
  }

  execute(execute){
    execute
  }

  private listarFabricante() {
    this.http.call_get('fabricante/selectBox', null).subscribe(
      response => {
        this.Fabricante = Object(response);
      }
    );
  }

  private listarTipoContador() {
    this.http.call_get('tipo-contador/selectBox', null).subscribe(
      response => {
        this.TipoContador = response;
      }
    );
  }

  private listarArmazen() {
    this.http.call_get('armazem/selectBox', null).subscribe(
      response => {
        this.Armazen = Object(response).data;
      }
    );
  }

  private listarEstadoContador() {
    this.http.call_get('estado-contador/selectBox', null).subscribe(
      response => {
        this.EstadoContadores = Object(response);
      }
    );
  }

  private listarEstadoContadorEstado(id) {
    this.http.call_get('estado-contador/selectBoxUpdate/' + id, null).subscribe(
      response => {
        this.EstadoContadores = Object(response);
      }
    );
  }

  private getValidateContador(id) {
    this.http.call_get('contador/getValidateContador/' + id, null).subscribe(
      response => {
        this.contadorValid = Object(response);
      }
    );
  }

  private listarCaudal() {
    this.http.call_get('caudal/selectBox', null).subscribe(
      response => {
        this.Caudal = Object(response);
      }
    );
  }
  private listarCalibre() {
    this.http.call_get('calibre/selectBox', null).subscribe(
      response => {
        this.Calibre = Object(response);
      }
    );
  }

  private setDataContador(contador: any) {
    this.contador = contador;
  }

  private loadingEstado = {
    estado: 'Seleccione o estado',
  }

  disableItems(event){
    console.log(event)
     //return event()
  }

  private editarEstadoContador(e) {
    this.disableItems(1)
    if (this.contadores_estado.estado_contador_id == null) {
      this.configService.showAlert("É obrigatório seleccionar o Estado", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    } else if (this.contadores_estado.observacao == null) {
      this.configService.showAlert("É obrigatório descrever Observação sobre a atualização o estado do contador", 'alert-danger', true);
      this.configService.loaddinStarter('stop');
    } else {
      this.configService.loaddinStarter('start');
      this.http.__call('contador/update/estado/' + this.contador.id, this.contadores_estado).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
          } else {
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.listaContadores();
            this.clearFormInputs(e);
            this.configService.loaddinStarter('stop');
            this.getPageFilterData(1);

          }
        }
      );
    }
  }

  private getHistoricoEstado() {

    this.configService.loaddinStarter('start');

    this.http.call_get('historico/estado/contador/' + this.contador.id, null).subscribe(
      response => {
        this.historicos = Object(response).data;
        this.configService.loaddinStarter('stop');
      }
    )
  }

  private ini() {
    this.contador = {
      id: null,
      contador: null,
      contador_ID: null,
      marca_id: null,
      modelo_id: null,
      medicao_id: null,
      numero_serie: null,
      id_classe_precisao: null,
      tipo_contador_id: null,
      calibre_id: null,
      caudal_id: null,
      digitos: null,
      ano_fabrico: null,
      ano_instalacao: null,
      observacao: null,
      fabricante_id: null,
      selo: null,
      armazem_id: null,
      ultima_leitura: null,
      ultima_verificacao: null,
      estado_contador_id: null,
      estadoContadorSlug: null
    }
  }
}
