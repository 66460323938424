import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalOrdemServicoService {

  public flag:string=null

  constructor(
    
  ) { 
   
  }
}
