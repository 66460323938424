import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpProgressEvent, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { map, retry, catchError, first, scan } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { Observable } from 'rxjs';
import { Server } from 'https';
import axios from 'axios'
export interface Download {
  content: Blob | null;
  progress: number;
}
@Injectable({
  providedIn: 'root'
})

export class HttpService {

  // variavel de filtração de dados
  public filters = {
    search: null, // ordem de pesquisa de elemento
    orderBy: null, // Ordenação
    direction: null,
    municipio_id: null,
    bairro_id: null,
    rua_id: null,
    quarteirao_id: null,
    distrito_id: null,
    hasAgendamento: null,
    motivo_id: null,
    rota_header_id: null,
    is_facturada: null,
    estado_id: null,
    leitor_id: null,
    tipo_medicao_id: 'LEITURA',
    data1: null,
    data2: null,
    status: null,
    tipo_objecto_id: null,
    nao_leitura: null,

    tipo_ramal_id: null,
    diametro_ramal_id: null,
    material_ramal_id: null,
    keyFilter: null,

    pagination: {
      perPage: 5,// Entrada - define o limite de resultados a serem gerados
      page: 1, //  define a pagina inicial ou proxima,
      lastPage: null,
      total: null // defini o total de registo da BD
    },
    filter: null // subelemente do filter
  }

  constructor(private auth: AuthService, private http: HttpClient) {

  }


  public SocketPort = environment.app_url;


  public __delete(url: string, body: object) {
    const token = this.auth.currentUserValue ? `${this.auth.currentUserValue.token.type} ${this.auth.currentUserValue.token.token}` : null
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: token });
    url = environment.app_url + url;
    return this.http.delete<any>(url, body);
  }
  public $__call(url: string, body: object) {
    const token = this.auth.currentUserValue ? `${this.auth.currentUserValue.token.type} ${this.auth.currentUserValue.token.token}` : null
    const headers = new HttpHeaders({ Authorization: token })

    url = environment.app_url + url;
    return this.http.post<any>(url, body, { headers, reportProgress: true, observe: 'events' });
  }
  public __call(url: string, body: object) {
    const token = this.auth.currentUserValue ? `${this.auth.currentUserValue.token.type} ${this.auth.currentUserValue.token.token}` : null
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: token })

    url = environment.app_url + url;
    return this.http.post<any>(url, body, { headers });
  }

  public __callForEvent(url: string, body: object) {
    const token = this.auth.currentUserValue ? `${this.auth.currentUserValue.token.type} ${this.auth.currentUserValue.token.token}` : null
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: token })

    url = environment.app_url + url;
    return this.http.post<any>(url, body, { headers, reportProgress: true, observe: 'events' });
  }

  public call_get(url: string, body: object = null) {
    const token = this.auth.currentUserValue ? `${this.auth.currentUserValue.token.type} ${this.auth.currentUserValue.token.token}` : null
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: token });
    url = environment.app_url + url;
    return this.http.get(url, { headers });
  }

  public $call_get(url: string, body: object) {
    const token = this.auth.currentUserValue ? `${this.auth.currentUserValue.token.type} ${this.auth.currentUserValue.token.token}` : null
    const headers = new HttpHeaders({ responseType: 'blob' as 'json' });
    url = environment.app_url + url;
    return this.http.get<Blob>(url, { headers })
      .map(
        (res) => {
          return new Blob([res], { type: 'application/pdf' });
        });
  }

  public _get(url: string) {
    const token = this.auth.currentUserValue ? `${this.auth.currentUserValue.token.type} ${this.auth.currentUserValue.token.token}` : null
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: token });
    url = environment.app_url + url;
    return this.http.get(url);
  }
  progress: number = 0

  public _getFileFromServer(url): Observable<any> {
    url = environment.app_url + url;
    return this.http.post(url, null,
      {
        responseType: 'blob',
      }
    ).map(
      (res) => {
        return new Blob([res], { type: 'application' });
      });
  }


  public _getFileFromServerSaft(url): any {
    url = environment.app_url + url;
    return this.http.post<Blob>(url, null, { responseType: 'blob' as 'json' }).pipe(
      map(
        (res) => {
          return new Blob([res], { type: 'application/pdf' });
        })
    );
  }

  public fileFromServer(url): any {
    url = environment.app_url + url;
    return this.http.post<Blob>(url, null, { responseType: 'blob' as 'json' }).pipe(
      map(
        (res) => {
          return new Blob([res], { type: 'application/pdf' });
        })
    );
  }



  public getBaseUrl() {
    return environment.app_url;
  }

  canActivateRouterLink(permission: string): boolean {
    //console.log(permission)
    if (!permission) return true
    return this.auth.canActivateRouterLink(permission);
  }

  public caixa: any = [];

  public getOpenCaixa() {

    this.call_get('caixa/open', null).subscribe(
      response => {
        this.caixa = Object(response).data;

      }
    );
  }

  public codeReferencia: any;
  public validationReferenciaBancaria(ref, bank) {
    this.__call('referencia/validation', { referencia: ref, banco_id: bank }).subscribe(
      response => {
        this.codeReferencia = Object(response);
      }
    );
  }




}
