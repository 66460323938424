import { Component, OnInit, Input } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { PedidosComponent } from 'src/app/components/pedidos/pedidos/pedidos.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-circuito-empresarial-form',
  templateUrl: './circuito-empresarial-form.component.html',
  styleUrls: ['./circuito-empresarial-form.component.css']
})
export class CircuitoEmpresarialFormComponent implements OnInit {
  

  @Input() cliente: any;
  @Input() selectForms:any;
  @Input() siglaEstadoPedido:any;
  @Input() servicoTarifario:any;
  @Input() ServicoObject:any;

  private servico = {
    id: null,
    chaveServico: null,
    conta_id: null,
    estado: null,
    tarifario_id: null,
    adsl_username: null,
    adsl_password: null,
    cliente_id: null,
    contaDescricao: null,

    tipoFacturacao: null,

    agencia_id: null,
    estado_pedido: null,
    pedido_id: null,
    observacao_pedido: null,
    estado_actual_pedido: null,

    par_cabo: null,
    armario: null,
    central: null,
    caixa: null,
    cabo_id: null,
    armario_primario: null,
    armario_secundario: null,
    par_caixa: null,
    par_adsl: null,
    tipo_pedido: null,
    tipoPedido: null,
    estado_pedido_id: null,

     //******** */
     servico: null,  
     servico_class: null,
     servico_omg: null, 
     nivel_servico: null,
     descricao_servico: null,
     debito_binario: null, 
     interface_dte: null, 
     redundancia: null,  
     cpe_sede_a: null, 
     cpe_sede_b: null,
     endereco_ponto_a: null,  
     endereco_ponto_b: null,  
     latitude_ponto_a: null, 
     longitude_ponto_a: null, 
     latitude_ponto_b: null, 
     longitude_ponto_b: null
  }

  private interest = {
    id: null
  }

  private isFinalizar: boolean = true; 
  private armarioRecursos: any = [];

  private caixaRecursoRedes: any = [];
  private caboRecursoRedes: any = [];
  private parCabos: any = [];
  private armarioPrimarios: any = [];
  private armarioSecundarios: any = [];
  private parCaixas: any = [];
  private parADSLs: any = [];


  constructor(private http: HttpService, private configService: ConfigService, private router: Router, private PedidosComponent: PedidosComponent) { 

  }

  ngOnInit() {
    this.getPageFilterData(1);
  }

  private clearForm() {
    this.isFinalizar = true;
    this.servico.estado_pedido = null;
    this.servico.observacao_pedido = null;
    this.servico.chaveServico = null;
    this.servico.adsl_password = null;
    this.servico.adsl_username = null;
    this.servico.conta_id = null;
    this.servico.agencia_id = null;
    this.servico.central = null;
    this.servico.par_cabo = null;
    this.servico.armario = null;
    this.servico.caixa = null;
    this.servico.par_caixa = null;
    this.servico.armario_primario = null;
    this.servico.tipoFacturacao = null;
    this.servico.armario_secundario = null;
  }

  private showSubmit() {
    this.isFinalizar = true;
  }


  private finalizarPedido() {
    //console.log(this.ServicoObject)
    this.servico.estado_pedido = this.siglaEstadoPedido;
    this.servico.tarifario_id = this.servicoTarifario;
    this.servico.estado_pedido_id = this.ServicoObject.estado_pedido_id;
    this.servico.pedido_id = this.ServicoObject.pedido_id;
    this.servico.estado_actual_pedido = this.ServicoObject.estado_actual_pedido;
    this.servico.tipo_pedido = this.ServicoObject.tipo_pedido;
    this.servico.cliente_id = this.ServicoObject.cliente_id;
    this.servico.contaDescricao = this.ServicoObject.contaDescricao;
   
  

   if (this.servico.tarifario_id == null) {
      this.configService.showAlert('Não tem tarifário', 'alert-danger', true);
    } else if ( (this.servico.estado_pedido == 'FN' && this.servico.tipoFacturacao == null) && (this.servico.estado_pedido == 'FN' && this.servico.conta_id == null)) {
      this.configService.showAlert('Escolhe o tipo de Facturação', 'alert-danger', true);
    } else {

      this.isFinalizar = false;

      this.configService.loaddinStarter('start');
      this.http.__call('pedido/servico/conta', this.servico).subscribe(
        res => {
          if (Object(res).code == 500) {
            this.configService.showAlert(Object(res).message, 'alert-danger', true);
            this.isFinalizar = true;
          } else {
            this.configService.showAlert(Object(res).message, 'alert-success', true);
            this.configService.loaddinStarter('stop');
            //this.servico.tarifario_id = null;
            this.servico.longitude_ponto_b = null;
            this.servico.latitude_ponto_b = null;
            this.servico.endereco_ponto_b = null;
            this.servico.longitude_ponto_a = null;
            this.servico.latitude_ponto_a = null;
            this.servico.endereco_ponto_a = null;
            this.servico.descricao_servico = null;
            this.servico.cpe_sede_b = null;
            this.servico.cpe_sede_a = null;
            this.servico.nivel_servico = null;
            this.servico.servico_omg = null;
            this.servico.redundancia = null;
            this.servico.servico_class = null;
            this.servico.interface_dte = null;
            this.servico.debito_binario = null;
            this.servico.servico = null;
            this.servico.conta_id = null;
            this.cliente.tarifario_descricao = null;
            this.cliente.tipoFacturacao = null;
            this.servico.tarifario_id  = null;
            this.PedidosComponent.getPageFilterData(1);
          }
        }
      );
    }
    

  }

  private hideBotton(){
    this.PedidosComponent.hideBottonAvancar();
  }

  private gerarAdslPassword() {
    var anysize = 6;//the size of string
    let randomNumbers = '';
    var charset = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
    for (var i = 0; i < anysize; i++) {
      randomNumbers += charset[Math.floor(Math.random() * charset.length)];
    }
    this.servico.adsl_password = randomNumbers;

  }



  getPageFilterData(page: number) {
    if (this.http.filters.pagination.perPage == null) {
      return;
    }
    this.http.filters.pagination.page = Number.isInteger(page) ? page : 1 ;
  }

  generateAdslUsername(event: any) {
    this.servico.adsl_username = event.target.value;
  }



}
